import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { UserType } from "@common/ADAPT.Common.Model/embed/user-type";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { lastValueFrom } from "rxjs";
import { UserManagementService } from "../user-management.service";

/**
 * Select an access level (permission set), NOT an organisation role.
 */
@Component({
    selector: "adapt-select-access-level",
    templateUrl: "./select-access-level.component.html",
})
export class SelectAccessLevelComponent extends BaseComponent implements OnInit, OnChanges {
    @Input() public userType?: UserType;
    @Input() public accessLevel?: Role;
    @Input() public filter?: (accessLevel: Role) => boolean;
    @Input() public required = true;

    @Output() public accessLevelChange = new EventEmitter<Role>();

    public accessLevels?: Role[];

    constructor(
        private userManagementService: UserManagementService,
    ) {
        super();
    }

    public async ngOnInit() {
        await this.reload();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.userType && !changes.userType.firstChange) {
            this.reload();
        }
    }

    public async reload() {
        this.accessLevels = await lastValueFrom(this.userManagementService.getAccessLevels(this.userType));
        if (this.filter) {
            this.accessLevels = this.accessLevels.filter(this.filter);
        }
    }
}

import { Component, Input, ViewChild } from "@angular/core";
import { Goal } from "@common/ADAPT.Common.Model/organisation/goal";
import { IDxPopoverEvent } from "@common/ux/dx.types";
import { DxPopoverComponent } from "devextreme-angular";

@Component({
    selector: "adapt-display-goal-description",
    templateUrl: "./display-goal-description.component.html",
})
export class DisplayGoalDescriptionComponent {
    @Input() public goal?: Goal;

    public popoverVisible = false;
    public popoverCancelHiding = false;

    @ViewChild(DxPopoverComponent) private popoverComponent?: DxPopoverComponent;

    public get goalIdAttr() {
        return `goalId${this.goal?.goalId}`;
    }

    // handling the case where theme description is too longer than we need s scroll view
    // - has to prevent the popover from going away if pointer moved to the popover content
    //   and cancel hiding

    public preventHide() {
        this.popoverComponent?.instance.option("visible", true);
        this.popoverCancelHiding = true;
        this.popoverVisible = true;
    }

    public allowHide() {
        this.popoverCancelHiding = false;
        this.popoverVisible = false;
    }

    public onPopoverHiding(e: IDxPopoverEvent) {
        if (this.popoverCancelHiding) {
            e.cancel = true;
        }
    }
}

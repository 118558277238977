import { MeetingAgendaItem, MeetingAgendaItemStatus, MeetingAgendaItemType } from "./meeting-agenda-item";

export class MeetingAgendaItemExtensions {
    public constructor(private meetingAgendaItem: MeetingAgendaItem) { }

    public get isPreWork() {
        return this.meetingAgendaItem.type === MeetingAgendaItemType.PreWork;
    }

    public get isInProgress() {
        return this.meetingAgendaItem.status === MeetingAgendaItemStatus.InProgress;
    }

    public get isEnded() {
        return this.meetingAgendaItem.status === MeetingAgendaItemStatus.Ended;
    }

    public get isNotStarted() {
        return this.meetingAgendaItem.status === MeetingAgendaItemStatus.NotStarted;
    }

    public startItem() {
        this.meetingAgendaItem.status = MeetingAgendaItemStatus.InProgress;

        if (!this.meetingAgendaItem.meeting) {
            return [this.meetingAgendaItem, ...[]];
        }

        // clear other started item
        const otherStartedItems = this.meetingAgendaItem.meeting?.meetingAgendaItems
            .filter((i) => i !== this.meetingAgendaItem && i.extensions.isInProgress);
        otherStartedItems.forEach((i) => i.status = MeetingAgendaItemStatus.NotStarted);

        return [this.meetingAgendaItem, ...otherStartedItems];
    }

    public endItem() {
        this.meetingAgendaItem.status = MeetingAgendaItemStatus.Ended;
    }
}

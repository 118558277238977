import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "adaptTime",
})
export class TimePipe extends DatePipe implements PipeTransform {
    public transform(value: any): any {
        return super.transform(value, "hh:mm a");
    }
}

<div *adaptIfAlto="true"
     class="d-flex pt-5 w-100 flex-column align-items-center h-100 justify-content-center">
    <ng-container *ngTemplateOutlet="pageContent"></ng-container>
</div>
<adapt-page-with-splash-image *adaptIfAlto="false">
    <ng-container *ngTemplateOutlet="pageContent"></ng-container>
</adapt-page-with-splash-image>

<ng-template #pageContent>
    <a href="https://theadaptway.com"
       aria-label="Go to adapt website">
        <img src="/content/shell/images/adaptplatformlogo.svg"
             class="mb-4"
             alt="adapt logo"
             width="140" />
    </a>

    <div *ngIf="providerStatus"
         class="d-flex flex-column gap-3">
        <h1 class="m-0">Finish logging in with {{providerName ?? "an external account"}}</h1>

        <div>
            We could not find {{projectLabel}} credentials that match
            <ng-container *ngIf="!!providerStatus?.name; else genericAccount">the following {{providerName ?? "external"}} account:</ng-container>
            <ng-template #genericAccount>your {{providerName ?? "external"}} account.</ng-template>
        </div>

        <div *ngIf="!!providerStatus?.name"
             class="d-flex flex-row align-items-center gap-2">
            <img *ngIf="provider"
                 [src]="provider.iconPath"
                 [alt]="provider.providerDisplayName"
                 style="height: 32px; width: 32px;" />

            <div class="d-flex flex-column">
                <strong>{{providerStatus.name}}</strong>
                <small *ngIf="providerStatus.email"
                       class="text-muted">{{providerStatus.email}}</small>
            </div>
        </div>

        <div>If you are a new user, please click the link in the welcome email in your inbox to finish setting up your account.</div>

        <div>
            If you already have an {{projectLabel}} account, make sure your account email is the same as your {{providerName ?? "external"}} email.
            You can also link your existing {{projectLabel}} by following the instructions in the article linked below.
        </div>

        <div>
            <a [href]="ssoArticleLink"
               target="_blank"
               rel="noopener"><i class="fal fa-external-link-alt me-1"></i>Click here</a> to learn more about single sign-on (SSO).
        </div>

        <button [adaptBlockingClick]="cancel"
                [unsubscribeOnDestroy]="false"
                type="button"
                class="btn btn-secondary w-100">Return to login</button>
    </div>
</ng-template>

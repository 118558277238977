import { Inject, Injectable } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { Logger } from "@common/lib/logger/logger";
import { FunctionUtilities } from "@common/lib/utilities/function-utilities";
import { BehaviorSubject, combineLatest, from, Observable, ReplaySubject, Subject } from "rxjs";
import { debounceTime, mergeMap, startWith, tap } from "rxjs/operators";
import { IdentityStorageService } from "../identity/identity-storage.service";
import { DYNAMIC_NODE_BUILDERS, IDynamicNodeBuilder } from "./dynamic-node-builder";
import { INavigationHierarchy, NAVIGATION_HIERARCHIES } from "./navigation-hierarchy";
import { NavigationNode } from "./navigation-node";
import { INavigationNode } from "./navigation-node.interface";
import { NavigationNodeBuilder } from "./navigation-node-builder";
import { NavigationUtilitiesService } from "./navigation-utilities.service";
import { RouteService } from "./route.service";
import { IRouteState, RouteEventsService } from "./route-events.service";

interface INodeLookup {
    [key: string]: INavigationNode | undefined;
}

@Injectable({
    providedIn: "root",
})
export class NavigationHierarchyService {
    public static readonly Id = "adapt.route.navigationhierarchy.service";

    private lookupBy = {
        url: {} as INodeLookup,
        hierarchy: {} as INodeLookup,
        $id: {} as INodeLookup,
    };

    private log = Logger.getLogger("NavigationHierarchyService");
    private activeNode?: INavigationNode;
    private _activeNode$ = new ReplaySubject<INavigationNode>(1);
    private _newDynamicNode$ = new Subject<INavigationNode>();
    private _hierarchyChange$ = new Subject<{ id: string, node: INavigationNode | undefined }>();
    private controllerToNodeTemplateLookup: { [controllerId: string]: INavigationNode } = {};
    private root = new NavigationNodeBuilder()
        .setId("root")
        .setCustomKeyValue("isHiddenInBreadcrumbs", true)
        .build();

    private hierarchySubjects: { [hierarchyId: string]: BehaviorSubject<INavigationNode | undefined> } = {};
    private hierarchiesInitialised = false;

    public constructor(
        private identityService: IdentityStorageService,
        private routeService: RouteService,
        private routeEventsService: RouteEventsService,
        @Inject(DYNAMIC_NODE_BUILDERS) nodeTemplateBuilders: IDynamicNodeBuilder[],
        @Inject(NAVIGATION_HIERARCHIES) navigationHierarchies: INavigationHierarchy[],
        navigationUtilitiesService: NavigationUtilitiesService,
    ) {
        navigationUtilitiesService.setNavHierarchyService(this);

        const hierarchies$: Observable<INavigationNode | undefined>[] = [];
        for (const hierarchy of navigationHierarchies) {
            this.hierarchySubjects[hierarchy.id] = new BehaviorSubject(undefined);
            const hierarchy$ = hierarchy.hierarchyNode$.pipe(
                startWith(undefined),
                tap((node) => {
                    if (node) {
                        this.registerHierarchyWithNode(hierarchy.id, node);
                    } else {
                        this.deregisterHierarchy(hierarchy.id);
                    }

                    this.hierarchySubjects[hierarchy.id].next(node);
                    this._hierarchyChange$.next({ id: hierarchy.id, node });
                }),
            );

            hierarchies$.push(hierarchy$);
        }

        // Need to force a subscription here so the nodes all get registered in the tap above
        // Also had issues with the BehaviorSubject not emitting on subscription if this was
        // written as hierarchy$.subscribe(this.hierarchySubjects[hierarchy.id]);
        // Maybe a bug in rxjs? Most noticable in Nimbus on startup where the sidebar wouldn't
        // ever finish loading
        combineLatest(hierarchies$).subscribe(() => this.hierarchiesInitialised = true);

        from(nodeTemplateBuilders).pipe(
            tap((n) => {
                const templates = n.buildDynamicNodes();
                templates.forEach(this.registerDynamicNodeTemplate);
            }),
            mergeMap((t) => t.activeNodeShouldBeRebuilt$),
            // Prevent double building in quick succession
            debounceTime(50),
        ).subscribe(() => {
            this.updateActiveNodeFromUrl();
        });

        this.initialise();
    }

    public get activeNode$() {
        return this._activeNode$.asObservable();
    }

    public get newDynamicNode$() {
        return this._newDynamicNode$.asObservable();
    }

    public get hierarchyChange$() {
        return this._hierarchyChange$.asObservable();
    }

    private initialise() {
        const self = this;

        // this is only emitted once on start up - so don't need to swallow and resubscribe like $locationChangeSuccess
        this.routeEventsService.navigationEnd$.subscribe((e) => {
            const newPath = e.newUrl ? this.routeService.stripQueryParams(e.newUrl) : undefined;
            const oldPath = e.oldUrl ? this.routeService.stripQueryParams(e.oldUrl) : undefined;
            if (newPath === oldPath) {
                // active node is not going to change if query params changed -> do nothing
                return;
            }

            if (!this.allHierarchiesBuilt() || (stateHasSameActiveNode(e.newState) && e.newUrl === e.oldUrl) || !this.identityService.isLoggedIn) {
                this.processActiveNode(NavigationNode.Blank);
                return;
            }

            let newActiveNode;
            const newNode$id = e.newState?.node$id;
            if (newNode$id) {
                newActiveNode = self.lookupBy.$id[newNode$id];
            }

            if (newActiveNode) {
                this.processActiveNode(newActiveNode);
            } else {
                this.updateActiveNodeFromUrl();
            }
        });

        function stateHasSameActiveNode(state?: IRouteState) {
            return state && self.activeNode
                && state.node$id === self.activeNode.$id;
        }
    }

    /**
     * Registers a hierarchy with the given node under the root (or sitemap)
     * @param hierarchyName The hierarchy to register
     * @param node The node associated with this hierarchy
     */
    private registerHierarchyWithNode(hierarchyName: string, node: INavigationNode) {
        this.deregisterHierarchy(hierarchyName);

        node.customData.urlPriority = 0;

        this.lookupBy.hierarchy[hierarchyName] = node;
        this.root.addChild(node);

        this.registerNodeAndChildren(node);
        this.updateActiveNodeFromUrl();
    }

    private deregisterHierarchy(hierarchyName: string) {
        const hierarchyNode = this.lookupBy.hierarchy[hierarchyName];

        if (hierarchyNode) {
            this.root.removeChild(hierarchyNode);
            this.deregisterNodeAndChildren(hierarchyNode);
        }

        delete this.lookupBy.hierarchy[hierarchyName];
    }

    @Autobind
    public registerDynamicNodeTemplate(node: INavigationNode) {
        if (!node.controller) {
            throw new Error("A dynamic node MUST have a controller");
        }

        this.setKeyValueOnLookup(node.controller, node, this.controllerToNodeTemplateLookup);
    }

    @Autobind
    public deregisterDynamicNodeTemplate(node: INavigationNode) {
        if (!node.controller) {
            throw new Error("A dynamic node MUST have a controller");
        }

        this.deleteKeyOnLookup(node.controller, this.controllerToNodeTemplateLookup);
    }

    public promiseToBuildDynamicParentChainFromControllerAndParams(controllerId: string, namedParams: any) {
        const nodeTemplate = this.controllerToNodeTemplateLookup[controllerId];
        if (!nodeTemplate) {
            throw new Error("This controller has not been registered as a dynamic node");
        }

        return this.promiseToBuildDynamicParentChainFromNodeAndParams(nodeTemplate, namedParams);
    }

    /**
     * Given an existing node, builds its dynamic version, using the functions defined in the
     * family of setXXXDynamicValueCallback functions in the node builder, then proceeds to build
     * an ancestory chain for that particular node using the same params.
     * @param template The template to build the dynamic one off and to follow the parent chain
     * @param namedParams named parameters in the route
     * @returns The dynamic node with its parent relationships filled.
     */
    @Autobind
    public promiseToBuildDynamicParentChainFromNodeAndParams(template: INavigationNode, namedParams: any) {
        const self = this;
        return promiseToBuildParentChainFromTemplate(template);

        async function promiseToBuildParentChainFromTemplate(nodeTemplate: INavigationNode) {
            if (FunctionUtilities.isFunction(nodeTemplate.dynamicNodeCallback)) {
                return nodeTemplate.dynamicNodeCallback!(nodeTemplate, namedParams);
            } else {
                // Use factory reference here so that this call can be mocked in tests
                const dynamicNode = await self.promiseToBuildDynamicNodeFromParams(nodeTemplate, namedParams);
                const parent = await promiseToBuildDynamicNodeParent();
                if (parent) {
                    dynamicNode.setParent(parent);
                }

                return dynamicNode;
            }

            async function promiseToBuildDynamicNodeParent() {
                const parent = nodeTemplate.parent;

                if (!parent) {
                    return;
                }

                return await promiseToBuildParentChainFromTemplate(parent);
            }
        }
    }

    /**
     * Builds a dynamic version of a node using the functions defined in the
     * family of setXXXDynamicValueCallback functions in the node builder. If the URL
     * does not have a dynamic function, it will be automatically built using the routeProvider.
     * @param nodeTemplate The node to base the dynamic node off.
     * @param namedParams named parameters in the route
     * @returns The dynamic node, with no relationships filled in.
     */
    public promiseToBuildDynamicNodeFromParams(nodeTemplate: INavigationNode, namedParams: any) {
        if (!FunctionUtilities.isFunction(nodeTemplate.dynamicCallback.url)) {
            nodeTemplate.dynamicCallback.url = this.promiseToGetUrl;
        }

        return nodeTemplate.promiseToBuildDynamicNode(namedParams);
    }

    @Autobind
    public promiseToGetUrl(node: INavigationNode, namedParams: any) {
        if (!node.controller) {
            return Promise.resolve(node.url);
        }

        return this.routeService.getControllerRoute(node.controller, namedParams);
    }

    /**
     * Get the node which represents the current page. The
     * activeNode$ observable will emit when this changes.
     * @returns {Node} The node for the current page
     */
    @Autobind
    public getActiveNode() {
        return this.activeNode;
    }

    /**
     * Returns the name of hierarchy the current page belongs to,
     * or undefined if it doesn't belong to any hierarchies.
     * @returns The name of the current hierarchy.
     */
    @Autobind
    public getActiveHierarchy() {
        let currentNode = this.activeNode;

        if (!currentNode) {
            return undefined;
        }

        let currentNodeParent = currentNode.parent;
        const rootNode = this.root;

        while (currentNodeParent && currentNodeParent !== rootNode) {
            currentNode = currentNode!.parent;
            currentNodeParent = currentNode!.parent;
        }

        const hierarchyNames = Object.keys(this.lookupBy.hierarchy);

        for (const hierarchyName of hierarchyNames) {
            if (this.lookupBy.hierarchy[hierarchyName] === currentNode) {
                return hierarchyName;
            }
        }

        return undefined;
    }

    public getNodeByUrl(url: string) {
        return this.lookupBy.url[url];
    }

    public getNodeByHierarchy(name: string) {
        return this.lookupBy.hierarchy[name];
    }

    public getNodeTemplateForControllerId(controllerId: string) {
        return this.controllerToNodeTemplateLookup[controllerId];
    }

    @Autobind
    public registerNodeAndChildren(node: INavigationNode) {
        this.registerNode(node);
        node.children.forEach(this.registerNodeAndChildren);
    }

    private registerNode(node: INavigationNode) {
        if (typeof node.customData.urlPriority !== "number") {
            node.customData.urlPriority = 100;
        }

        const existingUrlNode = node.url && this.lookupBy.url[node.url];

        // Smaller numbers indicate higher priority
        if (!existingUrlNode
            || node.customData.urlPriority < (existingUrlNode.customData.urlPriority ?? 100)) {
            this.setKeyValueOnLookup(node.url, node, this.lookupBy.url);
        }

        this.setKeyValueOnLookup(node.$id, node, this.lookupBy.$id);
    }

    private setKeyValueOnLookup(key: number | string | undefined, value: INavigationNode, lookup: any) {
        if (key) {
            lookup[key] = value;
        }
    }

    @Autobind
    public deregisterNodeAndChildren(node: INavigationNode) {
        this.deregisterNode(node);
        node.children.forEach(this.deregisterNodeAndChildren);
    }

    private deregisterNode(node: INavigationNode) {
        this.deleteKeyOnLookup(node.url, this.lookupBy.url);
        this.deleteKeyOnLookup(node.$id, this.lookupBy.$id);

        if (node === this.activeNode) {
            this.processActiveNode(NavigationNode.Blank);
        }
    }

    private deleteKeyOnLookup(key: number | string | undefined, lookup: any) {
        if (key) {
            delete lookup[key];
        }
    }

    /** Returns an observable which will emit with the current node for the specified
     * hierarchy and each time it changes
     */
    public hierarchyChanged(hierarchyId: string): Observable<INavigationNode | undefined> {
        return this.hierarchySubjects[hierarchyId];
    }

    public updateActiveNodeFromUrl() {
        if (!this.allHierarchiesBuilt()) {
            return;
        }

        // search by URL first just in case we have node defined for certain searchParam of a path
        const url = this.routeService.currentUrl;
        let newActiveNode = this.getNodeByUrl(url!);
        if (!newActiveNode) { // then only fallback to whatever it was, search by path without searchParam
            const path = this.routeService.stripQueryParams(url);
            newActiveNode = this.getNodeByUrl(path);
        }

        if (newActiveNode) {
            this.processActiveNode(newActiveNode);
        } else {
            this.promiseToGenerateActiveNode()
                .then(this.processActiveNode)
                .then((dynamicNode) => this._newDynamicNode$.next(dynamicNode!));
        }
    }

    public updateActiveNodeFromUrlIfRouteParamMatchesValue(paramKey: string, paramValue: any) {
        const routeParamValue = this.routeService.currentActivatedRoute?.snapshot?.paramMap?.get(paramKey);
        if (routeParamValue && String(routeParamValue) === String(paramValue)) {
            this.updateActiveNodeFromUrl();
        }
    }

    private allHierarchiesBuilt() {
        return this.hierarchiesInitialised && Object.keys(this.lookupBy.hierarchy)
            .every(this.hierarchyIsBuilt);
    }

    @Autobind
    private hierarchyIsBuilt(hierarchyName: any) {
        return !!this.getNodeByHierarchy(hierarchyName);
    }

    private promiseToGenerateActiveNode(): PromiseLike<INavigationNode | undefined> {
        const currentController = this.routeService.currentControllerId;
        if (!currentController) {
            // for routes that arent attributable to a controller, lets just not do anything, and return straight away
            return Promise.resolve(undefined);
        }

        const currentParams = this.routeService.currentActivatedRoute?.snapshot.params;
        const nodeTemplate = this.controllerToNodeTemplateLookup[currentController];

        if (nodeTemplate) {
            return this.promiseToBuildDynamicParentChainFromNodeAndParams(nodeTemplate, currentParams);
        } else {
            this.log.info(currentController + " has not been registered in the Nav Hierarchy. Register it to get a more accurate breadcrumb.");

            // It would be preferable to use navUtilities.promiseToBuildNodeForControllerAndParams,
            // but to avoid circular dependencies we have to build this node by hand
            const controllerConfig = this.routeService.getControllerRouteConfig(currentController);
            if (controllerConfig) {
                return new NavigationNodeBuilder()
                    .setTitle(controllerConfig.data!.title)
                    .setController(currentController)
                    .promiseToSetUrl(this.routeService.getControllerRoute(currentController, currentParams))
                    .promiseToBuild();
            }

            throw new Error("Unable to find config for controller " + currentController);
        }
    }

    @Autobind
    public processActiveNode(newActiveNode: INavigationNode) {
        const oldActiveNode = this.activeNode;

        if (newActiveNode === oldActiveNode) {
            return;
        }

        this.activeNode = newActiveNode;
        this._activeNode$.next(newActiveNode);

        return newActiveNode;
    }
}

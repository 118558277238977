import { Component, Input } from "@angular/core";
import { Purpose } from "@common/ADAPT.Common.Model/organisation/purpose";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { CommonPurposeVisionService } from "@org-common/lib/purpose-vision/common-purpose-vision.service";
import { CommonPurposeVisionAuthService } from "@org-common/lib/purpose-vision/common-purpose-vision-auth.service";
import { BehaviorSubject, Observable } from "rxjs";
import { map, startWith, switchMap } from "rxjs/operators";
import { GuidingPhilosophyUiService } from "../guiding-philosophy-ui.service";

@Component({
    selector: "adapt-team-guidance-dashboard-element",
    templateUrl: "./team-guidance-dashboard-element.component.html",
})
export class TeamGuidanceDashboardElementComponent extends BaseComponent {
    public readonly EditPurposeVision = CommonPurposeVisionAuthService.EditPurposeVision;

    @Input()
    public set team(value: Team | undefined) {
        this.team$.next(value);
    }

    @Input() public dashboardTitle = "Guidance";

    public team$ = new BehaviorSubject<Team | undefined>(undefined);
    public content$: Observable<string | undefined>;
    public canEditTeamGuidance$: Observable<boolean>;

    public constructor(
        purposeVisionService: CommonPurposeVisionService,
        rxjsBreezeService: RxjsBreezeService,
        authorisationService: AuthorisationService,
        private guidingPhilosophyUiService: GuidingPhilosophyUiService,
    ) {
        super();

        this.content$ = this.team$.pipe(
            emptyIfUndefinedOrNull(),
            switchMap((team) => rxjsBreezeService.entityTypeChangedInSave(Purpose).pipe(
                startWith(undefined),
                map(() => team),
            )),
            switchMap((team) => purposeVisionService.getPurpose(team)),
            map((purpose) => purpose?.content),
            this.takeUntilDestroyed(),
        );

        this.canEditTeamGuidance$ = this.team$.pipe(
            emptyIfUndefinedOrNull(),
            switchMap((team) => authorisationService.promiseToGetHasAccess(CommonPurposeVisionAuthService.EditPurposeVision, team)),
        );
    }

    public editTeamGuidance() {
        return this.team$.pipe(
            switchMap((team) => this.guidingPhilosophyUiService.editTeamPurpose(team!)),
        ).subscribe();
    }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AdaptSignalRModule } from "@common/lib/signalr-provider/signalr.module";
import { SignalRService } from "@common/lib/signalr-provider/signalr.service";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDirectorySharedModule } from "../directory-shared/directory-shared.module";
import { ConflictingChangesDialogComponent } from "./conflicting-changes-dialog/conflicting-changes-dialog.component";
import { EntitySignalRHub } from "./entity-signalr-hub.service";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AdaptCommonDialogModule,
        AdaptDirectorySharedModule,
        AdaptButtonModule,
        AdaptSignalRModule,
    ],
    declarations: [
        ConflictingChangesDialogComponent,
    ],
})
export class AdaptEntitySyncModule {
    constructor() {
        SignalRService.registerHubImplementation(EntitySignalRHub.Name, EntitySignalRHub);
    }
}

<adapt-dialog [maxWidth]="640"
              [minWidth]="480"
              [maxHeight]="400">
    <h3 adapt-dialog-title>Participants for survey: {{survey.name}}</h3>

    <div adapt-dialog-content
         class="h-100">
        <div *adaptLoadingUntilFirstEmit="let suppData of surveySuppData$"
             class="row h-100">
            <div class="col">
                <h3>Participants</h3>
                <div *ngIf="suppData?.respondents; else noParticipants"
                     class="scrollable-textarea">
                    <pre>{{suppData!.respondents}}</pre>
                </div>
                <ng-template #noParticipants>
                    <p>No participants were recorded for this survey.</p>
                </ng-template>
            </div>
            <div class="col">
                <h3>Non-participants</h3>
                <div *ngIf="suppData?.nonrespondents; else noNonParticipants"
                     class="scrollable-textarea">
                    <pre>{{suppData!.nonrespondents}}</pre>
                </div>
                <ng-template #noNonParticipants>
                    <p>No non-participants were recorded for this survey.</p>
                </ng-template>
            </div>
        </div>
    </div>

    <div adapt-dialog-footer
         class="ms-auto">
        <button #focus
                adaptButton="close"
                (click)="cancel()"></button>
    </div>
</adapt-dialog>

import { Component, Inject } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { lastValueFrom } from "rxjs";
import { ADAPT_DIALOG_DATA } from "../adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "../base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";

@Component({
    templateUrl: "./handle-failed-save-dialog.component.html",
})
export class HandleFailedSaveDialogComponent extends BaseDialogWithDiscardConfirmationComponent<IBreezeEntity[]> {
    public readonly dialogName = "HandleFailedSave";
    public entitiesToConfirm = this.entities;

    public latestFailure?: string;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) private entities: IBreezeEntity[],
    ) {
        super();
    }

    @Autobind
    public async saveAndClose() {
        try {
            this.latestFailure = undefined;
            await lastValueFrom(this.commonDataService.saveEntities(this.entities));
            this.resolve(this.entities);
        } catch (e) {
            this.latestFailure = ErrorHandlingUtilities.getHttpResponseMessage(e);
        }
    }
}

<div class="swatch">
    <ng-content></ng-content>
    <div class="colour"
         [style.background-color]="value">
        <p [style.color]="textColorValue">
            {{rgb}}<br />
            {{hex}}<br />
            <ng-content select="[swatch-inner-content]"></ng-content>
        </p>
        <div *ngIf="colorVariants as colorVariantsList"
             class="sub-colour">
            <div *ngFor="let variant of colorVariantsList"
                 class="colour"
                 style="background-color: {{variant.value}}">
                <div class="percentage">{{variant.name}}<span *ngIf="isPercentage">%</span>
                </div>
            </div>
        </div>
    </div>
    <div class="variable">
        <ng-container *ngIf="hasSassVariable">
            {{getSassVariableDisplay(variable)}}<br />
        </ng-container>
        {{getCssVariableDisplay(variable)}}
        <span *ngIf="colorVariants as colorVariantsList"
              class="small">
            <ng-container *ngFor="let variant of colorVariantsList">
                <ng-container *ngIf="hasSassVariable">
                    <br />{{getSassVariableDisplay(variable + "-" + variant.name)}}
                </ng-container>
                <br />{{getCssVariableDisplay(variable + "-" + variant.name)}}
            </ng-container>
        </span>
    </div>
</div>

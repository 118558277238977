import { Component } from "@angular/core";
import { Value } from "@common/ADAPT.Common.Model/organisation/value";
import { map, Observable } from "rxjs";
import { ValuesConstitutionService } from "../values-constitution.service";
import { valuesConstitutionRoute } from "../values-constitution-page/values-constitution-page.route";

@Component({
    selector: "adapt-values-constitution-wide-view",
    templateUrl: "./values-constitution-wide-view.component.html",
    styleUrls: ["./values-constitution-wide-view.component.scss"],
})
export class ValuesConstitutionWideViewComponent {
    public vcUrl$: Observable<string>;
    public values$: Observable<Value[]>;

    public constructor(
        vcService: ValuesConstitutionService,
    ) {
        this.vcUrl$ = valuesConstitutionRoute.getRoute();

        this.values$ = vcService.getValuesConstitution().pipe(
            map((vc) => vc ? vc.values : []),
        );
    }
}

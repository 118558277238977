<ng-container [ngSwitch]="hasProgress">
    <adapt-progress-bar *ngSwitchCase="true"
                        [percentageProgress]="keyResult.currentProgress"
                        [overrideText]="progressText"
                        [progressTooltip]="progressChangeTooltip"
                        colour="#6c757d"
                        backgroundColour="#e9ecef"></adapt-progress-bar>
    <!-- colour and backgroundColour are $gray-600 and $gray-200 from _adapt-common-variables.scss -->
    <div *ngSwitchDefault
         class="progress no-key-result-progress">
        This key result is yet to be scored.
    </div>
</ng-container>

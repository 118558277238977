import { Component } from "@angular/core";
import { OAuthService } from "@org-common/lib/oauth/oauth.service";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";

@WorkflowStepComponent("adapt-calendar-integration-step")
@Component({
    selector: "adapt-calendar-integration-step",
    templateUrl: "./calendar-integration-step.component.html",
})
export class CalendarIntegrationStepComponent extends WorkflowStepComponentAdapter {
    public isAuthenticated = false;

    public constructor(oauthService: OAuthService) {
        super();

        oauthService.isAuthenticated$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((authed) => this.isAuthenticated = authed);
    }

    public workflowStepNextText() {
        return this.isAuthenticated
            ? "Next"
            : "Skip";
    }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptIconModule } from "@common/ux/icon/icon.module";
import { AdaptLayoutModule } from "@common/ux/layout/layout.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptMenuModule } from "@common/ux/menu/menu.module";
import { AdaptProgressBarModule } from "@common/ux/progress-bar/progress-bar.module";
import { AdaptSteppedProgressBarModule } from "@common/ux/stepped-progress-bar/stepped-progress-bar.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptCommonWorkflowModule } from "@common/workflow/workflow.module";
import { AdaptKeyFunctionsCommonModule } from "@org-common/lib/architecture/key-functions/key-functions-common.module";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { AdaptEulaModule } from "@org-common/lib/eula/eula.module";
import { AdaptKanbanModule } from "@org-common/lib/kanban/kanban.module";
import { AdaptKanbanSharedModule } from "@org-common/lib/kanban/kanban-shared.module";
import { AdaptLabellingModule } from "@org-common/lib/labelling/labeling.module";
import { AdaptCommonMeetingsModule } from "@org-common/lib/meetings/common-meetings.module";
import { AdaptDisplayTreeObjectiveModule } from "@org-common/lib/objectives/display-tree-objective.component/display-tree-objective.module";
import { AdaptObjectivesSharedModule } from "@org-common/lib/objectives/objectives-shared.module";
import { AdaptSurveyModule } from "@org-common/lib/survey/survey.module";
import { AdaptTeamDashboardSharedModule } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.module";
import { AdaptZoneMapModule } from "@org-common/lib/zone-map/zone-map.module";
import { DxDataGridModule, DxListModule, DxSelectBoxModule, DxTabPanelModule, DxTagBoxModule, DxTextBoxModule } from "devextreme-angular";
import { AdaptCallToActionModule } from "../call-to-action/call-to-action.module";
import { DebugPageComponent } from "./debug-page/debug-page.component";
import { debugPageRoute } from "./debug-page/debug-page.route";
import { PrivacyTermConditionsPageRoute, PrivacyTermsConditionsPageComponent } from "./privacy-terms-conditions-page/privacy-terms-conditions-page.component";
import { ColourSwatchComponent } from "./theme-test/colour-swatch/colour-swatch.component";
import { CommonComponentsPageComponent } from "./theme-test/common-components-page/common-components-page.component";
import { commonComponentsPageRoute } from "./theme-test/common-components-page/common-components-page.route";
import { CommonFontsAndColorsPageComponent } from "./theme-test/common-fonts-and-colors-page/common-fonts-and-colors-page.component";
import { commonFontsAndColorsPageRoute } from "./theme-test/common-fonts-and-colors-page/common-fonts-and-colors-page.route";
import { OrgCommonComponentsPageComponent } from "./theme-test/org-common-components-page/org-common-components-page.component";
import { orgCommonComponentsPageRoute } from "./theme-test/org-common-components-page/org-common-components-page.route";
import { OrgCommonFontsAndColorsPageComponent } from "./theme-test/org-common-fonts-and-colors-page/org-common-fonts-and-colors-page.component";
import { orgCommonFontsAndColorsPageRoute } from "./theme-test/org-common-fonts-and-colors-page/org-common-fonts-and-colors-page.route";
import { ThemeTestContainerComponent } from "./theme-test/theme-test-container/theme-test-container.component";
import { provideThemeTestPage } from "./theme-test/theme-test-page";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        DxDataGridModule,
        DxTabPanelModule,
        DxTextBoxModule,
        DxSelectBoxModule,
        DxListModule,
        DxTagBoxModule,
        AdaptLoadingSpinnerModule,
        AdaptButtonModule,
        AdaptIconModule,
        AdaptTooltipModule,
        AdaptLayoutModule,
        AdaptMenuModule,
        AdaptCommonWorkflowModule,
        AdaptDashboardElementModule,
        AdaptTooltipModule,
        AdaptZoneMapModule,
        AdaptSurveyModule,
        AdaptDisplayTreeObjectiveModule,
        AdaptKeyFunctionsCommonModule,
        AdaptDirectorySharedModule,
        AdaptTeamDashboardSharedModule,
        AdaptKanbanModule,
        AdaptKanbanSharedModule,
        AdaptObjectivesSharedModule,
        AdaptLabellingModule,
        AdaptCommonMeetingsModule,
        AdaptSteppedProgressBarModule,
        AdaptProgressBarModule,
        AdaptEulaModule,
        AdaptCallToActionModule,
    ],
    declarations: [
        DebugPageComponent,
        PrivacyTermsConditionsPageComponent,
        CommonComponentsPageComponent,
        ThemeTestContainerComponent,
        CommonFontsAndColorsPageComponent,
        ColourSwatchComponent,
        OrgCommonComponentsPageComponent,
        OrgCommonFontsAndColorsPageComponent,
    ],
    exports: [
        ThemeTestContainerComponent,
        ColourSwatchComponent,
    ],
    providers: [
        provideThemeTestPage({
            route: commonComponentsPageRoute,
            title: "2a. Common Components",
            sections: [
                { id: "text", title: "Text" },
                { id: "buttons", title: "Buttons" },
                { id: "alerts", title: "Alert Banners" },
                { id: "cards", title: "Cards" },
                { id: "devexpress", title: "DevExpress" },
                { id: "dashboardelement", title: "Dashboard Elements" },
                { id: "menus", title: "Menus" },
            ],
        }),
        provideThemeTestPage({
            route: commonFontsAndColorsPageRoute,
            title: "1a. Common Fonts and Colors",
            sections: [
                { id: "fonts", title: "Fonts" },
                { id: "colors", title: "Colors" },
            ],
        }),
        provideThemeTestPage({
            route: orgCommonComponentsPageRoute,
            title: "2b. Org Common Components",
            sections: [
                { id: "zones", title: "Zone Map" },
                { id: "surveys", title: "Surveys" },
                { id: "meetings", title: "Meetings" },
                { id: "key-functions", title: "Key Functions" },
                { id: "links", title: "Links" },
                { id: "objectives", title: "Objectives" },
                { id: "kanban", title: "Kanban" },
                { id: "labels", title: "Labels" },
            ],
        }),
        provideThemeTestPage({ route: orgCommonFontsAndColorsPageRoute, title: "1b. Org Common Fonts and Colors" }),
    ],
})
export class AdaptOrgCommonPagesModule {
    public static readonly Routes = [
        ...debugPageRoute.routes,
        ...PrivacyTermConditionsPageRoute.routes,
        ...commonComponentsPageRoute.routes,
        ...commonFontsAndColorsPageRoute.routes,
        ...orgCommonComponentsPageRoute.routes,
        ...orgCommonFontsAndColorsPageRoute.routes,
    ];
}

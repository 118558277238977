<!-- Ordinal of 99 to make sure we are always the furthest to the right -->
<button adaptToolbarContent
        alignment="right"
        [ordinal]="99"
        id="filterButton"
        class="btn-filter"
        adaptButton="filter"
        [ngClass]="{active: isOpen, filterchange: !isDefault }"
        (click)="isOpen = !isOpen"
        data-test="filter-button"></button>

<dx-popup class="dx-swatch-dark"
          [shading]="false"
          [width]="300"
          height="auto"
          maxHeight="70vh"
          [showTitle]="false"
          [dragEnabled]="false"
          [hideOnOutsideClick]="true"
          [showCloseButton]="false"
          (onHidden)="onHidden()">
    <dxo-position at="right bottom"
                  my="right top"
                  of="#filterButton">
        <dxo-offset [x]="-5"
                    [y]="10"></dxo-offset>
    </dxo-position>
    <div class="filter"
         data-test="filter-popover"
         *dxTemplate="let data of 'content'">
        <div class="filter-header align-items-center"
             *ngIf="resetEnabled || breakpoint.isMobileSize">
            <h3 class="filter-title">Filter</h3>
            <div class="filter-header-buttons">
                <button *ngIf="resetEnabled"
                        (click)="onReset()"
                        adaptButton="resetBorderless"
                        class="ms-auto"
                        data-test="filter-reset-button"></button>
            </div>
        </div>
        <div class="filter-body">
            <dx-scroll-view direction="vertical"
                            showScrollbar="always">
                <ng-content></ng-content>
            </dx-scroll-view>
        </div>
    </div>
</dx-popup>

import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { PersonProfileCategory } from "./person-profile-category";
import { PersonProfileItemType } from "./person-profile-item-type";
import { PersonProfileItemValue } from "./person-profile-item-value";

export class PersonProfileItem extends BaseEntity<PersonProfileItem> {
    public personProfileItemId!: number;
    public personProfileCategoryId!: number;
    public itemType!: PersonProfileItemType;
    public label!: string;
    public ordinal!: number;
    public userEditable!: boolean;
    public isPrivate!: boolean;
    public personProfileItemValues!: PersonProfileItemValue[];
    public profileCategory!: PersonProfileCategory;

    public get isDate() {
        return this.itemType === PersonProfileItemType.Date;
    }

    public get isRichText() {
        return this.itemType === PersonProfileItemType.RichText;
    }

    public get isText() {
        return this.itemType === PersonProfileItemType.Text;
    }
}

export const PersonProfileItemBreezeModel = new BreezeModelBuilder("PersonProfileItem", PersonProfileItem)
    .withIdField()
    .withLabelField("label")
    .hasSource()
    .orderBy("ordinal")
    .isOrganisationEntity()
    .build();

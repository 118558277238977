import { DefaultBackFinishButtons, DefaultBackNextButtons, DefaultCancelProceedButtons, DefaultNextButtons, GuidedTour } from "@common/lib/guided-tour/guided-tour.service";

export const ObjectiveDialogTourIdentifier = "ObjectiveDialogTour";

export const ObjectiveDialogTour: GuidedTour = {
    id: ObjectiveDialogTourIdentifier,
    saveRunStatus: true,
    steps: [{
        title: "Adding an objective",
        text: `<p>This tour will guide you through the different elements of the objective dialog.</p>`,
        buttons: DefaultCancelProceedButtons,
    }, {
        title: "Objective title",
        text: `<p>Set the objective title here - a rallying call to the organisation or team to get behind.</p>`,
        waitForAndAttachToElementSelector: `[data-tour="objective-title-input"]`,
        canClickTarget: false,
        attachTo: {
            on: "bottom",
        },
        buttons: DefaultNextButtons,
    }, {
        title: "Objective type",
        text: `<p>Set the length of the objective - Annual or Quarterly.</p>`,
        waitForAndAttachToElementSelector: `[data-tour="objective-type"]`,
        canClickTarget: false,
        attachTo: {
            on: "bottom",
        },
        buttons: DefaultBackNextButtons,
    }, {
        title: "Assign an owner for this objective",
        text: `<p>Here, you can select the person who will be responsible for this objective.</p>`,
        waitForAndAttachToElementSelector: `[data-tour="objective-owner-dropdown"]`,
        canClickTarget: false,
        attachTo: {
            on: "left",
        },
        buttons: DefaultBackNextButtons,
    }, {
        title: "Describe the objective",
        text: `<p>Add enough information into the description that you (and everyone else) is clear about the 'why' of this objective.</p>`,
        waitForAndAttachToElementSelector: `[data-tour="objective-description"]`,
        canClickTarget: false,
        attachTo: {
            on: "left",
        },
        buttons: DefaultBackNextButtons,
    }, {
        title: "Supporting another objective?",
        text: `<p>Sometimes, an objective will support another one. Set this field if it makes sense for your objective.</p>`,
        waitForAndAttachToElementSelector: `[data-tour="objective-supporting"]`,
        canClickTarget: false,
        attachTo: {
            on: "left",
        },
        buttons: DefaultBackNextButtons,
    }, {
        title: "Due date",
        text: `<p>Set the date this objective is due.</p>`,
        waitForAndAttachToElementSelector: `[data-tour="objective-date"]`,
        canClickTarget: false,
        attachTo: {
            on: "left",
        },
        buttons: DefaultBackFinishButtons,
    }],
};

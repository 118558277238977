import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptFeatureModule } from "@common/feature/feature.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { DxCheckBoxModule } from "devextreme-angular";
import { EulaComponent } from "./eula/eula.component";
import { EulaAcceptancePageComponent } from "./eula-acceptance-page/eula-acceptance-page.component";
import { eulaAcceptancePageRoute } from "./eula-acceptance-page/eula-acceptance-page.route";
import { EulaDialogComponent } from "./eula-dialog/eula-dialog.component";
import { EulaPageComponent } from "./eula-page/eula-page.component";
import { eulaPageRoute } from "./eula-page/eula-page.route";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        DxCheckBoxModule,
        AdaptButtonModule,
        AdaptLoadingSpinnerModule,
        AdaptFeatureModule,
        AdaptCommonDialogModule,
    ],
    exports: [
        EulaComponent,
        EulaDialogComponent,
    ],
    declarations: [
        EulaAcceptancePageComponent,
        EulaPageComponent,
        EulaComponent,
        EulaDialogComponent,
    ],
})
export class AdaptEulaModule {
    public static readonly Routes = [
        ...eulaAcceptancePageRoute.routes,
        ...eulaPageRoute.routes,
    ];
}

<adapt-collapsible-search-row #row
                              [result]="result"
                              [matches]="matches"
                              [href$]="href$"
                              [crumbTemplate]="crumbs">
    <div class="d-flex flex-grow-1 align-items-center justify-content-between">
        <div class="d-flex flex-grow-0 align-items-center me-2 text-muted">
            <div class="d-flex align-items-center result-icon"
                 [adaptTooltip]="CAInputsViewOptions.text">
                <i [ngClass]="CAInputsViewOptions.iconClass"
                   class="fa-inverse"></i>
            </div>
        </div>

        <div class="flex-grow-1 text-break d-flex align-items-center">
            <adapt-highlighted-text [needle]="keyword"
                                    [haystack]="result.name"></adapt-highlighted-text>
        </div>
    </div>
</adapt-collapsible-search-row>

<ng-template #crumbs
             let-matched="match">
    <ng-container *ngIf="asMatch(matched) as match">
        <ng-container *ngFor="let crumb of match.field; first as first; last as last">
            <ng-container *ngIf="first && match.extra && InputTypeMetadata[match.extra.inputType] as inputType; else crumbText">
                <span><i [ngClass]="inputType.iconClass"></i> {{inputType.singularLabel}}</span>
            </ng-container>
            <ng-template #crumbText><span>{{crumb}}</span></ng-template>
            <span *ngIf="!last"
                  class="px-1">&gt;</span>
        </ng-container>
    </ng-container>
</ng-template>

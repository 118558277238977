import { Component, EventEmitter, Input, Output } from "@angular/core";
import { RoleInOrganisation, RoleInOrganisationLabel } from "@common/ADAPT.Common.Model/organisation/connection";

@Component({
    selector: "adapt-select-role-in-organisation",
    templateUrl: "./select-role-in-organisation.component.html",
})
export class SelectRoleInOrganisationComponent {
    public readonly RoleInOrganisationChoices = [
        RoleInOrganisation.OwnerLeader,
        RoleInOrganisation.Leader,
        RoleInOrganisation.Other,
    ].map((role) => ({
        text: RoleInOrganisationLabel[role],
        value: role,
    }));
    public readonly RoleInOrganisationTooltip = `
        <p><b>Owner & leader</b> - someone who has significant ownership or shareholding, in addition to being a senior leader of the business</p>
        <p><b>Leader</b> - someone who is a senior leader in the business but does not have a significant ownership or shareholding</p>
        <p><b>Other</b> - anyone else such as an non active owner, an employee or an external stakeholder of some sort</p>`;

    @Input() public value = RoleInOrganisation.OwnerLeader;
    @Output() public valueChange = new EventEmitter<RoleInOrganisation>();

    @Input() public disabled = false;
    @Input() public withLabel = false;
}

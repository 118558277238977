import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptRenderComponentModule } from "@common/ux/render-component/render-component.module";
import { DxCheckBoxModule, DxRadioGroupModule, DxTextAreaModule, DxTextBoxModule, DxValidatorModule } from "devextreme-angular";
import { DxiValidationRuleModule } from "devextreme-angular/ui/nested";
import { EditExternalDashboardDialogComponent } from "./edit-external-dashboard-dialog/edit-external-dashboard-dialog.component";
import { ExternalDashboardDashboardElementComponent } from "./external-dashboard-dashboard-element/external-dashboard-dashboard-element.component";
import { ExternalDashboardPageComponent } from "./external-dashboard-page/external-dashboard-page.component";
import { organisationExternalDashboardPageRoute, teamExternalDashboardPageRoute } from "./external-dashboard-page/external-dashboard-page.route";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AdaptButtonModule,
        AdaptCommonDialogModule,
        AdaptDashboardElementModule,
        AdaptEntityValidatorModule,
        DxRadioGroupModule,
        DxTextBoxModule,
        DxTextAreaModule,
        DxValidatorModule,
        DxiValidationRuleModule,
        DxCheckBoxModule,
        AdaptRenderComponentModule,
    ],
    declarations: [
        ExternalDashboardDashboardElementComponent,
        EditExternalDashboardDialogComponent,
        ExternalDashboardPageComponent,
    ],
    exports: [
        ExternalDashboardDashboardElementComponent,
    ],
})

export class AdaptExternalDashboardModule {
    public static readonly Routes = [
        ...organisationExternalDashboardPageRoute.routes,
        ...teamExternalDashboardPageRoute.routes,
    ];
}

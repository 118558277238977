<adapt-context-sidebar-content *ngIf="articleId"
                               [context]="context"
                               [fullScreen]="fullScreen">
    <h2 context-sidebar-title>{{articleName || "Guidance"}}</h2>

    <ng-container *ngIf="articles.length > 1; else singleArticle">
        <dx-tab-panel [items]="articles">
            <div *dxTemplate="let item of 'title'">
                <h4>{{item.name}}</h4>
            </div>
            <div *dxTemplate="let item of 'item'">
                <adapt-styled-implementation-kit [articleId]="item.articleId"
                                                 (articleName)="item.name = $event"
                                                 [contentStyle]="contentStyle"
                                                 [title]="title"></adapt-styled-implementation-kit>
            </div>
        </dx-tab-panel>
    </ng-container>

    <ng-template #singleArticle>
        <adapt-styled-implementation-kit [articleId]="firstArticle"
                                         (articleName)="articleNameUpdater.next($event)"
                                         [contentStyle]="contentStyle"
                                         [title]="title"
                                         [loadOnEmit]="loadOnEmit"></adapt-styled-implementation-kit>
    </ng-template>
</adapt-context-sidebar-content>

<adapt-dialog [maxWidth]="600">
    <h3 adapt-dialog-title>Move actions</h3>
    <div adapt-dialog-content>
        <p>You are about to move the following {{items.length}} actions to another board:</p>
        <ul>
            <li *ngFor="let item of items">
                <adapt-item-link [item]="item"
                                 [showDialogLink]="false"
                                 [showStatus]="true"
                                 [showSummary]="true"
                                 [useBoldItemCode]="true"></adapt-item-link>
            </li>
        </ul>

        <!-- board -->
        <label class="mb-2">Destination Board</label>
        <adapt-select-board id="inputBoard"
                            [boards]="firstBoard ? [firstBoard] : []"
                            (boardsChange)="onSelectedBoardChanged($event[0])"
                            [required]="true"
                            [allowMultiSelection]="false"
                            [showEditableBoardsOnly]="true"
                            data-test="move-item-board-dropdown"></adapt-select-board>

        <div *ngIf="board?.isPersonalBoard"
             class="alert alert-warning mt-3 mb-0">
            If you move these actions to a personal board, you will also be assigned to the actions.
        </div>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="!hasItemToMove"
                                   (saveClick)="resolve(board!)"
                                   (cancelClick)="cancel()"
                                   data-test="move-item-dialog-buttons">
    </adapt-standard-dialog-buttons>
</adapt-dialog>

import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ActiveEntityUtilities } from "@common/lib/data/active-entity-utilities";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Organisation } from "./organisation";
import { Purpose } from "./purpose";
import { ValueStreamBusinessModel } from "./value-stream-business-model";
import { ValueStreamProduct } from "./value-stream-product";
import { ValueStreamStakeholder } from "./value-stream-stakeholder";

export class ValueStream extends BaseEntity<ValueStream> {
    public valueStreamId!: number;
    public organisationId!: number;
    public name!: string;
    public ordinal!: number;
    public startDate!: Date;
    public endDate?: Date;
    public businessModels!: ValueStreamBusinessModel[];
    public organisation!: Organisation;
    public products!: ValueStreamProduct[];
    public stakeholders!: ValueStreamStakeholder[];
    public purposes!: Purpose[];

    // todo: move these methods and properties to an extension method interface when we remove all legacy code (when arch v1 is gone, and we have this area of the code fully typed)

    public readonly colourClass = "colour-ValueStream";
    public readonly iconClass = "icon-ValueStreams";

    @Autobind
    public isActive() {
        return ActiveEntityUtilities.isActive(this);
    }

    public get activeState() {
        return ActiveEntityUtilities.getActiveState(this);
    }

    public get purpose() {
        return ArrayUtilities.getSingleFromArray(this.purposes);
    }
}

export const ValueStreamBreezeModel = new BreezeModelBuilder("ValueStream", ValueStream)
    .hasSource()
    .isOrganisationEntity()
    .orderBy("ordinal")
    .withIdField("valueStreamId")
    .withSortField("ordinal")
    .withActivePath("self")
    .build();

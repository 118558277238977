import { Component } from "@angular/core";
import { InputGroup } from "@common/ADAPT.Common.Model/organisation/input-group";
import { InputGroupTypeMetadata } from "@common/ADAPT.Common.Model/organisation/input-type-metadata";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { StrategicInputsService } from "@org-common/lib/strategic-inputs/strategic-inputs.service";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import { BehaviorSubject, catchError, map, of } from "rxjs";

@WorkflowStepComponent("adapt-add-competitor-analysis-name-description")
@Component({
    selector: "adapt-add-competitor-analysis-name-description",
    templateUrl: "./add-competitor-analysis-name-description.component.html",
})
export class AddCompetitorAnalysisNameDescriptionComponent extends WorkflowStepComponentAdapter {
    public inputGroupTypeLabel?: string;
    public inputGroup?: InputGroup;
    public inputGroups: InputGroup[] = [];
    public workflowStepCompleted = new BehaviorSubject<boolean>(false);
    public isEditorValid = true;

    constructor(
        private commonDataService: CommonDataService,
        private strategicInputService: StrategicInputsService,
    ) {
        super();
    }

    public workflowStepOnInit() {
        this.inputGroup = this.workflowStep?.workflow.runData;
        if (this.inputGroup) {
            this.emitEntityChange(this.inputGroup);
            this.inputGroupTypeLabel = InputGroupTypeMetadata[this.inputGroup.type].singularLabel;
            this.strategicInputService.getCanvasById(this.inputGroup!.canvasId).pipe(
                map((c) => c?.inputGroups),
            ).subscribe((ig) => this.inputGroups = ig ?? []);
            this.updateCompletion();
        }
    }

    public updateCompletion() {
        this.inputGroup?.extensions.validateInputGroupName(this.inputGroups);
        this.workflowStepCompleted.next(!this.inputGroup?.entityAspect.hasValidationErrors && this.isEditorValid);
    }

    public workflowStepNext() {
        if (this.inputGroup && this.inputGroup.entityAspect.entityState.isAdded()) {
            return this.commonDataService.saveEntities(this.inputGroup).pipe(
                catchError((error) => {
                    const errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(error);
                    this.workflowStepErrorMessage.next(errorMessage);
                    throw error;
                }),
            );
        }
        return of(undefined);
    }
}

/* eslint-disable max-classes-per-file */
import { LocalStorage } from "@common/lib/storage/local-storage";
import { Trace } from "../lib/logger/trace";
import { QueuedCaller } from "../lib/queued-caller/queued-caller";
import { ObjectUtilities } from "../lib/utilities/object-utilities";

export enum AdaptEnvironment {
    Production,
    ProductionBeta,
    Staging,
    Devcloud,
    Local,
}

export enum AdaptProject {
    Unknown = "Unknown",
    Cumulus = "Cumulus",
    Nimbus = "Nimbus",
    Alto = "Alto",
}

export const AdaptProjectLabel: { [k in AdaptProject]: string } = {
    [AdaptProject.Unknown]: "Unknown",
    [AdaptProject.Cumulus]: "embedADAPT",
    [AdaptProject.Nimbus]: "runADAPT",
    [AdaptProject.Alto]: "adapt HQ",
};

export abstract class RawClientConfiguration {
    // Provide empty values so tests don't fall over
    public ApiBaseUri = "";
    public EmbedApiBaseUri = ""; // these 2 are used by nimbus only to switch between embed and alto
    public AltoApiBaseUri = "";
    public ServiceApiBaseUri = "";
    public EmbedBaseUri = "";
    public AltoBaseUri = "";
    public IntercomAppId = "";
    public TrustedVimeoDomain = "";
    public StripePublicKey = "";
    public DropboxAppKey = "";
    public OneDriveClientId = "";
    public BoxClientId = "";
    public GoogleApiKey = "";
    public GoogleClientId = "";
    public MicrosoftClientId = "";
    public DeepDiveDomain = "";
    public SentryDsn?: string;
    public AdaptEnvironmentName: keyof typeof AdaptEnvironment = "Local";
    public AdaptProjectName: keyof typeof AdaptProject = "Unknown";
    public FroalaKey = "";
    public TraceLevelName: keyof typeof Trace = "Debug";
    public ToasterTraceLevelName: keyof typeof Trace = "Warning";
    public SentryTraceLevelName: keyof typeof Trace = "Error";
    public AnalyticsProductId = "";
    public EmbedAzureTablesUrl = "";
    public AltoAzureTablesUrl = "";
    public AuthAuditTableName = "AuthAudit";
    public DataAuditTableName = "DataAudit";
    public CalendlyBaseUri = "";
    public DxLicenseKey = "";
    public GoogleTagManagerId = "";
    public MetaPixelId = "";

    protected initialiseConfiguration(configToCopy: Partial<RawClientConfiguration>) {
        Object.assign(this, configToCopy);
    }
}

interface IAdaptWindow extends Window {
    AdaptClientConfiguration?: RawClientConfiguration;
}

const CurrentApiBaseUriKey = "CurrentApiBaseUriKey";

export class ClientConfiguration extends RawClientConfiguration {
    private queuedConfig = new QueuedCaller<ClientConfiguration>();

    public constructor() {
        super();

        // For testing we set this on the window so we can control it.
        const adaptWindow = window as IAdaptWindow;
        if (adaptWindow.AdaptClientConfiguration) {
            this.initialiseConfiguration(adaptWindow.AdaptClientConfiguration);
        }
    }

    /** Will call the callback once the client configuration has been initialised.
     * Typically useful when you want to do some setup as soon as possible (and
     * before the app is initialised. e.g. Logging).
     */
    public onInitialisation(fn: (config: ClientConfiguration) => void) {
        this.queuedConfig.call(fn);
    }

    public initialiseConfiguration(configToCopy: Partial<RawClientConfiguration>) {
        super.initialiseConfiguration(configToCopy);

        if (typeof this.AdaptEnvironment === "undefined") {
            throw new Error(`Unknown Environment Name: ${this.AdaptEnvironmentName}`);
        }
        if (this.AdaptProject === AdaptProject.Unknown) {
            throw new Error(`Unknown Project Name: ${this.AdaptProjectName}`);
        }
        ObjectUtilities.assertValueIsEnumKey(this.TraceLevelName, Trace);
        ObjectUtilities.assertValueIsEnumKey(this.ToasterTraceLevelName, Trace);
        ObjectUtilities.assertValueIsEnumKey(this.SentryTraceLevelName, Trace);

        this.ApiBaseUri = this.CurrentApiBaseUri;

        this.queuedConfig.setCallee(this);
    }

    public get AdaptEnvironment(): AdaptEnvironment {
        return AdaptEnvironment[this.AdaptEnvironmentName];
    }

    public get AdaptProject(): AdaptProject {
        const project = AdaptProject[this.AdaptProjectName];
        return typeof project === "undefined"
            ? AdaptProject.Unknown
            : project;
    }

    public get AdaptProjectLabel(): string {
        const project = this.AdaptProject;
        if (project === AdaptProject.Unknown) {
            throw new Error("Unknown ADAPT Project");
        }

        return AdaptProjectLabel[project];
    }

    public get Profile() {
        switch (this.AdaptEnvironment) {
            case AdaptEnvironment.Local:
            case AdaptEnvironment.Devcloud:
                return "development";
            case AdaptEnvironment.Staging:
                return "staging";
        }
    }

    public get IsDebug() {
        return !!this.Profile;
    }

    public get TraceLevel() {
        return Trace[this.TraceLevelName];
    }

    public get ToasterLevel() {
        return Trace[this.ToasterTraceLevelName];
    }

    public get SentryLevel() {
        return Trace[this.SentryTraceLevelName];
    }

    public get CurrentApiBaseUri() {
        return LocalStorage.get<string>(CurrentApiBaseUriKey) ?? this.ApiBaseUri;
    }

    public set CurrentApiBaseUri(value: string) {
        LocalStorage.set(CurrentApiBaseUriKey, value);
    }

    public get IsCurrentEmbedApiBaseUri() {
        return this.CurrentApiBaseUri === this.EmbedApiBaseUri;
    }

    public get FrontEndBaseUri() {
        return this.IsCurrentEmbedApiBaseUri
            ? this.EmbedBaseUri
            : this.AltoBaseUri;
    }
}

export const AdaptClientConfiguration = new ClientConfiguration();

<adapt-toolbar-content alignment="right">
    <button adaptButton="view"
            (click)="viewCoachingSessionHistory()">coaching session history
    </button>
</adapt-toolbar-content>

<div class="row">
    <div class="col-12 col-md-10 col-lg-9 col-xl-8 col-xxl-7 m-auto d-flex gap-3 flex-column">
        <div class="content">
            <adapt-implementation-kit-content [articleId]="ImplementationKitArticle.CoachAccess"></adapt-implementation-kit-content>
        </div>

        <ng-container *adaptLoading="!account">
            <adapt-coach-access-session-select [readOnly]="false"
                                               [coachOption]="coachOption"
                                               (coachOptionChange)="coachOptionUpdater.next($event)"
                                               (coachRequestChange)="coachRequest = $event"
                                               (canRequestCoach)="canRequestCoach = $event"></adapt-coach-access-session-select>

            <button class="w-100 btn btn-primary"
                    [adaptBlockingClick]="requestCoach"
                    [disabled]="!canRequestCoach || submitting">Request a coach<i
                class="fal fa-angle-right ms-2"></i></button>
        </ng-container>
    </div>
</div>

import { Component, OnInit } from "@angular/core";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { IAdaptLinkObject, RouteService } from "@common/route/route.service";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { ObjectivesRouteService } from "@org-common/lib/objectives/objectives-route.service";
import { SearchService } from "@org-common/lib/search/search.service";
import { SearchRowLabelledBaseComponent } from "@org-common/lib/search/search-row-labelled-base.component";
import { EMPTY, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ICommentSearchResultExtra, IObjectiveSearchResult, ISearchResultMatch } from "../search-results.interface";

@Component({
    selector: "adapt-search-row-objective",
    templateUrl: "./search-row-objective.component.html",
    styleUrls: ["./search-row-objective.component.scss"],
})
export class SearchRowObjectiveComponent extends SearchRowLabelledBaseComponent<IObjectiveSearchResult> implements OnInit {
    public href$?: Observable<IAdaptLinkObject> = EMPTY;
    public objective?: Objective;

    public constructor(
        protected searchService: SearchService,
        protected labellingService: LabellingService,
        private routeService: RouteService,
        private objectivesRouteService: ObjectivesRouteService,
    ) {
        super(searchService, labellingService);
    }

    public async ngOnInit() {
        await super.ngOnInit();

        this.href$ = this.objectivesRouteService.getEditObjectivePageRoute(this.result.objectiveId, this.result.teamId || undefined).pipe(
            map((url) => this.routeService.parseRouterObject(url)!),
        );
        this.matches = this.excludeBreadcrumbs(["Title"], this.withoutLastBreadcrumb(this.result.results));

        this.objective = new Objective();
        this.objective.type = this.result.type;
        this.objective.title = "";
        this.objective.index = this.result.index;
        this.objective.teamId = this.result.teamId || undefined;
    }

    public isKeyResult(match?: ISearchResultMatch) {
        if (!match || !match.field.length) {
            return false;
        }

        return match.field[0] === "Key Results";
    }

    public asMatch(match: any) {
        return match as ISearchResultMatch;
    }

    public isComment(match?: ISearchResultMatch): match is ISearchResultMatch<ICommentSearchResultExtra> {
        return !!match?.extra?.commentId;
    }
}

import { Injectable } from "@angular/core";
import { BaseEntity } from "@common/ADAPT.Common.Model/base-entity";
import { BreezeService } from "@common/lib/data/breeze.service";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { EntityAction, EntityChangedEventArgs, ValidationErrorsChangedEventArgs } from "breeze-client";
import { BehaviorSubject, from, merge, Observable, Subject } from "rxjs";
import { filter, map, startWith, switchMap, tap } from "rxjs/operators";
import { emptyIfUndefinedOrNull } from "../utilities/rxjs-utilities";
import { IBreezeEntity } from "./breeze-entity.interface";

@Injectable({
    providedIn: "root",
})
export class RxjsBreezeService {
    private entityTypeChangeBlocker$ = new BehaviorSubject<boolean>(false);
    private entityChangedFromAutoUpdater$ = new Subject<IBreezeEntity>();

    public constructor(
        private breezeService: BreezeService,
        private commonDataService: CommonDataService,
    ) {
    }

    public set entityTypeChangeBlocker(value: boolean) {
        this.entityTypeChangeBlocker$.next(value);
    }

    public get entityPropertyChanged$() {
        return this.breezeEntityChanged$.pipe(
            filter((data) => data.entityAction === EntityAction.PropertyChange && typeof data.args === "object"),
            map((data) => ({
                entity: data.entity,
                property: data.args!.propertyName!,
            })),
        );
    }

    public get savingInProgress$() {
        return this.commonDataService.savingInProgress$;
    }

    public entityTypeDetached<T extends BaseEntity<T>>(entityType: new (...args: any[]) => T) {
        return this.breezeEntityChanged$.pipe(
            filter((data) => data.entity instanceof entityType
                && (data.entityAction === EntityAction.Detach || (!!data.entity && data.entity.entityAspect.entityState.isDeleted()))),
            map((data) => data.entity as T),
        );
    }

    public entityTypeUndo<T extends BaseEntity<T>>(entityType: new (...args: any[]) => T) {
        return this.breezeEntityChanged$.pipe(
            // state changed to unchanged -> undo
            filter((data) => data.entity instanceof entityType && !!data.entity
                && data.entity.entityAspect.entityState.isUnchanged() && data.entityAction === EntityAction.EntityStateChange),
            map((data) => data.entity as T),
        );
    }

    public emitEntityChangedFromAutoUpdater(entity: IBreezeEntity) {
        this.entityChangedFromAutoUpdater$.next(entity);
    }

    // used to capture entity attach from import for entity restore
    public entityAttachedOnImport<T extends BaseEntity<T>>(entityType: new (...args: any[]) => T) {
        const isSubscribingType = ObjectUtilities.createIsInstanceFilter(entityType);
        return this.breezeEntityChanged$.pipe(
            filter((data) => !!data.entity
                && isSubscribingType(data.entity) && data.entityAction === EntityAction.AttachOnImport),
            map((data) => data.entity as T),
        );
    }

    /** Creates a hot observable which will emit once per save if any of the entities that
     * were saved are of the type specified.
     */
    public entityTypeChangedInSave<T extends BaseEntity<T>>(entityType: new (...args: any[]) => T) {
        return this.commonDataService.saveCompleted$.pipe(
            map((entities) => entities.filter(ObjectUtilities.createIsInstanceFilter(entityType))),
            filter((entitiesOfType) => entitiesOfType.length > 0),
        );
    }

    public entityTypeChangedFromRemote<T extends BaseEntity<T>>(entityType: new (...args: any[]) => T) {
        const entityChangedFromRemote$ = this.entityChangedFromAutoUpdater$.pipe(
            filter(ObjectUtilities.createIsInstanceFilter(entityType)),
        );

        return entityChangedFromRemote$;
    }

    /**
     * Capture any entity saved in current or other session
     */
    public entityTypeChanged<T extends BaseEntity<T>>(entityType: new (...args: any[]) => T) {
        const entityChangedFromSave$ = this.entityTypeChangedInSave(entityType).pipe(
            switchMap((entities) => from(entities)),
        );
        const entityChangedFromUpdate$ = this.entityTypeChangedFromRemote(entityType);

        // use emitEntity to cache and clear to ensure the same entity emitted from entityChangeCommitted$
        // is not emitted multiple times. e.g. agenda item changed from server while editing agenda item
        // - entityTypeChangeBlocker will be true so no entity will be emitted
        // - after agenda item editing is finished, entityTypeBlock will be false, and the previously held value
        //   will be emitted and set to undefined
        // - if agenda item is edited and closed again, since emitEntity is undefined, it won't be emitted.
        let emitEntity: T | undefined;
        return merge(entityChangedFromSave$, entityChangedFromUpdate$).pipe(
            tap((entity) => emitEntity = entity),
            switchMap(() => this.entityTypeChangeBlocker$),
            filter((isBlocking) => !isBlocking),
            map(() => emitEntity),
            emptyIfUndefinedOrNull(),
            tap(() => emitEntity = undefined),
        );
    }

    /**
     * Gets a hot observable which emits each time an entity change has been committed. This might be from
     * a local save operation or a save by another user which has been integrated into the local cache.
     * A new or modified entity will have an entity state of 'Unchanged' and a deleted entity will have an
     * entity state of 'Detached'
     */
    public get entityChangeCommitted$() {
        return this.breezeEntityChanged$.pipe(
            filter((data) => {
                const action = data.entityAction;

                // When new or existing saved locally
                return action === EntityAction.MergeOnSave
                    // When new or existing saved in another session
                    || action === EntityAction.MergeOnQuery
                    // When new entity saved in another session
                    || action === EntityAction.AttachOnQuery
                    // When deleted locally
                    || action === EntityAction.Detach
                    // When deleted in another session
                    || (action === EntityAction.EntityStateChange && data.entity!.entityAspect.entityState.isDetached());
            }),
            map((data) => data.entity),
        );
    }

    public get validationErrorsChanged$() {
        return new Observable<ValidationErrorsChangedEventArgs>((subscriber) => {
            const handle = this.breezeEntityManager.validationErrorsChanged.subscribe((data) => subscriber.next(data));
            return () => this.breezeEntityManager.validationErrorsChanged.unsubscribe(handle);
        });
    }

    public get breezeEntityChanged$() {
        return new Observable<EntityChangedEventArgs>((subscriber) => {
            const handle = this.breezeEntityManager.entityChanged.subscribe((data) => {
                if (!this.breezeService.isTransientEntity(data.entity as IBreezeEntity)) {
                    subscriber.next(data);
                }
            });

            return () => this.breezeEntityManager.entityChanged.unsubscribe(handle);
        });
    }

    /** Create a hot observable which will emit the value of the specified property, and then any time it changes */
    public entityPropertyChangedWithInitialValue<T extends IBreezeEntity<T>, K extends keyof T>(entity: T, property: K): Observable<T[K]> {
        return this.entityPropertyChanged(entity, property).pipe(
            map((i) => i[property]),
            startWith(entity[property]),
        );
    }

    /** Create a hot observable which will emit immediately with the given entity, and also every time
     * any of the specified properties changes
     */
    public entityPropertyChangedWithInitialEntity<T extends IBreezeEntity<T>>(entity: T, ...properties: (keyof T)[]) {
        return this.entityPropertyChanged(entity, ...properties).pipe(
            startWith(entity),
        );
    }

    /**
     * Creates a hot observable which will emit each time the entity has the specified
     * properties changed (or any if none). Note this won't emit upon subscription.
     */
    public entityPropertyChanged<T extends IBreezeEntity<T>>(entity: T, ...properties: (keyof T)[]) {
        const propertyChange$ = this.entityPropertyChanged$.pipe(
            filter((v) => v.entity === entity),
            filter((v) => properties.length === 0 || properties.indexOf(v.property) >= 0),
            map((v) => v.entity as T),
        );

        // entityPropertyChanged$ not emitted for rejection so always emit when that occurs
        const changesRejected$ = this.breezeEntityChanged$.pipe(
            filter((v) => v.entity === entity && v.entityAction === EntityAction.RejectChanges),
            map((v) => v.entity as T),
        );

        return merge(propertyChange$, changesRejected$);
    }

    private get breezeEntityManager() {
        return this.breezeService.breezeEntityManager;
    }
}

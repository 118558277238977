import { Injectable } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { BreezeEntityUtilities } from "@common/lib/data/breeze-entity-utilities";
import { IBreezeModel } from "@common/lib/data/breeze-model.interface";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { EMPTY, Observable } from "rxjs";
import { finalize, map, switchMap, tap, withLatestFrom } from "rxjs/operators";
import toastr from "toastr";
import { EntitySignalRHub } from "./entity-signalr-hub.service";
import { IEntityUpdateSummary } from "./entity-update-summary.interface";

type EntityUpdate<T extends IBreezeEntity<T>> = [T, Person];

@Injectable({
    providedIn: "root",
})
export class EntityUpdateUtilities {
    public constructor(
        private entityHub: EntitySignalRHub,
    ) { }

    /**
     * Subscribe to the result of this method for toaster updates to be displayed when an entity
     * is updated (or it is a related entity of an update). Make sure to unsubscribe when the
     * page you are on is left so that they won't continue to be shown (and currently displayed
     * toasters will be closed)
     */
    public displayToasterOnEntityChange<T extends IBreezeEntity<T>>(getEntity: Observable<T> | (() => T | undefined), formatEntity: (entity: T) => string) {
        const displayedToasters: JQuery<HTMLElement>[] = [];
        return this.onEntityChangeWithPerson(getEntity).pipe(
            tap(([entity, person]) => {
                const entitySummary = formatEntity(entity);
                const toastrElement = toastr.info(`${person.fullName} has updated ${entitySummary}`, "", {
                    positionClass: "toast-top-right",
                    timeOut: 60000,
                    closeButton: true,
                });
                displayedToasters.push(toastrElement);
            }),
            finalize(() => displayedToasters.forEach((t) => toastr.clear(t))),
        );
    }

    /**
     * Emits when the entity returned by the callback has been changed, or it is a
     * related entity of a change.
     */
    public onEntityChange<T extends IBreezeEntity<T>>(getEntity: Observable<T> | (() => T | undefined)) {
        return this.onEntityChangeWithPerson(getEntity).pipe(
            map(([entity, _person]) => entity),
        );
    }

    public onEntityChangeWithPerson<T extends IBreezeEntity<T>>(getEntity: Observable<T> | (() => T | undefined)) {
        let entity$: Observable<T | undefined>;
        if (typeof getEntity === "function") {
            entity$ = this.entityHub.entitiesUpdated$.pipe(
                map(() => getEntity()),
            );
        } else {
            entity$ = getEntity;
        }

        return this.entityHub.entitiesUpdated$.pipe(
            withLatestFrom(entity$),
            switchMap(([updates, entity]) => {
                if (!entity) {
                    return EMPTY;
                }

                const allEntities = this.getChangedAndRelatedEntities(updates);
                const matchingEntities = allEntities.filter((e) => e === entity) as T[];
                return matchingEntities.map((e) => [e, updates.changedByPerson] as const);
            }),
        );
    }

    /**
     * Creates an observable which emits once for each entity which is synced with the provided type.
     * This will also emit for synced changes where the entity type is one of the related entities of
     * the updates.
     */
    public onEntityTypeChange<T extends IBreezeEntity<T>>(model: IBreezeModel<T>) {
        return this.entityHub.entitiesUpdated$.pipe(
            switchMap((updates) => {
                const changedEntities = this.changedEntitiesByType(updates, model);
                return changedEntities;
            }),
        );
    }

    /**
     * Creates an observable which emits once for each entity, including the person who made the change,
     * which is synced with the provided type. This will also emit for synced changes where the entity
     * type is one of the related entities of the updates.
     */
    public onEntityTypeChangeWithPerson<T extends IBreezeEntity<T>>(model: IBreezeModel<T>): Observable<EntityUpdate<T>> {
        return this.entityHub.entitiesUpdated$.pipe(
            switchMap((updates) => {
                const changedEntities = this.changedEntitiesByType(updates, model);
                return changedEntities.map((e) => [e, updates.changedByPerson] as EntityUpdate<T>);
            }),
        );
    }

    private changedEntitiesByType<T extends IBreezeEntity<T>>(updates: IEntityUpdateSummary, model: IBreezeModel<T>) {
        const entitiesToFilter = this.getChangedAndRelatedEntities(updates);
        const changedEntities = BreezeEntityUtilities.filterEntitiesByModel(entitiesToFilter, model);
        return changedEntities;
    }

    private getChangedAndRelatedEntities(updates: IEntityUpdateSummary) {
        return ArrayUtilities.mergeArrays([updates.changedEntities, updates.entitiesRelatedToChanges]);
    }
}

import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Person } from "../person/person";
import { KeyResult } from "./key-result";

export class KeyResultValue extends BaseEntity<KeyResultValue> {
    public keyResultValueId!: number;
    public keyResultId!: number;
    public value!: number;
    public dateTime!: Date;
    public personId!: number;

    public keyResult!: KeyResult;
    public person!: Person;

    public get formattedValue() {
        return this.keyResult.getFormattedValue(this.value);
    }
}

export const KeyResultValueBreezeModel = new BreezeModelBuilder("KeyResultValue", KeyResultValue)
    .isOrganisationEntity()
    .orderByDesc("dateTime")
    .persistChangedEntity()
    .build();

import { Component, OnInit } from "@angular/core";
import { ZoneMetadata } from "@common/ADAPT.Common.Model/methodology/zone";
import { IAdaptLinkObject } from "@common/route/route.service";
import { SearchService } from "@org-common/lib/search/search.service";
import { SearchRowBaseComponent } from "@org-common/lib/search/search-row-base.component";
import { Observable } from "rxjs";
import { StrategicViewIcon, StrategicViewOption } from "../../strategy/strategy-view-constants";
import { StrategyBoardDefaultViewOptions, StrategyBoardPageRoute, StrategyBoardViewParam } from "../../strategy-board/strategy-board-page/strategy-board-page.component";
import { IStrategicThemeSearchResult } from "../search-results.interface";

@Component({
    selector: "adapt-search-row-strategic-theme",
    templateUrl: "./search-row-strategic-theme.component.html",
})
export class SearchRowStrategicThemeComponent extends SearchRowBaseComponent<IStrategicThemeSearchResult> implements OnInit {
    public readonly ThemeIcon = StrategicViewIcon.ThemeIcon;
    public readonly ZoneMetadata = ZoneMetadata;

    public href$?: Observable<IAdaptLinkObject>;

    public constructor(
        protected searchService: SearchService,
    ) {
        super(searchService);
    }

    public ngOnInit() {
        super.ngOnInit();

        this.href$ = StrategyBoardPageRoute.getRouteObject(undefined, {
            [StrategyBoardViewParam]: [StrategicViewOption.Themes, ...StrategyBoardDefaultViewOptions].join(","),
        });
        this.matches = this.excludeNameBreadcrumb(this.result.results);
    }
}

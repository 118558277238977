import { Injectable } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { CommonPurposeVisionService } from "@org-common/lib/purpose-vision/common-purpose-vision.service";
import { forkJoin } from "rxjs";
import { switchMap } from "rxjs/operators";
import { EditGuidingPhilosophyDialogComponent } from "./edit-guiding-philosophy-dialog/edit-guiding-philosophy-dialog.component";
import { EditTeamPurposeDialogComponent } from "./edit-team-purpose-dialog/edit-team-purpose-dialog.component";
import { GuidingPhilosophyService } from "./guiding-philosophy.service";

@Injectable({
    providedIn: "root",
})
export class GuidingPhilosophyUiService {
    public constructor(
        private dialogService: AdaptCommonDialogService,
        private purposeVisionService: CommonPurposeVisionService,
        private guidingPhilosophyService: GuidingPhilosophyService,
    ) { }

    @Autobind
    public editGuidingPhilosophy() {
        return forkJoin({
            guidingPhilosophy: this.purposeVisionService.getOrCreateVision(),
            organisationPurpose: this.purposeVisionService.getOrCreatePurpose(),
            valueStreamPurposes: this.guidingPhilosophyService.getOrCreateActiveValueStreamPurposes(),
        }).pipe(
            switchMap((data) => this.dialogService.open(EditGuidingPhilosophyDialogComponent, data)),
        );
    }

    public editTeamPurpose(team: Team) {
        return this.purposeVisionService.getOrCreatePurpose(team).pipe(
            switchMap((purpose) => this.dialogService.open(EditTeamPurposeDialogComponent, purpose)),
        );
    }
}

import { Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, Renderer2 } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";

@Directive({
    selector: "button[adaptButtonToggle]",
})
export class ButtonToggleDirective implements OnChanges {
    @Input("adaptButtonToggle") public toggleEnabled = false;
    @Output("adaptButtonToggleChange") public toggleEnabledChange = new EventEmitter<boolean>();

    public constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
    ) {
        renderer.setAttribute(elementRef.nativeElement, "data-toggle", "button");
    }

    public ngOnChanges() {
        // Using @HostBinding or not using a timeout results in the oppositely bound values to what you would expect!
        // I think this is something to do with our hybrid project causing issues here as I was unable to replicate in
        // a plunkr
        setTimeout(this.updateElement);
    }

    @HostListener("click")
    public onClick() {
        this.toggleEnabledChange.emit(!this.toggleEnabled);
    }

    @Autobind
    private updateElement() {
        this.renderer.setAttribute(this.elementRef.nativeElement, "aria-pressed", String(this.toggleEnabled));

        if (this.toggleEnabled) {
            this.renderer.addClass(this.elementRef.nativeElement, "active");
        } else {
            this.renderer.removeClass(this.elementRef.nativeElement, "active");
        }
    }
}

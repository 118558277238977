import { Component, OnInit } from "@angular/core";
import { InputTypeMetadata } from "@common/ADAPT.Common.Model/organisation/input-type-metadata";
import { IAdaptLinkObject } from "@common/route/route.service";
import { SearchService } from "@org-common/lib/search/search.service";
import { SearchRowBaseComponent } from "@org-common/lib/search/search-row-base.component";
import { Observable } from "rxjs";
import { CompetitorAnalysisPageRoute } from "../../strategic-inputs/competitor-analysis-page/competitor-analysis-page.component";
import { CAInputsViewOptions } from "../../strategy-board/strategy-board-page/strategy-board-page.component";
import { ICompetitorAnalysisInputSearchResultExtra, ICompetitorAnalysisSearchResult, ISearchResultMatch } from "../search-results.interface";

@Component({
    selector: "adapt-search-row-competitor-analysis",
    templateUrl: "./search-row-competitor-analysis.component.html",
})
export class SearchRowCompetitorAnalysisComponent extends SearchRowBaseComponent<ICompetitorAnalysisSearchResult> implements OnInit {
    public readonly CAInputsViewOptions = CAInputsViewOptions;
    public readonly InputTypeMetadata = InputTypeMetadata;

    public href$?: Observable<IAdaptLinkObject>;

    public constructor(
        protected searchService: SearchService,
    ) {
        super(searchService);
    }

    public ngOnInit() {
        super.ngOnInit();

        this.href$ = CompetitorAnalysisPageRoute.getRouteObject();

        // only remove the name breadcrumb from the top level
        this.matches = this.excludeNameBreadcrumb(this.result.results, true);
    }

    public asMatch(value: any) {
        return value as ISearchResultMatch<ICompetitorAnalysisInputSearchResultExtra>;
    }
}

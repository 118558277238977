import { BaseConnectionState } from "./base-connection-state";
import { ConnectedState } from "./connected-state";
import { ConnectionEvent } from "./connection-event.enum";
import { ReconnectingState } from "./reconnecting-state";
import { SignalRConnectionContext } from "./signalr-connection-context";

export class ConnectionInterruptedState extends BaseConnectionState {
    public readonly event = ConnectionEvent.Interrupted;

    public constructor(context: SignalRConnectionContext) {
        super(context);

        const msg = `Connection to SignalR endpoint ${this.signalREndpoint} lost, attempting reconnection`;
        this.context.resetConnectionPromise(msg);
        this.context.raiseConnectionEvent(ConnectionEvent.Lost);
    }

    public reestablishExistingConnection(): void {
        const connectionMessage = `Connection to SignalR endpoint ${this.signalREndpoint} has been restablished`;
        this.context.state = new ConnectedState(this.context, this, connectionMessage);
    }

    public startReconnecting(): void {
        this.context.state = new ReconnectingState(this.context, this);
    }
}

import { Component, Input, ViewChild } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { UserService } from "@common/user/user.service";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { ConfigureKanbanBoardsComponent } from "@org-common/lib/configuration/common/configure-kanban-boards/configure-kanban-boards.component";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";

@Component({
    selector: "adapt-configure-personal-kanban",
    templateUrl: "./configure-personal-kanban.component.html",
})
export class ConfigurePersonalKanbanComponent extends ConfigureFeatureBase {
    @Input() public configItem?: IConfigItem;

    public person?: Person;

    @ViewChild(ConfigureKanbanBoardsComponent) public kanbanConfigureComponent?: ConfigureKanbanBoardsComponent;

    public constructor(
        configurationService: ConfigurationService,
        private userService: UserService,
    ) {
        super(configurationService);
    }

    @Autobind
    public async initialiseData() {
        this.person = await this.userService.getCurrentPerson();
        this.kanbanConfigureComponent?.refresh();
    }
}

import { ElementRef, Renderer2 } from "@angular/core";
import { Logger } from "@common/lib/logger/logger";
import { AdaptRenderer } from "@common/ux/adapt-renderer.service";

export class BaseDirective {
    protected readonly renderer: AdaptRenderer;

    // Even though constructor.name will be minified, we only really care about it
    // for local development where this won't occur
    protected readonly log = Logger.getLogger(this.constructor.name);

    protected constructor(
        protected elementRef: ElementRef<HTMLElement>,
        renderer: Renderer2,
    ) {
        this.renderer = new AdaptRenderer(renderer);
    }

    public get nativeElement() {
        return this.elementRef.nativeElement;
    }
}

import { Component, ElementRef, ViewChild } from "@angular/core";
import { NonParticipantUserTypes } from "@common/ADAPT.Common.Model/embed/user-type";
import { LabelLocation } from "@common/ADAPT.Common.Model/organisation/label-location";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ObjectiveType } from "@common/ADAPT.Common.Model/organisation/objective-type";
import { IEntityWithRequiredTeam } from "@common/ADAPT.Common.Model/organisation/team-entity.interface";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ChatAiService } from "@common/lib/chat-ai/chat-ai.service";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import { ValueChangedEvent } from "devextreme/ui/text_box";
import { BehaviorSubject, of, tap } from "rxjs";
import { ObjectivesService } from "../objectives.service";
import { ObjectivesAuthService } from "../objectives-auth.service";
import { ObjectivesRouteService } from "../objectives-route.service";
import { SelectSupportingObjectiveComponent } from "../select-supporting-objective/select-supporting-objective.component";

export const ObjectiveMetadataStepSelector = "adapt-objective-metadata-step";
export enum ObjectiveMetadataStep {
    Title,
    Others,
}

@WorkflowStepComponent(ObjectiveMetadataStepSelector)
@Component({
    selector: "adapt-objective-metadata-step",
    templateUrl: "./objective-metadata-step.component.html",
})
export class ObjectiveMetadataStepComponent extends WorkflowStepComponentAdapter {
    public readonly ObjectiveMetadataStep = ObjectiveMetadataStep;
    public readonly ObjectiveType = ObjectiveType;
    public readonly NonParticipantUserTypes = NonParticipantUserTypes;

    public objective?: Objective;
    public workflowStepCompleted = new BehaviorSubject<boolean>(false);
    public collapseMoreOptions = true;

    public reviewResponse?: string;

    @ViewChild(SelectSupportingObjectiveComponent) private selectSupportingObjectiveComponent?: SelectSupportingObjectiveComponent;

    public constructor(
        private objectivesAuthService: ObjectivesAuthService,
        private objectivesService: ObjectivesService,
        private chatAiService: ChatAiService,
        private objectivesRouteService: ObjectivesRouteService,
        elementRef: ElementRef,
    ) {
        super(elementRef);
    }

    public postWorkflow() {
        // if not alto, go to the edit objective page after create (alto is managed by event type preset pipe)
        if (AdaptClientConfiguration.AdaptProjectName !== AdaptProject.Alto && this.workflowStep?.customData === ObjectiveMetadataStep.Title) {
            return this.objectivesRouteService.gotoEditObjectivePageRoute(this.objective!.objectiveId, this.objective!.teamId);
        } else {
            return of(undefined);
        }
    }

    public workflowStepOnInit() {
        this.objective = this.workflowStep?.workflow.runData as Objective;
        if (this.objective) {
            // this will emit to entitiesToConfirm to be handled by dialog discard - OK to emit multiple times as workflow-run dialog will only add once
            this.emitEntityChange(this.workflowStep?.workflow.runData);

            if (this.objective.parentObjective) {
                // we have a supporting objective pre-filled, so expand the more options by default
                this.collapseMoreOptions = false;
            }
        }

        this.checkStepCompletion();
    }

    public workflowStepNext() {
        if (this.workflowStep?.customData === ObjectiveMetadataStep.Title && this.objective) {
            return this.objectivesService.saveEntities([this.objective, ...this.objective.labelLocations]);
        } else {
            return of(undefined);
        }
    }

    public checkStepCompletion() {
        this.objective?.entityAspect.validateEntity();
        if (this.workflowStep?.customData === ObjectiveMetadataStep.Others) {
            this.workflowStepCompleted.next(!!this.objective?.assigneePersonId);
        } else {
            this.workflowStepCompleted.next(!this.objective?.entityAspect.hasValidationErrors && !!this.objective?.assigneePersonId &&
                !!this.objective?.title.trim());
        }
    }

    @Autobind
    public suggestObjectiveTitle() {
        return this.chatAiService.suggestObjectiveTitle(this.objective!.description ?? "unknown").pipe(
            tap((suggestedTitle) => this.objective!.title = suggestedTitle),
            tap(() => this.checkStepCompletion()),
            this.takeUntilDestroyed(),
        );
    }

    @Autobind
    public reviewObjectiveTitle() {
        if (this.objective?.title && this.objective.description) {
            return this.chatAiService.reviewObjectiveTitle(this.objective.title, this.objective.description).pipe(
                tap((reviewResponse) => this.reviewResponse = reviewResponse),
                this.takeUntilDestroyed(),
            );
        } else {
            return of(undefined);
        }
    }

    public onTitleChanged(e: ValueChangedEvent) {
        if (e.event) {
            this.checkStepCompletion();
            this.reviewResponse = undefined;
        }
    }

    public async onTypeChanged() {
        await this.objectivesService.autoUpdateDueDate(this.objective);
        if (this.objective?.type === ObjectiveType.Annual) {
            this.objective.parentObjectiveId = undefined;
        }

        this.selectSupportingObjectiveComponent?.ngOnChanges();
    }

    @Autobind
    public filterAssignees(person: Person) {
        return this.objective?.teamId
            ? this.objectivesAuthService.personCanEditObjectivesForTeam(person, this.objective as IEntityWithRequiredTeam)
            : true;
    }

    public onLabelLocationsChanged(labelLocations: LabelLocation[]) {
        labelLocations?.forEach((labelLocation) => this.emitEntityChange(labelLocation));
    }
}

import { ISurveyCategory } from "../survey-category.interface";
import { OrganisationDiagnosticCategoryGrade } from "./organisation-diagnostic-questions";

export class OrganisationDiagnosticConstants {
    // todo: change these to CSS variables (for all survey types)
    public static readonly GoodColor = "#16A34A";
    public static readonly GoodBackgroundColor = "#f2f5e9";
    public static readonly WarningColor = "#FCD34D";
    public static readonly WarningBackgroundColor = "#fdf0e4";
    public static readonly BadColor = "#F87171";
    public static readonly BadBackgroundColor = "#f7e4e2";

    public static readonly GoodPercentageThreshold = 80;
    public static readonly WarningPercentageThreshold = 50;

    public static readonly MaxScore = 5;

    public static readonly Categories: readonly ISurveyCategory[] = [{
        name: OrganisationDiagnosticCategoryGrade.Good,
        color: OrganisationDiagnosticConstants.GoodColor,
        percentageThreshold: OrganisationDiagnosticConstants.GoodPercentageThreshold,
    }, {
        name: OrganisationDiagnosticCategoryGrade.Warning,
        color: OrganisationDiagnosticConstants.WarningColor,
        percentageThreshold: OrganisationDiagnosticConstants.WarningPercentageThreshold,
    }, {
        name: OrganisationDiagnosticCategoryGrade.Bad,
        color: OrganisationDiagnosticConstants.BadColor,
        percentageThreshold: 0,
    }];
}

import { Component, ElementRef, Injector } from "@angular/core";
import { CanvasType, InputsCanvas } from "@common/ADAPT.Common.Model/organisation/inputs-canvas";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { setPageActivationSearchParam, setReturnPathSearchParam } from "@common/ux/base-routed.component";
import { MeetingAgendaItemComponent } from "@org-common/lib/meetings/meeting-agenda-component-registry";
import { StrategicInputsService } from "@org-common/lib/strategic-inputs/strategic-inputs.service";
import { InputsCanvasIdSearchParam, StrategicInputsPageRoute } from "@org-common/lib/strategic-inputs/strategic-inputs-page/strategic-inputs-page.component";
import { StrategyAuthService } from "@org-common/lib/strategy/strategy-auth.service";
import { of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { TourIdentifier } from "../../../tours/tour-identifier.enum";

@MeetingAgendaItemComponent("adapt-add-swt-agenda-item")
@Component({
    selector: "adapt-add-swt-agenda-item",
    templateUrl: "./add-swt-agenda-item.component.html",
})
export class AddSwtAgendaItemComponent extends BaseComponent {
    public pageActivationTours = [TourIdentifier.SWTFeatureTour];
    public readonly EditStrategicInputs = StrategyAuthService.EditStrategicInputs;

    public constructor(
        elementRef: ElementRef,
        private injector: Injector,
        private strategicInputsService: StrategicInputsService,
    ) {
        super(elementRef);
    }

    @Autobind
    public recordSWT() {
        return this.strategicInputsService.getLatestCanvasOfType(CanvasType.StrengthsWeaknessesTrends).pipe(
            switchMap((canvas) => {
                // if there is a canvas created from yesterday, use that, otherwise create a new one
                if (!canvas) {
                    return this.strategicInputsService.createCanvasOfType(CanvasType.StrengthsWeaknessesTrends).pipe(
                        switchMap((newCanvas) => this.strategicInputsService.saveEntities(newCanvas)),
                    );
                }
                return of(canvas);
            }),
            switchMap((canvas: InputsCanvas) => {
                let searchParams = setReturnPathSearchParam(
                    this.injector,
                    { [InputsCanvasIdSearchParam]: canvas?.inputsCanvasId },
                    StrategicInputsPageRoute.id,
                    "Return to meeting");

                if (this.pageActivationTours?.length) {
                    searchParams = setPageActivationSearchParam(searchParams, StrategicInputsPageRoute.id, this.pageActivationTours);
                }

                return StrategicInputsPageRoute.gotoRoute(
                    undefined,
                    searchParams,
                );
            }),
        );
    }

    public gotoSWTPage() {
        let searchParams = setReturnPathSearchParam(
            this.injector,
            {},
            StrategicInputsPageRoute.id,
            "Return to meeting");
        if (this.pageActivationTours?.length) {
            searchParams = setPageActivationSearchParam(searchParams, StrategicInputsPageRoute.id, this.pageActivationTours);
        }
        return StrategicInputsPageRoute.gotoRoute(undefined, searchParams);
    }
}

import { Component, Input } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { BaseComponent } from "@common/ux/base.component/base.component";

@Component({
    selector: "adapt-person-default-image",
    templateUrl: "./person-default-image.component.svg",
    styleUrls: ["./person-default-image.component.scss"],
})
export class PersonDefaultImageComponent extends BaseComponent {

    @Input() public person?: Person;
    @Input() public containerClass = "";
    @Input() public imageSize: "xs" | "sm" | "md" | "lg" = "md";

    constructor() { super(); }
}

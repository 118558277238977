import { NgIfContext } from "@angular/common";
import { Component, Input, OnInit, QueryList, TemplateRef, ViewChildren } from "@angular/core";
import { Value } from "@common/ADAPT.Common.Model/organisation/value";
import { ValuesConstitution } from "@common/ADAPT.Common.Model/organisation/values-constitution";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxSortableEvent } from "@common/ux/dx.types";
import { DragStartEvent } from "devextreme/ui/sortable";
import { defer, lastValueFrom, merge, ReplaySubject } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { ValueDescriptionComponent } from "../value-description/value-description.component";
import { ValuesConstitutionService } from "../values-constitution.service";
import { ValuesConstitutionUiService } from "../values-constitution-ui.service";

@Component({
    selector: "adapt-values-constitution",
    templateUrl: "./values-constitution.component.html",
    styleUrls: ["./values-constitution.component.scss"],
})
export class ValuesConstitutionComponent extends BaseComponent implements OnInit {
    @Input() public set valuesConstitution(vc: ValuesConstitution | undefined) {
        if (vc) {
            this.valuesConstitution$.next(vc);
        }
    }

    @Input() public isEditing = false;
    @Input() public noValuesTemplate?: TemplateRef<NgIfContext<boolean>>;
    @Input() public fullPage = true;

    @ViewChildren("valueDescription") public valueDescriptionComponent?: QueryList<ValueDescriptionComponent>;

    public values: Value[] = [];
    public valuesConstitution$ = new ReplaySubject<ValuesConstitution>(1);

    public constructor(
        private commonDataService: CommonDataService,
        private vcService: ValuesConstitutionService,
        private vcUiService: ValuesConstitutionUiService,
    ) {
        super();
    }

    public ngOnInit() {
        const valueUpdatedInVc$ = this.valuesConstitution$.pipe(
            switchMap((vc) => this.vcService.valueUpdate(vc).pipe(map(() => vc))),
        );
        merge(this.valuesConstitution$, valueUpdatedInVc$).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((vc) => {
            this.values = vc
                ? [...vc.values].sort((a, b) => a.ordinal - b.ordinal)
                : [];
        });
    }

    public onDragStart(e: DragStartEvent) {
        // itemData is not assigned by default
        e.itemData = e.fromData[e.fromIndex];
        e.cancel = !this.isEditing;
    }

    public async updateOrdinals(e: IDxSortableEvent<Value[]>) {
        // Occasionally it looks like this can be called after the value has been detached
        // leading to the valuesConstitution being undefined
        if (!e.itemData || !e.itemData.valuesConstitution) {
            return;
        }

        const values = e.itemData.valuesConstitution.values;
        SortUtilities.reorderItemInIntegerSortedArray(values, "ordinal", e.fromIndex!, e.toIndex!);
        await lastValueFrom(this.commonDataService.saveEntities(values));
    }

    @Autobind
    public editValue(value: Value) {
        return defer(() => this.vcUiService.editValue(value)).pipe(
            tap(() => this.valueDescriptionComponent
                ?.find((cmp) => cmp.value === value)
                ?.updateValueQuestionOrdinals()),
        );
    }

    @Autobind
    public deleteValue(value: Value) {
        // Need to take this reference before the delete as the nav property
        // will become null after deletion
        const vc = value.valuesConstitution;

        return this.vcUiService.deleteValue(value).pipe(
            switchMap(() => this.vcService.saveEntities([value, ...vc.values])),
        );
    }

    public suppressClick($event: MouseEvent) {
        // DX will switch selection, which will change the route, which will cancel our dialog
        // So prevent DX even knowing about the click.
        $event.stopPropagation();
    }
}

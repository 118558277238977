<p>Please review your cadences below.
    You can make any changes by using the back button below to navigate to that specific event.</p>

<div class="my-3">
    <adapt-review-recurrences *ngIf="recurrences"
                              [scheduledRecurrences]="recurrences"></adapt-review-recurrences>
</div>

<p>Upon saving your cadence, the meetings for each cadence will be
    {{cadenceCycle.entityAspect.entityState.isAdded() ? "created" : "updated"}} within the platform.
    This may take a minute to finish saving.
</p>

<p *ngIf="authedWithMicrosoft">The first meeting from each cadence will be automatically synced to your calendar.
    You can sync the other meetings at any time by viewing the meeting on your team's meetings page.</p>

<adapt-time-scheduler-wrapper [items]="items"
                              [sections]="sections"
                              [start]="schedulerStartTime"
                              [currentPeriod]="currentPeriod"
                              [cycle]="cycle"></adapt-time-scheduler-wrapper>

<div *ngIf="started && progressTotalCount > 0"
     class="mt-3">
    <p>Saving your meetings...</p>
    <dx-progress-bar width="100%"
                     [min]="0"
                     [max]="progressTotalCount"
                     [value]="progressCurrentCount"
                     [showStatus]="true"></dx-progress-bar>
</div>

import { Component, Inject, OnInit } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { IdentityStorageService } from "@common/identity/identity-storage.service";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { ORGANISATION_CONFIGURATION_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { RouteService } from "@common/route/route.service";
import { RouteEvent } from "@common/route/route-event.enum";
import { IApplicationBarComponentOptions } from "@common/shell/application-bar/application-bar.component";
import { ICommonShellComponentOptions } from "@common/shell/common-shell/common-shell.component";
import { ShellUiService } from "@common/shell/shell-ui.service";
import { UserService } from "@common/user/user.service";
import { EulaService } from "@org-common/lib/eula/eula.service";
import { AccountService } from "@org-common/lib/organisation/account/account.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { OrgCommonShellService } from "../org-common-shell.service";

@Component({
    selector: "adapt-org-common-shell-wrapper",
    templateUrl: "./org-common-shell-wrapper.component.html",
})
export class OrgCommonShellWrapperComponent implements OnInit {
    public applicationBarOptions: IApplicationBarComponentOptions = {};
    public commonShellOptions: ICommonShellComponentOptions = {
        viewFinishedLoadingEvent: RouteEvent.ComponentActivated,
        applicationBarOptions: this.applicationBarOptions,

        // We expect this once the controller has activated
        viewIsLoadedAfterInitialisation: false,
    };

    public constructor(
        private shellService: OrgCommonShellService,
        private shellUiService: ShellUiService,
        private identityStorageService: IdentityStorageService,
        private orgService: OrganisationService,
        private userService: UserService,
        eulaService: EulaService,
        accountService: AccountService,
        routeService: RouteService,
        @Inject(ORGANISATION_CONFIGURATION_PAGE) organisationConfigurationPageRoute: IAdaptRoute<{}>,
    ) {
        const banner = {
            text: "The customer license agreement has not been accepted.",
            class: "attention-banner",
            isDismissible: false,
            buttonText: "Configure",
            buttonAction: () => routeService.gotoRoute(organisationConfigurationPageRoute, undefined, { section: "Billing" }),
        };
        eulaService.eulaHasBeenAccepted$.subscribe((hideBanner) => {
            if (hideBanner) {
                this.shellUiService.removeBanner(banner);
            } else {
                this.shellUiService.addBanner(banner);
            }
        });

        accountService.addAccountBanner();
    }

    public ngOnInit() {
        // handle logging in via accessToken in the URL hash
        if (window.location.hash) {
            const params = new URLSearchParams(document.location.hash.replace("#", "?"));
            if (params.has("accessToken") && params.has("userName")) {
                this.identityStorageService.sessionData = {
                    access_token: params.get("accessToken") as string,
                    userName: params.get("userName") as string,
                };
                window.location.hash = "";
            }
        }

        // Shell Events
        this.orgService.organisationChanged$.subscribe((organisation) => {
            this.shellUiService.setToolbarIsVisible(!!organisation);
            this.shellUiService.setSidebarIsVisible(!!organisation);

            this.applicationBarOptions.defaultMastheadImageSrc = "/content/images/default_organisation.png";
            // We can't just set mastheadImageId here as we also need to handle the case of no
            // initial image -> one being uploaded so we need another event for that.
        });
        this.orgService.organisationChanging$.subscribe(() => {
            this.shellUiService.setViewIsLoading(true);

            this.applicationBarOptions.defaultMastheadImageSrc = "/content/shell/images/adaptplatformlogo.svg";
            // Clear out the imageId so that the default image is shown
            this.applicationBarOptions.mastheadImageId = undefined;
        });

        // ApplicationBar Events
        this.userService.userChanged$.subscribe(this.updateOrganisationImage);
        this.orgService.organisationChanged$.subscribe(this.updateOrganisation);
        this.orgService.organisationImageIdChanged$.subscribe(this.updateMastheadImageId);
    }

    @Autobind
    private updateMastheadImageId(imageId?: string) {
        this.applicationBarOptions.mastheadImageId = imageId;
    }

    @Autobind
    private updateOrganisationImage() {
        this.shellService.promiseToGetMastheadUrl()
            .then(((url) => this.applicationBarOptions.mastheadHref = url));
    }

    @Autobind
    private updateOrganisation(organisation?: Organisation) {
        if (organisation) {
            // add the organisation name to the title if viewing as a coach or stakeholder manager
            this.userService.currentPerson$.subscribe((person) => {
                let titleSet = false;
                if (person) {
                    const connections = person.getActiveConnections();
                    const isOrgMember = connections.some((conn) => conn.isEmployeeConnection() || conn.isStakeholderConnection());
                    if (!isOrgMember) {
                        this.shellUiService.setTitlePrefix(`[${StringUtilities.shorten(organisation.name, 20)}] `);
                        titleSet = true;
                    }
                }
                if (!titleSet) {
                    this.shellUiService.setTitlePrefix();
                }
            });
        }
        this.updateOrganisationImage();
    }
}

import { Injectable, Injector } from "@angular/core";
import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { KeyFunction, KeyFunctionBreezeModel } from "@common/ADAPT.Common.Model/organisation/key-function";
import { IKeyFunctionLocation } from "@common/ADAPT.Common.Model/organisation/key-function-location";
import { KeyFunctionRole, KeyFunctionRoleBreezeModel } from "@common/ADAPT.Common.Model/organisation/key-function-role";
import { KeyFunctionSupplementaryDataBreezeModel } from "@common/ADAPT.Common.Model/organisation/key-function-supplementary-data";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Role, RoleBreezeModel } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleTypeCode } from "@common/ADAPT.Common.Model/organisation/role-type-code";
import { Team, TeamBreezeModel } from "@common/ADAPT.Common.Model/organisation/team";
import { TeamLocation, TeamLocationBreezeModel } from "@common/ADAPT.Common.Model/organisation/team-location";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { BaseOrganisationService } from "@org-common/lib/organisation/base-organisation.service";
import { forkJoin, of } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { IntegratedArchitectureFrameworkQueryUtilities } from "../integrated-architecture-framework-query-utilities";

@Injectable({
    providedIn: "root",
})
export class KeyFunctionsService extends BaseOrganisationService {
    public constructor(
        injector: Injector,
    ) {
        super(injector);
    }

    public getKeyFunctionById(keyFunctionId: number) {
        return this.commonDataService.getById(KeyFunctionBreezeModel, keyFunctionId);
    }

    public getAllKeyFunctions() {
        return this.commonDataService.getAll(KeyFunctionBreezeModel);
    }

    public getKeyFunctionLeader(keyFunction: KeyFunction) {
        const leaderRole$ = keyFunction.leaderRole
            ? of(keyFunction.leaderRole)
            : this.commonDataService.getById(RoleBreezeModel, keyFunction.leaderRoleId);

        return leaderRole$.pipe(
            filter(ObjectUtilities.assertIsInstanceFilter(Role)),
            map((i) => i.roleConnections.find((rc) => rc.isActive())),
            map((i) => i?.connection?.person),
        );
    }

    /** Get the key function along with the leader role and any supplementary data */
    public getPrimedKeyFunction(keyFunctionId: number) {
        const key = `primedKeyFunction${keyFunctionId}`;
        const predicate = new MethodologyPredicate<KeyFunction>("keyFunctionId", "==", keyFunctionId);
        return this.commonDataService.getWithOptions(KeyFunctionBreezeModel, key, {
            predicate,
            navProperty: "leaderRole,supplementaryData",
        }).pipe(
            map(ArrayUtilities.getSingleFromArray),
        );
    }

    public getKeyFunctionRoles(keyFunction: KeyFunction) {
        const key = `keyFunctionRolesForKeyFunction${keyFunction.keyFunctionId}`;
        const predicate = new MethodologyPredicate<KeyFunctionRole>("keyFunctionId", "==", keyFunction.keyFunctionId);
        return this.commonDataService.getWithOptions(KeyFunctionRoleBreezeModel, key, {
            predicate,
            navProperty: "role",
        }).pipe(
            map((keyFunctionRoles) => keyFunctionRoles.sort((a, b) => a.ordinal - b.ordinal)),
        );
    }

    public getKeyFunctionRolesByRoleId(roleId: number) {
        const key = `keyFunctionRolesByRoleId${roleId}`;
        const predicate = new MethodologyPredicate<KeyFunctionRole>("roleId", "==", roleId);
        return this.commonDataService.getWithOptions(KeyFunctionRoleBreezeModel, key, {
            predicate,
            navProperty: "keyFunction",
        });
    }

    /** Returns the team locations associated with the specified key function, sorted by ordinal */
    public getKeyFunctionTeamLocations(keyFunction: KeyFunction) {
        const key = `keyFunctionTeamsForKeyFunction${keyFunction.keyFunctionId}`;
        const predicate = new MethodologyPredicate<TeamLocation>("keyFunctionId", "==", keyFunction.keyFunctionId);
        return this.commonDataService.getWithOptions(TeamLocationBreezeModel, key, {
            predicate,
            navProperty: "team,keyFunction",
        }).pipe(
            map((teamLocations) => teamLocations
                .filter((tl) => tl.team.isActive())
                .sort((a, b) => a.ordinal - b.ordinal)),
        );
    }

    public getTeamsWithKeyFunctionAsSoleLocation(keyFunction: KeyFunction) {
        const key = `teamsInKeyFunction${keyFunction.keyFunctionId}`;
        const teamLocationPredicate = new MethodologyPredicate<TeamLocation>("keyFunctionId", "==", keyFunction.keyFunctionId);
        return this.commonDataService.getWithOptions(TeamBreezeModel, key, {
            predicate: new MethodologyPredicate<Team>("teamLocations", "any", teamLocationPredicate),
            // don't need nav property team location as that's already primed when get teams to construct the nav hierarchy
        }).pipe(
            map((teams) => teams.filter((i) => i.teamLocations.length === 1)),
        );
    }

    public addTeamToKeyFunction(team: Team, keyFunction: KeyFunction) {
        return this.getKeyFunctionTeamLocations(keyFunction).pipe(
            map((i) => i.filter((tl) => tl.team.isActive()).length),
            switchMap((activeTeamCount) => {
                return this.commonDataService.create(TeamLocationBreezeModel, {
                    keyFunction,
                    team,
                    ordinal: activeTeamCount,
                });
            }),
        );
    }

    public addRoleToKeyFunction(role: Role, keyFunction: KeyFunction, ordinal: number) {
        return this.commonDataService.create(KeyFunctionRoleBreezeModel, {
            keyFunction,
            role,
            ordinal,
        });
    }

    public getKeyFunctionsInLocation(location: IKeyFunctionLocation) {
        const predicate = new MethodologyPredicate<KeyFunction>("zone", "==", location.zone)
            .and(new MethodologyPredicate<KeyFunction>("valueStreamId", "==", location.valueStreamId));

        return this.commonDataService.getByPredicate(KeyFunctionBreezeModel, predicate).pipe(
            map((keyFunctions) => keyFunctions.sort((a, b) => a.ordinal - b.ordinal)),
        );
    }

    public addKeyFunctionAndAssociatedEntities() {
        const archData = new IntegratedArchitectureFrameworkQueryUtilities(this.commonDataService);

        return forkJoin([
            archData.getRoleTypeByCode(RoleTypeCode.Tier1),
            this.currentOrganisation$,
        ]).pipe(
            switchMap(([tier1RoleType, organisation]) => this.commonDataService.create(RoleBreezeModel, {
                organisation,
                roleType: tier1RoleType,
                startDate: new Date(),
                connectionType: ConnectionType.Employee,
                label: "Leader", // Just a placeholder for the backend to update during save
            })),
            switchMap((leaderRole) => this.commonDataService.create(KeyFunctionBreezeModel, {
                organisation: leaderRole.organisation as Organisation,
                leaderRole,
            })),
            switchMap((keyFunction) => this.createSupplementaryData(keyFunction).pipe(
                map(() => keyFunction))),
        );
    }

    public getOrCreateSupplementaryData(keyFunction: KeyFunction) {
        return this.getSupplementaryData(keyFunction).pipe(
            switchMap((supplementaryData) => supplementaryData
                ? of(supplementaryData)
                : this.createSupplementaryData(keyFunction)),
        );
    }

    public getSupplementaryData(keyFunction: KeyFunction) {
        if (keyFunction.supplementaryData) {
            return of(keyFunction.supplementaryData);
        }

        return this.commonDataService.getById(KeyFunctionSupplementaryDataBreezeModel, keyFunction.keyFunctionId);
    }

    private createSupplementaryData(keyFunction: KeyFunction) {
        return this.commonDataService.create(KeyFunctionSupplementaryDataBreezeModel, {
            keyFunction,
        });
    }
}

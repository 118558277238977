import { Injectable, Injector } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { IEntityWithOptionalTeam } from "@common/ADAPT.Common.Model/organisation/team-entity.interface";
import { BaseService } from "@common/service/base.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { from } from "rxjs";
import { ISurveyAuthService } from "../survey-auth.service.interface";

@Injectable({
    providedIn: "root",
})
export class TeamAssessmentAuthService extends BaseService implements ISurveyAuthService {
    public static readonly EditTeamAssessment = "editTeamAssessment";
    public static readonly ReadTeamAssessment = "readTeamAssessment";

    public constructor(
        injector: Injector,
        private authService: AuthorisationService,
        private featuresService: FeaturesService,
    ) {
        super(injector);
    }

    public static registerAccessVerifiers(authorisationService: AuthorisationService) {
        authorisationService.registerAccessVerifier(
            TeamAssessmentAuthService.EditTeamAssessment,
            {
                requirePermissions: [
                    FeaturePermissionName.CulturalNetworkTeamAssessmentEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            TeamAssessmentAuthService.ReadTeamAssessment,
            {
                requirePermissions: [
                    FeaturePermissionName.CulturalNetworkTeamAssessmentEdit,
                    FeaturePermissionName.CulturalNetworkTeamAssessmentRead,
                ],
            },
        );
    }

    public isFeatureActive(entity?: IEntityWithOptionalTeam) {
        return this.featuresService.isFeatureActive(FeatureName.CulturalNetworkTeamAssessment, entity);
    }

    public hasReadAccessToSurveys$(entity?: IEntityWithOptionalTeam) {
        return from(this.authService.promiseToGetHasAccess(TeamAssessmentAuthService.ReadTeamAssessment, entity));
    }

    public hasEditAccessToSurveys$(entity?: IEntityWithOptionalTeam) {
        return from(this.authService.promiseToGetHasAccess(TeamAssessmentAuthService.EditTeamAssessment, entity));
    }
}

<dx-tag-box [dataSource]="dataSource"
            [grouped]="true"
            [value]="initialSelections"
            [disabled]="disabled"
            (onValueChanged)="onValueChanged($event)"
            [acceptCustomValue]="false"
            [showClearButton]="showClearButton"
            [hideSelectedItems]="true"
            groupTemplate="group"
            itemTemplate="item"
            tagTemplate="tag"
            displayExpr="fullName"
            valueExpr="personId"
            placeholder="Select people..."
            [searchExpr]="$any('fullName')"
            [searchEnabled]="true"
            [showDropDownButton]="true"
            (onInitialized)="onTagBoxInitialized($event)"
            class="select-people"
            data-test="select-people">
    <div *dxTemplate="let data of 'group'">
        <i [ngClass]="data.iconClass"></i>
        {{data.key}}
    </div>
    <div *dxTemplate="let data of 'item'"
         data-test="select-person-item">
        {{data.fullName}}
    </div>
    <div *dxTemplate="let data of 'tag'">
        <div class="dx-tag-content align-items-center d-flex flex-row">
            <adapt-link-person [person]="data"
                               [showImage]="true"
                               imageSize="xs"
                               [adaptLinkDisabled]="true"></adapt-link-person>
            <div class="dx-tag-remove-button">
                <i class="fal fa-times"></i>
            </div>
        </div>
    </div>

    <dx-validator></dx-validator>
</dx-tag-box>

<adapt-dialog [maxWidth]="600">
    <h3 adapt-dialog-title>Link objectives</h3>
    <div adapt-dialog-content
         class="d-flex flex-column">
        <div class="d-flex flex-row justify-content-between"
             *adaptLoadingUntilFirstEmit="let treeData of treeData$">
            <dx-tree-view width="100%"
                          [items]="treeData"
                          [searchEnabled]="true"
                          [selectByClick]="true"
                          (onItemRendered)="onTreeItemRendered($event)"
                          (onContentReady)="updateScrollbarOption($event)"
                          (onItemSelectionChanged)="onObjectiveSelected($event)"
                          noDataText="No objectives to link to"
                          showCheckBoxesMode="normal"
                          selectionMode="multiple"
                          class="align-self-start">
                <div *dxTemplate="let objectiveNode of 'objectiveTemplate'"
                     class="objective-node">
                    <adapt-link-objective [objective]="objectiveNode.objective"
                                          [showStatus]="showObjectiveStatus(objectiveNode.objective)"
                                          [showStatusIcon]="false"
                                          [generateHref]="false"
                                          data-test="related-objective"></adapt-link-objective>
                </div>
                <div *dxTemplate="let teamNode of 'teamTemplate'"
                     class="objective-node team-root-node">
                    <div *ngIf="teamNode.teamId !== -1">
                        <span class="align-top">Team: </span>
                        <adapt-link-team [teamId]="teamNode.teamId"></adapt-link-team>
                    </div>
                    <div *ngIf="teamNode.teamId === -1">
                        <span>Organisation</span>
                    </div>
                </div>
            </dx-tree-view>
            <dx-menu [dataSource]="statusFilterItems"
                     class="align-self-start">
                <div *dxTemplate="let itemData of 'selectItem'"
                     class="m-1">
                    <dx-check-box [(value)]="itemData.statusFilter[itemData.valueKey]"
                                  [text]="itemData.text"
                                  (onValueChanged)="updateOtherObjectives()"
                                  [width]="100"></dx-check-box>
                </div>
            </dx-menu>
        </div>
    </div>
    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="selectedObjectives.length < 1"
                                   [saveBlockingClick]="save"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>

import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Person } from "../person/person";
import { SystemEntity } from "./system-entity";

export class SystemKnowledgeExpert extends BaseEntity<SystemKnowledgeExpert> {
    public systemEntityId!: number;
    public knowledgeExpertId!: number;

    // navigation
    public system!: SystemEntity;
    public knowledgeExpert!: Person;
}

export const SystemKnowledgeExpertBreezeModel = new BreezeModelBuilder("SystemKnowledgeExpert", SystemKnowledgeExpert)
    .hasSource()
    .withIdField()
    .isOrganisationEntity()
    .build();

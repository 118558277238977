import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { PricingModel } from "./pricing-model";
import { UserType } from "./user-type";

export class PricingModelUser extends BaseEntity<PricingModelUser> {
    public pricingModelUserId!: number;
    public pricingModelId!: number;
    public userType!: UserType;
    public minUsers!: number;
    public maxUsers?: number;
    public monthlyFeeDollars!: number;

    public pricingModel!: PricingModel;
}

export const PricingModelUserBreezeModel = new BreezeModelBuilder("PricingModelUser", PricingModelUser)
    .hasSource()
    .build();

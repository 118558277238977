import { Injectable } from "@angular/core";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { AuthorisationService } from "../authorisation/authorisation.service";

@Injectable({
    providedIn: "root",
})
export class Tier1ArchitectureAuthService {
    public static ReadTier1 = "readTier1Architecture";
    public static EditTier1 = "editTier1Architecture";
    public static ReadValueStreamContents = "readValueStreamContents";
    public static EditValueStreamContents = "editValueStreamCOntents";
    public static ReadValueStream = "readValueStream";

    public constructor(
        private authService: AuthorisationService,
    ) { }

    public static registerAccessVerifiers(authorisationService: AuthorisationService) {
        authorisationService.registerAccessVerifier(
            Tier1ArchitectureAuthService.ReadTier1,
            {
                requirePermissions: [
                    FeaturePermissionName.ArchitectureTier1Read,
                    FeaturePermissionName.ArchitectureTier1Edit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            Tier1ArchitectureAuthService.EditTier1,
            {
                requirePermissions: FeaturePermissionName.ArchitectureTier1Edit,
            },
        );
        authorisationService.registerAccessVerifier(
            Tier1ArchitectureAuthService.ReadValueStream,
            {
                requirePermissions: [
                    FeaturePermissionName.ArchitectureTier1Read,
                    FeaturePermissionName.ArchitectureTier1Edit,
                    FeaturePermissionName.CompassGuidingPhilosophyRead,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            Tier1ArchitectureAuthService.ReadValueStreamContents,
            {
                requirePermissions: [
                    FeaturePermissionName.ArchitectureValueStreamContentsRead,
                    FeaturePermissionName.ArchitectureValueStreamContentsEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            Tier1ArchitectureAuthService.EditValueStreamContents,
            {
                requirePermissions: [
                    FeaturePermissionName.ArchitectureValueStreamContentsEdit,
                ],
            },
        );
    }

    public get currentPersonCanEditTier1() {
        return this.authService.currentPersonHasPermission(FeaturePermissionName.ArchitectureTier1Edit);
    }
}

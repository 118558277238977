import toastr from "toastr";
import { SignalRInvokeError } from "../signalr-provider/signalr-invoke-error";
import { ILogInstance, ILogProvider } from "./log-provider.interface";
import { Trace } from "./trace";

const toastrTraceMapping: { [level in Trace]: ToastrDisplayMethod } = {
    [Trace.Debug]: toastr.info,
    [Trace.Info]: toastr.info,
    [Trace.Warning]: toastr.warning,
    [Trace.Error]: toastr.error,
    [Trace.Success]: toastr.success,
};

export class ToasterLogProvider implements ILogProvider {
    public constructor(public readonly logLevel: Trace) { }

    public write(logInstance: ILogInstance) {
        if (logInstance.message.toString().includes(SignalRInvokeError.SignalRMessageId)) {
            return;
        }

        const logger = toastrTraceMapping[logInstance.level];
        logger(logInstance.message);
    }
}

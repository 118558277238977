import { Injectable, Injector } from "@angular/core";
import { Anchor, AnchorBreezeModel } from "@common/ADAPT.Common.Model/organisation/anchor";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { map, switchMap, tap } from "rxjs";
import { BaseOrganisationService } from "../organisation/base-organisation.service";
import { EditAnchorDialogComponent } from "./edit-anchor-dialog/edit-anchor-dialog.component";

@Injectable({
    providedIn: "root",
})
export class StrategicAnchorsService extends BaseOrganisationService {

    public constructor(
        injector: Injector,
        private dialogService: AdaptCommonDialogService,
    ) {
        super(injector);
    }

    public getAllAnchors() {
        return this.commonDataService.getAll(AnchorBreezeModel).pipe(
            map((anchors) => this.sortAnchors(anchors)),
        );
    }

    public sortAnchors(anchors: Anchor[]) {
        return anchors.sort(SortUtilities.getSortByFieldFunction<Anchor>("ordinal"));
    }

    public createAnchor(name?: string, description?: string) {
        return this.commonDataService.create(AnchorBreezeModel, {
            organisationId: this.organisationId,
            name,
            description,
        }).pipe(
            switchMap((anchor) => this.updateAnchorOrdinal(anchor).pipe(map(() => anchor))),
        );
    }

    public updateAnchorOrdinal(anchor: Anchor) {
        return this.getAllAnchors().pipe(
            tap((anchors) => {
                const maxOrdinal = anchors
                    .filter((i) => i.anchorId !== anchor.anchorId)
                    .reduce((max, i) => i.ordinal > max ? i.ordinal : max, 0);
                anchor.ordinal = maxOrdinal + 1;
            }),
        );
    }

    public editAnchor(anchor: Anchor) {
        return this.dialogService.open(EditAnchorDialogComponent, anchor);
    }

    public deleteAnchor(anchor: Anchor) {
        const dialogData: IConfirmationDialogData = {
            title: `Deleting Anchor...`,
            message: `<p>You are about to delete anchor with name:</p>
                <blockquote><b>${anchor.name}</b></blockquote>
                <p>If you choose to delete, they can no longer be recovered.</p>
                <p>Are you sure you want to continue?</p>`,
            confirmButtonText: "Confirm & Delete",
            cancelButtonText: "Cancel",
            checkboxMessage: `Check here to confirm that you want to delete the anchor permanently`,
        };
        return this.dialogService.openConfirmationDialog(dialogData).pipe(
            switchMap(() => this.remove(anchor)),
            switchMap(() => this.saveEntities(anchor)),
        );
    }
}

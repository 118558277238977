export class Breakpoint {
    public static readonly XS = new Breakpoint("XS", 0);
    public static readonly SM = new Breakpoint("SM", 576);
    public static readonly MD = new Breakpoint("MD", 768);
    public static readonly LG = new Breakpoint("LG", 992);
    public static readonly XL = new Breakpoint("XL", 1200);
    public static readonly XXL = new Breakpoint("XXL", 1400);

    public static readonly Order = [
        Breakpoint.XXL,
        Breakpoint.XL,
        Breakpoint.LG,
        Breakpoint.MD,
        Breakpoint.SM,
        Breakpoint.XS,
    ];

    private constructor(
        public readonly name: string,
        public readonly minSize: number,
    ) {
    }

    public get isMobileSize() {
        return !this.isDesktopSize;
    }

    public get isDesktopSize() {
        return this.is(Breakpoint.MD);
    }

    /** Returns true if the given breakpoint minimum size is satisfied
     * i.e. Matches Bootstraps behaviour of mobile first
     * e.g. If window is MD, XS and SM will return true as well
     */
    public is(breakpoint: Breakpoint) {
        return this.minSize >= breakpoint.minSize;
    }
}

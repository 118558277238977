<adapt-dialog [maxWidth]="900">
    <h3 adapt-dialog-title>{{template.Subject}}</h3>

    <div adapt-dialog-content>
        <div class="form-group">
            <div class="email"
                 [froalaView]="template.HtmlBody"></div>
        </div>
    </div>

    <div adapt-dialog-footer
         class="ms-auto">
        <button adaptButton="close"
                (click)="cancel()"></button>
    </div>
</adapt-dialog>

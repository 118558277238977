import { Injectable } from "@angular/core";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { AuthorisationService } from "../authorisation/authorisation.service";

@Injectable({
    providedIn: "root",
})
export class CommonIntegratedArchitectureFrameworkAuthService {
    public static ReadTier2 = "readTier2Architecture";
    public static EditTier2 = "editTier2Architecture";
    public static ConfigureTier2 = "configureTier2Architecture";
    public static EditAnyTier2 = "editAnyTier2Architecture";
    public static ConfigureAnyTier2 = "configureAnyTier2Architecture";
    public static EditRoleContents = "editRoleContents";

    public constructor(
        private authorisationService: AuthorisationService,
    ) {}

    public static registerAccessVerifiers(authorisationService: AuthorisationService) {
        authorisationService.registerAccessVerifier(
            CommonIntegratedArchitectureFrameworkAuthService.ReadTier2,
            {
                requirePermissions: [
                    FeaturePermissionName.ArchitectureTier2Read,
                    FeaturePermissionName.ArchitectureTier2Edit,
                    FeaturePermissionName.ArchitectureTier2Configure,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            CommonIntegratedArchitectureFrameworkAuthService.EditAnyTier2,
            {
                requirePermissions: FeaturePermissionName.ArchitectureTier2Edit,
            },
        );
        authorisationService.registerAccessVerifier(
            CommonIntegratedArchitectureFrameworkAuthService.ConfigureAnyTier2,
            {
                requirePermissions: FeaturePermissionName.ArchitectureTier2Configure,
            },
        );
        authorisationService.registerAccessVerifier(
            CommonIntegratedArchitectureFrameworkAuthService.EditRoleContents,
            {
                requirePermissions: [
                    FeaturePermissionName.ArchitectureTier2PersonalEdit,
                ],
            },
        );
    }

    public currentPersonCan(checker: (person: Person) => boolean) {
        if (!this.authorisationService.currentPerson) {
            return false;
        }

        return checker(this.authorisationService.currentPerson);
    }

    @Autobind
    public currentPersonCanEntity<TEntity>(checker: (person: Person, entity?: TEntity) => Promise<boolean> | boolean, entity?: TEntity) {
        if (!this.authorisationService.currentPerson) {
            return false;
        }

        return checker(this.authorisationService.currentPerson, entity);
    }

    @Autobind
    public personCanReadTier2(person: Person) {
        return this.authorisationService.personHasPermission(person, FeaturePermissionName.ArchitectureTier2Read)
            || this.authorisationService.personHasPermission(person, FeaturePermissionName.ArchitectureTier2Edit)
            || this.authorisationService.personHasPermission(person, FeaturePermissionName.ArchitectureTier2Configure);
    }

    public currentPersonCanReadTier2() {
        if (!this.authorisationService.currentPerson) {
            return false;
        }

        return this.personCanReadTier2(this.authorisationService.currentPerson);
    }

    @Autobind
    public personCanConfigureTier2(person: Person, team?: Team) {
        return this.authorisationService.personHasPermission(person, FeaturePermissionName.ArchitectureTier2Configure, team);
    }
}

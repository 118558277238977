import { FeaturePermissionName } from "../embed/feature-permission-name.enum";
import { Role } from "./role";
import { RoleTypeCode } from "./role-type-code";

export class RoleExtensions {
    public constructor(private role: Role) { }

    public getPermission(featurePermissionName: FeaturePermissionName) {
        if (!this.role.roleFeaturePermissions) {
            throw new Error("This role hasn't had its permissions fetched");
        }

        for (const roleFeaturePermission of this.role.roleFeaturePermissions) {
            if (!roleFeaturePermission.featurePermission) {
                throw new Error("This roles roleFeaturePermission hasn't had its feature permissions fetched");
            }

            if (roleFeaturePermission.featurePermission.name === featurePermissionName) {
                return roleFeaturePermission;
            }
        }

        return undefined;
    }

    public hasPermission(permissionName: FeaturePermissionName): boolean {
        return !!this.getPermission(permissionName);
    }

    /**
     * Is this role allocated by the system (e.g. team leader, team member, key function leader, cultural leader)
     */
    public isSystemAllocatedRole() {
        if (!this.role.roleTypeId) {
            return false;
        }

        this.verifyRoleTypePrimed();

        switch (this.role.roleType!.code) {
            case RoleTypeCode.Coach:
            case RoleTypeCode.Tier1:
            case RoleTypeCode.TeamLeader:
            case RoleTypeCode.TeamMember:
            case RoleTypeCode.TeamParticipant:
            case RoleTypeCode.CulturalLeader:
                return true;

            default:
                return false;
        }
    }

    /**
     * Whether this role has access permissions or not.
     */
    public hasAccessPermissions() {
        return this.role.roleFeaturePermissions.length > 0;
    }

    public isViewerAccessRole() {
        this.verifyRoleTypePrimed();
        return this.role.roleType?.code === RoleTypeCode.Viewer;
    }

    public isCollaboratorAccessRole() {
        this.verifyRoleTypePrimed();
        return this.role.roleType?.code === RoleTypeCode.Collaborator;
    }

    public isLeaderAccessRole() {
        this.verifyRoleTypePrimed();
        return this.role.roleType?.code === RoleTypeCode.Leader;
    }

    public isCoachAccessRole() {
        this.verifyRoleTypePrimed();
        return this.role.roleType?.code === RoleTypeCode.Coach;
    }

    public isCulturalLeaderRole() {
        this.verifyRoleTypePrimed();
        return this.role.roleType?.code === RoleTypeCode.CulturalLeader;
    }

    public isTier1Role() {
        this.verifyRoleTypePrimed();
        return this.role.roleType?.code === RoleTypeCode.Tier1;
    }

    public get roleLabel() {
        return this.role.extensions.hasAccessPermissions() && !this.role.extensions.isSystemAllocatedRole()
            ? "access level"
            : "role";
    }

    private verifyRoleTypePrimed() {
        if (this.role.roleTypeId && !this.role.roleType && !this.role.entityAspect.entityState.isDetached()) {
            throw new Error(`RoleType not primed (RoleId: ${this.role.roleId})`);
        }
    }
}

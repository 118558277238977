import { Injectable } from "@angular/core";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { IDynamicNodeBuilder } from "@common/route/dynamic-node-builder";
import { NavigationUtilitiesService } from "@common/route/navigation-utilities.service";
import { organisationEditObjectivePageRoute } from "@org-common/lib/objectives/edit-objective-page/edit-objective-page.route";
import { ObjectivesService } from "@org-common/lib/objectives/objectives.service";
import { organisationObjectivesPageRoute } from "@org-common/lib/objectives/objectives-page/objectives-page.route";
import { managePeoplePageRoute } from "@org-common/lib/user-management/manage-people-page/manage-people-page.component";
import { WorkflowService } from "@org-common/lib/workflow/workflow.service";
import { WorkflowJourneyPageRoute } from "@org-common/lib/workflow/workflow-journey-page/workflow-journey-page.component";
import { WorkflowsPageRoute } from "@org-common/lib/workflow/workflows-page/workflows-page.component";
import { Observable } from "rxjs";
import { organisationConfigurationPageRoute } from "../configuration/organisation-configuration-page/organisation-configuration-page.component";
import { organisationDashboardPageRoute } from "../organisation/organisation-dashboard-page/organisation-dashboard-page.route";

@Injectable()
export class OrganisationDynamicNavigationService implements IDynamicNodeBuilder {
    public activeNodeShouldBeRebuilt$: Observable<unknown>;

    public constructor(
        private navUtilitiesFactory: NavigationUtilitiesService,
        private objectivesService: ObjectivesService,
        private workflowService: WorkflowService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        this.activeNodeShouldBeRebuilt$ = rxjsBreezeService.entityTypeChangedInSave(Objective);
    }

    public buildDynamicNodes() {
        return [
            this.buildDynamicEditObjectiveNode(),
            this.buildDynamicConfigurationNode(),
            this.buildDynamicManagePeopleNode(),
            this.buildDynamicWorkflowNode(),
        ];
    }

    private buildDynamicWorkflowNode() {
        const dynamicTitleCallback = this.navUtilitiesFactory.createDynamicValueFunctionForOptions<string>({
            entityIdParamName: "workflowId",
            getEntityByParamCallback: (workflowId) => {
                const workflow = this.workflowService.getWorkflowById(workflowId);
                return Promise.resolve(workflow!.name);
            },
            getValue: (value) => value,
        });
        return this.navUtilitiesFactory.nodeBuilderForController(WorkflowJourneyPageRoute.id)
            .setDynamicParentController(WorkflowsPageRoute.id)
            .setTitleDynamicValueCallback(dynamicTitleCallback)
            .build();
    }

    private buildDynamicEditObjectiveNode() {
        const dynamicTitleFunction = this.navUtilitiesFactory.createDynamicValueFunctionForOptions({
            entityIdParamName: "objectiveId",
            getEntityByParamCallback: (id: number) => this.objectivesService.getObjectiveById(id),
            getValue: (objective) => objective.title,
        });
        return this.navUtilitiesFactory.nodeBuilderForController(organisationEditObjectivePageRoute.id)
            .setTitleDynamicValueCallback(dynamicTitleFunction)
            .setDynamicParentController(organisationObjectivesPageRoute.id)
            .build();
    }

    private buildDynamicConfigurationNode() {
        return this.navUtilitiesFactory.nodeBuilderForController(organisationConfigurationPageRoute.id)
            .setDynamicParentController(organisationDashboardPageRoute.id)
            .build();
    }

    private buildDynamicManagePeopleNode() {
        return this.navUtilitiesFactory.nodeBuilderForController(managePeoplePageRoute.id)
            .setDynamicParentController(organisationDashboardPageRoute.id)
            .build();
    }
}

export enum ConnectionEvent {
    /** Raised when SignalR has not been instructed to connect yet or when connecting has failed. */
    Disconnected,

    /** Raised when SignalR is attempting to connect for the first time */
    Connecting,

    /** Raised when SignalR has connected to the remote endpoint and is ready to accept connections */
    Connected,

    /** Raised when the SignalR connection has been lost but has not been confirmed to have dropped yet */
    Interrupted,

    /** Raised when the SignalR connection has been dropped and is trying to reconnect */
    Reconnecting,

    /** Raised when SignalR disconnected from the remote endpoint and will start queuing
     * requests to run when a connection is established.
     * Will occur when either the connection has been interrupted or is reconnecting
     */
    Lost,

    /** Raised when SignalR had lost its connection but is now back in the connected state
     * Use this event when you need to rerun logic as if it is a new connected event.
     */
    Reestablished,
}

<ng-container *ngIf="canLoginToSupport$ | async; else cannotLogin">
    <adapt-sidebar-tab-icon [iconClass]="iconClass"
                            iconText="Help"
                            (click)="navigateToSupport()"></adapt-sidebar-tab-icon>
</ng-container>

<ng-template #cannotLogin>
    <adapt-sidebar-tab-icon [iconClass]="iconClass"
                            iconText="Help">
    </adapt-sidebar-tab-icon>
</ng-template>

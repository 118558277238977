import { CommonRouteConstants } from "@common/route/common-route.constants";
import { PageRouteBuilder } from "@common/route/page-route-builder";
import { SetInitialPasswordPageComponent } from "./set-initial-password-page.component";

export const setInitialPasswordPageRoute = new PageRouteBuilder()
    .usingNgComponent("adapt-set-initial-password-page", SetInitialPasswordPageComponent)
    .atUrl(CommonRouteConstants.SetInitialPassword)
    .requiresAnonymous(true)
    .requiresLogin(false)
    .build();

import { TemplateRef } from "@angular/core";
import { BaseEntity } from "@common/ADAPT.Common.Model/base-entity";
import { WorkflowStep } from "@common/ADAPT.Common.Model/embed/workflow-step";
import { WorkflowConnection } from "@common/ADAPT.Common.Model/organisation/workflow-connection";
import { Observable, ObservableInput, Subject } from "rxjs";

export const WorkflowStepComponentRegistry: Map<string, any> = new Map();
export const WorkflowStepComponent = (key: string): any => (cls: any) => {
    WorkflowStepComponentRegistry.set(key, cls);
};

export const WorkflowStepOverviewComponentRegistry: Map<string, any> = new Map();
export const WorkflowStepOverviewComponent = (key: string): any => (cls: any) => {
    WorkflowStepOverviewComponentRegistry.set(key, cls);
};

export interface IWorkflowStepFooterTemplate {
    alignment: "left" | "center" | "right";
    template: TemplateRef<any>;
}

export interface IWorkflowStepComponent {
    /**
     * This is the workflow step
     */
    workflowStep?: WorkflowStep;
    /**
     * WorkflowConnection that runs this flow
     */
    workflowConnection?: WorkflowConnection;

    /**
     * Workflow run dialog will subscribe to this to move on to the next if this is emitted.
     * Used by custom component to move to the next step.
     * Optionally takes a boolean, that if true indicates we should automatically continue to the next workflow after this completes.
     */
    workflowStepFinishCurrent?: Observable<boolean | void>;
    /**
     * Showing step error message
     */
    workflowStepErrorMessage?: Observable<string | undefined>;
    /**
     * Subscribe to this to get entity change callback from workflow step to be added to entitiesToConfirm for the
     * wizard dialog. WorkflowStepComponent to implement the Observable if the component wants any entity change
     * to cause discard confirmation prompt.
     * Workflow run dialog will subscribe to this before OnInit call (which can then create new entity if required)
     */
    workflowStepEntityChange?: Observable<BaseEntity<any>>;
    /**
     * This will be called when step component is loaded and step instance properties are set
     */
    workflowStepOnInit?: () => any;
    /**
     * This will be called when the dialog fullscreen is toggled
     */
    onDialogFullscreenToggled?: (fullscreen: boolean) => void;
    /**
     * This is to subscribe to step completion -> i.e. emit true/false to determine if can go to next step
     */
    workflowStepCompleted?: Observable<boolean>;
    /**
     * Emitting to this observable from custom step implementation to result in the workflow dialog updating IK size and scrollbar
     */
    updateDimensionsTrigger?: Observable<void>;
    /**
     * This is to subscribe to allow step to be skipped -> i.e. emit true/false to determine if can skip or not
     * if the step definition has canSkip = true
     */
    workflowStepSkipEnabled?: Observable<boolean>;
    /**
     * This is to subscribe to step completion -> i.e. emit true/false to determine if can go to next step
     */
    workflowStepFooterTemplates?: Observable<IWorkflowStepFooterTemplate[]>;
    /**
     * Emit an index starting from 0 to jump to that specific step
     */
    workflowGoToStep?: Observable<number | undefined>;
    /**
     * This is something to perform when click next on the step, which can be slow
     * @param interruptShortcut is something you can emit to within the function to interrupt the transition to next step and complete off the subscription
     */
    workflowStepNext?: (interruptShortcut?: Subject<void>) => ObservableInput<unknown>;
    /**
     * This is called when moving to previous step - typically cleaning up deleted entities
     */
    workflowStepPrevious?: (interruptShortcut?: Subject<void>) => Observable<unknown>;
    /**
     * Function that returns an observable to be executed after completion of the entire workflow
     * - this is used for example to move away from the page after a workflow completion
     * - results from multiple steps will all be forkJoin'ed - so make sure they will complete
     * - by the time this is executed, the step component will already be destroyed -> so make this stateless
     */
    postWorkflow?: () => Observable<unknown>;
    /**
     * Text to display for the next button.
     * By default, it will display the name of the next step.
     */
    workflowStepNextText?: () => string;
}

export interface IWorkflowStepOverviewComponent {
    /**
     * This is the workflow step
     */
    workflowStep?: WorkflowStep;
    /**
     * WorkflowConnection that runs this flow
     */
    workflowConnection?: WorkflowConnection;

    onDataChanged?: () => any;
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BullseyeQuadrant, BullseyeQuadrantStatement } from "@common/ADAPT.Common.Model/organisation/bullseye-quadrant-statement";
import { ServiceUri } from "@common/configuration/service-uri";
import { map } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class ChatAiService {
    private readonly PostCompletionPath = "/PostStatelessChatCompletions";

    public constructor(
        private httpClient: HttpClient,
    ) { }

    public generateBullseyeStatement(statements: BullseyeQuadrantStatement[], maxLength: number) {
        const prompt = `Given the we are good at: ${statements.filter((st) => st.quadrant === BullseyeQuadrant.GoodAt).map((st) => st.statement).join(", ")}.
            Passionate about ${statements.filter((st) => st.quadrant === BullseyeQuadrant.PassionateAbout).map((st) => st.statement).join(", ")}.
            Our customers need ${statements.filter((st) => st.quadrant === BullseyeQuadrant.CustomerNeed).map((st) => st.statement).join(", ")}.
            We can getting paid from ${statements.filter((st) => st.quadrant === BullseyeQuadrant.GetPaidFor).map((st) => st.statement).join(", ")}.
            Generate a bullseye statement for the organisation with not more than ${maxLength} characters. Please do not include character count in
            the response message.`;
        return this.getPromptCompletion(prompt);
    }

    public generateGoalDescription(goalName: string, currentMeasurement: string, targetMeasurement: string, maxLength = 200) {
        const prompt = `With my current organisation goal of "${goalName}",
            my current measurement is: ${currentMeasurement}, and I am trying to achieve ${targetMeasurement}.
            Generate a description for this goal with not more than ${maxLength} characters, outlines the desired outcomes and objectives,
            identify the gaps, identify what we need to do to bridge the gaps and what is our approach to achieving success.`;
        return this.getPromptCompletion(prompt);
    }

    public suggestSuccessMeasure(tryToAchieve: string, whyImportantAndNow: string) {
        const prompt = `If this is what I am trying to achieve: "${tryToAchieve}" and this is why it is important and why now: "${whyImportantAndNow}",
            please give me a few measurable examples on how we know if we have been successful in a numbered list formatted in html. Please ensure
            they are measurable, i.e. quantitative`;
        return this.getPromptCompletion(prompt);
    }

    public suggestObjectiveTitle(objectiveDescription: string) {
        const prompt = `Given that what I am trying to achieve, why it is important and why now
            and how we know we have been successful are in the objective description as follows: "${objectiveDescription}".
            Please suggest a concise statement for the objective title in present tense (using sentence case, not title case),
            making it feel excited and make sure it speaks the "why". Please ensure the response is strictly less than 100 characters in plain text.`;
        return this.getPromptCompletion(prompt);
    }

    public reviewObjectiveTitle(title: string, description: string) {
        const prompt = `Please review and suggest improvement on the objective title? "${title}"
            corresponding to the description of: "${description}"`;
        return this.getPromptCompletion(prompt);
    }

    public paraphraseStatement(statement: string) {
        const prompt = `Paraphrase the following statement: ${statement}`;
        return this.getPromptCompletion(prompt);
    }

    private getPromptCompletion(prompt: string) {
        const url = `${ServiceUri.MethodologyServicesServiceBaseUri}${this.PostCompletionPath}`;
        return this.httpClient.post<string>(url, prompt).pipe(
            map((response) => this.trimLeadingTrailingQuotes(response)),
        );
    }

    // AI generated text sometimes has quotes around them
    private trimLeadingTrailingQuotes(text: string) {
        return text.replace(/^"|"$/g, "");
    }
}

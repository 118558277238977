import { Component, Inject, Injector } from "@angular/core";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { MeetingAgendaItem } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";

@Component({
    selector: "adapt-edit-meeting-agenda-dialog",
    templateUrl: "./edit-meeting-agenda-dialog.component.html",
})
export class EditMeetingAgendaDialogComponent extends BaseDialogWithDiscardConfirmationComponent<Meeting> {
    public readonly dialogName = "EditMeetingAgendaDialog";
    public agendaItems: MeetingAgendaItem[] = [];

    public constructor(
        injector: Injector,
        @Inject(ADAPT_DIALOG_DATA) public meeting: Meeting,
    ) {
        super(injector);
        this.autoResolveData = meeting;
    }

    public get entitiesToConfirm() {
        return [
            ...this.agendaItems,
            ...this.agendaItems
                .filter((i) => !!i.supplementaryData)
                .map((i) => i.supplementaryData!),
            this.meeting,
        ];
    }
}

import { GuidedTourStepOptions } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourUtils } from "@common/lib/guided-tour/guided-tour.utils";

export class CommonTourSteps {
    public static openOrgMenuToShowSidebarNavNodeStep(
        featureToAccess: string,
        navNodeText: string,
    ) {
        return {
            title: "Open the menu",
            text: `<p>This menu allows you to navigate between pages on the platform.
                ${featureToAccess} can be accessed from under the <b>Organisation</b> menu section.
                <p><b>Click on here now</b> to open the menu.</p>`,
            attachTo: {
                element: "#sidebar-menu i",
                on: "right",
            },
            advanceOn: {
                selector: "#sidebar-menu",
                event: "click",
            },
            modalOverlayOpeningPadding: 0,
            showOn: () => {
                // this step will only be shown if Organisation Diagnostic node is not there
                const navNode = GuidedTourUtils.getFirstElementWithTextContent(
                    "adapt-display-link-hierarchy adapt-display-navigation-node span",
                    navNodeText);
                return !GuidedTourUtils.isElementVisible(navNode);
            },
        } as GuidedTourStepOptions;
    }
}

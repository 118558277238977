<dx-data-grid [dataSource]="items"
              [allowColumnResizing]="allowHeaderActions"
              [allowColumnReordering]="allowHeaderActions"
              [columnAutoWidth]="true"
              [wordWrapEnabled]="true"
              [rowAlternationEnabled]="true"
              [hoverStateEnabled]="true"
              [showRowLines]="true"
              [showBorders]="true"
              noDataText="No items matching the filter criteria were found"
              [stateStoring]="dxGridWrapperHelper.stateStoringOptions"
              (onInitialized)="onGridInitialized($event)"
              (onContentReady)="onGridContentReady($event)"
              (onSelectionChanged)="onSelectionChanged($event)">
    <dxo-column-chooser [enabled]="allowHeaderActions"></dxo-column-chooser>
    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true"
               [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-group-panel [visible]="allowHeaderActions"></dxo-group-panel>
    <dxo-grouping [autoExpandAll]="true"></dxo-grouping>
    <dxo-export [enabled]="allowHeaderActions"
                fileName="Actions"></dxo-export>
    <dxo-sorting *ngIf="!allowHeaderActions"
                 mode="none"></dxo-sorting>
    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-selection *ngIf="allowSelection"
                   [mode]="allowMultipleSelection ? 'multiple' : 'single'"
                   showCheckBoxesMode="onClick"></dxo-selection>

    <dxi-column caption="Person"
                cellTemplate="personCellTemplate"
                groupCellTemplate="personGroupCellTemplate"
                dataField="assignee.fullName"
                dataType="string"
                alignment="left"
                [width]="150"></dxi-column>

    <dxi-column caption="Item code"
                cellTemplate="codeCellTemplate"
                dataField="code"
                dataType="string"
                alignment="left"
                [width]="100">
        <dxo-header-filter [dataSource]="codeHeaderItems"></dxo-header-filter>
    </dxi-column>

    <dxi-column caption="Summary"
                dataField="summary"
                dataType="string"
                alignment="left"
                [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column caption="Description"
                cellTemplate="descriptionCellTemplate"
                dataField="description"
                dataType="string"
                alignment="left"
                [visible]="false"
                [allowHeaderFiltering]="false"></dxi-column>

    <dxi-column caption="Status"
                dataField="status"
                dataType="string"
                alignment="left"
                cellTemplate="statusCellTemplate"
                [calculateCellValue]="calculateStatusCellValue"
                [width]="120"
                [allowSearch]="false">
        <dxo-header-filter [dataSource]="statusHeaderItems"></dxo-header-filter>
    </dxi-column>

    <dxi-column caption="Team"
                cellTemplate="teamCellTemplate"
                dataField="board.team.name"
                dataType="string"
                alignment="left"
                [width]="100"></dxi-column>

    <dxi-column dataField="createdDateTime"
                dataType="date"
                caption="Date created"
                [format]="dateFormat"
                sortOrder="desc"
                alignment="left"
                [visible]="false"
                [width]="160"></dxi-column>

    <dxi-column dataField="lastUpdatedDateTime"
                dataType="date"
                caption="Date last updated"
                [format]="dateFormat"
                alignment="left"
                [visible]="false"
                [width]="160"></dxi-column>

    <dxi-column dataField="dueDate"
                dataType="date"
                caption="Date due"
                [format]="dateFormat"
                alignment="left"
                [visible]="false"
                [width]="160"></dxi-column>

    <div *dxTemplate="let cellItem of 'personCellTemplate'">
        <adapt-link-person *ngIf="asItem(cellItem.data) as item"
                           [person]="item.assignee"
                           [showImage]="true"
                           imageSize="xs"
                           nullValueText="Unassigned"></adapt-link-person>
    </div>
    <div *dxTemplate="let item of 'personGroupCellTemplate'">
        <div>Person: {{item.value ?? "Unassigned"}}</div>
    </div>

    <div *dxTemplate="let cellItem of 'codeCellTemplate'">
        <adapt-item-link *ngIf="asItem(cellItem.data) as item"
                         [item]="item"
                         [showSummary]="false"
                         [showStatus]="false"></adapt-item-link>
    </div>

    <div *dxTemplate="let cellItem of 'statusCellTemplate'"
         class="d-flex">
        <adapt-display-item-status *ngIf="asItem(cellItem.data) as item"
                                   [itemStatus]="item.status"></adapt-display-item-status>
    </div>

    <div *dxTemplate="let cellItem of 'descriptionCellTemplate'">
        <div *ngIf="asItem(cellItem.data) as item"
             [froalaView]="item.description"></div>
    </div>

    <div *dxTemplate="let cellItem of 'teamCellTemplate'">
        <ng-container *ngIf="asItem(cellItem.data) as item">
            <adapt-link-team *ngIf="item.board?.team"
                             [team]="item.board!.team"></adapt-link-team>
            <ng-container *ngIf="!item.board?.team">N/A</ng-container>
        </ng-container>
    </div>
</dx-data-grid>

<adapt-dialog [maxWidth]="600"
              [showHelpButton]="false"
              [expandable]="false">
    <h3 adapt-dialog-title>Change status of actions</h3>

    <ng-container adapt-dialog-content>
        <p>You are about to change the status of the following {{changedItems.length}} actions to
            <b><span class="ms-1"
                      [ngClass]="statusMeta.iconClass"></span> {{statusMeta.name}}</b>:
        </p>
        <ul>
            <li *ngFor="let item of changedItems">
                <adapt-item-link [item]="item"
                                 [showDialogLink]="false"
                                 [showStatus]="true"
                                 [showSummary]="true"
                                 [useBoldItemCode]="true"></adapt-item-link>
            </li>
        </ul>

        <p *ngIf="data.status === ItemStatus.Backlog">Setting the status to {{statusMeta.name}}
            means the actions will not be visible by default.
            You can click the <b>Backlog</b> button at any time to display the Backlog column.</p>

        <p *ngIf="data.status === ItemStatus.Closed">Setting the status to {{statusMeta.name}}
            means the actions will not be visible in the standard actions view.
            You can find these actions by using the <b>Search</b> function.</p>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveButtonPreset="saveAndClose"
                                   (saveClick)="resolve(data)"
                                   (cancelClick)="cancel()"></adapt-standard-dialog-buttons>
</adapt-dialog>

import { Component, Input } from "@angular/core";

@Component({
    selector: "adapt-tab-styled-dashboard-element",
    templateUrl: "./tab-styled-dashboard-element.component.html",
    styleUrl: "./tab-styled-dashboard-element.component.scss",
})
export class AdaptTabStyledDashboardElementComponent {
    @Input() public headerWidth? = "300px";
    @Input() public borderColour = "black";
    @Input() public backgroundColour = "white";
    @Input() public tourName?: string;
}

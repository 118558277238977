<ng-container *ngIf="canReadPage$ | async; else showOnlyText">
    <a class="text-nowrap"
       [routerLink]="(href$ | async)?.path"
       [queryParams]="(href$ | async)?.queryParams"
       adaptTooltip="Go to the {{linkTooltipName}} objectives page">
        <i [ngClass]="linkIcon"></i>
        {{linkText}}
        <adapt-team-private-icon [team]="team"
                                 [showTooltip]="false"></adapt-team-private-icon>
    </a>
</ng-container>
<ng-template #showOnlyText>
    <span>
        <i [ngClass]="linkIcon"></i>
        {{linkText}}
    </span>
</ng-template>

import { ElementRef } from "@angular/core";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

export class ShellContentElementHeightTracker {
    private element: JQuery<HTMLElement>;
    private shellContentHeight = 0;
    private heightChange$ = new Subject<number>();
    private previousContentElementOffset = 0;
    private previousContentElementHeight = 0;
    private previousMaxHeight = 0;
    private minContentElementOffset = 0;

    public constructor(
        el: ElementRef,
        private contentElementSelector: string, // e.g. dx-list dx-scroll-view div#id
        private minElementHeight: number,
        private bottomMargin = 0,
        private maxElementHeight?: () => number,
    ) {
        this.element = jQuery(el.nativeElement);
    }

    // This is typically called from ngAfterViewChecked to poll for window resize and remaining height allowed
    // for the content element identified by the selector from constructor
    public get contentElementHeight() {
        let result = this.previousContentElementHeight;
        const root = jQuery(":root");
        const shellContentElement = root.find(".shell-content");
        if (!shellContentElement || shellContentElement.length < 1) {
            return result;
        }

        let contentElementOffset = 0;
        const contentElement = this.element.find(this.contentElementSelector);
        // with animation, it looks like AfterViewChecked is already called even when contentElement as no width or height, skip that first
        if (contentElement && contentElement.length === 1 && contentElement.width() && contentElement.height()) {
            contentElementOffset = contentElement.offset()!.top - shellContentElement.offset()!.top;
            if (contentElementOffset !== this.previousContentElementOffset) {
                // element moved - need to reset min offset (also happened with you use browser back to an element with tracker)
                this.minContentElementOffset = 0;
            }

            if (contentElementOffset < this.minContentElementOffset) {
                contentElementOffset = this.minContentElementOffset;
            } else if (contentElementOffset > this.minContentElementOffset) {
                this.minContentElementOffset = contentElementOffset;
            }
        }

        let maxHeight = 0;
        if (this.maxElementHeight) {
            maxHeight = this.maxElementHeight();
        }

        if (contentElementOffset !== this.previousContentElementOffset ||
            this.shellContentHeight !== shellContentElement.height() ||
            maxHeight !== this.previousMaxHeight) {
            this.previousContentElementOffset = contentElementOffset;
            this.shellContentHeight = shellContentElement.height()!;
            this.previousMaxHeight = maxHeight;

            result = this.shellContentHeight - contentElementOffset - this.bottomMargin;
            if (result < this.minElementHeight) {
                result = this.minElementHeight;
            }

            if (maxHeight && result > maxHeight) {
                result = maxHeight;
            }

            this.heightChange$.next(result);
        }

        this.previousContentElementHeight = result;
        return result;
    }

    // Need to call get contentElementHeight from ngAfterViewChecked from the component to trigger this
    public get onContentHeightChanged$() {
        return this.heightChange$.asObservable().pipe(
            // only notify of height change after debounce to allow the widget to be updated
            debounceTime(200),
        );
    }

    public reset() {
        this.previousContentElementOffset = 0;
        this.previousContentElementHeight = 0;
        this.previousMaxHeight = 0;
        this.minContentElementOffset = 0;
    }

    public destroy() {
        // this will remove all subscribers
        this.heightChange$.complete();
    }
}

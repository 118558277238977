import { booleanAttribute, Component, EventEmitter, Input, Output } from "@angular/core";

export interface IRating {
    checked: boolean;
    title: string;
}

export const Ratings = [
    "Poor",
    "Needs Improvement",
    "Average",
    "Very Good",
    "Excellent",
];

@Component({
    selector: "adapt-rating-stars",
    templateUrl: "./rating-stars.component.html",
    styleUrl: "./rating-stars.component.scss",
})
export class RatingStarsComponent {
    public readonly ratings = Ratings;

    @Input() public rating?: number;
    @Output() public ratingChange = new EventEmitter<number>();

    @Input({ transform: booleanAttribute }) public disabled = false;
    @Input({ transform: booleanAttribute }) public compact = false;

    public setRating(index: number) {
        this.ratingChange.emit(index);
    }
}

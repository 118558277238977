import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { from, ObservableInput, Subject, Subscription } from "rxjs";
import { finalize } from "rxjs/operators";
import { BaseDirective } from "../base.directive";

@Directive({
    selector: "button[adaptBlockingClick]",
})
export class BlockingClickDirective extends BaseDirective implements OnInit, OnDestroy {
    private subscriptions: Subscription[] = [];
    private progressElement?: unknown;

    @Input("adaptBlockingClick") public inProgressCallee!: (param?: unknown) => ObservableInput<unknown>;
    @Input("adaptBlockingClickParam") public blockingClickParam?: unknown;
    @Input() public passClickEvent = false;
    @Input() public stopPropagation = false;

    /**
     * Specify as false to prevent unsubscribing to active subscriptions when this directive
     * is destroyed. In 99% of cases you do not want to do this. One possible exception is a
     * BlockingClick inside a ngFor which is bound to a nav property array. The BlockingClick
     * inside the ngFor deletes one of the entities during the save and breeze removes it from
     * the nav property array, which destroys this button before the save has fully completed.
     */
    @Input() public unsubscribeOnDestroy = true;

    public inProgressChange = new Subject<boolean>();

    public constructor(
        elementRef: ElementRef<HTMLElement>,
        renderer: Renderer2,
    ) {
        super(elementRef, renderer);

        this.progressElement = this.renderer.createIconElement("fal fa-spinner");
    }

    public ngOnInit() {
        if (!this.inProgressCallee) {
            throw new Error("You must provide a callback which will be called on click");
        }
    }

    public ngOnDestroy() {
        this.cleanupSubscriptions();
    }

    private cleanupSubscriptions() {
        if (!this.unsubscribeOnDestroy) {
            // don't log warning here without any subscription as this flag is set to false to avoid the subsequent error.
            // If directive destroyed (i.e. moving away from the page) without any click or action callback,
            // there won't be any subscription. With this flag set to false, we just don't do anything to the subscriptions on destroy.
            return;
        }

        // timeout was added so that existing subscriptions on closed dialogs can be completed properly
        setTimeout(() => {
            if (this.subscriptions.length) {
                this.log.error("BlockingClick: It looks like there's an active subscription here and unsubscribeOnDestroy==true."
                    + " This is most likely caused by a button click action removing the button from DOM via an ngIf."
                    + " To fix, remove the button after the action you wish to wait for has actually completed."
                    + " If you really want this subscription to continue execution then specify unsubscribeOnDestroy=false (be REALLY sure of this).");
            }

            this.subscriptions.forEach((s) => s.unsubscribe());

            this.inProgressChange.complete();
        }, 50);

    }

    @HostListener("click", ["$event"])
    public onClick($event: MouseEvent) {
        if (this.stopPropagation) {
            $event.stopPropagation();
        }

        this.inProgress = true;

        const param = this.passClickEvent
            ? $event
            : this.blockingClickParam;
        const calleeResult = this.inProgressCallee(param);
        // breaking const subscription = from() into these 3 lines to get rid of the warnings caused by the comment
        // above if (!subscription.closed) before.
        // - typical warning: ... from finalize: cannot access 'subscription' before initialization
        // for the case where subscription.closed as described by Julian below this.
        let subscription: Subscription | undefined;
        subscription = undefined;
        subscription = from(calleeResult).pipe(
            finalize(() => {
                this.inProgress = false;
                if (subscription) {
                    ArrayUtilities.removeElementFromArray(subscription, this.subscriptions);
                }
            }),
        ).subscribe();

        // test for subscription to be closed just in case "from(calleeResult)" returns very fast (e.g. of(true))
        // we have seen occurences of the finalize method for subscription being called prior to the subscription being pushed
        // to our internal array
        if (!subscription.closed) {
            this.subscriptions.push(subscription);
        }
    }

    private set inProgress(isInProgress: boolean) {
        this.isDisabled = isInProgress;
        this.inProgressChange.next(isInProgress);

        if (isInProgress) {
            this.renderer.prependElement(this.nativeElement, this.progressElement);
            this.renderer.addClass(this.progressElement, "fa-spin");
        } else {
            this.renderer.removeChild(this.nativeElement, this.progressElement);
            this.renderer.removeClass(this.progressElement, "fa-spin");
        }
    }

    private set isDisabled(disable: boolean) {
        if (disable) {
            this.renderer.disable(this.nativeElement);
        } else {
            this.renderer.enable(this.nativeElement);
        }
    }
}

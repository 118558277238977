import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptToolbarModule } from "@common/shell/toolbar/toolbar.module";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptCollapsibleDashboardElementModule } from "@common/ux/collapsible-dashboard-element/collapsible-dashboard-element.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptMenuModule } from "@common/ux/menu/menu.module";
import { AdaptStyledDashboardElementModule } from "@common/ux/styled-dashboard-element/styled-dashboard-element.module";
import { AdaptTwoColumnModule } from "@common/ux/two-column/two-column.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AdaptCallToActionModule } from "@org-common/lib/call-to-action/call-to-action.module";
import { AdaptFeaturesModule } from "@org-common/lib/features/features.modules";
import { AdaptKanbanModule } from "@org-common/lib/kanban/kanban.module";
import { AdaptObjectivesModule } from "@org-common/lib/objectives/objectives.module";
import { AdaptCommonPurposeVisionModule } from "@org-common/lib/purpose-vision/common-purpose-vision.module";
import { AdaptStrategicAnchorsModule } from "@org-common/lib/strategic-anchors/strategic-anchors.module";
import { AdaptStrategicGoalsModule } from "@org-common/lib/strategic-goals/strategic-goals.module";
import { AdaptStrategyBoardModule } from "@org-common/lib/strategy-board/strategy-board.module";
import { StrategyDashboardPageRoute } from "@org-common/lib/strategy-dashboard-page/strategy-dashboard-page.component";
import { EditPersonAccessDialogComponent } from "@org-common/lib/user-management/edit-person-access-dialog/edit-person-access-dialog.component";
import { UserManagementCommonUiService } from "@org-common/lib/user-management/user-management-ui.service";
import { provideUserRoleActions } from "@org-common/lib/user-management/user-role-actions/user-role-actions.component";
import { AdaptValuesConstitutionModule } from "@org-common/lib/values-constitution/values-constitution.module";
import { AdaptWorkflowModule } from "@org-common/lib/workflow/workflow.module";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { lastValueFrom } from "rxjs";
import { OrganisationDashboardPageComponent } from "./organisation-dashboard-page/organisation-dashboard-page.component";
import { organisationDashboardPageRoute } from "./organisation-dashboard-page/organisation-dashboard-page.route";

@NgModule({
    imports: [
        CommonModule,
        AdaptTwoColumnModule,
        AdaptAuthorisationModule,
        AdaptFeaturesModule,
        AdaptCommonPurposeVisionModule,
        AdaptCollapsibleDashboardElementModule,
        AdaptObjectivesModule,
        AdaptKanbanModule,
        AdaptValuesConstitutionModule,
        AdaptStrategyBoardModule,
        AdaptHtmlEditorModule,
        AdaptValuesConstitutionModule,
        AdaptToolbarModule,
        AdaptButtonModule,
        AdaptMenuModule,
        AdaptStrategicAnchorsModule,
        AdaptCallToActionModule,
        AdaptStrategicGoalsModule,
        AdaptWorkflowModule,
        AdaptStyledDashboardElementModule,
        CollapseModule,
    ],
    declarations: [
        OrganisationDashboardPageComponent,
    ],
    exports: [
        OrganisationDashboardPageComponent,
    ],
    providers: [
        provideUserRoleActions({
            viewAccess: async (injector, connection) => {
                const userManagementUiService = injector.get(UserManagementCommonUiService);
                await lastValueFrom(userManagementUiService.showUserAccessSummaryDialogForConnection(connection), { defaultValue: undefined });
            },
            configureAccess: async (injector, connection) => {
                const commonDialogService = injector.get(AdaptCommonDialogService);
                await lastValueFrom(commonDialogService.open(EditPersonAccessDialogComponent, connection), { defaultValue: undefined });
            },
        }),
    ],
})
export class AdaptAltoOrganisationModule {
    public static readonly Routes = [
        ...organisationDashboardPageRoute.routes,
        ...StrategyDashboardPageRoute.routes,
    ];
}

import { Component, Injector, OnInit } from "@angular/core";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { KanbanService } from "../../kanban.service";
import { KanbanNavigationService } from "../../kanban-navigation.service";
import { ItemUtilities } from "../item-utilities";

@Component({
    selector: "adapt-item-page",
    templateUrl: "./item-page.component.html",
})
export class ItemPageComponent extends BaseRoutedComponent implements OnInit {
    public item?: Item;
    public itemMessage = "Loading item...";

    public constructor(
        private kanbanService: KanbanService,
        private kanbanNavService: KanbanNavigationService,
        injector: Injector,
    ) {
        super(injector);
    }

    public async ngOnInit() {
        const itemCodeOrIdRouteParam = this.getRouteParam("itemCodeOrId");
        const breakdown = ItemUtilities.getItemCodeBreakDown(itemCodeOrIdRouteParam!);

        if (!breakdown.boardIndex) {
            this.itemMessage = `Oops! "${itemCodeOrIdRouteParam}" doesn't look like a valid item code. Please ensure you have the correct link.`;
            this.notifyActivated();
            return;
        }

        this.kanbanService.getItemByCodeOrId(itemCodeOrIdRouteParam!).pipe(
            tap((item) => {
                if (!item) {
                    this.itemMessage = "This item does not exist or you do not have permission to view this item.";
                }
            }),
            switchMap((item) => item ? this.kanbanNavService.gotoItemPage(item) : of(null)),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.notifyActivated());
    }
}

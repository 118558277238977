import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { KeyResultValue } from "./key-result-value";
import { Objective } from "./objective";

export class KeyResult extends BaseEntity<KeyResult> {
    public keyResultId!: number;
    public objectiveId!: number;
    public title!: string;
    public targetValue!: number;
    public targetValuePrefix?: string;
    public targetValueSuffix?: string;
    public ordinal!: number;

    public objective!: Objective;
    public values!: KeyResultValue[];

    public readonly iconClass = "fal fa-key";

    /** Gets the progress, rounded to the nearest percent, of this key result */
    public get currentProgress() {
        return this.getNthOldestProgress(1);
    }

    /** Gets the most recent value set for this Key Result, zero if not updated. */
    public get currentValue() {
        return this.getNthOldestValue(1);
    }

    public get currentKeyResultValue() {
        return this.getNthOldestKeyResultValue(1);
    }

    public getNthOldestProgress(n: number) {
        if (this.targetValue <= 0) {
            return 0;
        }

        const nthValue = this.getNthOldestValue(n);
        return Math.round(nthValue / this.targetValue * 100);
    }

    public getNthOldestValue(n: number) {
        const value = this.getNthOldestKeyResultValue(n);
        return value
            ? value.value
            : 0;
    }

    private getNthOldestKeyResultValue(n: number) {
        if (this.values.length >= n) {
            // values array is only in order if queried from db. added value will just be appended to the end
            // - no guarantee. simply return the latest value here.
            this.values.sort((v1, v2) => v1.dateTime > v2.dateTime ? -1 : 1);
            return this.values[n - 1];

        }
    }

    /**
     * Produce a human readable version of the key result with the given value
     * Keep this in sync with the back end version in KeyResultExtensions.cs
     */
    public getFormattedValue(value: number, withSuffix = true) {
        let formatted = "";

        const prefix = this.targetValuePrefix;
        if (prefix) {
            formatted += this.targetValuePrefix;

            const lastPrefixChar = prefix.slice(-1);
            if (!StringUtilities.isSpecial(lastPrefixChar)) {
                formatted += " ";
            }
        }

        formatted += value;

        const suffix = this.targetValueSuffix;
        if (suffix && withSuffix) {
            const firstSuffixChar = suffix[0];
            if (!StringUtilities.isSpecial(firstSuffixChar)) {
                formatted += " ";
            }

            formatted += this.targetValueSuffix;
        }

        return formatted;
    }
}

export const KeyResultBreezeModel = new BreezeModelBuilder("KeyResult", KeyResult)
    .hasSource()
    .withIdField("keyResultId")
    .isOrganisationEntity()
    .orderBy("ordinal")
    .alwaysFetchingNavigationProperty("values")
    .overridePropertyDisplayName("title", "name")
    .overridePropertyDisplayName("targetValue", "target value")
    .overridePropertyDisplayName("targetValuePrefix", "prefix")
    .overridePropertyDisplayName("targetValueSuffix", "suffix")
    .persistChangedEntity()
    .build();

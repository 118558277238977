import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IObjectiveGroup } from "../objectives.service";

@Component({
    selector: "adapt-objective-list",
    templateUrl: "./objective-list.component.html",
})
export class ObjectiveListComponent extends BaseComponent implements OnChanges {
    @Input() public objectiveGroup!: IObjectiveGroup;
    @Input() public depth = 0;
    @Input() public currentTeamId: number | null = null; // have to use null here as breeze entity is setting this to null rather than undefined
    @Output() public objectiveChange = new EventEmitter<Objective>();

    public childDepth = 1;

    public get objective() {
        return this.objectiveGroup.objective;
    }

    public get isExternalObjective() {
        return this.objective.teamId !== this.currentTeamId;
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.depth) {
            this.childDepth = this.depth + 1;
        }
    }

    public onObjectiveChanged(objective: Objective) {
        this.objectiveChange.emit(objective);
    }
}

import { Component, Injector, TemplateRef, ViewChild } from "@angular/core";
import { IMeetingLocation } from "@common/ADAPT.Common.Model/organisation/meeting";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { CalendarIntegrationUtilities } from "@org-common/lib/calendar/calendar-integration-utilities";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { of, Subject, switchMap, throwError } from "rxjs";
import { catchError, finalize, tap } from "rxjs/operators";
import { ScheduleMeetingWorkflowStepBaseComponent } from "../schedule-meeting-workflow-step-base.component";

@WorkflowStepComponent("adapt-schedule-meeting-schedule-step")
@Component({
    selector: "adapt-schedule-meeting-schedule-step",
    templateUrl: "./schedule-meeting-schedule-step.component.html",
})
export class ScheduleMeetingScheduleStepComponent extends ScheduleMeetingWorkflowStepBaseComponent {
    @ViewChild("startMeetingNowButton")
    public set startMeetingNowButton(ref: TemplateRef<any>) {
        this.workflowStepFooterTemplates.next([
            { alignment: "right", template: ref },
        ]);
    }

    public constructor(
        injector: Injector,
        protected dialogService: AdaptCommonDialogService,
        private commonDataService: CommonDataService,
        private calendarIntegrationUtilities: CalendarIntegrationUtilities,
    ) {
        super(injector);
    }

    public onMeetingLocationChange(meetingLocation?: IMeetingLocation) {
        this.runData.meetingLocation = meetingLocation;
        this.validateEntities();
    }

    public workflowStepPrevious() {
        this.runData.hasGoneBack = true;
        return of(undefined);
    }

    public workflowStepNextText() {
        if (this.runData.rescheduling) {
            return this.showRunMeetingNowButton || this.meetingIsRescheduling
                ? "Reschedule meeting"
                : "Restart meeting";
        }

        return this.workflowStep?.workflowStepNextText;
    }

    public workflowStepNext(interruptShortcut: Subject<void>) {
        return this.promptCalendarIntegration(interruptShortcut).pipe(
            tap(() => this.submitting = true),
            switchMap(() => this.commonDataService.saveEntities(this.runData.entitiesToConfirm)),
            switchMap(() => this.runData.sendInvitations && !this.runData.meeting.extensions.isEnded
                ? this.calendarIntegrationUtilities.createOrUpdateProviderMeeting(this.runData.meeting, this.runData.meetingLocation, this.runData.createTeamsMeeting)
                : of(undefined)),
            tap(() => this.workflowStepErrorMessage.next(undefined)),
            catchError((err) => {
                const errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(err);
                this.workflowStepErrorMessage.next(errorMessage);
                return throwError(() => new Error(errorMessage));
            }),
            finalize(() => this.submitting = false),
        );
    }

    private promptCalendarIntegration(interruptShortcut: Subject<void>) {
        const organiserInfo = this.runData.organiserInfo;
        if (organiserInfo && !organiserInfo.isOrganiser) {
            return this.dialogService.openConfirmationDialogWithBoolean({
                title: "Calendar integration warning",
                message: `<p>Only the meeting organiser can update the integrated calendar entry. The organiser is the first person to have synced the meeting.</p>
                    <p>Any changes you make will not automatically update the calendars of attendees.</p>
                    <p>Please ask <b>${organiserInfo.name}</b> to sync the meeting once you have finished editing the meeting.</p>`,
                hideCancelButton: false,
            }).pipe(
                switchMap((res) => {
                    if (!res) {
                        interruptShortcut.next();
                    }

                    return of(undefined);
                }),
            );
        }

        if (this.runData.hasConflicts && this.runData.sendInvitations) {
            return this.dialogService.openConfirmationDialogWithBoolean({
                title: "Meeting Has Scheduling Conflicts",
                message: "<p>This meeting has scheduling conflicts.</p><p>Do you still want to continue?</p>",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                hideCancelButton: false,
            }).pipe(
                switchMap((res) => {
                    if (!res) {
                        interruptShortcut.next();
                    }

                    return of(undefined);
                }),
            );
        }

        return of(undefined);
    }
}

import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { UrlUtilities } from "@common/lib/utilities/url-utilities";
import { DeepDive, DeepDiveType } from "./deep-dive";

const DeepDiveVideoSlug = "deep-dive-videos";

export class DeepDiveExtensions {
    public constructor(private deepDive: DeepDive) { }

    public get storageSlug() {
        if (this.deepDive.type === DeepDiveType.Video) {
            return DeepDiveVideoSlug;
        }

        return this.deepDive.location;
    }

    public get url() {
        return this.deepDive.type === DeepDiveType.Scorm
            ? `${AdaptClientConfiguration.DeepDiveDomain}/${this.storageSlug}`
            : this.deepDive.location;
    }

    public get frameUrl() {
        if (this.deepDive.type === DeepDiveType.Scorm) {
            return `${this.url}/index.html`;
        }

        const video = UrlUtilities.getVideoEmbedDetails(this.url);
        if (video) {
            return video.url;
        }

        return this.url;
    }

    public get displayUrl() {
        if (this.deepDive.type === DeepDiveType.Video) {
            return this.frameUrl;
        }

        return this.frameUrl.replace(AdaptClientConfiguration.DeepDiveDomain, "");
    }

    public get assetUrl() {
        return `${AdaptClientConfiguration.DeepDiveDomain}/${this.storageSlug}/assets`;
    }

    public get imageUrl() {
        if (!this.deepDive.image) {
            return this.deepDive.image;
        }

        return `${this.assetUrl}/${this.deepDive.image}`;
    }

    public get duration() {
        if (!this.deepDive.durationMinimum) {
            return undefined;
        }

        return this.deepDive.durationMaximum
            ? `${this.deepDive.durationMinimum}-${this.deepDive.durationMaximum} mins`
            : `${this.deepDive.durationMinimum} mins`;
    }
}

<h3 class="mt-3">Reset pathway completion</h3>
<p><small>Removes any 'completed', 'incomplete' statuses for all pathways.</small></p>
<button class="btn btn-primary mb-3"
        (click)="resetWorkflowConnections()">Reset all pathways</button>

<h3 class="border-top pt-3">Complete a pathway</h3>
<p><small>Use this to allow testing of pathways later in the flow - without having to run them individually.
        Click the pathway to complete it!</small></p>
<div *ngFor="let category of categoriesWorkflows">
    <div *ngIf="category && category.key !== undefined && category.items.length > 0">
        <label>{{OrganisationOutcomes[category.key].name}}</label>

        <div *ngFor="let workflow of category.items">
            <i [ngClass]="{'fa-check text-success': completedWorkflows.includes(workflow)}"
               class="fal fa-fw me-2"></i>
            <button class="btn btn-link"
                    [disabled]="completedWorkflows.includes(workflow)"
                    (click)="completeWorkflow(workflow)">{{workflow.name}}</button>
        </div>
    </div>
</div>

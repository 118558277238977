import { Injectable, Injector } from "@angular/core";
import { Purpose, PurposeBreezeModel } from "@common/ADAPT.Common.Model/organisation/purpose";
import { ValueStream } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { FunctionUtilities } from "@common/lib/utilities/function-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { Tier1ArchitectureAuthService } from "@org-common/lib/architecture/tier1-architecture-auth.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { BaseOrganisationService } from "@org-common/lib/organisation/base-organisation.service";
import { CommonPurposeVisionService } from "@org-common/lib/purpose-vision/common-purpose-vision.service";
import { forkJoin, lastValueFrom, of } from "rxjs";
import { map, mergeAll, mergeMap, toArray } from "rxjs/operators";
import { CommonTier1ArchitectureService } from "../architecture/common-tier1-architecture.service";

@Injectable({
    providedIn: "root",
})
export class GuidingPhilosophyService extends BaseOrganisationService {
    public constructor(
        injector: Injector,
        private authorisationService: AuthorisationService,
        private tier1ArchService: CommonTier1ArchitectureService,
        private commonPurposeVisionService: CommonPurposeVisionService,
    ) {
        super(injector);
    }

    protected organisationInitialisationActions() {
        return [
            this.authorisationService.promiseToVerifyAccess(Tier1ArchitectureAuthService.ReadValueStream)
                .then(() => lastValueFrom(this.tier1ArchService.getActiveValueStreams()))
                .catch(FunctionUtilities.noop),
        ];
    }

    public getValueStreamPurposes() {
        return this.commonDataService.getWithOptions(
            PurposeBreezeModel,
            "getValueStreamPurposesValueStreamNotNull",
            {
                predicate: new MethodologyPredicate<Purpose>("valueStreamId", "!=", null),
                navProperty: "valueStream",
            });
    }

    public getPurposeByValueStreamId(valueStreamId: number) {
        if (!valueStreamId) {
            return of(undefined);
        }

        const predicate = new MethodologyPredicate<Purpose>("valueStreamId", "==", valueStreamId);
        return this.commonDataService.getTopByPredicate(PurposeBreezeModel, 1, predicate).pipe(
            map(ArrayUtilities.getSingleFromArray),
        );
    }

    /**
     * Creates a cold observable that emits once with all active value streams (sorted by
     * ordinal) along with their corresponding purpose, if it exists.
     */
    public getActiveValueStreamsWithPurposes() {
        return forkJoin([
            this.tier1ArchService.getActiveValueStreams(),
            this.getValueStreamPurposes(),
        ]).pipe(
            map(([valueStreams]) => valueStreams.sort(SortUtilities.getSortByFieldFunction<ValueStream>("ordinal"))),
        );
    }

    public getOrCreateActiveValueStreamPurposes() {
        return this.tier1ArchService.getActiveValueStreams().pipe(
            mergeMap((valueStreams) => valueStreams.map((vs) => this.commonPurposeVisionService.getOrCreatePurpose(vs))),
            mergeAll(),
            toArray(),
        );
    }
}

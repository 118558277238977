import { Component, OnInit } from "@angular/core";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { SearchService } from "@org-common/lib/search/search.service";
import { SearchRowBaseComponent } from "@org-common/lib/search/search-row-base.component";
import { defer, Observable } from "rxjs";
import { IPersonSearchResult } from "../search-results.interface";

@Component({
    selector: "adapt-search-row-person",
    templateUrl: "./search-row-person.component.html",
})
export class SearchRowPersonComponent extends SearchRowBaseComponent<IPersonSearchResult> implements OnInit {
    public href$?: Observable<string>;

    public constructor(
        protected searchService: SearchService,
        private directorySharedService: DirectorySharedService,
    ) {
        super(searchService);
    }

    public ngOnInit() {
        super.ngOnInit();

        this.href$ = defer(async () => {
            const profileUrl = await this.directorySharedService.promiseToGetProfileUrl(this.result.person);
            return profileUrl ?? this.directorySharedService.promiseToGetDirectoryUrl(this.result.person);
        }).pipe(
            emptyIfUndefinedOrNull(),
        );
    }
}

<span *ngFor="let ratingText of ratings; index as index"
      (click)="!disabled && setRating(index + 1)"
      [adaptTooltip]="ratingText"
      class="fa-1x my-1 me-1 fa-star"
      [attr.data-test]="'rating-star-' + index"
      [ngClass]="{
        'fas checked': (index + 1) <= (rating ?? 0),
        'fal': (index + 1) > (rating ?? 0),
        'clickable': !disabled,
        'fa-2x my-3 me-2': !compact}"></span>

// eslint-disable-next-line max-classes-per-file
import { Injectable, Injector } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { IdentityService } from "@common/identity/identity.service";
import { PromiseUtilities } from "@common/lib/utilities/promise-utilities";
import { AbstractGuard } from "@common/route/abstract.guard";
import { GuardFailureType } from "@common/route/route-events.service";
import { from, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { FeaturesService } from "../features/features.service";
import { OrganisationService } from "../organisation/organisation.service";

@Injectable({
    providedIn: "root",
})
export class FeatureGuardService {
    public static forFeature(feature: FeatureName) {
        @Injectable({
            providedIn: "root",
        })
        class FeatureGuard extends AbstractGuard {
            public static readonly Id = "FeatureGuard";

            constructor(
                private organisationService: OrganisationService,
                private identityService: IdentityService,
                private featuresService: FeaturesService,
                injector: Injector,
            ) {
                super(injector);
            }

            public canActivateWithBypassEvent(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, bypassEvent: boolean) {
                const result = from(this.organisationService.promiseToWaitForOrganisationReady()).pipe(
                    switchMap(() => this.identityService.promiseToCheckIsLoggedIn()),
                    switchMap((isLoggedIn) => isLoggedIn
                        ? PromiseUtilities.promiseToValidatePromiseResolution(this.featuresService.promiseToVerifyRejectOrgFeatures([feature]))
                        : of(false)),
                );
                return this.wrapActivationResult(result, GuardFailureType.FeatureGuardFailed, route, state, bypassEvent);
            }
        }

        return FeatureGuard;
    }
}

<div class="d-flex w-100 flex-column align-items-center h-100 px-3 py-3">
    <div class="align-items-center my-auto col-12 col-sm-9 col-lg-6 col-xl-5 col-xxl-4">
        <div class="text-center">
            <h1>Start today with
                <a href="https://theadaptway.com"
                   target="_blank"
                   aria-label="Go to adapt website">
                    <img src="/content/shell/images/adaptplatformlogo.svg"
                         class="mb-1"
                         alt="adapt logo"
                         width="120" />
                </a>
                HQ
            </h1>
            <h3 *ngIf="hasCompletedHealthCheck">to analyse your health check results.</h3>
            <h4 *ngIf="highlightFreeTrial"><span class="highlight"><strong>Try free for 14-days.</strong></span></h4>
            <h4 *ngIf="!highlightFreeTrial">A business that goes beyond you starts here.</h4>
        </div>

        <div class="mt-4 d-flex flex-column align-items-start">
            <dx-validation-group #validationGroup
                                 class="d-flex flex-column align-items-start w-100">
                <div class="d-flex flex-row w-100 gap-3">
                    <div class="w-100">
                        <label class="required">First name</label>
                        <dx-text-box (valueChange)="signupData.firstName = $event; validateGroup()"
                                     [disabled]="isProcessing"
                                     data-test="signup-firstName"
                                     width="100%">
                            <dx-validator>
                                <dxi-validation-rule type="required"
                                                     message="First Name is required"></dxi-validation-rule>
                            </dx-validator>
                        </dx-text-box>
                    </div>

                    <div class="w-100">
                        <label class="required">Last name</label>
                        <dx-text-box (valueChange)="signupData.lastName = $event; validateGroup()"
                                     [disabled]="isProcessing"
                                     data-test="signup-lastName"
                                     width="100%">
                            <dx-validator>
                                <dxi-validation-rule type="required"
                                                     message="Last Name is required"></dxi-validation-rule>
                            </dx-validator>
                        </dx-text-box>
                    </div>
                </div>

                <label class="mt-3 required">Email</label>
                <dx-text-box (valueChange)="signupData.emailAddress = $event; validateGroup()"
                             mode="email"
                             [disabled]="isProcessing"
                             data-test="signup-email"
                             width="100%">
                    <dx-validator>
                        <dxi-validation-rule type="email"></dxi-validation-rule>
                        <dxi-validation-rule type="required"
                                             message="Email is required"></dxi-validation-rule>
                    </dx-validator>
                </dx-text-box>

                <label class="mt-3 required">Set a password</label>
                <dx-text-box width="100%"
                             mode="password"
                             data-test="signup-password"
                             placeholder="At least 6 characters"
                             [disabled]="isProcessing"
                             (valueChange)="signupData.password = $event; validateGroup()">
                    <dx-validator>
                        <dxi-validation-rule type="stringLength"
                                             [min]="6"
                                             message="Password should contain at least 6 characters"></dxi-validation-rule>
                    </dx-validator>
                </dx-text-box>

                <label class="mt-3 required">Company name</label>
                <dx-text-box width="100%"
                             [disabled]="isProcessing"
                             (valueChange)="signupData.companyName = $event; validateGroup()"
                             data-test="signup-companyName">
                    <dx-validator>
                        <dxi-validation-rule type="required"
                                             message="Company name is required"></dxi-validation-rule>
                    </dx-validator>
                </dx-text-box>
            </dx-validation-group>

            <button class="mt-4 w-100"
                    adaptButton="primary"
                    [disabled]="!canSignup"
                    [adaptBlockingClick]="signup"
                    data-test="signup-button"
                    [unsubscribeOnDestroy]="false">Get started</button>

            <div class="alert alert-danger mt-3"
                 *ngIf="errorMessage">{{errorMessage}}</div>
        </div>

        <div class="mt-3 text-center">
            By signing up, you agree to the
            <ng-container *ngIf="TermsConditionsPageRoute$ | async as pageRoute">
                <a [routerLink]="pageRoute.path"
                   [queryParams]="pageRoute.queryParams"
                   target="_blank">terms and conditions</a>
            </ng-container>
            <ng-container *ngIf="PrivacyPolicyPageRoute$ | async as pageRoute">
                and
                <a [routerLink]="pageRoute.path"
                   [queryParams]="pageRoute.queryParams"
                   target="_blank">privacy policy</a>
            </ng-container>.

            <div class="mt-3 fw-bold"
                 *ngIf="!hasCompletedHealthCheck">
                Already have an account? <a [routerLink]="loginPage$ | async">Click here</a>
            </div>

            <img class="image-testimonial"
                 src="/app/assets/hq-mobile-testimonial.svg"
                 alt="mobile HQ testimonial" />
        </div>
    </div>
</div>
<adapt-marketing-tracker pageName="/account/signup"></adapt-marketing-tracker>

import { Component, Input } from "@angular/core";
import { CalendarIntegrationProvider } from "@common/ADAPT.Common.Model/organisation/organisation-detail";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { EMPTY, Observable } from "rxjs";
import { OAuthService } from "../../../oauth/oauth.service";
import { ICalendarConnectComponent } from "../../calendar.interface";

@Component({
    selector: "adapt-connect-no-calendar-integration",
    templateUrl: "./connect-local-calendar-integration.component.html",
    styleUrls: ["../../calendar-integration.scss"],
})
export class ConnectLocalCalendarIntegrationComponent extends BaseComponent implements ICalendarConnectComponent {
    @Input() public showLogoutButton = false;
    @Input() public disabled = false;

    public isAuthed$: Observable<boolean>;

    public constructor(private oauthService: OAuthService) {
        super();

        this.isAuthed$ = oauthService.isAuthedWithProvider$(CalendarIntegrationProvider.Local);
    }

    @Autobind
    public login() {
        if (this.disabled) {
            return EMPTY;
        }

        return this.oauthService.login(CalendarIntegrationProvider.Local);
    }
}


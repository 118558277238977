import { Component, Injector, OnInit } from "@angular/core";
import { Meeting, MeetingStatus } from "@common/ADAPT.Common.Model/organisation/meeting";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { PersonFlag } from "@common/ADAPT.Common.Model/person/person-flag.enum";
import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { IAdaptMenuItem } from "@common/ux/menu/menu.component";
import { Breakpoint } from "@common/ux/responsive/breakpoint";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";
import { BehaviorSubject, forkJoin } from "rxjs";
import { combineLatestWith, debounceTime, switchMap } from "rxjs/operators";
import { teamKanbanPageRoute } from "../../kanban/kanban-page/kanban-page.route";
import { PersonFlagService } from "../../person/person-flag.service";
import { MeetingTabId } from "../../shell/meeting-tab-content/meeting-tab-id";
import { MeetingsService } from "../meetings.service";
import { MeetingsUiService } from "../meetings-ui.service";
import { TeamActiveMeetingTour } from "./team-active-meeting-tour";

const TeamIdRouteParam = "teamId";
const MeetingIdRouteParam = "meetingId";
const ShowDescriptionParam = "showDescription";

@Component({
    selector: "adapt-team-active-meeting-page",
    templateUrl: "./team-active-meeting-page.component.html",
    styleUrls: ["./team-active-meeting-page.component.scss"],
})
export class TeamActiveMeetingPageComponent extends BaseRoutedComponent implements OnInit {
    public readonly ViewTeamKanban = CommonTeamsAuthService.ViewTeamKanban;

    public team?: Team;
    public meeting?: Meeting;
    public updateData$ = new BehaviorSubject<void>(undefined);
    public canEditTeamMeeting = false;

    public teamWorkUrl?: string;
    public useActiveMeetingPage = false;
    public descriptionExpanded = false;
    public showDescriptionOnLoad = false;

    public activeMeetingOptions?: IAdaptMenuItem[];

    private teamId?: number;
    private meetingId?: number;

    private returnWorkflowPath?: string;

    public constructor(
        private teamsService: CommonTeamsService,
        protected meetingsService: MeetingsService,
        private meetingsUiService: MeetingsUiService,
        private responsiveService: ResponsiveService,
        private personFlagService: PersonFlagService,
        rxjsBreezeService: RxjsBreezeService,
        injector: Injector,
    ) {
        super(injector);

        // redirect from this page when meeting deleted
        rxjsBreezeService.entityTypeDetached(Meeting).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((meeting) => {
            if (meeting.meetingId === this.meetingId && meeting.teamId === this.teamId) {
                this.meetingsUiService.useActiveMeetingPage(AdaptClientConfiguration.AdaptProject === AdaptProject.Alto);
                this.meetingsService.gotoMeetingPage(meeting);
            }
        });

        rxjsBreezeService.entityTypeChanged(Meeting).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((meeting) => {
            if (meeting.meetingId === this.meetingId && meeting.teamId === this.teamId) {
                if (meeting.status !== MeetingStatus.InProgress) {
                    // meeting ended, redirect to meetings page
                    // reset useActiveMeetingPage state as well
                    this.meetingsUiService.useActiveMeetingPage(AdaptClientConfiguration.AdaptProject === AdaptProject.Alto);
                    if (!this.returnWorkflowPath) {
                        this.meetingsService.gotoMeetingPage(meeting);
                    }
                    return;
                }
                this.updateData$.next();
            }
        });
    }

    public get isLgBreakpoint() {
        return this.responsiveService.currentBreakpoint.is(Breakpoint.LG);
    }

    public async ngOnInit() {
        await this.updateFromPageParams();
        this.returnWorkflowPath = this.getSearchParameterValue("returnWorkflowPath");
        this.navigationEnd.subscribe(() => this.updateFromPageParams());

        this.meetingsService.meetingIdChange$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            this.updateData$.next();
        });

        if (AdaptClientConfiguration.AdaptProjectName === AdaptProject.Alto) {
            await this.personFlagService.setFlagAndRunTour(PersonFlag.RanActiveMeetingTour, TeamActiveMeetingTour);
        }

        this.updateData$.pipe(
            debounceTime(10), // limit number of refresh from consecutive updates
            switchMap(() => forkJoin([
                this.teamsService.getTeamById(this.teamId!),
                this.meetingsService.getMeetingById(this.meetingId!),
                teamKanbanPageRoute.getRoute({ teamId: this.teamId! }),
            ])),
            this.takeUntilDestroyed(),
        ).subscribe(([team, meeting, teamWorkUrl]) => {
            this.team = team;
            this.meeting = meeting;
            this.canEditTeamMeeting = this.meetingsService.canEditMeetingForTeam(team!);
            this.teamWorkUrl = teamWorkUrl;
            this.activeMeetingOptions = this.getActiveMeetingOptions();

            if (!meeting || meeting.status !== MeetingStatus.InProgress) {
                if (this.team) {
                    return this.meetingsService.gotoTeamMeetingsPage(this.team.teamId).subscribe();
                }
            }

            this.notifyActivated();
        });

        this.meetingsUiService.isUsingActiveMeetingPage$.pipe(
            combineLatestWith(this.shellUiService.tabIsEnabled(MeetingTabId)),
            this.takeUntilDestroyed(),
        ).subscribe(([useActiveMeetingPage, tabIsEnabled]) => {
            this.useActiveMeetingPage = tabIsEnabled && useActiveMeetingPage;
        });
    }

    @Autobind
    public endMeeting(meeting: Meeting) {
        return this.meetingsUiService.endMeeting(meeting, this.returnWorkflowPath);
    }

    private getActiveMeetingOptions(): IAdaptMenuItem[] {
        const menuItems: IAdaptMenuItem[] = [];

        if (this.canEditTeamMeeting) {
            menuItems.push({
                text: "End meeting",
                icon: "fal fa-fw fa-stop-circle",
                onClick: () => this.endMeeting(this.meeting!).subscribe(),
            });
        }

        if (this.teamWorkUrl && !this.isAlto) {
            menuItems.push({
                text: "View team actions",
                icon: "fal fa-fw fa-columns",
                onClick: () => teamKanbanPageRoute.gotoRoute({ teamId: this.teamId! }).subscribe(),
            });
        }

        return menuItems;
    }

    private async updateFromPageParams() {
        this.teamId = this.getRouteParamInt(TeamIdRouteParam);
        if (!this.teamId) {
            return this.handleUnauthorisedAccess();
        }

        this.meetingId = this.getRouteParamInt(MeetingIdRouteParam);
        if (!this.meetingId) {
            return this.handleUnauthorisedAccess();
        }
        this.descriptionExpanded = this.getSearchParameterBoolValue(ShowDescriptionParam);
        this.showDescriptionOnLoad = this.getSearchParameterBoolValue(ShowDescriptionParam);

        await this.setSearchParameterValue(ShowDescriptionParam, undefined);

        setTimeout(() => this.showDescriptionOnLoad = false, 3000);

        this.verifyHasAccessToRoute(this.meetingsService.canViewTeamMeetings(this.teamId));

        this.updateData$.next();
    }
}

import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Survey } from "@common/ADAPT.Common.Model/organisation/survey";
import { SurveyQuestionResponse } from "@common/ADAPT.Common.Model/organisation/survey-question-response";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ChartUtils } from "@common/ux/base-ui.service/chart-utils";
import { IDxChartTooltipPointInfo } from "@common/ux/dx.types";
import { DoneEvent } from "devextreme/viz/chart";
import { ReplaySubject } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { SurveyService } from "../survey.service";
import { ISurveyQuestions } from "../survey-questions.interface";

interface IChartData {
    score: number;
    label: string;
    count: number;
}

@Component({
    selector: "adapt-display-question-response-distribution",
    templateUrl: "./display-question-response-distribution.component.html",
})
export class DisplayQuestionResponseDistributionComponent extends BaseComponent implements OnInit, OnChanges {
    private survey$ = new ReplaySubject<Survey>(1);
    @Input() public set survey(survey: Survey) {
        this.survey$.next(survey);
    }
    public chartData: IChartData[] = [];

    @Input() public maxResponseCount = 0;
    @Output() public maxResponseCountChange = new EventEmitter<number>();

    @Input() public questionId?: number;
    @Input() public surveyQuestions!: ISurveyQuestions;

    public constructor(
        elementRef: ElementRef,
        private surveyService: SurveyService,
    ) {
        super(elementRef);
    }

    public ngOnInit() {
        if (!this.questionId) {
            throw new AdaptError("Required @Input questionId is undefined");
        }

        this.survey$.pipe(
            switchMap((survey) => this.surveyService.getSurveyQuestionResponses(survey, this.questionId!)),
            tap((responses) => this.formChartData(responses)),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.maxResponseCount && !changes.survey) { // if survey is changed, formChartData will call this update
            // survey not change -> other side have changed it or survey selection changed
            // - update this for max reset from survey selection
            this.updateMax();
        }
    }

    public updateChartDimensionAndSubscribeSizeChange(e: DoneEvent) {
        ChartUtils.updateChartDimension(e);
        this.sizeChange$.subscribe(() => ChartUtils.updateChartDimension(e));
    }

    @Autobind
    public customiseChartTooltip(info: IDxChartTooltipPointInfo) {
        return {
            text: `${info.point.data.count} responded with ${this.surveyQuestions.surveyResponseChoices[info.point.data.score - 1].text}`,
        };
    }

    private formChartData(responses: SurveyQuestionResponse[]) {
        this.chartData = [];
        for (const responseChoice of this.surveyQuestions.surveyResponseChoices) {
            const responseCount = responses.filter((response) => response.response === responseChoice.value).length;
            this.chartData.push({
                score: responseChoice.value,
                label: responseChoice.text,
                count: responseCount,
            });
        }

        // data source change should call refresh or the axis will be reset to not use any defined value from dxi-value-axis
        this.updateMax();
    }

    private updateMax() {
        // need checking of max count in refresh to update max if we reset counts from survey selection change
        let maxResponseCount = 0;
        this.chartData.forEach((i) => {
            if (i.count > maxResponseCount) {
                maxResponseCount = i.count;
            }
        });

        if (!this.maxResponseCount || maxResponseCount > this.maxResponseCount) {
            this.maxResponseCount = maxResponseCount;
            this.maxResponseCountChange.emit(maxResponseCount);
        }
    }
}

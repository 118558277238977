import { AfterContentInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { Layout } from "@common/ADAPT.Common.Model/organisation/layout";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { LocalStorage } from "@common/lib/storage/local-storage";
import { RouteEventsService } from "@common/route/route-events.service";
import { ShellUiService } from "@common/shell/shell-ui.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ChangeAction, ChangeManagerService } from "@common/ux/change-manager/change-manager.service";
import isEqual from "lodash.isequal";
import { BehaviorSubject, EMPTY, from, Observable, of, Subject, timer } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { EntityWithLayout, ILayout, ILayoutBase, ILayoutBaseColumn, ILayoutColumn, ILayoutComponent, ILayoutUpdateListener, TComponentFromLayout } from "../layout.interface";
import { LayoutService } from "../layout.service";

@Component({
    selector: "adapt-layout-manager",
    templateUrl: "./layout-manager.component.html",
    styleUrls: ["./layout-manager.component.scss"],
})
export class LayoutManagerComponent extends BaseComponent implements AfterContentInit, OnChanges {
    // fired once whole layout has been built and restored
    @Output() public initialised = new EventEmitter<void>();

    // key that will be used for serialisation. only used for localStorage right now
    @Input() public page?: string;

    private _entity?: EntityWithLayout;

    @Input()
    public set entity(entity: EntityWithLayout | undefined) {
        const firstSet = this._entity === undefined;
        this._entity = entity;

        if (!firstSet) {
            throw new AdaptError("LayoutManager does not support entity changing, try recreate the layout manager instead");
        }
    }

    // entity that has layout property
    public get entity() {
        return this._entity;
    }

    // if the current user has permission to edit the layout
    @Input() public allowEditing = false;

    // if the layout should take the full page
    @Input() public fullPage = false;

    // disables adding the controls to the toolbar
    @Input() public nested = false;

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("ngClass") public hostClasses: { [name: string]: boolean } = {};

    @Input() public innerClass = "";

    @Input() public showEditLayoutButton = true;

    // the current layout
    public layout: ILayout = {
        columns: [],
        meta: {
            version: "1",
        },
    };

    // serialisable version of current layout
    public currentLayout!: ILayoutBase;

    // if the layout is currently being edited
    private _isEditing$ = new BehaviorSubject<boolean>(false);
    public isEditing$ = this._isEditing$.asObservable();

    // any columns which are added due to not already existing in DOM
    public dynamicColumns: (string | undefined)[] = [];

    // store default layout (from DOM) so we can revert back to it
    private defaultLayout!: ILayoutBase;

    // the default layout, with the component enabled state.
    // need to store this as we need to refer to original enable state when resetting/cancelling changes
    private defaultLayoutWithEnabled!: ILayoutBase;

    // store saved layout (from storage) so we can revert back to it
    private savedLayout?: ILayoutBase;

    // lets manager notify column/component that the layout has finished updating (for updating menus, etc)
    private _layoutUpdated$ = new Subject<void>();
    public layoutUpdated$ = this._layoutUpdated$.asObservable();

    // lets column/component notify the manager that we want to update the layout (mostly size changes)
    private updateLayout$ = new Subject<void>();

    // store the layout entity in-between deletes as we need to delete
    // in multiple steps and won't have access to layout
    private layoutEntity: Layout | null = null;
    private pendingEntities = new Set([] as IBreezeEntity[]);

    // components which have registered themselves with the layout manager but have not been added to a column yet
    private pendingComponents: ILayoutComponent[] = [];

    public constructor(
        protected elementRef: ElementRef<HTMLElement>,
        private layoutService: LayoutService,
        private shellUiService: ShellUiService,
        private changeManager: ChangeManagerService,
        routeEventsService: RouteEventsService,
    ) {
        super(elementRef);

        this.updateLayout$.pipe(
            switchMap(() => this.updateLayoutHistory()),
            this.takeUntilDestroyed(),
        ).subscribe();

        routeEventsService.navigationEnd$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.ngOnChanges());
    }

    /**
     * Key to use for column sortable groups.
     */
    public get groupKey() {
        if (this.page) return this.page;

        const name = this.entity?.entityType.name;
        const id = this.entity?.entityAspect.getKey().values.join(",");
        return `${name}-${id}`;
    }

    /**
     * If the layout is being edited.
     */
    public get isEditing() {
        return this._isEditing$.value;
    }

    /**
     * If the currently active layout matches the default layout.
     */
    public get isLayoutDefault() {
        return isEqual(this.defaultLayout, this.currentLayout);
    }

    /**
     * If the currently active layout matches the initially loaded layout.
     */
    public get isLayoutUnchanged() {
        // savedLayout is undefined when not saved, so need to mark unchanged when layout is default and no saved layout
        return this.savedLayout
            ? isEqual(this.savedLayout, this.currentLayout)
            : this.isLayoutDefault;
    }

    /**
     * Runs after all the layout content has initialised.
     */
    public ngAfterContentInit() {
        this.log.debug("Initialising the layout manager");

        // needs to run next digest for the DOM to be available.
        // - need additional delay to allow hide-if-empty to get evaluated first next digest cycle
        timer(100).pipe(
            switchMap(() => {
                // save the default layout before we restore anything so we can snap back to it later
                this.defaultLayout = this.cloneLayout();
                this.defaultLayoutWithEnabled = this.cloneLayout(true);
                this.savedLayout = this.getLayoutFromStorage();

                return this.restorePersistedLayout();
            }),
            tap(() => this.initialised.emit()),
        ).subscribe();
    }

    /**
     * Resets the layout to the last saved layout.
     */
    @Autobind
    public resetToSavedLayout() {
        const reconcile = this.savedLayout
            ? this.reconcileLayout(this.savedLayout)
            : of(void 0);

        return reconcile.pipe(
            switchMap(() => this.updateLayoutHistory()),
            switchMap(() => {
                if (this.savedLayout && !isEqual(this.savedLayout, this.currentLayout)) {
                    this.log.debug("Saved layout is different from current layout after reset, force save");
                    return this.saveLayout();
                }

                this.notifyLayoutUpdated();
                return of(void 0);
            }),
        );
    }

    /**
     * Resets the layout to the default layout.
     */
    @Autobind
    public resetToDefaultLayout() {
        return this.reconcileAndUpdateLayout(this.defaultLayoutWithEnabled);
    }

    /**
     * Handles toggling of the Edit Layout button.
     */
    @Autobind
    public toggleEditing(editing: boolean) {
        let action$ = of<void>(void 0);

        if (!editing) {
            const originalAction = action$ = this.savedLayout
                ? this.resetToSavedLayout()
                : this.resetToDefaultLayout();

            if (!this.isLayoutUnchanged) {
                action$ = from(this.changeManager.checkForChangesAndPrompt()).pipe(
                    switchMap((changeAction) => {
                        this.changeManager.blockRouteOnUnsavedChanges();
                        if (changeAction === ChangeAction.DiscardAndContinue ||
                            // this happens if entity sync updated entity and it becomes unchanged
                            // -> needed this so that we can cancel edit or else it will get stuck in editting mode
                            changeAction === ChangeAction.Continue) {
                            return originalAction;
                        } else if (changeAction === ChangeAction.SaveAndContinue) {
                            return this.saveLayout();
                        }
                        return EMPTY;
                    }),
                );
            }
        }

        return action$.pipe(
            tap(() => this._isEditing$.next(editing)),
        );
    }

    /**
     * Saves the current layout to storage.
     */
    public saveLayout() {
        return this.saveLayoutToStorage(this.currentLayout!).pipe(
            switchMap(() => this.updateLayoutHistory()),
        );
    }

    /**
     * Gets the requested column from the layout.
     * Adds the column to the layout if it does not exist already.
     * @param ref LayoutColumnComponent instance
     * @param element HTML element of the column
     * @param defaultSize size to use if column does not exist already
     */
    public getColumn(ref: ILayoutUpdateListener<ILayoutColumn>, element: HTMLElement, defaultSize?: string) {
        const col = this.layoutService.createColumn<ILayoutColumn>({ element, ref });
        LayoutService.setSize(col, defaultSize);
        this.layout.columns.push(col);
        return col;
    }

    /**
     * Removes a column from the current layout.
     * @param column column to remove
     */
    public removeColumn(column: ILayoutColumn) {
        this.log.debug("Removing column");

        const foundColumn = this.layout.columns.indexOf(column);
        if (foundColumn > -1) {
            this.layout.columns.splice(foundColumn, 1);
        }
    }

    /**
     * Gets the requested component from the layout.
     * Returns a placeholder component if requested does not exist in layout yet.
     *
     * Does not add the component by default, as we don't have access to the column at this point.
     * @param componentId UNIQUE ID of the component
     * @param ref LayoutComponentComponent instance
     * @param element HTML element of the component
     * @param defaultSize size to use if component does not exist already
     */
    public getComponent(componentId: string, ref: ILayoutUpdateListener<ILayoutComponent>, element: HTMLElement, defaultSize?: string) {
        let { component } = LayoutService.findComponentInLayout(this.layout.columns, componentId);

        // create the component if it does not exist already
        if (!component) {
            component = this.layoutService.createComponent<ILayoutComponent>(componentId);
        }

        component.element = element;
        component.ref = ref;
        if (!component.size) {
            LayoutService.setSize(component, defaultSize);
        }

        if (!this.pendingComponents.includes(component)) {
            this.pendingComponents.push(component);
        }

        return component;
    }

    /**
     * Adds the pending components to the given column in the order they appear in the given components.
     * @param components elements for pending components
     * @param column column to add pending components to
     */
    public resolvePendingComponents(components: HTMLElement[], column: ILayoutColumn) {
        components.forEach((elem) => {
            const cmp = this.pendingComponents.find((c) => c.element === elem);
            if (cmp) {
                this.log.debug(`Adding ${cmp.id} to LayoutManager`);

                if (!column.options.components.includes(cmp)) {
                    column.options.components.push(cmp);
                }

                this.pendingComponents.splice(this.pendingComponents.indexOf(cmp), 1);
            }
        });
    }

    /**
     * Moves the given component from the given source column to the destination column, inserting at the given index.
     * @param component component to move
     * @param srcColumn column to move component from
     * @param destColumn column to move component to
     * @param index desired index for the component in the destination column
     */
    public moveComponent<T extends ILayoutBaseColumn>(component: TComponentFromLayout<T>, srcColumn: T, destColumn: T, index: number) {
        const currentIndex = srcColumn.options.components.indexOf(component);

        // check if already moved in the layout
        if (currentIndex !== -1) {
            // remove item from old layout
            srcColumn.options.components.splice(currentIndex, 1);

            // add item to new layout
            destColumn.options.components.splice(index, 0, component);
        }

        if (srcColumn.element && destColumn.element && component.element) {
            const currentElementIndex = Array.from(srcColumn.element.children).indexOf(component.element);

            // move the element
            if (index >= destColumn.element.children.length) {
                destColumn.element.appendChild(component.element);
            } else {
                // if same column and new position is greater than the current position,
                //     then insert the element after the element at the given index.
                // else insert the element before the element at the given index.
                const position: InsertPosition = srcColumn === destColumn && index > currentElementIndex ? "afterend" : "beforebegin";
                destColumn.element.children[index].insertAdjacentElement(position, component.element);
            }
        }
    }

    /**
     * Removes a component from the current layout.
     * @param component component to remove
     */
    public removeComponent(component: ILayoutComponent) {
        this.log.debug("Removing component", component);

        const {
            component: foundComponent,
            column,
        } = LayoutService.findComponentInLayout(this.layout.columns, component.id);
        if (foundComponent && column) {
            const colLayout = column.options.components;
            const componentIdx = column.options.components.indexOf(foundComponent);
            // remove component from layout fully
            colLayout.splice(componentIdx, 1);
        }
    }

    /**
     * Resizes the current layout according to the given size array.
     * Any elements in a now non-existent column will be moved to the last available column.
     * @param targetSizes array of size classes e.g. `["col-md-8", "col-md-4"]`
     */
    public resizeLayout(targetSizes: string[]) {
        const layout = this.cloneLayout(true);

        const targetColumnCount = targetSizes.length;
        const currentColumnCount = layout.columns.length;
        const enabledColumnCount = layout.columns.filter((col) => col.options.enabled).length;

        if (targetColumnCount < enabledColumnCount) {
            // less columns than previously, move everything into the last available column
            const targetColumn = layout.columns[targetColumnCount - 1].options.components;

            for (let i = targetColumnCount; i < currentColumnCount; i++) {
                const srcColumn = layout.columns[i];

                // move every component from the overflowed column to the last available column
                const overflowedLayout = srcColumn.options.components;
                while (overflowedLayout.length !== 0) {
                    const component = overflowedLayout.shift()!;
                    targetColumn.push(component);
                }

                // hide the overflowed column from the layout
                LayoutService.setOptions(srcColumn, { enabled: false, manuallyEnabled: false });
            }
        } else if (targetColumnCount > enabledColumnCount) {
            // more columns than previously, enable any disabled columns
            targetSizes.forEach((size, columnIndex) => {
                if (columnIndex < currentColumnCount) {
                    // we have existing columns we can enable
                    LayoutService.setOptions(layout.columns[columnIndex], {
                        enabled: true,
                        manuallyEnabled: true,
                    });
                } else {
                    // need to add a new column
                    const col = this.layoutService.createColumn();
                    LayoutService.setSize(col, size);
                    layout.columns.push(col);
                }
            });
        }

        targetSizes.forEach((size, columnIndex) => {
            LayoutService.setSize(layout.columns[columnIndex], size);
        });

        return this.reconcileAndUpdateLayout(layout);
    }

    /**
     * Informs the layout manager we have made a change to the layout.
     * i.e. resized a component
     */
    public updateLayout() {
        this.updateLayout$.next();
    }

    /**
     * Sets the default layout for the layout manager.
     * Use when elements are added dynamically and the default layout needs to account for them.
     */
    public updateDefaultLayout() {
        this.defaultLayout = this.cloneLayout();
        this.defaultLayoutWithEnabled = this.cloneLayout(true);
        return this.updateLayoutHistory();
    }

    /**
     * Gets the layout from storage and modify the DOM to reflect the layout.
     * Saves the default layout to storage if does not exist already.
     * @private
     */
    public restorePersistedLayout() {
        if (this.savedLayout) {
            // layout found in storage, apply it
            return this.reconcileLayout(this.savedLayout).pipe(
                switchMap(() => this.updateLayoutHistory(this.savedLayout)),
            );
        }

        return this.updateLayoutHistory();
    }

    /**
     * Adds classes to the manager component as required.
     */
    public ngOnChanges() {
        // add classes to the host element
        this.hostClasses = {
            ...this.hostClasses,
            "row": true,
            "mt-2": this.isEditing,
            "h-100": !this.isEditing,
        };

        if (this.fullPage) {
            this.shellUiService.removeDefaultShellPadding();
        }
    }

    /**
     * Notifies that the layout has finished updating.
     * @private
     */
    private notifyLayoutUpdated() {
        this._layoutUpdated$.next();
    }

    /**
     * Gets the unique key for this layout, for storage and retrieval.
     * @private
     */
    private getStorageKey() {
        if (this.page) {
            return `layout-${this.page}`;
        }
        return null;
    }

    /**
     * Gets the layout from storage.
     * @private
     */
    private getLayoutFromStorage() {
        return this.entity
            ? this.layoutService.loadLayout(this.entity)
            : this.getLayoutFromLocalStorage();
    }

    /**
     * Saves the given layout to storage.
     * @param layout layout to save
     * @private
     */
    private saveLayoutToStorage(layout: ILayoutBase) {
        return this.entity
            ? this.saveLayoutToEntity(layout)
            : this.saveLayoutToLocalStorage(layout);
    }

    /**
     * Saves the given layout to the entity.
     * @param layout layout to save to entity
     * @private
     */
    private saveLayoutToEntity(layout: ILayoutBase) {
        const pending = Array.from(this.pendingEntities);
        this.log.debug("Saving layout entities", pending);

        return this.layoutService.saveEntities(pending).pipe(
            switchMap(() => {
                if (this.entity!.layout === null) {
                    // need to delete the layout entity now...
                    if (this.layoutEntity) {
                        return this.layoutService.remove(this.layoutEntity).pipe(
                            switchMap(() => this.layoutService.saveEntities([this.layoutEntity!])),
                            tap(() => this.layoutEntity = null),
                        );
                    }
                }
                return of(void 0);
            }),
            switchMap(() => this.clearPendingEntities()),
            tap(() => this.savedLayout = layout),
        );
    }

    /**
     * Gets the layout from local storage.
     * @private
     */
    private getLayoutFromLocalStorage() {
        const localStorageName = this.getStorageKey();
        if (localStorageName) {
            return LocalStorage.get<ILayoutBase>(localStorageName);
        }
        return undefined;
    }

    /**
     * Saves the layout to local storage.
     * @param layout layout to save to local storage
     * @private
     */
    private saveLayoutToLocalStorage(layout: ILayoutBase) {
        const localStorageName = this.getStorageKey();
        if (localStorageName) {
            this.log.info("Saving layout to local storage", layout);
            LocalStorage.set(localStorageName, layout);
            this.savedLayout = layout;
        }
        return of(void 0);
    }

    /**
     * Adds a new layout to the layout history for undo/redo/cancel purposes.
     * @param layout layout to add to history
     * @private
     */
    private updateLayoutHistory(layout?: ILayoutBase): Observable<void> {
        if (!layout) {
            layout = this.cloneLayout();
        }

        let update$ = of<void>(void 0);

        if (!isEqual(layout, this.currentLayout)) {
            this.currentLayout = layout;

            // write layout entities on changes so that change manager kicks in
            if (this.entity) {
                update$ = this.clearPendingEntities();

                if (!this.isLayoutUnchanged) {
                    update$ = update$.pipe(
                        switchMap(() => this.updateEntities()),
                        tap((entities) => {
                            if (entities) {
                                this.pendingEntities = new Set([...this.pendingEntities, ...entities]);
                            }
                            this.log.debug("Pending entities", this.pendingEntities);
                        }),
                        map(() => void 0),
                    );
                }
            }
        }

        return update$.pipe(
            tap(() => this.notifyLayoutUpdated()),
        );
    }

    /**
     * Updates entities which require changes.
     * @private
     */
    private updateEntities(): Observable<(EntityWithLayout | Layout)[]> {
        if (this.entity) {
            if (!this.isLayoutDefault) {
                return this.layoutService.saveLayout(this.entity, this.currentLayout);
            }

            // delete the layout entities if setting to default
            if (this.savedLayout) {
                this.layoutEntity = this.entity.layout;
                this.entity.layout = null;
                return of([this.entity]);
            }
        }

        return of([] as any);
    }

    /**
     * Removes all pending entities.
     * @private
     */
    private clearPendingEntities() {
        const entities = Array.from(this.pendingEntities);
        if (this.entity) {
            entities.push(this.entity);
        }
        return this.layoutService.rejectChanges(entities).pipe(
            tap(() => this.pendingEntities.clear()),
        );
    }

    /**
     * Transforms the current layout into a storage-suitable object.
     * Strips all ref and element from the columns/components.
     * @param withComponentsEnabled whether to include enabled option in components
     * @private
     */
    public cloneLayout(withComponentsEnabled = false): ILayoutBase {
        const meta = Object.assign({}, this.layout.meta);
        return Object.assign({}, this.layout, {
            columns: this.layout.columns.map((col) => LayoutService.cloneColumn(col, withComponentsEnabled)),
            meta,
        });
    }

    /**
     * Mutates the current layout to reflect the given desired layout, then fire a layout update.
     * @param desiredLayout layout to transform active layout to
     */
    public reconcileAndUpdateLayout(desiredLayout: ILayoutBase) {
        return this.reconcileLayout(desiredLayout).pipe(
            switchMap(() => this.updateLayoutHistory()),
        );
    }

    /**
     * Mutates the current layout to reflect the given desired layout.
     * @param desiredLayout layout to transform active layout to
     * @private
     */
    private reconcileLayout(desiredLayout: ILayoutBase): Observable<void> {
        if (isEqual(this.currentLayout, desiredLayout)) {
            this.log.debug("Reconciliation skipped, no changes required");
            // don't return EMPTY as we still want to follow the chain of events
            return of(void 0);
        }

        // flat array of all elements
        const allDesiredComponents = desiredLayout.columns.flatMap((col) => col.options.components.map((cmp) => cmp.id));
        const withoutDuplicates = Array.from(new Set(allDesiredComponents));
        if (!isEqual(allDesiredComponents, withoutDuplicates)) {
            this.log.error("Reconciliation skipped, duplicate elements present in the desired layout");
            return EMPTY;
        }

        desiredLayout.columns.forEach((desiredCol, desiredColIdx) => {
            if (desiredColIdx >= this.layout.columns.length) {
                // add the dynamic column
                this.dynamicColumns.push(desiredCol.size);
            }
        });

        // needs to run next digest so dynamic columns are available
        return timer(0).pipe(
            switchMap(() => {
                this.log.debug("Reconciling layout");

                desiredLayout.columns.forEach((desiredCol, desiredColIdx) => {
                    const currentCol = this.layout.columns[desiredColIdx];

                    if (currentCol) {
                        desiredCol.options.components.forEach((desiredElem, desiredElemIdx) => {
                            const {
                                component,
                                column,
                            } = LayoutService.findComponentInLayout(this.layout.columns, desiredElem.id);
                            if (component && column) {
                                this.moveComponent(component, column, currentCol, desiredElemIdx);
                                LayoutService.setSize(component, desiredElem.size);
                                component.options = { enabled: component.options?.enabled, ...desiredElem.options };
                                component.ref.onUpdate(component);
                            }
                        });

                        LayoutService.setSize(currentCol, desiredCol.size);
                        currentCol.options = { ...desiredCol.options, components: currentCol.options.components };
                        currentCol.ref.onUpdate(currentCol);
                    }
                });

                // delete all dynamic columns that are not needed anymore
                // we do this after moving the components, else they will be deleted
                for (let i = 0; i < (this.layout.columns.length - desiredLayout.columns.length); i++) {
                    this.dynamicColumns.splice(-1, 1);
                }

                // needs to run next digest so dynamic columns have been updated
                return timer(0);
            }),
            switchMap(() => of(void 0)),
        );
    }
}

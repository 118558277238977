import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Location } from "./location";
import { Role } from "./role";

export class RoleLocation extends BaseEntity<RoleLocation> {
    public roleLocationId!: number;
    public roleId!: number;
    public locationId!: number;
    public isSystemRoleLocation!: boolean;
    public location!: Location;
    public role!: Role;
}

export const RoleLocationBreezeModel = new BreezeModelBuilder("RoleLocation", RoleLocation)
    .hasSource()
    .alwaysFetchingNavigationProperty("location")
    .isOrganisationEntity()
    .build();

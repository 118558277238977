<adapt-quadrant-view *ngFor="let quadrant of Quadrants"
                     [quadrant]="quadrant"
                     [corner]="QuadrantCorners[quadrant]"
                     [hideOuter]="hideOuter"
                     [isEditing]="isEditing"
                     [isSelecting]="isSelecting"
                     (hasBullseyeStatementChange)="onQuadrantBullseyeChanged(quadrant, $event)"
                     [hasBullseyeStatement]="hasBullseyeStatement"
                     (bullseyeStatementChange)="bullseyeStatementChange.emit($event)"
                     [selectedStatements]="selectedStatements"
                     (statementSelect)="statementSelect.emit($event)"
                     (statementDeselect)="statementDeselect.emit($event)"
                     class="quadrant"></adapt-quadrant-view>
<i *ngIf="showZoom"
   class="zoom-button far"
   [ngClass]="{'fa-magnifying-glass-minus': hideOuter, 'fa-magnifying-glass-plus': !hideOuter}"
   adaptTooltip="Zoom in/out of bullseye"
   (click)="toggleZoom()"
   data-tour="bullseye-zoom-button"></i>

import { Component, Input } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { ExternalDashboard } from "@common/ADAPT.Common.Model/organisation/external-dashboard";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { IDxListItemReorderedEvent } from "@common/ux/dx.types";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { EditExternalDashboardDialogComponent } from "@org-common/lib/external-dashboard/edit-external-dashboard-dialog/edit-external-dashboard-dialog.component";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { switchMap } from "rxjs";
import { tap } from "rxjs/operators";

@Component({
    selector: "adapt-configure-external-dashboard",
    templateUrl: "./configure-external-dashboard.component.html",
    styleUrls: ["./configure-external-dashboard.component.scss"],
})
export class ConfigureExternalDashboardComponent extends ConfigureFeatureBase {
    public readonly productName = AdaptClientConfiguration.AdaptProjectLabel;

    @Input() public team?: Team;
    @Input() public configItem?: IConfigItem;

    public featureIsEnabledAndSaved = false;
    public externalDashboards: ExternalDashboard[] = [];

    public constructor(
        configurationService: ConfigurationService,
        private dialogService: AdaptCommonDialogService,
        private commonDataService: CommonDataService,
        private orgService: OrganisationService,
        private featuresService: FeaturesService,
    ) {
        super(configurationService);
    }

    public async initialiseData() {
        this.externalDashboards = await this.orgService.promiseToGetExternalDashboards(this.team?.teamId);
        this.featureIsEnabledAndSaved = this.featuresService.checkIfFeatureActiveAndSaved(FeatureName.OrganisationExternalDashboard, this.team);
    }

    // called when the config Save button is clicked
    public async onSave() {
        await this.initialiseData();
        return super.onSave();
    }

    @Autobind
    public onItemReordered(e: IDxListItemReorderedEvent<ExternalDashboard>) {
        SortUtilities.reorderItemInIntegerSortedArray(this.externalDashboards, "ordinal", e.fromIndex, e.toIndex);
    }

    @Autobind
    public addExternalDashboard() {
        const data: Partial<ExternalDashboard> = {
            // If organisation external link, it will be checked against public read anyway
            // - just set the flag there to be more explicit when inspecting entity.
            isPublicRead: !this.team,
            team: this.team,
            ordinal: this.externalDashboards.length,
        };

        return this.orgService.createExternalDashboard(data).pipe(
            switchMap((externalDashboard) => this.editExternalDashboard(externalDashboard)),
        );
    }

    @Autobind
    public editExternalDashboard(externalDashboard: ExternalDashboard) {
        return this.dialogService.open(EditExternalDashboardDialogComponent, externalDashboard).pipe(
            switchMap(() => this.initialiseData()),
        );
    }

    @Autobind
    public deleteExternalDashboard(externalDashboard: ExternalDashboard) {
        const confirmationDialogData: IConfirmationDialogData = {
            title: `Delete external link`,
            message: `Are you sure you wish to delete the <b>${externalDashboard.name}</b> external link?`,
            confirmButtonText: "Delete",
        };
        return this.dialogService.openConfirmationDialog(confirmationDialogData).pipe(
            switchMap(() => this.commonDataService.remove(externalDashboard)),
            tap(() => {
                ArrayUtilities.removeElementFromArray(externalDashboard, this.externalDashboards);
                SortUtilities.updateIntegerSortedArrayAfterItemRemoval(this.externalDashboards, "ordinal", externalDashboard.ordinal);
            }),
            switchMap(() => this.commonDataService.saveEntities([...this.externalDashboards, externalDashboard])),
            switchMap(() => this.initialiseData()),
        );
    }
}

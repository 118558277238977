import { DOCUMENT } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { Component, Inject, Input } from "@angular/core";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { ServiceUri } from "@common/configuration/service-uri";
import { ExternalLoginProviders, IExternalLoginProvider } from "@common/identity/external-login/external-login-provider.interface";
import { IExternalLoginProviderStatus } from "@common/identity/external-login/external-login-status.interface";
import { IdentityService } from "@common/identity/identity.service";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { UrlUtilities } from "@common/lib/utilities/url-utilities";
import { RouteEventsService } from "@common/route/route-events.service";
import { UserService } from "@common/user/user.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { switchMap } from "rxjs";
import { catchError } from "rxjs/operators";
import { ConfigureFeatureBase } from "../../base-components/configure-feature-base";
import { IConfigItem } from "../../configuration.interfaces";
import { ConfigurationService } from "../../configuration.service";

@Component({
    selector: "adapt-configure-personal-single-sign-on",
    templateUrl: "./configure-personal-single-sign-on.component.html",
    styleUrl: "./configure-personal-single-sign-on.component.scss",
})
export class ConfigurePersonalSingleSignOnComponent extends ConfigureFeatureBase {
    @Input() public configItem?: IConfigItem;

    public readonly ExternalLoginProviders = Object.fromEntries(Object.entries(ExternalLoginProviders)
        .filter(([_, v]) => v.enabled));
    public readonly projectLabel = AdaptClientConfiguration.AdaptProjectLabel;

    public profileUrl?: string;

    public hasPassword = true;
    public configuredProviders?: IExternalLoginProviderStatus[];
    public otherProviders?: IExternalLoginProviderStatus[];

    public errorMessage?: string;

    public constructor(
        configurationService: ConfigurationService,
        private httpClient: HttpClient,
        private identityService: IdentityService,
        private dialogService: AdaptCommonDialogService,
        private userService: UserService,
        private directorySharedService: DirectorySharedService,
        private routeEventsService: RouteEventsService,
        @Inject(DOCUMENT) private document: Document,
    ) {
        super(configurationService);

        // errors can be passed in from login-external-page
        this.routeEventsService.userNavigationError$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((errorMessage) => {
            this.errorMessage = errorMessage;
        });
    }

    public get canRemoveProvider() {
        return this.hasPassword || (this.configuredProviders?.length ?? 0) > 1;
    }

    @Autobind
    public async initialiseData() {
        const person = await this.userService.getCurrentPerson();
        this.profileUrl = await this.directorySharedService.promiseToGetProfileUrl(person!);

        const result = await this.identityService.getExternalLoginStatus();
        if (result.body) {
            const { providers, hasPassword } = result.body;

            const [configuredProviders, otherProviders] = ArrayUtilities.partition(providers, (l) => l.linked);
            this.configuredProviders = configuredProviders;
            this.otherProviders = otherProviders;

            this.hasPassword = hasPassword;
        }
    }

    @Autobind
    public async signInProvider(provider: IExternalLoginProvider) {
        // remove the origin from the href to get the path/query/hash
        // we want to redirect the user back to this config page after logging in with the external login
        const pathWithQuery = this.document.location.href.replace(this.document.location.origin, "");
        const signInUrl = UrlUtilities.setQueryParam(provider.signInUrl, "returnUrl", pathWithQuery);
        this.document.location.assign(signInUrl);
    }

    @Autobind
    public removeProvider(provider: IExternalLoginProvider) {
        return this.dialogService.openConfirmationDialog({
            title: "Remove access",
            message: `<p>If you remove access, logging in using your <b>${provider.providerDisplayName}</b> account will no longer sign you into ${this.projectLabel}.</p>
                      <p>You can link another <b>${provider.providerDisplayName}</b> account again at any time.</p>`,
            confirmButtonText: "Remove",
        }).pipe(
            switchMap(() => this.httpClient.post(`${ServiceUri.AccountServiceUri}/UnlinkExternalLogin`, null, {
                params: { provider: provider.provider },
            })),
            switchMap(() => this.initialiseData()),
            catchError((err) => this.dialogService.showMessageDialog("Failed to remove access", ErrorHandlingUtilities.getHttpResponseMessage(err))),
        );
    }
}

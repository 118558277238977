<ng-container *ngIf="type === SearchType.Page">
    <adapt-search-row-page *ngFor="let result of searchResults.Page"
                           [result]="result"></adapt-search-row-page>
</ng-container>
<ng-container *ngIf="type === SearchType.PersonTeamRole">
    <ng-container *ngFor="let result of searchResults.PersonTeamRole">
        <adapt-search-row-person *ngIf="SearchService.isPerson(result) as person"
                                 [result]="person"></adapt-search-row-person>
        <adapt-search-row-team *ngIf="SearchService.isTeam(result) as team"
                               [result]="team"></adapt-search-row-team>
    </ng-container>
</ng-container>
<ng-container *ngIf="type === SearchType.Kanban">
    <adapt-search-row-kanban *ngFor="let result of searchResults.Kanban"
                             [result]="result"></adapt-search-row-kanban>
</ng-container>
<ng-container *ngIf="type === SearchType.Meeting">
    <adapt-search-row-meeting *ngFor="let result of searchResults.Meeting"
                              [result]="result"></adapt-search-row-meeting>
</ng-container>
<ng-container *ngIf="type === SearchType.Objective">
    <adapt-search-row-objective *ngFor="let result of sortedObjectiveResults"
                                [result]="result"></adapt-search-row-objective>
</ng-container>
<ng-container *ngIf="type === SearchType.Guidance">
    <ng-container *ngFor="let result of sortedGuidanceResults">
        <adapt-search-row-purpose-vision *ngIf="SearchService.isPurposeVision(result) as purposeVision"
                                         [result]="purposeVision"></adapt-search-row-purpose-vision>
        <adapt-search-row-value *ngIf="SearchService.isValue(result) as value"
                                [result]="value"></adapt-search-row-value>
        <adapt-search-row-strategic-theme *ngIf="SearchService.isStrategicTheme(result) as theme"
                                          [result]="theme"></adapt-search-row-strategic-theme>
        <adapt-search-row-strategic-goal *ngIf="SearchService.isStrategicGoal(result) as goal"
                                         [result]="goal"></adapt-search-row-strategic-goal>
        <adapt-search-row-strategic-anchor *ngIf="SearchService.isStrategicAnchor(result) as anchor"
                                           [result]="anchor"></adapt-search-row-strategic-anchor>
        <adapt-search-row-strength-weakness-trend *ngIf="SearchService.isStrengthWeaknessTrend(result) as swt"
                                                  [result]="swt"></adapt-search-row-strength-weakness-trend>
        <adapt-search-row-competitor-analysis *ngIf="SearchService.isCompetitorAnalysis(result) as ca"
                                              [result]="ca"></adapt-search-row-competitor-analysis>
        <adapt-search-row-bullseye *ngIf="SearchService.isBullseye(result) as bullseye"
                                   [result]="bullseye"></adapt-search-row-bullseye>
    </ng-container>
</ng-container>

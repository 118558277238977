import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptEditSimpleValueBreezeEntityDialogModule } from "@common/ux/edit-simple-value-breeze-entity-dialog/edit-simple-value-breeze-entity-dialog.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptSeeMoreModule } from "@common/ux/see-more/see-more.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AdaptStrategyModule } from "@org-common/lib/strategy/strategy.module";
import { AdaptCreateValueWorkflowModule } from "@org-common/lib/values-constitution/create-value-workflow/create-value.module";
import { AdaptValuesConstitutionModule } from "@org-common/lib/values-constitution/values-constitution.module";
import { DxListModule, DxTextAreaModule } from "devextreme-angular";
import { AdaptPurposeVisionModule } from "../../purpose-vision/purpose-vision.module";
import { AdaptAltoWorkflowsSharedModule } from "../shared/workflows-shared.module";
import { CraftVisionFoundationsAgendaItemComponent } from "./craft-vision/craft-vision-foundations-agenda-item/craft-vision-foundations-agenda-item.component";
import { CraftVisionWorkshopRecordVisionComponent } from "./craft-vision/craft-vision-workshop-record-vision/craft-vision-workshop-record-vision.component";
import { PurposeOwnersWhyAgendaItemComponent } from "./purpose/purpose-owners-why-agenda-item/purpose-owners-why-agenda-item.component";
import { PurposeWorkshopRecordPurposeComponent } from "./purpose/purpose-workshop-record-purpose/purpose-workshop-record-purpose.component";
import { SetValuesWorkshopRecordValuesComponent } from "./set-values/set-values-workshop-record-values/set-values-workshop-record-values.component";
import { StrategicAnchorsAgendaItemComponent } from "./strategic-anchors-agenda-item/strategic-anchors-agenda-item.component";

@NgModule({
    imports: [
        CommonModule,
        AdaptCommonDialogModule,
        AdaptAuthorisationModule,
        AdaptLoadingSpinnerModule,
        AdaptButtonModule,
        AdaptSeeMoreModule,
        AdaptHtmlEditorModule,
        AdaptEditSimpleValueBreezeEntityDialogModule,
        AdaptCreateValueWorkflowModule,
        AdaptValuesConstitutionModule,
        AdaptStrategyModule,
        AdaptAltoWorkflowsSharedModule,
        AdaptButtonModule,

        DxListModule,
        DxTextAreaModule,
        AdaptPurposeVisionModule,
    ],
    declarations: [
        SetValuesWorkshopRecordValuesComponent,
        PurposeWorkshopRecordPurposeComponent,
        CraftVisionWorkshopRecordVisionComponent,
        CraftVisionFoundationsAgendaItemComponent,
        PurposeOwnersWhyAgendaItemComponent,
        StrategicAnchorsAgendaItemComponent,
    ],
})
export class AdaptBuildStrategyForSuccessWorkflowModule {
    // prevent being optimised by tree-shaker
    public readonly meetingAgendaItemComponents = [
        SetValuesWorkshopRecordValuesComponent,
        PurposeWorkshopRecordPurposeComponent,
        CraftVisionWorkshopRecordVisionComponent,
        CraftVisionFoundationsAgendaItemComponent,
        PurposeOwnersWhyAgendaItemComponent,
        StrategicAnchorsAgendaItemComponent,
    ];
}

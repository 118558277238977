import { Component, Input } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { OrganisationFeatureFlag } from "@org-common/lib/features/organisation-feature-flag.enum";
import { OrganisationFeatureFlagService } from "@org-common/lib/features/organisation-feature-flag.service";
import { lastValueFrom, switchMap } from "rxjs";
import { MigrateValuesDialogComponent } from "../migrate-values-dialog/migrate-values-dialog.component";

@Component({
    selector: "adapt-configure-values-constitution",
    templateUrl: "./configure-values-constitution.component.html",
})
export class ConfigureValuesConstitutionComponent extends ConfigureFeatureBase {
    @Input() public configItem?: IConfigItem;
    @Input() public team?: Team;

    public usingValuesV2 = false;

    constructor(
        configurationService: ConfigurationService,
        private dialogService: AdaptCommonDialogService,
        private organisationFeatureFlagService: OrganisationFeatureFlagService,
    ) {
        super(configurationService);
    }

    public async initialiseData() {
        this.usingValuesV2 = await lastValueFrom(this.organisationFeatureFlagService.isFeatureFlagEnabled(OrganisationFeatureFlag.ValuesV2));
    }

    public migrateValues() {
        this.dialogService.open(MigrateValuesDialogComponent).pipe(
            switchMap(() => this.initialiseData()),
        ).subscribe();
    }
}

import { Logger } from "@common/lib/logger/logger";
import { SignalRInvokeError } from "@common/lib/signalr-provider/signalr-invoke-error";
import { lastValueFrom } from "rxjs";
import { AutoUpdaterMergeStrategy } from "./auto-updater-merge-strategy";
import { BaseEntityAutoUpdater } from "./base-entity-auto-updater";

export class AddedEntityAutoUpdater extends BaseEntityAutoUpdater {
    public userIsAllowedToIgnoreServerUpdates = false;

    public hasConflicts(): boolean {
        // The added entity state check for conflict is for the case where
        // - WorkflowConnection is added remotely, which then emits to all these workflow action card or component that subscribe
        //   to connection change and prime workflow status.
        //   WorkflowStatus updated remotely will get in later (timing) and since entity is already primed, it will cause conflict otherwise
        return !!this.localEntity && !this.localEntity.entityAspect.entityState.isUnchanged();
    }

    public updatesShouldBeIntegrated(): boolean {
        const relatedEntities = this.entityUpdate.RelatedEntities.map((re) => ({
            model: this.modelForType(re.EntityType),
            entityId: re.EntityId,
        }));

        if (relatedEntities.every((relatedEntity) => !!relatedEntity.model)) {
            return this.autoUpdaterTemplate.shouldFetchAddedEntityInfo(this.injector, {
                entityId: this.entityUpdate.EntityId,
                relatedEntities,
            });
        } else {
            // todo: once we register models at start up this error will be useful, but until then if you havent visited all pages, then there is a chance
            // this will throw an error because the data model has not been registered
            // throw new Error("Related model unregistered - please register it!");

            // certain related model unregistered - won't be integrated
            return false;
        }
    }

    public async promiseToIntegrateUpdates(_mergeStrategy: AutoUpdaterMergeStrategy) {
        if (this.localEntity) {
            await lastValueFrom(this.commonDataService.rejectChanges(this.localEntity!));
        }

        try {
            // TODO: check access verifier here before fetching
            this.localEntity = await lastValueFrom(this.commonDataService.getById(this.autoUpdaterTemplate.entityModel, this.entityUpdate.EntityId, true));
        } catch (e) {
            Logger.getLogger(AddedEntityAutoUpdater.name).error(`${SignalRInvokeError.SignalRMessageId}: Background fetch fail`, e);
        }
        return this.localEntity;
    }

    public postProcessHumanReadableSummary(summary: string): string {
        return `Has also added ${summary}`;
    }
}

import { Validator } from "breeze-client";
import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { ValueQuestion } from "./value-question";
import { ValuesConstitution } from "./values-constitution";

export class Value extends BaseEntity<Value> {
    public valueId!: number;
    public valuesConstitutionId!: number;
    public name!: string;
    public meaning?: string;
    public ordinal!: number;
    public valueQuestions!: ValueQuestion[];
    public valuesConstitution!: ValuesConstitution;
}

export const uniqueValueNameValidator = new Validator(
    "uniqueValueNameValidator",
    (value: Value) => {
        const otherNames = value.valuesConstitution.values
            .filter((i) => i !== value)
            .map((i) => i.name.toLocaleLowerCase());
        const sanitizedName = value.name.trim().toLocaleLowerCase();
        return otherNames.every((otherName) => otherName !== sanitizedName);
    },
    { messageTemplate: "There is an existing value with this name" },
);

export const ValueBreezeModel = new BreezeModelBuilder("Value", Value)
    .withSortField("ordinal")
    .hasSource()
    .isOrganisationEntity()
    .withEntityValidator(uniqueValueNameValidator)
    .persistChangedEntity()
    .build();

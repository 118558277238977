import { Injectable } from "@angular/core";
import { QueuedCaller } from "@common/lib/queued-caller/queued-caller";
import { IToolbar, IToolbarActionOptions } from "./toolbar.interface";

@Injectable({
    providedIn: "root",
})
export class ToolbarService {
    private queuedToolbar = new QueuedCaller<IToolbar>();

    public registerToolbar(toolbar: IToolbar) {
        this.queuedToolbar.setCallee(toolbar);
        return () => this.queuedToolbar = new QueuedCaller<IToolbar>();
    }

    public addContent(content: HTMLElement, options?: IToolbarActionOptions) {
        this.queuedToolbar.call((toolbar) => toolbar.addContent(content, options));
    }

    public removeContent(content: HTMLElement) {
        this.queuedToolbar.call((toolbar) => toolbar.removeContent(content));
    }

    public resetToolbar() {
        if (this.queuedToolbar.isSet) {
            this.queuedToolbar.call((toolbar) => toolbar.resetToolbar());
        } else {
            this.queuedToolbar.clearQueuedCalls();
        }
    }

    public setTitle(title: string | HTMLElement) {
        this.queuedToolbar.call((toolbar) => toolbar.setTitle(title));
    }

    public clearTitle() {
        this.queuedToolbar.call((toolbar) => toolbar.clearTitle());
    }

    public showToolbar() {
        this.queuedToolbar.call((toolbar) => toolbar.showToolbar());
    }

    public hideToolbar() {
        this.queuedToolbar.call((toolbar) => toolbar.hideToolbar());
    }

    public addToolbarClass(className: string) {
        this.queuedToolbar.call((toolbar) => toolbar.addToolbarClass(className));
    }

    public clearToolbarClass() {
        this.queuedToolbar.call((toolbar) => toolbar.clearToolbarClass());
    }
}

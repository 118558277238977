import { Component, Input } from "@angular/core";
import { OrganisationCategoryValue, Workflow, WorkflowScope, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { WorkflowStatusEnum } from "@common/ADAPT.Common.Model/organisation/workflow-status";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { IGroupedData } from "@common/lib/utilities/grouped-data.interface";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { OrganisationOutcomes } from "@org-common/lib/workflow/organisation-outcomes";
import { WorkflowService } from "@org-common/lib/workflow/workflow.service";
import { CategoryWorkflowStatus } from "@org-common/lib/workflow/workflow-map-dashboard-element/category-workflow-status.enum";
import { EMPTY, forkJoin, lastValueFrom, switchMap } from "rxjs";
import { map } from "rxjs/operators";

@Component({
    selector: "adapt-configure-pathway-completion",
    templateUrl: "./configure-pathway-completion.component.html",
})
export class ConfigurePathwayCompletionComponent extends ConfigureFeatureBase {
    @Input() public configItem?: IConfigItem;

    public readonly OrganisationOutcomes = OrganisationOutcomes;

    public categories = Object.values(OrganisationCategoryValue)
        .filter((category) => category !== OrganisationCategoryValue.Others);
    public categoriesWorkflows: IGroupedData<OrganisationCategoryValue | undefined, Workflow>[] = [];
    public completedWorkflows: Workflow[] = [];

    public constructor(
        configurationService: ConfigurationService,
        private commonDataService: CommonDataService,
        private workflowService: WorkflowService,
        private teamsService: CommonTeamsService,
        private dialogService: AdaptCommonDialogService,
    ) {
        super(configurationService);
    }

    public async initialiseData() {
        await this.updateData();
    }

    public resetWorkflowConnections() {
        this.dialogService.openConfirmationDialog({
            title: "Reset Pathway Connections",
            message: "This will remove all pathway connections (completed, incomplete, etc) for this organisation.",
            checkboxMessage: "Confirm that you wish to remove all pathway connections for this organisation",
        }).pipe(
            switchMap(() => this.workflowService.getAllWorkflowConnections()),
            switchMap((connections) => connections.length > 0
                ? forkJoin(connections.map((connection) => this.commonDataService.remove(connection))).pipe(
                    map(() => connections),
                ) : EMPTY),
            switchMap((connections) => this.commonDataService.saveEntities(connections)),
            switchMap(() => this.updateData()),
        ).subscribe();
    }

    public async completeWorkflow(workflow: Workflow) {
        const orderedWorkflows = this.getAllPrerequisites(workflow)
            .sort(SortUtilities.getSortByFieldFunction<Workflow>("ordinal"))
            .concat(workflow);

        const workflowsToComplete = orderedWorkflows
            .filter((i) => !this.completedWorkflows.includes(i));

        if (workflowsToComplete.length === 0) {
            this.dialogService.showErrorDialog("Complete Pathways", "No pathways to complete").subscribe();
            return;
        }

        const message = orderedWorkflows
            .map((i) => this.completedWorkflows.includes(i)
                ? `<li><s>${i.name}</s> (already completed)</li>`
                : `<li>${i.name}</li>`)
            .join("");

        const doComplete = await lastValueFrom(this.dialogService.openConfirmationDialogWithBoolean({
            title: "Complete Pathways",
            message: `<p>The following pathways will be completed:</p>
                <ul>${message}</ul>`,
            confirmButtonText: "Complete Pathway",
        }), { defaultValue: false });

        if (doComplete) {
            const entitiesToSave = new Set<IBreezeEntity>();

            for (const completeWorkflow of workflowsToComplete) {
                let connection = await this.getWorkflowConnection(completeWorkflow);
                if (!connection) {
                    const team = await this.teamsService.promiseToGetLeadershipTeam();
                    const { workflowConnection } = await lastValueFrom(this.workflowService.createWorkflowConnectionForWorkflow(completeWorkflow, undefined, team ? [team] : undefined, false));
                    connection = workflowConnection;
                    entitiesToSave.add(connection);
                }

                const statuses = await lastValueFrom(this.workflowService.getUpdateStatusForJourneyHierarchy(connection, true));
                for (const status of statuses) {
                    this.workflowService.setWorkflowStatusCompleted(status);
                    entitiesToSave.add(status);
                }
            }

            await lastValueFrom(this.commonDataService.saveEntities(Array.from(entitiesToSave)));
            await this.updateData();
        }
    }

    private getAllPrerequisites(workflow: Workflow) {
        const allPrerequisites = new Set<Workflow>();
        const prerequisites = this.workflowService.getAllPrerequisiteWorkflows(workflow);

        for (const prerequisite of prerequisites) {
            allPrerequisites.add(prerequisite);

            for (const innerPrerequisite of this.getAllPrerequisites(prerequisite)) {
                allPrerequisites.add(innerPrerequisite);
            }
        }

        return Array.from(allPrerequisites);
    }

    private async isWorkflowCompleted(workflow: Workflow) {
        const connection = await this.getWorkflowConnection(workflow);
        if (connection) {
            const status = await lastValueFrom(this.workflowService.getStatusForWorkflow(connection));
            if (status?.status === WorkflowStatusEnum.Completed) {
                return true;
            }
        }

        return false;
    }

    private async getWorkflowConnection(workflow: Workflow) {
        const team = await this.teamsService.promiseToGetLeadershipTeam();
        const connection = await lastValueFrom(this.workflowService.getLatestWorkflowConnection(workflow, undefined, team ? [team.teamId] : undefined));
        if (connection) {
            return connection;
        }

        return undefined;
    }

    private async updateData() {
        const workflows: Workflow[] = [];
        const completedWorkflows: Workflow[] = [];

        for (const category of this.categories) {
            const categoryWorkflows = this.workflowService.getWorkflowsByCategory(category)
                .filter((i) => !i.private && !i.isStateless && i.scope === WorkflowScope.Team && i.type === WorkflowType.Journey)
                .sort(SortUtilities.getSortByFieldFunction<Workflow>("ordinal"));

            const filteredWorkflows = await this.workflowService.filterWorkflows(categoryWorkflows, [CategoryWorkflowStatus.ComingSoon]);
            workflows.push(...filteredWorkflows);

            for (const workflow of filteredWorkflows) {
                if (await this.isWorkflowCompleted(workflow)) {
                    completedWorkflows.push(workflow);
                }
            }
        }

        this.completedWorkflows = completedWorkflows;
        this.categoriesWorkflows = ArrayUtilities.groupArrayBy(workflows, (w) => w.category);
    }
}

<div class="workflow-step-card active wrap-up-card">
    <div class="start">
        <i class="fal fa-fw fa-flag-checkered fa-inverse"></i>
    </div>

    <div class="d-flex flex-column flex-grow-1">
        <div class="title">Wrap up</div>
        <div>
            <div class="active-content">
                <ng-container *ngIf="wrapUpTour">
                    <p>Click "Start tour" below to start a guided tour showing you elements completed in this pathway.</p>
                    <button class="mt-2 mb-4 btn btn-primary align-self-start"
                            [disabled]="!hasWorkflowAccess"
                            (click)="runWrapUpTour()">Start tour</button>
                </ng-container>

                <p>Click <a [routerLink]="personalDashboardRoute$ | async">here</a> to return to your personal dashboard</p>

                <adapt-show-workflow-completion-status [workflow]="workflow"></adapt-show-workflow-completion-status>
            </div>
        </div>
    </div>
</div>

import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Organisation } from "./organisation";
import { Team } from "./team";

export class TeamMeetingGuidance extends BaseEntity<TeamMeetingGuidance> {
    public teamId!: number;
    public organisationId!: number;
    public meetingFrequency?: string;
    public meetingIntent?: string;
    public meetingGuidelines?: string;
    public decisionProcess?: string;
    public organisation!: Organisation;
    public team!: Team;

    // TODO: Ideally, this should use some sort of @EntityExtensions() type decorator. Revisit this when we redo stewardship.
    public get containsGuidance() {
        return this.meetingFrequency || this.meetingGuidelines || this.meetingIntent || this.decisionProcess;
    }
}

export const TeamMeetingGuidanceBreezeModel = new BreezeModelBuilder("TeamMeetingGuidance", TeamMeetingGuidance)
    .hasSource()
    .isOrganisationEntity()
    .withIdField("teamId")
    .build();

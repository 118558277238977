import { PageRouteBuilder } from "@common/route/page-route-builder";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { SurveyPageComponent } from "./survey-page.component";

export const SurveyPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-survey-page", SurveyPageComponent)
    .atUrl("/survey")
    .requiresLogin(false)
    .build();

export const SignupHealthCheckPageRoute = new PageRouteBuilder()
    .usingNgComponent("adapt-survey-page", SurveyPageComponent)
    .atUrl("/health-check")
    .requiresLogin(false)
    .requiresAnonymous(false)
    .build();

<adapt-feature-guidance [configItem]="configItem">
    <p>{{oauthService.getPromptText(personalConfig)}}</p>

    <ng-container *ngIf="calendarProvider">
        <ng-container *ngIf="personalConfig; else organisationConfigPrompt">
            <p *ngIf="hasConfigureAccess; else notAdminPersonalPrompt">
                If you would like to change the configured calendar integration for your entire organisation, please use the organisation
                configuration area:
                <a [routerLink]="organisationConfigRoute?.path"
                   [queryParams]="{section: ConfigArea[ConfigArea.CalendarIntegration]}">Organisation calendar integration</a>
            </p>

            <ng-template #notAdminPersonalPrompt>
                <p>If you would like to change the configured calendar integration, please contact your {{projectLabel}} administrator.</p>
            </ng-template>
        </ng-container>

        <ng-template #organisationConfigPrompt>
            <p *ngIf="!calendarProvider.isLocal">
                If you would like to logout or switch your provider account, please use the personal configuration area:
                <a [routerLink]="personalConfigRoute?.path"
                   [queryParams]="{section: ConfigArea[ConfigArea.CalendarIntegration]}">Personal calendar integration</a>
            </p>
        </ng-template>
    </ng-container>
</adapt-feature-guidance>

<div *ngIf="!calendarProvider && hasConfigureAccess"
     class="alert alert-warning">
    <strong>No calendar integration has been configured.</strong>
    Please select one of the options below to set the calendar integration for the organisation.
</div>

<adapt-calendar-provider-select *ngIf="(!calendarProvider || personalConfig) && !integrationIsDisabled; else resetProvider"
                                [horizontal]="false"
                                [showLogoutButton]="true"
                                [showDisableIntegration]="!personalConfig || (hasConfigureAccess && !calendarProvider)"></adapt-calendar-provider-select>

<ng-template #resetProvider>
    <ng-container *ngIf="integrationIsDisabled; else hasIntegration">
        <p>Calendar integration is not currently enabled for your organisation.</p>
    </ng-container>

    <ng-template #hasIntegration>
        <p>Your organisation is configured to use <strong>{{providerText}}</strong> as the calendar integration provider.</p>
    </ng-template>

    <div *ngIf="!personalConfig && hasConfigureAccess"
         class="mt-2">
        <button type="button"
                role="button"
                class="btn btn-primary"
                [adaptBlockingClick]="resetIntegration">
            {{integrationIsDisabled ? "Enable" : "Reset"}} calendar integration
        </button>
    </div>
</ng-template>

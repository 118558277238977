<div class="d-flex flex-wrap gap-3"
     [ngClass]="{'flex-column': !horizontal}">
    <div *ngFor="let calendarProvider of calendarProviders"
         class="me-auto">
        <adapt-render-component [component]="calendarProvider.connectComponent"
                                [componentInputs]="{showLogoutButton, disabled}"></adapt-render-component>
    </div>
    <div *ngIf="errorMessage"
         class="alert alert-danger w-100 mb-0"
         role="alert">{{errorMessage}}</div>

    <div *ngIf="!hasConfigureAccess && (!calendarProviders || calendarProviders.length === 0 || !provider)"
         class="alert alert-warning"
         role="alert">
        Calendar integration has not been configured for this organisation, please contact your {{projectLabel}} administrator to enable integration.
    </div>
</div>

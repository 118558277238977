import { Component, Inject, OnInit } from "@angular/core";
import { ExternalDashboard } from "@common/ADAPT.Common.Model/organisation/external-dashboard";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { IDxCustomRuleValidationCallbackEvent } from "@common/ux/dx.types";
import { OrganisationService } from "../../organisation/organisation.service";

@Component({
    selector: "adapt-edit-external-dashboard-dialog",
    templateUrl: "./edit-external-dashboard-dialog.component.html",
})
export class EditExternalDashboardDialogComponent extends BaseDialogWithDiscardConfirmationComponent<ExternalDashboard> implements OnInit {
    public readonly dialogName = "EditExternalDashboard";
    public readonly entitiesToConfirm = [this.externalDashboard];
    public readonly autoResolveData = this.externalDashboard;
    public readonly title = this.externalDashboard.entityAspect.entityState.isAdded()
        ? "Add external link"
        : "Edit external link";

    public readonly ReadAccessSelections = [
        {
            text: "Everyone in the team",
            value: false, // externalDashboard.isPublicRead will be set to false
        },
        {
            text: "Everyone in the team and anyone with access to public dashboards",
            value: true,
        },
    ];

    private externalDashboards: ExternalDashboard[] = [];

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public externalDashboard: ExternalDashboard,
        private orgService: OrganisationService,
    ) {
        super();
    }

    public get isValid() {
        return this.externalDashboard.name
            && !this.externalDashboard.entityAspect.hasValidationErrors
            && this.validateDuplicates({ value: this.externalDashboard.name });
    }

    public async ngOnInit() {
        this.externalDashboards = await this.orgService.promiseToGetExternalDashboards(this.externalDashboard.teamId);
    }

    @Autobind
    public validateDuplicates({ value }: IDxCustomRuleValidationCallbackEvent) {
        if (typeof value === "string") {
            const dashboardNameExists = this.externalDashboards
                .filter((dashboard) => dashboard !== this.externalDashboard)
                .some((dashboard) => dashboard.name.trim().toLowerCase() === value.trim().toLowerCase());
            return !dashboardNameExists;
        }
        return false;
    }
}

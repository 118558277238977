import { Component, ElementRef, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Workflow } from "@common/ADAPT.Common.Model/embed/workflow";
import { WorkflowConnection } from "@common/ADAPT.Common.Model/organisation/workflow-connection";
import { WorkflowStatus, WorkflowStatusEnum } from "@common/ADAPT.Common.Model/organisation/workflow-status";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { map, merge, of, switchMap } from "rxjs";
import { WorkflowService } from "../workflow.service";

@Component({
    selector: "adapt-show-workflow-completion-status",
    templateUrl: "./show-workflow-completion-status.component.html",
    styleUrls: ["./show-workflow-completion-status.component.scss"],
})
export class ShowWorkflowCompletionStatusComponent extends BaseComponent implements OnChanges {
    public readonly WorkflowStatusEnum = WorkflowStatusEnum;
    @Input() public workflow?: Workflow;

    public completionDate?: Date;
    public completedBy?: string;
    public startDate?: Date;
    public startedBy?: string;

    public workflowStatus = WorkflowStatusEnum.Incomplete;

    public constructor(
        elementRef: ElementRef,
        rxjsBreezeService: RxjsBreezeService,
        private workflowService: WorkflowService,
    ) {
        super(elementRef);

        merge(
            rxjsBreezeService.entityTypeChanged(WorkflowConnection),
            rxjsBreezeService.entityTypeChanged(WorkflowStatus),
        ).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.getStatus());
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.workflow && this.workflow) {
            this.getStatus();
        } else {
            this.workflowStatus = WorkflowStatusEnum.Incomplete;
            this.updateCompletionData(undefined);
        }
    }

    private getStatus() {
        if (this.workflow) {
            let rootWorkflow = this.workflow;
            while (rootWorkflow.parentWorkflow) {
                rootWorkflow = rootWorkflow.parentWorkflow;
            }
            this.workflowService.getLatestWorkflowConnectionForWorkflow(rootWorkflow).pipe(
                switchMap((workflowConnection) => {
                    if (!workflowConnection) {
                        return of(WorkflowStatusEnum.Incomplete);
                    }

                    return this.workflowService.getStatusForWorkflow(workflowConnection, this.workflow).pipe(
                        map((statusEntity) => {
                            this.updateCompletionData(statusEntity);
                            return statusEntity?.status ?? WorkflowStatusEnum.Incomplete;
                        }),
                    );
                }),
            ).subscribe((workflowStatus) => this.workflowStatus = workflowStatus);
        }
    }

    private updateCompletionData(workflowStatus?: WorkflowStatus) {
        this.completedBy = undefined;
        this.completionDate = undefined;
        this.startedBy = undefined;
        this.startDate = undefined;
        if (workflowStatus?.status === WorkflowStatusEnum.Completed) {
            this.completionDate = workflowStatus.completionTime;
            this.completedBy = workflowStatus.completedBy?.fullName;
        } else if (workflowStatus?.status === WorkflowStatusEnum.Current) {
            this.startDate = workflowStatus.startTime;
            this.startedBy = workflowStatus.startedBy?.fullName;
        }
    }
}

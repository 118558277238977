export interface ICookie {
    name: string;
    value: string;
    path?: string;
    maxAgeSeconds?: string;
}

// See https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
export class Cookie {
    /**
     * Set a cookie with the given values. This cookie will be set with Secure and
     * SameSite=Strict. If no max age is set then the cookie will most likely be
     * a session cookie (browser dependent).
     */
    public static set(cookie: ICookie) {
        let cookieString = `${cookie.name}=${cookie.value}; secure; samesite=strict`;

        if (cookie.path) {
            cookieString += `; path=${cookie.path}`;
        }

        if (cookie.maxAgeSeconds) {
            cookieString += `; max-age=${cookie.maxAgeSeconds}`;
        }

        document.cookie = cookieString;
    }

    /**
     * Delete the cookie with the provided name. If the cookie was set with a path then
     * that needs to be provided as well.
     */
    public static delete(name: string, path?: string) {
        let cookieString = `${name}=; max-age=0`;

        if (path) {
            cookieString += `; path=${path}`;
        }

        document.cookie = cookieString;
    }
}

import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { IdentityUxModule } from "@common/identity/ux/identity-ux.module";
import { provideUserMenuItem } from "@common/shell/application-bar-user-item-content/user-menu-item";
import { provideSidebarPopover } from "@common/shell/common-sidebar/sidebar-popover";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptStorageModule } from "@common/storage/storage.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { ContentWithSplashImageModule } from "@common/ux/content-with-splash-image/content-with-splash-image.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptCallToActionModule } from "@org-common/lib/call-to-action/call-to-action.module";
import { DxAccordionModule, DxDataGridModule, DxTextBoxModule, DxValidationGroupModule, DxValidatorModule } from "devextreme-angular";
import { AllOrganisationsGridComponent } from "./all-organisations-grid/all-organisations-grid.component";
import { MyOrganisationsPageComponent } from "./my-organisations-page/my-organisations-page.component";
import { myOrganisationsPageRoute } from "./my-organisations-page/my-organisations-page.route";
import { MyOrganisationsApplicationBarItem, MyOrganisationsUserMenuItem } from "./my-organisations-page/my-organisations-user-menu-item";
import { OrganisationCardComponent } from "./organisation-card/organisation-card.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        DxValidationGroupModule,
        DxValidatorModule,
        DxTextBoxModule,
        DxDataGridModule,
        DxAccordionModule,
        AdaptButtonModule,
        IdentityUxModule,
        ContentWithSplashImageModule,
        AdaptStorageModule,
        AdaptShellModule,
        AdaptTooltipModule,
        AdaptCallToActionModule,
    ],
    declarations: [
        MyOrganisationsPageComponent,
        AllOrganisationsGridComponent,
        OrganisationCardComponent,
    ],
})
export class AdaptMyOrganisationsModule {
    public static readonly Routes = [
        ...myOrganisationsPageRoute.routes,
    ];

    public static forRoot(): ModuleWithProviders<AdaptMyOrganisationsModule> {
        return {
            ngModule: AdaptMyOrganisationsModule,
            providers: [
                provideUserMenuItem(MyOrganisationsUserMenuItem),
                provideSidebarPopover(MyOrganisationsApplicationBarItem),
            ],
        };
    }

}

<ng-container *ngIf="eventTypePreset">
    <p>Please select from the dropdown boxes below when you and your team would like to conduct your {{eventNameText}}.</p>

    <div *ngIf="currentRecurrence as recurrence"
         class="d-flex row g-3 mb-3">
        <div class="col-12 col-xl"
             [adaptCollapsible]="recurrence.config.guidance">
            <adapt-styled-implementation-kit [articleId]="recurrence.config.guidance"
                                             contentStyle="embedded"></adapt-styled-implementation-kit>
        </div>

        <div class="col-12 col-xl-auto d-flex justify-content-center">
            <adapt-schedule-recurrence [recurrence]="recurrence"
                                       [cadenceCycle]="runData.eventCadenceCycle"
                                       [nextTimes]="nextTimes"
                                       [location]="recurrence.eventSeries.entityAspect.entityState.isAdded() ? lastLocation : undefined"
                                       [potentialLocations]="locations"
                                       [disabled]="configDisabled"
                                       (scheduledRecurrence)="onRecurrenceChange($event)"></adapt-schedule-recurrence>
        </div>
    </div>

    <div *ngIf="warning"
         class="alert alert-warning d-flex"
         role="alert">{{warning}}</div>

    <adapt-time-scheduler-wrapper [items]="items"
                                  [sections]="sections"
                                  [start]="schedulerStartTime"
                                  [currentPeriod]="currentPeriod"
                                  [cycle]="cycle"
                                  [showSectionToggle]="false"></adapt-time-scheduler-wrapper>
</ng-container>

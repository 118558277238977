import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnDestroy, ViewChild } from "@angular/core";
import { ToolbarService } from "../toolbar.service";

@Component({
    selector: "adapt-toolbar-title",
    template: "<div #contentRef><ng-content></ng-content></div>",
})
export class ToolbarTitleComponent implements AfterViewInit, OnChanges, OnDestroy {
    @Input() public toolbarClass?: string;

    @ViewChild("contentRef", { read: ElementRef }) private contentRef!: ElementRef;

    public constructor(
        private toolbarService: ToolbarService,
    ) { }

    public ngAfterViewInit() {
        this.toolbarService.setTitle(this.contentRef.nativeElement);
    }

    public ngOnChanges() {
        if (this.toolbarClass) {
            this.toolbarService.addToolbarClass(this.toolbarClass);
        }
    }

    public ngOnDestroy() {
        this.toolbarService.clearTitle();
        this.toolbarService.clearToolbarClass();
    }
}

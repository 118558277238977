import { Component, Input, OnInit } from "@angular/core";
import { OrganisationCategoryValue } from "@common/ADAPT.Common.Model/embed/workflow";
import { Survey, SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { IAdaptLinkObject } from "@common/route/route.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { lastValueFrom } from "rxjs";
import { SurveyService } from "../../survey/survey.service";
import { organisationDiagnosticSurveySummaryPage } from "../../survey/survey-summary-page/survey-summary-page.component";
import { OrganisationOutcomes } from "../organisation-outcomes";

@Component({
    selector: "adapt-display-workflow-category",
    templateUrl: "./display-workflow-category.component.html",
    styleUrls: ["./display-workflow-category.component.scss"],
})
export class DisplayWorkflowCategoryComponent extends BaseComponent implements OnInit {
    @Input() public category!: OrganisationCategoryValue;
    @Input() public showPlainTooltip = false;

    public odSurveySummaryPageRoute?: IAdaptLinkObject;
    public latestOdScoreString?: string;
    private surveyCategoryId?: number;

    public constructor(
        private surveyService: SurveyService,
    ) {
        super();
    }

    public get categoryMetadata() {
        return OrganisationOutcomes[this.category];
    }

    public ngOnInit() {
        this.surveyService.getMostRecentEndedSurveys(1, SurveyType.OrganisationDiagnostic).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(async (surveys) => {
            if (surveys.length > 0) {
                const survey = surveys[0];
                await this.updateSurveyDetails(survey);
            }
        });
    }

    private async updateSurveyDetails(survey: Survey) {
        const surveyQuestions = this.surveyService.getQuestionsForSurvey(survey);

        this.surveyCategoryId = surveyQuestions.categoryIds
            ?.find((categoryId) => surveyQuestions.getCategory(categoryId)?.categoryName === this.categoryMetadata.name);

        if (this.surveyCategoryId) {
            this.odSurveySummaryPageRoute = await lastValueFrom(organisationDiagnosticSurveySummaryPage.getRouteObject(undefined, { selectedCategoryId: this.surveyCategoryId }));

            const percentageScore = await lastValueFrom(this.surveyService.getCategoryPercentageScoreForSurvey(this.surveyCategoryId, survey, surveyQuestions));
            this.latestOdScoreString = Math.round(percentageScore).toFixed(0);
        } else {
            this.latestOdScoreString = undefined;
        }
    }
}

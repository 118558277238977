import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { IFocusable } from "../adapt-common-dialog/focusable";
import { AdaptHtmlEditorFroalaComponent } from "./html-editor-froala/html-editor-froala.component";

@Component({
    selector: "adapt-html-editor",
    template: `
        <adapt-html-editor-froala [contents]="contents"
                                  (contentsChange)="contentsChange.emit($event)"
                                  (isValidChange)="isValidChange.emit($event)"
                                  [forceSize]="forceSize"
                                  [placeholderText]="placeholderText"
                                  [required]="required"
                                  [onlyShowToolbarWhenFocussed]="onlyShowToolbarWhenFocussed"
                                  [readonly]="readonly"
                                  [showCodeView]="isValidChange.observed"
                                  [minHeight]="minHeight">
        </adapt-html-editor-froala>`,
    styleUrls: ["./html-editor.component.scss"],
    // needed to target editor elements
    encapsulation: ViewEncapsulation.None,
})
export class AdaptHtmlEditorComponent implements IFocusable {
    @Input() public contents?: string | null;
    @Output() public contentsChange = new EventEmitter<string>();
    @Output() public isValidChange = new EventEmitter<boolean>();

    @Input() public minHeight?: number;
    @Input() public forceSize: "standard" | "compact" = "standard";
    @Input() public placeholderText = "Type something";
    @Input() public required = false;
    @Input() public onlyShowToolbarWhenFocussed = false;
    @Input() public readonly = false;

    @ViewChild(AdaptHtmlEditorFroalaComponent) private froalaHtmlEditor?: AdaptHtmlEditorFroalaComponent;

    public getElementToFocus() {
        return this.froalaHtmlEditor?.getElementToFocus();
    }
}
<adapt-feature-guidance [configItem]="configItem">
    <p>
        Teams can use actions to visualise their work and limit work in progress to enable more effective teamwork.
    </p>
</adapt-feature-guidance>

<adapt-feature-configuration [team]="team"
                             [configItem]="configItem">
    <h3>Action boards</h3>
    <adapt-configure-kanban-boards [team]="team"></adapt-configure-kanban-boards>
</adapt-feature-configuration>

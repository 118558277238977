import { Params } from "@angular/router";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { PageRouteBuilder } from "@common/route/page-route-builder";
import { ReplaySubject } from "rxjs";
import { OrganisationRouteBuilder } from "./organisation-route-builder";

export class OrganisationPageRouteBuilder<TNamedParams extends Params, TInputParam = TNamedParams> extends PageRouteBuilder<TNamedParams, TInputParam> {
    private static OrganisationUrlPathPrefix = "/:organisationUrlIdentifier";

    // Need this as a replay subject so that the route resolver will wait till the organisation is ready.
    // Otherwise you will end up getting -1 in org path now and then depending on the timings
    private static organisationUrlIdentifierSubject = new ReplaySubject<string>(1);

    protected routeBuilder: OrganisationRouteBuilder;

    public constructor() {
        super();

        this.routeBuilder = new OrganisationRouteBuilder().enableChangeManagerDetection();
    }

    public static emitOrganisationUrlIdentifier(value?: string) {
        OrganisationPageRouteBuilder.organisationUrlIdentifierSubject.next(value ?? "-1");
    }

    /**
     * Specifies that this route is organisation scoped (will have the organisation url identifier at start)
     * @param partialUrl The URL of the route excluding the organisation identifier and *with* a leading slash
     */
    public atOrganisationUrl(partialUrl: string) {
        const prefix = OrganisationPageRouteBuilder.OrganisationUrlPathPrefix;
        return this.atUrl(prefix + this.addPrecedingSlashIfRequired(partialUrl))
            .requiresLogin()
            .validatesOrganisation()
            .addUrlParameterGetter("organisationUrlIdentifier", OrganisationPageRouteBuilder.organisationUrlIdentifierSubject.asObservable())
            .redirectToThisRouteFromUrl(partialUrl);
    }

    /**
     * Redirect to this route from the specified organisation scoped url
     * @param partialUrl The URL of the redirected route excluding the organisation identifier and with a leading slash
     */
    public redirectToThisRouteFromOrganisationUrl(partialUrl: string) {
        const prefix = OrganisationPageRouteBuilder.OrganisationUrlPathPrefix;
        return this.redirectToThisRouteFromUrl(prefix + this.addPrecedingSlashIfRequired(partialUrl));
    }

    public verifyingFeatures(featureName: FeatureName) {
        this.routeBuilder.requiresFeature(featureName);
        return this;
    }

    public verifyingAccess(accessVerifier: string) {
        this.routeBuilder.requiresAccess(accessVerifier);
        return this;
    }

    public validatesOrganisation() {
        this.routeBuilder.requiresOrganisationValidation();
        return this;
    }

    public requiresCoach(coachRequired = true) {
        this.routeBuilder.requiresCoach(coachRequired);
        return this;
    }
}

import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";

@Pipe({
    name: "timeAgo",
})
export class TimeAgoPipe implements PipeTransform {
    public transform(value: moment.MomentInput): any {
        return moment(value).fromNow();
    }

}

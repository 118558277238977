<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.ResilientBusinessAssessment"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<adapt-manage-surveys [surveyType]="surveyType"
                      [callToActionTextTemplate]="callToActionText"
                      (initialise)="onInitialised()"></adapt-manage-surveys>

<ng-template #callToActionText>
    <p>
        The resilient business assessment collects quantitative data from everyone in the
        business.
    </p>
    <p>
        Using the resilient business assessment tool you will be able to send a survey to
        each of your employees and collate and analyse this data dynamically. The results
        are divided into Leader and Employee results, allowing you to compare the two.
    </p>

    <p>
        Survey questions will assess the following areas:
    </p>
    <ul class="list-content">
        <li><b>Leadership &amp; culture</b> - The organisation's leadership capabilities, shared assumptions, values and beliefs</li>
        <li><b>Economic engine</b> - The viability and robustness of the organisation's offering</li>
        <li><b>Organisation design</b> - How people and work are organised</li>
        <li><b>Financial security</b> - Business literacy across the organisation</li>
    </ul>
</ng-template>

import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { MeetingNote, MeetingNoteTypeMetadata } from "@common/ADAPT.Common.Model/organisation/meeting-note";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { EditSimpleValueBreezeEntityDialogComponent, IEditSimpleValueBreezeEntityDialogData, SimpleValueType } from "@common/ux/edit-simple-value-breeze-entity-dialog/edit-simple-value-breeze-entity-dialog.component";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { filter, switchMap } from "rxjs/operators";
import { MeetingsService } from "../meetings.service";

@Component({
    selector: "adapt-display-meeting-note",
    templateUrl: "./display-meeting-note.component.html",
    styleUrls: ["../meeting-styles.scss", "./display-meeting-note.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class DisplayMeetingNoteComponent implements OnInit {
    @Input() public note!: MeetingNote;
    @Input() public edit = false;

    public tooltip: string = "";
    public iconClass: string = "";
    public menuVisible: boolean = false;

    public actionMenu: IAdaptMenuItem[] = [];

    public constructor(
        private dialogService: AdaptCommonDialogService,
        private meetingsService: MeetingsService,
    ) { }

    public ngOnInit() {
        this.buildMenu();

        if (this.note) {
            this.tooltip = this.note.type;
            this.iconClass = MeetingNoteTypeMetadata.IconClass[this.note.type];
        }
    }

    private buildMenu() {
        const menuItems: IAdaptMenuItem[] = [];

        menuItems.push({
            text: `Edit ${this.note.type}`,
            icon: "fal fa-fw fa-edit",
            onClick: () => this.editNote().subscribe(),
        });

        menuItems.push({
            text: `Delete ${this.note.type}`,
            icon: "fal fa-fw fa-trash-alt",
            onClick: () => this.deleteNote().subscribe(),
        });

        const menu: IAdaptMenuItem = {
            ...MenuComponent.SmallRootMenu,
            items: menuItems,
        };
        this.actionMenu = [menu];
    }

    @Autobind
    public editNote() {
        const dialogData: IEditSimpleValueBreezeEntityDialogData = {
            title: `Edit ${this.note.type}`,
            entities: [{
                label: this.note.type,
                entity: this.note,
                fieldName: "content",
                type: SimpleValueType.RichText,
            }],
            saveOnClose: true,
        };
        return this.dialogService.open(EditSimpleValueBreezeEntityDialogComponent, dialogData);
    }

    @Autobind
    public deleteNote() {
        const confirmationDialogData: IConfirmationDialogData = {
            title: `Delete ${this.note.type}`,
            message: `Are you sure you want to delete this ${this.note.type.toLowerCase()}?`,
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
        };

        return this.dialogService.openConfirmationDialogWithBoolean(confirmationDialogData).pipe(
            filter((confirmDelete) => !!confirmDelete),
            switchMap(() => this.meetingsService.remove(this.note)),
            switchMap(() => this.meetingsService.saveEntities([this.note])),
        );
    }
}

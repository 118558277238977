import { Injectable } from "@angular/core";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { MeetingAgendaTemplate } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-template";
import { WorkflowConnection } from "@common/ADAPT.Common.Model/organisation/workflow-connection";
import { map, of, switchMap } from "rxjs";
import { take, tap } from "rxjs/operators";
import { IMeetingWorkflowCustomData } from "../meetings/meeting-workflow-custom-data.interface";
import { MeetingsService } from "../meetings/meetings.service";
import { WorkflowService } from "./workflow.service";

export interface IWorkflowMeetingDetails {
    template?: MeetingAgendaTemplate;
}

export interface IRunWorkshopCustomData {
    meetingId?: number;
}

@Injectable({
    providedIn: "root",
})
export class WorkflowMeetingService {
    public constructor(
        private meetingsService: MeetingsService,
        private workflowService: WorkflowService,
    ) { }

    public createWorkflowMeeting(meeting: Meeting, template: MeetingAgendaTemplate) {
        return this.meetingsService.getAgendaItemsForMeetingAgendaTemplate(template.meetingAgendaTemplateId).pipe(
            switchMap((templateItems) => this.meetingsService.importAgendaItems(templateItems, meeting.meetingId)),
            switchMap(() => this.meetingsService.fetchArticlesForMeetingSuppData(meeting, template.meetingDescriptionArticleSlug)),
            tap(() => meeting.name = template.name),
            map(() => meeting.extensions.getAllMeetingEntities()),
        );
    }

    public getMeetingWorkflowConnection(meeting?: Meeting) {
        const meetingCustomData = meeting?.extensions.getCustomData<IMeetingWorkflowCustomData>();
        return meetingCustomData?.workflowConnectionId
            ? this.workflowService.getWorkflowConnectionById(meetingCustomData.workflowConnectionId)
            : of(undefined);
    }

    public getMeetingFromWorkflowConnection(workflowConnection: WorkflowConnection) {
        if (!workflowConnection.teamId) {
            // meetings can only be attached to a workflow connection with teamId - meeting needs a team!
            return of(undefined);
        }

        return this.meetingsService.canViewTeamMeetings(workflowConnection.teamId).pipe(
            take(1),
            switchMap((canRead) => canRead
                ? this.workflowService.getWorkflowCustomData<IRunWorkshopCustomData>(workflowConnection).pipe(
                    switchMap((customData) => customData.meetingId
                        ? this.meetingsService.getMeetingById(customData.meetingId)
                        : of(undefined)))
                : of(undefined)),
        );
    }
}

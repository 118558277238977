import { Injectable } from "@angular/core";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { ObjectiveBreezeModel } from "@common/ADAPT.Common.Model/organisation/objective";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { IDynamicNodeBuilder } from "@common/route/dynamic-node-builder";
import { NavigationUtilitiesService } from "@common/route/navigation-utilities.service";
import { teamExternalDashboardPageRoute } from "@org-common/lib/external-dashboard/external-dashboard-page/external-dashboard-page.route";
import { teamKanbanPageRoute } from "@org-common/lib/kanban/kanban-page/kanban-page.route";
import { MeetingsService } from "@org-common/lib/meetings/meetings.service";
import { teamActiveMeetingPageRoute } from "@org-common/lib/meetings/team-active-meeting-page/team-active-meeting-page.route";
import { TeamMeetingsWithCadencePageRoute } from "@org-common/lib/meetings/team-meetings-with-cadence-page/team-meetings-with-cadence-page.component";
import { teamEditObjectivePageRoute } from "@org-common/lib/objectives/edit-objective-page/edit-objective-page.route";
import { teamObjectivesPageRoute } from "@org-common/lib/objectives/objectives-page/objectives-page.route";
import { analyseTeamAssessmentSurveyPageRoute } from "@org-common/lib/survey/analyse-survey-page/analyse-survey-page.route";
import { manageTeamAssessmentsPageRoute } from "@org-common/lib/survey/team-assessment/manage-team-assessments-page/manage-team-assessments-page.route";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { teamValuesRoute } from "@org-common/lib/values-constitution/values-constitution-page/values-constitution-page.route";
import { lastValueFrom, Observable } from "rxjs";
import { teamConfigurationPageRoute } from "../configuration/team-configuration-page/team-configuration-page.route";
import { teamDashboardPageRoute } from "../team/team-dashboard-page/team-dashboard-page.route";

@Injectable()
export class TeamDynamicNavigationService implements IDynamicNodeBuilder {
    public activeNodeShouldBeRebuilt$: Observable<unknown>;

    public constructor(
        private navUtilitiesFactory: NavigationUtilitiesService,
        private commonDataService: CommonDataService,
        private teamsService: CommonTeamsService,
        private meetingsService: MeetingsService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        this.activeNodeShouldBeRebuilt$ = rxjsBreezeService.entityTypeChanged(Team);
    }

    public buildDynamicNodes() {
        return [
            this.buildDynamicTeamNode(),
            this.buildDynamicKanbanNode(),
            this.buildDynamicTeamMeetingsNode(),
            this.buildDynamicTeamActiveMeetingNode(),
            this.buildDynamicObjectivesNode(),
            this.buildDynamicExternalDashboardNode(),
            this.buildDynamicEditObjectiveNode(),
            this.buildDynamicValuesNode(),
            this.buildDynamicConfigureNode(),
            ...this.buildDynamicTeamAssessmentNodes(),
        ];
    }

    private buildDynamicTeamNode() {
        const dynamicTitleFunction = this.navUtilitiesFactory.createDynamicValueFunctionForOptions<Team>({
            entityIdParamName: "teamId",
            getEntityByParamCallback: (id) => isNaN(id)
                ? Promise.resolve(undefined)
                : lastValueFrom(this.teamsService.getTeamById(id)),
            getValue: (team) => team.name,
        });
        return this.navUtilitiesFactory.nodeBuilderForController(teamDashboardPageRoute.id)
            .setTitleDynamicValueCallback(dynamicTitleFunction)
            .build();
    }

    private buildDynamicKanbanNode() {
        return this.navUtilitiesFactory.nodeBuilderForController(teamKanbanPageRoute.id)
            .setDynamicParentController(teamDashboardPageRoute.id)
            .build();
    }

    private buildDynamicTeamMeetingsNode() {
        return this.navUtilitiesFactory
            .nodeBuilderForController(TeamMeetingsWithCadencePageRoute.id)
            .setDynamicParentController(teamDashboardPageRoute.id)
            .build();
    }

    private buildDynamicExternalDashboardNode() {
        return this.navUtilitiesFactory.nodeBuilderForController(teamExternalDashboardPageRoute.id)
            .setDynamicParentController(teamDashboardPageRoute.id)
            .build();
    }

    private buildDynamicTeamActiveMeetingNode() {
        const dynamicTitleFunction = this.navUtilitiesFactory.createDynamicValueFunctionForOptions<Meeting>({
            entityIdParamName: "meetingId",
            getEntityByParamCallback: (id) => isNaN(id)
                ? Promise.resolve(undefined)
                : lastValueFrom(this.meetingsService.getMeetingById(id)),
            getValue: (meeting) => meeting.name,
        });
        return this.navUtilitiesFactory.nodeBuilderForController(teamActiveMeetingPageRoute.id)
            .setTitleDynamicValueCallback(dynamicTitleFunction)
            .setDynamicParentController(TeamMeetingsWithCadencePageRoute.id, "Meetings")
            .build();
    }

    private buildDynamicTeamAssessmentNodes() {
        const teamAssessmentNode = this.navUtilitiesFactory
            .nodeBuilderForController(manageTeamAssessmentsPageRoute.id)
            .setDynamicParentController(teamDashboardPageRoute.id)
            .build();

        const analyseTeamAssessmentNode = this.navUtilitiesFactory
            .nodeBuilderForController(analyseTeamAssessmentSurveyPageRoute.id)
            .setDynamicParentController(manageTeamAssessmentsPageRoute.id)
            .build();

        return [teamAssessmentNode, analyseTeamAssessmentNode];
    }

    private buildDynamicObjectivesNode() {
        return this.navUtilitiesFactory.nodeBuilderForController(teamObjectivesPageRoute.id)
            .setDynamicParentController(teamDashboardPageRoute.id)
            .build();
    }

    private buildDynamicValuesNode() {
        return this.navUtilitiesFactory.nodeBuilderForController(teamValuesRoute.id)
            .setDynamicParentController(teamDashboardPageRoute.id)
            .build();
    }

    private buildDynamicConfigureNode() {
        return this.navUtilitiesFactory.nodeBuilderForController(teamConfigurationPageRoute.id)
            .setDynamicParentController(teamDashboardPageRoute.id)
            .build();
    }

    private buildDynamicEditObjectiveNode() {
        const dynamicTitleFunction = this.navUtilitiesFactory.createDynamicValueFunctionForOptions({
            entityIdParamName: "objectiveId",
            getEntityByParamCallback: (id: number) => this.commonDataService.getById(ObjectiveBreezeModel, id),
            getValue: (objective) => objective.title,
        });
        return this.navUtilitiesFactory.nodeBuilderForController(teamEditObjectivePageRoute.id)
            .setTitleDynamicValueCallback(dynamicTitleFunction)
            .setDynamicParentController(teamObjectivesPageRoute.id)
            .build();
    }
}

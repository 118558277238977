import { Injectable } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { UserService } from "@common/user/user.service";
import { BehaviorSubject, of, Subject, switchMap } from "rxjs";
import { tap } from "rxjs/operators";
import { IOAuthService } from "../../oauth/oauth.interface";
import { IOAuthUser } from "../calendar.interface";

@Injectable({
    providedIn: "root",
})
export class LocalCalendarIntegrationAuthService implements IOAuthService {
    public user: IOAuthUser | undefined;

    private isAuthenticatedSubject = new BehaviorSubject<boolean>(true);
    private errorMessageSubject = new Subject<string>();

    public constructor(private userService: UserService) {
        // automatically populate local user
        this.userService.currentPerson$.subscribe((person) => this.setUser(person));
    }

    public get isAuthenticated$() {
        return this.isAuthenticatedSubject.asObservable();
    }

    public get errorMessage$() {
        return this.errorMessageSubject.asObservable();
    }

    public login() {
        return of(true).pipe(
            switchMap(() => this.userService.getCurrentPerson()),
            tap((person) => this.setUser(person)),
        );
    }

    public logout() {
        return of(true).pipe(
            tap(() => this.user = undefined),
        );
    }

    private setUser(person?: Person) {
        this.user = person
            ? { displayName: person.fullName, email: person.getLoginEmail()?.value ?? "" }
            : undefined;
    }
}

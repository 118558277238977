import { Component, Input } from "@angular/core";
import { ContactTypeMetadata } from "@common/ADAPT.Common.Model/embed/contact-type";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { PersonContact } from "@common/ADAPT.Common.Model/person/person-contact";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DirectoryAuthService } from "@org-common/lib/directory-shared/directory-auth.service";
import { PersonProfileService } from "@org-common/lib/person-profile/person-profile.service";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { map, startWith, switchMap } from "rxjs/operators";

interface IContactType {
    contactType: ContactTypeMetadata;
    contacts: PersonContact[];
}

@Component({
    selector: "adapt-person-profile-contacts",
    templateUrl: "./person-profile-contacts.component.html",
})
export class PersonProfileContactsComponent extends BaseComponent {

    @Input() public set person(value: Person | undefined) {
        this.person$.next(value);
    }

    @Input() public editMode = false;
    @Input() public disabled = false;

    private person$ = new BehaviorSubject<Person | undefined>(undefined);
    public editPositionPermission = DirectoryAuthService.ManagePositionAndRoles;
    public contactTypes = ContactTypeMetadata.All;
    public contacts$: Observable<PersonContact[]>;
    public mappedContactTypes$: Observable<IContactType[]>;

    constructor(
        private personProfileService: PersonProfileService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        const entityChanged$ = rxjsBreezeService.entityTypeChangedInSave(PersonContact)
            .pipe(
                startWith(undefined),
            );

        this.contacts$ = combineLatest([entityChanged$, this.person$])
            .pipe(
                map(([_ec, person]) => person),
                emptyIfUndefinedOrNull(),
                switchMap((person) => this.personProfileService.getContacts(person.personId)),
            );

        this.mappedContactTypes$ = this.contacts$.pipe(
            map((contacts) => {
                return this.contactTypes
                    .map((contactType) => {
                        const mappedContact = {
                            contactType,
                            contacts: contacts.filter((contact) => contact.contactType === contactType.type),
                        };
                        return mappedContact;
                    })
                    .filter((contactType) => contactType.contacts.length > 0);
            }),
        );
    }

    @Autobind
    public editContacts() {
        return this.personProfileService.editContactsForPerson(this.person$.value!);
    }
}

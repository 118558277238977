<ng-container *adaptLoading="!initialised">
    <ng-container *ngIf="categoryScoreSummary || categoryIntro">

        <div class="survey-category-summary">
            <span class="survey-category-text">
                <ng-container *ngIf="categoryScoreSummary; else showIntro">
                    <h3>Result summary</h3>
                    {{categoryScoreSummary}}
                </ng-container>
                <ng-template #showIntro>
                    <div [froalaView]="categoryIntro"></div>
                </ng-template>
            </span>
            <adapt-display-category-percentage [text]="doughnutText"
                                               [percentage]="percentageScore"
                                               [surveyType]="latestSurvey?.surveyType"></adapt-display-category-percentage>
        </div>
    </ng-container>
    <div class="d-flex flex-row">
        <h3 class="question-column">Questions</h3>
        <div *ngIf="isXL && latestSurvey && responseGroups.length > 1"
             class="question-stats d-flex">
            <h4 class="w-50 text-center"
                *ngFor="let group of responseGroups">{{responseGroupLabel[group]}}</h4>
        </div>
    </div>
    <div *ngFor="let questionId of categoryQuestionIds"
         class="survey-category-question d-flex flex-column flex-xl-row align-items-center avoid-page-break-inside">
        <span class="question-column">{{questionId}}. {{surveyQuestions.getQuestion(questionId)}}</span>
        <adapt-display-survey-question-stats class="question-stats"
                                             [showQuestionResponseDistribution]="false"
                                             [survey]="survey"
                                             [surveyQuestions]="surveyQuestions"
                                             [forceInitialise]="forceInitialise"
                                             [questionId]="questionId"></adapt-display-survey-question-stats>
    </div>
</ng-container>

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { EditorViewDirective } from "./editor-view.directive";

@NgModule({
    imports: [CommonModule],
    exports: [EditorViewDirective],
    declarations: [EditorViewDirective],
})
export class AdaptEditorViewModule {}

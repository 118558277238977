import { DomUtilities } from "@common/lib/utilities/dom-utilities";
import { firstValueFrom, Observable, OperatorFunction } from "rxjs";
import { first, shareReplay } from "rxjs/operators";

export abstract class LazyLoadLibrary {
    private _loadAndInitialise$?: Observable<void>;

    protected constructor(
        private libraryUrl: string,
    ) { }

    /**
     * Load and initialise the library in question, only performing the loading
     * and initialisation logic the first time it is called.
     */
    public promiseToInitialise() {
        return firstValueFrom(this.initialisation$);
    }

    private get initialisation$() {
        if (!this._loadAndInitialise$) {
            this._loadAndInitialise$ = DomUtilities.loadJsScript(this.libraryUrl).pipe(
                this.initialise(),
                shareReplay(1),
            );
        }

        return this._loadAndInitialise$.pipe(
            first(),
        );
    }

    private initialise(): OperatorFunction<void, void> {
        return (source: Observable<void>) => this.doInitialisation(source);
    }

    /**
     * Perform initialisation on the loaded library by performing pipe operations
     * on the source observable
     */
    protected abstract doInitialisation(source: Observable<void>): Observable<void>;
}

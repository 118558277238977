import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptFeatureModule } from "@common/feature/feature.module";
import { AdaptImplementationKitModule } from "@common/implementation-kit/implementation-kit.module";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptCollapsibleModule } from "@common/ux/collapsible/collapsible.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptSeeMoreModule } from "@common/ux/see-more/see-more.module";
import { AdaptStyledDashboardElementModule } from "@common/ux/styled-dashboard-element/styled-dashboard-element.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptCallToActionModule } from "@org-common/lib/call-to-action/call-to-action.module";
import { AdaptFeaturesModule } from "@org-common/lib/features/features.modules";
import { AdaptCommonPurposeVisionModule } from "@org-common/lib/purpose-vision/common-purpose-vision.module";
import { DxPopupModule, DxScrollViewModule, DxTabPanelModule } from "devextreme-angular";
import { AdaptAuthorisationModule } from "../authorisation/authorisation.module";
import { EditGuidingPhilosophyDialogComponent } from "./edit-guiding-philosophy-dialog/edit-guiding-philosophy-dialog.component";
import { EditTeamPurposeDialogComponent } from "./edit-team-purpose-dialog/edit-team-purpose-dialog.component";
import { GuidingPhilosophyPageComponent } from "./guiding-philosophy-page/guiding-philosophy-page.component";
import { guidingPhilosophyRoute } from "./guiding-philosophy-page/guiding-philosophy-page.route";
import { TeamGuidanceDashboardElementComponent } from "./team-guidance-dashboard-element/team-guidance-dashboard-element.component";
import { TeamPurposeDashboardElementComponent } from "./team-purpose-dashboard-element/team-purpose-dashboard-element.component";

@NgModule({
    imports: [
        CommonModule,
        AdaptButtonModule,
        AdaptShellModule,
        AdaptHtmlEditorModule,
        AdaptCommonDialogModule,
        AdaptLoadingSpinnerModule,
        AdaptDateModule,
        AdaptSeeMoreModule,
        AdaptTooltipModule,
        DxTabPanelModule,
        DxPopupModule,
        DxScrollViewModule,
        AdaptCallToActionModule,
        AdaptDashboardElementModule,
        AdaptCommonPurposeVisionModule,
        AdaptImplementationKitModule,
        AdaptAuthorisationModule,
        AdaptFeaturesModule,
        AdaptFeatureModule,
        AdaptStyledDashboardElementModule,
        AdaptCollapsibleModule,
    ],
    declarations: [
        GuidingPhilosophyPageComponent,
        EditGuidingPhilosophyDialogComponent,
        EditTeamPurposeDialogComponent,
        TeamPurposeDashboardElementComponent,
        TeamGuidanceDashboardElementComponent,
    ],
    exports: [
        TeamGuidanceDashboardElementComponent,
    ],
})
export class AdaptGuidingPhilosophyModule {
    public static readonly Routes = [
        ...guidingPhilosophyRoute.routes,
    ];
}

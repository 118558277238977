import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Organisation } from "../organisation/organisation";
import { PersonProfileItem } from "./person-profile-item";

export class PersonProfileCategory extends BaseEntity<PersonProfileCategory> {
    public personProfileCategoryId!: number;
    public organisationId!: number;
    public label!: string;
    public ordinal!: number;

    public organisation!: Organisation;
    public personProfileItems!: PersonProfileItem[];
}

export const PersonProfileCategoryBreezeModel = new BreezeModelBuilder("PersonProfileCategory", PersonProfileCategory)
    .withIdField()
    .hasSource()
    .isOrganisationEntity()
    .withPluralName("PersonProfileCategories")
    .orderBy("ordinal")
    .alwaysFetchingNavigationProperty("personProfileItems")
    .build();

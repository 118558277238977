import { OrganisationPageRouteBuilder } from "../../route/organisation-page-route-builder";
import { ValuesConstitutionAuthService } from "../values-constitution-auth.service";
import { ValuesConstitutionPageComponent } from "./values-constitution-page.component";

export const valuesConstitutionRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("values-constitution-page", ValuesConstitutionPageComponent)
    .atOrganisationUrl("/values")
    .redirectToThisRouteFromOrganisationUrl("/culture/values")
    .redirectToThisRouteFromOrganisationUrl("/culture/values-constitution")
    .redirectToThisRouteFromOrganisationUrl("/culture/valuesConstitution")
    .reloadOnSearch(false)
    .verifyingAccess(ValuesConstitutionAuthService.ReadValuesConstitution)
    .withTitle("Values")
    .build();

export const teamValuesRoute = new OrganisationPageRouteBuilder<{ teamId: number }>()
    .usingNgComponent("values-constitution-page", ValuesConstitutionPageComponent)
    .atOrganisationUrl("/team/:teamId/values")
    .redirectToThisRouteFromOrganisationUrl("/people/teams/:teamId/values")
    .reloadOnSearch(false)
    .withTitle("Values")
    .build();

<adapt-dialog [maxWidth]="600">
    <h3 adapt-dialog-title>{{title}}</h3>

    <ng-container adapt-dialog-content>
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="label">Label</label>
                    <dx-text-box id="label"
                                 [(value)]="externalDashboard.name">
                        <dx-validator>
                            <dxi-validation-rule type="custom"
                                                 [validationCallback]="validateDuplicates"
                                                 message="Dashboard name already in use"></dxi-validation-rule>
                            <dxi-validation-rule [adaptEntity]="externalDashboard"
                                                 adaptEntityProperty="name"></dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
                <div class="form-group">
                    <label for="url">URL</label>
                    <dx-text-area id="url"
                                  [adaptValidateEntity]="externalDashboard"
                                  adaptValidateEntityProperty="url"
                                  [(value)]="externalDashboard.url"
                                  [inputAttr]="{ rows: 3 }"></dx-text-area>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="form-group"
                     *ngIf="externalDashboard.team as team">
                    <label>Who can view this dashboard?</label>
                    <dx-radio-group [items]="ReadAccessSelections"
                                    [(value)]="externalDashboard.isPublicRead"
                                    [disabled]="team.isPrivate"
                                    valueExpr="value"
                                    displayExpr="text"></dx-radio-group>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <dx-check-box text="Open in new tab"
                              [(value)]="externalDashboard.openInNewTab"></dx-check-box>
            </div>
        </div>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [discardConfirmationInstance]="instance"
                                   [saveIsDisabled]="!isValid">
    </adapt-standard-dialog-buttons>
</adapt-dialog>

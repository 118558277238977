import { Component, ElementRef, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { CanvasType, InputsCanvas } from "@common/ADAPT.Common.Model/organisation/inputs-canvas";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { startWith } from "rxjs";
import { StrategicInputsService } from "../strategic-inputs.service";

@Component({
    selector: "adapt-select-canvas",
    templateUrl: "./select-canvas.component.html",
})
export class SelectCanvasComponent extends BaseComponent implements OnChanges {
    @Input() public canvas?: InputsCanvas;
    @Output() public canvasChange = new EventEmitter<InputsCanvas>();

    @Input() public canvasTypes: CanvasType[] = [CanvasType.StrengthsWeaknessesTrends];

    public canvases: InputsCanvas[] = [];

    public readonly isMobile$ = this.responsiveService.isMobileSize$;

    public constructor(
        elementRef: ElementRef,
        rxjsBreezeService: RxjsBreezeService,
        private inputsService: StrategicInputsService,
        private responsiveService: ResponsiveService,
    ) {
        super(elementRef);

        rxjsBreezeService.entityTypeChanged(InputsCanvas).pipe(
            startWith(undefined),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updateDataSource());
    }

    public ngOnChanges() {
        this.updateDataSource();
    }

    public canvasDisplayExpr(canvas: InputsCanvas) {
        return canvas
            ? InputsCanvas.GetCanvasTitle(canvas.type, canvas.canvasDate)
            : "Undefined";
    }

    private updateDataSource() {
        this.inputsService.getAllCanvases().pipe(
            this.takeUntilDestroyed(),
        ).subscribe((canvases) => {
            this.canvases = canvases.filter((canvas) => this.canvasTypes.includes(canvas.type));
            if (!this.canvas && this.canvases.length > 0) {
                this.canvas = this.canvases[0];
                this.canvasChange.emit(this.canvas);
            }

            this.isInitialised = true;
        });
    }
}

<span class="badge round-pill pe-2 d-flex align-items-center text-nowrap gap-2"
      [ngClass]="[isCompactView ? 'compact' : '' , objectiveStatusMetadata?.statusClass ?? '']">
    <span [ngClass]="'status-icon-without-circle-' + objectiveStatus.toLowerCase()"
          class="invert-color"></span>
    {{objectiveStatusMetadata?.name}}
    <i *ngIf="showRightChevronDown"
       class="fal fa-chevron-down"></i>
    <i *ngIf="showRightChevronUp"
       class="fal fa-chevron-up"></i>
</span>

import { getMethodTransformer } from "./get-method-transformer";

/**
 * Adapted from https://github.com/andreypopp/autobind-decorator
 * @copyright 2015, Andrey Popp <8mayday@gmail.com>
 *
 * The decorator may be used on classes or methods. If used on a super class, it
 * will only apply to exactly that class - it won't be inherited by sub classes.
 * Autobinding does not come for free (~30% slower - see above readme), so it is
 * only recommended to be used where absolutely necessary.
 * ```
 * @Autobind
 * class FullBound {}
 *
 * class PartBound {
 *   @autobind
 *   method () {}
 * }
 * ```
 */
const boundMethod = getMethodTransformer((fn, thisValue) => fn.bind(thisValue));

export function Autobind(...args: any[]) {
    if (args.length === 1) {
        return boundClass(args[0]);
    } else {
        return boundMethod(args[0], args[1], args[2]);
    }
}

/**
 * Use boundMethod to bind all methods on the target.prototype
 */
function boundClass(target: any) {
    // (Using reflect to get all keys including symbols)
    let keys: PropertyKey[] = Object.getOwnPropertyNames(target.prototype);

    // use symbols if support is provided
    if (typeof Object.getOwnPropertySymbols === "function") {
        keys = keys.concat(Object.getOwnPropertySymbols(target.prototype));
    }

    keys.forEach((key) => {
        // Ignore special case target method
        if (key === "constructor") {
            return;
        }

        const descriptor = Object.getOwnPropertyDescriptor(target.prototype, key);

        // Only methods need binding
        if (descriptor && typeof descriptor.value === "function") {
            Object.defineProperty(target.prototype, key, boundMethod(target, key, descriptor));
        }
    });
    return target;
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptImplementationKitModule } from "@common/implementation-kit/implementation-kit.module";
import { AdaptCommonDialogModule } from "../ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptLoadingSpinnerModule } from "../ux/loading-spinner/loading-spinner.module";
import { DeepDiveComponent } from "./components/deep-dive/deep-dive.component";
import { WorkflowScormDialogComponent } from "./components/workflow-scorm-dialog/workflow-scorm-dialog.component";


@NgModule({
    imports: [
        CommonModule,
        AdaptCommonDialogModule,
        AdaptLoadingSpinnerModule,
        AdaptImplementationKitModule,
    ],
    declarations: [
        WorkflowScormDialogComponent,
        DeepDiveComponent,
    ],
    exports: [
        DeepDiveComponent,
    ],
})
export class AdaptCommonWorkflowModule { }

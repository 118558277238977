<ng-container *ngIf="(keyFunction$ | async) as keyFunction">
    <div class="d-flex flex-grow-1 flex-column">
        <adapt-link-key-function [keyFunction]="keyFunction"></adapt-link-key-function>
        <adapt-display-labels *ngIf="keyFunction.labelLocations?.length"
                              class="ms-1 mt-1"
                              [labelLocations]="keyFunction.labelLocations"></adapt-display-labels>
    </div>
    <adapt-link-person [person]="leaderPerson$ | async"
                       [imageOnly]="true"
                       imageSize="sm"></adapt-link-person>
</ng-container>

<div class="d-flex flex-column align-items-center">
    <div class="pb-3">
        <adapt-strategic-guidance area="vision"></adapt-strategic-guidance>
    </div>

    <ng-container *ngIf="guidingPhilosophy?.vision">
        <div class="mb-4 bt-1">
            <h4>Your vision statement</h4>
            <div class="mt-2 p-3 vision"
                 [froalaView]="guidingPhilosophy!.vision"></div>
        </div>
    </ng-container>

    <button *adaptIfAuthorised="CompassGuidingPhilosophyEdit"
            class="btn btn-primary px-4"
            [adaptBlockingClick]="recordVision"
            role="button">{{!!guidingPhilosophy?.vision ? "Update" : "Record"}} vision statement
    </button>
</div>

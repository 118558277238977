import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DxDateBoxModule, DxTextAreaModule, DxTextBoxModule, DxValidatorModule } from "devextreme-angular";
import { AdaptImplementationKitModule } from "../../implementation-kit/implementation-kit.module";
import { AdaptCommonDialogModule } from "../adapt-common-dialog/adapt-common-dialog.module";
import { AdaptCollapsibleModule } from "../collapsible/collapsible.module";
import { AdaptEntityValidatorModule } from "../entity-validator/entity-validator.module";
import { AdaptHtmlEditorModule } from "../html-editor/html-editor.module";
import { AdaptSelectCountryModule } from "../select-country/select-country.module";
import { AdaptTooltipModule } from "../tooltip.directive/tooltip.module";
import { EditSimpleValueBreezeEntityDialogComponent } from "./edit-simple-value-breeze-entity-dialog.component";


@NgModule({
    declarations: [EditSimpleValueBreezeEntityDialogComponent],
    exports: [EditSimpleValueBreezeEntityDialogComponent],
    imports: [
        CommonModule,
        AdaptCommonDialogModule,
        AdaptHtmlEditorModule,
        AdaptTooltipModule,
        AdaptEntityValidatorModule,
        AdaptSelectCountryModule,
        AdaptImplementationKitModule,
        AdaptCollapsibleModule,

        DxDateBoxModule,
        DxTextAreaModule,
        DxTextBoxModule,
        DxValidatorModule,
    ],
})
export class AdaptEditSimpleValueBreezeEntityDialogModule { }

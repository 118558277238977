import { Component, Injector } from "@angular/core";
import { EventSeries } from "@common/ADAPT.Common.Model/organisation/event-series";
import { EventTypePreset } from "@common/ADAPT.Common.Model/organisation/event-type";
import { IReviewRecurrencesMap } from "@org-common/lib/schedule/review-recurrences/review-recurrences.component";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { BehaviorSubject } from "rxjs";
import { CadenceStepBaseComponent } from "../cadence-step-base.component";

@WorkflowStepComponent("adapt-finish-cadence-step")
@Component({
    selector: "adapt-finish-cadence-step",
    templateUrl: "./finish-cadence-step.component.html",
})
export class FinishCadenceStepComponent extends CadenceStepBaseComponent {
    public workflowStepCompleted = new BehaviorSubject<boolean>(true);
    public recurrences?: IReviewRecurrencesMap;

    public constructor(injector: Injector) {
        super(injector);
    }

    public async workflowStepOnInit() {
        await super.workflowStepOnInit();

        this.recurrences = new Map<EventTypePreset, EventSeries>();
        for (const { eventSeries, eventTypePreset } of this.runData.scheduledPresets.values()) {
            this.recurrences.set(eventTypePreset!, eventSeries);
        }
    }
}

import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { KeyResult } from "@common/ADAPT.Common.Model/organisation/key-result";
import { KeyResultValue } from "@common/ADAPT.Common.Model/organisation/key-result-value";
import { ObjectiveComment } from "@common/ADAPT.Common.Model/organisation/objective-comment";
import { IChainComment } from "@org-common/lib/comment-chain/chain-comment/chain-comment.component";
import { IChainUpdate } from "@org-common/lib/comment-chain/chain-update/chain-update.component";

@Component({
    selector: "adapt-key-result-item-with-comments",
    templateUrl: "./key-result-item-with-comments.component.html",
    styleUrl: "./key-result-item-with-comments.component.scss",
})
export class KeyResultItemWithCommentsComponent implements OnChanges {
    @Input() public keyResult?: KeyResult;
    @Input() public isEditing = false;
    @Input() public comments?: IChainComment<ObjectiveComment>[];
    @Input() public updates?: IChainUpdate<KeyResultValue>[];
    @Output() public chainUpdateDeleted = new EventEmitter<IChainUpdate<KeyResultValue>>();
    @Output() public chainUpdateEdited = new EventEmitter<IChainUpdate<KeyResultValue>>();
    @Output() public keyResultUpdated = new EventEmitter<KeyResult>();

    public keyResultComments: IChainComment<ObjectiveComment>[] = [];
    public keyResultUpdates: IChainUpdate<KeyResultValue>[] = [];
    public commentsCollapsed = true;

    public ngOnChanges() {
        if (this.comments && this.keyResult) {
            this.keyResultComments = this.comments.filter((comment) => !!this.keyResult?.values &&
                this.keyResult.values.some((value) => value.dateTime.getTime() === comment.entity.dateTime.getTime()));
        } else {
            this.keyResultComments = [];
        }

        if (this.updates && this.keyResult) {
            this.keyResultUpdates = this.updates.filter((update) => update.updateEntity.keyResult === this.keyResult);
        }
    }
}

<adapt-confirmation-dialog (closed)="onDialogClosed($event)"
                           (initialised)="onInitialised($event)">
    <p>Are you sure you wish to delete this board?</p>

    <ng-container *adaptIfAuthorised="ViewTeamKanban;Entity:team; else noKanbanRead">
        <div *ngIf="board.items.length">
            The following actions are located in this board
            <ul class="ps-0">
                <li *ngFor="let item of board.items">
                    <adapt-item-link [item]="item"
                                     [showSummary]="true"></adapt-item-link>
                </li>
            </ul>
            <div class="mt-3">
                <b>Deleting this board will result in these {{board.items.length}} actions being deleted.</b>
            </div>
        </div>
    </ng-container>

    <ng-template #noKanbanRead>
        <p>
            <b>Deleting this board will result in all actions on the board being deleted.</b>
            We cannot display which actions will be deleted as you do not have read permission for this board.
        </p>
    </ng-template>
</adapt-confirmation-dialog>

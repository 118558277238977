import { Renderer2 } from "@angular/core";

export class AdaptRenderer {
    // TODO is there a better way to extend the functionality of Renderer2?
    // Can't make this an injectable and turn it into a service as a new
    // instance of Renderer2 is created for each directive (using a factory)
    public addClass = this.renderer.addClass.bind(this.renderer);
    public removeChild = this.renderer.removeChild.bind(this.renderer);
    public createText = this.renderer.createText.bind(this.renderer);
    public createElement = this.renderer.createElement.bind(this.renderer);
    public setAttribute = this.renderer.setAttribute.bind(this.renderer);
    public removeClass = this.renderer.removeClass.bind(this.renderer);

    public constructor(private renderer: Renderer2) {
    }

    /** Takes an array or space separated list of classes and applies it to the element */
    public addClasses(element: HTMLElement, classes: string | string[]) {
        if (typeof classes === "string") {
            classes = classes.split(/\s+/g);
        }

        for (const klass of classes) {
            this.renderer.addClass(element, klass);
        }
    }

    public prependElement(parentElement: HTMLElement, child: unknown) {
        const firstElement = parentElement.firstChild;
        this.renderer.insertBefore(parentElement, child, firstElement);
    }

    public createIconElement(iconClasses: string) {
        const iconElement = this.renderer.createElement("i");
        this.renderer.setAttribute(iconElement, "aria-hidden", "true");
        this.addClasses(iconElement, iconClasses);

        return iconElement;
    }

    public disable(element: HTMLElement) {
        this.renderer.setAttribute(element, "disabled", "");
    }

    public enable(element: HTMLElement) {
        this.renderer.removeAttribute(element, "disabled");
    }

    public isDisabled(element: HTMLElement) {
        return typeof element.getAttribute("disabled") === "string";
    }
}

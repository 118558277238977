import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ObjectiveStatus, ObjectiveStatusMetadata } from "@common/ADAPT.Common.Model/organisation/objective-status";
import { ObjectiveType } from "@common/ADAPT.Common.Model/organisation/objective-type";

export enum DisplayType {
    AO = "AO",
    QO = "QO",
    All = "All",
}

@Component({
    selector: "adapt-objectives-overview-summary",
    templateUrl: "./objectives-overview-summary.component.html",
    styleUrl: "./objectives-overview-summary.component.scss",
})
export class ObjectivesOverviewSummaryComponent implements OnChanges {
    public readonly ObjectiveStatusMetadata = ObjectiveStatusMetadata;
    public readonly DisplayType = DisplayType;

    @Input() public objectives: Objective[] = [];
    @Output() public displayTypeChange = new EventEmitter<DisplayType>();

    public overallProgress = 0;
    public statusObjectiveCount: Record<ObjectiveStatus, number> = this.resetCount();
    public currentDisplayType = DisplayType.All;

    public ngOnChanges() {
        const locallyFilteredObjectives = this.objectives.filter((o) =>
            this.currentDisplayType === DisplayType.All ||
            (this.currentDisplayType === DisplayType.AO
                ? o.type === ObjectiveType.Annual
                : o.type === ObjectiveType.Quarterly));
        const totalScore = locallyFilteredObjectives.reduce((total, next) => total + next.currentProgress, 0);
        this.overallProgress = locallyFilteredObjectives.length > 0
            ? totalScore / locallyFilteredObjectives.length
            : 0;

        this.statusObjectiveCount = this.resetCount();
        locallyFilteredObjectives.forEach((o) => this.statusObjectiveCount[o.status]++);
    }

    public get overallProgressText() {
        return `Overall progress: ${Math.round(this.overallProgress)}%`;
    }

    public displayTypeChanged(displayType: DisplayType) {
        this.currentDisplayType = displayType;
        this.ngOnChanges();
        this.displayTypeChange.emit(this.currentDisplayType);
    }

    public showStatus(metadata: ObjectiveStatusMetadata) {
        if (metadata.status === ObjectiveStatus.Closed || metadata.status === ObjectiveStatus.Complete) {
            // only closed title will be treated differently - not showing if there is no closed objective
            return this.statusObjectiveCount[metadata.status] > 0;
        }

        return true;
    }

    private resetCount() {
        return {
            [ObjectiveStatus.OnTrack]: 0,
            [ObjectiveStatus.Slow]: 0,
            [ObjectiveStatus.Stuck]: 0,
            [ObjectiveStatus.Complete]: 0,
            [ObjectiveStatus.Closed]: 0,
        };
    }
}

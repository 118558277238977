<adapt-dialog [maxWidth]="1200">
    <h3 adapt-dialog-title>{{isNew ? "Add" : "Edit"}} strategic theme</h3>
    <div adapt-dialog-content
         class="h-100">
        <div class="d-flex flex-column flex-lg-row gap-3">
            <div class="left-column d-block"
                 [adaptCollapsible]="ImplementationKitArticle.StrategicThemeIntro">
                <adapt-strategic-guidance [articleId]="ImplementationKitArticle.StrategicThemeIntro"
                                          area="strategic themes"></adapt-strategic-guidance>
            </div>

            <div class="d-flex flex-column mt-4 mt-lg-0 right-column">
                <label>Zone</label>
                <adapt-select-zone [(zone)]="theme.zone"
                                   [required]="true"
                                   [filter]="filterZone"></adapt-select-zone>

                <label class="mt-3">Theme name</label>
                <dx-text-box [(value)]="theme.name"
                             [maxLength]="60"
                             [adaptValidateEntity]="theme"
                             adaptValidateEntityProperty="name"></dx-text-box>

                <label class="mt-3">Description</label>
                <adapt-html-editor [(contents)]="theme.description"
                                   [minHeight]="300"
                                   (isValidChange)="isEditorValid = $event"
                                   forceSize="compact"></adapt-html-editor>
            </div>
        </div>
    </div>
    <div adapt-dialog-footer
         class="d-flex w-100">
        <button adaptButton="delete"
                *ngIf="!isNew"
                [adaptBlockingClick]="deleteTheme"></button>
        <adapt-standard-dialog-buttons [saveIsDisabled]="theme.entityAspect.hasValidationErrors || !isEditorValid"
                                       [saveBlockingClick]="saveAndClose"
                                       (cancelClick)="cancel()"
                                       cancelText="Cancel">
        </adapt-standard-dialog-buttons>
    </div>
</adapt-dialog>

import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { lastValueFrom } from "rxjs";
import { AutoUpdaterMergeStrategy } from "./auto-updater-merge-strategy";
import { BaseEntityAutoUpdater } from "./base-entity-auto-updater";

export class ModifiedEntityAutoUpdater extends BaseEntityAutoUpdater {
    public userIsAllowedToIgnoreServerUpdates = true;

    public hasConflicts(): boolean {
        return !!this.localEntity && !this.localEntity.entityAspect.entityState.isUnchanged();
    }

    public updatesShouldBeIntegrated(): boolean {
        return !!this.localEntity;
    }

    public promiseToIntegrateUpdates(mergeStrategy: AutoUpdaterMergeStrategy): Promise<IBreezeEntity | void> {
        const localChanges: { [field: string]: any } = {};
        if (this.localEntity) {
            Object.keys(this.localEntity.entityAspect.originalValues)
                .forEach((k) => localChanges[k] = this.localEntity![k]);
        }

        return lastValueFrom(this.commonDataService.rejectChanges(this.localEntity!))
            .then(() => lastValueFrom(this.commonDataService.getById(this.autoUpdaterTemplate.entityModel, this.entityUpdate.EntityId, true)))
            .then((updatedEntity: IBreezeEntity) => {
                let localFieldsToApply = Object.keys(localChanges);

                if (mergeStrategy === AutoUpdaterMergeStrategy.RemoteOverwritesLocal) {
                    localFieldsToApply = localFieldsToApply
                        .filter((f) => !this.entityUpdate.ModifiedProperties.some((p) => this.localFieldMatchesModifiedProperty(f, p)));
                }

                localFieldsToApply.forEach((k) => updatedEntity[k] = localChanges[k]);

                return updatedEntity;
            });
    }

    public postProcessHumanReadableSummary(summary: string): string {
        return `Has also modified ${summary}`;
    }

    private localFieldMatchesModifiedProperty(localField: string, modifiedProperty: string) {
        return localField.toLowerCase() === modifiedProperty.toLowerCase();
    }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AdaptFeatureModule } from "@common/feature/feature.module";
import { AdaptImplementationKitModule } from "@common/implementation-kit/implementation-kit.module";
import { registerGuidedTour } from "@common/lib/guided-tour/guided-tour-registrar";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptCollapsibleModule } from "@common/ux/collapsible/collapsible.module";
import { AdaptCollapsibleDashboardElementModule } from "@common/ux/collapsible-dashboard-element/collapsible-dashboard-element.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptIconModule } from "@common/ux/icon/icon.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptMenuModule } from "@common/ux/menu/menu.module";
import { AdaptProgressBarModule } from "@common/ux/progress-bar/progress-bar.module";
import { AdaptStyledDashboardElementModule } from "@common/ux/styled-dashboard-element/styled-dashboard-element.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AdaptCallToActionModule } from "@org-common/lib/call-to-action/call-to-action.module";
import { AdaptCommentChainModule } from "@org-common/lib/comment-chain/comment-chain.module";
import { AdaptLabellingModule } from "@org-common/lib/labelling/labeling.module";
import { AdaptTeamDashboardSharedModule } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.module";
import { DxCheckBoxModule, DxDataGridModule, DxDateBoxModule, DxListModule, DxMenuModule, DxNumberBoxModule, DxPopoverModule, DxScrollViewModule, DxSelectBoxModule, DxSliderModule, DxTagBoxModule, DxTemplateModule, DxTextBoxModule, DxTreeViewModule } from "devextreme-angular";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { AdaptDirectorySharedModule } from "../directory-shared/directory-shared.module";
import { AdaptFeaturesModule } from "../features/features.modules";
import { AdaptKanbanSharedModule } from "../kanban/kanban-shared.module";
import { WorkflowService } from "../workflow/workflow.service";
import { AdaptChatAiModule } from "./../../../../common/src/lib/chat-ai/chat-ai.module";
import { AddObjectiveLinkDialogComponent } from "./add-objective-link-dialog.component/add-objective-link-dialog.component";
import { AddObjectiveWorkflow } from "./add-objective-workflow";
import { DeleteKeyResultConfirmationDialogComponent } from "./delete-key-result-confirmation-dialog/delete-key-result-confirmation-dialog.component";
import { DeleteObjectiveConfirmationDialogComponent } from "./delete-objective-confirmation-dialog/delete-objective-confirmation-dialog.component";
import { DisplayKeyResultProgressComponent } from "./display-key-result-progress/display-key-result-progress.component";
import { DisplayObjectiveProgressComponent } from "./display-objective-progress/display-objective-progress.component";
import { DisplayObjectivesHierarchyComponent } from "./display-objectives-hierarchy.component/display-objectives-hierarchy.component";
import { AdaptDisplayTreeObjectiveModule } from "./display-tree-objective.component/display-tree-objective.module";
import { EditKeyResultDialogComponent } from "./edit-key-result-dialog/edit-key-result-dialog.component";
import { EditObjectiveDialogComponent } from "./edit-objective-dialog/edit-objective-dialog.component";
import { EditObjectivePageComponent } from "./edit-objective-page/edit-objective-page.component";
import { organisationEditObjectivePageRoute, teamEditObjectivePageRoute } from "./edit-objective-page/edit-objective-page.route";
import { KeyResultItemWithCommentsComponent } from "./key-result-item-with-comments/key-result-item-with-comments.component";
import { KeyResultListItemComponent } from "./key-result-list-item.component/key-result-list-item.component";
import { KeyResultsListComponent } from "./key-results-list/key-results-list.component";
import { MoveObjectiveDialogComponent } from "./move-objective-dialog/move-objective-dialog.component";
import { ObjectiveDashboardSummaryComponent } from "./objective-dashboard-summary.component/objective-dashboard-summary.component";
import { ObjectiveDescriptionFormationStepComponent } from "./objective-description-formation-step/objective-description-formation-step.component";
import { ObjectiveDialogTour, ObjectiveDialogTourIdentifier } from "./objective-dialog-tour";
import { ObjectiveFilterComponent } from "./objective-filter/objective-filter.component";
import { ObjectiveFilterService } from "./objective-filter/objective-filter.service";
import { ObjectiveListComponent } from "./objective-list.component/objective-list.component";
import { ObjectiveListItemComponent } from "./objective-list-item.component/objective-list-item.component";
import { ObjectiveMetadataStepComponent } from "./objective-metadata-step/objective-metadata-step.component";
import { ObjectiveTreeViewComponent } from "./objective-tree-view.component/objective-tree-view.component";
import { ObjectivesAuthService } from "./objectives-auth.service";
import { ObjectivesGridComponent } from "./objectives-grid/objectives-grid.component";
import { ObjectivesOverviewComponent } from "./objectives-overview/objectives-overview.component";
import { ObjectivesOverviewSummaryComponent } from "./objectives-overview-summary/objectives-overview-summary.component";
import { ObjectivesPageComponent } from "./objectives-page/objectives-page.component";
import { organisationObjectivesPageRoute, teamObjectivesPageRoute } from "./objectives-page/objectives-page.route";
import { AdaptObjectivesSharedModule } from "./objectives-shared.module";
import { ObjectivesTreeDashboardElementComponent } from "./objectives-tree-dashboard-element.component/objectives-tree-dashboard-element.component";
import { OrgObjectivesDashboardElementComponent } from "./org-objectives-dashboard-element/org-objectives-dashboard-element.component";
import { PersonalObjectivesDashboardElementComponent } from "./personal-objectives-dashboard-element/personal-objectives-dashboard-element.component";
import { ReorderObjectivesDialogComponent } from "./reorder-objectives-dialog/reorder-objectives-dialog.component";
import { ReviewObjectiveDialogComponent } from "./review-objective-dialog/review-objective-dialog.component";
import { SelectSupportingObjectiveComponent } from "./select-supporting-objective/select-supporting-objective.component";
import { SortableObjectiveGroupsComponent } from "./sortable-objective-groups/sortable-objective-groups.component";
import { UpdateKeyResultDialogComponent } from "./update-key-result-dialog/update-key-result-dialog.component";

@NgModule({
    imports: [
        CommonModule,
        AdaptShellModule,
        AdaptProgressBarModule,
        AdaptHtmlEditorModule,
        AdaptTooltipModule,
        FormsModule,
        ReactiveFormsModule,
        CollapseModule,
        DxListModule,
        DxSelectBoxModule,
        DxTagBoxModule,
        DxTemplateModule,
        DxTextBoxModule,
        DxNumberBoxModule,
        DxDateBoxModule,
        DxMenuModule,
        DxCheckBoxModule,
        DxTreeViewModule,
        DxSliderModule,
        DxScrollViewModule,
        DxDataGridModule,
        AdaptButtonModule,
        AdaptCommentChainModule,
        AdaptHtmlEditorModule,
        AdaptDirectorySharedModule,
        AdaptCommonDialogModule,
        AdaptAuthorisationModule,
        AdaptDateModule,
        AdaptLoadingSpinnerModule,
        AdaptEntityValidatorModule,
        AdaptObjectivesSharedModule,
        AdaptDisplayTreeObjectiveModule,
        AdaptCallToActionModule,
        AdaptTeamDashboardSharedModule,
        AdaptLabellingModule,
        AdaptDashboardElementModule,
        AdaptKanbanSharedModule,
        AdaptCollapsibleDashboardElementModule,
        AdaptFeaturesModule,
        AdaptFeatureModule,
        AdaptStyledDashboardElementModule,
        AdaptImplementationKitModule,
        AdaptCollapsibleModule,
        AdaptMenuModule,
        AdaptChatAiModule,
        AdaptIconModule,
        DxPopoverModule,
    ],
    declarations: [
        EditObjectiveDialogComponent,
        ObjectiveDashboardSummaryComponent,
        ObjectivesPageComponent,
        ObjectiveListItemComponent,
        KeyResultListItemComponent,
        ObjectiveListComponent,
        ObjectiveTreeViewComponent,
        DisplayObjectivesHierarchyComponent,
        EditObjectivePageComponent,
        EditKeyResultDialogComponent,
        UpdateKeyResultDialogComponent,
        ObjectiveFilterComponent,
        AddObjectiveLinkDialogComponent,
        DeleteObjectiveConfirmationDialogComponent,
        DeleteKeyResultConfirmationDialogComponent,
        DisplayKeyResultProgressComponent,
        PersonalObjectivesDashboardElementComponent,
        DisplayObjectiveProgressComponent,
        ObjectivesTreeDashboardElementComponent,
        MoveObjectiveDialogComponent,
        OrgObjectivesDashboardElementComponent,
        ReorderObjectivesDialogComponent,
        SortableObjectiveGroupsComponent,
        KeyResultsListComponent,
        KeyResultItemWithCommentsComponent,
        ObjectivesOverviewComponent,
        ObjectivesOverviewSummaryComponent,
        ObjectivesGridComponent,
        ReviewObjectiveDialogComponent,
        ObjectiveDescriptionFormationStepComponent,
        ObjectiveMetadataStepComponent,
        SelectSupportingObjectiveComponent,
    ],
    exports: [
        AdaptObjectivesSharedModule,
        ObjectivesTreeDashboardElementComponent,
        PersonalObjectivesDashboardElementComponent,
        OrgObjectivesDashboardElementComponent,
    ],
    providers: [
        ObjectiveFilterService,
    ],
})
export class AdaptObjectivesModule {
    public static readonly Routes = [
        ...teamEditObjectivePageRoute.routes,
        ...organisationEditObjectivePageRoute.routes,
        ...teamObjectivesPageRoute.routes,
        ...organisationObjectivesPageRoute.routes,
    ];

    public readonly workflowStepComponents = [
        ObjectiveDescriptionFormationStepComponent,
        ObjectiveMetadataStepComponent,
    ];

    constructor(authorisationService: AuthorisationService, workflowService: WorkflowService) {
        ObjectivesAuthService.registerAccess(authorisationService);
        registerGuidedTour(ObjectiveDialogTourIdentifier, ObjectiveDialogTour);
        workflowService.addPersistentWorkflow(WorkflowService.CreateWorkflowPersistenceConfig(AddObjectiveWorkflow));
    }
}

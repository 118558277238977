import { Component } from "@angular/core";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import { BehaviorSubject } from "rxjs";
import { ICreateValueData } from "../create-value-workflow";

@WorkflowStepComponent("adapt-create-value-meaning")
@Component({
    selector: "adapt-create-value-meaning",
    templateUrl: "./create-value-meaning.component.html",
    styleUrls: ["./create-value-meaning.component.scss"],
})
export class CreateValueMeaningComponent extends WorkflowStepComponentAdapter {
    public data?: ICreateValueData;

    public workflowStepCompleted = new BehaviorSubject<boolean>(false);
    public isEditorValid = true;

    public constructor() {
        super();
    }

    public async workflowStepOnInit() {
        this.data = this.workflowStep?.workflow.runData;
        if (!this.data) {
            throw new Error("Workflow data is not available");
        }

        this.validateEntities();
    }

    public validateEntities() {
        this.workflowStepCompleted.next((this.data?.value?.entityAspect.validateEntity() ?? false) && this.isEditorValid);
    }
}

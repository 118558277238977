<adapt-dialog [maxWidth]="600">
    <h3 adapt-dialog-title>Update {{keyResult.title}}</h3>
    <div adapt-dialog-content>
        <div *ngIf="currentKeyResultValue">
            <div class="current-value d-inline-flex align-items-center flex-wrap w-100">
                <adapt-link-person [personId]="currentKeyResultValue!.personId"
                                   [showImage]="true"></adapt-link-person>
                <span class="ms-1">had previously set the key result to {{currentKeyResultValue!.formattedValue}} on
                    {{currentKeyResultValue!.dateTime | adaptDate}}</span>
            </div>
            <h3>New Value
                <span [ngClass]="changeClass">
                    (
                    <i [ngClass]="changeIcon"></i>
                    {{difference$ | async}} )
                </span>
            </h3>
        </div>

        <div class="d-flex flex-column flex-md-row mt-3">
            <dx-slider #focus
                       class="flex-grow-1 mb-3 mb-md-0"
                       [min]="0"
                       [max]="keyResult.targetValue"
                       [step]="sliderStep"
                       [value]="keyResultValue.value"
                       (valueChange)="pushValueUpdate($event)"
                       [label]="{ visible: true }">
                <dxo-tooltip [enabled]="true"
                             position="bottom"
                             showMode="onHover"
                             [format]="tooltipChange"></dxo-tooltip>
            </dx-slider>

            <!-- Need w-auto to overwrite width:100% !important from 'input-group' or slider above won't be visible -->
            <div class="w-auto ms-md-2 d-flex justify-content-end input-group">
                <div *ngIf="keyResult.targetValuePrefix"
                     class="input-group-text">
                    <span>{{keyResult.targetValuePrefix}}</span>
                </div>
                <dx-number-box [adaptValidateEntity]="keyResultValue"
                               adaptValidateEntityProperty="value"
                               [showSpinButtons]="true"
                               [min]="0"
                               [max]="keyResult.targetValue"
                               [width]="valueWidth"
                               [(value)]="keyResultValue.value"
                               data-test="key-result-value-input"></dx-number-box>
                <div *ngIf="keyResult.targetValueSuffix"
                     class="input-group-text">
                    <span>{{keyResult.targetValueSuffix}}</span>
                </div>
            </div>
        </div>
        <div class="row mt-4"
             *ngIf="objectiveComment">
            <adapt-add-comment class="col-12"
                               [commentingPerson]="objectiveComment.person"
                               [(newComment)]="objectiveComment.comment"
                               [allowSave]="false"></adapt-add-comment>
        </div>

    </div>
    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="keyResultValue.value < 0 || keyResultValue.value > keyResult.targetValue || (!keyResultValue.value && keyResultValue.value !== 0)"
                                   [saveBlockingClick]="save"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>

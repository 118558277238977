import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from "@angular/core";
import { ToolbarContentLocation } from "../toolbar.interface";
import { ToolbarService } from "../toolbar.service";

@Directive({
    selector: "[adaptToolbarContent]",
})
export class ToolbarContentDirective implements AfterViewInit, OnDestroy {
    @Input() public ordinal?: number;
    @Input() public alignment?: "left" | "center" | "right";

    public constructor(
        private elementRef: ElementRef<HTMLElement>,
        private toolbarService: ToolbarService,
    ) {
    }

    private get contentLocation() {
        switch (this.alignment) {
            case "left":
                return ToolbarContentLocation.Left;
            case "center":
                return ToolbarContentLocation.Center;
            default:
                return ToolbarContentLocation.Right;
        }
    }

    public ngAfterViewInit() {
        const element = this.elementRef.nativeElement;
        this.toolbarService.addContent(element, {
            contentLocation: this.contentLocation,
            contentOrdinal: this.ordinal,
        });
    }

    public ngOnDestroy() {
        this.toolbarService.removeContent(this.elementRef.nativeElement);
    }
}

import { NgModule } from "@angular/core";
import { mapToCanActivate, RouterModule, Routes } from "@angular/router";
import { IdentityUxModule } from "@common/identity/ux/identity-ux.module";
import { AdaptCommonPagesModule } from "@common/lib/pages/pages.module";
import { AdaptBullseyeModule } from "@org-common/lib/bullseye/bullseye.module";
import { AdaptErrorPageModule } from "@org-common/lib/error-page/error-page.module";
import { AdaptExternalDashboardModule } from "@org-common/lib/external-dashboard/external-dashboard.module";
import { AdaptKanbanModule } from "@org-common/lib/kanban/kanban.module";
import { AdaptCommonMeetingsModule } from "@org-common/lib/meetings/common-meetings.module";
import { provideTeamMeetingsPageRoute } from "@org-common/lib/meetings/meetings.service";
import { TeamMeetingsWithCadencePageRoute } from "@org-common/lib/meetings/team-meetings-with-cadence-page/team-meetings-with-cadence-page.component";
import { AdaptMyOrganisationsModule } from "@org-common/lib/my-organisations/my-organisations.module";
import { AdaptObjectivesModule } from "@org-common/lib/objectives/objectives.module";
import { AdaptOrgCommonPagesModule } from "@org-common/lib/pages/pages.module";
import { BaseGuard } from "@org-common/lib/route/base.guard";
import { AdaptSearchCommonModule } from "@org-common/lib/search/search.module";
import { AdaptStrategicAnchorsModule } from "@org-common/lib/strategic-anchors/strategic-anchors.module";
import { AdaptStrategicGoalsModule } from "@org-common/lib/strategic-goals/strategic-goals.module";
import { AdaptStrategicInputsModule } from "@org-common/lib/strategic-inputs/strategic-inputs.module";
import { AdaptStrategyBoardModule } from "@org-common/lib/strategy-board/strategy-board.module";
import { AdaptSurveyModule } from "@org-common/lib/survey/survey.module";
import { AdaptValuesConstitutionModule } from "@org-common/lib/values-constitution/values-constitution.module";
import { AdaptAltoAboutModule } from "./about/about.module";
import { AdaptAccountModule } from "./account/account.module";
import { AppComponent } from "./app.component";
import { AdaptCoachAccessModule } from "./coach-access/coach-access.module";
import { AdaptConfigurationModule } from "./configuration/configuration.module";
import { AdaptAltoOrganisationModule } from "./organisation/organisation.module";
import { employeeDirectoryPageRoute } from "./person/employee-directory-page/employee-directory-page.component";
import { AdaptPersonModule } from "./person/person.module";
import { AdaptPurposeVisionModule } from "./purpose-vision/purpose-vision.module";
import { AdaptAltoTeamModule } from "./team/team.module";
import { AdaptAltoWorkflowsModule } from "./workflows/workflows.module";

const routes: Routes = [
    ...AdaptCommonMeetingsModule.Routes,
    ...AdaptOrgCommonPagesModule.Routes,
    ...AdaptSurveyModule.CommonRoutes,
    ...AdaptSurveyModule.OrganisationDiagnosticRoutes,
    ...AdaptPersonModule.Routes,
    ...AdaptAccountModule.Routes,
    ...IdentityUxModule.Routes,
    ...AdaptSearchCommonModule.Routes,
    ...AdaptAltoOrganisationModule.Routes,
    ...AdaptAltoTeamModule.Routes,
    ...AdaptConfigurationModule.Routes,
    ...AdaptErrorPageModule.Routes,
    ...employeeDirectoryPageRoute.routes,
    ...AdaptCommonPagesModule.Routes,
    ...AdaptAltoAboutModule.Routes,
    ...AdaptMyOrganisationsModule.Routes,
    ...AdaptAltoWorkflowsModule.Routes,
    ...AdaptKanbanModule.Routes,
    ...AdaptObjectivesModule.Routes,
    ...AdaptSurveyModule.TeamAssessmentRoutes,
    ...AdaptSurveyModule.ResilientAssessmentRoutes,
    ...AdaptSurveyModule.EngagementRoutes,
    ...AdaptPurposeVisionModule.Routes,
    ...AdaptValuesConstitutionModule.Routes,
    ...AdaptBullseyeModule.Routes,
    ...AdaptStrategyBoardModule.Routes,
    ...AdaptStrategicGoalsModule.Routes,
    ...AdaptStrategicInputsModule.Routes,
    ...AdaptStrategicAnchorsModule.Routes,
    ...AdaptExternalDashboardModule.Routes,
    ...AdaptCoachAccessModule.Routes,
    { path: "", pathMatch: "full", children: [], canActivate: mapToCanActivate([BaseGuard]) }, // BaseGuard will determine where to forward depends on login or not
    AppComponent.DefaultOrganisationRoute,
    { path: "**", redirectTo: "" }, // no match -> to home
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [
        provideTeamMeetingsPageRoute(TeamMeetingsWithCadencePageRoute),
    ],
})
export class AppRoutingModule { }

<dx-data-grid [dataSource]="objectives"
              [columnAutoWidth]="true"
              [allowColumnResizing]="true"
              [allowColumnReordering]="true"
              [rowAlternationEnabled]="true"
              [hoverStateEnabled]="true"
              [showRowLines]="true"
              [showBorders]="true"
              (onInitialized)="onGridInitialized($event)"
              [stateStoring]="dxGridWrapperHelper.stateStoringOptions"
              keyExpr="objectiveId">
    <dxo-load-panel [enabled]="true">
    </dxo-load-panel>

    <dxo-pager [showPageSizeSelector]="true"
               [allowedPageSizes]="[10, 25, 50, 100, 250]">
    </dxo-pager>
    <dxo-paging [pageSize]="50">
    </dxo-paging>
    <dxo-column-chooser [enabled]="true">
    </dxo-column-chooser>
    <dxo-group-panel [visible]="true">
    </dxo-group-panel>
    <dxo-header-filter [visible]="true">
    </dxo-header-filter>
    <dxo-filter-row [visible]="true">
    </dxo-filter-row>

    <dxo-export [enabled]="true"
                fileName="Objectives">
    </dxo-export>

    <dxi-column dataField="objectiveCode"
                dataType="string"
                caption="Objective"
                cssClass="vertically-centered"
                [calculateCellValue]="getObjectiveDisplayText"
                cellTemplate="objectiveCellTemplate"></dxi-column>
    <dxi-column dataField="typeCode"
                dataType="string"
                [visible]="false"
                caption="Type"></dxi-column>
    <dxi-column dataField="status"
                dataType="string"
                [calculateCellValue]="getObjectiveStatusName"
                cellTemplate="statusCellTemplate"></dxi-column>
    <dxi-column dataField="assigneePerson.fullName"
                dataType="string"
                alignment="left"
                caption="Assignee"
                cellTemplate="personCellTemplate"></dxi-column>
    <dxi-column dataField="currentProgress"
                dataType="number"
                alignment="left"
                caption="Score"
                [minWidth]="210"
                [calculateDisplayValue]="appendPercentage"
                cellTemplate="scoreCellTemplate"></dxi-column>
    <dxi-column dataField="parentObjectiveId"
                dataType="string"
                caption="Supporting"
                alignment="left"
                [groupIndex]="0"
                [calculateCellValue]="getObjectiveParentDisplayText"
                groupCellTemplate="parentGroupCellTemplate"
                cellTemplate="parentCellTemplate"></dxi-column>
    <dxi-column *ngIf="hasExternalObjectives"
                dataField="teamId"
                dataType="number"
                alignment="left"
                caption="Team"
                [calculateCellValue]="getTeamDisplayText"
                groupCellTemplate="teamGroupCellTemplate"
                cellTemplate="teamCellTemplate"></dxi-column>
    <dxi-column dataField="dueDate"
                dataType="date"
                [format]="DateFormat"
                [minWidth]="90"
                dataType="date"></dxi-column>
    <dxi-column caption=""
                dataType="string"
                dataField="actions"
                cellTemplate="actionCellTemplate"
                [allowSorting]="false"
                [allowReordering]="false"
                [allowHiding]="false"
                [allowFiltering]="false"
                [allowResizing]="false"
                [allowExporting]="false"
                [fixed]="true"
                fixedPosition="right"
                [width]="50">
    </dxi-column>

    <div *dxTemplate="let item of 'objectiveCellTemplate'"
         class="objective-link">
        <adapt-link-objective [objective]="item.data"
                              [showStatusIcon]="false"></adapt-link-objective>
    </div>
    <div *dxTemplate="let item of 'statusCellTemplate'"
         class="d-flex">
        <adapt-select-objective-status *ngIf="hasEditPermissions"
                                       [objective]="item.data"></adapt-select-objective-status>
        <adapt-display-objective-status *ngIf="!hasEditPermissions"
                                        [objectiveStatus]="item.data.status"></adapt-display-objective-status>
    </div>
    <div *dxTemplate="let item of 'personCellTemplate'">
        <adapt-link-person [person]="item.data.assigneePerson"
                           [showImage]="true"
                           imageSize="sm"></adapt-link-person>
    </div>
    <div *dxTemplate="let item of 'scoreCellTemplate'">
        <adapt-display-objective-progress [objective]="item.data"
                                          [scaleHeight]="1"></adapt-display-objective-progress>
    </div>
    <div *dxTemplate="let item of 'parentCellTemplate'"
         class="objective-link">
        <adapt-link-objective *ngIf="item.data.parentObjective"
                              [objective]="item.data.parentObjective"
                              [showStatusIcon]="false"></adapt-link-objective>
    </div>
    <div *dxTemplate="let item of 'parentGroupCellTemplate'"
         class="d-flex align-items-center gap-2">
        <ng-container *ngIf="getObjectiveByKey(item.data.key) as objective; else noParent">
            Supporting
            <adapt-link-objective [objective]="objective"
                                  [showStatusIcon]="false"></adapt-link-objective>
        </ng-container>
        <ng-template #noParent>
            <span>Not supporting any other objective</span>
        </ng-template>
    </div>
    <div *dxTemplate="let item of 'teamCellTemplate'">
        <adapt-link-objectives-page *ngIf="item.data.team"
                                    [team]="item.data.team"
                                    [view]="ObjectiveViewType.ObjectiveGridView"></adapt-link-objectives-page>
    </div>
    <div *dxTemplate="let item of 'teamGroupCellTemplate'">
        <adapt-link-objectives-page [team]="getTeamByKey(item.data.key)"
                                    [addObjectiveTextSuffix]="true"
                                    [view]="ObjectiveViewType.ObjectiveGridView"></adapt-link-objectives-page>
    </div>
    <div *dxTemplate="let item of 'actionCellTemplate'">
        <adapt-objective-actions [objective]="item.data"
                                 [currentPageTeamId]="teamId ?? null"></adapt-objective-actions>
    </div>
</dx-data-grid>

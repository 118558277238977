<ng-template #noDisplayContent>
    <ng-container *adaptLoading="!isInitialised">
        <div *ngIf="isEditing && zone === Zone.EconomicEngine && !hasThemes"
             class="alert alert-secondary">
            There are no strategic themes on your strategic working board - add a strategic theme using the button above.
        </div>
    </ng-container>
</ng-template>

<ng-container *ngIf="hasAnyContent; else noDisplayContent">
    <!-- Need this scroll view around the sortable for dragging if we have more themes within a zone or we can't possibly order those wrapped zone -->
    <dx-scroll-view [scrollByContent]="true"
                    direction="horizontal"
                    *ngIf="isEditing">
        <dx-sortable [ngClass]="(isDraggingTheme && isHorizontal)
                        ? 'dragging-horizontal-goals-zone'
                        :  isSingleColumn ? 'goals-zone-single-column' : 'goals-zone'"
                     handle="#categoryGripper"
                     [itemOrientation]="categoryDragOrientation"
                     [data]="orderedThemeNames"
                     [allowDropInsideItem]="false"
                     (onDragStart)="onThemeDragStart($event)"
                     (onDragChange)="preventDragToUncategorisedArea($event)"
                     (onReorder)="reorderThemes($event)">
            <adapt-collapsible-dashboard-element *ngFor="let theme of orderedThemeNames"
                                                 [name]="theme"
                                                 [isExpandable]="hasDisplayContent(theme)"
                                                 [isCollapsible]="hasDisplayContent(theme)"
                                                 [collapsed]="!hasDisplayContent(theme)"
                                                 [handleId]="isThemeDraggable ? 'categoryGripper' : undefined"
                                                 class="card category"
                                                 [draggable]="false"
                                                 [overrideStyleClass]="ZoneClass[zone!]"
                                                 [ngClass]="{'category-multi-columns': !isSingleColumn, 'category-single-column': isSingleColumn}">
                <ng-container *ngIf="getMenuItemsForTheme(theme) as menuItems"
                              adapt-collapsible-header-action-right>
                    <adapt-menu *ngIf="isEditing && menuItems[0].items?.length"
                                class="ms-auto me-2"
                                [items]="menuItems"
                                data-tour="theme-action-menu"></adapt-menu>
                </ng-container>
                <adapt-display-theme-info adapt-collapsible-header-action
                                          class="ms-1"
                                          *ngIf="!includesThemesView && getThemeByName(theme)?.description"
                                          [theme]="getThemeByName(theme)"></adapt-display-theme-info>

                <div class="d-flex flex-column pt-2 transparent-background"
                     *ngIf="hasDisplayContent(theme)">
                    <ng-container *ngIf="includesThemesView">
                        <div *ngIf="getThemeByName(theme)?.description as description; else blankDescription"
                             [froalaView]="description"></div>
                        <ng-template #blankDescription>
                            <span>No description. Edit theme to enter a description.</span>
                        </ng-template>
                    </ng-container>
                    <div [ngClass]="{'mt-2': includesThemesView}">
                        <!-- This this div outside dx-sortable to avoid having goals sticking to the edge of the drop zone -->
                    </div>
                    <div class="p-2 zone-container"
                         *ngIf="includesSWTInputsView && categorisedSWTInputs[theme] as zoneInputLocations"
                         [className]="ZoneClass[zone!]"
                         [ngClass]="{'mt-2': hasDisplayContent(theme, StrategicViewOption.SWTInputs),
                         'categorised-drag-border': isDraggingInputTheme === theme && draggingInputType === CanvasType.StrengthsWeaknessesTrends}">
                        <div class="container-label pb-1">
                            <div class="category-label">
                                <div class="fa fa-bolt"></div>
                                <b class="px-1">SWT</b>
                            </div>
                        </div>
                        <dx-sortable class="d-flex flex-column"
                                     handle="#inputGripper"
                                     [data]="zoneInputLocations"
                                     (onDragStart)="onInputDragStart($event)"
                                     (onDragEnd)="reorderInput($event)">
                            <ng-container *ngFor="let inputLocation of zoneInputLocations; let first = first">
                                <adapt-strategic-input class="input-swt"
                                                       [input]="inputLocation.input"
                                                       [showCanvasNameInContent]="true"
                                                       [isCollapsible]="true"
                                                       [isDetachable]="isEditing"
                                                       [showIcon]="true"
                                                       [tooltip]="InputTypeMetadata[inputLocation.input.type].tooltip"
                                                       tooltipPlacement="top-left"
                                                       [overrideStyleClass]="ZoneClass[zone!]"
                                                       (detach)="detachSWTInputLocation(inputLocation)"
                                                       [handleId]="(isEditing && zoneInputLocations.length > 1) ? 'inputGripper' : undefined"
                                                       [ngClass]="{'mt-2': !first}"></adapt-strategic-input>
                            </ng-container>
                        </dx-sortable>
                    </div>

                    <div class="p-2 zone-container"
                         *ngIf="includesCAInputsView && categorisedCAInputs[theme] as zoneInputLocations"
                         [className]="ZoneClass[zone!]"
                         [ngClass]="{'mt-2': hasDisplayContent(theme, StrategicViewOption.CAInputs),
                            'categorised-drag-border': isDraggingInputTheme === theme && draggingInputType === CanvasType.CompetitorAnalysis}">
                        <div class="container-label pb-1"
                             [ngClass]="{'single-ca': zoneInputLocations.length === 1}">
                            <div class="category-label">
                                <div class="fa fa-boxing-glove"></div>
                                <b class="px-1">Competitor analysis</b>
                            </div>
                        </div>
                        <dx-sortable class="d-flex flex-column"
                                     handle="#CAInputGripper"
                                     [data]="zoneInputLocations"
                                     (onDragStart)="onInputDragStart($event)"
                                     (onDragEnd)="reorderInput($event)">
                            <ng-container *ngFor="let inputLocation of zoneInputLocations; let first = first">
                                <adapt-strategic-input class="input-ca"
                                                       [input]="inputLocation.input"
                                                       [showCanvasNameInContent]="true"
                                                       [isCollapsible]="true"
                                                       [isDetachable]="isEditing"
                                                       [showIcon]="true"
                                                       [tooltip]="InputTypeMetadata[inputLocation.input.type].tooltip"
                                                       tooltipPlacement="top-left"
                                                       [overrideStyleClass]="ZoneClass[zone!]"
                                                       (detach)="detachCAInputLocation(inputLocation)"
                                                       [handleId]="(isEditing && zoneInputLocations.length > 1) ? 'CAInputGripper' : undefined"
                                                       [ngClass]="{'mt-2': !first}"></adapt-strategic-input>
                            </ng-container>
                        </dx-sortable>
                    </div>

                    <div class="p-2 zone-container"
                         *ngIf="includesBullseyeView && categorisedBullseyeStatements[theme] as zoneBullseyeStatementLocations"
                         [className]="ZoneClass[zone!]"
                         [ngClass]="{'mt-2': hasDisplayContent(theme, StrategicViewOption.Bullseye),
                            'categorised-drag-border': isDraggingBullseyeStatementTheme === theme}">
                        <div class="container-label pb-1">
                            <div class="category-label">
                                <div class="fa fa-crosshairs"></div>
                                <b class="px-1">Bullseye</b>
                            </div>
                        </div>
                        <dx-sortable class="d-flex flex-column"
                                     handle="#statementGripper"
                                     [data]="zoneBullseyeStatementLocations"
                                     (onDragStart)="onBullseyeStatementDragStart($event)"
                                     (onDragEnd)="reorderBullseyeStatement($event)">
                            <ng-container *ngFor="let statementLocation of zoneBullseyeStatementLocations; let first = first"
                                          [ngTemplateOutlet]="renderBullseyeStatement"
                                          [ngTemplateOutletContext]="{
                                            first,
                                            statementLocation,
                                            statementCount: zoneBullseyeStatementLocations.length}">
                            </ng-container>
                        </dx-sortable>
                    </div>
                </div>
            </adapt-collapsible-dashboard-element>
        </dx-sortable>
    </dx-scroll-view>
</ng-container>

<ng-template #renderBullseyeStatement
             let-first="first"
             let-statementLocation="statementLocation"
             let-statementCount="statementCount">
    <div class="bullseye-statement"
         [ngClass]="{'mt-2': !first}"
         [className]="ZoneClass[zone!]"
         *ngIf="statementLocation?.bullseyeStatement">
        <i *ngIf="isEditing && statementCount > 1"
           class="fal fa-grip-dots-vertical pe-1 gripper"
           id="statementGripper"></i>
        <i class="fal"
           [ngClass]="getQuadrantIcon(statementLocation.bullseyeStatement.quadrant)"
           [adaptTooltip]="getQuadrantTooltip(statementLocation.bullseyeStatement.quadrant)"></i>
        <h5 class="mb-0 flex-grow-1">{{statementLocation.bullseyeStatement.statement}}</h5>
        <button *ngIf="isEditing"
                class="text-black"
                adaptButton="deleteBorderless"
                (click)="detachBullseyeStatementLocation(statementLocation)"
                adaptTooltip="Detach from strategic working board"
                tooltipPlacement="bottom"></button>
    </div>
</ng-template>

import { Component, Input, OnDestroy, TemplateRef, ViewChild } from "@angular/core";
import { IAdaptLinkObject, RouteService } from "@common/route/route.service";
import { ShellUiService } from "@common/shell/shell-ui.service";
import { InitializedEvent } from "devextreme/ui/accordion";
import { merge, Observable } from "rxjs";
import { delay, startWith } from "rxjs/operators";
import { SearchService } from "../search.service";
import { IBaseSearchResult, ISearchResultMatch } from "../search-results.interface";
import { SearchRowBaseComponent } from "../search-row-base.component";

@Component({
    selector: "adapt-collapsible-search-row",
    templateUrl: "./collapsible-search-row.component.html",
    styleUrls: ["./collapsible-search-row.component.scss"],
})
export class CollapsibleSearchRowComponent<T extends IBaseSearchResult> extends SearchRowBaseComponent<T> implements OnDestroy {
    @Input() public href$?: Observable<IAdaptLinkObject | string>;
    @Input() public crumbTemplate?: TemplateRef<any>;
    @Input() public snippetTemplate?: TemplateRef<any>;

    @Input() public openInNewTab = false;
    @Input() public showFirstCrumb = true;

    @ViewChild("defaultSnippetTemplate") public defaultSnippetTemplate?: TemplateRef<any>;

    public constructor(
        protected searchService: SearchService,
        private shellUiService: ShellUiService,
        private routeService: RouteService,
    ) {
        super(searchService);
    }

    public getRouterLink(href: IAdaptLinkObject | string | null) {
        return this.routeService.parseRouterObject(href || undefined)!;
    }

    public accordionInitialised(event: InitializedEvent) {
        // weird wrapping occurs as accordions have fixed height, so update their dimensions on certain changes
        merge(
            // when sidebar changes state
            this.shellUiService.sidebarState$,
            // when query changes (includes toggling result groups)
            this.searchService.searchQuery$,
        ).pipe(
            // update dimensions straight away as well since the row can be
            // stuck at "height: 0px" when going back to the search page.
            startWith(undefined),
            delay(0),
            this.takeUntilDestroyed(),
        ).subscribe(() => event.component?.updateDimensions());
    }

    public asMatch(match: ISearchResultMatch) {
        return match as ISearchResultMatch;
    }
}

import { Injectable } from "@angular/core";
import { defer, lastValueFrom, Subject } from "rxjs";
import { switchMap } from "rxjs/operators";
import { IdentityService } from "../identity/identity.service";
import { Autobind } from "../lib/autobind.decorator/autobind.decorator";
import { Logger } from "../lib/logger/logger";
import { QueuedCaller } from "../lib/queued-caller/queued-caller";
import { RouteService } from "../route/route.service";
import { AdaptCommonDialogService } from "../ux/adapt-common-dialog/adapt-common-dialog.service";
import { ISidebar } from "./common-sidebar/sidebar.interface";
import { IBannerSpec, IShell } from "./shell.interface";

@Injectable({
    providedIn: "root",
})
export class ShellUiService {
    private queuedSidebar = new QueuedCaller<ISidebar>();
    private queuedShell = new QueuedCaller<IShell>();
    private rawContainerChange = new Subject<boolean>();

    protected readonly log = Logger.getLogger(this.constructor.name);

    public constructor(
        private dialogService: AdaptCommonDialogService,
        private identityService: IdentityService,
        private routeService: RouteService,
    ) {}

    public get sidebarState$() {
        return defer(() => this.queuedSidebar.promiseToWaitUntilCalleeSet()).pipe(
            switchMap((sidebar) => sidebar.state$),
        );
    }

    public get rawContainerChange$() {
        return this.rawContainerChange.asObservable();
    }

    public emitRawContainerChange(isRawContainer: boolean) {
        this.rawContainerChange.next(isRawContainer);
    }

    public get sidebarTab$() {
        return defer(() => this.queuedSidebar.promiseToWaitUntilCalleeSet()).pipe(
            switchMap((sidebar) => sidebar.activeTab$),
        );
    }

    @Autobind
    public async promiseToLogout() {
        const confirmLogout = await lastValueFrom(this.dialogService.openConfirmationDialogWithBoolean({
            title: "Logout",
            message: "Are you sure you wish to logout?",
            confirmButtonText: "Logout",
            cancelButtonText: "Cancel",
        }));

        if (confirmLogout) {
            try {
                await this.identityService.logout();
            } finally {
                this.routeService.gotoHome();
            }
        }
    }

    public registerSidebar(sidebar: ISidebar) {
        this.queuedSidebar.setCallee(sidebar);
    }

    public toggleSidebar() {
        this.queuedSidebar.call((sidebar) => sidebar.toggleSidebarOpenClosed());
    }

    public focusTab(tabId: string) {
        this.queuedSidebar.call((sidebar) => sidebar.focusTab(tabId));
    }

    public tabIsEnabled(tabId: string) {
        return this.queuedSidebar.promiseToCall((sidebar) => sidebar.tabIsEnabled(tabId));
    }

    public registerShell(shell: IShell) {
        this.queuedShell.setCallee(shell);
    }

    public setViewIsLoading(isLoading: boolean) {
        this.queuedShell.call((shell) => shell.setViewIsLoading(isLoading));
    }

    public setToolbarIsVisible(isVisible: boolean) {
        this.queuedShell.call((shell) => shell.setToolbarIsVisible(isVisible));
    }

    public setSidebarIsVisible(isVisible: boolean) {
        this.queuedShell.call((shell) => shell.setSidebarIsVisible(isVisible));
    }

    public setTitlePrefix(prefix?: string) {
        this.queuedShell.call((shell) => shell.setTitlePrefix(prefix));
    }

    public resetToDefaultPadding() {
        this.queuedShell.call((shell) => shell.setIsRawContainer(false));
    }

    public removeDefaultShellPadding() {
        this.queuedShell.call((shell) => shell.setIsRawContainer(true));
    }

    public addBanner(banner: IBannerSpec) {
        this.queuedShell.call((shell) => shell.addBanner(banner));
    }

    public removeBanner(banner: IBannerSpec) {
        this.queuedShell.call((shell) => shell.removeBanner(banner));
    }
}

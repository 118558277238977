<adapt-dialog [maxWidth]="480">
    <h3 adapt-dialog-title>Change Password for {{person.fullName}}</h3>

    <ng-container *adaptLoading="isLoading"
                  adapt-dialog-content>
        <div *ngIf="submitMessage"
             role="alert"
             class="alert"
             [ngClass]="{'alert-danger': isError, 'alert-success': !isError}"
             [innerHtml]="submitMessage"></div>

        <dx-validation-group *ngIf="hasPassword; else noPassword">
            <!-- Existing Password -->
            <div class="form-group row align-items-center">
                <label class="col-md-5"
                       for="inputExistingPassword">Existing Password</label>
                <div class="col-md-7">
                    <dx-text-box id="inputExistingPassword"
                                 [(value)]="oldPassword"
                                 (onValueChanged)="validateGroup()"
                                 mode="password"
                                 placeholder="Existing Password"
                                 data-test="existing-password-input">
                        <dx-validator>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
            </div>

            <!-- New Password -->
            <div class="form-group row align-items-center">
                <label class="col-md-5"
                       for="inputNewPassword">New Password</label>
                <div class="col-md-7">
                    <dx-text-box id="inputNewPasswor"
                                 [(value)]="newPassword"
                                 (onValueChanged)="validateGroup()"
                                 mode="password"
                                 placeholder="New Password"
                                 data-test="new-password-input">
                        <dx-validator>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                            <dxi-validation-rule type="stringLength"
                                                 [min]="5"
                                                 message="Password must be at least five characters"></dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
            </div>

            <!-- Confirm Password -->
            <div class="form-group row align-items-center">
                <label class="col-md-5"
                       for="inputConfirmPassword">Confirm Password</label>
                <div class="col-md-7">
                    <dx-text-box id="inputConfirmPassword"
                                 [(value)]="confirmPassword"
                                 (onValueChanged)="validateGroup()"
                                 mode="password"
                                 placeholder="Confirm Password"
                                 data-test="confirm-password-input">
                        <dx-validator>
                            <dxi-validation-rule type="compare"
                                                 [comparisonTarget]="passwordComparison"
                                                 message="Passwords do not match"></dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>
            </div>
        </dx-validation-group>

        <ng-template #noPassword>
            <div class="alert alert-warning m-0">
                <p>Your account does not have a password set.</p>
                <p>Clicking the button below will send a password reset email to your account's email address,
                    which will allow you to set a password for your account.</p>

                <button class="btn btn-primary w-100"
                        [adaptBlockingClick]="resetPassword"
                        data-test="send-password-reset-email">Send password reset email</button>
            </div>
        </ng-template>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="saveDisabled"
                                   [saveBlockingClick]="saveAndClose"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>

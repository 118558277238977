import { Injectable } from "@angular/core";
import { organisationEditObjectivePageRoute, teamEditObjectivePageRoute } from "./edit-objective-page/edit-objective-page.route";
import { ObjectiveViewType } from "./objective-view-type.enum";
import { organisationObjectivesPageRoute, teamObjectivesPageRoute } from "./objectives-page/objectives-page.route";

@Injectable({
    providedIn: "root",
})
export class ObjectivesRouteService {
    public getEditObjectivePageRoute(objectiveId: number, teamId?: number) {
        if (teamId) {
            return teamEditObjectivePageRoute.getRoute({ objectiveId, teamId });
        } else {
            return organisationEditObjectivePageRoute.getRoute({ objectiveId });
        }
    }

    public getObjectivePageRoute(teamId?: number, view?: ObjectiveViewType) {
        const searchParams = view
            ? { view }
            : {};

        if (teamId) {
            return teamObjectivesPageRoute.getRouteObject({ teamId }, searchParams);
        } else {
            return organisationObjectivesPageRoute.getRouteObject(undefined, searchParams);
        }
    }

    public gotoObjectivePageRoute(teamId?: number, view?: ObjectiveViewType) {
        const searchParams = view
            ? { view }
            : {};

        if (teamId) {
            return teamObjectivesPageRoute.gotoRoute({ teamId }, searchParams);
        } else {
            return organisationObjectivesPageRoute.gotoRoute(undefined, searchParams);
        }
    }

    public navigateToObjectivePageRoute(teamId?: number) {
        this.gotoObjectivePageRoute(teamId).subscribe();
    }

    public gotoEditObjectivePageRoute(objectiveId: number, teamId?: number) {
        if (teamId) {
            return teamEditObjectivePageRoute.gotoRoute({ objectiveId, teamId });
        } else {
            return organisationEditObjectivePageRoute.gotoRoute({ objectiveId });
        }
    }
}

import { DefaultBackFinishButtons, DefaultBackNextButtons, DefaultNextButtons, GuidedTour } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourUtils } from "@common/lib/guided-tour/guided-tour.utils";

export const CompetitorsAnalysisTour: GuidedTour = {
    steps: [{
        title: "Record competitor analysis",
        text: `<p>You can add a new competitor by clicking this button.</p>`,
        waitForAndAttachToElementSelector: `[data-tour="add-competitor"]`,
        attachTo: {
            on: "right",
        },
        modalOverlayOpeningPadding: 1,
        buttons: DefaultNextButtons,
        canClickTarget: false,
        showOn: () => !GuidedTourUtils.isElementVisibleWithDataTourSelector("edit-input"),
    }, {
        title: "Edit Competitor",
        text: `<p>You can edit the competitor information by clicking here.</p>`,
        waitForAndAttachToElementSelector: `[data-tour="edit-input"]`,
        buttons: DefaultBackNextButtons,
        attachTo: {
            on: "bottom",
        },
        canClickTarget: false,
        showOn: () => GuidedTourUtils.isElementVisibleWithDataTourSelector("edit-input"),
    }, {
        title: "Add win",
        text: `<p>Add a win by clicking here.</p>`,
        waitForAndAttachToElementSelector: `[data-tour="add-input-win"]`,
        buttons: DefaultBackNextButtons,
        attachTo: {
            on: "bottom",
        },
        canClickTarget: false,
        showOn: () => GuidedTourUtils.isElementVisibleWithDataTourSelector("add-input-win"),
    }, {
        title: "Add lose",
        text: `<p>Add a lose by clicking here.</p>`,
        waitForAndAttachToElementSelector: `[data-tour="add-input-lose"]`,
        buttons: DefaultBackNextButtons,
        attachTo: {
            on: "bottom",
        },
        canClickTarget: false,
        showOn: () => GuidedTourUtils.isElementVisibleWithDataTourSelector("add-input-lose"),
    }, {
        title: "Add compete",
        text: `<p>Add a compete by clicking here.</p>`,
        waitForAndAttachToElementSelector: `[data-tour="add-input-compete"]`,
        buttons: DefaultBackNextButtons,
        attachTo: {
            on: "bottom",
        },
        canClickTarget: false,
        showOn: () => GuidedTourUtils.isElementVisibleWithDataTourSelector("add-input-compete"),
    }, {
        title: "End of competitor analysis tour",
        text: `<p>This concludes the tour on recording competitors.</p>
        <p>Try adding some more competitors!</p>`,
        buttons: DefaultBackFinishButtons,
    }],
};

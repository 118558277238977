import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Objective } from "./objective";

export class ObjectiveLink extends BaseEntity<ObjectiveLink> {
    public objectiveLinkId!: number;
    public objective1Id!: number;
    public objective2Id!: number;

    public objective1!: Objective;
    public objective2!: Objective;
}

export const ObjectiveLinkBreezeModel = new BreezeModelBuilder("ObjectiveLink", ObjectiveLink)
    .hasSource()
    .isOrganisationEntity()
    .alwaysFetchingNavigationProperty("objective2")
    .build();

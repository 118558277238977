<adapt-theme-test-container label="Components"
                            *ngIf="isActivated">
    <div class="section">
        <h2>Outcomes</h2>
        <div class="d-flex flex-wrap">
            <ng-container *ngFor="let enum of OrganisationCategoryValue | keyvalue">
                <adapt-display-workflow-category class="p-2"
                                                 [category]="enum.value"></adapt-display-workflow-category>
            </ng-container>
        </div>

        <h2>Pathways</h2>
        <div class="pathways">
            <h3>Onboarding</h3>
            <h4>Not started</h4>
            <adapt-display-workflow-card *ngIf="workflowWelcomeOnboarding"
                                         [workflow]="workflowWelcomeOnboarding"></adapt-display-workflow-card>

            <h3>Understand Journey</h3>
            <h4>Survey in progress</h4>
            <adapt-display-workflow-card *ngIf="understandJourneySurveyInProgress"
                                         [connection]="understandJourneySurveyInProgress"></adapt-display-workflow-card>

            <h4>Survey in progress (current user outstanding response)</h4>
            <adapt-display-workflow-card *ngIf="understandJourneySurveyInProgressCurrentUserOutstanding"
                                         [connection]="understandJourneySurveyInProgressCurrentUserOutstanding"></adapt-display-workflow-card>

            <h4>Survey complete</h4>
            <adapt-display-workflow-card *ngIf="understandJourneySurveyComplete"
                                         [connection]="understandJourneySurveyComplete"></adapt-display-workflow-card>

            <h4>Survey completed, meeting not scheduled</h4>
            <adapt-display-workflow-card *ngIf="understandJourneySurveyCompleteMeetingNotScheduled"
                                         [connection]="understandJourneySurveyCompleteMeetingNotScheduled"></adapt-display-workflow-card>

            <h3>Others</h3>
            <h4>Up next</h4>
            <adapt-display-workflow-card *ngIf="workflowUpNext"
                                         [workflow]="workflowUpNext"></adapt-display-workflow-card>

            <h4>Started</h4>
            <adapt-display-workflow-card *ngIf="workflowStarted"
                                         [connection]="workflowStarted"></adapt-display-workflow-card>

            <h4>Meeting not scheduled</h4>
            <adapt-display-workflow-card *ngIf="workflowMeetingNotScheduled"
                                         [connection]="workflowMeetingNotScheduled"></adapt-display-workflow-card>

            <h4>Meeting scheduled</h4>
            <adapt-display-workflow-card *ngIf="workflowMeetingScheduled"
                                         [connection]="workflowMeetingScheduled"></adapt-display-workflow-card>

            <h4>Meeting running</h4>
            <adapt-display-workflow-card *ngIf="workflowMeetingRunning"
                                         [connection]="workflowMeetingRunning"></adapt-display-workflow-card>

            <h4>Prerequisites not satisfied</h4>
            <adapt-display-workflow-card *ngIf="workflowLocked"
                                         [workflow]="workflowLocked"></adapt-display-workflow-card>

            <h4>Planned</h4>
            <adapt-display-workflow-card *ngIf="workflowComingSoon"
                                         [workflow]="workflowComingSoon"></adapt-display-workflow-card>
        </div>
    </div>

    <div class="section">
        <h2>Dashboard element (HQ)</h2>
        <adapt-styled-dashboard-element name="Element title without link">Projected dashboard content goes here</adapt-styled-dashboard-element>

        <adapt-styled-dashboard-element name="Element title with a link"
                                    linkText="Link text"
                                        url="#">Projected dashboard content goes here
        </adapt-styled-dashboard-element>
    </div>

    <div class="section">
        <h2>Workflow Dialog</h2>
        <div>
            <button class="btn btn-primary"
                    (click)="showDialog()">Show 'Create a value' workflow dialog'
            </button>
        </div>
    </div>

    <div class="section">
        <h2>Tours</h2>
        <div>
            <button *ngFor="let tour of allTours"
                    class="btn btn-primary me-3 mb-3"
                    (click)="runWrapUpTour(tour)">Show {{tour}}</button>
        </div>
    </div>

</adapt-theme-test-container>

<adapt-collapsible-search-row #row
                              [result]="result"
                              [matches]="matches"
                              [href$]="href$">
    <div class="d-flex flex-grow-1 align-items-center justify-content-between">
        <div class="d-flex flex-grow-0 align-items-center me-2 text-muted">
            <div class="d-flex align-items-center result-icon"
                 [adaptTooltip]="inputTypeMetadata?.singularLabel">
                <i [ngClass]="inputTypeMetadata?.iconClass"
                   class="fa-inverse"></i>
            </div>
            <span class="ms-2 me-2">{{canvasName}}</span> &gt;
            <span class="ms-2 me-2">{{inputTypeMetadata?.pluralLabel}}</span> &gt;
        </div>

        <div class="flex-grow-1 text-break d-flex align-items-center">
            <adapt-highlighted-text [needle]="keyword"
                                    [haystack]="result.name"></adapt-highlighted-text>
        </div>
    </div>
</adapt-collapsible-search-row>

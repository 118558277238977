import { Component, Input, ViewChild } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { ConfigureKanbanBoardsComponent } from "@org-common/lib/configuration/common/configure-kanban-boards/configure-kanban-boards.component";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";

@Component({
    selector: "adapt-configure-team-kanban",
    templateUrl: "./configure-team-kanban.component.html",
})
export class ConfigureTeamKanbanComponent extends ConfigureFeatureBase {
    @Input() public configItem?: IConfigItem;
    @Input() public team!: Team;

    @ViewChild(ConfigureKanbanBoardsComponent) public kanbanConfigureComponent?: ConfigureKanbanBoardsComponent;

    public constructor(
        configurationService: ConfigurationService,
    ) {
        super(configurationService);
    }

    public async initialiseData() {
        return Promise.resolve();
    }
}

import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { DxSelectBoxModule, DxTextBoxModule } from "devextreme-angular";
import { SelectDocumentComponent } from "./select-document/select-document.component";
import { SelectDocumentDialogComponent } from "./select-document-dialog/select-document-dialog.component";
import { BoxProviderService } from "./storage-providers/box-provider.service";
import { CustomUrlProviderService } from "./storage-providers/custom-url-provider.service";
import { DropboxProviderService } from "./storage-providers/dropbox-provider.service";
import { FileUploaderProviderService } from "./storage-providers/file-uploader-provider.service";
import { GoogleDriveProviderService } from "./storage-providers/google-drive-provider.service";
import { OneDriveProviderService } from "./storage-providers/one-drive-provider.service";
import { STORAGE_PROVIDERS } from "./storage-providers/storage-provider.interface";

@NgModule({
    imports: [
        CommonModule,
        AdaptButtonModule,
        AdaptTooltipModule,
        AdaptCommonDialogModule,
        DxSelectBoxModule,
        DxTextBoxModule,
    ],
    declarations: [
        SelectDocumentComponent,
        SelectDocumentDialogComponent,
    ],
    exports: [
        SelectDocumentComponent,
    ],
})
export class AdaptDocumentSelectorModule {
    public static forRoot(): ModuleWithProviders<AdaptDocumentSelectorModule> {
        return {
            ngModule: AdaptDocumentSelectorModule,
            providers: [
                {
                    provide: STORAGE_PROVIDERS,
                    useClass: DropboxProviderService,
                    multi: true,
                },
                {
                    provide: STORAGE_PROVIDERS,
                    useClass: BoxProviderService,
                    multi: true,
                },
                {
                    provide: STORAGE_PROVIDERS,
                    useClass: OneDriveProviderService,
                    multi: true,
                },
                {
                    provide: STORAGE_PROVIDERS,
                    useClass: GoogleDriveProviderService,
                    multi: true,
                },
                {
                    provide: STORAGE_PROVIDERS,
                    useClass: FileUploaderProviderService,
                    multi: true,
                },
                {
                    provide: STORAGE_PROVIDERS,
                    useClass: CustomUrlProviderService,
                    multi: true,
                },
            ],
        };
    }

    public static forChild(): ModuleWithProviders<AdaptDocumentSelectorModule> {
        return {
            ngModule: AdaptDocumentSelectorModule,
        };
    }
}

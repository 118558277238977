import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { EntityExtensions } from "../entity-extensions.decorator";
import { DeepDiveExtensions } from "./deep-dive-extensions";

export enum DeepDiveGroup {
    Owner = "Owner",
    Employee = "Employee",
    CulturalLeader = "CulturalLeader",
    Leader = "Leader",
    TeamLeader = "TeamLeader",
}
export const DeepDiveGroupMapping = Object.keys(DeepDiveGroup).map((id: DeepDiveGroup) => ({ id, name: StringUtilities.camelToSentenceCase(DeepDiveGroup[id]) }));

export enum DeepDiveType {
    Scorm = "Scorm",
    Video = "Video",
}
export const DeepDiveTypeMapping = [
    {
        id: DeepDiveType.Scorm,
        name: "SCORM",
    },
    {
        id: DeepDiveType.Video,
        name: "Video",
    },
];

export class DeepDive extends BaseEntity<DeepDive> {
    public deepDiveId!: number;
    public type!: DeepDiveType;
    public title!: string;
    public location!: string;
    public group!: DeepDiveGroup;
    public image?: string;
    public description?: string;
    public durationMinimum?: number;
    public durationMaximum?: number;
    public lastUpdated?: Date;

    @EntityExtensions(DeepDiveExtensions)
    public extensions!: DeepDiveExtensions;
}

export const DeepDiveBreezeModel = new BreezeModelBuilder("DeepDive", DeepDive)
    .hasSource()
    .withUniqueKeys("location")
    .build();

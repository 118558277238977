import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { behaviourMetadata } from "@common/ADAPT.Common.Model/organisation/value-question";
import { IAdaptLinkObject } from "@common/route/route.service";
import { SearchService } from "@org-common/lib/search/search.service";
import { SearchRowBaseComponent } from "@org-common/lib/search/search-row-base.component";
import { Observable } from "rxjs";
import { teamValuesRoute, valuesConstitutionRoute } from "../../values-constitution/values-constitution-page/values-constitution-page.route";
import { CollapsibleSearchRowComponent } from "../collapsible-search-row/collapsible-search-row.component";
import { ISearchResultMatch, IValueQuestionSearchResultExtra, IValueSearchResult } from "../search-results.interface";

@Component({
    selector: "adapt-search-row-value",
    templateUrl: "./search-row-value.component.html",
})
export class SearchRowValueComponent<T extends IValueSearchResult> extends SearchRowBaseComponent<T> implements OnInit {
    public href$?: Observable<IAdaptLinkObject>;

    public defaultSnippetTemplate?: TemplateRef<any>;
    private defaultSnippetSetter = this.createThrottledUpdater<TemplateRef<any> | undefined>((tmp) => this.defaultSnippetTemplate = tmp);
    @ViewChild(CollapsibleSearchRowComponent) public set searchRow(cmp: CollapsibleSearchRowComponent<T>) {
        this.defaultSnippetSetter.next(cmp.defaultSnippetTemplate);
    }

    public constructor(
        protected searchService: SearchService,
    ) {
        super(searchService);
    }

    public ngOnInit() {
        super.ngOnInit();

        this.href$ = this.result.teamId
            ? teamValuesRoute.getRouteObject({ teamId: this.result.teamId })
            : valuesConstitutionRoute.getRouteObject();

        this.matches = this.excludeNameBreadcrumb(this.result.results);
    }

    public getBehaviour(match: ISearchResultMatch<IValueQuestionSearchResultExtra>) {
        switch (match.extra?.goodBehaviour) {
            case true:
                return behaviourMetadata.good;
            case false:
                return behaviourMetadata.bad;
            default:
                return undefined;
        }
    }

    public isBehaviour(match?: ISearchResultMatch<IValueQuestionSearchResultExtra>) {
        return !!match?.extra?.valuesV2 && match?.extra?.goodBehaviour !== null;
    }
}

import { Component, Input } from "@angular/core";

@Component({
    selector: "adapt-incomplete-work",
    templateUrl: "./incomplete-work.component.html",
    styleUrls: ["./incomplete-work.component.scss"],
})
export class IncompleteWorkComponent {
    @Input() public message?: string;
}

<adapt-filter-content (reset)="resetFilterOptions()"
                      [isDefault]="isDefaultFilter"
                      [urlParamNames]="KanbanFilterParamKeys">
    <div class="form-group"
         *ngIf="!!team">
        <label>Assignee</label>
        <adapt-select-person (personChange)="assigneeChanged($event)"
                             [person]="filter.assignee"
                             [teamGroup]="team"
                             data-test="filter-assignee"></adapt-select-person>
    </div>
    <div class="form-group">
        <label>Labels</label>
        <adapt-select-label [allowLabelCreation]="false"
                            [labels]="filter.labels ? filter.labels : []"
                            (labelsChange)="onLabelsChanged($event)"></adapt-select-label>
    </div>
    <div class="form-group">
        <label>Due Dates</label>
        <dx-select-box [dataSource]="itemDueDateDataSource"
                       [value]="filter.dueDate"
                       (valueChange)="onDueDateChanged($event)"
                       displayExpr="label"
                       valueExpr="value"
                       data-test="due-date-options"></dx-select-box>
    </div>
    <div class="form-group">
        <label>Created Within</label>
        <dx-select-box [dataSource]="DurationOptions"
                       [value]="filter.createdWithin?.slug"
                       (valueChange)="onCreatedDateChanged($event)"
                       displayExpr="text"
                       valueExpr="slug"
                       placeholder="Select timespan..."
                       [showClearButton]="true"
                       data-test="created-date-options"></dx-select-box>
    </div>
    <div class="form-group">
        <label>Updated within</label>
        <dx-select-box [dataSource]="DurationOptions"
                       [value]="filter.updatedWithin?.slug"
                       (valueChange)="onUpdatedDateChanged($event)"
                       displayExpr="text"
                       valueExpr="slug"
                       placeholder="Select timespan..."
                       [showClearButton]="true"
                       data-test="updated-date-options"></dx-select-box>
    </div>
</adapt-filter-content>

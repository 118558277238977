import { ActiveState, IActiveEntity, ITemporalEntity } from "@common/ADAPT.Common.Model/activeEntity.interface";
import moment from "moment";

export class ActiveEntityUtilities {

    public static isActive(entity: ITemporalEntity) {
        // this includes active now and pendings
        return ActiveEntityUtilities.getActiveState(entity) !== ActiveState.Inactive;
    }

    /**
     * Determines whether this entity is active (aka current).
     * @param {Object} entity The entity to assess (it must have both startDate and endDate properties).
     * @returns {bool} A boolean representation of whether the entity is active.
     */
    public static isActiveNow(entity: ITemporalEntity) {
        return ActiveEntityUtilities.dateExistsAndIsBeforeNow(entity.startDate)
            && ActiveEntityUtilities.dateDoesntExistOrIsAfterNow(entity.endDate);
    }

    public static getActiveState(entity: ITemporalEntity) {
        if (ActiveEntityUtilities.dateDoesntExistOrIsAfterNow(entity.endDate)) {
            return ActiveEntityUtilities.dateExistsAndIsBeforeNow(entity.startDate)
                ? ActiveState.Active
                : ActiveState.PendingActive;
        } else {
            return ActiveState.Inactive;
        }
    }

    public static isActiveAt(entity: ITemporalEntity, specifiedDate: Date) {
        return ActiveEntityUtilities.dateExistsAndIsBeforeSpecifiedDate(entity.startDate, specifiedDate)
            && ActiveEntityUtilities.dateDoesntExistOrIsAfterSpecifiedDate(entity.endDate, specifiedDate);
    }

    public static isInactiveAt(entity: ITemporalEntity, specifiedDate: Date) {
        return ActiveEntityUtilities.dateExistsAndIsBeforeSpecifiedDate(entity.endDate, specifiedDate);
    }

    /**
     * Determines whether this entity is inactive (aka not current).
     * @param {Object} entity The entity to assess (it must have both startDate and endDate properties).
     * @returns {bool} A boolean representation of whether the entity is inactive.
     */
    public static isInactiveNow(entity: ITemporalEntity) {
        return !ActiveEntityUtilities.isActiveNow(entity);
    }

    /**
     * Determines whether this entity is historic (aka in the past).
     * @param {Object} entity The entity to assess (it must have both startDate and endDate properties).
     * @returns {bool} A boolean representation of whether the entity is historic.
     */
    public static isHistoric(entity: ITemporalEntity) {
        return ActiveEntityUtilities.dateExistsAndIsBeforeNow(entity.startDate)
            && ActiveEntityUtilities.dateExistsAndIsBeforeNow(entity.endDate);
    }

    /**
     * Determines whether this entity is not historic (aka not in the past - could be current or in the future).
     * @param {Object} entity The entity to assess (it must have both startDate and endDate properties).
     * @returns {bool} A boolean representation of whether the entity is not historic.
     */
    public static isNotHistoric(entity: ITemporalEntity) {
        return !ActiveEntityUtilities.isHistoric(entity);
    }

    public static isCurrentForOrganisation(entity: IActiveEntity, organisationId: number) {
        return entity.isActive()
            && entity.organisationId === organisationId;
    }

    private static dateExistsAndIsBeforeNow(date: Date | undefined) {
        return !!date && moment(date).isSameOrBefore(moment());
    }

    private static dateExistsAndIsBeforeSpecifiedDate(date: Date | undefined, specifiedDate: Date) {
        return !!date && moment(date).isSameOrBefore(moment(specifiedDate));
    }

    private static dateDoesntExistOrIsAfterNow(date: Date | undefined) {
        return !date || moment(date).isAfter(moment());
    }

    private static dateDoesntExistOrIsAfterSpecifiedDate(date: Date | undefined, specifiedDate: Date) {
        return !date || moment(date).isAfter(moment(specifiedDate));
    }
}

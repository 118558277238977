<div class="row flex-lg-nowrap m-0"
     [ngClass]="{
         'full-page-columns': fullPage || useFullPageColumns,
         'flush-columns': removeColumnPadding
     }">
    <!-- 'left-column' class here is used by base-scroll-persisting.component.ts to search for scrollable container
        if the component instance wants to persist scroll location. Please also change base.component.ts
        if the class is to be removed or renamed.
    -->
    <!-- class name string defined in shell-style.constants.ts -->
    <div #leftColumn
         class="column left-column"
         [ngClass]="leftColumnClasses"
         [class.empty]="leftColumnIsEmpty"
         [class.only-column]="rightColumnIsEmpty">
        <ng-content select="[left-column]"></ng-content>
    </div>

    <!-- 'right-column' is used by base-scroll-persisting.component.ts as above to restore scroll position of component -->
    <div #rightColumn
         class="column right-column"
         [ngClass]="rightColumnClasses"
         [class.empty]="rightColumnIsEmpty"
         [class.only-column]="leftColumnIsEmpty">
        <ng-content select="[right-column]"></ng-content>
    </div>
</div>

<div *adaptLoading="!initialised"
     class="chart-wrapper">
    <dx-chart class="chart-dimension"
              [dataSource]="chartData"
              [rotated]="presentResponseGroups.length > 1"
              [customizePoint]="customiseChartPoint"
              (onPointClick)="onPointClicked($event)"
              (onDone)="updateChartDimensionAndSubscribeSizeChange($event)">
        <dxo-title text="{{surveyName}} scores by {{analyseTargetLabel.toLowerCase()}}"
                   *ngIf="showTitle">
            <dxo-font [size]="22"
                      [weight]="400"></dxo-font>
        </dxo-title>
        <dxo-animation [enabled]="false"></dxo-animation>
        <dxo-common-series-settings>
            <dxo-label [visible]="presentResponseGroups.length > 1"
                       position="inside"
                       backgroundColor="#00000000"
                       [horizontalOffset]="-2000"
                       [customizeText]="customiseChartText"></dxo-label>
        </dxo-common-series-settings>
        <dxi-series *ngIf="presentResponseGroups.indexOf(ResponseGroup.All) >= 0"
                    type="bar"
                    [tag]="ResponseGroup.All"
                    valueField="All"
                    [argumentField]="!isSmallWidth ? 'category' : 'categoryShortName'"
                    name="Category Average">
        </dxi-series>
        <dxi-series *ngIf="presentResponseGroups.indexOf(ResponseGroup.Leader) >= 0"
                    type="bar"
                    [tag]="ResponseGroup.Leader"
                    valueField="Leader"
                    [argumentField]="!isSmallWidth ? 'category' : 'categoryShortName'"
                    name="Leaders Average">
        </dxi-series>
        <dxi-series *ngIf="presentResponseGroups.indexOf(ResponseGroup.NonLeader) >= 0"
                    type="bar"
                    [tag]="ResponseGroup.NonLeader"
                    valueField="NonLeader"
                    [argumentField]="!isSmallWidth ? 'category' : 'categoryShortName'"
                    name="Employees Average">
        </dxi-series>
        <dxi-value-axis [allowDecimals]="false"
                        [valueMarginsEnabled]="false"
                        [showZero]="true"
                        [visualRange]="[0, 100]"
                        visualRangeUpdateMode="keep">
            <dxo-title *ngIf="!isSmallWidth"
                       text="Percentage score"></dxo-title>
            <dxo-label [customizeText]="pointToPercentage"></dxo-label>
        </dxi-value-axis>
        <dxo-argument-axis [inverted]="presentResponseGroups.length > 1">
            <dxo-title *ngIf="!isSmallWidth"
                       text="{{analyseTargetLabel}}"></dxo-title>
            <dxo-label [overlappingBehavior]="isSmallWidth ? 'rotate' : 'none'"
                       [customizeText]="customiseXAxisText">
                <dxo-font [size]="14"></dxo-font>
            </dxo-label>
        </dxo-argument-axis>
        <dxo-tooltip [enabled]="true"
                     [customizeTooltip]="customiseChartTooltip"></dxo-tooltip>
        <dxo-legend [visible]="false"></dxo-legend>
    </dx-chart>
</div>

import { AfterViewChecked, AfterViewInit, Directive, ElementRef, OnDestroy } from "@angular/core";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ScrollPersistenceHelper } from "./scroll-persistence-helper";

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class BaseScrollPersistingComponent extends BaseComponent implements OnDestroy, AfterViewInit, AfterViewChecked {
    private scrollPersistenceHelper: ScrollPersistenceHelper;

    constructor(protected elementRef: ElementRef) {
        super(elementRef);

        this.scrollPersistenceHelper = new ScrollPersistenceHelper(elementRef);
    }

    public ngOnDestroy() {
        super.ngOnDestroy();

        this.scrollPersistenceHelper.onDestroy();
    }

    public ngAfterViewInit() {
        this.scrollPersistenceHelper.afterViewInit();
    }

    public ngAfterViewChecked() {
        this.scrollPersistenceHelper.afterViewChecked();
    }

    /**
     * Calling this will result in the scroll position of the parent mainview/left/right-column to be remembered
     * till session refresh.
     * @param key The key use to store the scroll position - gives you control over if all component of the same type
     *  share a single position or component's input.
     */
    protected rememberScrollPosition(key: string) {
        this.scrollPersistenceHelper.rememberScrollPosition(key);
    }
}

import { Component, Input, ViewChild } from "@angular/core";
import { Account } from "@common/ADAPT.Common.Model/account/account";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { DisplayHistoricalInvoicesComponent } from "@common/payment-processing/display-historical-invoices/display-historical-invoices.component";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { AccountService } from "@org-common/lib/organisation/account/account.service";
import { Observable, of } from "rxjs";

@Component({
    selector: "adapt-configure-billing",
    templateUrl: "./configure-billing.component.html",
})
export class ConfigureBillingComponent extends ConfigureFeatureBase {
    @Input() public configItem?: IConfigItem;

    public readonly ProductLabel = AdaptClientConfiguration.AdaptProjectLabel;
    public account$: Observable<Account | undefined> = of(undefined);

    @ViewChild(DisplayHistoricalInvoicesComponent) private invoicesComponent?: DisplayHistoricalInvoicesComponent;

    constructor(
        configurationService: ConfigurationService,
        public accountService: AccountService,
    ) {
        super(configurationService);

        accountService.accountStatusChanged$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.invoicesComponent?.ngOnChanges()); // this will update the invoices collection
    }

    @Autobind
    public async initialiseData() {
        this.account$ = this.accountService.getAccount();
    }
}

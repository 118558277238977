<adapt-dialog [maxWidth]="850">
    <h3 adapt-dialog-title>{{title}}</h3>
    <ng-container adapt-dialog-content>
        <div class="form-group">
            <label for="name">Profile item name</label>
            <dx-text-box #focus
                         id="name"
                         placeholder="Enter profile item name"
                         [(value)]="profileItem!.label"
                         data-test="category-item-name-input">
                <dx-validator>
                    <dxi-validation-rule type="custom"
                                         [validationCallback]="validateDuplicates"
                                         message="Profile item name already in use"></dxi-validation-rule>
                    <dxi-validation-rule [adaptEntity]="profileItem"
                                         adaptEntityProperty="label"></dxi-validation-rule>
                </dx-validator>
            </dx-text-box>
        </div>

        <div class="form-group">
            <label for="type">Profile item type</label>
            <dx-select-box id="type"
                           [dataSource]="itemTypes"
                           [(value)]="profileItem.itemType"
                           displayExpr="label"
                           valueExpr="type">
                <div *dxTemplate="let obj of 'item'">
                    {{obj.label}}
                </div>
            </dx-select-box>
        </div>

        <div class="form-group">
            <dx-check-box text="Private"
                          [value]="profileItem.isPrivate"
                          (valueChange)="profileItem.isPrivate = $any($event)"
                          data-test="private-profile-item-checkbox">
            </dx-check-box>
            <small> (data entered will only be visible to this person and those with permission to view private profile data)</small>
        </div>
    </ng-container>
    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="!isValid"
                                   [saveBlockingClick]="saveAndClose"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>

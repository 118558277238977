import { Injector } from "@angular/core";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { IBreezeModel } from "@common/lib/data/breeze-model.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { of } from "rxjs";
import { IEntityAutoUpdaterTemplate } from "../entity-auto-updater-template.interface";
import { IEntityUpdate } from "../entity-updates.interface";
import { AutoUpdaterMergeStrategy } from "./auto-updater-merge-strategy";
import { IEntityAutoUpdater } from "./entity-auto-updater.interface";

export abstract class BaseEntityAutoUpdater implements IEntityAutoUpdater {
    public readonly model = this.autoUpdaterTemplate.entityModel;

    public abstract userIsAllowedToIgnoreServerUpdates: boolean;

    protected commonDataService: CommonDataService;

    private primedRelatedEntitiesInCache?: IBreezeEntity[];

    public constructor(
        protected injector: Injector,
        protected autoUpdaterTemplate: IEntityAutoUpdaterTemplate,
        public readonly entityUpdate: IEntityUpdate,
        public localEntity?: IBreezeEntity,
    ) {
        this.commonDataService = injector.get(CommonDataService);
    }

    public abstract hasConflicts(): boolean;
    public abstract updatesShouldBeIntegrated(): boolean;
    public abstract promiseToIntegrateUpdates(mergeStrategy: AutoUpdaterMergeStrategy): Promise<IBreezeEntity | void>;
    protected abstract postProcessHumanReadableSummary(summary: string): string;

    public get conflictsShouldBeAutomaticallyIntegrated() {
        return this.autoUpdaterTemplate.conflictsShouldBeAutomaticallyIntegrated ?? false;
    }

    public get htmlSummary(): string {
        if (!this.localEntity) {
            return "";
        }

        const summary = this.autoUpdaterTemplate.getHtmlSummary(this.localEntity, this.relatedEntitiesInCache);
        return `<span>${this.postProcessHumanReadableSummary(summary)}</span>`;
    }

    public get relatedEntitiesInCache(): IBreezeEntity[] {
        if (!this.primedRelatedEntitiesInCache) {
            this.primedRelatedEntitiesInCache = this.entityUpdate.RelatedEntities
                .map((re) => {
                    const model = this.modelForType(re.EntityType);
                    if (model) {
                        return this.commonDataService.getLocalModelEntityById(model, re.EntityId);
                    } else {
                        return undefined;
                    }
                })
                .filter((e) => !!e) as IBreezeEntity[];
        }

        return this.primedRelatedEntitiesInCache;
    }

    public primeAfterFetch() {
        if (this.autoUpdaterTemplate.primeCallback && this.localEntity) {
            if (!this.localEntity.entityAspect.entityState.isDetached()) {
                // only prime if the local entity has not been detached
                return this.autoUpdaterTemplate.primeCallback(this.localEntity);
            }
        }

        return of(undefined);
    }

    protected modelForType(type: string): IBreezeModel | undefined {
        return this.commonDataService.modelsByType[type];
    }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptTooltipModule } from "../tooltip.directive/tooltip.module";
import { CollapsibleDirective } from "./collapsible.directive";
import { CollapsibleButtonComponent } from "./collapsible-button/collapsible-button.component";

@NgModule({
    exports: [
        CollapsibleDirective,
    ],
    declarations: [
        CollapsibleDirective,
        CollapsibleButtonComponent,
    ],
    imports: [
        CommonModule,
        AdaptTooltipModule,
    ],
})
export class AdaptCollapsibleModule { }


export enum ConnectionType {
    Employee = "Employee",
    Stakeholder = "Stakeholder",
    Coach = "Coach",
}

export class ConnectionTypeLabel {
    private static singularLabels: { [type in ConnectionType]: string } = {
        Employee: "Employee",
        Stakeholder: "External Stakeholder",
        Coach: "Coach",
    };

    private static pluralLabels: { [type in ConnectionType]: string } = {
        Employee: "Employees",
        Stakeholder: "External Stakeholders",
        Coach: "Coaches",
    };

    public static singular(connectionType: ConnectionType) {
        return ConnectionTypeLabel.singularLabels[connectionType];
    }

    public static plural(connectionType: ConnectionType) {
        return ConnectionTypeLabel.pluralLabels[connectionType];
    }

    public static ordinal(connectionType: ConnectionType) {
        switch (connectionType) {
            case ConnectionType.Employee:
                return 0;
            case ConnectionType.Stakeholder:
                return 1;
            case ConnectionType.Coach:
                return 2;
            default:
                throw new Error("Unknown connection type (2)");
        }
    }
}

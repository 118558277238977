import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ICardPartialDetails } from "@common/payment-processing/card-partial-details.interface";
import { ICardOrganisationIdentifier, PaymentProcessingService } from "@common/payment-processing/payment-processing.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { EMPTY } from "rxjs";
import { catchError, finalize, tap } from "rxjs/operators";
import { IUpdateCreditCardInput, UpdateCreditCardDialogComponent } from "../update-credit-card-dialog/update-credit-card-dialog.component";

@Component({
    selector: "adapt-configure-credit-card",
    templateUrl: "./configure-credit-card.component.html",
})
export class ConfigureCreditCardComponent implements OnInit {
    @Input() public organisationId?: number;
    @Input() public eulaToken?: string;
    @Input() public allowEditing = true;
    @Output() public cardIsSet = new EventEmitter<boolean>();

    @Input() public cardDetails?: ICardPartialDetails;
    @Output() public cardDetailsChange = new EventEmitter<ICardPartialDetails>();

    public isEditMode = false;
    public errorMessage?: string;

    private stripeCardComplete: boolean = false;
    public isLoading = true;

    constructor(
        private paymentProcessingService: PaymentProcessingService,
        private dialogService: AdaptCommonDialogService,
    ) {
    }

    private get organisationIdentifier(): ICardOrganisationIdentifier {
        return {
            organisationId: this.organisationId,
            eulaToken: this.eulaToken,
        };
    }

    public ngOnInit() {
        this.initialiseData();
    }

    @Autobind
    public initialiseData() {
        if (!this.cardDetails) {
            this.getCardDetails().subscribe();
        } else {
            this.isLoading = false;
        }
    }

    public changesAreValid() {
        return !this.isEditMode ||
            !!(!this.errorMessage && this.stripeCardComplete && this.cardDetails && this.cardDetails.name);
    }

    public hasChanges() {
        return this.isEditMode;
    }

    public getCardDetails() {
        this.errorMessage = undefined;
        this.isLoading = true;

        return this.paymentProcessingService.getCreditCardPartialDetails(this.organisationIdentifier)
            .pipe(
                tap((cardDetails) => {
                    this.cardDetails = cardDetails;
                    this.cardDetailsChange.emit(cardDetails);
                    this.cardIsSet.emit(!!cardDetails);
                }),
                catchError((errorMessage: string) => {
                    this.errorMessage = errorMessage;
                    return EMPTY;
                }),
                finalize(() => this.isLoading = false),
            );
    }

    @Autobind
    public showUpdateCreditCardDialog() {
        const updateCreditCardInput: IUpdateCreditCardInput = {
            cardDetails: this.cardDetails,
            organisationIdentifier: this.organisationIdentifier,
        };

        return this.dialogService.open(UpdateCreditCardDialogComponent, updateCreditCardInput).pipe(
            // don't switchMap to avoid BlockingClick unsubscribeOnDestroy
            tap(() => this.getCardDetails().subscribe()),
        );
    }
}

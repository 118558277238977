import { Location } from "./location";

export class LocationExtensions {
    public constructor(private location: Location) {}

    public get label() {
        if (this.location.valueStream) {
            return this.location.valueStream.name;
        }

        // we just return empty strings for entities without a filled in navigation property
        return "";
    }

    public isLocationSet() {
        return this.location.valueStreamId;
    }

    public getKey() {
        let key = "";

        if (this.location.valueStreamId) {
            key = "valueStreamId==" + this.location.valueStreamId;
        } else {
            throw new Error("missing");
        }

        return key;
    }
}

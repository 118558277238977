import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptImplementationKitModule } from "@common/implementation-kit/implementation-kit.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptSeeMoreModule } from "@common/ux/see-more/see-more.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AdaptBullseyeModule } from "@org-common/lib/bullseye/bullseye.module";
import { AdaptCommonTeamsModule } from "@org-common/lib/teams/common-teams.module";
import { DxTextBoxModule } from "devextreme-angular";
import { MeetingBehavioursAgendaItemComponent } from "./meeting-behaviours-agenda-item/meeting-behaviours-agenda-item.component";

@NgModule({
    declarations: [
        MeetingBehavioursAgendaItemComponent,
    ],
    imports: [
        CommonModule,
        DxTextBoxModule,
        AdaptLoadingSpinnerModule,
        AdaptImplementationKitModule,
        AdaptButtonModule,
        AdaptBullseyeModule,
        AdaptHtmlEditorModule,
        AdaptSeeMoreModule,
        AdaptCommonTeamsModule,
        AdaptAuthorisationModule,
    ],
})
export class AdaptApplyGoodStewardshipWorkflowModule {
    // prevent being optimised by tree-shaker
    public readonly workflowStepComponents = [
        MeetingBehavioursAgendaItemComponent,
    ];
}

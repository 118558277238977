<div class="mt-3 offset-lg-3 col-lg-6 offset-md-2 col-md-8">
    <h1>Adapt platform customer license agreement</h1>

    <ng-container *adaptLoadingUntilFirstEmit="let isValidLink of isValidLink$">
        <ng-container *ngIf="isValidLink; else invalidLinkTemplate">

            <p role="alert"
               class="alert alert-info">
                {{requestorName}} has asked you to accept the adapt platform customer license agreement
                on behalf of {{organisationName}}. Please read it below, tick the
                checkbox and submit to signal your acceptance.
            </p>

            <adapt-eula></adapt-eula>

            <dx-check-box class="d-block mt-3"
                          text="I have read and agree to the terms of the customer license agreement"
                          [value]="eulaAccepted"
                          (valueChange)="eulaAccepted = $any($event)"
                          [disabled]="eulaAcceptanceSaved"></dx-check-box>

            <button class="mt-4"
                    adaptButtonIcon="ok"
                    [hidden]="eulaAcceptanceSaved"
                    [disabled]="!eulaAccepted"
                    [adaptBlockingClick]="acceptEula">Submit</button>

            <div *ngIf="eulaAcceptanceSaved"
                 role="alert"
                 class="mt-3 alert alert-info">
                Thank you for accepting the adapt platform customer license agreement. You will receive a
                confirmation email shortly.
            </div>
            <div *ngIf="errorMessage"
                 role="alert"
                 class="mt-3 alert alert-danger">
                {{errorMessage}}
            </div>
        </ng-container>
        <ng-template #invalidLinkTemplate>
            <div *ngIf="isEulaAccepted">
                Adapt platform customer license agreement has already been accepted
            </div>
            <div *ngIf="!isEulaAccepted">
                This is not a valid link.
            </div>
        </ng-template>
    </ng-container>
</div>

<adapt-dashboard-element *adaptIfAlto="false"
                         name="Values"
                         [url]="vcUrl$ | async"
                         data-test="values-constitution-dashboard-element">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
</adapt-dashboard-element>

<adapt-styled-dashboard-element *adaptIfAlto="true"
                            name="Values"
                            [url]="vcUrl$ | async"
                            linkText="View values"
                            data-test="values-constitution-dashboard-element">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
</adapt-styled-dashboard-element>

<ng-template #content>
    <adapt-values-constitution-list-view *ngIf="mode === 'list'"
                                         [team]="team$ | async"></adapt-values-constitution-list-view>
    <adapt-values-constitution-wide-view *ngIf="mode === 'wide'"></adapt-values-constitution-wide-view>
</ng-template>

import { Component, Inject, Input, OnInit } from "@angular/core";
import { CalendarIntegrationProvider } from "@common/ADAPT.Common.Model/organisation/organisation-detail";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { filter } from "rxjs/operators";
import { AuthorisationService } from "../../authorisation/authorisation.service";
import { ConfigurationAuthService } from "../../configuration/configuration-auth.service";
import { OAuthService } from "../../oauth/oauth.service";
import { CALENDAR_PROVIDERS, ICalendarProvider } from "../calendar.interface";

@Component({
    selector: "adapt-calendar-provider-select",
    templateUrl: "./calendar-provider-select.component.html",
})
export class CalendarProviderSelectComponent extends BaseComponent implements OnInit {
    @Input() public disabled = false;
    @Input() public horizontal = true;
    @Input() public showLogoutButton = false;
    @Input() public showDisableIntegration = true;

    public readonly projectLabel = AdaptClientConfiguration.AdaptProjectLabel;
    public readonly CalendarIntegrationProvider = CalendarIntegrationProvider;

    public hasConfigureAccess = false;
    public provider?: ICalendarProvider;
    public errorMessage?: string;

    public constructor(
        public oauthService: OAuthService,
        private authorisationService: AuthorisationService,
        @Inject(CALENDAR_PROVIDERS) public providers?: ICalendarProvider[],
    ) {
        super();

        oauthService.errorMessage$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((err) => this.errorMessage = err);

        // clear error when successfully authenticated
        oauthService.isAuthenticated$.pipe(
            filter((authed) => authed),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.errorMessage = undefined);

        oauthService.authProvider$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((provider) => this.provider = provider);
    }

    public async ngOnInit() {
        this.hasConfigureAccess = await this.authorisationService.promiseToGetHasAccess(ConfigurationAuthService.ConfigureOrganisation);
    }

    public get calendarProviders(): ICalendarProvider[] | undefined {
        // only show currently configured provider when no configure organisation access
        // cannot save provider without OrganisationDetail edit permission
        if (!this.hasConfigureAccess) {
            const provider = this.providers?.find((p) => p === this.provider);
            return provider ? [provider] : [];
        }

        return this.providers?.filter((provider) => {
            if (provider.id === CalendarIntegrationProvider.None) {
                return this.showDisableIntegration;
            }

            // show all options if no provider currently set
            // or only the current provider if provider set
            return !this.provider || this.provider === provider;
        });
    }
}

import { Directive, Host, Input, OnChanges, OnDestroy, Optional, Self, SimpleChanges } from "@angular/core";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { ValidationError, ValidationErrorsChangedEventArgs } from "breeze-client";
import { DxCheckBoxComponent, DxDateBoxComponent, DxNumberBoxComponent, DxSelectBoxComponent, DxSliderComponent, DxTextAreaComponent, DxTextBoxComponent } from "devextreme-angular";

interface IValidateComponent {
    option: (key: string, value: any) => void;
}

interface IOptionChangedEvent {
    name: string;
    value: any;
}

@Directive({
    selector: "[adaptValidateEntity][adaptValidateEntityProperty]",
})
export class ValidateEntityDirective implements OnChanges, OnDestroy {
    private static readonly IsValidOption = "isValid";
    private static readonly ValidationErrorOption = "validationError";

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("adaptValidateEntity") public validateEntity!: IBreezeEntity;
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("adaptValidateEntityProperty") public validateProperty!: string;

    private validateComponent?: IValidateComponent;
    private unsubscribeValidationErrorsChanged?: () => void;

    private isValid = true;
    private validationError?: { message: string };
    private isDestroyed = false;

    public constructor(
        @Host() @Self() @Optional() private hostTextBoxComponent?: DxTextBoxComponent,
        @Host() @Self() @Optional() private hostNumberBoxComponent?: DxNumberBoxComponent,
        @Host() @Self() @Optional() private hostSelectBoxComponent?: DxSelectBoxComponent,
        @Host() @Self() @Optional() private hostSliderComponent?: DxSliderComponent,
        @Host() @Self() @Optional() private hostCheckBoxComponent?: DxCheckBoxComponent,
        @Host() @Self() @Optional() private hostTextAreaComponent?: DxTextAreaComponent,
        @Host() @Self() @Optional() private hostDateBoxComponent?: DxDateBoxComponent,
    ) { }

    public ngOnDestroy() {
        this.unsubscribeValidationErrorsChanged?.();
        this.isDestroyed = true;
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.validateEntity || changes.validateProperty) {
            setTimeout(() => {
                // came across issue using this directive from old dialog where dx component instance are not initialised
                // (even in ngOnInit()) - but the instance is there in the next digest cycle
                if (this.validateEntity && this.validateEntity.entityAspect && this.validateProperty && !this.isDestroyed) {
                    const supportedDxComponents = [
                        this.hostTextBoxComponent,
                        this.hostNumberBoxComponent,
                        this.hostSelectBoxComponent,
                        this.hostSliderComponent,
                        this.hostCheckBoxComponent,
                        this.hostTextAreaComponent,
                        this.hostDateBoxComponent,
                    ];
                    for (const checkComponent of supportedDxComponents) {
                        this.validateComponent = checkComponent?.instance;

                        if (this.validateComponent) {
                            break;
                        }
                    }

                    if (this.validateComponent) {
                        this.validateComponent.option("onOptionChanged", (e: IOptionChangedEvent) => {
                            if (e.name === ValidateEntityDirective.IsValidOption) {
                                if (e.value !== this.isValid) {
                                    // this is to workaround issue where number validation is run after value is fed back into the component
                                    // - which will overwrite the isValid this directive has set
                                    setTimeout(() => {
                                        this.validateComponent!.option(ValidateEntityDirective.IsValidOption, this.isValid);
                                        this.validateComponent!.option(ValidateEntityDirective.ValidationErrorOption, this.validationError);
                                    });
                                }
                            }
                        });

                        if (this.unsubscribeValidationErrorsChanged) {
                            this.unsubscribeValidationErrorsChanged();
                        }

                        // breeze subscription returns an Id which is required to be unsubscribed using the same entity
                        // using unsubscribe function here with the subscribeEntity from this context to ensure is the same entity
                        const entityAspect = this.validateEntity.entityAspect; // just in case entity changed from binding input
                        const subscriptionId = entityAspect.validationErrorsChanged.subscribe((args) => this.handleValidationErrorChange(args));
                        this.unsubscribeValidationErrorsChanged = () => entityAspect.validationErrorsChanged.unsubscribe(subscriptionId);

                        this.validate();
                    } else {
                        throw new AdaptError("You can only validate entity for the limted dxComponents supported by this directive");
                    }
                }
            });
        }
    }

    private handleValidationErrorChange(args: ValidationErrorsChangedEventArgs) {
        const self = this;
        if (args.added.find(matchValidationProperty) || args.removed.find(matchValidationProperty)) {
            this.validate();
        }

        function matchValidationProperty(e: ValidationError) {
            return e.propertyName === self.validateProperty;
        }
    }

    private validate() {
        if (this.validateComponent && this.validateEntity && this.validateProperty) {
            const entityAspect = this.validateEntity.entityAspect;
            let hasError = false;
            if (entityAspect.hasValidationErrors) {
                const errors = entityAspect.getValidationErrors(this.validateProperty);
                if (errors.length > 0) {
                    // concatenate all errors into a single string
                    const errorMessage = errors.map((e) => e.errorMessage).join("; ");
                    this.isValid = false;
                    this.validationError = { message: errorMessage };
                    hasError = true;
                }
            }

            if (!hasError) {
                this.isValid = true;
                this.validationError = undefined;
            }

            setTimeout(() => {
                this.validateComponent!.option(ValidateEntityDirective.IsValidOption, this.isValid);
                this.validateComponent!.option(ValidateEntityDirective.ValidationErrorOption, this.validationError);
            });
        }
    }
}

import { Directive, Injector } from "@angular/core";
import { AngularGlobals } from "@common/lib/angular-globals/angular-globals";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { defer, EMPTY, Observable } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { AdaptCommonDialogService } from "../adapt-common-dialog.service";
import { BaseDialogComponent, DialogResolveData } from "../base-dialog.component/base-dialog.component";

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseDialogWithDiscardConfirmationComponent<TInputData, TResolveData = TInputData> extends BaseDialogComponent<TInputData, TResolveData> {
    protected commonDialogService: AdaptCommonDialogService;
    protected commonDataService: CommonDataService;

    /** Entities that will be checked for changes when cancelling the dialog. If the default
     * saveAndClose is being used, they they will also be what is attempted to be saved with the
     * backend.
     */
    protected abstract readonly entitiesToConfirm: IBreezeEntity[];

    /** Set this property in the implementing class in order to enable the default saveAndClose behaviour */
    protected autoResolveData?: TResolveData;

    public constructor(injector?: Injector, requiredResolveData?: DialogResolveData) {
        super(requiredResolveData);
        if (!injector) {
            injector = AngularGlobals.injector;
        }
        this.commonDialogService = injector.get(AdaptCommonDialogService);
        this.commonDataService = injector.get(CommonDataService);
    }

    public get hasUnsavedEntity() {
        return this.entitiesToConfirm.some((e) => e?.entityAspect.entityState.isAddedModifiedOrDeleted());
    }

    public get instance() {
        return this;
    }

    @Autobind
    public saveAndClose(): Promise<unknown> | Observable<unknown> {
        if (!this.autoResolveData && this.requiresResolveData) {
            throw new Error("Default implementation of saveAndClose in BaseDialogWithDiscardConfirmationComponent must have autoResolveValue defined by the implementing class");
        }

        return defer(() => this.commonDataService.saveEntities(this.entitiesToConfirm)).pipe(
            tap(() => this.resolve(this.autoResolveData!)),
            catchError((e) => {
                this.setErrorMessage(ErrorHandlingUtilities.getHttpResponseMessage(e));
                return EMPTY;
            }),
        );
    }

    public cancel() {
        const entities = this.entitiesToConfirm; // still keeping this abstract entitiesToConfirm for entities to reject
        if (this.hasUnsavedEntity) {
            const result = this.commonDialogService.openConfirmDiscardDialog().pipe(
                switchMap((shouldDiscard) => shouldDiscard
                    ? this.commonDataService.rejectChanges(entities)
                    : EMPTY),
            );
            result.subscribe(() => {
                // This is for entity changed before the dialog is actually destroyed, e.g. froala edit from a dialog
                // may change the entity value again. As events is completed inline from the ngOnDestroy which can be
                // before or after froala dom destroy -> do the reject in the next digest cycle.
                super.events.subscribe({
                    complete: () => setTimeout(() => this.commonDataService.rejectChanges(entities).subscribe()),
                });

                super.cancel();
            });

            return;
        } else if (entities.length > 0) {
            // still need this as 'hasUnsavedEntity' can be overwritten, e.g. in the case of EditObjectiveDialog where
            // you won't need confirmation to discard unchanged new entities.
            super.events.subscribe({
                complete: () => setTimeout(() => this.commonDataService.rejectChanges(entities).subscribe()),
            });
        }

        super.cancel();
    }

    // this won't discard entities when closing dialog
    @Autobind
    public close() {
        return super.cancel();
    }

    public get entitiesAreUnmodifiedOrInvalid() {
        if (this.entitiesToConfirm.every((i) => i.entityAspect.entityState.isDetached())) {
            // all detached entities are considered unchanged - cannot splice them as
            // it may be a snapshot of another collection from the implementing class -> i.e.
            // splice not actually remove it from the source collection
            return true;
        }

        const invalidEntitiesAreToBePersisted = this.entitiesToConfirm
            .filter((i) => !i.entityAspect.entityState.isDeleted())
            .filter((i) => !i.entityAspect.entityState.isDetached())
            .some((i) => i.entityAspect.hasValidationErrors);
        return invalidEntitiesAreToBePersisted
            || this.entitiesToConfirm.every((i) => i.entityAspect.entityState.isUnchanged());
    }

    public hasUnusedAddedType<AddedType extends IBreezeEntity<any>, UsedByType extends IBreezeEntity<any>>(
        addedType: new (...args: any[]) => AddedType,
        usedByType: new (...args: any[]) => UsedByType,
        entityIdField: keyof AddedType & keyof UsedByType,
    ) {
        const entitiesOfAddedType = this.entitiesToConfirm
            .filter((i) => i.entityAspect.entityState.isAdded())
            .filter(ObjectUtilities.createIsInstanceFilter(addedType));
        const usedByEntities = this.entitiesToConfirm.filter(ObjectUtilities.createIsInstanceFilter(usedByType));
        return entitiesOfAddedType.some((i) => !usedByEntities.find((u) => u[entityIdField] === (i[entityIdField] as number)));
    }
}

import { Component, ElementRef, Input } from "@angular/core";
import { Survey, SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { SurveyResponseGroup } from "@common/ADAPT.Common.Model/organisation/survey-response";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ChartUtils } from "@common/ux/base-ui.service/chart-utils";
import { DateFormats } from "@common/ux/date-formats";
import { IDxChartCustomizePoint, IDxChartTooltipPointInfo } from "@common/ux/dx.types";
import { DoneEvent, PointClickEvent } from "devextreme/viz/chart";
import moment from "moment";
import { ReplaySubject } from "rxjs";
import { tap } from "rxjs/operators";
import { SurveyUtils } from "../survey-utils";

interface IChartData {
    score: number;
    participation: number;
    endTime: Date;
    scoreTooltip: string;
    participationTooltip: string;
    leaderScore?: number;
    nonLeaderScore?: number;
}

interface ILegendSeries {
    name: string;
    color: string;
}

@Component({
    selector: "adapt-display-survey-history",
    templateUrl: "./display-survey-history.component.html",
    styleUrls: ["./display-survey-history.component.scss"],
})
export class DisplaySurveyHistoryComponent extends BaseComponent {
    public static readonly ScoreTag = "ScoreTag";
    public static readonly LeaderTag = "LeaderTag";
    public static readonly NonLeaderTag = "NonLeaderTag";
    public static readonly ParticipationTag = "ParticipationTag";

    public legendSeries: readonly ILegendSeries[] = [];

    @Input() public set surveys(values: Survey[]) {
        this.surveys$.next(values);
    }

    public initialised = false;
    public chartData: IChartData[] = [];
    public hasMultiResponseGroups = false;

    private surveys$ = new ReplaySubject<Survey[]>(1);
    private surveyType?: SurveyType;
    private teamId?: number;

    public constructor(
        elementRef: ElementRef,
    ) {
        super(elementRef);
        this.surveys$.pipe(
            tap((surveys) => this.formChartData(surveys)),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.initialised = true);
    }

    public updateChartDimensionAndSubscribeSizeChange(e: DoneEvent) {
        ChartUtils.updateChartDimension(e);
        this.sizeChange$.subscribe(() => ChartUtils.updateChartDimension(e));
    }

    @Autobind
    public customiseChartTooltip(info: IDxChartTooltipPointInfo) {
        if (info.point.tag === DisplaySurveyHistoryComponent.ScoreTag) {
            return {
                html: info.point.data.scoreTooltip,
            };
        } else if (info.point.tag === DisplaySurveyHistoryComponent.LeaderTag) {
            return {
                html: this.getTooltipHtml(info.point.data.survey, `Leaders average: ${info.point.data.leaderScore!.toFixed(0)}%`) +
                    `<div>Leaders participation: ${info.point.data.leaderResult?.percentageParticipation}%</div>`,
            };
        } else if (info.point.tag === DisplaySurveyHistoryComponent.NonLeaderTag) {
            return {
                html: this.getTooltipHtml(info.point.data.survey, `Employees average: ${info.point.data.nonLeaderScore!.toFixed(0)}%`) +
                    `<div>Employees participation: ${info.point.data.nonLeaderResult?.percentageParticipation}%</div>`,
            };
        } else if (info.point.tag === DisplaySurveyHistoryComponent.ParticipationTag) {
            return {
                html: info.point.data.participationTooltip,
            };
        }
    }

    @Autobind
    public customiseChartPoint(point: IDxChartCustomizePoint) {
        if (this.surveyType) {
            let score: number | undefined;
            if (point.tag === DisplaySurveyHistoryComponent.ScoreTag) {
                score = point.data.score;
            } else if (point.tag === DisplaySurveyHistoryComponent.LeaderTag) {
                score = point.data.leaderScore;
            } else if (point.tag === DisplaySurveyHistoryComponent.NonLeaderTag) {
                score = point.data.nonLeaderScore;
            } else {
                score = undefined;
            }

            if (score) {
                const color = SurveyUtils.forSurveyType(this.surveyType).getStatusColorFromPercentage(score);
                return { color };
            }
        }

        return {};
    }

    @Autobind
    public onPointClicked(point: PointClickEvent) {
        if (point.target && point.target.data && point.target.data.survey && this.surveyType) {
            point.component.hideTooltip();
            SurveyUtils.forSurveyType(this.surveyType)
                .gotoAnalyseSurveyPageRoute$(point.target.data.survey.surveyId, this.teamId)
                .subscribe();
        }
    }

    public customiseChartLabel(e: IDxChartTooltipPointInfo) {
        if (e.point.tag === DisplaySurveyHistoryComponent.ScoreTag) {
            return `Overall: ${e.value.toFixed(0)}%`;
        } else if (e.point.tag === DisplaySurveyHistoryComponent.LeaderTag) {
            return `Leaders: ${e.value.toFixed(0)}%`;
        } else if (e.point.tag === DisplaySurveyHistoryComponent.NonLeaderTag) {
            return `Employees: ${e.value.toFixed(0)}%`;
        } else {
            return "";
        }
    }

    public customizeDate(e: { value: Date }) {
        return moment(e.value).format("MMM YYYY"); // show month and year only as DX simply shows 01 of each month
    }

    private formChartData(surveys: Survey[]) {
        this.hasMultiResponseGroups = false;
        this.chartData = surveys.map((survey) => {
            const surveyResult = SurveyUtils.getSurveyResultForResponseGroup(survey, SurveyResponseGroup.All)!;
            if (survey.surveyResults.length > 1) {
                this.hasMultiResponseGroups = true;
            }
            return {
                survey,
                zero: 0, // for dummy series to trigger legends
                score: surveyResult.averagePercentageScore!, // TODO: show for different ResponseGroup
                participation: surveyResult.percentageParticipation!,
                endTime: survey.endTime,
                scoreTag: DisplaySurveyHistoryComponent.ScoreTag,
                scoreTooltip: this.getTooltipHtml(survey, `Average score: ${surveyResult.averagePercentageScore!.toFixed(0)}%`),
                participationTag: DisplaySurveyHistoryComponent.ParticipationTag,
                participationTooltip: this.getTooltipHtml(survey, `Participation rate: ${surveyResult.percentageParticipation!.toFixed(0)}%`),
                leaderScore: SurveyUtils.getSurveyResultForResponseGroup(survey, SurveyResponseGroup.Leader)?.averagePercentageScore!,
                nonLeaderScore: SurveyUtils.getSurveyResultForResponseGroup(survey, SurveyResponseGroup.NonLeader)?.averagePercentageScore!,
                leaderTag: DisplaySurveyHistoryComponent.LeaderTag,
                nonLeaderTag: DisplaySurveyHistoryComponent.NonLeaderTag,
                leaderResult: SurveyUtils.getSurveyResultForResponseGroup(survey, SurveyResponseGroup.Leader),
                nonLeaderResult: SurveyUtils.getSurveyResultForResponseGroup(survey, SurveyResponseGroup.NonLeader),
            };
        });

        this.legendSeries = [];
        if (surveys.length > 0) {
            // use first survey to determine type
            this.surveyType = surveys[0].surveyType;
            this.teamId = surveys[0].teamId;
            this.legendSeries = SurveyUtils.forSurveyType(this.surveyType).resultCategories;
        }
    }

    private getTooltipHtml(survey: Survey, additionalLine: string) {
        return `<h3>${survey.name}</h3>
            <div>Survey ended: ${moment(survey.endTime).format(DateFormats.moment.short)}</div>
            <div>${additionalLine}</div>`;
    }
}

<dx-select-box class="objective-support-parent-selection"
               [(value)]="objective.parentObjective"
               [items]="groupedSupportiveObjectives"
               [showClearButton]="true"
               [grouped]="true"
               displayExpr="title"
               fieldTemplate="field"
               groupTemplate="group"
               data-test="objective-supports-dropdown">
    <div *dxTemplate="let data of 'group'">
        <div *ngIf="!data.key; else teamTemplate">Organisation Objectives</div>
        <ng-template #teamTemplate>
            <div>Team Objectives</div>
        </ng-template>
    </div>
    <div *dxTemplate="let obj of 'field'">
        <div *ngIf="!!obj; else noParent">
            <adapt-link-objective class="m-2"
                                  [objective]="obj"
                                  [generateHref]="false"></adapt-link-objective>
            <dx-text-box class="d-none"
                         value="hidden element required by dxTemplate"
                         [readOnly]="true"></dx-text-box>
        </div>
    </div>
    <div *dxTemplate="let obj of 'item'">
        <adapt-link-objective [objective]="obj"
                              [generateHref]="false"
                              [showStatusIcon]="false"></adapt-link-objective>
    </div>
    <ng-template #noParent>
        <dx-text-box value=""
                     [readOnly]="true"></dx-text-box>
    </ng-template>
</dx-select-box>

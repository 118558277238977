import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptLinkDisabledModule } from "@common/ux/link-disabled/link-disabled.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { AdaptLabellingModule } from "@org-common/lib/labelling/labeling.module";
import { AdaptTeamDashboardSharedModule } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.module";
import { AdaptZoneMapModule } from "@org-common/lib/zone-map/zone-map.module";
import { DxListModule, DxSelectBoxModule, DxTextBoxModule } from "devextreme-angular";
import { KeyFunctionCardComponent } from "./key-function-card/key-function-card.component";
import { LinkKeyFunctionComponent } from "./link-key-function/link-key-function.component";
import { ListKeyFunctionsComponent } from "./list-key-functions/list-key-functions.component";
import { SelectKeyFunctionComponent } from "./select-key-function/select-key-function.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        DxSelectBoxModule,
        AdaptLoadingSpinnerModule,
        AdaptTooltipModule,
        AdaptDirectorySharedModule,
        AdaptZoneMapModule,
        AdaptTeamDashboardSharedModule,
        AdaptLabellingModule,
        DxListModule,
        DxTextBoxModule,
        AdaptLinkDisabledModule,
    ],
    exports: [
        LinkKeyFunctionComponent,
        KeyFunctionCardComponent,
        SelectKeyFunctionComponent,
        ListKeyFunctionsComponent,
    ],
    declarations: [
        LinkKeyFunctionComponent,
        KeyFunctionCardComponent,
        SelectKeyFunctionComponent,
        ListKeyFunctionsComponent,
    ],
})
export class AdaptKeyFunctionsCommonModule { }

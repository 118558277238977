<adapt-dialog>
    <h3 adapt-dialog-title>{{isNew ? "Add" : "Edit"}} anchor</h3>
    <div adapt-dialog-content
         class="h-100">
        <div class="d-flex flex-column flex-lg-row">
            <div *ngIf="articleId"
                 [adaptCollapsible]="articleId"
                 class="left-column d-block pe-3 me-lg-4 mb-4 mb-lg-0">
                <adapt-styled-implementation-kit [articleId]="articleId"
                                                 contentStyle="embedded"></adapt-styled-implementation-kit>
            </div>

            <div class="d-flex flex-column mt-4 mt-lg-0 right-column">
                <label class="mt-3">Anchor name</label>
                <dx-text-box [value]="anchor.name"
                             [maxLength]="60"
                             (valueChange)="nameHasChanged($event)"
                             [adaptValidateEntity]="anchor"
                             adaptValidateEntityProperty="name"
                             data-tour="anchor-name-field"></dx-text-box>

                <label class="mt-3">Description</label>
                <adapt-html-editor [(contents)]="anchor.description"
                                   [minHeight]="300"
                                   (isValidChange)="isEditorValid = $event"
                                   forceSize="compact"></adapt-html-editor>
            </div>
        </div>
    </div>
    <div adapt-dialog-footer
         class="d-flex w-100">
        <button adaptButton="delete"
                *ngIf="!isNew"
                [adaptBlockingClick]="deleteAnchor"
                data-tour="delete-anchor-button"></button>
        <adapt-standard-dialog-buttons [saveIsDisabled]="anchor.entityAspect.hasValidationErrors || anchor.entityAspect.entityState.isUnchanged() || !isEditorValid"
                                       [saveBlockingClick]="saveAndClose"
                                       (cancelClick)="cancel()"
                                       cancelText="Cancel">
        </adapt-standard-dialog-buttons>
    </div>
</adapt-dialog>

import { ILogger } from "@common/lib/logger/logger.interface";
import { HubConnection } from "@microsoft/signalr";
import { ConnectionEvent } from "./connection-event.enum";
import { IConnectionState } from "./connection-state.interface";
import { SignalRConnectionContext } from "./signalr-connection-context";

export abstract class BaseConnectionState implements IConnectionState {
    protected readonly connection: HubConnection;
    protected readonly signalREndpoint: string;
    protected readonly log: ILogger;

    public constructor(
        protected context: SignalRConnectionContext,
    ) {
        this.connection = context.connection;
        this.signalREndpoint = context.hubName;
        this.log = context.log;
    }

    public abstract get event(): ConnectionEvent;

    public connect(): void {
        // Nothing to do
    }

    public disconnect(): void {
        // Nothing to do
    }

    public interruptConnection(): void {
        // Nothing to do
    }

    public reestablishExistingConnection(): void {
        // Nothing to do
    }

    public startReconnecting(): void {
        // Nothing to do
    }

}

<ng-container *ngIf="values.length > 0; else noValuesTemplate">

    <dx-sortable data-test="values-constitution-list"
                 handle=".card-drag-handle"
                 [data]="values"
                 [moveItemOnDrop]="true"
                 [allowReordering]="isEditing"
                 (onDragStart)="onDragStart($event)"
                 (onReorder)="updateOrdinals($event)">
        <div *ngFor="let value of values"
             data-test="value-item">
            <div class="card">
                <div class="card-body">
                    <div *ngIf="isEditing"
                         class="edit-mode-buttons"
                         (click)="suppressClick($event)">
                        <!-- Shouldn't need an [unsubscribeOnDestroy]="false"
                                here, but when DX has a new array assigned to "items"
                                it doesn't reuse the built DOM like *ngFor does with
                                trackBy. And so this element gets destroyed before the
                                edit call has completed -->
                        <button adaptButtonIcon="edit"
                                class="btn btn-link"
                                [adaptBlockingClick]="editValue"
                                [adaptBlockingClickParam]="value"
                                [unsubscribeOnDestroy]="false"
                                adaptTooltip="Edit value"
                                data-test="edit-value-button"></button>
                        <!-- Delete suffers same issue as listed above  -->
                        <button adaptButtonIcon="delete"
                                class="btn btn-link"
                                [adaptBlockingClick]="deleteValue"
                                [adaptBlockingClickParam]="value"
                                [unsubscribeOnDestroy]="false"
                                adaptTooltip="Delete value"
                                data-test="delete-value-button"></button>
                    </div>

                    <adapt-value-description #valueDescription
                                             [value]="value"></adapt-value-description>
                </div>

                <div *ngIf="isEditing"
                     class="card-drag-handle dx-list-item-after-bag dx-list-reorder-handle-container">
                    <div class="dx-list-reorder-handle"></div>
                </div>
            </div>
        </div>
    </dx-sortable>
</ng-container>

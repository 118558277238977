import { Component } from "@angular/core";
import { BaseDialogComponent, DialogResolveData } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";

@Component({
    selector: "adapt-eula-dialog",
    templateUrl: "./eula-dialog.component.html",
})
export class EulaDialogComponent extends BaseDialogComponent<void> {
    public readonly dialogName = "EulaDialog";

    public constructor() {
        super(DialogResolveData.NotRequired);
    }
}

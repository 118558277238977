import { Component, Injector } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { setPageActivationSearchParam, setReturnPathSearchParam } from "@common/ux/base-routed.component";
import { MeetingAgendaItemComponent } from "@org-common/lib/meetings/meeting-agenda-component-registry";
import { CompetitorAnalysisPageRoute } from "@org-common/lib/strategic-inputs/competitor-analysis-page/competitor-analysis-page.component";
import { StrategyAuthService } from "@org-common/lib/strategy/strategy-auth.service";
import { TourIdentifier } from "../../../tours/tour-identifier.enum";

@MeetingAgendaItemComponent("adapt-add-competitor-analysis-agenda-item")
@Component({
    selector: "adapt-add-competitor-analysis-agenda-item",
    templateUrl: "./add-competitor-analysis-agenda-item.component.html",
})
export class AddCompetitorAnalysisAgendaItemComponent extends BaseComponent {
    public pageActivationTours = [TourIdentifier.CompetitorsAnalysisTour];
    public readonly EditStrategicInputs = StrategyAuthService.EditStrategicInputs;

    public constructor(
        private injector: Injector,
    ) {
        super();
    }

    @Autobind
    public recordCompetitorsAnalysis() {
        let searchParams = setReturnPathSearchParam(
            this.injector,
            {},
            CompetitorAnalysisPageRoute.id,
            "Return to meeting");
        if (this.pageActivationTours?.length) {
            searchParams = setPageActivationSearchParam(searchParams, CompetitorAnalysisPageRoute.id, this.pageActivationTours);
        }
        return CompetitorAnalysisPageRoute.gotoRoute(undefined, searchParams);
    }
}

import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DxTreeViewComponent } from "devextreme-angular";
import { Subject, Subscription } from "rxjs";
import { NavigationHierarchyService } from "../navigation-hierarchy.service";
import { INavigationNode } from "../navigation-node.interface";

@Component({
    selector: "adapt-display-link-hierarchy",
    templateUrl: "./display-link-hierarchy.component.html",
    styleUrls: ["./display-link-hierarchy.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayLinkHierarchyComponent extends BaseComponent implements OnInit, OnChanges, AfterViewInit {
    private static readonly ExclusiveExpansionChanged = new Subject<INavigationNode>();

    @Input("hierarchy") public rootNode?: INavigationNode;
    @Input() public expandAllLinks = false;

    @ViewChild(DxTreeViewComponent) public treeViewComponent?: DxTreeViewComponent;
    public dataSource: INavigationNode[] = [];
    private expansionSubscription?: Subscription;

    public constructor(
        private navigationHierarchyService: NavigationHierarchyService,
    ) {
        super();

        // Not currently a publically exposed option
        // (this.treeViewOptions as any).deferRendering = false;
    }

    public ngOnInit() {
        this.navigationHierarchyService.activeNode$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((node) => this.onActiveNodeUpdated(node));
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.rootNode) {
            this.dataSource = this.rootNode
                ? [this.rootNode]
                : [];
        }
    }

    public ngAfterViewInit() {
        if (this.expandAllLinks) {
            this.treeViewComponent!.instance.expandAll();
        }
    }

    public onItemClick(e: any) {
        const node = e.itemData as INavigationNode;
        if (this.treeViewComponent && node && !node.url) {
            // node without controller or redirecting - single click expand
            // - cannot use https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxTreeView/Configuration/#expandEvent
            // as it will close off expanded node, e.g. clicking on Configure Organisation will be redirected to Org Details
            // and expand parent node but single click event will also toggle the node expansion again to close it.
            this.treeViewComponent.instance.expandItem(node);
        }

        if (node && node.customData?.exclusiveExpand) {
            DisplayLinkHierarchyComponent.ExclusiveExpansionChanged.next(node);
        }
    }

    @Autobind
    public onActiveNodeUpdated(newActiveNode: INavigationNode) {
        if (!this.rootNode || !this.treeViewComponent) {
            return;
        }

        const ancestorNodes = ObjectUtilities.linkedListToArray(newActiveNode, (n) => n.parent);
        const thisAncestorIndex = ancestorNodes.indexOf(this.rootNode);
        if (thisAncestorIndex < 0) {
            // We are not an ancestor, so do nothing!
            return;
        }

        // Expand the new active node as well if it has children
        const sliceFrom = newActiveNode.children.length > 0
            ? 0
            : 1;
        const nodesToExpand = ancestorNodes.slice(sliceFrom, thisAncestorIndex + 1)
            .reverse(); // Reverse so we expand from the top down.
        for (const node of nodesToExpand) {
            // Stop when we hit a node that shouldn't be expanded
            // This state can be hit if the newActive node is a dynamic node with a parent
            // ancestor (but it won't be in the children of the ancestors)
            if (node.children.length === 0) {
                break;
            }

            this.treeViewComponent.instance.expandItem(node);
            if (node && node.customData?.exclusiveExpand) {
                DisplayLinkHierarchyComponent.ExclusiveExpansionChanged.next(node);
            }
        }
    }

    public onContentReady(e: any) {
        if (!e.element) {
            return;
        }

        // Even though our content doesn't overflow, a scrollbar still seems to be shown
        // occassionally.
        // Adapt https://www.devexpress.com/Support/Center/Question/Details/T478191
        // Scrollable is not in DX Types
        const scrollableElement: any = e.element.find(".dx-scrollable");
        scrollableElement.dxScrollable("instance")
            .option("showScrollbar", "never");

        const activeNode = this.navigationHierarchyService.getActiveNode();
        if (activeNode) {
            this.onActiveNodeUpdated(activeNode);
        }

        if (this.rootNode) {
            this.preExpandNodeAndChildrenIfNeeded(this.rootNode);

            if (this.rootNode.customData.exclusiveExpand) {
                this.expansionSubscription?.unsubscribe();
                this.expansionSubscription = DisplayLinkHierarchyComponent.ExclusiveExpansionChanged.pipe(
                    this.takeUntilDestroyed(),
                ).subscribe((expandedNode) => {
                    if (this.rootNode !== expandedNode) {
                        this.treeViewComponent?.instance.collapseItem(this.rootNode);
                    }
                });
            }
        }
    }

    @Autobind
    private preExpandNodeAndChildrenIfNeeded(node: INavigationNode) {
        if (!this.treeViewComponent) {
            throw new Error("TreeView is expected to be defined in onContentReady");
        }

        if (node.customData.expandOnLoad) {
            this.treeViewComponent.instance.expandItem(node);
        }

        node.children.forEach(this.preExpandNodeAndChildrenIfNeeded);
    }
}

<ng-container *adaptLoading="!isInitialised">
    <adapt-toolbar-content alignment="center">
        <div class="d-flex">
            <i [ngClass]="{'alto-page-heading-icon': isAlto}"
               class="far fa-anchor m-auto me-2"></i>
            <h2>Strategic anchors</h2>
        </div>
    </adapt-toolbar-content>
    <adapt-toolbar-content *adaptIfAuthorised="EditStrategicAnchors"
                           alignment="right">
        <button *ngIf="!isEditing  && anchors.length > 0"
                adaptButton="edit"
                data-test="edit-mode-button"
                (click)="isEditing = true"
                data-tour="values-edit"></button>
        <button adaptButton="add"
                data-tour="add-anchor-button"
                [adaptBlockingClick]="addAnchor">
            anchor
        </button>
    </adapt-toolbar-content>
    <adapt-toolbar-content>
        <button *ngIf="isEditing && anchors.length > 0"
                adaptButton="view"
                data-test="view-mode-button"
                (click)="isEditing = false"></button>
    </adapt-toolbar-content>

    <ng-container *ngIf="anchors.length > 0; else showCTA">
        <adapt-strategic-anchors [anchors]="anchors"
                                 [isEditing]="isEditing"></adapt-strategic-anchors>
    </ng-container>
</ng-container>

<ng-template #showCTA>
    <div class="alert alert-secondary mt-3">
        There are currently no anchors recorded. Please use the add anchor button above to get started.
    </div>
</ng-template>

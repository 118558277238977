import { Component, Inject } from "@angular/core";
import { CoachSession } from "@common/ADAPT.Common.Model/organisation/coach-session";
import { CoachSessionStatus } from "@common/ADAPT.Common.Model/organisation/coach-session-status";
import { UserService } from "@common/user/user.service";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";

@Component({
    selector: "adapt-coach-access-feedback-dialog",
    templateUrl: "./coach-access-feedback-dialog.component.html",
})
export class CoachAccessFeedbackDialogComponent extends BaseDialogWithDiscardConfirmationComponent<CoachSession> {
    public readonly dialogName = "CoachAccessFeedbackDialog";
    protected readonly autoResolveData = this.coachSession;
    protected readonly entitiesToConfirm = [this.coachSession];

    // need to store original status, as we update the status when providing feedback
    private originalStatus = this.coachSession.status;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public coachSession: CoachSession,
        private userService: UserService,
    ) {
        super();
    }

    public get saveIsDisabled() {
        return !this.coachSession.feedbackComment && !this.coachSession.feedbackRating;
    }

    public get canProvideFeedback() {
        return this.originalStatus === CoachSessionStatus.FeedbackRequested
            && this.isSessionForCurrentPerson;
    }

    public get isSessionForCurrentPerson() {
        return this.coachSession.personId === this.userService.getCurrentPersonId();
    }

    public get alreadyHasFeedback() {
        return this.coachSession.status === CoachSessionStatus.FeedbackComplete
            && this.coachSession.entityAspect.entityState.isUnchanged();
    }

    public updateFeedbackState() {
        if (this.coachSession.status !== CoachSessionStatus.FeedbackComplete) {
            this.coachSession.status = CoachSessionStatus.FeedbackComplete;
        }
    }
}

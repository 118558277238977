import { IDuration } from "@common/ux/duration-selector";

export enum SearchType {
    // Remote (search API)
    Kanban,
    Meeting,
    Objective,
    System,
    Tier1,
    Guidance,

    // Local
    Page,
    ImplementationKit,
    PersonTeamRole,
}

export interface ISearchOptions {
    keyword: string | undefined;
    types: Set<SearchType>;
    labelIds?: Set<number>;
    activeOnly?: boolean;
    personId?: number;
    teamId?: number;
    updatedSince?: IDuration;
}

export interface ISearchOptionsUrlParams {
    q?: string;
    labelIds?: string;
    types?: string;
    inactive?: number;
    personId?: number;
    teamId?: number;
    since?: string;
}

export type ISearchUrlParams = Record<keyof ISearchOptionsUrlParams, string>;

export interface ISearchProviderOptions extends ISearchOptions {
    keyword: string;
}

export interface ISearchApiParams extends Omit<ISearchProviderOptions, "types" | "labelIds" | "updatedSince"> {
    organisationId: number;
    types: SearchType[];
    labelIds: number[];
    updatedSince?: Date;
}

export interface ISearchSubGroup<T = any> {
    type: T,
    name: string;
    icon: string;
}

export interface ISearchGroup<TSub = any> {
    type: SearchType;
    subGroups?: ISearchSubGroup<TSub>[];
    name: string;
    icon: string;
    remote: boolean;
}

import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Survey } from "./survey";
import { SurveyQuestionResponse } from "./survey-question-response";

export enum SurveyResponseGroup {
    All = "All",
    Leader = "Leader",
    NonLeader = "NonLeader",
}

export const SurveyResponseGroupLabel: { [key in SurveyResponseGroup]: string } = {
    [SurveyResponseGroup.All]: "Overall average",
    [SurveyResponseGroup.Leader]: "Leaders",
    [SurveyResponseGroup.NonLeader]: "Employees",
};

export class SurveyResponse extends BaseEntity<SurveyResponse> {
    public surveyResponseId!: number;
    public surveyId!: number;
    public percentageScore?: number;
    public responseGroup!: SurveyResponseGroup;

    // nav properties
    public survey?: Survey;
    public surveyQuestionResponses!: SurveyQuestionResponse[];
}

export const SurveyResponseBreezeModel = new BreezeModelBuilder("SurveyResponse", SurveyResponse)
    .hasSource()
    .withIdField()
    .isOrganisationEntity()
    .build();

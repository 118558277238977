import { Component, Injector, OnInit } from "@angular/core";
import { ValuesConstitution } from "@common/ADAPT.Common.Model/organisation/values-constitution";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { CommonPurposeVisionAuthService } from "@org-common/lib/purpose-vision/common-purpose-vision-auth.service";
import { ValuesConstitutionAuthService } from "@org-common/lib/values-constitution/values-constitution-auth.service";
import { combineLatest, Observable } from "rxjs";
import { map } from "rxjs/operators";

@Component({
    selector: "adapt-organisation-dashboard-page",
    templateUrl: "./organisation-dashboard-page.component.html",
})
export class OrganisationDashboardPageComponent extends BaseRoutedComponent implements OnInit {
    public readonly PurposeVisionRead = CommonPurposeVisionAuthService.ReadPurposeVision;
    public readonly ValuesRead = ValuesConstitutionAuthService.ReadValuesConstitution;

    public valuesConstitution?: ValuesConstitution;
    public hasAccess$: Observable<boolean>;

    constructor(
        injector: Injector,
        private authorisationService: AuthorisationService,
    ) {
        super(injector);

        this.hasAccess$ = combineLatest([
            this.authorisationService.getHasAccess(this.PurposeVisionRead),
            this.authorisationService.getHasAccess(this.ValuesRead),
        ]).pipe(
            map((hasAccess) => hasAccess.includes(true)),
        );
    }

    public ngOnInit() {
        this.notifyActivated();
    }
}

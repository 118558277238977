import { DefaultOKButtons, GuidedTour } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourUtils } from "@common/lib/guided-tour/guided-tour.utils";

const customiseReturnPathTour = (tourId: string, title: string, text: string) => {
    return {
        id: tourId,
        saveRunStatus: true,
        steps: [{
            title,
            text,
            waitForAndAttachToElementSelector: `[data-tour="return-to-path-button"]`,
            attachTo: {
                on: "bottom-start",
            },
            modalOverlayOpeningPadding: 3,
            canClickTarget: false, // don't allow the button to be clicked or this step will have nothing to attach to
            buttons: DefaultOKButtons,
            showOn: () => {
                // Just in case people click away before tour processed
                const cancelButton = GuidedTourUtils.getFirstElementWithTextContent(`[data-tour="return-to-path-button"]`);
                return GuidedTourUtils.isElementVisible(cancelButton);
            },
        }],
    } as GuidedTour;
};

export const ReturnPathTourIdentifier = "ReturnPathButtonTour";
export const ReturnPathTour = customiseReturnPathTour(
    "ReturnPathTour",
    "How to navigate back?",
    "Once you've finished your tasks on this page, click here to return to your previous location.",
);

export const ReturnToStartupPathwayTourIdentifier = "ReturnToStartupPathwayTour";
export const ReturnToStartupPathwayTour = customiseReturnPathTour(
    "ReturnToStartupPathwayTour",
    "Proceed to onboarding",
    "Once you've reviewed your health check results, click here to proceed to your onboarding.",
);

<adapt-manage-surveys [surveyType]="surveyType"
                      [callToActionTextTemplate]="callToActionText"
                      (initialise)="onInitialised()"></adapt-manage-surveys>

<ng-template #callToActionText>
    <p>
        This health check will give the owners and key leaders a high level picture of where their business is at
        and a starting point from which to use the features of our product and services.
    </p>
    <p>
        Using the health check tool, you will be able to send a survey to
        each of your leaders and collate and analyse this data dynamically.
    </p>

    <p>
        The survey questions are built to allow us to guide you on one of our pre-defined pathways and increase awareness on
        burning issues.
    </p>
</ng-template>

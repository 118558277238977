import { Component, Inject } from "@angular/core";
import { Account } from "@common/ADAPT.Common.Model/account/account";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent, DialogResolveData } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";

export interface ICancelSubscriptionResult {
    reason: string
    freeText: string
}
@Component({
    selector: "adapt-cancel-subscription-dialog",
    templateUrl: "./cancel-subscription-dialog.component.html",
})
export class CancelSubscriptionDialogComponent extends BaseDialogComponent<Account, ICancelSubscriptionResult>{
    public readonly dialogName = "CancelSubscriptionDialog";
    public readonly CancellationReasons = [
        "Too expensive",
        "I'm not using this anymore",
        "I'm switching to another product",
        "Other",
    ];

    public cancellationReason = "";
    public improvementText = "";

    public isEmpty = StringUtilities.isEmpty;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public account: Account,
    ) {
        super(DialogResolveData.NotRequired);
    }

    public cancelSubscription() {
        const result = {
            reason: this.cancellationReason,
            freeText: this.improvementText,
        };
        this.resolve(result);
    }
}

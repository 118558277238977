import { AfterViewChecked, Component, ElementRef, HostListener, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Zone, ZoneMetadata } from "@common/ADAPT.Common.Model/methodology/zone";
import { BullseyeQuadrant, BullseyeQuadrantIcon, BullseyeQuadrantText } from "@common/ADAPT.Common.Model/organisation/bullseye-quadrant-statement";
import { BullseyeStatementLocation } from "@common/ADAPT.Common.Model/organisation/bullseye-statement-location";
import { InputLocation } from "@common/ADAPT.Common.Model/organisation/input-location";
import { InputTypeMetadata } from "@common/ADAPT.Common.Model/organisation/input-type-metadata";
import { CanvasType } from "@common/ADAPT.Common.Model/organisation/inputs-canvas";
import { Theme } from "@common/ADAPT.Common.Model/organisation/theme";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxSortableEvent } from "@common/ux/dx.types";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { BullseyeService } from "@org-common/lib/bullseye/bullseye.service";
import { StrategicInputsService } from "@org-common/lib/strategic-inputs/strategic-inputs.service";
import { StrategyService } from "@org-common/lib/strategy/strategy.service";
import { StrategicViewIcon, StrategicViewOption } from "@org-common/lib/strategy/strategy-view-constants";
import { Orientation } from "devextreme/common";
import { debounceTime, forkJoin, map, merge, of, Subject, switchMap, tap } from "rxjs";
import { AuthorisationService } from "../../authorisation/authorisation.service";
import { StrategyAuthService } from "../../strategy/strategy-auth.service";

interface ICategorisedInputLocations { [category: string]: InputLocation[] }

// this is supposed to be same as the max width of a theme card under class .category-multi-columns
const dragDirectionThreshold = 380;

@Component({
    selector: "adapt-strategy-zone",
    templateUrl: "./strategy-zone.component.html",
    styleUrls: ["./strategy-zone.component.scss"],
})
export class StrategyZoneComponent extends BaseComponent implements OnChanges, AfterViewChecked {
    @Input() public isEditing = false;
    @Input() public zone?: Zone;
    @Input() public views: StrategicViewOption[] = [];
    @Input() public hasThemes?: boolean;

    public readonly CanvasType = CanvasType;
    public readonly StrategicViewIcon = StrategicViewIcon;
    public readonly StrategicViewOption = StrategicViewOption;
    public readonly ZoneClass = ZoneMetadata.BackgroundStyleClass;
    public readonly Zone = Zone;
    public readonly InputTypeMetadata = InputTypeMetadata;

    public includesThemesView = false;
    public includesSWTInputsView = false;
    public includesCAInputsView = false;
    public includesBullseyeView = false;

    public categorisedSWTInputs: ICategorisedInputLocations = {};
    public categorisedCAInputs: ICategorisedInputLocations = {};
    public categorisedBullseyeStatements: { [category: string]: BullseyeStatementLocation[] } = {};
    public orderedThemeNames: string[] = [];

    public categoryDragOrientation: Orientation = "vertical";

    public isDraggingTheme = false;
    public orderedThemes: Theme[] = [];
    public isSingleColumn = false;

    public isDraggingInputTheme?: string | null; // null is uncategorised, undefined is no drag
    public draggingInputType?: CanvasType;
    public isDraggingBullseyeStatementTheme?: string | null;
    public hasAttachedSWTInputs = false;
    public hasAttachedCAInputs = false;
    public hasAttachedBullseye = false;
    public hasDisplayThemes = false;

    private readonly ThemeLaneDisplayOrder = [StrategicViewOption.Themes, StrategicViewOption.Goals, StrategicViewOption.SWTInputs, StrategicViewOption.CAInputs, StrategicViewOption.Bullseye];
    private dragOrientationSet = false;
    // This is to make sure only a single updateZone happening (from ngChanges or entity type changed: Goal, Theme and InputLocation)
    private triggerUpdateZone = new Subject<void>();
    private themeMenuItemsMap: { [themeName: string]: IAdaptMenuItem[] } = {};

    public constructor(
        elementRef: ElementRef,
        private inputsService: StrategicInputsService,
        private bullseyeService: BullseyeService,
        private strategyService: StrategyService,
        private commonDataService: CommonDataService,
        private authorisationService: AuthorisationService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super(elementRef);

        merge(
            rxjsBreezeService.entityTypeChanged(Theme),
            rxjsBreezeService.entityTypeChanged(InputLocation),
            rxjsBreezeService.entityTypeChanged(BullseyeStatementLocation),
            this.triggerUpdateZone.asObservable(),
        ).pipe(
            debounceTime(500),
            switchMap(() => this.updateZone()),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public get isHorizontal() {
        return this.categoryDragOrientation === "horizontal";
    }

    public get isThemeDraggable() {
        return this.isEditing && this.orderedThemeNames.length > 1;
    }

    public get hasAnyContent() {
        return this.hasAttachedSWTInputs || this.hasAttachedCAInputs || this.hasDisplayThemes || this.hasAttachedBullseye;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.views) {
            this.isInitialised = false;
            this.includesThemesView = this.views.includes(StrategicViewOption.Themes);
            this.includesSWTInputsView = this.views.includes(StrategicViewOption.SWTInputs);
            this.includesCAInputsView = this.views.includes(StrategicViewOption.CAInputs);
            this.includesBullseyeView = this.views.includes(StrategicViewOption.Bullseye);
            this.triggerUpdateZone.next();
        }
    }

    // cannot use AfterViewInit as the that's triggered before the goals query finishes
    public ngAfterViewChecked() {
        if (this.isInitialised && !this.dragOrientationSet) {
            // this is needed to allow wrapping of goal cards in Econ zone while not pushing width off the screen for other zones
            const element = this.elementRef?.nativeElement as HTMLElement;
            const parentElementWidth = element.parentElement?.offsetWidth;
            if (parentElementWidth && parentElementWidth > 200) {
                this.dragOrientationSet = true;
                const allCategoryNodes = element.querySelectorAll(".category");
                // Check category nodes - if node offset top doesn't have 2 identical -> vertical, else horizontal
                // this is just for the dxSortable for categories
                const offsetTops: number[] = [];
                allCategoryNodes.forEach((categoryNode: HTMLElement) => offsetTops.push(categoryNode.offsetTop));

                // this is from afterViewChecked -> which will result in ExpressionChangedAfterItHasBeenCheckedError
                // if this drag orientation which is bounded in the component template is changed here
                setTimeout(() => {
                    this.isSingleColumn = parentElementWidth < dragDirectionThreshold;
                    this.categoryDragOrientation = !this.isSingleColumn && ArrayUtilities.distinct(offsetTops).length < allCategoryNodes.length
                        ? "horizontal" // at least 2 at the same top -> can drag horizontally
                        : "vertical";
                }, 50);
            }
        }
    }

    @HostListener("window:resize")
    public onWindowResize() {
        this.dragOrientationSet = false;
    }

    public hasDisplayContent(themeName: string, currentViewOption?: StrategicViewOption) {
        let hasPreviousContent = false;
        for (const previousOption of this.ThemeLaneDisplayOrder) {
            if ((previousOption === currentViewOption) || hasPreviousContent) {
                break;
            }

            hasPreviousContent = this.hasViewOptionContent(themeName, previousOption);
        }
        return hasPreviousContent;
    }

    private hasViewOptionContent(themeName: string, viewOption: StrategicViewOption) {
        let hasContent = false;
        switch (viewOption) {
            case StrategicViewOption.Themes:
                hasContent = this.includesThemesView;
                break;
            case StrategicViewOption.SWTInputs:
                hasContent = this.includesSWTInputsView && !!this.categorisedSWTInputs[themeName]?.length;
                break;
            case StrategicViewOption.CAInputs:
                hasContent = this.includesCAInputsView && !!this.categorisedCAInputs[themeName]?.length;
                break;
            case StrategicViewOption.Bullseye:
                hasContent = this.includesBullseyeView && !!this.categorisedBullseyeStatements[themeName]?.length;
                break;
            default:
                break;
        }

        return hasContent;
    }

    public onThemeDragStart(e: IDxSortableEvent<string[]>) {
        // not going to allow drag/drop if there is only 1 theme or the uncategorised group
        e.cancel = !this.isThemeDraggable || e.fromIndex! >= this.orderedThemeNames.length;
        if (!e.cancel) {
            this.isDraggingTheme = true;
            if (this.isHorizontal) {
                // need to refresh the sortable as we will take away flex-wrap to workaround issue with wrapped item
                // - can't update from callback - need to do it next digest cycle
                setTimeout(() => e.component?.update());
            }
        }
    }

    public onInputDragStart(e: IDxSortableEvent<InputLocation[]>) {
        e.cancel = !this.isEditing;
        if (!e.cancel) {
            const draggedInputLocation = e.fromData![e.fromIndex!];
            this.isDraggingInputTheme = draggedInputLocation?.theme?.name ?? null;
            this.draggingInputType = draggedInputLocation?.input.canvas.type;
        }
    }

    public onBullseyeStatementDragStart(e: IDxSortableEvent<BullseyeStatementLocation[]>) {
        e.cancel = !this.isEditing;
        if (!e.cancel) {
            const draggedStatementLocation = e.fromData![e.fromIndex!];
            this.isDraggingBullseyeStatementTheme = draggedStatementLocation?.theme?.name ?? null;
        }
    }

    public preventDragToUncategorisedArea(e: IDxSortableEvent<string[]>) {
        // I need the uncategorised container to be in the same container as the category container so that they can be
        // equally stretched. Having the uncategorised container outside this sortable will result in weird scaling.
        // - so need this to prevent item from being dragged into the uncategorised area - want to keep that at the end
        e.cancel = e.toIndex! >= this.orderedThemeNames.length;
    }

    public detachBullseyeStatementLocation(bullseyeStatementLocation: BullseyeStatementLocation) {
        if (!bullseyeStatementLocation.theme) {
            throw new Error("Inputs must be attached to a theme");
        }

        const locationsGroup = this.categorisedBullseyeStatements[bullseyeStatementLocation.theme.name];
        const remainingLocations = locationsGroup.filter((i) => i !== bullseyeStatementLocation);
        this.bullseyeService.detachBullseyeStatementLocation(bullseyeStatementLocation).pipe(
            switchMap(() => (remainingLocations.length > 0)
                ? this.reIndexBullseyeStatementsOrdinal(remainingLocations)
                : of(undefined)),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public reorderBullseyeStatement(e: IDxSortableEvent<BullseyeStatementLocation[]>) {
        this.isDraggingBullseyeStatementTheme = undefined;
        if (e.fromIndex !== e.toIndex) {
            SortUtilities.moveItemInArray(e.fromData!, e.fromIndex!, e.toIndex!);
            this.reIndexBullseyeStatementsOrdinal(e.fromData!).subscribe();
        }
    }

    public reorderInput(e: IDxSortableEvent<InputLocation[]>) {
        this.isDraggingInputTheme = undefined;
        this.draggingInputType = undefined;
        if (e.fromIndex !== e.toIndex) {
            SortUtilities.moveItemInArray(e.fromData!, e.fromIndex!, e.toIndex!);
            this.reIndexInputLocationsOrdinal(e.fromData!).subscribe();
        }
    }

    public reorderThemes(e: IDxSortableEvent<string[]>) {
        this.isDraggingTheme = false;
        if (e.fromIndex === e.toIndex) {
            return;
        }

        SortUtilities.moveItemInArray(this.orderedThemeNames, e.fromIndex!, e.toIndex!);
        this.reorderThemesWithNamesOrder(this.orderedThemeNames).subscribe();
    }

    public getThemeByName(name: string) {
        return this.orderedThemes.find((theme) => theme.name === name);
    }

    @Autobind
    public editTheme(theme: Theme) {
        return this.strategyService.editTheme(theme);
    }

    public detachSWTInputLocation(inputLocation: InputLocation) {
        if (!inputLocation.theme) {
            throw new Error("Inputs must be attached to a theme");
        }

        const inputLocationsGroup = this.categorisedSWTInputs[inputLocation.theme!.name];
        this.detachInputLocation(inputLocation, inputLocationsGroup);
    }

    public detachCAInputLocation(inputLocation: InputLocation) {
        if (!inputLocation.theme) {
            throw new Error("Inputs must be attached to a theme");
        }

        const detachCollection = this.categorisedCAInputs[inputLocation.theme!.name];
        this.detachInputLocation(inputLocation, detachCollection);
    }

    private detachInputLocation(inputLocation: InputLocation, collection: InputLocation[]) {
        const remainingInputLocationsInGroup = collection.filter((i) => i !== inputLocation);
        this.inputsService.detachInputLocation(inputLocation).pipe(
            switchMap(() => {
                if (remainingInputLocationsInGroup.length > 0) {
                    return this.reIndexInputLocationsOrdinal(remainingInputLocationsInGroup);
                } else {
                    return of(undefined);
                }
            }),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    private reIndexInputLocationsOrdinal(inputLocations: InputLocation[]) {
        let ordinal = 0;
        for (const inputLocation of inputLocations) {
            inputLocation.ordinal = ++ordinal;
        }

        return this.inputsService.saveEntities(inputLocations);
    }

    private reIndexBullseyeStatementsOrdinal(bullseyeStatementLocations: BullseyeStatementLocation[]) {
        let ordinal = 0;
        for (const location of bullseyeStatementLocations) {
            location.ordinal = ++ordinal;
        }

        return this.bullseyeService.saveEntities(bullseyeStatementLocations);
    }

    public getMenuItemsForTheme(themeName: string) {
        return this.themeMenuItemsMap[themeName];
    }

    private async createMenuItemsForTheme(theme: Theme) {
        const menuItems: IAdaptMenuItem[] = [{
            icon: MenuComponent.SmallRootMenu.icon,
            items: [{
                text: "Edit strategic theme",
                onClick: () => this.editTheme(theme).subscribe(),
                icon: StrategicViewIcon.ThemeIcon,
                separatorBottom: true,
            }],
        }];

        if (await this.authorisationService.promiseToGetHasAccess(StrategyAuthService.EditStrategicInputs)) {
            menuItems[0].items?.push(this.inputsService.getAttachSWTInputMenuItem(theme));
            menuItems[0].items?.push(this.inputsService.getAttachCompetitorAnalysisInputMenuItem(theme));
        }

        if (await this.authorisationService.promiseToGetHasAccess(StrategyAuthService.EditBullseye)) {
            menuItems[0].items?.push(this.bullseyeService.getAttachBullseyeStatementMenuItem(theme));
        }

        return menuItems;
    }

    private updateZone() {
        if (this.zone) {
            return forkJoin([
                this.authorisationService.promiseToGetHasAccess(StrategyAuthService.ReadStrategyBoard),
                this.authorisationService.promiseToGetHasAccess(StrategyAuthService.ReadStrategicInputs),
                this.authorisationService.promiseToGetHasAccess(StrategyAuthService.ReadBullseye),
            ]).pipe(
                // only get content we have read access for
                switchMap(([canReadBoard, canReadInputs, canReadBullseye]) => forkJoin([
                    canReadBoard ? this.strategyService.getThemesByZone(this.zone!) : of([]),
                    canReadInputs ? this.inputsService.getInputLocationsForZone(this.zone!) : of([]),
                    canReadBullseye ? this.bullseyeService.getBullseyeStatementLocationsForZone(this.zone!) : of([]),
                ])),
                switchMap(([themes, inputLocations, bullseyeStatementLocations]) => {
                    // need to prime canvases first
                    let unprimedCanvasIds = inputLocations
                        .filter((i) => i.input.canvasId && !i.input.canvas)
                        .map((i) => i.input.canvasId);
                    unprimedCanvasIds = ArrayUtilities.distinct(unprimedCanvasIds);
                    if (unprimedCanvasIds.length > 0) {
                        return forkJoin(unprimedCanvasIds.map((canvasId) => this.inputsService.getCanvasById(canvasId))).pipe(
                            map(() => ({ themes, inputLocations, bullseyeStatementLocations })),
                        );
                    } else {
                        return of({ themes, inputLocations, bullseyeStatementLocations });
                    }
                }),
                switchMap((content) => {
                    // build theme menus
                    const themeToMenuMap = Object.fromEntries(content.themes
                        .map((theme) => [theme.name, this.createMenuItemsForTheme(theme)]));

                    if (Object.keys(themeToMenuMap).length === 0) {
                        return of(content);
                    }

                    return forkJoin(themeToMenuMap).pipe(
                        tap((menuItems) => this.themeMenuItemsMap = menuItems),
                        map(() => content),
                    );
                }),
                switchMap(({ themes, inputLocations, bullseyeStatementLocations }) => {
                    this.orderedThemes = themes;
                    this.categoriseInputLocations(inputLocations);
                    this.categoriseBullseyeStatements(bullseyeStatementLocations);

                    this.orderedThemeNames = this.orderedThemes.map((theme) => theme.name);
                    this.hasDisplayThemes = this.orderedThemeNames.length > 0; // Display theme is an odd one where it will be shown even if theme description is unticked
                    return of(undefined);
                }),
                tap(() => this.isInitialised = true),
                this.takeUntilDestroyed(),
            );
        } else {
            return of(undefined);
        }
    }

    private categoriseInputLocations(inputLocations: InputLocation[]) {
        this.categorisedSWTInputs = {};
        this.categorisedCAInputs = {};
        this.hasAttachedSWTInputs = this.includesSWTInputsView && inputLocations.filter((i) => i.input.canvas.type === CanvasType.StrengthsWeaknessesTrends).length > 0;
        this.hasAttachedCAInputs = this.includesCAInputsView && inputLocations.filter((i) => i.input.canvas.type === CanvasType.CompetitorAnalysis).length > 0;
        inputLocations.forEach((inputLocation) => this.getCategorisedInputsGroup(inputLocation).push(inputLocation));
    }

    private getCategorisedInputsGroup(inputLocation: InputLocation) {
        switch (inputLocation.input.canvas.type) {
            case CanvasType.StrengthsWeaknessesTrends:
                return this.getInputLocationsGroup(inputLocation, this.categorisedSWTInputs);
            case CanvasType.CompetitorAnalysis:
                return this.getInputLocationsGroup(inputLocation, this.categorisedCAInputs);
            default:
                throw new Error("TODO: this input type is not yet implemented: " + inputLocation.input.canvas.type);
        }

    }

    private getInputLocationsGroup(inputLocation: InputLocation, categorisedInputLocations: ICategorisedInputLocations) {
        let inputLocations = categorisedInputLocations[inputLocation.theme.name];
        if (!inputLocations) {
            inputLocations = [];
            categorisedInputLocations[inputLocation.theme.name] = inputLocations;
        }

        return inputLocations;
    }

    private categoriseBullseyeStatements(locations: BullseyeStatementLocation[]) {
        this.categorisedBullseyeStatements = {};
        this.hasAttachedBullseye = this.includesBullseyeView && locations.length > 0;
        locations.forEach((location) => {
            if (!location.theme) {
                throw new Error("Bullseye locations must be attached to a theme");
            } else {
                let catLocations = this.categorisedBullseyeStatements[location.theme.name];
                if (!catLocations) {
                    catLocations = [];
                    this.categorisedBullseyeStatements[location.theme.name] = catLocations;
                }

                catLocations.push(location);
            }
        });
    }

    private reorderThemesWithNamesOrder(orderedThemeNames: string[]) {
        // orderedThemes will include all themes including those without goals -> so just need to swap current visible ones without touching the invisible
        for (let i = 0; i < orderedThemeNames.length; i++) {
            const themeName = orderedThemeNames[i];
            const themeIndex = this.orderedThemes.findIndex((theme) => theme.name === themeName);
            const namesAfter = orderedThemeNames.slice(i + 1);
            for (let j = 0; j < themeIndex; j++) {
                const theme = this.orderedThemes[j];
                // get the 1st theme before themeIndex that's in namesAfter and swap them
                if (namesAfter.includes(theme.name)) {
                    // swap j and themeIndex
                    [this.orderedThemes[j], this.orderedThemes[themeIndex]] = [this.orderedThemes[themeIndex], this.orderedThemes[j]];
                    break;
                }
            }
        }

        let index = 0;
        this.orderedThemes.forEach((theme) => theme.ordinal = index++);
        // let breeze does its magic - there won't be a save POST if orderedThemes are unchanged
        return this.commonDataService.saveEntities(this.orderedThemes);
    }

    public getQuadrantTooltip(quadrant: BullseyeQuadrant) {
        return BullseyeQuadrantText[quadrant];
    }

    public getQuadrantIcon(quadrant: BullseyeQuadrant) {
        return BullseyeQuadrantIcon[quadrant];
    }
}

import { Component, Input, ViewChild } from "@angular/core";
import { PersonProfileCategory } from "@common/ADAPT.Common.Model/person/person-profile-category";
import { PersonProfileItem } from "@common/ADAPT.Common.Model/person/person-profile-item";
import { PersonProfileItemType } from "@common/ADAPT.Common.Model/person/person-profile-item-type";
import { PersonProfileItemTypeLabel } from "@common/ADAPT.Common.Model/person/person-profile-item-type-label";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IDxListItemReorderedEvent, IDxListSelectionChangedEvent } from "@common/ux/dx.types";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { DxListComponent } from "devextreme-angular";
import { switchMap, tap } from "rxjs/operators";
import { EditPersonalProfileCategoryDialogComponent } from "../edit-personal-profile-category-dialog/edit-personal-profile-category-dialog.component";
import { EditPersonalProfileItemDialogComponent } from "../edit-personal-profile-item-dialog/edit-personal-profile-item-dialog.component";

@Component({
    selector: "adapt-configure-personal-profile",
    templateUrl: "./configure-personal-profile.component.html",
    styleUrls: ["./configure-personal-profile.component.scss"],
})
export class ConfigurePersonalProfileComponent extends ConfigureFeatureBase {
    @Input() public configItem?: IConfigItem;

    public categories?: PersonProfileCategory[];
    public categoryItems?: PersonProfileItem[];
    public selectedCategory?: PersonProfileCategory;
    @ViewChild("categoryList") private categoryList!: DxListComponent;

    public constructor(
        configurationService: ConfigurationService,
        private commonDataService: CommonDataService,
        private dialogService: AdaptCommonDialogService,
        private directorySharedService: DirectorySharedService,
    ) {
        super(configurationService);
    }

    @Autobind
    public async initialiseData() {
        await this.getCategories();

        // prime the items for later
        await this.directorySharedService.promiseToGetAllPersonProfileItems();
        this.selectFirstCategory();
    }

    private async getCategories() {
        this.categories = await this.directorySharedService.promiseToGetAllPersonProfileCategories();
    }

    public onCategorySelectionChanged(e: IDxListSelectionChangedEvent<PersonProfileCategory>) {
        this.selectedCategory = ArrayUtilities.getSingleFromArray(e.addedItems);
        this.categoryItems = this.selectedCategory
            ? this.selectedCategory.personProfileItems.sort(SortUtilities.getSortByFieldFunction<PersonProfileItem>("ordinal"))
            : [];
    }

    @Autobind
    public addCategory() {
        return this.directorySharedService.createProfileCategory(this.categories!.length)
            .pipe(switchMap((cat) => this.editCategory(cat)))
            .pipe(tap((cat) => this.categories!.push(cat)))
            .pipe(tap((cat) => this.selectCategory(cat)));
    }

    @Autobind
    public editCategory(category: PersonProfileCategory) {
        return this.dialogService.open(EditPersonalProfileCategoryDialogComponent, category);
    }

    @Autobind
    public deleteCategory(category: PersonProfileCategory) {
        return this.deleteConfirmation("Delete profile category", "Are you sure you wish to delete the '" + category.label + "' category?").pipe(
            switchMap(() => this.commonDataService.remove(category)),
            switchMap(() => this.commonDataService.saveEntities([category])),
            tap(() => ArrayUtilities.removeElementFromArray(category, this.categories!)),
            tap(() => this.selectFirstCategory()),
        );
    }

    public async updateCategoryOrdinals(e: IDxListItemReorderedEvent<PersonProfileCategory>) {
        SortUtilities.reorderItemInIntegerSortedArray(this.categories!, "ordinal", e.fromIndex, e.toIndex);
    }

    public async updateItemOrdinals(e: IDxListItemReorderedEvent<PersonProfileItem>) {
        SortUtilities.reorderItemInIntegerSortedArray(this.categoryItems!, "ordinal", e.fromIndex, e.toIndex);
    }

    @Autobind
    public addCategoryItem() {
        return this.directorySharedService.createProfileItem(this.selectedCategory!.personProfileCategoryId, this.selectedCategory!.personProfileItems.length)
            .pipe(switchMap((item) => this.editCategoryItem(item)))
            .pipe(tap((item) => this.categoryItems!.push(item)));
    }

    @Autobind
    public editCategoryItem(item: PersonProfileItem) {
        return this.dialogService.open(EditPersonalProfileItemDialogComponent, item);
    }

    @Autobind
    public deleteCategoryItem(item: PersonProfileItem) {
        const message = "Are you sure you wish to delete the '" + item.label + "' profile item from this category?"
            + "<p><b>NOTE: This will remove ALL data associated with this profile item in your organisation.</b></p>";

        return this.deleteConfirmation("Delete profile item", message).pipe(
            switchMap(() => this.commonDataService.remove(item)),
            switchMap(() => this.commonDataService.saveEntities([item])),
            tap(() => () => ArrayUtilities.removeElementFromArray(item, this.categoryItems!)),
        );
    }

    public getItemTypeLabel(itemType: PersonProfileItemType) {
        return PersonProfileItemTypeLabel.ByType[itemType];
    }

    private selectCategory(category: PersonProfileCategory) {
        if (category && this.categoryList) {
            this.selectedCategory = category;
            this.categoryList.instance.selectItem(this.categories!.indexOf(category));
        } else {
            this.selectedCategory = undefined;
        }
    }

    private selectFirstCategory() {
        if (this.categoryList
            && this.categories
            && this.categories.length) {
            this.selectCategory(this.categories[0]);
        }
    }

    private deleteConfirmation(title: string, message: string) {
        return this.dialogService.openConfirmationDialog({
            title,
            message,
            confirmButtonText: "Delete & save",
        });
    }
}

import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from "@angular/core";
import { Survey, SurveyType, SurveyTypeLabel } from "@common/ADAPT.Common.Model/organisation/survey";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { IAdaptLinkObject, RouteService } from "@common/route/route.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { BehaviorSubject, combineLatest, EMPTY, forkJoin, Observable, of, ReplaySubject } from "rxjs";
import { delay, map, switchMap, tap } from "rxjs/operators";
import { SurveyService } from "../survey.service";
import { SurveyAuthService } from "../survey-auth.service";
import { SurveyUiService } from "../survey-ui.service";
import { SurveyUtils } from "../survey-utils";

@Component({
    selector: "adapt-manage-surveys",
    templateUrl: "./manage-surveys.component.html",
    styleUrls: ["../survey-global-styles.scss", "./manage-surveys.component.scss"],
})
export class ManageSurveysComponent extends BaseComponent implements OnInit {
    private static readonly ShowOutstandingSurveyResponsesQueryParamKey = "showOutstandingSurveyResponsesForSurveyId";

    @Input() public set team(value: Team) {
        this.team$.next(value);
    }

    @Input() public set surveyType(value: SurveyType) {
        this.surveyType$.next(value);
    }

    @Input() public callToActionTextTemplate?: TemplateRef<any>;
    @Output() public initialise = new EventEmitter<void>();

    public ongoingSurveys: Survey[] = [];
    public upcomingSurveys: Survey[] = [];
    public previousSurveys: Survey[] = [];

    public canCaptureSurvey$: Observable<boolean> = of(false);

    public analysePageObj?: IAdaptLinkObject;
    public learnMoreUrl$: Observable<string>;

    private surveyType$ = new ReplaySubject<SurveyType>(1);
    private _surveyType!: SurveyType;
    private team$ = new BehaviorSubject<Team | undefined>(undefined);
    private _team?: Team;

    public constructor(
        private surveyService: SurveyService,
        private uiService: SurveyUiService,
        surveyAuthService: SurveyAuthService,
        rxjsBreezeService: RxjsBreezeService,
        private routeService: RouteService,
    ) {
        super();

        this.learnMoreUrl$ = this.surveyType$.pipe(
            map((type) => SurveyUtils.forSurveyType(type).learnMoreUrl),
        );

        combineLatest([this.surveyType$, this.team$]).pipe(
            switchMap(([surveyType, team]) => {
                this._surveyType = surveyType;
                this._team = team;
                this.canCaptureSurvey$ = surveyAuthService.forSurveyType(surveyType).hasEditAccessToSurveys$(team);
                return this.initialiseSurveyData(surveyType, team);
            }),
            this.takeUntilDestroyed(),
        ).subscribe();

        // refresh the surveys in this page if there is any survey being saved, e.g. restore ended survey
        rxjsBreezeService.entityTypeChangedInSave(Survey).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updateData());
    }

    public get surveyTypeLabel() {
        return SurveyTypeLabel[this._surveyType];
    }

    public ngOnInit() {
        const showDetailsForSurveyId = Number(
            this.routeService.getSearchParameterValue(ManageSurveysComponent.ShowOutstandingSurveyResponsesQueryParamKey));
        if (showDetailsForSurveyId) {
            this.routeService.deleteSearchParameter(ManageSurveysComponent.ShowOutstandingSurveyResponsesQueryParamKey);
            this.surveyService.getSurveyById(showDetailsForSurveyId).pipe(
                switchMap((survey) => survey ? this.uiService.viewOutstandingResponsesForSurvey(survey) : EMPTY),
                this.takeUntilDestroyed(),
            ).subscribe();
        }
    }

    @Autobind
    public captureNewSurvey() {
        return this.uiService.createSurvey(this._surveyType, this._team).pipe(
            tap(() => this.updateData()),
        );
    }

    public updateData() {
        this.initialiseSurveyData(this._surveyType, this._team).pipe(
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public get atLeastOneSurvey() {
        return this.upcomingSurveys.length > 0
            || this.ongoingSurveys.length > 0
            || this.previousSurveys.length > 0;
    }

    private initialiseSurveyData(surveyType: SurveyType, team?: Team) {
        return this.surveyService.primeSurveysIfAuthorised(surveyType, team).pipe(
            switchMap(() => forkJoin([
                this.surveyService.getOngoingSurveys(surveyType, team),
                this.surveyService.getUpcomingSurveys(surveyType, team),
                this.surveyService.getPreviousSurveys(surveyType, team),
                SurveyUtils.forSurveyType(surveyType).getAnalyseSurveyPageRoute$(undefined, team?.teamId),
            ])),
            tap(([ongoingSurveys, upcomingSurveys, previousSurveys, analysePageObj]) => {
                this.ongoingSurveys = ongoingSurveys;
                this.upcomingSurveys = upcomingSurveys;
                this.previousSurveys = previousSurveys;
                this.analysePageObj = analysePageObj;
            }),
            delay(0), // emit next digest cycle for *ngIf here to catch-up with the checking of previousSurveys.length before finishing spinner
            tap(() => {
                this.isInitialised = true;
                this.initialise.emit();
            }),
        );
    }
}

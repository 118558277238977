import { EntityWithLayout } from "@common/ux/layout/layout.interface";
import { BaseActiveEntity } from "../base-active-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { EntityExtensions } from "../entity-extensions.decorator";
import { ConnectionType } from "./connection-type";
import { IEntityWithLabelLocations } from "./entity-with-label-locations";
import { LabelLocation } from "./label-location";
import { Layout } from "./layout";
import { Organisation } from "./organisation";
import { RoleConnection } from "./role-connection";
import { RoleExtensions } from "./role-extensions";
import { RoleFeaturePermission } from "./role-feature-permission";
import { RoleLocation } from "./role-location";
import { RoleSupplementaryData } from "./role-supplementary-data";
import { RoleType } from "./role-type";
import { Team } from "./team";

// Note: This has to be in-sync with labels from server in DefaultRoles.cs
export enum DefaultRoleLabel {
    Leader = "Leader",
    LeaderAdministrator = "Leader + Administrator",
    Administrator = "Administrator",
    Collaborator = "Collaborator",
    CollaboratorAdministrator = "Collaborator + Administrator",
    Participant = "Participant",
    Viewer = "Viewer",
    Coach = "Coach",
    CulturalLeader = "Cultural Leader",
}

export class Role extends BaseActiveEntity<Role> implements EntityWithLayout, IEntityWithLabelLocations {
    public roleId!: number;
    public organisationId!: number;
    public teamId?: number;
    public roleTypeId?: number;
    public connectionType!: ConnectionType;
    public label!: string;
    public startDate!: Date;
    public endDate?: Date;

    public organisation!: Organisation;
    public roleConnections!: RoleConnection[];
    public roleFeaturePermissions!: RoleFeaturePermission[];
    public roleLocations!: RoleLocation[];
    public roleType!: RoleType | null;
    public supplementaryData?: RoleSupplementaryData;
    public layoutId!: number | null;
    public layout!: Layout | null;
    public team!: Team;
    public labelLocations!: LabelLocation[];

    @EntityExtensions(RoleExtensions)
    public extensions!: RoleExtensions;
}

export const RoleBreezeModel = new BreezeModelBuilder("Role", Role)
    .orderBy("label")
    .withLabelField("label")
    .withSortField("label")
    .hasSource("OrganisationRoles")
    .withIdField()
    .alwaysFetchingNavigationProperty("roleFeaturePermissions")
    .isOrganisationEntity()
    .build();

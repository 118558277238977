import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { ToolbarInactiveComponent } from "./toolbar-inactive/toolbar-inactive.component";

@NgModule({
    imports: [
        CommonModule,
        AdaptShellModule,
        AdaptTooltipModule,
    ],
    declarations: [
        ToolbarInactiveComponent,
    ],
    exports: [
        ToolbarInactiveComponent,
    ],
})
export class AdaptOrganisationSharedModule { }

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChildren } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { EntityPersistentService } from "@common/lib/data/entity-persistent.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { RouteEventsService } from "@common/route/route-events.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ChangeManagerService } from "@common/ux/change-manager/change-manager.service";
import { EMPTY, lastValueFrom, Observable, Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { ChainCommentComponent, IChainComment } from "../chain-comment/chain-comment.component";
import { IChainUpdate } from "../chain-update/chain-update.component";

export { IChainComment, IChainUpdate };

@Component({
    selector: "adapt-comment-chain",
    templateUrl: "./comment-chain.component.html",
    styleUrls: ["./comment-chain.component.scss"],
})
export class CommentChainComponent<TComment extends IBreezeEntity<TComment>, TUpdate extends IBreezeEntity<TUpdate>> extends BaseComponent implements OnInit, OnChanges, OnDestroy {
    @Input() public comments?: IChainComment<TComment>[];
    @Input() public updates?: IChainUpdate<TUpdate>[];
    @Input() public createComment$: Observable<IChainComment<TComment>> = EMPTY;
    @Input() public allowNewCommentSave = true;
    @Input() public noCommentsText = "No comments yet.";
    @Input() public focusAddCommentOnInit = false;
    // edit item and objective dialog will both create new placeholder comment after saving
    // but we don't want this behaviour for item preview pane as that will change back to a placeholder
    // dom just like Jira. This flag is to control the creation after save instead of rejecting newly created item comment
    // in item preview.
    @Input() public createNewCommentAfterSave = true;

    @Output() public saving = new EventEmitter<TComment>();
    @Output() public saved = new EventEmitter<TComment>();
    @Output() public saveFailed = new EventEmitter<{ e: any, entity: TComment }>();
    @Output() public chainUpdateEdited = new EventEmitter<IChainUpdate<TUpdate>>();
    @Output() public chainUpdateDeleted = new EventEmitter<IChainUpdate<TUpdate>>();
    @Output() public newCommentDiscarded = new EventEmitter<TComment | undefined>();
    @Output() public updateRequired = new EventEmitter<void>();

    // Temp to allow AngularJs interop
    @Output() public initialised = new EventEmitter<CommentChainComponent<TComment, TUpdate>>();

    @ViewChildren(ChainCommentComponent) public commentItemInstances: ChainCommentComponent<TComment>[] = [];

    public commentItems: (IChainComment<TComment> | IChainUpdate<TUpdate>)[] = [];
    public newComment?: IChainComment<TComment>;
    public justAddedComment?: IChainComment<TComment>;

    private cleanup: (() => void)[] = [];
    private maskChainUpdate = false;
    private newCommentSubscription?: Subscription;

    constructor(
        private commonDataService: CommonDataService,
        private entityPersistentService: EntityPersistentService,
        private changeManager: ChangeManagerService,
        private routeEventsService: RouteEventsService,
    ) {
        super();
    }

    public ngOnInit() {
        this.cleanup.push(this.changeManager.registerCleanupFunction(() => {
            this.maskChainUpdate = true;
            return this.removeNewCommentIfEmpty();
        }));
        this.cleanup.push(this.changeManager.registerCustomRestoreFunction(() => {
            this.maskChainUpdate = false;
            this.createPlaceholderCommentIfNotExists();
        }));
        // also need to cater for the case where cleanup function is triggered from route change but not really moving away from the page
        // - need to reset mask so that we can get further update
        this.routeEventsService.navigationEnd$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.maskChainUpdate = false);

        this.initialised.emit(this);
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        this.removeNewCommentIfEmpty();
        this.cleanup.forEach((cb) => cb());
        if (this.newCommentSubscription) { // entity persist service will complete the subject but here just in case
            this.newCommentSubscription.unsubscribe();
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.comments || changes.updates) {
            this.updateCommentItems();
        }

        if (changes.createComment$) {
            if (this.createComment$) {
                this.createPlaceholderCommentIfNotExists(true);
            } else {
                // createComment$ gone, most probably after a save and create observable removed from preview panel
                this.newComment = undefined;
            }
        }
    }

    public updateCommentItems() {
        if (this.maskChainUpdate) {
            return;
        }

        if (!this.comments) {
            this.comments = [];
        }

        const existingNewComment = this.comments
            .find((c) => c.entity.entityAspect.entityState.isAdded());
        if (existingNewComment) {
            this.newComment = existingNewComment;
        }

        this.updateChainUpdateCommentStatus();
        const existingComments = this.comments.filter((i) => !i.entity.entityAspect.entityState.isAdded());
        this.commentItems = [...existingComments, ...(this.updates || [])]
            .sort((a, b) => {
                let result = b.dateTime.getTime() - a.dateTime.getTime();
                if (result === 0) {
                    // exact same time -> comment first before update
                    result = "entity" in a ? 1 : -1;
                }

                return result;
            });
    }

    @Autobind
    public onDeleted(chainComment: IChainComment<TComment>) {
        ArrayUtilities.removeElementFromArray(chainComment, this.commentItems);
        if (this.comments) {
            ArrayUtilities.removeElementFromArray(chainComment, this.comments);
        }

        this.updateChainUpdateCommentStatus();
    }

    @Autobind
    public async saveNewComment() {
        if (!this.newComment) {
            return;
        }

        try {
            (this.newComment.entity as any).dateTime = new Date(); // use current time when saving - not when entering the page!
            this.saving.emit(this.newComment.entity);
            await lastValueFrom(this.commonDataService.saveEntities(this.newComment.entity));
            this.saved.emit(this.newComment.entity);
            this.commentItems.unshift(this.newComment);
            if (this.createNewCommentAfterSave) {
                this.createNewPlaceholderCommentIfSavedOrDetached();
            }
        } catch (e) {
            this.saveFailed.emit({ e, entity: this.newComment.entity });
            throw e; // need this throw so that the save button will stay behind if save failed
        }
    }

    private updateChainUpdateCommentStatus() {
        const comments = this.comments || [];
        comments.forEach((comment) => comment.hasUpdate = false);
        if (this.updates) {
            this.updates.forEach((update) => {
                const updateComment = comments!.find(
                    (comment) => comment.dateTime.getTime() === update.dateTime.getTime());
                update.hasComment = !!updateComment;
                if (updateComment) {
                    updateComment.hasUpdate = true;
                }
            });
        }
    }

    @Autobind
    private createNewPlaceholderCommentIfSavedOrDetached() {
        if (!this.newComment) {
            return;
        }

        const state = this.newComment.entity.entityAspect.entityState;
        if (!state.isUnchanged() && !state.isDetached()) {
            return;
        }

        if (state.isUnchanged()) {
            this.justAddedComment = this.newComment;
        }

        this.createPlaceholderComment();
    }

    @Autobind
    private createPlaceholderCommentIfNotExists(removeOnImport = false) {
        if (!this.newComment && this.createComment$) {
            this.createPlaceholderComment(removeOnImport);
        }
    }

    @Autobind
    private createPlaceholderComment(removeOnImport = false) {
        this.createComment$.subscribe((comment) => {
            this.newComment = comment;
            if (removeOnImport) {
                if (this.newCommentSubscription) {
                    this.newCommentSubscription.unsubscribe();
                }

                this.newCommentSubscription = this.entityPersistentService.entityTypeImported(comment.entity.entityType.shortName)
                    .pipe(
                        take(1),
                    ).subscribe(async (e) => {
                        if (this.comments && !this.comments.find((i) => i.entity === e.entity) && e.entity !== this.newComment?.entity) {
                            await this.removeNewComment();
                            this.updateRequired.emit();
                        }
                    });
            }
        });
    }

    @Autobind
    private removeNewCommentIfEmpty() {
        if (!this.newComment) {
            return Promise.resolve();
        }

        if (!StringUtilities.trimHtml(this.newComment.comment)) {
            return this.removeNewComment();
        }

        return Promise.resolve();
    }

    private async removeNewComment() {
        if (this.newComment) {
            await lastValueFrom(this.commonDataService.remove(this.newComment.entity));
            this.newCommentDiscarded.emit(this.newComment?.entity);
            this.newComment = undefined;
        }
    }
}

<adapt-dialog [maxWidth]="600"
              [showHelpButton]="false">
    <h3 adapt-dialog-title>Failed to save</h3>

    <div adapt-dialog-content
         class="alert alert-danger">
        <p>
            Saving those changes was unsuccessful.
        </p>
        <p>
            This may be caused by failed connection to the server, excessive delay from
            the server, internal server error or your browser being offline.
        </p>
        <p *ngIf="latestFailure">
            Your latest attempt to save failed with error: {{latestFailure}}.
        </p>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveText="Retry"
                                   [saveBlockingClick]="saveAndClose"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>

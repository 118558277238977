<adapt-dialog [maxWidth]="1200">
    <h3 class="d-flex align-items-center"
        adapt-dialog-title>Attach bullseye inputs to
        <ng-container *ngIf="data?.theme as theme">
            <i [ngClass]="StrategicViewIcon.ThemeIcon"
               adaptTooltip="Theme"
               class="mx-1"></i>
            {{theme.name}}
        </ng-container>
    </h3>

    <div adapt-dialog-content
         class="h-100">
        <div class="d-flex flex-column flex-xl-row gap-3">
            <div class="half-width-column"
                 [adaptCollapsible]="ImplementationKitArticle.BullseyeAttachStatements">
                <adapt-styled-implementation-kit [articleId]="ImplementationKitArticle.BullseyeAttachStatements"
                                                 contentStyle="embedded"></adapt-styled-implementation-kit>
            </div>
            <div *ngIf="hasBullseyeStatements; else noStatement"
                 class="half-width-column">
                <span class="mt-3 mb-2">Select one or more bullseye inputs to attach:</span>
                <adapt-bullseye-view [hideOuter]="true"
                                     [isSelecting]="true"
                                     [selectedStatements]="selectedStatements"
                                     (statementSelect)="onStatementSelected($event)"
                                     (statementDeselect)="onStatementDeselected($event)"></adapt-bullseye-view>
                <span class="mt-3">Note: Click on the currently selected input to remove.</span>
            </div>
            <ng-template #noStatement>
                <div class="alert alert-warning doc-column"
                     *adaptLoading="!isInitialised">
                    <p>
                        There are currently no bullseye inputs to attach to the strategic working board.
                    </p>
                </div>
            </ng-template>
        </div>
    </div>

    <div adapt-dialog-footer
         class="d-flex w-100">
        <adapt-standard-dialog-buttons [saveIsDisabled]="hasValidationErrors"
                                       [saveBlockingClick]="saveAndClose"
                                       saveText="Attach & close"
                                       (cancelClick)="cancel()"
                                       cancelText="Cancel">
        </adapt-standard-dialog-buttons>
    </div>
</adapt-dialog>

import { Pipe, PipeTransform } from "@angular/core";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import moment from "moment";

@Pipe({
    name: "adaptMinutesToDuration",
})
export class MinutesToDurationPipe implements PipeTransform {
    public transform(minutes: number): string {
        const duration = moment.duration({ minutes });
        return `${StringUtilities.zeroPad((duration.days() * 24) + duration.hours(), 2)}:${StringUtilities.zeroPad(duration.minutes(), 2)}`;
    }
}

import { AfterViewChecked, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from "@angular/core";
import { Survey, SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { SurveyResponseGroup, SurveyResponseGroupLabel } from "@common/ADAPT.Common.Model/organisation/survey-response";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { Breakpoint } from "@common/ux/responsive/breakpoint";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { combineLatest, EMPTY, ReplaySubject } from "rxjs";
import { switchMap } from "rxjs/operators";
import { DisplaySurveyQuestionStatsComponent } from "../display-survey-question-stats/display-survey-question-stats.component";
import { SurveyService } from "../survey.service";
import { ISurveyQuestions } from "../survey-questions.interface";
import { SurveyUtils } from "../survey-utils";

@Component({
    selector: "adapt-display-category-questions",
    templateUrl: "./display-category-questions.component.html",
    styleUrls: ["./display-category-questions.component.scss"],
})
export class DisplayCategoryQuestionsComponent extends BaseComponent implements OnInit, AfterViewChecked {

    @ViewChildren(DisplaySurveyQuestionStatsComponent) private allStats = new QueryList<DisplaySurveyQuestionStatsComponent>();
    @Input() public set categoryId(value: number) {
        this.categoryId$.next(value);
    }

    public get surveyQuestions() {
        return this.latestSurveyQuestions!;
    }

    @Input() public set surveyQuestions(value: ISurveyQuestions) {
        this.surveyQuestions$.next(value);
    }

    public get survey() {
        return this.latestSurvey!;
    }

    @Input() public set survey(value: Survey) {
        this.survey$.next(value);
    }

    @Output() public summaryHeightChange = new EventEmitter<number>();
    @Input() public set otherSummaryHeight(value: number | undefined) {
        this.otherSummaryHeight$.next(value!);
    }

    @Input() public forceInitialise: boolean = false;

    public categoryQuestionIds: number[] = [];
    public categoryScoreSummary?: string;
    public categoryIntro?: string;
    public categoryPercentageScoreText?: string;
    public percentageScore?: number;
    public latestSurvey?: Survey;
    public initialised = false;
    public isXL = false;
    public doughnutText = "Average";
    public responseGroups: SurveyResponseGroup[] = [];
    public responseGroupLabel = SurveyResponseGroupLabel;

    private latestCategoryId?: number;
    private latestSurveyQuestions?: ISurveyQuestions;

    private categoryId$ = new ReplaySubject<number>(1);
    private surveyQuestions$ = new ReplaySubject<ISurveyQuestions>(1);
    private survey$ = new ReplaySubject<Survey>(1);
    private otherSummaryHeight$ = new ReplaySubject<number>(1);

    private summaryElement?: JQuery<HTMLElement>;
    private summaryElementHeight = 0;
    private otherHeight?: number;

    public constructor(
        elementRef: ElementRef,
        responsiveService: ResponsiveService,
        private surveyService: SurveyService,
    ) {
        super(elementRef);

        responsiveService.currentBreakpoint$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((breakpoint) => this.isXL = breakpoint === Breakpoint.XL);

        combineLatest([this.categoryId$, this.surveyQuestions$, this.survey$]).pipe(
            switchMap((results) => {
                [this.latestCategoryId, this.latestSurveyQuestions, this.latestSurvey] = results;
                this.initialised = false;
                this.summaryElement = undefined; // it will be a new element after *adaptLoading toggle
                this.responseGroups = SurveyUtils.forSurveyType(this.latestSurvey.surveyType).responseGroups;

                this.doughnutText = this.latestSurvey.surveyType === SurveyType.ResilientBusinessAssessment ? "Overall" : "Average";

                if (this.latestCategoryId && this.latestSurveyQuestions && this.latestSurvey) {
                    this.categoryQuestionIds = this.latestSurveyQuestions.getQuestionIdsForCategory(this.latestCategoryId)!;
                    this.categoryIntro = this.latestSurveyQuestions.getCategory(this.latestCategoryId)?.categoryPageIntro;

                    return this.surveyService.getCategoryPercentageScoreForSurvey(this.latestCategoryId, this.latestSurvey, this.latestSurveyQuestions);
                } else {
                    return EMPTY;
                }
            }),
            this.takeUntilDestroyed(),
        ).subscribe((percentageScore) => {
            this.percentageScore = percentageScore;
            this.categoryPercentageScoreText = Math.floor(percentageScore) + "%";
            const category = this.latestSurveyQuestions!.getCategory(this.latestCategoryId!);
            if (category && category.gradeSummary) {
                const scoreGrade = SurveyUtils.forSurveyType(this.latestSurvey!.surveyType).getScoreGrade(percentageScore);
                this.categoryScoreSummary = category.gradeSummary[scoreGrade];
            }

            this.initialised = true;
        });

        this.sizeChange$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updateSummaryHeight());

        this.otherSummaryHeight$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((otherHeight) => {
            this.otherHeight = otherHeight;
            this.updateOtherHeight();
        });
    }

    public get isDrawn() {
        return !this.allStats.some((s) => !s.isDrawn);
    }

    public ngOnInit() {
        if (!this.latestCategoryId || !this.latestSurveyQuestions || !this.latestSurvey) {
            throw new AdaptError(`Expected categoryId(${this.latestCategoryId}), surveyQuestions(${this.latestSurveyQuestions}) or survey(${this.latestSurvey}) not found`);
        }
    }

    public ngAfterViewChecked() {
        // element not defined OnInit, AfterViewInit or AfterContentInit - this will check till it is defined
        if (!this.summaryElement || this.summaryElement.length < 1) {
            this.summaryElement = jQuery(this.elementRef!.nativeElement).find(".survey-category-summary");
        }

        this.updateSummaryHeight();
    }

    private updateSummaryHeight() {
        if (this.summaryElement && this.summaryElement.length > 0) {
            const currentHeight = this.summaryElement.height()!;
            if (currentHeight !== this.summaryElementHeight) {
                this.summaryElementHeight = currentHeight;
                this.summaryHeightChange.emit(currentHeight);
                this.updateOtherHeight();
            }
        }
    }

    private updateOtherHeight() {
        if (this.summaryElement?.length) {
            if (this.otherHeight) {
                this.summaryElement.css("min-height", `${this.otherHeight}px`);
            } else {
                this.summaryElement.css("min-height", "");
            }
        }
    }
}

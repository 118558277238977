import { InjectionToken, Provider, Type } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";

export const USER_EVENT_HANDLERS = new InjectionToken<IUserEventHandler[]>("USER_EVENT_HANDLERS");

export interface IUserEventHandler {
    userChanged(person?: Person): void;
}

export const provideUserEventHandler = (handler: Type<IUserEventHandler>): Provider => {
    return {
        provide: USER_EVENT_HANDLERS,
        useExisting: handler,
        multi: true,
    };
};

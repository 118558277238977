<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.StrategyBoard"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<div adaptToolbarContent
     adaptBlockingButtonGroup>
    <button adaptCallToActionButton
            *adaptIfAuthorised="EditStrategyBoard"
            adaptButtonIcon="add"
            [adaptBlockingClick]="addTheme">Add strategic theme
    </button>

    <adapt-menu class="px-1"
                *adaptIfAuthorised="EditStrategyBoard"
                data-tour="strategy-board-page-show-hide"
                [items]="viewMenuItems">
    </adapt-menu>

</div>
<!-- @ViewChild is null; not undefined before view contents are loaded -->
<ng-container [ngTemplateOutlet]="mapComponent?.viewToggleButtonsTemplate ?? undefined"
              adaptToolbarContent
              alignment="center"></ng-container>

<adapt-strategy-board [isEditing]="true"
                      [views]="views"></adapt-strategy-board>

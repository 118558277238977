import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Organisation } from "./organisation";

export class Configuration extends BaseEntity<Configuration> {
    public organisationId!: number;
    public productZoneName!: string;
    public productZonePosition!: number;

    // navigation
    public organisation!: Organisation;
}

export const ConfigurationBreezeModel = new BreezeModelBuilder("Configuration", Configuration)
    .hasSource()
    .withIdField("organisationId")
    .isOrganisationEntity()
    .build();

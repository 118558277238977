import { Component } from "@angular/core";
import { Bullseye } from "@common/ADAPT.Common.Model/organisation/bullseye";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { BullseyeService } from "../bullseye.service";

@Component({
    selector: "adapt-show-bullseye-dialog",
    templateUrl: "./show-bullseye-dialog.component.html",
    styleUrls: ["./show-bullseye-dialog.component.scss"],
})
export class ShowBullseyeDialogComponent extends BaseDialogComponent<void> {
    public readonly dialogName = "ShowBullseye";

    public bullseye?: Bullseye;

    public constructor(
        bullseyeService: BullseyeService,
    ) {
        super();

        bullseyeService.getBullseye().pipe(
            this.takeUntilDestroyed(),
        ).subscribe((bullseye) => this.bullseye = bullseye);
    }
}

import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Organisation } from "./organisation";
import { Team } from "./team";

export class GuidingPhilosophy extends BaseEntity<GuidingPhilosophy> {
    public guidingPhilosophyId!: number;
    public organisationId!: number;
    public teamId?: number;
    public context?: string;
    public vision?: string;
    public bullseye?: string;
    public seeds?: string;
    public lastUpdated!: Date;
    public organisation!: Organisation;
    public team!: Team;

    public get hasData() {
        return !!this.context || !!this.vision || !!this.bullseye || !!this.seeds;
    }
}

export const GuidingPhilosophyBreezeModel = new BreezeModelBuilder("GuidingPhilosophy", GuidingPhilosophy)
    .withPluralName("GuidingPhilosophies")
    .hasSource()
    .isOrganisationEntity()
    .persistChangedEntity()
    .build();

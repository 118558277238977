import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptCommonDataModule } from "@common/lib/data/common-data.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptEditorViewModule } from "@common/ux/html-editor/editor-view.module";
import { UnsavedChangesDialogComponent } from "./unsaved-changes-dialog/unsaved-changes-dialog.component";


@NgModule({
    imports: [
        CommonModule,
        AdaptCommonDataModule,
        AdaptCommonDialogModule,
        AdaptEditorViewModule,
    ],
    declarations: [
        UnsavedChangesDialogComponent,
    ],
    exports: [
        UnsavedChangesDialogComponent,
    ],
})
export class AdaptChangeManagerModule {
}

<div class="d-flex flex-column align-items-center gap-4">
    <adapt-strategic-guidance></adapt-strategic-guidance>

    <ng-container *ngIf="valuesConstitution && valuesConstitution.values.length > 0">
        <adapt-see-more-popup [minWidth]="500"
                              class="w-100"
                              popupTitle="Values"
                              buttonText="View values">
            <ng-template>
                <div class="w-100">
                    <adapt-values-constitution [valuesConstitution]="valuesConstitution"
                                               [fullPage]="false"
                                               [isEditing]="canEdit"></adapt-values-constitution>
                </div>
            </ng-template>
        </adapt-see-more-popup>
    </ng-container>

    <button *ngIf="canEdit"
            class="btn btn-primary px-4"
            [adaptBlockingClick]="recordValue"
            role="button">Record a value</button>
</div>

<adapt-dialog [maxWidth]="600"
              [expandable]="false"
              [showHelpButton]="false">
    <h3 adapt-dialog-title>Conflicting changes detected</h3>
    <div adapt-dialog-content
         class="content">
        <p>
            <adapt-link-person [person]="changedByPerson"></adapt-link-person>
            has made changes that conflict with yours:
        </p>
        <ul>
            <li *ngFor="let message of conflictingEntityMessages"
                [innerHTML]="message"></li>
        </ul>
        <p class="mb-0"
           [ngSwitch]="allowKeepLocalChanges">
            <ng-container *ngSwitchCase="true">
                We're going to merge their changes with yours.
                If there's any conflict, we can either use their version or your version.
            </ng-container>
            <ng-container *ngSwitchDefault>These changes require you to apply their changes.</ng-container>
        </p>
    </div>

    <div class="buttons"
         adapt-dialog-footer
         adaptBlockingButtonGroup>
        <ng-container [ngSwitch]="allowKeepLocalChanges">
            <button *ngSwitchCase="true"
                    adaptButton="default"
                    (click)="useTheirChanges()">Use their changes</button>
            <button *ngSwitchCase="true"
                    adaptButton="default"
                    (click)="useMyChanges()">Use my changes</button>
            <button *ngSwitchDefault
                    adaptButton="default"
                    (click)="useTheirChanges()">Apply their changes</button>
        </ng-container>
    </div>
</adapt-dialog>

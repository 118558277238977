import { Directive, OnInit } from "@angular/core";
import { LabelLocation } from "@common/ADAPT.Common.Model/organisation/label-location";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { lastValueFrom } from "rxjs";
import { SearchService } from "./search.service";
import { ILabelledSearchResult } from "./search-results.interface";
import { SearchRowBaseComponent } from "./search-row-base.component";

@Directive()
export abstract class SearchRowLabelledBaseComponent<TSearchResult extends ILabelledSearchResult> extends SearchRowBaseComponent<TSearchResult> implements OnInit {
    public labelLocations: LabelLocation[] = [];

    protected constructor(
        protected searchService: SearchService,
        protected labellingService: LabellingService,
    ) {
        super(searchService);
    }

    public async ngOnInit() {
        if (!this.keyword) {
            this.searchService.searchOptions$.pipe(
                this.takeUntilDestroyed(),
            ).subscribe(({ keyword }) => this.keyword = keyword!);
        }

        if (this.result.labelLocationIds && this.result.labelLocationIds.length > 0) {
            // we can just get all label locations from cache here as search service has retrieved the necessary label locations already.
            const allLabelLocations = await lastValueFrom(this.labellingService.getAllLabelLocations());
            this.labelLocations = allLabelLocations.filter((ll) => this.result.labelLocationIds.includes(ll.labelLocationId));
        }
    }
}

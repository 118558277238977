import { Component, Input, OnChanges } from "@angular/core";
import { ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { IConfigItem } from "../../configuration.interfaces";

@Component({
    selector: "adapt-feature-guidance",
    templateUrl: "./feature-guidance.component.html",
})
export class FeatureGuidanceComponent implements OnChanges {
    @Input() public configItem?: IConfigItem;

    public url?: string;
    public text?: string;

    public async ngOnChanges() {
        if (!this.configItem) {
            return;
        }

        if (this.configItem.supportUrl) {
            this.url = this.configItem.supportUrl;
        } else if (this.configItem.supportArticleId) {
            this.url = ImplementationKitService.GetArticleLink(this.configItem.supportArticleId);
        }

        this.text = this.configItem.supportText;
    }
}

import { Component, Input, ViewEncapsulation } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { BaseComponent } from "@common/ux/base.component/base.component";

@Component({
    selector: "adapt-person-image",
    templateUrl: "./person-image.component.html",
    styleUrls: ["./person-image.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class PersonImageComponent extends BaseComponent {
    public static readonly DefaultProfileImageUrl = "/content/user/person-image/default_user.png";

    @Input() public person?: Person;
    @Input() public containerClass = "";
    @Input() public imageSize: "xs" | "sm" | "md" | "lg" = "md";
    @Input() public disabled = false;

    public defaultImageSrc = PersonImageComponent.DefaultProfileImageUrl;

    public constructor() {
        super();
    }
}

import { InjectionToken, Type } from "@angular/core";
import { Observable } from "rxjs";
import { IAdaptMenuItem } from "../../ux/menu/menu.component";

export const USER_MENU_ITEMS = new InjectionToken<IUserMenuItem>("USER_MENU_ITEMS");

export interface IUserMenuItem extends IAdaptMenuItem {
    href$: Observable<string>;
    isShown$: Observable<boolean>;
    testAttribute?: string;
}

export function provideUserMenuItem(item: Type<IUserMenuItem>) {
    return {
        provide: USER_MENU_ITEMS,
        useClass: item,
        multi: true,
    };
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { AdaptTooltipModule } from "../tooltip.directive/tooltip.module";
import { StyledDashboardElementComponent } from "./styled-dashboard-element.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AdaptTooltipModule,
        CollapseModule,
    ],
    declarations: [
        StyledDashboardElementComponent,
    ],
    exports: [
        StyledDashboardElementComponent,
    ],
})
export class AdaptStyledDashboardElementModule {}

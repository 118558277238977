import { Component, Injector, OnInit } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { GuidingPhilosophy } from "@common/ADAPT.Common.Model/organisation/guiding-philosophy";
import { Purpose } from "@common/ADAPT.Common.Model/organisation/purpose";
import { ValueStream } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { CommonPurposeVisionService } from "@org-common/lib/purpose-vision/common-purpose-vision.service";
import { CommonPurposeVisionAuthService } from "@org-common/lib/purpose-vision/common-purpose-vision-auth.service";
import { from, lastValueFrom, merge, Observable } from "rxjs";
import { startWith, tap } from "rxjs/operators";
import { GuidingPhilosophyService } from "../guiding-philosophy.service";
import { GuidingPhilosophyUiService } from "../guiding-philosophy-ui.service";

@Component({
    selector: "adapt-guiding-philosophy-page",
    templateUrl: "./guiding-philosophy-page.component.html",
    styleUrls: ["./guiding-philosophy-page.component.scss"],
})
export class GuidingPhilosophyPageComponent extends BaseRoutedComponent implements OnInit {
    public readonly FeatureName = FeatureName;

    public guidingPhilosophy?: GuidingPhilosophy;
    public organisationPurpose?: Purpose;
    public valueStreamsWithPrimedPurpose?: ValueStream[] = [];

    public canEditGuidingPhilosophy$: Observable<boolean>;
    private article = this.isAlto ? ImplementationKitArticle.PurposeOverview : ImplementationKitArticle.ValuePurposeVision;
    public learnMoreUrl = ImplementationKitService.GetArticleLink(this.article);

    public lastUpdated?: Date;

    public constructor(
        private purposeVisionService: CommonPurposeVisionService,
        private guidingPhilosophyService: GuidingPhilosophyService,
        private guidingPhilosophyUiService: GuidingPhilosophyUiService,
        private rxjsBreezeService: RxjsBreezeService,
        authService: AuthorisationService,
        injector: Injector,
    ) {
        super(injector);
        this.canEditGuidingPhilosophy$ = from(authService.promiseToGetHasAccess(CommonPurposeVisionAuthService.EditPurposeVision));
    }

    public async ngOnInit() {
        [
            this.guidingPhilosophy,
            this.organisationPurpose,
            this.valueStreamsWithPrimedPurpose,
        ] = await Promise.all([
            lastValueFrom(this.purposeVisionService.getVision()),
            lastValueFrom(this.purposeVisionService.getPurpose()),
            lastValueFrom(this.guidingPhilosophyService.getActiveValueStreamsWithPurposes()),
        ]);

        // update lastUpdated date as required
        merge(
            this.rxjsBreezeService.entityTypeChanged(GuidingPhilosophy),
            this.rxjsBreezeService.entityTypeChanged(Purpose),
        ).pipe(
            startWith(undefined),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.lastUpdated = this.getLastUpdatedDate());

        this.notifyActivated();
    }

    @Autobind
    public editGuidingPhilosophy() {
        return this.guidingPhilosophyUiService.editGuidingPhilosophy().pipe(
            tap((data) => {
                // Need to set these because they might not have already been created
                this.guidingPhilosophy = data.guidingPhilosophy;
                this.organisationPurpose = data.organisationPurpose;
            }),
        );
    }

    public get guidingPhilosophyHasData() {
        return !!this.guidingPhilosophy?.hasData
            || !!this.organisationPurpose?.content
            || this.valueStreamsWithPrimedPurpose?.some((i) => !!i.purpose?.content);
    }

    private getLastUpdatedDate() {
        const valueStreamDates = this.valueStreamsWithPrimedPurpose
            ?.flatMap((vs) => vs.purposes.map((p) => p.lastUpdated)) ?? [];
        const dates = ([this.guidingPhilosophy?.lastUpdated, this.organisationPurpose?.lastUpdated, ...valueStreamDates]
            .filter((date) => !!date)) as Date[];
        if (dates.length === 0) {
            return undefined;
        }

        // use the most recent update date from the entities
        return new Date(Math.max.apply(null, dates.map((d) => d.getTime())));
    }
}

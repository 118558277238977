import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ObjectiveStatus } from "@common/ADAPT.Common.Model/organisation/objective-status";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { IGroupedData } from "@common/lib/utilities/grouped-data.interface";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { ItemReorderedEvent } from "devextreme/ui/list";
import { IObjectiveGroup, ObjectivesService } from "../objectives.service";

@Component({
    selector: "adapt-sortable-objective-groups",
    templateUrl: "./sortable-objective-groups.component.html",
    styleUrl: "./sortable-objective-groups.component.scss",
})
export class SortableObjectiveGroupsComponent implements OnChanges {
    @Input() public teamId?: number; // to determine if that's org or team objectives - will only sort 1 team or org at a time
    @Input() public objectiveGroups: IObjectiveGroup[] = [];
    @Input() public indented = false;
    @Output() public objectivesReordered = new EventEmitter<Objective[]>();

    public sortableChildGroups: { [objectiveId: number]: IObjectiveGroup[] } = {};
    public sortableGroupsByParent: IGroupedData<Objective | undefined, IObjectiveGroup>[] = [];

    public constructor(
        private objectivesService: ObjectivesService,
    ) { }

    public ngOnChanges() {
        // drop all parents which are not in this team

        const sortableGroups = Array.isArray(this.objectiveGroups)
            ? this.objectiveGroups
                .flatMap((group) => this.objectivesService.getTopLevelChildGroupsInTeam(group, this.teamId))
                .filter((group) => group.objective.status !== ObjectiveStatus.Closed) // won't sort closed objectives as they will go to the bottom
            : [];
        this.sortableGroupsByParent = ArrayUtilities.groupArrayBy(sortableGroups, (g) => g.objective.parentObjective)
            .filter((groupData) => this.objectivesService.objectiveGroupsAreSortableInTeam(groupData.items, this.teamId));

        // for this lookup table to avoid having to do filter every digest cycle - only want to show objectives matching the teamId
        this.sortableChildGroups = {};
        this.sortableGroupsByParent.forEach((groupData) =>
            groupData.items.forEach((g) => this.sortableChildGroups[g.objective.objectiveId] =
                g.childGroups.filter((childGroup) => childGroup.objective.teamId == this.teamId)));
    }

    public reorderObjectives(e: ItemReorderedEvent, groups: IObjectiveGroup[]) {
        const objectives = groups.map((group) => group.objective);
        SortUtilities.sequenceNumberFieldInArray(objectives, "ordinal"); // need this as ordinal wasn't used previously
        SortUtilities.reorderItemInIntegerSortedArray(objectives, "ordinal", e.fromIndex, e.toIndex);
        groups.sort(SortUtilities.getSortByFieldFunction((g) => g.objective.ordinal));
        this.objectivesReordered.emit(objectives);
    }
}

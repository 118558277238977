import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { ItemLink } from "@common/ADAPT.Common.Model/organisation/item-link";
import { ItemStatus, ItemStatusMetadata } from "@common/ADAPT.Common.Model/organisation/item-status";

export interface IItemCodeBreakdown {
    boardAbbreviation?: string;
    boardIndex?: number;
}

export class ItemUtilities {
    public static getSummaryHtml(item: Item) {
        return `<strong>${item.code}</strong> - <i>"${item.summary}"</i>`;
    }

    public static getItemLinkStatusAndItemCodeSortComparator(item: Item) {
        const itemsComparator = this.getItemStatusAndItemCodeSortComparator();
        return function compareStatusThenItemCode(link1: ItemLink, link2: ItemLink) {
            const otherItem1 = link1.primaryItemId === item.itemId
                ? link1.secondaryItem
                : link1.primaryItem;
            const otherItem2 = link2.primaryItemId === item.itemId
                ? link2.secondaryItem
                : link2.primaryItem;

            return itemsComparator(otherItem1, otherItem2);
        };
    }

    public static getItemStatusAndItemCodeSortComparator() {
        return function compareStatusThenItemCode(item1: Item, item2: Item) {
            if (!item1) {
                return -1;
            } else if (!item2) {
                return 1;
            } else if (item1.status !== item2.status) {
                return ItemStatusMetadata.ByStatus[item1.status].ordinal - ItemStatusMetadata.ByStatus[item2.status].ordinal;
            } else {
                // same status - sort by item code
                // can't use localeCompare as it is not compatible with Safari < 10
                return item1.code < item2.code
                    ? -1
                    : (item1.code > item2.code // eslint-disable-line no-extra-parens
                        ? 1
                        : 0);
            }
        };
    }

    public static getActiveItemLinkFilter(item: Item) {
        return function activeItemLinkFilter(link: ItemLink) {
            const otherItem = link.primaryItemId === item.itemId
                ? link.secondaryItem
                : link.primaryItem;

            if (!otherItem) {
                return false;
            } else {
                return otherItem.status !== ItemStatus.Closed;
            }
        };
    }

    /**
     * break down item code into board abbreviation and board index
     * If its just a number, assume its really just the item id.
     *
     * @param text Item Code or Id
     * @return Object consisting of board index and board abbreviation.
     */
    public static getItemCodeBreakDown(text?: string) {
        const emptyResult: IItemCodeBreakdown = {};

        if (typeof text !== "string") {
            return emptyResult;
        }

        const trimmedText = text.trim();
        if (trimmedText.length === 0) {
            return emptyResult;
        }

        const gap = trimmedText.lastIndexOf("-");
        let abbreviation = gap > 0
            ? trimmedText
                .substring(0, gap)
                .toUpperCase()
            : undefined;
        const abbreviationOrIndex = trimmedText.substring(gap + 1);
        const index = abbreviationOrIndex.length > 0
            ? Number(abbreviationOrIndex)
            : NaN;
        if (!abbreviation && isNaN(index)) {
            abbreviation = abbreviationOrIndex.toUpperCase();
        }

        if (abbreviation && (abbreviation.length > Board.MaxItemPrefixLength)) {
            return emptyResult;
        }

        // standard abbreviation and index (e.g. NAV-1234)
        if (abbreviation && !isNaN(index)) {
            return {
                boardAbbreviation: abbreviation,
                boardIndex: index,
            };
        }

        // abbreviation only (e.g. NAV)
        if (abbreviation && isNaN(index)) {
            return {
                boardAbbreviation: abbreviation,
            };
        }

        // index only
        if (!isNaN(index)) {
            return {
                boardIndex: index,
            };
        }

        return emptyResult;
    }
}

import { Component, OnInit } from "@angular/core";
import { BullseyeQuadrantText } from "@common/ADAPT.Common.Model/organisation/bullseye-quadrant-statement";
import { IAdaptLinkObject } from "@common/route/route.service";
import { SearchService } from "@org-common/lib/search/search.service";
import { SearchRowBaseComponent } from "@org-common/lib/search/search-row-base.component";
import { Observable } from "rxjs";
import { BullseyePageRoute } from "../../bullseye/bullseye-page/bullseye-page.component";
import { BullseyeViewOption } from "../../strategy-board/strategy-board-page/strategy-board-page.component";
import { IBullseyeQuadrantSearchResultExtra, IGuidanceSearchResult, ISearchResultMatch } from "../search-results.interface";

@Component({
    selector: "adapt-search-row-bullseye",
    templateUrl: "./search-row-bullseye.component.html",
})
export class SearchRowBullseyeComponent extends SearchRowBaseComponent<IGuidanceSearchResult> implements OnInit {
    public readonly BullseyeViewOption = BullseyeViewOption;

    public href$?: Observable<IAdaptLinkObject>;

    public constructor(
        protected searchService: SearchService,
    ) {
        super(searchService);
    }

    public ngOnInit() {
        super.ngOnInit();

        this.href$ = BullseyePageRoute.getRouteObject();

        this.matches = this.withoutLastBreadcrumb(this.result.results)
            .map((match: ISearchResultMatch<IBullseyeQuadrantSearchResultExtra>) => {
                if (match.extra) {
                    const quadrantText = BullseyeQuadrantText[match.extra.quadrant];
                    if (quadrantText) {
                        match.field = [quadrantText];
                    }
                }
                return match;
            });
    }
}

import { Component, Injector } from "@angular/core";
import { Zone } from "@common/ADAPT.Common.Model/methodology/zone";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { EditStrategicGoalBase } from "../edit-strategic-goal-base/edit-strategic-goal-base";

@WorkflowStepComponent("adapt-edit-goal-location-name-description")
@Component({
    selector: "adapt-edit-goal-location-name-description",
    templateUrl: "./edit-goal-location-name-description.component.html",
    styleUrls: ["./edit-goal-location-name-description.component.scss"],
})
export class EditGoalLocationNameDescriptionComponent extends EditStrategicGoalBase {
    public isEditorValid = true;
    public constructor(injector: Injector) {
        super(injector);
    }

    public workflowStepOnInit() {
        super.workflowStepOnInit(); // this will extra goal from runData
        this.updateCompletion();
    }

    public updateCompletion() {
        this.workflowStepCompleted.next((this.goal?.entityAspect.validateEntity() ?? false) && this.isEditorValid);
    }

    // Not defining goals for R&D
    public filterZone(zone: Zone) {
        return zone !== Zone.ResearchAndDevelopment;
    }
}

import { NgClass } from "@angular/common";
import { Component, Input } from "@angular/core";

@Component({
    selector: "adapt-toolbar-content",
    template: `
        <div class="toolbar-compiled-content"
             adaptToolbarContent
             [ngClass]="class"
             [alignment]="alignment"
             [ordinal]="ordinal">
            <ng-content></ng-content>
        </div>`,
})
export class ToolbarContentComponent {
    @Input() public class?: NgClass;
    @Input() public ordinal?: number;
    @Input() public alignment?: "left" | "center" | "right";
}

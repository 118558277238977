import { CommonModule } from "@angular/common";
import { InjectionToken, Injector, NgModule } from "@angular/core";
import { ORGANISATION_DASHBOARD_PAGE, PERSONAL_DASHBOARD_PAGE, TEAM_DASHBOARD_PAGE } from "@common/page-route-providers";
import { AdaptCommonRouteModule } from "@common/route/common-route.module";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { provideSidebarPopover } from "@common/shell/common-sidebar/sidebar-popover";
import { provideSidebarTab } from "@common/shell/common-sidebar/sidebar-tab";
import { AdaptShellModule } from "@common/shell/shell.module";
import { UserService } from "@common/user/user.service";
import { AdaptMenuModule } from "@common/ux/menu/menu.module";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { MeetingTab } from "@org-common/lib/shell/meeting-tab-content/meeting-tab";
import { AdaptOrgCommonShellModule } from "@org-common/lib/shell/org-common-shell.module";
import { StrategyDashboardPageRoute } from "@org-common/lib/strategy-dashboard-page/strategy-dashboard-page.component";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { EMPTY, filter, from, map, of, switchMap } from "rxjs";
import { OrganisationHierarchyNavigationService } from "../navigation/organisation-hierarchy-navigation.service";
import { PersonNavigationService } from "../navigation/person-navigation.service";
import { TeamHierarchyNavigationService } from "../navigation/team-hierarchy-navigation.service";
import { MenuTab } from "./menu-tab-content/menu-tab";
import { MenuTabContentComponent } from "./menu-tab-content/menu-tab-content.component";
import { provideSidebarShortcutTab } from "./shortcut-tab";
import { SupportApplicationBarItem } from "./support-application-bar-item";

function getRouteFn<TRoute extends IAdaptRoute<any>>(routeToken: InjectionToken<TRoute>) {
    return (injector: Injector) => of(injector.get(routeToken));
}

@NgModule({
    imports: [
        CommonModule,
        AdaptMenuModule,
        AdaptShellModule,
        AdaptCommonRouteModule,
        AdaptOrgCommonShellModule,
    ],
    declarations: [
        MenuTabContentComponent,
    ],
    providers: [
        provideSidebarTab(MenuTab),
        provideSidebarShortcutTab(
            {
                id: "sidebar-personal", label: "Personal Dashboard", ordinal: 1, shortLabel: "Personal",
                isDisplayedFn: (injector: Injector) => injector.get(OrganisationService).organisationChanged$.pipe( // need this to update when changing organisation
                    switchMap(() => injector.get(UserService).getCurrentPerson()),
                    filter((person) => !!person),
                    map((person) => !!person!.getLatestConnection()?.isActive()),
                ),
            },
            PersonNavigationService.IconClass,
            getRouteFn(PERSONAL_DASHBOARD_PAGE),
        ),
        provideSidebarShortcutTab(
            { id: "sidebar-team", label: "Team Dashboard", ordinal: 2, shortLabel: "Team" },
            TeamHierarchyNavigationService.IconClass,
            getRouteFn(TEAM_DASHBOARD_PAGE),
            (route, injector) => from(injector.get(CommonTeamsService).promiseToGetLeadershipTeamWithFallback()).pipe(
                switchMap((team) => team
                    ? route.gotoRoute({ teamId: team.teamId })
                    : EMPTY),
            ),
        ),
        provideSidebarShortcutTab(
            { id: "sidebar-organisation", label: "Organisation Dashboard", ordinal: 3, shortLabel: "Org" },
            OrganisationHierarchyNavigationService.IconClass,
            getRouteFn(ORGANISATION_DASHBOARD_PAGE),
        ),
        provideSidebarShortcutTab(
            { id: "sidebar-strategy", label: "Strategic Dashboard", ordinal: 4, shortLabel: "Strategy" },
            "fal fa-fw fa-bullseye-arrow",
            () => of(StrategyDashboardPageRoute),
        ),
        provideSidebarTab(MeetingTab),

        provideSidebarPopover(SupportApplicationBarItem),
    ],
})
export class AdaptAltoSidebarModule { }

import { OrganisationCategoryValue, WorkflowDialogWidth, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { buildLocalWorkflow } from "@org-common/lib/workflow/workflow.interface";
import { WorkflowIdentifier } from "@org-common/lib/workflow/workflow-identifier.enum";

export const knowYourCriticalNumbersWorkflowName = "Know the critical numbers";

export const knowYourCriticalNumbersWorkflow = buildLocalWorkflow({
    name: knowYourCriticalNumbersWorkflowName,
    workflowId: WorkflowIdentifier.KnowYourCriticalNumbers,
    type: WorkflowType.Journey,
    dialogWidth: WorkflowDialogWidth.ExtraLarge,
    overview: "Understand what numbers are critical to your organisation's success and resilience.",
    category: OrganisationCategoryValue.CriticalNumbers,
    articleSlug: ImplementationKitArticle.ComingSoon,
});

<div class="card avoid-page-break-inside"
     *adaptLoading="!anchor">
    <div class="d-flex align-items-center justify-content-between px-3 py-2 anchor-heading">
        <div class="d-flex align-items-lg-end">
            <h2 class="mb-0 text-center bold-font-weight">{{anchor!.name}}</h2>
            <button *ngIf="isEditing"
                    class="mx-3"
                    adaptButton="editBorderless"
                    [adaptBlockingClick]="editAnchor"
                    [adaptBlockingClickParam]="anchor"
                    adaptTooltip="Edit anchor"
                    data-test="edit-anchor-button"></button>
        </div>

        <i *ngIf="isEditing"
           class="fas fa-grip-dots-vertical pe-1 gripper"
           id="anchorGripper"></i>
    </div>
    <div class="ps-3 py-4 pe-5 anchor-content"
         [froalaView]="anchor!.description"></div>
</div>

import { Component, ElementRef, Injector, OnInit } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { CanvasType, CanvasTypeLabel, InputsCanvas } from "@common/ADAPT.Common.Model/organisation/inputs-canvas";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { StrategicViewIcon } from "@org-common/lib/strategy/strategy-view-constants";
import { lastValueFrom, of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { StrategyAuthService } from "../../strategy/strategy-auth.service";
import { StrategicInputsService } from "../strategic-inputs.service";

export const InputsCanvasIdSearchParam = "CanvasId";

@Component({
    selector: "adapt-strategic-inputs-page",
    templateUrl: "./strategic-inputs-page.component.html",
})
export class StrategicInputsPageComponent extends BaseRoutedComponent implements OnInit {
    public readonly EditStrategicInputs = StrategyAuthService.EditStrategicInputs;

    public currentCanvas?: InputsCanvas;

    public menuItems: IAdaptMenuItem[] = [{
        icon: MenuComponent.SmallRootMenu.icon,
        items: [
            {
                text: `Add canvas`,
                icon: StrategicViewIcon.InputsCanvasIcon,
                onClick: () => this.addSWTCanvas(),
            },
            // {
            //     text: `Add ${CanvasTypeLabel[CanvasType.StopStartKeepDoing]} Canvas`,
            //     icon: "fal fa-fw fa-retweet",
            //     onClick: () => this.addSSKDCanvas().subscribe(),
            // },
            {
                separator: true,
                text: "Delete current canvas",
                icon: "fal fa-fw fa-trash-alt",
                onClick: () => this.deleteCanvas().subscribe(),
            },
        ],
    }];


    public constructor(
        injector: Injector,
        elementRef: ElementRef,
        private strategicInputsService: StrategicInputsService,
        private dialogService: AdaptCommonDialogService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super(injector, elementRef);

        rxjsBreezeService.entityTypeChanged(InputsCanvas).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.ngOnInit());
    }

    public ngOnInit() {
        const canvasId = this.getSearchParameterIntValue(InputsCanvasIdSearchParam);
        const getCanvasObservable = canvasId
            ? this.strategicInputsService.getCanvasById(canvasId)
            : this.strategicInputsService.getLatestCanvas();
        getCanvasObservable.pipe(
            switchMap((canvas) => {
                if (canvasId && !canvas) { // just in case we manually enter an invalid id
                    return this.strategicInputsService.getLatestCanvas();
                } else {
                    return of(canvas);
                }
            }),
            this.takeUntilDestroyed(),
        ).subscribe((canvas) => {
            this.currentCanvas = canvas;
            if (canvas) {
                this.onCanvasChanged(canvas);
            }

            this.notifyActivated();
        });
    }

    public async addSWTCanvas() {
        await lastValueFrom(this.addCanvas(CanvasType.StrengthsWeaknessesTrends));
    }

    // TODO: Steve asked me to comment this out as there won't be any content for this for MVP
    // @Autobind
    // public addSSKDCanvas() {
    //     return this.addCanvas(CanvasType.StopStartKeepDoing);
    // }

    private addCanvas(canvasType: CanvasType) {
        return this.strategicInputsService.createCanvasOfType(canvasType).pipe(
            tap((canvas) => this.currentCanvas = canvas),
            switchMap((canvas) => this.strategicInputsService.saveEntities(canvas)),
        );
    }

    public onCanvasChanged(canvas: InputsCanvas) {
        this.setSearchParameterValue(InputsCanvasIdSearchParam, canvas.inputsCanvasId);
    }

    private deleteCanvas() {
        const dialogData: IConfirmationDialogData = {
            title: `Deleting ${CanvasTypeLabel[this.currentCanvas!.type]} Canvas...`,
            message: `<p>You are about to delete the current ${CanvasTypeLabel[this.currentCanvas!.type]} canvas dated:</p>
                <blockquote><b>${this.currentCanvas!.canvasDate}</b></blockquote>
                <p>All inputs within the canvas and their associations to zone and theme will be deleted. If you choose to delete, they can no longer be recovered.</p>
                <p>Are you sure you want to continue?</p>`,
            confirmButtonText: "Confirm & Delete",
            cancelButtonText: "Cancel",
            checkboxMessage: "Check here to confirm that you want to delete the canvas permanently",
        };
        return this.dialogService.openConfirmationDialog(dialogData).pipe(
            // CanvasRepositoryEntity will take care of associated entities and cause entity manager cache to be cleaned up
            switchMap(() => this.strategicInputsService.remove(this.currentCanvas)),
            switchMap(() => this.strategicInputsService.saveEntities(this.currentCanvas)),
        );
    }
}

export const StrategicInputsPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-strategic-inputs-page", StrategicInputsPageComponent)
    .atOrganisationUrl("/strength-weakness-trend")
    .withTitle("Strengths, Weaknesses & Trends")
    .verifyingFeatures(FeatureName.StrategicInputs)
    .verifyingAccess(StrategyAuthService.ReadStrategicInputs)
    .reloadOnSearch(false)
    .build();

import { Component, Injector, OnInit } from "@angular/core";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";

@Component({
    selector: "adapt-org-common-fonts-and-colors-page",
    templateUrl: "./org-common-fonts-and-colors-page.component.html",
    styleUrls: ["./org-common-fonts-and-colors-page.component.scss"],
})
export class OrgCommonFontsAndColorsPageComponent extends BaseRoutedComponent implements OnInit {
    constructor(
        injector: Injector,
    ) {
        super(injector);
    }

    public ngOnInit() {
        this.notifyActivated();
    }
}

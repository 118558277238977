import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptShellModule } from "@common/shell/shell.module";
import { ErrorPageComponent, ErrorPageRoute } from "./error-page.component";

@NgModule({
    imports: [
        CommonModule,
        AdaptShellModule,
    ],
    declarations: [
        ErrorPageComponent,
    ],
})
export class AdaptErrorPageModule {
    public static Routes = [
        ...ErrorPageRoute.routes,
    ];
}

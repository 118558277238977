import { Component, Input } from "@angular/core";
import { Account } from "@common/ADAPT.Common.Model/account/account";
import { NonParticipantUserTypes } from "@common/ADAPT.Common.Model/embed/user-type";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { EulaService } from "@org-common/lib/eula/eula.service";
import { EulaDialogComponent } from "@org-common/lib/eula/eula-dialog/eula-dialog.component";
import { catchError, tap } from "rxjs";

@Component({
    selector: "adapt-configure-eula",
    templateUrl: "./configure-eula.component.html",
})
export class ConfigureEulaComponent {
    @Input() public account!: Account;
    public readonly allowedEulaPersonUserTypes = NonParticipantUserTypes;
    public eulaPerson?: Person;
    public emailSentSuccessfully = false;
    public sendError = "";

    public constructor(
        private dialogService: AdaptCommonDialogService,
        private eulaService: EulaService,
    ) { }

    public showEula() {
        this.dialogService.open(EulaDialogComponent).subscribe();
    }

    public isEmployee(person: Person) {
        const activeConnections = person.getActiveConnections();
        return activeConnections.some((c) => c.isEmployeeConnection());
    }

    @Autobind
    public sendEulaInvite(person: Person) {
        this.emailSentSuccessfully = false;
        this.sendError = "";

        return this.eulaService.sendEulaInvite(person).pipe(
            tap(() => this.emailSentSuccessfully = true),
            catchError((e) => this.sendError = ErrorHandlingUtilities.getHttpResponseMessage(e)),
        );

    }
}

import { DefaultFinishButtons, DefaultNextButtons, GuidedTour } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourUtils } from "@common/lib/guided-tour/guided-tour.utils";
import { CommonTourSteps } from "./common-tour-steps";

export const StrategicThemesWrapUpTour: GuidedTour = {
    steps: [CommonTourSteps.openOrgMenuToShowSidebarNavNodeStep("Your strategic themes", "Strategic Working Board"), {
        waitForAndAttachToElementSelector: "adapt-display-link-hierarchy adapt-display-navigation-node span",
        elementSelectorTextContent: "Strategic Working Board", // looking for <adapt-display-navigation-node> with span content of this text
        advanceOnAttachedElementEvent: "click",
        attachTo: {
            on: "bottom-end",
        },
        title: "Access strategic themes",
        text: `<p>Strategic themes are available on your strategic working board.</p>
        <p><b>Click on it now</b> to show it.</p>`,
        modalOverlayOpeningPadding: 3,
    }, {
        title: "Your strategic working board",
        text: `<p>Your strategic working board is a central spot to store important inputs into your strategy.</p>
        <p>The board is broken up into the four zones.</p><p>As you gather more data, you will add it to this board.</p>`,
        buttons: DefaultNextButtons,
    }, {
        title: "Toggle theme descriptions",
        text: "<p>You can show/hide views here.</p><p><b>Click on it now</b> to show the theme descriptions.</p>",
        waitForAndAttachToElementSelector: `[data-tour="strategy-board-page-show-hide"]`,
        advanceOnAttachedElementEvent: "click",
        attachTo: {
            on: "bottom",
        },
    }, {
        title: "Toggle theme descriptions",
        text: "<p>By default you just see the theme names. You can toggle the display of theme descriptions using this button</p><p><b>Click on it now</b> to show the theme descriptions.</p>",
        waitForAndAttachToElementSelector: `[data-tour="Theme descriptions"]`,
        advanceOnAttachedElementEvent: "click",
        attachTo: {
            on: "bottom",
        },
    }, {
        title: "Edit strategic theme",
        text: "You can edit your strategic theme using this dropdown.",
        waitForAndAttachToElementSelector: `[data-tour="theme-action-menu"]`,
        advanceOnAttachedElementEvent: "click",
        showOn: () => {
            // this step will only be shown if you haven't saved the previous addition
            const actionMenu = GuidedTourUtils.getFirstElementWithTextContent(`[data-tour="theme-action-menu"]`);
            return GuidedTourUtils.isElementVisible(actionMenu);
        },
        attachTo: {
            on: "bottom",
        },
        buttons: DefaultNextButtons,
    }, {
        title: "Continue",
        text: `<p>You can continue your journey by going to your <b>Personal Dashboard</b>.</p>`,
        waitForAndAttachToElementSelector: `[id="sidebar-personal"]`,
        attachTo: {
            on: "right",
        },
        buttons: DefaultFinishButtons,
    }],
};

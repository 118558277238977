import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";

export interface IFilterComponentController {
    readonly isOpen: boolean;

    setFilterIsOpen(isOpen?: boolean): void;

    setFilterContent(content?: HTMLElement | JQuery): void;

    clearFilterContent(): void;
}

@Component({
    selector: "adapt-filter",
    template: `<div [ngClass]="{'d-none': !isOpen}" id="filter-component-content" #content></div>`,
    styleUrls: ["./filter.component.scss"],
})
export class FilterComponent implements OnInit, IFilterComponentController {
    public static Instance?: IFilterComponentController;

    public isOpen = false;
    public hasContent = false;
    @Output() public isDisplayedChange = new EventEmitter<boolean>();

    @ViewChild("content") private contentElement?: ElementRef;

    public ngOnInit() {
        FilterComponent.Instance = this;
    }

    public setFilterContent(content?: HTMLElement) {
        this.emptyContent();

        if (content && this.contentElement) {
            this.contentElement.nativeElement.append(content);
            this.hasContent = true;
            this.notifyFilterIsDisplayed();
        }
    }

    public clearFilterContent() {
        this.emptyContent();
        this.notifyFilterIsDisplayed();
    }

    public setFilterIsOpen(isOpen = false) {
        this.isOpen = isOpen;
        this.notifyFilterIsDisplayed();
    }

    private emptyContent() {
        if (this.contentElement) {
            this.contentElement.nativeElement.textContent = "";
        }
        this.hasContent = false;
    }

    private notifyFilterIsDisplayed() {
        this.isDisplayedChange.emit(this.hasContent && this.isOpen);
    }
}

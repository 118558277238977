<div *adaptLoading="!goal"
     class="d-flex flex-column">

    <div class="d-flex flex-column">
        <h3 class="d-flex align-items-center gap-2">{{goal?.name}} in
            <adapt-zone-label [zone]="goal!.zone"
                              class="name-location-value"></adapt-zone-label>
            <ng-container [ngTemplateOutlet]="editButton"
                          [ngTemplateOutletContext]="{step: 0}"></ng-container>
        </h3>
        <div class="mt-3 d-flex flex-column"
             *ngIf="goal?.description">
            <h3 class="d-flex">Description
                <ng-container [ngTemplateOutlet]="editButton"
                              [ngTemplateOutletContext]="{step: 0}"></ng-container>
            </h3>
            <adapt-see-more [maxHeight]="100">
                <div [froalaView]="goal?.description"
                     class="field-value"></div>
            </adapt-see-more>
        </div>
    </div>

    <div class="mt-3 d-flex flex-column">
        <h3 class="d-flex">Measurables
            <ng-container [ngTemplateOutlet]="editButton"
                          [ngTemplateOutletContext]="{step: 1}"></ng-container>
        </h3>

        <adapt-goal-measurements-graph *ngIf="goal!.measurements.length > 0; else noMeasurementsTemplate"
                                       #measurementsGraph
                                       [goal]="goal"
                                       [isEditing]="false"></adapt-goal-measurements-graph>
        <ng-template #noMeasurementsTemplate>No measurements defined for the goal</ng-template>
    </div>

</div>

<ng-template #editButton
             let-step="step">
    <button adaptTooltip="Edit"
            (click)="navigateToStep(step)"
            role="button"
            class="btn btn-blank ms-auto"><i class="fal fa-fw fa-edit"></i></button>
</ng-template>

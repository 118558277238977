import { ElementRef, Injector } from "@angular/core";
import { Goal } from "@common/ADAPT.Common.Model/organisation/goal";
import { Measurement } from "@common/ADAPT.Common.Model/organisation/measurement";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import { BehaviorSubject } from "rxjs";
import { StrategicGoalsService } from "../strategic-goals.service";

export class EditStrategicGoalBase extends WorkflowStepComponentAdapter {
    public goal?: Goal;
    public workflowStepCompleted = new BehaviorSubject<boolean>(false);

    protected strategicGoalsService: StrategicGoalsService;

    public constructor(
        injector: Injector,
    ) {
        super(injector.get(ElementRef));
        this.strategicGoalsService = injector.get(StrategicGoalsService);
        this.subscribeToEmitForEntityTypeChange(injector, Goal);
        this.subscribeToEmitForEntityTypeChange(injector, Measurement);
    }

    public workflowStepOnInit() {
        this.goal = this.workflowStep?.workflow.runData;
        if (this.goal) {
            this.emitEntityChange(this.goal);
        }
    }
}

import { Component, Injector } from "@angular/core";
import { SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";

@Component({
    selector: "adapt-manage-rb-assessments-page",
    templateUrl: "./manage-rb-assessments-page.component.html",
})
export class ManageRBAssessmentsPageComponent extends BaseRoutedComponent {
    public readonly surveyType = SurveyType.ResilientBusinessAssessment;

    public constructor(injector: Injector) {
        super(injector);
    }

    public onInitialised() {
        this.notifyActivated();
    }
}

<dx-tag-box [items]="orgLabels"
            [value]="initialSelections"
            (valueChange)="onValueChanged($event)"
            [acceptCustomValue]="allowLabelCreation"
            (onCustomItemCreating)="onLabelCreating($event)"
            [showClearButton]="true"
            [hideSelectedItems]="true"
            [noDataText]="allowLabelCreation ? 'Type and press enter to create a new label.' : 'No labels available'"
            displayExpr="name"
            valueExpr="labelId"
            [placeholder]="allowLabelCreation ? 'Select or create a new label...' : 'Select a label...'"
            [searchEnabled]="true"
            data-test="search-labels">
    <div *dxTemplate="let data of 'item'">
        <adapt-display-label [label]="data"
                             [adaptLinkDisabled]="true"></adapt-display-label>
    </div>
</dx-tag-box>

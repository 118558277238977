import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Goal } from "@common/ADAPT.Common.Model/organisation/goal";
import { Measurement, MeasurementType } from "@common/ADAPT.Common.Model/organisation/measurement";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxChartTooltipPointInfo } from "@common/ux/dx.types";
import { PointClickEvent } from "devextreme/viz/chart";
import moment from "moment";
import { filter } from "rxjs";
import { StrategicGoalsService } from "../strategic-goals.service";

interface IGraphItem {
    timestamp: Date;
    recordedValue?: number;
    targetValue?: number;
    id: number;
    type: MeasurementType;
    comment?: string;
    measurement: Measurement;
}

@Component({
    selector: "adapt-goal-measurements-graph",
    templateUrl: "./goal-measurements-graph.component.html",
    styleUrls: ["./goal-measurements-graph.component.scss"],
})
export class GoalMeasurementsGraphComponent extends BaseComponent implements OnChanges {
    @Input() public isEditing = false;
    @Input() public goal!: Goal;
    @Input() public showRecordedValue = true;
    @Input() public width = 300;

    @Output() public contentReady = new EventEmitter<boolean>();

    public dataSource: IGraphItem[] = [];

    public hasRecordedValue = false;

    public constructor(
        elementRef: ElementRef,
        rxjsBreezeService: RxjsBreezeService,
        private goalsService: StrategicGoalsService,
    ) {
        super(elementRef);

        rxjsBreezeService.entityTypeChanged(Measurement).pipe(
            filter((measurement) => measurement.goalId === this.goal?.goalId),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updateDataSource());
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.goal && this.goal) {
            this.updateDataSource();
        }
    }

    public update() {
        if (this.goal) {
            this.updateDataSource();
        }
    }

    @Autobind
    public customiseTooltip(info: IDxChartTooltipPointInfo) {
        let html = `<div class="d-flex flex-column">
            <h5>${info.seriesName}</h5>
            <span><b>Timestamp</b>: ${info.point.data.type === MeasurementType.Target
                ? this.formatTargetTimestamp(info.point.argument)
                : this.formatRecordTimestamp(info.point.argument)}</span>
            <span><b>Value</b>: ${info.point.value}</span>`;
        if (info.point.data.type === MeasurementType.Record && info.point.data.comment) {
            html += `<span style="max-width: 480px"><b>Comment</b>: ${info.point.data.comment}</span>`;
        }

        html += "</div>";
        return { html };
    }

    public onPointClick(info: PointClickEvent) {
        if (!this.isEditing) {
            return;
        }

        // need this or the tooltip will be above the spawn dialog
        info.component.hideTooltip();
        const measurement = info.target.data.measurement;
        if (measurement.type === MeasurementType.Record) {
            this.goalsService.editMeasurement(measurement).pipe(
                this.takeUntilDestroyed(),
            ).subscribe(() => this.updateDataSource());
        }
    }

    private formatTargetTimestamp(timestamp: Date) {
        return moment(timestamp).format("MM/yyyy");
    }

    private formatRecordTimestamp(timestamp: Date) {
        return moment(timestamp).format("DD/MM/yyyy");
    }

    private updateDataSource() {
        this.dataSource = this.goal.measurements
            .filter((measurement) => this.showRecordedValue || measurement.type !== MeasurementType.Record)
            .map((measurement) => ({
                id: measurement.measurementId,
                timestamp: measurement.timestamp,
                recordedValue: measurement.type === MeasurementType.Record ? measurement.value : undefined,
                targetValue: measurement.type === MeasurementType.Target ? measurement.value : undefined,
                type: measurement.type,
                comment: measurement.comment,
                measurement,
            }));
        this.hasRecordedValue = this.showRecordedValue &&
            this.goal.measurements.some((measurement) => measurement.type === MeasurementType.Record);
    }
}

import { IBreezeModel } from "@common/lib/data/breeze-model.interface";
import { Logger } from "../logger/logger";

export class ModelRegistrar {
    public static readonly TypeModels: { [toType: string]: IBreezeModel } = {};
    private static readonly Logger = Logger.getLogger("ModelRegistrar");

    public static registerModel(model: IBreezeModel) {
        if (ModelRegistrar.TypeModels[model.toType]) {
            ModelRegistrar.Logger.info(`Model "${model.toType}" registration will be overridden`);
        }

        // The latest model will override all previous registration
        ModelRegistrar.TypeModels[model.toType] = model;
    }

    public static unregisterModel(model: IBreezeModel) {
        if (ModelRegistrar.TypeModels[model.toType]) {
            delete ModelRegistrar.TypeModels[model.toType];
        }
    }

    public static getModel<T>(entityType: new (...args: any[]) => T) {
        return ModelRegistrar.TypeModels[entityType.name];
    }

    public static getAllModels() {
        return Object.values(ModelRegistrar.TypeModels);
    }
}

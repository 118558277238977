import { Inject, Injectable, Injector, Optional } from "@angular/core";
import { ConfigurationBreezeModel } from "@common/ADAPT.Common.Model/organisation/configuration";
import { ExternalDashboard, ExternalDashboardBreezeModel } from "@common/ADAPT.Common.Model/organisation/external-dashboard";
import { Organisation, OrganisationBreezeModel } from "@common/ADAPT.Common.Model/organisation/organisation";
import { OrganisationDetail, OrganisationDetailBreezeModel } from "@common/ADAPT.Common.Model/organisation/organisation-detail";
import { IdentityService } from "@common/identity/identity.service";
import { loginPageRoute } from "@common/identity/ux/login-page/login-page.route";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { EntityPersistentService } from "@common/lib/data/entity-persistent.service";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { IDeferred, PromiseUtilities } from "@common/lib/utilities/promise-utilities";
import { conditionalObservableForSubject } from "@common/lib/utilities/rxjs-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { RouteService } from "@common/route/route.service";
import { GuardFailureType, RouteEventsService } from "@common/route/route-events.service";
import { BaseService } from "@common/service/base.service";
import { UserService } from "@common/user/user.service";
import { DocumentSelectorService } from "@common/ux/document-selector/document-selector.service";
import { catchError, lastValueFrom, of, ReplaySubject, Subject, throwError } from "rxjs";
import { map, startWith, switchMap, tap } from "rxjs/operators";
import { AuthorisationNotificationService } from "../authorisation/authorisation-notification.service";
import { IOrganisationEntityUpdatedEventHandler, IOrganisationEventHandler, ORGANISATION_ENTITY_UPDATED_EVENT_HANDLERS, ORGANISATION_EVENT_HANDLERS } from "./organisation-event-handler.interface";
import { IOrganisationService, IOrganisationSwitchParams } from "./organisation-service.interface";
import { OrganisationStateService } from "./organisation-state.service";

@Injectable({
    providedIn: "root",
})
export class OrganisationService extends BaseService implements IOrganisationService {
    private static readonly UrlIdentifier = ":organisationUrlIdentifier";

    private _organisation$ = new ReplaySubject<Organisation | undefined>(1);
    private overrideOrganisationUrlIdentifier?: string;
    private deferredPermissionsInitialisation?: IDeferred<boolean>; // promise to track progress of permission initialisation

    private deferredInitialisation: IDeferred<void>;

    private organisationSwitching = new Subject<Organisation | undefined>();
    private organisationChanging = new Subject<Organisation | undefined>();
    private organisationEntityUpdated = new Subject<Organisation | undefined>();
    private organisationChanged = new Subject<Organisation | undefined>();
    private organisationImageIdChanged = new ReplaySubject<string | undefined>(1);

    // this is right before clearing data cache after confirming the switch is going to happen (used by base org service to re-initialise org services)
    public organisationSwitching$ = this.organisationSwitching.asObservable();
    // this is much later, after auth initialisation completed (used by signalr)
    public organisationChanging$ = this.organisationChanging.asObservable();

    // this is used by features factory as it doesn't need to wait for permissions
    public organisationEntityUpdated$ = conditionalObservableForSubject(
        this.organisationEntityUpdated,
        () => this.statesContext.isStateWithOrganisationEntity(),
        () => this.organisation,
    );

    // this will allow immediate callback if permissions already initialised
    // so you won't miss the event if you listen after the initialisation completed
    // - with this, can get rid of the virgin check in handler
    public organisationChanged$ = conditionalObservableForSubject(
        this.organisationChanged,
        () => this.statesContext.isOrganisationReady(),
        () => this.organisation,
    );

    public organisationImageIdChanged$ = this.organisationImageIdChanged.asObservable();

    public constructor(
        injector: Injector,
        authNotification: AuthorisationNotificationService,
        private identityService: IdentityService,
        private userService: UserService,
        private documentSelectorService: DocumentSelectorService,
        private statesContext: OrganisationStateService,
        private entityPersistentService: EntityPersistentService,
        private routeService: RouteService,
        private routeEventsService: RouteEventsService,
        @Optional() @Inject(ORGANISATION_EVENT_HANDLERS) private readonly organisationEventHandlers: IOrganisationEventHandler[],
        @Optional() @Inject(ORGANISATION_ENTITY_UPDATED_EVENT_HANDLERS) private readonly organisationEntityUpdatedEventHandlers: IOrganisationEntityUpdatedEventHandler[],
    ) {
        super(injector);

        // Can't initialise inline as @Optional uses null instead of undefined
        // See https://github.com/angular/angular/issues/25395
        if (!this.organisationEventHandlers) {
            this.organisationEventHandlers = [];
        }
        if (!this.organisationEntityUpdatedEventHandlers) {
            this.organisationEntityUpdatedEventHandlers = [];
        }

        this.deferredInitialisation = PromiseUtilities.defer();

        // notify factories to reinitialise after permissions changed
        authNotification.authorisationChanged$.subscribe(() => this.doPostInitialisation());

        this.userService.personEntityUpdated$.subscribe(() => this.initialise());
        this.identityService.logout$.subscribe(() => this.handleLogout());

        this.resetService();
        this.statesContext.organisationService = this;
    }

    protected initialisationActions() {
        return [this.deferredInitialisation.promise];
    }

    private get organisation() {
        return this.statesContext.organisation;
    }

    /** A hot observable which will emit undefined on organisation switching and the
     * organisation on organisation switched
     */
    public get organisation$() {
        return this._organisation$.asObservable();
    }

    /** A hot observable which emits the current organisation, then each time it changes */
    public get currentOrganisation$() {
        return this.organisation$.pipe(
            startWith(this.organisation),
        );
    }

    public updateImageIdentifier(value: string) {
        if (this.organisation && this.organisation.imageIdentifier !== value) {
            this.organisation.imageIdentifier = value;
            return this.commonDataService.saveEntities(this.organisation).pipe(
                tap(() => this.notifyOrganisationImageIdChanged()),
                catchError((e: any) => {
                    this.organisation!.entityAspect.rejectChanges();
                    return throwError(() => e);
                }),
            );
        }
        return of(undefined);
    }

    public getOrganisationConfiguration() {
        return this.commonDataService.getById(ConfigurationBreezeModel, this.getOrganisationId()).pipe(
            tap((configuration) => {
                if (!configuration) {
                    throw new Error("Misconfigured application - configuration missing!");
                }
            }),
            switchMap((configuration) => {
                if (configuration!.entityAspect.entityState.isAdded()) {
                    // newly created entity -> save first so that it won't keep getting recreated when cancelling on org configuration
                    // - i.e. cancel can revert back to default value (last saved)
                    return this.commonDataService.saveEntities([configuration!]).pipe(
                        map(() => configuration!),
                    );
                } else {
                    return of(configuration!);
                }
            }),
        );
    }

    public async validateOrganisation() {
        await lastValueFrom(this.waitUntilInitialised());

        const urlIdentifier = this.routeService.getRouteParam("organisationUrlIdentifier");

        if (urlIdentifier === "-1" || !urlIdentifier) {
            // no org id Url AND no default org
            // we must handle this, so we don't get into an infinite redirect loop (CM-512)
            const isLoggedIn = await this.identityService.promiseToCheckIsLoggedIn();
            return isLoggedIn
                ? Promise.reject({ noOrganisation: true })
                : Promise.reject({ requireLogin: true });
        }

        const currentUrlIdentifier: string = this.getOrganisationUrlIdentifier();
        if (currentUrlIdentifier !== urlIdentifier) {
            this.log.debug("Switching organisation", urlIdentifier);
            return this.promiseToSwitchOrganisation({ organisationUrlIdentifier: urlIdentifier! });
        } else {
            return this.promiseToWaitForOrganisationReady();
        }
    }

    public async promiseToGetOrganisation() {
        await lastValueFrom(this.waitUntilInitialised());
        return this.organisation;
    }

    public getOrganisationWithSupplementaryData(organisationId: number) {
        return this.commonDataService.getWithOptions(OrganisationBreezeModel, `organisationWithSupplementaryData${organisationId}`, {
            navProperty: "supplementaryData",
            predicate: new MethodologyPredicate<Organisation>("organisationId", "==", organisationId),
        }).pipe(
            map((orgs) => ArrayUtilities.getSingleFromArray(orgs)),
        );
    }

    public createOrganisationDetail(name: string) {
        return this.commonDataService.create(OrganisationDetailBreezeModel, {
            name,
            organisationId: this.getOrganisationId(),
        } as Partial<OrganisationDetail>);
    }

    public getOrganisationDetail(name: string) {
        const predicate = new MethodologyPredicate<OrganisationDetail>("name", "==", name);
        return this.commonDataService.getWithOptions(OrganisationDetailBreezeModel, predicate.getKey(OrganisationDetailBreezeModel.identifier), {
            predicate,
            top: 1,
            // org details should be in cache already, use its key.
            encompassingKey: UserService.ActiveOrganisationsKey,
        }).pipe(
            map(ArrayUtilities.getSingleFromArray),
        );
    }

    public getOrCreateOrganisationDetail(name: string) {
        return this.getOrganisationDetail(name).pipe(
            switchMap((detail) => detail
                ? of(detail)
                : this.createOrganisationDetail(name)),
        );
    }

    public async promiseToGetExternalDashboards(teamId?: number) {
        // just get all the dashboards initially which saves a bunch of other requests to the server
        await lastValueFrom(this.commonDataService.getAll(ExternalDashboardBreezeModel));

        const predicate = new MethodologyPredicate<ExternalDashboard>("teamId", "==", teamId ?? null);
        const dashboards = await lastValueFrom(this.commonDataService.getByPredicate(ExternalDashboardBreezeModel, predicate));
        return dashboards.sort(SortUtilities.getSortByFieldFunction<ExternalDashboard>("ordinal"));
    }

    public createExternalDashboard(data?: Partial<ExternalDashboard>) {
        const externalDashboard: Partial<ExternalDashboard> = {
            organisationId: this.getOrganisationId(),
            ...data,
        };
        return this.commonDataService.create(ExternalDashboardBreezeModel, externalDashboard);
    }

    public async promiseToSwitchOrganisation(params: IOrganisationSwitchParams) {
        // won't really switch until previous permission update completed
        await this.promiseToWaitForPermissionsInitialisation();

        this.statesContext.stateActions.switchOrganisation(params);
        return this.statesContext.promiseToWaitForOrganisationSwitchCompletion();
    }

    // this is called by the state machine when switchOrganisation is called
    @Autobind
    public promiseToPerformOrganisationSwitch(params?: IOrganisationSwitchParams) {
        if (params) {
            return this.promiseToPerformOrganisationSwitchInternal(params);
        } else {
            return Promise.resolve();
        }
    }

    public async promiseToGetOrganisationByUrlIdentifier(urlIdentifier: string) {
        const predicate = new MethodologyPredicate<Organisation>("urlIdentifier", "==", urlIdentifier);

        const array = await lastValueFrom(this.commonDataService.getByPredicate(OrganisationBreezeModel, predicate));
        return ArrayUtilities.getSingleFromArray(array);
    }

    public setOrganisationUrlIdentifierOverride(override?: string) {
        this.overrideOrganisationUrlIdentifier = override;
    }

    public getOrganisationId() {
        return this.organisation
            ? this.organisation.organisationId
            : -1;
    }

    @Autobind
    public notifyOrganisationEntityUpdatedEvent() {
        this.organisationEntityUpdated.next(this.organisation);

        for (const tracker of this.organisationEntityUpdatedEventHandlers) {
            tracker.organisationEntityUpdated(this.organisation!);
        }
    }

    @Autobind
    public notifyOrganisationChangingEvent() {
        this.organisationChanging.next(this.organisation);
    }

    @Autobind
    public notifyOrganisationChangedEvent() {
        this.organisationChanged.next(this.organisation);
        this._organisation$.next(this.organisation);
        this.notifyOrganisationImageIdChanged();

        for (const tracker of this.organisationEventHandlers) {
            tracker.organisationChanged(this.organisation!);
        }
    }

    public notifyOrganisationImageIdChanged() {
        const imageId = this.organisation && this.organisation.imageIdentifier;
        this.organisationImageIdChanged.next(imageId);
    }

    /**
     * This will return a promise which will block till the organisation is ready, i.e.
     * features, users, authorisation stuffs are all initialised
     */
    public promiseToWaitForOrganisationReady() {
        return this.statesContext.promiseToWaitForOrganisationReady();
    }

    /**
     * This will return an observable which will only emit if the organisation entity is ready.
     * This is before the above wait and is meant to be used by breeze service to wait before getting the
     * organisation entities needed for the above.
     */
    public waitForOrganisationEntity() {
        return this.statesContext.waitForOrganisationEntity();
    }

    private async initialise() {
        this.log.debug("Organisation service initialising");

        this.statesContext.stateActions.initialise();

        // overrideOrganisationId should not be re-set to allow access to non-default organisations.
        const org = await this.identityService.promiseToDoIfLoggedIn(() => this.promiseToGetCurrentOrganisation());
        try {
            if (!org) {
                if (this.routeService.currentActivatedRouteSnapshot?.routeConfig?.path?.includes(OrganisationService.UrlIdentifier)
                    || !this.routeService.currentControllerId) {
                    // no org -> only forward to error page if current route is an organisation route
                    // - with attemptedUrl, the error page will show insufficient permission error message when accessing the page
                    // Emitting guard failure type will use route service to grab current URL - attemptedUrl automatically inserted in handler
                    this.routeEventsService.emitGuardFailureType(GuardFailureType.OrganisationGuardFailed);
                } else if (this.routeService.currentControllerId === loginPageRoute.id) {
                    // logging in without default org - just do it like nimbus to pass through loading spinner
                    this.userService.notifyUserChanged();
                }
            }
        } catch {
            // do nothing
        } finally {
            if (this.organisation && ObjectUtilities.isObject(this.organisation)) {
                this.documentSelectorService.setOrganisationId(this.organisation.organisationId);
                this.statesContext.stateActions.updateOrganisationEntity();
            } else {
                this.documentSelectorService.clearOrganisationId();
                // need to notify subscribers when org changed
                this.notifyOrganisationChangedEvent();
                this.statesContext.stateActions.waitForPerson();
            }
        }

        this.deferredInitialisation.resolve();
        this.log.debug("Organisation service initialisation done");
    }

    private doPostInitialisation() {
        if (this.deferredPermissionsInitialisation) {
            // wants to resolve the initialisation promise regardless of the state (so don't put it in state action)
            this.deferredPermissionsInitialisation.resolve(true);
        }

        this.statesContext.stateActions.doPostInitialisation();
        this.entityPersistentService.load();
    }

    private resetService() {
        if (this.deferredPermissionsInitialisation) {
            // previously initialised deferred
            this.deferredPermissionsInitialisation.resolve(false);
        }

        this.deferredPermissionsInitialisation = PromiseUtilities.defer();
        this.deferredInitialisation = PromiseUtilities.defer();
        this.organisationImageIdChanged.next(undefined);
        this._organisation$.next(undefined);
        this.resetInitialisation();
    }

    private promiseToWaitForPermissionsInitialisation() {
        // Still can't get rid of this defer even if we already have a defer to track organisation readiness
        // in the organisation states context as we need this to determine if a previous wait for auth update
        // has finished, which will drive the performOrganisationSwitch() transition from PendingSwitchState
        // to AwaitingUserInitialisationState.
        // This defer will be resolved on AuthorisationChanged event and reset in resetService()
        if (this.deferredPermissionsInitialisation) {
            return this.deferredPermissionsInitialisation.promise;
        } else {
            return Promise.resolve(false);
        }
    }

    private async handleLogout() {
        this.statesContext.stateActions.logout();
        // this is to handle the case where identity is cleared on initialisation (from invalid/deleted user)
        // - won't do anything if is already forwarded elsewhere, e.g. from logout button action
        await lastValueFrom(loginPageRoute.gotoRoute());
        this.organisationSwitching.next(undefined);
    }

    private async promiseToPerformOrganisationSwitchInternal(params: IOrganisationSwitchParams): Promise<void> {
        // override the default organisation on next initialisation
        let predicate: MethodologyPredicate<Organisation> | undefined;

        if (params.organisationId) {
            predicate = new MethodologyPredicate<Organisation>("organisationId", "==", params.organisationId);
        } else if (params.organisationUrlIdentifier) {
            this.overrideOrganisationUrlIdentifier = params.organisationUrlIdentifier;
            predicate = new MethodologyPredicate<Organisation>("urlIdentifier", "==", params.organisationUrlIdentifier);
        }

        if (!predicate) {
            // TODO: Warn / handle use of an invalid parameter
            this.log.error("Invalid Organisation Params during switch");
            return;
        }

        const array = await lastValueFrom(this.commonDataService.getByPredicate(OrganisationBreezeModel, predicate, true));
        const org = ArrayUtilities.getSingleFromArray(array);
        if (org) {
            await this.userService.setDefaultOrganisation(org.organisationId);
        } else {
            // organisation not found === do not have permission to the organisation
            return Promise.reject({ validateOrganisation: true });
        }

        // emit here before resetting service which will clear data cache etc.
        // - this emit will result in all corresponding org service initialisation state to be reset
        //  - existing calls will be interrupted and new call will need to wait for the new org initialisation sequence to be completed
        this.organisationSwitching.next(org);
        this.resetService();
        await this.userService.resetDataCache();
    }

    private getOrganisationUrlIdentifier() {
        return this.organisation
            ? this.organisation.urlIdentifier
            : "-1";
    }

    private async promiseToGetCurrentOrganisation() {
        const organisationUrlIdentifier: string = this.overrideOrganisationUrlIdentifier ||
            (this.routeService.getRouteParam("organisationUrlIdentifier") ?? "-1");

        if (organisationUrlIdentifier && organisationUrlIdentifier !== OrganisationService.UrlIdentifier) {
            if (organisationUrlIdentifier !== "-1") {
                // check active organisations first as it should already be in the cache
                // (my-organisations-user-menu-item is querying this early)
                const activeOrganisations = await this.userService.getActiveOrganisationsForCurrentPerson();
                const activeOrg = activeOrganisations.find((i) => i.urlIdentifier === organisationUrlIdentifier);
                if (activeOrg) {
                    const setOrg = this.setOrganisation(activeOrg);
                    return Promise.resolve(setOrg);
                }

                const predicate = new MethodologyPredicate<Organisation>("urlIdentifier", "==", organisationUrlIdentifier);
                const organisations = await lastValueFrom(this.commonDataService.getByPredicate(OrganisationBreezeModel, predicate));
                const org = this.setOrganisation(ArrayUtilities.getSingleFromArray(organisations));
                if (org) {
                    return Promise.resolve(org);
                }
            }

            // if destination organisation is invalid, fall back to the default organisation
            // to prevent sidebar from raising exception and showing errors
            return this.setOrganisation(await this.userService.getDefaultOrganisation());
        }

        this.log.debug("loading default organisation");
        return this.setOrganisation(await this.userService.getDefaultOrganisation());
    }

    private setOrganisation(organisation?: Organisation) {
        this.log.debug("setting organisation", organisation);
        if (!organisation) {
            // user can't any org entity -> need to unblock route resolver getter
            for (const tracker of this.organisationEntityUpdatedEventHandlers) {
                tracker.organisationEntityUpdated(undefined);
            }
        }

        // this.organisation = organisation;
        this.statesContext.stateActions.setOrganisationEntity({ organisation });

        // unset override
        this.overrideOrganisationUrlIdentifier = undefined;

        return this.organisation;
    }
}

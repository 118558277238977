import moment from "moment";

export interface IDuration {
    text: string;
    value: Date;
    slug: string;
}

export class DurationSelector {
    public static readonly DataSource: IDuration[] = [
        { text: "7 days", value: moment().startOf("day").subtract(7, "days").toDate(), slug: "7d" },
        { text: "2 weeks", value: moment().startOf("day").subtract(2, "weeks").toDate(), slug: "2w" },
        { text: "1 month", value: moment().startOf("day").subtract(1, "month").toDate(), slug: "1m" },
        { text: "3 months", value: moment().startOf("day").subtract(3, "months").toDate(), slug: "3m" },
        { text: "6 months", value: moment().startOf("day").subtract(6, "months").toDate(), slug: "6m" },
        { text: "1 year", value: moment().startOf("day").subtract(1, "year").toDate(), slug: "1y" },
        { text: "2 years", value: moment().startOf("day").subtract(2, "years").toDate(), slug: "2y" },
        { text: "3 years", value: moment().startOf("day").subtract(3, "years").toDate(), slug: "3y" },
    ];
}

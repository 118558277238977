import { Component, Injector, OnInit } from "@angular/core";
import { Input as StrategicInput, InputType } from "@common/ADAPT.Common.Model/organisation/input";
import { InputGroup } from "@common/ADAPT.Common.Model/organisation/input-group";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { StrategicInputsService } from "@org-common/lib/strategic-inputs/strategic-inputs.service";
import { StrategyAuthService } from "@org-common/lib/strategy/strategy-auth.service";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import { BehaviorSubject, of, switchMap, tap } from "rxjs";

export interface IAddCompetitorAnalysisData {
    inputType: InputType;
}

@WorkflowStepComponent("adapt-add-competitor-analysis-input")
@Component({
    selector: "adapt-add-competitor-analysis-input",
    templateUrl: "./add-competitor-analysis-input.component.html",
    styleUrl: "./add-competitor-analysis-input.component.scss",
})
export class AddCompetitorAnalysisInputComponent extends WorkflowStepComponentAdapter implements OnInit {
    public inputGroup?: InputGroup;

    public inputs: StrategicInput[] = [];
    public workflowStepCompleted = new BehaviorSubject<boolean>(false);
    public hasEditAccess = false;
    public stepData?: IAddCompetitorAnalysisData;

    public constructor(
        private strategicInputsService: StrategicInputsService,
        private authService: AuthorisationService,
        rxjsBreezeService: RxjsBreezeService,
        injector: Injector,
    ) {
        super();
        this.subscribeToEmitForEntityTypeChange(injector, InputGroup);
        this.subscribeToEmitForEntityTypeChange(injector, StrategicInput);

        rxjsBreezeService.entityTypeChanged(StrategicInput).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updateInputs());
    }

    public async ngOnInit() {
        this.updateInputs();

        this.hasEditAccess = await this.authService.promiseToGetHasAccess(StrategyAuthService.EditStrategicInputs);
    }

    public updateCompletion() {
        this.workflowStepCompleted.next(this.inputGroup?.entityAspect.validateEntity() ?? !!(this.workflowStep?.canSkip));
    }

    public updateInputs() {
        if (this.inputGroup && this.stepData?.inputType) {
            this.inputs = this.inputGroup.inputs.filter((i) => i.type === this.stepData?.inputType);
        }
    }

    public workflowStepOnInit() {
        this.stepData = this.workflowStep?.customData;
        this.inputGroup = this.workflowStep?.workflow.runData;
        if (this.inputGroup) {
            this.emitEntityChange(this.inputGroup);
        }
        this.updateCompletion();
    }

    public postWorkflow() {
        this.strategicInputsService.triggerPulse(this.inputGroup?.name);
        return of(undefined);
    }

    public createInput() {
        if (this.inputGroup && this.stepData) {
            this.strategicInputsService.createInputOfType(this.stepData.inputType, this.inputGroup.canvas).pipe(
                tap((input) => input.inputGroup = this.inputGroup),
                switchMap((input) => this.strategicInputsService.editInput(input)),
            ).subscribe();
        }
    }
}

import { Injectable } from "@angular/core";
import { RouteService, SearchParamValue } from "@common/route/route.service";

export interface IUrlParams {
    name: string;
    value: string | undefined;
}

@Injectable({
    providedIn: "root",
})
export class UrlFilterService {
    constructor(private routeService: RouteService) {
    }

    public async setFilter(filters: Record<string, SearchParamValue>) {
        for (const [key, value] of Object.entries(filters)) {
            //we only want to clean delete these value from the url. !value doesn't work since we don't want get rid of numeric zero and boolean false.
            if (value === undefined || value === null || value === "") {
                await this.routeService.deleteSearchParameter(key);
            } else {
                await this.routeService.updateSearchParameterValue(key, value);
            }
        }
    }

    public getFilters(params: string[]): IUrlParams[] {
        return this.getSearchParametersValue(params);
    }

    public async clearFilters(params: string[]) {
        for (const param of params) {
            await this.routeService.deleteSearchParameter(param);
        }
    }

    public getSearchParametersValue(paramNames: string[]) {
        return paramNames.map((name) => ({ name, value: this.routeService.getSearchParameterValue(name) }));
    }

    public urlHasSearchParams() {
        return this.routeService.getSearchParameters !== undefined;
    }
}



import { Inject, Injectable } from "@angular/core";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { TEAM_DASHBOARD_PAGE } from "@common/page-route-providers";
import { IRouteData } from "@common/route/adapt-route-builder";
import { INavigationHierarchy, NAVIGATION_HIERARCHIES } from "@common/route/navigation-hierarchy";
import { NavigationHierarchyService } from "@common/route/navigation-hierarchy.service";
import { INavigationNode } from "@common/route/navigation-node.interface";
import { NavigationUtilitiesService } from "@common/route/navigation-utilities.service";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { RouteService } from "@common/route/route.service";
import { AuthorisationNotificationService } from "@org-common/lib/authorisation/authorisation-notification.service";
import { BehaviorSubject, combineLatest } from "rxjs";
import { map, skipWhile, switchMap, take, tap } from "rxjs/operators";
import { ISearchProviderOptions, SearchType } from "../search.interface";
import { SearchProvider } from "../search-provider";
import { IPageSearchResult, ISearchResultMatch } from "../search-results.interface";

@Injectable()
export class PageSearchProvider extends SearchProvider<IPageSearchResult> {
    public readonly Type = SearchType.Page;
    private nodes$ = new BehaviorSubject<INavigationNode[]>([]);
    private nonSidebarNodes?: INavigationNode[];

    public constructor(
        private navigation: NavigationHierarchyService,
        @Inject(NAVIGATION_HIERARCHIES) private navigationHierarchies: INavigationHierarchy[],
        @Inject(TEAM_DASHBOARD_PAGE) private teamDashboardPageRoute: IAdaptRoute<{teamId: number}>,
        private navUtilsService: NavigationUtilitiesService,
        authNotification: AuthorisationNotificationService,
        private routeService: RouteService,
    ) {
        super();
        // if authorisation changed, nonSidebarNodes will need to be reinitialised too
        // - changing own permissions is very rare but still happens in tests
        authNotification.authorisationChanged$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.nonSidebarNodes = undefined);

        // Reset this search provider if nav hierarchy change
        // - was trying this on search.service but noticed that we do not want Team, People and Role providers
        //   to reset if hierarchy changes
        this.navigation.hierarchyChange$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.isInitialised = false);
    }

    public shouldSkip(options: ISearchProviderOptions): boolean {
        return !!options.teamId || !!options.personId || !!options.updatedSince || (!!options.labelIds && options.labelIds.size > 0);
    }

    public initialise() {
        const hierarchies = this.navigationHierarchies.map((hierarchy) => this.navigation.hierarchyChanged(hierarchy.id));

        const flatLoop = (node: INavigationNode): INavigationNode[] => {
            if (node.children && node.children.length > 0) {
                return [node, ...node.children.flatMap(flatLoop)];
            }
            return [node];
        };

        return combineLatest(hierarchies).pipe(
            skipWhile((navs) => navs.some((nav) => !nav)),
            map((navs) => navs.flatMap(flatLoop)),
            tap((nodes) => this.nodes$.next(nodes)),
            take(1),
        );
    }

    public execute({ keyword }: ISearchProviderOptions) {
        return this.nodes$.pipe(
            take(1),
            switchMap(async (navs) => {
                if (!this.nonSidebarNodes) {
                    // just need to do this once unless authorisation changes
                    this.nonSidebarNodes = await this.initNonSidebarNodes();
                }

                return navs;
            }),
            map((navs) => navs.concat(this.nonSidebarNodes ?? [])
                .filter((nav) => !!nav.url &&
                    !nav.customData.isHiddenInBreadcrumbs && (
                        this.matchNodeTitle(nav, keyword) ||
                        this.matchRouteSearchKeywords(nav.controller, keyword)))
                // exclude team dashboards as they are already present in team results
                .filter((nav) => nav.controller !== this.teamDashboardPageRoute.id)
                .map((node) => {
                    const results: ISearchResultMatch[] = [];
                    const searchKeywords = this.getNodeSearchKeywords(node);
                    if (!this.matchNodeTitle(node, keyword) && Array.isArray(searchKeywords)) {
                        results.push({
                            field: ["Keyword"],
                            snippet: StringUtilities.generateSnippet(searchKeywords.find((kw) => kw.toLowerCase().includes(keyword.toLowerCase()))!, keyword),
                        });
                    }

                    return { node, results } as IPageSearchResult;
                })
                .slice(0, 10),
            ),
        );
    }

    private async initNonSidebarNodes() {
        const promiseToCreateNodes = this.routeService.routes
            // only routes which are tagged with enableNonNavigationNodeSearch will be added (e.g. manage access/people and config organisation)
            .filter((route) => !!route.data?.enableNonNavigationNodeSearch && route.data!.id)
            .map((route) => this.navUtilsService
                .promiseToBuildNodeForControllerAndParams(route.data!.id)
                // if no permission or route guard fails, will be rejected -> catch and ignore, which will not be included in the search
                .catch(() => undefined));
        return (await Promise.all(promiseToCreateNodes))
            .filter((node) => !!node) as INavigationNode[];
    }

    private matchNodeTitle(nav: INavigationNode, keyword: string) {
        return nav.title.toLowerCase().includes(keyword.toLowerCase());
    }

    private matchRouteSearchKeywords(controller: string | undefined, keyword: string) {
        const route = this.routeService.getControllerRouteConfig(controller);
        const searchKeywords = (route?.data as IRouteData)?.searchKeywords;
        if (Array.isArray(searchKeywords)) {
            return !!searchKeywords.find((i) => i.toLowerCase().includes(keyword.toLowerCase()));
        } else {
            return false;
        }
    }

    private getNodeSearchKeywords(node: INavigationNode) {
        const route = this.routeService.getControllerRouteConfig(node.controller);
        return (route?.data as IRouteData)?.searchKeywords;
    }
}

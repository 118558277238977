import { Component, ElementRef, Injector, OnInit } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { Bullseye } from "@common/ADAPT.Common.Model/organisation/bullseye";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { StrategyAuthService } from "@org-common/lib/strategy/strategy-auth.service";
import { WorkflowRunDialogComponent } from "@org-common/lib/workflow/workflow-run-dialog/workflow-run-dialog.component";
import { BullseyeService } from "../bullseye.service";
import { DefineBullseyeWorkflow } from "../workflows/bullseye/bullseye-workflow";

@Component({
    selector: "adapt-bullseye-page",
    templateUrl: "./bullseye-page.component.html",
    styleUrls: ["./bullseye-page.component.scss"],
})
export class BullseyePageComponent extends BaseRoutedComponent implements OnInit {
    public readonly EditBullseye = StrategyAuthService.EditBullseye;

    public bullseye?: Bullseye;

    public constructor(
        injector: Injector,
        elementRef: ElementRef,
        private bullseyeService: BullseyeService,
        private dialogService: AdaptCommonDialogService,
    ) {
        super(injector, elementRef);
    }

    public ngOnInit() {
        this.bullseyeService.getBullseye().pipe(
            this.takeUntilDestroyed(),
        ).subscribe((bullseye) => {
            this.bullseye = bullseye;
            this.isInitialised = true;
            this.notifyActivated();
        });
    }

    @Autobind
    public runBullseyeWorkflow() {
        return this.dialogService.open(WorkflowRunDialogComponent, {
            workflow: DefineBullseyeWorkflow,
        });
    }
}

export const BullseyePageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-bullseye-page", BullseyePageComponent)
    .atOrganisationUrl("/bullseye")
    .verifyingFeatures(FeatureName.Bullseye)
    .verifyingAccess(StrategyAuthService.ReadBullseye)
    .withTitle("Bullseye")
    .build();

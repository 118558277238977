import { AfterViewInit, Component, ElementRef, HostListener, Injector, OnInit, ViewChild } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { AllEventTypePresets } from "@common/ADAPT.Common.Model/organisation/event-type";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { QueuedCaller } from "@common/lib/queued-caller/queued-caller";
import { buttonPreset } from "@common/ux/button/button-preset";
import { IDxScrollViewEvent } from "@common/ux/dx.types";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { IItem, ISection, TimeSchedulerPeriod6Months } from "@common/ux/time-scheduler/time-scheduler.interface";
import { TimeSchedulerComponent } from "@common/ux/time-scheduler/time-scheduler/time-scheduler.component";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { ScheduleService } from "@org-common/lib/schedule/schedule.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { DxScrollViewComponent } from "devextreme-angular";
import moment from "moment";
import { delay, forkJoin, fromEvent, switchMap } from "rxjs";
import { tap } from "rxjs/operators";
import { ScheduleUiService } from "../../schedule/schedule-ui.service";
import { MeetingsService } from "../meetings.service";
import { MeetingsUiService } from "../meetings-ui.service";
import { TeamMeetingsPageComponent } from "../team-meetings-page/team-meetings-page.component";

@Component({
    selector: "adapt-team-meetings-with-cadence-page",
    templateUrl: "./team-meetings-with-cadence-page.component.html",
    styleUrls: ["./team-meetings-with-cadence-page.component.scss"],
})
export class TeamMeetingsWithCadencePageComponent extends TeamMeetingsPageComponent implements OnInit, AfterViewInit {
    @ViewChild("cadence") public cadenceRef?: ElementRef;
    public queuedScheduler = new QueuedCaller<TimeSchedulerComponent>();

    public sections: ISection[] = [];
    public items: IItem<Meeting>[] = [];
    public schedulerStartTime = moment().startOf("month").hour(9);
    public selectedCadenceItem?: IItem;
    public defaultCadencePeriod = TimeSchedulerPeriod6Months;

    public cadenceCollapsed = false;
    public cadenceCollapseManualOverride = false;
    public canUpdateCadence = false;
    public hasConfiguredCadence = false;
    public isMobile$ = this.responsiveService.isMobileSize$;

    // hides the adapt-meeting-details overflow while the collapse is animating
    // otherwise we get double scrollbar during the animation
    public hideOverflow = false;

    public menuItems: IAdaptMenuItem[] = [];

    private meetingDetailsComponent = new QueuedCaller<DxScrollViewComponent>();


    public constructor(
        teamsService: CommonTeamsService,
        meetingsService: MeetingsService,
        meetingsUiService: MeetingsUiService,
        rxjsBreezeService: RxjsBreezeService,
        injector: Injector,
        private scheduleService: ScheduleService,
        private scheduleUiService: ScheduleUiService,
        private responsiveService: ResponsiveService,
    ) {
        super(teamsService, meetingsService, meetingsUiService, rxjsBreezeService, injector);
    }

    public ngOnInit() {
        super.ngOnInit();
        fromEvent(window, "resize").subscribe(() => this.checkIfCalenderNeedsToBeCollapsed());

        this.meetingsFetched$.pipe(
            switchMap(() => forkJoin([
                this.scheduleService.getEventCadenceCycle(),
                this.scheduleService.hasConfiguredCadence(this.team!),
                this.scheduleService.getCurrentEventSeriesMapForPresetsWithinTeam(AllEventTypePresets, this.team!),
            ])),
            this.takeUntilDestroyed(),
        ).subscribe(([cadenceCycle, hasConfiguredCadence, eventSeriesMap]) => {
            this.canUpdateCadence = !!cadenceCycle;
            this.hasConfiguredCadence = hasConfiguredCadence;
            const menuItems: IAdaptMenuItem[] = [];

            if (hasConfiguredCadence) {
                menuItems.push({
                    text: "Clear cadence",
                    icon: buttonPreset.deleteBorderless.iconClass,
                    onClick: () => this.clearCadence().subscribe(),
                });
            }

            if (cadenceCycle) {
                menuItems.push({
                    text: `${hasConfiguredCadence ? "Update" : "Set"} cadence`,
                    icon: buttonPreset.resetBorderless.iconClass,
                    onClick: () => this.updateCadence().subscribe(),
                });
            }

            const menu: IAdaptMenuItem = {
                ...MenuComponent.SmallRootMenu,
                items: menuItems,
            };

            this.menuItems = [menu];

            this.sections = [];
            this.items = [];

            eventSeriesMap.forEach((series) => {
                // only show eventSeries that are not complete
                if (!series.extensions.isCompleted) {
                    this.sections.push(ScheduleUiService.SchedulerSectionFromEventType(series.eventType));
                    this.items.push(...series.meetings.map(ScheduleUiService.SchedulerItemFromMeeting));
                }
            });

            this.sections.push(ScheduleUiService.SchedulerSectionForAdhocMeeting());

            this.items.push(...this.endedMeetingsRaw
                .filter((m) => !m.eventSeriesId)
                .map(ScheduleUiService.SchedulerItemFromMeeting));
            this.items.push(...this.upcomingMeetingsRaw
                .filter((m) => !m.eventSeriesId)
                .map(ScheduleUiService.SchedulerItemFromMeeting));

            setTimeout(() => this.updateMeetingDetailsScroll());
        });

        this.meetingListSelectionChanged$.pipe(
            tap(() => {
                if (!this.selectedMeeting) {
                    this.selectedCadenceItem = undefined;
                    return;
                }

                this.selectedCadenceItem = this.items.find((item) => item.metadata === this.selectedMeeting);
                if (this.selectedCadenceItem) {
                    this.queuedScheduler.call((scheduler) => scheduler.scrollItemIntoView(this.selectedCadenceItem!));
                }
            }),
            delay(0),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.checkIfCalenderNeedsToBeCollapsed());
    }

    public ngAfterViewInit() {
        super.ngAfterViewInit();
        this.updateMeetingDetailsScroll();
    }

    public checkIfCalenderNeedsToBeCollapsed() {
        if (this.cadenceRef && !this.cadenceCollapsed) {
            const calendarHeight = this.cadenceRef.nativeElement.getBoundingClientRect().height / window.innerHeight;
            this.cadenceCollapsed = calendarHeight > .40;
        }
    }

    public meetingDetailsScrollViewComponentInitialised(value: DxScrollViewComponent) {
        this.meetingDetailsComponent.setCallee(value);
    }

    @HostListener("window:resize")
    public updateMeetingDetailsScroll() {
        this.hideOverflow = false;
        setTimeout(() => {
            this.meetingDetailsComponent.call((scrollViewComponent) => scrollViewComponent.instance.update());
        });
    }

    public onCadenceCollapseChanged(collapsed: boolean) {
        this.cadenceCollapseManualOverride = true;
        this.cadenceCollapsed = collapsed;
    }

    public meetingDetailsScrolled(e: IDxScrollViewEvent) {
        if (e.reachedTop && !e.reachedBottom) { // if both reachedTop and reachedBottom - panel doesn't need scrolling
            this.cadenceCollapseManualOverride = false;
            if (this.cadenceCollapsed) {
                this.cadenceCollapsed = false;
                this.hideOverflow = true;
            }
        } else if (!e.reachedTop) {
            if (!this.cadenceCollapseManualOverride && !this.cadenceCollapsed) {
                this.cadenceCollapsed = true;
                this.hideOverflow = true;
            }
        }
    }

    public handleCadenceSelectionChange(e: IItem<Meeting> | undefined) {
        if (e) {
            this.selectMeeting(e.metadata);
            this.updateMeetingListSelection();
        }
    }

    @Autobind
    public clearCadence() {
        return this.scheduleUiService.promptToClearCadence(AllEventTypePresets).pipe(
            tap(() => this.meetingsFetched$.next()),
        );
    }

    @Autobind
    public updateCadence() {
        return this.scheduleUiService.updateCadence().pipe(
            tap(() => this.meetingsFetched$.next()),
        );
    }
}

export const TeamMeetingsWithCadencePageRoute = new OrganisationPageRouteBuilder<{ teamId: number }>()
    .usingNgComponent("adapt-team-meetings-with-cadence-page", TeamMeetingsWithCadencePageComponent)
    .atOrganisationUrl("/team/:teamId/meetings")
    .verifyingFeatures(FeatureName.StewardshipWorkMeetings)
    .withTitle("Meetings")
    .withSearchKeyword("Ongoing Meetings")
    .withSearchKeyword("Upcoming Meetings")
    .withSearchKeyword("Past Meetings")
    .reloadOnSearch(false)
    .build();


import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { EmployeeEngagementAuthService } from "../employee-engagement/employee-engagement-auth.service";
import { OrganisationDiagnosticAuthService } from "../organisation-diagnostic/organisation-diagnostic-auth.service";
import { ResilientBusinessAssessmentAuthService } from "../resilient-business-assessment/resilient-business-assessment-auth.service";
import { AnalyseSurveyPageComponent } from "./analyse-survey-page.component";

const AnalysePageTitle = "Analyse Results";

export const analyseEngagementSurveyPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-analyse-survey-page", AnalyseSurveyPageComponent)
    .atOrganisationUrl("/culture/employee-engagement/analyse")
    .verifyingFeatures(FeatureName.CulturalNetworkEmployeeEngagement)
    .verifyingAccess(EmployeeEngagementAuthService.ReadEmployeeEngagement)
    .withTitle(AnalysePageTitle)
    .reloadOnSearch(false)
    .build();

export const analyseTeamAssessmentSurveyPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-analyse-survey-page", AnalyseSurveyPageComponent)
    .atOrganisationUrl("/team/:teamId/team-assessment/analyse")
    .redirectToThisRouteFromOrganisationUrl("/people/teams/:teamId/team-assessment/analyse")
    .verifyingFeatures(FeatureName.CulturalNetworkTeamAssessment)
    .withTitle(AnalysePageTitle)
    .reloadOnSearch(false)
    .build();

export const analyseResilientBusinessAssessmentSurveyPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-analyse-survey-page", AnalyseSurveyPageComponent)
    .atOrganisationUrl("/culture/resilient-business-assessment/analyse")
    .verifyingFeatures(FeatureName.CulturalNetworkResilientBusinessAssessment)
    .verifyingAccess(ResilientBusinessAssessmentAuthService.ReadResilientBusinessAssessment)
    .withTitle(AnalysePageTitle)
    .reloadOnSearch(false)
    .build();

export const analyseOrganisationDiagnosticPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-analyse-survey-page", AnalyseSurveyPageComponent)
    .atOrganisationUrl("/health-check/analyse")
    .verifyingFeatures(FeatureName.OrganisationDiagnostic)
    .verifyingAccess(OrganisationDiagnosticAuthService.ReadOrganisationDiagnostic)
    .withTitle(AnalysePageTitle)
    .reloadOnSearch(false)
    .build();

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Zone, ZoneMetadata } from "@common/ADAPT.Common.Model/methodology/zone";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";

@Component({
    selector: "adapt-select-zone",
    templateUrl: "./select-zone.component.html",
    styleUrls: ["./select-zone.component.scss"],
})
export class SelectZoneComponent implements OnInit {
    @Input() public required = false;
    @Input() public disabled = false;
    @Input() public zone?: Zone;
    @Input() public filter?: (zone: Zone) => boolean;

    @Output() public zoneChange = new EventEmitter<Zone>();

    public dataSource?: DataSource;

    public ngOnInit() {
        const zones = ZoneMetadata.InOrder;

        this.dataSource = new DataSource({
            store: new ArrayStore({
                data: zones,
            }),
            filter: this.filter,
        });
    }

    public reload() {
        this.dataSource?.reload();
    }
}

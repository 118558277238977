import { Injector, Type } from "@angular/core";
import { Logger } from "@common/lib/logger/logger";
import { ICommonDialogService } from "@common/ux/adapt-common-dialog/common-dialog-service.interface";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { Entity } from "breeze-client";
import { defer, of, switchMap, throwError } from "rxjs";
import { IBreezeEntity } from "./breeze-entity.interface";
import { EntityPersistentBreezeHelper, IImportManager } from "./entity-persistent-breeze-helper";
import { customPersistableDialogs, persistableDialogs } from "./persistable-dialog.decorator";

export interface IOrderedDialog {
    ordinal: number; // doesn't need to be sequential - lower ordinal will be opened first on restore
}

export interface IDialogDefinition extends IOrderedDialog {
    persistableDialogId: string;
    data?: unknown;
}

export class EntityPersistentDialogUtility {
    private _commonDialogService?: ICommonDialogService;
    private openedDialogs: IDialogDefinition[];
    private logger = Logger.getLogger("EntityPersistentDialogUtility");
    private dialogOrdinal = 0; // this will only get reset if the number of opened dialog is 0

    public constructor(
        private breezeHelper: EntityPersistentBreezeHelper,
        private injector: Injector,
    ) {
        this.openedDialogs = [];
    }

    public set commonDialogService(service: ICommonDialogService | undefined) {
        this._commonDialogService = service;
    }

    public get commonDialogService() {
        return this._commonDialogService;
    }

    public dialogOpened(dialogType: Type<any>, data?: unknown) {
        const persistableDialogId = this.getPersistableDialogIdFromType(dialogType);
        if (persistableDialogId) {
            this.openedDialogs.push({
                persistableDialogId,
                data,
                ordinal: this.nextDialogOrdinal,
            });

            this.logger.log("Detected number of opened dialogs: " + this.openedDialogs.length);
        }
    }

    public dialogClosed(dialogType: Type<any>) {
        const persistableDialogId = this.getPersistableDialogIdFromType(dialogType);
        if (persistableDialogId) {
            let dialogIndex = -1;
            // find in reverse order as last opened will be first closed
            for (let i = this.openedDialogs.length - 1; i >= 0; i--) {
                if (this.openedDialogs[i].persistableDialogId === persistableDialogId) {
                    dialogIndex = i;
                    break;
                }
            }

            if (dialogIndex >= 0) {
                this.openedDialogs.splice(dialogIndex, 1);
                this.logger.log("Closing dialog. Remaining opened dialogs: " + this.openedDialogs.length);
                this.checkResetDialogOrdinal();
            }
        }
    }

    public serialiseOpenedDialogs(): IDialogDefinition[] {
        // {...dialog} will make a shallow copy of the dialog object so we don't override the original data
        return this.openedDialogs.map(({ ...dialog }) => {
            const customPersistence = customPersistableDialogs[dialog.persistableDialogId];
            if (customPersistence) {
                dialog.data = customPersistence.config.encode(dialog.data, this.injector);
                console.debug("serialiseOpenedDialogs custom persistence", { customPersistence, dialog });
            }
            return this.breezeHelper.replaceBreezeEntities(dialog);
        });
    }

    public restoreOpenedDialogs(serialisedDialogs: IDialogDefinition[], unsavedEntities: IBreezeEntity[], importManager: IImportManager) {
        const savedDialog = serialisedDialogs.map((dialog: IDialogDefinition) =>
            this.breezeHelper.restoreBreezeEntities(dialog, unsavedEntities, importManager));

        const restoreDialogFunctionsWithOrdinal: {
            ordinal: number,
            restoreFunction: () => void
        }[] = savedDialog.map((dialog: IDialogDefinition) => ({
            ordinal: dialog.ordinal,
            restoreFunction: () => {
                const dialogType = persistableDialogs[dialog.persistableDialogId];
                if (dialogType) {
                    defer(() => dialogType.initialisationFn?.(this.injector) ?? of(undefined)).pipe(
                        switchMap(() => this.commonDialogService!.open(dialogType.classType, dialog.data)),
                    ).subscribe();
                }

                const customPersistenceDialog = customPersistableDialogs[dialog.persistableDialogId];
                if (customPersistenceDialog) {
                    const data = customPersistenceDialog.config.decode(dialog.data, this.injector);
                    if (data) {
                        this.commonDialogService!.open(customPersistenceDialog.type, data).subscribe();
                    }
                }
            },
        }));

        restoreDialogFunctionsWithOrdinal.sort((d1, d2) => d1.ordinal - d2.ordinal);
        // close all existing dialogs first before restoring
        this.commonDialogService?.closeAll();
        restoreDialogFunctionsWithOrdinal.forEach((dialog) => dialog.restoreFunction());
    }

    public promptToDiscardUnsavedEntities(importEntities: Entity[], entityTypes: string[]) {
        this.assertCommonDialogService();
        const dialog: IConfirmationDialogData = {
            title: "Unsaved changes",
            message: "<p>Unsaved changes detected from a previous session in the following data types:</p><p><ul>"
                + stringArrayToList(entityTypes.map((entityType) => this.breezeHelper!.models[entityType].singularName)) + "</ul></p>",
            confirmButtonText: "Discard All",
            cancelButtonText: "Restore",
        };

        if (importEntities.some(hasValidationErrors)) {
            dialog.message += "<p>There are some entities with validation errors that cannot be saved:</p><p><ul>"
                + extractTypes(importEntities.filter(hasValidationErrors))
                + "</ul></p>";
        }

        dialog.message += "<p>Click RESTORE to navigate back to the last known location or DISCARD ALL to delete all unsaved data.</p>";

        return this.commonDialogService!.openConfirmationDialogWithBoolean(dialog);

        function hasValidationErrors(entity: Entity) {
            return entity.entityAspect.hasValidationErrors;
        }

        function extractTypes(entities: Entity[]) {
            return entities.map((entity) => "<li>" + entity.entityType.shortName + "</li>").join("");
        }

        function stringArrayToList(types: string[]) {
            return types.map((str) => "<li>" + str + "</li>").join("");
        }
    }

    public promptToDiscardWithServerChanges() {
        this.assertCommonDialogService();
        const dialog: IConfirmationDialogData = {
            title: "Entities Changed from Server",
            message: "<p>Conflicts detected between the previously unsaved entities and the entities loaded from the server.  "
                + "Select RESTORE to overwrite the server entity or DISCARD to discard the unsaved entities and abort the restore.</p>"
                + "<p>Note that discarded entities can no longer be recovered.</p>"
                + "<p>Are you sure you want to continue with the restore and overwrite changes from the server?</p>",
            confirmButtonText: "Discard",
            cancelButtonText: "Restore",
        };

        return this.commonDialogService!.openConfirmationDialogWithBoolean(dialog);
    }

    public showFailedImportedEntities(failedEntitiesString: string) {
        this.assertCommonDialogService();
        return this.commonDialogService!.showMessageDialog(
            "Restore Failed",
            "<p>Failed restored previous unsaved entities due to the following entities which are no longer accessible.</p>"
            + "<p>[" + failedEntitiesString + "]</p>",
        );
    }

    private assertCommonDialogService() {
        if (!this.commonDialogService) {
            return throwError(() => "Common dialog service is undefined");
        }
    }

    private getPersistableDialogIdFromType(dialogType: Type<any>) {
        let persistableDialogId;
        const dialogIds = Object.keys(persistableDialogs)
            .concat(Object.keys(customPersistableDialogs));
        for (const dialogId of dialogIds) {
            const registeredType = persistableDialogs[dialogId]?.classType;
            if (registeredType === dialogType) {
                persistableDialogId = dialogId;
                break;
            }

            const registeredCustomType = customPersistableDialogs[dialogId];
            if (registeredCustomType?.type === dialogType) {
                persistableDialogId = dialogId;
                break;
            }
        }

        return persistableDialogId;
    }

    private get nextDialogOrdinal() {
        return this.dialogOrdinal++;
    }

    private checkResetDialogOrdinal() {
        if (this.openedDialogs.length === 0) {
            this.dialogOrdinal = 0;
            this.logger.log("Total number of opened dialog hits 0, resetting dialog ordinal");
        }
    }
}

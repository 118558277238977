import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { IBreezeModel } from "@common/lib/data/breeze-model.interface";
import { of } from "rxjs";
import { IEntityUpdate } from "../entity-updates.interface";
import { AutoUpdaterMergeStrategy } from "./auto-updater-merge-strategy";
import { IEntityAutoUpdater } from "./entity-auto-updater.interface";

export class NonImplementedAutoUpdater implements IEntityAutoUpdater {
    public userIsAllowedToIgnoreServerUpdates = true;
    public conflictsShouldBeAutomaticallyIntegrated = false;
    public relatedEntitiesInCache = [];
    public htmlSummary = "";

    public constructor(
        public readonly entityUpdate: IEntityUpdate,
    ) {
    }

    public get model(): IBreezeModel {
        throw new Error("Non implemented auto updater has no model");
    }

    public hasConflicts(): boolean {
        return false;
    }

    public updatesShouldBeIntegrated(): boolean {
        return false;
    }

    public promiseToIntegrateUpdates(_mergeStrategy: AutoUpdaterMergeStrategy): Promise<IBreezeEntity | void> {
        return Promise.resolve();
    }

    public primeAfterFetch() {
        return of(undefined);
    }
}

import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Feature } from "./feature";

// please keep this in sync with PermissionTypeName in .\Projects\Server\ADAPT.Methodology.Model\Embed\FeaturePermission.cs
export enum PermissionType {
    Read = "Read",
    Edit = "Edit",
    Publish = "Publish",
    Configure = "Configure",
    AnonymousRead = "AnonymousRead",
    QuantitativeRead = "QuantitativeRead",
    PublicRead = "PublicRead",
    PublicEdit = "PublicEdit",
    PersonalUseAndRead = "PersonalUseAndRead",
    PersonalEdit = "PersonalEdit",
}

export class FeaturePermission extends BaseEntity<FeaturePermission> {
    public featurePermissionId!: number;
    public featureId!: number;
    public permissionType!: PermissionType;
    public name!: FeaturePermissionName;
    public feature!: Feature;
}

export const FeaturePermissionBreezeModel = new BreezeModelBuilder("FeaturePermission", FeaturePermission)
    .hasSource()
    .build();

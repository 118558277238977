import { Component, Injector } from "@angular/core";
import { Survey, SurveyStatus } from "@common/ADAPT.Common.Model/organisation/survey";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { IAdaptLinkObject } from "@common/route/route.service";
import { setReturnPathSearchParam } from "@common/ux/base-routed.component";
import { SurveyService } from "@org-common/lib/survey/survey.service";
import { organisationDiagnosticSurveySummaryPage } from "@org-common/lib/survey/survey-summary-page/survey-summary-page.component";
import { WorkflowService } from "@org-common/lib/workflow/workflow.service";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import { filter, Subject, switchMap, tap } from "rxjs";
import { IOrganisationDiagnosticStatusCustomData } from "../organisation-diagnostic-status-custom-data";

@WorkflowStepComponent("adapt-pending-organisation-diagnostic-survey")
@Component({
    selector: "adapt-pending-organisation-diagnostic-survey",
    templateUrl: "./pending-organisation-diagnostic-survey.component.html",
})
export class PendingOrganisationDiagnosticSurveyComponent extends WorkflowStepComponentAdapter {
    public workflowStepCompleted = new Subject<boolean>();

    public surveySent = false;
    public surveyCompleted = false;
    public orgDiagSummaryLink?: IAdaptLinkObject;

    private relatedSurveyId?: number;

    public constructor(
        private injector: Injector,
        private workflowService: WorkflowService,
        private surveyService: SurveyService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        rxjsBreezeService.entityTypeChanged(Survey).pipe(
            filter((survey) => survey.surveyId === this.relatedSurveyId),
            this.takeUntilDestroyed(),
        ).subscribe((survey) => this.updateSurveyStatus(survey));
    }

    // using this function rather than constructor as this is called after the workflow step is set
    public workflowStepOnInit() {
        if (this.workflowStep && this.workflowConnection && !this.surveySent) {
            this.workflowService.getWorkflowCustomData<IOrganisationDiagnosticStatusCustomData>(this.workflowConnection).pipe(
                switchMap((customData) => {
                    if (customData.surveyId) {
                        // need a force remote here as entity sync from webjob is not getting back here
                        return this.surveyService.getSurveyById(customData.surveyId, true);
                    }

                    throw new Error("This step will no longer create survey - only run this after adapt-launch-organisation-diagnostic-survey step");
                }),
                filter((survey) => !!survey),
                tap((survey) => {
                    this.relatedSurveyId = survey?.surveyId;
                    this.surveySent = true;
                    this.updateSurveyStatus(survey);
                }),
                this.takeUntilDestroyed(),
            ).subscribe();
        }
    }

    private updateSurveyStatus(survey?: Survey) {
        this.surveyCompleted = (survey?.status === SurveyStatus.Ended);
        if (this.surveyCompleted) {

            organisationDiagnosticSurveySummaryPage.getRouteObject(
                undefined,
                setReturnPathSearchParam(this.injector, {}, organisationDiagnosticSurveySummaryPage.id, "Continue pathway"),
            ).subscribe((route) => this.orgDiagSummaryLink = route);
        }

        this.workflowStepCompleted.next(this.surveyCompleted);
    }
}

import { Component, Inject } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { AutoUpdaterMergeStrategy } from "../entity-auto-updater/auto-updater-merge-strategy";
import { IEntityAutoUpdater } from "../entity-auto-updater/entity-auto-updater.interface";

export interface IConflictingChangesDialogData {
    changedByPerson?: Person;
    conflictingUpdates: IEntityAutoUpdater[];
}

@Component({
    selector: "adapt-conflicting-changes-dialog",
    templateUrl: "./conflicting-changes-dialog.component.html",
    styleUrls: ["./conflicting-changes-dialog.component.scss"],
})
export class ConflictingChangesDialogComponent extends BaseDialogComponent<IConflictingChangesDialogData, AutoUpdaterMergeStrategy> {
    public readonly dialogName = "ConflictingChangesDialog";

    public changedByPerson?: Person;
    public conflictingEntityMessages: string[];
    public allowKeepLocalChanges: boolean;

    constructor(@Inject(ADAPT_DIALOG_DATA) public dialogData: IConflictingChangesDialogData) {
        super();

        this.changedByPerson = dialogData.changedByPerson;
        this.conflictingEntityMessages = dialogData.conflictingUpdates
            .map((e) => e.htmlSummary);
        this.allowKeepLocalChanges = dialogData.conflictingUpdates
            .every((e) => e.userIsAllowedToIgnoreServerUpdates);
    }

    public useTheirChanges() {
        this.resolve(AutoUpdaterMergeStrategy.RemoteOverwritesLocal);
    }

    public useMyChanges() {
        this.resolve(AutoUpdaterMergeStrategy.LocalOverwritesRemote);
    }
}

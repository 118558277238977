import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from "@angular/core";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ContextSidebarSource, ContextSidebarSourceInput, ContextSidebarState } from "../context-sidebar.interface";
import { ContextSidebarService } from "../context-sidebar.service";

@Component({
    selector: "adapt-context-sidebar-content",
    templateUrl: "./context-sidebar-content.component.html",
    // we don't want to accidentally see the heading or content before its projected
    styles: [":host { display: none }"],
})
export class ContextSidebarContentComponent extends BaseComponent implements AfterViewInit, OnDestroy {
    @Input() public context: ContextSidebarSourceInput = ContextSidebarSource.Page;
    @Input() public fullScreen?: boolean;

    @ViewChild("contentRef", { read: ElementRef }) private contentRef!: ElementRef;
    @ViewChild("titleRef", { read: ElementRef }) private titleRef!: ElementRef;

    public readonly pageContext = ContextSidebarSource.Page;
    public contextSidebarVisible = false;

    public constructor(private contextSidebarService: ContextSidebarService) {
        super();

        this.contextSidebarService.contextSidebarState$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((state) => this.contextSidebarVisible = state !== ContextSidebarState.Closed);
    }

    public ngAfterViewInit() {
        this.contextSidebarService.setContent(this.contentRef.nativeElement, this.context as ContextSidebarSource);
        this.contextSidebarService.setTitle(this.titleRef.nativeElement, this.context as ContextSidebarSource);
    }

    public ngOnDestroy() {
        this.contextSidebarService.clearTitle();
        this.contextSidebarService.clearContent();
    }

    public toggleHelp() {
        if (this.contextSidebarVisible) {
            this.hideContextSidebar();
            return;
        }

        this.contextSidebarService.setIsOpen(true, this.fullScreen ? ContextSidebarState.Fullscreen : undefined);
    }

    private hideContextSidebar() {
        this.contextSidebarService.setIsOpen(false);
    }
}

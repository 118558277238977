import { Inject, Injectable } from "@angular/core";
import { Params } from "@angular/router";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { IUserMenuItem } from "@common/shell/application-bar-user-item-content/user-menu-item";
import { AuthorisationNotificationService } from "@org-common/lib/authorisation/authorisation-notification.service";
import { DirectoryAuthService } from "@org-common/lib/directory-shared/directory-auth.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { combineLatest, Observable } from "rxjs";
import { filter, map, startWith, switchMap } from "rxjs/operators";
import { MY_PROFILE_ROUTE_PROVIDER } from "../directory-shared/person-profile-route-provider";

@Injectable()
export class ProfileUserMenuItem implements IUserMenuItem {
    public text = "Profile";
    public icon = "fal fa-address-card";
    public testAttribute = "profile";
    public href$: Observable<string>;
    public isShown$: Observable<boolean>;

    public constructor(
        orgService: OrganisationService,
        directoryAuthService: DirectoryAuthService,
        authNotificationService: AuthorisationNotificationService,
        @Inject(MY_PROFILE_ROUTE_PROVIDER) profilePageRoute: IAdaptRoute<Params, Params>,
    ) {
        // Organisation initialisation happens after the user is already initialised so don't
        // bother updating when the user changes. This also handles an organisation switch
        // correctly too.
        this.href$ = orgService.currentOrganisation$.pipe(
            filter((org) => !!org),
            switchMap(() => profilePageRoute.getRoute()),
        );
        this.isShown$ = combineLatest([
            orgService.currentOrganisation$,
            authNotificationService.authorisationChanged$.pipe(
                startWith(undefined),
            ),
        ]).pipe(
            map(([org, _]) => !!org && directoryAuthService.currentPersonCanReadTheirProfile()),
        );
    }
}

<dx-data-grid [dataSource]="coachSessions"
              [allowColumnResizing]="true"
              [allowColumnReordering]="true"
              [wordWrapEnabled]="true"
              [rowAlternationEnabled]="true"
              [hoverStateEnabled]="true"
              [showRowLines]="true"
              [showBorders]="true"
              [stateStoring]="dxGridWrapperHelper.stateStoringOptions"
              (onInitialized)="onGridInitialized($event)"
              noDataText="No coaching sessions have been booked yet">
    <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
    <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
    <dxo-paging [pageSize]="10"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true"
               [allowedPageSizes]="[10, 25, 50, 100]"></dxo-pager>
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>

    <dxi-column *ngIf="showInternalData"
                dataField="coachSessionId"
                caption="Id"
                dataType="number"
                alignment="left"
                [width]="60"></dxi-column>
    <dxi-column dataField="invoice.invoiceString"
                caption="Invoice #"
                dataType="string"
                alignment="left"></dxi-column>
    <dxi-column dataField="invoice.date"
                dataType="date"
                caption="Invoice Date"
                [format]="dateFormat"
                sortOrder="desc"
                alignment="left"></dxi-column>
    <dxi-column caption="Session Type"
                alignment="left"
                dataType="string"
                dataField="type"
                [calculateCellValue]="calculateTypeCellValue"></dxi-column>
    <dxi-column dataField="invoice.total"
                dataType="number"
                caption="Cost"
                format="currency"
                alignment="left"
                [width]="80"></dxi-column>
    <dxi-column *ngIf="personTemplate"
                cellTemplate="personCellTemplate"
                caption="Person"
                dataField="person.fullName"
                dataType="string"
                alignment="left"></dxi-column>
    <dxi-column cellTemplate="textCellTemplate"
                dataField="description"
                dataType="string"
                caption="Issue"
                alignment="left"></dxi-column>
    <dxi-column cellTemplate="ratingCellTemplate"
                dataField="feedbackRating"
                dataType="number"
                [caption]="showInternalData ? 'Session Rating' : 'Your Session Rating'"
                alignment="left"></dxi-column>
    <dxi-column cellTemplate="textCellTemplate"
                dataField="feedbackComment"
                dataType="string"
                [caption]="showInternalData ? 'Session Comment' : 'Your Session Comment'"
                alignment="left"></dxi-column>
    <dxi-column cellTemplate="statusCellTemplate"
                [calculateCellValue]="calculateStatusCellValue"
                caption="Status"
                dataType="string"
                dataField="status"
                alignment="left"
                [width]="150"></dxi-column>
    <dxi-column *ngIf="coachTemplate && showInternalData"
                cellTemplate="coachCellTemplate"
                caption="Coach"
                dataField="practitioner.person.fullName"
                dataType="string"
                alignment="left"></dxi-column>
    <dxi-column *ngIf="actionTemplate && showInternalData"
                caption=""
                dataType="string"
                dataField="actions"
                cellTemplate="actionsCellTemplate"
                [allowSorting]="false"
                [allowReordering]="false"
                [allowHiding]="false"
                [allowFiltering]="false"
                [allowResizing]="false"
                [fixed]="true"
                fixedPosition="right"
                [width]="50"></dxi-column>

    <div *dxTemplate="let item of 'textCellTemplate'">
        <p style="white-space: pre-line">{{item.value}}</p>
    </div>

    <div *dxTemplate="let item of 'ratingCellTemplate'">
        <adapt-rating-stars *ngIf="!!item.value; else noStars"
                            [rating]="item.value"
                            disabled
                            compact></adapt-rating-stars>
        <ng-template #noStars>N/A</ng-template>
    </div>

    <div *dxTemplate="let item of 'statusCellTemplate'"
         class="d-flex align-items-center"
         [adaptTooltip]="getStatusTooltip(item.data)">
        <div class="status badge"
             [ngClass]="item.data.statusMetadata.badgeClass">{{item.value}}
        </div>
        <span *ngIf="item.data.status === CoachSessionStatus.Paid"
              class="fas fa-xl fa-exclamation-triangle text-warning ms-1"></span>
    </div>

    <div *dxTemplate="let item of 'personCellTemplate'">
        <ng-container [ngTemplateOutlet]="personTemplate"
                      [ngTemplateOutletContext]="{$implicit: item.data.person}"></ng-container>
    </div>

    <div *dxTemplate="let item of 'coachCellTemplate'">
        <ng-container [ngTemplateOutlet]="coachTemplate"
                      [ngTemplateOutletContext]="{$implicit: item.data.practitioner}"></ng-container>
    </div>

    <div *dxTemplate="let item of 'actionsCellTemplate'">
        <ng-container [ngTemplateOutlet]="actionTemplate"
                      [ngTemplateOutletContext]="{$implicit: item.data, updated: actionsUpdated}"></ng-container>
    </div>
</dx-data-grid>

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Survey, SurveyStatus } from "@common/ADAPT.Common.Model/organisation/survey";
import { SurveyResponseGroup } from "@common/ADAPT.Common.Model/organisation/survey-response";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { switchMap } from "rxjs/operators";
import { SurveyService } from "../survey.service";
import { SurveyUiService } from "../survey-ui.service";
import { SurveyUtils } from "../survey-utils";

// shared usage in personal dashboard, org dashboard and manage EI survey page
@Component({
    selector: "adapt-display-survey",
    templateUrl: "./display-survey.component.html",
    styleUrls: ["./display-survey.component.scss", "../survey-global-styles.scss"],
})
export class DisplaySurveyComponent extends BaseComponent implements OnInit {
    @Input() public survey!: Survey;
    @Input() public hideParticipation = false;
    @Input() public canEdit = false;
    @Input() public forceLightBackground = false;
    @Output() public surveyChange = new EventEmitter<Survey>();

    public badgeClass = "bg-primary";
    public statusText = "Ongoing";
    public participationRate = 0;
    public participationText = "";

    public constructor(
        private surveyService: SurveyService,
        private uiService: SurveyUiService,
    ) {
        super();
    }

    public get notStarted() {
        return this.survey.status === SurveyStatus.NotStarted;
    }

    public ngOnInit() {
        if (this.survey.status === SurveyStatus.NotStarted) {
            this.badgeClass = "badge-upcoming";
            this.statusText = "Upcoming";
        } else if (this.survey.status === SurveyStatus.Started) {
            this.badgeClass = "badge-ongoing";
            this.statusText = "Active";
        } else if (this.survey.status === SurveyStatus.Ended) {
            this.badgeClass = "badge-completed";
            this.statusText = "Completed";
        } else {
            this.badgeClass = "bg-danger"; // Should not happen but just in case it will be red
            this.statusText = "FIXME!";
        }

        // TODO: handle multiple ResponseType
        const surveyResult = SurveyUtils.getSurveyResultForResponseGroup(this.survey, SurveyResponseGroup.All);
        if (surveyResult) {
            this.participationRate = surveyResult.percentageParticipation ? surveyResult.percentageParticipation : 0;
            this.participationText = `Participation: ${this.participationRate.toFixed(0)}%`;
        }
    }

    public getStatusColorFromPercentage(percentage: number) {
        return SurveyUtils.forSurveyType(this.survey.surveyType).getStatusColorFromPercentage(percentage);
    }

    public getStatusBackgroundFromPercentage(percentage: number) {
        return SurveyUtils.forSurveyType(this.survey.surveyType).getStatusBackgroundFromPercentage(percentage);
    }

    public editSurvey() {
        // get the supplementary data so message will be available
        return this.surveyService.getSupplementaryDataForSurvey(this.survey.surveyId).pipe(
            switchMap(() => this.uiService.editSurvey(this.survey)),
        ).subscribe(() => {
            // need to reinitial survey data after edit so that upcoming entries can be reordered
            this.surveyChange.emit(this.survey);
        });
    }

    public viewOutstandingResponses() {
        return this.uiService.viewOutstandingResponsesForSurvey(this.survey).subscribe();
    }

    public promptToDeleteSurvey() {
        const promptToDelete = this.notStarted
            ? this.uiService.promptToDeleteUpcomingSurvey(this.survey)
            : this.uiService.promptToDeleteSurvey(this.survey);

        return promptToDelete.pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.surveyChange.emit(this.survey));
    }
}

import { Injectable, Injector } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { IdentityService } from "../identity/identity.service";
import { AbstractGuard } from "./abstract.guard";
import { GuardFailureType } from "./route-events.service";

@Injectable({
    providedIn: "root",
})
export class AuthenticatedGuard extends AbstractGuard {
    public static readonly Id = "AuthenticatedGuard";

    constructor(
        private identityService: IdentityService,
        injector: Injector,
    ) {
        super(injector);
    }

    public canActivateWithBypassEvent(route: ActivatedRouteSnapshot, routerState: RouterStateSnapshot, bypass: boolean) {
        return this.wrapActivationResult(this.identityService.promiseToCheckIsLoggedIn(), GuardFailureType.AuthenticatedGuardFailed, route, routerState, bypass);
    }
}

import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { BaseScrollPersistingComponent } from "@common/ux/base.component/base-scroll-persisting.component";
import { IObjectiveGroup } from "../objectives.service";

@Component({
    selector: "adapt-objective-tree-view",
    templateUrl: "./objective-tree-view.component.html",
})
export class ObjectiveTreeViewComponent extends BaseScrollPersistingComponent implements OnInit {
    @Input() public objectiveGroups?: IObjectiveGroup[];
    @Input() public currentTeamId: number | null = null;
    @Input() public isCompactView = false;
    @Input() public applyFilter = false;

    public hasAnnualObjective = false;
    public hasExternalParent = false;

    public constructor(
        elementRef: ElementRef,
    ) {
        super(elementRef);
    }

    public ngOnInit() {
        this.rememberScrollPosition(`ObjectiveTreeView-${this.currentTeamId ?? "org"}-${this.isCompactView}`);
    }
}

<dx-list class="configure-boards-list"
         [dataSource]="activeBoards"
         [disabled]="saving"
         (onItemReordered)="onItemReordered($event)"
         data-test="configure-active-boards">
    <dxo-item-dragging [allowReordering]="true"></dxo-item-dragging>

    <div *dxTemplate="let board of 'item'">
        <ng-container [ngTemplateOutlet]="boardItem"
                      [ngTemplateOutletContext]="{$implicit: board}"></ng-container>
    </div>
</dx-list>

<ng-container *ngIf="archivedBoards.length > 0">
    <h4 class="mt-3">Archived boards</h4>
    <p>Actions within these boards will not be shown on any action view, however they can still be found through search.</p>

    <dx-list class="configure-boards-list"
             [dataSource]="archivedBoards"
             [disabled]="saving"
             data-test="configure-archived-boards">
        <div *dxTemplate="let board of 'item'">
            <ng-container [ngTemplateOutlet]="boardItem"
                          [ngTemplateOutletContext]="{$implicit: board}"></ng-container>
        </div>
    </dx-list>
</ng-container>

<ng-template #boardItem
             let-board>
    <div class="configure-boards-item"
         data-test="configure-boards-item">
        <div class="board-name"
             data-test="board-name">
            {{board.name}}
        </div>

        <div class="board-prefix-permission">
            <div class="board-prefix">
                    <span adaptTooltip="Actions begin with the prefix {{board.itemPrefix}}-"
                          class="badge bg-primary">{{board.itemPrefix}}</span>
            </div>
            <div class="board-permission">
                    <span *ngIf="!board.isPublicReadAccess"
                          adaptTooltip="Actions are {{board.isPersonalBoard ? 'only visible to and editable by you' : 'private to the team'}}"
                          class="badge bg-warning">
                        <i class="fal fa-fw fa-lock"></i>
                    </span>
                <span *ngIf="board.isPublicReadAccess && !board.isPublicWriteAccess"
                      adaptTooltip="Actions are visible to but not editable by the public"
                      class="badge bg-warning">
                        <i class="fal fa-fw fa-eye"></i>
                    </span>
                <span *ngIf="board.isPublicWriteAccess"
                      adaptTooltip="Actions are visible to and editable by the public"
                      class="badge bg-warning">
                        <i class="fal fa-fw fa-pen-square"></i>
                    </span>
            </div>
        </div>

        <div class="board-actions">
            <button adaptButton="editBorderless"
                    adaptTooltip="Edit board"
                    [disabled]="saving"
                    [adaptBlockingClick]="editBoard"
                    [adaptBlockingClickParam]="board"
                    data-test="edit-board"></button>
            <button *ngIf="board.isArchived"
                    adaptButton="unarchiveBorderless"
                    adaptTooltip="Un-archive board"
                    [disabled]="saving"
                    (click)="unarchiveBoard(board)"
                    data-test="unarchive-board"></button>
            <button *ngIf="!board.isArchived"
                    adaptButton="archiveBorderless"
                    adaptTooltip="Archive board"
                    [disabled]="saving"
                    (click)="archiveBoard(board)"
                    data-test="archive-board"></button>
            <button adaptButton="deleteBorderless"
                    adaptTooltip="Delete board"
                    [disabled]="saving"
                    [adaptBlockingClick]="removeBoard"
                    [adaptBlockingClickParam]="board"
                    data-test="delete-board"></button>
        </div>
    </div>
</ng-template>

<button class="mt-2"
        adaptButton="add"
        [adaptBlockingClick]="addBoard"
        data-test="add-board">board</button>

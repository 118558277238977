import { Component, Injector } from "@angular/core";
import { EventSeriesType } from "@common/ADAPT.Common.Model/organisation/event-series";
import { EventTypePreset } from "@common/ADAPT.Common.Model/organisation/event-type";
import { IMeetingLocation, Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { MeetingsService } from "@org-common/lib/meetings/meetings.service";
import { IScheduledRecurrence } from "@org-common/lib/schedule/schedule-recurrence/schedule-recurrence.interface";
import { ScheduleUiService } from "@org-common/lib/schedule/schedule-ui.service";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import moment from "moment/moment";
import { BehaviorSubject, forkJoin, lastValueFrom } from "rxjs";
import { CadenceStepBaseComponent } from "../cadence-step-base.component";
import { ISetCadenceStepData } from "../establish-cadence-workflows";

@WorkflowStepComponent("adapt-set-cadence-step")
@Component({
    selector: "adapt-set-cadence-step",
    templateUrl: "./set-cadence-step.component.html",
})
export class SetCadenceStepComponent extends CadenceStepBaseComponent {
    public eventTypePreset!: EventTypePreset;
    public workflowStepCompleted = new BehaviorSubject<boolean>(false);

    public lastLocation?: IMeetingLocation;
    public locations: IMeetingLocation[] = [];

    public configDisabled = false;
    public warning?: string;
    private warningUpdater = this.createThrottledUpdater<string | undefined>((warning) => this.warning = warning);

    public constructor(
        injector: Injector,
        private implementationKitService: ImplementationKitService,
        private meetingsService: MeetingsService,
    ) {
        super(injector);
    }

    public get currentRecurrence() {
        return this.runData.scheduledPresets.get(this.eventTypePreset);
    }

    public set currentRecurrence(recurrence: IScheduledRecurrence | undefined) {
        if (recurrence) {
            this.runData.scheduledPresets.set(this.eventTypePreset, recurrence);
        }
    }

    public get nextTimes() {
        const eventTypeId = this.currentRecurrence?.eventSeries.eventTypeId;
        return this.items
            .filter((i) => i.sectionId === eventTypeId)
            .map((i) => i.start.toDate());
    }

    public get eventNameText() {
        const eventSeries = this.currentRecurrence?.eventSeries;
        if (!eventSeries || !eventSeries.eventType) {
            return undefined;
        }

        const once = eventSeries.eventSeriesType === EventSeriesType.Once;
        return `${eventSeries.eventType.name} (${eventSeries.eventType.code}) meeting${!once ? "s" : ""}`;
    }

    public async workflowStepOnInit() {
        const data = this.workflowStep?.customData as ISetCadenceStepData;
        if (!data) {
            throw new Error("WorkflowStep customData is not available");
        }
        this.eventTypePreset = data.eventType;

        await super.workflowStepOnInit();

        await this.getRecurrence();
        this.updateFromRunData();
        this.updateCadenceCycleDisplay();
    }

    public async onRecurrenceChange(scheduledRecurrence: IScheduledRecurrence) {
        this.currentRecurrence = scheduledRecurrence;
        const { eventSeries } = scheduledRecurrence;

        this.configDisabled = false;
        this.warningUpdater.next(undefined);

        // only show this warning if any of the meetings have been run
        if (eventSeries.entityAspect.entityState.isModified()
            && eventSeries.extensions.hasRunAtLeastOneMeeting) {
            this.warningUpdater.next("Changing this cadence will cause its upcoming meetings to be updated with the new time and location. Any individual changes will be lost.");
        }

        // SFO should fall within a month from the current date
        if (scheduledRecurrence.eventTypePreset === EventTypePreset.SetFirstObjectives) {
            const monthDiff = moment().diff(eventSeries.startDate, "month", true);
            if (Math.abs(monthDiff) >= 1) {
                this.warningUpdater.next(`We recommend scheduling your ${scheduledRecurrence.eventSeries.eventType.name} within a month from today.`);
            }
        }

        // AS should fall within a month from the end of the cadence cycle
        if (scheduledRecurrence.eventTypePreset === EventTypePreset.AnnualStrategy) {
            const monthDiff = moment(eventSeries.startDate).diff(this.runData.eventCadenceCycle.extensions.nextCycleStartDate, "days", true);
            if (Math.abs(monthDiff) >= 30) {
                this.warningUpdater.next(`We recommend scheduling your ${scheduledRecurrence.eventSeries.eventType.name} within a month from the end of your cadence cycle.`);
            }
        }

        // OCI should not fall within the same week as MS
        if (scheduledRecurrence.eventTypePreset === EventTypePreset.OKRCheckIn) {
            const monthlyStrategyConfig = this.runData.scheduledPresets.get(EventTypePreset.MonthlyStrategy);
            if (monthlyStrategyConfig && monthlyStrategyConfig.eventSeries.weekIndex === scheduledRecurrence.eventSeries.weekIndex) {
                this.warningUpdater.next(`We recommend scheduling your ${scheduledRecurrence.eventSeries.eventType.name} within a different week from your ${monthlyStrategyConfig.eventSeries.eventType.name}.`);
            }
        }

        const events = await this.getAllMeetings();
        this.items = events.map(ScheduleUiService.SchedulerItemFromMeeting);

        if (eventSeries.meetings.length === 0) {
            this.warningUpdater.next("There are no meetings scheduled for this event as there are conflicts with other events. Please consider adjusting your chosen schedule.");
        }

        await this.preloadMeetingArticles();
    }

    private async getRecurrence() {
        // we only manually populate runData from the result here
        // if we populate cadences we haven't configured yet behaviour gets weird
        const recurrence = this.runData.scheduledPresets.get(this.eventTypePreset)
            ?? await this.scheduleService.getRecurrenceForEventTypePreset(this.eventTypePreset, this.leadershipTeam, this.runData.scheduledPresets, true);

        if (recurrence) {
            this.runData.scheduledPresets.set(recurrence.eventTypePreset!, recurrence);
        }
    }

    private updateFromRunData() {
        this.locations = [];

        for (const config of this.runData.scheduledPresets.values()) {
            // populate runData from the given recurrences
            // but only if already configured or if its the current preset
            // behaviour gets odd when you populate ones that are not configured...
            if (config.eventSeries.entityAspect.entityState.isUnchangedOrModified()
                || config.eventTypePreset === this.eventTypePreset) {
                // make sure we copy over the deleted entities for the config
                const existingPreset = this.runData.scheduledPresets.get(config.eventTypePreset!);
                if (existingPreset?.deletedEntities) {
                    config.deletedEntities = (config.deletedEntities ?? []).concat(existingPreset.deletedEntities);
                }
            }

            const location = config.eventSeries.extensions.getMeetingLocation();
            if (location && !this.locations.find((loc) => loc.name === location.name)) {
                this.locations.push(location);
            }

            if (config.eventTypePreset === this.eventTypePreset) {
                // get the currently defined location or use the last provided location as the default
                this.lastLocation = location ?? this.locations[this.locations.length - 1];

                // only allow continue after a config exists
                this.workflowStepCompleted.next(true);
            }
        }
    }

    private async getAllMeetings() {
        const meetings: Meeting[] = [];

        for (const stepData of this.runData.scheduledPresets.values()) {
            const conflictingEventTypes = stepData.config.conflictingEventTypes ?? [];

            // get all events that match the specified eventTypes.
            // promiseToCreateOrUpdateEventsForSchedule will make sure it skips any conflicting events
            const conflictingMeetings: Meeting[] = Array.from(this.runData.scheduledPresets.entries())
                .filter(([preset]) => conflictingEventTypes.includes(preset))
                .flatMap(([_, data]) => data.eventSeries.meetings);

            const eventSeries = await this.scheduleService.promiseToCreateOrUpdateMeetingsForSchedule(stepData, conflictingMeetings);
            meetings.push(...eventSeries.meetings);
        }

        return meetings;
    }

    // preload the articles used within the meetings so that creating the meetings at the end of the workflow is fast
    private async preloadMeetingArticles() {
        const eventSeries = this.currentRecurrence?.eventSeries;
        if (eventSeries) {
            const eventType = eventSeries.eventType;

            // if the meetings have already been saved, we don't need to prefetch the articles.
            // if we need to fetch the articles, it will be done at the end of the workflow.
            const meetingsNeedToBeSaved = eventSeries.meetings.some((m) => m.entityAspect.entityState.isAddedModifiedOrDeleted());
            if (eventType.meetingAgendaTemplateId && meetingsNeedToBeSaved) {
                const agendaItems = await lastValueFrom(this.meetingsService.getAgendaItemsForMeetingAgendaTemplate(eventType.meetingAgendaTemplateId));

                const articleSlugs = agendaItems
                    .map((i) => i.articleSlug)
                    .filter((slug) => !!slug) as ImplementationKitArticle[];

                if (eventType.meetingAgendaTemplate?.meetingDescriptionArticleSlug) {
                    articleSlugs.push(eventType.meetingAgendaTemplate.meetingDescriptionArticleSlug);
                }

                if (articleSlugs.length > 0) {
                    const articleRequests = articleSlugs.map((slug) => this.implementationKitService.getArticle(slug));
                    await lastValueFrom(forkJoin(articleRequests));
                }
            }

            const savedMeetings = eventSeries.meetings.filter((m) => m.entityAspect.entityState.isUnchangedOrModified());
            if (savedMeetings.length > 0) {
                // fetch the supp data for these meetings so that we don't have to do it later
                // otherwise they will get fetched individually in review-cadence-step when doing createWorkflowMeeting.
                const meetingIds = savedMeetings.map((m) => m.meetingId);
                await lastValueFrom(this.meetingsService.getSupplementaryDataForMeetings(meetingIds));
            }
        }
    }
}

import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Organisation } from "../organisation/organisation";
import { DocumentationSubscriptionLevel } from "./documentation-subscription-level";
import { Person } from "./person";

export class PersonPreferences extends BaseEntity<PersonPreferences> {
    public personId!: number;
    public defaultOrganisationId?: number;

    public notifyDailyItemSummary!: boolean;
    public notifyDailyObjectiveSummary!: boolean;
    public notifyUpcomingDueItems!: boolean;
    public notifyUpcomingDueObjectives!: boolean;

    public documentationSubscriptionLevel!: DocumentationSubscriptionLevel;

    public defaultOrganisation!: Organisation;
    public person!: Person;
}

export const PersonPreferencesBreezeModel = new BreezeModelBuilder("PersonPreferences", PersonPreferences)
    .withIdField()
    .hasSource()
    .isOrganisationEntity()
    .build();

import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren } from "@angular/core";
import { Zone } from "@common/ADAPT.Common.Model/methodology/zone";
import { Goal } from "@common/ADAPT.Common.Model/organisation/goal";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { RouteService } from "@common/route/route.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { Breakpoint } from "@common/ux/responsive/breakpoint";
import { BehaviorSubject, switchMap } from "rxjs";
import { AuthorisationService } from "../../authorisation/authorisation.service";
import { StrategyAuthService } from "../../strategy/strategy-auth.service";
import { StrategicGoalZoneComponent } from "../strategic-goal-zone/strategic-goal-zone.component";
import { StrategicGoalsService } from "../strategic-goals.service";

export const StrategyGoalExpandParam = "expand";

@Component({
    selector: "adapt-strategic-goals",
    templateUrl: "./strategic-goals.component.html",
    styleUrls: ["./strategic-goals.component.scss"],
})
export class StrategicGoalsComponent extends BaseComponent {
    public readonly FullLayoutBreakpoint = Breakpoint.XXL;

    @Input() public isEditing = false;
    @Input() public isReordering = false;
    @Input() public showToolbarMenu = false;
    @Input() public expandGoals = false;
    @Input() public updateZoneExpandQueryParams = true;
    @Input() public showClosed = false;

    @Output() public initialised = new EventEmitter<void>();
    @Output() public hasDefinedGoals = new EventEmitter<boolean>();

    public hasEditAccess = false;
    public hasGoals = false;

    private updater$ = new BehaviorSubject<void>(undefined);

    @ViewChildren(StrategicGoalZoneComponent) private zoneList?: QueryList<StrategicGoalZoneComponent>;

    public constructor(
        elementRef: ElementRef,
        private goalsService: StrategicGoalsService,
        private routeService: RouteService,
        rxjsBreezeService: RxjsBreezeService,
        authorisationService: AuthorisationService,
    ) {
        super(elementRef);

        rxjsBreezeService.entityTypeChanged(Goal).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updater$.next());

        this.updater$.pipe(
            switchMap(() => goalsService.getAllGoals()),
            this.takeUntilDestroyed(),
        ).subscribe((goals) => {
            this.hasGoals = goals.length > 0;
            this.hasDefinedGoals.emit(this.hasGoals);
            this.isInitialised = true;
            this.initialised.next();
        });

        this.updater$.pipe(
            switchMap(() => authorisationService.promiseToGetHasAccess(StrategyAuthService.EditStrategicGoals)),
            this.takeUntilDestroyed(),
        ).subscribe((hasEditAccess) => this.hasEditAccess = hasEditAccess);
    }

    public async detectChanges(zone: Zone, expand = false) {
        await this.updateUrlWithExpandedZones(zone, expand);
        this.zoneList?.filter((z) => z.zone === zone)
            .forEach((z: StrategicGoalZoneComponent) => z.detectChanges(expand));
    }

    @Autobind
    public addGoal(zone: Zone) {
        return this.goalsService.editGoalAfterCreate(zone);
    }

    public async updateUrlWithExpandedZones(zone: Zone, expand: boolean) {
        if (!this.updateZoneExpandQueryParams) {
            return;
        }

        const paramValue = this.routeService.getSearchParameterValue(StrategyGoalExpandParam);
        let paramValueArray: string[] = [];
        if (paramValue) {
            paramValueArray = paramValue.split(",");
        }
        if (!expand) {
            paramValueArray = ArrayUtilities.removeElementFromArray(zone, paramValueArray);
        } else if (!paramValueArray.includes(zone)) {
            paramValueArray.push(zone);
        }

        if (paramValueArray.length > 0) {
            await this.routeService.updateSearchParameterValue(StrategyGoalExpandParam, paramValueArray.join(","));
        } else {
            await this.routeService.deleteSearchParameter(StrategyGoalExpandParam);
        }
    }
}

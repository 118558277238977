import { ILogInstance, ILogProvider } from "./log-provider.interface";
import { ILogger } from "./logger.interface";
import { Trace } from "./trace";

export class Logger implements ILogger {
    private static logProviders: ILogProvider[] = [];

    public static registerLogProvider(logProvider: ILogProvider) {
        Logger.logProviders.push(logProvider);
    }

    /** Remove all loggers of the specified type. Mainly intended for testing
     * to remove the loggers we don't want running.
     */
    public static removeLogProviderOfType(type: new (...args: any[]) => ILogProvider) {
        Logger.logProviders = Logger.logProviders.filter((i) => !(i instanceof type));
    }

    /**
     * Get the log provider for the specified type.
     * @param type type to get log provider of, e.g. ToasterLogProvider
     */
    public static getLogProviderOfType(type: new (...args: any[]) => ILogProvider) {
        return Logger.logProviders.find((i) => i instanceof type);
    }


    public static getLogger(moduleId: string): ILogger {
        return new Logger(moduleId);
    }

    // We use lambda's for the public functions of this class as an alternative to
    // @Autobind so that we can avoid circular dependencies from using it.
    private constructor(private moduleId: string) { }

    public log = (message: string, ...optionalParams: unknown[]) => {
        this.write(Trace.Info, message, optionalParams);
    };

    public debug = (message: string, ...optionalParams: unknown[]) => {
        this.write(Trace.Debug, message, optionalParams);
    };

    public success = (message: string, ...optionalParams: unknown[]) => {
        this.write(Trace.Success, message, optionalParams);
    };

    public info = (message: string, ...optionalParams: unknown[]) => {
        this.write(Trace.Info, message, optionalParams);
    };

    public warn = (message: string, ...optionalParams: unknown[]) => {
        this.write(Trace.Warning, message, optionalParams);
    };

    public error = (message: string, ...optionalParams: unknown[]) => {
        this.write(Trace.Error, message, optionalParams);
    };

    private write(level: Trace, message: string, data: unknown[]) {
        const logInstance: ILogInstance = {
            level,
            timestamp: new Date(),
            moduleId: this.moduleId,
            message,
            data,
        };

        for (const logProvider of Logger.logProviders) {
            if (logProvider.logLevel > level) {
                continue;
            }

            try {
                logProvider.write(logInstance);
            } catch (e) {
                window.console.error(logInstance, e);
            }
        }
    }
}

import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { ActivationRequirement, AdaptRouteBuilder } from "@common/route/adapt-route-builder";
import { AccessGuardService } from "./access-guard.service";
import { ChangeGuard } from "./change.guard";
import { CoachGuard } from "./coach.guard";
import { FeatureGuardService } from "./feature-guard.service";
import { OrganisationGuard } from "./organisation.guard";

export class OrganisationRouteBuilder extends AdaptRouteBuilder {
    public requiresCoach(value: boolean = true) {
        this.activationRequirements[ActivationRequirement.Coach] = value;

        return this;
    }

    public enableChangeManagerDetection() {
        this.route.canActivate!.push(ChangeGuard);
        return this;
    }

    public requiresOrganisationValidation() {
        this.route.canActivate!.push(OrganisationGuard);
        return this;
    }

    public requiresFeature(feature: FeatureName) {
        const guard = FeatureGuardService.forFeature(feature);
        this.route.canActivate!.push(guard);

        return this;
    }

    public requiresAccess(access: string) {
        const guard = AccessGuardService.forAccess(access);
        this.route.canActivate!.push(guard);
        // previous config.settings.access.verifyAccess was only a single accessVerifierId?? Not supporting multiple??
        // - just use a string here temporarily as access will be verified using route activation guards (which allows multiple)
        this.routeData!.accessVerifier = access;
        return this;
    }

    protected setActivationRequirements() {
        super.setActivationRequirements();

        if (this.activationRequirements[ActivationRequirement.Coach]) {
            this.route.canActivate!.push(CoachGuard);
        }
    }
}

import { Component, ElementRef, Injector } from "@angular/core";
import { BullseyeQuadrant, BullseyeQuadrantStatement } from "@common/ADAPT.Common.Model/organisation/bullseye-quadrant-statement";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { QueuedCaller } from "@common/lib/queued-caller/queued-caller";
import { BullseyeService } from "@org-common/lib/bullseye/bullseye.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import { BehaviorSubject, filter, finalize, forkJoin, map, of, switchMap, take, tap } from "rxjs";

const StartStatementCount = 3;

export interface IInputQuadrantStatementsData {
    guidanceArticleSlug: ImplementationKitArticle;
    quadrant: BullseyeQuadrant;
    requiredQuadrants: BullseyeQuadrant[],
}

@WorkflowStepComponent("adapt-input-quadrant-statements")
@Component({
    selector: "adapt-input-quadrant-statements",
    templateUrl: "./input-quadrant-statements.component.html",
    styleUrls: ["./input-quadrant-statements.component.scss"],
})
export class InputQuadrantStatementsComponent extends WorkflowStepComponentAdapter {
    public readonly MaxStatementLength = 50;

    public stepData?: IInputQuadrantStatementsData;
    public statements: BullseyeQuadrantStatement[] = [];
    public workflowStepCompleted = new BehaviorSubject<boolean>(false);

    public quadrantQuestion?: string;
    private deletedStatements: BullseyeQuadrantStatement[] = [];
    private organisationName = new QueuedCaller<string>();

    public constructor(
        injector: Injector,
        elementRef: ElementRef,
        private bullseyeService: BullseyeService,
        orgService: OrganisationService,
    ) {
        super(elementRef);
        this.subscribeToEmitForEntityTypeChange(injector, BullseyeQuadrantStatement);
        orgService.currentOrganisation$.pipe(
            filter((org) => !!org),
            take(1),
            this.takeUntilDestroyed(),
        ).subscribe((org) => this.organisationName.setCallee(org!.name));
    }

    public workflowStepOnInit() {
        this.stepData = this.workflowStep?.customData;
        forkJoin([
            this.bullseyeService.getStatementsForQuadrant(this.stepData?.quadrant),
        ]).pipe(
            tap(([statements]) => {
                this.statements = statements;
            }),
            switchMap(() => {
                if (this.statements.length < StartStatementCount) {
                    return forkJoin(Array.from({ length: StartStatementCount - this.statements.length }, this.createEmptyStatement)).pipe(
                        map((newStatements) => [...this.statements, ...newStatements]),
                    );
                } else {
                    return of(this.statements);
                }
            }),
            tap((statements) => this.statements = statements),
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            this.triggerValidationCheck();
            this.organisationName.call((orgName) =>
                this.quadrantQuestion = BullseyeQuadrantStatement.getQuestionTextForQuadrant(this.stepData!.quadrant, orgName));
            this.isInitialised = true;
        });
    }

    public workflowStepNext() {
        // remove all blank statement and save all
        const invalidStatements = this.statements.filter((s) => !s.statement.trim());
        const clearEmpty$ = invalidStatements.length > 0
            ? forkJoin(invalidStatements.map((s) => this.bullseyeService.deleteStatement(s)))
            : of([]);
        return clearEmpty$.pipe(
            switchMap(() => this.bullseyeService.saveEntities([...this.statements, ...this.deletedStatements])),
            finalize(() => this.statements = this.statements.filter((s) =>
                !s.entityAspect.entityState.isDetached() && !s.entityAspect.entityState.isDeleted())),
        );
    }

    public workflowStepPrevious() {
        if (this.deletedStatements.length > 0) {
            // if deleted statements and go to previous, commit deletion as those entities cannot be retrieved next time this component is loaded
            return this.bullseyeService.saveEntities([...this.deletedStatements]);
        } else {
            return of(undefined);
        }
    }

    public addStatement() {
        this.createEmptyStatement().pipe(
            this.takeUntilDestroyed(),
        ).subscribe((statement) => this.statements.push(statement));
    }

    public deleteStatement(statement: BullseyeQuadrantStatement) {
        this.bullseyeService.deleteStatement(statement).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            const removeIndex = this.statements.findIndex((s) => s.bullseyeQuadrantStatementId === statement.bullseyeQuadrantStatementId);
            if (removeIndex >= 0) {
                this.statements.splice(removeIndex, 1);
            }

            this.deletedStatements.push(statement);
            this.triggerValidationCheck();
        });
    }

    public triggerValidationCheck() {
        const canProgressToNext = this.statements.some((statement) => !!statement.statement.trim());
        this.workflowStepCompleted.next(canProgressToNext);
    }

    @Autobind
    private createEmptyStatement() {
        return this.bullseyeService.createStatementForQuadrant(this.stepData?.quadrant);
    }
}

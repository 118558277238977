import { AsyncPipe, NgIf, NgTemplateOutlet } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptStyledDashboardElementModule } from "@common/ux/styled-dashboard-element/styled-dashboard-element.module";
import { FroalaViewModule } from "angular-froala-wysiwyg";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { AdaptObjectivesModule } from "../objectives/objectives.module";
import { AdaptStrategicAnchorsModule } from "../strategic-anchors/strategic-anchors.module";
import { AdaptStrategicGoalsModule } from "../strategic-goals/strategic-goals.module";
import { AdaptValuesConstitutionModule } from "../values-constitution/values-constitution.module";
import { AdaptWorkflowModule } from "../workflow/workflow.module";
import { StrategyDashboardPageComponent, StrategyDashboardPageRoute } from "./strategy-dashboard-page.component";

@NgModule({
    declarations: [
        StrategyDashboardPageComponent,
    ],
    imports: [
        AdaptStyledDashboardElementModule,
        AdaptStrategicGoalsModule,
        AdaptStrategicAnchorsModule,
        FroalaViewModule,
        AsyncPipe,
        AdaptObjectivesModule,
        AdaptValuesConstitutionModule,
        AdaptWorkflowModule,
        AdaptShellModule,
        AdaptButtonModule,
        CollapseModule,
        NgIf,
        NgTemplateOutlet,
    ],
    exports: [
        StrategyDashboardPageComponent,
    ],
})
export class AdaptStrategyDashboardPageModule {
    public static readonly Routes = [
        ...StrategyDashboardPageRoute.routes,
    ];
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptMarketingTrackerModule } from "@common/track/marketing-tracker/marketing-tracker.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { DxNumberBoxModule, DxSelectBoxModule, DxTextBoxModule, DxValidationGroupModule, DxValidatorModule } from "devextreme-angular";
import { ConfirmEmailPageComponent, EmailConfirmationPageRoute } from "./confirm-email-page/confirm-email-page.component";
import { SelectRoleInOrganisationComponent } from "./select-role-in-organisation/select-role-in-organisation.component";
import { SignupPageComponent, SignupPageRoute } from "./signup-page/signup-page.component";

@NgModule({
    declarations: [
        ConfirmEmailPageComponent,
        SignupPageComponent,
        SelectRoleInOrganisationComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,

        DxTextBoxModule,
        DxNumberBoxModule,
        DxSelectBoxModule,
        DxValidatorModule,
        DxValidationGroupModule,

        AdaptButtonModule,
        AdaptTooltipModule,
        AdaptLoadingSpinnerModule,
        AdaptMarketingTrackerModule,
    ],
    exports: [
        SelectRoleInOrganisationComponent,
    ],
})
export class AdaptAccountModule {
    public static readonly Routes = [
        ...SignupPageRoute.routes,
        ...EmailConfirmationPageRoute.routes,
    ];
}

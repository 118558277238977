<div *ngIf="configItem?.featureName"
     class="feature-check">
    <dx-check-box text="Enable {{enableFeatureText}}"
                  [value]="isFeatureEnabled"
                  (valueChange)="onFeatureStatusValueChanged($any($event))"></dx-check-box>
</div>

<div *ngIf="isFeatureEnabled"
     class="feature-detail">
    <ng-content></ng-content>
</div>

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { Observable } from "rxjs";
import { ImplementationKitArticle } from "../implementation-kit-article.enum";

@Component({
    selector: "adapt-styled-implementation-kit",
    styleUrls: ["./styled-implementation-kit.component.scss"],
    templateUrl: "./styled-implementation-kit.component.html",
})
export class StyledImplementationKitComponent extends BaseComponent {
    @Input() public articleId?: ImplementationKitArticle;
    @Input() public loadOnEmit?: Observable<void>;
    @Input() public contentStyle?: "embedded";
    @Output() public articleName = new EventEmitter<string>();
    @Output() public contentLoaded = new EventEmitter<void>();

    public name?: string;
    public error?: string;

    public constructor() {
        super();
    }

    public onArticleNameUpdated(name: string) {
        this.name = name;
        this.articleName.emit(name);
    }

    public onContentLoaded() {
        this.contentLoaded.emit();
    }
}

import { Component } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { MeetingAgendaItemComponent } from "@org-common/lib/meetings/meeting-agenda-component-registry";
import { SeedEntity } from "@org-common/lib/purpose-vision/common-purpose-vision.service";
import { CommonPurposeVisionAuthService } from "@org-common/lib/purpose-vision/common-purpose-vision-auth.service";
import { WorkflowRunDialogComponent } from "@org-common/lib/workflow/workflow-run-dialog/workflow-run-dialog.component";
import { alignOwnersVisionStandaloneWorkflow } from "../../align-owners-vision/align-owners-vision-workflow";

@MeetingAgendaItemComponent("adapt-craft-vision-foundations-agenda-item")
@Component({
    selector: "adapt-craft-vision-foundations-agenda-item",
    templateUrl: "./craft-vision-foundations-agenda-item.component.html",
})
export class CraftVisionFoundationsAgendaItemComponent extends BaseComponent {
    public readonly SeedEntity = SeedEntity;
    public readonly CompassGuidingPhilosophyEdit = CommonPurposeVisionAuthService.EditPurposeVision;

    public constructor(
        private commonDialogService: AdaptCommonDialogService,
    ) {
        super();
    }

    @Autobind
    public editOwnersAlignment() {
        return this.commonDialogService.open(WorkflowRunDialogComponent, {
            workflow: alignOwnersVisionStandaloneWorkflow,
        });
    }
}

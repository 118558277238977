<div *ngIf="meetingDescriptionArticleId || preWorkArticleId"
     class="meeting"
     [class.has-pre-work]="!!preWorkArticleId">
    <h4><i class="far fa-clipboard-list"></i>This pathway contains a meeting</h4>

    <div *ngIf="meetingDescriptionArticleId"
         class="description">
        <adapt-see-more>
            <adapt-implementation-kit-content [articleId]="meetingDescriptionArticleId"></adapt-implementation-kit-content>
        </adapt-see-more>
    </div>

    <div *ngIf="preWorkArticleId"
         class="mt-3">
        <h4>Pre-work</h4>
        <adapt-implementation-kit-content [articleId]="preWorkArticleId"></adapt-implementation-kit-content>
    </div>
</div>

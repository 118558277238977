<adapt-collapsible-search-row [result]="result"
                              [matches]="matches"
                              [href$]="href$">
    <div class="d-flex flex-grow-1 align-items-center justify-content-between">
        <div class="d-flex flex-grow-0 me-2 text-muted">
            <div class="d-flex align-items-center result-icon meeting-icon">
                <i class="fal fa-clipboard-list fa-inverse"></i>
            </div>

            <div class="ms-2 d-flex align-items-center">
                <adapt-link-team class="me-2"
                                 [teamId]="result.teamId"
                                 [labelLinkDisabled]="true"></adapt-link-team>
                &gt;
            </div>
        </div>

        <div class="flex-grow-1 text-break d-flex align-items-center">
            <adapt-highlighted-text [needle]="keyword"
                                    [haystack]="result.name"></adapt-highlighted-text>
        </div>
    </div>
</adapt-collapsible-search-row>

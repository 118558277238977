import { Component, Inject, InjectionToken, Input, Optional, Provider } from "@angular/core";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { combineLatest, Observable, of, ReplaySubject } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AuthorisationService } from "../../authorisation/authorisation.service";
import { CommonIntegratedArchitectureFrameworkAuthService } from "../common-integrated-architecture-framework-auth.service";

export const ROLE_PAGE_ROUTE_PROVIDER = new InjectionToken("ROLE_PAGE_ROUTE_PROVIDER");

export function provideRolePageRoute(route: IAdaptRoute<{roleId: number}>): Provider {
    return {
        provide: ROLE_PAGE_ROUTE_PROVIDER,
        useValue: route,
        multi: false,
    };
}

@Component({
    selector: "adapt-link-role",
    templateUrl: "./link-role.component.html",
    styleUrls: ["./link-role.component.scss"],
})
export class LinkRoleComponent {
    @Input() public showIcon = false;
    @Input() public set role(value: Role | undefined) {
        this.role$.next(value);
    }

    public role$ = new ReplaySubject<Role | undefined>(1);
    public roleDescriptionUrl$: Observable<string | null>;

    public constructor(
        authService: AuthorisationService,
        @Optional() @Inject(ROLE_PAGE_ROUTE_PROVIDER) roleDescriptionRoute?: IAdaptRoute<{roleId: number}>,
    ) {
        this.roleDescriptionUrl$ = roleDescriptionRoute
            ? combineLatest([
                authService.getHasAccess(CommonIntegratedArchitectureFrameworkAuthService.ReadTier2),
                this.role$,
            ]).pipe(
                switchMap(([hasAccess, role]) => (hasAccess && role && role.roleId > 0)
                    ? roleDescriptionRoute.getRoute(role)
                    : of(null)),
            )
            : of(null);
    }
}

import { Component, Injector, OnDestroy, OnInit, SecurityContext } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { ShellUiService } from "@common/shell/shell-ui.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { startWith } from "rxjs/operators";

const LinkSearchParam = "link";

@Component({
    selector: "adapt-external-dashboard-page",
    templateUrl: "./external-dashboard-page.component.html",
    styleUrls: ["./external-dashboard-page.component.scss"],
})
export class ExternalDashboardPageComponent extends BaseRoutedComponent implements OnInit, OnDestroy {
    public url?: SafeResourceUrl;

    public noLinkFound = false;

    constructor(
        private sanitizer: DomSanitizer,
        private organisationService: OrganisationService,
        private shellUIService: ShellUiService,
        injector: Injector,
    ) {
        super(injector);
    }

    public async ngOnInit() {
        this.navigationEnd.pipe(
            this.takeUntilDestroyed(),
            startWith(undefined),
        ).subscribe(() => {
            this.loadDashboard();
        });
    }

    public ngOnDestroy() {
        this.shellUIService.setToolbarIsVisible(true);
        super.ngOnDestroy();
    }

    public frameLoaded() {
        this.notifyActivated();
    }

    private async loadDashboard() {
        const teamId = this.getRouteParamInt("teamId");
        const dashboardId = this.getSearchParameterIntValue(LinkSearchParam);
        const dashboards = await this.organisationService.promiseToGetExternalDashboards(teamId);
        this.shellUIService.removeDefaultShellPadding();
        this.shellUIService.setToolbarIsVisible(false);
        if (dashboards.length > 0) {
            // use provided id for dashboard, else just load the first dashboard
            const dashboardFromId = dashboardId ? dashboards.find((d) => d.externalDashboardId === dashboardId) : undefined;
            const dashboard = dashboardFromId ?? dashboards[0];

            if (dashboard) {
                if (dashboard.externalDashboardId !== dashboardId) {
                    this.setSearchParameterValue(LinkSearchParam, dashboard.externalDashboardId);
                }

                const sanitisedUrl = this.sanitizer.sanitize(SecurityContext.URL, dashboard.url);
                if (sanitisedUrl) {
                    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(sanitisedUrl);
                }

                return;
            }
        }

        this.noLinkFound = true;
        this.deleteSearchParameter(LinkSearchParam);
        this.notifyActivated();
    }
}

import { Component } from "@angular/core";
import { MeetingAgendaItemComponent } from "@org-common/lib/meetings/meeting-agenda-component-registry";
import { TourIdentifier } from "../../../tours/tour-identifier.enum";

@MeetingAgendaItemComponent("adapt-record-quarterly-objectives-agenda-item")
@Component({
    selector: "adapt-record-quarterly-objectives-agenda-item",
    templateUrl: "./record-quarterly-objectives-agenda-item.component.html",
})
export class RecordQuarterlyObjectivesAgendaItemComponent {
    public readonly PageActivationTours = [TourIdentifier.RecordObjectivesFeatureTour];
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AdaptCommonUserModule } from "@common/user/adapt-common-user.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptDirectorySharedModule } from "../directory-shared/directory-shared.module";
import { AddCommentComponent } from "./add-comment/add-comment.component";
import { ChainCommentComponent } from "./chain-comment/chain-comment.component";
import { ChainUpdateComponent } from "./chain-update/chain-update.component";
import { CommentChainComponent } from "./comment-chain/comment-chain.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AdaptCommonUserModule,
        AdaptTooltipModule,
        AdaptButtonModule,
        AdaptDateModule,
        AdaptHtmlEditorModule,
        AdaptDirectorySharedModule,
    ],
    declarations: [
        ChainCommentComponent,
        AddCommentComponent,
        CommentChainComponent,
        ChainUpdateComponent,
    ],
    exports: [
        CommentChainComponent,
        AddCommentComponent,
    ],
})
export class AdaptCommentChainModule {
}

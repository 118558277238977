import { Component, Inject } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { DeepDive } from "@common/ADAPT.Common.Model/embed/deep-dive";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";

@Component({
    selector: "adapt-workflow-scorm-dialog",
    styleUrls: ["./workflow-scorm-dialog.component.scss"],
    templateUrl: "./workflow-scorm-dialog.component.html",
})
export class WorkflowScormDialogComponent extends BaseDialogComponent<DeepDive> {
    public readonly dialogName = "WorkflowScormDialog";
    public safeResourceLocation: SafeResourceUrl;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public data: DeepDive,
        private sanitizer: DomSanitizer,
    ) {
        super();

        const location = this.data.extensions.frameUrl;
        this.safeResourceLocation = this.sanitizer.bypassSecurityTrustResourceUrl(location);
    }
}

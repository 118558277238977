<adapt-render-zone-map-internal [zoneContentContainerTemplate]="expandedZoneContentTemplate"
                                [globalZoneContentContainerTemplate]="expandedGlobalZoneContentTemplate"
                                [showGlobalZoneLocation]="showGlobalZoneLocation"
                                [globalZoneLocationLabel]="globalZoneLocationLabel"></adapt-render-zone-map-internal>

<ng-template #expandedZoneContentTemplate
             let-zone>
    <div class="zone-content-container"
         [ngClass]="zoneContentClass(zone)">
        <div *ngIf="zone !== Zone.EconomicEngine; else economicEngineTemplate"
             class="zone-content">
            <ng-container [ngTemplateOutlet]="expandedZoneLocationTemplate"
                          [ngTemplateOutletContext]="{zone: zone}"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #economicEngineTemplate>
    <div class="research-and-development-container show-on-left"
         data-test="zone-ResearchAndDevelopment"
         *ngIf="showOnLeft && showResearchAndDevelopment">
        <ng-container [ngTemplateOutlet]="researchZoneNameTemplate"></ng-container>
        <div class="research-zone-content">
            <ng-container [ngTemplateOutlet]="expandedZoneLocationTemplate"
                          [ngTemplateOutletContext]="{zone: Zone.ResearchAndDevelopment}"></ng-container>
        </div>
    </div>
    <div class="economic-engine-sub-container">
        <div class="value-streams-container"
             *ngIf="(valueStreams | async)?.length">
            <div *ngFor="let valueStream of (valueStreams | async)"
                 class="value-stream-container">
                <ng-container *ngIf="valueStreamTitleTemplate"
                              [ngTemplateOutlet]="valueStreamTitleTemplate"
                              [ngTemplateOutletContext]="{valueStream}"></ng-container>
                <div class="value-stream-content">
                    <ng-container [ngTemplateOutlet]="expandedZoneLocationTemplate"
                                  [ngTemplateOutletContext]="{valueStream: valueStream}"></ng-container>
                </div>
                </div>
        </div>
        <div class="economic-engine-container"
             *ngIf="showEconomicEngine">
            <ng-container [ngTemplateOutlet]="expandedZoneLocationTemplate"
                          [ngTemplateOutletContext]="{zone: Zone.EconomicEngine}"></ng-container>
        </div>
    </div>

    <div class="research-and-development-container"
         data-test="research-and-development"
         *ngIf="!showOnLeft && showResearchAndDevelopment">
        <ng-container [ngTemplateOutlet]="researchZoneNameTemplate"></ng-container>
        <div class="research-zone-content">
            <ng-container [ngTemplateOutlet]="expandedZoneLocationTemplate"
                          [ngTemplateOutletContext]="{zone: Zone.ResearchAndDevelopment}"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #expandedGlobalZoneContentTemplate>
    <div class="zone-content-container mx-0 my-1">
        <div class="zone-content">
            <ng-container [ngTemplateOutlet]="globalZoneLocationTemplate"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #researchZoneNameTemplate>
    <div class="research-zone-name-container">
        <div class="research-zone-name">
            <i class="py-2 mx-2 fa-fw"
               [ngClass]="[ZoneMetadata.Icon[Zone.ResearchAndDevelopment]]"></i>
            <span class="zone-text">{{ ZoneMetadata.Name[Zone.ResearchAndDevelopment] }}</span>
        </div>
    </div>
</ng-template>

import { ChangeDetectorRef, Directive, HostBinding, OnInit, Optional } from "@angular/core";
import { BlockingClickDirective } from "@common/ux/button/blocking-click.directive";
import { ButtonIconComponent } from "@common/ux/button/button-icon.component";
import { CallToActionInlineComponent } from "./call-to-action-inline.component";

@Directive({
    selector: "button[adaptCallToActionButton], a[adaptCallToActionButton]",
})
export class CallToActionButtonDirective implements OnInit {
    @HostBinding("class") public buttonClasses: string;

    public constructor(
        private changeDetector: ChangeDetectorRef,
        @Optional() ctaInlineComponent?: CallToActionInlineComponent,
        @Optional() buttonIconComponent?: ButtonIconComponent,
        @Optional() private blockingClickDirective?: BlockingClickDirective,
    ) {
        buttonIconComponent?.preventDefaultButtonClasses();
        this.buttonClasses = ctaInlineComponent
            ? "btn btn-link"
            : "btn btn-primary";
    }

    public ngOnInit() {
        if (this.blockingClickDirective) {
            this.blockingClickDirective.unsubscribeOnDestroy = false;
            this.changeDetector.markForCheck();
        }
    }
}

<div class="d-flex justify-content-center">
    <a href="https://theadaptway.com"
       aria-label="Go to adapt website">
        <img src="/content/shell/images/adaptplatformlogo.svg"
             alt="adapt logo"
             width="140" />
    </a>
</div>

<div *ngIf="!isMobileSize"
     class="d-flex my-4">
    <dx-tab-panel [items]="items"
                  itemTitleTemplate="itemTitleTemplate"
                  (onSelectionChanged)="selectionChanged($event)"
                  (onInitialized)="onInitialised($event)"
                  class="w-100">

        <div *dxTemplate="let item of 'itemTitleTemplate'">
            <h2>{{item.title}}</h2>
        </div>

        <div *dxTemplate="let item of 'termsConditionsTemplate'">
            <ng-container *ngTemplateOutlet="termsConditionsTemplate"></ng-container>
        </div>

        <div *dxTemplate="let item of 'privacyPolicyTemplate'">
            <ng-container *ngTemplateOutlet="privacyPolicyTemplate"></ng-container>
        </div>
    </dx-tab-panel>
</div>

<div *ngIf="isMobileSize">
    <h2>Terms and Conditions</h2>
    <ng-container *ngTemplateOutlet="termsConditionsTemplate"></ng-container>
    <h2>Privacy</h2>
    <ng-container *ngTemplateOutlet="privacyPolicyTemplate"></ng-container>
</div>

<ng-template #termsConditionsTemplate>
    <adapt-eula></adapt-eula>
</ng-template>

<ng-template #privacyPolicyTemplate>
    <!-- DYNAMIC ENZUZO FORM - SO SLOW & UGLY -->
    <!-- <adapt-loading-spinner *ngIf="privacyLoading"
                           [largeSpinner]="true"></adapt-loading-spinner>
    <iframe src="https://app.enzuzo.com/policies/privacy/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDdXN0b21lcklEIjoyMzEzNCwiQ3VzdG9tZXJOYW1lIjoiY3VzdC1QN3YyWmd3RSIsIkN1c3RvbWVyTG9nb1VSTCI6IiIsIlJvbGVzIjpbInJlZmVycmFsIl0sIlByb2R1Y3QiOiJlbnRlcnByaXNlIiwiVmVyc2lvbiI6MCwiaXNzIjoiRW56dXpvIEluYy4iLCJuYmYiOjE2OTY5ODg0OTB9.dzvyYBl3XvwNwTdr_adrP1jyWmITZVKB1Vs-cbAbw60"
            frameborder="0"
            class="w-100"
            [ngClass]="{'d-none' : privacyLoading}"
            style="min-height: 500px"
            (load)="privacyLoading = false"></iframe> -->
    <!--
        *** STATIC ENZUZO - COPIED FROM WEBSITE ***

        Changes made:
        * remove all styling
        * remove the `h1` in the main element - its in our dialog box, so why repeat it?
        * remove the `margin-top` in the main element
        * remove `open=""` as this causes the tag to be open by default
        * remove the `Powered by Enzuzo` as why are we advertising for them?
        * wrap the 'last updated' at the bottom with `<p>` and `mt-3 mb-0` classes
    -->
    <main ez-policy=""
          ez-policy-mode="privacy">
        <div>
        </div>
        <details ez-acc-details="">
            <summary ez-acc-title="">
                <span ez-m="LABEL_PRIVACY2_HTML_INTRODUCTION">1. Introduction</span>
                <div>
                </div>
            </summary>
            <p>At <span ez-m="LABEL_PRIVACY2_HTML_INTRODUCTION_BODY_2">
                    Adapt By Design Pty Ltd
                </span>
                <span ez-m="LABEL_PRIVACY2_HTML_INTRODUCTION_BODY_1">, d/b/a Adapt</span> (&quot;us&quot;, &quot;we&quot;, &quot;our&quot;
                or
                the &quot;Company&quot;) we value your privacy and the importance of safeguarding your data. This Privacy Policy (the
                &quot;Policy&quot;) describes our privacy practices for the activities set out below. As per your rights, we inform you how
                we
                collect, store, access, and otherwise process information relating to individuals. In this Policy, personal data
                (&quot;Personal
                Data&quot;) refers to any information that on its own, or in combination with other available information, can identify an
                individual.
            </p>
            <p>The office locations of where <span ez-m="LABEL_PRIVACY2_HTML_INTRODUCTION_LOCATION">Adapt By Design Pty Ltd</span> can be
                found
                <a ez-m="LABEL_PRIVACY2_HTML_INTRODUCTION_LOCATION_1"
                   style="text-decoration:underline"
                   href="https://theadaptway.com">theadaptway.com</a>
            </p>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_INTRODUCTION_REGULATION">We are committed to protecting your privacy in accordance with the
                    highest level of privacy regulation. As such, we follow the obligations under the below regulations:</span>
            </p>
            <ul>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_INTRODUCTION_PIPEDA">Canada&#x27;s Personal Information Protection and Electronic
                        Documents
                        Act (PIPEDA) and the applicable provincial legislations</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_INTRODUCTION_QUEBEC_LAW_25">Quebec Law 25</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_INTRODUCTION_GDPR">the EU&#x27;s General Data Protection Regulation (GDPR)</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_INTRODUCTION_LGPD">Brazil’s Data Protection Legislation (LGPD)</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_INTRODUCTION_CCPA">California&#x27;s Consumer Privacy Act (CCPA) / California Privacy
                        Rights
                        Act (CPRA) and California Online Privacy Protection Act (CalOPPA)</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_INTRODUCTION_CPA">Colorado Privacy Act (CPA)</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_INTRODUCTION_VCDPA">Utah Consumer Privacy Act (UCPA)</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_INTRODUCTION_CTDPA">Connecticut Data Privacy Act (CTDPA)</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_INTRODUCTION_UCPA">Virginia Consumer Data Protection Act (VCDPA)</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_INTRODUCTION_POPIA">South Africa’s Protection of Personal Information Act (POPIA)</span>
                </li>
            </ul>
            <h3>
                <span ez-m="LABEL_PRIVACY2_HTML_INTRODUCTION_SCOPE">Scope</span>
            </h3>
            <p>This policy applies to the <span ez-m="LABEL_PRIVACY2_HTML_INTRODUCTION_SCOPE_ENT_1">Adapt By Design Pty Ltd</span> websites,
                domains, applications, services, and products.</p>
            <p>This Policy does not apply to third-party applications, websites, products, services or platforms that may be accessed
                through
                (non-<span ez-m="LABEL_PRIVACY2_HTML_INTRODUCTION_SCOPE_2">Adapt By Design Pty Ltd</span>) links that we may provide to you.
                These sites are owned and operated independently from us, and they have their own separate privacy and data collection
                practices. Any Personal Data that you provide to these websites will be governed by the third-party’s own privacy policy. We
                cannot accept liability for the actions or policies of these independent sites, and we are not responsible for the content
                or
                privacy practices of such sites.</p>
            <h3>
                <span ez-m="LABEL_PRIVACY_POLICY_HTML_INTRODUCTION_PROCESSING">Processing Activities</span>
            </h3>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_INTRODUCTION_ACTIVITIES">This Policy applies when you interact with us by doing any of the
                    following:</span>
            </p>
            <ul>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_INTRODUCTION_ACTIVITIES_1">Make use of our application and services as an authorized
                        user</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_INTRODUCTION_ACTIVITIES_2">Visit any of our websites that link to this Privacy
                        Statement</span>
                </li>
            </ul>
            <p>
                <span ez-m="LABEL_BACKLINK_BY_ENZUZO">This <a href="https://www.enzuzo.com/privacy-policy-generator"
                       style="text-decoration:underline">privacy policy was generated</a> by our data privacy and compliance vendor, Enzuzo,
                    using information and details specific to our business.</span>
            </p>
        </details>
        <details ez-acc-details="">
            <summary ez-acc-title="">
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA">2. Personal Data We Collect</span>
                <div>
                </div>
            </summary>
            <h3>
                <div>
                </div>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_TITLE">What Personal Data We Collect</span>
            </h3>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_MAKE_PURCHASE">When you make a purchase, or attempt to make a purchase, we
                    collect
                    the following types of Personal Data:</span>
            </p>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_INCLUDES">This includes:</span>
            </p>
            <ul>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_ACCOUNT">Account Information such as your name, email address, and
                        password</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_PAYMENT">Payment Information such as your billing address, phone number,
                        credit card, debit card or other payment method</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_DEMOGRAPHIC">Demographic Data including age, gender</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_FINANCIAL">Financial Information including credit card numbers</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_PURCHASE">Purchase Information specifically if personalized or
                        unique</span>
                </li>
            </ul>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_MAKE_USE">When you use our products and/or features, we collect the following
                    types of Personal Data:</span>
            </p>
            <ul>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_ACCOUNT">Account Information such as your name, email address, and
                        password</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_PAYMENT">Payment Information such as your billing address, phone number,
                        credit card, debit card or other payment method</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_DEMOGRAPHIC">Demographic Data including age, gender</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_FINANCIAL_1">Financial Information including credit card numbers</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_PURCHASE_1">Purchase Information specifically if personalized or
                        unique</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_FEEDBACK_1">Feedback, such as customer support or product reviews</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_PRODUCT_1">Product information, such as serial number, product
                        registration,
                        licensing information</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_CONTENT_1">Content, such as posts, comments, audio, or documents</span>
                </li>
            </ul>
            <h3>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_HOW_WE_COLLECT">How We Collect Your Personal Data</span>
            </h3>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_HOW_WE_COLLECT_SOURCES">We collect Personal Data from the following
                    sources:</span>
            </p>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_HOW_WE_COLLECT_YOU">
                    <strong>From You.</strong> You may give us your Account Information, Payment Information, Financial Information,
                    Demographic
                    Data, Purchase Information, Content, Feedback, Product Information, by filling in forms, using our products or services,
                    entering information online or by corresponding with us by post, phone, email or otherwise. This includes Personal Data
                    you
                    provide, for example, when you:</span>
            </p>
            <ul>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_HOW_WE_COLLECT_ACCOUNT">Create an account or purchase products on our
                        website;</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_HOW_WE_COLLECT_USE_PRODUCT">Use our products or services;</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_HOW_WE_COLLECT_CREATE_CONTENT">Create content through our products or
                        services;</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_HOW_WE_COLLECT_EXPRESS_INTEREST">Express interest in our products or
                        services;</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_HOW_WE_COLLECT_DOWNLOAD_SOFTWARE">Downloading software and/or our mobile
                        application;</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_HOW_WE_COLLECT_SUBSCRIBE">Subscribe to our newsletter;</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_HOW_WE_COLLECT_SURVEY">Complete a voluntary market research survey;</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_HOW_WE_COLLECT_CONTACT">Contact us with an inquiry or to report a problem
                        (by
                        phone, email, social media, or messaging service);</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_HOW_WE_COLLECT_AUTH">When you log in to our website via social
                        media;</span>
                </li>
            </ul>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_AUTO_TECH">
                    <strong>Automated technologies or interactions:</strong> As you interact with our website, we may automatically collect
                    the
                    following types of data (all as described above): Device Data about your equipment, Usage Data about your browsing
                    actions
                    and patterns, and Contact Data where tasks carried out via our website remain uncompleted, such as incomplete orders or
                    abandoned baskets. We collect this data by using cookies, server logs and other similar technologies. Please see our
                    Cookie
                    section (below) for further details.</span>
            </p>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_THIRD_PARTIES">
                    <strong>Third parties:</strong> We may receive Personal Data about you from various third parties, including:</span>
            </p>
            <ul>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_THIRD_PARTIES_1">Account Information and Payment Information from another
                        individual when they purchase a gift for you on our website;</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_THIRD_PARTIES_2">Device and Usage Data from third parties, including
                        analytics
                        providers such as Google;</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_THIRD_PARTIES_3">Account Information and Payment Data from social media
                        platforms when you log in to our website using such social media platforms;</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_THIRD_PARTIES_4">Content from communication services, including email
                        providers and social networks, when you give us permission to access your data on such third-party services or
                        networks;</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_THIRD_PARTIES_5">Account Information and Payment Data from third parties,
                        including organizations (such as law enforcement agencies), associations and groups, who share data for the purposes
                        of
                        fraud prevention and detection and credit risk reduction; and</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_THIRD_PARTIES_6">Account Information, Payment Data, and Financial Data
                        from
                        providers of technical, payment and delivery services.</span>
                </li>
            </ul>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_THIRD_PARTIES_CONCLUSION">If you provide us, or our service providers, with
                    any
                    Personal Data relating to other individuals, you represent that you have the authority to do so and acknowledge that it
                    will
                    be used in accordance with this Policy. If you believe that your Personal Data has been provided to us improperly, or to
                    otherwise exercise your rights relating to your Personal Data, please contact us by using the information set out in the
                    &quot;Contact us&quot; section below.</span>
            </p>
            <h3>
                <div>
                </div>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_DATA">Device and Usage Data</span>
            </h3>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_DATA_INTRO_NON_MOBILE">When you visit a Adapt By Design Pty Ltd website,
                    we
                    automatically collect and store information about your visit using browser cookies (files which are sent by us to your
                    computer), or similar technology.</span>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_DATA_INTRO_CONCLUSION">You can instruct your browser to refuse all
                    cookies
                    or to indicate when a cookie is being sent. The Help Feature on most browsers will provide information on how to accept
                    cookies, disable cookies or to notify you when receiving a new cookie. If you do not accept cookies, you may not be able
                    to
                    use some features of our Service and we recommend that you leave them turned on.</span>
            </p>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_DATA_PROCESS_INFO">We also process information when you use our services
                    and
                    products. This information may include:</span>
            </p>
            <ul>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_DATA_PROCESS_INFO_DEVICE">Device IDs</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_DATA_PROCESS_INFO_LOGIN">Login information</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_DATA_PROCESS_INFO_IP">IP Address</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_DATA_PROCESS_INFO_TIME">Time stamps</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_DATA_PROCESS_INFO_AUTH">Authentication records</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_DATA_PROCESS_INFO_LOCATION">Location information</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_DATA_PROCESS_INFO_PRODUCTS_VIEW">Individual Products you view</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_DATA_PROCESS_INFO_WEB_TERMS">Web terms or searches that led you to
                        the
                        site</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_DATA_PROCESS_INFO_TZ">Time zone</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_DATA_PROCESS_INFO_OTHER">Other operational data.</span>
                </li>
            </ul>
            <h3>
                <div>
                </div>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_DATA_FROM_THIRD_PARTIES">Data we collect from third parties</span>
            </h3>
            <p>We may receive your Personal Data from third parties such as companies subscribing to <span
                      ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_DATA_FROM_THIRD_PARTIES_INTRO_1">Adapt By Design Pty Ltd</span>
                services,
                partners and other sources. This Personal Data is not collected by us but by a third party and is subject to the relevant
                third
                party’s own separate privacy and data collection policies. We do not have any control or input on how your Personal Data is
                handled by third parties. As always, you have the right to review and rectify this information. If you have any questions
                you
                should first contact the relevant third party for further information about your Personal Data. Where that third party is
                unresponsive to your rights, you may contact the Data Protection Officer at <span
                      ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_DATA_FROM_THIRD_PARTIES_INTRO_1">Adapt By Design Pty Ltd</span> (contact
                details below).</p>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_DATA_FROM_THIRD_PARTIES_INTRO_2">Our websites and services may contain
                    links
                    to other websites, applications and services maintained by third parties. The information practices of such other
                    services,
                    or of social media networks that host our branded social media pages, are governed by third parties’ privacy statements,
                    which you should review to better understand those third parties’ privacy practices.</span>
            </p>
            <h3>
                <div>
                </div>
                <span ez-m="LABEL_PRIVACY_POLICY_HTML_PERSONAL_DATA_WHY_COLLECT">Purpose and Legal Basis for the Processing of Personal
                    Data</span>
            </h3>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_PROCESSING_INTRO">We collect and use your Personal Data with your
                    consent to
                    provide, maintain, and develop our products and services and understand how to improve them.</span>
            </p>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_PROCESSING_INTRO_INCLUDE">These purposes include:</span>
            </p>
            <ul>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_PROCESSING_DELIVER_PRODUCT">To deliver your product or
                        service</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_PROCESSING_FULFILL_ORDERS">To fulfill orders including electronic
                        and
                        non-electronic shipment</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_PROCESSING_BUILD_SAFE">Building a Safe and Secure Environment</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_PROCESSING_AUTH">To verify or authenticate your identity; and</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_PROCESSING_SECURITY">Investigate and prevent security incidents such
                        as
                        breaches, attacks and hacks</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_PROCESSING_DEV">Providing, Developing, and Improving our Products
                        and
                        Services</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_PROCESSING_IMPROVE">Deliver, maintain, debug and improve our
                        products
                        and services.</span>
                </li>
                <li>Enable you to access <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_PROCESSING_ACCESS_SERVICES">Adapt By Design Pty
                        Ltd</span> services and set up accounts.</li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_PROCESSING_SUPPORT">Provide you with technical and customer
                        support</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_PROCESSING_ADS">Organize and Deliver Advertising and
                        Marketing</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_PROCESSING_MARKETTING">Send you newsletters and other marketing
                        communications about current and future products, programs and services, events, competitions, surveys and
                        promotions
                        held by us or hosted on our behalf; and</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_PROCESSING_EVENTS">Organize events or register attendees and
                        schedule
                        meetings for events.</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_PROCESSING_RND">For research and development</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_PROCESSING_COMMUNICATE">To communicate with you about the Products
                        and
                        Services</span>
                </li>
            </ul>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_PROCESSING_CONCLUSION">Where we process your Personal Data to provide a
                    product or service, we do so because it is necessary to perform contractual obligations. All of the above processing is
                    necessary in our legitimate interests to provide products and services and to maintain our relationship with you and to
                    protect our business for example against fraud. Consent will be required to initiate services with you. New consent will
                    be
                    required if any changes are made to the type of data collected. Within our contract, if you fail to provide consent,
                    some
                    services may not be available to you.</span>
            </p>
            <h3>
                <div>
                </div>
                <span ez-m="LABEL_PRIVACY_POLICY_HTML_TOOLS">Third Party Tools</span>
            </h3>
            <p>
                <span ez-m="LABEL_PRIVACY_POLICY_HTML_TOOLS_INTRO">We use these third party tools to store your information:</span>
            </p>
            <ul>
                <li>Pipedrive</li>
            </ul>
            <h3>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_INT_TRANSFER">International Data Transfer and Storage</span>
            </h3>
            <p>Where possible, we store and process data on servers within the general geographical region where you reside (note: this may
                not
                be within the country in which you reside). Your Personal Data may also be transferred to, and maintained on, servers
                residing
                outside of your state, province, country or other governmental jurisdiction where the data laws may differ from those in
                your
                jurisdiction. We will take appropriate steps to ensure that your Personal Data is treated securely and in accordance with
                this
                Policy as well as applicable data protection law.<span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_INT_TRANSFER_BODY_2">In
                    regions that are not considered adequate, we will enter into EU standard contractual clauses (or equivalent measures)
                    with
                    parties outside the EEA and ensure adequate controls are in place for the security of your data.</span> More information
                about these clauses can be found here: <a ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_INT_TRANSFER_BODY_3"
                   style="text-decoration:underline"
                   href="https://eur-lex.europa.eu/legal-content/en/TXT/?uri=CELEX%3A32021D0914">https://eur-lex.europa.eu/legal-content/en/TXT/?uri=CELEX%3A32021D0914</a>
            </p>
            <h3>
                <div>
                </div>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_SHARING">Sharing and Disclosure</span>
            </h3>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_SHARING_PERSONAL_DATA">We will share your Personal Data with third
                    parties
                    only in the ways set out in this Policy or set out at the point when the Personal Data is collected.</span>
            </p>
            <p>We also use Google Analytics to help us understand how our customers use the site. You can read more about how Google uses
                your
                Personal Data here: <a ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_SHARING_GA"
                   style="text-decoration:underline"
                   href="https://www.google.com/intl/en/policies/privacy/">https://www.google.com/intl/en/policies/privacy/</a>
            </p>
            <p>You can also opt-out of Google Analytics here: <a ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_SHARING_GA_OPTOUT"
                   style="text-decoration:underline"
                   href="https://tools.google.com/dlpage/gaoptout?hl=en">https://tools.google.com/dlpage/gaoptout?hl=en</a>
            </p>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_SHARING_REMARKETTING">We may also use your Personal Data to provide you
                    with
                    targeted marketing via advertisements or communications (such as newsletters).</span>
            </p>
            <p>For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s
                (&quot;NAI&quot;)
                educational page at <a ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_SHARING_REMARKETTING_MORE_INFO"
                   style="text-decoration:underline"
                   href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work">http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work</a>
            </p>
            <p>Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at: <a
                   ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_SHARING_REMARKETTING_OPTOUT"
                   style="text-decoration:underline"
                   href="http://optout.aboutads.info/">http://optout.aboutads.info/</a>
            </p>
            <h3>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_LEGAL_AGREEMENT">Legal Requirement</span>
            </h3>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_LEGAL_AGREEMENT_BODY">We may use or disclose your Personal Data in order
                    to
                    comply with a legal obligation, in connection with a request from a public or government authority, or in connection
                    with
                    court or tribunal proceedings, to prevent loss of life or injury, or to protect our rights or property. Where possible
                    and
                    practical to do so, we will tell you in advance of such disclosure.</span>
            </p>
            <h3>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_SERVICE_PROVIDERS">Service Providers and Other Third Parties</span>
            </h3>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_USAGE_SERVICE_PROVIDERS_BODY">We may use a third party service provider,
                    independent contractors, agencies, or consultants to deliver and help us improve our products and services. We may share
                    your Personal Data with marketing agencies, database service providers, backup and disaster recovery service providers,
                    email service providers and others but only to maintain and improve our products and services. For further information
                    on
                    the recipients of your Personal Data, please contact us by using the information in the &quot;Contacting us&quot;
                    section
                    below.</span>
            </p>
        </details>
        <details ez-acc-details="">
            <summary ez-acc-title="">
                <span ez-m="LABEL_PRIVACY2_HTML_COOKIES">3. Cookies</span>
                <div>
                </div>
            </summary>
            <h3>
                <span ez-m="LABEL_PRIVACY2_HTML_COOKIES_TITLE">What are Cookies?</span>
            </h3>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_COOKIES_BODY">A cookie is a small file with information that your browser stores on your
                    device.
                    Information in this file is typically shared with the owner of the site in addition to potential partners and third
                    parties
                    to that business. The collection of this information may be used in the function of the site and/or to improve your
                    experience.</span>
            </p>
            <h3>
                <span ez-m="LABEL_PRIVACY2_HTML_COOKIES_HOW">How we use cookies</span>
            </h3>
            <style>
                ul ul {
                    padding-bottom: 0px;
                }
            </style>
            <ul>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_COOKIES_BEST_EXP">To give you the best experience possible, we use the following types
                        of
                        cookies:</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_COOKIES_NECESSARY">Strictly Necessary. As a web application, we require certain
                        necessary
                        cookies to run our service.</span>
                </li>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_COOKIES_PREF">Preference.</span>
                </li>
                <ul>
                    <li>
                        <span ez-m="LABEL_PRIVACY2_HTML_COOKIES_PREF_BODY">We use preference cookies to help us remember the way you like to
                            use
                            our service.</span>
                    </li>
                    <li>
                        <span ez-m="LABEL_PRIVACY2_HTML_COOKIES_PERSONALIZED">Some cookies are used to personalize content and present you
                            with
                            a tailored experience. For example, location could be used to give you services and offers in your area.</span>
                    </li>
                </ul>
                <li>
                    <span ez-m="LABEL_PRIVACY2_HTML_COOKIES_ANALYTICS">Analytics. We collect analytics about the types of people who visit
                        our
                        site to improve our service and product.</span>
                </li>
            </ul>
        </details>
        <details ez-acc-details="">
            <summary ez-acc-title="">
                <span ez-m="LABEL_PRIVACY2_HTML_RENTENTION">4. Retention &amp; Deletion</span>
                <div>
                </div>
            </summary>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_RENTENTION_BODY">We will only retain your Personal Data for as long as necessary for the
                    purpose
                    for which that data was collected and to the extent required by applicable law. When we no longer need Personal Data, we
                    will remove it from our systems and/or take steps to anonymize it.</span>
            </p>
        </details>
        <details ez-acc-details="">
            <summary ez-acc-title="">
                <span ez-m="LABEL_PRIVACY2_HTML_MERGER">5. Merger or Acquisition</span>
                <div>
                </div>
            </summary>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_MERGER_BODY">If we are involved in a merger, acquisition or asset sale, your personal
                    information may be transferred. We will provide notice before your personal information is transferred and becomes
                    subject
                    to a different Privacy Policy. Under certain circumstances, we may be required to disclose your personal information if
                    required to do so by law or in response to valid requests by public authorities (e.g. a court or a government
                    agency).</span>
            </p>
        </details>
        <details ez-acc-details="">
            <summary ez-acc-title="">
                <span ez-m="LABEL_PRIVACY2_HTML_KEEP_SAFE">6. How We Keep Your Data Safe</span>
                <div>
                </div>
            </summary>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_KEEP_SAFE_BODY_1">We have appropriate organizational safeguards and security measures in
                    place
                    to protect your Personal Data from being accidentally lost, used or accessed in an unauthorized way, altered or
                    disclosed.</span>
            </p>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_KEEP_SAFE_BODY_2">The communication between your browser and our website uses a secure
                    encrypted
                    connection wherever your Personal Data is involved.</span>
            </p>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_KEEP_SAFE_BODY_3">We require any third party who is contracted to process your Personal Data
                    on
                    our behalf to have security measures in place to protect your data and to treat such data in accordance with the
                    law.</span>
            </p>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_KEEP_SAFE_BODY_4">In the unfortunate event of a Personal Data breach, we will notify you and
                    any
                    applicable regulator when we are legally required to do so.</span>
            </p>
        </details>
        <details ez-acc-details="">
            <summary ez-acc-title="">
                <span ez-m="LABEL_PRIVACY2_HTML_CHILDREN_PRIVACY">7. Children&#x27;s Privacy</span>
                <div>
                </div>
            </summary>
            <p>We do not knowingly collect Personal Data from children under the age of <span
                      ez-m="LABEL_PRIVACY2_HTML_CHILDREN_PRIVACY_BODY">18 Years</span>
            </p>
        </details>
        <details ez-acc-details="">
            <summary ez-acc-title="">
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_RIGHTS">8. Your Rights for Your Personal Data</span>
                <div>
                </div>
            </summary>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_RIGHTS_BODY_1">Depending on your geographical location and citizenship, your
                    rights are subject to local data privacy regulations. These rights may include:</span>
            </p>
            <ul>
                <li>
                    <h4 ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_RIGHTS_BODY_2">Right to Access (PIPEDA, GDPR Article 15, CCPA/CPRA, CPA,
                        VCDPA,
                        CTDPA, UCPA, LGPD, POPIA)</h4>You have the right to learn whether we are processing your Personal Data and to
                    request a
                    copy of the Personal Data we are processing about you.
                </li>
                <li>
                    <h4 ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_RIGHTS_BODY_3">Right to Rectification (PIPEDA, GDPR Article 16, CPRA, CPA,
                        VCDPA, CTDPA, LGPD, POPIA)</h4>You have the right to have incomplete or inaccurate Personal Data that we process
                    about
                    you rectified.
                </li>
                <li>
                    <h4 ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_RIGHTS_BODY_4">Right to be Forgotten (right to erasure) (GDPR Article 17,
                        CCPA/CPRA, CPA, VCDPA, CTDPA, UCPA, LGPD, POPIA)</h4>You have the right to request that we delete Personal Data that
                    we
                    process about you, unless we need to retain such data in order to comply with a legal obligation or to establish,
                    exercise
                    or defend legal claims.
                </li>
                <li>
                    <h4 ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_RIGHTS_BODY_5">Right to Restriction of Processing (GDPR Article 18, LGPD)
                    </h4>
                    You have the right to restrict our processing of your Personal Data under certain circumstances. In this case, we will
                    not
                    process your Data for any purpose other than storing it.
                </li>
                <li>
                    <h4 ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_RIGHTS_BODY_6">Right to Portability (PIPEDA, GDPR Article 20, LGPD)</h4>You
                    have
                    the right to obtain Personal Data we hold about you, in a structured, electronic format, and to transmit such Personal
                    Data
                    to another data controller, where this is (a) Personal Data which you have provided to us, and (b) if we are processing
                    that
                    data on the basis of your consent or to perform a contract with you or the third party that subscribes to services.
                </li>
                <li>
                    <h4 ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_RIGHTS_BODY_7">Right to Opt Out (CPRA, CPA, VCDPA, CTDPA, UCPA)</h4>You have
                    the
                    right to opt out of the processing of your Personal Data for purposes of: (1) Targeted advertising; (2) The sale of
                    Personal
                    Data; and/or (3) Profiling in furtherance of decisions that produce legal or similarly significant effects concerning
                    you.
                    <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_RIGHTS_BODY_8">Under CPRA, you have the right to opt out of the sharing of
                        your Personal Data to third parties and our use and disclosure of your Sensitive Personal Data to uses necessary to
                        provide the products and services reasonably expected by you.</span>
                </li>
                <li>
                    <h4 ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_RIGHTS_BODY_9">Right to Objection (GDPR Article 21, LGPD, POPIA)</h4>Where
                    the
                    legal justification for our processing of your Personal Data is our legitimate interest, you have the right to object to
                    such processing on grounds relating to your particular situation. We will abide by your request unless we have
                    compelling
                    legitimate grounds for processing which override your interests and rights, or if we need to continue to process the
                    Personal Data for the establishment, exercise or defense of a legal claim.
                </li>
                <li>
                    <h4 ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_RIGHTS_BODY_10">Nondiscrimination and nonretaliation (CCPA/CPRA, CPA, VCDPA,
                        CTDPA, UCPA)</h4>You have the right not to be denied service or have an altered experience for exercising your
                    rights.
                </li>
                <li>
                    <h4 ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_RIGHTS_BODY_APPEAL">File an Appeal (CPA, VCDPA, CTDPA)</h4>You have the
                    right to
                    file an appeal based on our response to you exercising any of these rights. In the event you disagree with how we
                    resolved
                    the appeal, you have the right to contact the attorney general located here:<br />
                    <p>
                    </p>
                    If you are based in Colorado, please visit this <a ez-m="LABEL_PRIVACY2_HTML_COMPLAINTS_BODY_EEA_COLLARODA"
                       style="text-decoration:underline"
                       href="https://complaints.coag.gov/s/contact-us">website</a> to file a complaint.<br />
                    If you are based in Virginia, please visit this <a ez-m="LABEL_PRIVACY2_HTML_COMPLAINTS_BODY_EEA_VIRGINIA"
                       style="text-decoration:underline"
                       href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint">website</a> to file a
                    complaint.<br />
                    If you are based in Connecticut, please visit this <a ez-m="LABEL_PRIVACY2_HTML_COMPLAINTS_BODY_EEA_CONNECTICUT"
                       style="text-decoration:underline"
                       href="https://portal.ct.gov/AG/Common/Complaint-Form-Landing-page">website</a> to file a complaint.
                </li>
                <li>
                    <h4 ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_RIGHTS_BODY_COMPLAINT">File a Complaint (GDPR Article 77, LGPD, POPIA)</h4>
                    You
                    have the right to bring a claim before their competent data protection authority.
                    <p>
                    </p>
                    If you are based in the EEA, please visit this website (<a ez-m="LABEL_PRIVACY2_HTML_COMPLAINTS_BODY_EEA"
                       style="text-decoration:underline"
                       href="https://edpb.europa.eu/about-edpb/about-edpb/members_en">https://edpb.europa.eu/about-edpb/about-edpb/members_en</a>)
                    for a list of local data protection authorities.
                </li>
            </ul>
            <h3>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_RIGHTS_WITHDRAW_CONSENT">Withdrawing Consent</span>
            </h3>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_RIGHTS_WITHDRAW_CONSENT_BODY">If you have consented to our processing of your
                    Personal Data, you have the right to withdraw your consent at any time, free of charge, such as where you wish to opt
                    out
                    from marketing messages that you receive from us. If you wish to withdraw your consent, please contact us using the
                    information found at the bottom of this page.</span>
            </p>
            <h3>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_RIGHTS_EXERCISE_RIGHT">How to Exercise Your Rights</span>
            </h3>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_RIGHTS_EXERCISE_RIGHT_BODY_1">You can make a request to exercise any of these
                    rights in relation to your Personal Data by sending the request to our privacy team by using the form below.</span>
            </p>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_PERSONAL_DATA_RIGHTS_EXERCISE_RIGHT_BODY_2">For your own privacy and security, at our
                    discretion, we may require you to prove your identity before providing the requested information.</span>
            </p>
        </details>
        <details ez-acc-details="">
            <summary ez-acc-title="">
                <span ez-m="LABEL_PRIVACY2_HTML_CHANGES">9. Changes</span>
                <div>
                </div>
            </summary>
            <p>
                <span ez-m="LABEL_PRIVACY2_HTML_CHANGES_BODY">We may modify this Policy at any time. If we make changes to this Policy then
                    we
                    will post an updated version of this Policy at this website. When using our services, you will be asked to review and
                    accept
                    our Privacy Policy. In this manner, we may record your acceptance and notify you of any future changes to this
                    Policy.</span>
            </p>
        </details>
        <details ez-acc-details="">
            <summary ez-acc-title="">
                <span ez-m="LABEL_PRIVACY2_HTML_CONTACT_US">10. Contact Us</span>
                <div>
                </div>
            </summary>
            <p>
                <span ez-m="LABEL_PRIVACY_POLICY_HTML_CONTACT_INTRO">To request a copy for your information, unsubscribe from our email
                    list,
                    request for your data to be deleted, or ask a question about your data privacy, we&#x27;ve made the process
                    simple:</span>
            </p>
            <a href="https://app.enzuzo.com/policies/dsar/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDdXN0b21lcklEIjoyMzEzNCwiQ3VzdG9tZXJOYW1lIjoiY3VzdC1QN3YyWmd3RSIsIkN1c3RvbWVyTG9nb1VSTCI6IiIsIlJvbGVzIjpbInJlZmVycmFsIl0sIlByb2R1Y3QiOiJlbnRlcnByaXNlIiwiVmVyc2lvbiI6MCwiaXNzIjoiRW56dXpvIEluYy4iLCJuYmYiOjE3Mjk0ODAzOTl9.0aYG2TdDSIAZbagV4d4TmSxY0yfgLuQsgTnxKwP11js?"
               style="display:inline-block;margin-bottom:1em">Make a Data Request</a>
            <p>
                <strong>
                    <span ez-m="LABEL_PRIVACY_POLICY_HTML_CONTACT_US_WRITE">Write to us at:</span>
                </strong>
            </p>
            <p>
                <span ez-m="LABEL_PRIVACY_POLICY_HTML_CONTACT_US_COMPANY">Data Privacy Officer of Adapt</span>
            </p>
            <p>4/448 Roberts Road, <span ez-m="PML_LABEL_PRIVACY_ADDRESS_P_0">
                    <span ez-m="ADDRESS_2_">Subiaco</span>
                </span>, <span ez-m="PML_LABEL_PRIVACY_ADDRESS_P_1">
                    <span ez-m="ADDRESS_3_">WA</span>
                </span>, <span ez-m="PML_LABEL_PRIVACY_ADDRESS_P_2">
                    <span ez-m="ADDRESS_4_">6008</span>
                </span>
            </p>
        </details>
        <p class="mt-3 mb-0">Last Updated: 10/24/2023</p>
    </main>
</ng-template>

import { Component, Inject, OnInit } from "@angular/core";
import { EventType, EventTypePreset } from "@common/ADAPT.Common.Model/organisation/event-type";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent, DialogResolveData } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { defer, EMPTY, lastValueFrom, Observable } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { CommonTeamsService } from "../../teams/common-teams.service";
import { ScheduleService } from "../schedule.service";

@Component({
    selector: "adapt-cadence-clear-dialog",
    templateUrl: "./cadence-clear-dialog.component.html",
})
export class CadenceClearDialogComponent extends BaseDialogComponent<EventTypePreset[], void> implements OnInit {
    public readonly dialogName = "CadenceClear";

    public affectedEventTypes: EventType[] = [];

    public checkboxConfirmation = false;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public eventTypes: EventTypePreset[],
        private scheduleService: ScheduleService,
        private teamsService: CommonTeamsService,
        private commonDataService: CommonDataService,
    ) {
        super(DialogResolveData.NotRequired);
    }

    public async ngOnInit() {
        const team = await this.teamsService.promiseToGetLeadershipTeam();
        const cadenceCycle = await lastValueFrom(this.scheduleService.getEventCadenceCycle());
        if (!team || !cadenceCycle) {
            return;
        }

        const currentEventSeries = await this.scheduleService.getCurrentEventSeriesMapForPresetsWithinTeam(this.eventTypes, team);
        this.affectedEventTypes = Array.from(currentEventSeries.values())
            .filter((eventSeries) => !eventSeries.extensions.isCompleted)
            .map(({ eventType }) => eventType);
    }

    @Autobind
    public saveAndClose(): Promise<unknown> | Observable<unknown> {
        let modifiedEntities: IBreezeEntity[] = [];
        return defer(() => this.scheduleService.promiseToClearCadence(this.eventTypes)).pipe(
            tap((entities) => modifiedEntities = entities),
            switchMap((entities) => this.commonDataService.saveEntities(entities)),
            tap(() => this.resolve()),
            catchError((e) => {
                for (const modifiedEntity of modifiedEntities) {
                    modifiedEntity.entityAspect.rejectChanges();
                }

                this.setErrorMessage(ErrorHandlingUtilities.getHttpResponseMessage(e));
                return EMPTY;
            }),
        );
    }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptImplementationKitModule } from "@common/implementation-kit/implementation-kit.module";
import { AdaptContextSidebarModule } from "@common/shell/context-sidebar/context-sidebar.module";
import { AdaptToolbarModule } from "@common/shell/toolbar/toolbar.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptCollapsibleModule } from "@common/ux/collapsible/collapsible.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptMenuModule } from "@common/ux/menu/menu.module";
import { AdaptSeeMoreModule } from "@common/ux/see-more/see-more.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { DxChartModule, DxDateBoxModule, DxNumberBoxModule, DxPopoverModule, DxScrollViewModule, DxSelectBoxModule, DxSortableModule, DxTextAreaModule, DxTextBoxModule } from "devextreme-angular";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { AdaptAuthorisationModule } from "../authorisation/authorisation.module";
import { AdaptCallToActionModule } from "../call-to-action/call-to-action.module";
import { AdaptStrategicInputsSharedModule } from "../strategic-inputs/strategic-inputs-shared/strategic-inputs-shared.module";
import { AdaptStrategyModule } from "../strategy/strategy.module";
import { AdaptStrategyBoardModule } from "../strategy-board/strategy-board.module";
import { WorkflowService } from "../workflow/workflow.service";
import { AdaptZoneMapModule } from "../zone-map/zone-map.module";
import { DisplayGoalDescriptionComponent } from "./display-goal-description/display-goal-description.component";
import { EditGoalLocationNameDescriptionComponent } from "./edit-goal-location-name-description/edit-goal-location-name-description.component";
import { EditGoalMeasurementsComponent } from "./edit-goal-measurements/edit-goal-measurements.component";
import { EditGoalReviewComponent } from "./edit-goal-review/edit-goal-review.component";
import { EditMeasurementScoreDialogComponent } from "./edit-measurement-score-dialog/edit-measurement-score-dialog.component";
import { EditStrategicGoalWorkflow } from "./edit-strategic-goal-workflow";
import { GoalMeasurementsGraphComponent } from "./goal-measurements-graph/goal-measurements-graph.component";
import { GoalStatusComponent } from "./goal-status/goal-status.component";
import { SelectGoalStatusComponent } from "./select-goal-status/select-goal-status.component";
import { StrategicGoalItemComponent } from "./strategic-goal-item/strategic-goal-item.component";
import { StrategicGoalZoneComponent } from "./strategic-goal-zone/strategic-goal-zone.component";
import { StrategicGoalsComponent } from "./strategic-goals/strategic-goals.component";
import { StrategicGoalsPageComponent, StrategyGoalsPageRoute } from "./strategic-goals-page/strategic-goals-page.component";


@NgModule({
    declarations: [
        StrategicGoalsPageComponent,
        StrategicGoalZoneComponent,
        StrategicGoalItemComponent,
        StrategicGoalsComponent,
        GoalMeasurementsGraphComponent,
        GoalStatusComponent,
        EditMeasurementScoreDialogComponent,
        SelectGoalStatusComponent,
        EditGoalLocationNameDescriptionComponent,
        EditGoalMeasurementsComponent,
        EditGoalReviewComponent,
        DisplayGoalDescriptionComponent,
    ],
    imports: [
        CommonModule,
        AdaptCallToActionModule,
        AdaptImplementationKitModule,
        AdaptHtmlEditorModule,
        AdaptTooltipModule,
        AdaptButtonModule,
        AdaptCommonDialogModule,
        AdaptLoadingSpinnerModule,
        AdaptEntityValidatorModule,
        AdaptStrategyModule,
        AdaptMenuModule,
        AdaptZoneMapModule,

        DxTextBoxModule,
        DxTextAreaModule,
        DxSelectBoxModule,
        DxNumberBoxModule,
        DxDateBoxModule,
        DxChartModule,
        DxScrollViewModule,
        DxPopoverModule,

        CollapseModule,
        AdaptAuthorisationModule,
        AdaptContextSidebarModule,
        AdaptStrategyBoardModule,
        AdaptToolbarModule,
        AdaptStrategicInputsSharedModule,
        DxSortableModule,
        AdaptSeeMoreModule,
        AdaptCollapsibleModule,
    ],
    exports: [
        StrategicGoalsComponent,
    ],
})
export class AdaptStrategicGoalsModule {
    public static readonly Routes = [
        ...StrategyGoalsPageRoute.routes,
    ];

    public readonly workflowStepComponents = [
        EditGoalLocationNameDescriptionComponent,
        EditGoalMeasurementsComponent,
        EditGoalReviewComponent,
    ];

    public constructor(workflowService: WorkflowService) {
        workflowService.addPersistentWorkflow(WorkflowService.CreateWorkflowPersistenceConfig(EditStrategicGoalWorkflow));
    }
}

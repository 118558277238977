import { Component, InjectionToken, Injector, OnInit, Provider } from "@angular/core";
import { Value } from "@common/ADAPT.Common.Model/organisation/value";
import { ValuesConstitution } from "@common/ADAPT.Common.Model/organisation/values-constitution";
import { ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { ChangeManagerService } from "@common/ux/change-manager/change-manager.service";
import { Observable, ObservableInput, ReplaySubject } from "rxjs";
import { switchMap, take, tap } from "rxjs/operators";
import { AuthorisationService } from "../../authorisation/authorisation.service";
import { ValuesConstitutionService } from "../values-constitution.service";
import { ValuesConstitutionAuthService } from "../values-constitution-auth.service";
import { ValuesConstitutionUiService } from "../values-constitution-ui.service";

export type EditValueAction = (injector: Injector, value: Value) => ObservableInput<any>;
export const EDIT_VALUE_ACTION = new InjectionToken<EditValueAction>("EDIT_VALUE_ACTION");

export function provideEditValueAction(action: EditValueAction): Provider {
    return {
        provide: EDIT_VALUE_ACTION,
        useValue: action,
        multi: false,
    };
}

@Component({
    selector: "adapt-values-constitution-page",
    templateUrl: "./values-constitution-page.component.html",
})
export class ValuesConstitutionPageComponent extends BaseRoutedComponent implements OnInit {
    public isEditing = false;

    public teamId$ = new ReplaySubject<number | undefined>(1);
    public valuesConstitution$ = new ReplaySubject<ValuesConstitution | undefined>(1);
    public canEditValuesConstitution$: Observable<boolean>;

    private article = this.isAlto ? ImplementationKitArticle.ValuesOverview : ImplementationKitArticle.ValuePurposeVision;
    public learnMoreUrl = ImplementationKitService.GetArticleLink(this.article);

    public constructor(
        injector: Injector,
        authService: AuthorisationService,
        changeManager: ChangeManagerService,
        private vcService: ValuesConstitutionService,
        private vcUiService: ValuesConstitutionUiService,
    ) {
        super(injector);
        changeManager.unblockOnQueryParamChanges(); // not going to trigger change manager check if query param changes

        this.teamId$.pipe(
            switchMap((teamId) => vcService.getValuesConstitution(teamId)),
            tap(() => this.notifyActivated()),
            this.takeUntilDestroyed(),
        ).subscribe(this.valuesConstitution$);

        this.teamId$.pipe(
            switchMap((teamId) => vcService.valuesConstitutionUpdate(teamId)),
            this.takeUntilDestroyed(),
        ).subscribe(this.valuesConstitution$);

        this.canEditValuesConstitution$ = this.teamId$.pipe(
            switchMap((teamId) => authService.promiseToGetHasAccess(ValuesConstitutionAuthService.EditValuesConstitution, { teamId })),
        );
    }

    public ngOnInit() {
        const teamId = this.getRouteParamInt("teamId");
        this.teamId$.next(teamId);
    }

    @Autobind
    public addValue() {
        return this.teamId$.pipe(
            take(1),
            switchMap((teamId) => this.vcService.getOrCreateValuesConstitution(teamId)),
            tap((vc) => this.valuesConstitution$.next(vc)),
            switchMap((vc) => this.vcService.createValue(vc)),
            switchMap((value) => this.vcUiService.editValue(value)),
        );
    }
}

<adapt-collapsible-search-row [result]="result"
                              [matches]="matches"
                              [href$]="href$"
                              [openInNewTab]="true">
    <div class="p-2">
        <h4>
            <a [href]="href$ | async"
               target="_blank">
                <adapt-highlighted-text [needle]="keyword"
                                        [haystack]="result.name"></adapt-highlighted-text>
            </a>
        </h4>

        <div class="text-muted">
            <h6>{{result.categories_display_for_views}}</h6>

            <em>"<adapt-highlighted-text [needle]="keyword"
                                     [haystack]="result.answer_sample"></adapt-highlighted-text>..."</em>
        </div>
    </div>
</adapt-collapsible-search-row>
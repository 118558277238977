<adapt-objectives-overview-summary [objectives]="objectives"
                                   class="mt-3 align-self-center"></adapt-objectives-overview-summary>

<ng-container *ngFor="let context of renderGroupContexts">
    <ng-container *ngTemplateOutlet="renderGroup; context: {$implicit: context}"></ng-container>
</ng-container>

<ng-template #renderGroup
             let-context>
    <ng-container *ngIf="context.filteredObjectives.length > 0">
        <h3 class="mt-4 d-flex align-items-center">{{ context.title }}
            <i class="ms-2"
               [ngClass]="context.icon"
               [attr.id]="context.handleId"></i>
            <!-- dxhover* events are better which will work for tablet too - pointerenter/end not working properly for tablet -->
            <dx-popover [target]="'#' + context.handleId"
                        [showEvent]="{name: 'dxhoverstart', delay: 500}"
                        hideEvent="dxhoverend"
                        [width]="400"
                        height="auto"
                        maxHeight="70vh">
                <div *dxTemplate="let data of 'content'"
                     class="p-2">
                    <div [froalaView]="context.iconTooltip"></div>
                </div>
            </dx-popover>
            <button class="ms-1"
                    [(adaptCollapsibleButton)]="context.collapsible"></button>
        </h3>
        <div [collapse]="context.collapsible"
             class="w-100">
            <adapt-objective-list-item *ngFor="let objective of context.filteredObjectives"
                                       [objective]="objective"
                                       [hideKeyResults]="true"
                                       [isExternalObjective]="isExternalObjective(objective)"
                                       [hideProgressBar]="true"
                                       [redirectObjectivesPageView]="ObjectiveViewType.ObjectiveOverview"></adapt-objective-list-item>
        </div>
    </ng-container>
</ng-template>

<div class="edit-item-comments-component">
    <div class="edit-item-comments-header">
        <div class="title">
            <label>
                Comments
            </label>
        </div>
    </div>
    <!-- existing comments -->
    <div class="comments-container"
         *adaptLoading="isLoading">
        <div role="alert"
             class="alert alert-danger"
             *ngIf="saveError">
            {{saveError}}
        </div>
        <div class="comment-chain">
            <adapt-comment-chain class="adapt-comment-chain-container comments-in-edit-item-dialog"
                                 [comments]="chainComments"
                                 [allowNewCommentSave]="item.itemId > 0 && allowEdit"
                                 [createComment$]="createComment$"
                                 (saving)="onSaving()"
                                 (saveFailed)="onSaveFailed($event)"
                                 (initialised)="initialised.emit()"></adapt-comment-chain>
        </div>
    </div>
</div>
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptMenuModule } from "@common/ux/menu/menu.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptCallToActionModule } from "@org-common/lib/call-to-action/call-to-action.module";
import { DisplayAgendaNameComponent } from "@org-common/lib/meetings/display-agenda-name/display-agenda-name.component";
import { DxAccordionModule, DxCheckBoxModule, DxDateBoxModule, DxListModule, DxPopoverModule, DxSchedulerModule, DxSelectBoxModule, DxTextBoxModule } from "devextreme-angular";
import { AdaptCalendarModule } from "../calendar/calendar.module";
import { AdaptDirectorySharedModule } from "../directory-shared/directory-shared.module";
import { AdaptKanbanSharedModule } from "../kanban/kanban-shared.module";
import { AgendaItemMetaDataCountComponent } from "./agenda-item-meta-data-count/agenda-item-meta-data-count.component";
import { DisplayMeetingActionComponent } from "./display-meeting-action/display-meeting-action.component";
import { DisplayMeetingNoteComponent } from "./display-meeting-note/display-meeting-note.component";
import { EditAgendaComponent } from "./edit-agenda/edit-agenda.component";
import { EditAgendaItemComponent } from "./edit-agenda-item/edit-agenda-item.component";
import { EditMeetingAgendaDialogComponent } from "./edit-meeting-agenda-dialog/edit-meeting-agenda-dialog.component";
import { MeetingIntegrationImportDialogComponent } from "./meeting-integration-import-dialog/meeting-integration-import-dialog.component";
import { MeetingIntegrationPromptComponent } from "./meeting-integration-prompt/meeting-integration-prompt.component";
import { MeetingSidebarAgendaComponent } from "./meeting-sidebar-agenda/meeting-sidebar-agenda.component";
import { MeetingSidebarAgendaItemComponent } from "./meeting-sidebar-agenda-item/meeting-sidebar-agenda-item.component";
import { ScheduleMeetingComponent } from "./schedule-meeting/schedule-meeting.component";
import { ScheduleMeetingDisplayAvailabilityComponent } from "./schedule-meeting-display-availability/schedule-meeting-display-availability.component";
import { SelectMeetingLocationComponent } from "./select-meeting-location/select-meeting-location.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AdaptButtonModule,
        AdaptCallToActionModule,
        AdaptCommonDialogModule,
        AdaptDateModule,
        AdaptDirectorySharedModule,
        AdaptEntityValidatorModule,
        AdaptHtmlEditorModule,
        AdaptKanbanSharedModule,
        AdaptLoadingSpinnerModule,
        AdaptMenuModule,
        AdaptTooltipModule,
        AdaptCalendarModule,

        DxAccordionModule,
        DxListModule,
        DxTextBoxModule,
        DxDateBoxModule,
        DxSelectBoxModule,
        DxCheckBoxModule,
        DxSchedulerModule,
        DxPopoverModule,
    ],
    declarations: [
        DisplayMeetingNoteComponent,
        DisplayMeetingActionComponent,
        MeetingSidebarAgendaComponent,
        MeetingSidebarAgendaItemComponent,
        EditMeetingAgendaDialogComponent,
        EditAgendaComponent,
        EditAgendaItemComponent,
        AgendaItemMetaDataCountComponent,
        ScheduleMeetingComponent,
        SelectMeetingLocationComponent,
        MeetingIntegrationPromptComponent,
        ScheduleMeetingDisplayAvailabilityComponent,
        MeetingIntegrationImportDialogComponent,
        DisplayAgendaNameComponent,
    ],
    exports: [
        DisplayMeetingNoteComponent,
        DisplayMeetingActionComponent,
        MeetingSidebarAgendaComponent,
        MeetingSidebarAgendaItemComponent,
        EditAgendaComponent,
        EditAgendaItemComponent,
        ScheduleMeetingComponent,
        MeetingIntegrationPromptComponent,
        SelectMeetingLocationComponent,
        MeetingIntegrationImportDialogComponent,
        DisplayAgendaNameComponent,
    ],
})
export class AdaptMeetingsSharedModule {}

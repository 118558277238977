import { Injectable } from "@angular/core";
import { NavigationHierarchyService } from "@common/route/navigation-hierarchy.service";
import { NavigationTab } from "@common/shell/common-sidebar/navigation-tab";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { Observable } from "rxjs";

@Injectable()
export abstract class OrgCommonNavigationTab extends NavigationTab {
    protected recheckForFocusedTab$: Observable<unknown>;

    public constructor(
        navigationHierarchyService: NavigationHierarchyService,
        organisationService: OrganisationService,
    ) {
        super(navigationHierarchyService);
        this.recheckForFocusedTab$ = organisationService.currentOrganisation$;
    }
}

import { Component, Input, ViewChild } from "@angular/core";
import { MeetingAgendaItem } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { MeetingAgendaTemplate } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-template";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxPopoverEvent } from "@common/ux/dx.types";
import { MeetingsService } from "@org-common/lib/meetings/meetings.service";
import { DxPopoverComponent } from "devextreme-angular";
import { defer, Observable } from "rxjs";

@Component({
    selector: "adapt-display-agenda-name",
    templateUrl: "./display-agenda-name.component.html",
    styleUrl: "./display-agenda-name.component.scss",
})
export class DisplayAgendaNameComponent extends BaseComponent {
    @Input() public meetingAgendaTemplate!: MeetingAgendaTemplate;

    public agendaItems$: Observable<MeetingAgendaItem[]>;

    public popoverVisible = false;
    public popoverCancelHiding = false;

    @ViewChild(DxPopoverComponent) private popoverComponent?: DxPopoverComponent;

    public constructor(
        meetingsService: MeetingsService,
    ) {
        super();

        this.agendaItems$ = defer(() => meetingsService.getAgendaItemsForMeetingAgendaTemplate(this.meetingAgendaTemplate.meetingAgendaTemplateId)).pipe(
            emptyIfUndefinedOrNull(),
            this.takeUntilDestroyed(),
        );
    }

    public get meetingAgendaTemplateIdAttr() {
        return `meetingAgendaTemplateId${this.meetingAgendaTemplate.meetingAgendaTemplateId}`;
    }

    // handling the case where description is too long than we need a scroll view
    // - has to prevent the popover from going away if pointer moved to the popover content
    //   and cancel hiding

    public preventHide() {
        this.popoverComponent?.instance.option("visible", true);
        this.popoverCancelHiding = true;
        this.popoverVisible = true;
    }

    public allowHide() {
        this.popoverCancelHiding = false;
        this.popoverVisible = false;
    }

    public onPopoverHiding(e: IDxPopoverEvent) {
        if (this.popoverCancelHiding) {
            e.cancel = true;
        }
    }
}

import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { Workflow } from "@common/ADAPT.Common.Model/embed/workflow";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { WorkflowConnection } from "@common/ADAPT.Common.Model/organisation/workflow-connection";
import { WorkflowStatus, WorkflowStatusEnum } from "@common/ADAPT.Common.Model/organisation/workflow-status";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { UserService } from "@common/user/user.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { filter, lastValueFrom, merge } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { AuthorisationService } from "../../authorisation/authorisation.service";
import { OrganisationOutcomes } from "../organisation-outcomes";
import { WorkflowActionableStates, WorkflowActionState } from "../workflow.interface";
import { WorkflowService } from "../workflow.service";
import { WorkflowAuthService } from "../workflow-auth.service";

@Component({
    selector: "adapt-workflow-card-action",
    templateUrl: "./workflow-card-action.component.html",
    styleUrls: ["./workflow-card-action.component.scss"],
})
export class WorkflowCardActionComponent extends BaseComponent implements OnChanges {
    public readonly WorkflowActionState = WorkflowActionState;

    @Input() public connection?: WorkflowConnection;
    @Input() public workflow?: Workflow;

    @Input() public disabled = false;
    @Input() public disabledTooltip?: string;

    // Action function to call when action is clicked.
    // Receives an event which allows you to call preventDefault on it to skip the default action (start/continue pathway)
    @Input() public action?: (event: CustomEvent) => Promise<any>;
    @Input() public actionText?: string;
    @Input() public actionIcon?: string;

    @Output() public workflowState = new EventEmitter<WorkflowActionState>();

    public loading = true;
    public state: WorkflowActionState = WorkflowActionState.NotStarted;
    public categoryMetadata = OrganisationOutcomes.Others;
    public tooltip?: string;
    public startWorkflowButtonText?: string;
    public startWorkflowButtonIcon?: string;

    private currentStatus?: WorkflowStatusEnum;

    public constructor(
        private workflowService: WorkflowService,
        authorisationService: AuthorisationService,
        rxjsBreezeService: RxjsBreezeService,
        userService: UserService,
    ) {
        super();

        merge(
            authorisationService.getHasAccess(WorkflowAuthService.EditWorkflow),
            rxjsBreezeService.entityTypeChanged(WorkflowConnection),
            rxjsBreezeService.entityTypeChanged(WorkflowStatus),
            // for role in organisation changes
            rxjsBreezeService.entityTypeChanged(Connection).pipe(
                filter((connection) => connection.personId === userService.getCurrentPersonId()),
            ),
        ).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updateData());
    }

    public get isLocked() {
        return !WorkflowActionableStates.includes(this.state);
    }

    public async ngOnChanges() {
        await this.updateData();
        this.loading = false;
    }

    public async onActionClicked() {
        if (this.isLocked) {
            return;
        }

        if (this.action) {
            const event = new CustomEvent("cardActionClicked", { cancelable: true });
            await this.action?.(event);
            if (event.defaultPrevented) {
                return;
            }
        }

        // do default click action here (start/continue pathway)
        this.startWorkflow();
    }

    private async updateData() {
        const connectionWorkflow = this.workflowService.getWorkflow(this.connection);
        if (!this.workflow) {
            this.workflow = connectionWorkflow;
        } else if (!this.connection) {
            this.connection = await lastValueFrom(this.workflowService.getLatestWorkflowConnectionForWorkflow(this.workflow));
        }

        if (!this.workflow) {
            return;
        }

        if (this.workflow.category) {
            this.categoryMetadata = OrganisationOutcomes[this.workflow.category];
        }

        const status = await lastValueFrom(this.workflowService.getLatestStatusForWorkflow(this.workflow));
        this.currentStatus = status?.status ?? WorkflowStatusEnum.Incomplete;

        this.state = await this.workflowService.getWorkflowState(this.connection, this.workflow, this.disabled);
        this.tooltip = this.workflowService.getWorkflowStateTooltip(this.state, this.workflow, this.disabledTooltip);
        this.startWorkflowButtonText = this.workflowService.getWorkflowStateButtonText(this.state, this.actionText);
        this.startWorkflowButtonIcon = this.workflowService.getWorkflowStateButtonIcon(this.state, this.actionIcon, this.currentStatus);

        this.workflowState.emit(this.state);
    }

    private startWorkflow() {
        // this will get the existing connection, or create a new one (if we're restarting)
        this.workflowService.createWorkflowConnectionForWorkflow(this.workflow!).pipe(
            tap(({ workflowConnection, workflowStatus }) => {
                this.connection = workflowConnection;
                this.currentStatus = workflowStatus?.status;
            }),
            switchMap(({ workflowConnection }) => this.workflowService.executeJourneyForConnection(workflowConnection!, true)),
        ).subscribe();
    }
}

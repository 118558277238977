import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Person } from "../person/person";
import { Item } from "./item";
import { Organisation } from "./organisation";
import { Team } from "./team";

export class Board extends BaseEntity<Board> {
    public static readonly MaxItemPrefixLength = 5; // this should match the server side max length for this field

    public boardId!: number;
    public organisationId!: number;
    public teamId?: number;
    public personId?: number;
    public name!: string;
    public itemPrefix!: string;
    public lastItemIndex!: number;
    public ordinal!: number;
    public isPublicReadAccess!: boolean;
    public isPublicWriteAccess!: boolean;
    public isArchived!: boolean;
    public items!: Item[];
    public organisation!: Organisation;
    public person!: Person;
    public team!: Team;

    public get isPersonalBoard() {
        return !!this.personId;
    }

    public get isTeamBoard() {
        return !!this.teamId;
    }
}

export const BoardBreezeModel = new BreezeModelBuilder("Board", Board)
    .hasSource()
    .isOrganisationEntity()
    .withIdField()
    .orderBy("ordinal")
    .withSortField("ordinal")
    .withLabelField("name")
    .build();

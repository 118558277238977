import { Injectable, Injector } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { PersonContact, PersonContactBreezeModel } from "@common/ADAPT.Common.Model/person/person-contact";
import { PersonProfileCategoryBreezeModel } from "@common/ADAPT.Common.Model/person/person-profile-category";
import { PersonProfileItemValue, PersonProfileItemValueBreezeModel } from "@common/ADAPT.Common.Model/person/person-profile-item-value";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { catchError, combineLatest, map, throwError } from "rxjs";
import { take } from "rxjs/operators";
import { AuthorisationService } from "../authorisation/authorisation.service";
import { DirectoryAuthService } from "../directory-shared/directory-auth.service";
import { BaseOrganisationService } from "../organisation/base-organisation.service";
import { EditPersonContactsDialogComponent } from "./edit-person-contacts-dialog/edit-person-contacts-dialog.component";

@Injectable({
    providedIn: "root",
})
export class PersonProfileService extends BaseOrganisationService {
    public constructor(
        injector: Injector,
        private authorisationService: AuthorisationService,
        private dialogService: AdaptCommonDialogService,
    ) {
        super(injector);
    }

    public canEditProfile$(person: Person) {
        return combineLatest([
            this.authorisationService.getHasAccess(DirectoryAuthService.EditProfileForPerson, person),
            this.authorisationService.getHasAccess(DirectoryAuthService.ManagePositionAndRoles, person),
        ]).pipe(
            map((accessChecks) => accessChecks.some((hasAccess) => hasAccess)),
        );
    }

    public canEditProfile(person: Person) {
        return this.canEditProfile$(person).pipe(take(1));
    }

    public getContacts(personId: number) {
        const predicate = new MethodologyPredicate<PersonContact>("personId", "==", personId);

        return this.commonDataService.getByPredicate(PersonContactBreezeModel, predicate);
    }

    public getProfileItemValues(personId: number) {
        const predicate = new MethodologyPredicate<PersonProfileItemValue>("personId", "==", personId);

        return this.commonDataService.getByPredicate(PersonProfileItemValueBreezeModel, predicate);
    }

    public getProfileCategories() {
        return this.commonDataService.getAll(PersonProfileCategoryBreezeModel).pipe(
            map((categories) => categories.sort((a, b) => a.ordinal - b.ordinal)),
        );
    }

    public createProfileItemValue(personProfileItemId: number, personId: number) {
        const defaults = {
            personProfileItemId,
            personId,
        };

        return this.commonDataService.create(PersonProfileItemValueBreezeModel, defaults);
    }

    public updateImageIdentifier(person: Person, imageIdentifier: string) {
        person.imageIdentifier = imageIdentifier;
        return this.commonDataService.saveEntities(person).pipe(
            catchError((e: any) => {
                person.entityAspect.rejectChanges();
                return throwError(() => e);
            }),
        );
    }


    public editContactsForPerson(person: Person) {
        return this.dialogService.open(EditPersonContactsDialogComponent, person);
    }
}

<adapt-dialog [maxWidth]="900"
              [padding]="false"
              [allowMouseWheelContentScroll]="false">
    <h3 adapt-dialog-title>{{title}}</h3>

    <div adapt-dialog-content
         class="h-100">
        <adapt-user-access-summary-grid *ngIf="data"
                                        [person]="data.person"
                                        [connection]="data.connection">
        </adapt-user-access-summary-grid>
    </div>

    <div adapt-dialog-footer
         class="ms-auto">
        <button adaptButton="close"
                (click)="cancel()"></button>
    </div>
</adapt-dialog>

<div *ngIf="decisionCount > 0 || minuteCount > 0 || itemCount > 0"
     class="d-inline-flex align-items-center">
    <ng-container *ngIf="showTotalBadgeOnly; else showBreakdownCounts">
        <span class="badge bg-primary agenda-counter"
              [adaptTooltip]="totalBadgeTooltip">{{decisionCount + minuteCount + itemCount}}</span>
    </ng-container>
    <ng-template #showBreakdownCounts>
        <div *ngIf="decisionCount > 0"
             class="d-inline-flex align-items-center"
             adaptTooltip="Number of decisions">
            <i [ngClass]="MeetingNoteIconClass[MeetingNoteType.Decision]"></i>
            <span class="me-1">{{decisionCount}}</span>
        </div>
        <div *ngIf="minuteCount > 0"
             class="d-inline-flex align-items-center"
             adaptTooltip="Number of minutes">
            <i [ngClass]="MeetingNoteIconClass[MeetingNoteType.Minute]"></i>
            <span class="me-1">{{minuteCount}}</span>
        </div>
        <div *ngIf="itemCount > 0"
             class="d-inline-flex align-items-center"
             adaptTooltip="Number of actions">
            <i [ngClass]="MeetingItemIconClass"></i>
            <span class="me-1">{{itemCount}}</span>
        </div>
    </ng-template>
</div>

import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { BehaviorSubject, isObservable, Subject, Subscription } from "rxjs";
import { debounceTime, takeUntil, tap } from "rxjs/operators";

@Directive({
    selector: "[adaptRerender]",
})
export class RerenderDirective implements OnInit, OnDestroy {
    public static ngTemplateGuard_adaptRerender: "binding";

    private watch$ = new BehaviorSubject<void>(void 0);
    private destroyed$ = new Subject<void>();

    private sub?: Subscription;

    @Input() public adaptRerenderDelay = 0;

    // if detects changes of the input value, clear and rerender the view
    @Input()
    public set adaptRerender(value: any) {
        if (isObservable(value)) {
            this.sub?.unsubscribe();
            this.sub = value.subscribe(this.watch$);
        } else {
            this.watch$.next();
        }
    }

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
    ) {}

    public ngOnInit() {
        this.watch$.pipe(
            tap(() => this.viewContainer.clear()),
            debounceTime(this.adaptRerenderDelay),
            takeUntil(this.destroyed$),
        ).subscribe(() => {
            this.viewContainer.createEmbeddedView(this.templateRef);
        });
    }

    public ngOnDestroy() {
        this.sub?.unsubscribe();
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}

import { Component, Inject, Input, OnChanges } from "@angular/core";
import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { cacheLatest } from "@common/lib/utilities/rxjs-utilities";
import { ORGANISATION_DASHBOARD_PAGE, PERSONAL_DASHBOARD_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";

export interface IOrganisationCardData {
    organisation: Organisation;
    connectionTypes: ConnectionType[];
}

const connectionTypeToBadgeClass: { [type in ConnectionType]: string } = {
    [ConnectionType.Employee]: "badge-employee",
    [ConnectionType.Stakeholder]: "badge-stakeholder",
    [ConnectionType.Coach]: "badge-coach",
};

@Component({
    selector: "adapt-organisation-card",
    templateUrl: "./organisation-card.component.html",
    styleUrls: ["./organisation-card.component.scss"],
})
export class OrganisationCardComponent implements OnChanges {
    @Input() public cardData?: IOrganisationCardData;

    public connectionTypeToBadgeClass = connectionTypeToBadgeClass;
    public isCurrentOrganisation$: Observable<boolean>;
    public organisationUrl$: Observable<string | null>;
    private cardData$ = new BehaviorSubject<IOrganisationCardData | undefined>(undefined);

    public constructor(
        organisationService: OrganisationService,
        @Inject(ORGANISATION_DASHBOARD_PAGE) private organisationDashboardPageRoute: IAdaptRoute<{}>,
        @Inject(PERSONAL_DASHBOARD_PAGE) private personalDashboardPageRoute: IAdaptRoute<{}>,
    ) {
        this.isCurrentOrganisation$ = combineLatest([
            organisationService.currentOrganisation$,
            this.cardData$,
        ]).pipe(
            map(([currOrg, cardData]) => currOrg?.organisationId === cardData?.organisation.organisationId),
            cacheLatest(),
        );
        this.organisationUrl$ = combineLatest([
            this.cardData$,
            this.isCurrentOrganisation$,
        ]).pipe(
            switchMap(([cardData, isCurrentOrg]) => {
                if (!cardData || isCurrentOrg) {
                    return of(null);
                }

                return this.getOrganisationHref(cardData);
            }),
        );
    }

    public ngOnChanges() {
        this.cardData$.next(this.cardData);
    }

    @Autobind
    private getOrganisationHref(cardData: IOrganisationCardData) {
        const route = AdaptClientConfiguration.AdaptProject === AdaptProject.Alto
            ? this.personalDashboardPageRoute
            : this.organisationDashboardPageRoute;
        return route.getRoute({
            organisationUrlIdentifier: cardData.organisation.urlIdentifier,
        });
    }
}

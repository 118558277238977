import { Component, ElementRef, Injector, OnInit } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { PersonImageComponent } from "@common/user/person-image/person-image.component";
import { UserService } from "@common/user/user.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { EditSimpleValueBreezeEntityDialogComponent, IEditSimpleValueBreezeEntityDialogData, SimpleValueType } from "@common/ux/edit-simple-value-breeze-entity-dialog/edit-simple-value-breeze-entity-dialog.component";
import { DirectoryAuthService } from "@org-common/lib/directory-shared/directory-auth.service";
import { ErrorPageRoute } from "@org-common/lib/error-page/error-page.component";
import { OrganisationAuthService } from "@org-common/lib/organisation/organisation-auth.service";
import { PersonService } from "@org-common/lib/person/person.service";
import { ChangePasswordDialogComponent } from "@org-common/lib/person-profile/change-password-dialog/change-password-dialog.component";
import { PersonProfileService } from "@org-common/lib/person-profile/person-profile.service";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { Observable, ReplaySubject, startWith, switchMap, take, tap } from "rxjs";
import { ProfilePageEditTour } from "./profile-page-edit-tour";

const TourSearchParam = "tour";

@Component({
    selector: "adapt-profile-page",
    templateUrl: "./profile-page.component.html",
    styleUrls: ["./profile-page.component.scss"],
})
export class ProfilePageComponent extends BaseRoutedComponent implements OnInit {
    public readonly ChangePasswordForPerson = OrganisationAuthService.ChangePasswordForPerson;
    public readonly ManagePositionAndRoles = DirectoryAuthService.ManagePositionAndRoles;

    public personId$ = new ReplaySubject<number | undefined>(1);
    public person$: Observable<Person | undefined>;
    public isEditing = false;
    public defaultImageSrc = PersonImageComponent.DefaultProfileImageUrl;
    public canEditSomethingOnPage$: Observable<boolean>;

    public constructor(
        injector: Injector,
        elementRef: ElementRef,
        userService: UserService,
        personService: PersonService,
        private dialogService: AdaptCommonDialogService,
        private personProfileService: PersonProfileService,
    ) {
        super(injector, elementRef);

        this.person$ = this.personId$.pipe(
            switchMap((personId) => personId ? personService.getPerson(personId)
                : userService.currentPerson$),
            tap((person) => {
                if (!person) {
                    this.routeService.gotoHome();
                } else if (person.isStakeholderManager && userService.getCurrentPersonId() !== person.personId) {
                    ErrorPageRoute.gotoRoute().subscribe();
                }
            }),
        );

        this.canEditSomethingOnPage$ = this.person$.pipe(
            emptyIfUndefinedOrNull(),
            switchMap((person) => this.personProfileService.canEditProfile$(person)),
        );
    }

    public ngOnInit() {
        this.navigationEnd.pipe(
            startWith(undefined), // to get initial update when component first loaded
        ).subscribe(() => this.updateDataFromParam());
    }

    private updateDataFromParam() {
        const personId = this.getRouteParamInt("personId");
        this.personId$.next(personId);

        this.notifyActivated();

        const runTour = this.getSearchParameterBoolValue(TourSearchParam);
        if (runTour) {
            this.runTour();
            this.deleteSearchParameter(TourSearchParam);
        }
    }

    public runTour() {
        this.guidedTourService.run(ProfilePageEditTour);
    }

    public editName() {
        return this.person$.pipe(
            emptyIfUndefinedOrNull(),
            take(1),
            switchMap((person) => {
                const dlg: IEditSimpleValueBreezeEntityDialogData = {
                    title: "Edit Name",
                    entities: [{
                        label: "First Name",
                        entity: person,
                        fieldName: "firstName",
                        type: SimpleValueType.Text,
                    }, {
                        label: "Surname",
                        entity: person,
                        fieldName: "lastName",
                        type: SimpleValueType.Text,
                    }],
                    saveOnClose: true,
                };

                return this.dialogService.open(EditSimpleValueBreezeEntityDialogComponent, dlg);
            }),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public updateImageIdentifier(person: Person, imageIdentifier: string) {
        this.personProfileService.updateImageIdentifier(person, imageIdentifier).subscribe();
    }

    public async changePassword(person: Person) {
        return this.dialogService.open(ChangePasswordDialogComponent, person).subscribe();
    }
}

export const myProfilePageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-profile-page", ProfilePageComponent)
    .atOrganisationUrl("/person/profile")
    .withTitle("My Profile")
    .build();

export const personProfilePageRoute = new OrganisationPageRouteBuilder<{ personId: number }>()
    .usingNgComponent("adapt-profile-page", ProfilePageComponent)
    .atOrganisationUrl("/person/profile/:personId")
    .reloadOnSearch(false)
    .withTitle("Profile")
    .verifyingAccess("readAtLeastOneProfile")
    .build();

<div *ngIf="labelLocations?.length; else displayLabels"
     class="d-flex flex-wrap">

    <!-- Margin top negative to offset for the mt-2 for the display label which is required when the labels wrap -->
    <adapt-display-label *ngFor="let labelLocation of labelLocations"
                         class="me-1"
                         [label]="labelLocation.label"
                         [adaptLinkDisabled]="linkDisabled"></adapt-display-label>
</div>
<ng-template #displayLabels>
    <div *ngIf="labels?.length"
         class="d-flex flex-wrap">
        <adapt-display-label *ngFor="let label of labels"
                             class="me-1"
                             [label]="label"
                             [adaptLinkDisabled]="linkDisabled"
                             data-test="labels-display"></adapt-display-label>
    </div>
</ng-template>

import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { SystemComponent } from "./system-component";

export class SystemComponentSupplementaryData extends BaseEntity<SystemComponentSupplementaryData> {
    public systemComponentId!: number;
    public description!: string;

    // navigation
    public systemComponent!: SystemComponent;
}

export const SystemComponentSupplementaryDataBreezeModel = new BreezeModelBuilder("SystemComponentSupplementaryData", SystemComponentSupplementaryData)
    .hasSource()
    .withIdField("systemComponentId")
    .isOrganisationEntity()
    .persistChangedEntity()
    .build();


<adapt-dialog [maxWidth]="800"
              [minHeight]="700"
              [fitHeight]="true">
    <h3 adapt-dialog-title>{{data.linkToMeeting ? 'Link' : 'Import'}} calendar event from Outlook</h3>

    <div adapt-dialog-content
         class="d-flex flex-column gap-3 h-100">
        <!-- stays at the top when scrolling the calendar down -->
        <div class="position-sticky top-0 z-3 bg-white">
            <h3>Selected calendar event to {{data.linkToMeeting ? 'link' : 'import'}}</h3>

            @if (selectedEvent) {
                <div class="alert alert-secondary p-2 mb-0">
                    <ng-container [ngTemplateOutlet]="appointment"
                                  [ngTemplateOutletContext]="{ event: selectedEvent }"></ng-container>
                </div>
            } @else {
                No event selected. Click an event to select it.
            }
        </div>

        <dx-scheduler [dataSource]="dataSource"
                      [editing]="false"
                      startDateExpr="start.dateTime"
                      endDateExpr="end.dateTime"
                      allDayExp="isAllDay"
                      textExpr="subject"
                      [showAllDayPanel]="false"
                      [views]="['day', 'workWeek', 'month', 'agenda']"
                      [startDayHour]="6"
                      [endDayHour]="20"
                      [firstDayOfWeek]="1"
                      currentView="workWeek"
                      [showCurrentTimeIndicator]="true"
                      [shadeUntilCurrentTime]="true"
                      appointmentTooltipTemplate="tooltip-template"
                      (onCellClick)="selectedEvent = undefined"
                      (onAppointmentClick)="onSelectionChanged($event)"
                      (onAppointmentFormOpening)="onAppointmentFormOpening($event)"
                      class="flex-grow-1">
            <div *dxTemplate="let model of 'tooltip-template'"
                 class="text-start text-white">
                <ng-container [ngTemplateOutlet]="appointment"
                              [ngTemplateOutletContext]="{ event: model.appointmentData }"></ng-container>
            </div>
        </dx-scheduler>
    </div>

    <ng-template #appointment
                 let-event="event">
        <div class="d-flex flex-column gap-1">
            <div class="dx-scheduler-appointment-title fw-bold flex-grow-1 w-100">
                {{event.subject}}
                <a [href]="event.webLink"
                   target="_blank"
                   class="ms-1"><i class="fal fa-external-link"
                                   adaptTooltip="Open in Outlook"></i></a>
            </div>

            @if (event.start) {
                <div>
                    <i class="fal fa-fw fa-clock me-1"></i>
                    <span>{{event.start.dateTime | adaptDate}}</span>
                    <span class="mx-2">/</span>
                    <span>{{event.start.dateTime | adaptTime}}</span>
                    @if (event.end) {
                        <span class="mx-1">-</span>
                        <span>{{event.end.dateTime | adaptTime}}</span>
                    }
                </div>
            }

            @if (event.location?.displayName) {
                <div>
                    <i class="fal fa-fw fa-location-dot me-1"></i>{{event.location.displayName}}
                </div>
            }

            @if (eventPeople.get(event.id!); as people) {
                @if (people.length) {
                    <div class="d-flex align-items-center">
                        <i class="fal fa-fw fa-users me-1"></i>
                        <div class="d-inline-flex column-gap-2 d-inline-flex flex-wrap row-gap-1">
                            @for (person of people; track person.personId) {
                                <adapt-link-person [person]="person"
                                                   [showImage]="true"
                                                   [linkDisabled]="true"
                                                   imageSize="xs"></adapt-link-person>
                            }
                        </div>
                    </div>
                }
            }

            @if (event.disabled) {
                <div class="small">This meeting is already in {{AdaptProjectLabel}}.</div>
            }
        </div>
    </ng-template>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveButtonPreset]="data.linkToMeeting ? 'linkAttach' : 'import'"
                                   [saveIsDisabled]="!selectedEvent || selectedEvent.disabled"
                                   [saveBlockingClick]="importMeeting"
                                   (cancelClick)="cancel()"></adapt-standard-dialog-buttons>
</adapt-dialog>

import { EventSeriesType } from "@common/ADAPT.Common.Model/organisation/event-series";
import { EventSeriesDayOfWeekMetadata } from "@common/ADAPT.Common.Model/organisation/event-series-day-of-week";
import { EventSeriesWeekIndex, EventSeriesWeekIndexMetadata } from "@common/ADAPT.Common.Model/organisation/event-series-week-index";
import { EventTypePreset } from "@common/ADAPT.Common.Model/organisation/event-type";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { DateUtilities } from "@common/lib/utilities/date-utilities";
import moment from "moment";
import { IScheduleRecurrenceConfig, IScheduleRecurrenceField } from "./schedule-recurrence/schedule-recurrence.interface";

const dateField: IScheduleRecurrenceField = {
    label: "date",
    eventSeriesField: "startDate",
    width: 80,
};

const weekIndexField: IScheduleRecurrenceField = {
    label: "week",
    eventSeriesField: "weekIndex",
    width: 80,
    getChoices: () => EventSeriesWeekIndexMetadata.All.map((weekIndex) => ({
        text: weekIndex.name,
        value: weekIndex.weekIndex,
    })),
};

const dayOfWeekField: IScheduleRecurrenceField = {
    label: "day",
    eventSeriesField: "dayOfWeek",
    getChoices: () => EventSeriesDayOfWeekMetadata.All.map((dayOfWeek) => ({
        text: dayOfWeek.name,
        value: dayOfWeek.dayOfWeek,
    })),
};

const monthField: IScheduleRecurrenceField = {
    label: "month",
    eventSeriesField: "month",
    prefix: "of",
    fieldDisabled: (eventSeries) => {
        if (eventSeries.extensions.hasRunAtLeastOneMeeting) {
            return {
                disabled: true,
                disabledTooltip: "You have already run a meeting in this cadence. You cannot update the starting month anymore.",
            };
        }
        return undefined;
    },
    getChoices: (eventSeries, eventCadenceCycle) => {
        let cadenceStart = moment().startOf("month").toDate();

        if (eventSeries.eventType.code !== EventTypePreset.OKRCheckIn) {
            // all the other events cannot really be in the current month
            cadenceStart = moment(cadenceStart).add(1, "month").toDate();
        }

        // disable months that are outside the cadence cycle
        const cadenceEnd = eventCadenceCycle?.extensions.getEndDate(cadenceStart);
        const months = cadenceEnd
            ? DateUtilities.getMonthsInRange(cadenceStart, cadenceEnd)
            : undefined;

        return moment.months().map((month, idx) => ({
            text: month,
            value: idx + 1,
            disabled: months && !months.includes(idx),
        }));
    },
    afterFieldUpdated: (obj) => {
        // update the month of the startDate to be the newly selected month
        if (obj.month) {
            let startDate = moment(obj.startDate)
                .month(obj.month - 1);
            const today = moment()
                .startOf("day");

            // if the start date ends up being before today, we should just update the start date to start at today
            if (startDate.isBefore(today)) {
                startDate = moment(DateUtilities.replaceDate(startDate.toDate(), today.toDate()));
            }
            
            obj.startDate = startDate.toDate();
        }
    },
};

export const EventSeriesDefaults: { [eventType in EventTypePreset]: IScheduleRecurrenceConfig } = {
    [EventTypePreset.SetFirstObjectives]: {
        eventSeriesDefaults: {
            eventSeriesType: EventSeriesType.Once,
            interval: 1,
        },
        fields: [dateField],
        guidance: ImplementationKitArticle.EventTypeGuidanceSetFirstObjectives,
    },
    [EventTypePreset.AnnualStrategy]: {
        eventSeriesDefaults: {
            eventSeriesType: EventSeriesType.Once,
            interval: 1,
        },
        fields: [dateField],
        guidance: ImplementationKitArticle.EventTypeGuidanceAnnualStrategy,
        getStartDate: (_presetRecurrences, cadenceCycle) => {
            // about a week before the cycle ends
            const approxBeforeCycleEnd = moment(cadenceCycle.extensions.nextCycleStartDate)
                .add(-1, "week")
                .toDate();
            return moment(DateUtilities.getFirstWorkingDay(approxBeforeCycleEnd.getFullYear(), approxBeforeCycleEnd.getMonth(), approxBeforeCycleEnd.getDate()))
                .hour(9)
                .minute(0)
                .toDate();
        },
    },
    [EventTypePreset.QuarterlyStrategy]: {
        eventSeriesDefaults: {
            eventSeriesType: EventSeriesType.RelativeMonthly,
            interval: 3,
        },
        fields: [
            { ...weekIndexField, prefix: "3 months on the" },
            dayOfWeekField,
            { ...monthField, prefix: "starting" },
        ],
        guidance: ImplementationKitArticle.EventTypeGuidanceQuarterlyStrategy,
        conflictingEventTypes: [EventTypePreset.AnnualStrategy],
        conflictingEventTypeCheck: (proposedDate, potentialConflicts) => {
            return potentialConflicts.some(({ meetingDateTime }) => {
                // allow the QS if it is at least 2 months before the AS.
                const weeksBeforeProposedDate = moment(meetingDateTime).subtract(2, "month");
                return moment(proposedDate).isAfter(weeksBeforeProposedDate);
            });
        },
        getStartDate: (presetRecurrences) => {
            const setFirstObjectives = presetRecurrences.get(EventTypePreset.SetFirstObjectives);
            return moment(setFirstObjectives?.eventSeries.startDate ?? new Date())
                .startOf("month") // start of the month
                .add(3, "months") // 3 months after AS
                .hour(9)
                .minute(0)
                .toDate();
        },
    },
    [EventTypePreset.MonthlyStrategy]: {
        eventSeriesDefaults: {
            eventSeriesType: EventSeriesType.RelativeMonthly,
            interval: 1,
        },
        fields: [
            weekIndexField,
            { ...dayOfWeekField, suffix: "of every month" },
            { ...monthField, prefix: " starting" },
        ],
        guidance: ImplementationKitArticle.EventTypeGuidanceMonthlyStrategy,
        conflictingEventTypes: [EventTypePreset.SetFirstObjectives, EventTypePreset.AnnualStrategy, EventTypePreset.QuarterlyStrategy],
        conflictingEventTypeCheck: (proposedDate, potentialConflicts) => {
            return potentialConflicts.some(({ meetingDateTime, eventSeries }) => {
                if (eventSeries?.eventType.code === EventTypePreset.AnnualStrategy) {
                    // allow the MS if it is at least 2 weeks before the AS.
                    const weeksBeforeProposedDate = moment(meetingDateTime).subtract(14, "days");
                    return moment(proposedDate).isAfter(weeksBeforeProposedDate);
                } else if (eventSeries?.eventType.code === EventTypePreset.SetFirstObjectives) {
                    // allow the MS if it is at least 21 days after the SFO.
                    const daysDiff = moment(proposedDate).diff(meetingDateTime, "days", true);
                    return Math.abs(daysDiff) <= 21;
                } else if (eventSeries?.eventType.code === EventTypePreset.QuarterlyStrategy) {
                    // allow the MS if it is at least 14 days before/after a QS.
                    const daysDiff = moment(proposedDate).diff(meetingDateTime, "days", true);
                    return Math.abs(daysDiff) <= 14;
                }

                return moment(meetingDateTime).isSame(proposedDate, "month");
            });
        },
        getStartDate: (presetRecurrences) => {
            const setFirstObjectives = presetRecurrences.get(EventTypePreset.SetFirstObjectives);
            return moment(setFirstObjectives?.eventSeries.startDate ?? new Date())
                .startOf("month")
                .add(1, "month")
                .hour(9)
                .minute(0)
                .toDate();
        },
    },
    [EventTypePreset.OKRCheckIn]: {
        eventSeriesDefaults: {
            eventSeriesType: EventSeriesType.RelativeMonthly,
            interval: 1,
            // offset by 2 weeks from monthly
            weekIndex: EventSeriesWeekIndex.Third,
        },
        fields: [
            weekIndexField,
            { ...dayOfWeekField, suffix: "of every month" },
            { ...monthField, prefix: " starting" },
        ],
        guidance: ImplementationKitArticle.EventTypeGuidanceOKRCheckIn,
        conflictingEventTypes: [
            EventTypePreset.SetFirstObjectives,
            EventTypePreset.AnnualStrategy,
            EventTypePreset.QuarterlyStrategy,
            EventTypePreset.MonthlyStrategy,
        ],
        // normally conflicts are resolved by month, but OCI by design will fall within the same month as MS.
        // so only mark as a conflict if they occur in the same week
        conflictingEventTypeCheck: (proposedDate, potentialConflicts) =>
            potentialConflicts.some(({ meetingDateTime }) => moment(meetingDateTime).isSame(proposedDate, "week")),
        getStartDate: (presetRecurrences) => {
            const setFirstObjectives = presetRecurrences.get(EventTypePreset.SetFirstObjectives);
            const today = moment(setFirstObjectives?.eventSeries.startDate)
                // start this a bit after the SFO by default
                .add(2, "days")
                .toDate();
            const defaultStartDate = moment(DateUtilities.getFirstWorkingDay(today.getFullYear(), today.getMonth(), today.getDate()));
            return moment(defaultStartDate)
                .startOf("day")
                .hour(9)
                .minute(0)
                .toDate();
        },
    },
};

import { HttpClient } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { DeepDive, DeepDiveBreezeModel, DeepDiveGroup, DeepDiveType } from "@common/ADAPT.Common.Model/embed/deep-dive";
import { ServiceUri } from "@common/configuration/service-uri";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { BaseService } from "@common/service/base.service";
import { of } from "rxjs";
import { map, switchMap } from "rxjs/operators";

export interface IDeepDiveScormParams {
    location: string;
}

@Injectable({
    providedIn: "root",
})
export class DeepDiveService extends BaseService {
    public uploadUri = this.exposeUri<IDeepDiveScormParams>("UploadScorm");
    public uploadAssetUri = this.exposeUri<IDeepDiveScormParams>("UploadScormAsset");
    public listAssetsUri = this.exposeUri<IDeepDiveScormParams>("ListScormAssets");

    constructor(
        injector: Injector,
        private httpClient: HttpClient,
    ) {
        super(injector);
    }

    public getAllDeepDives = () => this.commonDataService.getAll(DeepDiveBreezeModel);
    public getDeepDiveById = (deepDiveId: number) => this.commonDataService.getById(DeepDiveBreezeModel, deepDiveId);

    public getDeepDiveByLocation(location: string) {
        const key = `deepDiveAt${location}`;
        const predicate = new MethodologyPredicate<DeepDive>("location", "==", location);
        return this.commonDataService.getWithOptions(DeepDiveBreezeModel, key, { predicate }).pipe(
            map((scorm) => ArrayUtilities.getSingleFromArray<DeepDive>(scorm)),
        );
    }

    public getDeepDivesByUserGroup(group: DeepDiveGroup) {
        const predicate = new MethodologyPredicate<DeepDive>("group", "==", group);
        const key = `deepDiveByGroup${predicate.getKey()}`;
        return this.commonDataService.getWithOptions(DeepDiveBreezeModel, key, { predicate });
    }

    public createDeepDive() {
        return this.commonDataService.create<DeepDive>(DeepDiveBreezeModel, {
            type: DeepDiveType.Scorm,
        });
    }

    public deleteDeepDive(deepDive: DeepDive) {
        let request$ = of({});
        if (deepDive.type === DeepDiveType.Scorm) {
            request$ = this.httpClient.post(`${ServiceUri.DeepDiveServiceBaseUri}/DeleteScorm`, null, {
                params: { location: deepDive.location },
            });
        }

        return request$.pipe(
            switchMap(() => this.commonDataService.remove<DeepDive>(deepDive)),
            switchMap(() => this.commonDataService.saveEntities(deepDive)),
        );
    }

    private exposeUri<T>(partialUri: string) {
        return (viewModel?: T) => {
            const uri = `${ServiceUri.DeepDiveServiceBaseUri}/${partialUri}`;
            return StringUtilities.buildUrl(uri, { ...viewModel });
        };
    }
}


<adapt-dialog [maxWidth]="700">
    <h3 adapt-dialog-title>View Meeting Pre-work</h3>

    <ng-container adapt-dialog-content>
        <div *adaptLoading="loading">
            <div *ngIf="preWorkAgendaItem; else noPreWork"
                 class="agenda-items dx-widget mb-1">
                <div class="dx-item dx-list-item pre-work">
                    <adapt-edit-agenda-item class="p-2 dx-list-item pre-work"
                                            [item]="preWorkAgendaItem"
                                            [readonly]="true"
                                            [expandOnInit]="true"
                                            [hideProgressIcon]="true"
                                            [hideNotesAndItems]="true"></adapt-edit-agenda-item>
                </div>
            </div>

            <ng-template #noPreWork>
                <p>This meeting does not have any pre-work.</p>
            </ng-template>
        </div>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveButtonPreset="close"
                                   [hideCancelButton]="true"
                                   (saveClick)="cancel()"></adapt-standard-dialog-buttons>
</adapt-dialog>

<div *adaptLoadingUntilFirstEmit="let values of values$">
    <div *ngIf="values.length > 0; else noValuesTemplate"
         class="values-grid">
        <div *ngFor="let value of values"
             class="value">
            <h3 class="text-center mb-2">{{value.name}}</h3>
            <div [froalaView]="value.meaning"></div>
        </div>
    </div>
    <ng-template #noValuesTemplate>
        <adapt-call-to-action-inline [featureUrl]="vcUrl$ | async">
            Adapt's values tool provides a place to capture and maintain your organisation's values.
        </adapt-call-to-action-inline>
    </ng-template>
</div>

<adapt-collapsible-search-row [result]="result"
                              [matches]="matches"
                              [href$]="href$"
                              [ngClass]="{'no-container': !showContainer}">
    <div class="d-flex align-items-center justify-content-center">
        <div class="d-flex flex-grow-0 me-2 align-items-center text-muted">
            <div class="d-flex align-items-center result-icon team-icon"
                 adaptTooltip="Team">
                <i class="fa-inverse fal fa-users"></i>
            </div>
        </div>

        <a *ngIf="href$ | async as link"
           [routerLink]="link.path">
            <div class="d-flex align-items-center">
                <adapt-highlighted-text [needle]="keyword"
                                        [haystack]="result.team.name"></adapt-highlighted-text>
                <adapt-display-labels *ngIf="showLabels"
                                      class="ms-2 d-inline-block"
                                      [linkDisabled]="true"
                                      [labelLocations]="result.team.labelLocations"></adapt-display-labels>
                <ng-container *ngIf="result.team.isInactive()">
                    <span class="badge bg-danger"
                          adaptTooltip="This team is no longer active in the organisation.">
                        <i class="fal fa-exclamation-circle"></i> Inactive
                    </span>
                </ng-container>
            </div>
        </a>
    </div>
</adapt-collapsible-search-row>

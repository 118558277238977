import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptFeatureModule } from "@common/feature/feature.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { ContentWithSplashImageModule } from "@common/ux/content-with-splash-image/content-with-splash-image.module";
import { LoginExternalPageComponent } from "./login-external-page/login-external-page.component";
import { loginExternalPageRoute } from "./login-external-page/login-external-page.route";

@NgModule({
    declarations: [
        LoginExternalPageComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        AdaptButtonModule,
        ContentWithSplashImageModule,
        AdaptFeatureModule,
    ],
})
export class ExternalLoginModule {
    public static readonly Routes = [
        ...loginExternalPageRoute.routes,
    ];
}

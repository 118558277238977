import { ApplicationRef, createComponent, EnvironmentInjector, Injector } from "@angular/core";
import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ObjectiveCode, ObjectiveTypeMetadata } from "@common/ADAPT.Common.Model/organisation/objective-type";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { forkJoin, lastValueFrom } from "rxjs";
import { LinkObjectiveComponent } from "../../objectives/link-objective.component/link-objective.component";
import { ObjectivesService } from "../../objectives/objectives.service";
import { ObjectivesAuthService } from "../../objectives/objectives-auth.service";
import { KanbanService } from "../kanban.service";
import { ItemLinkComponent } from "./item-link/item-link.component";
import { ItemUtilities } from "./item-utilities";

interface IParsedItemAnchor {
    itemCode: string;
    element: HTMLAnchorElement;
}

export function autoLinkEditorView(injector: Injector, editorElement: HTMLElement) {
    new AutoLinkItem(injector, editorElement).processText();
}

class AutoLinkItem {
    private accessibleBoards: Board[] = [];
    private hasObjectiveAccess?: boolean;

    private kanbanService: KanbanService;
    private objectivesService: ObjectivesService;
    private objectivesAuthService: ObjectivesAuthService;

    // needed to create the adapt-item-link components
    private applicationRef: ApplicationRef;
    private environmentInjector: EnvironmentInjector;

    // map of item code to Item
    private itemsMap = new Map<string, Item | Objective>();

    public constructor(
        injector: Injector,
        private editorElement: HTMLElement,
    ) {
        this.kanbanService = injector.get(KanbanService);
        this.applicationRef = injector.get(ApplicationRef);
        this.environmentInjector = injector.get(EnvironmentInjector);
        this.objectivesService = injector.get(ObjectivesService);
        this.objectivesAuthService = injector.get(ObjectivesAuthService);
    }

    public async processText() {
        this.accessibleBoards = await lastValueFrom(this.kanbanService.getAllAccessibleBoards());
        this.hasObjectiveAccess = await lastValueFrom(this.objectivesAuthService.hasReadAccessToObjective());

        // nothing to do if no boards or no kanban access
        if (this.accessibleBoards.length > 0 || this.hasObjectiveAccess) {
            await this.convertItems();
        }

        // clear the map so we aren't holding it in memory after it's needed
        this.itemsMap.clear();
    }

    private getItemRegex() {
        const groups = this.accessibleBoards
            .map((b) => b.itemPrefix.toUpperCase());
        groups.push(...[ObjectiveCode.AO, ObjectiveCode.QO]);

        return new RegExp(`(\\s|\\b|>)((?:${groups.join("|")})-\\d+)(\\s|\\b|<)`, "gm");
    }

    private async convertItems() {
        const walker = this.editorElement.ownerDocument.createTreeWalker(this.editorElement, NodeFilter.SHOW_TEXT, (node) => {
            return node.textContent && this.getItemRegex().test(node.textContent) && !this.ignoreNode(node as Element)
                ? NodeFilter.FILTER_ACCEPT
                : NodeFilter.FILTER_SKIP;
        });

        // need to walk all nodes first, cannot modify while walking
        const nodesToProcess = new Set<Node>();
        while (walker.nextNode()) {
            nodesToProcess.add(walker.currentNode);
        }

        // convert all item code references to adapt-item-link
        for (const node of nodesToProcess) {
            await this.addItemComponentsToTextNode(node);
        }

        // convert all remaining item links to adapt-item-link
        await this.convertAnchors();
    }

    private ignoreNode(node: Element) {
        while (node.parentNode) {
            node = node.parentNode as Element;
            if (["A", "BUTTON", "TEXTAREA"].includes(node.tagName)) {
                return true;
            }
        }

        return false;
    }

    private async addItemComponentsToTextNode(node: Node) {
        const parentNode = node.parentNode;
        if (parentNode) {
            const textContent = node.textContent ?? "";

            const regex = this.getItemRegex();
            const matches = Array.from(textContent.matchAll(regex));

            // fetch all the items that are referenced and store them in the items map
            await this.getItemsByCodeOrIdFromCache(matches.map((match) => match[2]));

            const link: (string | Node)[] = textContent.split(regex).map((part) => {
                const item = this.itemsMap.get(part);
                if (item) {
                    const componentRef = item instanceof Objective
                        ? this.createObjectiveLinkComponentRef(item)
                        : this.createItemLinkComponentRef(item);

                    // attach the adapt-item-link to the application so it is part of change detection
                    this.applicationRef.attachView(componentRef.hostView);

                    return componentRef.location.nativeElement as Node;
                }

                return part;
            });

            // can't use replaceWith on a text node, replace with a dummy element first.
            const dummyElement = document.createElement("span");
            parentNode.replaceChild(dummyElement, node);

            // replace the dummy element with the actual link elements
            dummyElement.replaceWith(...link);
        }
    }

    private async convertAnchors() {
        // get anchors that reference items in some manner (either /item/:id or has data-item-code)
        const anchorsWithItemReferences = this.getAnchorsWithItemReferences();

        // fetch all the items that are referenced and store them in the items map
        await this.getItemsByCodeOrIdFromCache(anchorsWithItemReferences.map((ref) => ref.itemCode));

        for (const { itemCode, element } of anchorsWithItemReferences) {
            const item = this.itemsMap.get(itemCode);
            if (item) {

                const componentRef = item instanceof Objective
                    ? this.createObjectiveLinkComponentRef(item)
                    : this.createItemLinkComponentRef(item);

                element.replaceWith(componentRef.location.nativeElement);

                // attach the adapt-item-link to the application so it is part of change detection
                this.applicationRef.attachView(componentRef.hostView);
            }
        }
    }

    private createItemLinkComponentRef(item: Item) {
        const itemLinkRef = createComponent(ItemLinkComponent, { environmentInjector: this.environmentInjector });
        itemLinkRef.setInput("item", item);
        itemLinkRef.setInput("showSummary", true);
        itemLinkRef.setInput("showStatus", true);
        itemLinkRef.setInput("showPersonImageLink", true);
        itemLinkRef.setInput("inline", true);

        // add a test attribute so we can e2e this
        itemLinkRef.location.nativeElement.dataset.test = "auto-linked-item";

        return itemLinkRef;
    }

    private createObjectiveLinkComponentRef(objective: Objective) {
        const objectiveLinkRef = createComponent(LinkObjectiveComponent, { environmentInjector: this.environmentInjector });
        objectiveLinkRef.setInput("objective", objective);
        objectiveLinkRef.setInput("showAssigneeImage", true);
        objectiveLinkRef.setInput("displayInline", true);
        objectiveLinkRef.setInput("displayBorder", true);

        // add a test attribute so we can e2e this
        objectiveLinkRef.location.nativeElement.dataset.test = "auto-linked-objective";

        return objectiveLinkRef;
    }

    private getAnchorsWithItemReferences() {
        // :not(.item-code) will exclude adapt-item-link that have been added already by us
        const anchors = Array.from(this.editorElement.querySelectorAll("a:not(.item-code)") ?? []) as HTMLAnchorElement[];
        return anchors.reduce((parsedAnchors, anchor) => {
            // link has an item code in it
            if (anchor.textContent && this.getItemRegex().test(anchor.textContent)) {
                if (anchor.dataset.itemCode) {
                    // anchor already has an item code
                    parsedAnchors.push({ itemCode: anchor.dataset.itemCode, element: anchor });
                }

                const itemIdMatch = anchor.href.match(/\/item\/(\d+)/);
                if (itemIdMatch) {
                    // anchor has an item ID
                    parsedAnchors.push({ itemCode: itemIdMatch[1], element: anchor });
                }
            }

            return parsedAnchors;
        }, [] as IParsedItemAnchor[]);
    }

    private async getItemsByCodeOrIdFromCache(codes: string[]) {
        const itemCodeQueries = codes.map((code) => this.getItemByCodeOrIdFromCache(code));

        if (itemCodeQueries.length > 0) {
            const fetchedItems = await lastValueFrom(forkJoin(itemCodeQueries));
            for (const [idx, item] of fetchedItems.entries()) {
                if (item) {
                    this.itemsMap.set(codes[idx], item);
                }
            }
        }
    }

    private async getItemByCodeOrIdFromCache(code: string) {
        let item: Item | Objective | undefined;

        const itemCodeCandidate = ItemUtilities.getItemCodeBreakDown(code);
        if (itemCodeCandidate.boardAbbreviation && itemCodeCandidate.boardIndex) {
            if (this.hasObjectiveAccess && (itemCodeCandidate.boardAbbreviation === ObjectiveCode.AO || itemCodeCandidate.boardAbbreviation == ObjectiveCode.QO)) {
                const objectivePredicate = new MethodologyPredicate<Objective>("index", "==", itemCodeCandidate.boardIndex)
                    .and(new MethodologyPredicate<Objective>("type", "==", ObjectiveTypeMetadata.ByCode[itemCodeCandidate.boardAbbreviation as ObjectiveCode].type));
                const objectives = await lastValueFrom(this.objectivesService.getObjectivesByPredicate(objectivePredicate));
                item = objectives[0];
            } else {
                // find item on the board already in entity cache so we can avoid a query
                // all boards are cached from kanbanService init
                const board = await lastValueFrom(this.kanbanService.getBoardByAbbreviation(itemCodeCandidate.boardAbbreviation));
                item = board?.items.find((i) => i.boardIndex === itemCodeCandidate.boardIndex);
            }
        } else if (itemCodeCandidate.boardIndex) {
            // only an index, try get item by ID
            // since no boardAbbreviation, boardIndex is an item ID instead.
            // all boards are cached from kanbanService init
            const boards = await lastValueFrom(this.kanbanService.getAllBoards());
            const boardItems = boards.flatMap((b) => b.items);
            item = boardItems.find((i) => i.itemId === itemCodeCandidate.boardIndex);
        }

        if (!item) {
            // couldn't find item in cache, fall back to original getItemByCodeOrId
            item = await lastValueFrom(this.kanbanService.getItemByCodeOrId(code));
        }

        return item;
    }
}

import { Component, Injector } from "@angular/core";
import { EventCadenceCycle } from "@common/ADAPT.Common.Model/organisation/event-cadence-cycle";
import { EventSeries } from "@common/ADAPT.Common.Model/organisation/event-series";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { MeetingAgendaItem } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { MeetingAgendaItemSupplementaryData } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item-supplementary-data";
import { MeetingAttendee } from "@common/ADAPT.Common.Model/organisation/meeting-attendee";
import { MeetingSupplementaryData } from "@common/ADAPT.Common.Model/organisation/meeting-supplementary-data";
import { ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { EventSeriesDefaults } from "@org-common/lib/schedule/event-series-defaults";
import { ISetCadenceRunData } from "@org-common/lib/schedule/schedule.interface";
import { ScheduleService } from "@org-common/lib/schedule/schedule.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import moment from "moment/moment";
import { BehaviorSubject, forkJoin, lastValueFrom, of, switchMap } from "rxjs";

@WorkflowStepComponent("adapt-set-cadence-cycle-step")
@Component({
    selector: "adapt-set-cadence-cycle-step",
    templateUrl: "./set-cadence-cycle-step.component.html",
})
export class SetCadenceCycleStepComponent extends WorkflowStepComponentAdapter {
    public readonly currentMonth = moment().month() + 1;
    public readonly months = moment.months()
        .map((text, value) => ({ text, value: value + 1 }));

    public runData!: ISetCadenceRunData;
    public workflowStepCompleted = new BehaviorSubject<boolean>(false);

    public constructor(
        injector: Injector,
        private commonDataService: CommonDataService,
        private teamsService: CommonTeamsService,
        private scheduleService: ScheduleService,
        private dialogService: AdaptCommonDialogService,
        private implementationKitService: ImplementationKitService,
    ) {
        super();

        this.subscribeToEmitForEntityTypeChange(injector, EventCadenceCycle);
        this.subscribeToEmitForEntityTypeChange(injector, EventSeries);
        this.subscribeToEmitForEntityTypeChange(injector, Meeting);
        this.subscribeToEmitForEntityTypeChange(injector, MeetingSupplementaryData);
        this.subscribeToEmitForEntityTypeChange(injector, MeetingAgendaItem);
        this.subscribeToEmitForEntityTypeChange(injector, MeetingAgendaItemSupplementaryData);
        this.subscribeToEmitForEntityTypeChange(injector, MeetingAttendee);
    }

    public get cadenceCycle() {
        return this.runData.eventCadenceCycle;
    }

    public get hasExistingCadences() {
        return this.runData.scheduledPresets.size > 0;
    }

    public async workflowStepOnInit() {
        const cadenceCycle = await lastValueFrom(this.scheduleService.getOrCreateEventCadenceCycle({ month: this.currentMonth }));

        const team = await this.teamsService.promiseToGetLeadershipTeam();
        if (!team) {
            throw new Error("Leadership team not found");
        }

        const workflow = this.workflowStep?.workflow;
        if (workflow) {
            const runData = workflow.runData as ISetCadenceRunData;
            this.runData = workflow.runData = await this.scheduleService.getCadenceRunData(runData.eventTypePresets, team, cadenceCycle, false);
        }

        this.workflowStepCompleted.next(true);

        // pre-load the guidance for all the required recurrences
        const articleSlugs: ImplementationKitArticle[] = this.runData.eventTypePresets.map((preset) => EventSeriesDefaults[preset].guidance);
        if (articleSlugs.length > 0) {
            forkJoin(articleSlugs.map((slug) => this.implementationKitService.getArticle(slug))).subscribe();
        }
    }

    public workflowStepNext() {
        if (this.hasExistingCadences
            && this.cadenceCycle.entityAspect.entityState.isAddedModifiedOrDeleted()) {

            // if the cycle hasn't been saved yet we can just clear the whole thing
            if (!this.cadenceCycle.entityAspect.entityState.isModified()) {
                for (const config of this.runData.scheduledPresets.values()) {
                    config.eventSeries.meetings
                        .flatMap((m) => m.extensions.getAllMeetingEntities())
                        .forEach((e) => e.entityAspect.rejectChanges());
                    config.eventSeries.entityAspect.rejectChanges();
                    config.deletedEntities?.forEach((e) => e.entityAspect.rejectChanges());
                }
                this.runData.scheduledPresets.clear();
                this.runData.deletedEntities.forEach((e) => e.entityAspect.rejectChanges());
                this.runData.deletedEntities = [];
                return of(undefined);
            }

            return this.dialogService.openConfirmationDialog({
                title: "Confirm cadence adjustment",
                message: `<p>All upcoming meetings that would fall after the end of the cycle will be deleted.</p>
                    <p>Furthermore, you should reschedule your Annual Strategy session to be near the end of your cadence cycle.</p>
                    <p>You may cancel if you would like to not continue with this change.</p>`,
            }).pipe(
                switchMap(() => this.scheduleService.promiseToUpdateEventSeriesOnCycleChange(this.runData)),
            );
        }

        return of(undefined);
    }

    public async undoChanges() {
        await lastValueFrom(this.commonDataService.rejectChanges(this.cadenceCycle));
    }
}

import { Survey, SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { SurveyQuestionResponse } from "@common/ADAPT.Common.Model/organisation/survey-question-response";
import { SurveyResponseGroup } from "@common/ADAPT.Common.Model/organisation/survey-response";
import moment from "moment";
import { EmployeeEngagementUtils } from "./employee-engagement/employee-engagement-utils";
import { OrganisationDiagnosticUtils } from "./organisation-diagnostic/organisation-diagnostic-utils";
import { ResilientBusinessAssessmentUtils } from "./resilient-business-assessment/resilient-business-assessment-utils";
import { SurveyDetails } from "./survey-details";
import { ISurveyQuestions } from "./survey-questions.interface";
import { ISurveyTypeUtils } from "./survey-type-utils.interface";
import { TeamAssessmentUtils } from "./team-assessment/team-assessment-utils";

// if missing survey type in this declaration, it will be a compile error - don't have to check in usage
const SurveyTypeUtils: { [type in SurveyType]: ISurveyTypeUtils } = {
    EmployeeEngagement: new EmployeeEngagementUtils(),
    TeamAssessment: new TeamAssessmentUtils(),
    ResilientBusinessAssessment: new ResilientBusinessAssessmentUtils(),
    OrganisationDiagnostic: new OrganisationDiagnosticUtils(),
};

export class SurveyUtils {
    public static forSurveyType(surveyType: SurveyType) {
        return SurveyTypeUtils[surveyType];
    }

    public static getSurveyQuestions(surveyDetails: SurveyDetails) {
        return SurveyTypeUtils[surveyDetails.surveyType].getSurveyQuestions(surveyDetails);
    }

    public static getCategoryPercentageScore(
        categoryId: number,
        surveyQuestions: ISurveyQuestions,
        questionResponses: SurveyQuestionResponse[],
        surveyType: SurveyType,
        responseGroup = SurveyResponseGroup.All,
    ) {
        const surveyTypeUtils = SurveyUtils.forSurveyType(surveyType);
        const category = surveyQuestions.getCategory(categoryId);
        const questionIds = surveyQuestions.getQuestionIdsForCategory(categoryId);
        if (category && questionIds) {
            const categoryScores = questionResponses
                .filter((response) => questionIds.indexOf(response.surveyQuestionId) >= 0
                    && (responseGroup === SurveyResponseGroup.All || response.surveyResponse!.responseGroup === responseGroup))
                .filter(surveyTypeUtils.filterQuestionResponse)
                .map((response) => surveyTypeUtils.convertScoreToPercentage(response.response));
            const totalScores = categoryScores.reduce((total, num) => total + num, 0);
            return totalScores / categoryScores.length;
        } else {
            return 0;
        }
    }

    public static startOfWorkingDay(date?: moment.MomentInput) {
        return moment(date).startOf("day").add(9, "h").toDate(); // 9am
    }

    public static startOfHour(date?: moment.MomentInput) {
        return moment(date).startOf("hour").toDate();
    }

    public static endOfWorkingDay(date?: moment.MomentInput) {
        return moment(date).startOf("day").add(17, "h").toDate(); // 5pm
    }

    public static defaultEndTime(date?: moment.MomentInput) {
        return SurveyUtils.endOfWorkingDay(moment(date).add(7, "d"));
    }

    public static sortSurveysByEndTimeAscendingOrder(surveys: Survey[]) {
        return surveys.sort((s1, s2) => s1.endTime.getTime() - s2.endTime.getTime());
    }

    public static sortSurveysByStartTimeAscendingOrder(surveys: Survey[]) {
        return surveys.sort((s1, s2) => s1.startTime.getTime() - s2.startTime.getTime());
    }

    public static sortSurveysByEndTimeDescendingOrder(surveys: Survey[]) {
        return surveys.sort((s1, s2) => s2.endTime.getTime() - s1.endTime.getTime());
    }

    public static getSurveyResultForResponseGroup(survey: Survey, responseGroup: SurveyResponseGroup) {
        for (const result of survey.surveyResults) {
            if (result.responseGroup === responseGroup) {
                return result;
            }
        }

        return undefined;
    }
}

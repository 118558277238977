import { Component, Inject, OnInit } from "@angular/core";
import { PersonProfileItem } from "@common/ADAPT.Common.Model/person/person-profile-item";
import { PersonProfileItemTypeLabel } from "@common/ADAPT.Common.Model/person/person-profile-item-type-label";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { IDxCustomRuleValidationCallbackEvent } from "@common/ux/dx.types";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";

@Component({
    selector: "adapt-edit-personal-profile-item-dialog",
    templateUrl: "./edit-personal-profile-item-dialog.component.html",
})
export class EditPersonalProfileItemDialogComponent extends BaseDialogWithDiscardConfirmationComponent<PersonProfileItem> implements OnInit {
    public readonly dialogName = "EditPersonalProfileItem";

    public title: string;

    public itemTypes = PersonProfileItemTypeLabel.All;

    private profileItems: PersonProfileItem[] = [];

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public profileItem: PersonProfileItem,
        private directorySharedService: DirectorySharedService,
    ) {
        super();

        this.title = profileItem.entityAspect.entityState.isAdded()
            ? "Add profile item"
            : "Edit profile item";
        this.autoResolveData = this.profileItem;
    }

    public get entitiesToConfirm() {
        return [this.profileItem];
    }

    public get isValid() {
        return this.profileItem.label
            && !this.profileItem.entityAspect.hasValidationErrors
            && this.validateDuplicates({ value: this.profileItem.label });
    }

    public async ngOnInit() {
        this.profileItems = await this.directorySharedService.promiseToGetAllPersonProfileItems();
    }

    @Autobind
    public validateDuplicates({ value }: IDxCustomRuleValidationCallbackEvent) {
        if (typeof value === "string") {
            const labelExists = this.profileItems
                .filter((profileItem) => profileItem !== this.profileItem && profileItem.personProfileCategoryId === this.profileItem.personProfileCategoryId)
                .some((profileItem) => profileItem.label.trim().toLowerCase() === value.trim().toLowerCase());
            return !labelExists;
        }
        return false;
    }
}

import { Injectable } from "@angular/core";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { UserAccessSummaryDialogComponent } from "./user-access-summary-dialog/user-access-summary-dialog.component";

@Injectable({
    providedIn: "root",
})
export class UserManagementCommonUiService {

    public constructor(
        private dialogService: AdaptCommonDialogService,
    ) {
    }

    public showUserAccessSummaryDialogForConnection(connection: Connection) {
        return this.dialogService.open(UserAccessSummaryDialogComponent, { connection });
    }
}

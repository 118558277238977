import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptImplementationKitModule } from "@common/implementation-kit/implementation-kit.module";
import { AdaptToolbarModule } from "@common/shell/toolbar/toolbar.module";
import { AdaptStorageModule } from "@common/storage/storage.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptProgressBarModule } from "@common/ux/progress-bar/progress-bar.module";
import { AdaptSeeMoreModule } from "@common/ux/see-more/see-more.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AdaptCalendarModule } from "@org-common/lib/calendar/calendar.module";
import { AdaptCommonMeetingsModule } from "@org-common/lib/meetings/common-meetings.module";
import { AdaptMeetingsSharedModule } from "@org-common/lib/meetings/meetings-shared.module";
import { AdaptStrategicGoalsModule } from "@org-common/lib/strategic-goals/strategic-goals.module";
import { AdaptStrategyModule } from "@org-common/lib/strategy/strategy.module";
import { AdaptSurveyModule } from "@org-common/lib/survey/survey.module";
import { AdaptWorkflowModule } from "@org-common/lib/workflow/workflow.module";
import { WorkflowService } from "@org-common/lib/workflow/workflow.service";
import { provideWorkflows } from "@org-common/lib/workflow-provider";
import { DxCheckBoxModule, DxTextAreaModule, DxTextBoxModule, DxValidationGroupModule, DxValidatorModule } from "devextreme-angular";
import { AdaptAccountModule } from "../account/account.module";
import { AdaptPersonModule } from "../person/person.module";
import { AdaptApplyGoodStewardshipWorkflowModule } from "./build-effective-team/apply-good-stewardship/apply-good-stewardship-workflow.module";
import { AddCompetitorAnalysisAgendaItemComponent } from "./build-strategy-for-success/add-competitor-analysis-agenda-item/add-competitor-analysis-agenda-item.component";
import { AddStrategicGoalsAgendaItemComponent } from "./build-strategy-for-success/add-strategic-goals-agenda-item/add-strategic-goals-agenda-item.component";
import { AddSwtAgendaItemComponent } from "./build-strategy-for-success/add-swt-agenda-item/add-swt-agenda-item.component";
import { AttachBullseyeStatementsAgendaItemComponent } from "./build-strategy-for-success/attach-bullseye-statements-agenda-item/attach-bullseye-statements-agenda-item.component";
import { AttachInputsAgendaItemComponent } from "./build-strategy-for-success/attach-inputs-agenda-item/attach-inputs-agenda-item.component";
import { AdaptBuildStrategyForSuccessWorkflowModule } from "./build-strategy-for-success/build-strategy-for-success.module";
import { AdaptGatherInputsWorkflowModule } from "./build-strategy-for-success/gather-inputs/gather-inputs-workflow.module";
import { StrategyBoardAgendaItemComponent } from "./build-strategy-for-success/strategy-board-agenda-item/strategy-board-agenda-item.component";
import { StrategyGoalAgendaItemComponent } from "./build-strategy-for-success/strategy-goal-agenda-item/strategy-goal-agenda-item.component";
import { AdaptStrategicThemesWorkflowModule } from "./identify-strategic-themes/identify-strategic-themes-workflow.module";
import { AnnualObjectivesKeyResultsAgendaItemComponent } from "./implement-your-strategy/annual-objectives-key-results-agenda-item/annual-objectives-key-results-agenda-item.component";
import { AdaptPlanYourStrategyWorkflowModule } from "./implement-your-strategy/establish-pulse/plan-your-strategy.module";
import { ObjectivesPageNavigatorComponent } from "./implement-your-strategy/objectives-page-navigator/objectives-page-navigator.component";
import { ObjectivesTourAgendaItemComponent } from "./implement-your-strategy/objectives-tour-agenda-item/objectives-tour-agenda-item.component";
import { QuarterlyObjectivesKeyResultsAgendaItemComponent } from "./implement-your-strategy/quarterly-objectives-key-results-agenda-item/quarterly-objectives-key-results-agenda-item.component";
import { RecordAnnualObjectivesAgendaItemComponent } from "./implement-your-strategy/record-annual-objectives-agenda-item/record-annual-objectives-agenda-item.component";
import { RecordQuarterlyObjectivesAgendaItemComponent } from "./implement-your-strategy/record-quarterly-objectives-agenda-item/record-quarterly-objectives-agenda-item.component";
import { InviteBusinessLeadersAddLeadershipTeamComponent } from "./invite-business-leaders/invite-business-leaders-add-leadership-team/invite-business-leaders-add-leadership-team.component";
import { SetUpProfileComponent } from "./onboarding/set-up-profile/set-up-profile.component";
import { AdaptOrganisationDiagnosticModule } from "./organisation-diagnostic/organisation-diagnostic.module";
import { AdaptAltoWorkflowsSharedModule } from "./shared/workflows-shared.module";
import { Workflows } from "./workflows";

@NgModule({
    declarations: [
        InviteBusinessLeadersAddLeadershipTeamComponent,
        StrategyBoardAgendaItemComponent,
        StrategyGoalAgendaItemComponent,
        AddCompetitorAnalysisAgendaItemComponent,
        AddSwtAgendaItemComponent,
        AttachInputsAgendaItemComponent,
        AddStrategicGoalsAgendaItemComponent,
        AttachBullseyeStatementsAgendaItemComponent,
        RecordAnnualObjectivesAgendaItemComponent,
        AnnualObjectivesKeyResultsAgendaItemComponent,
        RecordQuarterlyObjectivesAgendaItemComponent,
        QuarterlyObjectivesKeyResultsAgendaItemComponent,
        ObjectivesPageNavigatorComponent,
        SetUpProfileComponent,
        ObjectivesTourAgendaItemComponent,
    ],
    imports: [
        CommonModule,
        AdaptAuthorisationModule,
        RouterModule,
        AdaptWorkflowModule,
        AdaptTooltipModule,
        DxValidationGroupModule,
        DxValidatorModule,
        DxTextBoxModule,
        DxTextAreaModule,
        DxCheckBoxModule,
        AdaptAccountModule,
        AdaptPersonModule,
        AdaptLoadingSpinnerModule,
        AdaptProgressBarModule,
        AdaptDateModule,
        AdaptImplementationKitModule,
        AdaptToolbarModule,
        AdaptButtonModule,
        AdaptSurveyModule,

        AdaptAltoWorkflowsSharedModule,
        AdaptCommonMeetingsModule,
        AdaptMeetingsSharedModule,
        AdaptOrganisationDiagnosticModule,
        AdaptPlanYourStrategyWorkflowModule,
        AdaptCalendarModule,
        AdaptBuildStrategyForSuccessWorkflowModule,
        AdaptStrategicThemesWorkflowModule,
        AdaptApplyGoodStewardshipWorkflowModule,
        AdaptGatherInputsWorkflowModule,
        AdaptStorageModule,
        AdaptStrategicGoalsModule,
        AdaptStrategyModule,
        AdaptSeeMoreModule,
    ],
    providers: [
        provideWorkflows(Workflows),
    ],
})
export class AdaptAltoWorkflowsModule {
    public static readonly Routes = [
        ...AdaptWorkflowModule.Routes,
    ];

    // prevent being optimised by tree-shaker
    public readonly workflowStepComponents = [
        InviteBusinessLeadersAddLeadershipTeamComponent,
        StrategyBoardAgendaItemComponent,
        StrategyGoalAgendaItemComponent,
        AddCompetitorAnalysisAgendaItemComponent,
        AddSwtAgendaItemComponent,
        AttachInputsAgendaItemComponent,
        AddStrategicGoalsAgendaItemComponent,
        AttachBullseyeStatementsAgendaItemComponent,
        RecordAnnualObjectivesAgendaItemComponent,
        AnnualObjectivesKeyResultsAgendaItemComponent,
        RecordQuarterlyObjectivesAgendaItemComponent,
        QuarterlyObjectivesKeyResultsAgendaItemComponent,
        SetUpProfileComponent,
        ObjectivesTourAgendaItemComponent,
    ];

    public constructor(workflowService: WorkflowService) {
        workflowService.setLocalWorkflows(Object.values(Workflows));
    }
}

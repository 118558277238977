import { InjectionToken, Type } from "@angular/core";
import { IShellPopoverLinkItem } from "../shell-popover-link-item/shell-popover-link-item";

export const SIDEBAR_POPOVERS = new InjectionToken<IShellPopoverLinkItem[]>("SIDEBAR_POPOVERS");

export function provideSidebarPopover(popover: Type<IShellPopoverLinkItem>) {
    return {
        provide: SIDEBAR_POPOVERS,
        useClass: popover,
        multi: true,
    };
}

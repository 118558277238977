<ng-container *adaptLoading="!initialised">
    <div class="d-flex flex-column flex-1 flex-wrap w-100">
        <div class="d-flex flex-row align-items-center gap-2">
            <i *ngIf="showIcon"
               [class]="meeting.eventSeries?.eventType?.colour ? 'fas' : 'far'"
               [ngClass]="iconClass"
               [style.color]="meeting.eventSeries?.eventType?.colour"></i>
            <ng-container *ngIf="linkDisabled; else plainSpan">
                <a *ngIf="(meetingUrl$ | async) as meetingUrl; else plainSpan"
                   [routerLink]="meetingUrl.path"
                   [queryParams]="meetingUrl.queryParams">
                    <h5 class="my-1"><span *ngIf="showTeamName">{{meeting.team?.name}}: </span>{{meeting.name}}</h5>
                </a>
            </ng-container>
            <ng-template #plainSpan>
                <h5 class="text-wrap">{{meeting.name}}</h5>
            </ng-template>
            <div *ngIf="compact"
                 class="d-flex align-items-center ms-auto">
                <ng-container [ngTemplateOutlet]="inProgressBadge"></ng-container>
            </div>
        </div>
        <div *ngIf="meeting.extensions.hasPreWork && preWorkVisible"
             class="pre-work-notice">
            <button *ngIf="preWorkClickable; else plainPreWork"
                    class="btn btn-link small"
                    (click)="showPreWork()">Meeting requires pre-work
            </button>
            <ng-template #plainPreWork>
                <span class="small">Meeting requires pre-work</span>
            </ng-template>
        </div>
        <div class="small"
             title="When this meeting will take place">
            <span>{{meeting.meetingDateTime | adaptDate}}</span>
            <span class="mx-2">/</span>
            <span>{{meeting.meetingDateTime | adaptTime}}</span>
            <ng-container *ngIf="meeting.endTime">
                <span class="mx-1">-</span>
                <span>{{meeting.endTime | adaptTime}}</span>
            </ng-container>
        </div>
        <div *ngIf="meeting.location"
             class="small"
             title="Where this meeting will take place">{{meeting.location}}</div>
    </div>

    <div *ngIf="!compact"
         class="align-self-center d-flex">
        <div *ngIf="canStart; else inProgressBadge">
            <button *ngIf="showStartButton"
                    adaptButtonIcon="fal fa-fw fa-play-circle"
                    [adaptBlockingClick]="startMeeting"
                    class="btn btn-primary text-nowrap"
                    [unsubscribeOnDestroy]="false"
                    data-test="start-meeting-button">Start
            </button>
        </div>
    </div>
</ng-container>

<ng-template #inProgressBadge>
    <span *ngIf="isInProgress && showStatus; else showMeetingInfo"
          class="badge bg-danger">In progress</span>
    <ng-template #showMeetingInfo>
        <i *ngIf="nonStartableMeetingInfo && showInfoIcon"
           class="ms-1 fal fa-info-circle"
           [adaptTooltip]="nonStartableMeetingInfo"></i>
    </ng-template>
</ng-template>

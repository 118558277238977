import { Logger } from "../logger/logger";

// Non ajs service version of timerWorker-provider service - can be used anywhere and no injector dependencies!
export class TimerWorker {
    // If this changes, make sure to update it in angular.json assets too
    private static readonly workerSrc = "/timer-worker-src.js";

    private logger = Logger.getLogger("TimerWorker");

    /**
     * Same functionality as window.setInterval or $interval with the exception that this
     * interval will be driven from a worker thread, which workaround issue where main thread
     * interval suspended while not in focus.
     * There will be 1 worker thread per interval set.
     *
     * @param {() => void} callback Callback function
     * @param {number} interval The interval in msec the callback function will be fired
     * @returns {() => void} clear interval function to remove the timer
     */
    public setInterval(callback: () => void, interval: number) {
        if (interval <= 0) {
            throw new Error("interval is expected to be the number of millisecond > 0");
        }

        let worker: number | Worker | undefined;
        if (!Worker) {
            this.logger.warn("Worker not supported - using window.setInterval instead which may not work if the tab is not in focus!");
            worker = window.setInterval(callback, interval);
        } else {
            worker = new Worker(TimerWorker.workerSrc);
            worker.onmessage = callback;
            worker.postMessage(interval);
        }

        return () => {
            if (worker) {
                if (worker instanceof Worker) {
                    worker.postMessage(null); // this will clearInterval on the worker even if terminate is not working
                    worker.terminate();
                    worker = undefined;
                } else { // worker is a number from window.setInterval
                    window.clearInterval(worker);
                }
            }
        };
    }
}

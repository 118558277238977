import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Goal } from "./goal";

export enum MeasurementType {
    Target = "Target",
    Record = "Record",
}

export class Measurement extends BaseEntity<Measurement> {
    public measurementId!: number;
    public goalId!: number;
    public value!: number;
    public comment!: string;
    public timestamp!: Date;
    public type!: MeasurementType;

    public goal?: Goal;
}

// entity without an endpoint as this will come through a nav property from goal
export const MeasurementBreezeModel = new BreezeModelBuilder("Measurement", Measurement)
    .persistChangedEntity()
    .setPrimeSource("goal", "goalId")
    .build();

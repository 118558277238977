import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { AdaptClientConfiguration, AdaptEnvironment } from "@common/configuration/adapt-client-configuration";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { Observable, of, Subscription, throwError } from "rxjs";
import { catchError, switchMap, take } from "rxjs/operators";
import { IHelpJuiceArticle, ImplementationKitService } from "../implementation-kit.service";
import { ImplementationKitArticle } from "../implementation-kit-article.enum";


@Component({
    selector: "adapt-implementation-kit-content",
    templateUrl: "./implementation-kit-content.component.html",
})
export class ImplementationKitContentComponent extends BaseComponent implements OnChanges {
    @Input() public articleId?: ImplementationKitArticle;
    @Input() public loadOnEmit?: Observable<void>;
    @Output() public articleName = new EventEmitter<string>();
    @Output() public contentLoaded = new EventEmitter<void>();

    public article?: IHelpJuiceArticle;
    public error?: string;

    private loadSubscription?: Subscription;

    public constructor(
        private implementationKitService: ImplementationKitService,
    ) {
        super();
    }

    public get isLoading() {
        return !this.error && !this.article;
    }

    public get showEditingLink() {
        return AdaptClientConfiguration.AdaptEnvironment !== AdaptEnvironment.Production;
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.articleId && this.articleId) {
            // stop the article load if we change article again
            this.loadSubscription?.unsubscribe();
            this.article = undefined;
            this.error = undefined;

            this.articleName.emit("Loading...");
            this.loadSubscription = (this.loadOnEmit ?? of(undefined)).pipe(
                take(1),
                switchMap(() => this.implementationKitService.getArticle(this.articleId!)),
                catchError((err) => {
                    this.error = err;
                    return throwError(() => new Error(err));
                }),
            ).subscribe((data) => {
                this.article = data;
                this.articleName.emit(this.article.name);
            });
        }
    }

    public onContentLoaded() {
        this.contentLoaded.emit();
    }
}

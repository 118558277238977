import { Component, Injector, OnInit } from "@angular/core";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";

@Component({
    templateUrl: "./eula-page.component.html",
})
export class EulaPageComponent extends BaseRoutedComponent implements OnInit {

    public constructor(
        injector: Injector,
    ) {
        super(injector);
    }

    public ngOnInit() {
        this.notifyActivated();
    }
}

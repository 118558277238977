import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { ConnectNoCalendarIntegrationComponent } from "./connect-no-calendar-integration/connect-no-calendar-integration.component";


@NgModule({
    imports: [
        CommonModule,
        AdaptButtonModule,
    ],
    declarations: [
        ConnectNoCalendarIntegrationComponent,
    ],
    exports: [
        ConnectNoCalendarIntegrationComponent,
    ],
})
export class AdaptNoCalendarIntegrationModule {}

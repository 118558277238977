<div class="card card-dashboard">
    <div class="card-header">
        <h2 class="card-title">Guidance</h2>
        <div class="card-header-buttons">
            <button *ngIf="canConfigureTeam$ | async"
                    adaptButtonIcon="edit"
                    [adaptBlockingClick]="editTeamPurpose"></button>
        </div>
    </div>
    <div class="card-body"
         *adaptLoadingUntilFirstEmit="let purpose of purpose$">
        <div *ngIf="purpose && purpose.content; else noPurposeTemplate"
             [froalaView]="purpose.content">
        </div>
        <ng-template #noPurposeTemplate>
            <div class="alert alert-guidance">
                <h4>What is team guidance?</h4>
                <p>Team guidance describes the team's purpose and vision for the future.</p>
                <h4>Why have team guidance?</h4>
                <p>A clear and explicit purpose and vision provides the team with something to rally behind and work towards.</p>
            </div>
        </ng-template>
    </div>
</div>

import { Component, Inject } from "@angular/core";
import { Account } from "@common/ADAPT.Common.Model/account/account";
import { BillingPeriod } from "@common/ADAPT.Common.Model/account/account-extensions";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";

@Component({
    selector: "adapt-change-payment-frequency-dialog",
    templateUrl: "./change-payment-frequency-dialog.component.html",
})
export class ChangePaymentFrequencyDialogComponent extends BaseDialogWithDiscardConfirmationComponent<Account> {
    public readonly dialogName = "Update your subscription";
    public readonly BillingPeriod = BillingPeriod;

    public initialBillingPeriod: BillingPeriod[];
    public entitiesToConfirm: IBreezeEntity[];

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public account: Account,
    ) {
        super();
        this.autoResolveData = this.account;
        this.initialBillingPeriod = [this.account.billingPeriod];
        this.entitiesToConfirm = [this.account];
    }

    public onSelect(billingPeriod: BillingPeriod) {
        if (billingPeriod === this.initialBillingPeriod[0]) {
            this.account.entityAspect.rejectChanges();
        } else {
            this.account.billingPeriod = billingPeriod;
        }
    }
}

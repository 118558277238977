import { OrganisationOutcomes } from "@org-common/lib/workflow/organisation-outcomes";
import { SurveyDetails } from "../survey-details";
import { ISurveyQuestionCategory, ISurveyQuestions } from "../survey-questions.interface";
import { ISurveyResponseChoice } from "../survey-response-choice.interface";
import { TeamAssessmentQuestions } from "../team-assessment/team-assessment-questions";

const OrganisationName = "ORGANISATION_NAME";
const OrganisationDiagnosticQuestionList: { [surveyQuestionId: number]: string } = {
    [1]: "I am clear on what work and roles I should be doing, and what I should be delegating",
    [2]: "We have a clear vision for the business that has been communicated to everyone",
    [3]: "We regularly allocate time to work on our strategic goals",
    [4]: "We commit time to stay abreast with what's happening in our business environment (threats, trends and opportunities)",
    [5]: "Everyone on the leadership team is conscious of doing work that leverages their time (leveraged work is setting and communicating the vision and strategy, designing the business, building systems and work processes and coaching)",

    [6]: "All members of the leadership team have visibility into the priorities and progress of the company objectives",
    [7]: "Our leadership team is open, honest and there is a high level of trust",
    [8]: "Everyone on the leadership team feels safe to speak up, contribute ideas and give each other feedback",
    [9]: "Our leadership team meetings are useful and productive",
    [10]: "Everyone on the leadership team is the 'right' person (they fit our culture and role model our values)",

    [11]: "Key functions are clear to everyone in the business",
    [12]: "Leadership and accountability for each key function is documented and well communicated",
    [13]: "We have the 'right' people leading each key function (they have the commitment, capacity, and capability to do the job)",

    [14]: "Our business purpose (why we exist) has been considered, documented and properly communicated to everyone in the business",
    [15]: "We have clear, well communicated company values that we are confident will guide our behaviour",

    [16]: "The leaders are clear and aligned on what our bullseye is (i.e. our core focus / sweet spot / hedgehog)",
    [17]: "We are clear on our 3 strategic anchors that will support our success",
    [18]: "We have mapped out a path to our vision with clear 3-5 year business goals",
    [19]: "We collect inputs from our employees, customers and industry when setting strategy",

    [20]: "We have a regular, established cadence of meetings to review our strategy and set priorities",
    [21]: "We focus on measurable outcomes, not actions",
    [22]: "Everyone in the business has visibility into the 3-5 priorities for the period",
    [23]: "Everyone can see if we are on/off track to achieve our objectives for a period",
    [24]: "Each strategic initiative or objective has someone assigned and accountable for its outcome",

    [25]: "We all understand our critical numbers",
    [26]: "Every leader has at least one number they are accountable for tracking (sales / customer satisfaction / employee engagement / cash / profit)",
};

const OrganisationDiagnosticQuestionChoices: { [score: number]: string } = {
    [1]: "Strongly Disagree",
    [2]: "Disagree",
    [3]: "Neutral",
    [4]: "Agree",
    [5]: "Strongly Agree",
};

export enum OrganisationDiagnosticCategoryGrade {
    Bad = "Having Significant Challenges",
    Warning = "Having Some Challenges",
    Good = "Doing Well",
}

// any category name or question changes should be updated in OrganisationDiagnosticHandler.cs as well
const OrganisationDiagnosticQuestionCategories: { [id: number]: ISurveyQuestionCategory } = {
    [1]: {
        categoryName: OrganisationOutcomes.DoTheRightWork.name,
        shortCategoryName: OrganisationOutcomes.DoTheRightWork.shortName,
        questionIds: [1, 2, 3, 4, 5],
        gradeSummary: {
            [OrganisationDiagnosticCategoryGrade.Bad]: "Stop and review the work leaders are currently doing. If your leaders are not doing the right work it's going to be difficult for your business to grow and succeed.",
            [OrganisationDiagnosticCategoryGrade.Warning]: "Consider reviewing the work leaders are currently doing in the business, there may be an opportunity for leaders to be more effective with the work they do.",
            [OrganisationDiagnosticCategoryGrade.Good]: "Great! Everyone is doing the right leadership work and leveraging their time so the business can succeed.",
        },
    },
    [2]: {
        categoryName: OrganisationOutcomes.EffectiveLeadershipTeam.name,
        shortCategoryName: OrganisationOutcomes.EffectiveLeadershipTeam.shortName,
        questionIds: [6, 7, 8, 9, 10],
        gradeSummary: {
            [OrganisationDiagnosticCategoryGrade.Bad]: "Stop! An ineffective leadership team will NOT result in sustainable business success. You could consider doing some development work with an external consultant to help build your leadership team.",
            [OrganisationDiagnosticCategoryGrade.Warning]: "Consider where you could do better as a leadership team. An effective leadership team is critical for business success.",
            [OrganisationDiagnosticCategoryGrade.Good]: "Great work! You are an effective leadership team and that's critical to business success.",
        },
    },
    [3]: {
        categoryName: OrganisationOutcomes.FocusAccountability.name,
        shortCategoryName: OrganisationOutcomes.FocusAccountability.shortName,
        questionIds: [11, 12, 13],
        gradeSummary: {
            [OrganisationDiagnosticCategoryGrade.Bad]: "Stop. Do some work to understand how best to structure your business and assign leadership accountabilities.",
            [OrganisationDiagnosticCategoryGrade.Warning]: "You could consider how you are structured and who needs to be accountable for what.",
            [OrganisationDiagnosticCategoryGrade.Good]: "Good work. Leadership focus and accountability are essential for business success.",
        },
    },
    [4]: {
        categoryName: OrganisationOutcomes.Foundation.name,
        shortCategoryName: OrganisationOutcomes.Foundation.shortName,
        questionIds: [14, 15],
        gradeSummary: {
            [OrganisationDiagnosticCategoryGrade.Bad]: "There is work to be done here. Spend some time considering your business purpose and values.",
            [OrganisationDiagnosticCategoryGrade.Warning]: "Great strategy is anchored in solid cultural foundations - it looks like you have some work to do here.",
            [OrganisationDiagnosticCategoryGrade.Good]: "Well done! You understand the importance of having good cultural foundations.",
        },
    },
    [5]: {
        categoryName: OrganisationOutcomes.BuildStrategy.name,
        shortCategoryName: OrganisationOutcomes.BuildStrategy.shortName,
        questionIds: [16, 17, 18, 19],
        gradeSummary: {
            [OrganisationDiagnosticCategoryGrade.Bad]: "You don't have a strategy for your business - Get started NOW!",
            [OrganisationDiagnosticCategoryGrade.Warning]: "You have some work to do to build your strategy for success.",
            [OrganisationDiagnosticCategoryGrade.Good]: "Great work, you seem to understand the importance of having a well-defined strategy for your business.",
        },
    },
    [6]: {
        categoryName: OrganisationOutcomes.ImplementStrategy.name,
        shortCategoryName: OrganisationOutcomes.ImplementStrategy.shortName,
        questionIds: [20, 21, 22, 23, 24],
        gradeSummary: {
            [OrganisationDiagnosticCategoryGrade.Bad]: "There is considerable work to be done in developing the focus and discipline to ensure you successfully implement your strategy.",
            [OrganisationDiagnosticCategoryGrade.Warning]: "Effective strategy implementation requires focus and discipline. You may not be as effective as possible.",
            [OrganisationDiagnosticCategoryGrade.Good]: "Congratulations, you have the focus and discipline to effectively implement your strategy.",
        },
    },
    [7]: {
        categoryName: OrganisationOutcomes.CriticalNumbers.name,
        shortCategoryName: OrganisationOutcomes.CriticalNumbers.shortName,
        questionIds: [25, 26],
        gradeSummary: {
            [OrganisationDiagnosticCategoryGrade.Bad]: "Stop! You may need to invest some time to understand your critical numbers.",
            [OrganisationDiagnosticCategoryGrade.Warning]: "It appears there is work to do, for everyone to fully understand your critical numbers.",
            [OrganisationDiagnosticCategoryGrade.Good]: "Well done, you understand your critical numbers.",
        },
    },
};

export class OrganisationDiagnosticQuestions implements ISurveyQuestions {
    public questionIds = TeamAssessmentQuestions.shuffle(this.orderedQuestionIds);

    public get orderedQuestionIds() {
        return Object.keys(OrganisationDiagnosticQuestionList).map(Number);
    }

    public get categoryIds() {
        return Object.keys(OrganisationDiagnosticQuestionCategories).map(Number);
    }

    public get surveyResponseChoices() {
        return Object.keys(OrganisationDiagnosticQuestionChoices).map(Number)
            .map((value) => ({ text: OrganisationDiagnosticQuestionChoices[value], value } as ISurveyResponseChoice));
    }

    public constructor(private surveyDetails: SurveyDetails) { }

    public getQuestion(questionId: number) {
        const question = OrganisationDiagnosticQuestionList[questionId];
        if (question) {
            return question.replace(OrganisationName, this.surveyDetails.organisationName);
        } else {
            return undefined;
        }
    }

    public getCategory(categoryId: number) {
        return OrganisationDiagnosticQuestionCategories[categoryId];
    }

    public getQuestionIdsForCategory(categoryId: number) {
        const questionCategory = OrganisationDiagnosticQuestionCategories[categoryId];
        if (questionCategory) {
            return questionCategory.questionIds;
        } else {
            return undefined;
        }
    }
}

import { Component, EventEmitter, HostBinding, HostListener, Input, OnChanges, Output } from "@angular/core";

const ExpandIconClass = "fal fa-chevron-down";
const CollapseIconClass = "fal fa-chevron-up";

@Component({
    // eslint-disable-next-line  @angular-eslint/component-selector
    selector: "button[adaptCollapsibleButton]",
    template: `<adapt-icon [icon]="iconClass"></adapt-icon>`,
    styles: `:host { border-radius: 1rem }`,
})
export class CollapsibleButtonComponent implements OnChanges {

    @Input() public adaptCollapsibleButton = true;
    @Output() public adaptCollapsibleButtonChange = new EventEmitter<boolean>();
    @HostBinding("class") public buttonClasses = "btn btn-blank";

    public iconClass = ExpandIconClass;

    public ngOnChanges() {
        this.iconClass = this.adaptCollapsibleButton
            ? ExpandIconClass
            : CollapseIconClass;
    }

    @HostListener("click")
    public onClick() {
        this.adaptCollapsibleButton = !this.adaptCollapsibleButton;
        this.adaptCollapsibleButtonChange.emit(this.adaptCollapsibleButton);
        this.ngOnChanges();
    }
}

<dx-select-box [dataSource]="dataSource"
               [(value)]="location"
               (valueChange)="locationChange.emit($event)"
               [searchEnabled]="true"
               [acceptCustomValue]="true"
               (onCustomItemCreating)="onCustomLocationCreating($event)"
               [disabled]="disabled || loading"
               [showClearButton]="!required"
               placeholder="Select or create a new location..."
               noDataText="No locations to select from. Type to define a new location."
               searchExpr="name"
               displayExpr="name">
    <div *dxTemplate="let item of 'item'">
        <i *ngIf="item.available !== undefined"
           class="fal fa-fw me-1"
           [ngClass]="{'fa-circle-check text-success': item.available, 'fa-circle-xmark text-danger': !item.available}"></i>
        {{item.name}}
    </div>
</dx-select-box>

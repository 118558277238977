import { DefaultBackFinishButtons, DefaultBackNextButtons, DefaultCancelProceedButtons, DefaultNextButtons, GuidedTour } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourUtils } from "@common/lib/guided-tour/guided-tour.utils";

export const SWTTour: GuidedTour = {
    steps: [{
        title: "Introduction",
        text: `<p>This tour will walk you through the process of recording strengths, weaknesses & trends.</p>
        <p>If you already know how to do it, you can cancel this tour.</p>`,
        buttons: DefaultCancelProceedButtons,
    }, {
        title: "Add a canvas",
        text: `<p>Your strengths, weaknesses & trends are recorded into a canvas. <b>Click on this button now</b> to create a canvas.</p>`,
        waitForAndAttachToElementSelector: `[data-tour="add-swt-canvas"]`,
        advanceOnAttachedElementEvent: "click",
        attachTo: {
            on: "left",
        },
        showOn: () => {
            // this step will only be shown if no canvas has been added
            return GuidedTourUtils.isElementVisibleWithDataTourSelector("add-swt-canvas");
        },
    }, {
        title: "Add strength",
        text: `<p>You can click here to record a new strength input.</p>`,
        attachTo: {
            on: "right",
        },
        waitForAndAttachToElementSelector: `[data-tour="input-strength"]`,
        buttons: DefaultNextButtons,
        canClickTarget: false,
    }, {
        title: "Menu items",
        text: `<p>This is the menu where you can access the page actions.</p>
        <p><b>Click on it now</b> to reveal the associated options.</p>`,
        attachTo: {
            on: "left",
        },
        advanceOnAttachedElementEvent: "click",
        waitForAndAttachToElementSelector: `[data-tour="inputs-menu"]`,
    }, {
        title: "Add canvas",
        text: `<p>This is the menu item where you can add a new canvas.</p>`,
        attachTo: {
            on: "left",
        },
        waitForAndAttachToElementSelector: ".dx-menu-item",
        elementSelectorTextContent: "Add canvas",
        canClickTarget: false,
        modalOverlayOpeningPadding: 0,
        buttons: DefaultNextButtons,
    }, {
        title: "Switch between canvases",
        text: `<p>After adding a new canvas you switch between canvases here.</p>`,
        waitForAndAttachToElementSelector: `[data-tour="inputs-canvas-select"]`,
        buttons: DefaultBackNextButtons,
        canClickTarget: false,
        attachTo: {
            on: "right",
        },
    }, {
        title: "End of strengths, weaknesses & trends tour",
        text: `<p>This concludes the tour to record strengths, weaknesses & trends inputs.</p>
        <p>Try recording some strengths, weaknesses and trends!</p>`,
        buttons: DefaultBackFinishButtons,
    }],
};

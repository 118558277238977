<adapt-dialog [maxWidth]="500">
    <h3 adapt-dialog-title>Provide feedback</h3>

    <ng-container adapt-dialog-content>
        <ng-container *ngIf="alreadyHasFeedback; else noFeedbackSubmitted">
            <h3>Thanks!</h3>
            <p>We have already received feedback for this coaching session. You may close this dialog now.</p>
        </ng-container>

        <ng-template #noFeedbackSubmitted>
            <ng-container *ngIf="canProvideFeedback; else cannotProvideFeedback">
                <h3>Please provide feedback for your coach and your session</h3>

                <p>We appreciate any feedback you can provide about our coach, the coaching session, or anything else
                    you would like to let us know about.</p>

                <div class="text-center">
                    <adapt-rating-stars [(rating)]="coachSession.feedbackRating"
                                        (ratingChange)="updateFeedbackState()"></adapt-rating-stars>
                </div>

                <h4>Comments</h4>
                <dx-text-area [(value)]="coachSession.feedbackComment"
                              (valueChange)="updateFeedbackState()"
                              placeholder="Enter your feedback here..."></dx-text-area>
            </ng-container>

            <ng-template #cannotProvideFeedback>
                <ng-container *ngIf="isSessionForCurrentPerson; else otherPersonSession">
                    <p>Sorry, you can't provide feedback for this coaching session until the session has been
                        completed.</p>
                    <p>If the session has finished, please follow up with your coach.</p>
                </ng-container>

                <ng-template #otherPersonSession>
                    <p>You cannot provide feedback for another person's coaching session.</p>
                    <p>If this is a mistake, please follow up with your coach.</p>
                </ng-template>
            </ng-template>
        </ng-template>
    </ng-container>

    <ng-container adapt-dialog-footer>
        <adapt-standard-dialog-buttons *ngIf="!alreadyHasFeedback && canProvideFeedback; else cannotSave"
                                       adapt-dialog-footer
                                       [discardConfirmationInstance]="instance"
                                       [saveIsDisabled]="!coachSession.feedbackComment && !coachSession.feedbackRating"></adapt-standard-dialog-buttons>

        <ng-template #cannotSave>
            <div class="ms-auto">
                <button adaptButton="close"
                        (click)="cancel()"></button>
            </div>
        </ng-template>
    </ng-container>
</adapt-dialog>

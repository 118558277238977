import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Objective } from "./objective";

export class ObjectiveReview extends BaseEntity<ObjectiveReview> {
    public objectiveId!: number;
    public reviewDismissed!: boolean;
    public whatWentWell!: string;
    public whatWentWrong!: string;
    public whatWeLearned!: string;

    public objective?: Objective;

    public get hasContent() {
        return !!this.whatWentWell || !!this.whatWentWrong || !!this.whatWeLearned;
    }
}

export const ObjectiveReviewBreezeModel = new BreezeModelBuilder("ObjectiveReview", ObjectiveReview)
    .hasSource()
    .isOrganisationEntity()
    .persistChangedEntity()
    .setPrimeSource("objective", "objectiveId")
    .build();

import { ChangeDetectorRef, Directive, Input, OnInit } from "@angular/core";
import { Entity } from "breeze-client";
import { DxiValidationRuleComponent } from "devextreme-angular/ui/nested";

@Directive({
    selector: "dxi-validation-rule[adaptEntity][adaptEntityProperty]",
})
export class EntityValidationRuleDirective implements OnInit {
    @Input("adaptEntity") public entity?: Entity;
    @Input("adaptEntityProperty") public property?: string;

    public constructor(
        private dxValidationRuleComponent: DxiValidationRuleComponent,
        private changeDetector: ChangeDetectorRef,
    ) {
        if (!this.dxValidationRuleComponent) {
            throw new Error("DxValidationRuleComponent is undefined! Has the version of DX or Angular changed?");
        }
    }

    public ngOnInit() {
        this.dxValidationRuleComponent.type = "custom";
        this.dxValidationRuleComponent.validationCallback = (event: any) => {
            if (!this.entity || !this.property) {
                event.rule.message = "You should specify the entity and property to validate";
                return false;
            }

            const errors = this.entity.entityAspect.getValidationErrors(this.property);
            event.rule.message = errors.map((e) => e.errorMessage).join("; ");
            return errors.length === 0;
        };

        // We must do this since we updated the component properties dynamically
        this.changeDetector.markForCheck();
    }
}

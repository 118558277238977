import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { DxSelectBoxModule } from "devextreme-angular";
import { AdaptLabellingModule } from "../../labelling/labeling.module";
import { LinkTeamDashboardComponent } from "./link-team-dashboard/link-team-dashboard.component";
import { SelectTeamComponent } from "./select-team/select-team.component";
import { TeamPrivateIconComponent } from "./team-private-icon/team-private-icon.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AdaptTooltipModule,
        AdaptLabellingModule,
        DxSelectBoxModule,
    ],
    exports: [
        LinkTeamDashboardComponent,
        TeamPrivateIconComponent,
        SelectTeamComponent,
    ],
    declarations: [
        LinkTeamDashboardComponent,
        TeamPrivateIconComponent,
        SelectTeamComponent,
    ],
})
export class AdaptTeamDashboardSharedModule { }

import { Component, Input, OnDestroy } from "@angular/core";
import { Purpose } from "@common/ADAPT.Common.Model/organisation/purpose";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { CommonPurposeVisionService } from "@org-common/lib/purpose-vision/common-purpose-vision.service";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";
import { Observable, ReplaySubject } from "rxjs";
import { switchMap, take, tap } from "rxjs/operators";
import { GuidingPhilosophyUiService } from "../guiding-philosophy-ui.service";

@Component({
    selector: "adapt-team-purpose-dashboard-element",
    templateUrl: "./team-purpose-dashboard-element.component.html",
})
export class TeamPurposeDashboardElementComponent extends BaseComponent implements OnDestroy {
    @Input() public set team(value: Team | undefined) {
        if (value) {
            this.team$.next(value);
        }
    }
    public team$ = new ReplaySubject<Team>(1);
    public purpose$ = new ReplaySubject<Purpose | undefined>(1);
    public canConfigureTeam$: Observable<boolean>;

    public constructor(
        purposeVisionService: CommonPurposeVisionService,
        private gpUiService: GuidingPhilosophyUiService,
        authService: AuthorisationService,
    ) {
        super();

        this.canConfigureTeam$ = this.team$.pipe(
            switchMap((team) => authService.promiseToGetHasAccess(CommonTeamsAuthService.ConfigureTeam, team)),
        );

        this.team$.pipe(
            switchMap((team) => purposeVisionService.getPurpose(team)),
            this.takeUntilDestroyed(),
        ).subscribe(this.purpose$);
    }

    @Autobind
    public editTeamPurpose() {
        return this.team$.pipe(
            take(1),
            switchMap((team) => this.gpUiService.editTeamPurpose(team)),
            tap((purpose) => this.purpose$.next(purpose)),
        );
    }

    public ngOnDestroy() {
        super.ngOnDestroy();

        this.team$.complete();
        this.purpose$.complete();
    }
}

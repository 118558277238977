<adapt-dialog [maxWidth]="720">
    <h3 adapt-dialog-title>Edit agenda for meeting: {{meeting.name}}</h3>
    <ng-container adapt-dialog-content>
        <adapt-edit-agenda [meeting]="meeting"
                           [saveOnChange]="false"
                           (agendaItemsChange)="agendaItems = $event"></adapt-edit-agenda>
    </ng-container>
    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [discardConfirmationInstance]="instance"></adapt-standard-dialog-buttons>
</adapt-dialog>

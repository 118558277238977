<dx-select-box [value]="person"
               (valueChange)="itemChange($event)"
               [dataSource]="dataSource"
               [disabled]="disabled"
               [showClearButton]="!required"
               displayExpr="fullName"
               [grouped]="true"
               groupTemplate="group"
               itemTemplate="item"
               [searchEnabled]="true"
               [searchExpr]="$any('fullName')"
               placeholder="Select person..."
               (onInitialized)="onSelectBoxInitialized($event)">
    <div *dxTemplate="let data of 'group'">
        <i [ngClass]="data.iconClass"></i>
        {{data.key}}
    </div>
    <div *dxTemplate="let data of 'item'"
         data-test="select-person-item">
        {{data.fullName}}
    </div>
    <dx-validator></dx-validator>
</dx-select-box>

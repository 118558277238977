import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { WorkflowDialogWidth, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { WorkflowStepGuidancePosition } from "@common/ADAPT.Common.Model/embed/workflow-step";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { buttonPreset } from "@common/ux/button/button-preset";
import { buildLocalWorkflow } from "@org-common/lib/workflow/workflow.interface";

const editStrategicGoalWorkflowId = "EditStrategicGoal";

export const EditStrategicGoalWorkflow = buildLocalWorkflow({
    name: "Strategic Goal",
    workflowId: editStrategicGoalWorkflowId,
    persistenceId: editStrategicGoalWorkflowId,
    type: WorkflowType.Workflow,
    dialogWidth: WorkflowDialogWidth.ExtraLarge,
    featuresToEnable: [FeatureName.StrategicGoals],
    steps: [{
        ordinal: 0,
        name: "Goal title & description",
        componentSelector: "adapt-edit-goal-location-name-description",
        articleSlug: ImplementationKitArticle.StrategicGoalLocationNameEdit,
        articlePosition: WorkflowStepGuidancePosition.Left,
    }, {
        ordinal: 1,
        name: "Measurables",
        componentSelector: "adapt-edit-goal-measurements",
        articleSlug: ImplementationKitArticle.StrategicGoalMeasurementsEdit,
        articlePosition: WorkflowStepGuidancePosition.Left,
        allowBack: true,
    }, {
        ordinal: 2,
        name: "Review",
        componentSelector: "adapt-edit-goal-review",
        allowBack: true,
        workflowStepNextText: buttonPreset.saveAndClose.buttonText,
        workflowStepNextIcon: `${buttonPreset.saveAndClose.iconClass} fa-fw`,
        articleSlug: ImplementationKitArticle.StrategicGoalReviewEdit,
        articlePosition: WorkflowStepGuidancePosition.Left,
    }],
});

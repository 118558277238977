import { Component, Inject, Input, Optional } from "@angular/core";
import { Zone, ZoneMetadata } from "@common/ADAPT.Common.Model/methodology/zone";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { KEY_FUNCTION_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { EMPTY, Observable, ReplaySubject } from "rxjs";
import { map, switchMap } from "rxjs/operators";

@Component({
    selector: "adapt-link-key-function",
    templateUrl: "./link-key-function.component.html",
    styleUrls: ["./link-key-function.component.scss"],
})
export class LinkKeyFunctionComponent {
    @Input() public set keyFunction(value: KeyFunction | undefined) {
        if (value) {
            this.keyFunction$.next(value);
        }
    }
    public keyFunction$ = new ReplaySubject<KeyFunction>(1);

    @Input() public showIcon = true;

    public href$: Observable<string | null>;
    public iconColour$: Observable<string>;
    public iconTooltip$: Observable<string>;
    public readonly iconClass = KeyFunction.IconClass;

    public constructor(
        rxjsBreezeService: RxjsBreezeService,
        @Inject(KEY_FUNCTION_PAGE) @Optional() keyFunctionPageRoute?: IAdaptRoute<{}>,
    ) {
        this.href$ = this.keyFunction$.pipe(
            switchMap((keyFunction) => keyFunctionPageRoute ? keyFunctionPageRoute.getRoute(keyFunction) : EMPTY),
        );

        this.iconColour$ = this.keyFunction$.pipe(
            switchMap((i) => rxjsBreezeService.entityPropertyChangedWithInitialEntity(i, "zone", "valueStreamId")),
            map((i) => {
                const zone = i.zone ?? Zone.EconomicEngine;
                return ZoneMetadata.Colour[zone];
            }),
        );
        this.iconTooltip$ = this.keyFunction$.pipe(
            switchMap((i) => rxjsBreezeService.entityPropertyChangedWithInitialEntity(i, "zone", "valueStreamId")),
            map((i) => {
                if (i.zone) {
                    return `This key function exists in the ${ZoneMetadata.Name[i.zone]} zone`;
                } else if (i.valueStream) {
                    return `This key function exists in the ${i.valueStream.name} value stream`;
                } else {
                    return `This key function exists in a value stream`;
                }
            }),
        );
    }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptEditorViewModule } from "@common/ux/html-editor/editor-view.module";
import { PreviewEmailDialogComponent } from "./preview-email-dialog-component/preview-email-dialog-component.component";


@NgModule({
    imports: [
        CommonModule,
        AdaptCommonDialogModule,
        AdaptButtonModule,
        AdaptEditorViewModule,
    ],
    declarations: [
        PreviewEmailDialogComponent,
    ],
    exports: [
        PreviewEmailDialogComponent,
    ],
})
export class AdaptEmailModule { }

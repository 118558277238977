<adapt-see-more-popup [minWidth]="500"
                      popupTitle="Strategic themes"
                      buttonText="View strategic themes">
    <ng-template>
        <div class="w-100 d-flex pb-3 align-items-end border-bottom">
            <adapt-strategic-guidance area="strategic goals"></adapt-strategic-guidance>
            <button adaptButton="primary"
                    class="ms-3"
                    [adaptBlockingClick]="addGoal">Add goal</button>
        </div>
        <adapt-strategic-goals *adaptIfAuthorised="EditStrategyGoal"
                               [isEditing]="true"
                               [showToolbarMenu]="false"></adapt-strategic-goals>
    </ng-template>
</adapt-see-more-popup>

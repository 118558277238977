import { Component, Input } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";

@Component({
    selector: "adapt-configure-organisation-profile",
    templateUrl: "./configure-organisation-profile.component.html",
})
export class ConfigureOrganisationProfileComponent extends ConfigureFeatureBase {
    public static readonly DefaultOrgImageUrl = "/content/images/default_org.png";
    public readonly defaultImageSrcOrg = ConfigureOrganisationProfileComponent.DefaultOrgImageUrl;

    @Input() public configItem?: IConfigItem;

    public organisation?: Organisation;

    constructor(
        configurationService: ConfigurationService,
        private orgService: OrganisationService,
    ) {
        super(configurationService);
    }

    public async initialiseData() {
        this.organisation = await this.orgService.promiseToGetOrganisation();
    }

    public onCancel() {
        // you can cancel the org image change, we should notify that the org image id has been changed again
        this.orgService.notifyOrganisationImageIdChanged();
        return super.onCancel();
    }

    public onImageIdentifierChange(value: string) {
        if (this.organisation!.imageIdentifier !== value) {
            this.organisation!.imageIdentifier = value;
            this.orgService.notifyOrganisationImageIdChanged();
        }
    }
}

<adapt-toolbar-title>
    <h3>
        <i class="fal fa-fw fa-list me-2"></i> Search Results
        <ng-container *ngIf="isValidQuery">
            <span *ngIf="searchResults && !isLoading"
                  class="ms-1">({{resultCount}})</span>
            <span *ngIf="isLoading"
                  class="ms-1">(...)</span>
        </ng-container>
    </h3>
</adapt-toolbar-title>

<div class="m-2">
    <div class="row">
        <ng-container *ngIf="isValidQuery; else noSearchQuery">
            <h4 class="align-items-center d-flex">
                Showing results for <b *ngIf="searchKeyword"
                                       class="ms-1">"{{searchKeyword}}".</b>
                <adapt-display-labels *ngIf="searchService.searchLabels$ | async as labels"
                                      class="ms-2"
                                      [labels]="labels"
                                      [linkDisabled]="true"></adapt-display-labels>
            </h4>

            <div class="search-options mb-3">
                <span *ngIf="!options?.activeOnly">Showing inactive results. </span>
                <span *ngIf="options?.personId as personId">Relating to
                    <adapt-link-person [personId]="personId"></adapt-link-person>. </span>
                <span *ngIf="options?.teamId as teamId">Within team
                    <adapt-link-team [teamId]="teamId"
                                     [showLabels]="false"></adapt-link-team>. </span>
                <span *ngIf="options?.updatedSince as since">Updated within {{since.text}}.</span>

                <div *ngIf="slowLoadingIndicator"
                     class="alert alert-info my-2 py-1">
                    Search is taking a while to return results. Please wait...
                </div>
            </div>

            <div *ngIf="searchResults && resultCount === 0 && !isLoading"
                 class="alert alert-info">
                No results found.
            </div>

            <div class="col"
                 [ngClass]="{'col-lg-8': isLoading && showImplementationKitResults && hasNonSupportResults}">
                <ng-container *ngFor="let group of searchResultGroups | keyvalue: originalOrder; trackBy: trackByResultGroup">
                    <div *ngIf="group.value && group.key as category"
                         class="mb-4">
                        <h4 class="mb-2 text-dark"
                            data-test="search-result-group">
                            <i [class]="category.icon + ' me-1'"></i> {{category.name}}
                        </h4>

                        <div *ngFor="let error of getErrors(category.type)"
                             class="alert alert-danger">{{error}}</div>

                        <div [ngClass]="{'border-start ps-3 ms-2 category-subgroup': !!category.subGroups}">
                            <ng-container *ngIf="!category.subGroups; else hasSubGroups"
                                          [ngTemplateOutlet]="searchComponent"
                                          [ngTemplateOutletContext]="{$implicit: searchResults}"></ng-container>

                            <ng-template #hasSubGroups>
                                <div *ngFor="let subGroup of searchResultSubgroups.get(category.type) | keyvalue: originalOrder; trackBy: trackByResultSubGroup"
                                     class="mb-3">
                                    <h5 class="mb-2 text-dark">
                                        <i [class]="subGroup.key.icon + ' me-1'"></i> {{subGroup.key.name}}
                                    </h5>
                                    <ng-container [ngTemplateOutlet]="searchComponent"
                                                  [ngTemplateOutletContext]="{$implicit: subGroup.value}"></ng-container>
                                </div>
                            </ng-template>

                            <ng-template #searchComponent
                                         let-results>
                                <adapt-render-component [component]="searchElements!.component"
                                                        [componentInputs]="{ type: category.type, searchResults: results }">
                                </adapt-render-component>
                            </ng-template>
                        </div>
                    </div>
                </ng-container>

                <div *adaptLoading="isLoading"></div>
            </div>

            <div *ngIf="showImplementationKitResults"
                 class="col-12"
                 [ngClass]="{'col-lg-4': hasNonSupportResults || isLoading}">
                <div *ngIf="SearchTypeMapping[SearchType.ImplementationKit] as group">
                    <h4 class="mb-2"><i [class]="group.icon + ' me-1'"></i> {{group.name}}</h4>

                    <div *ngFor="let error of getErrors(SearchType.ImplementationKit)"
                         class="alert alert-danger">{{error}}
                    </div>

                    <div class="implementation-kit-container p-3">
                        <div *adaptLoading="isLoading">
                            <adapt-search-row-implementation-kit *ngFor="let result of searchResults?.ImplementationKit"
                                                                 [result]="result"></adapt-search-row-implementation-kit>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>

    <ng-template #noSearchQuery>
        <h4 class="mt-3 mb-4">No search keyword or labels provided.</h4>
    </ng-template>
</div>

import { HttpClient, HttpParams } from "@angular/common/http";
import { Component } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { ServiceUri } from "@common/configuration/service-uri";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseDialogComponent, DialogResolveData } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { OrganisationFeatureFlagService } from "@org-common/lib/features/organisation-feature-flag.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { teamValuesRoute, valuesConstitutionRoute } from "@org-common/lib/values-constitution/values-constitution-page/values-constitution-page.route";
import { defer, lastValueFrom } from "rxjs";
import { finalize, switchMap, tap } from "rxjs/operators";

interface IMigrateValuesResponse {
    dryRun: boolean;
    teamIds: number[];
    organisation: boolean;
    ok: boolean;
}

@Component({
    selector: "adapt-migrate-values-dialog",
    templateUrl: "./migrate-values-dialog.component.html",
    styleUrls: ["./migrate-values-dialog.component.scss"],
})
export class MigrateValuesDialogComponent extends BaseDialogComponent<void> {
    public readonly dialogName = "MigrateValuesDialog";

    public loadingStatus = true;
    public migrationData?: IMigrateValuesResponse;
    public teamsToUpdate = new Map<Team, string>();
    public organisationValuesRoute$ = valuesConstitutionRoute.getRoute();

    public checkboxConfirmation = false;

    public constructor(
        private httpClient: HttpClient,
        private dialogService: AdaptCommonDialogService,
        private organisationService: OrganisationService,
        private teamsService: CommonTeamsService,
        private organisationFeatureFlagService: OrganisationFeatureFlagService,
    ) {
        super(DialogResolveData.NotRequired);

        this.migrateValues(true).subscribe();
    }

    @Autobind
    public async performMigrateValues() {
        try {
            const migrateResponse = await lastValueFrom(this.migrateValues(false));
            if (!migrateResponse?.ok) {
                throw new Error("Migrating values failed. Please refresh the values then try again.");
            }

            // get feature flags since server will set the flag on migration
            await lastValueFrom(this.organisationFeatureFlagService.getAllFeatureFlagsStatus(true));

            await lastValueFrom(this.dialogService.showMessageDialog(
                "Success",
                `Your organisation and teams have successfully been migrated to behaviour-based values.
                We recommend you go through all the values and rewrite them as behaviours rather than questions.`,
            ), { defaultValue: undefined });

            this.resolve();
        } catch (error) {
            const errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(error);
            this.setErrorMessage(errorMessage);
        }
    }

    @Autobind
    public migrateValues(dryRun = true) {
        const uri = `${ServiceUri.MethodologyServicesServiceBaseUri}/MigrateValues`;
        const params = new HttpParams()
            .set("organisationId", this.organisationService.getOrganisationId())
            .set("dryRun", dryRun);

        return defer(() => {
            this.loadingStatus = true;
            this.setErrorMessage(undefined);
            return this.httpClient.post<IMigrateValuesResponse>(uri, null, { params });
        }).pipe(
            switchMap(async (resp) => {
                this.teamsToUpdate.clear();

                const teams = await Promise.all(resp.teamIds.map((teamId) => lastValueFrom(this.teamsService.getTeamById(teamId))));
                for (const team of teams) {
                    if (team) {
                        const url = await lastValueFrom(teamValuesRoute.getRoute({ teamId: team.teamId }));
                        this.teamsToUpdate.set(team, url);
                    }
                }

                return resp;
            }),
            tap((resp) => this.migrationData = resp),
            finalize(() => this.loadingStatus = false),
        );
    }
}

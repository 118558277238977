import { AdaptError } from "@common/lib/error-handler/adapt-error";

export class StorageNotAvailableError extends AdaptError {
    public constructor(sourceErr?: Error) {
        let msg = "Local Storage is not available";

        if (sourceErr) {
            msg += `: ${sourceErr.message}`;
        }

        super(msg);
    }
}

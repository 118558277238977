import FroalaEditor, { ParagraphFormat } from "froala-editor";

export class ParagraphFormatFroalaAction {
    public static registerAction() {
        FroalaEditor.RegisterCommand("paragraphFormat", {
            paragraphFormat: FroalaEditor.PLUGINS.paragraphFormat as ParagraphFormat,
            title: "Paragraph Format",
            plugin: "paragraphFormat",
            type: "dropdown",
            displaySelectionWidth: 100,
            displaySelection(editor: FroalaEditor) {
                return editor.opts.paragraphFormatSelection;
            },
            defaultSelection(editor: FroalaEditor) {
                return editor.language.translate(editor.opts.paragraphDefaultSelection);
            },
            html() {
                const itemsHTML = Object.entries(this.opts.paragraphFormat)
                    .map(([option, text]) => {
                        const optionTitle = this.language.translate(text);
                        const tag = option === "N"
                            ? this.html.defaultTag() || "DIV"
                            : option;

                        return `<li role="presentation">` +
                            `<${tag} style="padding: 0 !important; margin: 0 !important;" role="presentation">` +
                            `<a class="fr-command"
                                   tabIndex="-1"
                                   role="option"
                                   data-cmd="paragraphFormat"
                                   data-param1="${option}"
                                   title="${optionTitle}">${optionTitle}</a>` +
                            `</${tag}>` +
                            `</li>`;
                    })
                    .join("\n");

                return `<ul class="fr-dropdown-list" role="presentation">${itemsHTML}</ul>`;
            },
            callback(_buttonName: string, param: any) {
                this.paragraphFormat.apply(param);
            },
            refresh(button: any) {
                this.paragraphFormat.refresh(button);
            },
            refreshOnShow(button: any, dropdown: any) {
                this.paragraphFormat.refreshOnShow(button, dropdown);
            },
        });
    }
}

<ng-container *adaptLoadingUntilFirstEmit="let outstandingSurveyResponses of outstandingSurveyResponses$">
    <adapt-universal-dashboard-element *ngIf="outstandingSurveyResponses!.length > 0"
                                       name="Outstanding Survey{{outstandingSurveyResponses!.length > 1 ? 's' : ''}}">
        <div class="d-flex flex-column gap-3">
            <div *ngFor="let outstandingSurveyResponse of outstandingSurveyResponses">
                <h4>{{getSurveyHeader(outstandingSurveyResponse.survey)}}</h4>
                <adapt-personal-ongoing-survey [outstandingSurveyResponse]="outstandingSurveyResponse"></adapt-personal-ongoing-survey>
            </div>
        </div>
    </adapt-universal-dashboard-element>
</ng-container>

import { Inject, Injectable, Injector, Optional } from "@angular/core";
import { Connection, ConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/connection";
import { Organisation, OrganisationBreezeModel } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Person, PersonBreezeModel } from "@common/ADAPT.Common.Model/person/person";
import { PersonPreferencesBreezeModel } from "@common/ADAPT.Common.Model/person/person-preferences";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BreezeService } from "@common/lib/data/breeze.service";
import { EntityPersistentService } from "@common/lib/data/entity-persistent.service";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { RouteEventsService } from "@common/route/route-events.service";
import { BaseService } from "@common/service/base.service";
import { IUserEventHandler, USER_EVENT_HANDLERS } from "@common/user/user-event-handler.interface";
import { firstValueFrom, lastValueFrom, merge, ReplaySubject, Subject } from "rxjs";
import { filter, first, skip } from "rxjs/operators";
import { IdentityService } from "../identity/identity.service";

@Injectable({
    providedIn: "root",
})
export class UserService extends BaseService {
    public static readonly ActiveOrganisationsKey = "activeOrganisations";
    public static readonly CurrentPersonKey = "currentPerson";

    private personEntityUpdated = new ReplaySubject<Person | undefined>(1);
    private userChanged = new Subject<Person | undefined>();

    public personEntityUpdated$ = this.personEntityUpdated.asObservable();
    public userChanged$ = this.userChanged.asObservable();

    private readonly _currentPerson$ = new ReplaySubject<Person | undefined>(1);
    private person?: Person;

    public constructor(
        injector: Injector,
        breezeService: BreezeService,
        private identityService: IdentityService,
        private entityPersistentService: EntityPersistentService,
        private routeEventsService: RouteEventsService,
        @Optional() @Inject(USER_EVENT_HANDLERS) private userEventHandlers: IUserEventHandler[],
    ) {
        super(injector);

        // Can't initialise inline as @Optional uses null instead of undefined
        // See https://github.com/angular/angular/issues/25395
        if (!this.userEventHandlers) {
            this.userEventHandlers = [];
        }

        // when data cache cleared, person entity from a previous entity manager will no longer
        // be valid - need to reinitialise
        breezeService.dataCacheCleared$.subscribe(() => this.initialise());

        // Don't bother resetting on startup if there isn't a user logged in
        merge(
            this.identityService.identity$.pipe(
                first(),
                filter((i) => !!i),
            ),
            this.identityService.identity$.pipe(
                skip(1),
            ),
        ).subscribe(() => this.resetDataCache());
    }

    public get currentPerson$() {
        return this._currentPerson$.asObservable();
    }

    public get currentPerson() {
        return this.person;
    }

    @Autobind
    public getCurrentPerson() {
        return firstValueFrom(this.currentPerson$);
    }

    @Autobind
    public getCurrentPersonId() {
        return this.person
            ? this.person.personId
            : undefined;
    }

    /**
     * Promise to get the active connections for the current person
     * @returns {Promise} A promise that resolves with the active connections
     */
    @Autobind
    public async getActiveConnectionsAndRolePermissions() {
        if (this.person) {
            const connections = (await this.promiseToGetActiveConnectionsAndRolePermissionsForPersonId(this.person.personId, true)) as Connection[];
            return connections;
        }

        return [];
    }

    // moved this over from user-data.service as it is only used here by the above function
    private promiseToGetActiveConnectionsAndRolePermissionsForPersonId(personId: number, forceRemote: boolean) {
        const options = {
            forceRemote,
            navProperty: "roleConnections.role.roleFeaturePermissions,person",
            namedParams: {
                activeOnly: true,
            },
            predicate: new MethodologyPredicate<Connection>("personId", "==", personId),
        };
        const requestKey = "[ActiveConnectionsAndRolePermissionsForPersonId]" + ConnectionBreezeModel.identifier + options.predicate.getKey();
        return lastValueFrom(this.commonDataService.getWithOptions(ConnectionBreezeModel, requestKey, options));
    }

    @Autobind
    public async setDefaultOrganisation(organisationId: number) {
        // find the organisation based on the id
        // (we cant set it directly as the organisation could have been destroyed by a previous cache clear)
        const organisation = await lastValueFrom(this.commonDataService.getById(OrganisationBreezeModel, organisationId));
        await this.addOrUpdateDefaultOrganisation(organisation!);
    }

    @Autobind
    public async getDefaultOrganisation() {
        const activeOrganisations = await this.getActiveOrganisationsForCurrentPerson();

        // get the active organisation from the prefs table
        let defaultOrganisation = this.person && this.person.preferences
            ? this.person.preferences.defaultOrganisation
            : undefined;

        if (!defaultOrganisation
            || !activeOrganisations.includes(defaultOrganisation)) {
            if (activeOrganisations.length) {
                defaultOrganisation = activeOrganisations[0];
            } else {
                defaultOrganisation = undefined;
            }

            // don't save here as this is a getter, called from org initialisation which will block save
        }

        return defaultOrganisation;
    }

    @Autobind
    public async getActiveOrganisationsForCurrentPerson() {
        // the filtering for this is done on the server side, as doing it here causes horrible performance issues

        const key = UserService.ActiveOrganisationsKey;
        const options = {
            namedParams: {
                allowStakeholderOverride: false,
            },
        };

        return lastValueFrom(this.commonDataService.getWithOptions(OrganisationBreezeModel, key, options));
    }

    @Autobind
    public notifyUserChanged() {
        this.userChanged.next(this.person);

        for (const tracker of this.userEventHandlers) {
            tracker.userChanged(this.person);
        }
    }

    @Autobind
    public async resetDataCache() {
        this.person = undefined;
        this.entityPersistentService.currentPersonId = -1;

        await lastValueFrom(this.commonDataService.clearCache());
        // don't have to add initialise to the promise chain as initialise will be called from the datacache cleared event
    }

    @Autobind
    private async initialise() {
        await this.identityService.promiseToDoIfLoggedIn(() => this.getPersonData(), () => this.setPerson(undefined));

        if (this.person) {
            this.personEntityUpdated.next(this.person);
        }
    }

    @Autobind
    private async getPersonData() {
        try {
            const person = await this.promiseToGetCurrentPerson();
            this.setPerson(person);
        } catch (e: any) {
            // get person failed -> let someone knows if this is subscribed to, e.g. login page
            this.routeEventsService.emitUserNavigationError(e);
            this.log.error(e);
            this.person = undefined;
        } finally {
            if (!this.person) {
                this.identityService.clearAuthenticationData();
                this.entityPersistentService.currentPersonId = -1;
            } else {
                this.entityPersistentService.currentPersonId = this.person.personId;
            }
        }
    }

    private async promiseToGetCurrentPerson() {
        const options = {
            authenticated: true,
            source: "Person",
            navProperty: "preferences,practitioners,methodologyUser",
            forceRemote: true,
            params: [],
        };

        const people = await lastValueFrom(this.commonDataService.getWithOptions(PersonBreezeModel, UserService.CurrentPersonKey, options));
        return ArrayUtilities.getSingleFromArray(people);
    }

    @Autobind
    private setPerson(person?: Person) {
        this.log.debug("Fetched Person details");
        this._currentPerson$.next(person);
        if (person) {
            this.person = person;
        } else {
            // user logout needs to notify listeners, e.g. shell factory etc.
            this.notifyUserChanged();
        }
    }

    @Autobind
    private async addOrUpdateDefaultOrganisation(organisation: Organisation) {
        if (!this.person || !organisation) {
            return;
        }

        const activeOrganisations = await this.getActiveOrganisationsForCurrentPerson();
        if (!activeOrganisations.includes(organisation)) {
            // only update if organisation is among the active organisations of the current person
            return;
        }

        if (!this.person.preferences) {
            await lastValueFrom(this.commonDataService.create(PersonPreferencesBreezeModel, { person: this.person }));
        }

        this.person.preferences.defaultOrganisation = organisation;

        await lastValueFrom(this.commonDataService.save());
    }
}

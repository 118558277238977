import { HttpErrorResponse } from "@angular/common/http";
import { Component, Input, OnInit } from "@angular/core";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { OutstandingSurveyResponse, OutstandingSurveyResponseStatus } from "@common/ADAPT.Common.Model/organisation/outstanding-survey-response";
import { Survey } from "@common/ADAPT.Common.Model/organisation/survey";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { UserService } from "@common/user/user.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DateFormats } from "@common/ux/date-formats";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import moment from "moment";
import { EMPTY, Observable } from "rxjs";
import { catchError, finalize, switchMap, take, tap } from "rxjs/operators";
import { SurveyService } from "../survey.service";
import { SurveyPageRoute } from "../survey-page/survey-page.route";

// This is only used in this component - if going to be used in other places, move it to outstanding-survey-response.ts next to the enum
const OutstandingResponseStatusDisplay: { [status in OutstandingSurveyResponseStatus]: string } = {
    New: "Not Sent",
    InvitationProcessed: "Invitation Sent",
    ReminderProcessed: "Reminder Sent",
    InvitationFailed: "Invitation Failed",
    ReminderFailed: "Reminder Failed",
};

@Component({
    selector: "adapt-view-survey-outstanding-responses",
    templateUrl: "./view-survey-outstanding-responses.component.html",
})
export class ViewSurveyOutstandingResponsesComponent extends BaseComponent implements OnInit {
    public readonly dialogName = "ViewSurveyOutstandingResponses";
    public readonly dateTimeDisplayFormat = DateFormats.globalize.shortdatetime;
    public readonly adaptProjectLabel = AdaptClientConfiguration.AdaptProjectLabel;

    @Input() public survey!: Survey;
    @Input() public showNote = true;
    @Input() public showEmail = true;

    public outstandingSurveyResponses$: Observable<OutstandingSurveyResponse[]> = EMPTY;
    public reloading: { [connectionId: number]: boolean } = {};
    public canCopySurveyLinks = false;
    public showEndTime = false;
    public minEndTime: Date = new Date();
    public errorMessage?: string;

    public constructor(
        private directorySharedService: DirectorySharedService,
        private authService: AuthorisationService,
        private surveyService: SurveyService,
        private userService: UserService,
    ) {
        super();
    }

    public ngOnInit() {
        this.showEndTime = true;//!!data.showEndTime;
        const dayAfterStart = moment(this.survey.startTime).add(1, "d").startOf("day");
        const today = moment().startOf("day");
        this.minEndTime = dayAfterStart.isAfter(today) ? dayAfterStart.toDate() : today.toDate();

        // allow survey link copies for stakeholder managers on embed, but any leader on hq
        this.authService.promiseToCheckIsStakeholderManager().then((isStakeholderManager) => this.canCopySurveyLinks = isStakeholderManager || (this.isAlto && this.userService.currentPerson!.isLeader));

        this.outstandingSurveyResponses$ = this.surveyService.getOutstandingSurveyResponsesForSurvey(this.survey.surveyId, this.survey.surveyId > 0)
            .pipe(
                switchMap(async (outstandingSurveyResponses) => {
                    await this.directorySharedService.promiseToGetAllConnections();
                    return outstandingSurveyResponses;
                }), // prime connection
                tap((outstandingSurveyResponses) =>  // prime person contacts; otherwise no email
                    outstandingSurveyResponses.forEach((o) => this.directorySharedService.promiseToGetContactDetailsByPersonId(o.connection!.personId))),
            );
    }

    public getEmail(rowData: OutstandingSurveyResponse) {
        return rowData.connection?.person?.getLoginEmail()?.value;
    }

    public getStatus(rowData: OutstandingSurveyResponse) {
        return OutstandingResponseStatusDisplay[rowData.status];
    }

    public resendEmail(token: string, connection: Connection) {
        this.reloading[connection.connectionId] = true;
        this.setErrorMessage();
        this.surveyService.resendSurveyEmail(token)
            .pipe(
                // update outstanding entity -> status may be updated from sending email
                switchMap(() => this.surveyService.getOutstandingSurveyResponsesForPerson(true, connection.personId)),
                finalize(() => this.reloading[connection.connectionId] = false),
                this.takeUntilDestroyed(),
            )
            .subscribe({
                next: () => this.setErrorMessage(),
                error: (err: HttpErrorResponse) => this.setErrorMessage(err.error.Message),
            });
    }

    public copySurveyLink(token: string) {
        SurveyPageRoute.getRoute({}, {
            token,
            name: this.survey.name,
        })
            .pipe(take(1))
            .subscribe((route) => window.navigator.clipboard.writeText(`${window.location.origin}${route}`));
    }

    public onEndTimeChanged(endTime: Date) {
        this.survey.endTime = endTime;
        this.surveyService.saveEntities(this.survey).pipe(
            catchError((e) => {
                const errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(e);
                this.setErrorMessage(errorMessage);
                throw e;
            }),
        ).subscribe();
    }

    private setErrorMessage(msg?: string) {
        this.errorMessage = msg;
    }
}

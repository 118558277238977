import { Injectable } from "@angular/core";
import { OrganisationDetail, OrganisationDetailBreezeModel } from "@common/ADAPT.Common.Model/organisation/organisation-detail";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { UserService } from "@common/user/user.service";
import { merge, switchMap } from "rxjs";
import { debounceTime, distinctUntilChanged, map, tap } from "rxjs/operators";
import { OrganisationService } from "../organisation/organisation.service";
import { OrganisationFeatureFlag, OrganisationFeatureFlagDefaults } from "./organisation-feature-flag.enum";

export type FeatureFlagMap = Map<OrganisationFeatureFlag, boolean>;

@Injectable({
    providedIn: "root",
})
export class OrganisationFeatureFlagService {
    public static readonly FlagEnabledValue = "true";
    public static readonly FlagDisabledValue = "false";

    public constructor(
        private organisationService: OrganisationService,
        private commonDataService: CommonDataService,
        private rxjsBreezeService: RxjsBreezeService,
    ) {}

    public getAllFeatureFlagsStatus(forceRemote?: boolean) {
        const definedFlags = Object.values(OrganisationFeatureFlag);
        const predicate = new MethodologyPredicate<OrganisationDetail>("name", "in", definedFlags);
        return this.commonDataService.getWithOptions(OrganisationDetailBreezeModel, predicate.getKey(OrganisationDetailBreezeModel.identifier), {
            predicate,
            // org details could be in cache already, use its key.
            encompassingKey: UserService.ActiveOrganisationsKey,
            forceRemote,
        }).pipe(
            map((details) => {
                const detailNameMap = new Map(details.map((d) => [d.name, d]));
                return new Map(definedFlags.map((flag) => {
                    const detail = detailNameMap.get(flag);
                    return [flag, this.isFeatureFlagDetailEnabled(flag, detail)];
                })) as FeatureFlagMap;
            }),
        );
    }

    public isFeatureFlagEnabled$(flag: OrganisationFeatureFlag) {
        return merge(
            this.rxjsBreezeService.entityTypeChanged(OrganisationDetail),
            this.organisationService.currentOrganisation$,
        ).pipe(
            debounceTime(100),
            switchMap(() => this.isFeatureFlagEnabled(flag)),
            distinctUntilChanged(),
        );
    }

    public isFeatureFlagEnabled(flag: OrganisationFeatureFlag) {
        return this.organisationService.getOrganisationDetail(flag).pipe(
            map((detail) => this.isFeatureFlagDetailEnabled(flag, detail)),
        );
    }

    public setFeatureFlagEnabled(flag: OrganisationFeatureFlag, enabled: boolean) {
        return this.organisationService.getOrCreateOrganisationDetail(flag).pipe(
            tap((detail) => this.setFeatureFlagDetailEnabled(detail, enabled)),
        );
    }

    private setFeatureFlagDetailEnabled(detail: OrganisationDetail, enabled: boolean) {
        detail.value = enabled
            ? OrganisationFeatureFlagService.FlagEnabledValue
            : OrganisationFeatureFlagService.FlagDisabledValue;
    }

    private isFeatureFlagDetailEnabled(flag: OrganisationFeatureFlag, detail?: OrganisationDetail) {
        // no detail set, use the default if exists
        if (!detail) {
            return OrganisationFeatureFlagDefaults[flag]?.() ?? false;
        }

        return detail.value === OrganisationFeatureFlagService.FlagEnabledValue;
    }
}

import { Component, Injector } from "@angular/core";
import { EventCadenceCycle } from "@common/ADAPT.Common.Model/organisation/event-cadence-cycle";
import { EventSeries } from "@common/ADAPT.Common.Model/organisation/event-series";
import { EventTypePreset } from "@common/ADAPT.Common.Model/organisation/event-type";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { MeetingAgendaItem } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { MeetingAgendaItemSupplementaryData } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item-supplementary-data";
import { MeetingAttendee } from "@common/ADAPT.Common.Model/organisation/meeting-attendee";
import { MeetingSupplementaryData } from "@common/ADAPT.Common.Model/organisation/meeting-supplementary-data";
import { CalendarIntegrationProvider } from "@common/ADAPT.Common.Model/organisation/organisation-detail";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { PreventNavigationGuard } from "@common/route/prevent-navigation.guard";
import { CalendarIntegrationUtilities } from "@org-common/lib/calendar/calendar-integration-utilities";
import { MeetingsService } from "@org-common/lib/meetings/meetings.service";
import { OAuthService } from "@org-common/lib/oauth/oauth.service";
import { IReviewRecurrencesMap } from "@org-common/lib/schedule/review-recurrences/review-recurrences.component";
import { ScheduleUiService } from "@org-common/lib/schedule/schedule-ui.service";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowMeetingService } from "@org-common/lib/workflow/workflow-meeting.service";
import { BehaviorSubject, lastValueFrom, of } from "rxjs";
import { CadenceStepBaseComponent } from "../cadence-step-base.component";

@WorkflowStepComponent("adapt-review-cadence-step")
@Component({
    selector: "adapt-review-cadence-step",
    templateUrl: "./review-cadence-step.component.html",
})
export class ReviewCadenceStepComponent extends CadenceStepBaseComponent {
    public workflowStepCompleted = new BehaviorSubject<boolean>(true);
    public recurrences?: IReviewRecurrencesMap;

    public started = false;
    public progressTotalCount = 0;
    public progressCurrentCount = 0;

    public authedWithMicrosoft = false;

    private removePreventNavigationCallback?: () => void;

    public constructor(
        injector: Injector,
        private oauthService: OAuthService,
        private meetingsService: MeetingsService,
        private workflowMeetingService: WorkflowMeetingService,
        private preventNavigationGuard: PreventNavigationGuard,
        private calendarIntegrationUtilities: CalendarIntegrationUtilities,
    ) {
        super(injector);

        this.subscribeToEmitForEntityTypeChange(injector, EventCadenceCycle);
        this.subscribeToEmitForEntityTypeChange(injector, EventSeries);
        this.subscribeToEmitForEntityTypeChange(injector, Meeting);
        this.subscribeToEmitForEntityTypeChange(injector, MeetingSupplementaryData);
        this.subscribeToEmitForEntityTypeChange(injector, MeetingAgendaItem);
        this.subscribeToEmitForEntityTypeChange(injector, MeetingAgendaItemSupplementaryData);
        this.subscribeToEmitForEntityTypeChange(injector, MeetingAttendee);
    }

    public async workflowStepOnInit() {
        await super.workflowStepOnInit();

        this.items = [];
        this.recurrences = new Map<EventTypePreset, EventSeries>();
        for (const { eventSeries, eventTypePreset } of this.runData.scheduledPresets.values()) {
            this.recurrences.set(eventTypePreset!, eventSeries);
            this.items.push(...eventSeries.meetings.map(ScheduleUiService.SchedulerItemFromMeeting));
        }

        this.authedWithMicrosoft = await lastValueFrom(this.oauthService.isAuthedWithProvider(CalendarIntegrationProvider.Microsoft));

        this.updateCadenceCycleDisplay();
    }

    public async workflowStepNext() {
        const scheduledPresets = Array.from(this.runData.scheduledPresets.values());

        const allEntities = ArrayUtilities.distinct(scheduledPresets
            .flatMap(({ eventSeries, deletedEntities = [] }) => [
                eventSeries,
                ...eventSeries.meetings,
                ...deletedEntities,
            ] as IBreezeEntity[])
            .concat([this.runData.eventCadenceCycle, ...this.runData.deletedEntities]));

        const needToSaveCount = this.getNeedToSaveEntityCount(allEntities);
        if (needToSaveCount === 0) {
            return;
        }

        this.progressTotalCount = needToSaveCount;
        this.started = true;

        this.removePreventNavigationCallback = this.preventNavigationGuard.registerCanNavigateCallback(() => of(!this.started));

        try {
            this.commonDataService.setSavingInProgress(true);

            // save each eventSeries independently
            // else there can be hundreds of entities in a single save and its very very slow...
            for (const { eventSeries } of scheduledPresets) {
                // save the event series
                await lastValueFrom(this.commonDataService.saveEntities([eventSeries]));
                this.progressCurrentCount++;

                let meetingSynced = false;

                // populate and save each meeting
                const meetings = eventSeries.extensions.getSortedMeetings();
                await Promise.all(meetings.map(async (meeting) => {
                    if (meeting.entityAspect.entityState.isAdded()) {
                        // add attendees
                        await lastValueFrom(this.meetingsService.createDefaultMeetingAttendees(meeting));

                        // populate supp data and agenda items
                        await lastValueFrom(this.workflowMeetingService.createWorkflowMeeting(meeting, eventSeries.eventType.meetingAgendaTemplate!));
                    }

                    await lastValueFrom(this.commonDataService.saveEntities(meeting.extensions.getAllMeetingEntities()));

                    // only sync first non-started meeting that has been added or modified
                    if (!meetingSynced
                        && meeting.extensions.isNotStarted
                        && (meeting.entityAspect.entityState.isAdded() || meeting.entityAspect.entityState.isModified())) {
                        await lastValueFrom(this.calendarIntegrationUtilities.createOrUpdateProviderMeeting(meeting, eventSeries.extensions.getMeetingLocation()));
                        meetingSynced = true;
                    }

                    // don't need to add the count of saved entities here since we only recorded the meeting entities
                    this.progressCurrentCount++;
                }));
            }

            // issue a final save for the deleted entities
            // already saved entities should be skipped
            const saveCount = this.getNeedToSaveEntityCount(allEntities);
            await lastValueFrom(this.commonDataService.saveEntities(allEntities));
            this.progressCurrentCount += saveCount;
        } catch (e) {
            const errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(e);
            this.workflowStepErrorMessage.next(errorMessage);
            throw new AdaptError(errorMessage);
        } finally {
            this.removePreventNavigationCallback?.();
            this.commonDataService.setSavingInProgress(false);
        }

        // TODO: re-enable calendar integration
        // return this.oauthService.isAuthedWithProvider(CalendarIntegrationProvider.Microsoft);
    }

    private getNeedToSaveEntityCount(entities: IBreezeEntity[]) {
        return entities
            .filter((entity) => entity.entityAspect.entityState.isAddedModifiedOrDeleted())
            .length;
    }
}

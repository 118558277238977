import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { SurveyTypeLabel } from "@common/ADAPT.Common.Model/organisation/survey";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { ResilientBusinessAssessmentAuthService } from "../resilient-business-assessment-auth.service";
import { ManageRBAssessmentsPageComponent } from "./manage-rb-assessments-page.component";

export const manageRBAssessmentsPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-manage-rb-assessments-page", ManageRBAssessmentsPageComponent)
    .atOrganisationUrl("/culture/resilient-business-assessment")
    .verifyingFeatures(FeatureName.CulturalNetworkResilientBusinessAssessment)
    .verifyingAccess(ResilientBusinessAssessmentAuthService.ReadResilientBusinessAssessment)
    .withTitle(SurveyTypeLabel.ResilientBusinessAssessment)
    .reloadOnSearch(false)
    .build();

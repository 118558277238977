<adapt-feature-guidance [configItem]="configItem">
    <p>Teams are the key building blocks of your organisation structure. This is where the work of the organisation is organised, tracked
        and done.</p>
</adapt-feature-guidance>

<adapt-feature-configuration [configItem]="configItem">
    <div *ngIf="teamRoleTypes">
        <div *ngFor="let roleType of teamRoleTypes"
             class="row">
            <div class="col-md-6">
                <h3>{{inputLabelForRoleTypeCode(roleType.code)}}</h3>
                <div class="form-group">
                    <label for="inputRoleTypeSingular-{{roleType.roleTypeId}}">Singular</label>
                    <dx-text-box id="inputRoleTypeSingular-{{roleType.roleTypeId}}"
                                 [adaptValidateEntity]="roleType"
                                 adaptValidateEntityProperty="defaultLabel"
                                 [(value)]="roleType!.defaultLabel"></dx-text-box>
                </div>
                <div class="form-group">
                    <label for="inputRoleTypePlural-{{roleType.roleTypeId}}">Plural</label>
                    <dx-text-box id="inputRoleTypeSingular-{{roleType.roleTypeId}}"
                                 [adaptValidateEntity]="roleType"
                                 adaptValidateEntityProperty="defaultLabelPlural"
                                 [(value)]="roleType!.defaultLabelPlural"></dx-text-box>
                </div>
            </div>
        </div>
    </div>
</adapt-feature-configuration>

import { BaseActiveEntity } from "../base-active-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Connection } from "./connection";

export class Position extends BaseActiveEntity<Position> {
    public positionId!: number;
    public connectionId!: number;
    public name!: string;
    public startDate!: Date;
    public endDate?: Date;

    public connection!: Connection;
}

export const PositionBreezeModel = new BreezeModelBuilder("Position", Position)
    .withIdField()
    .hasSource()
    .isOrganisationEntity()
    .build();

import { DateUtilities } from "@common/lib/utilities/date-utilities";
import moment from "moment";
import { MethodologyPredicate } from "./methodology-predicate";

export class BreezePredicateUtilities {

    /**
     * Gets a predicate that filters data optionally prefixed by path for an active state at an optionally provided point in time
     * @param {string} path The path to the object that has a startDate and endDate (defaults to the entity itself)
     * @param {DateTime} date The date at which point to check for active state (defaults to end of today)
     * @returns {MethodologyPredicate} A predicate that can be used for data filtering.
     */
    public static getIsActivePredicateByPath(path?: string, date?: Date) {
        path = BreezePredicateUtilities.generatePathPrefixFromPath(path);
        date = BreezePredicateUtilities.getDateOrDefault(date);

        const predicate = new MethodologyPredicate<never>(path + "startDate", "<=", date);
        const endDatePredicate = new MethodologyPredicate<never>(path + "endDate", "==", null);

        endDatePredicate.or(new MethodologyPredicate<never>(path + "endDate", ">=", date));
        predicate.and(endDatePredicate);

        return predicate;
    }

    /**
     * Gets a predicate that filters data for an active state at an optionally provided point in time
     * @param {DateTime} date The date at which point to check for active state (defaults to end of today)
     * @returns {MethodologyPredicate} A predicate that can be used for data filtering.
     */
    public static getIsActivePredicate(date?: Date) {
        return BreezePredicateUtilities.getIsActivePredicateByPath(undefined, date);
    }

    /**
     * Gets a predicate that returns records that exist up to and including yesterday (local time)
     * @param {string} dateField - the field to filter
     * @returns {object} predicate
     */
    public static getIsYesterdayOrEarlierPredicate(dateField: string) {
        return new MethodologyPredicate<never>(dateField, "<", DateUtilities.getStartOfTodayLocalTime());
    }

    /**
     * Gets a predicate that returns records that exist today and in the future (local time)
     * @param {string} dateField - the field to filter
     * @returns {object} predicate
     */
    public static getIsTodayOrLaterPredicate(dateField: string) {
        return new MethodologyPredicate<never>(dateField, ">=", DateUtilities.getStartOfTodayLocalTime());
    }

    private static getDateOrDefault(date?: Date) {
        if (!date) {
            date = moment.utc()
                .endOf("day")
                .toDate();
        }

        return date;
    }

    private static generatePathPrefixFromPath(path?: string) {
        return path
            ? path + "."
            : "";
    }
}

<!-- closed objective will need to be reopened before the status can be changed -->
<adapt-display-objective-status *ngIf="objective.isClosed && !includeClosed; else selectStatus"
                                [objectiveStatus]="ObjectiveStatus.Closed"
                                adaptTooltip="The status of a closed objective can only be changed if it is re-opened"></adapt-display-objective-status>
<ng-template #selectStatus>
    <dx-select-box class="h-100 transparent-background"
                   fieldTemplate="field"
                   [showDropDownButton]="false"
                   [items]="dataSource"
                   displayExpr="name"
                   [dropDownOptions]="{minWidth: 100}"
                   [value]="objective.status"
                   (onValueChanged)="onStatusChanged($event)"
                   [(opened)]="dropDownOpened">
        <div *dxTemplate="let statusMetadata of 'item'">
            <adapt-display-objective-status [objectiveStatus]="statusMetadata"></adapt-display-objective-status>
        </div>
        <div *dxTemplate="let statusMetadata of 'field'">
            <adapt-display-objective-status [objectiveStatus]="statusMetadata"
                                            [showRightChevronDown]="!dropDownOpened"
                                            [showRightChevronUp]="dropDownOpened"></adapt-display-objective-status>
            <dx-text-box class="d-none"
                         value="hidden element required by dxTemplate"
                         [readOnly]="true"></dx-text-box>
        </div>
    </dx-select-box>
</ng-template>

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { provideOrganisationConfigurationPageRoute, providePersonalConfigurationPageRoute, provideTeamConfigurationPageRoute } from "@common/page-route-providers";
import { provideDynamicNodeBuilder } from "@common/route/dynamic-node-builder";
import { AdaptToolbarModule } from "@common/shell/toolbar/toolbar.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AdaptCommonConfigurationModule } from "@org-common/lib/configuration/configuration.module";
import { ConfigurationAuthService } from "@org-common/lib/configuration/configuration-auth.service";
import { AdaptOrganisationSharedModule } from "@org-common/lib/organisation/organisation-shared.module";
import { ConfigurationDynamicNavigationService } from "./configuration-dynamic-navigation.service";
import { ConfigurePathwayCompletionComponent } from "./organisation-configuration-page/configure-pathway-completion/configure-pathway-completion.component";
import { OrganisationConfigurationPageComponent, organisationConfigurationPageRoute } from "./organisation-configuration-page/organisation-configuration-page.component";
import { PersonalConfigurationPageComponent } from "./personal-configuration-page/personal-configuration-page.component";
import { personalConfigurationPageRoute } from "./personal-configuration-page/personal-configuration-page.route";
import { TeamConfigurationPageComponent } from "./team-configuration-page/team-configuration-page.component";
import { teamConfigurationPageRoute } from "./team-configuration-page/team-configuration-page.route";


@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AdaptCommonConfigurationModule,
        AdaptOrganisationSharedModule,
        AdaptButtonModule,
        AdaptLoadingSpinnerModule,
        AdaptToolbarModule,
        AdaptTooltipModule,
        AdaptAuthorisationModule,
    ],
    declarations: [
        OrganisationConfigurationPageComponent,
        TeamConfigurationPageComponent,
        PersonalConfigurationPageComponent,
        ConfigurePathwayCompletionComponent,
    ],
    providers: [
        provideDynamicNodeBuilder(ConfigurationDynamicNavigationService),
        provideTeamConfigurationPageRoute(teamConfigurationPageRoute),
        providePersonalConfigurationPageRoute(personalConfigurationPageRoute),
        provideOrganisationConfigurationPageRoute(organisationConfigurationPageRoute),
    ],
})
export class AdaptConfigurationModule {
    public static readonly Routes = [
        ...organisationConfigurationPageRoute.routes,
        ...teamConfigurationPageRoute.routes,
        ...personalConfigurationPageRoute.routes,
    ];

    public constructor(authService: AuthorisationService) {
        ConfigurationAuthService.registerAccess(authService);
    }

}

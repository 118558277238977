import { ArgumentsType } from "@common/lib/arguments-type";
import { Logger } from "@common/lib/logger/logger";
import { firstValueFrom, forkJoin, Observable, ObservableInput, of } from "rxjs";
import { exhaustMap, mergeMap, shareReplay, switchMap, take } from "rxjs/operators";

export interface IInitialisable {
    initialisation$: Observable<any>;
}

export abstract class BaseInitialisationService implements IInitialisable {
    protected readonly log = Logger.getLogger(this.constructor.name);

    private _initialisation?: Observable<void>;

    /** Actions to perform on initialisation, once. Beware, these actions cannot be dependent on
     * the initialisation observable
     */
    protected initialisationActions(): ObservableInput<unknown>[] {
        return [];
    }

    public get initialisation$() {
        if (!this._initialisation) {
            const actions = this.initialisationActions();
            const initialisationActions$ = actions.length > 0
                ? forkJoin(actions).pipe(switchMap(() => of(void 0)))
                : of(void 0);

            this._initialisation = of([]).pipe(
                exhaustMap(() => initialisationActions$),
                shareReplay(1),
            );
        }

        return this._initialisation;
    }

    public resetInitialisation() {
        this._initialisation = undefined;
    }

    public waitUntilInitialised() {
        return this.initialisation$.pipe(take(1));
    }

    protected afterInitialisation<T extends (...args: any[]) => any>(originalFn: T) {
        return (...args: ArgumentsType<T>) => {
            return this.initialisation$.pipe(
                take(1),
                mergeMap(() => originalFn(...args) as ReturnType<T>),
            );
        };
    }

    protected promiseAfterInitialisation<T extends (...args: any[]) => any>(originalFn: T) {
        return async (...args: ArgumentsType<T>) => {
            // need take(1) here or you cannot get pass the following await - need the Observable to be completed!
            await firstValueFrom(this.initialisation$);
            return originalFn(...args) as ReturnType<T>;
        };
    }

    protected wrapAfterInitialisation<T extends (...args: any[]) => any>(getOriginalFn: () => T) {
        return (...args: ArgumentsType<T>) => {
            return this.initialisation$.pipe(
                take(1),
                mergeMap(() => {
                    const originalFn = getOriginalFn();
                    return originalFn(...args) as ReturnType<T>;
                }),
            );
        };
    }
}

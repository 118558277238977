import moment from "moment";
import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Person } from "../person/person";
import { IEntityWithLabelLocations } from "./entity-with-label-locations";
import { KeyResult } from "./key-result";
import { LabelLocation } from "./label-location";
import { ObjectiveComment } from "./objective-comment";
import { ObjectiveItemLink } from "./objective-item-link";
import { ObjectiveLink } from "./objective-link";
import { ObjectiveReview } from "./objective-review";
import { ObjectiveStatus } from "./objective-status";
import { ObjectiveType, ObjectiveTypeMetadata } from "./objective-type";
import { Organisation } from "./organisation";
import { Team } from "./team";

export class Objective extends BaseEntity<Objective> implements IEntityWithLabelLocations {
    public objectiveId!: number;
    public organisationId!: number;
    public teamId?: number;
    public parentObjectiveId?: number;
    public title!: string;
    public description?: string;
    public assigneePersonId?: number;
    public creationDate!: Date;
    public modifiedDate!: Date;
    public dueDate!: Date;
    public status!: ObjectiveStatus;
    public type!: ObjectiveType;
    public ordinal!: number;
    public index!: number;

    public organisation!: Organisation;
    public team?: Team;
    public parentObjective!: Objective;
    public childObjectives!: Objective[];
    public objectiveLinks!: ObjectiveLink[];
    public itemLinks!: ObjectiveItemLink[];
    public comments!: ObjectiveComment[];
    public assigneePerson?: Person;
    public keyResults!: KeyResult[];
    public labelLocations!: LabelLocation[];
    public objectiveReview?: ObjectiveReview;

    public get typeCode() {
        return this.metadata.code;
    }

    public get objectiveCode() {
        return `${this.typeCode}-${this.index}`;
    }

    public get typeName() {
        return this.metadata.name;
    }

    public get iconClass() {
        return this.metadata.iconClass;
    }

    private get metadata() {
        return ObjectiveTypeMetadata.ByType[this.type];
    }

    /** Get the progress, rounded to the nearest percent, of this objective */
    public get currentProgress() {
        if (this.keyResults.length === 0) {
            return 0;
        }

        const totalProgress = this.keyResults.reduce((total, next) => total + next.currentProgress, 0);
        return Math.round(totalProgress / this.keyResults.length);
    }

    public get isOrganisationObjective() {
        return !this.teamId;
    }

    public get hasKeyResults() {
        return this.keyResults.length > 0;
    }

    public get hasBeenScored() {
        return this.keyResults.some((kr) => kr.values.length > 0);
    }

    public get isMoreThanTwoWeeksOverdue() {
        if (!this.isClosed && this.status !== ObjectiveStatus.Complete) {
            return moment().diff(this.dueDate, "days") > 14;
        }

        return false;
    }

    public get isClosed() {
        return this.status === ObjectiveStatus.Closed;
    }

    public get hasClosedParent() {
        return !this.isClosed && !!this.parentObjective?.isClosed;
    }
}

export const ObjectiveBreezeModel = new BreezeModelBuilder("Objective", Objective)
    .hasSource()
    .withIdField()
    .withLabelField("title")
    .withSortField("title")
    .isOrganisationEntity()
    .orderBy("ordinal")
    .persistChangedEntity()
    .build();

<ng-container *ngIf="objective">
    <div class="d-flex align-items-center gap-1 objective-code"
         [attr.data-tour]="'objective-type-' + objective!.typeCode">
        <span *ngIf="showStatusIcon"
              [ngClass]="'status-icon-' + objective!.status.toLowerCase()"
              [adaptTooltip]="badgeStatus.name">
        </span>
        <adapt-link-person *ngIf="showAssigneeImage"
                           [person]="objective!.assigneePerson!"
                           [showImage]="true"
                           [showText]="false"
                           [imageSize]="'xs'"></adapt-link-person>
        <strong class="objective-type text-nowrap"
                [adaptTooltip]="codeTooltip">
            {{objective!.objectiveCode}}
        </strong>

        <adapt-team-private-icon *ngIf="showPrivateTeamIcon"
                                 [team]="objective.team"></adapt-team-private-icon>
    </div>

    <ng-container *ngIf="generateHref && (canReadObjective$ | async); else showObjectiveTitle">
        <a [routerLink]="(objectiveHref$ | async)"
           class="objective-title">{{objective!.title}}</a>
    </ng-container>
    <ng-template #showObjectiveTitle>
        <span class="objective-title">{{objective!.title}}</span>
    </ng-template>
    <button adaptButtonIcon="delete"
            *ngIf="showHoverDeleteButton"
            class="btn btn-link delete-button"
            (click)="deleted.emit(objective)"></button>

    <adapt-display-objective-status *ngIf="showStatus"
                                    class="ms-auto"
                                    [objectiveStatus]="objective!.status"
                                    [isCompactView]="true"></adapt-display-objective-status>
</ng-container>

import { HttpErrorResponse } from "@angular/common/http";
import { Component, ElementRef, Injector, OnInit } from "@angular/core";
import { IdentityService } from "@common/identity/identity.service";
import { loginPageRoute } from "@common/identity/ux/login-page/login-page.route";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { PageRouteBuilder } from "@common/route/page-route-builder";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { catchError, finalize, throwError } from "rxjs";
import { personalDashboardPageRoute } from "../../person/personal-dashboard-page/personal-dashboard-page.route";
import { AccountService, IConfirmEmailBindingModel } from "../account.service";

const EmailSearchParam = "email";
const TokenSearchParam = "token";
const OrganisationIdSearchParam = "organisationId";

@Component({
    selector: "adapt-confirm-email-page",
    templateUrl: "./confirm-email-page.component.html",
})
export class ConfirmEmailPageComponent extends BaseRoutedComponent implements OnInit {
    public errorMessage?: string;
    public emailConfirmed = false;
    public isProcessing = false;

    public constructor(
        injector: Injector,
        elementRef: ElementRef,
        private identityService: IdentityService,
        private accountService: AccountService,
    ) {
        super(injector, elementRef);
    }

    public ngOnInit() {
        this.notifyActivated();

        const confirmEmailData: IConfirmEmailBindingModel = {
            email: this.getSearchParameterValue(EmailSearchParam),
            token: this.getSearchParameterValue(TokenSearchParam),
            organisationId: this.getSearchParameterIntValue(OrganisationIdSearchParam),
        };
        if (confirmEmailData.email && confirmEmailData.token) {
            this.isProcessing = true;
            this.accountService.confirmEmail(confirmEmailData).pipe(
                catchError((e: HttpErrorResponse) => {
                    this.errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(e);
                    return throwError(() => new Error(this.errorMessage));
                }),
                this.takeUntilDestroyed(),
                finalize(() => this.isProcessing = false),
            ).subscribe(() => this.emailConfirmed = true);
        } else {
            this.errorMessage = "Missing email or token. Cannot confirm email.";
        }
    }

    public async goHome() {
        const isLoggedIn = await this.identityService.promiseToCheckIsLoggedIn();
        return isLoggedIn
            ? personalDashboardPageRoute.gotoRoute().subscribe()
            : loginPageRoute.gotoRoute().subscribe();
    }
}

export const EmailConfirmationPageRoute = new PageRouteBuilder()
    .usingNgComponent("adapt-confirm-email-page", ConfirmEmailPageComponent)
    .atUrl("/account/confirm-email")
    .requiresLogin(false)
    .requiresAnonymous(false)
    .build();

import { Injectable, Injector } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { BaseService } from "@common/service/base.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { from } from "rxjs";
import { ISurveyAuthService } from "../survey-auth.service.interface";

@Injectable({
    providedIn: "root",
})
export class EmployeeEngagementAuthService extends BaseService implements ISurveyAuthService {
    public static readonly EditEmployeeEngagement = "editEmployeeEngagement";
    public static readonly ReadEmployeeEngagement = "readEmployeeEngagement";

    public constructor(
        injector: Injector,
        private authService: AuthorisationService,
        private featuresService: FeaturesService,
    ) {
        super(injector);
    }

    public static registerAccessVerifiers(authorisationService: AuthorisationService) {
        authorisationService.registerAccessVerifier(
            EmployeeEngagementAuthService.EditEmployeeEngagement,
            {
                requirePermissions: [
                    FeaturePermissionName.CulturalNetworkEmployeeEngagementEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            EmployeeEngagementAuthService.ReadEmployeeEngagement,
            {
                requirePermissions: [
                    FeaturePermissionName.CulturalNetworkEmployeeEngagementEdit,
                    FeaturePermissionName.CulturalNetworkEmployeeEngagementRead,
                ],
            },
        );
    }

    public isFeatureActive() {
        return this.featuresService.isFeatureActive(FeatureName.CulturalNetworkEmployeeEngagement);
    }

    public hasReadAccessToSurveys$() {
        return from(this.authService.promiseToGetHasAccess(EmployeeEngagementAuthService.ReadEmployeeEngagement));
    }

    public hasEditAccessToSurveys$() {
        return from(this.authService.promiseToGetHasAccess(EmployeeEngagementAuthService.EditEmployeeEngagement));
    }
}

import { Injectable } from "@angular/core";
import { ServiceUri } from "@common/configuration/service-uri";
import { StringUtilities } from "@common/lib/utilities/string-utilities";

export interface IStorageUriParams {
    itemId?: string;
    ownerId?: number;
    v?: number;
}

export interface IStoreImageUriParameters extends IStorageUriParams {
    maxWidth?: number;
    maxHeight?: number;
}

// response type from /StorageController/Store{File} if the server update to azure blob storage is successful
// (link and guid unknown otherwise)
export interface IStoreResponseType {
    link?: string;
    guid?: string;
}

@Injectable({
    providedIn: "root",
})
export class StorageService {
    public storeUri = this.exposeUri("Store");
    public storeFileUri = this.exposeUri("StoreFile");
    public retrieveUri = this.exposeUri("Retrieve");
    public retrieveFileUri = this.exposeUri("RetrieveFile");
    public replaceFileUri = this.exposeUri("ReplaceFile");

    public storeImageUri = this.exposeUri<IStoreImageUriParameters>("StoreImage");
    public replaceImageUri = this.exposeUri<IStoreImageUriParameters>("ReplaceImage");

    private exposeUri<T extends IStorageUriParams = IStorageUriParams>(partialUri: string) {
        return (viewModel?: T) => {
            const uri = `${ServiceUri.StorageServiceBaseUri}/${partialUri}`;
            return StringUtilities.buildUrl(uri, { ...viewModel });
        };
    }
}

import { DefaultCancelProceedButtons, DefaultFinishButtons, DefaultNextButtons, GuidedTour } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourUtils } from "@common/lib/guided-tour/guided-tour.utils";

export const RecordAnchorsTour: GuidedTour = {
    steps: [{
        title: "Recording Anchors",
        text: `<p>This tour will walk you through the process of recording strategic anchors.</p>
        <p>If you already know how to do it, you can cancel this tour now.</p>`,
        buttons: DefaultCancelProceedButtons,
    }, {
        title: "Adding a new anchor",
        text: `<p>To add an anchor, use the <b>'Add Anchor'</b> from the toolbar here.</p>
        <p><b>Click on it now</b> to display a dialog for you to fill in the name and description.</p>`,
        waitForAndAttachToElementSelector: `[data-tour="add-anchor-button"]`,
        attachTo: {
            on: "bottom",
        },
        modalOverlayOpeningPadding: 3,
        advanceOnAttachedElementEvent: "click",
    }, {
        title: "An anchor name is required",
        text: `<p>An anchor name is required to define a strategic anchor as it provides a clear and concise identifier for the anchor.</p>`,
        waitForAndAttachToElementSelector: `[data-tour="anchor-name-field"]`,
        attachTo: {
            on: "bottom",
        },
        modalOverlayOpeningPadding: 3,
        buttons: DefaultNextButtons,
    }, {
        title: "Saving your strategic anchor",
        text: `<p>After the anchor name is defined, you will then be able to save the anchor using this button.</p>
        <p>This will be disabled if you haven't typed anything in the name field.</p>`,
        waitForAndAttachToElementSelector: `[data-tour="standard-dialog-save-button"]`,
        attachTo: {
            on: "top",
        },
        modalOverlayOpeningPadding: 3,
        buttons: DefaultNextButtons,
    }, {
        title: "Cancel adding the anchor",
        text: `<p>For now, <b>Click on the cancel button</b> to cancel adding the anchor</p>`,
        waitForAndAttachToElementSelector: `[data-tour="standard-dialog-cancel-button"]`,
        attachTo: {
            on: "top",
        },
        modalOverlayOpeningPadding: 3,
        advanceOnAttachedElementEvent: "click",
        showOn: () => {
            // this step will only be shown if you haven't saved the previous addition
            const cancelButton = GuidedTourUtils.getFirstElementWithTextContent(`[data-tour="standard-dialog-cancel-button"]`);
            return GuidedTourUtils.isElementVisible(cancelButton);
        },
    }, {
        title: "Discard confirmation",
        text: `<p>This will discard any changes you have made in the dialog.</p>
        <b>Click on the discard button</b> now to discard.`,
        waitForAndAttachToElementSelector: `[data-tour="standard-dialog-save-button"]`,
        elementSelectorTextContent: "Discard",
        attachTo: {
            on: "bottom",
        },
        modalOverlayOpeningPadding: 3,
        advanceOnAttachedElementEvent: "click",
        showOn: () => {
            const cancelButton = GuidedTourUtils.getFirstElementWithTextContent(`[data-tour="standard-dialog-save-button"]`, "Discard");
            return GuidedTourUtils.isElementVisible(cancelButton);
        },
    }, {
        title: "Edit strategic anchor",
        text: `<p>Use this button to edit the strategic anchor.</p>
        <p><b>Click on it now</b></p>`,
        waitForAndAttachToElementSelector: `[data-tour="edit-anchor-button"]`,
        attachTo: {
            on: "bottom",
        },
        advanceOnAttachedElementEvent: "click",
        showOn: () => {
            // this step will only be shown if you have an anchor
            const editAnchorButton = GuidedTourUtils.getFirstElementWithTextContent(`[data-tour="edit-anchor-button"]`);
            return GuidedTourUtils.isElementVisible(editAnchorButton);
        },
    }, {
        title: "Deleting strategic anchor",
        text: `<p>Use this button to delete the strategic anchor.</p>
        <p>This is not clickable during the tour as it would disrupt the flow. You can try interacting with it later after completing the tour.</p>`,
        waitForAndAttachToElementSelector: `[data-tour="delete-anchor-button"]`,
        attachTo: {
            on: "right",
        },
        canClickTarget: false,
        showOn: () => {
            // this step will only be shown if you have an anchor
            const editAnchorButton = GuidedTourUtils.getFirstElementWithTextContent(`[data-tour="delete-anchor-button"]`);
            return GuidedTourUtils.isElementVisible(editAnchorButton);
        },
        buttons: DefaultNextButtons,
    }, {
        title: "Cancel editing the anchor",
        text: `<p>For now, <b>Click on the cancel button</b> to cancel editing the anchor</p>`,
        waitForAndAttachToElementSelector: `[data-tour="standard-dialog-cancel-button"]`,
        attachTo: {
            on: "top",
        },
        modalOverlayOpeningPadding: 3,
        advanceOnAttachedElementEvent: "click",
        showOn: () => {
            const cancelButton = GuidedTourUtils.getFirstElementWithTextContent(`[data-tour="standard-dialog-cancel-button"]`);
            return GuidedTourUtils.isElementVisible(cancelButton);
        },
    }, {
        title: "Reordering anchors",
        text: `<p>This button will be allow you to reorder your strategic anchors.</p>`,
        waitForAndAttachToElementSelector: `[data-tour="reorder-anchors-button"]`,
        attachTo: {
            on: "bottom",
        },
        canClickTarget: false,
        modalOverlayOpeningPadding: 3,
        showOn: () => {
            const cancelButton = GuidedTourUtils.getFirstElementWithTextContent(`[data-tour="reorder-anchors-button"]`);
            return GuidedTourUtils.isElementVisible(cancelButton);
        },
        buttons: DefaultNextButtons,
    }, {
        title: "End of record anchors tour",
        text: `<p>This concludes the tour to record strategic anchors.</p>
        <p>Now, proceed to record some anchors for your organisation.</p>`,
        buttons: DefaultFinishButtons,
    }],
};

import { Component, OnInit } from "@angular/core";
import { Purpose } from "@common/ADAPT.Common.Model/organisation/purpose";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { MeetingAgendaItemComponent } from "@org-common/lib/meetings/meeting-agenda-component-registry";
import { CommonPurposeVisionService } from "@org-common/lib/purpose-vision/common-purpose-vision.service";
import { CommonPurposeVisionAuthService } from "@org-common/lib/purpose-vision/common-purpose-vision-auth.service";
import { lastValueFrom, switchMap } from "rxjs";
import { tap } from "rxjs/operators";
import { PurposeVisionUiService } from "../../../../purpose-vision/purpose-vision-ui.service";

@MeetingAgendaItemComponent("adapt-purpose-workshop-record-purpose")
@Component({
    selector: "adapt-purpose-workshop-record-purpose",
    templateUrl: "./purpose-workshop-record-purpose.component.html",
    styleUrls: ["./purpose-workshop-record-purpose.component.scss"],
})
export class PurposeWorkshopRecordPurposeComponent extends BaseComponent implements OnInit {
    public purpose?: Purpose;
    public readonly CompassGuidingPhilosophyEdit = CommonPurposeVisionAuthService.EditPurposeVision;

    public constructor(
        private purposeVisionService: CommonPurposeVisionService,
        private purposeVisionUiService: PurposeVisionUiService,
    ) {
        super();
    }

    public async ngOnInit() {
        this.purpose = await lastValueFrom(this.purposeVisionService.getPurpose());
    }

    @Autobind
    public recordPurposeStatement() {
        return this.purposeVisionService.getOrCreatePurpose().pipe(
            tap((purpose) => this.purpose = purpose),
            switchMap((purpose) => this.purposeVisionUiService.editPurpose(purpose)),
        );
    }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptToolbarModule } from "@common/shell/toolbar/toolbar.module";
import { AdaptCommonUserModule } from "@common/user/adapt-common-user.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptLinkDisabledModule } from "@common/ux/link-disabled/link-disabled.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { DxDataGridModule, DxPopoverModule, DxSelectBoxModule, DxTagBoxModule, DxTextBoxModule, DxValidatorModule } from "devextreme-angular";
import { AdaptArchitectureModule } from "../architecture/architecture.module";
import { AdaptTeamDashboardSharedModule } from "../teams/team-dashboard-shared/team-dashboard-shared.module";
import { LinkPersonComponent } from "./link-person/link-person.component";
import { PersonLinkContentsComponent } from "./link-person-contents/link-person-contents.component";
import { PeopleGridComponent } from "./people-grid/people-grid.component";
import { SelectPeopleComponent } from "./select-people/select-people.component";
import { SelectPersonComponent } from "./select-person/select-person.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AdaptCommonUserModule,
        AdaptCommonDialogModule,
        AdaptToolbarModule,
        AdaptArchitectureModule,
        AdaptTeamDashboardSharedModule,
        AdaptLoadingSpinnerModule,
        AdaptButtonModule,
        AdaptTooltipModule,
        DxPopoverModule,
        DxSelectBoxModule,
        DxValidatorModule,
        DxDataGridModule,
        DxTagBoxModule,
        AdaptLinkDisabledModule,
        DxTextBoxModule,
    ],
    declarations: [
        LinkPersonComponent,
        PersonLinkContentsComponent,
        SelectPersonComponent,
        PeopleGridComponent,
        SelectPeopleComponent,
    ],
    exports: [
        LinkPersonComponent,
        SelectPersonComponent,
        PeopleGridComponent,
        SelectPeopleComponent,
    ],
})
export class AdaptDirectorySharedModule { }

import { Component, EventEmitter, HostListener, Input, Output } from "@angular/core";
import { GoalStatus, GoalStatusLabel, OrderedGoalStatuses } from "@common/ADAPT.Common.Model/organisation/goal";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { IDxSelectBoxValueChangedEvent } from "@common/ux/dx.types";

@Component({
    selector: "adapt-select-goal-status",
    templateUrl: "./select-goal-status.component.html",
    styleUrls: ["./select-goal-status.component.scss"],
})
export class SelectGoalStatusComponent {
    public readonly OrderedGoalStatuses = OrderedGoalStatuses;
    public readonly GoalStatus = GoalStatus;

    @Input() public status?: GoalStatus;
    @Output() public statusChange = new EventEmitter<GoalStatus>();

    public selectOpen = false;

    public constructor(private commonDialogService: AdaptCommonDialogService) { }

    public statusDisplayExpr(status: GoalStatus) {
        return GoalStatusLabel[status];
    }

    // Need this so that button click on this component won't be propagated through to it parent, e.g.
    // the card header where click on it will toggle collapse.
    @HostListener("click", ["$event"])
    public stopPropagation(e: Event) {
        e.stopPropagation();
    }

    public onStatusChanged(e: IDxSelectBoxValueChangedEvent<GoalStatus>) {
        if (e.value !== GoalStatus.Closed) {
            this.statusChange.emit(e.value);
            return;
        }

        const confirmationDialog: IConfirmationDialogData = {
            title: "Archive goal",
            message: "Are you sure you want to archive this goal? This will hide the goal in the standard view.",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
        };

        this.commonDialogService.openConfirmationDialogWithBoolean(confirmationDialog)
            .subscribe((confirmed) => {
                if (confirmed) {
                    this.statusChange.emit(e.value);
                } else {
                    e.component!.option("value", e.previousValue);
                }
            });
    }
}

import { DefaultFinishButtons, GuidedTour } from "@common/lib/guided-tour/guided-tour.service";

export const RecordObjectivesFeatureTour: GuidedTour = {
    id: "RecordObjectivesFeatureTour",
    saveRunStatus: true,
    steps: [{
        title: "Click here to add a new objective",
        text: `<p>To add a new objective, click on the <i>Add Objective</i> button on the toolbar.</p>`,
        waitForAndAttachToElementSelector: `[data-tour="add-objective-button"]`,
        attachTo: {
            on: "bottom",
        },
        advanceOnAttachedElementEvent: "click",
        modalOverlayOpeningPadding: 3,
        buttons: DefaultFinishButtons,
    }],
};

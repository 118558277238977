import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";

export type ResizeHandler = (width: number, height: number, previousWidth: number, previousHeight: number) => void;

export class ElementResizeHandler {
    private handlers: ResizeHandler[];
    private pollHandle?: number;
    private previousWidth?: number;
    private previousHeight?: number;

    public constructor(
        private element: JQuery<any>,
    ) {
        this.handlers = [];
    }

    public get isWatching() {
        return !!this.pollHandle;
    }

    public registerHandler(handler: ResizeHandler) {
        this.handlers.push(handler);
    }

    public get isSizeInitialised() {
        return typeof this.previousWidth === "number" && typeof this.previousHeight === "number";
    }

    public initialiseSize(width?: number, height?: number) {
        this.previousWidth = width;
        this.previousHeight = height;
    }

    public startWatching() {
        // using interval to check for size changes as there are no
        // resize event on div (element.onresize not fired - only works for window.onresize).
        // scope.$watch(fn) will only trigger when the mouse is over the div.
        // There is no way to detect div size changes.
        this.pollHandle = window.setInterval(this.pollSizeChange, 500);
    }

    public stopWatching() {
        if (this.pollHandle) {
            window.clearInterval(this.pollHandle);
            this.pollHandle = undefined;
        }
    }

    @Autobind
    private pollSizeChange() {
        const element = this.element;
        let elementWidth = element.width();
        const elementHeight = element.height();

        // if the element width is not reported correctly (e.g. SVG chart element for example),
        // then attempt to get it from the first child
        if (elementWidth === 0
            && elementHeight === 0
            && element.length > 0
            && element.children().length > 0) {
            elementWidth = element.children().first().width();
            // not setting elementHeight here since it causes unnecessary resizes
            // elementHeight = (element[0].firstChild as Element).clientHeight;
        }

        if (this.previousWidth !== elementWidth || this.previousHeight !== elementHeight) {
            this.handlers.forEach((h) => h(elementWidth!, elementWidth!, this.previousWidth!, this.previousHeight!));
            this.previousWidth = elementWidth;
            this.previousHeight = elementHeight;
        }
    }
}

import { Component, OnInit } from "@angular/core";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { LabelLocation } from "@common/ADAPT.Common.Model/organisation/label-location";
import { IAdaptLinkObject } from "@common/route/route.service";
import { KanbanService } from "@org-common/lib/kanban/kanban.service";
import { personalKanbanPageRoute, teamKanbanPageRoute } from "@org-common/lib/kanban/kanban-page/kanban-page.route";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { SearchService } from "@org-common/lib/search/search.service";
import { SearchRowLabelledBaseComponent } from "@org-common/lib/search/search-row-labelled-base.component";
import { EMPTY, lastValueFrom, Observable } from "rxjs";
import { ICommentSearchResultExtra, IKanbanSearchResult, ISearchResultMatch } from "../search-results.interface";

@Component({
    selector: "adapt-search-row-kanban",
    templateUrl: "./search-row-kanban.component.html",
})
export class SearchRowKanbanComponent extends SearchRowLabelledBaseComponent<IKanbanSearchResult> implements OnInit {
    public href$?: Observable<IAdaptLinkObject> = EMPTY;

    public labelLocations: LabelLocation[] = [];
    public item?: Item;

    public constructor(
        protected searchService: SearchService,
        protected labellingService: LabellingService,
        private kanbanService: KanbanService,
    ) {
        super(searchService, labellingService);
    }

    public async ngOnInit() {
        await super.ngOnInit();

        // TODO: use the logic in auto-link to grab the item from the local cache first?
        // getItemByCodeOrId will prime the board if not already present as well
        this.item = await lastValueFrom(this.kanbanService.getItemByCodeOrId(this.result.itemId.toString()));
        const searchParams = { item: this.result.itemId };

        if (!this.result.teamId) {
            this.href$ = personalKanbanPageRoute.getRouteObject({}, searchParams);
        } else {
            this.href$ = teamKanbanPageRoute.getRouteObject({ teamId: this.result.teamId }, searchParams);
        }

        this.matches = this.withoutLastBreadcrumb(this.excludeBreadcrumbs(["Summary", "Code"], this.result.results));
    }

    public asMatch(match: any) {
        return match as ISearchResultMatch;
    }

    public isComment(match?: ISearchResultMatch) : match is ISearchResultMatch<ICommentSearchResultExtra> {
        return !!match?.extra?.commentId;
    }
}

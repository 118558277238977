<div class="ongoing-survey-card">
    <div class="ongoing-survey-card-container">
        <div *ngIf="outstandingSurveyResponse?.survey"
             class="d-flex flex-row justify-content-between">
            <adapt-display-survey [survey]="outstandingSurveyResponse.survey"
                                  [hideParticipation]="true"></adapt-display-survey>
            <button class="ms-3 btn-primary"
                    adaptButtonIcon="add"
                    (click)="completeSurvey()"
                    data-test="complete-survey-button">Complete survey
            </button>
        </div>
    </div>
</div>

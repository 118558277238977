import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Organisation } from "./organisation";
import { Team } from "./team";
import { Value } from "./value";

export class ValuesConstitution extends BaseEntity<ValuesConstitution> {
    public valuesConstitutionId!: number;
    public organisationId!: number;
    public teamId?: number;
    public lastUpdated!: Date;
    public organisation!: Organisation;
    public team!: Team;
    public values!: Value[];
}

export const ValuesConstitutionBreezeModel = new BreezeModelBuilder("ValuesConstitution", ValuesConstitution)
    .hasSource()
    .alwaysFetchingNestedNavigationProperty("values.valueQuestions")
    .isOrganisationEntity()
    .persistChangedEntity()
    .build();

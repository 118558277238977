import { Injectable, Injector } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { IdentityService } from "../identity/identity.service";
import { AbstractGuard } from "./abstract.guard";
import { GuardFailureType } from "./route-events.service";

@Injectable({
    providedIn: "root",
})
export class UnauthenticatedGuard extends AbstractGuard {
    public static readonly Id = "UnauthenticatedGuard";

    constructor(
        private identityService: IdentityService,
        injector: Injector,
    ) {
        super(injector);
    }

    public canActivateWithBypassEvent(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, bypassEvent: boolean) {
        return this.wrapActivationResult(this.identityService.promiseToCheckIsAnonymous(), GuardFailureType.UnauthenticatedGuardFailed, route, state, bypassEvent);
    }
}

import { Injectable } from "@angular/core";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";

@Injectable({
    providedIn: "root",
})
export class StrategyAuthService {
    public static readonly ReadBullseye = "readBullseye";
    public static readonly EditBullseye = "editBullseye";
    public static readonly ReadStrategyBoard = "readStrategyBoard";
    public static readonly EditStrategyBoard = "editStrategyBoard";
    public static readonly ReadStrategicInputs = "readStrategicInputs";
    public static readonly EditStrategicInputs = "editStrategicInputs";
    public static readonly ReadStrategicGoals = "readStrategicGoals";
    public static readonly EditStrategicGoals = "editStrategicGoals";
    public static readonly ReadStrategicAnchors = "readStrategicAnchors";
    public static readonly EditStrategicAnchors = "editStrategicAnchors";

    public static registerAccess(authorisationService: AuthorisationService) {
        this.registerBullseye(authorisationService);
        this.registerStrategyBoard(authorisationService);
        this.registerStrategicInputs(authorisationService);
        this.registerStrategicGoals(authorisationService);
        this.registerStrategicAnchors(authorisationService);
    }

    private static registerBullseye(authorisationService: AuthorisationService) {
        authorisationService.registerAccessVerifier(
            StrategyAuthService.ReadBullseye,
            {
                requirePermissions: [
                    FeaturePermissionName.StrategyBullseyeRead,
                    FeaturePermissionName.StrategyBullseyeEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            StrategyAuthService.EditBullseye,
            {
                requirePermissions: [
                    FeaturePermissionName.StrategyBullseyeEdit,
                ],
            },
        );
    }

    private static registerStrategyBoard(authorisationService: AuthorisationService) {
        authorisationService.registerAccessVerifier(
            StrategyAuthService.ReadStrategyBoard,
            {
                requirePermissions: [
                    FeaturePermissionName.StrategyBoardRead,
                    FeaturePermissionName.StrategyBoardEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            StrategyAuthService.EditStrategyBoard,
            {
                requirePermissions: [
                    FeaturePermissionName.StrategyBoardEdit,
                ],
            },
        );
    }

    private static registerStrategicInputs(authorisationService: AuthorisationService) {
        authorisationService.registerAccessVerifier(
            StrategyAuthService.ReadStrategicInputs,
            {
                requirePermissions: [
                    FeaturePermissionName.StrategicInputsRead,
                    FeaturePermissionName.StrategicInputsEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            StrategyAuthService.EditStrategicInputs,
            {
                requirePermissions: [
                    FeaturePermissionName.StrategicInputsEdit,
                ],
            },
        );
    }

    private static registerStrategicGoals(authorisationService: AuthorisationService) {
        authorisationService.registerAccessVerifier(
            StrategyAuthService.ReadStrategicGoals,
            {
                requirePermissions: [
                    FeaturePermissionName.StrategicGoalsRead,
                    FeaturePermissionName.StrategicGoalsEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            StrategyAuthService.EditStrategicGoals,
            {
                requirePermissions: [
                    FeaturePermissionName.StrategicGoalsEdit,
                ],
            },
        );
    }

    private static registerStrategicAnchors(authorisationService: AuthorisationService) {
        authorisationService.registerAccessVerifier(
            StrategyAuthService.ReadStrategicAnchors,
            {
                requirePermissions: [
                    FeaturePermissionName.StrategicAnchorsRead,
                    FeaturePermissionName.StrategicAnchorsEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            StrategyAuthService.EditStrategicAnchors,
            {
                requirePermissions: [
                    FeaturePermissionName.StrategicAnchorsEdit,
                ],
            },
        );
    }
}

import { Injectable } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { FunctionUtilities } from "@common/lib/utilities/function-utilities";
import { NavigationHierarchyService } from "@common/route/navigation-hierarchy.service";
import { ISidebarTab, SidebarTabPosition } from "@common/shell/common-sidebar/sidebar-tab";
import { IComponentRender } from "@common/ux/render-component/component-render.interface";
import { defer, Observable, of } from "rxjs";
import { filter, first, map, startWith, switchMap } from "rxjs/operators";
import { CommonSidebarConstants } from "./common-sidebar.constants";

@Injectable()
export abstract class NavigationTab implements ISidebarTab {
    public abstract id: string;
    public abstract label: string;
    public abstract ordinal: number;
    public abstract icon?: IComponentRender<unknown>;
    public abstract content: IComponentRender<unknown>;

    public position = SidebarTabPosition.Top;

    public isLoading$: Observable<boolean>;
    public isDisplayed$ = of(true);
    public focusTab$: Observable<void>;
    public maxWidth = CommonSidebarConstants.NavigationTabMaxWidth;

    /**
     * On emission of this observable, recheck the current active navigation node for
     * a match with this tab's hierarchy. Typically just of(undefined) in Nimbus and
     * organisationService.currentOrganisation$ in Cumulus.
     */
    protected abstract recheckForFocusedTab$: Observable<unknown>;

    public constructor(
        private navigationHierarchyService: NavigationHierarchyService,
    ) {
        this.isLoading$ = navigationHierarchyService.hierarchyChanged(this.getHierarchyId()).pipe(
            map((node) => !node),
            startWith(true),
        );

        // Defer to ensure that the property is defined from the implementing class
        this.focusTab$ = defer(() => this.recheckForFocusedTab$).pipe(
            // In order to support the active tab only updating once for emit from
            // the source observable we have to wrap the following in a switch
            // map rather than flattening the chain (which would result in the
            // first() below make this only work on startup)
            switchMap(this.emitOnActiveNodeMatchesThisHierarchy),
        );
    }

    @Autobind
    private emitOnActiveNodeMatchesThisHierarchy() {
        // Take first as we only want to switch to the active tab once per time this is checked
        return this.navigationHierarchyService.activeNode$.pipe(
            map(() => this.navigationHierarchyService.getActiveHierarchy()),
            filter((hierarchy) => !!hierarchy),
            first(),
            filter((hierarchy) => hierarchy === this.getHierarchyId()),
            map(FunctionUtilities.noop),
        );
    }

    protected abstract getHierarchyId(): string;
}

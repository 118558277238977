import { Component, Input } from "@angular/core";
import { ActiveState } from "@common/ADAPT.Common.Model/activeEntity.interface";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { PositionConfig } from "devextreme/animation/position";
import { Observable, of, ReplaySubject } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { DirectorySharedService } from "../directory-shared.service";

@Component({
    selector: "adapt-link-person",
    templateUrl: "./link-person.component.html",
    styleUrls: ["./link-person.component.scss"],
})
export class LinkPersonComponent extends BaseComponent {
    @Input() public set person(value: Person | undefined) {
        this.person$.next(value);
    }
    public person$ = new ReplaySubject<Person | undefined>(1);

    @Input() public set personId(value: number | undefined) {
        if (value) {
            this.personId$.next(value);
        }
    }
    private personId$ = new ReplaySubject<number>(1);

    @Input() public set imageOnly(value: boolean) {
        this.showImage = value;
        this.showText = !value;
    }
    @Input() public preventWrap = false;
    @Input() public showText = true;
    @Input() public showImage = false;
    @Input() public linkDisabled = false;
    @Input() public nullValueText = "(Unallocated)";
    @Input() public imageSize: "xs" | "sm" | "md" | "lg" = "md";

    public popoverInitialised = false;
    public profileUrl$: Observable<string | undefined>;
    public personActiveText$: Observable<string>;
    public isActiveNow$: Observable<boolean>;
    public popoverVisible = true;
    public popoverCancelHiding = false;
    public popoverPosition: PositionConfig = {
        my: "left top",
        at: "left bottom",
    };

    public constructor(
        directorySharedService: DirectorySharedService,
    ) {
        super();

        this.personId$.pipe(
            switchMap((personId) => directorySharedService.promiseToGetPersonById(personId) as Promise<Person>),
            this.takeUntilDestroyed(),
        ).subscribe((person) => {
            if (person) {
                this.person$.next(person);
            }
        });

        this.profileUrl$ = this.person$.pipe(
            switchMap((person) => {
                // don't emit links for stakeholder managers, organisation admins will be able to edit our profiles
                // TODO: consider permissions to resolve this?
                if (!this.linkDisabled && person && !person.isStakeholderManager) {
                    return directorySharedService.promiseToGetProfileUrl(person) as Promise<string | undefined>;
                }

                return of(undefined);
            }),
        );

        // for the purposes of this component, an inactive person must have at least one connection, and all connections must be inactive
        // (stakeholder managers will have zero connections, but we dont really want the words 'inactive' to follow their name)
        this.personActiveText$ = this.person$.pipe(
            emptyIfUndefinedOrNull(),
            map((person) => person.activeText),
        );
        this.isActiveNow$ = this.person$.pipe(
            emptyIfUndefinedOrNull(),
            map((person) => person.activeState === ActiveState.Active),
        );
    }

    public onPopoverHiding(e: any) {
        if (this.popoverCancelHiding) {
            e.cancel = true;
        }
    }

    public onPopoverContentReady(e: any) {
        const self = this;
        // this is derived from:
        // https://www.devexpress.com/Support/Center/Question/Details/T546073/how-to-close-dx-popover-on-event-dxhoverend-angular
        //
        // Note that dxpointerdown will close the popover when the action link is clicked. The previous implementation of using actionPerformed
        // never worked (see production where you click on 'View Access' from person popover - the popover remains on top of the access
        // dialog). That's because person actions are registering promise function and waiting for promise resolution before actionPerformed
        // is being called. So provided the dialog is not closed, actionPerformed won't be toggled.
        // Not changing that behaviour as profile controller is relying on that to refresh the controller after changes from action.
        // Only need action started notification here - so might as well add the dxpointerdown to close popover instead.
        //
        // Also notice that personLink popover not showing for tablet and phone which was the behaviour before as the <a> will navigate
        // to the person dashboard immediate upon touch down.
        e.component.content().on("dxpointerleave", () => { this.popoverVisible = false; this.popoverCancelHiding = false; });
        e.component.content().on("dxpointerdown", handlePointerDown);
        e.component.content().on("dxpointerenter", () => this.popoverCancelHiding = true);

        function handlePointerDown(event: JQuery.Event) {
            // dont process right click events on the popover (so the user can open in new tab/window for example)
            if (event.which !== 3) {
                // use a longish timeout, as otherwise there is an intermittent error where the click doesn't register properly
                // this event is registered so that events that the popover is hidden for events that just spawn dialog boxes (e.g. change password)
                setTimeout(() => { self.popoverVisible = false; self.popoverCancelHiding = false; }, 200);
            }
        }
    }
}

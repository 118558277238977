import { Component, Input } from "@angular/core";
import { ItemMetadata } from "@common/ADAPT.Common.Model/organisation/item";
import { ItemStatusMetadata } from "@common/ADAPT.Common.Model/organisation/item-status";
import { MeetingItem } from "@common/ADAPT.Common.Model/organisation/meeting-item";

@Component({
    selector: "adapt-display-meeting-action",
    templateUrl: "./display-meeting-action.component.html",
    styleUrls: ["../meeting-styles.scss", "../../kanban/items/items-styles.scss"],
})
export class DisplayMeetingActionComponent {
    public readonly MeetingItemIconClass = ItemMetadata.IconClass;
    public readonly ItemStatusMetadata = ItemStatusMetadata;

    @Input() public meetingItem!: MeetingItem;
    @Input() public showStatus = true;
}

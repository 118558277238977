import { Injector } from "@angular/core";
import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { SurveyQuestionResponse } from "@common/ADAPT.Common.Model/organisation/survey-question-response";
import { SurveyResponseGroup } from "@common/ADAPT.Common.Model/organisation/survey-response";
import { UserService } from "@common/user/user.service";
import { DirectorySharedService } from "../../directory-shared/directory-shared.service";
import { analyseResilientBusinessAssessmentSurveyPageRoute } from "../analyse-survey-page/analyse-survey-page.route";
import { SurveyDetails } from "../survey-details";
import { ISurveyTypeUtils } from "../survey-type-utils.interface";
import { manageRBAssessmentsPageRoute } from "./manage-rb-assessments-page/manage-rb-assessments-page.route";
import { ResilientBusinessAssessmentConstants } from "./resilient-business-assessment.constants";
import { ResilientBusinessAssessmentQuestions } from "./resilient-business-assessment-questions";

export class ResilientBusinessAssessmentUtils implements ISurveyTypeUtils {
    // this will get overwritten by the corresponding project module constructor using
    // - SurveyService.registerLearnMoreUrlForSurveyType()
    // If that's not called, the CTA simply won't show the learn more button.
    public learnMoreUrl = "";

    public singularParticipant = "employee";
    public pluralParticipant = "employees";
    public analyseTargetLabel = "Zone";
    public responseGroups = [SurveyResponseGroup.Leader, SurveyResponseGroup.NonLeader];
    public surveyDescription = `<p>The survey will take approximately 15 minutes to complete.</p>
        <p>Please be open and honest in your responses. Your responses are confidential, and it’s
        important that we get a real and accurate assessment of your organisation.</p>
        <p>This survey will assist in understanding how resilient your organisation is. There are four
        sections, each asking you to rank, from strongly disagree to strongly agree, how you feel in
        regards to a statement about your organisation. If you do not have the knowledge in your role to
        answer a question, please select N/A.</p>`;
    public goodPercentageThreshold = ResilientBusinessAssessmentConstants.GoodPercentageThreshold;

    public get resultCategories() {
        return ResilientBusinessAssessmentConstants.Categories;
    }

    public getManageSurveyPageRoute$() {
        return manageRBAssessmentsPageRoute.getRoute();
    }

    public gotoManageSurveyPageRoute$() {
        return manageRBAssessmentsPageRoute.gotoRoute();
    }

    public getAnalyseSurveyPageRoute$(surveyId?: number) {
        return analyseResilientBusinessAssessmentSurveyPageRoute.getRouteObject(undefined, { surveyId });
    }

    public gotoAnalyseSurveyPageRoute$(surveyId?: number) {
        return analyseResilientBusinessAssessmentSurveyPageRoute.gotoRoute(undefined, { surveyId });
    }

    public getSurveyQuestions(surveyDetails: SurveyDetails) {
        return new ResilientBusinessAssessmentQuestions(surveyDetails);
    }

    public convertScoreToPercentage(score: number) {
        return (score - 1) * 100 / (ResilientBusinessAssessmentConstants.MaxScore - 1);
    }

    public filterQuestionResponse(response: SurveyQuestionResponse) {
        // filter out N/A response
        return response.response > 0 && response.response <= ResilientBusinessAssessmentConstants.MaxScore;
    }

    public getStatusColorFromPercentage(percentage: number) {
        if (percentage >= ResilientBusinessAssessmentConstants.GoodPercentageThreshold) {
            return ResilientBusinessAssessmentConstants.GoodColor;
        } else if (percentage >= ResilientBusinessAssessmentConstants.WarningPercentageThreshold) {
            return ResilientBusinessAssessmentConstants.WarningColor;
        } else {
            return ResilientBusinessAssessmentConstants.BadColor;
        }
    }

    public getStatusBackgroundFromPercentage(percentage: number) {
        if (percentage >= ResilientBusinessAssessmentConstants.GoodPercentageThreshold) {
            return ResilientBusinessAssessmentConstants.GoodBackgroundColor;
        } else if (percentage >= ResilientBusinessAssessmentConstants.WarningPercentageThreshold) {
            return ResilientBusinessAssessmentConstants.WarningBackgroundColor;
        } else {
            return ResilientBusinessAssessmentConstants.BadBackgroundColor;
        }
    }

    public getResultCategoryChartTooltipText(count: number, totalResponses: number, resultCategoryText: string) {
        return `${count} out of ${totalResponses} ${this.pluralParticipant} assessed the business as ${resultCategoryText}`;
    }

    public getScoreGrade(percentage: number) {
        for (const resultCategory of ResilientBusinessAssessmentConstants.Categories) {
            if (percentage >= resultCategory.percentageThreshold) {
                return resultCategory.name;
            }
        }

        return "No Grade";
    }

    public async getPotentialSurveyInvitees(injector: Injector) {
        const connection = await injector.get(DirectorySharedService).promiseToGetActiveConnectionsByPredicate();
        const currentPersonId = injector.get(UserService).getCurrentPersonId();
        return connection.filter((c) => c.connectionType === ConnectionType.Employee && c.personId !== currentPersonId);
    }
}

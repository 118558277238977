import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { Bullseye } from "./bullseye";
import { BullseyeQuadrant } from "./bullseye-quadrant-statement";

export enum QuadrantCorner {
    TopLeft = "TopLeft",
    TopRight = "TopRight",
    BottomLeft = "BottomLeft",
    BottomRight = "BottomRight",
}

export const QuadrantCorners: Record<BullseyeQuadrant, QuadrantCorner> = {
    [BullseyeQuadrant.PassionateAbout]: QuadrantCorner.TopLeft,
    [BullseyeQuadrant.GoodAt]: QuadrantCorner.TopRight,
    [BullseyeQuadrant.CustomerNeed]: QuadrantCorner.BottomLeft,
    [BullseyeQuadrant.GetPaidFor]: QuadrantCorner.BottomRight,
};


export class BullseyeExtensions {
    public constructor(private bullseye: Bullseye) { }

    public areQuadrantsCompleted(quadrants?: BullseyeQuadrant[]) {
        if (!quadrants) {
            // if no required quadrants are passed. assume that we want all quadrants to be completed.
            quadrants = Object.keys(QuadrantCorners) as BullseyeQuadrant[];
        }
        const statementsInBullseye = this.bullseye.quadrantStatements.filter((s) => s.inBullseye);
        const completedQuadrants = ArrayUtilities.distinct(statementsInBullseye.map((s) => s.quadrant));

        const intersect = ArrayUtilities.intersectArrays(completedQuadrants, quadrants);

        return intersect.length === quadrants.length;
    }
}

import { AngularGlobals } from "@common/lib/angular-globals/angular-globals";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { EMPTY, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AdaptCommonDialogService } from "./adapt-common-dialog.service";

export class CommonDialogHelper {

    /**
     * When you need a dialog where the user can cancel changes without rejecting the entities, then use
     * this to preserve the entity correctly.
     *
     * @param isUnchangedCondition whether the value in the dialog is different to the original
     * @param hasOriginalValueCondition whether the original entity had a value
     * @param resetValues a callback so that the original entity can be set back to its original value
     * @param entity the entity used in the dialog
     * @param cancel a callback to allow a cancel to occur
     */
    public static cancelDialogAndPreserveEntity(
        isUnchangedCondition: boolean,
        hasOriginalValueCondition: boolean,
        resetValues: () => void,
        entity: IBreezeEntity,
        cancel: () => void,
    ) {
        /* We need this custom logic due to the face that this dialog doesn't handle
        * its own saves. If a user creates an entity, clicks OK then comes back in
        * and makes some other changes, we track the changes according to the original
        * value that came into the dialog rather than the breeze entity state. This
        * ensures that a cancel on this second edit doesn't delete the entity, rather
        * it just reverts to the value that it was when the dialog was entered.
        */

        const commonDialogService = AngularGlobals.injector.get(AdaptCommonDialogService);
        const commonDataService = AngularGlobals.injector.get(CommonDataService);

        of(isUnchangedCondition).pipe(
            switchMap((isUnchanged) => {
                return isUnchanged
                    ? of(true)
                    : commonDialogService.openConfirmDiscardDialog();
            }),
            switchMap((shouldDiscard) => {
                if (!shouldDiscard) {
                    return EMPTY;
                }

                if (hasOriginalValueCondition) {
                    resetValues();
                    return of({});
                } else {
                    return commonDataService.rejectChanges(entity);
                }
            }),
        ).subscribe(() => {
            cancel();
        });
    }
}

<!-- ordinal of fifty to allow the page to have buttons to the left -->
<div adaptToolbarContent
     [ordinal]="50">
    <button class="dx-datagrid-export-button"
            adaptButtonIcon="dx-icon dx-icon-xlsxfile"
            adaptTooltip="Export all data"
            (click)="exportAllData()"
            data-test="export-data-button"></button>
    <button adaptButtonIcon="dx-icon dx-icon-column-chooser"
            adaptTooltip="Column chooser"
            (click)="showColumnChooser()"></button>
    <button adaptButtonIcon="dx-icon dx-icon-revert"
            adaptTooltip="Reset columns"
            (click)="resetGridState()"></button>
</div>

<dx-data-grid *adaptLoading="isLoading"
              class="people-grid-component"
              [dataSource]="gridData"
              [stateStoring]="gridHelper.stateStoringOptions"
              [columns]="gridColumns"
              [columnAutoWidth]="true"
              [allowColumnResizing]="true"
              [allowColumnReordering]="true"
              [rowAlternationEnabled]="true"
              [hoverStateEnabled]="true"
              [showRowLines]="true"
              [showBorders]="true"
              [noDataText]="stakeholderView ? 'No Stakeholders have been added to this organisation' : 'No data'"
              (onInitialized)="onGridInitialized($event)">
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-header-filter [visible]="true"></dxo-header-filter>
    <dxo-paging [pageSize]="25"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true"
               [allowedPageSizes]="[10, 25, 50, 100, 250]"></dxo-pager>
    <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
    <dxo-scrolling [useNative]="true"></dxo-scrolling>

    <dxo-summary>
        <dxi-group-item summaryType="count"
                        name="connectionTypeCountSummary"
                        showInColumn="People"></dxi-group-item>
        <dxi-total-item displayFormat="Total People: {0}"
                        column="People"
                        summaryType="count">
        </dxi-total-item>
    </dxo-summary>

    <div *dxTemplate="let item of 'connectionTypeTemplate'">
        {{item.value}} ({{getConnectionTypeSummaryCount(item)}})
    </div>

    <adapt-link-person *dxTemplate="let item of 'fullNameCellTemplate'"
                       [showImage]="true"
                       imageSize="sm"
                       [person]="item.data.person"></adapt-link-person>

    <ul *dxTemplate="let item of 'culturalLeadersTemplate'">
        <li *ngFor="let culturalLeader of item.data.culturalLeaders">
            <adapt-link-person [person]="culturalLeader"></adapt-link-person>
        </li>
    </ul>

    <ul *dxTemplate="let item of 'roleCellTemplate'">
        <li *ngFor="let roleConnection of item.data.roleConnections">
            <adapt-link-role [role]="roleConnection.role"></adapt-link-role>
        </li>
    </ul>

    <ul *dxTemplate="let item of 'teamCellTemplate'">
        <li *ngFor="let team of item.data.teams">
            <adapt-link-team [team]="team"
                             [focusPersonId]="item.data.person.personId"></adapt-link-team>
        </li>
    </ul>

    <ul *dxTemplate="let item of 'contactDetailCellTemplate'">
        <li *ngFor="let detail of item.data.contactDetails[item.column.caption]">
            <adapt-contact-detail [detail]="detail"
                                  [showIcon]="false"></adapt-contact-detail>
        </li>
    </ul>
</dx-data-grid>

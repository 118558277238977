import moment from "moment";

export interface ITimeFrame {
    amount: number;
    unit: moment.unitOfTime.DurationConstructor;
}

export interface IPeriod {
    name: string;
    timeFrame: ITimeFrame | ITimeFrame[];
    timeFrameIncrement: ITimeFrame | ITimeFrame[];
    timeFramePeriod: number;
    timeFrameHeaders: string[];
    timeFrameHeadersTooltip?: string[];
    classes?: string;
    tooltip?: string;
}

const MINUTES_DAY = 60 * 24;

export const TimeSchedulerPeriodYearPlusHalf: IPeriod = {
    name: "1½ years",
    timeFrameHeaders: ["YYYY", "MMM"],
    timeFrameHeadersTooltip: ["YYYY", "YYYY/MM - MMMM"],
    timeFrame: [{ amount: 1, unit: "year" }, { amount: 6, unit: "month" }],
    timeFrameIncrement: { amount: 9, unit: "months" },
    timeFramePeriod: MINUTES_DAY,
};

export const TimeSchedulerPeriodYear: IPeriod = {
    name: "1 year",
    timeFrameHeaders: ["YYYY", "MMM"],
    timeFrameHeadersTooltip: ["YYYY", "YYYY/MM - MMMM"],
    timeFrame: [{ amount: 1, unit: "year" }, { amount: 1, unit: "month" }],
    timeFrameIncrement: { amount: 6, unit: "months" },
    timeFramePeriod: MINUTES_DAY,
};

export const TimeSchedulerPeriod6Months: IPeriod = {
    name: "6 months",
    timeFrameHeaders: ["YYYY", "MMM"],
    timeFrameHeadersTooltip: ["YYYY", "YYYY/MM - MMMM"],
    timeFrame: { amount: 6, unit: "months" },
    timeFrameIncrement: { amount: 3, unit: "months" },
    timeFramePeriod: MINUTES_DAY,
};

export const TimeSchedulerPeriod3Months: IPeriod = {
    name: "3 months",
    timeFrameHeaders: ["YYYY", "MMM"],
    timeFrameHeadersTooltip: ["YYYY", "YYYY/MM - MMMM"],
    timeFrame: { amount: 3, unit: "months" },
    timeFrameIncrement: { amount: 1, unit: "months" },
    timeFramePeriod: MINUTES_DAY,
};

export const DefaultTimeSchedulerPeriods: IPeriod[] = [
    TimeSchedulerPeriodYearPlusHalf,
    TimeSchedulerPeriodYear,
    TimeSchedulerPeriod6Months,
    TimeSchedulerPeriod3Months,
];

export interface IItem<TMetadata = any> {
    sectionId: number;
    name: string;
    start: moment.Moment;
    end: moment.Moment;
    styles?: string;
    tooltip?: string;
    metadata?: TMetadata;
    disabled?: boolean;
    transparent?: boolean;
}

export interface IItemEvent<TMetadata = any> {
    item: IItem<TMetadata>;
    element: HTMLDivElement;
    event: MouseEvent;
}

export interface ISection {
    id: number;
    name: string;
    code: string;
    visible: boolean;
    colour?: string;
    tooltip?: string;
    toString?: () => string;
}

export interface ISectionItem {
    section: ISection;
    minRowHeight: number;
    itemProperties: IItemProperties[];
}

export interface IItemProperties {
    item: IItem;
    cssTop: number;
    cssLeft: number;
    cssWidth: number;
}

export interface IHeader {
    name: string;
    colspan: number;
    cycle?: number;
    tooltip?: string;
}

export interface ICycle {
    start: Date;
    end: Date;
}

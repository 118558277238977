import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Feature } from "../embed/feature";
import { Organisation } from "./organisation";
import { Team } from "./team";

export class FeatureStatus extends BaseEntity<FeatureStatus> {
    public featureStatusId!: number;
    public organisationId!: number;
    public teamId?: number;
    public featureId!: number;
    public feature!: Feature;
    public organisation!: Organisation;
    public team!: Team;
}

export const FeatureStatusBreezeModel = new BreezeModelBuilder("FeatureStatus", FeatureStatus)
    .withPluralName("Feature Statuses")
    .hasSource()
    .withIdField() // needed for entity sync
    .isOrganisationEntity()
    .build();

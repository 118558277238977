import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptContextSidebarModule } from "@common/shell/context-sidebar/context-sidebar.module";
import { AdaptToolbarModule } from "@common/shell/toolbar/toolbar.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptCollapsibleDashboardElementModule } from "@common/ux/collapsible-dashboard-element/collapsible-dashboard-element.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptMenuModule } from "@common/ux/menu/menu.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { DxDropDownButtonModule, DxScrollViewModule, DxSortableModule } from "devextreme-angular";
import { AdaptAuthorisationModule } from "../authorisation/authorisation.module";
import { AdaptCallToActionModule } from "../call-to-action/call-to-action.module";
import { AdaptStrategicInputsSharedModule } from "../strategic-inputs/strategic-inputs-shared/strategic-inputs-shared.module";
import { AdaptStrategyModule } from "../strategy/strategy.module";
import { AdaptZoneMapModule } from "../zone-map/zone-map.module";
import { StrategyBoardComponent } from "./strategy-board/strategy-board.component";
import { StrategyBoardPageComponent, StrategyBoardPageRoute } from "./strategy-board-page/strategy-board-page.component";
import { StrategyZoneComponent } from "./strategy-zone/strategy-zone.component";


@NgModule({
    declarations: [
        StrategyZoneComponent,
        StrategyBoardComponent,
        StrategyBoardPageComponent,
    ],
    imports: [
        CommonModule,

        DxScrollViewModule,
        DxSortableModule,

        AdaptStrategyModule,
        AdaptStrategicInputsSharedModule,
        AdaptButtonModule,
        AdaptHtmlEditorModule,
        AdaptMenuModule,
        AdaptZoneMapModule,
        AdaptToolbarModule,
        AdaptTooltipModule,
        AdaptContextSidebarModule,
        AdaptCallToActionModule,
        AdaptLoadingSpinnerModule,
        AdaptAuthorisationModule,
        AdaptCollapsibleDashboardElementModule,
        DxDropDownButtonModule,
    ],
    exports: [
        StrategyBoardComponent,
        StrategyBoardPageComponent,
    ],
})
export class AdaptStrategyBoardModule {
    public static readonly Routes = [
        ...StrategyBoardPageRoute.routes,
    ];
}

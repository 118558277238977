import { InjectionToken, Provider, Type } from "@angular/core";
import { EventSeriesType } from "@common/ADAPT.Common.Model/organisation/event-series";
import { EventSeriesDayOfWeek } from "@common/ADAPT.Common.Model/organisation/event-series-day-of-week";
import { EventSeriesWeekIndex } from "@common/ADAPT.Common.Model/organisation/event-series-week-index";
import { IMeetingCustomData, IMeetingLocation, Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { CalendarIntegrationProvider } from "@common/ADAPT.Common.Model/organisation/organisation-detail";
import { IOAuthService } from "../oauth/oauth.interface";

export interface ICalendarProvider {
    id: CalendarIntegrationProvider;
    name: string;
    isLocal: boolean;
    authService: Type<IOAuthService>;
    connectComponent: Type<ICalendarConnectComponent>;
    getCalendarLink?: (id: string) => string;
}

export interface ICalendarConnectComponent {
    showLogoutButton: boolean;
    disabled: boolean;
}

export const CALENDAR_PROVIDERS = new InjectionToken("CALENDAR_PROVIDERS");
export function provideCalendarProvider(provider: ICalendarProvider): Provider {
    return {
        provide: CALENDAR_PROVIDERS,
        useValue: provider,
        multi: true,
    };
}

export interface IOAuthUser {
    displayName: string;
    email: string;
    profilePicture?: string;
    userId?: string;
}

export interface IMeetingAttendee {
    type: "required" | "optional";
    // The email address of the person or entity.
    address?: string | null;
    // The display name of the person or entity.
    name?: string | null;
}

export interface IMeetingOrganiserInfo {
    name: string;
    isOrganiser: boolean;
}

export interface IScheduleMeetingRecurrence {
    type: EventSeriesType;
    interval: number;
    month?: number;
    daysOfWeek?: EventSeriesDayOfWeek;
    index?: EventSeriesWeekIndex;
    startDate: Date;
    endDate: Date;
}

export interface IScheduleMeeting {
    name: string;
    body?: string;
    startTime: Date;
    endTime: Date;
    location?: IMeetingLocation;
    attendees?: IMeetingAttendee[];
    recurrence?: IScheduleMeetingRecurrence;
    createOnlineMeeting?: boolean;
}

export interface IMeetingDifferences extends Partial<Meeting> {
    customDataObject?: IMeetingCustomData;
    remoteShouldBeUpdated?: boolean;
    hasOnlineMeeting?: boolean;
}

import { Injectable, Injector } from "@angular/core";
import { FeaturePermission } from "@common/ADAPT.Common.Model/embed/feature-permission";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { Connection, ConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/connection";
import { FeatureStatus } from "@common/ADAPT.Common.Model/organisation/feature-status";
import { RoleBreezeModel } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { RoleFeaturePermission } from "@common/ADAPT.Common.Model/organisation/role-feature-permission";
import { IEntityWithOptionalTeam } from "@common/ADAPT.Common.Model/organisation/team-entity.interface";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { IdentityService } from "@common/identity/identity.service";
import { ActiveEntityUtilities } from "@common/lib/data/active-entity-utilities";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { PromiseUtilities } from "@common/lib/utilities/promise-utilities";
import { UserService } from "@common/user/user.service";
import { combineLatest, lastValueFrom, merge } from "rxjs";
import { debounceTime, distinctUntilChanged, filter, map, startWith, switchMap } from "rxjs/operators";
import { FeaturesService } from "../features/features.service";
import { AfterOrganisationInitialisation, AfterOrganisationInitialisationObservable } from "../organisation/after-organisation-initialisation.decorator";
import { BaseOrganisationService } from "../organisation/base-organisation.service";
import { OrganisationService } from "../organisation/organisation.service";
import { AuthorisationNotificationService } from "./authorisation-notification.service";

export enum ServerRoles {
    StakeholderManagement = "StakeholderManagement",
}

export interface IAccessVerifier {
    requirePermissions?: FeaturePermissionName[] | FeaturePermissionName;
    invokeToGetEntityAccessVerifier?: (injector: Injector) => (person: Person, entity: any) => Promise<any>;
    invokeToGetAccessVerifier?: (injector: Injector) => (person: Person) => Promise<any>;
    checkAuthServiceImplementation?: (injector: Injector) => (person: Person) => boolean;
    checkAuthServiceImplementationWithEntity?: (injector: Injector) => (person: Person, entity: any) => Promise<boolean> | boolean;
}

export interface IActivePermission {
    teamId?: number;
    featurePermission: FeaturePermission;
}

export interface IRegisteredAccessVerifier {
    id: string;
    accessVerifier: IAccessVerifier;
}

type AllRequiredAccessVerifier = {
    [K in keyof IAccessVerifier]-?: boolean;
};

// we have extracted these out from the service due to an interaction with Angular 15.1 (https://github.com/angular/angular/issues/48764) and TS (https://github.com/microsoft/TypeScript/issues/52004)
const accessVerifierWithAllProperties: AllRequiredAccessVerifier = {
    checkAuthServiceImplementation: true,
    checkAuthServiceImplementationWithEntity: true,
    invokeToGetAccessVerifier: true,
    invokeToGetEntityAccessVerifier: true,
    requirePermissions: true,
};

@Injectable({
    providedIn: "root",
})
export class AuthorisationService extends BaseOrganisationService {
    // Use a required version of the access verifier so if we add new properties the compiler will catch it

    private static readonly accessVerifierProperties = Object.keys(accessVerifierWithAllProperties) as (keyof IAccessVerifier)[];

    public accessVerifiers: { [key: string]: IAccessVerifier } = {};
    public accessVerifierIds: { [key: string]: string } = {};

    private _currentPerson?: Person;
    private currentPermissions: IActivePermission[] = [];
    private currentServerRoles: string[] = [];
    private allActiveFeaturePermissions: FeaturePermission[] = [];

    private deferredInitialisation = PromiseUtilities.defer();
    private currentPersonHasActiveCoachConnection = false;

    public constructor(
        protected injector: Injector,
        private rxjsBreezeService: RxjsBreezeService,
        private identityService: IdentityService,
        private userService: UserService,
        private featuresService: FeaturesService,
        private notificationService: AuthorisationNotificationService,
        private orgService: OrganisationService,
    ) {
        super(injector);

        combineLatest([
            this.userService.userChanged$,
            this.notificationService.permissionsRefreshed$.pipe(startWith(undefined)),
            this.orgService.organisationEntityUpdated$,
        ]).pipe(
            switchMap(async ([person]) => {
                if (this.orgService.getOrganisationId() <= 0) {
                    return;
                }

                this.setPerson(person);

                const promises: Promise<any>[] = [];
                if (this.currentPerson) {
                    this.notificationService.notifyAuthorisationChanging();

                    promises.push(this.promiseToGetOrganisationData());

                    // just fetch all roles and role feature permissions here - its faster
                    promises.push(lastValueFrom(this.commonDataService.getAll(RoleBreezeModel)));

                    // Connections are needed to fulfil the synchronous permission verifications usually called from access verifier
                    // function which is guarded by auth service's promiseToVerify with @AfterInitialisation
                    // - previously worked most of the time as this was called from speed-catchup.service but there is
                    //   a race condition between that service initialisation and permission verification
                    //
                    // This also needed to be primed on user changed as the breeze cache will be cleared
                    promises.push(lastValueFrom(this.commonDataService.getAll(ConnectionBreezeModel)));
                }

                // this forces verifyAccess requests to wait for new permissions to load
                await Promise.all(promises);
                this.deferredInitialisation.resolve();
                this.log.debug("Initialisation complete");

                if (this.currentPerson) {
                    this.notificationService.notifyAuthorisationChanged();
                }
            }),
        ).subscribe();

        merge(
            this.rxjsBreezeService.entityTypeChanged(FeatureStatus),
            this.rxjsBreezeService.entityTypeChanged(RoleFeaturePermission).pipe(
                filter((roleFeaturePermission) =>
                    !!this.currentPerson?.getActiveConnections().some((c) => c.roleConnections.some((rc) => rc.roleId === roleFeaturePermission.roleId))),
            ),
            this.rxjsBreezeService.entityTypeChanged(RoleConnection).pipe(
                filter((roleConnection) => {
                    // If the connection isn't primed then we can't be sure the update
                    // doesn't affect the current person
                    if (!roleConnection.connection) {
                        return true;
                    }

                    // Don't even bother checking permissions if it doesn't affect the
                    // current person
                    if (roleConnection.connection.personId !== this.currentPerson?.personId) {
                        return false;
                    }

                    // If the role isn't primed then we can't be sure that the role doesn't
                    // affect any permissions
                    if (!roleConnection.role) {
                        return true;
                    }

                    return roleConnection.role.roleFeaturePermissions.length > 0;
                })),
        ).pipe(
            debounceTime(100), // if all multiple of the above entities, we only want to do a single data update
        ).subscribe(async () => {
            this.notificationService.notifyAuthorisationChanging();
            await this.promiseToGetOrganisationData();
            this.notificationService.notifyAuthorisationChanged();
        });
    }

    public get currentPerson() {
        return this._currentPerson;
    }

    protected organisationInitialisationActions() {
        return [this.deferredInitialisation.promise];
    }

    /**
     * Register an access verification object.
     *
     * @param {string} id The unique identifier of the access verification object.
     * @param {object} accessVerifier The access verification object. The access verification object should
     *   either have a requirePermissions property with a single permission, a comma-separated list of
     *   permissions or an array of permissions; or an invokeToGetEntityAccessVerifier property with an
     *   invocable array. It should return a promise that is resolved if access is granted or rejects if not.
     * @returns {function} A function that allows the removal of the registered object.
     */
    public registerAccessVerifier(id: string, accessVerifier: IAccessVerifier) {
        if (this.accessVerifiers[id]) {
            throw new Error("Registration of duplicate Access Verifier: " + id);
        }

        if (!AuthorisationService.accessVerifierProperties.some((p) => !!accessVerifier[p])) {
            throw new Error(`Registration of Access Verifier ${id} failed as none of ${AuthorisationService.accessVerifierProperties.join(", ")} have been set`);
        }

        this.accessVerifiers[id] = accessVerifier;
        this.accessVerifierIds[id] = id;

        // remove function has never been called - return this instead to get rid of all the tmp obj used for the registration
        return { id, accessVerifier } as IRegisteredAccessVerifier;
    }

    @AfterOrganisationInitialisation
    public promiseToCheckIsStakeholderManager() {
        // this is a promise so that we can do it after init
        return Promise.resolve(this.isStakeholderManager());
    }

    /**
     * Promise to verify access based on a specified access verifier.
     * @param {id} accessVerifierId The identifier for the access verifier object.
     * @param {object} entity The entity to verify access to if required.
     * @returns {Promise} A promise that resolves if access is verified and rejects if it is not.
     */
    @AfterOrganisationInitialisation
    public promiseToVerifyAccess(accessVerifierId: string, entity?: any): Promise<void> {
        const accessVerifier = this.accessVerifiers[accessVerifierId];

        if (!accessVerifier) {
            // if you get an exception thrown here, your code is probably wrong, some possible fixes:
            // - ensure that you registered the access verifier
            // - ensure that you used the registered access verifier id
            throw new Error("You are using an unregistered entity access verifier: " + accessVerifierId);
        }

        if (!this.currentPerson) {
            return deny("No one is logged in.");
        }

        if (accessVerifier.invokeToGetAccessVerifier) {
            const verifierFunc = accessVerifier.invokeToGetAccessVerifier(this.injector);
            return verifierFunc(this.currentPerson);
        }

        if (accessVerifier.invokeToGetEntityAccessVerifier) {
            if (!entity) {
                // if you get an exception thrown here, your code is probably wrong, some possible fixes:
                // - ensure that you are passing a valid entity
                // - ensure that you intended to set invokeToGetEntityAccessVerifier
                const msg = "You are using an entity access verifier without providing an entity: " + accessVerifierId;
                this.log.warn(msg);
                return deny(msg);
            }

            const entityAccessVerifier = accessVerifier.invokeToGetEntityAccessVerifier(this.injector);
            return entityAccessVerifier(this.currentPerson, entity);
        }

        if (accessVerifier.requirePermissions) {
            const permissions = getRequiredPermissions();
            return this.currentPersonHasOneOfPermissions(permissions, entity)
                ? allow()
                : deny("No permission in authorisation.service::checkRequirePermissions: " + accessVerifierId);
        }

        if (accessVerifier.checkAuthServiceImplementation) {
            const checker = accessVerifier.checkAuthServiceImplementation(this.injector);
            return checker(this.currentPerson)
                ? allow()
                : deny("No permission in authorisation.service::checkAuthServiceImplementation: " + accessVerifierId);
        }

        if (accessVerifier.checkAuthServiceImplementationWithEntity) {
            const checker = accessVerifier.checkAuthServiceImplementationWithEntity(this.injector);
            return Promise.resolve(checker(this.currentPerson, entity))
                .then((hasPermission) => hasPermission
                    ? allow()
                    : deny("No permission in authorisation.service::checkAuthServiceImplementationWithEntity: " + accessVerifierId));
        }

        // if you get an exception thrown here, your code is probably wrong, some possible fixes:
        // - ensure that the accessVerifier.requirePermissions is set to a valid value from configureAuthorisation.js file
        // - ensure the permission has been added to configureAuthorisation.js file
        // - ensure the permission name in configureAuthorisation.js matches the one in the back end (enum OrganisationSecurityPermission)
        throw new Error("You are using an improperly formed access verifier: " + accessVerifierId);

        function getRequiredPermissions() {
            let permissionsRequired: string | string[] | undefined = accessVerifier.requirePermissions;

            // convert a (potentially csv) value to an array, if it is not already
            if (permissionsRequired && !Array.isArray(permissionsRequired)) {
                permissionsRequired = permissionsRequired.split(",");
            }

            return permissionsRequired as FeaturePermissionName[];
        }

        // technically unnecessary but explicit in case further logic placed in allow / deny
        function allow() {
            return Promise.resolve();
        }

        function deny(message: string) {
            return Promise.reject(message);
        }
    }

    /** Checks if the current person has any of the specified permissions either on an organisational level
     * or to the entity specified
     * @param permissionsRequired One of these permissions must be satisfied to return true
     * @param entity The optional team based entity to check the permissions against
     * @returns True if the current person has permission, false otherwise
     */
    public currentPersonHasOneOfPermissions(permissionsRequired: FeaturePermissionName[], entity?: IEntityWithOptionalTeam) {
        if (!this.currentPerson) {
            return false;
        }

        return this.personHasAtLeastOnePermission(this.currentPerson, permissionsRequired, entity);
    }

    /**
     * Promise to verify access to a verified, returning true if access is enabled, false otherwise.
     * @param accessVerifierId The access verifier
     * @param entity The optional entity to check against
     */
    @AfterOrganisationInitialisation
    public async promiseToGetHasAccess(accessVerifierId: string, entity?: any) {
        const promise = this.promiseToVerifyAccess(accessVerifierId, entity);

        return PromiseUtilities.promiseToValidatePromiseResolution(promise);
    }

    public hasAtLeastOnePermission(accessVerifierIds: string[]) {
        const checks = accessVerifierIds.map((i) => this.getHasAccess(i));
        return combineLatest(checks).pipe(
            map((hasPermissions) => hasPermissions.includes(true)),
            distinctUntilChanged(),
        );
    }

    /** Creates a hot observable which will emit with the initial authorisation result
     * and also each time the result changes due to permission changes
     */
    @AfterOrganisationInitialisationObservable
    public getHasAccess(accessVerifierId: string, entity?: any) {
        const checkAuthorisation$ = this.notificationService.authorisationChanged$.pipe(
            startWith(undefined),
        );
        return checkAuthorisation$.pipe(
            switchMap(() => this.promiseToGetHasAccess(accessVerifierId, entity)),
            distinctUntilChanged(),
        );
    }

    public personHasAtLeastOnePermission(person: Person, permissionNames: FeaturePermissionName[], entity?: IEntityWithOptionalTeam, includePending = false) {
        if (person === this.currentPerson && this.isStakeholderManager() && person.getActiveConnections().length === 0) {
            // current stakeholder with no connection to the current organisation
            // - no need permission checking - just check if the feature is active
            // - this will also allow stakeholder to access private team stuffs
            return permissionNames.some((permissionName) => {
                const featurePermission = this.allActiveFeaturePermissions.find((i) => i.name === permissionName);
                if (featurePermission) {
                    const featureName = featurePermission.feature.name;
                    return this.featuresService.isFeatureActive(featureName, entity);
                } else {
                    return false;
                }
            });
        }

        const activePermissions = this.personActivePermissions(person, includePending);
        return permissionNames.some((p) => this.permissionIsSatisfiedBySet(p, activePermissions, entity));
    }

    public currentPersonHasPermission(permission: FeaturePermissionName, entity?: IEntityWithOptionalTeam) {
        if (!this.currentPerson) {
            return false;
        }

        return this.personHasPermission(this.currentPerson, permission, entity);
    }

    /**
     * Check if person has the specified permission as granted by their roles.
     * If the current person is passed in, and they have StakeholderManagement, it will work as expected
     * @param person Person to check.
     * @param permission FeaturePermission
     * @param entity Entity to check permission for.
     */
    public personHasPermission(person: Person, permission: FeaturePermissionName, entity?: IEntityWithOptionalTeam, includePending = false) {
        return this.personHasAtLeastOnePermission(person, [permission], entity, includePending);
    }

    /**
     * Checks if the given permission will match for at least one team permission the person has
     * e.g. If Jesse has TeamKanban for Team A but not Team B, this will return true
     * @param person The person to check the permission for
     * @param permission The permission to check against
     */
    public personHasPermissionInAtLeastOneTeam(person: Person, permission: FeaturePermissionName): boolean {
        const permissions = this.personActivePermissions(person);
        const matchedPermissions = permissions.filter((p) => p.featurePermission.name === permission);

        return matchedPermissions.some((i) => this.featureForPermissionIsActive(i));
    }

    /**
     * Checks whether a role connection is active and has the specified permission.
     * @param {Object} roleConnection The role connection to check.
     * @param {Object} permission The permission to check for.
     * @returns {boolean} A boolean representation of whether the role connection has the specified permission.
     */
    public roleConnectionHasPermission(roleConnection: RoleConnection, permission: FeaturePermissionName) {
        const self = this;
        return roleConnection
            && roleConnection.isActive()
            && roleConnection.role
            && roleConnection.role.isActive()
            && hasPermission(permission);

        function hasPermission(permissionName: FeaturePermissionName) {
            const roleFeaturePermission = roleConnection.role.extensions.getPermission(permissionName);
            if (!roleFeaturePermission) {
                return false;
            }

            return self.featuresService.isFeatureActive(roleFeaturePermission.featurePermission.feature.name);
        }
    }

    private personActivePermissions(person: Person, includePending = false): IActivePermission[] {
        if (this.currentPerson && this.currentPerson.personId === person.personId) {
            return this.currentPermissions;
        }

        return this.getActivePermissionsFromConnections(person.getActiveConnections(), includePending);
    }

    private getActivePermissionsFromConnections(connections: Connection[], includePending = false): IActivePermission[] {
        const permissions: IActivePermission[] = [];
        for (const connection of connections) {
            const activeCheckFunction = includePending
                ? ActiveEntityUtilities.isActive
                : ActiveEntityUtilities.isActiveNow;

            // Don't have to check for role active here as when you archive a role, all role connections for the role
            // will be ended.
            const validRoleConnections = connection.roleConnections
                .filter((rc) => rc && activeCheckFunction(rc) && rc.role);
            for (const roleConnection of validRoleConnections) {
                const featurePermissions = roleConnection.role.roleFeaturePermissions.map((roleFeaturePermission) => ({
                    teamId: roleConnection.teamId,
                    featurePermission: roleFeaturePermission.featurePermission,
                }));

                permissions.push(...featurePermissions);
            }
        }

        return permissions;
    }

    private permissionIsSatisfiedBySet(requiredPermission: FeaturePermissionName, availablePermissions: IActivePermission[], entity?: IEntityWithOptionalTeam) {
        const relatedPermissions = availablePermissions.filter((i) => i.featurePermission.name === requiredPermission);

        let denyGlobalPermissionForPrivateTeam = false;
        if (entity?.team) {
            // stakeholder already passed the check elsewhere
            // - we only enforce private team restriction for global permission if current person is not a coach
            denyGlobalPermissionForPrivateTeam = entity.team.isPrivate && !this.currentPersonHasActiveCoachConnection;
        }

        let permission = relatedPermissions.find((i) => !i.teamId && !denyGlobalPermissionForPrivateTeam); // global permission only for non team private entity
        if (!permission && entity) {
            permission = relatedPermissions.find((i) => i.teamId === entity.teamId);
        }

        if (!permission) {
            return false;
        }

        const featureName = permission.featurePermission.feature.name;
        return this.featuresService.isFeatureActive(featureName, entity);
    }

    private featureForPermissionIsActive(permission: IActivePermission) {
        const team = permission.teamId ? { teamId: permission.teamId } : undefined;
        return this.featuresService.isFeatureActive(permission.featurePermission.feature.name, team);
    }

    private setPerson(person?: Person) {
        this._currentPerson = person;
        this.currentPermissions = [];
        this.currentServerRoles = [];
    }

    private async promiseToGetOrganisationData() {
        this.allActiveFeaturePermissions = await this.featuresService.promiseToGetAllFeaturePermissions();

        this.currentPersonHasActiveCoachConnection = false;
        const organisation = await this.userService.getDefaultOrganisation();
        if (this.currentPerson && organisation) {
            await Promise.all([
                this.promiseToGetAndSetCurrentServerRoles(),
                this.promiseToUpdatePermissions(),
            ]);

            this.currentPersonHasActiveCoachConnection = this.currentPerson.getActiveConnections().some((c) => c.isCoachConnection());
        }

        if (!this.currentPermissions || !this.currentPermissions.length) {
            if (this.isStakeholderManager()) {
                this.currentPermissions = this.allActiveFeaturePermissions.map((featurePermission: FeaturePermission) =>
                    ({ featurePermission } as IActivePermission));
            }
        }
    }

    private async promiseToGetAndSetCurrentServerRoles() {
        const serverRoles = await this.identityService.getServerSecurityRoles();
        this.currentServerRoles = serverRoles.body ?? [];
    }

    private async promiseToUpdatePermissions() {
        this.currentPermissions = await this.getCurrentPermissions();
        this.log.debug("promiseToUpdatePermissions: promiseToGetAndCacheFeatureStatuses");
        await this.featuresService.promiseToGetAndCacheFeatureStatuses();
    }

    private async getCurrentPermissions() {
        const currentPermissions: IActivePermission[] = [];

        const org = this.orgService.getOrganisationId();
        // throws error if organisation not defined, no need to proceed as route change should handle this
        if (org < 0) {
            return Promise.reject();
        }

        const connections = await this.userService.getActiveConnectionsAndRolePermissions();
        for (const connection of connections) {
            // get feature permissions for role
            for (const roleConnection of connection.roleConnections) {
                if (!roleConnection.role || !roleConnection.role.roleFeaturePermissions) {
                    throw new Error("RoleConnection role & roleFeaturePermissions have not been fetched (and they should have been!)");
                }

                // if role or permissions aren't fetched, or the role is in a team, then don't process permissions
                if (!roleConnection.role || !roleConnection.role.roleFeaturePermissions
                    || !roleConnection.isActive() || !roleConnection.role.isActive()) {
                    continue;
                }

                for (const roleFeaturePermission of roleConnection.role.roleFeaturePermissions) {
                    // teamId will be null if no team - want that to be undefined instead
                    const teamId = roleFeaturePermission.role.teamId
                        ? roleFeaturePermission.role.teamId
                        : undefined;

                    const existingPermission = currentPermissions.find((currentPermission: IActivePermission) => currentPermission.teamId === teamId
                        && currentPermission.featurePermission.featurePermissionId === roleFeaturePermission.featurePermission?.featurePermissionId);
                    if (roleFeaturePermission.featurePermission && !existingPermission) {
                        currentPermissions.push({
                            teamId,
                            featurePermission: roleFeaturePermission.featurePermission,
                        });
                    }
                }
            }
        }

        return currentPermissions;
    }

    public isStakeholderManager() {
        return this.currentServerRoles.includes(ServerRoles.StakeholderManagement);
    }
}

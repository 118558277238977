import { DefaultBackFinishButtons, DefaultBackNextButtons, DefaultNextButtons, GuidedTour } from "@common/lib/guided-tour/guided-tour.service";
import { CommonTourSteps } from "./common-tour-steps";

export const OwnersWhyWrapUpTour: GuidedTour = {
    steps: [CommonTourSteps.openOrgMenuToShowSidebarNavNodeStep("Your Owners 'Why' answers", "Purpose & Vision"), {
        waitForAndAttachToElementSelector: "adapt-display-link-hierarchy adapt-display-navigation-node span",
        elementSelectorTextContent: "Purpose & Vision", // looking for <adapt-display-navigation-node> with span content of this text
        advanceOnAttachedElementEvent: "click",
        attachTo: {
            on: "bottom-end",
        },
        title: `Access purpose & vision`,
        text: `<p>Your organisation's Owners ‘Why’ answers are available here.</p>
        <p><b>Click on it now</b> to show it.</p>`,
        modalOverlayOpeningPadding: 3,
    }, {
        title: "See owners ‘why’ answers",
        text: "Here are the answers you just provided.",
        canClickTarget: false,
        waitForAndAttachToElementSelector: `[data-tour="owners-why-card"]`,
        attachTo: {
            on: "top",
        },
        scrollTo: false,
        buttons: DefaultNextButtons,
    }, {
        title: "Edit owners ‘why’ answers",
        text: "In the future, you can edit your Owners ‘why’ answers using this option, which will run you through the pathway again.",
        canClickTarget: false,
        waitForAndAttachToElementSelector: `[data-tour="owners-why-edit"]`,
        attachTo: {
            on: "bottom",
        },
        scrollTo: false,
        buttons: DefaultBackNextButtons,
    }, {
        title: "Continue",
        text: `<p>You can continue your journey by going to your <b>Personal Dashboard</b>.</p>`,
        waitForAndAttachToElementSelector: `[id="sidebar-personal"]`,
        attachTo: {
            on: "right",
        },
        buttons: DefaultBackFinishButtons,
    }],
};

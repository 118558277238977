import { Component, Input } from "@angular/core";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { ItemStatus } from "@common/ADAPT.Common.Model/organisation/item-status";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { PersonFlag } from "@common/ADAPT.Common.Model/person/person-flag.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { BehaviorSubject, combineLatest, Observable } from "rxjs";
import { filter, map, startWith, switchMap, take, tap } from "rxjs/operators";
import { PersonFlagService } from "../../person/person-flag.service";
import { KanbanService } from "../kanban.service";
import { myKanbanPageRoute } from "../kanban-page/kanban-page.route";

@Component({
    selector: "adapt-personal-work-dashboard-element",
    templateUrl: "./personal-work-dashboard-element.component.html",
    styleUrls: ["./personal-work-dashboard-element.component.scss"],
})
export class PersonalWorkDashboardElementComponent extends BaseComponent {
    @Input() public set person(value: Person) {
        this.person$.next(value);
    }
    private person$ = new BehaviorSubject<Person | undefined>(undefined);
    public url$: Observable<string | undefined>;
    public overdueItems$: Observable<Item[]>;
    public inProgressItems$: Observable<Item[]>;

    public blurPrivateActions = true;
    public blurPrivateActionsDefault = false;

    public itemMenu?: IAdaptMenuItem[];

    public constructor(
        private kanbanService: KanbanService,
        private personFlagService: PersonFlagService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        this.url$ = myKanbanPageRoute.getRoute();
        const entityChange$ = rxjsBreezeService.entityTypeChanged(Item).pipe(
            startWith(undefined),
        );

        this.person$.pipe(
            filter((person) => !!person),
            switchMap((person) => this.personFlagService.isFlagEnabled$(person!.personId, PersonFlag.BlurPrivateActions)),
            this.takeUntilDestroyed(),
        ).subscribe((blurPrivateActions) => {
            this.blurPrivateActionsDefault = blurPrivateActions;
            this.blurPrivateActions = blurPrivateActions;
            this.buildMenu();
        });

        const items$ = combineLatest([entityChange$, this.person$]).pipe(
            filter(([_changes, person]) => !!person),
            switchMap(([_changes, person]) => this.kanbanService.getItemsByPersonId(person!.personId)),
        );

        this.inProgressItems$ = items$.pipe(
            map((items) => items.filter((item) => item.status === ItemStatus.InProgress)),
        );

        this.overdueItems$ = items$.pipe(
            map((items) => items.filter((item) => item.extensions.isOverdue)),
        );
    }

    public toggleBlurPrivateActions() {
        this.blurPrivateActions = !this.blurPrivateActions;
        if (this.blurPrivateActions && !this.blurPrivateActionsDefault) {
            // if blur -> save.
            // The reason a person would be blurring private actions is because he expected them to be blurred next time.
            // Otherwise, what's the point of me blurring my own actions. If blurred, and next time I logged in and not blurred, they will be leaked.
            // Discussed with Steve, and here is the middle ground.
            this.toggleBlurPrivateActionDefault().subscribe();
        }
    }

    @Autobind
    public toggleBlurPrivateActionDefault() {
        return this.person$.pipe(
            filter((person) => !!person),
            take(1),
            tap(() => this.blurPrivateActionsDefault = !this.blurPrivateActionsDefault),
            switchMap((person) => this.personFlagService.setFlagAndSave(person!.personId, PersonFlag.BlurPrivateActions, this.blurPrivateActionsDefault)),
        );
    }

    private buildMenu() {
        this.itemMenu = [{
            icon: MenuComponent.SmallRootMenu.icon,
            items: [{
                text: "Blur private items by default",
                isChecked: this.blurPrivateActionsDefault,
                onIsCheckedChange: () => {
                    this.toggleBlurPrivateActionDefault().subscribe();
                },
            }],
        }];
    }
}

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IAdaptLinkObject, RouteService } from "@common/route/route.service";
import { BaseComponent } from "@common/ux/base.component/base.component";

@Component({
    selector: "adapt-dashboard-element",
    templateUrl: "./dashboard-element.component.html",
    styleUrls: ["./dashboard-element.component.scss"],
})
export class DashboardElementComponent extends BaseComponent {
    @Input() public name?: string;
    @Input() public info?: string;
    @Input() public nested = false;
    @Input() public hideBackground = false;
    @Input() public hideHeader = false;

    @Input() public linkObject?: IAdaptLinkObject;
    @Input() public set url(value: string | IAdaptLinkObject | undefined) {
        this.linkObject = this.routeService.parseRouterObject(value);
    }

    @Input() public disableClick = false;
    @Output() public headerClicked = new EventEmitter<any>();

    public constructor(private routeService: RouteService) {
        super();
    }

    public get hasClickSubscribers() {
        return !this.disableClick && this.headerClicked.observers.length > 0;
    }

    public onHeaderClicked() {
        this.headerClicked.emit();
    }
}

import { Component, Inject, OnInit } from "@angular/core";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { MeetingAgendaItem } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { lastValueFrom } from "rxjs";
import { MeetingsService } from "../meetings.service";

@Component({
    selector: "adapt-meeting-pre-work-dialog",
    templateUrl: "./meeting-pre-work-dialog.component.html",
    styleUrls: ["../meeting-styles.scss"],
})
export class MeetingPreWorkDialogComponent extends BaseDialogComponent<Meeting, void> implements OnInit {
    public readonly dialogName = "meetingPreWorkDialog";

    public loading = true;
    public preWorkAgendaItem?: MeetingAgendaItem;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public meeting: Meeting,
        private meetingsService: MeetingsService,
    ) {
        super();

        // skip loading if pre-work agendaItem with supplementaryData is already present
        const existingPreWork = this.meeting.meetingAgendaItems.find((agendaItem) => agendaItem.extensions.isPreWork);
        if (existingPreWork && existingPreWork.supplementaryData) {
            this.preWorkAgendaItem = existingPreWork;
            this.loading = false;
        }
    }

    public async ngOnInit() {
        // only fetch notes and items if meeting has started/ended
        const agendaItems = await lastValueFrom(this.meetingsService.getAgendaItemsForMeeting(this.meeting, !this.meeting.extensions.isNotStarted));
        this.preWorkAgendaItem = agendaItems.find((agendaItem) => agendaItem.extensions.isPreWork);
        this.loading = false;
    }
}

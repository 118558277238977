import { Component, Inject } from "@angular/core";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { ItemStatus, ItemStatusMetadata } from "@common/ADAPT.Common.Model/organisation/item-status";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";

export interface IItemBatchStatusData {
    items: Item[];
    status: ItemStatus;
}

@Component({
    selector: "adapt-item-batch-select-status-dialog",
    templateUrl: "./item-batch-select-status-dialog.component.html",
})
export class ItemBatchSelectStatusDialogComponent extends BaseDialogComponent<IItemBatchStatusData> {
    public readonly dialogName = "ItemBatchSelectStatusDialog";
    public readonly ItemStatus = ItemStatus;

    public changedItems: Item[];

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public data: IItemBatchStatusData,
    ) {
        super();
        this.changedItems = data.items.filter((item) => item.status !== data.status);
    }

    public get statusMeta() {
        return ItemStatusMetadata.ByStatus[this.data.status];
    }
}

import { ValidationError, Validator } from "breeze-client";
import { InputGroup } from "./input-group";

export class InputGroupExtensions {
    private readonly duplicateNameError: ValidationError;

    public constructor(private inputGroup: InputGroup) {
        this.duplicateNameError = new ValidationError(
            undefined as unknown as Validator,
            {
                property: inputGroup.entityType.getProperty("name"),
            },
            "Competitor name already in use",
            "validInputGroupName",
        );
    }

    public isInputGroupNameUnique(inputGroups: InputGroup[]) {
        const nameExists = inputGroups
            .filter((ig) => ig !== this.inputGroup)
            .some((ig) => ig.name.trim().toLowerCase() === this.inputGroup.name.trim().toLowerCase());
        return !nameExists;
    }

    public validateInputGroupName(inputGroups: InputGroup[]) {
        if (!this.isInputGroupNameUnique(inputGroups)) {
            this.inputGroup.entityAspect.addValidationError(this.duplicateNameError);
        } else {
            this.inputGroup.entityAspect.removeValidationError(this.duplicateNameError);
        }
    }
}

import { Component, Input } from "@angular/core";
import { Account } from "@common/ADAPT.Common.Model/account/account";
import { BaseComponent } from "@common/ux/base.component/base.component";

@Component({
    selector: "adapt-update-billing-details",
    templateUrl: "./update-billing-details.component.html",
})
export class UpdateBillingDetailsComponent extends BaseComponent {
    @Input() public account!: Account;

    public constructor() {
        super();
    }
}

import { InjectionToken, Provider } from "@angular/core";
import { IAdaptRoute } from "@common/route/page-route-builder";

export const PERSON_PROFILE_ROUTE_PROVIDER = new InjectionToken("PERSON_PROFILE_ROUTE_PROVIDER");

export function providePersonProfileRoute(route: IAdaptRoute<{ personId: number }>): Provider {
    return {
        provide: PERSON_PROFILE_ROUTE_PROVIDER,
        useValue: route,
        multi: false,
    };
}

export const MY_PROFILE_ROUTE_PROVIDER = new InjectionToken("MY_PROFILE_ROUTE_PROVIDER");

export function provideMyProfileRoute(route: IAdaptRoute<any>): Provider {
    return {
        provide: MY_PROFILE_ROUTE_PROVIDER,
        useValue: route,
        multi: false,
    };
}
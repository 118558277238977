import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxTreeViewItemRenderedEvent } from "@common/ux/dx.types";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import DataSource from "devextreme/data/data_source";
import dxDropDownBox from "devextreme/ui/drop_down_box";
import dxTreeView, { Item } from "devextreme/ui/tree_view";
import { forkJoin } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { KanbanService } from "../kanban.service";
import { KanbanAuthService } from "../kanban-auth.service";
import { ITreeViewPickerNode, TreeViewUtils } from "./tree-view-utils";

interface ITreeViewPickerLookup {
    [key: string]: ITreeViewPickerNode;
}

interface ISetupData {
    accessibleBoards: Board[];
    editableBoards: Board[];
    currentTeams: Team[];
    hasEditPersonalStewardshipKanban: boolean;
}

@Component({
    selector: "adapt-select-board",
    templateUrl: "./select-board.component.html",
    styleUrls: ["./select-board.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class SelectBoardComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
    @Input() public team?: Team;
    @Input() public boards?: Board[];
    @Output() public boardsChange = new EventEmitter<Board[]>();

    @Input() public required: boolean = false;
    // TODO: do we still need colouredIcon?
    @Input() public showColouredIcon: boolean = false;
    @Input() public allowMultiSelection: boolean = false;
    @Input() public showTeamBoards: boolean = false;
    @Input() public showPersonalBoards: boolean = false;
    @Input() public showEditableBoardsOnly: boolean = false;

    // this saves us annoying checks to get a singular board
    public get board() {
        return this.boards ? this.boards[0] : undefined;
    }

    public selectedIds: string[] = [];
    public isDropdownOpen = false;
    public treeViewDataSource: ITreeViewPickerNode[] = [];

    private treeViewDataIdLookup: ITreeViewPickerLookup = {};
    private treeViewComponent?: dxTreeView;
    private dropdownComponent?: dxDropDownBox;

    private isInternalBoardChange = false;

    constructor(
        private kanbanService: KanbanService,
        private kanbanAuthService: KanbanAuthService,
        private teamsService: CommonTeamsService,
        private rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        this.rxjsBreezeService.entityTypeChanged(Board).pipe(
            debounceTime(10),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.refreshData());
    }

    public async ngOnInit() {
        await this.teamsService.promiseToGetAllTeams();
        this.refreshData();
    }

    public ngOnChanges(changes: SimpleChanges) {
        // We don't need a full refresh if this has occurred because the user has changed a selection
        if (this.isInternalBoardChange) {
            this.isInternalBoardChange = false;
            return;
        }

        if (hasChanged(changes.team) || hasChanged(changes.board) || hasChanged(changes.boards)) {
            this.refreshData();
        }

        function hasChanged(change: SimpleChange) {
            return change && change.currentValue !== change.previousValue && !change.isFirstChange();
        }
    }

    public onDropdownInitialize(component?: dxDropDownBox) {
        this.dropdownComponent = component;
        if (!this.dropdownComponent) {
            return;
        }

        if (this.allowMultiSelection) {
            this.dropdownComponent.option("showClearButton", true);
            this.dropdownComponent.option("placeholder", "Select boards...");
        } else {
            this.dropdownComponent.option("placeholder", "Select board...");
        }
    }

    public onTreeViewInitialize(component?: dxTreeView) {
        this.treeViewComponent = component;
        if (!this.treeViewComponent) {
            return;
        }

        if (!this.allowMultiSelection) {
            this.treeViewComponent.option("selectionMode", "single");
            this.treeViewComponent.option("showCheckBoxesMode", "normal");
        }
    }

    @Autobind
    private refreshData() {
        forkJoin({
            accessibleBoards: this.kanbanService.getAllAccessibleBoards(),
            editableBoards: this.kanbanService.getAllEditableBoards(),
            currentTeams: this.teamsService.promiseToGetActiveTeamsForCurrentPerson(),
            hasEditPersonalStewardshipKanban: this.kanbanAuthService.canEditPersonalStewardshipKanban(),
        }).subscribe(this.setupData);
    }

    @Autobind
    private setupData(results: ISetupData) {
        this.treeViewDataSource = [];

        if (this.team) {
            this.treeViewDataSource = TreeViewUtils.generateTreeViewPickerNodesForTeam(this.team);

            if (this.showEditableBoardsOnly && !results.currentTeams.includes(this.team)) {
                this.treeViewDataSource = this.treeViewDataSource
                    .filter((n) => !n.board || this.kanbanAuthService.currentPersonCanEditBoard(n.board));
            }
        } else {
            const pickableBoards = this.showEditableBoardsOnly
                ? results.editableBoards
                : results.accessibleBoards;

            this.treeViewDataSource = TreeViewUtils.generateTreeViewPickerNodes(pickableBoards, results.currentTeams);

            if (this.showTeamBoards) {
                // the all_boards node has isPersonBased = true, ignore for this filter
                // otherwise, the dropdown will show "all_boards" instead of "All Boards"
                this.treeViewDataSource = this.treeViewDataSource.filter((n) => !n.isPersonBased || n.id === TreeViewUtils.AllBoardsId);
            }
            if (this.showPersonalBoards) {
                this.treeViewDataSource = this.treeViewDataSource.filter((n) => n.isPersonBased);
            }
        }

        // handle pre-selection
        if (this.boards) {
            if (this.allowMultiSelection) {
                this.selectedIds = this.treeViewDataSource
                    .filter((n) => n.board && this.boards!.includes(n.board))
                    .map((n) => n.id);
            } else {
                this.selectedIds = this.treeViewDataSource
                    .filter((n) => n.board === this.board)
                    .map((n) => n.id);
            }
        }

        // update tree view
        this.treeViewDataSource.forEach(this.selectIfSelected);
        this.treeViewDataSource.forEach(this.expandIfHasChildSelection);

        // simplify for dropdown after data source has been set so the correct nodes are selected in the tree view
        if (this.allowMultiSelection) {
            this.selectedIds = TreeViewUtils.simplifySelectedTreeViewNodes(this.selectedIds, this.treeViewDataSource);
        }

        this.setDataSource(this.treeViewDataSource);
        this.treeViewDataIdLookup = this.treeViewDataSource.reduce(reduceTreeView, {} as ITreeViewPickerLookup);

        function reduceTreeView(lookup: ITreeViewPickerLookup, next: ITreeViewPickerNode) {
            lookup[next.id] = next;
            return lookup;
        }
    }

    @Autobind
    private selectIfSelected(node: ITreeViewPickerNode) {
        if (this.nodeIsSelected(node)) {
            node.selected = true;
        }
    }

    @Autobind
    private expandIfHasChildSelection(node: ITreeViewPickerNode) {
        if (this.hasASelectedChild(node)) {
            node.expanded = true;
        }
    }

    @Autobind
    private hasASelectedChild(node: ITreeViewPickerNode): boolean {
        if (this.nodeIsSelected(node)) {
            return true;
        } else {
            const children = this.getNodeChildren(node);

            if (children.length === 0) {
                return false;
            } else {
                return children.some(this.hasASelectedChild);
            }
        }
    }

    private nodeIsSelected(node: ITreeViewPickerNode): boolean {
        return this.selectedIds.includes(node.id);
    }

    private getNodeChildren(node: ITreeViewPickerNode): ITreeViewPickerNode[] {
        return this.treeViewDataSource.filter((n) => n.parentId === node.id);
    }

    private setDataSource(data: ITreeViewPickerNode[]) {
        const dataSource = new DataSource({
            store: data,
            sort: "ordinal",
        });

        // these aren't guaranteed to be available when this runs
        // (namely the treeView does not initialize until the dropdown opens)
        if (this.treeViewComponent) {
            this.treeViewComponent.option("dataSource", dataSource);
        }
        if (this.dropdownComponent) {
            this.dropdownComponent.option("dataSource", dataSource);
        }
    }

    @Autobind
    public onDropdownValueChanged() {
        // if the dropdown reports that it has some selected ids, then we don't need to synchronise the tree view and drop down - as the value is coming from the tree view
        // (NOTE: dxDropdown sets the value (this.selectedIds) to null when clear button is clicked)
        if (this.selectedIds) {
            return;
        }

        // if the user has cleared the selection in the dropdown, then we must synchronise the tree view with this drop down change
        // we do this by unselecting all items in the tree and then setting our tree view default to be 'all boards'
        if (this.treeViewComponent) {
            this.treeViewComponent.unselectAll();
        } else {
            this.treeViewDataSource.forEach((n) => n.selected = false);
        }

        this.onTreeViewSelectionChanged([TreeViewUtils.AllBoardsId]);
    }

    @Autobind
    public onTreeViewSelectionChanged(selectedIds: string[]) {
        const isValid = !this.required || selectedIds.length > 0;
        this.dropdownComponent!.option("isValid", isValid);
        if (isValid) {
            this.dropdownComponent!.option("validationError", {});
        } else {
            const message = this.allowMultiSelection
                ? "At least one board must be selected"
                : "A board selection is required";
            this.dropdownComponent!.option("validationError", { message });
        }

        if (this.allowMultiSelection) {
            this.selectedIds = TreeViewUtils.simplifySelectedTreeViewNodes(selectedIds, this.treeViewDataSource);
        } else {
            // put board entries before team entries so that the correct board is shown in the dropdown
            const sortedIds = selectedIds.sort((a, b) => {
                const mapping = ["team", "board"];
                return mapping.indexOf(b.split("_")[0]) - mapping.indexOf(a.split("_")[0]);
            });
            this.selectedIds = [sortedIds[0]];
        }

        const selectedBoards = selectedIds
            .map((id) => this.treeViewDataIdLookup[id])
            .filter((n) => !!n)
            .map((n) => n.board!)
            .filter((b) => !!b);

        this.boardsChange.emit(selectedBoards);
        this.isInternalBoardChange = true;
        this.boards = selectedBoards;

        if (!this.allowMultiSelection && this.isDropdownOpen && this.dropdownComponent) {
            this.dropdownComponent.close();
        }
    }

    @Autobind
    public onTreeViewItemRendered(e: IDxTreeViewItemRenderedEvent<Item>) {
        const node = e.itemData as ITreeViewPickerNode;

        if (!this.allowMultiSelection && !node.isSingleSelectable) {
            // CM-5081 DX 21.1 has screwed up types - ditch the any
            this.disableTreeViewElement(e.itemElement as any);
        }
    }

    // CM-5081 DX 21.1 has screwed up types - really should be without the prefix?
    private disableTreeViewElement(treeViewElement: JQuery<HTMLElement>) {
        // Prevent click selection as per
        // https://www.devexpress.com/Support/Center/Question/Details/T508174/dxtreeview-how-to-disable-tree-nodes
        treeViewElement.css("pointer-events", "none");

        // Disable checkbox as per
        // https://www.devexpress.com/Support/Center/Question/Details/T242153/dxtreeview-how-to-disable-a-heckbox-in-the-root-node
        const checkboxElement: any = treeViewElement.parent().find(".dx-checkbox");
        checkboxElement.dxCheckBox("instance").option("disabled", true);
    }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ITypedSimpleChange } from "@common/ux/base.component/typed-simple-change";
import { StorageImageService } from "../storage-image.service";

interface IStorageImageChanges extends SimpleChanges {
    imageIdentifier: ITypedSimpleChange<string>;
    imageWidth: ITypedSimpleChange<number>;
    imageHeight: ITypedSimpleChange<number>;
    defaultImageSrc: ITypedSimpleChange<string>;
}

@Component({
    selector: "adapt-storage-image",
    templateUrl: "./storage-image.component.html",
    styleUrls: ["./storage-image.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StorageImageComponent extends BaseComponent implements OnChanges {
    @Input() public imageIdentifier?: string;
    @Input() public imageHeight?: number | string;
    @Input() public imageWidth?: number | string;
    @Input() public defaultImageSrc?: string;
    @Output() public load = new EventEmitter<void>();
    @Output() public error = new EventEmitter<void>();

    public imageSrc?: string;

    private isFirstChange = true;

    public constructor(
        private storageImageService: StorageImageService,
    ) {
        super();

        storageImageService.imageUpdated$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe(
            (imageId) => this.updateImageIfAppropriate(imageId),
        );
    }

    public get style() {
        return {
            "max-height": this.buildDimension(this.imageHeight),
            "max-width": this.buildDimension(this.imageWidth),
            "object-fit": "cover",
        };
    }

    private buildDimension(dimension: number | string | undefined) {
        if (!dimension) {
            return "";
        }

        return typeof dimension === "number"
            ? `${dimension}px`
            : dimension;
    }

    @Autobind
    private updateImageIfAppropriate(imageId?: string) {
        if (imageId === this.imageIdentifier) {
            this.setImage();
        }
    }

    public ngOnChanges(changes: IStorageImageChanges) {
        if (this.isFirstChange || changes.imageWidth || changes.imageHeight) {
            // we want to set the height/width only if its unset and the other one is set
            // this way we can request an image of a certain height and width (i.e. both parameters are set)
            // ...or request an image with just a certain height/width (i.e. only one parameter is set) to maintain the aspect ratio
            this.imageHeight = this.imageHeight
                ? this.imageHeight
                : this.imageWidth
                    ? undefined
                    : 60;
            this.imageWidth = this.imageWidth
                ? this.imageWidth
                : this.imageHeight
                    ? undefined
                    : 60;
        }

        if (this.isFirstChange || changes.imageIdentifier || changes.defaultImageSrc) {
            this.setImage();
        }

        this.isFirstChange = false;
    }

    @Autobind
    private setImage() {
        this.imageSrc = this.imageIdentifier
            ? this.storageImageService.getRetrieveImageUri(this.imageIdentifier)
            : this.defaultImageSrc;
    }
}

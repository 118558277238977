import { Inject, Injectable, Injector } from "@angular/core";
import { OrganisationCategoryValue, Workflow, WorkflowScope, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { WorkflowStep } from "@common/ADAPT.Common.Model/embed/workflow-step";
import { Connection, RoleInOrganisationLabel } from "@common/ADAPT.Common.Model/organisation/connection";
import { FeatureStatus } from "@common/ADAPT.Common.Model/organisation/feature-status";
import { MeetingStatus } from "@common/ADAPT.Common.Model/organisation/meeting";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { SurveyStatus } from "@common/ADAPT.Common.Model/organisation/survey";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { WorkflowConnection, WorkflowConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/workflow-connection";
import { WorkflowStatus, WorkflowStatusBreezeModel, WorkflowStatusEnum } from "@common/ADAPT.Common.Model/organisation/workflow-status";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { Logger } from "@common/lib/logger/logger";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { FunctionUtilities } from "@common/lib/utilities/function-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { PERSONAL_DASHBOARD_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { RouteService } from "@common/route/route.service";
import { UserService } from "@common/user/user.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BehaviorSubject, combineLatest, concat, defer, forkJoin, from, lastValueFrom, merge, Observable, of, shareReplay } from "rxjs";
import { defaultIfEmpty, filter, map, startWith, switchMap, take, tap } from "rxjs/operators";
import { AuthorisationService } from "../authorisation/authorisation.service";
import { FeaturesService } from "../features/features.service";
import { MeetingsService } from "../meetings/meetings.service";
import { BaseOrganisationService } from "../organisation/base-organisation.service";
import { OrganisationDiagnosticAuthService } from "../survey/organisation-diagnostic/organisation-diagnostic-auth.service";
import { SurveyService } from "../survey/survey.service";
import { CommonTeamsService } from "../teams/common-teams.service";
import { CommonTeamsAuthService } from "../teams/common-teams-auth.service";
import { IWorkflowPersistenceConfig, IWorkflowRunData, WorkflowActionState } from "./workflow.interface";
import { WorkflowAuthService } from "./workflow-auth.service";
import { RunWorkflowSearchParam, WorkflowJourneyPageRoute } from "./workflow-journey-page/workflow-journey-page.component";
import { CategoryWorkflowStatus } from "./workflow-map-dashboard-element/category-workflow-status.enum";
import { WorkflowRunDialogComponent } from "./workflow-run-dialog/workflow-run-dialog.component";

export const StartOnboardingSearchParam = "startOnboarding";
const CurrentRunningWorkflowId = "currentRunningWorkflowId";

@Injectable({
    providedIn: "root",
})
export class WorkflowService extends BaseOrganisationService {
    public readonly Logger = Logger.getLogger("WorkflowService");

    private allWorkflows: Workflow[] = [];
    private persistentWorkflows: IWorkflowPersistenceConfig[] = [];

    private workflowIsRunning = false;

    // cache these as they are used VERY often (breeze is quite slow at handling these)
    private activeTeamsForCurrentPerson$?: Observable<Team[]>;
    private leadershipTeam$?: Observable<Team>;
    private workflowConnections$?: Observable<WorkflowConnection[]>;
    private update$ = new BehaviorSubject<any>(undefined);

    public constructor(
        injector: Injector,
        private userService: UserService,
        private teamsService: CommonTeamsService,
        private featuresService: FeaturesService,
        private authorisationService: AuthorisationService,
        private dialogService: AdaptCommonDialogService,
        private routeService: RouteService,
        private rxjsBreezeService: RxjsBreezeService,
        private orgDiagAuthService: OrganisationDiagnosticAuthService,
        @Inject(PERSONAL_DASHBOARD_PAGE) private personalDashboardPageRoute: IAdaptRoute<{}>,
    ) {
        super(injector);

        this.rxjsBreezeService.entityTypeChanged(RoleConnection).pipe(
            filter((rc) => !rc || !!rc.teamId && rc.connection?.personId === this.userService.getCurrentPersonId()),
        ).subscribe(this.update$);
    }

    /**
     * Creates a generic persistence config for the given workflow.
     * Make sure the given workflow has a persistenceId set, otherwise this will throw an error.
     */
    public static CreateWorkflowPersistenceConfig<TInputData = any, TEncodedData = TInputData>(workflow: Workflow) {
        if (!workflow.persistenceId) {
            throw new Error("Must add persistenceId field to workflow to make it persistent");
        }

        return {
            id: workflow.persistenceId,
            encode: (workflowRunData) => workflowRunData.runData,
            decode: (runData) => ({ workflow, runData }),
        } as IWorkflowPersistenceConfig<TInputData, TEncodedData>;
    }

    protected organisationInitialisationActions() {
        return [
            defer(() => {
                // clear the cached observables when org init
                this.activeTeamsForCurrentPerson$ = undefined;
                this.leadershipTeam$ = undefined;
                this.workflowConnections$ = undefined;
                return of(undefined);
            }),
        ];
    }

    public getWorkflowPageRoute(workflowId: string, runWorkflow?: boolean) {
        return WorkflowJourneyPageRoute.getRouteObject({ workflowId }, { runWorkflow });
    }

    public getPersonalPageWithContinueWorkflow(workflowId: string) {
        return this.personalDashboardPageRoute.getRouteObject(undefined, {
            [CurrentRunningWorkflowId]: workflowId,
        });
    }

    public setLocalWorkflows(localWorkflows: Workflow[]) {
        this.allWorkflows = localWorkflows;
    }

    public addPersistentWorkflow(persistentWorkflow: IWorkflowPersistenceConfig) {
        this.persistentWorkflows.push(persistentWorkflow);
    }

    public getAllLocalWorkflows() {
        return this.allWorkflows;
    }

    public getWorkflowsByCategory(category: OrganisationCategoryValue) {
        return this.allWorkflows.filter((workflow) => workflow.category === category ||
            (category === OrganisationCategoryValue.Others && !workflow.category));
    }

    public getWorkflowById(identifier: string) {
        return this.allWorkflows.find((workflow) => workflow.workflowId === identifier ||
            // try find sub-workflows - only 2 levels max so no need to recurse
            workflow.workflows?.find((w) => w.workflowId === identifier));
    }

    public getPersistentWorkflowById(identifier: string) {
        return this.persistentWorkflows.find(({ id }) => id === identifier);
    }

    public getWorkflow(connection?: WorkflowConnection) {
        if (connection?.workflowId) {
            return this.getWorkflowById(connection?.workflowId);
        }

        return undefined;
    }

    public getWorkflowCustomData<T>(workflowConnection: WorkflowConnection) {
        return this.getStatusForWorkflow(workflowConnection).pipe(
            map((status) => {
                if (!status) {
                    throw new Error("Expecting to find workflow status entity to read");
                }

                if (!status.customData) {
                    return {} as T;
                } else {
                    return JSON.parse(status.customData) as T;
                }
            }),
        );
    }

    public updateWorkflowCustomData<T>(workflowConnection: WorkflowConnection, customData: T, save = true) {
        return this.getStatusForWorkflow(workflowConnection).pipe(
            switchMap((status) => {
                if (!status) {
                    throw new Error("Expecting to find workflow status entity to update");
                }

                status.customData = JSON.stringify(customData);
                if (!save) {
                    return of(status);
                }
                return this.saveEntities(status).pipe(
                    map(() => status),
                );
            }),
        );
    }

    public enableFeaturesForWorkflow(workflow: Workflow) {
        if (!workflow.featuresToEnable || workflow.featuresToEnable.length === 0) {
            return of(undefined);
        }

        // TODO: check if user can enable features.
        //  user are all leader by default in HQ, but this may change.
        return forkJoin(workflow.featuresToEnable.map((feature) => {
            if (!this.featuresService.checkIfFeatureActiveAndSaved(feature)) {
                return this.featuresService.promiseToSetFeatureStatus(feature, undefined, true);
            }
            return of(undefined);
        })).pipe(
            switchMap((featureStatuses) => {
                const definedFeatureStatuses = featureStatuses
                    .filter((f) => !!f) as FeatureStatus[];
                return this.commonDataService.saveEntities(definedFeatureStatuses);
            }),
        );
    }

    public getAllWorkflowConnections() {
        // TODO: optimise this later - currently there is only a limited number of workflow connections for each org
        // - and hence this is mainly used for priming purposes (reduce number of requests)
        // - this is included all connections which are completed as well (which are needed for the trophy status)
        if (!this.workflowConnections$) {
            this.workflowConnections$ = merge(
                this.rxjsBreezeService.entityTypeChanged(WorkflowConnection),
                this.update$,
            ).pipe(
                startWith(undefined),
                switchMap(() => this.commonDataService.getAll(WorkflowConnectionBreezeModel)),
                shareReplay(1),
            );
        }

        return this.workflowConnections$.pipe(take(1));
    }

    // this is still used by meeting - custom data has connectionId, which will use this
    public getWorkflowConnectionById(workflowConnectionId: number) {
        return this.commonDataService.getById(WorkflowConnectionBreezeModel, workflowConnectionId);
    }

    public executeWorkflow(workflowConnection: WorkflowConnection, workflow?: Workflow, skipOutcomes = false) {
        return this.dialogService.open(
            WorkflowRunDialogComponent,
            {
                workflowConnection,
                workflow,
                skipOutcomes,
            } as IWorkflowRunData,
        );
    }

    public updateWorkflowRunSearchParam(workflowId: string) {
        this.workflowIsRunning = true;
        if (this.routeService.getSearchParameterValue(RunWorkflowSearchParam) || this.routeService.getSearchParameterValue(StartOnboardingSearchParam)) {
            // only add if not running from journey page, which is already handled by runWorkflow=true param
            // or StartOnboarding search param
            return Promise.resolve(false);
        }

        return this.routeService.updateSearchParameterValue(CurrentRunningWorkflowId, workflowId, true);
    }

    public updateWorkflowStopSearchParam(workflowId: string) {
        this.workflowIsRunning = false;
        // only clear if there is a matching existing id
        const existingWorkflowId = this.routeService.getSearchParameterValue(CurrentRunningWorkflowId);
        if (existingWorkflowId === workflowId) {
            return this.routeService.updateSearchParameterValue(CurrentRunningWorkflowId, undefined, true);
        } else {
            const hasStartOnboarding = this.routeService.getSearchParameterValue(StartOnboardingSearchParam);
            if (hasStartOnboarding) {
                return this.routeService.deleteSearchParameter(StartOnboardingSearchParam, true);
            }
        }

        return Promise.resolve(false);
    }

    public continueWorkflowFromSearchParam() {
        const existingWorkflowId = this.routeService.getSearchParameterValue(CurrentRunningWorkflowId);
        if (existingWorkflowId && !this.workflowIsRunning) {
            const workflow = this.getWorkflowById(existingWorkflowId);
            if (workflow) {
                return this.canContinueWorkflow(workflow).pipe(
                    switchMap((canContinue) => {
                        if (canContinue) {
                            return this.executeJourney(workflow);
                        } else {
                            return this.updateWorkflowStopSearchParam(existingWorkflowId);
                        }
                    }),
                );
            }

            return from(this.updateWorkflowStopSearchParam(existingWorkflowId));
        } else {
            return of(undefined);
        }
    }

    public executeJourney(workflow: Workflow, restartIfCompleted = false, skipOutcomes = false) {
        return this.getOrCreateWorkflowConnectionForWorkflow(workflow).pipe(
            switchMap(({ workflowConnection, workflowStatus }) => {
                return this.executeJourneyForConnectionWithStatus(workflowConnection, workflowStatus, restartIfCompleted, skipOutcomes);
            }),
        );
    }

    public executeJourneyForConnection(workflowConnection: WorkflowConnection, restartIfCompleted = false, skipOutcomes = false) {
        return this.getStatusForWorkflow(workflowConnection).pipe(
            switchMap((status) => {
                return this.executeJourneyForConnectionWithStatus(workflowConnection, status, restartIfCompleted, skipOutcomes);
            }),
        );
    }

    public getWorkflowStateButtonText(state: WorkflowActionState, actionText?: string) {
        switch (state) {
            case WorkflowActionState.NoPermission:
            case WorkflowActionState.NotInOrganisation:
                return "No access";
            case WorkflowActionState.PrerequisitesNotSatisfied:
                return "Finish pathways to unlock";
            case WorkflowActionState.ComingSoon:
                return "Planned";
            case WorkflowActionState.Completed:
                return "Completed";
            default:
                if (actionText) {
                    return actionText;
                }
                const continueStates = [WorkflowActionState.Current, WorkflowActionState.NotInStartedTeam];
                return continueStates.includes(state)
                    ? "Continue pathway"
                    : "Start pathway";
        }
    }

    public getWorkflowStateButtonIcon(state: WorkflowActionState, actionIcon?: string, currentStatus?: WorkflowStatusEnum) {
        switch (state) {
            case WorkflowActionState.Completed:
                return actionIcon ?? "fal fa-fw fa-check";
            case WorkflowActionState.Current:
            case WorkflowActionState.NotStarted:
            // TODO: should we allow custom icon when disabled?
            case WorkflowActionState.Disabled:
                return actionIcon ?? `${currentStatus === WorkflowStatusEnum.Current ? "fas" : "fal"} fa-fw fa-play`;
            default:
                return "fal fa-fw fa-lock";
        }
    }

    public getWorkflowStateTooltip(state: WorkflowActionState, workflow: Workflow, disabledTooltip?: string) {
        switch (state) {
            case WorkflowActionState.Completed:
                return "You can restart the pathway by clicking here";
            case WorkflowActionState.NoPermission:
                return "You do not have permission to run pathways";
            case WorkflowActionState.NotInOrganisation:
                return "You are not in the organisation";
            case WorkflowActionState.PrerequisitesNotSatisfied:
                const compulsoryPrerequisiteWorkflows = this.getAllPrerequisiteWorkflows(workflow);
                const workflowListItems = compulsoryPrerequisiteWorkflows
                    .map((flow) => `<li>${flow.name}</li>`)
                    .join("\n");
                return `<p class="mb-0">The following pathways need to be completed prior to starting this pathway:</p>
                    <ul class="mt-1 mb-0 list-content">${workflowListItems}</ul>`;
            case WorkflowActionState.MissingRoles:
                const roleListItems = (workflow.requiredRoles ?? [])
                    .map((role) => `<li>${RoleInOrganisationLabel[role]}</li>`)
                    .join("\n");
                return `<p class="mb-0">Unfortunately, you do not possess the necessary roles within the organisation to start this pathway.</p>
                    <p class="mb-0">One of the following roles is required:</p>
                    <ul class="mt-1 mb-0 list-content">${roleListItems}</ul>`;
            case WorkflowActionState.NotInLeadershipTeam:
                return "You cannot start the pathway because you are not in the leadership team";
            case WorkflowActionState.NotInStartedTeam:
                return "You cannot continue the pathway as you are not in the team that started it";
            case WorkflowActionState.ComingSoon:
                return "This pathway is planned to be completed sometime in the future";
            case WorkflowActionState.Disabled:
                return disabledTooltip;
        }
    }

    public async getWorkflowState(workflowConnection?: WorkflowConnection, workflow?: Workflow, disabled = false) {
        const connectionWorkflow = this.getWorkflow(workflowConnection);
        if (!workflow) {
            workflow = connectionWorkflow;
        } else if (!workflowConnection) {
            workflowConnection = await lastValueFrom(this.getLatestWorkflowConnectionForWorkflow(workflow));
        }

        if (!workflow || disabled) {
            return WorkflowActionState.Disabled;
        }

        const hasWorkflowAccess = await this.authorisationService.promiseToGetHasAccess(WorkflowAuthService.EditWorkflow);
        if (!hasWorkflowAccess) {
            return WorkflowActionState.NoPermission;
        }

        const currentPerson = await this.userService.getCurrentPerson();
        const hasOrganisationConnection = !!(currentPerson?.getLatestConnection()?.isActive());
        if (!hasOrganisationConnection) {
            return WorkflowActionState.NotInOrganisation;
        }

        if (workflow.extensions.isComingSoon) {
            return WorkflowActionState.ComingSoon;
        }

        const compulsoryPrerequisiteWorkflows = this.getAllPrerequisiteWorkflows(workflow);
        const hasRequiredWorkflows = compulsoryPrerequisiteWorkflows && compulsoryPrerequisiteWorkflows.length > 0;
        if (hasRequiredWorkflows) {
            const prerequisitesSatisfied = await lastValueFrom(this.areWorkflowPrerequisitesSatisfied(workflow));
            if (!prerequisitesSatisfied) {
                return WorkflowActionState.PrerequisitesNotSatisfied;
            }
        }

        const status = await lastValueFrom(workflowConnection
            ? this.getStatusForWorkflow(workflowConnection)
            : this.getLatestStatusForWorkflow(workflow));
        const currentStatus = status?.status ?? WorkflowStatusEnum.Incomplete;

        if (currentStatus === WorkflowStatusEnum.Current) {
            const canContinueWorkflow = await lastValueFrom(this.canContinueWorkflow(workflow));
            if (!canContinueWorkflow) {
                return this.currentPersonHasRequiredRoles(workflow)
                    ? WorkflowActionState.NotInStartedTeam
                    : WorkflowActionState.MissingRoles;
            }
        }

        const canStartWorkflow = await lastValueFrom(this.canStartWorkflow(workflow));
        if (!canStartWorkflow) {
            return this.currentPersonHasRequiredRoles(workflow)
                ? WorkflowActionState.NotInLeadershipTeam
                : WorkflowActionState.MissingRoles;
        }

        if (currentStatus === WorkflowStatusEnum.Completed) {
            return WorkflowActionState.Completed;
        }

        return currentStatus === WorkflowStatusEnum.Current
            ? WorkflowActionState.Current
            : WorkflowActionState.NotStarted;
    }

    private executeJourneyForConnectionWithStatus(workflowConnection: WorkflowConnection, workflowStatus?: WorkflowStatus, restartIfCompleted = false, skipOutcome = false) {
        const workflow = this.getWorkflow(workflowConnection);
        if (!workflow) {
            throw new Error(`Workflow ${workflowConnection.workflowId} could not be found`);
        }

        this.log.debug(`journey ${workflow.workflowId} starting...`);

        if (!workflowStatus || workflowStatus.status !== WorkflowStatusEnum.Completed || restartIfCompleted) {
            this.log.debug(`journey ${workflow.workflowId} not completed, resuming...`);
            return this.getOrSetCurrentStepForJourney(workflowConnection, true).pipe(
                switchMap((workflowStep) => {
                    const stepWorkflow = workflowStep?.workflow;
                    return this.executeWorkflow(workflowConnection, stepWorkflow, skipOutcome);
                }),
                switchMap(() => this.checkRunNextWorkflow(workflow, restartIfCompleted, skipOutcome)),
            );
        }

        this.log.debug(`journey ${workflow.workflowId} is completed`);
        return this.checkRunNextWorkflow(workflow, restartIfCompleted, skipOutcome);
    }

    private checkRunNextWorkflow(currentWorkflow: Workflow, restartIfCompleted: boolean, skipOutcome: boolean): Observable<undefined> {
        if (currentWorkflow.nextWorkflowId) {
            const nextWorkflow = this.getWorkflowById(currentWorkflow.nextWorkflowId);
            if (nextWorkflow) {
                return this.executeJourney(nextWorkflow, restartIfCompleted, skipOutcome);
            }
        }

        return of(undefined);
    }

    private getOrCreateWorkflowConnectionForWorkflow(workflow: Workflow): Observable<{
        workflowConnection: WorkflowConnection,
        workflowStatus?: WorkflowStatus,
    }> {
        return this.getLatestWorkflowConnectionForWorkflow(workflow).pipe(
            switchMap((workflowConnection) => {
                if (!workflowConnection) {
                    this.log.debug(`journey ${workflow.workflowId} connection not found, creating...`);
                    return this.createWorkflowConnectionForWorkflow(workflow);
                }

                return this.getStatusForWorkflow(workflowConnection, workflow).pipe(
                    map((workflowStatus) => ({ workflowConnection, workflowStatus })),
                );
            }),
        );
    }

    // check if the current person can start the workflow
    public canStartWorkflow(workflow: Workflow) {
        if (workflow.extensions.isComingSoon) {
            return of(false);
        }

        if (workflow.scope === WorkflowScope.Team) {
            return forkJoin([
                this.getActiveTeamIdsForCurrentPerson(),
                this.getLeadershipTeam(),
            ]).pipe(
                // assuming all team scoped workflow requires leadership team membership - that's the only team in MVP
                map(([currentPersonTeamIds, leadershipTeam]) => leadershipTeam && currentPersonTeamIds.includes(leadershipTeam.teamId)),
                map((passedTeamCheck) => passedTeamCheck && this.currentPersonHasRequiredRoles(workflow)),
            );
        } else {
            // only team scoped workflow requires the current person to be in the leadership team to start
            return of(this.currentPersonHasRequiredRoles(workflow));
        }
    }

    // check if current person can continue a started workflow
    public canContinueWorkflow(workflow: Workflow) {
        return this.getLatestStatusForWorkflow(workflow).pipe(
            switchMap((workflowStatus) => {
                if (!workflowStatus || workflowStatus.status !== WorkflowStatusEnum.Current) {
                    // not started or completed -> cannot continue
                    return of(false);
                }

                if (workflowStatus.workflowConnection.teamId) {
                    // can only continue if you are in the team
                    return this.getActiveTeamIdsForCurrentPerson().pipe(
                        map((currentPersonTeamIds) => currentPersonTeamIds.includes(workflowStatus.workflowConnection.teamId!)),
                        map((passedTeamCheck) => passedTeamCheck && this.currentPersonHasRequiredRoles(workflow)),
                    );
                } else {
                    // only the current person can continue
                    return of(workflowStatus.workflowConnection.connection?.personId === this.userService.getCurrentPersonId() &&
                        this.currentPersonHasRequiredRoles(workflow));
                }
            }),
        );
    }

    public currentPersonHasRequiredRoles(workflow: Workflow) {
        if (!workflow.requiredRoles || workflow.requiredRoles.length < 1) {
            return true;
        }

        const currentPersonRole = this.userService.currentPerson?.getLatestConnection()?.roleInOrganisation;
        if (currentPersonRole) {
            return workflow.requiredRoles.includes(currentPersonRole);
        }

        // not restricting if you don't have a role in the organisation
        // - if stakeholder manager, this will be true, then you will see message about not in the leadership team if he tries to start/continue
        return true;
    }

    public createWorkflowConnectionForWorkflow(workflow: Workflow, person?: Person, teams?: Team[], saveAfterCreate = true, forceCreate = false) {
        return combineLatest([
            person ? of(person) : this.userService.getCurrentPerson(),
            teams ? of(teams) : this.getActiveTeamsForCurrentPerson(),
        ]).pipe(
            switchMap((args) => this.enableFeaturesForWorkflow(workflow).pipe(
                map(() => args),
            )),
            switchMap(([innerPerson, innerTeams]) => this.getOrCreateWorkflowConnection(workflow, innerPerson!.getLatestConnection()!, innerTeams.length ? innerTeams[0] : undefined, false, forceCreate)),
            switchMap((workflowConnection) => {
                // give the journey an incomplete status so it will show on the dashboard.
                // if we use getOrSetCurrentStepForJourney, the first step will be marked current, which means the outcomes won't be shown.
                return this.getOrCreateStatusForWorkflow(workflowConnection, workflow, false).pipe(
                    map((workflowStatus) => ({ workflowConnection, workflowStatus })),
                );
            }),
            switchMap((entities) => saveAfterCreate
                ? this.commonDataService.saveEntities(Object.values(entities)).pipe(map(() => entities))
                : of(entities)),
        );
    }

    public getLatestStatusForWorkflow(workflow: Workflow) {
        return this.getLatestWorkflowConnectionForWorkflow(workflow).pipe(
            switchMap((workflowConnection) => workflowConnection
                ? this.getStatusForWorkflow(workflowConnection)
                : of(undefined)),
        );
    }

    public getLatestWorkflowConnectionForWorkflow(workflow?: Workflow) {
        if (!workflow) {
            return of(undefined);
        }

        return this.getAllWorkflowConnections().pipe(
            switchMap(() => this.getActiveTeamIdsForCurrentPerson()),
            switchMap((teamIds) => this.getLatestWorkflowConnection(workflow, this.userService.getCurrentPersonId(), teamIds)),
        );
    }

    // so we can work out if a workflow has been completed or not.
    // this gets workflowStatus as well
    public getLatestWorkflowConnection(workflow?: Workflow, personId?: number, teamIds?: number[]) {
        if (!workflow) {
            return of(undefined);
        }

        const connectionPredicate = this.getWorkflowConnectionPredicate(workflow, personId, teamIds);
        const key = `getLatestWorkflowConnectionForWorkflow${connectionPredicate.getKey()}`;
        return this.commonDataService.getWithOptions(WorkflowConnectionBreezeModel, key, {
            predicate: connectionPredicate,
            navProperty: "statuses",
            top: 1,
            orderBy: "workflowConnectionId DESC",
            postRequestEncompassingKey: (entities: WorkflowConnection[]) => entities.length > 0
                ? `getStatusForWorkflowConnection${entities[0].workflowConnectionId}`
                : undefined,
        }).pipe(
            // find the latest connection (highest workflowConnectionId)
            map(ArrayUtilities.getSingleFromArray),
        );
    }

    public isSurveyUsedByActiveWorkflow(surveyId: number) {
        return this.getAllOutstandingWorkflowConnections().pipe(
            switchMap((connections) => concat(...connections.map((connection) => this.getWorkflowCustomData<any>(connection)))),
            map((customData) => customData?.surveyId === surveyId),
            filter((surveyUsed) => surveyUsed),
            take(1), // first connection to have a matching surveyId will complete
            defaultIfEmpty(false),
        );
    }

    private getWorkflowConnectionPredicate(workflow: Workflow, personId?: number, teamIds?: number[]) {
        const connectionPredicate = new MethodologyPredicate<WorkflowConnection>("workflowId", "==", workflow.workflowId);
        switch (workflow.scope) {
            case WorkflowScope.Personal:
                connectionPredicate.and(new MethodologyPredicate<WorkflowConnection>("connection.personId", "==", personId ?? null));
                break;
            case WorkflowScope.Team:
                if (teamIds?.length) {
                    connectionPredicate.and(new MethodologyPredicate<WorkflowConnection>("teamId", "in", teamIds));
                }
                break;
            default:
                this.Logger.error(`Unexpected workflow scope '${workflow.scope}'`);
                break;
        }

        return connectionPredicate;
    }

    private getOrCreateWorkflowConnection(workflow: Workflow, connection: Connection, team?: Team, saveAfterCreate = true, forceCreate = false) {
        let connectionQuery: Observable<WorkflowConnection[]>;
        if (forceCreate) {
            connectionQuery = of([]);
        } else if (workflow.scope === WorkflowScope.Personal) {
            connectionQuery = this.getOutstandingWorkflowConnectionsForPerson(connection.person.personId);
        } else if (team) {
            connectionQuery = this.getOutstandingWorkflowConnectionsForTeam(team.teamId);
        } else {
            throw new Error(`Workflow with scope of "${workflow.scope} cannot be defined without team"`);
        }

        return connectionQuery.pipe(
            switchMap((workflowConnections) => {
                // check if workflow is already assigned, if not assign it
                const existingConnection = workflowConnections.find((workflowConnection) => workflowConnection.workflowId === workflow.workflowId);
                if (existingConnection) {
                    return of(existingConnection);
                }

                return this.createWorkflowConnection(workflow, connection, team).pipe(
                    switchMap((newConnection) => saveAfterCreate
                        ? this.commonDataService.saveEntities(newConnection).pipe(
                            map(() => newConnection))
                        : of(newConnection)),
                );
            }),
        );
    }

    private createWorkflowConnection(workflow: Workflow, connection?: Connection, team?: Team) {
        return this.commonDataService.create(WorkflowConnectionBreezeModel, {
            connectionId: workflow.scope === WorkflowScope.Personal ? connection!.connectionId : undefined,
            teamId: workflow.scope === WorkflowScope.Team ? team!.teamId : undefined,
            organisationId: workflow.scope === WorkflowScope.Personal ? connection!.organisationId : team!.organisationId,
        }).pipe(
            tap((workflowConnection) => workflowConnection.workflowId = workflow.workflowId),
        );
    }

    public deleteWorkflowConnection(workflowConnection: WorkflowConnection) {
        return this.finalizeWorkflow(workflowConnection).pipe(
            switchMap(() => this.commonDataService.remove(workflowConnection)),
            switchMap(() => this.commonDataService.saveEntities([workflowConnection])),
        );
    }

    public finalizeWorkflow(workflowConnection?: WorkflowConnection) {
        const workflow = this.getWorkflow(workflowConnection);

        const preDeleteTask = FunctionUtilities.isFunction(workflow?.finalizeWorkflow)
            ? workflow!.finalizeWorkflow(this.injector, workflowConnection)
            : of(undefined);

        return preDeleteTask.pipe(
            switchMap(() => this.commonFinalizeWorkflow(workflowConnection)),
        );
    }

    public getAssociatedMeetingSurvey(workflowConnection: WorkflowConnection) {
        const meetingsService = this.injector.get(MeetingsService);
        const surveyService = this.injector.get(SurveyService);
        const canReadMeetings$ = this.authorisationService.getHasAccess(CommonTeamsAuthService.ViewAnyTeamMeeting).pipe(take(1));
        const canReadSurveys$ = this.orgDiagAuthService.hasReadAccessToSurveys$();

        return forkJoin([
            this.getWorkflowCustomData<any>(workflowConnection),
            canReadMeetings$,
            canReadSurveys$,
        ]).pipe(
            switchMap(([customData, canReadMeetings, canReadSurvey]) => forkJoin([
                (customData?.meetingId && canReadMeetings) ? meetingsService.getMeetingById(customData.meetingId) : of(undefined),
                (customData?.surveyId && canReadSurvey) ? surveyService.getSurveyById(customData.surveyId) : of(undefined),
            ])),
        );
    }

    private commonFinalizeWorkflow(workflowConnection?: WorkflowConnection) {
        if (!workflowConnection) {
            return of(undefined);
        }

        return this.getAssociatedMeetingSurvey(workflowConnection).pipe(
            switchMap(([meeting, survey]) => {
                const removeEntities: IBreezeEntity[] = [];
                if (meeting && meeting.status !== MeetingStatus.Ended) {
                    // meeting that's not completed will be deleted
                    removeEntities.push(meeting);
                }

                if (survey && survey.status !== SurveyStatus.Ended) {
                    // survey that's not ended will be deleted
                    removeEntities.push(survey);
                }

                return removeEntities.length > 0
                    ? forkJoin(removeEntities.map((entity) => this.remove(entity))).pipe(
                        switchMap(() => this.saveEntities(removeEntities)))
                    : of(undefined);
            }),
        );
    }

    // this is for all connected workflows which is not completed
    public getOutstandingWorkflowConnectionsForPerson(personId: number, teamId?: number) {
        // this will include all teams if teamId is not defined
        return this.getAllWorkflowConnections().pipe( // prime all workflow connections first as there are quite a few variety of connection queries
            switchMap(() => teamId ? of([teamId]) : this.getActiveTeamIdsForCurrentPerson()),
            switchMap((teamIds) => {
                const connectionPredicate = new MethodologyPredicate<WorkflowConnection>("connection.personId", "==", personId);
                if (teamIds.length > 0) {
                    connectionPredicate.or(new MethodologyPredicate<WorkflowConnection>("teamId", "in", teamIds));
                }
                const statusesPredicate = new MethodologyPredicate<WorkflowStatus>("workflowId", "==", null)
                    .and(new MethodologyPredicate<WorkflowStatus>("workflowStepId", "==", null))
                    .and(new MethodologyPredicate<WorkflowStatus>("status", "!=", WorkflowStatusEnum.Completed));
                const predicate = new MethodologyPredicate<WorkflowConnection>("statuses", "any", statusesPredicate)
                    .and(connectionPredicate);
                return this.commonDataService.getWithOptions(WorkflowConnectionBreezeModel, connectionPredicate.getKey("outstandingWorkflowConnections"), {
                    predicate,
                    orderBy: "workflowConnectionId DESC",
                });
            }),
        );
    }

    public getOutstandingWorkflowConnectionsForTeam(teamId: number) {
        const connectionPredicate = new MethodologyPredicate<WorkflowConnection>("teamId", "==", teamId);
        const statusesPredicate = new MethodologyPredicate<WorkflowStatus>("workflowId", "==", null)
            .and(new MethodologyPredicate<WorkflowStatus>("workflowStepId", "==", null))
            .and(new MethodologyPredicate<WorkflowStatus>("status", "!=", WorkflowStatusEnum.Completed));
        const predicate = new MethodologyPredicate<WorkflowConnection>("statuses", "any", statusesPredicate)
            .and(connectionPredicate);
        return this.commonDataService.getWithOptions(WorkflowConnectionBreezeModel, connectionPredicate.getKey("outstandingWorkflowConnections"), {
            predicate,
        });
    }

    public getAllOutstandingWorkflowConnections() {
        const statusesPredicate = new MethodologyPredicate<WorkflowStatus>("workflowId", "==", null)
            .and(new MethodologyPredicate<WorkflowStatus>("workflowStepId", "==", null))
            .and(new MethodologyPredicate<WorkflowStatus>("status", "!=", WorkflowStatusEnum.Completed));
        return this.commonDataService.getByPredicate<WorkflowConnection>(WorkflowConnectionBreezeModel,
            new MethodologyPredicate<WorkflowConnection>("statuses", "any", statusesPredicate));
    }

    public areWorkflowPrerequisitesSatisfied(workflow: Workflow) {
        const requiredWorkflows = this.getAllPrerequisiteWorkflows(workflow);
        if (requiredWorkflows.length === 0) {
            return of(true);
        }

        // make sure all workflows have been completed before
        const requiredChecks = requiredWorkflows
            .map((flow) => this.hasWorkflowCompletedBeforeByCurrentPerson(flow));

        return this.getAllWorkflowConnections().pipe( // prime once so all subsequent checks won't issue a separate query
            switchMap(() => forkJoin(requiredChecks)),
            map((completes) => completes.every(Boolean)),
        );
    }

    public hasWorkflowCompletedBeforeByCurrentPerson(workflow: Workflow) {
        return this.getActiveTeamIdsForCurrentPerson().pipe(
            switchMap((teamIds) => this.hasWorkflowCompletedBefore(workflow, this.userService.getCurrentPersonId(), teamIds)),
        );
    }

    private hasWorkflowCompletedBefore(workflow: Workflow, personId?: number, teamIds?: number[]) {
        const predicate = this.getWorkflowConnectionPredicate(workflow, personId, teamIds);
        const key = `getWorkflowConnectionsForWorkflow${predicate.getKey()}`;
        return this.commonDataService.getWithOptions(WorkflowConnectionBreezeModel, key, {
            predicate,
            navProperty: "statuses",
        }).pipe(
            map((connections) => !!connections.find(
                (connection) => !!connection.statuses.find(
                    (status) => !status.workflowId && !status.workflowStepId // top level connection status
                        && status.status === WorkflowStatusEnum.Completed))),
        );
    }

    public getAllPrerequisiteWorkflows(workflow: Workflow) {
        if (!workflow.compulsoryPrerequisites || workflow.compulsoryPrerequisites.length === 0) {
            return [];
        }

        const workflows = new Set<Workflow>();

        for (const requisiteId of workflow.compulsoryPrerequisites) {
            const flow = this.getWorkflowById(requisiteId);
            if (flow) {
                workflows.add(flow);
            }
        }

        return Array.from(workflows)
            .sort(SortUtilities.getSortByFieldFunction<Workflow>("ordinal"));
    }

    public getStatusForWorkflow(workflowConnection: WorkflowConnection, workflow?: Workflow) {
        const workflowPredicate = new MethodologyPredicate<WorkflowStatus>("workflowId", "==",
            (!workflow || workflowConnection.workflowId === workflow.workflowId) ? null : workflow.workflowId);
        const workflowStepPredicate = new MethodologyPredicate<WorkflowStatus>("workflowStepId", "==", null);
        const predicate = new MethodologyPredicate<WorkflowStatus>("workflowConnectionId", "==", workflowConnection.workflowConnectionId)
            .and(workflowPredicate)
            .and(workflowStepPredicate);
        const key = `getStatusForWorkflow${predicate.getKey()}`;
        return this.commonDataService.getWithOptions(WorkflowStatusBreezeModel, key, {
            predicate,
            encompassingKey: this.getStatusForWorkflowConnectionEncompassingKey(workflowConnection),
        }).pipe(
            map(ArrayUtilities.getSingleFromArray),
        );
    }

    private getOrCreateStatusForWorkflow(workflowConnection: WorkflowConnection, workflow?: Workflow, saveAfterCreate = false) {
        return this.getStatusForWorkflow(workflowConnection, workflow).pipe(
            switchMap((rootStatus) => rootStatus
                ? of(rootStatus)
                : this.createStatusEntity(workflowConnection, this.isTopConnectionWorkflow(workflowConnection, workflow) ? undefined : workflow).pipe(
                    switchMap((newStatus) => {
                        if (saveAfterCreate) {
                            return this.commonDataService.saveEntities([newStatus]).pipe(
                                map(() => newStatus),
                            );
                        }
                        return of(newStatus);
                    }),
                )),
        );
    }

    private isTopConnectionWorkflow(workflowConnection: WorkflowConnection, workflow?: Workflow) {
        return workflowConnection.workflowId === workflow?.workflowId;
    }

    private getStatusForWorkflowStep(workflowConnection: WorkflowConnection, workflowStep: WorkflowStep) {
        const predicate = new MethodologyPredicate<WorkflowStatus>("workflowConnectionId", "==", workflowConnection.workflowConnectionId)
            .and(new MethodologyPredicate<WorkflowStatus>("workflowId", "==", null))
            .and(new MethodologyPredicate<WorkflowStatus>("workflowStepId", "==", workflowStep.workflowStepId ?? null));
        const key = `getStatusForWorkflowStep${predicate.getKey()}`;
        return this.commonDataService.getWithOptions(WorkflowStatusBreezeModel, key, {
            predicate,
            encompassingKey: this.getStatusForWorkflowConnectionEncompassingKey(workflowConnection),
        }).pipe(
            map(ArrayUtilities.getSingleFromArray),
        );
    }

    public updateStatusForWorkflowStep(workflowConnection: WorkflowConnection, workflowStep: WorkflowStep, status: WorkflowStatusEnum) {
        const changedEntities: WorkflowStatus[] = [];
        // if updating status -> flowConnection.statuses should have already been populated with the status of all steps and workflow
        const stepStatusEntity = this.findStatusEntityForStep(workflowStep, workflowConnection.statuses);
        if (stepStatusEntity) {
            stepStatusEntity.status = status;
            if (status === WorkflowStatusEnum.Completed) {
                stepStatusEntity.completionTime = new Date();
                stepStatusEntity.completedById = this.userService.getCurrentPersonId();
            } else if (status === WorkflowStatusEnum.Current) {
                stepStatusEntity.startTime = new Date();
                stepStatusEntity.startedById = this.userService.getCurrentPersonId();
            }
            changedEntities.push(stepStatusEntity);

            if (status === WorkflowStatusEnum.Completed || status === WorkflowStatusEnum.Skipped) {
                // check all siblings for completion or skipped
                const workflowCompleted = workflowStep.workflow.steps?.filter((step) => step !== workflowStep)
                    .map((step) => this.findStatusEntityForStep(step, workflowConnection.statuses))
                    .every((stepStatus) => stepStatus?.status === WorkflowStatusEnum.Completed || stepStatus?.status === WorkflowStatusEnum.Skipped);
                if (workflowCompleted) {
                    // set workflow to completed
                    const workflowStatusEntity = this.findStatusEntityForWorkflow(workflowStep.workflow, workflowConnection.statuses);
                    if (workflowStatusEntity) {
                        this.setWorkflowStatusCompleted(workflowStatusEntity);
                        changedEntities.push(workflowStatusEntity);

                        if (this.areStatusAndStepInSameWorkflow(workflowStatusEntity, workflowStep) && workflowStep.workflow.parentWorkflow) {
                            // workflow is part of a journey -> check all workflows for the journey (parentWorkflow)
                            const journeyCompleted = workflowStep.workflow.parentWorkflow.workflows!.filter((workflow) => workflow !== workflowStep.workflow)
                                .map((workflow) => this.findStatusEntityForWorkflow(workflow, workflowConnection.statuses))
                                .every((workflowStatus) => workflowStatus?.status === WorkflowStatusEnum.Completed);
                            if (journeyCompleted) {
                                const journeyStatusEntity = this.findStatusEntityForWorkflow(workflowStep.workflow.parentWorkflow, workflowConnection.statuses);
                                if (journeyStatusEntity) {
                                    this.setWorkflowStatusCompleted(journeyStatusEntity);
                                    changedEntities.push(journeyStatusEntity);
                                }
                            }
                        }
                    }
                }
            }
        }

        return changedEntities;
    }

    private areStatusAndStepInSameWorkflow(status: WorkflowStatus, step: WorkflowStep) {
        return status.workflowId === step.workflow.workflowId;
    }

    private getOrCreateStatusForWorkflowStep(workflowConnection: WorkflowConnection, workflowStep: WorkflowStep, saveAfterCreate = false) {
        return this.getStatusForWorkflowStep(workflowConnection, workflowStep).pipe(
            switchMap((status) => status
                ? of(status)
                : this.createStatusEntity(workflowConnection, undefined, workflowStep).pipe(
                    switchMap((newStatus) => {
                        if (saveAfterCreate) {
                            return this.commonDataService.saveEntities([newStatus]).pipe(
                                map(() => newStatus),
                            );
                        }
                        return of(newStatus);
                    }),
                )),
        );
    }

    private createStatusEntity(workflowConnection: WorkflowConnection, workflow?: Workflow, workflowStep?: WorkflowStep) {
        return this.commonDataService.create(WorkflowStatusBreezeModel, {
            workflowConnectionId: workflowConnection.workflowConnectionId,
            status: WorkflowStatusEnum.Incomplete,
            workflowId: workflow?.workflowId,
            workflowStepId: workflowStep?.workflowStepId,
        });
    }

    public getOrSetCurrentStepForWorkflow(workflowConnection: WorkflowConnection, workflow: Workflow) {
        return this.getUpdateStatusForWorkflowHierarchy(workflowConnection, workflow).pipe(
            switchMap((statusEntities) => this.findOrUpdateFirstCurrentStepFromWorkflow(workflow, statusEntities, true)),
            switchMap((workflowStep) => this.getStatusForWorkflow(workflowConnection).pipe(
                switchMap((topLevelStatus) => {
                    // status entity for journey not updated when executed from journey page
                    if (workflowStep && topLevelStatus && topLevelStatus.status !== WorkflowStatusEnum.Current) {
                        this.setWorkflowStatusCurrent(topLevelStatus);
                        return this.saveEntities(topLevelStatus).pipe(
                            map(() => workflowStep),
                        );
                    } else {
                        return of(workflowStep);
                    }
                }),
            )),
        );
    }

    public getOrSetCurrentStepForJourney(journeyConnection: WorkflowConnection, updateStatus = true) {
        if (this.getWorkflow(journeyConnection)?.type !== WorkflowType.Journey) {
            throw new Error("This should only be called for connection to workflow of type Journey");
        }
        return this.getUpdateStatusForJourneyHierarchy(journeyConnection, updateStatus).pipe(
            switchMap((statusEntities) => this.findOrUpdateFirstCurrentWorkflowStepFromJourney(
                this.getWorkflow(journeyConnection)!, statusEntities, updateStatus)),
        );
    }

    private getStatusForWorkflowConnectionEncompassingKey(_workflowConnection: WorkflowConnection) {
        // we have statuses as a always fetching nav prop on WorkflowConnection, so just use the ID as encompassing key.
        return WorkflowConnectionBreezeModel.identifier;

        // return `getStatusForWorkflowConnection${workflowConnection.workflowConnectionId}`;
    }

    private getUpdateStatusForWorkflowHierarchy(workflowConnection: WorkflowConnection, workflow: Workflow, shouldUpdate = true) {
        return this.primeStatusForWorkflowConnection(workflowConnection).pipe(
            switchMap(() => shouldUpdate
                ? this.getOrCreateStatusForWorkflow(workflowConnection, workflow)
                : this.getStatusForWorkflow(workflowConnection, workflow)),
            switchMap((workflowStatus) => {
                if (workflowStatus) {
                    if (workflow.steps?.length) {
                        return forkJoin(workflow.steps.map((step) => shouldUpdate
                            ? this.getOrCreateStatusForWorkflowStep(workflowConnection, step)
                            : this.getStatusForWorkflowStep(workflowConnection, step))).pipe(
                                map((stepStatuses) => [workflowStatus, ...stepStatuses.filter((s) => !!s)] as WorkflowStatus[]),
                            );
                    } else {
                        return of([workflowStatus]);
                    }
                }

                return of([]);
            }),
        );
    }

    public getUpdateStatusForJourneyHierarchy(journeyConnection: WorkflowConnection, shouldUpdate = true) {
        return this.primeStatusForWorkflowConnection(journeyConnection).pipe(
            switchMap(() => shouldUpdate
                ? this.getOrCreateStatusForWorkflow(journeyConnection)
                : this.getStatusForWorkflow(journeyConnection)),
            // journey only contains workflow and workflow contains steps
            switchMap((journeyStatus) => {
                if (journeyStatus) {
                    const childrenWorkflows = this.getWorkflow(journeyConnection)?.workflows;
                    return childrenWorkflows?.length
                        ? forkJoin(childrenWorkflows.map((workflow) =>
                            this.getUpdateStatusForWorkflowHierarchy(journeyConnection, workflow, shouldUpdate))).pipe(
                                map((workflowStatusEntities) => [journeyStatus, ...ArrayUtilities.mergeArrays(workflowStatusEntities!)]),
                            )
                        : of([journeyStatus]);
                } else {
                    return of([]);
                }
            }),
        );
    }

    public async filterWorkflows(categoryWorkflows: Workflow[], hiddenStatuses: CategoryWorkflowStatus[]) {
        return await ArrayUtilities.asyncFilter(categoryWorkflows, async (workflow) => {
            if (hiddenStatuses.includes(CategoryWorkflowStatus.ComingSoon) && workflow.extensions.isComingSoon) {
                return false;
            }
            const status = await lastValueFrom(this.getLatestStatusForWorkflow(workflow));
            if (workflow.hideIfCompleted && status?.status === WorkflowStatusEnum.Completed) {
                return false;
            }
            if (hiddenStatuses.includes(CategoryWorkflowStatus.Completed)) {
                if (status?.status === WorkflowStatusEnum.Completed) {
                    return false;
                }
            }
            if (hiddenStatuses.includes(CategoryWorkflowStatus.Current)) {
                if (status?.status === WorkflowStatusEnum.Current) {
                    return false;
                }
            }
            if (hiddenStatuses.includes(CategoryWorkflowStatus.Blocked)) {
                const prerequisitesSatisfied = await lastValueFrom(this.areWorkflowPrerequisitesSatisfied(workflow));
                if (!prerequisitesSatisfied) {
                    return false;
                }
            }
            return true;
        });
    }

    public async completeWorkflow(workflow: Workflow, saveChanges = true) {
        const entitiesToSave = new Set<IBreezeEntity>();

        let connection = await lastValueFrom(this.getLatestWorkflowConnectionForWorkflow(workflow));
        if (!connection) {
            const team = await lastValueFrom(this.getLeadershipTeam());
            const { workflowConnection } = await lastValueFrom(this.createWorkflowConnectionForWorkflow(workflow, undefined, team ? [team] : undefined, false));
            connection = workflowConnection;
            entitiesToSave.add(connection);
        }

        const statuses = await lastValueFrom(this.getUpdateStatusForJourneyHierarchy(connection, true));
        for (const status of statuses) {
            this.setWorkflowStatusCompleted(status);
            entitiesToSave.add(status);
        }

        if (saveChanges) {
            await lastValueFrom(this.saveEntities(Array.from(entitiesToSave)));
        }
    }

    private primeStatusForWorkflowConnection(workflowConnection: WorkflowConnection) {
        const key = `getStatusForWorkflowConnection${workflowConnection.workflowConnectionId}`;
        return this.commonDataService.getWithOptions(WorkflowStatusBreezeModel, key, {
            predicate: new MethodologyPredicate("workflowConnectionId", "==", workflowConnection.workflowConnectionId),
            encompassingKey: WorkflowConnectionBreezeModel.identifier,
        });
    }

    private async findOrUpdateFirstCurrentWorkflowStepFromJourney(journey: Workflow, statusEntities: WorkflowStatus[], updateStatus = false) {
        if (statusEntities.length < 1 && !updateStatus) {
            return undefined;
        }

        let currentWorkflowStep: WorkflowStep | undefined;
        // find first workflow steps with current, set workflow status to current if it is not already current
        let currentWorkflow = this.findFirstWorkflowWithStatus(journey.workflows!, WorkflowStatusEnum.Current, statusEntities);
        if (currentWorkflow) {
            currentWorkflowStep = await this.findOrUpdateFirstCurrentStepFromWorkflow(currentWorkflow, statusEntities, false, !updateStatus);
        }

        if (!currentWorkflowStep) {
            // get 1st incomplete workflow and start the first step
            currentWorkflow = this.findFirstWorkflowWithStatus(journey.workflows!, WorkflowStatusEnum.Incomplete, statusEntities);
            if (currentWorkflow) {
                currentWorkflowStep = await this.findOrUpdateFirstCurrentStepFromWorkflow(currentWorkflow, statusEntities, false, !updateStatus);

                if (updateStatus) {
                    const flowStatusEntity = this.findStatusEntityForWorkflow(currentWorkflow, statusEntities);
                    if (flowStatusEntity) {
                        this.setWorkflowStatusCurrent(flowStatusEntity);

                        const journeyStatusEntity = statusEntities.find((statusEntity) =>
                            statusEntity.workflowConnection === flowStatusEntity.workflowConnection &&
                            !statusEntity.workflowId &&
                            !statusEntity.workflowStepId);
                        if (journeyStatusEntity) {
                            this.setWorkflowStatusCurrent(journeyStatusEntity);
                        }
                    }
                }
            }
        }

        if (currentWorkflowStep && updateStatus) {
            await lastValueFrom(this.saveChangedEntities(statusEntities));
        }

        return currentWorkflowStep;
    }

    private async findOrUpdateFirstCurrentStepFromWorkflow(workflow: Workflow, statusEntities: WorkflowStatus[], saveAfterChange?: boolean, disableUpdate?: boolean) {
        // find 1st current
        let currentWorkflowStep = this.findFirstStepWithStatus(workflow.steps!, WorkflowStatusEnum.Current, statusEntities);
        if (!currentWorkflowStep) {
            // get 1st incomplete step and change it to current
            currentWorkflowStep = this.findFirstStepWithStatus(workflow.steps!, WorkflowStatusEnum.Incomplete, statusEntities);
            if (currentWorkflowStep) {
                const statusEntity = this.findStatusEntityForStep(currentWorkflowStep, statusEntities);
                if (statusEntity && !disableUpdate) {
                    this.setWorkflowStatusCurrent(statusEntity);
                }
            }
        }

        if (currentWorkflowStep) {
            const workflowStatusEntity = this.findStatusEntityForWorkflow(workflow, statusEntities);
            if (workflowStatusEntity && !disableUpdate) {
                this.setWorkflowStatusCurrent(workflowStatusEntity);
            }

            if (saveAfterChange) {
                await lastValueFrom(this.saveChangedEntities(statusEntities));
            }
        }

        return currentWorkflowStep;
    }

    private findFirstWorkflowWithStatus(workflows: Workflow[], status: WorkflowStatusEnum, statusEntities: WorkflowStatus[]) {
        return workflows?.find((workflow) => this.findStatusEntityForWorkflow(workflow, statusEntities)?.status === status);
    }

    private findStatusEntityForWorkflow(workflow: Workflow, statusEntities: WorkflowStatus[]) {
        return statusEntities
            // only consider workflow statuses (not steps)
            .filter((statusEntity) => !statusEntity.workflowStepId)
            .find((statusEntity) => {
                // workflowId will be null if this is a root level status (i.e. journey)
                return statusEntity.workflowId !== null
                    ? statusEntity.workflowId === workflow.workflowId
                    : statusEntity.workflowConnection.workflowId === workflow.workflowId;
            });
    }

    private findFirstStepWithStatus(steps: WorkflowStep[], status: WorkflowStatusEnum, statusEntities: WorkflowStatus[]) {
        return steps?.find((s) => this.findStatusEntityForStep(s, statusEntities)?.status === status);
    }

    public findStatusEntityForStep(step: WorkflowStep, statusEntities: WorkflowStatus[]) {
        return statusEntities.find((statusEntity) => statusEntity.workflowStepId === step.workflowStepId);
    }

    public setWorkflowStatusCurrent(statusEntity: WorkflowStatus) {
        if (statusEntity.status !== WorkflowStatusEnum.Current) {
            statusEntity.status = WorkflowStatusEnum.Current;
            statusEntity.startedById = this.userService.getCurrentPersonId();
            statusEntity.startTime = new Date();
        }
    }

    public setWorkflowStatusCompleted(statusEntity: WorkflowStatus) {
        if (statusEntity.status !== WorkflowStatusEnum.Completed) {
            statusEntity.status = WorkflowStatusEnum.Completed;
            statusEntity.completedById = this.userService.getCurrentPersonId();
            statusEntity.completionTime = new Date();
        }
    }

    private getLeadershipTeam() {
        if (!this.leadershipTeam$) {
            this.leadershipTeam$ = this.update$.pipe(
                switchMap(() => this.teamsService.promiseToGetLeadershipTeam()),
                shareReplay(1),
            );
        }

        return this.leadershipTeam$.pipe(take(1));
    }

    private getActiveTeamsForCurrentPerson() {
        if (!this.activeTeamsForCurrentPerson$) {
            this.activeTeamsForCurrentPerson$ = this.update$.pipe(
                switchMap(() => this.teamsService.promiseToGetActiveTeamsForCurrentPerson()),
                shareReplay(1),
            );
        }

        return this.activeTeamsForCurrentPerson$.pipe(take(1));
    }

    private getActiveTeamIdsForCurrentPerson() {
        return this.getActiveTeamsForCurrentPerson().pipe(
            map((teams) => teams.map((team) => team.teamId)),
        );
    }
}

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";

@Component({
    selector: "adapt-meeting-dashboard-card",
    templateUrl: "./meeting-dashboard-card.component.html",
    styleUrls: ["./meeting-dashboard-card.component.scss"],
})
export class MeetingDashboardCardComponent {
    @Input() public meeting?: Meeting;

    @Input() public showCard = true;
    @Input() public showStatus = true;
    @Input() public showTeamName = false;
    @Input() public notSelectable = true;
    @Input() public selected = false;
    @Input() public preWorkVisible = true;
    @Input() public preWorkClickable = true;
    @Input() public highlightPreWork = true;
    @Output() public meetingStarted = new EventEmitter<Meeting>();
    @Output() public elementSelected = new EventEmitter<HTMLElement>();


    public get hasPreWork() {
        return this.meeting?.extensions.hasPreWork && this.highlightPreWork;
    }
}

<dx-chart [dataSource]="dataSource"
          (onPointClick)="onPointClick($event)"
          (onDone)="contentReady.emit(true)">
    <dxo-size [width]="width"></dxo-size>
    <dxi-series valueField="targetValue"
                name="Milestone"
                color="var(--adapt-milestone-color)"></dxi-series>
    <dxi-series valueField="recordedValue"
                name="Measurement"
                color="var(--adapt-measurement-color)">
        <dxo-point symbol="triangle"></dxo-point>
    </dxi-series>
    <dxo-common-series-settings type="line"
                                argumentField="timestamp"></dxo-common-series-settings>
    <dxo-argument-axis discreteAxisDivisionMode="crossLabels"
                       [valueMarginsEnabled]="true">
        <dxo-label [visible]="true"
                   format="MM/yyyy"></dxo-label>
        <dxo-grid [visible]="true"></dxo-grid>
    </dxo-argument-axis>
    <dxi-value-axis *ngIf="goal?.unit">
        <dxo-title [text]="goal.unit"></dxo-title>
    </dxi-value-axis>
    <dxo-tooltip [enabled]="true"
                 [customizeTooltip]="customiseTooltip">
    </dxo-tooltip>
    <dxo-legend [visible]="hasRecordedValue"
                verticalAlignment="top"
                horizontalAlignment="center"
                itemTextPosition="right"></dxo-legend>
</dx-chart>

import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { KeyFunction } from "./key-function";

export class KeyFunctionSupplementaryData extends BaseEntity<KeyFunctionSupplementaryData> {
    public keyFunctionId!: number;
    public purpose?: string;
    public systems?: string;
    public accountabilities?: string;

    public keyFunction!: KeyFunction | null;
}

export const KeyFunctionSupplementaryDataBreezeModel = new BreezeModelBuilder("KeyFunctionSupplementaryData", KeyFunctionSupplementaryData)
    .hasSource()
    .withPluralName("KeyFunctionSupplementaryData")
    .isOrganisationEntity()
    .build();

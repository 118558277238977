<adapt-dashboard-element name="Actions"
                         [url]="url$ | async">
    <div adapt-dashboard-title-buttons
         class="d-flex flex-row align-items-center gap-1">
        <button class="btn btn-primary btn-pill"
                [adaptButtonIcon]="blurPrivateActions ? 'fal fa-eye' : 'fal fa-eye-slash'"
                adaptTooltip="{{blurPrivateActions ? 'Show' : 'Blur'}} private actions"
                (click)="toggleBlurPrivateActions()"
                [attr.data-test]="'blur-private-actions-button-' + (blurPrivateActions ? 'show' : 'blur')"></button>
        <adapt-menu [items]="itemMenu"></adapt-menu>
    </div>

    <div class="row item">
        <ng-container *adaptLoadingUntilFirstEmit="let overdueItems of overdueItems$">
            <div *ngIf="overdueItems.length > 0"
                 class="col-12 col-lg-6">
                <h4><span class="overdue-icon"></span> Overdue ({{overdueItems.length}}) </h4>
                <div *ngFor="let item of overdueItems"
                     class="kanban-card">
                    <adapt-kanban-card [item]="item"
                                       [showAssignee]="false"
                                       [draggable]="false"
                                       [highlight]="true"
                                       [blurSensitiveContent]="blurPrivateActions"></adapt-kanban-card>
                </div>
            </div>
        </ng-container>

        <ng-container *adaptLoadingUntilFirstEmit="let inProgressItems of inProgressItems$">
            <div class="col-12 col-lg-6">
                <h4><span class="item-status-badge-inprogress"></span> In progress ({{inProgressItems.length}})</h4>
                <ng-container *ngIf="inProgressItems.length > 0;else noItemsInProgressTemplate">
                    <div *ngFor="let item of inProgressItems"
                         class="kanban-card">
                        <adapt-kanban-card [item]="item"
                                           [draggable]="false"
                                           [showAssignee]="false"
                                           [highlight]="item.extensions.isOverdue"
                                           [blurSensitiveContent]="blurPrivateActions"></adapt-kanban-card>
                    </div>
                </ng-container>
                <ng-template #noItemsInProgressTemplate>
                    <div class="col-empty">
                        You have no items in progress
                    </div>
                </ng-template>
            </div>
        </ng-container>
    </div>
</adapt-dashboard-element>

export enum ToolbarContentLocation {
    Left,
    Center,
    Right,
}

export interface IToolbarActionOptions {
    contentLocation?: ToolbarContentLocation;
    contentOrdinal?: number;
}

export interface IToolbar {
    addContent(domElement: HTMLElement, options?: IToolbarActionOptions): void;
    removeContent(domElement: HTMLElement): void;
    resetToolbar(): void;
    setTitle(title: string | HTMLElement): void;
    clearTitle(): void;
    showToolbar(): void;
    hideToolbar(): void;
    addToolbarClass(classString: string): void;
    clearToolbarClass(): void;
}

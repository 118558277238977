import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import { EventTypePreset } from "@common/ADAPT.Common.Model/organisation/event-type";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";
import { ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { buttonPreset } from "@common/ux/button/button-preset";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject } from "rxjs";
import { debounceTime, filter, map, startWith, switchMap, take, tap, withLatestFrom } from "rxjs/operators";
import { AuthorisationService } from "../../authorisation/authorisation.service";
import { CommonTeamsAuthService } from "../../teams/common-teams-auth.service";
import { ObjectiveFilter } from "../objective-filter/objective-filter";
import { ObjectiveFilterParamKeys } from "../objective-filter/objective-filter.component";
import { ObjectiveFilterService } from "../objective-filter/objective-filter.service";
import { ObjectiveViewType } from "../objective-view-type.enum";
import { IObjectiveGroup, ObjectivesService } from "../objectives.service";
import { ObjectivesAuthService } from "../objectives-auth.service";
import { ObjectivesUiService } from "../objectives-ui.service";

@Component({
    selector: "adapt-display-team-objectives",
    templateUrl: "./objectives-page.component.html",
})
export class ObjectivesPageComponent extends BaseRoutedComponent implements OnInit, OnDestroy {
    public readonly isMobile$ = this.responsiveService.isMobileSize$;
    // can't use enum in template without this
    public readonly ViewType = ObjectiveViewType;
    public readonly ObjectiveFilterParamKeys = Object.values(ObjectiveFilterParamKeys);
    private readonly ViewQueryParam = "view";

    public pageMenu: IAdaptMenuItem[] = [{
        icon: MenuComponent.SmallRootMenu.icon,
        items: [{
            text: "Reorder objectives",
            icon: buttonPreset.reorder.iconClass,
            onClick: this.reorderObjectives,
        }],
    }];

    public objectiveFilter$ = new ReplaySubject<ObjectiveFilter>(1);
    public teamId$ = new ReplaySubject<number | undefined>(1);
    public teamObjectivesGroups$: Observable<IObjectiveGroup[]>;
    public currentView = ObjectiveViewType.ObjectiveTreeView;
    public teamId?: number;
    public hasEditPermissions$: Observable<boolean>;
    public team$: Observable<Team | undefined>;
    public containsSortableObjectives = false;

    private article = this.isAlto ? ImplementationKitArticle.ObjectivesOverview : ImplementationKitArticle.IntroducingObjectivesAndKeyResults;
    public learnMoreUrl = ImplementationKitService.GetArticleLink(this.article);

    private triggerUpdate$ = new BehaviorSubject<void>(undefined);

    public isDefaultFilter = true;
    private isDefaultFilterUpdater = this.createThrottledUpdater<boolean>((isDefault) => this.isDefaultFilter = isDefault);
    private lastEmittedObjectiveGroups: IObjectiveGroup[] = [];
    public lastQueriedObjectives: Objective[] = [];

    public constructor(
        injector: Injector,
        private objectivesService: ObjectivesService,
        private objectivesUiService: ObjectivesUiService,
        private objectivesAuthService: ObjectivesAuthService,
        private filterService: ObjectiveFilterService,
        private authorisationService: AuthorisationService,
        private responsiveService: ResponsiveService,
        teamsService: CommonTeamsService,
        labellingService: LabellingService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super(injector);

        this.teamObjectivesGroups$ = combineLatest([this.teamId$, this.objectiveFilter$, this.triggerUpdate$]).pipe(
            debounceTime(200),
            tap(() => this.isInitialised = false),
            switchMap(([teamId, objFilter]) => {
                if (objFilter.objectiveStatuses.size > 0 && !(objFilter.onlyClosedAndSupporting)) {
                    return this.objectivesService.getPrimedObjectives(objFilter, teamId);
                } else {
                    return of([]);
                }
            }),
            // prime label locations
            switchMap((objectives) => labellingService.primeLabelLocationsForObjectives(this.objectivesService.getAllObjectives(objectives)).pipe(
                map(() => objectives),
            )),
            withLatestFrom(this.teamId$, this.objectiveFilter$),
            map(([data, teamId, objFilter]) => {
                this.lastQueriedObjectives = data;
                if (!objFilter.showSupportedOrgObjectives && teamId) {
                    // parents won't be included when not showing related on a team page.
                    // we don't need to group differently for org. objectives
                    // since child team objectives can be filtered out later
                    return this.objectivesService.groupObjectives(data);
                }
                return this.objectivesService.groupObjectivesIncludingExternalParents(data);
            }),
            tap((objectiveGroups) => {
                this.lastEmittedObjectiveGroups = objectiveGroups;
                this.containsSortableObjectives = this.objectivesService.objectiveGroupsAreSortableInTeam(objectiveGroups, this.teamId);
                this.isInitialised = true;
            }),
        );

        this.hasEditPermissions$ = this.teamId$.pipe(
            switchMap((teamId) => this.objectivesAuthService.hasWriteAccessToObjective(teamId)),
        );

        this.team$ = this.teamId$.pipe(
            switchMap((teamId) => teamId ? teamsService.getTeamById(teamId) : of(undefined)),
        );

        rxjsBreezeService.entityTypeChanged(Objective).pipe(
            // only trigger update if we are showing supporting team/org objectives
            // or showing external team objectives
            withLatestFrom(this.objectiveFilter$),
            filter(([obj, objFilter]) => obj.teamId == this.teamId || // teamId from obj can be null but this.teamId is undefined for org - so purposely not using ===
                !this.teamId && objFilter.showSupportingTeamObjectives ||
                !!this.teamId && !obj.teamId && objFilter.showSupportedOrgObjectives), // not interested in another team's objectives - only organisation objective if showing
            this.takeUntilDestroyed(),
        ).subscribe(() => this.triggerUpdate$.next());
        // have to settle with refresh even if there is only a change of property as the view may change from filter, which is used in query
    }

    public ngOnInit() {
        this.updateData();

        this.objectivesService.objectiveUpdated$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.onObjectiveChanged());

        this.updateViewType();

        this.navigationEnd.subscribe(() => this.updateData());

        this.filterService.filter.option$.pipe(
            debounceTime(100), // only need to update isDefault once even if there are multiple options changed
            startWith(undefined),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.isDefaultFilterUpdater.next(this.filterService.isDefault(!!this.teamId)));
    }

    @Autobind
    public reorderObjectives() {
        this.objectivesUiService.reorderObjectives(this.lastEmittedObjectiveGroups, this.teamId)
            .subscribe();
    }

    private updateData() {
        this.teamId = this.getRouteParamInt("teamId");
        this.teamId$.next(this.teamId);

        this.verifyHasAccessToRoute(this.objectivesAuthService.hasReadAccessToObjective(this.teamId));

        this.teamObjectivesGroups$.pipe(
            take(1),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.notifyActivated());
    }

    private updateViewType() {
        const viewParam = this.getSearchParameterIntValue(this.ViewQueryParam);
        if (viewParam) {
            this.currentView = viewParam;
        } else {
            this.currentView = ObjectiveViewType.ObjectiveTreeView;
        }
    }

    public onViewChanged(view: ObjectiveViewType) {
        this.setSearchParameterValue(this.ViewQueryParam, view);
    }

    public ngOnDestroy() {
        super.ngOnDestroy();

        this.teamId$.complete();
        this.objectiveFilter$.complete();
    }

    public filterChanged(objFilter: ObjectiveFilter) {
        this.objectiveFilter$.next(objFilter);
    }

    @Autobind
    public async addObjective() {
        const canViewMeeting = await this.authorisationService.promiseToGetHasAccess(CommonTeamsAuthService.ViewAnyTeamMeeting);
        if (AdaptClientConfiguration.AdaptProjectName === AdaptProject.Alto && canViewMeeting) {
            this.objectivesUiService.createObjectiveForEventTypePreset(EventTypePreset.AnnualStrategy).pipe(
                switchMap(() => of(undefined))).subscribe();
        } else {
            // forwarding to edit objective page is handled by the creation wizard/dialog so the redirection won't be broken when switching to/from wizard
            // or restore
            this.objectivesUiService.createObjective(this.teamId).subscribe();
        }
    }

    public onObjectiveChanged() {
        this.teamId$.next(this.teamId);
    }
}

@if (provider && hasAssociatedProviderMeeting && !authenticated) {
    <i class="fal fa-fw fa-calendar-days me-1"></i>This meeting has been integrated with {{provider.name}}. You can view the status by
    logging in.
}

@if (provider && authenticated && loading) {
    <span class="text-muted">
        <i class="fal fa-fw fa-spin fa-spinner me-1"></i>Checking calendar integration status...
    </span>
}

@if (provider && authenticated && !loading) {
    @if (meetingLink) {
        <div>
            <i class="fal fa-fw fa-calendar-days me-1"></i>
            <a [href]="meetingLink"
               target="_blank">View event in {{provider.name}}</a>
        </div>
    }

    @if (teamsLink) {
        <div>
            <i class="fal fa-fw fa-camera-web me-1"></i>
            <a [href]="teamsLink"
               target="_blank">Join Teams meeting</a>
        </div>
    }

    @if (meeting.extensions.isNotStarted && (outOfSync || providerMeetingMissing || userCalendarMeetingMissing)) {
        <div>
            <i class="fal fa-fw fa-triangle-exclamation me-1"></i>
            @if (userCalendarMeetingMissing) {
                <span>We could not find the event in your calendar. This can happen if you deleted the event.</span>
            } @else {
                <span>This meeting
                    {{outOfSync ? (remoteHasChanges ? 'is missing changes made in' : (hasDifferences ? 'has' : 'may have') + ' changes not yet synced to') : 'has not been synced to'}}
                    {{provider.name}}.</span>
            }

            @if ((organiser.isOrganiser || remoteHasChanges || outOfSync || providerMeetingMissing) && canEditTeamMeeting) {
                <div>
                    <i class="fal fa-fw me-1"></i>
                    <!-- flip the options depending on the changes source
                         e.g. if changes are remote, then show the update local meeting option first -->
                    <div class="d-inline-flex gap-1"
                         [class.flex-row-reverse]="remoteHasChanges"
                         adaptBlockingButtonGroup>
                        @if (canCreateOrUpdateRemoteMeeting) {
                            <button class="btn btn-link"
                                    [adaptBlockingClick]="createOrUpdateRemoteMeeting">
                                {{outOfSync ? 'Update' : 'Create'}} the {{provider.name}} event
                            </button>

                            @if (providerMeetingMissing) {
                                <span>or</span>
                                <button class="btn btn-link"
                                        [adaptBlockingClick]="attachExistingRemoteMeeting">
                                    Attach existing {{provider.name}} event
                                </button>
                            }

                        } @else {
                            <div>
                                <span class="text-decoration-line-through text-muted">{{outOfSync ? 'Update' : 'Create'}} the
                                    {{provider.name}} event</span>
                                <i class="fal fa-info-circle ms-1"
                                   adaptTooltip="Only <b>{{organiser.name}}</b> can update the {{provider.name}} entry. Please ask them to sync the event."
                                   [adaptTooltipIsHtml]="true"></i>
                            </div>
                        }

                        @if (hasDifferences) {
                            <span>or</span>
                            <button class="btn btn-link"
                                    [adaptBlockingClick]="syncFromRemoteMeeting"
                                    adaptTooltip="Any changes made to the name, time or location of your {{AdaptProjectLabel}} meeting will be overwritten.">
                                Update the {{AdaptProjectLabel}} meeting
                            </button>
                        }
                    </div>
                </div>
            } @else {
                Only <b>{{organiser.name}}</b> can update the calendar entry. Please ask them to sync the event.
            }
        </div>
    }
}

import { Injectable } from "@angular/core";
import { QueuedCaller } from "@common/lib/queued-caller/queued-caller";
import { defer, of } from "rxjs";
import { mergeMap } from "rxjs/operators";
import { ContextSidebarSource, ContextSidebarState, IContextSidebar } from "./context-sidebar.interface";

@Injectable({
    providedIn: "root",
})
export class ContextSidebarService implements IContextSidebar {
    private queuedContextSidebar = new QueuedCaller<IContextSidebar>();

    public registerContextSidebar(contextSidebar: IContextSidebar) {
        this.queuedContextSidebar.setCallee(contextSidebar);
    }

    public clearContextSidebar() {
        this.queuedContextSidebar = new QueuedCaller<IContextSidebar>();
    }

    public get contextSidebarState$() {
        return defer(() => this.queuedContextSidebar.promiseToCall((sidebar) => sidebar.contextSidebarState$)).pipe(
            mergeMap((value) => value),
        );
    }

    public get hasDialogContent() {
        if (this.queuedContextSidebar.isSet) {
            return this.queuedContextSidebar.definedCallee.hasDialogContent;
        }

        return false;
    }

    public get hasDialogContentChange$() {
        if (this.queuedContextSidebar.isSet) {
            return this.queuedContextSidebar.definedCallee.hasDialogContentChange$;
        }

        return of(false);
    }

    public clearContent(source?: ContextSidebarSource) {
        this.queuedContextSidebar.call((sidebar) => sidebar.clearContent(source));
    }

    public clearTitle(source?: ContextSidebarSource) {
        this.queuedContextSidebar.call((sidebar) => sidebar.clearTitle(source));
    }

    public setContent(content: HTMLElement, source?: ContextSidebarSource) {
        this.queuedContextSidebar.call((sidebar) => sidebar.setContent(content, source));
    }

    public setTitle(title: HTMLElement, source?: ContextSidebarSource) {
        this.queuedContextSidebar.call((sidebar) => sidebar.setTitle(title, source));
    }

    public setMaxWidth(width?: number) {
        this.queuedContextSidebar.call((sidebar) => sidebar.setMaxWidth(width));
    }

    public setIsOpen(isOpen: boolean, state?: Exclude<ContextSidebarState, ContextSidebarState.Closed>) {
        this.queuedContextSidebar.call((sidebar) => sidebar.setIsOpen(isOpen, state));
    }
}

import { MeetingAgendaItem } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";

export const MeetingAgendaItemComponentRegistry: Map<string, any> = new Map();
export const MeetingAgendaItemComponent = (key: string): any => (cls: any) => {
    MeetingAgendaItemComponentRegistry.set(key, cls);
};

export interface IMeetingAgendaItemComponent {
    meetingAgendaItem?: MeetingAgendaItem;

    onDataChanged?: () => any;
}

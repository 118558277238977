import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { BehaviorSubject, combineLatest, of, Subject } from "rxjs";
import { distinctUntilChanged, startWith, switchMap, takeUntil } from "rxjs/operators";
import { AuthorisationNotificationService } from "../authorisation/authorisation-notification.service";
import { FeaturesService } from "./features.service";

@Directive({
    selector: "[adaptIfFeatureActive]",
})
export class AdaptIfFeatureActiveDirective implements OnInit, OnDestroy {
    @Input("adaptIfFeatureActive") public set feature(value: FeatureName | undefined) {
        this.feature$.next(value);
    }

    @Input("adaptIfFeatureActiveTeam") public set team(value: Team | undefined) {
        this.team$.next(value);
    }

    @Input("adaptIfFeatureActiveElse") public elseTemplate?: TemplateRef<unknown>;

    private feature$ = new BehaviorSubject<FeatureName | undefined>(undefined);
    private team$ = new BehaviorSubject<Team | undefined>(undefined);
    private destroyed$ = new Subject<void>();

    public constructor(
        private templateRef: TemplateRef<unknown>,
        private viewContainer: ViewContainerRef,
        private featuresService: FeaturesService,
        private authorisationNotificationService: AuthorisationNotificationService,
    ) {
    }

    public ngOnInit() {
        // Authorisation isn't quite the right event here, but due to the heavy caching in this
        // area we can only be sure that all of it will be updated after this event fires.
        const checkFeatureStatus$ = this.authorisationNotificationService.authorisationChanged$.pipe(
            startWith(undefined),
        );
        combineLatest([this.feature$, this.team$, checkFeatureStatus$]).pipe(
            switchMap(([feature, team]) => {
                if (!feature) {
                    return of(true); // if no feature is required - pass -> always show
                }

                return this.featuresService.promiseToCheckIfFeatureActive(feature, team);
            }),
            distinctUntilChanged(),
            takeUntil(this.destroyed$),
        ).subscribe((isFeatureActive) => {
            this.viewContainer.clear();

            if (isFeatureActive) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else if (this.elseTemplate) {
                this.viewContainer.createEmbeddedView(this.elseTemplate);
            }
        });
    }

    public ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}

<ng-container *ngIf="layoutManager">
    <ng-container *ngIf="layoutManager.isEditing$ | async">
        <div role="alert"
             class="alert alert-danger"
             *ngIf="saveError">
            {{saveError}}
        </div>
        <div class="header mb-1">
            <div>
                <button adaptButtonIcon="refresh"
                        [adaptBlockingClick]="layoutManager.resetToDefaultLayout"
                        [disabled]="layoutManager.isLayoutDefault"
                        data-test="layout-reset-default-button">Reset to default layout
                </button>
            </div>
            <div class="d-flex align-items-center">
                <adapt-menu *ngIf="menuItems"
                            class="mt-n1 mb-n1 me-1 d-inline-block"
                            data-test="layout-options-button"
                            [items]="menuItems"
                            [showOnHover]="true"></adapt-menu>

                <button adaptButtonIcon="save"
                        [adaptBlockingClick]="saveLayout"
                        [disabled]="layoutManager.isLayoutUnchanged"
                        data-test="layout-save-button">Save changes
                </button>
                <button adaptButtonIcon="cancel"
                        [adaptBlockingClick]="layoutManager.toggleEditing"
                        data-test="layout-cancel-button">Cancel changes
                </button>
            </div>
        </div>
    </ng-container>
</ng-container>

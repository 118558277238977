<adapt-confirmation-dialog (closed)="onDialogClosed($event)"
                           (initialised)="onInitialised($event)">
    <span>The following objective will be deleted:</span>
    <adapt-link-objective class="ms-3 mt-2"
                          [objective]="objective"
                          [generateHref]="false"></adapt-link-objective>

    <div *ngIf="hasRelatedDeletion"
         class="mt-3">
        <p class="mb-1">This includes the following:</p>
        <ul>
            <li *ngIf="objective.hasKeyResults">
                {{objective.keyResults.length}} key results for this objective and their corresponding values
            </li>
            <li *ngIf="objective.objectiveLinks.length > 0">
                {{objective.objectiveLinks.length}} objective links related to this objective
            </li>
            <li *ngIf="objective.itemLinks.length > 0">
                {{objective.itemLinks.length}} item links related to this objective
            </li>
        </ul>
    </div>
    <div *ngIf="objective.childObjectives.length > 0"
         class="mt-3">
        <p class="mb-1">
            The following supporting objectives will no longer support this objective:
        </p>
        <ul>
            <li *ngFor="let child of objective.childObjectives"
                class="d-flex">
                <adapt-link-objective [objective]="child"
                                      [generateHref]="false"></adapt-link-objective>
            </li>
        </ul>
    </div>
    <p class="mt-3">Are you sure you want to delete?</p>
</adapt-confirmation-dialog>

import { Component, Injector, TemplateRef, ViewChild } from "@angular/core";
import { MeetingAgendaItem } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { MeetingAgendaItemSupplementaryData } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item-supplementary-data";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IFocusable } from "@common/ux/adapt-common-dialog/focusable";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { DxTextBoxComponent } from "devextreme-angular/ui/text-box";
import isEqual from "lodash.isequal";
import { forkJoin, lastValueFrom, Observable, of, Subject, switchMap } from "rxjs";
import { tap } from "rxjs/operators";
import { MeetingsService } from "../../meetings.service";
import { MeetingsUiService } from "../../meetings-ui.service";
import { ScheduleMeetingWorkflowStepBaseComponent } from "../schedule-meeting-workflow-step-base.component";

@WorkflowStepComponent("adapt-schedule-meeting-customise-agenda-step")
@Component({
    selector: "adapt-schedule-meeting-customise-agenda-step",
    templateUrl: "./schedule-meeting-customise-agenda-step.component.html",
})
export class ScheduleMeetingCustomiseAgendaStepComponent extends ScheduleMeetingWorkflowStepBaseComponent implements IFocusable {
    @ViewChild("focus") public elementToFocus?: DxTextBoxComponent;

    @ViewChild("startMeetingNowButton")
    public set startMeetingNowButton(ref: TemplateRef<any>) {
        this.workflowStepFooterTemplates.next([
            { alignment: "right", template: ref },
        ]);
    }

    public addingNewAgendaItem = false;
    public allowEndedEditing = false;
    public isEditorValid = true;

    public constructor(
        injector: Injector,
        private commonDataService: CommonDataService,
        protected dialogService: AdaptCommonDialogService,
        protected meetingsService: MeetingsService,
        protected meetingsUiService: MeetingsUiService,
    ) {
        super(injector);
    }

    public get allowStartingMeetingWithoutAgenda() {
        return false;
    }

    public get stepIsValid() {
        return this.runData.meeting.meetingAgendaItems.length > 0 && this.allEntitiesAreValid;
    }

    public get agendaIsReadonly() {
        return this.runData.meeting.extensions.isInProgress
            && this.meetingsService.isCurrentPersonInMeeting(this.runData.meeting);
    }

    public get agendaIsLocked() {
        return this.runData.meeting.extensions.isEnded && !this.allowEndedEditing;
    }

    public async workflowStepOnInit() {
        super.workflowStepOnInit();

        await lastValueFrom(this.meetingsService.initialiseMeeting(this.runData.meeting));
        this.emitMeetingEntitiesChanged();

        this.runData.initialAgendaItems = this.runData.initialAgendaItems
            ?? this.getAgendaItemEntities();
        this.runData.initialDescription = this.runData.initialDescription
            ?? this.runData.meeting.supplementaryData?.purpose;
    }

    public getElementToFocus() {
        return this.elementToFocus;
    }

    public onMeetingNameChange(name: string) {
        this.runData.meeting.name = name;
        this.onEntitiesChanged([this.runData.meeting]);
    }

    @Autobind
    public allowAgendaEdit() {
        return this.meetingsUiService.promptForEndedMeetingAgendaEdit().pipe(
            tap(() => this.allowEndedEditing = true),
            this.takeUntilDestroyed(),
        );
    }

    public async onAgendaItemsChange() {
        this.emitMeetingEntitiesChanged();

        // shouldn't allow save while adding a new agenda item
        if (this.addingNewAgendaItem || !this.isEditorValid) {
            this.isValid.next(false);
        }
    }

    public onDescriptionChange(description: string) {
        const suppData = this.runData.meeting!.supplementaryData!;
        suppData.purpose = description;
        this.onEntitiesChanged([suppData]);
    }

    public workflowStepPrevious(interruptShortcut: Subject<void>) {
        const newAgendaItemEntities = this.getAgendaItemEntities();
        const initialAgendaItems = this.runData.initialAgendaItems ?? [];

        const hasChanges = !isEqual(initialAgendaItems, newAgendaItemEntities)
            || !isEqual(this.runData.initialDescription, this.runData.meeting.supplementaryData?.purpose)
            || !this.runData.meeting.entityAspect.entityState.isAdded();

        // don't need to prompt if no agenda items to begin with
        if ((initialAgendaItems.length > 0 || newAgendaItemEntities.length !== initialAgendaItems.length) && hasChanges) {
            return this.dialogService.openConfirmationDialogWithBoolean({
                title: "Discard changes",
                message: `<p>You have made changes to this meeting agenda. If you go back, your meeting agenda changes will be deleted.
                    Any changes made to your meetings description will also be lost.</p>
                    <p>Do you still want to continue?</p>`,
                checkboxMessage: "Confirm that you understand your meeting agenda and/or description will be deleted",
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                hideCancelButton: false,
            }).pipe(
                switchMap((res) => {
                    if (!res) {
                        interruptShortcut.next();
                    }

                    return this.clearAgendaItems();
                }),
            );
        }

        return this.clearAgendaItems();
    }

    private clearAgendaItems() {
        const entities = this.getAgendaItemEntities();

        const clear$: Observable<any> = entities.length > 0
            ? forkJoin(entities.map((entity) => this.commonDataService.remove(entity)))
            : of(undefined);

        return clear$.pipe(
            tap(() => {
                if (this.runData.meeting.supplementaryData) {
                    this.runData.meeting.supplementaryData.purpose = "";
                }
                this.emitMeetingEntitiesChanged();

                this.runData.hasGoneBack = true;
                this.runData.initialAgendaItems = undefined;
                this.runData.initialDescription = undefined;
            }),
        );
    }

    private getAgendaItemEntities() {
        const allEntities = this.runData.meeting.extensions.getAllMeetingEntities();
        return allEntities.filter((i) => i instanceof MeetingAgendaItem || i instanceof MeetingAgendaItemSupplementaryData)
            // sort so the order is stable
            .sort((a, b) => a.meetingAgendaItemId - b.meetingAgendaItemId);
    }
}

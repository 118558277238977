import { Injectable } from "@angular/core";
import { PersonDetail } from "@common/ADAPT.Common.Model/person/person-detail";
import { PersonFlag, PersonFlagDefaults } from "@common/ADAPT.Common.Model/person/person-flag.enum";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { GuidedTour, GuidedTourService } from "@common/lib/guided-tour/guided-tour.service";
import { UserService } from "@common/user/user.service";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { firstValueFrom, lastValueFrom, switchMap } from "rxjs";
import { filter, map, startWith, tap } from "rxjs/operators";
import { PersonService } from "./person.service";

@Injectable({
    providedIn: "root",
})
export class PersonFlagService {
    public static readonly FlagEnabledValue = "true";
    public static readonly FlagDisabledValue = "false";

    public constructor(
        private personService: PersonService,
        private commonDataService: CommonDataService,
        private guidedTourService: GuidedTourService,
        private userService: UserService,
        private responsiveService: ResponsiveService,
        private rxjsBreezeService: RxjsBreezeService,
    ) { }

    public isFlagEnabled$(personId: number, flag: PersonFlag) {
        return this.rxjsBreezeService.entityTypeChanged(PersonDetail).pipe(
            startWith(undefined),
            filter((personDetail) => !personDetail || personDetail.personId === personId),
            switchMap(() => this.isFlagEnabled(personId, flag)),
        );
    }

    public isFlagEnabled(personId: number, flag: PersonFlag) {
        return this.personService.getPersonDetail(personId, flag).pipe(
            map((detail) => this.isFlagDetailEnabled(flag, detail)),
        );
    }

    public setFlagAndSave(personId: number, flag: PersonFlag, enabled: boolean) {
        return this.setFlag(personId, flag, enabled).pipe(
            switchMap((detail) => this.commonDataService.saveEntities([detail]).pipe(
                map(() => enabled),
            )),
        );
    }

    public setFlag(personId: number, flag: PersonFlag, enabled: boolean) {
        return this.personService.getOrCreatePersonDetail(personId, flag).pipe(
            tap((detail) => this.setFlagDetail(detail, enabled)),
        );
    }

    public async setFlagAndRunTour(flag: PersonFlag, tour?: GuidedTour) {
        const personId = this.userService.getCurrentPersonId();
        if (personId) {
            if (!await lastValueFrom(this.isFlagEnabled(personId, flag))
                && !await firstValueFrom(this.responsiveService.isMobileSize$)) {
                this.setFlagAndSave(personId, flag, true).subscribe();
                this.guidedTourService.run(tour, true);
            }
        }
    }

    private setFlagDetail(detail: PersonDetail, enabled: boolean) {
        detail.value = enabled
            ? PersonFlagService.FlagEnabledValue
            : PersonFlagService.FlagDisabledValue;
    }

    private isFlagDetailEnabled(flag: PersonFlag, detail?: PersonDetail) {
        // no detail set, use the default if exists
        if (!detail) {
            return PersonFlagDefaults[flag]?.() ?? false;
        }

        return detail.value === PersonFlagService.FlagEnabledValue;
    }
}

<div class="card gap-1"
     [ngClass]="cardClasses"
     [ngStyle]="cardStyles">
    <div class="objective-list-item-body">
        <div class="objective-heading"
             [ngStyle]="headingStyles">
            <div class="objective-title">
                <adapt-link-objective [objective]="objective"
                                      [showStatusIcon]="false"></adapt-link-objective>
                <div class="ms-auto d-flex justify-content-between align-items-center flex-wrap">
                    <adapt-link-objectives-page *ngIf="isExternalObjective"
                                                class="px-3"
                                                [team]="objective.team"
                                                [view]="redirectObjectivesPageView"></adapt-link-objectives-page>
                    <adapt-display-objective-status *ngIf="!hasEditPermissions"
                                                    [objectiveStatus]="objective.status"></adapt-display-objective-status>
                    <adapt-select-objective-status *ngIf="hasEditPermissions"
                                                   [objective]="objective"></adapt-select-objective-status>
                </div>
            </div>
            <!-- Need the extra div so the alignment margins will always apply even when the button isn't shown -->
            <div [ngStyle]="keyResultToggleStyles"
                 *ngIf="!hideKeyResults">
                <button *ngIf="isFocussed"
                        [style.visibility]="!isContextualObjective ? 'visible' : 'hidden'"
                        class="btn"
                        (click)="toggleKeyResults()">
                    <i class="key-result-chevron"
                       [ngClass]="keyResultChevronIconClass"></i>
                </button>
            </div>
        </div>
        <ng-container *ngIf="isFocussed && isLG">
            <ng-container *ngTemplateOutlet="progressTemplate"></ng-container>
            <ng-container *ngTemplateOutlet="metadataTemplate"></ng-container>
        </ng-container>

        <adapt-objective-actions *ngIf="hasEditPermissions"
                                 class="ms-3"
                                 [objective]="objective"
                                 [currentPageTeamId]="currentPageTeamId"></adapt-objective-actions>
    </div>
    <adapt-display-labels *ngIf="objective.labelLocations?.length"
                          class="mb-1"
                          [labelLocations]="objective.labelLocations"></adapt-display-labels>
    <div class="objective-list-item-body-sm"
         *ngIf="isFocussed && !isLG">
        <!-- If not hiding progress bar don't want the extra div so the progress bar can be at the end when > md
             (objective-list-item-body-sm has further sm/md breakpoints), progress will go to next row when reach sm -->
        <ng-container *ngIf="!hideProgressBar">
            <ng-container *ngTemplateOutlet="metadataTemplate"></ng-container>
            <ng-container *ngTemplateOutlet="progressTemplate"></ng-container>
        </ng-container>
        <!-- If hiding progress bar, no point showing just the 3 character status in a separate row.
            Add an extra div here so progress and meta are on the same row even if the parent container switches to column -->
        <div *ngIf="hideProgressBar"
             class="d-flex align-items-center justify-content-between w-100">
            <ng-container *ngTemplateOutlet="metadataTemplate"></ng-container>
            <ng-container *ngTemplateOutlet="progressTemplate"></ng-container>
        </div>
    </div>
    <ng-template #metadataTemplate>
        <div class="objective-metadata"
             [ngClass]="{'without-progress': hideProgressBar}">
            <adapt-link-person [personId]="objective.assigneePersonId"
                               [showImage]="true"></adapt-link-person>
            <div class="objective-due-date">
                <strong>Due:</strong> {{objective.dueDate | adaptDate}}
            </div>
        </div>
    </ng-template>
    <ng-template #progressTemplate>
        <div class="objective-progress"
             *ngIf="!hideProgressBar">
            <adapt-display-objective-progress [objective]="objective"></adapt-display-objective-progress>
        </div>
        <div *ngIf="hideProgressBar"
             class="objective-percentage-only">{{progressText}}</div>
    </ng-template>

    <div *ngIf="!hideKeyResults && keyResultsExpanded">
        <div *ngIf="!objective.hasKeyResults">
            There are no Key Results for this Objective.
        </div>
        <adapt-key-result-list-item *ngFor="let keyResult of objective.keyResults"
                                    [keyResult]="keyResult"
                                    [depth]="depth"></adapt-key-result-list-item>
    </div>
</div>

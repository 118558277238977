import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";

@Pipe({
    name: "adaptMoment",
})
export class MomentPipe implements PipeTransform {
    public transform(value: moment.MomentInput, format: string): any {
        return moment(value).format(format);
    }

}

import { StringUtilities } from "../utilities/string-utilities";

export class GuidedTourUtils {
    public static getFirstElementWithTextContent(elementSelector: string, content?: string) {
        let foundElement: Element | undefined;
        const allElements = Array.from(document.querySelectorAll(elementSelector))
            .filter((e) => !GuidedTourUtils.elementHasAncestorOfDisplayNone(e));
        if (!content && allElements.length > 0) {
            return allElements[0];
        }

        if (content) {
            // eslint-disable-next-line @typescript-eslint/prefer-for-of
            for (let i = 0; i < allElements.length; i++) {
                const textContent = allElements[i].textContent;
                if (textContent && StringUtilities.equalsIgnoreCase(textContent, content)) {
                    foundElement = allElements[i];
                    break;
                }
            }
        }

        return foundElement;
    }

    public static elementHasAncestorOfDisplayNone(element: Element | null) {
        while (element) {
            if (StringUtilities.equalsIgnoreCase(window.getComputedStyle(element).display ?? "", "none")) {
                return true;
            }

            element = element.parentElement;
        }

        return false;
    }

    public static isElementVisibleWithDataTourSelector(dataTourSelector: string) {
        const element = GuidedTourUtils.getFirstElementWithTextContent(`[data-tour="${dataTourSelector}"]`);
        return GuidedTourUtils.isElementVisible(element);
    }

    public static isElementVisible(element?: Element) {
        if (!element) {
            return false;
        }

        const boundingRect = element.getBoundingClientRect();
        // this check is needed for when the sidebar is collapsed, left will be negative.
        const withinBounds = boundingRect.left >= 0;
        return (boundingRect.width > 0 && boundingRect.height > 0 && withinBounds);
    }

    public static waitForElementWithTextContentToBeVisible(elementSelector: string, content?: string, delayBeforeResolve = 1, waitDuration = 3000) {
        const tryPeriod = 100;
        const maxCount = waitDuration / tryPeriod;
        let count = 0;

        return new Promise<Element>((resolve, reject) => {
            checkElementVisibility(resolve, reject);
        });

        function checkElementVisibility(resolve: (result: Element) => void, reject: (result: string) => void) {
            setTimeout(() => {
                count++;
                const element = GuidedTourUtils.getFirstElementWithTextContent(elementSelector, content);
                if (GuidedTourUtils.isElementVisible(element)) {
                    setTimeout(() => resolve(element!), delayBeforeResolve);
                } else if (count < maxCount) {
                    checkElementVisibility(resolve, reject);
                } else {
                    reject(`Element "${elementSelector}" cannot be found!`);
                }
            }, tryPeriod);
        }
    }
}

<div *ngIf="meeting"
     [ngClass]="{'dashboard-meetings': true, 'has-pre-work': hasPreWork, 'card': showCard}">
    <div class="card-body d-flex flex-row align-items-center">
        <adapt-display-meeting-summary class="w-100"
                                       [meeting]="meeting"
                                       [compact]="true"
                                       [showIcon]="true"
                                       [showTeamName]="showTeamName"
                                       [linkDisabled]="notSelectable"
                                       [selected]="selected"
                                       [preWorkVisible]="preWorkVisible"
                                       [preWorkClickable]="preWorkClickable"
                                       (meetingStarted)="meetingStarted.emit($event)"
                                       (elementSelected)="elementSelected.emit($event)"
                                       [showStatus]="showStatus"></adapt-display-meeting-summary>
    </div>
</div>

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { ServiceUri } from "@common/configuration/service-uri";
import { IUserEventHandler } from "@common/user/user-event-handler.interface";
import { IOrganisationEventHandler } from "@org-common/lib/organisation/organisation-event-handler.interface";
import { Intercom, IntercomConfigObject } from "@supy-io/ngx-intercom";
import { IntercomBootInput } from "@supy-io/ngx-intercom/src/app/ng-intercom/types/intercom-boot-input";
import { BehaviorSubject, combineLatest, lastValueFrom, merge, of, timer } from "rxjs";
import { catchError, debounceTime, first, skipUntil, switchMap } from "rxjs/operators";

const IntercomStartDelayInMilliseconds = 7000;

interface IIntercomUserData {
    UserId: number;
    Email: string;
    Hash: string;
}

// Intercom allows custom user attributes
// refer to the following:
//  https://www.intercom.com/help/en/articles/179-send-custom-user-attributes-to-intercom
//  https://developers.intercom.com/installing-intercom/web/attributes-objects/#data-attributes
interface IIntercomCustomUserAttributes {
    [k: string]: string | number | boolean | null | undefined;
}

@Injectable({
    providedIn: "root",
})
export class IntercomTracker implements IUserEventHandler, IOrganisationEventHandler {
    private organisation$ = new BehaviorSubject<Organisation | undefined>(undefined);
    private person$ = new BehaviorSubject<Person | undefined>(undefined);

    public constructor(
        private httpClient: HttpClient,
        private intercom: Intercom,
        private intercomConfig: IntercomConfigObject,
    ) {
        let isLoggedIn = false;

        // Only delay for the first load as we only care about Intercom loading it's own
        // resources causing contention with us loading ours. Also, otherwise every user
        // switch will have a delay (e.g. logout / login) which can lead to weird states
        const person$ = merge(
            this.person$.pipe(
                debounceTime(IntercomStartDelayInMilliseconds),
                first(),
            ),
            this.person$.pipe(
                skipUntil(timer(IntercomStartDelayInMilliseconds)),
            ),
        );
        const intercomData$ = person$.pipe(
            switchMap(async (person) => {
                if (person) {
                    const data = await this.promiseToGetIntercomUserData();

                    if (data) {
                        const bootData: IntercomBootInput & IIntercomCustomUserAttributes = {
                            user_id: String(data.UserId),
                            email: data.Email,
                            user_hash: data.Hash,
                            name: person.fullName,
                            platform: AdaptClientConfiguration.AdaptProjectName,
                        };
                        return bootData;
                    }

                    return data;
                }
            }),
        );

        combineLatest([intercomData$, this.organisation$]).subscribe(([intercomData, organisation]) => {
            if (!isLoggedIn && !intercomData) { // not logged in
                this.intercom.boot({});
            } else if (!isLoggedIn && intercomData) { // not currently logged in, but logging in now
                if (organisation) {
                    intercomData.company = {
                        company_id: String(organisation.organisationId),
                        name: organisation.name,
                    };
                }

                this.intercom.boot(intercomData);
                isLoggedIn = true;
            } else if (isLoggedIn && !intercomData) { // was logged in, but now logging out
                this.intercom.shutdown();
                this.intercom.boot({});
                isLoggedIn = false;
            } else if (isLoggedIn && organisation) { // was logged in, but now changing organisation
                this.intercom.update({
                    company: {
                        company_id: String(organisation.organisationId),
                        name: organisation.name,
                    },
                });
            }
        });
    }

    public setAppId() {
        // According to docs this should be done in ngOnInit, but since
        // we don't have any Angular routing ngOnInit will never be called
        // so do it here instead.
        this.intercomConfig.appId = AdaptClientConfiguration.IntercomAppId;
    }

    public userChanged(person?: Person) {
        this.person$.next(person);
    }

    public organisationChanged(organisation: Organisation) {
        if (organisation && organisation.organisationId) {
            this.organisation$.next(organisation);
        }
    }

    private async promiseToGetIntercomUserData() {
        const uri = `${ServiceUri.MethodologyServicesServiceBaseUri}/IntercomUserData`;

        return lastValueFrom(this.httpClient.get<IIntercomUserData>(uri)
            .pipe(catchError(() => of(undefined))));
    }
}

import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ObjectiveType } from "@common/ADAPT.Common.Model/organisation/objective-type";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { Breakpoint } from "@common/ux/responsive/breakpoint";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { IObjectiveGroup } from "../objectives.service";

@Component({
    selector: "adapt-display-objectives-hierarchy",
    templateUrl: "./display-objectives-hierarchy.component.html",
    styleUrls: ["./display-objectives-hierarchy.component.scss"],
})
export class DisplayObjectivesHierarchyComponent extends BaseComponent implements OnInit, OnChanges, AfterViewInit, AfterViewChecked {
    @Input() public objectiveGroup!: IObjectiveGroup;
    @Input() public isCompactView = false;
    @Input() public showChildren = true;
    @Input() public currentTeamId: number | null = null; // have to use null here as breeze entity is setting this to null rather than undefined
    @Output() public heightChange = new EventEmitter();

    @Input() public hasAnnualObjective = false;
    @Output() public hasAnnualObjectiveChange = new EventEmitter<boolean>();

    @Input() public hasExternalParent = false;
    @Output() public hasExternalParentChange = new EventEmitter<boolean>();

    @Input() public isRoot = false;
    @Input() public applyFilter = false;

    public isHorizontal = false;
    public isExternalObjective = false;
    public allChildrenAreExternalParent = false;
    public isQuarterly = false;
    public needIndentForAnnual = false;
    public needIndent = false;
    public hasChildren = false;
    public isExternalObjectiveWithInternalChild = false;
    public isExternalObjectiveWithInternalParent = false;

    public lines: string[] = [];

    private element: JQuery<Element>;

    private linesInvalidated = new Subject<void>();
    private currentBreakpoint = Breakpoint.XL;
    private height?: number;

    private hasAnnualObjectiveChangeUpdater = this.createThrottledUpdater((result: boolean) => this.hasAnnualObjectiveChange.emit(result), 10);
    private hasExternalParentChangeUpdater = this.createThrottledUpdater((result: boolean) => this.hasExternalParentChange.emit(result), 10);

    public constructor(
        el: ElementRef,
        private responsiveService: ResponsiveService,
    ) {
        super();
        this.element = jQuery(el.nativeElement);
        this.height = this.element.height();

        // having a 10ms buffering gaps for multiple children size changes,
        // which can happen after children prime links
        this.linesInvalidated.pipe(
            debounceTime(100),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updateLines());

        this.responsiveService.currentBreakpoint$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((breakpoint) => {
            this.currentBreakpoint = breakpoint;
            this.isHorizontal = this.currentBreakpoint.isDesktopSize && (!this.isExternalObjective || !this.allChildrenAreExternalParent);
            this.invalidateLines();
        });
    }

    @HostListener("window:resize")
    public onResize() {
        this.invalidateLines();
    }

    public ngAfterViewChecked() {
        const elementHeight = this.element.height();
        if (elementHeight !== this.height) {
            this.height = elementHeight;
            this.childHeightChanged();
        }
    }

    public ngOnInit() {
        if (!this.objectiveGroup) {
            throw new TypeError("'objectiveGroup' is required");
        }
    }

    public ngOnChanges(changesObj: SimpleChanges) {
        if (this.objectiveGroup) {
            this.isExternalObjective = this.objective.teamId !== this.currentTeamId;
            this.isQuarterly = this.objective.type === ObjectiveType.Quarterly;
            this.needIndentForAnnual = this.hasAnnualObjective && this.isQuarterly;
            this.hasChildren = this.objectiveGroup.childGroups.length > 0;
            // this will allow external QOs to be vertically stacked if they are all under the same AO
            this.allChildrenAreExternalParent = this.hasChildren &&
                this.objectiveGroup.childGroups.every((childGroup) => childGroup.objective.teamId !== this.currentTeamId) &&
                this.objectiveGroup.childGroups.every((childGroup) => !!childGroup.childGroups?.length);
            this.isExternalObjectiveWithInternalChild = this.isExternalObjective && this.objHasInternalChild(this.objective);
            this.isExternalObjectiveWithInternalParent = this.isExternalObjective && this.objHasInternalParent(this.objective);
            this.isHorizontal = this.currentBreakpoint.isDesktopSize && (!this.isExternalObjectiveWithInternalChild || !this.allChildrenAreExternalParent);
            this.needIndent = (this.needIndentForAnnual || this.hasExternalParent) && !this.isExternalObjectiveWithInternalChild && this.isRoot;

            if (!this.isQuarterly && !this.isExternalObjective) {
                this.hasAnnualObjectiveChangeUpdater.next(true); // only emit if is annual
            }

            if (this.isExternalObjectiveWithInternalChild) {
                this.hasExternalParentChangeUpdater.next(true);
            }
        }

        if (changesObj.hasAnnualObjective || changesObj.hasExternalParent) {
            this.invalidateLines();
        }
    }

    // need to declare return type for recurring function
    private objHasInternalChild(objective: Objective): boolean {
        if (objective.childObjectives?.length) {
            const someChildInternal = objective.childObjectives.some((child) => child.teamId === this.currentTeamId);
            if (someChildInternal) {
                return true;
            }

            return objective.childObjectives.some((child) => this.objHasInternalChild(child));
        }

        return false;
    }

    private objHasInternalParent(objective: Objective): boolean {
        if (objective.parentObjective) {
            if (objective.parentObjective.teamId === this.currentTeamId) {
                return true;
            }

            return this.objHasInternalParent(objective.parentObjective);
        }

        return false;
    }

    public ngAfterViewInit() {
        this.invalidateLines();
    }

    public get objective() {
        return this.objectiveGroup.objective;
    }

    public toggleShowChildren() {
        this.showChildren = !this.showChildren;
        this.childHeightChanged();
    }

    public childHeightChanged() {
        this.invalidateLines();
        this.heightChange.emit();
    }

    private invalidateLines() {
        this.linesInvalidated.next();
    }

    private updateLines() {
        const parentOffset = this.element.children().first().offset()!;
        this.lines = [];
        if (this.objective) {
            // id defined in display-tree-objective component template included into this component template
            const thisDisplayCard = this.element.find(".display-tree-objective#" + this.objective.objectiveId).parent();
            if (thisDisplayCard.length > 0) {
                const thisDisplayCardOffset = thisDisplayCard.offset()!;
                let startPoint: JQuery.Coordinates;
                if (this.isHorizontal) {
                    startPoint = {
                        top: thisDisplayCardOffset.top - parentOffset.top + 20,
                        left: thisDisplayCardOffset.left - parentOffset.left + thisDisplayCard.width()!,
                    };
                } else {
                    startPoint = {
                        top: thisDisplayCardOffset.top - parentOffset.top + thisDisplayCard.height()!,
                        left: thisDisplayCardOffset.left - parentOffset.left + 20,
                    };
                }

                if (this.hasChildren) {
                    this.objective.childObjectives.forEach((child) => {
                        // id is defined in the corresponding display-tree-objective component instance
                        // which is from the child display-objectives-hierarchy
                        const childCard = this.element.find(".display-tree-objective#" + child.objectiveId);
                        if (childCard.length > 0) {
                            const childCardOffset = childCard.parent().offset()!;
                            if (this.isHorizontal) {
                                this.lines.push(createHorizontalLineToComponentWithOffset(startPoint, childCardOffset));
                            } else {
                                this.lines.push(createVerticalLineToComponentWithOffset(startPoint, childCardOffset));
                            }
                        }
                    });
                }
            }
        }

        function createVerticalLineToComponentWithOffset(startPoint: JQuery.Coordinates, componentOffset: JQuery.Coordinates) {
            const endPoint: JQuery.Coordinates = {
                top: componentOffset.top - parentOffset.top + 20,
                left: componentOffset.left - parentOffset.left,
            };

            return startPoint.left + "," + startPoint.top + " " + startPoint.left + "," + endPoint.top + " " + endPoint.left + "," + endPoint.top;
        }

        function createHorizontalLineToComponentWithOffset(startPoint: JQuery.Coordinates, componentOffset: JQuery.Coordinates) {
            const endPoint: JQuery.Coordinates = {
                top: componentOffset.top - parentOffset.top + 20,
                left: componentOffset.left - parentOffset.left,
            };

            if (endPoint.top === startPoint.top) {
                return startPoint.left + "," + startPoint.top + " " + endPoint.left + "," + endPoint.top;
            } else {
                const vertexOffset = 30;
                return (startPoint.left + vertexOffset) + "," + startPoint.top + " " +
                    (startPoint.left + vertexOffset) + "," + endPoint.top + " " +
                    endPoint.left + "," + endPoint.top;
            }
        }
    }
}

import { Component, Input, ViewChild } from "@angular/core";
import { Account, SubscriptionSubStatus } from "@common/ADAPT.Common.Model/account/account";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ConfigureCreditCardComponent } from "@common/payment-processing/configure-credit-card/configure-credit-card.component";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { AccountService } from "@org-common/lib/organisation/account/account.service";
import { Observable, of } from "rxjs";
import { UpdateInvoicePaymentMethodDialogComponent } from "../update-invoice-payment-method-dialog/update-invoice-payment-method-dialog.component";

@Component({
    selector: "adapt-configure-payment-method",
    templateUrl: "./configure-payment-method.component.html",
})
export class ConfigurePaymentMethodComponent extends ConfigureFeatureBase {
    public readonly AdaptProjectLabel = AdaptClientConfiguration.AdaptProjectLabel;

    @Input() public configItem?: IConfigItem;
    @ViewChild(ConfigureCreditCardComponent) public creditCardComponent?: ConfigureCreditCardComponent;

    public account$: Observable<Account | undefined> = of(undefined);

    constructor(
        configurationService: ConfigurationService,
        private accountService: AccountService,
        private dialogService: AdaptCommonDialogService,
    ) {
        super(configurationService);
    }

    @Autobind
    public async initialiseData() {
        this.account$ = this.accountService.getAccount();
        this.creditCardComponent?.initialiseData();
    }

    public getSubscriptionButtonText(account?: Account) {
        if (!account) {
            return "";
        }

        if (account.extensions.isPendingCancellation || account.subStatus === SubscriptionSubStatus.SubscriptionCancelled) {
            return "Resume subscription";
        }

        return !account.extensions.isActive
            ? "Start subscription"
            : "Update subscription";
    }

    public hasChanges() {
        return this.creditCardComponent
            ? this.creditCardComponent.hasChanges()
            : false;
    }

    public changesAreValid() {
        return this.creditCardComponent
            ? this.creditCardComponent.changesAreValid()
            : true;
    }

    public showUpdateInvoicePaymentMethodDialog(account?: Account) {
        if (!account?.extensions.isActive) {
            this.accountService.showSubscriptionDialog().subscribe();
            return;
        }

        this.dialogService.open(UpdateInvoicePaymentMethodDialogComponent).subscribe();
    }
}

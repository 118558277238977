<div class="d-flex flex-column"
     *ngIf="objective">
    <ng-container *ngIf="workflowStep?.customData === ObjectiveMetadataStep.Title; else otherMetadata">
        <h4>Title</h4>
        <dx-text-box id="objective"
                     #focus
                     [adaptValidateEntity]="objective"
                     adaptValidateEntityProperty="title"
                     placeholder="Type your objective title here"
                     [(value)]="objective.title"
                     (onValueChanged)="onTitleChanged($event)"
                     data-test="objective-title-input"></dx-text-box>
        <div *ngIf="!objective.title && !!objective.description"
             class="w-100 d-flex mt-2">
            <button adaptButton="wizardLink"
                    class="ms-auto"
                    [adaptBlockingClick]="suggestObjectiveTitle">Suggest an objective title</button>
        </div>
        <div *ngIf="!!objective.title && !!objective.description && !reviewResponse"
             class="w-100 d-flex mt-2">
            <button adaptButton="wizardLink"
                    class="ms-auto"
                    [adaptBlockingClick]="reviewObjectiveTitle">Review the objective title</button>
        </div>
        <div *ngIf="reviewResponse"
             class="mt-3 alert alert-light d-flex align-items-start">
            <div [froalaView]="reviewResponse"></div>
            <button adaptButton="dismissBorderless"
                    class="ms-1 me-n2 mt-n2"
                    (click)="reviewResponse = undefined"></button>
        </div>
    </ng-container>
    <ng-template #otherMetadata>
        <h4>Objective type</h4>
        <div class="d-inline-block">
            <div class="form-check form-check-inline">
                <input type="radio"
                       id="annualObjective"
                       name="ObjectiveType"
                       [value]="ObjectiveType.Annual"
                       [(ngModel)]="objective.type"
                       (ngModelChange)="onTypeChanged()"
                       class="form-check-input"
                       data-test="annual-radio-input">
                <label class="form-check-label"
                       for="annualObjective">Annual</label>
            </div>
            <div class="form-check form-check-inline"
                 data-tour="quarterly-radio-input">
                <input type="radio"
                       id="quarterlyObjective"
                       name="ObjectiveType"
                       [value]="ObjectiveType.Quarterly"
                       [(ngModel)]="objective.type"
                       (ngModelChange)="onTypeChanged()"
                       class="form-check-input"
                       data-test="quarterly-radio-input">
                <label class="form-check-label"
                       for="quarterlyObjective">Quarterly</label>
            </div>
        </div>

        <h4 class="mt-4">Owner</h4>
        <adapt-select-person [(person)]="objective.assigneePerson"
                             (personChange)="checkStepCompletion()"
                             [userTypes]="NonParticipantUserTypes"
                             [teamGroup]="objective.team"
                             [required]="true"
                             [filter]="filterAssignees"
                             data-test="objective-owner-dropdown"></adapt-select-person>

        <h4 class="mt-4">Due date</h4>
        <dx-date-box width="100%"
                     [(value)]="objective.dueDate"
                     (valueChange)="checkStepCompletion()"
                     data-test="objective-date-dropdown">
        </dx-date-box>

        <span class="mt-4 d-flex align-items-center gap-1">More options
            <button [(adaptCollapsibleButton)]="collapseMoreOptions"
                    data-test="objective-more-options-button"></button>
        </span>
        <div [collapse]="collapseMoreOptions">
            <div class="mt-4 d-flex flex-column">
                <h4>Supporting objective</h4>
                <adapt-select-supporting-objective [objective]="objective"></adapt-select-supporting-objective>

                <h4 class="mt-4">Labels</h4>
                <adapt-select-label-for-related-entity [objective]="objective"
                                                       (entitiesChange)="onLabelLocationsChanged($event)">
                </adapt-select-label-for-related-entity>
            </div>
        </div>
    </ng-template>
</div>

import { Inject, Injectable } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { PERSONAL_CONFIGURATION_PAGE } from "@common/page-route-providers";
import { NavigationNodeBuilder } from "@common/route/navigation-node-builder";
import { NavigationUtilitiesService } from "@common/route/navigation-utilities.service";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { UserService } from "@common/user/user.service";
import { AuthorisationNotificationService } from "@org-common/lib/authorisation/authorisation-notification.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { BaseOrgCommonNavigationHierarchyService } from "@org-common/lib/sidebar/base-org-common-navigation-hierarchy.service";
import { EMPTY, merge } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Injectable()
export class ConfigurationPersonalHierarchyNavigationService extends BaseOrgCommonNavigationHierarchyService {
    public static readonly HierarchyId = "ADAPT.Configuration.Person.Navigation.Hierarchy";
    public static readonly AltoIconClass = "fal fa-fw fa-user";
    public static readonly CumulusIconClass = "fal fa-fw fa-user";

    public id = ConfigurationPersonalHierarchyNavigationService.HierarchyId;
    public activeNodeShouldBeRebuilt$ = EMPTY;

    public constructor(
        private navUtilities: NavigationUtilitiesService,
        private userService: UserService,
        orgService: OrganisationService,
        authNotification: AuthorisationNotificationService,
        rxjsBreezeService: RxjsBreezeService,
        @Inject(PERSONAL_CONFIGURATION_PAGE) private personalConfigurationPageRoute: IAdaptRoute<{}>,
    ) {
        super(orgService, authNotification);
        this.rebuildHierarchy$ = merge(
            rxjsBreezeService.entityTypeChangedInSave(Person),
        ).pipe(
            debounceTime(100),
        );
    }

    public async buildHierarchy() {
        const person = await this.userService.getCurrentPerson();
        if (!person || !person.isActive()) {
            return new NavigationNodeBuilder().build();
        }

        return this.navUtilities.nodeBuilder()
            .setIconClass(AdaptClientConfiguration.AdaptProject === AdaptProject.Alto
                ? ConfigurationPersonalHierarchyNavigationService.AltoIconClass
                : ConfigurationPersonalHierarchyNavigationService.CumulusIconClass)
            .setHideIconInBreadcrumb(true)
            .setCustomKeyValue("isHiddenInBreadcrumbs", true)
            .setTitle("Personal Configuration")
            .promiseToAddChildController(this.personalConfigurationPageRoute.id)
            .promiseToBuild();
    }
}

import { Logger } from "@common/lib/logger/logger";

const log = Logger.getLogger("EntityExtensions");

export function EntityExtensions<TExtensions extends object, TEntity extends object>(
    extensionConstructor: new (entity: TEntity) => TExtensions,
) {
    return (prototype: TEntity, property: keyof TEntity) => {
        log.debug(`Creating extension property ("${String(property)}") on entity prototype`, prototype);

        Object.defineProperty(prototype, property, {
            // Cannot be enumerable or Breeze will attempt to traverse the property when saving
            enumerable: false,
            get(this: TEntity) {
                if (!this.hasOwnProperty(property)) {
                    log.debug(`Lazily creating extension property ("${String(property)}") on entity`, this);
                    Object.defineProperty(this, property, {
                        enumerable: false,
                        writable: false,
                        value: new extensionConstructor(this),
                    });
                }

                return this[property];
            },
        });
    };
}

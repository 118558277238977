import { Component, ElementRef, Input, OnChanges } from "@angular/core";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ObjectiveLink } from "@common/ADAPT.Common.Model/organisation/objective-link";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { filter } from "rxjs";
import { IObjectiveTeamGroup, ObjectivesService } from "../objectives.service";
import { ObjectivesUiService } from "../objectives-ui.service";

@Component({
    selector: "adapt-display-linked-objectives",
    templateUrl: "./display-linked-objectives.component.html",
    styleUrl: "./display-linked-objectives.component.scss",
})
export class DisplayLinkedObjectivesComponent extends BaseComponent implements OnChanges {
    @Input() public objectives?: Objective[];
    @Input() public objective?: Objective;
    @Input() public hasEditPermissions = false;
    @Input() public showGroupObjectiveTextSuffix = true;
    @Input() public blurTitle = false;
    @Input() public currentPageTeamId?: number;

    public teamGroupedObjectives?: IObjectiveTeamGroup[];

    public constructor(
        private objectivesService: ObjectivesService,
        private objectivesUiService: ObjectivesUiService,
        elementRef: ElementRef,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super(elementRef);

        rxjsBreezeService.entityTypeChanged(ObjectiveLink).pipe(
            filter((link) => link.objective1Id === this.objective?.objectiveId || link.objective2Id === this.objective?.objectiveId),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.ngOnChanges());

        // change of objective status requires reordering
        rxjsBreezeService.entityTypeChanged(Objective).pipe(
            filter((objective) => !!this.objectives?.some((o) => o.objectiveId === objective.objectiveId)),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.ngOnChanges());
    }

    public ngOnChanges() {
        if (this.objective) {
            const linkObjectives = this.objective.objectiveLinks.map((link) => link.objective2);
            this.teamGroupedObjectives = this.objectivesService.groupObjectivesByTeam(linkObjectives);
        } else if (this.objectives) {
            this.teamGroupedObjectives = this.objectivesService.groupObjectivesByTeam(this.objectives);
        } else {
            this.teamGroupedObjectives = [];
        }

        // if not defined or organisation, no additional sort is required as org will also go first
        if (this.currentPageTeamId) {
            // current team group should go first as there won't be a redirect link
            this.teamGroupedObjectives.sort((g1, g2) => {
                if (g1.team?.teamId === g2.team?.teamId) {
                    return 0; // same team, no change
                } else if (this.currentPageTeamId === g1.team?.teamId) {
                    return -1; // g1 before g2
                } else if (this.currentPageTeamId === g2.team?.teamId) {
                    return 1; // g2 before g1
                } else {
                    return 0; // both g1 g2 not current team -> unchanged
                }
            });
        }
    }

    public removeObjectiveLink(target: Objective) {
        if (this.objective) {
            const link = this.objective.objectiveLinks.find((l) => l.objective2 === target);
            if (link) {
                this.objectivesUiService.promptToDeleteObjectiveLink(link).pipe(
                    this.takeUntilDestroyed(),
                ).subscribe();
            }

        }
    }
}

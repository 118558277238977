import { Component, Input, OnChanges } from "@angular/core";
import { ActiveState } from "@common/ADAPT.Common.Model/activeEntity.interface";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ObjectiveViewType } from "../objective-view-type.enum";
import { ObjectivesService } from "../objectives.service";

@Component({
    selector: "adapt-objectives-overview",
    templateUrl: "./objectives-overview.component.html",
    styleUrl: "./objectives-overview.component.scss",
})
export class ObjectivesOverviewComponent implements OnChanges {
    public readonly ObjectiveViewType = ObjectiveViewType;
    public readonly infoIcon = "fal fa-circle-info";
    public readonly errorIcon = "fal fa-exclamation-circle";
    public readonly checkIcon = "fal fa-check-circle";

    @Input() public objectives: Objective[] = [];
    @Input() public hasEditPermissions = false;
    @Input() public teamId?: number;

    public renderGroupContexts: IObjectiveRenderGroupContext[] = [];

    public constructor(private objectivesService: ObjectivesService) { }

    public ngOnChanges() {
        this.objectives = this.objectives.filter((o) => !o.isClosed);
        this.objectives.sort(this.objectivesService.sortObjectives(this.teamId!));

        this.renderGroupContexts = [{
            title: "Objectives with no key results",
            filteredObjectives: this.objectives.filter((o) => !o.hasKeyResults),
            icon: this.errorIcon,
            iconTooltip: `<p>Objectives need to have key results to accurately measure outcomes.</p><p>Please add key results to these objectives.</p>`,
            collapsible: false,
            handleId: "ObjNoResults",
        }, {
            title: "Active objectives supporting closed objectives",
            filteredObjectives: this.objectives.filter((o) => o.hasClosedParent),
            icon: this.errorIcon,
            iconTooltip: `<p>Objectives that support a closed objective lack visibility and accountability.</p><p>Please close these objectives, or change the objective they are supporting.</p>`,
            collapsible: false,
            handleId: "ObjClosedParents",
        }, {
            title: "Objectives overdue by more than 2 weeks",
            filteredObjectives: this.objectives.filter((o) => o.isMoreThanTwoWeeksOverdue),
            icon: this.errorIcon, //error. should have closed
            iconTooltip: `<p>Ensure your open objectives reflect what you are actually working on and when they are due.</p><p>Please either close these objectives or update their due date.</p>`,
            collapsible: false,
            handleId: "ObjOverdue2Weeks",
        }, {
            title: "Objectives with inactive assignee",
            filteredObjectives: this.objectives.filter((o) => o.assigneePerson?.activeState === ActiveState.Inactive),
            icon: this.errorIcon,
            iconTooltip: `<p>Ensure that objectives are owned by someone who is active in the organisation.</p><p>Please reassign these objectives.</p>`,
            collapsible: false,
            handleId: "ObjInactiveAssignee",
        }, {
            title: "Objectives that have not been scored",
            filteredObjectives: this.objectives.filter((o) => o.hasKeyResults && !o.hasBeenScored),
            icon: this.infoIcon,
            iconTooltip: "<p>This highlights objectives that haven't been scored yet.</p><p>If appropriate, please score these objectives.</p>",
            collapsible: false,
            handleId: "ObjNotScored",
        }, {
            title: "Objectives with no issues",
            filteredObjectives: this.objectives.filter((o) => o.hasKeyResults && o.hasBeenScored && o.assigneePerson?.activeState !== ActiveState.Inactive && !o.isMoreThanTwoWeeksOverdue && !o.hasClosedParent),
            icon: this.checkIcon,
            iconTooltip: "<p>These objectives have no outstanding issues.</p>",
            collapsible: true,
            handleId: "ObjAllOk",
        }];
    }

    public isExternalObjective(objective: Objective) {
        // intentionally != to allow undefined match null
        return objective.teamId != this.teamId;
    }
}

export interface IObjectiveRenderGroupContext {
    filteredObjectives: Objective[];
    title: string;
    icon: string;
    iconTooltip?: string;
    handleId: string;
    collapsible: boolean;
}

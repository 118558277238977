import { DefaultBackFinishButtons, DefaultBackNextButtons, GuidedTour } from "@common/lib/guided-tour/guided-tour.service";
import { CommonTourSteps } from "./common-tour-steps";

export const StrategicGoalsWrapUpTour: GuidedTour = {
    steps: [CommonTourSteps.openOrgMenuToShowSidebarNavNodeStep("Your strategic goals", "Strategic Goals"), {
        waitForAndAttachToElementSelector: "adapt-display-link-hierarchy adapt-display-navigation-node span",
        elementSelectorTextContent: "Strategic Goals", // looking for <adapt-display-navigation-node> with span content of this text
        advanceOnAttachedElementEvent: "click",
        attachTo: {
            on: "bottom-end",
        },
        title: `Access strategic goals`,
        text: `<p>Your organisation's strategic goals are available here.</p>
        <p><b>Click on it now</b> to show it.</p>`,
        modalOverlayOpeningPadding: 3,
    }, {
        title: "Your strategic goal",
        text: "Here is a goal you have just defined. By default, each goal displays its name, status and an expand button to show you more information.",
        canClickTarget: false,
        waitForAndAttachToElementSelector: `[data-tour="goal-item"]`,
        attachTo: {
            on: "bottom",
        },
        buttons: DefaultBackNextButtons,
    }, {
        title: "Expand a strategic goal",
        text: "<b>Click on this</b> to expand the goal",
        canClickTarget: true,
        advanceOnAttachedElementEvent: "click",
        waitForAndAttachToElementSelector: `[data-tour="expand-goal"]`,
        attachTo: {
            on: "bottom",
        },
        buttons: DefaultBackNextButtons,
    }, {
        title: "An expanded goal",
        text: "This expanded strategic goal shows you the milestones (and measurements) for this goal, as well as some actions you can perform on the goal.",
        canClickTarget: false,
        waitForAndAttachToElementSelector: `[data-tour="goal-item"]`,
        attachTo: {
            on: "bottom",
        },
        buttons: DefaultBackNextButtons,
    }, {
        title: "Goal actions",
        text: "The footer of the goal allows some common actions.<br /><br />You can add a measurement to track your progress, edit the goal (name, description or milestones), or simply delete the goal.",
        canClickTarget: false,
        waitForAndAttachToElementSelector: `[data-tour="goal-bottom-buttons"]`,
        attachTo: {
            on: "bottom",
        },
        buttons: DefaultBackNextButtons,
    }, {
        title: "Goal status",
        text: "As you review your goals, you can set the status of the goal by click on this. This is a great way of giving people a feeling for the overall status of a goal. Goals can be 'On track', 'Slow' or 'Stuck'.",
        canClickTarget: false,
        waitForAndAttachToElementSelector: `[data-tour="select-goal-status"]`,
        attachTo: {
            on: "bottom",
        },
        buttons: DefaultBackNextButtons,
    }, {
        title: "Actions for goals",
        text: "For each zone, you can add a new goal, or expand/collapse the existing goals.",
        canClickTarget: false,
        waitForAndAttachToElementSelector: `[data-tour="goal-button-group"]`,
        attachTo: {
            on: "bottom",
        },
        buttons: DefaultBackNextButtons,
    }, {
        title: "Reorder goals",
        text: "Within a zone, goals can be reordered. Select this to set the desired order of your goals.",
        canClickTarget: false,
        waitForAndAttachToElementSelector: `[data-tour="reorder-goals-button"]`,
        attachTo: {
            on: "bottom",
        },
        buttons: DefaultBackNextButtons,
    }, {
        title: "Continue",
        text: `<p>You can continue your journey by going to your <b>Personal Dashboard</b>.</p>`,
        waitForAndAttachToElementSelector: `[id="sidebar-personal"]`,
        attachTo: {
            on: "right",
        },
        buttons: DefaultBackFinishButtons,
    }],
};

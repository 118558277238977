import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { EntityExtensions } from "../entity-extensions.decorator";
import { EventSeries } from "./event-series";
import { MeetingAgendaItem } from "./meeting-agenda-item";
import { MeetingAttendee } from "./meeting-attendee";
import { MeetingExtensions } from "./meeting-extensions";
import { MeetingItem } from "./meeting-item";
import { MeetingSupplementaryData } from "./meeting-supplementary-data";
import { Organisation } from "./organisation";
import { Team } from "./team";

export enum MeetingStatus {
    NotStarted = "NotStarted",
    InProgress = "InProgress",
    Ended = "Ended",
}

export interface IMeetingLocation {
    id?: string;
    name?: string;
    emailAddress?: string | null;
}

// interface used by meeting customData for meeting scheduling
export interface IMeetingCustomData {
    /**
     * if "automatically send invites" was checked
     */
    invitationsSent?: boolean;

    /**
     * the user id of the organiser
     */
    microsoftUserId?: string;

    /**
     * the uid field of the meeting
     */
    microsoftUniqueId?: string;

    /**
     * the uid/email of the location (room, Teams)
     */
    microsoftLocation?: string;

    /**
     * the last sync time
     */
    microsoftLastSynced?: string;

    /**
     * if the meeting was imported
     */
    imported?: boolean;

    seriesSequenceId?: number;
}

export class Meeting extends BaseEntity<Meeting> {
    public meetingId!: number;
    public organisationId!: number;
    public teamId!: number;
    public eventSeriesId?: number;
    public meetingDateTime!: Date;
    public lastUpdatedDateTime!: Date;
    public createdDateTime!: Date;
    public endTime!: Date;
    public status!: MeetingStatus;
    public name!: string;
    public customData?: string;
    public location?: string;

    public organisation!: Organisation;
    public team?: Team;
    public supplementaryData?: MeetingSupplementaryData;
    public eventSeries?: EventSeries;
    public meetingAttendees!: MeetingAttendee[];
    public meetingItems!: MeetingItem[];
    public meetingAgendaItems!: MeetingAgendaItem[];

    @EntityExtensions(MeetingExtensions)
    public extensions!: MeetingExtensions;
}

export const MeetingBreezeModel = new BreezeModelBuilder("Meeting", Meeting)
    .orderByDesc("meetingDateTime")
    .hasSource()
    .withIdField("meetingId")
    .withLabelField("name")
    .alwaysFetchingNestedNavigationProperty("eventSeries.eventType")
    .alwaysFetchingNestedNavigationProperty("meetingAgendaItems")
    .isOrganisationEntity()
    .persistChangedEntity()
    .build();

import { BreezeModelUtilities } from "@common/lib/data/breeze-model-utilities";
import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Person } from "../person/person";
import { Item } from "./item";

export class ItemLink extends BaseEntity<ItemLink> {
    public itemLinkId!: number;
    public primaryItemId!: number;
    public secondaryItemId!: number;
    public dateTime!: Date;
    public addedById!: number;
    public addedBy!: Person;
    public primaryItem!: Item;
    public secondaryItem!: Item;
}

export const ItemLinkBreezeModel = new BreezeModelBuilder("ItemLink", ItemLink)
    .hasSource()
    .isOrganisationEntity()
    .withPropertyValidator("primaryItemId", BreezeModelUtilities.EntityIdValidator)
    .withPropertyValidator("secondaryItemId", BreezeModelUtilities.EntityIdValidator)
    .persistChangedEntity()
    .alwaysFetchingNestedNavigationProperty("primaryItem")
    .alwaysFetchingNestedNavigationProperty("secondaryItem")
    .build();

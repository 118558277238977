<div *ngIf="loading"
     class="mt-1 text-muted">
    <i class="fal fa-fw fa-spin fa-spinner"></i> Checking availability...
</div>

<div *ngIf="!loading && available !== undefined"
     class="mt-1"
     [ngClass]="{'text-success': available, 'text-danger': !available}">
    <i class="fal fa-fw"
       [ngClass]="{'fa-check': available, 'fa-xmark': !available}"></i>
    {{text}}

    <ng-content></ng-content>
</div>

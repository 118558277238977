import { Component, ElementRef, EventEmitter, HostListener, Inject, Output, ViewChild, ViewEncapsulation } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ContentReadyEvent } from "devextreme/ui/popup";
import { BehaviorSubject, combineLatest, Subject } from "rxjs";
import { delay, tap } from "rxjs/operators";
import { ADAPT_DIALOG_DATA } from "../adapt-common-dialog.globals";

interface IClickPosition {
    x: number;
    y: number;
}
interface IBoundingClientRect {
    left: number;
    top: number;
}

interface IExpandEvent {
    click: IClickPosition;
    rect: IBoundingClientRect;
}

interface IFrameConfig {
    src: string;
    width: number;
    height: number;
}

export interface IFullscreenDialogConfig {
    iframe?: IFrameConfig;
    image?: string;
}

@Component({
    selector: "adapt-fullscreen-dialog",
    templateUrl: "./fullscreen-dialog.component.html",
    styleUrls: ["./fullscreen-dialog.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class FullscreenDialogComponent extends BaseComponent {
    @Output() public close = new EventEmitter<void>();

    public src: SafeResourceUrl;
    public isFrame: boolean;

    @ViewChild("scroller") private scroller?: ElementRef;
    @ViewChild("content") private content?: ElementRef;

    private popupOpen$ = new Subject<void>();
    private imageExpand$ = new Subject<IExpandEvent>();

    public resizedHeight?: number;
    public resizedWidth?: number;

    public visible = false;
    public expanded = false;

    public triggerResize$ = new BehaviorSubject<void>(undefined);

    constructor(
        @Inject(ADAPT_DIALOG_DATA) public dialogData: IFullscreenDialogConfig,
        private sanitiser: DomSanitizer,
        elementRef: ElementRef,
    ) {
        super(elementRef);
        this.sizeChange$.subscribe(() => this.resizePopup());

        this.isFrame = !!dialogData.iframe;
        // angular will refuse to load the iframe if we don't trust the URL
        this.src = this.sanitiser.bypassSecurityTrustResourceUrl(this.isFrame ? dialogData.iframe!.src : dialogData.image || "");

        // delay to ensure expanded image is available
        this.imageExpand$.pipe(
            delay(0),
            tap((e) => this.scrollExpand(e)),
            this.takeUntilDestroyed(),
        ).subscribe();

        // make sure resize happens as popup becomes available
        combineLatest([this.popupOpen$, this.triggerResize$]).pipe(
            delay(0),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.resizePopup());
    }

    @HostListener("document:keydown", ["$event"])
    public handleEscape(event: KeyboardEvent) {
        if (event.key === "Escape") {
            if (this.visible || this.expanded) {
                this.closePopup();
            }
        }
    }

    public get contentHeight() {
        return this.isFrame ? this.dialogData.iframe?.height : this.content?.nativeElement.naturalHeight;
    }

    public get contentWidth() {
        return this.isFrame ? this.dialogData.iframe?.width : this.content?.nativeElement.naturalWidth;
    }

    public get maxHeight() {
        const fullHeight = window.innerHeight * 0.95;
        return this.content
            ? Math.min(this.contentHeight, fullHeight)
            : fullHeight;
    }

    public get maxWidth() {
        const fullWidth = window.innerWidth * 0.95;
        return this.content
            ? Math.min(this.contentWidth, fullWidth)
            : fullWidth;
    }

    public get canExpand() {
        if (this.content && !this.isFrame) {
            return this.contentWidth > Math.ceil(this.resizedWidth!) || this.contentHeight > Math.ceil(this.resizedHeight!);
        }
        return false;
    }

    private resizePopup() {
        const ratio = this.contentWidth / this.contentHeight;
        const newHeight = Math.min(this.maxHeight, this.maxWidth / ratio);
        const newWidth = Math.min(this.maxWidth, this.maxHeight * ratio);

        this.resizedHeight = newHeight;
        this.resizedWidth = newWidth;
    }

    private calculateScrollPosition(event: IExpandEvent) {
        // cursor position within the image
        const imageX = event.click.x - event.rect.left;
        const imageY = event.click.y - event.rect.top;

        // cursor position as a percentage of the image dimensions
        const xPercent = imageX / this.resizedWidth!;
        const yPercent = imageY / this.resizedHeight!;

        // scale the cursor position from the resized image to the full sized image
        // then take away half the screen dimension to centre the scroll.
        const x = (this.contentWidth * xPercent) - (window.innerWidth / 2);
        const y = (this.contentHeight * yPercent) - (window.innerHeight / 2);

        return { left: x, top: y };
    }

    private scrollExpand(event: IExpandEvent) {
        this.scroller!.nativeElement.scroll(this.calculateScrollPosition(event));
    }

    private transformClickEvent(event: MouseEvent) {
        // pull out the bounding rect here otherwise the event loses it (all values will become 0)
        const rect = (event.target! as HTMLImageElement).getBoundingClientRect();
        return {
            click: {
                x: event.clientX,
                y: event.clientY,
            },
            rect: {
                left: rect.left,
                top: rect.top,
            },
        };
    }

    public closePopup() {
        this.visible = false;
        this.close.emit();
    }

    public openPopup() {
        this.visible = true;
        this.popupOpen$.next();
    }

    public toggleExpandImage(event: MouseEvent) {
        if (this.expanded) {
            this.closePopup();
            return;
        }

        if (this.canExpand) {
            this.imageExpand$.next(this.transformClickEvent(event));
            this.expanded = !this.expanded;
        } else {
            this.closePopup();
        }
    }

    public onContentReady(e: ContentReadyEvent) {
        // add id to dx-popup-content for styling.
        const content = jQuery(e.component.content());
        content.attr("id", "imagePopup");

        // border on the parent causes image to scale, making text blurry
        content.parent().css("border", "none");
    }
}

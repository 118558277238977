/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/ban-types */

import { Logger } from "../logger/logger";

/**
 * Return a descriptor removing the value and returning a getter
 * The getter will return a .bind version of the function
 * and memoize the result against a symbol on the instance
 */
export function getMethodTransformer(transformFn: (fn: Function, thisValue: any) => Function) {
    const log = Logger.getLogger("MethodTransformer");
    return methodTransformer;

    function methodTransformer(target: any, key: PropertyKey, descriptor: TypedPropertyDescriptor<any>): TypedPropertyDescriptor<any> {
        const fn = descriptor.value;

        if (typeof fn !== "function") {
            throw new Error(`A method decorator can only be applied to methods not: ${typeof fn}`);
        }

        // In IE11 calling Object.defineProperty has a side-effect of evaluating the
        // getter for the property which is being replaced. This causes infinite
        // recursion and an "Out of stack space" error.
        let definingProperty = false;

        return {
            configurable: true,
            get() {
                if (definingProperty || this === target.prototype || this.hasOwnProperty(key)
                    || typeof fn !== "function") {
                    return fn;
                }

                let transformedFn = transformFn(fn, this);
                definingProperty = true;
                Object.defineProperty(this, key, {
                    configurable: true,
                    get() {
                        return transformedFn;
                    },
                    set(value) {
                        transformedFn = value;
                    },
                });
                definingProperty = false;
                return transformedFn;
            },
            set(value: Function) {
                // Force getter before setting in the case where
                // const a = new A();
                // a.b = mockFn;
                // This ensures that the transformed function has been placed on the
                // instance property (created above) so setting it here actually sets it
                // on the instance rather than the prototype object (if we didn't do this
                // then later instances of the class would get the changed prototype
                // method!).
                // Can't just have this as an unused expression as it will get optimised
                // away so just log it.
                // CM-3894: Another reason to have this is if we don't have this, the app
                // will fail to start with a stack overflow on a prod build of ES5 with any
                // code like the following:
                // ```
                // class Parent { @Autobind method() {} }
                // class Child extends Parent { method() {} }
                // ```
                // i.e. Overriding a super class method which has a decorator on it.
                log.debug(
                    `Force evaluating decorated method getter (with name "${String(key)}")`
                    + ` as it is being set before use. Its type is:`,
                    typeof this[key],
                );
                this[key] = value;
            },
        };
    }
}

<dx-pie-chart [ngClass]="{'compact': displayCompact}"
              [dataSource]="chartData"
              type="doughnut"
              resolveLabelOverlapping="shift"
              [customizePoint]="customiseChartPoint"
              [startAngle]="90"
              (onDone)="updateChartDimensionAndSubscribeSizeChange($event)">
    <dxo-animation [enabled]="false"></dxo-animation>
    <dxo-legend orientation="horizontal"
                itemTextPosition="right"
                horizontalAlignment="center"
                verticalAlignment="bottom"
                [columnCount]="4"></dxo-legend>
    <dxi-series argumentField="label"
                [minSegmentSize]="0"
                valueField="count">
        <dxo-label [visible]="true"
                   position="columns"
                   [customizeText]="customiseLabel">
            <dxo-font [size]="11"></dxo-font>
            <dxo-connector [visible]="true"
                           [width]="0.5"></dxo-connector>
        </dxo-label>
    </dxi-series>
    <dxo-tooltip [enabled]="true"
                 [customizeTooltip]="customiseChartTooltip"></dxo-tooltip>
</dx-pie-chart>

import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { ItemComment } from "@common/ADAPT.Common.Model/organisation/item-comment";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { defer, EMPTY, Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { KanbanService } from "../../kanban.service";
import { ItemChainComment } from "../item-preview/item-chain-comment";

@Component({
    selector: "adapt-edit-item-comments",
    templateUrl: "./edit-item-comments.component.html",
    styleUrls: ["./edit-item-comments.component.scss"],
})
export class EditItemCommentsComponent extends BaseComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() public item!: Item;
    @Input() public allowEdit = true;
    @Output() public initialised = new EventEmitter<void>();
    @Output() public afterViewInit = new EventEmitter<void>();

    public isLoading = true;
    public chainComments: ItemChainComment[] = [];
    public createComment$: Observable<ItemChainComment> = EMPTY;
    public saveError?: string;

    public constructor(
        private kanbanService: KanbanService,
    ) {
        super();
    }

    public ngOnInit() {
        this.createComment$ = defer(() => this.kanbanService.createItemComment(this.item).pipe(
            map((comment) => new ItemChainComment(comment, this.allowEdit)),
        ));

        this.ngOnChanges();
    }

    public ngAfterViewInit() {
        this.afterViewInit.emit();
    }

    public ngOnChanges() {
        this.fetchItemData(this.item).pipe(
            tap(() => this.chainComments = this.item.comments.map((i: ItemComment) => new ItemChainComment(i, this.allowEdit))),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    private fetchItemData(item: Item) {
        this.isLoading = true;
        return this.kanbanService.primeRelatedItemData(item).pipe(
            tap(() => this.isLoading = false),
        );
    }

    public onSaving() {
        this.saveError = undefined;
    }

    public onSaveFailed(data: { e: any, entity: ItemComment }) {
        this.saveError = ErrorHandlingUtilities.getHttpResponseMessage(data.e);
    }
}

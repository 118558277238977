import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from "@angular/core";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { RouteService } from "@common/route/route.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ITypedSimpleChange } from "@common/ux/base.component/typed-simple-change";
import { Breakpoint } from "@common/ux/responsive/breakpoint";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { ReplaySubject } from "rxjs";
import { switchMap } from "rxjs/operators";
import { ObjectiveFilterService } from "../objective-filter/objective-filter.service";
import { ObjectiveViewType } from "../objective-view-type.enum";
import { ObjectivesAuthService } from "../objectives-auth.service";
import { ObjectivesUiService } from "../objectives-ui.service";

interface IObjectiveListItemChanges extends SimpleChanges {
    objective: ITypedSimpleChange<Objective>;
}

@Component({
    selector: "adapt-objective-list-item",
    templateUrl: "./objective-list-item.component.html",
    styleUrls: ["./objective-list-item.component.scss"],
})
export class ObjectiveListItemComponent extends BaseComponent implements OnChanges, OnDestroy {
    @Input() public objective!: Objective;
    @Input() public isExternalObjective = false;
    @Input() public currentPageTeamId: number | null = null;
    @Input() public depth = 0;
    @Input() public hideKeyResults = false;
    @Input() public hideProgressBar = false;
    @Input() public redirectObjectivesPageView = ObjectiveViewType.ObjectiveListView;
    @Output() public objectiveChange = new EventEmitter<Objective>();

    public keyResultsExpanded = true;
    public cardClasses = {
        "unfocussed": false,
        "organisation-objective": false,
        "external-objective": false,
    };
    public cardStyles = {
        "margin-left.rem": 0,
    };
    public headingStyles = {
        "margin-right.rem": 0,
    };
    public keyResultToggleStyles = {
        "margin-right.rem": 0,
    };

    public isFocussed = true;
    public hasEditPermissions = false;
    private objective$ = new ReplaySubject<Objective>(1);

    public constructor(
        filterService: ObjectiveFilterService,
        private objectivesAuthService: ObjectivesAuthService,
        private objectivesUiService: ObjectivesUiService,
        private responsiveService: ResponsiveService,
        private routeService: RouteService,
    ) {
        super();

        filterService.isFocussedObjective(this.objective$).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((isFocussedObjective) => {
            this.isFocussed = isFocussedObjective;
            this.cardClasses.unfocussed = !isFocussedObjective;

            // Collapse KRs from other teams (or the organisation) by default
            this.keyResultsExpanded = this.objective.hasKeyResults
                && !this.isContextualObjective
                && isFocussedObjective;
        });
        this.objective$.pipe(
            this.takeUntilDestroyed(),
            switchMap(() => this.objectivesAuthService.hasWriteAccessToObjective(this.objective.teamId)),
        ).subscribe((hasPermissions) => this.hasEditPermissions = hasPermissions);
    }

    public get isLG() {
        return this.responsiveService.currentBreakpoint.is(Breakpoint.LG);
    }

    public get progressText() {
        return `${Math.round(this.objective.currentProgress)}%`;
    }

    public ngOnChanges(changes: IObjectiveListItemChanges) {
        if (changes.objective) {
            this.objective$.next(this.objective);

            this.cardClasses["organisation-objective"] = this.isOrganisationObjective;
        }

        if (changes.isExternalObjective) {
            this.cardClasses["external-objective"] = this.isExternalObjective;
        }

        if (changes.depth) {
            this.cardStyles["margin-left.rem"] = this.depth;
            this.headingStyles["margin-right.rem"] = -this.depth;
            this.keyResultToggleStyles["margin-right.rem"] = this.depth;
        }

    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        this.objective$.complete();
    }

    public get isOrganisationObjective() {
        return this.objective.isOrganisationObjective;
    }

    public toggleKeyResults() {
        this.keyResultsExpanded = !this.keyResultsExpanded;
    }

    public get keyResultChevronIconClass() {
        return this.keyResultsExpanded
            ? "fal fa-chevron-up"
            : "fal fa-chevron-down";
    }

    public addKeyResult() {
        this.objectivesUiService.createKeyResultForObjective(this.objective)
            .subscribe();
    }

    public editObjective() {
        this.objectivesUiService.editObjective(this.objective)
            .subscribe(() => this.objectiveChange.emit(this.objective));
    }

    public promptToDeleteObjective() {
        this.objectivesUiService.promptToDeleteObjective(this.objective)
            .subscribe(() => this.objectiveChange.emit(this.objective));
    }

    public get isContextualObjective() {
        const teamId = parseInt(this.routeService.getRouteParam("teamId") ?? "", 10) || null;
        return this.objective.teamId !== teamId;
    }
}

import { Injector, Type } from "@angular/core";

export const persistableDialogs: {
    [name: string]: {classType: Type<any>, initialisationFn?: (injector: Injector) => Promise<any>}
} = {};

export function PersistableDialog(name: string, initialisationFn?: (injector: Injector) => Promise<any>) {

    return (classType: Type<any>) => {
        persistableDialogs[name] = { classType, initialisationFn };
    };
}

export interface ICustomPersistableDialog<TInputData = any, TEncodedData = any> {
    /**
     * Encode the dialog input data into a plain object form. No recursive entries are allowed
     * except for breeze entities, which will be handled after encoding.
     */
    encode: (data: TInputData, injector: Injector) => TEncodedData | undefined;

    /**
     * Decode the encoded plain object into the dialog type and the dialog data, which will be used to open the dialog.
     */
    decode: (encodedData: TEncodedData | undefined, injector: Injector) => TInputData | undefined;
}

export const customPersistableDialogs: { [name: string]: { type: Type<any>, config: ICustomPersistableDialog } } = {};

export function CustomPersistableDialog(name: string, config: ICustomPersistableDialog) {
    return (type: Type<any>) => {
        customPersistableDialogs[name] = { type, config };
    };
}

const disabledEntityPersistentDialogs: Type<unknown>[] = [];

export function DisableEntityPersistent() {
    return (classType: Type<unknown>) => {
        disabledEntityPersistentDialogs.push(classType);
    };
}

export function isDisabledEntityPersistentDialog(dialogType: Type<unknown>) {
    return disabledEntityPersistentDialogs.includes(dialogType);
}

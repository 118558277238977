import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import dxFileUploader, { ProgressEvent as FileUploaderProgressEvent, UploadedEvent, UploadErrorEvent, UploadHttpMethod, ValueChangedEvent } from "devextreme/ui/file_uploader";
import { IdentityService } from "../../identity/identity.service";
import { Autobind } from "../../lib/autobind.decorator/autobind.decorator";
import { Logger } from "../../lib/logger/logger";
import { ErrorHandlingUtilities } from "../../lib/utilities/error-handling-utilities";
import { BaseComponent } from "../../ux/base.component/base.component";
import { StorageImageService } from "../storage-image.service";

interface IUploadHeaders {
    Accept: string;
    Authorization: string;
}

@Component({
    selector: "adapt-select-image",
    templateUrl: "./select-image.component.html",
    styleUrls: ["./select-image.component.scss"],
})
export class SelectImageComponent extends BaseComponent implements OnInit, OnChanges {
    private static defaultResizeWidth = 120;
    private static defaultResizeHeight = 120;

    public static globalId = 1;

    private readonly logger = Logger.getLogger("SelectImageComponent");
    public readonly acceptedFilterTypes = ["image/jpeg", "image/png", "image/gif", "image/bmp"];


    public triggerIdentifier: string;

    @Input() public imageIdentifier?: string;
    @Output() public imageIdentifierChange = new EventEmitter<string>();

    /** Width of image to to display */
    @Input() public imageWidth?: number | string;
    /** Height of image to to display */
    @Input() public imageHeight?: number | string;
    /** Image source to show when image identifier is not given */
    @Input() public defaultImageSrc?: string;
    /** Don't allow selecting a new image */
    @Input() public disabled = false;
    /** Width to resize image to on upload */
    @Input() public resizeWidth = 0;
    /** Height to resize image to on upload */
    @Input() public resizeHeight = 0;
    /** Apply inline editing styles to component */
    @Input() public inline = false;

    public imageDataUrl?: string;

    public uploadMode?: string;
    public uploadMethod?: UploadHttpMethod;
    public uploadUrl?: string;
    public uploadHeaders?: IUploadHeaders;

    public uploading = false;
    public progress = 0;

    public error?: string;
    public fileUploader?: dxFileUploader;

    public constructor(
        private storageImageService: StorageImageService,
        private identityService: IdentityService,
    ) {
        super();
        // this is the identifier for the outermost div where the dxFileUploader will be triggered on
        // - use sequence number so that there can be multiple <adapt-select-image> in a single page
        //   and the right uploader is triggered.
        this.triggerIdentifier = `select_image_id_${SelectImageComponent.globalId++}`;
    }

    public ngOnInit() {
        this.setUploadUrl();
        this.setUploadHeaders();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.imageHeight) {
            this.setImageHeight();
        }

        if (changes.imageWidth) {
            this.setImageWidth();
        }

        if (changes.imageIdentifier) {
            this.setUploadUrl();
        }
    }

    public get imageStyle() {
        return {
            "max-height": this.buildDimension(this.imageHeight),
            "max-width": this.buildDimension(this.imageWidth),
        };

    }

    private buildDimension(dimension: number | string | undefined) {
        if (!dimension) {
            return "";
        }

        return typeof dimension === "number"
            ? `${dimension}px`
            : dimension;
    }

    @Autobind
    private async setUploadHeaders() {
        const token = await this.identityService.promiseToGetAccessToken();

        this.uploadHeaders = {
            Accept: "application/json, text/plain, */*",
            Authorization: "Bearer " + token,
        };

    }

    @Autobind
    private setUploadUrl() {
        const resizeSizes = {
            maxWidth: SelectImageComponent.defaultResizeWidth,
            maxHeight: SelectImageComponent.defaultResizeHeight,
        };

        // If even if only one is set, set them both as we want to scale only to that one dimension
        // And we need to set it to zero for the server to ignore that dimension.
        // e.g. resizeHeight should be 120, set resizeWidth to 0 so that it can be any width to match a
        // height of 120.
        if (this.resizeHeight > 0 || this.resizeWidth > 0) {
            resizeSizes.maxWidth = this.resizeWidth;
            resizeSizes.maxHeight = this.resizeHeight;
        }

        this.uploadUrl = this.storageImageService.getStoreImageUri(
            undefined,
            resizeSizes.maxWidth,
            resizeSizes.maxHeight,
        );
        this.uploadMethod = "POST";
    }

    @Autobind
    private setImageHeight() {
        this.imageHeight = this.imageHeight
            ? this.imageHeight
            : this.imageWidth
                ? undefined
                : 60;
    }

    @Autobind
    private setImageWidth() {
        this.imageWidth = this.imageWidth
            ? this.imageWidth
            : this.imageHeight
                ? undefined
                : 60;
    }

    @Autobind
    public onUploadStarted() {
        this.uploading = true;
        this.error = undefined;
    }

    @Autobind
    public onProgress(e: FileUploaderProgressEvent) {
        this.progress = e.bytesLoaded / e.bytesTotal * 100;
    }

    @Autobind
    public onValueChanged(e: ValueChangedEvent) {
        // is the File API supported?
        const supported = !!(window.File && window.FileReader && window.FileList && window.Blob) && this.acceptedFilterTypes.some((v) => e.value?.length && v == e.value[0].type);

        if (!supported || !e.value?.length) {
            if (e.value) {
                this.logger.info(`Tried to upload invalid image file type: ${e.value[0].type}`);
            }
            this.error = "Invalid file type";
            return;
        }

        const image = e.value[0];

        const fileReader = new FileReader();
        fileReader.onload = (progressEvent: ProgressEvent) => {
            this.imageDataUrl = ((progressEvent.target! as FileReader).result as string);
        };

        fileReader.readAsDataURL(image);
    }

    @Autobind
    public onUploaded(e: UploadedEvent) {
        const originalId = this.imageIdentifier;

        const response = JSON.parse(e.request.response);
        this.imageIdentifier = Object.keys(response)[0];
        this.imageIdentifierChange.emit(this.imageIdentifier);
        this.setUploadUrl();

        this.storageImageService.updateImage(originalId ?? this.imageIdentifier);

        this.uploading = false;
    }

    @Autobind
    public onUploadError(e: UploadErrorEvent) {
        const message = ErrorHandlingUtilities.getHttpResponseMessage(e.request);

        if (message) {
            this.error = message;
            this.imageDataUrl = undefined;
            this.uploading = false;
        }
    }

    public showRealImage() {
        // Do a repaint if the fileUploader has already been initialised (that initialisation process will add a click handler to the target)
        // if target is already initialised before fileUploader, this is not required but no harm doing it.
        // Do this after image is loaded as dx seems to be checking for the dimension of the target, if not showing, it won't add the handler
        // - after image is loaded, dimension won't be 0x0
        // - if defaultImageSrc is not define, there will be an UPLOAD dom - so the outer div won't have dimension 0 and that's initialised before
        //   dxFileUploader (parent of dxFileUploader)
        // - so that's working fine - test by removing the defaultImageSrc in the template where this is used.
        this.fileUploader?.repaint();
        this.imageDataUrl = undefined;
    }
}

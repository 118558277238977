import { Connection, ConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/connection";
import { BaseQueryUtilities } from "@common/lib/data/base-query-utilities";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";

export class ConnectionQueryUtilities extends BaseQueryUtilities {
    public getConnectionsForPersonId(personId: number, forceRemote?: boolean) {
        const predicate = new MethodologyPredicate<Connection>("personId", "==", personId);
        return this.commonDataService.getByPredicate(ConnectionBreezeModel, predicate, forceRemote);
    }
}

<adapt-dialog [maxWidth]="1200"
              [minimal]="true">
    <div adapt-dialog-content>
        <h1 class="p-3 mb-0">{{data.title}}</h1>

        <button class="btn-close floating-close"
                (click)="cancel()"></button>

        <!-- todo: lock down this iframe as much as possible! -->
        <iframe class="scorm"
                [src]="safeResourceLocation"></iframe>
    </div>
</adapt-dialog>

import { Component, ElementRef, Input, OnChanges } from "@angular/core";
import { Input as StrategicInput, InputType } from "@common/ADAPT.Common.Model/organisation/input";
import { InputTypeMetadata } from "@common/ADAPT.Common.Model/organisation/input-type-metadata";
import { InputsCanvas } from "@common/ADAPT.Common.Model/organisation/inputs-canvas";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { StrategyAuthService } from "@org-common/lib/strategy/strategy-auth.service";
import { switchMap } from "rxjs";
import { StrategicInputsService } from "../strategic-inputs.service";

@Component({
    selector: "adapt-input-type",
    templateUrl: "./input-type.component.html",
    styleUrls: ["./input-type.component.scss"],
})
export class InputTypeComponent extends BaseComponent implements OnChanges {
    public readonly EditStrategicInputs = StrategyAuthService.EditStrategicInputs;

    @Input() public inputType?: InputType;
    @Input() public canvas?: InputsCanvas;

    public inputs: StrategicInput[] = [];
    public inputTypeTitle = "Undefined";
    public typeArticleId = "";
    public inputTypeIconClass = "";

    public constructor(
        elementRef: ElementRef,
        private strategicInputsService: StrategicInputsService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super(elementRef);

        rxjsBreezeService.entityTypeChanged(StrategicInput).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.ngOnChanges());
    }

    public ngOnChanges() {
        if (this.inputType && this.canvas) {
            this.inputs = this.canvas.inputs.filter((i) => i.type === this.inputType);
            this.inputTypeTitle = InputTypeMetadata[this.inputType].pluralLabel;
            this.typeArticleId = InputTypeMetadata[this.inputType].articleId;
            this.inputTypeIconClass = InputTypeMetadata[this.inputType].iconClass;
            this.isInitialised = true;
        } else {
            this.inputs = [];
        }
    }

    @Autobind
    public createInput() {
        return this.strategicInputsService.createInputOfType(this.inputType!, this.canvas!).pipe(
            switchMap((input) => this.strategicInputsService.editInput(input)),
        );
    }
}

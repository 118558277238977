import { Injectable, Injector } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { QueuedCaller } from "@common/lib/queued-caller/queued-caller";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { forkJoin, from, lastValueFrom, Observable, ObservableInput, of } from "rxjs";
import { map } from "rxjs/operators";
import { AbstractGuard } from "./abstract.guard";
import { IGuard } from "./guard.interface";
import { NavigationHierarchyService } from "./navigation-hierarchy.service";
import { INavigationNode } from "./navigation-node.interface";
import { NavigationNodeBuilder } from "./navigation-node-builder";
import { RouteService } from "./route.service";

type AccessVerificationFn = (accessVerifierId: string, entity?: any) => Promise<void>;

interface IDynamicValueFunctionOptions<T> {
    entityIdParamName: string;
    getEntityByParamCallback: (id: any) => ObservableInput<T | undefined>;
    getValue: (entity: T) => string;
}

interface IDynamicNodeCallbackOptions<T> {
    entityKeyName: keyof T;
    entityParentKeyName: keyof T;
    getEntityByKeyCallback: (id: number) => PromiseLike<T | undefined>;
    entityRouteParamName?: keyof T;
    parentController?: string;
    rootParentController?: string;
    getLabelByCallback?: (entity: T) => string;
    generateRootControllerParamsFromEntity?: (entity: T) => object;
}

@Injectable({
    providedIn: "root",
})
export class NavigationUtilitiesService {
    public static readonly Id = "adapt.navigation.utilities.service";

    private accessVerificationCallback?: AccessVerificationFn;
    private queuedNavHierarchyService = new QueuedCaller<NavigationHierarchyService>();

    public constructor(
        private routeService: RouteService,
        private injector: Injector,
        private router: Router,
        private activatedRoute: ActivatedRoute,
    ) { }

    /**
     * Sets the function which will be used to check accessVerifierIds
     * @param callback a function which takes an accessVerifierId and an optional entity
     *      and resolves if valid, rejects otherwise.
     */
    public setAccessVerificationCallback(callback: AccessVerificationFn) {
        this.accessVerificationCallback = callback;
    }

    public setNavHierarchyService(service: NavigationHierarchyService) {
        this.queuedNavHierarchyService.setCallee(service);
    }

    public promiseToGetNodeByUrl(url: string) {
        return this.queuedNavHierarchyService.promiseToCall((i) => i.getNodeByUrl(url));
    }

    /**
     * Helper function to build a node for the given controller and parameters. Search Parameters
     * are not supported since they are not considered significant or unique per route. Will check
     * to see if the user is authorised to access the controller first, rejecting the promise if not.
     * @param controllerId The controller to build the node for
     * @param namedParams Parameters to be used when building the node
     * @returns A promise that resolves with the build node
     */
    public promiseToBuildNodeForControllerAndParams(controllerId: any, namedParams?: any) {
        return this.nodeBuilderForControllerAndParams(controllerId, namedParams)
            .promiseToBuild();
    }

    /**
     * Creates a node builder for a controller with the title, route and url prefilled. Search Parameters
     * are not supported since they are not considered significant or unique per route. Feature and Access will
     * automatically be checked if it is defined on the route config or is manually specified
     * @param controllerId The controller to build the node for
     * @param namedParams Named URL parameters to be used when building the node
     * @returns A builder that can be used to further customise the node. Must be built
     *      with promiseToBuild()
     */
    public nodeBuilderForControllerAndParams(controllerId: string, namedParams?: any, accessVerifierId?: string, searchParams?: any) {
        const builder = this.nodeBuilderForController(controllerId);

        // additional checks to determine if the org features defined for the controller is active
        builder.promiseToSetUrl(this.routeService.getControllerRoute(controllerId, namedParams, searchParams));
        const controllerConfig = this.getControllerConfig(controllerId);
        if (controllerConfig) {
            // OrganisationGuard requires access to current activated URL which can only be evaluated while changing route
            // - not to be used when building navigation hierarchy
            // - OrganisationGuard is only implemented in Cumulus -> so cannot use it here - can only check for the name
            // - change guard is for triggering change manager detection - exclude from here too
            const checkInstances = controllerConfig.canActivate
                ?.filter((guard) => guard.Id !== "OrganisationGuard" && guard.Id !== "ChangeGuard")
                .map((i) => this.injector.get<IGuard>(i));
            const canActivateObservables = checkInstances?.map((i) => {
                if (i instanceof AbstractGuard) {
                    // if evaluating canActivate from here, DO NOT emit any failure event -> this usually comes in after route checking
                    // for the activation
                    return i.canActivateWithBypassEvent(this.activatedRoute.snapshot, this.router.routerState.snapshot, true) as Observable<boolean>;
                } else {
                    return i.canActivate(this.activatedRoute.snapshot, this.router.routerState.snapshot) as Observable<boolean>;
                }
            });
            if (canActivateObservables) {
                canActivateObservables.push(of(true)); // just in case there is nothing to resolve
                // only expect each to be Promise<boolean> or Observable<boolean> - needs all true to activate
                builder.ifResolves(
                    lastValueFrom(forkJoin(canActivateObservables).pipe(
                        map((results) => results.every((result) => result)),
                    )).then((canActivate) => canActivate
                        ? Promise.resolve()
                        : Promise.reject("Cannot activate " + controllerConfig?.path)),
                );
            }
        }


        return builder.ifAuthorised(accessVerifierId!);
    }

    /**
     * Gets a node builder for a controller with the title, route prefilled - not prefilling the url.
     * If you would like the URL prefilled use nodeBuilderForControllerAndParams
     * @param controllerId The controller
     * @returns A prefilled Nodebuilder with controller values.
     */
    public nodeBuilderForController(controllerId: any) {
        const controllerConfig = this.getControllerConfig(controllerId);
        const builder = this.nodeBuilder()
            .setTitle(controllerConfig.data!.title)
            .setController(controllerId);

        const originalIfAuthorised = builder.ifAuthorised;
        builder.ifAuthorised = ifAuthorised;

        return builder;

        /**
         * Only builds this node if the user has access to the given permission. If not specified
         * will check for a config defined accessVerifier, if none found the node will be built.
         * promiseToBuild will reject if the user does not have access.
         * @param {String} [accessVerifierId] Optional accessVerifier.
         * @param {Entity} [entity] Optional entity to crosscheck the access verfier against
         * @returns {Builder} The builder for use again.
         */
        function ifAuthorised(accessVerifierId?: string, entity?: any) {
            if (!accessVerifierId) {
                if (controllerConfig) {
                    accessVerifierId = controllerConfig.data!.accessVerifier;
                }
                // else the angular route canActivate already include the accessVerifier which is already verified
            }

            if (accessVerifierId) {
                originalIfAuthorised(accessVerifierId, entity);
            }

            return builder;
        }
    }

    /**
     * A customised nodeBuilder with helper methods to automatically add children
     * via controllerIds
     */
    public nodeBuilder() {
        const self = this;

        // TODO Extend the node-builder class
        const builder = Object.assign(new NavigationNodeBuilder(), {
            promiseToAddChildController,
            promiseToAddChildControllerIfAuthorised,
            setDynamicParentController,
            ifAuthorised,
        });

        return builder;

        function promiseToAddChildControllerIfAuthorised(accessVerifierId: any, controllerId: any, namedParams?: any) {
            const childNodePromise = self.nodeBuilderForControllerAndParams(controllerId, namedParams, accessVerifierId)
                .promiseToBuild();
            builder.promiseToAddChild(childNodePromise);

            return builder;
        }

        function promiseToAddChildController(controllerId: any, namedParams?: any) {
            const childNodePromise = self.promiseToBuildNodeForControllerAndParams(controllerId, namedParams);

            builder.promiseToAddChild(childNodePromise);

            return builder;
        }

        function setDynamicParentController(controllerId: string | (() => Promise<string>), nodeLabelIfParentNotFound?: string) {
            builder.setParentDynamicValueCallback(findOrBuildParentNode);

            return builder;

            async function findOrBuildParentNode(_nodeTemplate: INavigationNode, namedParams: any) {
                const parentControllerId = typeof controllerId === "function"
                    ? await controllerId()
                    : controllerId;

                const parentUrl = await self.routeService.getControllerRoute(parentControllerId, namedParams);

                const navHierarchyService = await self.queuedNavHierarchyService.promiseToWaitUntilCalleeSet();
                const parentNode = navHierarchyService.getNodeByUrl(parentUrl);
                if (parentNode) {
                    return parentNode;
                }

                const parentTemplate = navHierarchyService.getNodeTemplateForControllerId(parentControllerId);
                if (parentTemplate) {
                    return navHierarchyService.promiseToBuildDynamicNodeFromParams(parentTemplate, namedParams);
                }

                const parentRoute = self.routeService.getControllerRouteConfig(parentControllerId);
                if (parentRoute && parentRoute.data!.title) {
                    return new NavigationNodeBuilder()
                        .setTitle(parentRoute.data!.title)
                        .setUrl(parentUrl)
                        .build();
                }

                if (nodeLabelIfParentNotFound) {
                    return new NavigationNodeBuilder()
                        .setTitle(nodeLabelIfParentNotFound)
                        .build();
                }
            }
        }

        /**
         * Only builds this node if the user has access to the given permission.
         * promiseToBuild will reject if the user does not have access.
         * @param accessVerifierId accessVerifierId.
         * @param Optional entity to crosscheck the access verfier against
         * @returns The builder for use again.
         */
        // accessVerifierId being optional is a hack to enable it being optional in nodeBuilderForController
        function ifAuthorised(accessVerifierId?: string, entity?: any) {
            if (!self.accessVerificationCallback) {
                throw new Error("You must setAccessVerificationCallback in navigationUtilities before calling this function");
            }

            builder.ifResolves(self.accessVerificationCallback(accessVerifierId!, entity));

            return builder;
        }
    }

    /**
     * Creates a dynamic node callback function, generating the node to the given
     * specifications
     */
    public createDynamicNodeCallbackFromOptions<T>(options: IDynamicNodeCallbackOptions<T>) {
        const self = this;

        if (!options.entityRouteParamName) {
            options.entityRouteParamName = options.entityKeyName;
        }

        return promiseToBuildDynamicNode;

        function promiseToBuildDynamicNode(nodeTemplate: any, namedParams: any) {
            const entityId = StringUtilities.stringToInt(namedParams[options.entityRouteParamName!]);
            if (!entityId) {
                throw new Error(options.entityKeyName.toString() + " must be set");
            }

            if (!options.parentController) {
                options.parentController = nodeTemplate.controller;
            }

            return promiseToBuildEntityNodeFromEntityId(entityId);

            async function promiseToBuildEntityNodeFromEntityId(entityIdToBuildFrom: number): Promise<INavigationNode | undefined> {
                if (!entityIdToBuildFrom) {
                    return Promise.resolve(undefined);
                }

                const entity = await options.getEntityByKeyCallback(entityIdToBuildFrom);
                if (!entity) {
                    return Promise.resolve(undefined);
                }

                const entityParams = getParamsFromEntityId(entity[options.entityKeyName]);
                const label = options.getLabelByCallback
                    ? options.getLabelByCallback(entity)
                    : (entity as any).label;

                return self.nodeBuilderForControllerAndParams(nodeTemplate.controller, entityParams)
                    .setIsDynamic()
                    .setTitle(label)
                    .promiseToAddParent(determineOrBuildParent())
                    .promiseToBuild();

                async function determineOrBuildParent(): Promise<INavigationNode | undefined> {
                    const parentEntityId = Number(entity![options.entityParentKeyName]);

                    const entityUrl = await promiseToGetParentUrl();
                    const navHierarchyService = await self.queuedNavHierarchyService.promiseToWaitUntilCalleeSet();
                    const entityNode = navHierarchyService.getNodeByUrl(entityUrl);

                    if (entityNode) {
                        return Promise.resolve(entityNode);
                    } else if (parentEntityId) {
                        return promiseToBuildEntityNodeFromEntityId(parentEntityId);
                    } else {
                        // Todo check assertion
                        return navHierarchyService.promiseToBuildDynamicParentChainFromControllerAndParams(options.rootParentController!, entityParams);
                    }

                    function promiseToGetParentUrl() {
                        if (!parentEntityId) {
                            if (!options.rootParentController) {
                                throw new Error("The rootParentController option must be set");
                            }

                            if (options.generateRootControllerParamsFromEntity) {
                                Object.assign(entityParams, options.generateRootControllerParamsFromEntity(entity!));
                            }

                            return self.routeService.getControllerRoute(options.rootParentController, entityParams);
                        }

                        const parentEntityParams = getParamsFromEntityId(parentEntityId);
                        return self.routeService.getControllerRoute(options.parentController!, parentEntityParams);
                    }
                }
            }

            function getParamsFromEntityId(entityIdForParams: any) {
                const params: any = {};

                params[options.entityKeyName] = entityIdForParams;

                return params;
            }
        }
    }

    public createDynamicValueFunctionForOptions<T>(options: IDynamicValueFunctionOptions<T>) {
        return dynamicValueFunction;

        async function dynamicValueFunction(nodeTemplate: INavigationNode, namedParams: Params) {
            // Named params may not be an int (see Nimbus dynamic org node), so only
            // use converted value if it is actually a number
            let param = namedParams[options.entityIdParamName];
            const intParam = parseInt(String(param), 10);
            if (!isNaN(intParam)) {
                param = intParam;
            }

            if (!param) {
                return nodeTemplate.title;
            }

            const entity = await lastValueFrom(from(options.getEntityByParamCallback(param)));
            if (!entity) {
                return "";
            }

            return options.getValue(entity);
        }
    }

    private getControllerConfig(controllerId: string) {
        const controllerConfig = this.routeService.getControllerRouteConfig(controllerId);
        if (!controllerConfig) {
            throw new Error('The controllerId "' + controllerId + '" has not been registered! Check your registration config.');
        }

        return controllerConfig;
    }
}

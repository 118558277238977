import { defer } from "rxjs";
import { Logger } from "../logger/logger";

export class DomUtilities {
    private static log = Logger.getLogger("DomUtilities");

    /** Creates a cold observable that emits with the script load event then completes */
    public static loadJsScript(scriptPath: string) {
        return defer(() => DomUtilities.promiseToLoadJsScript(scriptPath));
    }

    /**
     * Converts a value from rem to pixels.
     *
     * @param rem - The value in rem to convert to pixels.
     * @return The converted value in pixels.
     */
    public static convertRemToPixels(rem: number) {
        return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
    }

    /** Adds the given script to the dom and loads its contents */
    private static promiseToLoadJsScript(scriptPath: string) {
        return new Promise<void>((resolve, reject) => {
            const newScript = document.createElement("script");

            newScript.onload = (e) => {
                DomUtilities.log.info(`Successfully loaded ${scriptPath}`, e);
                resolve();
            };
            newScript.onerror = (e) => {
                DomUtilities.log.error(`Failed to load ${scriptPath}`, e);
                reject(e);
            };

            document.body.append(newScript);
            newScript.src = scriptPath;
        });
    }
}

import { Component, Inject, InjectionToken, Injector, Input, OnInit, Optional, Provider } from "@angular/core";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";

type ConfigureAccessLevelAction = (injector: Injector, role: Role) => Promise<void>;

export const CONFIGURE_ACCESS_LEVEL_ACTION = new InjectionToken<ConfigureAccessLevelAction>("CONFIGURE_ACCESS_LEVEL_ACTION");

export function provideConfigureAccessLevelAction(action: ConfigureAccessLevelAction): Provider {
    return {
        provide: CONFIGURE_ACCESS_LEVEL_ACTION,
        useValue: action,
        multi: false,
    };
}

@Component({
    selector: "adapt-link-access-level",
    templateUrl: "./link-access-level.component.html",
})
export class LinkAccessLevelComponent implements OnInit {
    @Input() public role?: Role;
    @Input() public showIcon = true;

    public linkDisabled = false;

    public constructor(
        private injector: Injector,
        @Optional() @Inject(CONFIGURE_ACCESS_LEVEL_ACTION) public configureAccessLevelAction?: ConfigureAccessLevelAction,
    ) {
    }

    public ngOnInit() {
        if (!this.configureAccessLevelAction) {
            this.linkDisabled = true;
        }
    }

    public configureAccessLevel() {
        this.configureAccessLevelAction?.(this.injector, this.role!);
    }
}

<adapt-dialog [maxWidth]="600">
    <h3 adapt-dialog-title>{{title}}</h3>

    <div adapt-dialog-content>
        <div class="alert alert-info">
            Set the access levels for this user.
            Levels are cumulative allowing you to build up flexible permission sets for your employees.
        </div>

        <table>
            <tr>
                <th>Access level</th>
                <th></th>
            </tr>
            <tr *ngFor="let rc of roleConnections">
                <td class="pt-1">
                    <adapt-link-access-level [role]="rc.role"></adapt-link-access-level>
                </td>
                <td class="ps-2">
                    <button adaptButtonIcon="delete"
                            class="ms-3 btn btn-link"
                            *ngIf="!rc.endDate"
                            (click)="permissionRemoved(rc)"
                            title="Remove access level"></button>
                </td>
            </tr>
            <tr *ngIf="roleConnections">
                <td class="pt-3">
                    <label>Add new access level</label>
                    <adapt-select-access-level [userType]="UserType.Leader"
                                               [accessLevel]="newAccessLevel"
                                               [required]="false"
                                               (accessLevelChange)="newAccessLevelSelected($event)"
                                               [filter]="roleFilter"
                                               data-test="select-access-level"></adapt-select-access-level>
                </td>
            </tr>
        </table>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [discardConfirmationInstance]="instance"></adapt-standard-dialog-buttons>
</adapt-dialog>

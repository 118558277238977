import { BreezeModelUtilities } from "@common/lib/data/breeze-model-utilities";
import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Person } from "../person/person";
import { Item } from "./item";

export class ItemComment extends BaseEntity<ItemComment> {
    public itemCommentId!: number;
    public itemId!: number;
    public dateTime!: Date;
    public addedById!: number;
    public comment!: string;
    public addedBy!: Person;
    public item!: Item;
}

export const ItemCommentBreezeModel = new BreezeModelBuilder("ItemComment", ItemComment)
    .hasSource()
    .isOrganisationEntity()
    .withIdField("itemCommentId")
    .alwaysFetchingNavigationProperty("addedBy")
    .withPropertyValidator("comment", BreezeModelUtilities.NonEmptyStringPropertyValidator)
    .persistChangedEntity()
    .build();

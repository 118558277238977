import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DxScrollViewModule } from "devextreme-angular";
import { SteppedProgressBarComponent } from "./stepped-progress-bar.component";


@NgModule({
    declarations: [
        SteppedProgressBarComponent,
    ],
    imports: [
        CommonModule,
        DxScrollViewModule,
    ],
    exports: [
        SteppedProgressBarComponent,
    ],
})
export class AdaptSteppedProgressBarModule {}

<adapt-confirmation-dialog (closed)="onDialogClosed($event)"
                           (initialised)="onInitialised($event)">
    <span>The following key result will be deleted</span>
    <span *ngIf="keyResult.values.length > 0">
        together with its {{keyResult.values.length}} values and their corresponding comments
    </span>

    <div class="mt-2 mb-3">
        <adapt-key-result-list-item [keyResult]="keyResult"
                                    [isEditing]="false"
                                    [layout]="listItemLayout"></adapt-key-result-list-item>
    </div>

    <p>Are you sure you want to delete?</p>
</adapt-confirmation-dialog>

import { Component, Input, OnInit } from "@angular/core";
import { SearchType } from "@org-common/lib/search/search.interface";
import { SearchService } from "@org-common/lib/search/search.service";
import { ISearchResults } from "@org-common/lib/search/search-results.interface";

@Component({
    selector: "adapt-search-row-elements",
    templateUrl: "./search-row-elements.component.html",
})
export class SearchRowElementsComponent implements OnInit {
    @Input() public type!: SearchType;
    @Input() public searchResults!: ISearchResults;

    public readonly SearchType = SearchType;
    public readonly SearchService = SearchService;

    public sortedObjectiveResults?: ISearchResults["Objective"];
    public sortedGuidanceResults?: ISearchResults["Guidance"];

    public ngOnInit() {
        if (this.type === SearchType.Objective) {
            this.sortedObjectiveResults = SearchService.SortByTeamId(this.searchResults.Objective);
        }

        if (this.type === SearchType.Guidance) {
            this.sortedGuidanceResults = SearchService.SortByZone(SearchService.SortByTeamId(this.searchResults.Guidance));
        }
    }
}

import { Component, Input } from "@angular/core";
import { CalendarIntegrationProvider } from "@common/ADAPT.Common.Model/organisation/organisation-detail";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { PersonImageComponent } from "@common/user/person-image/person-image.component";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { EMPTY } from "rxjs";
import { ICalendarConnectComponent, IOAuthUser } from "../../../calendar/calendar.interface";
import { OAuthService } from "../../oauth.service";

@Component({
    selector: "adapt-connect-with-microsoft",
    templateUrl: "./connect-with-microsoft.component.html",
    styleUrls: ["../../../calendar/calendar-integration.scss", "./connect-with-microsoft.component.scss"],
})
export class ConnectWithMicrosoftComponent extends BaseComponent implements ICalendarConnectComponent {
    @Input() public showLogoutButton = false;
    @Input() public disabled = false;

    public defaultImageSrc = PersonImageComponent.DefaultProfileImageUrl;

    // internally track user as we don't want to show user when a different provider is active
    public user?: IOAuthUser;

    public constructor(private oauthService: OAuthService) {
        super();

        oauthService.isAuthedWithProvider$(CalendarIntegrationProvider.Microsoft).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((authed) => this.user = authed
            ? this.oauthService.user
            : undefined);
    }

    @Autobind
    public login() {
        if (this.disabled) {
            return EMPTY;
        }

        return this.oauthService.login(CalendarIntegrationProvider.Microsoft);
    }

    @Autobind
    public logout() {
        if (this.disabled) {
            return EMPTY;
        }

        return this.oauthService.logout();
    }
}

<div class="toolbar-directive"
     [ngClass]="customClasses"
     *ngIf="features.toolbarEnabled"
     data-test="toolbar">
    <div class="toolbar-directive-left">
        <div class="toolbar-directive-title"
             #title></div>

        <div class="toolbar-directive-search"
             *ngIf="features.searchEnabled">
            <dx-text-box mode="search"
                         placeholder="Search"
                         (onValueChanged)="searchValueChangedCallback && searchValueChangedCallback!($event)">
            </dx-text-box>
        </div>

        <div class="toolbar-directive-group toolbar-directive-compiled-content"
             #leftContent></div>
    </div>

    <div class="toolbar-directive-center">
        <div class="toolbar-directive-group toolbar-directive-compiled-content"
             #centerContent></div>
    </div>

    <div class="toolbar-directive-right">
        <div class="toolbar-directive-group toolbar-directive-compiled-content"
             #rightContent></div>
    </div>
</div>

import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { ItemStatus, ItemStatusMetadata } from "@common/ADAPT.Common.Model/organisation/item-status";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IAdaptMenuItem } from "@common/ux/menu/menu.component";
import { switchMap } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { KanbanNavigationService } from "../../kanban-navigation.service";
import { KanbanUiService } from "../../kanban-ui.service";
import { IItemBatchSelectPersonData, ItemBatchSelectPersonDialogComponent } from "../item-batch-select-person-dialog/item-batch-select-person-dialog.component";
import { ItemBatchSelectStatusDialogComponent } from "../item-batch-select-status-dialog/item-batch-select-status-dialog.component";

@Component({
    selector: "adapt-item-batch-action-toolbar",
    templateUrl: "./item-batch-action-toolbar.component.html",
    styleUrls: ["./item-batch-action-toolbar.component.scss"],
})
export class ItemBatchActionToolbarComponent extends BaseComponent implements OnChanges {
    @Input() public items!: Item[];
    @Input() public isPersonalBoardView = false;
    @Output() public actionPerformed = new EventEmitter<void>();
    @Output() public exitSelectionMode = new EventEmitter<void>();

    public statusChangeMenu: IAdaptMenuItem[] = [];

    public itemsAreSelected = false;
    public boards: Board[] = [];
    public singleBoard?: Board;
    public personalItemSelected = false;

    public constructor(
        private commonDataService: CommonDataService,
        private commonDialogService: AdaptCommonDialogService,
        private kanbanUiService: KanbanUiService,
        private kanbanNavService: KanbanNavigationService,
    ) {
        super();
    }

    public ngOnChanges() {
        this.itemsAreSelected = this.items.length > 0;
        this.personalItemSelected = this.items.some((item) => item.board?.isPersonalBoard);

        const itemBoards = this.items
            .map((i) => i.board)
            .filter((b) => !!b) as Board[];
        this.boards = ArrayUtilities.distinct(itemBoards);
        this.singleBoard = this.boards.length === 1
            ? this.boards[0]
            : undefined;

        this.buildStatusChangeMenu();
    }

    @Autobind
    public assignPerson() {
        const options: IItemBatchSelectPersonData = { boards: this.boards, isPersonalBoardView: this.isPersonalBoardView };
        return this.commonDialogService.open(ItemBatchSelectPersonDialogComponent, options).pipe(
            switchMap((person) => {
                for (const item of this.items) {
                    item.assigneeId = person?.personId;
                }
                return this.commonDataService.saveEntities(this.items);
            }),
            tap(() => this.actionPerformed.emit()),
            catchError((e) => this.commonDataService.rejectChanges(this.items).pipe(
                switchMap(() => this.commonDialogService.showErrorDialog("Failed to save changes", ErrorHandlingUtilities.getHttpResponseMessage(e))),
            )),
        );
    }

    @Autobind
    public moveItems() {
        const firstItem = this.items[0];
        return this.kanbanUiService.openMoveItemsDialog(this.items).pipe(
            switchMap((board) => {
                for (const item of this.items) {
                    item.boardId = board.boardId;
                    if (board.isPersonalBoard) {
                        item.assigneeId = board.personId;
                    }
                }
                return this.commonDataService.saveEntities(this.items);
            }),
            tap(() => this.actionPerformed.emit()),
            catchError((e) => this.commonDataService.rejectChanges(this.items).pipe(
                switchMap(() => this.commonDialogService.showErrorDialog("Failed to save changes", ErrorHandlingUtilities.getHttpResponseMessage(e))),
            )),
            tap(() => this.kanbanNavService.gotoItemPage(firstItem, false).subscribe()),
        );
    }

    private buildStatusChangeMenu() {
        const statusItems: IAdaptMenuItem[] = [
            ItemStatusMetadata.ToDo,
            ItemStatusMetadata.InProgress,
            ItemStatusMetadata.Done,
            ItemStatusMetadata.Backlog,
            ItemStatusMetadata.Closed,
        ].map((status) => ({
            text: status.name,
            icon: status.iconClass,
            // put backlog and closed in separate section since they will behave differently.
            beginGroup: status === ItemStatusMetadata.Backlog,
            onClick: () => this.changeStatus(status.status).subscribe(),
            // only show the status option if not all the selected items are this status
            visible: !this.items.every((i) => i.status === status.status),
        } as IAdaptMenuItem));

        this.statusChangeMenu = [{
            text: "Change status...",
            icon: "fal fa-fw fa-wand-magic-sparkles",
            disabled: !this.itemsAreSelected,
            items: statusItems,
        }];
    }

    private changeStatus(status: ItemStatus) {
        return this.commonDialogService.open(ItemBatchSelectStatusDialogComponent, { items: this.items, status }).pipe(
            switchMap(() => {
                for (const item of this.items) {
                    item.status = status;
                }
                return this.commonDataService.saveEntities(this.items);
            }),
            tap(() => this.actionPerformed.emit()),
            catchError((e) => this.commonDataService.rejectChanges(this.items).pipe(
                switchMap(() => this.commonDialogService.showErrorDialog("Failed to save changes", ErrorHandlingUtilities.getHttpResponseMessage(e))),
            )),
        );
    }
}

<div *adaptLoading="!runData">
    <p>Select a month for your yearly cycle to begin in:</p>

    <dx-select-box class="mb-3"
                   [items]="months"
                   [width]="150"
                   displayExpr="text"
                   valueExpr="value"
                   [(value)]="cadenceCycle.month"></dx-select-box>

    <div *ngIf="hasExistingCadences"
         class="alert"
         [class.alert-warning]="cadenceCycle.entityAspect.entityState.isModified()"
         [class.alert-info]="!cadenceCycle.entityAspect.entityState.isModified()"
         role="alert">
        You have already set up your cadences for this year.
        If you change your cycle, those cadences will be discarded.
        All associated meetings will be updated once you have set your new cadence.

        <div *ngIf="cadenceCycle.entityAspect.entityState.isModified()"
             class="mt-1">
            <button type="button"
                    adaptButtonIcon="refresh"
                    class="btn btn-link"
                    (click)="undoChanges()">Undo changes</button>
        </div>
    </div>

    <p class="mb-1">Your first cycle will be <b>{{cadenceCycle.extensions.firstCycleLength}}</b> months long,
        running from {{cadenceCycle.extensions.startMonthYear}}
        to {{cadenceCycle.extensions.endMonthYear}}.</p>
    <p>Every cycle after that will be 1 year long, starting {{cadenceCycle.extensions.nextCycleMonthYear}}.</p>
</div>

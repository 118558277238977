<ng-container>
    <!-- Need this scroll view around the sortable for dragging if we have more themes within a zone or we can't possibly order those wrapped zone -->
    <dx-scroll-view [scrollByContent]="true"
                    direction="horizontal"
                    *ngIf="isEditing; else readonlyGoalsView"
                    [ngClass]="{
                'uncategorised-drag-border': isDraggingGoal,
                'dragging-categorised-goal': isDraggingCategorisedGoal,
            }">
        <dx-sortable [ngClass]="{'goals-zone-is-reordering' : isReordering}"
                     data-cat-name="goals"
                     class="goals-zone"
                     handle="#goalGripper"
                     [id]="zone"
                     [itemOrientation]="categoryDragOrientation"
                     [group]="goalDropGroup"
                     [data]="goals"
                     (onDragStart)="onGoalDragStart($event)"
                     (onRemove)="onGoalRemove($event)"
                     (onAdd)="onGoalAdd($event)"
                     (onDragEnd)="reorderGoal($event)">
            <adapt-strategic-goal-item *ngFor="let goal of goals"
                                       class="d-block category"
                                       [ngClass]="{'is-reordering-category': isReordering}"
                                       [id]="goal.goalId"
                                       [goal]="goal"
                                       [isExpanded]="expandGoals"
                                       [isReordering]="isReordering"
                                       [isDraggable]="true"
                                       (isExpandChanged)="onIsExpandChangedOnItem()"
                                       [isEditing]="isEditing"
                                       data-tour="goal-item"></adapt-strategic-goal-item>
        </dx-sortable>
    </dx-scroll-view>
</ng-container>

<ng-template #readonlyGoalsView>
    <div *ngIf="goals.length > 0"
         class="goals-zone">
        <adapt-strategic-goal-item *ngFor="let goal of goals"
                                   class="avoid-page-break-inside"
                                   [goal]="goal"
                                   [isExpanded]="expandGoals"
                                   [isDraggable]="true"
                                   (isExpandChanged)="onIsExpandChangedOnItem()"
                                   [isEditing]="false"></adapt-strategic-goal-item>
    </div>
</ng-template>

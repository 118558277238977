import { DefaultBackFinishButtons, DefaultBackNextButtons, DefaultCancelTourButton, GuidedTour, nextStep } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourUtils } from "@common/lib/guided-tour/guided-tour.utils";
import { identifyStrategicThemesWorkflowName } from "../workflows/identify-strategic-themes/identify-strategic-themes-workflow";

export const AttachInputsTour: GuidedTour = {
    steps: [{
        title: "Introduction",
        text: `<p>This tour will walk you through the process of attaching strategic inputs to the strategic working board.</p>
        <p>If you already know how to do it, you can cancel this tour.</p>`,
        buttons: [DefaultCancelTourButton, {
            classes: "btn btn-primary",
            text: "Proceed",
            action: nextStep,
        }],
    }, {
        title: "Add a theme",
        text: `<p>Inputs attach to strategic themes.</p>
        <p>You do not have any themes at the moment, so you cannot attach any inputs.</p>
        <p>We recommend following the <b>${identifyStrategicThemesWorkflowName}</b> pathway to figure out your strategic themes.</p>`,
        showOn: () => {
            // this step will only be shown if there are no themes
            const themeMenu = GuidedTourUtils.getFirstElementWithTextContent(`[data-tour="theme-action-menu"]`);
            return !GuidedTourUtils.isElementVisible(themeMenu);
        },
        buttons: [DefaultCancelTourButton],
    }, {
        title: "Attaching inputs to a theme",
        text: `<p>To attach inputs to a theme, use the corresponding action item in the menu.</p>
        <p><b>Click on it now</b> to reveal the associated options.</p>`,
        waitForAndAttachToElementSelector: `[data-tour="theme-action-menu"]`,
        attachTo: {
            on: "bottom",
        },
        modalOverlayOpeningPadding: 3,
        advanceOnAttachedElementEvent: "click",
        showOn: () => {
            // this step will only be shown if there is a theme
            const themeMenu = GuidedTourUtils.getFirstElementWithTextContent(`[data-tour="theme-action-menu"]`);
            return GuidedTourUtils.isElementVisible(themeMenu);
        },
    }, {
        title: "Attach inputs dialog",
        text: `<p>This is the menu item which will display the action dialog to attach SWT inputs to the theme.</p>
        <p>This is not clickable during the tour as it would disrupt the flow. You can try interacting with it later after completing the tour.</p>
        <p><b>Click 'Next' to continue</b>.</p>`,
        waitForAndAttachToElementSelector: ".dx-menu-item",
        elementSelectorTextContent: "Attach strength, weakness & trend inputs",
        modalOverlayOpeningPadding: 0,
        attachTo: {
            on: "left",
        },
        canClickTarget: false, // have to prevent attached element from being clickable or the menu item will disappear and the guided will lose the attach handle
        buttons: DefaultBackNextButtons,
    }, {
        title: "Attaching competitor analysis inputs",
        text: `<p>This is the menu item which will display the action dialog to attach Competitor Analysis inputs to the zone.</p>
    <p><b>Click 'Next' to continue</b>.</p>`,
        waitForAndAttachToElementSelector: ".dx-menu-item",
        elementSelectorTextContent: "Attach competitor analysis inputs",
        modalOverlayOpeningPadding: 0,
        attachTo: {
            on: "left",
        },
        canClickTarget: false, // have to prevent attached element from being clickable or the menu item will disappear and the guided will lose the attach handle
        buttons: DefaultBackNextButtons,
    }, {
        title: "End of attach inputs tour",
        text: `<p>This concludes the tour to attach inputs to your strategic working board.</p>
        <p>Now, proceed to attach inputs to your preferred themes.</p>`,
        buttons: DefaultBackFinishButtons,
    }],
};

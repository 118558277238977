import { AfterViewChecked, AfterViewInit, Directive, ElementRef, Injector, OnDestroy } from "@angular/core";
import { ScrollPersistenceHelper } from "./base.component/scroll-persistence-helper";
import { BaseRoutedComponent } from "./base-routed.component";

@Directive()
export class BaseScrollPersistingRoutedComponent extends BaseRoutedComponent implements OnDestroy, AfterViewInit, AfterViewChecked {
    private scrollPersistenceHelper: ScrollPersistenceHelper;

    constructor(injector: Injector, elementRef: ElementRef) {
        super(injector, elementRef);

        this.scrollPersistenceHelper = new ScrollPersistenceHelper(elementRef);
    }

    public blockScrollPositionRestore() {
        this.scrollPersistenceHelper.blockRestore();
    }

    public unblockScrollPositionRestore() {
        this.scrollPersistenceHelper.unblockRestore();
    }

    public ngOnDestroy() {
        super.ngOnDestroy();

        this.scrollPersistenceHelper.onDestroy();
    }

    public ngAfterViewInit() {
        this.scrollPersistenceHelper.afterViewInit();
    }

    public ngAfterViewChecked() {
        this.scrollPersistenceHelper.afterViewChecked();
    }

    /**
     * Calling this will result in the scroll position of the parent mainview/left/right-column to be remembered
     * till session refresh.
     * @param key The key use to store the scroll position - gives you control over if all component of the same type
     *  share a single position or component's input.
     */
    protected rememberScrollPosition(key: string) {
        this.scrollPersistenceHelper.rememberScrollPosition(key);
    }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptTooltipModule } from "../tooltip.directive/tooltip.module";
import { RatingStarsComponent } from "./rating-stars.component";


@NgModule({
    imports: [
        CommonModule,
        AdaptTooltipModule,
    ],
    declarations: [
        RatingStarsComponent,
    ],
    exports: [
        RatingStarsComponent,
    ],
})
export class RatingStarsModule {}

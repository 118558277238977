import { Component, Input, OnChanges } from "@angular/core";
import { ObjectiveStatus, ObjectiveStatusMetadata } from "@common/ADAPT.Common.Model/organisation/objective-status";

@Component({
    selector: "adapt-display-objective-status",
    templateUrl: "./display-objective-status.component.html",
    styleUrls: ["./display-objective-status.component.scss"],
})
export class DisplayObjectiveStatusComponent implements OnChanges {
    @Input() public objectiveStatus!: ObjectiveStatus;
    @Input() public isCompactView = false;
    @Input() public showRightChevronDown = false;
    @Input() public showRightChevronUp = false;

    public objectiveStatusMetadata?: ObjectiveStatusMetadata;

    public ngOnChanges() {
        this.objectiveStatusMetadata = ObjectiveStatusMetadata.ByStatus[this.objectiveStatus];
    }
}

import { defer, EMPTY, MonoTypeOperatorFunction, Observable, of, Subject } from "rxjs";
import { finalize, pairwise, shareReplay, startWith, switchMap, tap } from "rxjs/operators";

class CleanupEmittedValuesInit { }

/**
 * RXJS operator that allows you to perform cleanup on the previous value that was emitted
 * when the next value is emitted. When the source observable is completed, the final value
 * that is emitted can be cleaned up too.
 */
export function cleanupEmittedValues<T>(cleanup: (value: T) => void): MonoTypeOperatorFunction<T> {
    return (source: Observable<T>) => {
        return source.pipe(
            // startWith so pairwise emits on the first actual location
            // Use class instance so we don't conflict with any possible passed in values
            startWith(new CleanupEmittedValuesInit()),
            pairwise(),
            switchMap(([previousValue, currentValue]) => {
                if (!(previousValue instanceof CleanupEmittedValuesInit)) {
                    cleanup(previousValue);
                }

                // instanceof CleanupEmittedValuesInit *shouldn't* be possible, but
                // guard against it to keep TS happy
                return currentValue instanceof CleanupEmittedValuesInit
                    ? EMPTY
                    : of(currentValue);
            }),
            finalizeWithLastValue((location) => cleanup(location)),
        );
    };
}

/**
 * Mimics the behaviour of rxjs/finalize, except it also passes the last value
 * that passed through the observable to the callback so cleanup can be performed
 * with it.
 */
export function finalizeWithLastValue<T>(callback: (value: T) => void): MonoTypeOperatorFunction<T> {
    return (source: Observable<T>) => {
        return defer(() => {
            let lastValue: T;
            return source.pipe(
                tap((v) => lastValue = v),
                finalize(() => callback(lastValue)),
            );
        });
    };
}

export function emptyIfUndefinedOrNull<T>(tapIfUndefinedOrNull?: () => void) {
    return switchMap<T | undefined | null, Observable<T>>((value) => {
        if (typeof value === "undefined" || value === null) {
            if (tapIfUndefinedOrNull) {
                tapIfUndefinedOrNull();
            }

            return EMPTY;
        } else {
            return of(value);
        }
    });
}

/**
 * Returns an observable for the given subject.
 * If return value of `condition` is truthy, observable will start with the return value of `getter`.
 * @param subject subject to make observable.
 * @param condition condition to check before emitting.
 * @param getter value to start observable with.
 */
export function conditionalObservableForSubject<T>(subject: Subject<T>, condition: () => boolean, getter: () => T) {
    return defer(() => {
        if (condition()) {
            return subject.asObservable().pipe(startWith(getter()));
        }
        return subject.asObservable();
    });
}

/** Caches the most recently emitted value, automatically unsubscribing from
 * the source and clearing the cache when all subscribers have unsubscribed.
 * Use this in place of shareReplay(1) in components remove the possibility
 * of any memory leaks. You may not want to use this in services as if there
 * are side effects, then they will not occur when nothing is subscribed to it.
 * To read more see https://blog.strongbrew.io/share-replay-issue/
 */
export function cacheLatest<T>() {
    return shareReplay<T>({
        refCount: true,
        bufferSize: 1,
    });
}

<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.WritingGreatObjectives"
                                          context="dialog"></adapt-context-sidebar-implementation-kit>

<!-- Note: if maxWidth set to more than 950, it won't have enough space to display context sidebar help, causing e2e test to fail -->
<adapt-dialog [maxWidth]="950"
              [fitHeight]="true"
              [padding]="false">
    <h3 adapt-dialog-title>{{isNew ? 'Add' : 'Edit'}} objective</h3>
    <ng-container adapt-dialog-menu
                  *ngIf="isNew">
        <button class="btn btn-primary btn-pill"
                adaptButtonIcon="fal fa-hat-wizard"
                (click)="useObjectiveWizard()"
                data-test="use-objective-wizard-button">Use objective wizard</button>
    </ng-container>
    <div adapt-dialog-content
         class="h-100">
        <div class="row objective-content-container h-100">
            <dx-scroll-view class="mainbar flex-fill">
                <div class="d-flex flex-column me-3">
                    <div class="form-group"
                         data-tour="objective-title-input">
                        <label for="objective">Title</label>
                        <dx-text-box id="objective"
                                     #focus
                                     [adaptValidateEntity]="objective"
                                     adaptValidateEntityProperty="title"
                                     placeholder="Type your objective title here"
                                     (onKeyUp)="validateObjective()"
                                     (valueChange)="objectiveTitleChanged = true"
                                     [(value)]="objective.title"
                                     data-test="objective-title-input"></dx-text-box>
                    </div>

                    <div class="mt-3 form-group"
                         data-tour="objective-description">
                        <label for="description">Description</label>
                        <adapt-html-editor [(contents)]="objective.description"
                                           data-test="objective-description-input"
                                           (isValidChange)="isEditorValid = $event"></adapt-html-editor>
                    </div>
                </div>
            </dx-scroll-view>
            <div class="sidebar">
                <div class="mt-3 form-group"
                     data-tour="objective-type">
                    <legend>Objective type</legend>
                    <div class="d-inline-block"
                         [adaptTooltip]="hasChild ? 'Objective type cannot be changed if it has a supporting objective' : undefined">
                        <div class="form-check form-check-inline"
                             data-tour="annual-radio-input">
                            <input type="radio"
                                   id="annualObjective"
                                   name="ObjectiveType"
                                   [disabled]="hasChild"
                                   [value]="ObjectiveTypeEnum.Annual"
                                   [ngModel]="objective.type"
                                   (ngModelChange)="onObjectiveTypeChanged($event)"
                                   class="form-check-input"
                                   data-test="annual-radio-input">
                            <label class="form-check-label"
                                   for="annualObjective">Annual</label>
                        </div>
                        <div class="form-check form-check-inline"
                             data-tour="quarterly-radio-input">
                            <input type="radio"
                                   id="quarterlyObjective"
                                   name="ObjectiveType"
                                   [disabled]="hasChild"
                                   [value]="ObjectiveTypeEnum.Quarterly"
                                   [ngModel]="objective.type"
                                   (ngModelChange)="onObjectiveTypeChanged($event)"
                                   class="form-check-input"
                                   data-test="quarterly-radio-input">
                            <label class="form-check-label"
                                   for="quarterlyObjective">Quarterly</label>
                        </div>
                    </div>
                </div>

                <div class="form-group"
                     data-tour="objective-owner-dropdown">
                    <label for="owner">Owner</label>
                    <adapt-select-person id="owner"
                                         [person]="objective.assigneePerson"
                                         (personChange)="onPersonChange($event)"
                                         [userTypes]="allowedObjectiveOwnerUserTypes"
                                         [teamGroup]="objective.team"
                                         [required]="true"
                                         [filter]="filterAssignees"
                                         data-test="objective-owner-dropdown"></adapt-select-person>
                </div>

                <div class="mt-3 form-group"
                     data-tour="objective-date">

                    <div class="d-flex flex-column flex-lg-row">
                        <label for="dueDate">Due date</label>
                        <i *ngIf="isAutoDueDate"
                           class="ms-1 fal fa-circle-info align-self-center"
                           [adaptTooltipIsHtml]="true"
                           adaptTooltip="This date is pre-filled as the next <b>{{objective.type}} Strategy</b> meeting"></i>
                    </div>
                    <dx-date-box id="dueDate"
                                 width="100%"
                                 [value]="objective.dueDate"
                                 (valueChange)="dueDateChanged($event)"
                                 (onOpened)="dueDateTouched = true"
                                 data-test="objective-date-dropdown">
                    </dx-date-box>
                </div>

                <div class="mt-3 form-group"
                     data-tour="objective-supporting">
                    <label>Supporting objective</label>
                    <adapt-select-supporting-objective [objective]="objective"></adapt-select-supporting-objective>
                </div>

                <div class="mt-3 form-group">
                    <label for="objectiveLabels">Labels</label>
                    <adapt-select-label-for-related-entity [objective]="objective"
                                                           (entitiesChange)="changedLabelLocations = $event"
                                                           data-test="objective-labels">
                    </adapt-select-label-for-related-entity>
                </div>
            </div>
        </div>
    </div>
    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="!isObjectiveValid || !hasUnsavedEntity || !this.isEditorValid"
                                   [saveBlockingClick]="save"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>

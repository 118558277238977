<div *ngIf="openSurveys.length > 0 && hasChanges"
     class="mt-3 alert alert-warning">
    <span>Disabling {{surveyTypeLabel}} feature for your {{team ? 'team' : 'organisation'}} will result in the following surveys and all
        their corresponding submitted responses to be <b>deleted</b> upon 'Save'</span>

    <div class="mt-3 mb-4"
         *ngFor="let survey of openSurveys">
        <adapt-display-survey [canEdit]="false"
                              [forceLightBackground]="true"
                              [survey]="survey">
        </adapt-display-survey>
    </div>

    <span>Click 'Cancel' if this is not what you want to do.</span>
</div>

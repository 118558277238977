<ng-container *ngIf="withLabel">
    <label>Role in organisation</label>
    <i class="ms-1 fal fa-circle-info"
       [adaptTooltip]="RoleInOrganisationTooltip"
       [adaptTooltipIsHtml]="true"
       tooltipContentClasses="text-start"></i>
</ng-container>
<div class="d-flex w-100 align-items-center">
    <dx-select-box width="100%"
                   [disabled]="disabled"
                   [items]="RoleInOrganisationChoices"
                   displayExpr="text"
                   valueExpr="value"
                   [value]="value"
                   (valueChange)="valueChange.emit($event)"></dx-select-box>
</div>

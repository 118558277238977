import { Injector } from "@angular/core";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { RouteService } from "@common/route/route.service";
import { RouteEventsService } from "@common/route/route-events.service";
import { CommonSidebarConstants } from "@common/shell/common-sidebar/common-sidebar.constants";
import { ISidebarTab, SIDEBAR_TABS, SidebarTabPosition } from "@common/shell/common-sidebar/sidebar-tab";
import { ShellUiService } from "@common/shell/shell-ui.service";
import { SidebarTabIconComponent } from "@common/shell/sidebar-tab-icon/sidebar-tab-icon.component";
import { IComponentRender } from "@common/ux/render-component/component-render.interface";
import { MenuTabId } from "@org-common/lib/shell/menu-tab-content/menu-tab-id";
import { from, Observable, ObservableInput, of, switchMap } from "rxjs";
import { map, startWith, tap } from "rxjs/operators";

export function provideSidebarShortcutTab<TRoute extends IAdaptRoute<any>>(
    tabConfig: Partial<ISidebarTab> & Pick<ISidebarTab, "id" | "label" | "ordinal"> & { shortLabel?: string, isDisplayedFn?: (injector: Injector) => Observable<boolean> },
    iconClass: string,
    getRoute: (injector: Injector) => ObservableInput<TRoute>,
    onClickFn?: (route: TRoute, injector: Injector) => ObservableInput<any>) {
    return {
        provide: SIDEBAR_TABS,
        useFactory: (injector: Injector, routeEventsService: RouteEventsService, routeService: RouteService): ISidebarTab => {
            return {
                ...tabConfig,
                maxWidth: tabConfig.maxWidth ?? CommonSidebarConstants.NavigationTabMaxWidth,
                position: tabConfig.position ?? SidebarTabPosition.Top,
                icon: {
                    component: SidebarTabIconComponent,
                    params: {
                        iconClass,
                        iconText: tabConfig.shortLabel,
                    },
                } as IComponentRender<SidebarTabIconComponent>,
                isDisplayed$: tabConfig.isDisplayed$ ??
                    (tabConfig.isDisplayedFn ? tabConfig.isDisplayedFn(injector) : of(true)),
                isLoading$: tabConfig.isLoading$ ?? of(false),
                focusTab$: new Observable<void>(),
                isActive$: routeEventsService.navigationEnd$.pipe(
                    startWith(undefined),
                    switchMap(() => getRoute(injector)),
                    map((route) => routeService.currentControllerId === route.id),
                ),
                onClick(e: CustomEvent) {
                    e.preventDefault(); // need this preventDefault or the sidebar will toggle closed when click of the button
                    return from(getRoute(injector)).pipe(
                        tap(() => injector.get(ShellUiService).focusTab(MenuTabId)),
                        switchMap((route) => onClickFn
                            ? onClickFn(route, injector)
                            : route.gotoRoute()),
                    );
                },
            };
        },
        deps: [Injector, RouteEventsService, RouteService],
        multi: true,
    };
}

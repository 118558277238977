// TODO Should we map these to entities and properties of entities? Might be a bit easier to
// keep in sync with the data model and would allow us to just pass a entity/property name
// into the call to action component
export class CallToActionText {
    public static valueStream = {
        purpose: "The purpose is the heart of your value stream. The WHY of it's existence, and why it matters. Be sure to make the distinction between WHAT you do and WHY you do it. The purpose is designed to guide your value stream and your decision making.",
        marketDescription: "Who is the market you are targeting? This description could include business type, business to business or business to consumer, industry, size or geography. What are the characteristics of your customers and what do they need?",
        customerStories: "The different customer roles in your customer’s business that will influence the decision to do business with you. Once you have identified the various roles, flesh out what the customer story is for each role. What are the needs unique to that role that you need to satisfy?",
        productsAndServices: "Work through each element that makes up your full offering.",
        pointOfDifference: "What makes you different from your competitors. Identify why your customers choose you and not them.",
        commercialModel: "This is the commercial interaction with your customer. In what way do they pay for the goods and services you offer.",
    };

    public static keyFunctions = "A Key Function is a set of accountabilities and processes carried out within an area of the business.";
    public static keyFunction = {
        purpose: "For this Key Function, why it is important to have these accountabilities and processes in place within your area of business.",
        teams: "The group of people who come together to achieve a common goal.",
    };

    public static guidingPhilosophy = {
        purpose: "Your purpose answers the question of why this business exists. It should talk to your cause, your passion, what you care about.",
        vision: "Your vision is where you see the business heading, but to bring that vision to life you must paint a picture so that everyone is aligned and they can gain the traction to make it happen.",
        foundingStory: "The founding story is the story of how we got here and the influences that the founder brought with them.",
        bullseye: "The Bullseye answers the question 'What do we do?' But more importantly, it answers the question 'What don't we do'?",
    };
}

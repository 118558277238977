import { Injectable, Injector } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { PromiseUtilities } from "@common/lib/utilities/promise-utilities";
import { AbstractGuard } from "@common/route/abstract.guard";
import { GuardFailureType } from "@common/route/route-events.service";
import { OrganisationService } from "../organisation/organisation.service";

@Injectable({
    providedIn: "root",
})
export class OrganisationGuard extends AbstractGuard {
    public static readonly Id = "OrganisationGuard";

    constructor(
        private orgService: OrganisationService,
        injector: Injector,
    ) {
        super(injector);
    }

    public canActivateWithBypassEvent(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, bypassEvent: boolean) {
        return this.wrapActivationResult(
            PromiseUtilities.promiseToValidatePromiseResolution(this.orgService.validateOrganisation()),
            GuardFailureType.OrganisationGuardFailed,
            route,
            state,
            bypassEvent,
        );
    }
}

import { Component, Input } from "@angular/core";
import { Workflow } from "@common/ADAPT.Common.Model/embed/workflow";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { WorkflowConnection } from "@common/ADAPT.Common.Model/organisation/workflow-connection";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ShellUiService } from "@common/shell/shell-ui.service";
import { lastValueFrom, of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { MeetingTabId } from "../../shell/meeting-tab-content/meeting-tab-id";
import { WorkflowService } from "../../workflow/workflow.service";
import { IWorkflowStepOverviewComponent, WorkflowStepOverviewComponent } from "../../workflow/workflow-component-registry";
import { WorkflowMeetingService } from "../../workflow/workflow-meeting.service";
import { MeetingsService } from "../meetings.service";
import { MeetingsUiService } from "../meetings-ui.service";

export const showWorkflowMeetingComponentSelector = "adapt-show-workflow-meeting";

@WorkflowStepOverviewComponent(showWorkflowMeetingComponentSelector)
@Component({
    selector: showWorkflowMeetingComponentSelector,
    templateUrl: "./show-workflow-meeting.component.html",
    styleUrls: ["./show-workflow-meeting.component.scss"],
})
export class ShowWorkflowMeetingComponent implements IWorkflowStepOverviewComponent {
    @Input("workflowConnection") public set setWorkflowConnection(workflowConnection: WorkflowConnection) {
        // call onDataChanged manually if workflowConnection input is set
        // needed when this component is being used outside of a workflow overview
        this.workflowConnection = workflowConnection;
        this.onDataChanged();
    }

    public workflowConnection?: WorkflowConnection;
    public workflow?: Workflow;

    public meeting?: Meeting;
    public nonStartableMeetingInfo?: string;

    public constructor(
        private workflowService: WorkflowService,
        private workflowMeetingService: WorkflowMeetingService,
        private meetingsService: MeetingsService,
        private meetingsUiService: MeetingsUiService,
        private shellUiService: ShellUiService,
    ) {}

    public onDataChanged() {
        if (this.workflowConnection) {
            this.workflow = this.workflowService.getWorkflow(this.workflowConnection);

            this.workflowMeetingService.getMeetingFromWorkflowConnection(this.workflowConnection).pipe(
                tap((meeting) => this.meeting = meeting),
                switchMap((meeting) => meeting
                    ? this.meetingsService.getNonStartableMeetingInfo(meeting)
                    : of(undefined)),
                tap((nonStartableMeetingInfo) => this.nonStartableMeetingInfo = nonStartableMeetingInfo),
            ).subscribe();
        }
    }

    public get isLiveMeeting() {
        return this.meeting?.extensions.isInProgress;
    }

    public get isUpcomingMeeting() {
        return this.meeting?.extensions.isNotStarted;
    }

    public get hasUpcomingOrLiveMeeting() {
        return this.isLiveMeeting || this.isUpcomingMeeting;
    }

    @Autobind
    public async joinMeeting(event: CustomEvent) {
        event.preventDefault();
        if (await lastValueFrom(this.meetingsUiService.isUsingActiveMeetingPage)) {
            this.meetingsService.gotoActiveMeetingPage(this.meeting!).subscribe();
        } else {
            this.shellUiService.focusTab(MeetingTabId);
        }
    }

    @Autobind
    public async runMeeting(event: CustomEvent) {
        event.preventDefault();
        if (this.meeting) {
            const canStartMeeting = await lastValueFrom(this.meetingsService.canStartMeeting(this.meeting));
            if (canStartMeeting) {
                this.meetingsService.saveEntities(this.meeting!.extensions.startMeeting()).pipe(
                    switchMap(() => this.meetingsUiService.moveMeetingToActivePage(this.meeting!)),
                ).subscribe();
            }
        }
    }
}

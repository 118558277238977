import { Component, Injector, OnInit } from "@angular/core";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { AddPeopleDialogComponent } from "../add-people-dialog/add-people-dialog.component";

@Component({
    selector: "adapt-employee-directory-page",
    templateUrl: "./employee-directory-page.component.html",
})
export class EmployeeDirectoryPageComponent extends BaseRoutedComponent implements OnInit {
    public constructor(
        private dialogService: AdaptCommonDialogService,
        injector: Injector,
    ) {
        super(injector);
    }

    public addPeople() {
        this.dialogService.open(AddPeopleDialogComponent).subscribe();
    }

    public ngOnInit() {
        this.notifyActivated();
    }
}

export const employeeDirectoryPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-employee-directory-page", EmployeeDirectoryPageComponent)
    .atOrganisationUrl("/people/directory")
    .withTitle("Employee Directory")
    // .withHelp(StandardIntercomSearchTerm.PersonalProfile)
    .validatesOrganisation()
    .build();


import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Organisation } from "./organisation";
import { Role } from "./role";

export class CulturalLeadership extends BaseEntity<CulturalLeadership> {
    public organisationId!: number;
    public catchupFrequency!: number;
    public primaryName!: string;
    public primaryCollectionName!: string;
    public primaryRoleId!: number;
    public secondaryName!: string;
    public secondaryCollectionName!: string;

    public organisation!: Organisation;
    public primaryRole!: Role;
}

export const CulturalLeadershipBreezeModel = new BreezeModelBuilder("CulturalLeadership", CulturalLeadership)
    .hasSource()
    .isOrganisationEntity()
    .build();

<div class="card">
    <div class="card-header d-flex align-items-center">
        <h2 class="mb-0">Overview</h2>
        <adapt-button-toggle-group class="ms-auto me-3"
                                   [showAsButtonLink]="true"
                                   [value]="currentDisplayType"
                                   (valueChange)="displayTypeChanged($event)">
            <button [adaptToggleGroupButton]="DisplayType.AO"
                    adaptTooltip="Show overview for annual objectives only"
                    class="btn">{{DisplayType.AO}}</button>
            <button [adaptToggleGroupButton]="DisplayType.QO"
                    adaptTooltip="Show overview for quarterly objectives only"
                    class="btn">{{DisplayType.QO}}</button>
            <button [adaptToggleGroupButton]="DisplayType.All"
                    adaptTooltip="Show overview for all objectives"
                    class="btn">{{DisplayType.All}}</button>
        </adapt-button-toggle-group>
    </div>
    <div class="card-body summary-content">
        <adapt-progress-bar [percentageProgress]="overallProgress"
                            [overrideText]="overallProgressText"
                            [scaleHeight]="1.5"></adapt-progress-bar>
        <div class="mt-3 d-flex gap-2">
            <ng-container *ngFor="let statusMetadata of ObjectiveStatusMetadata.All">
                <div *ngIf="showStatus(statusMetadata)"
                     class="count-block"
                     [ngClass]="statusMetadata.statusClass">
                    <span class="number-block">{{statusObjectiveCount[statusMetadata.status]}}</span>
                    <span>{{statusMetadata.name}}</span>
                </div>
            </ng-container>
        </div>
    </div>
</div>

import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { ProcessStep } from "./process-step";

export class ProcessStepSupplementaryData extends BaseEntity<ProcessStepSupplementaryData> {
    public processStepId!: number;
    public description!: string | null;
    public guidanceQuestions!: string | null;

    public processStep!: ProcessStep | null;
}

export const ProcessStepSupplementaryDataBreezeModel = new BreezeModelBuilder("ProcessStepSupplementaryData", ProcessStepSupplementaryData)
    .hasSource()
    .isOrganisationEntity()
    .withIdField()
    .build();

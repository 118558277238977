<span *ngIf="!surveySent">Launching health check...</span>
<ng-container *adaptLoading="!surveySent">
    <div class="w-100 d-flex flex-column align-items-center">
        <ng-container *ngIf="!surveyCompleted; else showSurveyCompleted">
            <h2>Your survey invitations have been sent!</h2>
            <adapt-show-survey-progress [workflowConnection]="workflowConnection"
                                        class="my-3"
                                        [workflowCard]="false"></adapt-show-survey-progress>
            <p class="mt-5 alert alert-info">
                This pathway is currently pending the completion of the survey by all members of your leadership team.
                When all responses to the survey have been received, you will then be able to schedule a meeting to review the results.
            </p>
        </ng-container>
        <ng-template #showSurveyCompleted>
            <h2>Your health check has been completed. Well done!</h2>
            <p class="mt-5">
                <span *ngIf="orgDiagSummaryLink">The results can be viewed <a [routerLink]="orgDiagSummaryLink.path"
                       [queryParams]="orgDiagSummaryLink.queryParams">here</a>.</span>
            </p>
            <p>Let's move on to the next step and schedule a meeting with your team to review the results.</p>
        </ng-template>
    </div>
</ng-container>

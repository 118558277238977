<ng-container *adaptLoadingUntilFirstEmit="let team of team$">

    <div adaptToolbarContent>
        <a *adaptIfAuthorised="ConfigureTeam;Entity:team"
           [routerLink]="configPeopleUrl?.path"
           adaptButton="configureLink"
           adaptTooltip="Go to team configuration"></a>
    </div>

    <div class="dashboard px-0 px-md-3">
        <adapt-styled-dashboard-element name="{{team.name}} team members"
                                    linkText="Add another person to the team"
                                    [url]="configPeopleUrl"
                                    [contentCard]="true">
            <adapt-team-membership [team]="team"></adapt-team-membership>
        </adapt-styled-dashboard-element>

        <div class="split">
            <adapt-team-guidance-dashboard-element *adaptIfAuthorised="PurposeVisionRead;Entity:team"
                                                   [team]="team"
                                                   dashboardTitle="Team guidance"
                                                   data-test="purpose-dashboard-element"></adapt-team-guidance-dashboard-element>
            <adapt-team-meeting-guidance-dashboard-element *adaptIfAuthorised="viewTeamMeetings;Entity:team"
                                                           [team]="team"></adapt-team-meeting-guidance-dashboard-element>
        </div>

        <adapt-workflows-dashboard-element [team]="team"></adapt-workflows-dashboard-element>
    </div>
</ng-container>

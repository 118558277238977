import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { TeamMeetingsPageComponent } from "./team-meetings-page.component";

export const teamMeetingsPageRoute = new OrganisationPageRouteBuilder<{ teamId: number }>()
    .usingNgComponent("adapt-team-meetings-page", TeamMeetingsPageComponent)
    .atOrganisationUrl("/team/:teamId/team-meetings")
    .verifyingFeatures(FeatureName.StewardshipWorkMeetings)
    .withTitle("Meetings")
    .withSearchKeyword("Ongoing Meetings")
    .withSearchKeyword("Upcoming Meetings")
    .withSearchKeyword("Past Meetings")
    .reloadOnSearch(false)
    .build();

<ng-container *adaptLoading="!isInitialised">
    <div class="outer-quarter-circle"
         [ngClass]="cornerClass"></div>
    <div class="quarter-circle"
         [ngClass]="cornerClass">
        <div class="good-at"
             [ngClass]="cornerClass">
        </div>
        <div class="bullseye-part"
             [ngClass]="bullseyeClasses">
            <div class="bullseye-target-dot"
                 [ngClass]="cornerClass"></div>
        </div>
    </div>
    <svg xmlns="http://www.w3.org/2000/svg"
         class="overlay-svg"
         *ngIf="arcPath">
        <!-- <path id="arc"
              d="M300,0 A300,300 0 0,0 0,300"></path> -->
        <path [attr.id]="arcId"
              class="quadrant-arc"
              [attr.d]="arcPath"></path>
        <text class="quadrant-text">
            <textPath [attr.xlink:href]="arcIdRef"
                      [attr.startOffset]="startOffset">{{quadrantText}}</textPath>
        </text>
    </svg>
    <div class="text-container-outer"
         *ngIf="!hideOuter">
        <div class="statement-text"
             [ngClass]="{'statement-text-in-edit': isEditing || isSelecting, 'statement-selected': isStatementSelected(statement)}"
             *ngFor="let statement of statements"
             (click)="onStatementClicked(statement)"
             [adaptTooltip]="statement.statement">
            {{statement.statement}}
        </div>
    </div>
    <div class="text-container-inner">
        <div class="statement-text-in-bullseye"
             [ngClass]="{'statement-text-in-edit': isEditing || isSelecting, 'statement-selected': isStatementSelected(statement)}"
             *ngFor="let statement of inBullseyeStatements"
             (click)="onStatementClicked(statement)"
             [adaptTooltip]="statement.statement">
            {{statement.statement}}
        </div>
    </div>
</ng-container>

import { DefaultBackFinishButtons, DefaultBackNextButtons, DefaultNextButtons, GuidedTour } from "@common/lib/guided-tour/guided-tour.service";
import { CommonTourSteps } from "./common-tour-steps";

export const SetValuesWrapUpTour: GuidedTour = {
    steps: [CommonTourSteps.openOrgMenuToShowSidebarNavNodeStep("Values", "Values"), {
        waitForAndAttachToElementSelector: "adapt-display-link-hierarchy adapt-display-navigation-node span",
        elementSelectorTextContent: "Values", // looking for <adapt-display-navigation-node> with span content of this text
        advanceOnAttachedElementEvent: "click",
        attachTo: {
            on: "bottom-end",
        },
        title: `Access your values`,
        text: `<p>Your organisation's values are available here.</p>
        <p><b>Click on it now</b> to show it.</p>`,
        modalOverlayOpeningPadding: 3,
    }, {
        title: "Your values",
        text: "Here are the values you have just entered.",
        canClickTarget: false,
        waitForAndAttachToElementSelector: `[data-tour="values-card"]`,
        attachTo: {
            on: "bottom",
        },
        buttons: DefaultNextButtons,
    }, {
        title: "Edit values",
        text: "In the future, you can edit your values using this option.",
        canClickTarget: false,
        waitForAndAttachToElementSelector: `[data-tour="values-edit"]`,
        attachTo: {
            on: "bottom",
        },
        buttons: DefaultBackNextButtons,
    }, {
        title: "Continue",
        text: `<p>You can continue your journey by going to your <b>Personal Dashboard</b>.</p>`,
        waitForAndAttachToElementSelector: `[id="sidebar-personal"]`,
        attachTo: {
            on: "right",
        },
        buttons: DefaultBackFinishButtons,
    }],
};

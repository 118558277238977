import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";

export const featurePermissionTranslations: { [featurePermission in FeaturePermissionName]: string } = {
    [FeaturePermissionName.CulturalNetworkCulturalLeadershipCohortRead]: "View who is in which cultural group",
    [FeaturePermissionName.CulturalNetworkCulturalLeadershipCohortConfigure]: "Configure who is in which cultural group",
    [FeaturePermissionName.CulturalNetworkCulturalIndexPersonalUseAndRead]: "Enable and view their own cultural index",
    [FeaturePermissionName.CulturalNetworkCulturalIndexPersonalEdit]: "Edit their own cultural index",
    [FeaturePermissionName.CulturalNetworkCulturalIndexRead]: "View and edit cultural index information for everyone who has it enabled",
    [FeaturePermissionName.CulturalNetworkCulturalIndexAnonymousRead]: "View anonymous cultural index information for everyone",
    [FeaturePermissionName.CulturalNetworkCulturalIndexQuantitativeRead]: "View quantitative cultural index information for everyone",
    [FeaturePermissionName.CulturalNetworkCareerValuationPersonalUseAndRead]: "Enable and view their own career valuation",
    [FeaturePermissionName.CulturalNetworkCareerValuationPersonalEdit]: "Edit their own career valuation",
    [FeaturePermissionName.CulturalNetworkCareerValuationRead]: "View and edit career valuation information for everyone who has it enabled",
    [FeaturePermissionName.CulturalNetworkCareerValuationAnonymousRead]: "View anonymous career valuation information for everyone",
    [FeaturePermissionName.CulturalNetworkCareerValuationQuantitativeRead]: "View quantitative career valuation information for everyone",
    [FeaturePermissionName.CulturalNetworkEmployeeEngagementRead]: "View employee engagement survey results for the organisation",
    [FeaturePermissionName.CulturalNetworkEmployeeEngagementEdit]: "Initiate employee engagement surveys for the organisation",
    [FeaturePermissionName.CulturalNetworkTeamAssessmentRead]: "View team assessment survey results (excluding private teams)",
    [FeaturePermissionName.CulturalNetworkTeamAssessmentEdit]: "Initiate team assessment surveys (excluding private teams)",
    [FeaturePermissionName.CulturalNetworkResilientBusinessAssessmentRead]: "View resilient business assessment survey results for the organisation",
    [FeaturePermissionName.CulturalNetworkResilientBusinessAssessmentEdit]: "Initiate resilient business assessment surveys for the organisation",
    [FeaturePermissionName.OrganisationDiagnosticRead]: "View health check results for the organisation",
    [FeaturePermissionName.OrganisationDiagnosticEdit]: "Initiate health check surveys for the organisation",

    [FeaturePermissionName.PeoplePeerCatchUpPersonalUseAndRead]: "Enable and view their own Peer Catch-ups",
    [FeaturePermissionName.PeoplePeerCatchUpPersonalEdit]: "Edit their own Peer Catch-ups",
    [FeaturePermissionName.PeoplePeerCatchUpRead]: "View all Peer Catch-ups (excluding private teams)",
    [FeaturePermissionName.PeoplePeerCatchUpEdit]: "Edit Peer Catch-ups for everyone else",
    [FeaturePermissionName.PeoplePeerCatchUpAnonymousRead]: "View anonymised Peer Catch-up data such as organisation statistics",

    [FeaturePermissionName.PeopleProfilePersonalUseAndRead]: "Enable and view their own profile data",
    [FeaturePermissionName.PeopleProfilePersonalEdit]: "Edit their own profile data",
    [FeaturePermissionName.PeopleProfilePublicRead]: "View public profile data",
    [FeaturePermissionName.PeopleProfileRead]: "View all public and private profile data",
    [FeaturePermissionName.PeopleProfileEdit]: "Edit all public and private profile data",
    [FeaturePermissionName.PeoplePositionPersonalEdit]: "Edit their own position name",
    [FeaturePermissionName.PeoplePositionEdit]: "Edit all positions and roles",

    [FeaturePermissionName.CompassGuidingPhilosophyRead]: "View purpose and vision of the organisation and view team guidance (excluding private teams)",
    [FeaturePermissionName.CompassGuidingPhilosophyEdit]: "Edit purpose and vision of the organisation and edit team guidance (excluding private teams)",
    [FeaturePermissionName.CompassValuesConstitutionRead]: "View the values for the organisation and all teams (excluding private teams)",
    [FeaturePermissionName.CompassValuesConstitutionEdit]: "Edit the values for the organisation and all teams (excluding private teams)",
    [FeaturePermissionName.CompassResilientBusinessGoalsRead]: "View the resilient business goals for the organisation",
    [FeaturePermissionName.CompassResilientBusinessGoalsEdit]: "Edit the resilient business goals for the organisation",

    [FeaturePermissionName.StewardshipWorkKanbanPersonalEdit]: "Enable personal actions",
    [FeaturePermissionName.StewardshipWorkKanbanRead]: "View all team actions (excluding private teams)",
    [FeaturePermissionName.StewardshipWorkKanbanEdit]: "Edit all team actions (excluding private teams)",
    [FeaturePermissionName.StewardshipWorkKanbanPublicRead]: "View all publicly accessible team actions",
    [FeaturePermissionName.StewardshipWorkKanbanPublicEdit]: "Edit all publicly accessible team actions",
    [FeaturePermissionName.StewardshipWorkMeetingsRead]: "View all team meetings (excluding private teams)",
    [FeaturePermissionName.StewardshipWorkMeetingsEdit]: "Edit all team meetings (excluding private teams)",
    [FeaturePermissionName.StewardshipWorkMeetingsParticipantEdit]: "View and edit participated meeting notes and actions",
    [FeaturePermissionName.StewardshipObjectivesRead]: "View all organisation and team objectives (excluding private teams)",
    [FeaturePermissionName.StewardshipObjectivesEdit]: "Edit all organisation and team objectives (excluding private teams)",
    [FeaturePermissionName.StewardshipObjectivesPublicRead]: "View organisation objectives only",
    [FeaturePermissionName.StewardshipObjectivesTeamRead]: "View team objectives that are public",

    [FeaturePermissionName.OrganisationTeamRead]: "Able to access private team if you are a member of it",
    [FeaturePermissionName.OrganisationTeamConfigure]: "Configure features for any team in the organisation",
    [FeaturePermissionName.OrganisationConfigure]: "Configure organisation features",
    [FeaturePermissionName.OrganisationAccessManagementConfigure]: "Configure access for the organisation, including people and roles",
    [FeaturePermissionName.OrganisationBillingConfigure]: "Configure account and billing details for the organisation",
    [FeaturePermissionName.OrganisationExternalDashboardPublicRead]: "View organisation and only publicly accessible team external links",
    [FeaturePermissionName.OrganisationExternalDashboardRead]: "View organisation and all team external links (excluding private teams)",
    [FeaturePermissionName.ArchitectureValueStreamContentsRead]: "View the contents of the value streams within the organisation",
    [FeaturePermissionName.ArchitectureValueStreamContentsEdit]: "Edit the contents of the value streams within the organisation",
    [FeaturePermissionName.ArchitectureTier2PersonalEdit]: "Edit the contents of role which is assigned to the person",

    [FeaturePermissionName.PlatformStandardRead]: "Standard permissions every person needs in order to log in.",
    [FeaturePermissionName.PlatformWorkflowEdit]: "Run pathways within the organisation",

    [FeaturePermissionName.ArchitectureTier1Read]: "View value streams and key functions for the organisation",
    [FeaturePermissionName.ArchitectureTier1Edit]: "Edit value streams and key functions for the organisation",
    [FeaturePermissionName.ArchitectureTier2Read]: "View all systems, roles & guidance materials for the organisation",
    [FeaturePermissionName.ArchitectureTier2Edit]: "Edit all systems, roles & guidance materials for the organisation",
    [FeaturePermissionName.ArchitectureTier2Configure]: "Create and assign systems within the organisation",

    [FeaturePermissionName.StakeholdersPeopleRead]: "View stakeholders for the organisation",

    [FeaturePermissionName.StrategyBullseyeRead]: "View the bullseye for the organisation",
    [FeaturePermissionName.StrategyBullseyeEdit]: "Edit the bullseye for the organisation",
    [FeaturePermissionName.StrategyBoardRead]: "View the strategic working board and themes for the organisation",
    [FeaturePermissionName.StrategyBoardEdit]: "Edit the strategic working board and themes for the organisation",
    [FeaturePermissionName.StrategicInputsRead]: "View the strategic inputs for the organisation",
    [FeaturePermissionName.StrategicInputsEdit]: "Edit the strategic inputs for the organisation",
    [FeaturePermissionName.StrategicGoalsRead]: "View the strategic goals for the organisation",
    [FeaturePermissionName.StrategicGoalsEdit]: "Edit the strategic goals for the organisation",
    [FeaturePermissionName.StrategicAnchorsRead]: "View the strategic anchors for the organisation",
    [FeaturePermissionName.StrategicAnchorsEdit]: "Edit the strategic anchors for the organisation",
};

export const featurePermissionTeamTranslations: { [featurePermission in FeaturePermissionName]: string } = {
    ...featurePermissionTranslations,

    [FeaturePermissionName.CulturalNetworkTeamAssessmentRead]: "View team assessment survey results for the team",
    [FeaturePermissionName.CulturalNetworkTeamAssessmentEdit]: "Initiate team assessment surveys for the team",

    [FeaturePermissionName.ArchitectureTier2Read]: "View all systems, roles & guidance materials for the team",
    [FeaturePermissionName.ArchitectureTier2Edit]: "Edit all systems, roles & guidance materials for the team",
    [FeaturePermissionName.ArchitectureTier2Configure]: "Create and assign systems within the team",

    [FeaturePermissionName.PeoplePeerCatchUpRead]: "View all Peer Catch-ups within the team",
    [FeaturePermissionName.PeoplePeerCatchUpEdit]: "Edit their own Peer Catch-ups within the team",

    [FeaturePermissionName.CompassGuidingPhilosophyRead]: "View guidance for the team",
    [FeaturePermissionName.CompassGuidingPhilosophyEdit]: "Edit guidance for the team",
    [FeaturePermissionName.CompassValuesConstitutionRead]: "View the values for the team",
    [FeaturePermissionName.CompassValuesConstitutionEdit]: "Edit the values for the team",

    [FeaturePermissionName.StewardshipWorkKanbanRead]: "View all actions for the team",
    [FeaturePermissionName.StewardshipWorkKanbanEdit]: "Edit all actions for the team",
    [FeaturePermissionName.StewardshipWorkMeetingsRead]: "View all meetings for the team",
    [FeaturePermissionName.StewardshipWorkMeetingsEdit]: "Edit all meetings for the team",
    [FeaturePermissionName.StewardshipWorkMeetingsParticipantEdit]: "View and edit participated meeting notes and actions for the team",
    [FeaturePermissionName.StewardshipObjectivesRead]: "View all objectives for the team",
    [FeaturePermissionName.StewardshipObjectivesEdit]: "Edit all objectives for the team",

    [FeaturePermissionName.OrganisationTeamConfigure]: "Configure features for the team",
    [FeaturePermissionName.OrganisationExternalDashboardRead]: "View external links, regardless of privacy settings, for the team",
};

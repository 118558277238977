import { BaseEntity } from "@common/ADAPT.Common.Model/base-entity";
import { BreezeModelBuilder } from "@common/ADAPT.Common.Model/breeze-model-builder";
import { Survey } from "./survey";

export class SurveySupplementaryData extends BaseEntity<SurveySupplementaryData> {
    public surveyId!: number;
    public message?: string;
    public respondents?: string;
    public nonrespondents?: string;

    public survey!: Survey;
}

export const SurveySupplementaryDataBreezeModel = new BreezeModelBuilder("SurveySupplementaryData", SurveySupplementaryData)
    .withPluralName("SurveySupplementaryData")
    .hasSource("SurveySupplementaryData")
    .withIdField("surveyId")
    .isOrganisationEntity()
    .build();

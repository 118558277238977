<!-- todo this article id is for health check only!  make this generic some time! -->
<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.HealthCheckFeatureHelp"
                                          context="page"
                                          [fullScreen]="true"></adapt-context-sidebar-implementation-kit>

<adapt-toolbar-content alignment="right">
    <button role="button"
            class="btn btn-primary"
            adaptButtonIcon="fal fa-fw fa-clock-rotate-left"
            (click)="gotoHistory()">Previous results</button>
    <button role="button"
            class="btn btn-primary"
            adaptButtonIcon="fal fa-fw fa-search"
            (click)="gotoAnalyseSurveyPage()">Analyse results</button>
    <button adaptButton="tour"
            data-tour="health-check-tour"
            (click)="runTour()"></button>
</adapt-toolbar-content>
<adapt-toolbar-content alignment="left"
                       *ngIf="categoryIdSelected">
    <button role="button"
            class="btn btn-primary"
            (click)="unselectCategory()"><i class="fal fa-arrow-turn-down-left me-1"></i>Return to organisation highlights</button>
</adapt-toolbar-content>
<ng-container *adaptLoading="(!survey || !surveyQuestions) && !isInitialised">
    <ng-container *ngIf="survey; else noSurvey">
        <adapt-display-categories-summary class="half-page"
                                          [survey]="survey"
                                          [surveyQuestions]="surveyQuestions"
                                          [selectedCategoryId]="categoryIdSelected"
                                          (categoryIdClicked)="onCategoryIdChanged($event)"></adapt-display-categories-summary>
        <adapt-category-summary [survey]="survey"
                                [surveyQuestions]="surveyQuestions"
                                [categoryId]="categoryIdSelected"
                                (categoryIdChange)="onCategoryIdChanged($event)"></adapt-category-summary>
    </ng-container>
    <ng-template #noSurvey>
        <div class="alert alert-info">There is currently no completed survey</div>
    </ng-template>
</ng-container>

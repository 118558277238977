import { Component, OnInit } from "@angular/core";
import { IAdaptLinkObject } from "@common/route/route.service";
import { teamMeetingsPageRoute } from "@org-common/lib/meetings/team-meetings-page/team-meetings-page.route";
import { TeamMeetingsWithCadencePageRoute } from "@org-common/lib/meetings/team-meetings-with-cadence-page/team-meetings-with-cadence-page.component";
import { SearchRowBaseComponent } from "@org-common/lib/search/search-row-base.component";
import { EMPTY, Observable } from "rxjs";
import { IMeetingSearchResult } from "../search-results.interface";

@Component({
    selector: "adapt-search-row-meeting",
    templateUrl: "./search-row-meeting.component.html",
    styleUrls: ["./search-row-meeting.component.scss"],
})
export class SearchRowMeetingComponent extends SearchRowBaseComponent<IMeetingSearchResult> implements OnInit {
    public href$?: Observable<IAdaptLinkObject> = EMPTY;

    public ngOnInit() {
        super.ngOnInit();

        const route = this.isAlto
            ? TeamMeetingsWithCadencePageRoute 
            : teamMeetingsPageRoute;
        this.href$ = route.getRouteObject({ teamId: this.result.teamId }, { meetingId: this.result.meetingId });
        this.matches = this.withoutLastBreadcrumb(this.excludeNameBreadcrumb(this.result.results, true));
    }
}

<adapt-dialog [maxWidth]="800"
              [minWidth]="480">
    <h3 adapt-dialog-title>Outstanding responses for survey: {{survey.name}}</h3>

    <div adapt-dialog-content>
        <adapt-view-survey-outstanding-responses [survey]="survey"></adapt-view-survey-outstanding-responses>
    </div>

    <div adapt-dialog-footer
         class="ms-auto">
        <button #focus
                adaptButton="close"
                (click)="cancel()"></button>
    </div>
</adapt-dialog>

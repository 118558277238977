/** Mirrors server side EntityUpdates.cs */
export interface IEntityUpdates {
    ChangedByPersonId: number;
    Updates: IEntityUpdate[];
}

/** Mirrors server side EntityUpdate.cs */
export interface IEntityUpdate {
    UpdateType: EntityUpdateType;
    EntityType: string;
    EntityId: number;
    /** The fields on the entity which have been updated. Will be in PascalCase */
    ModifiedProperties: string[];
    RelatedEntities: { EntityType: string, EntityId: number }[];
}

/** Mirrors server side EntityUpdateType.cs */
export enum EntityUpdateType {
    Added = 0,
    Modified = 1,
    Deleted = 2,
}

import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Organisation } from "./organisation";
import { Team } from "./team";

export class ExternalDashboard extends BaseEntity<ExternalDashboard> {
    public externalDashboardId!: number;
    public organisationId!: number;
    public teamId?: number;
    public name!: string;
    public url!: string;
    public isPublicRead!: boolean;
    public ordinal!: number;
    public openInNewTab!: boolean;

    public organisation!: Organisation;
    public team!: Team;
}

export const ExternalDashboardBreezeModel = new BreezeModelBuilder("ExternalDashboard", ExternalDashboard)
    .hasSource()
    .withIdField()
    .isOrganisationEntity()
    .build();

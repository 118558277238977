import { Component, Inject } from "@angular/core";
import { Survey } from "@common/ADAPT.Common.Model/organisation/survey";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";

export interface IViewSurveyOutstandingResponsesData {
    survey: Survey;
    showEndTime?: boolean;
}

@Component({
    selector: "adapt-view-survey-outstanding-responses-dialog",
    templateUrl: "./view-survey-outstanding-responses-dialog.component.html",
})
export class ViewSurveyOutstandingResponsesDialogComponent extends BaseDialogComponent<IViewSurveyOutstandingResponsesData, Survey> {
    public readonly dialogName = "ViewSurveyOutstandingResponses";
    public survey: Survey;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) data: IViewSurveyOutstandingResponsesData,
    ) {
        super();

        this.survey = data.survey;
    }
}

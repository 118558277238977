<!-- DYNAMIC ENZUZO FORM - SO SLOW & UGLY -->
<!-- <adapt-loading-spinner *ngIf="!loaded"
                       [largeSpinner]="true"></adapt-loading-spinner>

 <iframe src="https://app.enzuzo.com/policies/eula/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJDdXN0b21lcklEIjoyMzEzNCwiQ3VzdG9tZXJOYW1lIjoiY3VzdC1QN3YyWmd3RSIsIkN1c3RvbWVyTG9nb1VSTCI6IiIsIlJvbGVzIjpbInJlZmVycmFsIl0sIlByb2R1Y3QiOiJlbnRlcnByaXNlIiwiVmVyc2lvbiI6MCwiaXNzIjoiRW56dXpvIEluYy4iLCJuYmYiOjE2OTY5ODg0OTB9.dzvyYBl3XvwNwTdr_adrP1jyWmITZVKB1Vs-cbAbw60"
        frameborder="0"
        class="w-100 h-100"
        [ngClass]="{'d-none' : !loaded}"
        style="min-height: 500px"
        (load)="onLoaded()"></iframe> -->

<!--
   *** STATIC ENZUZO - COPIED FROM WEBSITE ***

   Changes made:
   * remove all styling
   * remove the `h1` in the main element - its in our dialog box, so why repeat it?
   * remove the `margin-top` in the main element
   * remove `open=""` as this causes the tag to be open by default
   * remove the `Powered by Enzuzo` as why are we advertising for them?
   * wrap the 'last updated' at the bottom with `<p>` and `mt-3 mb-0` classes
   -->

<main ez-policy=""
      ez-policy-mode="eula">
    <div>
    </div>
    <details ez-acc-details="">
        <summary ez-acc-title="">
            <span ez-m="LABEL_EULA_HTML_HEADER_INTRODUCTION">1. Introduction</span>
            <div>
            </div>
        </summary>
        <p>This End User License Agreement (the &quot;Agreement&quot;) is a binding agreement between you (&quot;User&quot; or
            &quot;you&quot;) and <span ez-m="LABEL_EULA_HTML_BODY_INTRODUCTION_INTRO_COMPANY">
                <span ez-m="LABEL_EULA_HTML_BODY_INTRODUCTION_INTRO_COMPANY_NAME">Adapt By Design Pty Ltd</span>
            </span>
            <span ez-m="LABEL_EULA_HTML_BODY_INTRODUCTION_INTRO_ALT_BUSINESS">, d/b/a Adapt</span> (&quot;Company&quot;). This Agreement
            governs your use of the <span ez-m="LABEL_EULA_HTML_BODY_INTRODUCTION_INTRO_APP_NAME">
                undefined
            </span> mobile application for <span ez-m="LABEL_EULA_HTML_BODY_INTRODUCTION_INTRO_MOBILE">Apple iOS or Google Android operating
                systems or Facebook</span> (including all related documentation, the &quot;Application&quot;). Company reserves the right to
            change or modify any of the terms and conditions contained in the Agreement without notice and all such revisions are effective
            immediately upon posting and apply to all access to and continued use of the Application.
        </p>
        <p>
            <span ez-m="LABEL_EULA_HTML_BODY_INTRODUCTION_INSTALLATION">BY DOWNLOADING/INSTALLING/USING THE APPLICATION, YOU:</span>
        </p>
        <ul style="list-style:none !important">
            <li>
                <span ez-m="LABEL_EULA_HTML_BODY_INTRODUCTION_INSTALLATION_A">(A) ACCEPT AND AGREE TO BE BOUND AND COMPLY WITH THIS
                    AGREEMENT;</span>
            </li>
            <li>
                <span ez-m="LABEL_EULA_HTML_BODY_INTRODUCTION_INSTALLATION_B">(B) YOU REPRESENT AND WARRANT THAT YOU ARE THE LEGAL AGE OF
                    MAJORITY UNDER APPLICABLE LAW TO FORM A BINDING CONTRACT WITH US; AND,</span>
            </li>
            <li>
                <span ez-m="LABEL_EULA_HTML_BODY_INTRODUCTION_INSTALLATION_C">(C) YOU AGREE IF YOU ACCESS THE APPLICATION FROM A
                    JURISDICTION WHERE IT IS NOT PERMITTED, YOU DO SO AT YOUR OWN RISK. IF YOU DO NOT AGREE TO THESE TERMS, DO NOT
                    DOWNLOAD/INSTALL/USE THE APPLICATION.</span>
            </li>
        </ul>
    </details>
    <details ez-acc-details="">
        <summary ez-acc-title="">
            <span ez-m="LABEL_EULA_HTML_HEADER_LICENSE">2. License</span>
            <div>
            </div>
        </summary>
        <p>
            <span ez-m="LABEL_EULA_HTML_BODY_LICENSE_AGREEMENT_P1">Subject to the terms of this Agreement, Company grants you a limited,
                non-exclusive, revocable, non-transferable license to download, install, and use the Application for your personal,
                non-commercial use on a single mobile device owned or otherwise controlled by you (&quot;Mobile Device&quot;) strictly in
                accordance with the Application&#x27;s documentation.</span>
        </p>
        <p>
            <span ez-m="LABEL_EULA_HTML_BODY_LICENSE_AGREEMENT_P2">You shall not:</span>
        </p>
        <ul style="list-style:none !important">
            <li>
                <span ez-m="LABEL_EULA_HTML_BODY_LICENSE_AGREEMENT_P2A">(a) copy the Application, except as expressly permitted by this
                    Agreement;</span>
            </li>
            <li>
                <span ez-m="LABEL_EULA_HTML_BODY_LICENSE_AGREEMENT_P2B">(b) modify, translate, adapt or otherwise create derivative works or
                    improvements, whether or not patentable, of the Application;</span>
            </li>
            <li>
                <span ez-m="LABEL_EULA_HTML_BODY_LICENSE_AGREEMENT_P2C">(c) reverse engineer, disassemble, integrate, modify, remove,
                    decompile, decode, create derivates works or updates of, adapt, or otherwise attempt to derive or gain access to the
                    source code of the Application or any part thereof;</span>
            </li>
            <li>
                <span ez-m="LABEL_EULA_HTML_BODY_LICENSE_AGREEMENT_P2D">(d) remove, delete, alter or obscure any trademarks or any
                    copyright, trademark, patent or other intellectual property or proprietary rights notices from the Application,
                    including any copy thereof;</span>
            </li>
            <li>
                <span ez-m="LABEL_EULA_HTML_BODY_LICENSE_AGREEMENT_P2E">(e) rent, lease, lend, sell, sublicense, assign, distribute,
                    publish, transfer or otherwise make available the Application or any features or functionality of the Application, to
                    any third party for any reason, including by making the Application available on a network where it is capable of being
                    accessed by more than one device at any time; or</span>
            </li>
            <li>
                <span ez-m="LABEL_EULA_HTML_BODY_LICENSE_AGREEMENT_P2F">(f) remove, disable, circumvent or otherwise create or implement any
                    workaround to any copy protection, rights management or security features in or protecting the Application.</span>
            </li>
        </ul>
        <p>Nothing in this Agreement should be interpreted to restrict third-party terms, including <span
                  ez-m="LABEL_EULA_HTML_BODY_LICENSE_AGREEMENT_P3">Apple Media Services Terms and Conditions (link:
                https://www.apple.com/legal/internet-services/itunes/us/terms.html) and/or Google Play Terms of Service (link:
                https://play.google.com/intl/en_US/about/play-terms/)</span>. You must ensure that you comply with applicable third-party
            terms and conditions.</p>
        <p>
            <span ez-m="LABEL_EULA_HTML_BODY_LICENSE_AGREEMENT_P4">You acknowledge and agree that the Application is provided under license,
                and not sold, to you. You do not acquire any ownership interest in the Application under this Agreement, or any other rights
                thereto other than to use the Application in accordance with the license granted, and subject to all terms, conditions and
                restrictions, under this Agreement. Company and its licensors and service providers reserve and shall retain their entire
                right, title and interest in and to the Application, including all copyrights, trademarks and other intellectual property
                rights therein or relating thereto, except as expressly granted to you in this Agreement.</span>
        </p>
    </details>
    <details ez-acc-details="">
        <summary ez-acc-title="">
            <span ez-m="LABEL_EULA_HTML_HEADER_USER_CONTENT">3. User Content</span>
            <div>
            </div>
        </summary>
        <p>
            <span ez-m="LABEL_EULA_HTML_BODY_USER_CONTENT_P1">The Application may provide you with the opportunity to create, submit, post,
                display, transmit, publish, distribute, or broadcast content and materials to us or in the Application, including but not
                limited to text, writings, video, audio, photographs, graphics, comments, feedback, or personal information or other
                material (collectively, &quot;Content&quot;). You are responsible for your use of the Application and for any Content you
                provide, including compliance with applicable laws, rules, and regulations.</span>
        </p>
        <p>
            <span ez-m="LABEL_EULA_HTML_BODY_USER_CONTENT_P2">By submitting, posting or displaying Content on or through the Application,
                you grant us a worldwide, non-exclusive, royalty-free license to use, copy, reproduce, process, disclose, adapt, modify,
                publish, transmit, display and distribute such Content for any purpose, commercial advertising, or otherwise, and to prepare
                derivative works of, or incorporate in other works, such as Content, and grant and authorize sublicenses of the foregoing.
                The use and distribution may occur in any media formats and through any media channels.</span>
        </p>
        <p>
            <span ez-m="LABEL_EULA_HTML_BODY_USER_CONTENT_P3">We do not assert any ownership over your Content. You retain full ownership of
                all of your Content and any intellectual property rights or other proprietary rights associated with your Content. We are
                not liable for any statements or representations in your Content provided by you in any area in the Application. You are
                solely responsible for your Content related to the Application and you expressly agree to exonerate us from any and all
                responsibility and to refrain from any legal action against us regarding your Content.</span>
        </p>
        <p>
            <span ez-m="LABEL_EULA_HTML_BODY_USER_CONTENT_P4">We have the right, in our sole and absolute discretion, (1) to edit, redact,
                or otherwise change any Content; (2) to recategorize any Content to place them in more appropriate locations in the
                Application; and (3) to prescreen or delete any Content at any time and for any reason, without notice. We have no
                obligation to monitor your Content. Any use of the Application in violation of this Agreement may result in, among other
                things, termination or suspension of your rights to use the Application.</span>
        </p>
    </details>
    <details ez-acc-details="">
        <summary ez-acc-title="">
            <span ez-m="LABEL_EULA_HTML_HEADER_USER_INFO">4. Collection and Use of Your Information</span>
            <div>
            </div>
        </summary>
        <p>You acknowledge that when you download, install or use the Application, Company may use automatic means (including, for example,
            cookies and web beacons) to collect information about your Mobile Device and about your use of the Application. You also may be
            required to provide certain information about yourself as a condition to downloading, installing or using the Application or
            certain of its features or functionality, and the Application may provide you with opportunities to share information about
            yourself with others. All information we collect through or in connection with this Application is subject to our <a
               href="/privacy-policy"
               ez-m="LABEL_EULA_HTML_BODY_USER_INFO_P1">Privacy Policy</a>. By downloading, installing, using and providing information to
            or through this Application, you consent to all actions taken by us with respect to your information in compliance with the
            Privacy Policy.</p>
    </details>
    <details ez-acc-details="">
        <summary ez-acc-title="">
            <span ez-m="LABEL_EULA_HTML_HEADER_UPDATES">5. Updates</span>
            <div>
            </div>
        </summary>
        <p>
            <span ez-m="LABEL_EULA_HTML_BODY_UPDATES_P1">Company may from time to time in its sole discretion develop and provide
                Application updates, which may include upgrades, bug fixes, patches and other error corrections and/or new features
                (collectively, including related documentation, &quot;Updates&quot;). Updates may also modify or delete in their entirety
                certain features and functionality. You agree that Company has no obligation to provide any Updates or to continue to
                provide or enable any particular features or functionality. Based on your Mobile Device settings, when your Mobile Device is
                connected to the internet either:</span>
        </p>
        <ul style="list-style:none !important">
            <li>
                <span ez-m="LABEL_EULA_HTML_BODY_UPDATES_P1A">(a) the Application will automatically download and install all available
                    Updates; or</span>
            </li>
            <li>
                <span ez-m="LABEL_EULA_HTML_BODY_LICENSE_AGREEMENT_P2B">(b) modify, translate, adapt or otherwise create derivative works or
                    improvements, whether or not patentable, of the Application;</span>
            </li>
            <li>
                <span ez-m="LABEL_EULA_HTML_BODY_UPDATES_P1B">(b) you may receive notice of or be prompted to download and install available
                    Updates.</span>
            </li>
        </ul>
        <p>
            <span ez-m="LABEL_EULA_HTML_BODY_UPDATES_P2">You shall promptly download and install all Updates and acknowledge and agree that
                the Application or portions thereof may not properly operate should you fail to do so. You further agree that all Updates
                will be deemed part of the Application and be subject to all terms and conditions of this Agreement.</span>
        </p>
    </details>
    <details ez-acc-details="">
        <summary ez-acc-title="">
            <span ez-m="LABEL_EULA_HTML_HEADER_TERM">6. Term and Termination</span>
            <div>
            </div>
        </summary>
        <p>
            <span ez-m="LABEL_EULA_HTML_BODY_TERM_P1">The term of Agreement commences when you download/install the Application and will
                continue in effect until terminated as provided herein (the &quot;Term&quot;). You may terminate this Agreement by deleting
                the Application and all copies thereof from your Mobile Device. Company may terminate this Agreement at any time without
                notice if it ceases to support the Application, which Company may do in its sole discretion. In addition, this Agreement
                will terminate immediately and automatically without any notice if you violate any of the terms and conditions of this
                Agreement. Upon termination, all rights granted to you under this Agreement will also terminate and you must cease all use
                of the Application and delete all copies of the Application from your Mobile Device and account. Termination will not limit
                any of Company&#x27;s rights or remedies at law or in equity.</span>
        </p>
    </details>
    <details ez-acc-details="">
        <summary ez-acc-title="">
            <span ez-m="LABEL_EULA_HTML_HEADER_DISCLAIMER">7. Disclaimer of Warranties</span>
            <div>
            </div>
        </summary>
        <p>
            <span ez-m="LABEL_EULA_HTML_BODY_DISCLAIMER_P1">YOU EXPRESSLY ACKNOWLEDGE AND AGREE THAT USE OF THE LICENSED APPLICATION IS AT
                YOUR SOLE RISK. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE APPLICATION IS PROVIDED TO LICENSEE &quot;AS IS&quot;
                AND &quot;AS AVAILABLE,&quot; WITH ALL FAULTS AND DEFECTS AND WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED
                UNDER APPLICABLE LAW, COMPANY, ON ITS OWN BEHALF AND ON BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND
                SERVICE PROVIDERS, EXPRESSLY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, WITH RESPECT TO THE
                APPLICATION, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
                NON-INFRINGEMENT, AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE OR TRADE PRACTICE.
                WITHOUT LIMITATION TO THE FOREGOING, COMPANY PROVIDES NO WARRANTY OR UNDERTAKING, AND MAKES NO REPRESENTATION OF ANY KIND
                THAT THE APPLICATION WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE OR WORK WITH ANY OTHER
                SOFTWARE, APPLICATIONS, SYSTEMS OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR RELIABILITY STANDARDS OR
                BE ERROR-FREE OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF OR
                LIMITATIONS ON IMPLIED WARRANTIES OR THE LIMITATIONS ON THE APPLICABLE STATUTORY RIGHTS OF A CONSUMER, SO SOME OR ALL OF THE
                ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.</span>
        </p>
    </details>
    <details ez-acc-details="">
        <summary ez-acc-title="">
            <span ez-m="LABEL_EULA_HTML_HEADER_LIMITATION">8. Limitation of Liability</span>
            <div>
            </div>
        </summary>
        <p>
            <span ez-m="LABEL_EULA_HTML_BODY_LIMITATION_P1">TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL COMPANY OR
                ITS AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS OR SERVICE PROVIDERS, BE LIABLE FOR PERSONAL INJURY OR ANY
                INCIDENTAL, SPECIAL, INDIRECT, OR CONSEQUENTIAL DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF
                PROFITS, LOSS OF DATA, BUSINESS INTERRUPTION, OR ANY OTHER COMMERCIAL DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO YOUR
                USE OF OR INABILITY TO USE THE APPLICATION, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, TORT, OR
                OTHERWISE) AND EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. SOME JURISDICTIONS DO NOT ALLOW THE
                LIMITATION OF LIABILITY FOR PERSONAL INJURY, OR OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION MAY NOT APPLY TO
                YOU. In no event shall Company’s total liability to you for all damages (other than as may be required by applicable law in
                cases involving personal injury) exceed the amount of fifty dollars ($50.00). The foregoing limitations will apply even if
                the above stated remedy fails of its essential purpose.</span>
        </p>
    </details>
    <details ez-acc-details="">
        <summary ez-acc-title="">
            <span ez-m="LABEL_EULA_HTML_HEADER_IDEMNIFICATION">9. Indemnification</span>
            <div>
            </div>
        </summary>
        <p>
            <span ez-m="LABEL_EULA_HTML_BODY_IDEMNIFICATION_P1">You agree to indemnify, defend and hold harmless Company and its officers,
                directors, employees, agents, affiliates, successors and assigns from and against any and all losses, damages, liabilities,
                deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or expenses of whatever
                kind, including attorneys&#x27; fees, arising from or relating to your use or misuse of the Application or your breach of
                this Agreement. Furthermore, you agree that Company assumes no responsibility for the content you submit or make available
                through this Application.</span>
        </p>
    </details>
    <details ez-acc-details="">
        <summary ez-acc-title="">
            <span ez-m="LABEL_EULA_HTML_HEADER_EXPORT_REGULATION">10. Export Regulation</span>
            <div>
            </div>
        </summary>
        <p>
            <span ez-m="LABEL_EULA_HTML_BODY_EXPORT_REGULATION_P1">The Application may be subject to United States export control laws,
                including the U.S. Export Administration Act and its associated regulations. You shall not, directly or indirectly, export,
                re-export or release the Application to, or make the Application accessible from, any jurisdiction or country to which
                export, re-export or release is prohibited by law, rule or regulation. You shall comply with all applicable federal laws,
                regulations and rules, and complete all required undertakings (including obtaining any necessary export license or other
                governmental approval), prior to exporting, re-exporting, releasing or otherwise making the Application available outside
                the United States.</span>
        </p>
    </details>
    <details ez-acc-details="">
        <summary ez-acc-title="">
            <span ez-m="LABEL_EULA_HTML_HEADER_SEVERABILITY">11. Severability</span>
            <div>
            </div>
        </summary>
        <p>
            <span ez-m="LABEL_EULA_HTML_BODY_SEVERABILITY_P1">If any provision of this Agreement is illegal or unenforceable under
                applicable law, the remainder of the provision will be amended to achieve as closely as possible the effect of the original
                term and all other provisions of this Agreement will continue in full force and effect.</span>
        </p>
    </details>
    <details ez-acc-details="">
        <summary ez-acc-title="">
            <span ez-m="LABEL_EULA_HTML_HEADER_GOVERNING_LAW">12. Governing Law</span>
            <div>
            </div>
        </summary>
        <p>This Agreement is governed by and construed in accordance with the internal laws of <span
                  ez-m="LABEL_EULA_HTML_BODY_GOVERNING_LAW_P1">undefined</span> without giving effect to any choice or conflict of law
            provision, principle, or rule and notwithstanding your domicile, residence, or physical location. Any action or proceeding
            arising out of or relating to this Application and/or under this Agreement will be instituted in the Country of <span
                  ez-m="LABEL_EULA_HTML_BODY_GOVERNING_LAW_P1">undefined</span>, and each party irrevocably submits to the exclusive
            jurisdiction of such courts in any such action or proceeding. You waive any and all objections to the exercise of jurisdiction
            over you by such courts and to the venue of such courts.</p>
        <p>
            <span ez-m="LABEL_EULA_HTML_BODY_GOVERNING_LAW_P2">If (a) you are not a U.S. citizen; (b) you do not reside in the U.S.; (c) you
                are not accessing the Application from the U.S.; and (d) you are a citizen of one of the countries identified below, you
                hereby agree that any dispute or claim arising from this Agreement shall be governed by the applicable law set forth below,
                without regard to any conflict of law provisions, and you hereby irrevocably submit to the non-exclusive jurisdiction of the
                courts located in the state, province or country identified below whose law governs:</span>
        </p>
        <p>
            <span ez-m="LABEL_EULA_HTML_BODY_GOVERNING_LAW_P3">If you are a citizen of any European Union country or Switzerland, Norway or
                Iceland, the governing law and forum shall be the laws and courts of your usual place of residence.</span>
        </p>
    </details>
    <details ez-acc-details="">
        <summary ez-acc-title="">
            <span ez-m="LABEL_EULA_HTML_HEADER_AGREEMENT">13. Entire Agreement</span>
            <div>
            </div>
        </summary>
        <p>
            <span ez-m="LABEL_EULA_HTML_BODY_AGREEMENT_P1">This Agreement constitutes the entire and only agreement between the parties in
                relation to its subject matter and replaces and extinguishes all prior or simultaneous agreements, undertakings,
                arrangements, understandings or statements of any nature made by the parties or any of them whether oral or written (and, if
                written, whether or not in draft form) with respect to such subject matter. Each of the parties acknowledges that they are
                not relying on any statements, warranties or representations given or made by any of them in relation to the subject matter
                of this Agreement, save those expressly set out in this Agreement, and that they shall have no rights or remedies with
                respect to such subject matter otherwise than under this Agreement save to the extent that they arise out of the fraud or
                fraudulent misrepresentation of another party. No variation of this Agreement shall be effective unless it is in writing and
                signed by or on behalf of Company.</span>
        </p>
    </details>
    <details ez-acc-details="">
        <summary ez-acc-title="">
            <span ez-m="LABEL_EULA_HTML_HEADER_WAVER">14. Waiver</span>
            <div>
            </div>
        </summary>
        <p>
            <span ez-m="LABEL_EULA_HTML_BODY_WAVER_P1">No failure to exercise, and no delay in exercising, on the part of either party, any
                right or any power hereunder shall operate as a waiver thereof, nor shall any single or partial exercise of any right or
                power hereunder preclude further exercise of that or any other right hereunder.</span>
        </p>
    </details>
    <p class="mt-3 mb-0">Last Updated: 10/24/2023</p>
</main>

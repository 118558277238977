import { Component, Inject, InjectionToken, Injector, Optional, Provider } from "@angular/core";
import { IAddPeopleConfig } from "@common/identity/add-people-binding-model.interface";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";

export type AddPeopleAction = (injector: Injector, config?: IAddPeopleConfig) => Promise<void>;
export const ADD_PEOPLE_ACTION = new InjectionToken<AddPeopleAction>("ADD_PEOPLE_ACTION");

export function provideAddPeopleAction(action: AddPeopleAction): Provider {
    return {
        provide: ADD_PEOPLE_ACTION,
        useValue: action,
        multi: false,
    };
}

@Component({
    selector: "adapt-manage-people",
    templateUrl: "./manage-people-page.component.html",
})
export class ManagePeoplePageComponent extends BaseRoutedComponent {
    public constructor(
        private injector: Injector,
        @Optional() @Inject(ADD_PEOPLE_ACTION) public configureAddPeopleAction?: AddPeopleAction,
    ) {
        super(injector);
    }

    public addPeople() {
        return this.configureAddPeopleAction?.(this.injector);
    }
}

export const managePeoplePageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-manage-people-page", ManagePeoplePageComponent)
    .atOrganisationUrl("/manage/people")
    .withTitle("Manage People")
    .withSearchKeyword("Configuration")
    .withSearchKeyword("Configure")
    .verifyingAccess("manageAccess")
    .build();

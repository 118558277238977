<adapt-dialog [maxWidth]="bullseye?.statement ? 900 : 640">
    <h2 adapt-dialog-title>Bullseye</h2>
    <div adapt-dialog-content
         class="d-flex flex-column flex-lg-row">
        <adapt-bullseye-view [showZoom]="true"
                             class="flex-fill"></adapt-bullseye-view>
        <div *ngIf="bullseye?.statement"
             class="ms-4 d-flex flex-column right-column justify-content-center">
            <h4>Bullseye statement</h4>
            <div [froalaView]="bullseye!.statement"></div>
        </div>
    </div>
    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [hideCancelButton]="true"
                                   saveButtonPreset="cancel"
                                   saveText="Close"
                                   (saveClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ResponsiveService } from "../../ux/responsive/responsive.service";
import { ApplicationBarUserItemContentComponent } from "../application-bar-user-item-content/application-bar-user-item-content.component";
import { IShellPopoverLinkItem } from "../shell-popover-link-item/shell-popover-link-item";
import { ApplicationBarUserItemComponent } from "./application-bar-user-item.component";

@Injectable()
export class ApplicationBarUserItem implements IShellPopoverLinkItem {
    public readonly linkContent = {
        component: ApplicationBarUserItemComponent,
    };
    public readonly popover = {
        component: ApplicationBarUserItemContentComponent,
    };
    public readonly ordinal = 999;
    public readonly isShown$: Observable<boolean>;

    public constructor(
        responsiveService: ResponsiveService,
    ) {
        this.isShown$ = responsiveService.currentBreakpoint$.pipe(
            map((b) => b.isDesktopSize),
        );
    }
}

import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SupportTimeoutInMs, SupportUrlHome } from "@common/implementation-kit/implementation-kit.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { of, throwError } from "rxjs";
import { catchError, map, timeout } from "rxjs/operators";
import { ISearchProviderOptions, SearchType } from "../search.interface";
import { SearchProvider } from "../search-provider";
import { IHelpJuiceSearchResult } from "../search-results.interface";

interface ISearchQuestion {
    question: IHelpJuiceSearchResult;
}

@Injectable()
export class HelpJuiceSearchProvider extends SearchProvider<IHelpJuiceSearchResult> {
    // IDs of Helpjuice categories to not include in search results
    private static readonly IgnoredCategoryIds: number[] = [
        356406, // embed platform Context articles
        376039, // HQ platform Context articles
        273317, // under development articles
    ];

    // Number of search results to display. Helpjuice returns 20 by default.
    private static readonly ResultCount = 10;

    public readonly Type = SearchType.ImplementationKit;

    public constructor(
        private http: HttpClient,
    ) {
        super();
    }

    public shouldSkip(options: ISearchProviderOptions): boolean {
        return !!options.teamId || !!options.personId || !!options.updatedSince || (!!options.labelIds && options.labelIds.size > 0);
    }

    public initialise() {
        return of(true);
    }

    public execute({ keyword }: ISearchProviderOptions) {
        const queryParams = new HttpParams()
            .set("query", keyword);

        const fullUrl = `${SupportUrlHome}/search`;
        return this.http.get<ISearchQuestion[]>(fullUrl, { params: queryParams }).pipe(
            timeout({
                each: SupportTimeoutInMs,
                with: () => throwError(() => new Error("Loading implementation kit timed out")),
            }),
            catchError((err) => throwError(() => new Error(ErrorHandlingUtilities.getHttpResponseMessage(err)))),
            map((data) => data.map((resp) => resp.question)
                .filter((article) => !article.parent_categories.some((category) => HelpJuiceSearchProvider.IgnoredCategoryIds.includes(category)))
                .slice(0, HelpJuiceSearchProvider.ResultCount)),
        );
    }
}

import { InjectionToken, Provider } from "@angular/core";
import { Workflow } from "@common/ADAPT.Common.Model/embed/workflow";
import { WorkflowIdentifier } from "./workflow/workflow-identifier.enum";

export const WORKFLOWS = new InjectionToken<Record<WorkflowIdentifier, Workflow>>("WORKFLOWS");

export function provideWorkflows(workflows: Record<WorkflowIdentifier, Workflow>): Provider {
    return {
        provide: WORKFLOWS,
        useValue: workflows,
        multi: false,
    };
}

interface IDateFormat {
    short: string;
    long: string;
    full: string;
    shortdatetime: string;
    longdatetime: string;
}

export class DateFormats {
    // these date formats work in globalize and angular (https://angular.io/api/common/DatePipe#custom-format-options)
    public static readonly globalize: IDateFormat = {
        short: "d MMM yyyy",
        long: "d MMMM yyyy",
        full: "dd MMMM yyyy h:mm a",
        shortdatetime: "dd MMM yyyy h:mm a",
        longdatetime: "dd MMMM yyyy h:mm a",
    };

    public static readonly moment: IDateFormat = {
        short: "DD MMM YYYY",
        long: "D MMMM YYYY",
        full: "dddd, Do MMMM YYYY",
        shortdatetime: "DD MMM YYYY h:mm A",
        longdatetime: "D MMMM YYYY h:mm A",
    };
}

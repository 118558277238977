import { Component, Input } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { PersonPreferences } from "@common/ADAPT.Common.Model/person/person-preferences";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { UserService } from "@common/user/user.service";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";

@Component({
    selector: "adapt-configure-personal-notifications",
    templateUrl: "./configure-personal-notifications.component.html",
})
export class ConfigurePersonalNotificationsComponent extends ConfigureFeatureBase {
    @Input() public configItem?: IConfigItem;

    public person?: Person;
    public preferences?: PersonPreferences;
    public readonly projectLabel = AdaptClientConfiguration.AdaptProjectLabel;

    public constructor(
        configurationService: ConfigurationService,
        private userService: UserService,
    ) {
        super(configurationService);
    }

    @Autobind
    public async initialiseData() {
        this.person = await this.userService.getCurrentPerson();
    }
}

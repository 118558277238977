import { InjectionToken, Provider, Type } from "@angular/core";
import { BaseDialogComponent } from "./base-dialog.component/base-dialog.component";

export const DIALOG_EVENT_HANDLERS = new InjectionToken<IDialogEventHandler[]>("DIALOG_EVENT_HANDLERS");

export interface IDialogEventHandler {
    dialogOpened(dialog: BaseDialogComponent<unknown>, data?: unknown): void;
    dialogCancelled(dialog: BaseDialogComponent<unknown>): void;
    dialogResolved(dialog: BaseDialogComponent<unknown>, resolveData?: unknown): void;
}

export function provideDialogEventHandler(handler: Type<IDialogEventHandler>): Provider {
    return {
        provide: DIALOG_EVENT_HANDLERS,
        useExisting: handler,
        multi: true,
    };
}

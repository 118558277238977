import { Component, Inject, Injector, OnInit } from "@angular/core";
import { PERSONAL_DASHBOARD_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { IConfigGroup, IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigArea } from "@org-common/lib/configuration/configuration-types";
import { Observable } from "rxjs";
import { allPersonalConfigGroups } from "./personal-configuration-page-elements";

@Component({
    selector: "adapt-personal-configuration-page",
    templateUrl: "./personal-configuration-page.component.html",
})
export class PersonalConfigurationPageComponent extends BaseRoutedComponent implements OnInit {
    public ConfigArea = ConfigArea;
    public configGroups: IConfigGroup[] = [];
    public selectedItem?: IConfigItem;

    public personalDashboardLink$: Observable<string>;

    public constructor(
        injector: Injector,
        @Inject(PERSONAL_DASHBOARD_PAGE) personalDashboardPageRoute: IAdaptRoute<{}>,
    ) {
        super(injector);

        this.personalDashboardLink$ = personalDashboardPageRoute.getRoute();
    }

    public ngOnInit() {
        this.configGroups = allPersonalConfigGroups();
        this.notifyActivated();
    }
}

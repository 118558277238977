import { Injectable, Injector } from "@angular/core";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { UserType } from "@common/ADAPT.Common.Model/embed/user-type";
import { Connection, ConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/connection";
import { KeyFunctionBreezeModel } from "@common/ADAPT.Common.Model/organisation/key-function";
import { Role, RoleBreezeModel } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleConnection, RoleConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { RoleType, RoleTypeBreezeModel } from "@common/ADAPT.Common.Model/organisation/role-type";
import { RoleTypeCode } from "@common/ADAPT.Common.Model/organisation/role-type-code";
import { Team, TeamBreezeModel, TeamType } from "@common/ADAPT.Common.Model/organisation/team";
import { TeamLocation, TeamLocationBreezeModel } from "@common/ADAPT.Common.Model/organisation/team-location";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { ActiveEntityUtilities } from "@common/lib/data/active-entity-utilities";
import { BreezePredicateUtilities } from "@common/lib/data/breeze-predicate-utilities";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { LocalStorage } from "@common/lib/storage/local-storage";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { UserService } from "@common/user/user.service";
import moment from "moment";
import { forkJoin, from, lastValueFrom, MonoTypeOperatorFunction, Observable, of, tap } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { IntegratedArchitectureFrameworkQueryUtilities } from "../architecture/integrated-architecture-framework-query-utilities";
import { Tier1ArchitectureAuthService } from "../architecture/tier1-architecture-auth.service";
import { AuthorisationService } from "../authorisation/authorisation.service";
import { DirectorySharedService } from "../directory-shared/directory-shared.service";
import { LabellingService } from "../labelling/labelling.service";
import { AfterOrganisationInitialisation, AfterOrganisationInitialisationObservable } from "../organisation/after-organisation-initialisation.decorator";
import { BaseOrganisationService } from "../organisation/base-organisation.service";
import { OrganisationService } from "../organisation/organisation.service";

@Injectable({
    providedIn: "root",
})
export class CommonTeamsService extends BaseOrganisationService {
    private teamMemberRoleType!: RoleType;
    private teamLeaderRoleType!: RoleType;
    private readonly showingPrivateTeams = "ShowingPrivateTeams";

    private archData = new IntegratedArchitectureFrameworkQueryUtilities(this.commonDataService);

    public constructor(
        injector: Injector,
        private authorisationService: AuthorisationService,
        private directorySharedService: DirectorySharedService,
        private labellingService: LabellingService,
        private userService: UserService,
        private organisationService: OrganisationService,
    ) {
        super(injector);
    }

    public get waitForInitialisation$() {
        return this.waitUntilOrganisationInitialised();
    }

    protected organisationInitialisationActions() {
        return [
            this.initialiseService(),
        ];
    }

    protected initialiseService() {
        return this.commonDataService.getAll(RoleTypeBreezeModel).pipe(
            tap((allRoleTypes) => {
                this.teamLeaderRoleType = assertGetRoleType(allRoleTypes, RoleTypeCode.TeamLeader);
                this.teamMemberRoleType = assertGetRoleType(allRoleTypes, RoleTypeCode.TeamMember);
            }),
        );

        function assertGetRoleType(roleTypes: RoleType[], code: RoleTypeCode) {
            const roleType = roleTypes.find((rt) => rt.code === code);

            if (!roleType) {
                throw new Error(`Unable to find ${code} roleType`);
            }

            return roleType;
        }
    }

    public get teamMemberLabelPlural() {
        return this.teamMemberRoleType.defaultLabelPlural;
    }

    public get teamLeaderLabel() {
        return this.teamLeaderRoleType.defaultLabel;
    }

    @AfterOrganisationInitialisationObservable
    public getTeamLeaderLabel$() {
        return of(this.teamLeaderLabel);
    }

    @AfterOrganisationInitialisationObservable
    public getTeamMemberLabelPlural$() {
        return of(this.teamMemberLabelPlural);
    }

    public getTeamById(teamId: number) {
        return this.commonDataService.getById(TeamBreezeModel, teamId);
    }

    public promiseToGetAllTeams() {
        return lastValueFrom(this.commonDataService.getAll(TeamBreezeModel).pipe(
            this.primeTeamsProperties(),
        ));
    }

    public promiseToGetLeadershipTeam() {
        const predicate = new MethodologyPredicate<Team>("teamType", "==", TeamType.Leadership);
        return lastValueFrom(this.commonDataService.getByPredicate(TeamBreezeModel, predicate).pipe(
            map((teams) => teams[0]),
        ));
    }

    public async promiseToGetLeadershipTeamWithFallback() {
        let team = await this.promiseToGetLeadershipTeam();
        if (!team) {
            // this shouldn't happen in normal self-led, but can probably happen in QA env.
            const teams = await this.promiseToGetActiveTeamsForCurrentPerson();
            team = teams[0];
        }

        return team;
    }

    public promiseToCreateTeam() {
        const organisationId = this.organisationService.getOrganisationId();
        return lastValueFrom(this.commonDataService.create(TeamBreezeModel, {
            organisationId,
            startDate: moment.utc().toDate(),
            isPrivate: false,
            allowObjectivesTeamRead: true,
            teamType: TeamType.Standard,
        }));
    }

    public promiseToCreateTeamRoleConnection(team: Team, role: Role) {
        const defaults: Partial<RoleConnection> = {
            team,
            role,
        };
        return lastValueFrom(this.archData.createRoleConnection(defaults));
    }

    public async promiseToAddTeamMember(team: Team, connection: Connection, userType: UserType) {
        const roleConnection = await (userType === UserType.Viewer
            ? this.promiseToAddTeamRole(team, RoleTypeCode.TeamParticipant)
            : this.promiseToAddTeamRole(team, RoleTypeCode.TeamMember));
        roleConnection.connection = connection;
        if (connection.startDate > new Date()) {
            // use connection startDate if that's in the future
            roleConnection.startDate = connection.startDate;
        }
        return roleConnection;
    }

    public async promiseToAddTeamLeader(team: Team, connection: Connection) {
        const roleConnection = await this.promiseToAddTeamRole(team, RoleTypeCode.TeamLeader);
        roleConnection.connection = connection;
        if (connection.startDate > new Date()) {
            roleConnection.startDate = connection.startDate;
        }
        return roleConnection;
    }

    private async promiseToAddTeamRole(team: Team, teamRoleTypeCode: RoleTypeCode) {
        const role = await this.promiseToGetTeamRoleByRoleTypeCode(team.teamId, teamRoleTypeCode);
        if (!role) {
            throw new Error("Team role failed to fetch");
        }

        return this.promiseToCreateTeamRoleConnection(team, role);
    }

    public get isShowingPrivateTeams() {
        return !!LocalStorage.get<boolean>(this.showingPrivateTeams);
    }

    public set isShowingPrivateTeams(value: boolean) {
        LocalStorage.set(this.showingPrivateTeams, value);
    }

    private primeTeamsProperties(): MonoTypeOperatorFunction<Team[]> {
        return ((source: Observable<Team[]>) => {
            const getAllKeyFunctions$ = from(this.authorisationService.promiseToGetHasAccess(Tier1ArchitectureAuthService.ReadTier1)).pipe(
                switchMap((hasTier1) => hasTier1
                    ? this.commonDataService.getAll(KeyFunctionBreezeModel)
                    : of(undefined)),
            );

            return source.pipe(
                // priming teamLocations, teamLeaderPerson and label location since we deny $expand for Teams
                // to prevent private team leaks.
                // don't have to prime parent and childTeams as they are all covered by getAll(TeamBreezeModel)
                // when priming TeamLocations, need to get all KeyFunctions too as this will result in subsequent
                // TeamLocation query to use local breeze cache only since that's covered by getAll
                switchMap((teams) => forkJoin([
                    this.getAllTeamLocations(),
                    getAllKeyFunctions$,
                ]).pipe(
                    map(() => teams),
                )),
                switchMap((teams) => {
                    // only prime people we haven't fetched already (in most cases they should all be fetched)
                    const leaderPersonIds = teams
                        .filter((team) => team.teamLeaderPerson === null && team.teamLeaderPersonId)
                        .map((team) => team.teamLeaderPersonId) as number[];
                    return this.directorySharedService.primePeopleWithIds(ArrayUtilities.distinct(leaderPersonIds)).pipe(
                        map(() => teams),
                    );
                }),
                switchMap((teams) => this.labellingService.primeLabelLocationsForAllTeams().pipe(
                    map(() => teams),
                )),
            );
        });
    }

    public async promiseToGetAllActiveTeams() {
        const teams = await lastValueFrom(this.commonDataService.getActive(TeamBreezeModel).pipe(
            this.primeTeamsProperties(),
        ));
        return teams.sort((a, b) => a.name.localeCompare(b.name));
    }

    public getActiveChildTeams(team: Team) {
        const predicate = new MethodologyPredicate<Team>("parentTeamId", "==", team.teamId);
        return this.commonDataService.getActiveByPredicate(TeamBreezeModel, predicate);
    }

    public getAllTeamLocations() {
        return this.commonDataService.getAll(TeamLocationBreezeModel);
    }

    public async promiseToGetTeamRoleByRoleTypeCode(teamId: number, roleTypeCode: RoleTypeCode) {
        const predicate = new MethodologyPredicate<Role>("teamId", "==", teamId)
            .and(new MethodologyPredicate<Role>("roleType.code", "==", roleTypeCode));

        const teamRole = await lastValueFrom(this.commonDataService.getByPredicate(RoleBreezeModel, predicate));
        return ArrayUtilities.getSingleFromArray(teamRole);
    }

    @AfterOrganisationInitialisation
    public async promiseToGetActiveTeamsForCurrentPerson() {
        const person = await this.userService.getCurrentPerson();
        return this.promiseToGetActiveTeamsForPersonId(person!.personId);
    }

    public getActiveTeamIdsForCurrentPerson() {
        return from(this.promiseToGetActiveTeamsForCurrentPerson()).pipe(
            map((teams) => teams.map((i) => i.teamId)),
        );
    }

    public async promiseToGetActiveTeamsForPersonId(personId: number) {
        await lastValueFrom(this.archData.getRoleConnectionsForPersonId(personId, true)); // prime from before
        const teams = await this.promiseToGetAllActiveTeams();
        return teams.filter((team) =>
            team.roleConnections.some((rc) => rc?.connection?.personId === personId && ActiveEntityUtilities.isActive(rc)));
    }

    public async promiseToGetAllTeamMembers(team: Team) {
        const roleConnections = await this.promiseToGetTeamMemberRoleConnections(team);
        return this.getUniqueRoleConnectionPeople(roleConnections);
    }

    public async promiseToGetLatestTeamMembers(team: Team) {
        const predicate = new MethodologyPredicate<RoleConnection>("teamId", "==", team.teamId)
            .and(new MethodologyPredicate<RoleConnection>("endDate", "==", team.endDate));
        const roleConnections = await lastValueFrom(this.getRoleConnectionsFromActiveTeamsByPredicate(predicate));
        return this.getUniqueRoleConnectionPeople(roleConnections);
    }

    public async promiseToGetActiveTeamMembers(team: Team) {
        const roleConnections = await this.promiseToGetTeamMemberRoleConnections(team, true);
        return this.getUniqueRoleConnectionPeople(roleConnections);
    }

    public async promiseToGetTeamMemberRoleConnections(team: Team, activeOnly?: boolean) {
        const predicate = new MethodologyPredicate<RoleConnection>("teamId", "==", team.teamId);

        const roleConnections = await lastValueFrom(this.getRoleConnectionsFromActiveTeamsByPredicate(predicate));
        return roleConnections.filter((r) => r.isActive() || !activeOnly);
    }

    public async promiseToGetTeamLeaderRoleConnection(team: Team) {
        const predicate = new MethodologyPredicate<RoleConnection>("teamId", "==", team.teamId)
            .and(new MethodologyPredicate<RoleConnection>("role.roleType.code", "==", RoleTypeCode.TeamLeader));

        const roleConnections = await lastValueFrom(this.getRoleConnectionsFromActiveTeamsByPredicate(predicate));
        return ArrayUtilities.getSingleFromArray(roleConnections.filter((r) => r.isActive()));
    }

    @AfterOrganisationInitialisationObservable
    private getRoleConnectionsFromActiveTeamsByPredicate(predicate: MethodologyPredicate<RoleConnection>) {
        return this.commonDataService.getWithOptions(
            RoleConnectionBreezeModel,
            predicate.getKey(RoleConnectionBreezeModel.identifier),
            {
                // As Isaac pointed out, RoleConnections are already primed from getAll Connections in auth service.
                // Don't have to prime again and just use Connection identifier as the encompassing key
                encompassingKey: ConnectionBreezeModel.identifier,
                predicate,
            },
        );
    }

    private getActiveRoleConnectionsForTeamId(connection: Connection, teamId: number) {
        return ArrayUtilities.getSingleFromArray(connection.roleConnections
            .filter((roleConnection) => roleConnection.isActive())
            .filter((roleConnection) => roleConnection.role?.teamId === teamId));
    }

    public async promiseToVerifyPersonActiveInTeam(person: Person, team: Team) {
        const teamMembers = await this.promiseToGetActiveTeamMembers(team);
        return teamMembers.includes(person);
    }

    public async promiseToVerifyTeamRoleHasFeaturePermission(team: Team, roleTypeCode: RoleTypeCode, featurePermission: FeaturePermissionName) {
        const role = await this.promiseToGetTeamRoleByRoleTypeCode(team.teamId, roleTypeCode);
        if (!role) {
            return false;
        }

        return role.extensions.hasPermission(featurePermission);
    }

    public async promiseToReactivateTeam(team: Team) {
        // we are consciously setting this to be undefined, so the person reactivating the team has to think about who the new TL & TMs will be
        team.teamLeaderPersonId = undefined;
        team.endDate = undefined;

        await lastValueFrom(this.commonDataService.save());
    }

    public async promiseToUpdateTeamName(team: Team) {
        // this is called from onSave(), which is already saved -> so these roles update won't be saved and get left behind
        // triggering unsaved entities prompt when switching page - saving it here before broadcast.
        const updatedRoles: Role[] = [];

        const roles = [
            await this.promiseToGetTeamRoleByRoleTypeCode(team.teamId, RoleTypeCode.TeamMember),
            await this.promiseToGetTeamRoleByRoleTypeCode(team.teamId, RoleTypeCode.TeamLeader),
            await this.promiseToGetTeamRoleByRoleTypeCode(team.teamId, RoleTypeCode.TeamParticipant),
        ];

        for (const role of roles) {
            if (!role) {
                this.log.warn("Something went wrong with the team roles for this team.");
                continue;
            }
            role.label = team.name + " " + role.roleType!.defaultLabel;
            updatedRoles.push(role);
        }

        return lastValueFrom(this.commonDataService.saveEntities(updatedRoles));
    }

    public async promiseToUpdateRoleConnectionsForTeamLeaderChange(team: Team, newTeamLeaderConnection: Connection, oldTeamLeaderConnection?: Connection) {
        const connections: (RoleConnection | undefined)[] = [];

        if (oldTeamLeaderConnection) {
            this.log.info("Removing old team leader", oldTeamLeaderConnection.person);
            const existingRoleConnection = this.getActiveRoleConnectionsForTeamId(oldTeamLeaderConnection, team.teamId);
            if (existingRoleConnection) {
                connections.push(await this.promiseToChangeToTeamMemberRole(existingRoleConnection, team));
            }
        }

        if (newTeamLeaderConnection) {
            this.log.info("Adding new team leader", newTeamLeaderConnection);
            const existingRoleConnection = this.getActiveRoleConnectionsForTeamId(newTeamLeaderConnection, team.teamId);
            connections.push(await this.promiseToChangeToTeamLeaderRole(team, newTeamLeaderConnection, existingRoleConnection));
        }

        return connections;
    }

    private async promiseToChangeToTeamLeaderRole(team: Team, newTeamLeaderConnection: Connection, roleConnection?: RoleConnection) {
        let connection: Connection | undefined;

        if (roleConnection) {
            // person was previously a team member, so check if they were accidentally demoted
            this.log.info("New team leader already had an active role connection", roleConnection);
            connection = roleConnection.connection;
            this.removeTeamMember(roleConnection);

            const existingRoleConnections = this.commonDataService.getChangedReferenceEntities(RoleConnectionBreezeModel, "connectionId", connection.connectionId);

            if (existingRoleConnections) {
                const existingTeamLeaderRoleConnection = existingRoleConnections.find((rc) => rc.role?.roleType!.code === RoleTypeCode.TeamLeader);
                if (existingTeamLeaderRoleConnection) {
                    this.log.info("Reusing existing and unsaved old team leader role", existingTeamLeaderRoleConnection);
                    // accidental demotion, just re-instate the old team leader role connection
                    await lastValueFrom(this.commonDataService.rejectChanges(existingTeamLeaderRoleConnection));
                    return existingTeamLeaderRoleConnection;
                }
            }
        }

        // person is not a current team member, just create a new role connection
        this.log.info("Creating new team leader role connection for person", newTeamLeaderConnection);
        return this.promiseToAddTeamLeader(team, connection ?? newTeamLeaderConnection);
    }

    private async promiseToChangeToTeamMemberRole(roleConnection: RoleConnection, team: Team) {
        const connection = roleConnection.connection;
        const isNew = roleConnection.entityAspect.entityState.isAdded();

        this.removeTeamMember(roleConnection, true);

        if (isNew) {
            // team leader hadn't been saved yet - accidental promotion?
            const existingRoleConnections = this.commonDataService.getChangedReferenceEntities(RoleConnectionBreezeModel, "connectionId", connection.connectionId);

            if (existingRoleConnections) {
                const existingTeamMemberRole = existingRoleConnections.find((rc) => rc.role?.roleType!.code === RoleTypeCode.TeamMember);

                if (existingTeamMemberRole) {
                    this.log.info("Reusing existing unsaved team member role", existingTeamMemberRole);
                    // accidental promotion, re-instate the old team member role connection
                    await lastValueFrom(this.commonDataService.rejectChanges(existingTeamMemberRole));
                    return existingTeamMemberRole;
                }
            }

            // don't bother creating a team member role connection if we're creating the team right now or they were selected by accident
            return undefined;
        }

        // create a team member role connection for this demoted team leader
        this.log.info("Creating new team member role connection for demoted person", connection);
        return this.promiseToAddTeamMember(team, connection, connection.userType);
    }

    public removeTeamMember(roleConnection: RoleConnection, dontResetTeamLeader: boolean = false) {
        if (!dontResetTeamLeader
            && (roleConnection.role && roleConnection.role.roleType
                // this may be used to remove newly added roleConnection without having the role assigned.
                && roleConnection.role.roleType.code === RoleTypeCode.TeamLeader
                && roleConnection.team.teamLeaderPersonId === roleConnection.connection.personId)) {
            roleConnection.team.teamLeaderPersonId = undefined;
        }

        if (roleConnection.entityAspect.entityState.isAdded()) {
            roleConnection.entityAspect.setDeleted();
        } else {
            roleConnection.endDate = moment.utc().toDate();
        }

        return roleConnection;
    }

    public async promiseToEndTeam(team: Team) {
        if (!team.endDate) {
            throw new Error("No team.endDate specified");
        }

        // end the associated roles
        for (const role of team.roles) {
            if (!role.endDate) {
                role.endDate = team.endDate;
            }
        }

        team.boards.forEach((t) => t.isArchived = true);

        await this.promiseToUpdateTeamLocationOrdinalsForRemovedTeamLocations(team.teamLocations);
    }

    public async promiseToRemoveTeam(team: Team) {
        // The server side will remove all related entities to this team
        // We chose to delete on the server side as its less work than fetching every single related entity on the client side prior to the delete
        // We do, however, need to delete the locations, which affects the navigation properties so
        // we need to keep references to the teamLocations and stubs that have the locations to update the
        // ordinals after deletion.
        const teamLocations = team.teamLocations.slice();
        const teamLocationStubs = teamLocations.map((tl) => ({ ordinal: tl.ordinal }));

        await Promise.all([
            ...teamLocations.map((tl) => lastValueFrom(this.commonDataService.remove(tl))),
            lastValueFrom(this.commonDataService.remove(team)),
        ] as Promise<any>[]);
        await this.promiseToUpdateTeamLocationOrdinalsForRemovedTeamLocations(teamLocationStubs);
    }

    // Get: Team Roles

    public promiseToGetTeamRoles(team: Team, roleTypeCode?: RoleTypeCode) {
        const predicate = new MethodologyPredicate<Role>("teamId", "==", team.teamId);

        if (roleTypeCode) {
            predicate.and(new MethodologyPredicate<Role>("roleType.code", "==", roleTypeCode));
        }

        return lastValueFrom(this.archData.getActiveRolesByPredicate(predicate));
    }

    public promiseToGetTeamRoleTypes() {
        // don't have to include TeamParticipant here as this is only used by ConfigureTeam to determine the label for leader and member
        const teamRoleTypeCodes = [RoleTypeCode.TeamMember, RoleTypeCode.TeamLeader];
        const predicate = new MethodologyPredicate<RoleType>("code", "in", teamRoleTypeCodes);
        return lastValueFrom(this.commonDataService.getByPredicate(RoleTypeBreezeModel, predicate));
    }

    private getUniqueRoleConnectionPeople(roleConnections: RoleConnection[]) {
        return ArrayUtilities.distinct(roleConnections.map((roleConnection) => roleConnection.connection.person));
    }

    private promiseToUpdateTeamLocationOrdinalsForRemovedTeamLocations(teamLocations: Pick<TeamLocation, "ordinal">[]) {
        const updateTeamLocationsOrdinals = teamLocations.map(async (teamLocation) => {
            const predicate = BreezePredicateUtilities.getIsActivePredicateByPath("team");
            const otherTeamLocations = await lastValueFrom(this.commonDataService.getByPredicate(TeamLocationBreezeModel, predicate));
            SortUtilities.updateIntegerSortedArrayAfterItemRemoval(otherTeamLocations, "ordinal", teamLocation.ordinal);
            return teamLocation;
        });

        return Promise.all(updateTeamLocationsOrdinals);
    }
}

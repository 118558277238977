import { HubConnectionState } from "@microsoft/signalr";
import { AdaptError } from "../error-handler/adapt-error";
import { SignalRInvokeError } from "./signalr-invoke-error";

export class SignalRHubResubscribeError extends AdaptError {
    public constructor(private invokeError: SignalRInvokeError) {
        super(`Failed to resubscribe: ${invokeError.message}`);
    }

    public get shouldBeLogged() {
        return this.invokeError.state === HubConnectionState.Connected;
    }
}

<adapt-dialog [maxWidth]="650"
              [expandable]="false"
              [showHelpButton]="false">
    <h3 adapt-dialog-title>Confirm cadence reset</h3>

    <div adapt-dialog-content>
        <p class="mb-1">Are you sure you would like to clear your cadence? This will delete all <b>non-started</b> meetings for each of the following
            events:</p>
        <ul class="mb-1">
            <li *ngFor="let eventType of affectedEventTypes">
                <i class="fas fa-square me-1" [style.color]="eventType.colour"></i> {{eventType.name}}
            </li>
        </ul>
        <p>Meetings that are in progress or ended will not be affected.</p>
        <p>You can set your cadence again by clicking the <b>Set cadence</b> button after clearing.</p>

        <div class="my-3">
            <dx-check-box [value]="checkboxConfirmation"
                          (valueChange)="checkboxConfirmation = $any($event)"
                          text="Confirm that you understand these meetings will be deleted and your cadence will be cleared"
                          data-test="confirm-checkbox"></dx-check-box>
        </div>
        <span><i class="fal fa-fw fa-triangle-exclamation me-1"></i> This action may take a couple of minutes</span>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveButtonPreset="refresh"
                                   saveText="Clear & save"
                                   [saveIsDisabled]="!checkboxConfirmation"
                                   [saveBlockingClick]="saveAndClose"
                                   (cancelClick)="cancel()"></adapt-standard-dialog-buttons>
</adapt-dialog>

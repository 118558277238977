import { DefaultFinishButtons, DefaultNextButtons, GuidedTour } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourUtils } from "@common/lib/guided-tour/guided-tour.utils";
import { CommonTourSteps } from "./common-tour-steps";

export const BullseyeWrapUpTour: GuidedTour = {
    steps: [CommonTourSteps.openOrgMenuToShowSidebarNavNodeStep("Your bullseye", "Bullseye"), {
        waitForAndAttachToElementSelector: "adapt-display-link-hierarchy adapt-display-navigation-node span",
        elementSelectorTextContent: "Bullseye", // looking for <adapt-display-navigation-node> with span content of this text
        advanceOnAttachedElementEvent: "click",
        attachTo: {
            on: "bottom-end",
        },
        title: "Access bullseye",
        text: `<p>Your organisation bullseye is available here.</p>
        <p><b>Click on it now</b> to show it.</p>`,
        modalOverlayOpeningPadding: 3,
    }, {
        title: "The bullseye",
        text: "This is your bullseye",
        waitForAndAttachToElementSelector: `[data-tour="bullseye"]`,
        attachTo: {
            on: "left",
        },
        buttons: DefaultNextButtons,
    }, {
        title: "Zooming into the bullseye",
        text: "<p>You can zoom into the bullseye using this button</p><p><b>Click on it now</b> to experience it.</p>",
        waitForAndAttachToElementSelector: `[data-tour="bullseye-zoom-button"]`,
        advanceOnAttachedElementEvent: "click",
        attachTo: {
            on: "left",
        },
    }, {
        title: "Zooming out from the bullseye",
        text: "<p>You can zoom out by clicking on the same button</p><p><b>Click on it now</b> to return.</p>",
        waitForAndAttachToElementSelector: `[data-tour="bullseye-zoom-button"]`,
        advanceOnAttachedElementEvent: "click",
        attachTo: {
            on: "left",
        },
    }, {
        title: "Bullseye statement",
        text: "You have defined a bullseye statement which is shown here.",
        showOn: () => {
            // this step will only be shown if Organisation Diagnostic node is not there
            const customBullseyeStatement = GuidedTourUtils.getFirstElementWithTextContent(
                `[data-tour="custom-bullseye-statement"]`);
            return GuidedTourUtils.isElementVisible(customBullseyeStatement);
        },
        attachTo: {
            element: `[data-tour="custom-bullseye-statement"]`,
            on: "top",
        },
        buttons: DefaultNextButtons,
    }, {
        title: "Edit bullseye",
        text: "In the future, you can edit your bullseye using this option.",
        canClickTarget: false,
        waitForAndAttachToElementSelector: `[data-tour="edit-bullseye-button"]`,
        attachTo: {
            on: "bottom",
        },
        buttons: DefaultNextButtons,
    }, {
        title: "Continue",
        text: `<p>You can continue your journey by going to your <b>Personal Dashboard</b>.</p>`,
        waitForAndAttachToElementSelector: `[id="sidebar-personal"]`,
        attachTo: {
            on: "right",
        },
        buttons: DefaultFinishButtons,
    }],
};

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptFeatureModule } from "@common/feature/feature.module";
import { AdaptDashboardElementModule } from "../dashboard-element/dashboard-element.module";
import { AdaptStyledDashboardElementModule } from "../styled-dashboard-element/styled-dashboard-element.module";
import { UniversalDashboardElementComponent } from "./universal-dashboard-element.component";

@NgModule({
    imports: [
        CommonModule,
        AdaptFeatureModule,
        AdaptDashboardElementModule,
        AdaptStyledDashboardElementModule,
    ],
    declarations: [
        UniversalDashboardElementComponent,
    ],
    exports: [
        UniversalDashboardElementComponent,
    ],
})
export class AdaptUniversalDashboardElementModule { }

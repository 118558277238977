<adapt-dashboard-element name="Actions"
                         [url]="url$ | async"
                         *ngIf="showElement">
    <div *adaptLoadingUntilFirstEmit="let team of team$">
        <div *ngIf="boards.length; else noBoardsTemplate">
            <div class="d-flex">
                <div>
                    <ol class="item-counts">
                        <li *ngFor="let itemCount of itemCounts">
                            <span class="item-count">{{itemCount.count}}</span>
                            <span>{{itemCount.label}}</span>
                        </li>
                    </ol>
                    <div *ngIf="isTeamActive">
                        <button adaptButtonIcon="add"
                                *adaptIfAuthorised="editTeamKanban;Entity:team"
                                (click)="createNewItem()">Add action</button>
                    </div>
                </div>
                <adapt-boards-item-statuses-graph *ngIf="kanbanStats"
                                                  [statusCounts]="kanbanStats.statusCounts"></adapt-boards-item-statuses-graph>
            </div>
        </div>
        <ng-template #noBoardsTemplate>
            <div class="alert alert-guidance">
                <p>
                    Teams can use actions to visualise their work and limit work in progress to enable more
                    effective teamwork.
                    <span *adaptIfAuthorised="configureTeam;Entity:team;else noConfigurePermission">
                        Create a board in order to manage team actions.
                    </span>
                </p>

                <ng-template #noConfigurePermission>
                    Team actions have not yet been set up by the team leader.
                </ng-template>
            </div>
        </ng-template>
    </div>
</adapt-dashboard-element>

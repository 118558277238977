import { UrlUtilities } from "@common/lib/utilities/url-utilities";
import { IStorageProvider } from "./storage-provider.interface";

const adaptIdentifierKey = "_adaptStorageType"; // additional query param to identify storage type

export class StorageProviderUtilities {
    public static addUrlServiceNameIdentifier(url: string, storageProviderService: IStorageProvider): string {
        return UrlUtilities.setQueryParam(url, adaptIdentifierKey, storageProviderService.getName());
    }

    public static getUrlServiceNameIdentifier(url: string): string | undefined {
        return UrlUtilities.getQueryParamValue(url, adaptIdentifierKey);
    }
}

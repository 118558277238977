import { Injectable } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { Subject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class AuthorisationNotificationService {
    private _authorisationChanging$ = new Subject<void>();
    private _authorisationChanged$ = new Subject<void>();
    private _permissionsRefreshed$ = new Subject<void>();

    public get authorisationChanging$() {
        return this._authorisationChanging$.asObservable();
    }

    /** Get a hot observable which will emit each time a change is made which alters
     * any permissions
     */
    public get authorisationChanged$() {
        return this._authorisationChanged$.asObservable();
    }

    public get permissionsRefreshed$() {
        return this._permissionsRefreshed$.asObservable();
    }

    public notifyAuthorisationChanging() {
        this._authorisationChanging$.next();
    }

    public refreshPermissions() {
        this._permissionsRefreshed$.next();
    }

    @Autobind
    public notifyAuthorisationChanged() {
        this._authorisationChanged$.next();
    }
}

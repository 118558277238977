import { NgModule } from "@angular/core";
import { TooltipDirective } from "./tooltip.directive";

@NgModule({
    exports: [
        TooltipDirective,
    ],
    declarations: [
        TooltipDirective,
    ],
})
export class AdaptTooltipModule {
}

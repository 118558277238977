import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { TwoColumnLayoutComponent } from "./two-column-layout.component";
import { TwoColumnPageComponent } from "./two-column-page.component";

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        TwoColumnLayoutComponent,
        TwoColumnPageComponent,
    ],
    declarations: [
        TwoColumnLayoutComponent,
        TwoColumnPageComponent,
    ],
})
export class AdaptTwoColumnModule { }

import { ProcessStep } from "./process-step";
import { ProcessStepType, ProcessStepTypeMetadata } from "./process-step-type";

export class ProcessStepExtensions {
    public constructor(private processStep: ProcessStep) { }

    public get iconClass() {
        return ProcessStepTypeMetadata.IconClass[this.type];
    }

    public get isProcessStep() {
        return this.type === ProcessStepType.ProcessStep;
    }

    public get isRoleTask() {
        return this.type === ProcessStepType.RoleTask;
    }

    public get isProcessMapLink() {
        return this.type === ProcessStepType.ProcessMapLink;
    }

    private get type() {
        return this.processStep.type;
    }
}

<adapt-dialog [maxWidth]="1200">
    <h3 class="d-flex align-items-center"
        adapt-dialog-title>Attach {{canvasTypeLabel}} inputs to
        <i [ngClass]="StrategicViewIcon.ThemeIcon"
           adaptTooltip="Theme"
           class="mx-1"></i>
        {{data.theme.name}}
    </h3>
    <div adapt-dialog-content
         class="h-100">
        <div class="d-flex flex-column flex-xl-row gap-3">
            <div class="doc-column"
                 [adaptCollapsible]="implementationKitArticle">
                <adapt-styled-implementation-kit [articleId]="implementationKitArticle"
                                                 contentStyle="embedded"></adapt-styled-implementation-kit>
            </div>
            <div *ngIf="hasCanvas; else noCanvas"
                 class="flex-grow-1 d-flex flex-column mt-4 mt-xl-0">

                <div *ngIf="isInitialised"
                     class="mt-3 d-flex flex-column">
                    <span>Choose inputs to attach to the destination above:</span>
                    <ng-container *ngIf="showCanvasSelector">
                        <label class="mt-3">Canvas</label>
                        <adapt-select-canvas (canvasChange)="selectedCanvasUpdater.next($event)"
                                             [canvasTypes]="[data.canvasType]"></adapt-select-canvas>
                    </ng-container>

                    <label class="mt-2">Input</label>
                    <adapt-select-input [canvas]="selectedCanvas"
                                        [inputFilter]="inputFilter"
                                        (inputChange)="onInputSelected($event)"></adapt-select-input>

                    <div *ngIf="existingLocations.length > 0"
                         class="mt-4">
                        <span>Attached {{canvasTypeLabel}} inputs:</span>
                        <dx-list *ngIf="existingLocations.length > 0"
                                 [activeStateEnabled]="false"
                                 [hoverStateEnabled]="false"
                                 [focusStateEnabled]="false"
                                 [dataSource]="existingLocations"
                                 (onItemReordered)="reorderInputLocations($event)">
                            <dxo-item-dragging [data]="existingLocations"
                                               [allowReordering]="true"></dxo-item-dragging>
                            <div *dxTemplate="let inputLocation of 'item'">
                                <adapt-strategic-input [input]="inputLocation.input"
                                                       [showIcon]="true"
                                                       [showCanvasNameInContent]="true"
                                                       [isDetachable]="true"
                                                       (detach)="onInputDetached($event)"
                                                       [isCollapsible]="true"></adapt-strategic-input>
                            </div>
                        </dx-list>
                    </div>
                </div>
            </div>
            <ng-template #noCanvas>
                <div class="alert alert-warning doc-column"
                     *adaptLoading="!isInitialised">
                    <p>
                        There are no strengths, weaknesses &amp; trends defined
                    </p>
                    <p>
                        <span *ngIf="inputsPathwayRoute$ | async as route">Please run the <a [routerLink]="route.path">{{data.inputName}}
                                pathway </a></span>
                        <span *ngIf="data.pageRoute$ | async as route">or add some inputs on the <a [routerLink]="route">{{data.inputName}}
                                page</a> .</span>
                    </p>
                </div>
            </ng-template>
        </div>
    </div>
    <div adapt-dialog-footer
         class="d-flex w-100">
        <adapt-standard-dialog-buttons [saveIsDisabled]="hasValidationErrors"
                                       [saveBlockingClick]="saveAndClose"
                                       (cancelClick)="cancel()"
                                       cancelText="Cancel">
        </adapt-standard-dialog-buttons>
    </div>
</adapt-dialog>

<div class="display-objectives-hierarchy"
     [ngClass]="{'horizontal': isHorizontal, 'vertical': !isHorizontal,'compact': isCompactView}"
     *ngIf="objectiveGroup">
    <svg *ngIf="hasChildren"
         class="overlay-svg">
        <polyline *ngFor="let line of lines"
                  stroke="white"
                  stroke-width="4"
                  fill="none"
                  [attr.points]="line"></polyline>
        <polyline *ngFor="let line of lines"
                  stroke="grey"
                  stroke-width="2"
                  fill="none"
                  [attr.points]="line"></polyline>
    </svg>
    <div class="d-flex"
         [ngClass]="{'flex-row': isHorizontal, 'flex-column': !isHorizontal}">
        <ng-container *ngIf="needIndent && isHorizontal">
            <ng-container *ngIf="hasExternalParent">
                <div class="objective-card horizontal me-5 compact-vertical-root-ao"
                     [ngClass]="{'compact': isCompactView}"></div>
                <span class="ps-2 pe-1 ms-2"></span>
                <!-- This will match the <i> for the expand/collapse icon -->
            </ng-container>
            <ng-container *ngIf="needIndentForAnnual">
                <div class="objective-card horizontal me-5"
                     [ngClass]="{'compact': isCompactView}"></div>
                <span class="ps-2 pe-1 ms-2"></span>
            </ng-container>
        </ng-container>
        <!-- Note for 'compact' condition below:
            1st block before '||' - when compact view is specified and not covered by compact-vertical-root-ao/qo above it
            2nd block - external obj hanging off objectives for this page to the right
            3rd block - the external objective between the compact-vertical-root-ao/qo and obj for this page (this will be laid out vertically from root)
        -->
        <adapt-display-tree-objective class="objective-card"
                                      data-test="objective-tree-item"
                                      [ngClass]="{'compact-vertical-root-ao': isExternalObjectiveWithInternalChild && isRoot && !isQuarterly,
                                        'compact-vertical-root-qo': isExternalObjectiveWithInternalChild && isRoot && isQuarterly,
                                        'compact': (isCompactView && !(isExternalObjectiveWithInternalChild && isRoot)) || isExternalObjectiveWithInternalParent || (isExternalObjectiveWithInternalChild && !isRoot)}"
                                      [objective]="objective"
                                      [currentPageTeamId]="currentTeamId"
                                      [isExternalObjective]="isExternalObjective"
                                      [isCompactView]="isCompactView"
                                      [applyFilter]="applyFilter"
                                      (heightChange)="childHeightChanged()"></adapt-display-tree-objective>
        <div *ngIf="hasChildren"
             class="expand-collapse-button-container">
            <button (click)="toggleShowChildren()"
                    [ngClass]="{'expand-collapse-button-horizontal': isHorizontal, 'expand-collapse-button-vertical': !isHorizontal}">
                <i class="fal"
                   [ngClass]="{
                    'fa-angle-left': showChildren && isHorizontal,
                    'fa-angle-right': !showChildren && isHorizontal,
                    'fa-angle-up': showChildren && !isHorizontal,
                    'fa-angle-down': !showChildren && !isHorizontal,
                }"></i>
            </button>
        </div>
        <div *ngIf="hasChildren && showChildren"
             class="d-flex flex-column">
            <adapt-display-objectives-hierarchy *ngFor="let childGroup of objectiveGroup.childGroups; let first=first"
                                                class="ms-5 pe-3"
                                                [currentTeamId]="currentTeamId"
                                                [ngClass]="{'mt-3': !first}"
                                                (heightChange)="childHeightChanged()"
                                                [objectiveGroup]="childGroup"
                                                [isCompactView]="isCompactView"
                                                [applyFilter]="applyFilter">
            </adapt-display-objectives-hierarchy>
        </div>
    </div>
</div>

import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";

@Component({
    selector: "adapt-sidebar-tab-icon",
    templateUrl: "./sidebar-tab-icon.component.html",
    styleUrls: ["./sidebar-tab-icon.component.scss"],
})
export class SidebarTabIconComponent implements OnInit, OnChanges {
    @Input() public iconClass?: string;
    public iconClassValue?: string;

    @Input() public iconText?: string;

    public ngOnInit() {
        if (!this.iconClass) {
            throw new Error("Sidebar tab icon should be defined at ngOnInit");
        }

        // iconClass is set from render-component, which will cause "Expression has changed after it was checked"
        // error if used directly in the template.
        // Use iconClassValue in the template instead which is only changed at the right stage of the life-cycle hook
        this.iconClassValue = this.iconClass;
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.iconClass) {
            this.iconClassValue = this.iconClass;
        }
    }
}

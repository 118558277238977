import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MSAL_INSTANCE, MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { BrowserCacheLocation, IPublicClientApplication, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { Logger } from "@common/lib/logger/logger";
import { SentryLogProvider } from "@common/lib/logger/sentry-log-provider";
import { Trace } from "@common/lib/logger/trace";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { ConnectWithMicrosoftComponent } from "./connect-with-microsoft/connect-with-microsoft.component";
import { OAuthSettings } from "./microsoft-oauth";


let msalInstance: IPublicClientApplication | undefined;

export function MSALInstanceFactory(): IPublicClientApplication {
    const sentryLogger = Logger.getLogProviderOfType(SentryLogProvider);

    msalInstance =
        msalInstance ??
        new PublicClientApplication({
            auth: {
                ...OAuthSettings,
                // need to re-define it here as undefined before config is initialised
                clientId: AdaptClientConfiguration.MicrosoftClientId,
            },
            cache: {
                cacheLocation: BrowserCacheLocation.LocalStorage,
            },
            system: {
                loggerOptions: {
                    logLevel: LogLevel.Error,
                    loggerCallback: (level, message) => {
                        // strip the timestamp
                        message = message.split("GMT] : ").slice(1).join("] : ");
                        if (level === LogLevel.Error) {
                            // write errors straight to sentry. don't use normal logger as user will see error popup,
                            //  even though we are handling the errors manually.
                            sentryLogger?.write({
                                level: Trace.Error,
                                timestamp: new Date(),
                                moduleId: "MSAL",
                                message,
                                data: [],
                            });
                            return;
                        }
                    },
                },
            },
        });

    return msalInstance;
}


@NgModule({
    declarations: [
        ConnectWithMicrosoftComponent,
    ],
    imports: [
        CommonModule,
        AdaptButtonModule,
    ],
    providers: [
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        MsalService,
        MsalBroadcastService,
    ],
    exports: [
        ConnectWithMicrosoftComponent,
    ],
})
export class AdaptMicrosoftOAuthModule {}

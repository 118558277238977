import { ItemComment } from "@common/ADAPT.Common.Model/organisation/item-comment";
import { IChainComment } from "@org-common/lib/comment-chain/chain-comment/chain-comment.component";

// TODO: move this somewhere else
export class ItemChainComment implements IChainComment<ItemComment> {
    public constructor(
        public readonly entity: ItemComment,
        public canUpdate: boolean,
    ) { }

    public get person() {
        return this.entity.addedBy;
    }

    public get dateTime() {
        return this.entity.dateTime;
    }

    public get comment() {
        return this.entity.comment;
    }

    public set comment(value: string) {
        this.entity.comment = value;
    }
}

import { ExternalDashboard } from "@common/ADAPT.Common.Model/organisation/external-dashboard";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { INavigationHierarchy } from "@common/route/navigation-hierarchy";
import { INavigationNode } from "@common/route/navigation-node.interface";
import { NavigationUtilitiesService } from "@common/route/navigation-utilities.service";
import { AuthorisationNotificationService } from "@org-common/lib/authorisation/authorisation-notification.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { defer, EMPTY, merge, Observable, ObservableInput } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { organisationExternalDashboardPageRoute, teamExternalDashboardPageRoute } from "../external-dashboard/external-dashboard-page/external-dashboard-page.route";
import { OrganisationAuthService } from "../organisation/organisation-auth.service";

export abstract class BaseOrgCommonNavigationHierarchyService implements INavigationHierarchy {
    public abstract id: string;
    public hierarchyNode$: Observable<INavigationNode | undefined>;

    /** Set this in the implementing class to rebuild the hierarchy on a custom event */
    protected rebuildHierarchy$: Observable<unknown> = EMPTY;

    public constructor(
        orgService: OrganisationService,
        authNotification: AuthorisationNotificationService,
    ) {
        const deregisterHierarchy$ = merge(
            orgService.organisationChanging$,
            authNotification.authorisationChanging$,
        ).pipe(
            map(() => undefined),
        );
        const buildHierarchy$ = merge(
            authNotification.authorisationChanged$,
            // Defer so that if rebuildHierarchy is set by the implementing class
            // then it will actually be it's final value by the time this is
            // subscribed to
            defer(() => this.rebuildHierarchy$),
        ).pipe(
            switchMap(() => this.buildHierarchy()),
        );
        this.hierarchyNode$ = merge(
            deregisterHierarchy$,
            buildHierarchy$,
        );
    }

    protected abstract buildHierarchy(): ObservableInput<INavigationNode>;

    public promiseToBuildExternalDashboardNode(externalDashboard: ExternalDashboard, navUtilitiesFactory: NavigationUtilitiesService, team?: Team) {
        if (externalDashboard.openInNewTab) {
            return navUtilitiesFactory.nodeBuilder()
                .ifAuthorised(OrganisationAuthService.ViewExternalDashboard, team)
                .setTitle(externalDashboard.name)
                .setUrl(externalDashboard.url)
                .setIsExternalLink()
                .setIconClass("fal fa-fw fa-sm fa-external-link-alt")
                .setHideIconInBreadcrumb(true)
                .promiseToBuild();
        }

        let routeId = organisationExternalDashboardPageRoute.id;
        let nameParams;

        if (team) {
            routeId = teamExternalDashboardPageRoute.id;
            nameParams = { teamId: team.teamId };
        }
        return navUtilitiesFactory
            .nodeBuilderForControllerAndParams(routeId, nameParams, undefined, { link: externalDashboard.externalDashboardId })
            .ifAuthorised(OrganisationAuthService.ViewExternalDashboard, team)
            .setTitle(externalDashboard.name)
            .setIconClass("fal fa-fw fa-sm fa-browsers")
            .setHideIconInBreadcrumb(true)
            .promiseToBuild();
    }
}

import { Component, Input } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { PersonProfileCategory } from "@common/ADAPT.Common.Model/person/person-profile-category";
import { PersonProfileItem } from "@common/ADAPT.Common.Model/person/person-profile-item";
import { PersonProfileItemType } from "@common/ADAPT.Common.Model/person/person-profile-item-type";
import { PersonProfileItemValue } from "@common/ADAPT.Common.Model/person/person-profile-item-value";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { EditSimpleValueBreezeEntityDialogComponent, IEditSimpleEntity, IEditSimpleValueBreezeEntityDialogData, SimpleValueType } from "@common/ux/edit-simple-value-breeze-entity-dialog/edit-simple-value-breeze-entity-dialog.component";
import { PersonProfileService } from "@org-common/lib/person-profile/person-profile.service";
import { BehaviorSubject, combineLatest, lastValueFrom, Observable } from "rxjs";
import { map, startWith, switchMap } from "rxjs/operators";

interface IProfileItemAndValue {
    item: PersonProfileItem;
    value?: PersonProfileItemValue;
}

interface IProfileCategory {
    category: PersonProfileCategory;
    items: IProfileItemAndValue[];
    isEmpty: boolean;
}

@Component({
    selector: "adapt-person-profile-items",
    templateUrl: "./person-profile-items.component.html",
    styleUrls: ["./person-profile-items.component.scss"],
})
export class PersonProfileItemsComponent extends BaseComponent {
    @Input() public set person(value: Person | undefined) {
        this.person$.next(value);
    }

    @Input() public editMode = false;
    @Input() public disabled = false;

    private person$ = new BehaviorSubject<Person | undefined>(undefined);
    public categories$: Observable<IProfileCategory[]>;

    constructor(
        private personProfileService: PersonProfileService,
        private dialogService: AdaptCommonDialogService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        const entityChanged$ = rxjsBreezeService.entityTypeChangedInSave(PersonProfileItemValue)
            .pipe(
                startWith(undefined),
            );

        const itemValues$ = combineLatest([entityChanged$, this.person$])
            .pipe(
                map(([_ec, person]) => person),
                emptyIfUndefinedOrNull(),
                switchMap((person) => this.personProfileService.getProfileItemValues(person.personId)),
            );

        this.categories$ = combineLatest([this.personProfileService.getProfileCategories(), itemValues$])
            .pipe(
                map(([categories, values]) => {
                    return categories.map((category) => {

                        const items = category.personProfileItems
                            .sort(SortUtilities.getSortByFieldFunction("ordinal"))
                            .map((item) => ({
                                item,
                                value: values.find((a) => a.personProfileItemId === item.personProfileItemId),
                            } as IProfileItemAndValue));

                        const profileCategory: IProfileCategory = {
                            category,
                            items,
                            isEmpty: items.find((item) => item.value !== undefined) === undefined,
                        };
                        return profileCategory;
                    });
                }),
            );
    }

    public async editProfileItem(category: IProfileCategory) {
        const dlg: IEditSimpleValueBreezeEntityDialogData = {
            title: `Edit ${category.category.label} Items`,
            entities: [],
            saveOnClose: true,
        };

        for (const item of category.items) {
            const entity: IEditSimpleEntity = {
                label: item.item.label,
                entity: item.value
                    ? item.value
                    : await lastValueFrom(this.personProfileService.createProfileItemValue(item.item.personProfileItemId, this.person$.value!.personId)),
                fieldName: item.item.itemType === PersonProfileItemType.Date
                    ? "date"
                    : "text",
                type: item.item.itemType as unknown as SimpleValueType,
            };

            dlg.entities.push(entity);
        }

        return this.dialogService.open(EditSimpleValueBreezeEntityDialogComponent, dlg).subscribe();
    }
}

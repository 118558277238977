<div *ngIf="otherTeamMembers.length">
    <div class="d-flex">
        <div>
            <p>In addition to yourself, these people will receive a survey invitation:</p>
            <div *ngFor="let person of otherTeamMembers">
                <adapt-link-person [person]="person"
                                   [showImage]="true"></adapt-link-person>
            </div>
        </div>

        <!-- TODO: Experimenting with not showing an optional message. also uncomment check in understand-your-journey.cy.ts
            <div class="flex-fill ms-4">
                <h4>Optional note to include with the survey invitation</h4>
                <dx-text-area placeholder="Type your custom message here..."
                            [(value)]="customMessageText"
                            [readOnly]="startingSurvey"
                            [inputAttr]="{ rows: 5 }"
                            data-test="diagnostic-survey-note">
                </dx-text-area>
            </div>
        -->
    </div>
</div>

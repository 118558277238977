import { Injector } from "@angular/core";
import { TeamBreezeModel } from "@common/ADAPT.Common.Model/organisation/team";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { IBreezeModel } from "@common/lib/data/breeze-model.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { Observable } from "rxjs";
import { IAddedEntityInfo, IEntityAutoUpdaterTemplate } from "./entity-auto-updater-template.interface";

export class EntityAutoUpdaterTemplateBuilder<TEntity> {
    private autoUpdaterTemplate: Partial<IEntityAutoUpdaterTemplate<TEntity>> = {};

    public static forModel<T>(model: IBreezeModel<T>) {
        return new EntityAutoUpdaterTemplateBuilder(model);
    }

    protected constructor(private model: IBreezeModel<TEntity>) {
        this.autoUpdaterTemplate.entityModel = model;
    }

    public withSummaryHtml(summariser: (entity: TEntity, relatedEntities: IBreezeEntity[]) => string) {
        this.autoUpdaterTemplate.getHtmlSummary = summariser;
        return this;
    }

    public conflictsShouldBeAutomaticallyIntegrated(shouldIntegrate: boolean) {
        this.autoUpdaterTemplate.conflictsShouldBeAutomaticallyIntegrated = shouldIntegrate;
        return this;
    }

    /** Set up this auto updater to fetch new entities if the related entity is already in the local cache */
    public fetchAddedEntityIfRelatedEntityInCache() {
        return this.fetchingAddedEntityWhen((injector, addedEntityInfo) => {
            const commonDataService: CommonDataService = injector.get(CommonDataService);

            const hasAtLeastOneRelatedEntityInCache = addedEntityInfo.relatedEntities
                .some((re) => re.model && commonDataService.getLocalModelEntityById(re.model, re.entityId));

            return hasAtLeastOneRelatedEntityInCache;
        });
    }

    public primeAfterFetch(primeFunction?: (entity: TEntity) => Observable<unknown>) {
        this.autoUpdaterTemplate.primeCallback = primeFunction;
        return this;
    }

    /**
     * Set up this auto updater to fetch new entities if there are any existing entities of the same type
     * that match the same teamId.
     * e.g. Fetch new organisation objective if there are any organisation objectives already in the cache.
     * e.g. Fetch new team objective if there are any objectives for the same team already in the cache.
     * Note that the backend implementation must specify a related entity of type team for this to work
     */
    public fetchAddedEntityIfAnyExistingOptionalTeamEntityInCache() {
        return this.fetchingAddedEntityWhen((injector, addedEntityInfo) => {
            const commonDataService: CommonDataService = injector.get(CommonDataService);
            return addedEntityInfo.relatedEntities.some((re) => {
                if (re.model !== TeamBreezeModel) {
                    return false;
                }

                // Related entityId is non nullable on the back end, so we encode org objectives with teamId 0
                const comparisonTeamId = re.entityId > 0 ? re.entityId : null;
                const model = this.model as unknown as IBreezeModel<IBreezeEntity>;
                return commonDataService.hasLocalEntity(model, (i) => i.teamId === comparisonTeamId);
            });
        });
    }

    public fetchingAddedEntityWhen(shouldFetchAddedEntity: (injector: Injector, entityUpdate: IAddedEntityInfo) => boolean) {
        this.autoUpdaterTemplate.shouldFetchAddedEntityInfo = shouldFetchAddedEntity;
        return this;
    }

    public build(): IEntityAutoUpdaterTemplate<TEntity> {
        if (!this.autoUpdaterTemplate.getHtmlSummary || !this.autoUpdaterTemplate.shouldFetchAddedEntityInfo) {
            throw new Error("You must set all template properties");
        }

        return this.autoUpdaterTemplate as IEntityAutoUpdaterTemplate<TEntity>;
    }
}

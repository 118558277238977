import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptIdentityModule } from "@common/identity/identity.module";
import { AdaptStorageModule } from "@common/storage/storage.module";
import { AdaptDocumentSelectorModule } from "@common/ux/document-selector/document-selector.module";
import { EditorViewDirective } from "@common/ux/html-editor/editor-view.directive";
import { AdaptEditorViewModule } from "@common/ux/html-editor/editor-view.module";
import { FroalaEditorModule } from "angular-froala-wysiwyg";
import { AdaptHtmlEditorComponent } from "./html-editor.component";
import { AdaptHtmlEditorFroalaComponent } from "./html-editor-froala/html-editor-froala.component";
import { LoomFroala } from "./html-editor-froala/loom-froala";

@NgModule({
    imports: [
        CommonModule,
        AdaptEditorViewModule,
        AdaptStorageModule,
        AdaptIdentityModule,
        AdaptDocumentSelectorModule.forChild(),
        FroalaEditorModule,
    ],
    exports: [
        AdaptHtmlEditorComponent,
        EditorViewDirective,
    ],
    declarations: [
        AdaptHtmlEditorComponent,
        AdaptHtmlEditorFroalaComponent,
    ],
})
export class AdaptHtmlEditorModule {
    constructor() {
        // add loom to froala video providers
        LoomFroala.registerConfig();
    }
}

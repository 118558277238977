import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DxValidatorModule } from "devextreme-angular";
import { BreezeValidationRuleDirective } from "./breeze-validation-rule.directive";
import { EntityValidationRuleDirective } from "./entity-validation-rule.directive";
import { ValidateEntityDirective } from "./validate-entity.directive";

@NgModule({
    imports: [
        CommonModule,
        DxValidatorModule,
    ],
    exports: [
        DxValidatorModule,
        ValidateEntityDirective,
        EntityValidationRuleDirective,
        BreezeValidationRuleDirective,
    ],
    declarations: [
        ValidateEntityDirective,
        EntityValidationRuleDirective,
        BreezeValidationRuleDirective,
    ],
})
export class AdaptEntityValidatorModule { }

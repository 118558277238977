import { Component, Inject } from "@angular/core";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { ValueChangedEvent } from "devextreme/ui/text_box";
import { DocumentDescriptor } from "../document-descriptor";

@Component({
    selector: "adapt-select-document-dialog",
    templateUrl: "./select-document-dialog.component.html",
})
export class SelectDocumentDialogComponent extends BaseDialogComponent<DocumentDescriptor | undefined, DocumentDescriptor> {
    public readonly dialogName = "SelectDocument";

    public dialogTitle: string;
    public name = "";
    public nameSet = false;
    public selectedDoc?: DocumentDescriptor;

    constructor(
        @Inject(ADAPT_DIALOG_DATA) data?: DocumentDescriptor,
    ) {
        super();

        let url: string | undefined;

        if (data) {
            this.name = data.getName();
            url = data.getUrl();
        }

        // name already provided, don't overwrite it!
        if (this.name) {
            this.nameSet = true;
        }

        this.selectedDoc = new DocumentDescriptor(this.name, url);

        this.dialogTitle = this.selectedDoc.getUrl()
            ? "Edit Link"
            : "Insert Link";
    }

    public ok() {
        if (this.selectedDoc) {
            this.selectedDoc.setName(this.name);
            this.resolve(this.selectedDoc);
        }
    }

    public onValueChange(event: ValueChangedEvent) {
        // event is undefined when value is changed programmatically (i.e. setting this.name)
        if (event.event !== undefined) {
            this.nameSet = true;
        }
    }

    public onSelectedDocumentChange(document?: DocumentDescriptor) {
        this.selectedDoc = document;

        if (this.selectedDoc) {
            if (!this.name || !this.nameSet) {
                this.name = this.selectedDoc.getName();
            }
        }
        this.clearErrorMessage();
    }
}

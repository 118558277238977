import { IActiveEntity } from "@common/ADAPT.Common.Model/activeEntity.interface";
import { Validator } from "breeze-client";
import { StringUtilities } from "../utilities/string-utilities";

export class BreezeModelUtilities {
    // this is to validate if the string property is empty
    // when this is added, it is only used for itemComment
    public static NonEmptyStringPropertyValidator = new Validator(
        "entityNonEmptyStringPropertyValidator",
        BreezeModelUtilities.entityNonEmptyStringPropertyValidatorFunction,
        {
            messageTemplate: "This field should not be empty",
        },
    );

    // entityIdValidator defined here as it can be potentially used to validate entity model
    // which requires assigned entity (entityId != 0), e.g. RoleConnection requires valid connectionId and roleId
    public static EntityIdValidator = new Validator(
        "entityIdValidator",
        BreezeModelUtilities.entityIdValidatorFunction,
        {
            messageTemplate: "Entity ID must have non-zero.",
        },
    );

    public static getActive<T extends IActiveEntity>(temporalArray: T[]) {
        return temporalArray.filter((element: T) => element.isActive());
    }

    /**
     * Gets the latest or active element of an ADAPT temporal array.
     * @param {Array} temporalArray The array of temporal breeze entities to be traversed.
     * @returns {Object} The latest or active object
     */
    public static getLatestOrActive<T extends IActiveEntity>(temporalArray: T[]) {
        if (temporalArray && Array.isArray(temporalArray) && temporalArray.length > 0) {
            let lastItem = temporalArray[0];

            if (!lastItem.endDate) {
                return lastItem;
            }

            for (const testItem of temporalArray) {
                if (!testItem.endDate) {
                    return testItem;
                }

                if (testItem.endDate > lastItem.endDate!) {
                    lastItem = testItem;
                }
            }

            return lastItem;
        }

        return undefined;
    }

    private static entityNonEmptyStringPropertyValidatorFunction(propertyValue: any) {
        return StringUtilities.isString(propertyValue) && !!StringUtilities.trimHtml(propertyValue);
    }

    private static entityIdValidatorFunction(entityId: any) {
        return !!entityId;
    }
}

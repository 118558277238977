import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptCommonDataModule } from "@common/lib/data/common-data.module";
import { AdaptStorageModule } from "../storage/storage.module";
import { AdaptTooltipModule } from "../ux/tooltip.directive/tooltip.module";
import { ContactDetailComponent } from "./contact-detail/contact-detail.component";
import { PersonDefaultImageComponent } from "./person-default-image/person-default-image.component";
import { PersonImageComponent } from "./person-image/person-image.component";

@NgModule({
    imports: [
        CommonModule,
        AdaptTooltipModule,
        AdaptStorageModule,
        AdaptCommonDataModule,
    ],
    exports: [
        PersonImageComponent,
        ContactDetailComponent,
        PersonDefaultImageComponent,
    ],
    declarations: [
        PersonImageComponent,
        ContactDetailComponent,
        PersonDefaultImageComponent,
    ],
})
export class AdaptCommonUserModule { }

import { Injectable } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { from, of } from "rxjs";
import { tap } from "rxjs/operators";
import { ISearchProviderOptions, SearchType } from "../search.interface";
import { SearchProvider } from "../search-provider";
import { ITeamSearchResult, PersonTeamRoleResultType } from "../search-results.interface";

@Injectable()
export class TeamSearchProvider extends SearchProvider<ITeamSearchResult> {
    public readonly Type = SearchType.PersonTeamRole;

    public teams: Team[] = [];

    public constructor(private teamsService: CommonTeamsService) {
        super();
    }

    public shouldSkip(options: ISearchProviderOptions): boolean {
        return !!options.teamId || !!options.personId || !!options.updatedSince;
    }

    public initialise() {
        return from(this.teamsService.promiseToGetAllTeams()).pipe(
            tap((teams: Team[]) => this.teams = teams),
        );
    }

    public execute({ keyword, activeOnly, labelIds }: ISearchProviderOptions) {
        const hasLabels = !!labelIds && labelIds.size > 0;
        return of(this.teams
            .filter((t) => !activeOnly || t.isActive())
            .filter((t) => !hasLabels || t.labelLocations.some((ll) => labelIds?.has(ll.labelId)))
            .filter((t) => !keyword || t.name.toLowerCase().includes(keyword?.toLowerCase()))
            .slice(0, 10)
            .map((team) => ({ type: PersonTeamRoleResultType.Team, team } as ITeamSearchResult)));
    }
}

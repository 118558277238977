import { Component, ElementRef, Inject, Input, OnChanges } from "@angular/core";
import { Workflow } from "@common/ADAPT.Common.Model/embed/workflow";
import { GuidedTour, GuidedTourService } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourRegistry } from "@common/lib/guided-tour/guided-tour-registrar";
import { PERSONAL_DASHBOARD_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { WorkflowAuthService } from "@org-common/lib/workflow/workflow-auth.service";

@Component({
    selector: "adapt-workflow-journey-wrap-up-card",
    templateUrl: "./workflow-journey-wrap-up-card.component.html",
    styleUrls: ["./workflow-journey-wrap-up-card.component.scss"],
})
export class WorkflowJourneyWrapUpCardComponent extends BaseComponent implements OnChanges {
    @Input() public workflow!: Workflow;

    public wrapUpTour?: GuidedTour;
    public personalDashboardRoute$ = this.personalDashboardPageRoute.getRoute();
    public hasWorkflowAccess = true;

    constructor(
        elementRef: ElementRef,
        private authorisationService: AuthorisationService,
        private guidedTourService: GuidedTourService,
        @Inject(PERSONAL_DASHBOARD_PAGE) private personalDashboardPageRoute: IAdaptRoute<{}>,
    ) {
        super(elementRef);
    }

    public async ngOnChanges() {
        if (this.workflow?.wrapUpGuidedTourIdentifier) {
            this.wrapUpTour = GuidedTourRegistry.get(this.workflow.wrapUpGuidedTourIdentifier);
            this.hasWorkflowAccess = await this.authorisationService.promiseToGetHasAccess(WorkflowAuthService.EditWorkflow);
        }
    }

    public runWrapUpTour() {
        if (this.wrapUpTour) {
            this.guidedTourService.run(this.wrapUpTour);
        }
    }
}

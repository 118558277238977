import { Component, ElementRef, Injector, OnInit } from "@angular/core";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { WorkflowService } from "../workflow.service";

@Component({
    selector: "adapt-workflows-page",
    templateUrl: "./workflows-page.component.html",
})
export class WorkflowsPageComponent extends BaseRoutedComponent implements OnInit {

    public constructor(
        injector: Injector,
        elementRef: ElementRef,
        private workflowService: WorkflowService,
    ) {
        super(injector, elementRef);
    }

    public ngOnInit() {
        this.workflowService.continueWorkflowFromSearchParam().pipe(
            this.takeUntilDestroyed(),
        ).subscribe();

        this.removeDefaultShellPadding();
        this.notifyActivated();
    }
}

export const WorkflowsPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-workflows-page", WorkflowsPageComponent)
    .atOrganisationUrl("/pathways")
    .withTitle("Pathways")
    .build();


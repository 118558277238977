import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { DxScrollViewModule } from "devextreme-angular";
import { CollapseModule } from "ngx-bootstrap/collapse";
import { AdaptButtonModule } from "../button/button.module";
import { AdaptTooltipModule } from "../tooltip.directive/tooltip.module";
import { CollapsibleDashboardElementComponent } from "./collapsible-dashboard-element.component";


@NgModule({
    declarations: [
        CollapsibleDashboardElementComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,

        DxScrollViewModule,
        AdaptButtonModule,
        AdaptTooltipModule,

        CollapseModule,
    ],
    exports: [
        CollapsibleDashboardElementComponent,
    ],
})
export class AdaptCollapsibleDashboardElementModule { }

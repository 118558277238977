import { Directive, ElementRef, OnDestroy } from "@angular/core";
import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { Logger } from "@common/lib/logger/logger";
import { ElementUtilities } from "@common/lib/utilities/element-utilities";
import { ElementResizeHandler } from "@common/ux/base.component/element-resize-handler";
import dxScrollView from "devextreme/ui/scroll_view";
import { asapScheduler, asyncScheduler, interval, Subject } from "rxjs";
import { debounceTime, delay, filter, first, share, takeUntil, throttleTime } from "rxjs/operators";

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseComponent implements OnDestroy {
    public isInitialised: boolean = false;

    public isAlto = AdaptClientConfiguration.AdaptProjectName === AdaptProject.Alto;

    // Even though constructor.name will be minified, we only really care about it
    // for local development where this won't occur
    protected readonly log = Logger.getLogger(this.constructor.name);

    protected ImplementationKitArticle = ImplementationKitArticle;

    private _isDestroyed = false;
    private destroyed$ = new Subject<void>();

    private listenerDeregistrations = Array<VoidFunction>();
    private resizeHandler?: ElementResizeHandler;
    private onSizeChanged$?: Subject<void>;

    private isInitialisedUpdater = this.createThrottledUpdater((isInitialised: boolean) => this.isInitialised = isInitialised, 10, false);
    // this private flag won't be used by template -> can be set without throttled updater to avoid unnecessary emit which slows the rendering
    // - it will make sure no subsequent check after instance gets into view
    // - isInitialised will be used in template and need to be guarded by the throttled updater above to resolve ExpressionChangedAfterItHasBeenCheckedError
    private isElementInView = false;

    public constructor(protected elementRef?: ElementRef) { }

    public get isDestroyed() {
        return this._isDestroyed;
    }

    public ngOnDestroy() {
        this._isDestroyed = true;
        this.destroyed$.next();
        this.destroyed$.complete();

        this.listenerDeregistrations.forEach((f) => f());

        if (this.resizeHandler && this.resizeHandler.isWatching) {
            this.resizeHandler.stopWatching();
        }

        if (this.onSizeChanged$) {
            this.onSizeChanged$.complete();
        }
    }

    public scrollIntoView() {
        this.elementRef?.nativeElement.scrollIntoView({ behavior: "smooth" });
    }

    // This is used to throttle changes to avoid ExpressionChangedAfterItHasBeenCheckedError.
    protected createThrottledUpdater<T>(updateCallback: (emitValue: T) => void, throttleMsec = 100, useAsyncScheduler = true) {
        const updater = new Subject<T>();
        updater.pipe(
            // take the latest value to allow update during the throttle period
            throttleTime(throttleMsec, asyncScheduler, { leading: true, trailing: true }),
            delay(0, useAsyncScheduler ? asyncScheduler : asapScheduler), // next digest cycle to avoid ExpressionCHangedAfterItHasBeenCheckedError
            share(),
            this.takeUntilDestroyed(),
        ).subscribe(updateCallback);

        return updater;
    }

    // Wait for dxScrollView within the component to be visible and then call update on it
    // - this is applicable to dx-scroll-view with showScrollbar always so that the scrollbar is actually updated, not showing when scrollbar is not required
    protected updateScrollViewWhenVisible(scrollView: dxScrollView) {
        const element = jQuery(scrollView.element()).get(0);
        if (element) {
            interval(500).pipe(
                filter(() => ElementUtilities.isVisible(element)),
                first(),
                this.takeUntilDestroyed(),
            ).subscribe(() => scrollView.update());
        }
    }

    /** Use this method to prevent memory leaks when subscribing directly in a component.
     * This will transform the observable to only emit values while the component is in a
     * valid state (i.e. until it gets destroyed)
     * You don't need this if the Observable is used with the async pipe in the template.
     */
    protected takeUntilDestroyed<T>() {
        // If we use takeWhile, then it will only unsubscribe after the next emit from
        // the source. takeUntil will unsubscribe as soon as this.destroyed emits.
        return takeUntil<T>(this.destroyed$);
    }

    protected get sizeChange$() {
        if (!this.elementRef) {
            throw new AdaptError("ElementRef must be set to use resize handler!");
        }

        if (!this.onSizeChanged$) {
            this.onSizeChanged$ = new Subject<void>();
        }

        if (!this.resizeHandler) {
            this.resizeHandler = new ElementResizeHandler(jQuery(this.elementRef.nativeElement));
        }

        if (!this.resizeHandler.isWatching) {
            this.resizeHandler.startWatching();
            this.resizeHandler.registerHandler(() => this.onSizeChanged$!.next());
        }

        return this.onSizeChanged$.asObservable().pipe(
            debounceTime(200), // prevent excessive number of refresh when resizing
        );
    }

    protected forceInitialiseElement(callbackIfInitialised?: () => void) {
        if (!this.isElementInView) {
            this.isElementInView = true;
            this.isInitialisedUpdater.next(true);
            if (callbackIfInitialised) {
                // callback after updater (throttled at 10)
                setTimeout(() => callbackIfInitialised(), 20);
            }
        }
    }

    protected setInitialiseWhenElementComesIntoView(callbackIfInitialised?: () => void) {
        if (!this.elementRef) {
            throw new AdaptError("elementRef must be set in the super constructor to use this function");
        }

        if (!this.isElementInView) {
            // initialize when element comes into view
            const boundingClientRect = (this.elementRef!.nativeElement as HTMLElement).getBoundingClientRect();
            const elementTop = boundingClientRect.top;
            const elementBottom = elementTop + boundingClientRect.bottom;
            if ((elementTop >= 0 && elementTop <= window.innerHeight) || (elementBottom >= 0 && elementBottom <= window.innerHeight)) {
                this.isElementInView = true;
                this.isInitialisedUpdater.next(true);
                if (callbackIfInitialised) {
                    // callback after updater (throttled at 10)
                    setTimeout(() => callbackIfInitialised(), 20);
                }
            }
        }
    }
}

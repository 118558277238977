import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptTooltipModule } from "../tooltip.directive/tooltip.module";
import { DashboardElementComponent } from "./dashboard-element.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AdaptTooltipModule,
    ],
    exports: [
        DashboardElementComponent,
    ],
    declarations: [
        DashboardElementComponent,
    ],
})
export class AdaptDashboardElementModule { }

<div [ngClass]="{
        'board-icon-coloured': showColouredIcon,
        'personal-board': board && board.isPersonalBoard,
        'team-board': board && board.isTeamBoard
     }">
    <dx-drop-down-box [(value)]="selectedIds"
                      [dataSource]="treeViewDataSource"
                      valueExpr="id"
                      displayExpr="label"
                      (onInitialized)="onDropdownInitialize($event.component!)"
                      (onValueChanged)="onDropdownValueChanged()"
                      [(opened)]="isDropdownOpen">
        <div *dxTemplate="let data of 'content'">
            <dx-tree-view class="dropdown-treeview"
                          [dataSource]="treeViewDataSource"
                          dataStructure="plain"
                          keyExpr="id"
                          parentIdExpr="parentId"
                          displayExpr="label"
                          selectionMode="multiple"
                          showCheckBoxesMode="selectAll"
                          selectAllText="Select all boards"
                          noDataText="No boards fit your search criteria"
                          (onInitialized)="onTreeViewInitialize($event.component)"
                          [selectByClick]="true"
                          [searchEnabled]="true"
                          (onSelectionChanged)="onTreeViewSelectionChanged($event.component.getSelectedNodeKeys())"
                          (onItemRendered)="onTreeViewItemRendered($event)">
            </dx-tree-view>
        </div>
    </dx-drop-down-box>
</div>

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptProgressBarModule } from "@common/ux/progress-bar/progress-bar.module";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { AdaptKanbanSharedModule } from "@org-common/lib/kanban/kanban-shared.module";
import { AdaptLabellingModule } from "@org-common/lib/labelling/labeling.module";
import { AdaptObjectivesSharedModule } from "../objectives-shared.module";
import { DisplayTreeObjectiveComponent } from "./display-tree-objective.component";

@NgModule({
    imports: [
        CommonModule,
        AdaptDateModule,
        AdaptProgressBarModule,
        AdaptDirectorySharedModule,
        AdaptObjectivesSharedModule,
        AdaptKanbanSharedModule,
        AdaptLabellingModule,
    ],
    exports: [
        DisplayTreeObjectiveComponent,
    ],
    declarations: [
        DisplayTreeObjectiveComponent,
    ],
})
export class AdaptDisplayTreeObjectiveModule { }

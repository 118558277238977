import { Component, Input } from "@angular/core";
import { ZoneMetadata } from "@common/ADAPT.Common.Model/methodology/zone";
import { KeyFunction } from "@common/ADAPT.Common.Model/organisation/key-function";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { Observable, ReplaySubject } from "rxjs";
import { filter, map, startWith, switchMap } from "rxjs/operators";
import { KeyFunctionsService } from "../key-functions.service";

@Component({
    selector: "adapt-key-function-card",
    templateUrl: "./key-function-card.component.html",
    styleUrls: ["./key-function-card.component.scss"],
})
export class KeyFunctionCardComponent {
    public readonly ZoneMetadata = ZoneMetadata.BackgroundStyleClass;
    @Input() public set keyFunction(value: KeyFunction | undefined) {
        if (value) {
            this.keyFunction$.next(value);
        }
    }
    public keyFunction$ = new ReplaySubject<KeyFunction>(1);

    public leaderPerson$: Observable<Person | undefined>;

    public constructor(
        private rxjsBreezeService: RxjsBreezeService,
        keyFunctionsService: KeyFunctionsService,
    ) {
        this.leaderPerson$ = this.keyFunction$.pipe(
            switchMap((kf) => this.leaderWillUpdate(kf)),
            switchMap((kf) => keyFunctionsService.getKeyFunctionLeader(kf)),
        );
    }

    private leaderWillUpdate(keyFunction: KeyFunction) {
        return this.rxjsBreezeService.entityTypeChanged(RoleConnection).pipe(
            filter((rc) => rc.roleId === keyFunction.leaderRoleId),
            map(() => keyFunction),
            startWith(keyFunction),
        );
    }
}

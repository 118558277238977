<ng-container *ngIf="note">
    <div class="my-1 d-flex flex-row align-items-start note-action-container meeting-note-button-panel-container justify-content-between">
        <div class="d-flex pt-1">
            <i [adaptTooltip]="tooltip"
               [ngClass]="iconClass"
               class="me-2"></i>
            <div [froalaView]="note.content"
                 class="left-of-button-panel"></div>
        </div>
        <div *ngIf="edit"
             [ngClass]="{'button-panel-visible': menuVisible}"
             class="button-panel ps-1 m-n1">
            <adapt-menu [items]="actionMenu"
                        [showOnHover]="true"
                        (visibleChanged)="menuVisible = $event"></adapt-menu>
        </div>
    </div>
</ng-container>

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DxFileUploaderModule } from "devextreme-angular";
import { SelectImageComponent } from "./select-image/select-image.component";
import { StorageImageComponent } from "./storage-image/storage-image.component";

@NgModule({
    imports: [
        CommonModule,
        DxFileUploaderModule,
    ],
    exports: [
        SelectImageComponent,
        StorageImageComponent,
    ],
    declarations: [
        SelectImageComponent,
        StorageImageComponent,
    ],
})
export class AdaptStorageModule { }

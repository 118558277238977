<div *ngIf="agendaItems.length > 0; else noItemTemplate"
     class="agenda-items">
    <adapt-edit-agenda-item *ngFor="let item of agendaItems"
                            class="p-2 dx-list-item"
                            [ngClass]="{'pre-work': item.extensions.isPreWork}"
                            [item]="item"
                            [readonly]="true"
                            [expandOnInit]="expandItemsOnInit"
                            [hideProgressIcon]="hideProgressIcon"
                            [hideNotesAndItems]="hideNotesAndItems"
                            (expandDetailsChange)="expandChanged($event)"
                            (canExpandChange)="canExpandChanged($event)"></adapt-edit-agenda-item>
    <div class="total-duration-container">
        <p><i class="fal fa-clock pe-1"></i> <strong>Total Time:</strong> {{totalPlannedDurationInMinutes | adaptMinutesToDuration}}</p>
    </div>
</div>
<ng-template #noItemTemplate>
    <div class="mt-3">
        <adapt-call-to-action-inline>
            There is no meeting agenda.
        </adapt-call-to-action-inline>
    </div>
</ng-template>

// eslint-disable-next-line max-classes-per-file
import { Injectable } from "@angular/core";
import { MsalBroadcastService, MsalService } from "@azure/msal-angular";
import { AccountInfo, AuthenticationResult, BrowserAuthError, EventMessage, EventType, InteractionRequiredAuthError, InteractionType, PublicClientApplication, ServerError } from "@azure/msal-browser";
import { CalendarIntegrationProvider } from "@common/ADAPT.Common.Model/organisation/organisation-detail";
import { Logger } from "@common/lib/logger/logger";
import { LocalStorage } from "@common/lib/storage/local-storage";
import { AfterInitialisationObservable } from "@common/service/after-initialisation.decorator";
import { BaseInitialisationService } from "@common/service/base-initialisation.service";
import { Client } from "@microsoft/microsoft-graph-client";
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { EMPTY, ReplaySubject, Subject, throwError } from "rxjs";
import { catchError, finalize, switchMap, tap } from "rxjs/operators";
import { IOAuthUser } from "../../calendar/calendar.interface";
import { OrganisationService } from "../../organisation/organisation.service";
import { IOAuthService } from "../oauth.interface";
import { OAuthService } from "../oauth.service";
import { OAuthSettings } from "./microsoft-oauth";

export class MicrosoftGraphClientError extends Error {}

@Injectable({ providedIn: "root" })
export class MicrosoftAuthService extends BaseInitialisationService implements IOAuthService {
    protected log = Logger.getLogger(MicrosoftAuthService.name);

    public user: IOAuthUser | undefined;
    public graphClient?: Client;
    private authProvider?: AuthCodeMSALBrowserAuthenticationProvider;

    private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
    private errorMessageSubject = new Subject<string>();

    private refreshAttempted = false;

    public constructor(
        private organisationService: OrganisationService,
        private msalService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
    ) {
        super();

        this.msalService.instance.enableAccountStorageEvents();
        this.msalBroadcastService.msalSubject$.subscribe((msg: EventMessage) => {
            const successEvents: EventType[] = [EventType.LOGIN_SUCCESS, EventType.ACQUIRE_TOKEN_SUCCESS, EventType.SSO_SILENT_SUCCESS, EventType.ACCOUNT_ADDED, EventType.ACCOUNT_REMOVED];
            const failureEvents: EventType[] = [EventType.LOGIN_FAILURE, EventType.ACQUIRE_TOKEN_FAILURE, EventType.SSO_SILENT_FAILURE];
            if (successEvents.includes(msg.eventType)) {
                this.handleAuthenticationSuccess(msg);
            }
            if (failureEvents.includes(msg.eventType)) {
                this.handleAuthenticationError(msg);
            }
        });
    }

    protected initialisationActions() {
        const initialiseObservable = this.msalService.handleRedirectObservable().pipe(
            tap(() => {
                const accounts = this.msalService.instance.getAllAccounts();
                if (accounts.length > 0) {
                    this.updateFromAccount(accounts[0]);
                }
            }),
        );

        return [initialiseObservable];
    }

    // Hot observable that emits on subscribe, and every time authentication changes
    public get isAuthenticated$() {
        return this.waitUntilInitialised().pipe(
            switchMap(() => this.isAuthenticatedSubject.asObservable()),
        );
    }

    // Hot observable that emits on error, and every time error changes
    public get errorMessage$() {
        return this.waitUntilInitialised().pipe(
            switchMap(() => this.errorMessageSubject.asObservable()),
        );
    }

    // Prompt the user to sign in and grant consent to the requested permission scopes
    @AfterInitialisationObservable
    public login() {
        const currentOrgId = this.organisationService.getOrganisationId();
        const lastLoginOrgId = LocalStorage.get<number>(OAuthService.GetLastProviderOrganisationLocalStorageKey(CalendarIntegrationProvider.Microsoft));

        return this.msalService.loginPopup({
            ...OAuthSettings,
            // always prompt for select_account so that multi-tenant people can change account easily
            // ... unless the org ID is the same as the last login org ID.
            prompt: lastLoginOrgId !== currentOrgId ? "select_account" : undefined,
        }).pipe(
            catchError((err) => this.handleLoginError(err)),
        );
    }

    private handleLoginError(err: any) {
        const standardMessage = "Failed to sign in using Microsoft. Please try again later";

        let message = err.toString();
        let unhandledError = false;
        if (err instanceof ServerError) {
            message = standardMessage;
        } else if (err instanceof BrowserAuthError) {
            switch (err.errorCode) {
                case "empty_window_error":
                case "popup_window_error":
                    message = "Popups are required to sign in using Microsoft. Please allow popups and try again.";
                    break;
                case "user_cancelled":
                    message = undefined;
                    break;
                default:
                    unhandledError = true;
                    message = standardMessage;
                    break;
            }
        } else {
            unhandledError = true;
            message = standardMessage;
        }

        this.errorMessageSubject.next(message);
        this.updateFromAccount(null);

        // don't need to show an error toaster or log since we handled the error
        return unhandledError
            ? throwError(() => err)
            : EMPTY;
    }

    // Sign out
    @AfterInitialisationObservable
    public logout() {
        return this.msalService.logoutRedirect({
            // Return false to stop navigation after local logout
            // see: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/logout.md#skipping-the-server-sign-out
            onRedirectNavigate: () => false,
        }).pipe(
            finalize(() => this.updateFromAccount(null)),
        );
    }

    private handleAuthenticationSuccess(msg: EventMessage) {
        this.log.info("Microsoft authentication succeeded", msg);
        const payload = msg.payload as AuthenticationResult;
        this.refreshAttempted = false;

        // only react to ACQUIRE_TOKEN_SUCCESS when the account details have changed
        const activeAccount = this.msalService.instance.getActiveAccount();
        if (msg.eventType !== EventType.ACQUIRE_TOKEN_SUCCESS || payload.account.homeAccountId !== activeAccount?.homeAccountId) {
            this.updateFromAccount(payload.account);
        }
    }

    private async handleAuthenticationError(msg: EventMessage) {
        this.log.warn("Microsoft authentication failed", msg);

        const activeAccount = this.msalService.instance.getActiveAccount();
        const isTimeoutError = msg.error instanceof BrowserAuthError && msg.error.errorCode === "monitor_window_timeout";

        // AADSTS700084:
        //  The refresh token was issued to a single page app (SPA), and therefore has a fixed, limited lifetime of 1.00:00:00, which cannot be extended.
        //  It is now expired and a new sign in request must be sent by the SPA to the sign in page.
        const isExpiredRefreshToken = msg.error?.message.includes("AADSTS700084");

        // attempt a refresh if there is an active account
        if (activeAccount) {
            if (!this.refreshAttempted) {
                this.refreshAttempted = true;
                const authResult = await this.msalService.instance.acquireTokenSilent({ ...OAuthSettings, account: activeAccount })
                    .catch((error) => {
                        if (error instanceof InteractionRequiredAuthError || isTimeoutError || isExpiredRefreshToken) {
                            // fallback to interaction when silent call fails
                            return this.msalService.instance.acquireTokenPopup({ ...OAuthSettings, account: activeAccount });
                        }
                        return undefined;
                    })
                    .catch((error) => {
                        this.log.warn("Refreshing Microsoft authentication failed", error);
                        return undefined;
                    });

                if (authResult) {
                    this.updateFromAccount(authResult.account);
                    this.refreshAttempted = false;
                    return;
                }
            }

            // for some reason this error does not occur in QA, so check for the monitor_window_timeout error instead.
            // we don't want to automatically try logging in again if its monitor_window_timeout.
            if (this.refreshAttempted || isTimeoutError || isExpiredRefreshToken) {
                this.errorMessageSubject.next("Your Microsoft session has expired, please log in again.");
            }

            this.updateFromAccount(null);
        }
    }

    // Update the local account state from an AccountInfo object
    private updateFromAccount(account: AccountInfo | null) {
        this.msalService.instance.setActiveAccount(account);
        this.graphClient = account ? this.getGraphClient(account) : undefined;
        this.user = account ? this.getUser(account) : undefined;
        if (this.user && !this.user.profilePicture) {
            this.getUserPicture().then((pic) => {
                // before setting the pic, make sure user is still defined (since the MS service might have reset it due to auth expiry, etc)
                if (this.user) {
                    this.user.profilePicture = pic;
                }
            });
        }
        this.isAuthenticatedSubject.next(!!this.user);
    }

    // Get the authentication provider for the given account
    private getAuthProvider(account: AccountInfo) {
        return new AuthCodeMSALBrowserAuthenticationProvider(
            this.msalService.instance as PublicClientApplication,
            {
                account,
                scopes: OAuthSettings.scopes,
                interactionType: InteractionType.Popup,
            },
        );
    }

    // Get the microsoft graph client instance for the given account
    private getGraphClient(account: AccountInfo) {
        this.authProvider = this.getAuthProvider(account);

        return Client.initWithMiddleware({
            defaultVersion: "beta",
            fetchOptions: {
                headers: {
                    Prefer: "outlook.timezone=\"UTC\"",
                },
            },
            authProvider: this.authProvider,
        });
    }

    // Get the user info from the given account
    private getUser(account: AccountInfo) {
        const user: IOAuthUser = {
            displayName: account.name ?? account.idTokenClaims?.name ?? "",
            email: account.username ?? account.idTokenClaims?.preferred_username ?? account.idTokenClaims?.email ?? "",
            userId: account.homeAccountId,
        };
        this.log.info("Got user", { account, user });
        return user;
    }

    // Get the profile pic of the current user
    private async getUserPicture() {
        if (!this.graphClient) {
            throw new MicrosoftGraphClientError("Graph client is not initialized");
        }

        // this call can fail if there is no user picture set.
        try {
            const result = await this.graphClient
                .api("/me/photo/$value")
                .get();
            const blobUrl = window.URL.createObjectURL(result);
            this.log.debug("Got profile picture", { result, blobUrl });
            return blobUrl;
        } catch (e) {
            return undefined;
        }
    }
}

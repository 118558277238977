import { Observable } from "rxjs";
import { ISidebarTab } from "./sidebar-tab";

export enum SidebarState {
    Open,
    Transitioning,
    Closed,
}

export interface ISidebar {
    state$: Observable<SidebarState>;
    activeTab$: Observable<ISidebarTab | undefined>;
    toggleSidebarOpenClosed(): void;
    focusTab(tabId: string): void;
    tabIsEnabled(tabId: string): boolean;
}

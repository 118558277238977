import { Injectable } from "@angular/core";
import { IShellPopoverLink, IShellPopoverLinkItem } from "@common/shell/shell-popover-link-item/shell-popover-link-item";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { ConfigurationAuthService } from "@org-common/lib/configuration/configuration-auth.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { combineLatest, Observable, switchMap } from "rxjs";
import { map } from "rxjs/operators";
import { coachAccessPageRoute } from "../coach-access-page/coach-access-page.component";
import { ApplicationBarCoachAccessItemComponent } from "./application-bar-coach-access-item.component";

@Injectable()
export class ApplicationBarCoachAccessItem implements IShellPopoverLinkItem {
    public readonly link: IShellPopoverLink;
    public readonly linkContent = {
        component: ApplicationBarCoachAccessItemComponent,
    };
    // just before the user item
    public readonly ordinal = 998;
    public readonly isShown$: Observable<boolean>;
    public readonly isInternal = true;

    public constructor(
        responsiveService: ResponsiveService,
        orgService: OrganisationService,
        authService: AuthorisationService,
    ) {
        this.link = {
            href$: orgService.currentOrganisation$.pipe(
                switchMap(() => coachAccessPageRoute.getRoute()),
            ),
            openInNewTab: false,
        };

        this.isShown$ = combineLatest([
            responsiveService.currentBreakpoint$,
            orgService.currentOrganisation$,
            authService.getHasAccess(ConfigurationAuthService.ConfigureOrganisationBilling),
        ]).pipe(
            map(([b, currentOrg, hasBillingAccess]) => b.isDesktopSize && !!currentOrg && hasBillingAccess),
        );
    }
}

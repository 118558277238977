import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Role } from "./role";

export class RoleSupplementaryData extends BaseEntity<RoleSupplementaryData> {
    public roleId!: number;
    public capabilities?: string;
    public attributes?: string;
    public relationships?: string;
    public keyAccountabilities?: string;
    public role!: Role;

    public isEmpty() {
        return !this.capabilities && !this.attributes && !this.relationships && !this.keyAccountabilities;
    }
}

export const RoleSupplementaryDataBreezeModel = new BreezeModelBuilder("RoleSupplementaryData", RoleSupplementaryData)
    .withPluralName("RoleSupplementaryData")
    .hasSource("RoleSupplementaryData")
    .withIdField("roleId")
    .isOrganisationEntity()
    .build();

<adapt-dialog [maxWidth]="600"
              [showGuidancePanelOnOpen]="true">
    <h3 adapt-dialog-title>{{addMode ? "Add" : "Edit"}} board</h3>

    <div adapt-dialog-content>
        <form role="form"
              class="pb-1">
            <div class="row">
                <div class="col-md-9">
                    <!-- name -->
                    <div class="form-group">
                        <label for="inputName">Board name</label>
                        <dx-text-box #focus
                                     id="inputName"
                                     [(value)]="board.name"
                                     [adaptValidateEntity]="board"
                                     adaptValidateEntityProperty="name"
                                     (onValueChanged)="onBoardNameChange()"></dx-text-box>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-9">
                    <!-- prefix -->
                    <div class="form-group">
                        <label for="inputPrefix">Action prefix</label>
                        <dx-text-box id="inputPrefix"
                                     [(value)]="board.itemPrefix"
                                     [maxLength]="5"
                                     [adaptValidateEntity]="board"
                                     adaptValidateEntityProperty="itemPrefix"
                                     [inputAttr]="{ 'style':  'text-transform: uppercase'}"
                                     (onValueChanged)="onItemPrefixChange()"></dx-text-box>
                    </div>
                </div>
            </div>

            <!-- access -->
            <div *ngIf="board.isTeamBoard">
                <fieldset>
                    <legend>Who can view this board?</legend>
                    <dx-radio-group [items]="publicReadAccessOptions"
                                    valueExpr="value"
                                    displayExpr="text"
                                    [(value)]="board.isPublicReadAccess"
                                    [disabled]="board.team && board.team.isPrivate"
                                    (onValueChanged)="onIsPublicReadAccessChange()"></dx-radio-group>
                </fieldset>
                <fieldset>
                    <legend>Who can edit actions on this board?</legend>
                    <dx-radio-group [items]="publicEditAccessOptions"
                                    valueExpr="value"
                                    displayExpr="text"
                                    [(value)]="board.isPublicWriteAccess"
                                    [disabled]="board.team && board.team.isPrivate"
                                    (onValueChanged)="onIsPublicWriteAccessChange()"></dx-radio-group>
                </fieldset>
            </div>
        </form>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="board.entityAspect.hasValidationErrors"
                                   [saveBlockingClick]="saveAndClose"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>

<adapt-context-sidebar-content context="dialog">
    <h2 context-sidebar-title>Guidance</h2>

    <div *ngIf="board.isPersonalBoard">
        <h3>Personal Board Details</h3>
        <p>Provide a unique name and action prefix for the personal board.</p>
        <p>For example, specifying an action prefix of <strong>HOME</strong> will produce actions in your board like
            <strong>HOME-1</strong>, <strong>HOME-2</strong> and <strong>HOME-3</strong>.
        </p>
    </div>
    <div *ngIf="board.isTeamBoard">
        <h3>Board Details</h3>
        <p>Provide a unique name and prefix for the team board.</p>
        <p>For example, specifying an action prefix of <strong>NAV</strong> will produce actions in your board like
            <strong>NAV-1</strong>, <strong>NAV-2</strong> and <strong>NAV-3</strong>.
        </p>

        <h3>Access Security</h3>
        <p *ngIf="!board.team?.isPrivate; else privateAccessText">You can restrict view or edit access to the board by people
            outside the team if you wish.</p>
        <ng-template #privateAccessText>
            <p>Access to the board is restricted to team members of this private team and coaches only.</p>
        </ng-template>
    </div>
</adapt-context-sidebar-content>

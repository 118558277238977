import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { EntityAspect, EntityType } from "breeze-client";

export abstract class BaseEntity<T> implements IBreezeEntity<T> {
    public readonly entityAspect!: IBreezeEntityAspect<T>;
    public readonly entityType!: EntityType;

    public isComplete!: boolean;
}

interface IBreezeEntityAspect<T> extends EntityAspect {
    originalValues: Partial<T>;
}

import { Directive, Injector, ViewChild } from "@angular/core";
import { EventCadenceCycle } from "@common/ADAPT.Common.Model/organisation/event-cadence-cycle";
import { EventSeries } from "@common/ADAPT.Common.Model/organisation/event-series";
import { EventTypePreset } from "@common/ADAPT.Common.Model/organisation/event-type";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { MeetingAgendaItem } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { MeetingAgendaItemSupplementaryData } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item-supplementary-data";
import { MeetingAttendee } from "@common/ADAPT.Common.Model/organisation/meeting-attendee";
import { MeetingSupplementaryData } from "@common/ADAPT.Common.Model/organisation/meeting-supplementary-data";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { DateUtilities } from "@common/lib/utilities/date-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { ICycle, IItem, IItemEvent, ISection, TimeSchedulerPeriod6Months, TimeSchedulerPeriodYear, TimeSchedulerPeriodYearPlusHalf } from "@common/ux/time-scheduler/time-scheduler.interface";
import { TimeSchedulerWrapperComponent } from "@common/ux/time-scheduler/time-scheduler-wrapper/time-scheduler-wrapper.component";
import { ISetCadenceRunData } from "@org-common/lib/schedule/schedule.interface";
import { ScheduleService } from "@org-common/lib/schedule/schedule.service";
import { ScheduleUiService } from "@org-common/lib/schedule/schedule-ui.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import moment from "moment";
import { lastValueFrom } from "rxjs";

@Directive()
export class CadenceStepBaseComponent extends WorkflowStepComponentAdapter {
    public runData!: ISetCadenceRunData;

    @ViewChild(TimeSchedulerWrapperComponent) public scheduler?: TimeSchedulerWrapperComponent;
    public sections: ISection[] = [];
    public items: IItem<Meeting>[] = [];

    public currentPeriod = TimeSchedulerPeriodYearPlusHalf;
    public cycle?: ICycle;

    // start display at this month (doesn't make sense to be scheduling in the past)
    public schedulerStartTime = moment().startOf("month").hour(9);

    public popupItem?: IItemEvent<Meeting>;
    public popupVisible = false;

    protected leadershipTeam!: Team;

    protected scheduleService: ScheduleService;
    protected teamsService: CommonTeamsService;
    protected dialogService: AdaptCommonDialogService;
    protected commonDataService: CommonDataService;

    public constructor(
        injector: Injector,
    ) {
        super();

        this.scheduleService = injector.get(ScheduleService);
        this.teamsService = injector.get(CommonTeamsService);
        this.dialogService = injector.get(AdaptCommonDialogService);
        this.commonDataService = injector.get(CommonDataService);

        this.subscribeToEmitForEntityTypeChange(injector, EventCadenceCycle);
        this.subscribeToEmitForEntityTypeChange(injector, EventSeries);
        this.subscribeToEmitForEntityTypeChange(injector, Meeting);
        this.subscribeToEmitForEntityTypeChange(injector, MeetingSupplementaryData);
        this.subscribeToEmitForEntityTypeChange(injector, MeetingAgendaItem);
        this.subscribeToEmitForEntityTypeChange(injector, MeetingAgendaItemSupplementaryData);
        this.subscribeToEmitForEntityTypeChange(injector, MeetingAttendee);
    }

    public get cadenceCycle() {
        return this.runData.eventCadenceCycle;
    }

    public async workflowStepOnInit() {
        const workflow = this.workflowStep?.workflow;
        if (!workflow) {
            throw new Error("Workflow not found");
        }

        // runData holds the configured schedules so we can add them all at once at the end
        this.runData = workflow.runData as ISetCadenceRunData;

        const leadershipTeam = await this.teamsService.promiseToGetLeadershipTeam();
        if (!leadershipTeam) {
            throw new Error("Leadership team not found");
        }
        this.leadershipTeam = leadershipTeam;

        if (!this.runData.eventCadenceCycle) {
            throw new Error("EventCadenceCycle must exist to continue setting cadence");
        }

        // populate event types to show in the scheduler
        for (const preset of this.runData.eventTypePresets) {
            const eventType = await lastValueFrom(this.scheduleService.getEventTypeForTeam(preset, this.leadershipTeam.teamId));
            if (!eventType) {
                throw new Error(`Event type ${preset} not found for teamId=${this.leadershipTeam.teamId}`);
            }

            this.sections.push(ScheduleUiService.SchedulerSectionFromEventType(eventType));
        }
    }

    protected updateCadenceCycleDisplay() {
        const cadenceStartDate = moment(DateUtilities.getNextWorkingDay())
            .hour(9)
            .minute(0)
            .toDate();

        // use the SFO startDate for calculating the cycle endDate
        // SFO value won't be present if already ran, or if cacence hasn't been set yet.
        const annualStartDate = this.runData.scheduledPresets.get(EventTypePreset.SetFirstObjectives)?.eventSeries.startDate
            ?? cadenceStartDate;
        const endDate = this.runData.eventCadenceCycle.extensions.getNextCycleStartDate(cadenceStartDate);

        this.cycle = { start: annualStartDate, end: endDate };

        // update the current period view based on the cycle length
        const cycleMonths = this.runData.eventCadenceCycle.extensions.getCycleLength(cadenceStartDate);
        this.currentPeriod = cycleMonths > 12
            ? TimeSchedulerPeriodYearPlusHalf
            : cycleMonths >= 6
                ? TimeSchedulerPeriodYear
                : TimeSchedulerPeriod6Months;
    }
}

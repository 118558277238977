import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptTimeSchedulerModule } from "@common/ux/time-scheduler/time-scheduler.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { DxCheckBoxModule, DxDateBoxModule, DxPopoverModule, DxSelectBoxModule } from "devextreme-angular";
import { AdaptMeetingsSharedModule } from "../meetings/meetings-shared.module";
import { CadenceClearDialogComponent } from "./cadence-clear-dialog/cadence-clear-dialog.component";
import { ReviewRecurrencesComponent } from "./review-recurrences/review-recurrences.component";
import { ScheduleRecurrenceComponent } from "./schedule-recurrence/schedule-recurrence.component";


@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        AdaptCommonDialogModule,
        AdaptDateModule,
        AdaptMeetingsSharedModule,
        AdaptTimeSchedulerModule,
        AdaptTooltipModule,
        DxCheckBoxModule,
        DxDateBoxModule,
        DxPopoverModule,
        DxSelectBoxModule,
    ],
    declarations: [
        ScheduleRecurrenceComponent,
        ReviewRecurrencesComponent,
        CadenceClearDialogComponent,
    ],
    exports: [
        ScheduleRecurrenceComponent,
        ReviewRecurrencesComponent,
    ],
})
export class AdaptScheduleModule {}

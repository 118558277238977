<div class="row">
    <div class="col-xs-12 col-lg-9">
        <div class="alert alert-danger">
            <span class="fal fa-4x fa-hand-paper"></span>
            <br /><br />

            <ng-container *ngIf="page || errorMessage; else unknownErrorTemplate">
                <ng-container *ngIf="page">
                    <p>Unfortunately, there was an error when attempting to access this page.</p>
                    <p>You tried to access <strong>{{page}}</strong>.</p>
                </ng-container>
                <ng-container *ngIf="errorMessage">
                    <p>{{errorMessage}}</p>
                </ng-container>
            </ng-container>

            <ng-template #unknownErrorTemplate>
                <p>Unfortunately, something has gone wrong.</p>
            </ng-template>

            <div role="alert"
                 class="alert alert-warning">Please contact your {{platformName}} administrator, coach or adapt support for assistance in
                resolving this issue.
            </div>
        </div>
    </div>
</div>

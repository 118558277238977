<adapt-dialog>
    <h3 adapt-dialog-title>Coaching session history</h3>

    <ng-container adapt-dialog-content>
        <ng-template #personTemplate
                     let-person>
            <adapt-link-person [person]="person"
                               [showImage]="true"
                               imageSize="sm"
                               [preventWrap]="true"></adapt-link-person>
        </ng-template>

        <adapt-coach-sessions-grid *adaptLoading="!organisation"
                                   [organisation]="organisation"
                                   [personTemplate]="personTemplate"></adapt-coach-sessions-grid>
    </ng-container>

    <div adapt-dialog-footer
         class="ms-auto">
        <button adaptButton="close"
                (click)="cancel()"></button>
    </div>
</adapt-dialog>

import { Component } from "@angular/core";
import { uniqueValueNameValidator } from "@common/ADAPT.Common.Model/organisation/value";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { ValuesConstitutionService } from "../../values-constitution.service";
import { ICreateValueData } from "../create-value-workflow";

@WorkflowStepComponent("adapt-create-value-name")
@Component({
    selector: "adapt-create-value-name",
    templateUrl: "./create-value-name.component.html",
})
export class CreateValueNameComponent extends WorkflowStepComponentAdapter {
    public readonly uniqueValueNameValidator = uniqueValueNameValidator;

    public data: ICreateValueData = {
        value: undefined,
        valuesConstitution: undefined,
    };

    public workflowStepCompleted = new BehaviorSubject<boolean>(false);

    public constructor(private valuesConstitutionService: ValuesConstitutionService) {
        super();
    }

    public async workflowStepOnInit() {
        if (this.workflowStep?.workflow) {
            this.data = this.workflowStep.workflow.runData ?? this.data;
            if (!this.data.valuesConstitution) {
                this.data.valuesConstitution = await lastValueFrom(this.valuesConstitutionService.getOrCreateValuesConstitution());
            }

            if (!this.data.value) {
                this.data.value = await lastValueFrom(this.valuesConstitutionService.createValue(this.data.valuesConstitution));
            }

            this.workflowStep.workflow.runData = this.data;
        }

        this.validateEntities();
    }

    public validateEntities() {
        this.workflowStepCompleted.next(this.data.value?.entityAspect.validateEntity() ?? false);
        if (this.data.value) {
            this.emitEntityChange(this.data.value);
        }
        if (this.data.valuesConstitution) {
            this.emitEntityChange(this.data.valuesConstitution);
        }
    }
}

<div>
    <i *ngIf="showIcon"
       [ngClass]="iconClass"></i>
    <span *ngIf="detail.description"
          class="contact-description">{{detail.description}}</span>
    <a [attr.href]="url">{{detail.value}}</a>
    <span *ngIf="detail.isPrivate"
          class="ps-1">
        <small class="fal fa-lock"
               adaptTooltip="This information is only visible to this person and those with permission to view private profile data"></small>
    </span>
</div>

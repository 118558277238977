import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";

@Directive({
    selector: "[adaptIfAlto]",
})
export class IfAltoDirective {
    public isAlto = AdaptClientConfiguration.AdaptProjectName === AdaptProject.Alto;

    public constructor(
        private templateRef: TemplateRef<unknown>,
        private viewContainer: ViewContainerRef,
    ) {

    }

    @Input() public set adaptIfAlto(ifAltoOnly: boolean) {
        if (ifAltoOnly === this.isAlto) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}

/* eslint-disable max-classes-per-file */
import { Injectable } from "@angular/core";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { UrlUtilities } from "@common/lib/utilities/url-utilities";
import { Observable } from "rxjs";
import { delay } from "rxjs/operators";
import { DocumentDescriptor } from "../document-descriptor";
import { LazyLoadLibrary } from "./lazy-load-library";
import { IStorageProvider, StorageProviderGroup } from "./storage-provider.interface";
import { StorageProviderUtilities } from "./storage-provider-utilities";

@Injectable()
export class OneDriveProviderService implements IStorageProvider {
    private static readonly FileIdentifier = "file";
    private static readonly ServiceName = "OneDrive";

    public disabled: boolean = false;
    private windowOpened: boolean = false;
    private oneDriveLazyLoad = new OneDriveLazyLoadLibrary();

    public async setupComponent() {
        try {
            await this.oneDriveLazyLoad.promiseToInitialise();
        } catch (e) {
            this.disabled = true;
        }
    }

    public cleanupComponent(): void { /* Nothing to do */ }
    public onOrganisationChanged(): void { /* Nothing to do */ }

    public getName(): string {
        return OneDriveProviderService.ServiceName;
    }

    public getDisplayName(): string {
        return "OneDrive";
    }

    public getIconClass(): string {
        return "fal fa-fw fa-cloud";
    }

    public getGroupName = () => StorageProviderGroup.CLOUD;

    public getSelectionInProgressText(): string {
        return "Complete selection in the popup OneDrive file chooser.";
    }

    public getDocument(url: string): DocumentDescriptor | null {
        if (StringUtilities.isString(url)) {
            if (StorageProviderUtilities.getUrlServiceNameIdentifier(url) === OneDriveProviderService.ServiceName
                || url.indexOf("sharepoint.com") > 0) {
                // OneDrive identified file in the query param
                let name = UrlUtilities.getQueryParamValue(url, OneDriveProviderService.FileIdentifier);

                if (!name) {
                    name = UrlUtilities.getFileName(url);
                }

                if (name) {
                    const result = new DocumentDescriptor(decodeURIComponent(name), url);

                    result.setIconClass(this.getIconClass());
                    return result;
                }
            }
        }

        return null;
    }

    public async openChooser(): Promise<DocumentDescriptor | null> {
        if (this.windowOpened) {
            // OneDrive chooser doesn't have any interface to cancel (e.g. Dropbox.cancelChooser()) - so reject here for user to manually close it
            return Promise.reject("Please close any other OneDrive chooser popup before selecting...");
        } else {
            this.windowOpened = true;
        }

        return new Promise((resolve, reject) => this.doChoose(resolve, reject));
    }

    private doChoose(resolve: (document: DocumentDescriptor | null) => void, reject: (e: any) => void) {
        const options: OneDrive.IOneDriveOpenOptions = {
            clientId: AdaptClientConfiguration.OneDriveClientId,
            // Action 'download' will give short-lived URL.
            // Tried using 'query' and then based on the response data, using
            //    bearerToken = responseData.accessToken
            //    driveId and item id from responseData.value[0],
            // hitting responseData.apiEndPoint using https://developer.microsoft.com/en-us/graph/docs/api-reference/v1.0/api/driveitem_createlink
            // (as the apiEndPoint indicates using Microsoft Graph API).
            // response from that POST is a URL similar to what action=share returning
            // might as well use action = share for long-lived URL (drawback is the office 365 online for known office doc, which can then be downloaded)
            action: "share",
            multiSelect: false,
            advanced: {
                redirectUri: window.location.origin + "/content/ux/document-selector/content/one-drive-signin-7.2.html",
            },
            success: onSuccess,
            cancel: onCancel,
            error: onCancel,
            viewType: "all",
        };
        const self = this;

        OneDrive.open(options);

        function onSuccess(responseData: OneDrive.IOneDriveOpenSuccessResponse) {
            self.windowOpened = false;
            if (Array.isArray(responseData.value) && responseData.value.length > 0) {
                const fileName = responseData.value[0].name;
                let link = StorageProviderUtilities.addUrlServiceNameIdentifier(responseData.value[0].webUrl, self);

                if (!UrlUtilities.getQueryParamValue(link, OneDriveProviderService.FileIdentifier)) {
                    // OneDrive not identifying the file name using query param 'file' as Julian discovered in
                    // personal account
                    link = UrlUtilities.setQueryParam(link, OneDriveProviderService.FileIdentifier, fileName);
                }

                resolve(new DocumentDescriptor(fileName, link));
            } else {
                reject(null);
            }
        }

        function onCancel(e: any = null) {
            self.windowOpened = false;
            reject(e);
        }
    }
}

class OneDriveLazyLoadLibrary extends LazyLoadLibrary {
    public constructor() {
        super("https://js.live.net/v7.2/OneDrive.js");
    }

    protected doInitialisation(source: Observable<void>) {
        return source.pipe(
            // There must be some kind of async init logic in the script, because the open
            // fails if we don't have this delay
            delay(500),
        );
    }
}

<adapt-dashboard-element name="Contacts">

    <div adapt-dashboard-title-buttons
         *ngIf="editMode">
        <button adaptButton="editBorderless"
                [adaptBlockingClick]="editContacts"
                [disabled]="disabled"
                data-tour="edit-contact-details-button"
                data-test="edit-contact-details-button">
        </button>
    </div>

    <ng-container *adaptLoadingUntilFirstEmit="let contactTypes of mappedContactTypes$">
        <dl *ngFor="let contactType of contactTypes">
            <dd *ngFor="let contact of contactType.contacts">
                <adapt-contact-detail [detail]="contact"></adapt-contact-detail>
            </dd>
        </dl>
    </ng-container>
</adapt-dashboard-element>

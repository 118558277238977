import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDocumentSelectorModule } from "@common/ux/document-selector/document-selector.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptSeeMoreModule } from "@common/ux/see-more/see-more.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { DxButtonGroupModule, DxListModule, DxSortableModule, DxTextBoxModule } from "devextreme-angular";
import { WorkflowService } from "../../workflow/workflow.service";
import { AdaptValuesConstitutionCommonModule } from "../values-constitution-common.module";
import { CreateValueBehavioursComponent } from "./create-value-behaviours/create-value-behaviours.component";
import { CreateValueMeaningComponent } from "./create-value-meaning/create-value-meaning.component";
import { CreateValueNameComponent } from "./create-value-name/create-value-name.component";
import { CreateValueReviewComponent } from "./create-value-review/create-value-review.component";
import { createValueWorkflow } from "./create-value-workflow";


@NgModule({
    imports: [
        CommonModule,

        AdaptEntityValidatorModule,
        AdaptHtmlEditorModule,
        AdaptTooltipModule,
        AdaptButtonModule,
        AdaptDocumentSelectorModule,
        AdaptValuesConstitutionCommonModule,

        DxTextBoxModule,
        DxButtonGroupModule,
        DxListModule,
        DxSortableModule,
        AdaptSeeMoreModule,
    ],
    declarations: [
        CreateValueNameComponent,
        CreateValueMeaningComponent,
        CreateValueBehavioursComponent,
        CreateValueReviewComponent,
    ],
})
export class AdaptCreateValueWorkflowModule {
    // prevent being optimised by tree-shaker
    public readonly workflowStepComponents = [
        CreateValueNameComponent,
        CreateValueMeaningComponent,
        CreateValueBehavioursComponent,
        CreateValueReviewComponent,
    ];

    public constructor(workflowService: WorkflowService) {
        workflowService.addPersistentWorkflow(WorkflowService.CreateWorkflowPersistenceConfig(createValueWorkflow));
    }
}

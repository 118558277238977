import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ItemStatus, ItemStatusMetadata } from "@common/ADAPT.Common.Model/organisation/item-status";

@Component({
    selector: "adapt-display-item-status",
    templateUrl: "./display-item-status.component.html",
    styleUrls: ["./display-item-status.component.scss"],
})
export class DisplayItemStatusComponent implements OnChanges {
    @Input() public itemStatus?: ItemStatus;
    @Input() public showRightChevronDown = false;
    @Input() public showRightChevronUp = false;


    public metadata?: ItemStatusMetadata;
    public badgeClass = "";

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.itemStatus && this.itemStatus) {
            this.metadata = ItemStatusMetadata.ByStatus[this.itemStatus];
            this.badgeClass = `badge-${this.itemStatus.toLowerCase()}`;
        }
    }
}

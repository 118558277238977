<ng-content *ngIf="layoutEntity?.options?.enabled"></ng-content>
<ng-container *ngIf="isEditing">
    <div class="action-buttons align-items-center">
        <div class="d-flex flex-column movement-buttons">
            <button class="btn move-component-up"
                    adaptTooltip="Move up"
                    data-test="move-up-button"
                    (click)="moveComponent(-1)"
                    [disabled]="isFirstComponentInColumn">
                <i class="fal fa-fw fa-chevron-up"></i>
            </button>
            <button class="btn move-component-down"
                    adaptTooltip="Move down"
                    data-test="move-down-button"
                    (click)="moveComponent(1)"
                    [disabled]="isLastComponentInColumn">
                <i class="fal fa-fw fa-chevron-down"></i>
            </button>
        </div>
        <button class="btn hide-component"
                [adaptTooltip]="componentHidden ? 'Show' : 'Hide'"
                (click)="toggleHideComponent()">
            <i class="fal fa-fw"
               [ngClass]="{'fa-eye-slash': componentHidden, 'fa-eye': !componentHidden}"></i>
        </button>
        <adapt-menu *ngIf="menuItems"
                    adaptTooltip="Size"
                    [items]="menuItems"
                    [showOnHover]="true"></adapt-menu>
        <button class="btn move-component"
                adaptTooltip="Move (drag)">
            <i class="fal fa-fw fa-arrows-alt"></i>
        </button>
    </div>
</ng-container>

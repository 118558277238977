import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptEditorViewModule } from "../ux/html-editor/editor-view.module";
import { AdaptLoadingSpinnerModule } from "../ux/loading-spinner/loading-spinner.module";
import { ImplementationKitContentComponent } from "./implementation-kit-content/implementation-kit-content.component";
import { StyledImplementationKitComponent } from "./styled-implementation-kit/styled-implementation-kit.component";

@NgModule({
    imports: [
        CommonModule,
        AdaptLoadingSpinnerModule,
        AdaptEditorViewModule,
    ],
    declarations: [
        ImplementationKitContentComponent,
        StyledImplementationKitComponent,
    ],
    exports: [
        ImplementationKitContentComponent,
        StyledImplementationKitComponent,
    ],
})
export class AdaptImplementationKitModule { }

import FroalaEditor from "froala-editor";

export interface IRangeOffset {
    startOffset: number;
    endOffset: number;
}

export class FroalaActionUtilities {
    /**
     * Helper function for custom froala actions to restore the cursor after an action link has been added/edited.
     * @param editor froala editor.
     * @param anchorNode add/edit mode.
     * @param offsets where in the document the cursor is.
     */
    public static restoreCursor(editor: FroalaEditor, anchorNode: HTMLElement, offsets: IRangeOffset) {
        // even if blur event is disabled in this flow, still observed cases where froala marker (added in editor.selection.save())
        // was removed when shortcut keys are used.
        // restoring to the end of the last anchorNode as a fail-safe measure.
        editor.selection.restore();
        if (editor.selection.element() !== anchorNode) {
            editor.selection.setAtEnd(anchorNode);
            editor.selection.restore();
            // setAtEnd above will set the anchorOffset at the end of the node
            // - need to check offsets against this to address the case where element is set to fr-image and invalidates
            //   the previous offsets
            const endPosition = (editor.selection.get() as Selection).anchorOffset;
            if (editor.doc.getSelection().rangeCount > 0 && offsets.startOffset <= endPosition && offsets.endOffset <= endPosition) {
                const range = editor.doc.getSelection().getRangeAt(0);
                range.setStart(range.startContainer, offsets.startOffset);
                range.setEnd(range.endContainer, offsets.endOffset);
            }
        }
    }
}

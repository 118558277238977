/* eslint-disable max-classes-per-file */
import { AfterViewInit, Component, Injector, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { KeyResultValue } from "@common/ADAPT.Common.Model/organisation/key-result-value";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ObjectiveComment } from "@common/ADAPT.Common.Model/organisation/objective-comment";
import { ObjectiveItemLink } from "@common/ADAPT.Common.Model/organisation/objective-item-link";
import { ObjectiveReview } from "@common/ADAPT.Common.Model/organisation/objective-review";
import { ObjectiveStatus } from "@common/ADAPT.Common.Model/organisation/objective-status";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { cacheLatest } from "@common/lib/utilities/rxjs-utilities";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { Breakpoint } from "@common/ux/responsive/breakpoint";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { CommentChainComponent, IChainComment, IChainUpdate } from "@org-common/lib/comment-chain/comment-chain/comment-chain.component";
import { EntityUpdateUtilities } from "@org-common/lib/entity-sync/entity-update-utilities";
import { ItemUtilities } from "@org-common/lib/kanban/items/item-utilities";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { DxScrollViewComponent } from "devextreme-angular";
import moment from "moment";
import { BehaviorSubject, combineLatest, EMPTY, merge, Observable, of, ReplaySubject, Subscription } from "rxjs";
import { filter, first, map, startWith, switchMap, take, tap, withLatestFrom } from "rxjs/operators";
import { KeyResultListItemLayout } from "../key-result-list-item.component/key-result-list-item.component";
import { ObjectivesService } from "../objectives.service";
import { ObjectivesAuthService } from "../objectives-auth.service";
import { ObjectivesRouteService } from "../objectives-route.service";
import { ObjectivesUiService } from "../objectives-ui.service";

@Component({
    selector: "adapt-edit-objective-page",
    templateUrl: "./edit-objective-page.component.html",
    styleUrls: ["./edit-objective-page.component.scss"],
})
export class EditObjectivePageComponent extends BaseRoutedComponent implements OnInit, OnDestroy, AfterViewInit {
    public readonly FeatureName = FeatureName;
    public readonly ObjectiveStatus = ObjectiveStatus;

    public isDescriptionCollapsed = false;
    public isKeyResultsCollapsed = false;
    public isActivityCollapsed = false;
    public reviewCollapsed = false;

    public showKeyResultActivities = false;
    public showCommentActivities = true;

    //TODO: refactor in CM-5628
    public objectiveId$ = new ReplaySubject<number>(1);
    public primedObjective$: Observable<Objective>;
    public newComment$: Observable<ObjectiveChainComment> = EMPTY;

    public chainComments$: Observable<IChainComment<ObjectiveComment>[]>;
    public chainUpdates$: Observable<IChainUpdate<KeyResultValue>[]>;
    public filteredChainComments$: Observable<IChainComment<ObjectiveComment>[]>;
    public filteredChainUpdates$: Observable<IChainUpdate<KeyResultValue>[]>;
    public triggerActivityUpdate$ = new BehaviorSubject<void>(undefined);

    public lastObjective?: Objective;
    public hasEditPermissions = false;
    public showReviewReminder = false;
    public isXL$: Observable<boolean>;
    public listItemLayout = KeyResultListItemLayout.Compact;
    public linkedActions: Item[] = [];

    @ViewChild(CommentChainComponent)
    public commentChain?: CommentChainComponent<ObjectiveComment, KeyResultValue>;

    @ViewChildren(DxScrollViewComponent) public scrollViews?: QueryList<DxScrollViewComponent>;

    private toastrSubscription: Subscription;
    private objectivesRouteService: ObjectivesRouteService;

    private accessSubscription?: Subscription;
    private isSingleColumn = false;

    public constructor(
        private objectivesService: ObjectivesService,
        private objectivesUiService: ObjectivesUiService,
        private objectivesAuthService: ObjectivesAuthService,
        private responsiveService: ResponsiveService,
        entityUpdateUtilities: EntityUpdateUtilities,
        labellingService: LabellingService,
        injector: Injector,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super(injector);
        this.objectivesRouteService = injector.get(ObjectivesRouteService);
        this.primedObjective$ = this.objectiveId$.pipe(
            switchMap((objectiveId) => this.objectivesService.getPrimedObjective(objectiveId)),
            // prime label for objective
            switchMap((objective) => objective
                ? labellingService.getLabelLocationsForObjective(objective.objectiveId).pipe(
                    map(() => objective))
                : of(undefined)),
            cacheLatest(), // Prevent unnecessary re-queries for each subscription.
            tap(() => {
                this.notifyActivated();
                this.isInitialised = true;
            }),
            filter(ObjectUtilities.createIsInstanceFilter(Objective)),
        );

        const objectiveUpdate$ = combineLatest([
            merge(this.primedObjective$, entityUpdateUtilities.onEntityChange(this.primedObjective$)),
            this.triggerActivityUpdate$,
        ]);
        this.chainUpdates$ = objectiveUpdate$.pipe(
            map(([updates]) => this.mapToCommentChainUpdate(updates)),
        );
        this.chainComments$ = objectiveUpdate$.pipe(
            map(([objective]) => objective.comments.map((c) =>
                new ObjectiveChainComment(c, this.hasEditPermissions))),
        );
        this.filteredChainComments$ = this.chainComments$.pipe(
            map((comments) => this.showCommentActivities
                ? comments.filter((comment) => this.showKeyResultActivities || !this.isKeyResultValueComment(comment))
                : comments.filter((comment) => this.showKeyResultActivities && this.isKeyResultValueComment(comment))),
        );
        this.filteredChainUpdates$ = this.chainUpdates$.pipe(
            map((updates) => this.showKeyResultActivities ? updates : []),
        );

        this.primedObjective$.pipe(
            // will get emit on navigationEnd (i.e. switch from 1 objective to another) - need to update permission too! (can't just take(1))
            switchMap((objective) => this.objectivesAuthService.hasWriteAccessToObjective(objective.teamId)),
            withLatestFrom(this.primedObjective$),
            this.takeUntilDestroyed(),
        ).subscribe(([hasAccess, objective]) => {
            this.hasEditPermissions = hasAccess;
            this.lastObjective = objective;
            this.updateShowReviewReminder(objective);
            this.updateLinkedActions();
        });

        this.isXL$ = this.responsiveService.currentBreakpoint$.pipe(
            map((breakpoint) => breakpoint.is(Breakpoint.XL)),
        );

        this.responsiveService.currentBreakpoint$.pipe(
            tap((breakpoint) => this.isSingleColumn = !breakpoint.is(Breakpoint.LG)),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.isSingleColumn ? this.shellUiService.resetToDefaultPadding() : this.removeDefaultShellPadding());


        this.toastrSubscription = entityUpdateUtilities
            .displayToasterOnEntityChange(this.primedObjective$, (o) => `<i>${o.title}</i>`)
            .subscribe();

        // prevent deleted objective from being edited or scored
        rxjsBreezeService.entityTypeDetached(Objective).pipe(
            filter((obj) => obj.objectiveId === this.lastObjective?.objectiveId),
            this.takeUntilDestroyed(),
        ).subscribe((detachedObj) => this.onObjectiveDeleted(detachedObj));

        merge(
            rxjsBreezeService.entityTypeChanged(Objective),
            rxjsBreezeService.entityTypeChanged(ObjectiveReview).pipe(map((review) => review.objective)),
        ).pipe(
            filter((objective) => !!objective && objective.objectiveId === this.lastObjective?.objectiveId),
            this.takeUntilDestroyed(),
        ).subscribe((objective) => this.updateShowReviewReminder(objective!));

        merge(
            rxjsBreezeService.entityTypeChanged(Item).pipe(
                filter((item) => !!this.lastObjective?.itemLinks.some((l) => l.itemId === item.itemId)),
            ),
            rxjsBreezeService.entityTypeChanged(ObjectiveItemLink).pipe(
                filter((l) => l.objectiveId === this.lastObjective?.objectiveId),
            ),
        ).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updateLinkedActions());

    }

    private updateLinkedActions() {
        this.linkedActions = this.lastObjective?.itemLinks?.map((l) => l.item)
            .sort(ItemUtilities.getItemStatusAndItemCodeSortComparator()) ?? [];
    }

    private isKeyResultValueComment(comment: IChainComment<ObjectiveComment>) {
        return comment.entity.objective.keyResults.flatMap((kr) => kr.values)
            .some((kv) => kv.dateTime.getTime() === comment.entity.dateTime.getTime());
    }

    public get isClosed() {
        return this.lastObjective && this.lastObjective.status === ObjectiveStatus.Closed;
    }

    private updateShowReviewReminder(objective?: Objective) {
        const daysToDueDate = moment(objective?.dueDate).diff(moment.now(), "days");
        this.showReviewReminder = this.hasEditPermissions && !!objective &&
            (objective.status === ObjectiveStatus.Complete ||
                (daysToDueDate < 14 && (!objective.isClosed || daysToDueDate > -14))) && // don't show if close and overdue more than 2 weeks
            (!objective.objectiveReview?.hasContent && !objective.objectiveReview?.reviewDismissed);
    }

    public dismissReview(objective: Objective) {
        if (objective) {
            this.objectivesUiService.dismissObjectiveReview(objective).pipe(
                this.takeUntilDestroyed(),
            ).subscribe();
        }
    }

    public reviewObjective(objective: Objective) {
        return this.objectivesUiService.reviewObjective(objective).pipe(
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    @Autobind
    private mapToCommentChainUpdate(objective: Objective): IChainUpdate<KeyResultValue>[] {
        const keyResultUpdates = objective.keyResults.flatMap((kr) => kr.values)
            .map((keyResultValue) => new KeyResultChainUpdate(keyResultValue, this.hasEditPermissions));

        return keyResultUpdates;
    }

    public ngOnInit() {
        this.navigationEnd.pipe(
            startWith(undefined),
        ).subscribe(() => {
            // set view container to raw with 100% height to enable scroll view scrolling without having to calculate and set height
            // - reset if single column -> won't see scrollbar
            this.isSingleColumn ? this.shellUiService.resetToDefaultPadding() : this.removeDefaultShellPadding();
            this.refresh();

            // this will refresh the scroll if moving to different objective page (same component, different page)
            this.ngAfterViewInit();
        });
    }

    public ngAfterViewInit() {
        // need to wait for the scroll view to be visible - or it will show a dot on refresh regardless the height of the scroll content or
        // even if there won't be any scroll
        // scrollViews length will be 0 here sometimes - need to subscribe to changes
        this.scrollViews?.changes.pipe(
            startWith(undefined), // this is for the case when length is 2 in 1 out of 100 chance
            this.takeUntilDestroyed(),
        ).subscribe((views?: QueryList<DxScrollViewComponent>) => {
            views?.forEach((v) => this.updateScrollViewWhenVisible(v.instance));
        });
    }

    @Autobind
    public refresh() {
        const objectiveId = this.getRouteParamInt("objectiveId");
        this.objectiveId$.next(objectiveId!);

        const teamId = this.getRouteParamInt("teamId");
        this.accessSubscription?.unsubscribe();
        this.accessSubscription = this.objectivesAuthService.hasWriteAccessToObjective(teamId).subscribe((hasAccess) => {
            if (hasAccess) {
                this.newComment$ = this.primedObjective$.pipe(
                    first(),
                    switchMap((objective) => this.objectivesService.createObjectiveComment(objective)),
                    map((comment) => new ObjectiveChainComment(comment, true)),
                );
            } else {
                this.newComment$ = EMPTY;
            }
        });

        this.verifyHasAccessToRoute(this.objectivesAuthService.hasReadAccessToObjective(teamId));
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        this.objectiveId$.complete();
        this.toastrSubscription.unsubscribe();
        this.accessSubscription?.unsubscribe();
    }

    @Autobind
    public removeObjectiveLink(target: Objective) {
        const link = this.lastObjective?.objectiveLinks.find((l) => l.objective2 === target);
        if (link) {
            this.objectivesUiService.promptToDeleteObjectiveLink(link)
                .subscribe();
        }
    }

    public removeObjectiveItemLink(item: Item) {
        const link = this.lastObjective?.itemLinks.find((l) => l.item === item);
        if (link) {
            this.objectivesUiService.promptToDeleteObjectiveItemLink(link)
                .subscribe();
        }
    }

    @Autobind
    public onObjectiveDeleted(deletedObjective: Objective) {
        this.objectivesRouteService.navigateToObjectivePageRoute(deletedObjective.teamId);
    }

    @Autobind
    public deleteChainUpdate(chainUpdate: IChainUpdate<KeyResultValue>) {
        this.primedObjective$.pipe(
            take(1),
            switchMap((objective) => {
                const keyResultValue = chainUpdate.updateEntity;
                if (keyResultValue) {
                    const updateComment = this.findMatchingComment(objective, keyResultValue);
                    return this.objectivesUiService.promptToDeleteKeyResultValue(keyResultValue, updateComment).pipe(
                        tap(() => this.refresh()),
                    );
                } else {
                    return EMPTY;
                }
            }),
        ).subscribe();
    }

    @Autobind
    public editChainUpdate(chainUpdate: IChainUpdate<KeyResultValue>) {
        this.primedObjective$.pipe(
            take(1),
            switchMap((objective) => {
                const keyResultValue = chainUpdate.updateEntity;
                if (keyResultValue) {
                    const updateComment = this.findMatchingComment(objective, keyResultValue);
                    if (!updateComment) {
                        return this.objectivesService.createObjectiveComment(objective).pipe(
                            tap((comment) => comment.dateTime = chainUpdate.dateTime),
                            map((newComment) => ({ updateComment: newComment, keyResultValue })),
                        );
                    } else {
                        return of({ updateComment, keyResultValue });
                    }
                } else {
                    return EMPTY;
                }
            }),
            switchMap((updateData) => {
                const sortedValues = updateData.keyResultValue.keyResult.values.sort(
                    (a, b) => a.dateTime.getTime() - b.dateTime.getTime());
                const editValueIndex = sortedValues.indexOf(updateData.keyResultValue);
                const previousValue = editValueIndex > 0 ? sortedValues[editValueIndex - 1] : undefined;

                return this.objectivesUiService.editKeyResultValue(updateData.keyResultValue, updateData.updateComment, previousValue)
                    .pipe(tap(() => this.refresh()));
            }),
        ).subscribe();
    }

    public addKeyResult() {
        this.primedObjective$.pipe(
            first(),
            switchMap((objective) => this.objectivesUiService.createKeyResultForObjective(objective)),
        ).subscribe();
    }

    public addObjectiveLink() {
        this.primedObjective$.pipe(
            first(),
            switchMap((objective) => this.objectivesUiService.addObjectiveLinkForObjective(objective)),
        ).subscribe();
    }

    public addObjectiveItemLink() {
        this.primedObjective$.pipe(
            first(),
            switchMap((objective) => this.objectivesUiService.addObjectiveItemLinkForObjective(objective)),
        ).subscribe();
    }

    @Autobind
    public editObjective(objective: Objective) {
        return this.objectivesUiService.editObjective(objective).pipe(
            this.takeUntilDestroyed(),
        );
    }

    private findMatchingComment(objective: Objective, keyResultValue: KeyResultValue) {
        return objective.comments.find((comment) =>
            comment.dateTime.getTime() === keyResultValue.dateTime.getTime());
    }
}

class ObjectiveChainComment implements IChainComment<ObjectiveComment> {
    public constructor(
        public readonly entity: ObjectiveComment,
        public readonly canUpdate: boolean,
    ) { }

    public get person() {
        return this.entity.person;
    }

    public get dateTime() {
        return this.entity.dateTime;
    }

    public get comment() {
        return this.entity.comment;
    }

    public set comment(value: string) {
        this.entity.comment = value;
    }
}

class KeyResultChainUpdate implements IChainUpdate<KeyResultValue> {
    public constructor(
        public readonly updateEntity: KeyResultValue,
        public readonly canUpdate: boolean,
    ) { }

    public get person() {
        return this.updateEntity.person;
    }

    public get dateTime() {
        return this.updateEntity.dateTime;
    }

    public get iconClass() {
        if (this.updateEntity.keyResult) {
            return this.updateEntity.keyResult.iconClass;
        } else {
            // keyResult won't be there if key result value is deleted. same for text below.
            return "fal fa-trash-alt";
        }
    }

    public get text() {
        if (this.updateEntity.keyResult) {
            return `Updated key result '${this.updateEntity.keyResult.title}' to ${this.updateEntity.formattedValue}`;
        } else {
            return "<key result value deleted>";
        }

    }
}

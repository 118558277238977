import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IWorkflowRating, PathwayRatingStatus, PathwayRatingStatusCssClassMap } from "@common/ADAPT.Common.Model/organisation/workflow-rating";
import { ServiceUri } from "@common/configuration/service-uri";

@Injectable({
    providedIn: "root",
})
export class PathwayReviewService {
    constructor(private http: HttpClient) {
    }

    public getAll() {
        return this.http.get<IWorkflowRating[]>(`${ServiceUri.WorkflowRatingBaseUri}/GetAll`);
    }

    public submitRating(workflowRating: IWorkflowRating) {
        return this.http.post(`${ServiceUri.WorkflowRatingBaseUri}/Submit`, workflowRating);
    }

    public updateRating(rating: IWorkflowRating) {
        return this.http.put(`${ServiceUri.WorkflowRatingBaseUri}/UpdateRating`, rating);
    }

    public getStatus(Status: PathwayRatingStatus) {
        return PathwayRatingStatusCssClassMap[Status];
    }
}

import { ValidationError, Validator } from "breeze-client";
import { SystemEntity } from "./system-entity";

export class SystemEntityExtensions {
    private readonly duplicateNameError: ValidationError;

    public constructor(private system: SystemEntity) {
        this.duplicateNameError = new ValidationError(
            undefined as unknown as Validator,
            {
                property: system.entityType.getProperty("name"),
            },
            "System name already in use",
            "validSystemName",
        );
    }

    public isSystemNameUnique(systems: SystemEntity[]) {
        const nameExists = systems
            .filter((sys) => sys !== this.system)
            .some((sys) => sys.name.trim().toLowerCase() === this.system.name.trim().toLowerCase());
        return !nameExists;
    }

    public validateSystemName(systems: SystemEntity[]) {
        if (!this.isSystemNameUnique(systems)) {
            this.system.entityAspect.addValidationError(this.duplicateNameError);
        } else {
            this.system.entityAspect.removeValidationError(this.duplicateNameError);
        }
    }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DxSelectBoxModule } from "devextreme-angular";
import { SelectCountryComponent } from "./select-country.component";

@NgModule({
    imports: [
        CommonModule,
        DxSelectBoxModule,
    ],
    exports: [
        SelectCountryComponent,
    ],
    declarations: [
        SelectCountryComponent,
    ],
})
export class AdaptSelectCountryModule { }

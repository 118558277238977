<!-- When this was AngularJS we had to use deferRendering=false so opening
     children was not laggy. This seems fast enough now it's been converted
     to Angular. It's not a publicly available option so if we want to set
     that, then we'll have to figure that out. -->
<dx-tree-view [dataSource]="dataSource"
              keyExpr="$id"
              itemsExpr="children"
              [activeStateEnabled]="false"
              [focusStateEnabled]="false"
              [hoverStateEnabled]="false"
              (onItemClick)="onItemClick($event)"
              (onContentReady)="onContentReady($event)">
    <adapt-display-navigation-node *dxTemplate="let node of 'item'"
                                   [node]="node"></adapt-display-navigation-node>
</dx-tree-view>

<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.UsingKanbanBoards"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<adapt-kanban-view [items$]="items$"
                   [team]="team"
                   [person]="person"
                   [boardIds]="boardIds"
                   (boardIdsChange)="onBoardSelectionChanged($event)"
                   [selectedItemId]="focusItemId"
                   (selectedItemChange)="onSelectedItemChanged($event)"
                   [filter]="filter"
                   (filterChange)="onFilterChanged($event)"
                   [openDialog]="openDialog"
                   [backlogShown]="showBacklog"
                   (backlogShownChange)="onShowBacklogChanged($event)"
                   [columnGroup]="columnGroup"
                   (columnGroupChange)="onColumnGroupChanged($event)"
                   (itemDialogOpened)="itemDialogOpened($event)"
                   (itemDialogClosed)="itemDialogClosed()"></adapt-kanban-view>

import { Component, Inject, Input, OnInit, Optional } from "@angular/core";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { NAVIGATE_TEAMS_PAGE, ORGANISATION_DASHBOARD_PAGE, PERSONAL_DASHBOARD_PAGE, TEAM_CONFIGURATION_PAGE } from "@common/page-route-providers";
import { INavigationNode } from "@common/route/navigation-node.interface";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { IAdaptLinkObject, RouteService } from "@common/route/route.service";
import { SearchService } from "@org-common/lib/search/search.service";
import { SearchRowBaseComponent } from "@org-common/lib/search/search-row-base.component";
import { from, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { IPageSearchResult } from "../search-results.interface";

interface INavigationBreadcrumbsNodes {
    root?: INavigationNode;
    area?: INavigationNode;
    branches: INavigationNode[];
    leafParent?: INavigationNode;
    leaf?: INavigationNode;
    totalCount: number;
}

@Component({
    selector: "adapt-search-row-page",
    templateUrl: "./search-row-page.component.html",
    styleUrls: ["./search-row-page.component.scss"],
})
export class SearchRowPageComponent extends SearchRowBaseComponent<IPageSearchResult> implements OnInit {
    @Input() public showDetails = true;
    public href$?: Observable<IAdaptLinkObject>;
    public nodes: INavigationBreadcrumbsNodes = {
        branches: [],
        totalCount: 0,
    };

    private readonly hiddenRoutes: string[] = [];

    public constructor(
        protected searchService: SearchService,
        private routeService: RouteService,
        @Inject(ORGANISATION_DASHBOARD_PAGE) private organisationDashboardPageRoute: IAdaptRoute<{}>,
        @Inject(PERSONAL_DASHBOARD_PAGE) private personalDashboardPageRoute: IAdaptRoute<{}>,
        @Inject(TEAM_CONFIGURATION_PAGE) private teamConfigurationPageRoute: IAdaptRoute<{ teamId: number }>,
        @Inject(NAVIGATE_TEAMS_PAGE) @Optional() private navigateTeamsPageRoute?: IAdaptRoute<{}>,
    ) {
        super(searchService);
    }

    public ngOnInit() {
        super.ngOnInit();

        this.hiddenRoutes.push(this.organisationDashboardPageRoute.id);

        if (this.navigateTeamsPageRoute) {
            this.hiddenRoutes.push(this.navigateTeamsPageRoute.id);
        }

        this.href$ = this.getNodeUrl(this.result.node).pipe(
            map((nodeUrl) => this.routeService.parseRouterObject(nodeUrl)!),
        );
        this.setBreadcrumbNodesFromNode(this.result.node);

        if (this.showDetails) {
            this.matches = this.result.results;
        }
    }

    public getNodeUrl(node: INavigationNode) {
        if (node?.url) {
            return of(node.url);
        } else if (node && !node.url && node.customData && node.customData.onClickGoToController) {
            return from(this.routeService.getControllerRoute(node.customData.onClickGoToController));
        } else {
            return of("");
        }
    }

    public nodeExists(node?: INavigationNode) {
        return !!node && node.controller && !this.hiddenRoutes.includes(node.controller);
    }

    public nodeTitle(node?: INavigationNode) {
        if (!node) {
            return undefined;
        }
        // make it obvious that the personal dashboard is the dashboard. (else it just shows your name)
        if (node.controller === this.personalDashboardPageRoute.id) {
            return `${node.title} - Personal Dashboard`;
        }
        // make it obvious that the team configuration is the configuration. (else it just shows the team name)
        if (node.controller === this.teamConfigurationPageRoute.id) {
            return `${node.title} Configuration`;
        }
        return node.title;
    }

    private setBreadcrumbNodesFromNode(node: INavigationNode) {
        const breadcrumbNodes = ObjectUtilities.linkedListToArray(node, (n) => n.parent)
            .reverse()
            .filter((n) => !n.customData.isHiddenInBreadcrumbs);

        this.nodes.totalCount = breadcrumbNodes.length;

        // This process breaks down the breadcrumb array into multiple elements
        // to make the process of dynamically hiding nodes when the width is not enough
        // easier to orchestrate.
        // Order is important below! We are going to be hiding the nodes in the inverse order
        // to below.
        // i.e. [1, 2, 3, 4, 5] => (root = 1, area = 2, branches = [3], parent = 4, leaf = 5)
        this.nodes.leaf = ArrayUtilities.removeArrayIndex(breadcrumbNodes, breadcrumbNodes.length - 1);
        this.nodes.leafParent = ArrayUtilities.removeArrayIndex(breadcrumbNodes, breadcrumbNodes.length - 1);
        this.nodes.root = ArrayUtilities.removeArrayIndex(breadcrumbNodes, 0);
        this.nodes.area = ArrayUtilities.removeArrayIndex(breadcrumbNodes, 0);
        this.nodes.branches = breadcrumbNodes; // Splice modifies in place so can just assign left overs
    }
}

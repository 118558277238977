import { ISurveyCategory } from "../survey-category.interface";

enum EngagementCategory {
    HighlyDisengaged = "Highly disengaged",
    Disengaged = "Disengaged",
    Engaged = "Engaged",
    HighlyEngaged = "Highly engaged",
}

export class EmployeeEngagementConstants {
    public static readonly HighlyEngagedColor = "rgba(153, 180, 86, 1)";
    public static readonly HighlyEngagedBackgroundColor = "#f2f5e9";
    public static readonly EngagedColor = "rgba(19, 149, 186, 1)";
    public static readonly EngagedBackgroundColor = "#e0f1f6";
    public static readonly DisengagedColor = "rgba(239, 139, 44, 1)";
    public static readonly DisengagedBackgroundColor = "#fdf0e4";
    public static readonly HighlyDisengagedColor = "rgba(192, 46, 29, 1)";
    public static readonly HighlyDisengagedBackgroundColor = "#f7e4e2";

    public static readonly HighlyEngagedPercentageThreshold = 75;
    public static readonly EngagedPercentageThreshold = 50;
    public static readonly DisengagedPercentageThreshold = 25;

    public static readonly MaxScore = 5;

    public static readonly Categories: readonly ISurveyCategory[] = [{
        name: EngagementCategory.HighlyEngaged,
        color: EmployeeEngagementConstants.HighlyEngagedColor,
        percentageThreshold: EmployeeEngagementConstants.HighlyEngagedPercentageThreshold,
    }, {
        name: EngagementCategory.Engaged,
        color: EmployeeEngagementConstants.EngagedColor,
        percentageThreshold: EmployeeEngagementConstants.EngagedPercentageThreshold,
    }, {
        name: EngagementCategory.Disengaged,
        color: EmployeeEngagementConstants.DisengagedColor,
        percentageThreshold: EmployeeEngagementConstants.DisengagedPercentageThreshold,
    }, {
        name: EngagementCategory.HighlyDisengaged,
        color: EmployeeEngagementConstants.HighlyDisengagedColor,
        percentageThreshold: 0,
    }];
}

import { DOCUMENT } from "@angular/common";
import { Component, Inject, Input, OnDestroy, OnInit } from "@angular/core";
import { AdaptClientConfiguration, AdaptEnvironment } from "@common/configuration/adapt-client-configuration";
import { IdentityService } from "@common/identity/identity.service";
import { IdentityUxService } from "@common/identity/ux/identity-ux.service";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ReleaseNotifierService } from "@common/lib/release-notifier/release-notifier.service";
import { ConnectionEvent } from "@common/lib/signalr-provider/connection-state/connection-event.enum";
import { SignalRService } from "@common/lib/signalr-provider/signalr.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { NavigationHierarchyService } from "@common/route/navigation-hierarchy.service";
import { RouteEvent } from "@common/route/route-event.enum";
import { RouteEventsService } from "@common/route/route-events.service";
import { IBannerSpec, IShell } from "@common/shell/shell.interface";
import { ShellUiService } from "@common/shell/shell-ui.service";
import { UserService } from "@common/user/user.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { BehaviorSubject, EMPTY, Observable, of, Subscription, timer } from "rxjs";
import { debounceTime, filter, switchMap, tap } from "rxjs/operators";
import { IApplicationBarComponentOptions } from "../application-bar/application-bar.component";

export interface ICommonShellComponentOptions {
    viewIsLoadingEvent?: RouteEvent;
    viewFinishedLoadingEvent?: RouteEvent;
    applicationBarOptions?: IApplicationBarComponentOptions;

    /** Set the view as loaded once the shell has finished initialising */
    viewIsLoadedAfterInitialisation?: boolean;
    disableReleaseNotification?: boolean;
}

@Component({
    selector: "adapt-common-shell",
    templateUrl: "./common-shell.component.html",
    styleUrls: ["./common-shell.component.scss"],
})
export class CommonShellComponent extends BaseComponent implements IShell, OnInit, OnDestroy {
    @Input() public options!: ICommonShellComponentOptions;
    @Input() public extraSidebarClasses?: string;

    private readonly networkBanners = {
        disconnected: {
            text: `${AdaptClientConfiguration.AdaptProjectLabel} can't detect an active internet connection.`
                + " We'll keep attempting to reconnect.",
            class: "connection-state disconnected",
            isDismissible: false,
        },
        reconnected: {
            text: `${AdaptClientConfiguration.AdaptProjectLabel} has now reconnected to the internet.`,
            class: "connection-state reconnected",
            isDismissible: true,
        },
    };

    public viewIsLoading = true;
    public userIsLoggedIn = false;
    public sidebarIsVisible = false;
    public toolbarIsVisible = true;
    public containerClass = "container-raw";
    public filterDisplayed = false;
    public contextSidebarArticleId?: ImplementationKitArticle;
    public banners: IBannerSpec[] = [];
    public isSaving$: Observable<boolean>;
    public filterDisplayedUpdater = this.createThrottledUpdater((display: boolean) => this.filterDisplayed = display);

    private readonly InitialCurtainShading = "rgba(0, 0, 0, 0)";
    private readonly FinalCurtainShading = "rgba(0, 0, 0, 0.1)";
    public curtainShadingColor = this.InitialCurtainShading;

    private titlePrefix?: string;

    private reconnectionDialogSubscription?: Subscription;

    private clientReleaseUpdateSubscription?: Subscription;
    private readonly clientUpdateRequiredBanner: IBannerSpec = {
        text: `${AdaptClientConfiguration.AdaptProjectLabel} has been updated. Please refresh your browser to pick up the latest release.`,
        class: "connection-state disconnected",
        isDismissible: false,
    };

    private contextSidebarVisible = new BehaviorSubject<boolean>(false);
    public contextSidebarVisible$ = this.contextSidebarVisible.asObservable();

    // this will prevent viewIsLoading from being set at the wrong lifecycle hook and unnecessarily changes too frequently
    // 0 throttle -> just emit the next digest cycle -> no throttle
    private viewIsLoading$ = this.createThrottledUpdater((isLoading: boolean) => this.viewIsLoading = isLoading, 0);

    public constructor(
        private signalRService: SignalRService,
        private userService: UserService,
        private shellUiService: ShellUiService,
        private identityService: IdentityService,
        private releaseNotifier: ReleaseNotifierService,
        private commonDialogService: AdaptCommonDialogService,
        private routeEventsService: RouteEventsService,
        navigationHierarchyService: NavigationHierarchyService,
        rxjsBreezeService: RxjsBreezeService,
        identityUxService: IdentityUxService,
        @Inject(DOCUMENT) document: Document,
    ) {
        super();
        identityUxService.registerForAnotherTabEvents();

        this.isSaving$ = rxjsBreezeService.savingInProgress$;

        // separate subscription so it is not affected by the debounce.
        // there were situations where the event listener could be applied even when not needed
        this.isSaving$.pipe(
            tap((isSaving) => {
                if (isSaving) {
                    document.defaultView?.addEventListener("beforeunload", this.beforeUnloadHandler);
                } else {
                    document.defaultView?.removeEventListener("beforeunload", this.beforeUnloadHandler);
                }
            }),
            this.takeUntilDestroyed(),
        ).subscribe();

        this.isSaving$.pipe(
            tap(() => {
                this.curtainShadingColor = this.InitialCurtainShading;
            }),
            filter((isSaving) => isSaving),
            debounceTime(250),
            // if still saving 250ms later, change curtain color - debounceTime so second save will reset the timer
            tap((isSaving) => {
                if (isSaving) {
                    this.curtainShadingColor = this.FinalCurtainShading;
                }
            }),
            this.takeUntilDestroyed(),
        ).subscribe();

        this.shellUiService.rawContainerChange$.pipe(
            debounceTime(100),
            this.takeUntilDestroyed(),
        ).subscribe((isRawContainer) => this.containerClass = isRawContainer ? "container-raw" : "container-fluid");

        const profile = AdaptClientConfiguration.Profile;
        if (profile) {
            this.addBanner({
                text: `You are using a ${profile} version of ${AdaptClientConfiguration.AdaptProjectLabel}.`
                    + ` Feel free to modify any data without consequence.`,
                class: `application-environment application-environment-${profile}`,
                isDismissible: false,
            });
        }

        const environment = AdaptClientConfiguration.AdaptEnvironment;
        if (environment === AdaptEnvironment.ProductionBeta) {
            this.addBanner({
                text: `You are using a beta version of ${AdaptClientConfiguration.AdaptProjectLabel}.`
                    + ` Data is LIVE and will be reflected on the main site.`,
                class: `application-environment application-environment-beta`,
                isDismissible: false,
            });
        }

        navigationHierarchyService.activeNode$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((node) => {
            if (!node || !node.title) {
                document.title = AdaptClientConfiguration.AdaptProjectLabel;
                return;
            }

            const titleParts = [
                AdaptClientConfiguration.AdaptProjectLabel,
            ];

            if (node.parent && node.parent.title) {
                titleParts.push(node.parent.title);
            }

            titleParts.push(node.title);

            document.title = titleParts.slice().reverse().join(" - ");

            if (this.titlePrefix) {
                document.title = `${this.titlePrefix}${document.title}`;
            }
        });
    }

    public async ngOnInit() {
        this.shellUiService.registerShell(this);

        this.options = {
            viewIsLoadingEvent: RouteEvent.ResolveStart,
            viewFinishedLoadingEvent: RouteEvent.NavigationEnd,
            viewIsLoadedAfterInitialisation: true,
            applicationBarOptions: {},
            ...this.options,
        };

        // once initialiseOptions() is called, settings is already defined
        // - register all subscriptions here rather than waiting for the subsequent
        //   promises to resolve as the controller will be instantiated right after this
        //   (e.g. about controller will broadcast activateSuccess before registering for the event after promises resolve)
        this.setup();

        this.userIsLoggedIn = await this.identityService.promiseToCheckIsLoggedIn();

        if (!this.options.disableReleaseNotification) {
            this.clientReleaseUpdateSubscription = this.releaseNotifier.clientUpdateRequired$.pipe(
                switchMap((updateRequired) => {
                    if (updateRequired) {
                        this.addBanner(this.clientUpdateRequiredBanner);
                        return timer(2000);
                    } else {
                        this.removeBanner(this.clientUpdateRequiredBanner);
                        return of(-1);
                    }
                }),
                switchMap((timerSequence) => {
                    if (timerSequence < 0) {
                        return EMPTY;
                    }

                    const refreshAppDialog: IConfirmationDialogData = {
                        title: "Update Available",
                        message: `<p>${this.clientUpdateRequiredBanner.text}</p>
                            <p>Running an older version of ${AdaptClientConfiguration.AdaptProjectLabel} will potentially cause issues and incompatibilty with our server.</p>
                            <p>Do you want to refresh your browser now to pick up the latest update?</p>`,
                        confirmButtonText: "Refresh now",
                        cancelButtonText: "I will refresh later",
                    };

                    return this.commonDialogService.openConfirmationDialog(refreshAppDialog);
                }),
            ).subscribe(() => {
                window.location.reload();
            });
        }
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        if (this.clientReleaseUpdateSubscription) {
            this.clientReleaseUpdateSubscription.unsubscribe();
        }
    }

    @Autobind
    public beforeUnloadHandler(event: BeforeUnloadEvent) {
        event.preventDefault();
        event.returnValue = "Saving in progress...";
    }

    @Autobind
    public setup() {
        this.routeEventsService.navigationEnd$.subscribe(() => this.setIsRawContainer(false));

        if (this.options.viewIsLoadingEvent !== undefined) {
            this.routeEventsService.getCustomObservableForEvent(this.options.viewIsLoadingEvent!)
                .subscribe((e) => {
                    if (e.newUrl !== e.oldUrl) {
                        this.setViewIsLoading(true);
                    }
                });
        }

        if (this.options.viewFinishedLoadingEvent !== undefined) {
            this.routeEventsService.getCustomObservableForEvent(this.options.viewFinishedLoadingEvent!)
                .subscribe(() => this.setViewIsLoading(false));
        }

        this.signalRService.connectionStateChanged$.subscribe(this.handleConnectionEvent);
        this.userService.userChanged$.subscribe((person) => this.userIsLoggedIn = !!person);

        if (this.options.viewIsLoadedAfterInitialisation) {
            this.setViewIsLoading(false);
        }
    }

    public addBanner(banner: IBannerSpec) {
        if (this.banners.indexOf(banner) < 0) {
            this.banners.push(banner);
        }
    }

    public removeBanner(banner: IBannerSpec) {
        ArrayUtilities.removeElementFromArray(banner, this.banners);
    }

    public setToolbarIsVisible(isVisible: boolean) {
        this.toolbarIsVisible = isVisible;
    }

    public setSidebarIsVisible(isVisible: boolean) {
        this.sidebarIsVisible = isVisible;
    }

    public setViewIsLoading(isLoading: boolean) {
        this.viewIsLoading$.next(isLoading);
    }

    public setIsRawContainer(isRawContainer: boolean) {
        this.shellUiService.emitRawContainerChange(isRawContainer);
    }

    public setTitlePrefix(prefix?: string) {
        this.titlePrefix = prefix;
    }

    public showArticleInContextSidebar(articleId: ImplementationKitArticle) {
        this.contextSidebarArticleId = articleId;
        this.contextSidebarVisible.next(true);
    }

    public hideContextSidebar() {
        this.contextSidebarVisible.next(false);
    }

    @Autobind
    public handleConnectionEvent(event: ConnectionEvent) {
        switch (event) {
            case ConnectionEvent.Lost:
                this.addBanner(this.networkBanners.disconnected);
                break;
            case ConnectionEvent.Reconnecting:
                // if a previous dialog is still there - dismiss it first
                this.reconnectionDialogSubscription?.unsubscribe();
                this.reconnectionDialogSubscription = this.commonDialogService.showMessageDialog(
                    "Connection lost",
                    `${AdaptClientConfiguration.AdaptProjectLabel} has lost its connection to the internet. `
                    + "We will keep attempting to reconnect for you. "
                    + "Attempting to continue browsing will more than likely result in errors.",
                ).subscribe();
                break;
            case ConnectionEvent.Reestablished:
                this.reconnectionDialogSubscription?.unsubscribe();
                this.reconnectionDialogSubscription = undefined;
                this.removeBanner(this.networkBanners.disconnected);
                this.addBanner(this.networkBanners.reconnected);
                setTimeout(() => this.removeBanner(this.networkBanners.reconnected), 10000);
                break;
            case ConnectionEvent.Disconnected:
                ObjectUtilities.forEach(this.networkBanners, (v) => this.removeBanner(v));
                break;
        }
    }
}

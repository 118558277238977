import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Person } from "../person/person";
import { WorkflowConnection } from "./workflow-connection";

export enum WorkflowStatusEnum {
    Incomplete = "Incomplete",
    Current = "Current",
    Completed = "Completed",
    Skipped = "Skipped",
    Blocked = "Blocked",
}

export class WorkflowStatus extends BaseEntity<WorkflowStatus> {
    public workflowStatusId!: number;
    public workflowConnectionId!: number;
    public status!: WorkflowStatusEnum;
    public workflowId?: string;
    public workflowStepId?: string;
    public startTime?: Date;
    public completionTime?: Date;
    public startedById?: number;
    public completedById?: number;
    public customData?: string; // custom data, can be anything, e.g. JSON string with surveyId or meetingId etc.

    // navigation
    public workflowConnection!: WorkflowConnection;
    public startedBy?: Person;
    public completedBy?: Person;
}

export const WorkflowStatusBreezeModel = new BreezeModelBuilder("WorkflowStatus", WorkflowStatus)
    .withPluralName("Workflow Statuses")
    .hasSource()
    .withIdField() // needed for entity sync
    .isOrganisationEntity()
    .build();

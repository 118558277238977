import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ITypedSimpleChange } from "@common/ux/base.component/typed-simple-change";

interface IObjectiveDashboardSummaryChanges extends SimpleChanges {
    objective: ITypedSimpleChange<Objective>;
}

@Component({
    selector: "adapt-objective-dashboard-summary",
    templateUrl: "./objective-dashboard-summary.component.html",
    styleUrls: ["./objective-dashboard-summary.component.scss"],
})
export class ObjectiveDashboardSummaryComponent implements OnChanges {
    @Input() public objective!: Objective;
    @Input() public displayAssignee = true;
    public objectiveProgress = 0;
    public hasBeenMeasured = false;

    public ngOnChanges(changes: IObjectiveDashboardSummaryChanges) {
        const changedObjective = changes.objective && changes.objective.currentValue;
        if (changedObjective) {
            this.objectiveProgress = changedObjective.currentProgress;
            this.hasBeenMeasured = changedObjective.keyResults.some((kr) => {
                return kr.values.length > 0;
            });
        }
    }
}

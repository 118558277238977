import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { DxValidationGroupComponent } from "devextreme-angular";
import { lastValueFrom } from "rxjs";
import { IdentityUxService, IForgotPasswordPayload } from "../identity-ux.service";
import { resetPasswordPageRoute } from "../reset-password-page/reset-password-page.route";

@Component({
    selector: "adapt-forgot-password-page",
    templateUrl: "./forgot-password-page.component.html",
})
export class ForgotPasswordPageComponent extends BaseRoutedComponent implements OnInit {
    @ViewChild("validationGroup") public validationGroup: DxValidationGroupComponent | undefined;

    public email = "";

    public isProcessing = false;
    public isDisabled = true;
    public isError = false;
    public submitMessage?: string;

    public constructor(
        public injector: Injector,
        public identityUxService: IdentityUxService,
    ) {
        super(injector);
    }

    public ngOnInit() {
        this.notifyActivated();
        this.shellUiService.removeDefaultShellPadding();
    }

    public validateGroup() {
        this.isDisabled = !this.validationGroup?.instance.validate().isValid;
    }

    @Autobind
    public async submit() {
        if (this.isProcessing || this.isDisabled) {
            return;
        }

        this.isProcessing = true;
        this.submitMessage = undefined;

        const route = await lastValueFrom(resetPasswordPageRoute.getRoute());
        const redirectUrl = window.location.origin + route;
        const payload: IForgotPasswordPayload = { email: this.email, redirectUrl };

        try {
            await this.identityUxService.promiseToSendForgotPassword(payload);
            this.submitMessage = `If an account associated with this email exists, instructions on how to reset your password reset will be sent to <b>"${this.email}"</b>.`;
            this.isError = false;
        } catch (e) {
            this.submitMessage = ErrorHandlingUtilities.getHttpResponseMessage(e);
            this.isError = true;
        } finally {
            this.isProcessing = false;
            this.email = "";
        }
    }
}

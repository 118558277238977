import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild } from "@angular/core";
import { IDxMenuItemClicked } from "@common/ux/dx.types";
import { Item, Orientation } from "devextreme/ui/menu";
import { DxMenuComponent } from "devextreme-angular";

export interface IAdaptMenuItem extends Item {
    tooltip?: string;


    templateRef?: TemplateRef<any>;
    templateData?: any;

    // DX handles calling this for us
    onClick?: () => void;

    /** If this is defined, then a checkbox will automatically be rendered for the item */
    isChecked?: boolean;

    /**
     * @deprecated use separatorTop or separatorBottom as its clearer
     */
    separator?: boolean;

    separatorTop?: boolean;
    separatorBottom?: boolean;

    /** Will be called when isChecked changes */
    onIsCheckedChange?: (isChecked: boolean) => void;

    // if the menu item should have padding on itself
    padding?: boolean;

    items?: IAdaptMenuItem[]; // without this, items will be dxMenuItem[] which won't allow onClick
}

@Component({
    selector: "adapt-menu",
    templateUrl: "./menu.component.html",
    styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnChanges {
    public static readonly StandardRootMenu: IAdaptMenuItem = {
        text: "",
        icon: "fal fa-fw fa-2x fa-ellipsis-h",
    };

    public static readonly SmallRootMenu: IAdaptMenuItem = {
        text: "",
        icon: "fal fa-fw fa-ellipsis-h small-root-menu",
    };

    public static readonly SmallAddMenu: IAdaptMenuItem = {
        text: "",
        icon: "fal fa-fw fa-plus-square",
    };

    @Input() public items?: IAdaptMenuItem[];

    @Input() public showOnHover = false;
    @Input() public disabled = false;
    @Input() public orientation: Orientation = "horizontal";
    @Output() public visibleChanged = new EventEmitter<boolean>();

    @ViewChild(DxMenuComponent) private menuComponent?: DxMenuComponent;

    // this won't stop propagation to dxMenu - only stop propagate event to parent container
    @HostListener("click", ["$event"])
    public stopPropagation(e: Event) {
        e.stopPropagation();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.items) {
            this.processMenuItems(this.items);
        }
    }

    public refresh() {
        this.processMenuItems(this.items);

        // need these to get dx to update if we are removing/adding items
        this.menuComponent?.instance.option("items", this.items);
        this.menuComponent?.instance.repaint();
    }

    public onItemClick(e: IDxMenuItemClicked<IAdaptMenuItem>) {
        const item = e.itemData!;
        if (typeof item.isChecked === "boolean") {
            item.isChecked = !item.isChecked;
            this.updateCheckedIcons(item);

            if (item.onIsCheckedChange) {
                item.onIsCheckedChange(item.isChecked);
            }
        }
    }

    private processMenuItems(items?: IAdaptMenuItem[]) {
        if (!items) {
            return;
        }

        let hasIcons = false;

        for (const item of items) {
            this.updateCheckedIcons(item);

            if (item.icon) {
                hasIcons = true;
            }

            // use an empty placeholder if other menu items have icons
            item.icon = (hasIcons && !item.icon)
                ? "fal fa-fw"
                : item.icon;

            if (Array.isArray(item.items)) {
                this.processMenuItems(item.items);
            }
        }
    }

    private updateCheckedIcons(item: IAdaptMenuItem) {
        if (typeof item.isChecked !== "boolean") {
            return;
        }

        const result = item.isChecked
            ? "fa-check-square"
            : "fa-square";
        item.icon = "fal fa-fw " + result;
    }
}

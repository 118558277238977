import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { DxSelectBoxModule, DxTextBoxModule } from "devextreme-angular";
import { AdaptTabStyledDashboardElementModule } from "../tab-style-container/tab-styled-dashboard-element.module";
import { RenderExpandedZoneMapComponent } from "./render-expanded-zone-map/render-expanded-zone-map.component";
import { RenderZoneMapComponent } from "./render-zone-map/render-zone-map.component";
import { RenderZoneMapInternalComponent } from "./render-zone-map-internal/render-zone-map-internal.component";
import { SelectZoneComponent } from "./select-zone/select-zone.component";
import { ZoneLabelComponent } from "./zone-label/zone-label.component";

@NgModule({
    imports: [
        CommonModule,
        DxSelectBoxModule,
        DxTextBoxModule,
        AdaptButtonModule,
        AdaptTooltipModule,
        AdaptTabStyledDashboardElementModule,
    ],
    exports: [
        RenderZoneMapComponent,
        RenderExpandedZoneMapComponent,
        ZoneLabelComponent,
        SelectZoneComponent,
    ],
    declarations: [
        RenderZoneMapInternalComponent,
        RenderExpandedZoneMapComponent,
        RenderZoneMapComponent,
        ZoneLabelComponent,
        SelectZoneComponent,
    ],
})
export class AdaptZoneMapModule { }

import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ITypedSimpleChange } from "../base.component/typed-simple-change";

interface IProgressBarChanges extends SimpleChanges {
    percentageProgress: ITypedSimpleChange<number>;
    percentageChange: ITypedSimpleChange<number>;
    scaleHeight: ITypedSimpleChange<number>;
    colour: ITypedSimpleChange<string | undefined>;
    backgroundColour: ITypedSimpleChange<string | undefined>;
    overrideText: ITypedSimpleChange<string | undefined>;
    leftAligned: ITypedSimpleChange<boolean | undefined>;
}

@Component({
    selector: "adapt-progress-bar",
    templateUrl: "./progress-bar.component.html",
    styleUrls: ["./progress-bar.component.scss"],
})
export class ProgressBarComponent implements OnChanges {
    @Input() public scaleHeight = 1;
    @Input() public colour?: string;
    @Input() public backgroundColour?: string;

    @Input() public percentageProgress = 0;
    @Input() public progressTooltip?: string;
    @Input() public overrideText?: string;
    @Input() public leftAligned = false;

    public totalProgress = 100;
    public progressStyles = {
        "height.rem": 1.4,
        "font-size.em": 1, // Use em so relative to our CSS $font-size-base
        "background-color": "",
    };
    public progressBarStyles = {
        "width.%": 0,
    };
    public progressTextStyles = {
        "color": "",
        "line-height.rem": 1.4,
    };
    public overrideBarColorStyles = {
        "background-color": "",
    };
    public progressText?: string;
    public textAlignmentClasses = "progress-text-center";

    public ngOnChanges(changes: IProgressBarChanges) {
        if (changes.percentageProgress) {
            this.progressBarStyles["width.%"] = this.percentageProgress;
            if (!this.overrideText) {
                this.progressText = `${this.percentageProgress}%`;
            }
        }

        if (changes.overrideText) {
            this.progressText = this.overrideText;
        }

        if (changes.scaleHeight) {
            this.progressStyles["height.rem"] = this.scaleHeight + 0.4;
            this.progressStyles["font-size.em"] = this.scaleHeight;

            // need this override as bootstrap is now setting it to 0
            this.progressTextStyles["line-height.rem"] = this.scaleHeight + 0.4;
        }

        if (changes.colour && this.colour) {
            this.overrideBarColorStyles["background-color"] = this.colour;
        }

        if (changes.backgroundColour && this.backgroundColour) {
            this.progressStyles["background-color"] = this.backgroundColour;
            this.progressTextStyles.color = this.backgroundColour;
        }

        if (changes.leftAligned) {
            this.textAlignmentClasses = this.leftAligned ? "progress-text-left" : "progress-text-center";
        }
    }
}

import { Component, Input, OnInit } from "@angular/core";
import { SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxChartCustomizePoint } from "@common/ux/dx.types";
import { SurveyUtils } from "../survey-utils";

enum PercentageCategory {
    Score = "Score",
    Missing = "Missing",
}

interface IDataItem {
    percentage: number;
    category: PercentageCategory;
    color: string;
}

@Component({
    selector: "adapt-display-category-percentage",
    templateUrl: "./display-category-percentage.component.html",
})
export class DisplayCategoryPercentageComponent extends BaseComponent implements OnInit {
    @Input() public percentage = 0;
    @Input() public text = "";
    @Input() public surveyType = SurveyType.ResilientBusinessAssessment;

    public chartData: IDataItem[] = [];

    public constructor() {
        super();
    }

    public ngOnInit() {
        const surveyUtils = SurveyUtils.forSurveyType(this.surveyType);
        this.chartData.push({
            percentage: this.percentage,
            category: PercentageCategory.Score,
            color: surveyUtils.getStatusColorFromPercentage(this.percentage),
        });
        this.chartData.push({
            percentage: 100 - this.percentage,
            category: PercentageCategory.Missing,
            color: "#bababa",
        });
    }

    public customiseChartPoint(point: IDxChartCustomizePoint) {
        if (point.data.color) {
            return { color: point.data.color };
        } else {
            return {};
        }
    }
}

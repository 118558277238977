import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { CommonTeamsService } from "../../common-teams.service";

@Component({
    selector: "adapt-select-team",
    templateUrl: "./select-team.component.html",
})
export class SelectTeamComponent implements OnInit, OnChanges {
    @Input() public teamId?: number;
    @Input() public team?: Team;
    @Output() public teamChange = new EventEmitter<Team | undefined>();

    @Input() public required = false;
    @Input() public activeOnly = true;
    @Input() public disabled = false;
    @Input() public filter?: (team: Team) => boolean;

    public dataSource?: DataSource;

    private teams: Team[] = [];

    constructor(private teamsService: CommonTeamsService) { }

    public async ngOnInit() {
        this.teams = this.activeOnly
            ? await this.teamsService.promiseToGetAllActiveTeams()
            : await this.teamsService.promiseToGetAllTeams();
        if (this.teamId) {
            // won't call this if teamId is not set or it will reset the @Input() team entity
            this.updateSelectedTeamFromTeamId();
        }

        this.dataSource = new DataSource({
            store: new ArrayStore({
                data: this.teams,
                key: "teamId",
            }),
            sort: "name",
            filter: this.filter,
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.teamId) {
            this.updateSelectedTeamFromTeamId();
        }
    }

    public reload() {
        return this.dataSource?.reload();
    }

    private updateSelectedTeamFromTeamId() {
        if (this.teamId) {
            this.team = this.teams.find((i) => i.teamId === this.teamId);
        } else {
            this.team = undefined;
        }
    }
}

<ol class="breadcrumbs-directive">
    <li *ngIf="nodeExistsAndIsNotCollapsed(nodes.root)"
        class="d-print-none">
        <adapt-display-navigation-node class="breadcrumb-text"
                                       [isInBreadcrumb]="true"
                                       [node]="nodes.root"></adapt-display-navigation-node>
        <i class="breadcrumb-separator"
           aria-hidden="true">/</i>
    </li>
    <li *ngIf="nodeExistsAndIsNotCollapsed(nodes.area)">
        <adapt-display-navigation-node class="breadcrumb-text"
                                       [isInBreadcrumb]="true"
                                       [node]="nodes.area"></adapt-display-navigation-node>
        <i class="breadcrumb-separator"
           aria-hidden="true">/</i>
    </li>
    <li *ngFor="let node of nodes.branches">
        <adapt-display-navigation-node class="breadcrumb-text"
                                       [isInBreadcrumb]="true"
                                       [node]="node"></adapt-display-navigation-node>
        <i class="breadcrumb-separator"
           aria-hidden="true">/</i>
    </li>
    <li class="collapsed-node-indicator"
        *ngIf="atLeastOneNodeIsCollapsed()">
        <span class="me-1"
              [adaptTooltip]="collapsedTooltip"
              tooltipPlacement="bottom-left">...</span>
        <i class="breadcrumb-separator"
           aria-hidden="true">/</i>
    </li>
    <li *ngIf="nodeExistsAndIsNotCollapsed(nodes.leafParent)"
        [ngClass]="{'d-print-none': nodes.leafParent && !nodes.root}">
        <adapt-display-navigation-node class="breadcrumb-text"
                                       [isInBreadcrumb]="true"
                                       [node]="nodes.leafParent"></adapt-display-navigation-node>
        <i class="breadcrumb-separator"
           aria-hidden="true">/</i>
    </li>
    <li>
        <adapt-display-navigation-node class="breadcrumb-text breadcrumb-leaf"
                                       [node]="nodes.leaf"
                                       [isInBreadcrumb]="true"
                                       [linkToPage]="false"></adapt-display-navigation-node>
    </li>
</ol>

<div class="d-grid"
     data-tour="pathways">
    <div adaptToolbarContent
         class="d-flex flex-column flex-md-row row-gap-2 mw-100 overflow-auto">
        <adapt-menu [items]="menuItems"
                    data-tour="inputs-menu"></adapt-menu>
    </div>
    <div class="mb-4 mx-md-4"
         *ngFor="let category of categoriesWorkflows">
        <adapt-tab-styled-dashboard-element [headerWidth]="'400px'"
                                            [borderColour]="getOutcomeData(category).colour"
                                            [backgroundColour]="getOutcomeData(category).backgroundColour"
                                            [tourName]="category.key">
            <div header
                 class="d-flex">
                <adapt-display-workflow-category class="ms-2"
                                                 [category]="category.key!"></adapt-display-workflow-category>

                <div class="heading-description d-flex align-items-center ms-2">{{getOutcomeData(category).name}}</div>
            </div>
            <div body
                 *ngIf="category && category.items.length > 0"
                 [attr.data-tour]="category.key"
                 class="d-flex flex-column flex-md-row row-gap-2 mw-100 overflow-auto">
                <div class="workflows">
                    <adapt-display-workflow-card *ngFor="let workflow of category.items"
                                                 [workflow]="workflow"
                                                 [attr.data-tour]="workflow.workflowId"
                                                 [showOutcomeIcon]="false"></adapt-display-workflow-card>
                </div>
            </div>
        </adapt-tab-styled-dashboard-element>
    </div>
</div>
import { Injector } from "@angular/core";

export class AngularGlobals {
    private static _injector: Injector;

    public static set injector(injector) {
        AngularGlobals._injector = injector;
    }

    public static get injector(): Injector {
        return AngularGlobals._injector;
    }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { UserType } from "@common/ADAPT.Common.Model/embed/user-type";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { PeopleQueryUtilities } from "@common/user/people-query-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import dxSelectBox, { InitializedEvent } from "devextreme/ui/select_box";
import Validator from "devextreme/ui/validator";
import { merge, startWith } from "rxjs";
import { debounceTime, switchMap } from "rxjs/operators";
import { SelectPersonUtilities } from "../select-person-utilities";

export type ISelectPersonFilter = (person: Person) => boolean;

@Component({
    selector: "adapt-select-person",
    templateUrl: "./select-person.component.html",
    styleUrls: ["./select-person.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class SelectPersonComponent extends BaseComponent implements OnInit, OnChanges {
    private allPeople: Person[] = [];

    @Input() public person?: Person;
    @Output() public personChange = new EventEmitter<Person | undefined>();

    @Input() public required: boolean = false;
    @Input() public activeOnly: boolean = true;
    @Input() public filter?: ISelectPersonFilter;
    @Input() public teamGroup?: Team;
    @Input() public disabled: boolean = false;
    // all user types by default - allow specify selection to a certain group
    @Input() public userTypes = [UserType.Coach, UserType.Leader, UserType.Collaborator, UserType.Viewer, UserType.None];

    public dataSource?: DataSource;
    private selectBoxInstance?: dxSelectBox;

    constructor(
        private commonDataService: CommonDataService,
        private rxjsBreezeService: RxjsBreezeService,
    ) {
        super();
    }

    public async ngOnInit() {
        const peopleData = new PeopleQueryUtilities(this.commonDataService);

        merge(
            this.rxjsBreezeService.entityTypeChanged(RoleConnection),
            this.rxjsBreezeService.entityTypeChanged(Connection),
        ).pipe(
            startWith(undefined),
            debounceTime(100),
            switchMap(() => this.activeOnly
                ? peopleData.promiseToGetActivePeople()
                : peopleData.getAllPeople()),
            this.takeUntilDestroyed(),
        ).subscribe((people) => {
            this.allPeople = people;
            this.reload();
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.allPeople.length) {
            if ((changes.filter && !changes.filter.firstChange)
                || (changes.teamGroup && !changes.teamGroup.firstChange)) {
                this.reload();
            }
        }
    }

    public onSelectBoxInitialized(e: InitializedEvent) {
        this.selectBoxInstance = e.component;

        if (this.required) {
            const validator = Validator.getInstance(e.element!) as Validator;
            validator.option("validationRules", [{ type: "required", message: "Person is required" }]);
            validator.validate();
        }
    }

    public reset() {
        this.selectBoxInstance?.reset();
        this.reload();
    }

    public reload() {
        let filteredPeople = this.filter
            ? this.allPeople.filter(this.filter)
            : this.allPeople;
        // filter by userType
        filteredPeople = filteredPeople.filter((person) => person.getActiveConnections().some((c) => this.userTypes.includes(c.userType)));

        if (this.person && filteredPeople.indexOf(this.person) < 0) {
            filteredPeople.push(this.person);
        }

        const orderedPeople = filteredPeople.sort((person1, person2) => person1.fullName < person2.fullName ? -1 : 1);

        this.dataSource = new DataSource({
            store: new ArrayStore({
                data: orderedPeople,
                key: "personId",
            }),
            group: (person) => SelectPersonUtilities.getPersonGroup(person, this.teamGroup, this.activeOnly),
            postProcess: SelectPersonUtilities.postProcessPeopleDataSource,
        });
    }

    public itemChange(selection?: Person) {
        this.personChange.emit(selection);
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { QuadrantCorners } from "@common/ADAPT.Common.Model/organisation/bullseye-extensions";
import { BullseyeQuadrant, BullseyeQuadrantStatement } from "@common/ADAPT.Common.Model/organisation/bullseye-quadrant-statement";

@Component({
    selector: "adapt-bullseye-view",
    templateUrl: "./bullseye-view.component.html",
    styleUrls: ["./bullseye-view.component.scss"],
})
export class BullseyeViewComponent implements OnInit {
    public readonly QuadrantCorners = QuadrantCorners;
    public Quadrants = Object.keys(QuadrantCorners) as BullseyeQuadrant[];

    public quadrantHasBullseye: Record<BullseyeQuadrant, boolean> = {
        PassionateAbout: false,
        CustomerNeed: false,
        GoodAt: false,
        GetPaidFor: false,
    };
    public hasBullseyeStatement = false;

    @Input() public showZoom = false;
    @Input() public hideOuter = false;
    @Input() public isEditing = false;
    @Input() public isSelecting = false;
    @Input() public quadrants?: BullseyeQuadrant[] | undefined;
    @Output() public bullseyeStatementChange = new EventEmitter<BullseyeQuadrantStatement>();

    @Input() public selectedStatements: BullseyeQuadrantStatement[] = [];
    @Output() public statementSelect = new EventEmitter<BullseyeQuadrantStatement>();
    @Output() public statementDeselect = new EventEmitter<BullseyeQuadrantStatement>();

    public ngOnInit(): void {
        if (this.quadrants) {
            this.Quadrants = this.quadrants;
        }
    }

    public onQuadrantBullseyeChanged(quadrant: BullseyeQuadrant, hasBullseye: boolean) {
        this.quadrantHasBullseye[quadrant] = hasBullseye;
        // Do this async as this is coming back from event or after view hook which will cause ExpressionChangedAfterItHasBeenCheckedError otherwise
        setTimeout(() => this.hasBullseyeStatement = Object.values(this.quadrantHasBullseye).some((value) => !!value));
    }

    public toggleZoom() {
        this.hideOuter = !this.hideOuter;
    }
}


export enum ItemStatus {
    ToDo = "ToDo",
    InProgress = "InProgress",
    Done = "Done",
    Backlog = "Backlog",
    Closed = "Closed",
}

// todo: look at possibly combining the icon and badge classes

export class ItemStatusMetadata {
    public static readonly Backlog = new ItemStatusMetadata(
        ItemStatus.Backlog,
        0,
        "Backlog",
        "#4a3c3c",
        "item-status-backlog",
        "item-status-badge-backlog",
    );
    public static readonly ToDo = new ItemStatusMetadata(
        ItemStatus.ToDo,
        1,
        "To do",
        "#165f75",
        "item-status-todo",
        "item-status-badge-todo",
    );
    public static readonly InProgress = new ItemStatusMetadata(
        ItemStatus.InProgress,
        2,
        "In progress",
        "#ba4707",
        "item-status-inprogress",
        "item-status-badge-inprogress",
    );
    public static readonly Done = new ItemStatusMetadata(
        ItemStatus.Done,
        3,
        "Done",
        "#1a7038",
        "item-status-done",
        "item-status-badge-done",
    );
    public static readonly Closed = new ItemStatusMetadata(
        ItemStatus.Closed,
        4,
        "Archived",
        "#6c757d",
        "item-status-closed",
        "item-status-badge-closed",
    );

    public static readonly All = [
        ItemStatusMetadata.Backlog,
        ItemStatusMetadata.ToDo,
        ItemStatusMetadata.InProgress,
        ItemStatusMetadata.Done,
        ItemStatusMetadata.Closed,
    ];

    // TODO: get rid of this as we are going to specify what columns to show for kanban
    public static readonly ByStatus: { [status in ItemStatus]: ItemStatusMetadata } = {
        [ItemStatus.ToDo]: ItemStatusMetadata.ToDo,
        [ItemStatus.InProgress]: ItemStatusMetadata.InProgress,
        [ItemStatus.Done]: ItemStatusMetadata.Done,
        [ItemStatus.Backlog]: ItemStatusMetadata.Backlog,
        [ItemStatus.Closed]: ItemStatusMetadata.Closed,
    };

    /** Lookup by the enum value from the backend */
    public static readonly ByIntValue: { [intValue: number]: ItemStatusMetadata } = {
        0: ItemStatusMetadata.ToDo,
        1: ItemStatusMetadata.InProgress,
        2: ItemStatusMetadata.Done,
    };

    private constructor(
        public readonly status: ItemStatus,
        public readonly ordinal: number,
        public readonly name: string,
        public readonly color: string,
        public readonly statusClass: string,
        public readonly iconClass: string,
    ) { }
}

import { Component, Input } from "@angular/core";

@Component({
    selector: "adapt-call-to-action-title",
    template: `
        <h2 class="my-3">
            <i *ngIf="iconClass"
                [ngClass]="iconClass"></i>
            <ng-content></ng-content>
        </h2>
    `,
})
export class CallToActionTitleComponent {
    @Input() public iconClass?: string;
}

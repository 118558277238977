import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { UserType } from "@common/ADAPT.Common.Model/embed/user-type";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { PeopleQueryUtilities } from "@common/user/people-query-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import dxTagBox, { InitializedEvent, ValueChangedEvent } from "devextreme/ui/tag_box";
import Validator from "devextreme/ui/validator";
import { defer, merge } from "rxjs";
import { debounceTime, startWith, switchMap, tap } from "rxjs/operators";
import { ISelectPersonFilter } from "../select-person/select-person.component";
import { SelectPersonUtilities } from "../select-person-utilities";

@Component({
    selector: "adapt-select-people",
    templateUrl: "./select-people.component.html",
    styleUrls: ["./select-people.component.scss"],
})
export class SelectPeopleComponent extends BaseComponent implements OnInit, OnChanges {
    private allPeople: Person[] = [];

    @Input() public people: Person[] = [];
    @Output() public peopleChange = new EventEmitter<Person[]>();

    @Input() public required: boolean = false;
    @Input() public showClearButton: boolean = false;
    @Input() public activeOnly: boolean = true;
    @Input() public filter?: ISelectPersonFilter;
    @Input() public teamGroup?: Team;
    @Input() public disabled: boolean = false;
    // all user types by default - allow specify selection to a certain group
    @Input() public userTypes = [UserType.Coach, UserType.Leader, UserType.Collaborator, UserType.Viewer, UserType.None];

    public dataSource?: DataSource;
    public initialSelections: number[] = [];

    private tagBoxInstance?: dxTagBox;

    constructor(
        private commonDataService: CommonDataService,
        private rxjsBreezeService: RxjsBreezeService,
    ) {
        super();
    }

    public ngOnInit() {
        const peopleData = new PeopleQueryUtilities(this.commonDataService);

        const updatePeople$ = defer(() => this.activeOnly
            ? peopleData.promiseToGetActivePeople()
            : peopleData.getAllPeople(),
        ).pipe(
            tap((people) => {
                this.allPeople = people;
                this.reload();
            }),
        );

        merge(
            this.rxjsBreezeService.entityTypeChanged(RoleConnection),
            this.rxjsBreezeService.entityTypeChanged(Connection),
        ).pipe(
            // startWith means update will happen straight away
            startWith(undefined),
            debounceTime(100),
            switchMap(() => updatePeople$),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (this.allPeople.length) {
            if ((changes.filter && !changes.filter.firstChange)
                || (changes.teamGroup && !changes.teamGroup.firstChange)
                || (changes.people && !changes.people.firstChange)) {
                this.reload();
            }
        }
    }

    public onTagBoxInitialized(e: InitializedEvent) {
        this.tagBoxInstance = e.component;

        if (this.required) {
            const validator = Validator.getInstance(e.element!) as Validator;
            validator.option("validationRules", [{ type: "required", message: "Person is required" }]);
            validator.validate();
        }
    }

    public reset() {
        this.tagBoxInstance?.reset();
        this.reload();
    }

    public reload() {
        this.initialSelections = this.people.map((i) => i.personId);

        let filteredPeople = this.filter
            ? this.allPeople.filter(this.filter)
            : this.allPeople;
        // filter by userType
        filteredPeople = filteredPeople.filter((person) => person.getActiveConnections().some((c) => this.userTypes.includes(c.userType)));

        for (const person of this.people) {
            if (!filteredPeople.includes(person)) {
                filteredPeople.push(person);
            }
        }

        const orderedPeople = filteredPeople.sort((person1, person2) => person1.fullName < person2.fullName ? -1 : 1);

        this.dataSource = new DataSource({
            store: new ArrayStore({
                data: orderedPeople,
                key: "personId",
            }),
            group: (person) => SelectPersonUtilities.getPersonGroup(person, this.teamGroup, this.activeOnly),
            postProcess: SelectPersonUtilities.postProcessPeopleDataSource,
        });
    }

    public onValueChanged(event: ValueChangedEvent) {
        const lastSelections = (event.value as number[])
            .map((personId) => this.allPeople.find((p) => p.personId === personId)!)
            .filter((person) => !!person);

        this.peopleChange.emit(lastSelections);
    }
}

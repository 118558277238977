<div class="card"
     *adaptLoadingUntilFirstEmit="let account of account$">
    <div class="card-header">
        <h3 class="card-title">Subscription</h3>
    </div>
    <div class="card-body">
        <adapt-configure-eula [account]="account"
                              class="mb-3"></adapt-configure-eula>
        @if (account && account.extensions.isActive) {
            @if (account.extensions.isFree) {
                <div>
                    @if (account.extensions.isBilledUsingCreditCard) {
                        You are on a free plan.
                    } @else {
                        Your subscription payments are invoiced by the adapt accounts team.
                    }
                </div>
            } @else {

                @if (account && account.extensions.isUsingPricingModel) {
                    <p>
                        Your {{AdaptProjectLabel}} subscription
                        @if (account.pricingModel?.monthlyFeeDollars) {
                            <span data-test="subscription-payment-summary">
                                has a fixed {{account.extensions.billingPeriodLabel()}} fee of
                                {{account.extensions.subscriptionCost | currency: account.currency?.code?.toUpperCase() : 'symbol' : '1.0-0'}}
                                <small>(ex. GST)</small>
                        </span>
                        }

                        @if (!account.pricingModel?.monthlyFeeDollars) {
                            <span>is based on the amount of users:</span>
                        }
                    </p>
                    @if (!account.pricingModel?.monthlyFeeDollars) {
                        <adapt-display-pricing-breakdown [pricingModel]="account.pricingModel"
                                                         [organisation]="account.organisation"></adapt-display-pricing-breakdown>
                    }

                } @else {
                    <p>Billed {{account?.monthlyFeeDollars | currency:account?.currency?.code?.toUpperCase()}}/month
                        <small> (ex. GST)</small>
                    </p>
                }

                @if (invoice$ | async; as invoice) {
                    <p>Your next payment is due <strong>{{invoice.date | adaptDate}}</strong></p>
                    <button *adaptIfAlto="true"
                            adaptButton="primary"
                            data-test="change-payment-frequency"
                            [adaptBlockingClick]="changePaymentFrequency"
                            [unsubscribeOnDestroy]="true">Change payment frequency
                    </button>
                }

            }

            <button class="mt-2"
                    adaptButton="primary"
                    [adaptBlockingClick]="accountService.cancelSubscriptionDialog"
                    [unsubscribeOnDestroy]="false">Cancel subscription
            </button>
        } @else {
            <p>
                @if (account?.extensions?.isPendingCancellation) {
                    You have cancelled your subscription.
                } @else {
                    Currently, your organisation does not have an active subscription to adapt.
                }
            </p>

            @if (canChargeOrganisation$ | async) {
                <div role="alert"
                     class="alert alert-info mt-3">
                    <h4><span class="fal fa-info-circle me-1"></span>Potential Subscription Costs</h4>

                    <label>How much would we be charging you?</label>
                    @if (account?.extensions?.isFree) {
                        <p>Nothing! You are on a free plan.</p>
                    } @else if (account?.extensions?.isUsingPricingModel) {
                        <p>You would be billed on a per user basis. The amount charged will be based on the number of
                            active users on the day of billing.</p>
                        <adapt-display-pricing-breakdown *ngIf="account?.pricingModel as pricingModel"
                                                         [pricingModel]="pricingModel"
                                                         [organisation]="account?.organisation">
                        </adapt-display-pricing-breakdown>
                    } @else {
                        <p>Annual Fee: {{account?.extensions?.annualSubscriptionCost | currency:account?.currency?.code?.toUpperCase()}} ex.
                            GST</p>
                    }
                </div>
            } @else {
                <ng-container *adaptIfAlto="false">Please see your resilient business coach to initiate a subscription.</ng-container>
            }
        }
    </div>
</div>

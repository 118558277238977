import { Component, Input } from "@angular/core";
import { OutstandingSurveyResponse } from "@common/ADAPT.Common.Model/organisation/outstanding-survey-response";
import { take } from "rxjs/operators";
import { SurveyPageRoute } from "../survey-page/survey-page.route";

@Component({
    selector: "adapt-personal-ongoing-survey",
    templateUrl: "./personal-ongoing-survey.component.html",
    styleUrls: ["../survey-global-styles.scss"],
})
export class PersonalOngoingSurveyComponent {
    @Input() public outstandingSurveyResponse!: OutstandingSurveyResponse;

    public completeSurvey() {
        SurveyPageRoute.gotoRoute({},
            {
                token: this.outstandingSurveyResponse.token,
                name: this.outstandingSurveyResponse.survey.name,
            }).pipe(
                take(1), // complete on emit -> don't have to unsubscribe
            ).subscribe();
    }
}

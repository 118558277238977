<adapt-collapsible-search-row [result]="result"
                              [matches]="matches"
                              [href$]="href$"
                              [ngClass]="{'no-container': !showContainer}">
    <div class="d-flex align-items-center justify-content-center">
        <adapt-link-person [person]="result.person"
                           [showText]="false"
                           [showImage]="true"
                           imageSize="sm"></adapt-link-person>
        <a [routerLink]="href$ | async"
           class="ms-2">
            <adapt-highlighted-text [needle]="keyword"
                                    [haystack]="result.person.fullName"></adapt-highlighted-text>
            <ng-container *ngIf="!result.person.isActive()">
                <span class="badge bg-danger ms-2"
                      adaptTooltip="This person is no longer active in the organisation.">
                    <i class="fal fa-exclamation-circle"></i> Inactive
                </span>
            </ng-container>
        </a>
    </div>
</adapt-collapsible-search-row>

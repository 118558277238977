import { Component, Inject, Input, OnChanges } from "@angular/core";
import { IMeetingCustomData, Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { CalendarIntegrationProvider } from "@common/ADAPT.Common.Model/organisation/organisation-detail";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { Event } from "@microsoft/microsoft-graph-types-beta";
import { MeetingsUiService } from "@org-common/lib/meetings/meetings-ui.service";
import { lastValueFrom, Subject, switchMap } from "rxjs";
import { debounceTime, delay, distinctUntilChanged, filter, tap } from "rxjs/operators";
import { DirectorySharedService } from "../../directory-shared/directory-shared.service";
import { MeetingsService } from "../../meetings/meetings.service";
import { OAuthService } from "../../oauth/oauth.service";
import { CALENDAR_PROVIDERS, ICalendarProvider, IMeetingDifferences } from "../calendar.interface";
import { CalendarIntegrationUtilities } from "../calendar-integration-utilities";

@Component({
    selector: "adapt-link-external-meeting",
    templateUrl: "./link-external-meeting.component.html",
})
export class LinkExternalMeetingComponent extends BaseComponent implements OnChanges {
    public readonly AdaptProjectLabel = AdaptClientConfiguration.AdaptProjectLabel;

    @Input() public meeting!: Meeting;

    public loading = true;
    public authenticated = false;
    public canEditTeamMeeting = false;

    public meetingLink?: string;
    public teamsLink?: string;

    private triggerUpdate$ = new Subject<void>();

    private differences?: IMeetingDifferences;
    private currentProvider?: ICalendarProvider;
    private meetingProvider?: ICalendarProvider;

    private providerMeeting?: Event;

    public constructor(
        rxjsBreezeService: RxjsBreezeService,
        meetingsService: MeetingsService,
        private commonDataService: CommonDataService,
        private oauthService: OAuthService,
        private directorySharedService: DirectorySharedService,
        private calendarIntegrationUtilities: CalendarIntegrationUtilities,
        private meetingsUiService: MeetingsUiService,
        @Inject(CALENDAR_PROVIDERS) private calendarProviders: ICalendarProvider[],
    ) {
        super();

        rxjsBreezeService.entityTypeChanged(Meeting).pipe(
            filter((meeting) => this.meeting.meetingId === meeting.meetingId && !this.meeting.extensions.isEnded),
            tap(() => this.loading = true),
            // large delay to ensure the event is in the attendees calendar (mostly relevant due to entity sync of meeting)
            debounceTime(2_000),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.triggerUpdate$.next());

        this.oauthService.authProvider$.pipe(
            distinctUntilChanged(),
            this.takeUntilDestroyed(),
        ).subscribe((provider) => {
            this.currentProvider = provider;
            this.triggerUpdate$.next();
        });

        this.oauthService.isAuthedWithProvider$(CalendarIntegrationProvider.Microsoft).pipe(
            distinctUntilChanged(),
            this.takeUntilDestroyed(),
        ).subscribe((authed) => {
            this.authenticated = authed;
            this.triggerUpdate$.next();
        });

        this.triggerUpdate$.pipe(
            // no point checking provider meeting if ended.
            // if in progress, we still want to fetch so we can show the online meeting link.
            filter(() => !this.meeting.extensions.isEnded),
            tap(() => {
                this.loading = true;
                this.meetingProvider = this.getCalendarProviderForMeeting();

                if (this.meeting?.team) {
                    this.canEditTeamMeeting = meetingsService.canEditMeetingForTeam(this.meeting.team);
                }
            }),
            debounceTime(500),
            switchMap(() => this.getProviderMeeting()),
            tap((providerMeeting) => {
                // only compare differences for upcoming meetings.
                this.differences = this.meeting.extensions.isNotStarted && providerMeeting
                    ? this.calendarIntegrationUtilities.getMeetingEventDifferences(this.meeting, providerMeeting)
                    : {} as IMeetingDifferences;
            }),
            // show the spinner for a little longer so it doesn't flash in and out
            delay(250),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.loading = false);
    }

    public get provider() {
        return this.meetingProvider ?? this.currentProvider;
    }

    public get providerMeetingMissing() {
        return this.authenticated && !this.providerMeeting && !this.hasAssociatedProviderMeeting;
    }

    public get userCalendarMeetingMissing() {
        return this.authenticated && !this.providerMeeting && this.hasAssociatedProviderMeeting;
    }

    public get outOfSync() {
        if (!this.meetingLink || !this.differences) {
            return false;
        }

        return this.remoteShouldBeUpdated || this.hasDifferences;
    }

    public get remoteHasChanges() {
        if (!this.meetingLink || !this.differences) {
            return false;
        }

        return !this.remoteShouldBeUpdated && this.hasDifferences;
    }

    public get remoteShouldBeUpdated() {
        return this.differences?.remoteShouldBeUpdated ?? false;
    }

    public get canCreateOrUpdateRemoteMeeting() {
        return this.organiser.isOrganiser || this.providerMeetingMissing;
    }

    public get hasDifferences() {
        if (!this.meetingLink || !this.differences) {
            return false;
        }

        const { remoteShouldBeUpdated, hasOnlineMeeting, ...differences } = this.differences;
        return Object.keys(differences).length > 0;
    }

    public get organiser() {
        return this.calendarIntegrationUtilities.getMeetingOrganiser(this.meeting, this.providerMeeting);
    }

    public get hasAssociatedProviderMeeting() {
        const providerMeetingId = this.calendarIntegrationUtilities.getProviderMeetingId(this.meeting, CalendarIntegrationProvider.Microsoft);

        // organiser will always be able to fetch the meeting, if it doesn't return then it's been deleted
        // otherwise just check if there is an associated provider meeting id
        return this.organiser.isOrganiser
            ? !!this.providerMeeting
            : !!providerMeetingId;
    }

    public ngOnChanges() {
        if (this.meeting) {
            this.loading = !this.meeting.extensions.isEnded;
            this.providerMeeting = undefined;
            this.meetingLink = undefined;
            this.teamsLink = undefined;
            this.differences = undefined;

            this.updateProviderFromMeeting();

            this.triggerUpdate$.next();
        }
    }

    private getCalendarProviderForMeeting() {
        const providerMeetingId = this.calendarIntegrationUtilities.getProviderMeetingId(this.meeting, CalendarIntegrationProvider.Microsoft);
        return providerMeetingId
            ? this.calendarProviders.find((p) => p.id === CalendarIntegrationProvider.Microsoft)
            : undefined;
    }

    @Autobind
    public async createOrUpdateRemoteMeeting() {
        if (this.meeting.meetingAttendees.length > 0) {
            // prime all contacts for the attendees so we can get their email
            await this.directorySharedService.promiseToGetContactDetailsByPersonIds(this.meeting.meetingAttendees.map((attendee) => attendee.attendeeId));
        }

        // need to update the date so that not remoteShouldBeUpdated when it loads the new provider meeting
        this.meeting.lastUpdatedDateTime = new Date();

        const customData = this.meeting.extensions.getCustomData<IMeetingCustomData>();
        await lastValueFrom(this.calendarIntegrationUtilities.createOrUpdateProviderMeeting(this.meeting, {
            name: this.meeting.location,
            id: customData.microsoftLocation,
        }, !!this.teamsLink));

        await lastValueFrom(this.commonDataService.saveEntities([this.meeting]));
    }

    @Autobind
    public attachExistingRemoteMeeting() {
        return this.meetingsUiService.linkExistingCalendarIntegrationMeeting(this.meeting);
    }

    @Autobind
    public async syncFromRemoteMeeting() {
        if (this.differences) {
            const { customDataObject, remoteShouldBeUpdated, hasOnlineMeeting, ...differences } = this.differences;

            if (differences) {
                this.meeting = Object.assign(this.meeting, differences);
            }

            const customData = customDataObject ?? this.meeting.extensions.getCustomData<IMeetingCustomData>();
            customData.microsoftLastSynced = this.providerMeeting?.lastModifiedDateTime ?? new Date().toISOString();
            this.meeting.extensions.updateCustomData(customData);

            await lastValueFrom(this.commonDataService.saveEntities([this.meeting]));
        }
    }

    private getProviderMeeting() {
        return this.calendarIntegrationUtilities.getProviderMeeting(this.meeting).pipe(
            tap((microsoftMeeting) => {
                this.updateProviderFromMeeting();
                this.providerMeeting = microsoftMeeting;
                this.meetingLink = microsoftMeeting?.id && this.meetingProvider
                    ? this.meetingProvider.getCalendarLink?.(microsoftMeeting.id)
                    : undefined;
                this.teamsLink = microsoftMeeting?.onlineMeeting?.joinUrl ?? undefined;
            }),
        );
    }

    private updateProviderFromMeeting() {
        const providerMeetingId = this.meeting
            ? this.calendarIntegrationUtilities.getProviderMeetingId(this.meeting, CalendarIntegrationProvider.Microsoft)
            : undefined;
        this.meetingProvider = providerMeetingId
            ? this.calendarProviders?.find((p) => p.id === CalendarIntegrationProvider.Microsoft)
            : undefined;
    }
}

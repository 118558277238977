import { KeyValue } from "@angular/common";
import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import { Logger } from "@common/lib/logger/logger";
import { SentryLogProvider } from "@common/lib/logger/sentry-log-provider";
import { Trace } from "@common/lib/logger/trace";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import isEqual from "lodash.isequal";
import { distinctUntilChanged, tap } from "rxjs/operators";
import { OrganisationPageRouteBuilder } from "../../route/organisation-page-route-builder";
import { ISearchGroup, ISearchOptions, ISearchSubGroup, ISearchUrlParams, SearchType } from "../search.interface";
import { searchGroupMapping, SearchService, SearchSlowErrorTimeout, SearchSlowTimeout } from "../search.service";
import { ISearchResults, ISubGroupedSearchResult } from "../search-results.interface";
import Timeout = NodeJS.Timeout;

type ISearchTypeKeys = keyof typeof SearchType;

@Component({
    selector: "adapt-search-page",
    templateUrl: "./search-page.component.html",
    styleUrls: ["./search-page.component.scss"],
})
export class SearchPageComponent extends BaseRoutedComponent implements OnInit, OnDestroy {
    public readonly SearchType = SearchType;
    public readonly SearchTypeMapping = SearchService.SearchTypeMapping;

    public searchKeyword?: string;
    public searchResults?: ISearchResults;
    public searchResultGroups = new Map<ISearchGroup, boolean>();
    public searchResultSubgroups = new Map<SearchType, Map<ISearchSubGroup, ISearchResults>>();
    public resultCount = 0;
    public isValidQuery = false;
    public hasNonSupportResults = false;

    public options?: ISearchOptions;
    public isLoading = false;

    public slowTimeouts: Timeout[] = [];
    public slowLoadingIndicator = false;

    public sentryLogger = Logger.getLogProviderOfType(SentryLogProvider);
    public searchService: SearchService;

    public searchElements = SearchService.SearchElementRegistrar!;

    constructor(injector: Injector) {
        super(injector);

        // This is to break cyclic webpack dependency from cumulus unit test, with:
        //  SearchService -> searchPageRoute -> searchPageComponent -> SearchService
        this.searchService = injector.get(SearchService);
    }

    // needed for iterating maps. angular will sort maps differently if not specified.
    public originalOrder() {
        return 0;
    }

    public trackByResultGroup(_index: number, group: KeyValue<ISearchGroup, boolean>) {
        return group.key.type;
    }

    public trackByResultSubGroup(_index: number, group: KeyValue<ISearchSubGroup, ISearchResults>) {
        return group.key.type;
    }

    public ngOnInit() {
        this.searchService.showResults();

        this.restoreFromParams();

        this.searchService.searchOptions$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((options) => {
            this.options = options;
            this.isValidQuery = this.searchService.shouldPerformSearch(options.keyword, options.labelIds);
        });

        this.searchService.searchQuery$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((options) => {
            this.searchKeyword = options?.keyword;
        });

        this.searchService.searchResults$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((results) => {
            this.searchResults = results;

            // new result set, reset the groups
            if (!results) {
                this.searchResultGroups.clear();
                this.searchResultSubgroups.clear();
            }

            if (results) {
                for (const group of searchGroupMapping) {
                    // filter out Implementation Kit results as we handle those separately
                    if (group.type === SearchType.ImplementationKit) {
                        continue;
                    }

                    this.searchResultGroups.set(group, false);

                    const key = this.getGroupKey(group.type);
                    const groupResults = results[key];

                    // show the group if there are results, or if there are any errors
                    if ((groupResults && groupResults.length > 0) || this.getErrors(group.type).length > 0) {
                        this.searchResultGroups.set(group, true);

                        // group results by subgroup if necessary
                        if (group.subGroups && !this.searchResultSubgroups.has(group.type)) {
                            const subGroupMap = this.getSubGroupMapFromResults(group, groupResults as ISubGroupedSearchResult<any>[]);
                            // only add to the subgroups if not already there
                            if (subGroupMap.size > 0) {
                                this.searchResultSubgroups.set(group.type, subGroupMap);
                            }
                        }
                    }
                }
            }

            this.hasNonSupportResults = !Array.from(this.searchResultGroups.values())
                .every((hasResults) => !hasResults);

            this.resultCount = Object.values(results ?? {})
                .reduce((acc, category) => acc += category?.length ?? 0, 0);
        });

        this.searchService.isLoading$.pipe(
            distinctUntilChanged(),
            tap((loading) => {
                this.isLoading = loading;
                if (loading) {
                    this.slowTimeouts.push(setTimeout(() => this.slowLoadingIndicator = true, SearchSlowTimeout));
                    this.slowTimeouts.push(setTimeout(() => {
                        this.sentryLogger?.write({
                            level: Trace.Error,
                            timestamp: new Date(),
                            moduleId: this.constructor.name,
                            message: "Search taking a while...",
                            data: [],
                        });
                    }, SearchSlowErrorTimeout));
                } else {
                    this.slowTimeouts.forEach(clearTimeout);
                    this.slowLoadingIndicator = false;
                }
            }),
            this.takeUntilDestroyed(),
        ).subscribe();

        this.notifyActivated();
        this.navigationEnd.subscribe(() => {
            this.searchService.showResults();
            this.restoreFromParams();
            this.notifyActivated();
        });
    }

    private getSubGroupMapFromResults(group: ISearchGroup, typeResults?: ISubGroupedSearchResult<any>[]) {
        const subGroupMap = new Map<ISearchSubGroup, ISearchResults>();

        if (typeResults) {
            const groupKey = this.getGroupKey(group.type);
            for (const subGroup of group.subGroups!) {
                const items = typeResults.filter((result) => result.type === subGroup.type);
                if (items.length > 0) {
                    subGroupMap.set(subGroup, { [groupKey]: items } as ISearchResults);
                }
            }
        }

        return subGroupMap;
    }

    public getErrors(type: SearchType) {
        return this.searchService.providerSearchErrors.get(type) ?? [];
    }

    public get showImplementationKitResults() {
        return (this.isLoading && this.options?.types.has(SearchType.ImplementationKit))
            || this.hasImplementationKitResults
            || this.getErrors(SearchType.ImplementationKit).length > 0;
    }

    private get hasImplementationKitResults() {
        return this.searchResults?.ImplementationKit
            && this.searchResults.ImplementationKit.length > 0
            && !this.isLoading;
    }

    private restoreFromParams() {
        // setting the options here can cause multiple searches as this is run again when the searchParams update
        // so make sure the options have actually changed first before setting
        const options = this.searchService.optionsFromSearchParams(this.getSearchParameters() as ISearchUrlParams);
        if (!isEqual(options, this.options)) {
            // make sure we start with the defaults so that label, etc. are overridden
            this.searchService.setSearchOptions({ ...this.searchService.getEmptyOptions(), ...options });
        }
    }

    private getGroupKey(type: SearchType) {
        return SearchType[type] as ISearchTypeKeys;
    }
}

export const SearchPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-search-page", SearchPageComponent)
    .atOrganisationUrl("/search")
    .withTitle("Search")
    .reloadOnSearch(true)
    .build();

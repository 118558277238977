import { Invoice } from "../account/invoice";
import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Person } from "../person/person";
import { Practitioner } from "../practitioner/practitioner";
import { CoachSessionStatus, CoachSessionStatusMetadata } from "./coach-session-status";
import { Organisation } from "./organisation";


export class CoachSession extends BaseEntity<CoachSession> {
    public coachSessionId!: number;
    public invoiceId!: number;
    public organisationId!: number;
    public personId!: number;
    public practitionerId?: number;
    public status!: CoachSessionStatus;
    public description!: string;
    public feedbackRating?: number;
    public feedbackComment?: string;

    // navigation
    public invoice!: Invoice;
    public organisation!: Organisation;
    public person!: Person;
    public practitioner?: Practitioner;

    public get statusMetadata() {
        return CoachSessionStatusMetadata.ByType[this.status];
    }
}

export const CoachSessionBreezeModel = new BreezeModelBuilder("CoachSession", CoachSession)
    .hasSource()
    .withIdField("organisationId")
    .alwaysFetchingNavigationProperty("person")
    .alwaysFetchingNestedNavigationProperty("invoice.invoiceItems")
    .alwaysFetchingNestedNavigationProperty("invoice.accountPayments")
    .alwaysFetchingNestedNavigationProperty("practitioner.person")
    .isOrganisationEntity()
    .build();

import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { DxValidationGroupComponent } from "devextreme-angular";
import { lastValueFrom } from "rxjs";
import { IdentityUxService, IResetPasswordPayload, ReasonCode } from "../identity-ux.service";
import { loginPageRoute } from "../login-page/login-page.route";

@Component({
    selector: "adapt-reset-password-page",
    templateUrl: "./reset-password-page.component.html",
})
export class ResetPasswordPageComponent extends BaseRoutedComponent implements OnInit {
    @ViewChild("validationGroup") public validationGroup: DxValidationGroupComponent | undefined;

    public email?: string;
    public emailProvided = false;
    public password = "";
    public confirmPassword = "";

    public token: string;

    public isProcessing = false;
    public isDisabled = true;
    public submitMessage?: string;

    public constructor(
        public injector: Injector,
        public identityUxService: IdentityUxService,
    ) {
        super(injector);

        this.token = this.getSearchParameterValue("token")!;
        if (!this.token) {
            // redirect user home if they got to page without a token
            this.routeService.gotoHome();
        }

        const email = this.getSearchParameterValue("email");
        if (email) {
            this.emailProvided = true;
        }
        this.email = email ?? "";
    }

    public passwordComparison = () => this.password;

    public ngOnInit() {
        this.notifyActivated();
        this.shellUiService.removeDefaultShellPadding();
    }

    public validateGroup() {
        this.isDisabled = !this.validationGroup?.instance.validate().isValid;
    }

    @Autobind
    public async submit() {
        if (this.isProcessing) {
            return;
        }

        this.isProcessing = true;
        this.submitMessage = undefined;

        const payload: IResetPasswordPayload = {
            email: this.email!,
            newPassword: this.password,
            token: this.token,
        };

        try {
            const reasonCode = ReasonCode.resetPassword;
            await this.identityUxService.promiseToResetPassword(payload);
            await lastValueFrom(loginPageRoute.gotoRoute(undefined, { reasonCode }, true));
        } catch (e) {
            if (e === "Invalid token.") {
                e = "Email address is invalid";
            }

            this.submitMessage = "Password reset failed: " + e;
        } finally {
            this.isProcessing = false;
        }
    }
}

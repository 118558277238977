import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Logger } from "@common/lib/logger/logger";
import { ReplaySubject } from "rxjs";
import { map } from "rxjs/operators";
import { Breakpoint } from "./breakpoint";

@Injectable({
    providedIn: "root",
})
export class ResponsiveService {
    private _currentBreakpoint: Breakpoint;
    private breakpoint$ = new ReplaySubject<Breakpoint>(1);

    private window: Window;

    private readonly log = Logger.getLogger(this.constructor.name);

    public constructor(
        @Inject(DOCUMENT) document: Document,
    ) {
        const window = document.defaultView;

        if (window === null) {
            throw new Error("window is null");
        }

        this.window = window;

        this.window.addEventListener("resize", () => this.checkForBreakpointChangeAndBroadcast());

        this._currentBreakpoint = this.getCurrentWindowBreakpointSize();

        this.breakpoint$.next(this.currentBreakpoint);
    }

    public get currentBreakpoint() {
        return this._currentBreakpoint;
    }

    public get currentBreakpoint$() {
        return this.breakpoint$.asObservable();
    }

    public get isMobileSize$() {
        return this.breakpoint$.pipe(
            map((b) => b.isMobileSize),
        );
    }

    private getCurrentWindowBreakpointSize() {
        const width = this.currentWindowWidth;

        for (const breakpoint of Breakpoint.Order) {
            if (width >= breakpoint.minSize) {
                return breakpoint;
            }
        }

        // This shouldn't be possible based on the definition of breakpointValuesOrder, but
        // default to what the current value is.
        return this.currentBreakpoint;
    }

    private checkForBreakpointChangeAndBroadcast() {
        const oldBreakpoint = this.currentBreakpoint;
        const newBreakpoint = this.getCurrentWindowBreakpointSize();

        if (newBreakpoint !== oldBreakpoint) {
            this._currentBreakpoint = newBreakpoint;

            this.log.info("Bootstrap breakpoint change to " + newBreakpoint.name + " from " + oldBreakpoint.name);
            this.breakpoint$.next(newBreakpoint);
        }
    }

    public get currentWindowWidth() {
        return this.window.innerWidth;
    }
}

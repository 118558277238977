import { AfterViewInit, Component, ComponentFactoryResolver, ComponentRef, EventEmitter, Input, OnChanges, Output, Renderer2, Type, ViewChild, ViewContainerRef } from "@angular/core";

@Component({
    selector: "adapt-render-component",
    template: `<ng-container #viewContainer></ng-container>`,
})
export class RenderComponentComponent<T> implements AfterViewInit, OnChanges {
    @Input() public component?: Type<T>;
    @Input() public componentInputs?: Partial<T>;
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("eagerRenderComponents") public eagerRenderedComponentTypes?: Type<unknown>[];
    @Output() public componentRendered = new EventEmitter<T>();
    @ViewChild("viewContainer", { read: ViewContainerRef }) public viewContainerRef?: ViewContainerRef;

    private eagerRenderedComponents = new Map<Type<unknown>, ComponentRef<unknown>>();
    private currentRenderedComponent?: ComponentRef<unknown>;

    public constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private renderer: Renderer2,
    ) {
    }

    public ngAfterViewInit() {
        setTimeout(() => {
            // any change of the component (including initializing chilren contents) cannot be done from the call stack of
            // ngAfterViewInit -> or it will cause ExpressionChangedAfterItHasBeenCheckedError
            if (this.eagerRenderedComponentTypes) {
                for (const type of this.eagerRenderedComponentTypes) {
                    const componentRef = this.renderComponentWithoutInputs(type);
                    this.renderer.setStyle(componentRef.location.nativeElement, "display", "none");
                }
            }

            this.renderComponent();
        });
    }

    public ngOnChanges() {
        if (this.viewContainerRef) {
            this.renderComponent();
        }
    }

    private renderComponent() {
        if (this.memoiseRenderedComponents && this.currentRenderedComponent) {
            this.renderer.setStyle(this.currentRenderedComponent.location.nativeElement, "display", "none");
        } else if (!this.memoiseRenderedComponents) {
            this.viewContainerRef!.clear();
        }

        if (!this.component) {
            return;
        }

        let componentRef = this.eagerRenderedComponents.get(this.component) as ComponentRef<T> | undefined;
        if (!componentRef) {
            componentRef = this.renderComponentWithoutInputs(this.component);
        }

        this.currentRenderedComponent = componentRef;
        this.renderer.removeStyle(componentRef.location.nativeElement, "display");

        if (this.componentInputs) {
            for (const [key, value] of Object.entries(this.componentInputs)) {
                (componentRef.instance as any)[key] = value;
            }

            componentRef.changeDetectorRef.markForCheck();
        }

        this.componentRendered.emit(componentRef.instance);
    }

    private renderComponentWithoutInputs<TComponent>(componentType: Type<TComponent>) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
        const eagerComponentRef = this.viewContainerRef!.createComponent(componentFactory);

        if (this.memoiseRenderedComponents) {
            this.eagerRenderedComponents.set(componentType, eagerComponentRef);
        }

        return eagerComponentRef;
    }

    private get memoiseRenderedComponents() {
        return !!this.eagerRenderedComponentTypes;
    }
}

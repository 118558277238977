<adapt-render-zone-map-internal [verticalLayout]="verticalLayout"
                                [zoneContentContainerTemplate]="defaultZoneContentTemplate"
                                [zoneMenuTemplate]="zoneMenuTemplate"
                                [fullLayoutBreakpoint]="fullLayoutBreakpoint"></adapt-render-zone-map-internal>

<ng-template #defaultZoneContentTemplate
             let-zone>
    <div class="zone-content">
        <ng-container [ngTemplateOutlet]="zoneContentTemplate"
                      [ngTemplateOutletContext]="{$implicit: zone}"></ng-container>
    </div>
</ng-template>

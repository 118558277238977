import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Organisation } from "../organisation/organisation";
import { Person } from "./person";

export enum PersonDetailNames {
    LastAccess = "LastAccess",
    WelcomeEmailDate = "WelcomeEmailDate",
}

export class PersonDetail extends BaseEntity<PersonDetail> {
    public personId!: number;
    public organisationId!: number;
    public name!: string;
    public value!: string;
    public organisation!: Organisation;
    public person!: Person;
}

export const PersonDetailBreezeModel = new BreezeModelBuilder("PersonDetail", PersonDetail)
    .withIdField()
    .hasSource()
    .isOrganisationEntity()
    .build();

<adapt-dialog [maxWidth]="850">
    <h3 adapt-dialog-title>Edit Contacts</h3>
    <ng-container adapt-dialog-content>
        <dx-validation-group>
            <!-- contact details -->
            <dl *ngFor="let contactTypeGroup of (contactGroups$ | async); trackBy:contactGroupByType"
                class="dl-horizontal"
                data-test="contact-detail-group">
                <dt>{{contactTypeGroup.contactType.name}}</dt>
                <dd *ngFor="let contactDetail of contactTypeGroup.contactDetails">
                    <div class="form-group mb-2"
                         data-test="contact-detail-form">
                        <div class="input-group">

                            <span class="input-group-text">
                                <i class="fal fa-fw"
                                   [class]="contactTypeGroup.contactType.iconClass"></i>
                            </span>

                            <dx-text-box class="col-xl-3 rounded-0 me-0"
                                         [(value)]="contactDetail.description"
                                         [maxLength]="50"
                                         placeholder="Description"></dx-text-box>

                            <!-- if email -->
                            <dx-text-box class="flex-fill rounded-0"
                                         *ngIf="contactTypeGroup.contactType.type === ContactType.Email"
                                         [(value)]="contactDetail.value"
                                         (onValueChanged)="validateEntities()"
                                         [placeholder]="contactTypeGroup.contactType.name"
                                         [maxLength]="255">
                                <dx-validator>
                                    <dxi-validation-rule type="email"></dxi-validation-rule>
                                </dx-validator>
                            </dx-text-box>

                            <!-- if not email -->
                            <dx-text-box class="flex-fill rounded-0"
                                         *ngIf="contactTypeGroup.contactType.type !== ContactType.Email"
                                         [(value)]="contactDetail.value"
                                         [placeholder]="contactTypeGroup.contactType.name"
                                         [maxLength]="255">
                            </dx-text-box>


                            <div class="input-group-text">
                                <dx-check-box class="me-1"
                                              [value]="contactDetail.isPrivate"
                                              (valueChange)="contactDetail.isPrivate = $any($event)"
                                              text="Private"></dx-check-box>

                                <span class="fal fa-fw fa-lock"
                                      adaptTooltip="If checked, only visible to this person and those with permission to view private profile data"></span>
                            </div>


                            <div *ngIf="!isLoginEmail(contactDetail)">
                                <button (click)="deleteContactDetail(contactTypeGroup.contactType.type, contactDetail)"
                                        adaptButtonIcon="delete"
                                        class="btn btn-danger"></button>
                            </div>
                            <div *ngIf="isLoginEmail(contactDetail)"
                                 class="input-group-text">

                                <i class="fal fa-fw fa-key"
                                   adaptTooltip="This email is used to log in to embedADAPT and cannot be deleted"></i>

                            </div>
                        </div>
                    </div>
                </dd>
                <!-- add new contact type -->
                <dd>
                    <button adaptButton="addLink"
                            (click)="promiseToAddContactDetail(contactTypeGroup.contactType)">
                        Add {{contactTypeGroup.contactType.name}}</button>
                </dd>
            </dl>
        </dx-validation-group>
    </ng-container>
    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="saveDisabled"
                                   [saveBlockingClick]="saveAndClose"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>

<div class="design-system">
    <div class="row">
        <div class="col-lg-3">
            <div class="side-column">
                <h2>Select Theme Area</h2>
                <ul>
                    <li *ngFor="let page of pageLinks">
                        <a [routerLink]="page.url">{{page.title}}</a>
                        <ul *ngIf="isCurrentPage(page.url) && page.sections as sections">
                            <li *ngFor="let section of sections">
                                <a class="link-color"
                                   role="button"
                                   (click)="scrollToSection(section.id)">{{section.title}}</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>

        <div class="content-body col-lg-8">
            <div class="header">
                <h1>The Ehrmantraut Code</h1>
                <p class="subtitle">"This business requires restraint."</p>
            </div>

            <h2 class="section-header">
                {{label}}
            </h2>

            <ng-content></ng-content>
        </div>
    </div>
</div>

import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { IdentityService } from "@common/identity/identity.service";
import { IdentityUxService } from "@common/identity/ux/identity-ux.service";
import { resetPasswordPageRoute } from "@common/identity/ux/reset-password-page/reset-password-page.route";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { DxValidationGroupComponent } from "devextreme-angular";
import { lastValueFrom } from "rxjs";

@Component({
    selector: "adapt-change-password-dialog",
    templateUrl: "./change-password-dialog.component.html",
})
export class ChangePasswordDialogComponent extends BaseDialogComponent<Person> implements OnInit {
    public readonly dialogName = "ChangePassword";

    @ViewChild(DxValidationGroupComponent) public validationGroup?: DxValidationGroupComponent;

    public isLoading = true;

    public oldPassword = "";
    public newPassword = "";
    public confirmPassword = "";

    public saveDisabled = true;
    public hasPassword = true;

    public isError = false;
    public submitMessage?: string;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public person: Person,
        private identityService: IdentityService,
        private identityUxService: IdentityUxService,
    ) {
        super();
    }

    public async ngOnInit() {
        const result = await this.identityService.getExternalLoginStatus();
        this.hasPassword = result.body?.hasPassword ?? true;

        this.isLoading = false;
    }

    @Autobind
    public passwordComparison() {
        return this.newPassword;
    }

    @Autobind
    public async resetPassword() {
        const email = this.person.getLoginEmail()?.value;
        if (!email) {
            return;
        }

        const route = await lastValueFrom(resetPasswordPageRoute.getRoute());
        const redirectUrl = window.location.origin + route;

        try {
            await this.identityUxService.promiseToSendForgotPassword({ email, redirectUrl });
            this.submitMessage = `A password reset email has been sent to <b>"${email}"</b>.`
                + ` Please check your email and follow the instructions in the email.`;
            this.isError = false;
        } catch (e) {
            this.submitMessage = ErrorHandlingUtilities.getHttpResponseMessage(e);
            this.isError = true;
        }
    }

    public validateGroup() {
        this.saveDisabled = !this.validationGroup
            || !this.validationGroup.instance.validate().isValid
            || !this.hasPassword;
    }

    @Autobind
    public async saveAndClose() {
        const payload = {
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
        };

        try {
            await this.identityService.changePassword(payload);
            this.resolve(this.person);
        } catch (error) {
            this.oldPassword = "";

            const message = ErrorHandlingUtilities.getHttpResponseMessage(error);
            this.submitMessage = message;
            this.isError = true;
        }
    }
}

import { AfterViewChecked, Component, ElementRef, ViewChild, ViewEncapsulation } from "@angular/core";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ElementUtilities } from "@common/lib/utilities/element-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { teamKanbanPageRoute } from "@org-common/lib/kanban/kanban-page/kanban-page.route";
import { MeetingsService } from "@org-common/lib/meetings/meetings.service";
import { MeetingsUiService } from "@org-common/lib/meetings/meetings-ui.service";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";
import { Observable } from "rxjs";
import { pairwise, startWith, tap } from "rxjs/operators";
import { OrganisationFeatureFlag } from "../../features/organisation-feature-flag.enum";

@Component({
    selector: "adapt-meeting-tab-content",
    templateUrl: "./meeting-tab-content.component.html",
    styleUrls: ["./meeting-tab-content.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class MeetingTabContentComponent extends BaseComponent implements AfterViewChecked {
    public readonly OrganisationFeatureFlag = OrganisationFeatureFlag;
    public readonly ViewTeamKanban = CommonTeamsAuthService.ViewTeamKanban;

    public set meeting$(value: Observable<Meeting | undefined>) {
        // setter is called from adapt-render-component through [componentInputs]
        // - this is to intercept meeting change to reinitialise tab height for button panel to appear at the bottom
        //   and also reinitialising corresponding team work url
        this._meeting$ = value.pipe(
            // note that we still want to emit if undefined to unload the contents of this component (template *ngIf)
            // - otherwise the last meeting will stay behind in the hidden tab.
            // so don't use emptyIfUndefinedOfNull() here!
            tap((meeting) => {
                if (meeting) {
                    this.canEditTeamMeeting = this.meetingsService.canEditMeetingForTeam(meeting.team!);
                    this.heightInitialised = false;
                    this.teamWorkUrl$ = teamKanbanPageRoute.getRoute({ teamId: meeting!.teamId });
                }
            }),
        );
    }

    // component template is subscribing to meeting$
    public get meeting$() {
        return this._meeting$;
    }

    public teamWorkUrl$?: Observable<string>;
    public canEditTeamMeeting = false;

    @ViewChild("meetingTabContent") private contentContainer!: ElementRef<HTMLElement>;

    private heightInitialised = false;
    private _meeting$!: Observable<Meeting | undefined>;

    public constructor(
        elementRef: ElementRef,
        private meetingsService: MeetingsService,
        private meetingsUiService: MeetingsUiService,
        private responsiveService: ResponsiveService,
    ) {
        super(elementRef);
        this.responsiveService.currentBreakpoint$.pipe(
            startWith(this.responsiveService.currentBreakpoint),
            pairwise(),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.heightInitialised = false); // reinitialise height to keep bottom sticky button if breakpoint changed
    }

    public ngAfterViewChecked() {
        if (!this.heightInitialised
            && this.contentContainer
            // the content can be outside of the view when the loading spinner is still showing for the tab
            && ElementUtilities.isElementInView(this.contentContainer.nativeElement)) {
            // setting the content to full height of the sidebar so we can add the sticky button at the bottom
            const top = this.contentContainer.nativeElement.getBoundingClientRect().top;
            if (top >= 0) {
                this.heightInitialised = true;
                // additional 10px from common-sidebar scroll view padding at the bottom
                let bottomOffset = 20;
                if (this.responsiveService.currentBreakpoint.isMobileSize) {
                    // for mobile, there is an additional logout button panel at the bottom
                    bottomOffset += 54;
                }

                this.contentContainer.nativeElement.style.height = `calc(100vh - ${top + bottomOffset}px)`;
            }
        }
    }

    @Autobind
    public editMeetingAgenda(meeting: Meeting) {
        return this.meetingsUiService.editMeetingAgenda(meeting);
    }

    @Autobind
    public gotoMeetingPage(meeting: Meeting) {
        return this.meetingsService.gotoMeetingPage(meeting);
    }

    @Autobind
    public endMeeting(meeting: Meeting) {
        return this.meetingsUiService.endMeeting(meeting);
    }

    @Autobind
    public rescheduleMeeting(meeting: Meeting) {
        return this.meetingsUiService.rescheduleMeeting(meeting);
    }

    @Autobind
    public moveToActiveMeetingPage(meeting: Meeting) {
        return this.meetingsUiService.moveMeetingToActivePage(meeting);
    }
}

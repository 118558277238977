import { Component, Input } from "@angular/core";
import { Workflow } from "@common/ADAPT.Common.Model/embed/workflow";
import { IAdaptLinkObject } from "@common/route/route.service";
import { Observable, ReplaySubject } from "rxjs";
import { switchMap } from "rxjs/operators";
import { WorkflowService } from "../workflow.service";

@Component({
    selector: "adapt-link-workflow",
    templateUrl: "./link-workflow.component.html",
})
export class LinkWorkflowComponent {
    @Input() public set workflow(value: Workflow | undefined) {
        if (value) {
            this.workflow$.next(value);
        }
    }
    public workflow$ = new ReplaySubject<Workflow>(1);

    public href$: Observable<IAdaptLinkObject | undefined>;

    public constructor(
        workflowService: WorkflowService,
    ) {
        this.href$ = this.workflow$.pipe(
            switchMap(({ workflowId }) => workflowService.getWorkflowPageRoute(workflowId)),
        );
    }
}

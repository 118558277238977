/* eslint-disable max-classes-per-file */
import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";

export interface ITreeViewPickerNode {
    id: string;
    parentId: string | null;
    ordinal: number;
    label: string;
    board?: Board;
    team?: Team;
    expanded?: boolean;
    disabled?: boolean;
    visible?: boolean;
    isSingleSelectable: boolean;
    isPersonBased: boolean;
    selected?: boolean;
}

export class TreeViewUtils {
    public static readonly AllBoardsId = "all_boards";
    private static readonly PersonalBoardsNodeId = "personal_boards_root";
    private static readonly PersonalBoardsPlaceholderNodeId = "no_personal_boards";
    private static readonly TeamBoardsNodeId = "team_boards_root";
    private static readonly OtherTeamBoardsNodeId = "other_team_boards_root";

    public static generateTreeViewPickerNodes(boards: Board[], activeTeams: Team[]): ITreeViewPickerNode[] {
        let treeViewNodes: ITreeViewPickerNode[] = [];
        treeViewNodes.push(TreeViewUtils.generateAllBoardsNode());

        const boardNodes = boards.map(TreeViewUtils.generateNodeForBoard);
        treeViewNodes = treeViewNodes.concat(boardNodes);

        const personalBoardsNode = TreeViewUtils.generatePersonalBoardsNode();
        treeViewNodes.push(personalBoardsNode);
        personalBoardsNode.disabled = !boardNodes.some((n) => n.parentId === personalBoardsNode.id);
        if (personalBoardsNode.disabled) {
            treeViewNodes.push(TreeViewUtils.generatePersonalBoardsPlaceholderNode());
        }

        const boardTeams = boards
            .filter((b) => b.isTeamBoard && b.team)
            .map((b) => b.team);
        const uniqueTeams = boardTeams.filter((t, idx, arr) => arr.indexOf(t) === idx);
        const teamNodes = uniqueTeams
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((t, idx) => TreeViewUtils.getNodeForTeam(t, idx, activeTeams));
        treeViewNodes = treeViewNodes.concat(teamNodes);

        const teamBoardsNode = TreeViewUtils.generateTeamBoardsNode();
        if (teamNodes.some((n) => n.parentId === teamBoardsNode.id)) {
            treeViewNodes.push(teamBoardsNode);
        }

        const otherTeamBoardsNode = TreeViewUtils.generateOtherTeamBoardsNode();
        if (teamNodes.some((n) => n.parentId === otherTeamBoardsNode.id)) {
            treeViewNodes.push(otherTeamBoardsNode);
        }

        return treeViewNodes;
    }

    public static generateTreeViewPickerNodesForTeam(team: Team) {
        const treeViewNodes: ITreeViewPickerNode[] = [];
        treeViewNodes.push(TreeViewUtils.generateAllBoardsNode());
        treeViewNodes.push(TreeViewUtils.getNodeForTeam(team, 0));
        team.boards
            .sort((a, b) => a.ordinal - b.ordinal)
            .filter((b) => !b.isArchived)
            .map(TreeViewUtils.generateNodeForBoard)
            .forEach((n) => treeViewNodes.push(n));

        return treeViewNodes;
    }

    public static getNodeForTeam(team: Team, index: number, activeTeams?: Team[]): ITreeViewPickerNode {
        let parentId: string | null = null;
        let expanded = true;

        if (activeTeams) {
            const isActiveTeam = activeTeams.indexOf(team) >= 0;
            expanded = isActiveTeam;
            parentId = isActiveTeam
                ? TreeViewUtils.TeamBoardsNodeId
                : TreeViewUtils.OtherTeamBoardsNodeId;
        }

        return {
            id: TreeViewUtils.generateIdForTeam(team.teamId),
            parentId,
            ordinal: index,
            label: team.name,
            team,
            expanded,
            isSingleSelectable: false,
            isPersonBased: false,
        };
    }

    public static generateNodeForBoard(board: Board): ITreeViewPickerNode {
        const isPersonalBoard = !!board.personId;
        return {
            id: "board_" + board.boardId,
            parentId: isPersonalBoard
                ? TreeViewUtils.PersonalBoardsNodeId
                : TreeViewUtils.generateIdForTeam(board.teamId!),
            ordinal: board.ordinal,
            label: board.name + " (" + board.itemPrefix + ")",
            board,
            isSingleSelectable: true,
            isPersonBased: isPersonalBoard,
        };
    }

    public static generateIdForTeam(teamId: number) {
        return "team_" + teamId;
    }

    public static generateAllBoardsNode(): ITreeViewPickerNode {
        return {
            id: TreeViewUtils.AllBoardsId,
            parentId: "__none__", // Don't use null so it isn't lumped together as a root node.
            ordinal: 0,
            label: "All boards",
            visible: false,
            isSingleSelectable: false,
            isPersonBased: true,
        };
    }

    public static generatePersonalBoardsNode(): ITreeViewPickerNode {
        return {
            id: TreeViewUtils.PersonalBoardsNodeId,
            parentId: null,
            ordinal: 0,
            label: "Personal boards",
            expanded: true,
            isSingleSelectable: false,
            isPersonBased: true,
        };
    }

    public static generatePersonalBoardsPlaceholderNode(): ITreeViewPickerNode {
        return {
            id: TreeViewUtils.PersonalBoardsPlaceholderNodeId,
            parentId: TreeViewUtils.PersonalBoardsNodeId,
            ordinal: 0,
            label: "No personal boards",
            isSingleSelectable: false,
            isPersonBased: true,
            disabled: true,
        };
    }

    public static generateTeamBoardsNode(): ITreeViewPickerNode {
        return {
            id: TreeViewUtils.TeamBoardsNodeId,
            parentId: null,
            ordinal: 1,
            label: "My team boards",
            expanded: true,
            isSingleSelectable: false,
            isPersonBased: false,
        };
    }

    public static generateOtherTeamBoardsNode(): ITreeViewPickerNode {
        return {
            id: TreeViewUtils.OtherTeamBoardsNodeId,
            parentId: null,
            ordinal: 2,
            label: "Other team boards",
            expanded: false,
            isSingleSelectable: false,
            isPersonBased: false,
        };
    }

    public static simplifySelectedTreeViewNodes(selectedIds: string[], nodes: ITreeViewPickerNode[]) {
        const simplifier = new SelectedNodesSimplifier(selectedIds, nodes);
        return simplifier.simplify(TreeViewUtils.AllBoardsId);
    }
}

class SelectedNodesSimplifier {
    public constructor(private selectedIds: string[], private nodes: ITreeViewPickerNode[]) { }

    public simplify(allSelectedId: string): string[] {
        const rootNodes = this.nodes.filter((n) => !n.parentId);
        const simplifiedSelectedNodeIds = this.simplifyForNodes(rootNodes);

        if (rootNodes.every((n) => simplifiedSelectedNodeIds.includes(n.id))) {
            return [allSelectedId];
        } else {
            return simplifiedSelectedNodeIds;
        }
    }

    private simplifyForNodes(nodes: ITreeViewPickerNode[]): string[] {
        return nodes.reduce((curr, next) => {
            return curr.concat(this.simplifyForIdRecurse(next.id));
        }, [] as string[]);
    }

    private simplifyForIdRecurse(id: string): string[] {
        if (this.selectedIds.includes(id)) {
            return [id];
        }

        const childNodes = this.nodes
            .filter((n) => n.parentId === id);

        if (childNodes.length === 0) {
            return [];
        }

        const simplifiedNodeIds = this.simplifyForNodes(childNodes);

        if (childNodes.every((n) => simplifiedNodeIds.includes(n.id))) {
            return [id];
        } else {
            return simplifiedNodeIds;
        }
    }
}

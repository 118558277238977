import { HubConnectionState } from "@microsoft/signalr";
import { BaseConnectionState } from "./base-connection-state";
import { ConnectingState } from "./connecting-state";
import { ConnectionEvent } from "./connection-event.enum";
import { SignalRConnectionContext } from "./signalr-connection-context";

export class DisconnectedState extends BaseConnectionState {
    public readonly event = ConnectionEvent.Disconnected;

    public constructor(context: SignalRConnectionContext, disconnectReason?: string) {
        super(context);
        this.context.resetConnectionPromise(disconnectReason);

        if (this.connection.state !== HubConnectionState.Disconnected) {
            this.connection.stop();
        }
    }

    public connect(): void {
        this.context.state = new ConnectingState(this.context);
    }
}

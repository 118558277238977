import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptCollapsibleDashboardElementModule } from "@common/ux/collapsible-dashboard-element/collapsible-dashboard-element.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptAuthorisationModule } from "../../authorisation/authorisation.module";
import { StrategicInputComponent } from "./strategic-input/strategic-input.component";


@NgModule({
    declarations: [
        StrategicInputComponent,
    ],
    imports: [
        CommonModule,

        AdaptCollapsibleDashboardElementModule,
        AdaptButtonModule,
        AdaptHtmlEditorModule,
        AdaptLoadingSpinnerModule,
        AdaptTooltipModule,
        AdaptAuthorisationModule,
    ],
    exports: [
        StrategicInputComponent,
    ],
})
export class AdaptStrategicInputsSharedModule { }

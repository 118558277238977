<div class="content-container"
     [ngStyle]="showAllContent ? null : clippedContentStyle">
    <!-- This div has overflow: hidden to chop the content off but cannot get the actual unchopped height -->
    <div>
        <!-- Need this extra diff to group all the projected content to get the actual scrollHeight -->
        <ng-content></ng-content>
    </div>
</div>
<div class="see-more-container"
     [class.content-clipped]="!showAllContent"
     [class.gradient]="gradient"
     *ngIf="contentOverflows">
    <button class="btn btn-link"
            (click)="toggleShowMore()">
        <i class="fa"
           [ngClass]="{'fa-chevron-down': !showAllContent, 'fa-chevron-up': showAllContent}"></i>
        {{buttonText}}
    </button>
</div>

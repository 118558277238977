import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { LinkDisabledDirective } from "./link-disabled.directive";


@NgModule({
    declarations: [
        LinkDisabledDirective,
    ],
    exports: [
        LinkDisabledDirective,
    ],
    imports: [
        CommonModule,
        RouterModule,
    ],
})
export class AdaptLinkDisabledModule {}

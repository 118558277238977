import { EventEmitter, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, ActivationStart, Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, ResolveStart, Router, RouterStateSnapshot } from "@angular/router";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { EMPTY, merge, Observable, Subject } from "rxjs";
import { filter, map, share } from "rxjs/operators";
import { RouteService } from "./route.service";
import { RouteEvent } from "./route-event.enum";

export interface IRouteEvent {
    type: RouteEvent;
    event?: Event | BaseRoutedComponent;
}

export interface IRouteState {
    node$id?: number;
}

export enum GuardFailureType {
    AuthenticatedGuardFailed,
    UnauthenticatedGuardFailed,
    AccessGuardFailed,
    CoachGuardFailed,
    FeatureGuardFailed,
    OrganisationGuardFailed,
    ChangeGuardFailed,
    NoRoute,
}

export interface IGuardFailureEvent {
    route: ActivatedRouteSnapshot;
    state: RouterStateSnapshot;
    type: GuardFailureType;
}

@Injectable({
    providedIn: "root",
})
export class RouteEventsService {
    private _combinedEvents$: Observable<IRouteEvent>;
    private _routerEvents$: Observable<Event>;
    private _componentActivated$ = new EventEmitter<BaseRoutedComponent | undefined>();

    private _guardFailureEvents$ = new Subject<IGuardFailureEvent>();
    private _userNavigationError$ = new Subject<string>();

    constructor(
        router: Router,
        private routeService: RouteService,
    ) {
        this._routerEvents$ = router.events.pipe(
            share(),
        );

        this._combinedEvents$ = merge(
            this._routerEvents$.pipe(
                map(this.mapRouterEvent),
            ),
            this._componentActivated$.pipe(map((component) => ({
                type: RouteEvent.ComponentActivated,
                event: component,
            }))),
        ).pipe(
            share(),
        );

        this.routerEvents$.subscribe((e) => {
            if (e.constructor === ActivationStart) {
                this.routeService.setActivatingRouteSnapshot(e.snapshot);
            } else if (e.constructor === NavigationCancel || e.constructor === NavigationError || e.constructor === NavigationEnd) {
                this.routeService.setActivatingRouteSnapshot(undefined);
            }
        });
    }

    public get navigationStart$() {
        // consistent with old common route service where registerLocationChangeListener is listening to $locationChangeStart
        return this.getCustomObservableForEvent(RouteEvent.NavigationStart);
    }

    public get navigationEnd$() {
        return this.getCustomObservableForEvent(RouteEvent.NavigationEnd);
    }

    public get userNavigationError$() {
        return this._userNavigationError$.asObservable();
    }

    public get searchParameterChanged$() {
        return this.routeService.currentActivatedRoute?.queryParams ?? EMPTY;
    }

    public emitUserNavigationError(errorMessage: string) {
        this._userNavigationError$.next(errorMessage);
    }

    public getCustomObservableForEvent(event: RouteEvent) {
        return this.getObservableForEvent(event).pipe(
            filter(() => {
                if (event === RouteEvent.NavigationEnd &&
                    this.routeService.currentActivatedRouteSnapshot?.data?.reloadOnSearch === false &&
                    this.routeService.currentNavigationUrl && this.routeService.previousNavigationUrl) {
                    if (this.routeService.stripQueryParams(this.routeService.currentNavigationUrl) ===
                        this.routeService.stripQueryParams(this.routeService.previousNavigationUrl)) {
                        // same url - just different query param - not going to reload -> won't emit navStart event
                        return false;
                    }
                }

                return true;
            }),
            map(() => ({
                newUrl: this.routeService.currentNavigationUrl,
                oldUrl: this.routeService.previousNavigationUrl,
                newState: this.routeService.currentNavigationState,
                oldState: this.routeService.previousNavigationState,
            })),
        );
    }

    public get routerEvents$() {
        return this._routerEvents$;
    }

    public get componentActivated$() {
        return this._componentActivated$;
    }

    public get guardFailureEvents$() {
        return this._guardFailureEvents$.asObservable();
    }

    public getObservableForEvent(event: RouteEvent) {
        return this._combinedEvents$.pipe(
            filter((e) => e.type === event),
        );
    }

    public emitComponentActivated(activatedComponent?: BaseRoutedComponent) {
        this._componentActivated$.emit(activatedComponent);
    }

    public emitGuardFailureEvent(type: GuardFailureType, route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this._guardFailureEvents$.next({
            type,
            route,
            state,
        });
    }

    public emitGuardFailureType(type: GuardFailureType) {
        this.emitGuardFailureEvent(type, this.routeService.currentActivatedRouteSnapshot!, this.routeService.currentRouterState);
    }

    @Autobind
    private mapRouterEvent(event: Event) {
        let type = RouteEvent.Other;
        switch (event.constructor) {
            case NavigationStart:
                type = RouteEvent.NavigationStart;
                break;
            case NavigationEnd:
                type = RouteEvent.NavigationEnd;
                break;
            case NavigationCancel:
                type = RouteEvent.NavigationCancel;
                break;
            case NavigationError:
                type = RouteEvent.NavigationError;
                break;
            case ResolveStart:
                type = RouteEvent.ResolveStart;
                break;
            default:
                type = RouteEvent.Other;
                break;
        }

        return {
            type,
            event,
        };
    }
}

import { ClassProvider, InjectionToken, Type } from "@angular/core";
import { Observable } from "rxjs";
import { INavigationNode } from "./navigation-node.interface";

export const DYNAMIC_NODE_BUILDERS = new InjectionToken<IDynamicNodeBuilder[]>("DYNAMIC_NODE_TEMPLATES");

export function provideDynamicNodeBuilder(builderType: Type<IDynamicNodeBuilder>): ClassProvider {
    return {
        provide: DYNAMIC_NODE_BUILDERS,
        useClass: builderType,
        multi: true,
    };
}

export interface IDynamicNodeBuilder {
    activeNodeShouldBeRebuilt$: Observable<unknown>;
    buildDynamicNodes(): INavigationNode[];
}

import { DefaultBackFinishButtons, DefaultBackNextButtons, DefaultCancelProceedButtons, DefaultNextButtons, GuidedTour } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourUtils } from "@common/lib/guided-tour/guided-tour.utils";

let expandedAgendaDuringTour = false;

export const TeamActiveMeetingTour: GuidedTour = {
    steps: [{
        title: "Meeting Tour",
        text: "This is a tour of the features available to you when running a meeting inside the adapt platform.",
        buttons: DefaultCancelProceedButtons,
    }, {
        title: "Meeting title",
        text: "This is the title of the meeting.",
        waitForAndAttachToElementSelector: `[data-tour="active-meeting-title"]`,
        attachTo: {
            on: "bottom",
        },
        modalOverlayOpeningPadding: 3,
        buttons: DefaultBackNextButtons,
    }, {
        title: "Elapsed meeting time",
        text: "This shows elapsed time of the meeting.",
        waitForAndAttachToElementSelector: `[data-tour="active-meeting-time-elapsed"]`,
        attachTo: {
            on: "bottom",
        },
        buttons: DefaultBackNextButtons,
    }, {
        // this is shown when agenda item is expanded
        title: "Agenda items",
        text: "This shows you all the agenda items for the meeting.",
        waitForAndAttachToElementSelector: `[data-tour="meeting-agenda"]`,
        attachTo: {
            on: "top",
        },
        showOn: () => {
            // [data-tour="meeting-agenda"] makes sure we target the agenda in the page and not in the sidebar
            const actionItems = GuidedTourUtils.getFirstElementWithTextContent(`[data-tour="meeting-agenda"] [data-tour="edit-agenda-item"]`);
            const hasExpandedItem = GuidedTourUtils.isElementVisible(actionItems);
            expandedAgendaDuringTour = hasExpandedItem;
            return expandedAgendaDuringTour;
        },
        modalOverlayOpeningPadding: 3,
        canClickTarget: false,
        buttons: DefaultBackNextButtons,
    }, {
        // this is shown when no agenda item is expanded
        title: "Agenda items",
        text: "This shows you all the agenda items for the meeting. <b>Click on an agenda item now</b> to see more details.",
        waitForAndAttachToElementSelector: `[data-tour="meeting-agenda"]`,
        attachTo: {
            on: "top",
        },
        advanceOnAttachedElementEvent: "click",
        showOn: () => {
            // [data-tour="meeting-agenda"] makes sure we target the agenda in the page and not in the sidebar
            const actionItems = GuidedTourUtils.getFirstElementWithTextContent(`[data-tour="meeting-agenda"] [data-tour="edit-agenda-item"]`);
            const hasExpandedItem = GuidedTourUtils.isElementVisible(actionItems);
            expandedAgendaDuringTour = !hasExpandedItem;
            return expandedAgendaDuringTour;
        },
        modalOverlayOpeningPadding: 3,
        canClickTarget: true,
    }, {
        title: "Edit an agenda item",
        text: "Edit the name, duration & description of the agenda item.",
        waitForAndAttachToElementSelector: `[data-tour="active-meeting-page"] [data-tour="edit-agenda-item"]`,
        // if the agenda item was expanded as part of the last step, we need to wait a bit for the item to be fully ready
        beforeWaitForAndAttachToElementPromise: async () => {
            if (expandedAgendaDuringTour) {
                await new Promise((resolve) => setTimeout(resolve, 1500));
            }
        },
        attachTo: {
            on: "top",
        },
        canClickTarget: false,
        buttons: DefaultBackNextButtons,
    }, {
        title: "Add a decision",
        text: "Add a decision related to this agenda item.",
        waitForAndAttachToElementSelector: `[data-tour="active-meeting-page"] [data-tour="add-agenda-decision"]`,
        attachTo: {
            on: "top",
        },
        showOn: () => {
            const actionItems = GuidedTourUtils.getFirstElementWithTextContent(`[data-tour="active-meeting-page"] [data-tour="agenda-action-items"]`);
            return GuidedTourUtils.isElementVisible(actionItems);
        },
        modalOverlayOpeningPadding: 3,
        canClickTarget: false,
        buttons: DefaultBackNextButtons,
    }, {
        title: "Record a minute",
        text: "Record a minute related to this agenda item.",
        waitForAndAttachToElementSelector: `[data-tour="active-meeting-page"] [data-tour="add-agenda-minute"]`,
        attachTo: {
            on: "top",
        },
        showOn: () => {
            const actionItems = GuidedTourUtils.getFirstElementWithTextContent(`[data-tour="active-meeting-page"] [data-tour="agenda-action-items"]`);
            return GuidedTourUtils.isElementVisible(actionItems);
        },
        modalOverlayOpeningPadding: 3,
        canClickTarget: false,
        buttons: DefaultBackNextButtons,
    }, {
        title: "Add an action",
        text: "Create an action related to this agenda item.",
        waitForAndAttachToElementSelector: `[data-tour="active-meeting-page"] [data-tour="add-agenda-work-item"]`,
        attachTo: {
            on: "top",
        },
        showOn: () => {
            const actionItems = GuidedTourUtils.getFirstElementWithTextContent(`[data-tour="active-meeting-page"] [data-tour="agenda-action-items"]`);
            return GuidedTourUtils.isElementVisible(actionItems);
        },
        canClickTarget: false,
        modalOverlayOpeningPadding: 3,
        buttons: DefaultBackNextButtons,
    }, {
        title: "Complete the agenda item",
        text: "Once you have finished discussing the agenda item, click here to mark it as completed.",
        waitForAndAttachToElementSelector: `[data-tour="active-meeting-page"] [data-tour="toggle-agenda-item"]`,
        attachTo: {
            on: "right",
        },
        canClickTarget: false,
        buttons: DefaultBackNextButtons,
    }, {
        title: "Navigate to live meeting",
        text: "This icon will show when there is a live meeting running. You can always navigate to the meeting page by clicking here.",
        waitForAndAttachToElementSelector: `[id="sidebar-meeting"]`,
        attachTo: {
            on: "right",
        },
        modalOverlayOpeningPadding: 3,
        buttons: DefaultBackNextButtons,
    }, {
        title: "Ending a meeting",
        text: "Click here when you want to end the current meeting.",
        waitForAndAttachToElementSelector: `[data-tour="stop-meeting-button"]`,
        attachTo: {
            on: "top",
        },
        buttons: DefaultNextButtons,
        canClickTarget: false,
        modalOverlayOpeningPadding: 3,
    }, {
        title: "Meeting action toolbar",
        text: `<p>This is the meeting action toolbar.<br/><br/>More actions are shown in the '...' menu to the right including a way to:</p>
            <ul class="list-content">
              <li>Re-run this tour</li>
              <li>Move the meeting to the side bar</li>
              <li>Edit the agenda</li>
              <li>Send calendar invites and download to your calendar</li>
            </ul>`,
        waitForAndAttachToElementSelector: `[data-tour="active-meeting-toolbar-actions"]`,
        attachTo: {
            on: "bottom",
        },
        canClickTarget: false,
        buttons: DefaultBackFinishButtons,
    }],
};

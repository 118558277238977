import { Component, Input, OnChanges } from "@angular/core";
import { Label } from "@common/ADAPT.Common.Model/organisation/label";
import { IAdaptLinkObject } from "@common/route/route.service";
import { EMPTY, Observable } from "rxjs";
import { LabellingService } from "../labelling.service";

@Component({
    selector: "adapt-display-label",
    templateUrl: "./display-label.component.html",
    styleUrls: ["./display-label.component.scss"],
})
export class DisplayLabelComponent implements OnChanges {
    @Input() public label!: Label;

    public href$: Observable<IAdaptLinkObject | undefined> = EMPTY;

    constructor(private labellingService: LabellingService) { }

    //changed to on change because on init can not label but an have the link still enabled and will not be able to retrieve label in time.
    public ngOnChanges() {
        if (this.label) {
            this.href$ = this.labellingService.getLabelLink(this.label);
        }
    }
}

import { AfterContentInit, ContentChildren, Directive, ElementRef, QueryList, Renderer2 } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { delay } from "rxjs/operators";
import { BaseDirective } from "../base.directive";
import { BlockingClickDirective } from "./blocking-click.directive";

@Directive({
    selector: "[adaptBlockingButtonGroup]",
})
export class BlockingButtonGroupDirective extends BaseDirective implements AfterContentInit {
    @ContentChildren(BlockingClickDirective)
    public blockingButtonsContent?: QueryList<BlockingClickDirective>;

    private existingButtonDisabledStates = new Map<HTMLElement, boolean>();

    public constructor(
        elementRef: ElementRef<HTMLElement>,
        renderer: Renderer2,
    ) {
        super(elementRef, renderer);

    }

    public ngAfterContentInit() {
        if (!this.blockingButtonsContent) {
            throw new Error("ContentChildren should be defined in ngAfterContentInit");
        }

        // Changes won't fire the first time, so initialise with the existing values
        this.blockingButtonsContent.forEach(this.setupDirective);
        this.blockingButtonsContent.changes.subscribe((directives: QueryList<BlockingClickDirective>) => {
            directives.forEach(this.setupDirective);
        });
    }

    @Autobind
    private setupDirective(directive: BlockingClickDirective) {
        // We call inProgressChange.complete() in the directive so we don't have to handle
        // unsubscribing in here (otherwise we will leak memory)
        directive.inProgressChange.pipe(
            // Ensure the disabled state detected below is consistent with the actual bound value
            // Otherwise, it might get the value from the previous digest
            delay(0),
        ).subscribe((isInProgress: boolean) => {
            const otherButtons = jQuery(this.nativeElement)
                .find("button")
                .toArray()
                .filter((buttonElement) => buttonElement !== directive.nativeElement);

            otherButtons.forEach((buttonElement) => {
                let disableButton = isInProgress;

                // Store the existing state of the button to handle the case where a button is initially disabled,
                // the blocking button disables all other buttons, then it finishes, enabling them all.
                // If it the button is initially disabled then it should stay disabled after the blocking button completes
                if (disableButton) {
                    this.existingButtonDisabledStates.set(buttonElement, this.renderer.isDisabled(buttonElement));
                } else {
                    disableButton = !!this.existingButtonDisabledStates.get(buttonElement);
                    this.existingButtonDisabledStates.delete(buttonElement);
                }

                if (disableButton) {
                    this.renderer.disable(buttonElement);
                } else {
                    this.renderer.enable(buttonElement);
                }
            });
        });
    }
}

import { Component, Input } from "@angular/core";
import { OutstandingSurveyResponse } from "@common/ADAPT.Common.Model/organisation/outstanding-survey-response";
import { Survey, SurveyTypeLabel } from "@common/ADAPT.Common.Model/organisation/survey";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { BehaviorSubject, Observable } from "rxjs";
import { startWith, switchMap } from "rxjs/operators";
import { SurveyService } from "../survey.service";

@Component({
    selector: "adapt-display-ongoing-survey-personal-dashboard-element",
    templateUrl: "./display-ongoing-survey-personal-dashboard-element.component.html",
    styleUrls: ["../survey-global-styles.scss"],
})
export class DisplayOngoingSurveyPersonalDashboardElementComponent extends BaseComponent {
    @Input() public set person(value: Person) {
        this.person$.next(value);
    }
    public person$ = new BehaviorSubject<Person | undefined>(undefined);

    public outstandingSurveyResponses$: Observable<OutstandingSurveyResponse[]>;

    public constructor(
        surveyService: SurveyService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        this.outstandingSurveyResponses$ = rxjsBreezeService.entityTypeChanged(OutstandingSurveyResponse).pipe(
            startWith(undefined),
            switchMap(() => this.person$),
            emptyIfUndefinedOrNull(),
            switchMap((person) => surveyService.getOutstandingSurveyResponsesForPerson(true, person.personId)),
        );
    }

    public getSurveyHeader(survey?: Survey) {
        if (!survey) {
            return "Completed Survey";
        }

        let header = SurveyTypeLabel[survey.surveyType];
        if (survey.team) {
            header += ": " + survey.team.name;
        }

        return header;
    }
}

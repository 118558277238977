import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { InputGroup } from "./input-group";
import { InputsCanvas } from "./inputs-canvas";

export enum InputType {
    Strength = "Strength",
    Weakness = "Weakness",
    Trend = "Trend",
    Stop = "Stop",
    Start = "Start",
    KeepDoing = "KeepDoing",
    Win = "Win",
    Lose = "Lose",
    Compete = "Compete",
}

export class Input extends BaseEntity<Input> {
    public inputId!: number;
    public name!: string;
    public description?: string;
    public canvasId!: number;
    public type!: InputType;
    public inputGroupId?: number;

    public canvas!: InputsCanvas;
    public inputGroup?: InputGroup;
}

// entity without an endpoint as this will come through a nav property from canvas
export const InputBreezeModel = new BreezeModelBuilder("Input", Input)
    .build();

import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { Autobind } from "../lib/autobind.decorator/autobind.decorator";
import { IStorageUriParams, StorageService } from "./storage.service";

@Injectable({
    providedIn: "root",
})
@Autobind
export class StorageImageService {
    private _imageUpdated$ = new Subject<string>();

    public constructor(private storageFactory: StorageService) {}

    /** Get a hot observable which will emit with the guid each time an image is updated */
    public get imageUpdated$() {
        return this._imageUpdated$.asObservable();
    }

    public updateImage(imageIdentifier: string) {
        this._imageUpdated$.next(imageIdentifier);
    }

    public getRetrieveImageUri(imageIdentifier: string, ownerId?: number) {
        return this.storageFactory.retrieveUri({
            itemId: imageIdentifier,
            ownerId,
        } as IStorageUriParams);
    }

    public getStoreImageUri(ownerIdentifier: number | undefined, maxWidth: number, maxHeight: number) {
        return this.storageFactory.storeImageUri({
            ownerId: ownerIdentifier,
            maxWidth,
            maxHeight,
        } as IStorageUriParams);
    }

    public getReplaceImageUri(imageIdentifier: string, ownerIdentifier: number | undefined, maxWidth: number, maxHeight: number) {
        return this.storageFactory.replaceImageUri({
            ownerId: ownerIdentifier,
            itemId: imageIdentifier,
            maxWidth,
            maxHeight,
        } as IStorageUriParams);
    }
}

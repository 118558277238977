import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { assign, createMachine } from "xstate";
import { IOrganisationSwitchParams } from "./organisation-service.interface";

export enum OrganisationState {
    InitialState = "InitialState",
    OrganisationEntityUpdatedState = "OrganisationEntityUpdatedState",
    OrganisationReadyState = "OrganisationReadyState",
    SwitchingState = "SwitchingState",
    AwaitingUserInitialisationState = "AwaitingUserInitialisationState",
}

export enum OrganisationEvent {
    SetOrganisationEntity = "SetOrganisationEntity",
    UpdateOrganisationEntity = "UpdateOrganisationEntity",
    WaitForPerson = "WaitForPerson",
    Logout = "Logout",
    Initialise = "Initialise",
    DoPostInitialisation = "DoPostInitialisation",
    SwitchOrganisation = "SwitchOrganisation",
    FallbackSwitch = "FallbackSwitch",
}

export enum OrganisationActions {
    InitialiseWithOrganisationEntity = "InitialiseWithOrganisationEntity",
    NotifyOrganisationEntityUpdatedEvent = "NotifyOrganisationEntityUpdatedEvent",
    NotifyOrganisationChangingEvent = "NotifyOrganisationChangingEvent",
    NotifyOrganisationChangedEvent = "NotifyOrganisationChangedEvent",
    PromiseToPerformOrganisationSwitch = "PromiseToPerformOrganisationSwitch",
    LogEntry = "LogEntry",
}

export type OrganisationEvents =
    | { type: OrganisationEvent.SetOrganisationEntity, organisation?: Organisation }
    | { type: OrganisationEvent.UpdateOrganisationEntity }
    | { type: OrganisationEvent.WaitForPerson }
    | { type: OrganisationEvent.Logout }
    | { type: OrganisationEvent.Initialise }
    | { type: OrganisationEvent.DoPostInitialisation }
    | { type: OrganisationEvent.SwitchOrganisation } & IOrganisationSwitchParams
    | { type: OrganisationEvent.FallbackSwitch };

export type OrganisationEventActionParameters<T extends OrganisationEvents["type"]> = Partial<Extract<OrganisationEvents, { type: T }>>;
export type OrganisationEventActions = {
    [event in OrganisationEvents["type"]as Uncapitalize<event>]: (data?: OrganisationEventActionParameters<event>) => any;
}

export interface IOrganisationContext {
    error?: any;
    organisation?: Organisation;
}

export interface IOrganisationSchema {
    context: IOrganisationContext;
    states: Record<keyof typeof OrganisationState, {}>;
}

const machineDefaultContext: IOrganisationContext = {
    organisation: undefined,
    error: undefined,
};

// Visualise at: https://stately.ai/viz/9fc5e4f3-6c20-4b83-a465-a6ad9feeb2a0
export const OrganisationMachine = createMachine<IOrganisationContext, OrganisationEvents>({
    id: "OrganisationState",
    initial: OrganisationState.InitialState,
    context: { ...machineDefaultContext },
    on: {
        [OrganisationEvent.SetOrganisationEntity]: {
            actions: assign((_context, event) => ({
                organisation: event.organisation,
                error: undefined,
            })),
        },
        [OrganisationEvent.Logout]: {
            actions: [
                assign({ ...machineDefaultContext }),
                OrganisationActions.NotifyOrganisationEntityUpdatedEvent,
            ],
            target: OrganisationState.AwaitingUserInitialisationState,
        },
    },
    states: {
        [OrganisationState.InitialState]: {
            entry: [
                assign({ ...machineDefaultContext }),
                OrganisationActions.LogEntry,
                OrganisationActions.InitialiseWithOrganisationEntity,
            ],
            on: {
                [OrganisationEvent.UpdateOrganisationEntity]: {
                    target: OrganisationState.OrganisationEntityUpdatedState,
                },
                [OrganisationEvent.WaitForPerson]: {
                    target: OrganisationState.AwaitingUserInitialisationState,
                },
                [OrganisationEvent.Initialise]: {
                    target: OrganisationState.InitialState,
                    // will not call initialiseWithOrganisationEntity again
                    internal: true,
                },
            },
        },
        [OrganisationState.OrganisationEntityUpdatedState]: {
            entry: [
                OrganisationActions.LogEntry,
                // - this will be picked up by features service, which will call userService.promiseToNotifyUserChanged upon completion
                // - auth service will pick up user changed notification and notify permission completed
                OrganisationActions.NotifyOrganisationEntityUpdatedEvent,
                OrganisationActions.InitialiseWithOrganisationEntity,
            ],
            on: {
                [OrganisationEvent.DoPostInitialisation]: {
                    target: OrganisationState.OrganisationReadyState,
                },
                [OrganisationEvent.SwitchOrganisation]: {
                    target: OrganisationState.SwitchingState,
                },
            },
        },
        [OrganisationState.OrganisationReadyState]: {
            entry: [
                OrganisationActions.LogEntry,
                OrganisationActions.NotifyOrganisationChangedEvent,
            ],
            on: {
                [OrganisationEvent.DoPostInitialisation]: {
                    target: OrganisationState.OrganisationReadyState,
                },
                [OrganisationEvent.SwitchOrganisation]: {
                    target: OrganisationState.SwitchingState,
                },
            },
        },
        [OrganisationState.SwitchingState]: {
            entry: [OrganisationActions.LogEntry],
            invoke: {
                id: OrganisationActions.PromiseToPerformOrganisationSwitch,
                src: OrganisationActions.PromiseToPerformOrganisationSwitch,
                onDone: {
                    actions: OrganisationActions.NotifyOrganisationChangingEvent,
                    target: OrganisationState.AwaitingUserInitialisationState,
                },
                onError: {
                    actions: assign((_context, event) => ({ error: event.data })),
                    target: OrganisationState.OrganisationReadyState,
                },
            },
        },
        [OrganisationState.AwaitingUserInitialisationState]: {
            entry: [OrganisationActions.LogEntry],
            on: {
                [OrganisationEvent.SwitchOrganisation]: {
                    target: OrganisationState.SwitchingState,
                },
                [OrganisationEvent.Initialise]: {
                    target: OrganisationState.InitialState,
                },
                [OrganisationEvent.FallbackSwitch]: {
                    target: OrganisationState.OrganisationReadyState,
                },
            },
        },
    },
});

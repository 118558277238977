<dx-chart class="me-2"
          [ngClass]="{'compact': displayCompact}"
          [dataSource]="chartData"
          [rotated]="true"
          [barGroupPadding]="0.5"
          [customizePoint]="customizeChartPoint"
          (onDone)="updateChartDimensionAndSubscribeSizeChange($event)">
    <dxo-animation [enabled]="false"></dxo-animation>
    <dxo-common-series-settings argumentField="responseGroup"
                                valueField="value"
                                [barPadding]="0.1"
                                type="bar">
        <dxo-label [visible]="true"
                   position="inside"
                   [horizontalOffset]="-2000"
                   backgroundColor="transparent"
                   [customizeText]="customizeSeriesLabel"></dxo-label>
    </dxo-common-series-settings>
    <dxo-argument-axis [aggregateByCategory]="true"
                       [inverted]="true">
        <dxo-label [customizeText]="customizeArgumentLabel"></dxo-label>
    </dxo-argument-axis>
    <dxi-value-axis [valueMarginsEnabled]="false"
                    [showZero]="true"
                    [visualRange]="[0, 100]"
                    visualRangeUpdateMode="keep">
        <dxo-grid [visible]="true"></dxo-grid>
        <dxo-label [customizeText]="pointToPercentage"></dxo-label>
    </dxi-value-axis>

    <dxo-series-template nameField="valueType"></dxo-series-template>
    <dxo-legend [visible]="false"></dxo-legend>
</dx-chart>

// eslint-disable-next-line max-classes-per-file
import { Injectable, Injector } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { IdentityService } from "@common/identity/identity.service";
import { AbstractGuard } from "@common/route/abstract.guard";
import { GuardFailureType } from "@common/route/route-events.service";
import { from, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AuthorisationService } from "../authorisation/authorisation.service";

@Injectable({
    providedIn: "root",
})
export class AccessGuardService {
    public static forAccess(access: string) {
        @Injectable({
            providedIn: "root",
        })
        class AccessGuard extends AbstractGuard {
            public static readonly Id = "AccessGuard";
            constructor(
                private identityService: IdentityService,
                private authorisationService: AuthorisationService,
                injector: Injector,
            ) {
                super(injector);
            }

            public canActivateWithBypassEvent(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, bypassEvent: boolean) {
                const result = from(this.identityService.promiseToCheckIsLoggedIn()).pipe(
                    switchMap((isLoggedIn) => isLoggedIn ? this.authorisationService.promiseToGetHasAccess(access) : of(false)),
                );
                return this.wrapActivationResult(result, GuardFailureType.AccessGuardFailed, route, state, bypassEvent);
            }
        }

        return AccessGuard;
    }
}

/* eslint-disable max-classes-per-file */
import { Type } from "@angular/core";
import { Params, Route } from "@angular/router";
import { AngularGlobals } from "@common/lib/angular-globals/angular-globals";
import { AdaptRouteBuilder } from "@common/route/adapt-route-builder";
import { IAdaptLinkObject, RouteService } from "@common/route/route.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

export interface IAdaptRouteActions<TNamedParams extends Params, TInputParam = TNamedParams> {
    /**
     * Use this function to return a complete url represented as a string.
     * Do not use this anywhere you need to use the angular router's related components (like [routerLink]).
     */
    getRoute(namedParams?: TInputParam, searchParams?: Params): Observable<string>;

    /**
     * Use this function to return a route object with the path and query params separated.
     * This is the function to call when using the angular router's components (like [routerLink] and [queryParams]).
     */
    getRouteObject(namedParams?: TInputParam, searchParams?: Params): Observable<IAdaptLinkObject>;

    gotoRoute(namedParams?: TInputParam, searchParams?: Params, redirect?: boolean, replaceUrlIfSamePath?: boolean): Observable<void>;
}

export interface IAdaptRoute<TNamedParams extends Params, TInputParam = TNamedParams> extends IAdaptRouteActions<TNamedParams, TInputParam> {
    id: string;
    routes: Route[];
}

export class PageRouteBuilder<TNamedParams extends Params, TInputParam = TNamedParams> {
    private static routeUniquenessCounter = 1;

    protected redirects: string[] = [];
    private componentSelector?: string;
    private inputParamMapper: (params: TInputParam) => TNamedParams;

    protected routeBuilder: AdaptRouteBuilder;

    public constructor(
    ) {
        this.inputParamMapper = (i) => i as unknown as TNamedParams;
        this.routeBuilder = new AdaptRouteBuilder();
    }

    public usingNgComponent(componentSelector: string, component: Type<any>) {
        this.componentSelector = componentSelector;
        this.routeBuilder.setComponent(component);

        return this;
    }

    public atUrl(url: string) {
        this.routeBuilder.setPath(url);
        return this;
    }

    public withTitle(title: string) {
        this.routeBuilder.setTitle(title);
        return this;
    }

    public reloadOnSearch(reload: boolean) {
        this.routeBuilder.reloadOnSearch(reload);
        return this;
    }

    public withHelp(help: string) {
        this.routeBuilder.setHelp(help);
        return this;
    }

    public requiresLogin(loginRequired = true) {
        this.routeBuilder.requiresAuthentication(loginRequired);
        return this;
    }

    public requiresAnonymous(anonymousRequired = true) {
        this.routeBuilder.requiresUnauthentication(anonymousRequired);
        return this;
    }

    public addUrlParameterGetter(paramName: string, getter: Observable<string | undefined>) {
        this.routeBuilder.addUrlParamGetter(paramName, getter);
        return this;
    }

    public mapInputParam(m: (params: TInputParam) => TNamedParams) {
        this.inputParamMapper = m;
        return this;
    }

    public redirectToThisRouteFromUrl(url: string) {
        this.routeBuilder.redirectFromUrl(url);
        return this;
    }

    public withSearchKeyword(keyword: string) {
        this.routeBuilder.withSearchKeyword(keyword);
        return this;
    }

    public enableNonNavigationNodeSearch() {
        this.routeBuilder.enableNonNavigationNodeSearch();
        return this;
    }

    public build(): IAdaptRoute<TNamedParams, TInputParam> {
        if (!this.routeBuilder.url || !this.componentSelector || !this.routeBuilder.component) {
            throw new Error("Url and component must be specified");
        }

        this.routeBuilder.setId(`${this.componentSelector}_` + PageRouteBuilder.routeUniquenessCounter++);
        const route = this.routeBuilder.build();

        return new this.AdaptRoute(this, [route, ...this.routeBuilder.redirectedRoutes]);
    }

    protected addPrecedingSlashIfRequired(partialUrl: string) {
        if (partialUrl.length && partialUrl[0] !== "/") {
            return "/" + partialUrl;
        }

        return partialUrl;
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    private AdaptRoute = class implements IAdaptRoute<TNamedParams, TInputParam> {
        private routeId: string;

        public constructor(
            private routeBuilder: PageRouteBuilder<TNamedParams, TInputParam>,
            public routes: Route[],
        ) {
            this.routeId = this.routeBuilder.routeBuilder.Id;
        }

        public getRoute(inputParams?: TInputParam, searchParams?: Params): Observable<string> {
            const routeService = this.routeService;
            const namedParams = inputParams && this.routeBuilder.inputParamMapper(inputParams);
            return routeService.getRoute(this, namedParams, searchParams);
        }

        public getRouteObject(inputParams?: TInputParam, searchParams?: Params): Observable<IAdaptLinkObject> {
            const routeService = this.routeService;
            const namedParams = inputParams && this.routeBuilder.inputParamMapper(inputParams);
            return routeService.getRouteObject(this, namedParams, searchParams);
        }

        public gotoRoute(inputParams?: TInputParam, searchParams?: Params, redirect?: boolean, replaceUrlIfSamePath?: boolean): Observable<void> {
            const routeService = this.routeService;
            const namedParams = inputParams && this.routeBuilder.inputParamMapper(inputParams);
            return routeService.gotoRoute(this, namedParams, searchParams, redirect, replaceUrlIfSamePath).pipe(
                map(() => void 0),
            );
        }

        public get id() {
            return this.routeId;
        }

        private get routeService() {
            const routeService = AngularGlobals.injector.get(RouteService);
            if (!routeService) {
                throw new Error("Angular RouteService must have initialised before getting this route");
            }

            return routeService;
        }
    };
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { DxButtonGroupModule, DxTextAreaModule, DxValidationGroupModule, DxValidatorModule } from "devextreme-angular";
import { EditBehaviourComponent } from "./edit-behaviour/edit-behaviour.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,

        AdaptHtmlEditorModule,
        AdaptTooltipModule,

        DxButtonGroupModule,
        DxTextAreaModule,
        DxValidationGroupModule,
        DxValidatorModule,
    ],
    declarations: [
        EditBehaviourComponent,
    ],
    exports: [
        EditBehaviourComponent,
    ],
})
export class AdaptValuesConstitutionCommonModule {}

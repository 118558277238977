import { Component, Inject, Injector } from "@angular/core";
import { UserType, UserTypeExtensions } from "@common/ADAPT.Common.Model/embed/user-type";
import { Connection, ConnectionBreezeModel, RoleInOrganisation } from "@common/ADAPT.Common.Model/organisation/connection";
import { ConnectionType, ConnectionTypeLabel } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { PositionBreezeModel } from "@common/ADAPT.Common.Model/organisation/position";
import { RoleConnection, RoleConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { DateFormats } from "@common/ux/date-formats";
import { lastValueFrom, Subject } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { UserManagementService } from "../user-management.service";

@Component({
    selector: "adapt-convert-connection-type-dialog",
    templateUrl: "./convert-connection-type-dialog.component.html",
    styleUrls: ["./convert-connection-type-dialog.component.scss"],
})
export class ConvertConnectionTypeDialogComponent extends BaseDialogWithDiscardConfirmationComponent<Connection> {
    public readonly dialogName = "ConvertConnectionType";
    public readonly ConnectionTypeLabel = ConnectionTypeLabel;
    public readonly UserTypeExtensions = UserTypeExtensions;
    public readonly UserType = UserType;
    public readonly DateFormat = DateFormats.globalize.short;
    public readonly ConnectionType = ConnectionType;
    public readonly Now = new Date();

    public title = "";
    public destConnectionType: ConnectionType;
    public confirmed?: boolean | null = false; // making this optional as dx-select-box value being optional - won't be able to use [()] without it
    public changeoverDate = this.Now;
    public existingTeamCount = 0;
    public entitiesToConfirm: IBreezeEntity[] = [];
    public keepInTeams?: boolean | null = false;
    public triggerUpdate = new Subject<void>();

    private activeRoleConnections: RoleConnection[];

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public connection: Connection,
        injector: Injector,
        private userManagementService: UserManagementService,
    ) {
        super(injector);

        if (connection.isEmployeeConnection()) {
            this.title = "Convert employee account to stakeholder";
            this.destConnectionType = ConnectionType.Stakeholder;
        } else if (connection.isStakeholderConnection()) {
            this.title = "Convert stakeholder account to employee";
            this.destConnectionType = ConnectionType.Employee;
        } else {
            throw new Error("Only expect this dialog to be used to convert between employee and stakeholder");
        }

        this.activeRoleConnections = this.connection.roleConnections.filter((i) => i.isActive());
        this.existingTeamCount = connection.roleConnections.filter((i) => !!i.teamId && i.isActive()).length;

        // using triggerUpdate subject here to prevent overlapping update
        this.triggerUpdate.pipe(
            tap(() => this.isInitialised = false),
            // switchMap: new emit, previous incomplete switchMap will be switched out, no longer subscribed -> replaced by new one
            switchMap(() => this.updateChangedEntities()),
            tap(() => this.isInitialised = true),
            this.takeUntilDestroyed(), // avoid error from updating while destroying the dialog through cancel
        ).subscribe();

        this.isInitialised = true;
        // for the case where it is converted to employee, the conversion can start here - redo after options changed
        this.triggerUpdate.next();
    }

    public get isUserTypeUpdateRequired() {
        return this.destConnectionType === ConnectionType.Employee && this.connection.userType === UserType.None;
    }

    public onChangeoverDateChanged(date: Date) {
        this.changeoverDate = date;
        this.triggerUpdate.next();
    }

    private async updateChangedEntities() {

        await lastValueFrom(this.commonDataService.rejectChanges(this.entitiesToConfirm));

        // destination connection
        const newConnection = await lastValueFrom(this.commonDataService.create(ConnectionBreezeModel, {
            startDate: this.changeoverDate,
            hasAccess: this.connection.hasAccess,
            organisationId: this.connection.organisationId,
            connectionType: this.destConnectionType,
            userType: this.isUserTypeUpdateRequired
                ? UserType.Viewer
                : this.connection.userType,
            personId: this.connection.personId,
            roleInOrganisation: RoleInOrganisation.Employee,
        }));
        this.autoResolveData = newConnection;

        // remove access from old connection
        this.connection.hasAccess = false;

        // create new role connections to match old role connections (excluding CL / Teams as appropriate)
        const newRoleConnections: RoleConnection[] = [];
        for (const roleConnection of this.activeRoleConnections) {

            if (roleConnection.endDate) {
                continue;
            }

            if (!this.keepInTeams && roleConnection.teamId) {
                continue;
            }

            const newRoleConnection = await lastValueFrom(this.commonDataService.create(RoleConnectionBreezeModel, {
                connection: newConnection,
                roleId: roleConnection.roleId,
                teamId: roleConnection.teamId,
                startDate: this.changeoverDate,
            }));
            newRoleConnections.push(newRoleConnection);
        }

        // end role connections
        // - this will take care of all related team, CL cohort and position on the server side
        this.connection.endDate = this.changeoverDate;

        // add the new access level if required
        if (this.isUserTypeUpdateRequired) {
            const accessLevel = await this.userManagementService.promiseToGetDefaultAccessLevel(newConnection.userType);

            const newRoleConnection = await lastValueFrom(this.commonDataService.create(RoleConnectionBreezeModel, {
                connection: newConnection,
                role: accessLevel,
                startDate: this.changeoverDate,
            }));
            newRoleConnections.push(newRoleConnection);
        }

        this.entitiesToConfirm = [
            this.connection,
            newConnection,
            ...newRoleConnections,
        ];

        if (newConnection.connectionType === ConnectionType.Employee) {
            // only create position for employee connection type consistent with add employee on server
            const position = await lastValueFrom(this.commonDataService.create(PositionBreezeModel, {
                name: ConnectionTypeLabel.singular(ConnectionType.Employee),
                startDate: this.changeoverDate,
                connection: newConnection,
            }));

            this.entitiesToConfirm.push(position);
        }

        // require to confirm again after updating options
        this.confirmed = false;
    }
}

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { IdentityService } from "@common/identity/identity.service";
import { loginPageRoute } from "@common/identity/ux/login-page/login-page.route";
import { IGuard } from "@common/route/guard.interface";
import { GuardFailureType, RouteEventsService } from "@common/route/route-events.service";
import { lastValueFrom } from "rxjs";
import { OrganisationService } from "../organisation/organisation.service";

@Injectable({
    providedIn: "root",
})
export class BaseGuard implements IGuard {
    public static readonly Id = "BaseGuard";

    constructor(
        private router: Router,
        private organisationService: OrganisationService,
        private identityService: IdentityService,
        private routeEventsService: RouteEventsService,
    ) { }

    public async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isLoggedIn = await this.identityService.promiseToCheckIsLoggedIn();

        if (isLoggedIn) {
            const redirectPath = await this.getRedirectPath(state.url);

            if (redirectPath !== undefined) {
                return this.router.parseUrl(redirectPath);
            } else {
                this.routeEventsService.emitGuardFailureEvent(GuardFailureType.NoRoute, route, state);
                return false;
            }
        } else {
            const url = await lastValueFrom(loginPageRoute.getRoute());
            return this.router.parseUrl(url);
        }
    }

    private async getRedirectPath(path: string) {
        const orgUrlIdentifierRegex = /^\/(\w+)\/?$/;
        const urlRegexResults = orgUrlIdentifierRegex.exec(path);

        if (!urlRegexResults) {
            return undefined;
        }

        const urlIdentifier = urlRegexResults[1];
        if (!urlIdentifier) {
            return undefined;
        }

        // if it gets into here, then we've gotten into a state where the route is not quite valid, so we need to set our org factory to
        // force the org to be the UrlIdentifier that was set in the URL
        // (this prevents the org factory from then requesting the users default organisation)
        this.organisationService.setOrganisationUrlIdentifierOverride(urlIdentifier);

        const organisation: Organisation | undefined = await this.organisationService.promiseToGetOrganisationByUrlIdentifier(urlIdentifier);
        if (organisation) {
            return `/${organisation.urlIdentifier}/dashboard`;
        }

        return undefined;
    }
}

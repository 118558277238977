import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Item } from "./item";
import { Objective } from "./objective";

export class ObjectiveItemLink extends BaseEntity<ObjectiveItemLink> {
    public objectiveItemLinkId!: number;
    public objectiveId!: number;
    public itemId!: number;

    public objective!: Objective;
    public item!: Item;
}

export const ObjectiveItemLinkBreezeModel = new BreezeModelBuilder("ObjectiveItemLink", ObjectiveItemLink)
    .hasSource()
    .isOrganisationEntity()
    .alwaysFetchingNavigationProperty("objective")
    .alwaysFetchingNavigationProperty("item")
    .build();

import { AfterViewInit, Component, ElementRef, EventEmitter, Inject, InjectionToken, Injector, Input, OnChanges, OnInit, Optional, Output, Provider, Renderer2, SimpleChanges } from "@angular/core";
import { UserType, UserTypeExtensions } from "@common/ADAPT.Common.Model/embed/user-type";
import { Connection, RoleInOrganisation, RoleInOrganisationLabel } from "@common/ADAPT.Common.Model/organisation/connection";
import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { DefaultRoleLabel, Role } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { RouteService } from "@common/route/route.service";
import { ShellUiService } from "@common/shell/shell-ui.service";
import { UserService } from "@common/user/user.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IAdaptMenuItem, MenuComponent } from "@common/ux/menu/menu.component";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AuthorisationNotificationService } from "@org-common/lib/authorisation/authorisation-notification.service";
import { ConfigurationAuthService } from "@org-common/lib/configuration/configuration-auth.service";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { MenuTabId } from "@org-common/lib/shell/menu-tab-content/menu-tab-id";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { EndConnectionDialogComponent } from "@org-common/lib/user-management/end-connection-dialog/end-connection-dialog.component";
import { lastValueFrom } from "rxjs";
import { debounceTime, filter, switchMap } from "rxjs/operators";
import { ActivationUrlDialogComponent } from "../activation-url-dialog/activation-url-dialog.component";
import { ConvertConnectionTypeDialogComponent } from "../convert-connection-type-dialog/convert-connection-type-dialog.component";
import { ConvertUserTypeDialogComponent, IConvertUserTypeDialogData } from "../convert-user-type-dialog/convert-user-type-dialog.component";
import { ReactivateAccountDialogComponent } from "../reactivate-account-dialog/reactivate-account-dialog.component";
import { UserManagementService } from "../user-management.service";

export const USER_ROLE_ACTIONS = new InjectionToken("USER_ROLE_ACTIONS");

type IUserRoleAction = (injector: Injector, connection: Connection) => Promise<void>;

export interface IUserRoleActions {
    viewAccess?: IUserRoleAction;
    configureAccess?: IUserRoleAction;
    configureRoles?: IUserRoleAction;
}

export function provideUserRoleActions(actionCallbacks: IUserRoleActions): Provider {
    return {
        provide: USER_ROLE_ACTIONS,
        useValue: actionCallbacks,
        multi: false,
    };
}

@Component({
    selector: "adapt-user-role-actions",
    template: `
        <adapt-menu [items]="actionMenu"
                    data-test="user-role-actions"></adapt-menu>`,
})
export class UserRoleActionsComponent extends BaseComponent implements OnChanges, OnInit, AfterViewInit {
    @Input() public connection!: Connection; // from component bindings
    @Output() public connectionChanged = new EventEmitter<Connection>();
    @Output() public refreshRequired = new EventEmitter<void>();

    public actionMenu: IAdaptMenuItem[] = [{
        icon: MenuComponent.SmallRootMenu.icon,
        items: [],
    }];

    private readonly viewAccessItem: IAdaptMenuItem;
    private readonly configureAccessItem: IAdaptMenuItem;
    private readonly configureRolesItem: IAdaptMenuItem;
    private readonly disallowLogin: IAdaptMenuItem;
    private readonly allowLogin: IAdaptMenuItem;
    private readonly deactivateAccount: IAdaptMenuItem;
    private readonly reactivateAccount: IAdaptMenuItem;
    private readonly sendInvitationEmailItem: IAdaptMenuItem;
    private readonly convertEmployeeAccount: IAdaptMenuItem;
    private readonly convertStakeholderAccount: IAdaptMenuItem;
    private readonly generateActivationUrl: IAdaptMenuItem;

    private readonly changeRoleInOrganisationMenu: IAdaptMenuItem;
    private readonly convertAccessMenu: IAdaptMenuItem;
    private readonly convertToLeaderUser: IAdaptMenuItem;
    private readonly convertToCollaboratorUser: IAdaptMenuItem;
    private readonly convertToViewerUser: IAdaptMenuItem;
    private readonly convertToNoneUser: IAdaptMenuItem;

    private readonly changeRoleToOwnerLeader: IAdaptMenuItem;
    private readonly changeRoleToLeader: IAdaptMenuItem;
    private readonly changeRoleToOther: IAdaptMenuItem;

    public constructor(
        private injector: Injector,
        private directorySharedService: DirectorySharedService,
        private userManagementService: UserManagementService,
        private commonDialogService: AdaptCommonDialogService,
        private userService: UserService,
        private renderer: Renderer2,
        private rxjsBreezeService: RxjsBreezeService,
        private teamsService: CommonTeamsService,
        private authService: AuthorisationService,
        private routeService: RouteService,
        private authNotificationService: AuthorisationNotificationService,
        elementRef: ElementRef,
        @Optional() @Inject(USER_ROLE_ACTIONS) public userRoleActions?: IUserRoleActions,
    ) {
        super(elementRef);

        this.configureRolesItem = {
            text: "Configure roles",
            icon: "fal fa-fw fa-user-tag",
            onClick: this.configureRolesAction,
        };
        this.viewAccessItem = {
            text: "View access level summary",
            icon: "fal fa-fw fa-key-skeleton",
            onClick: this.viewAccessAction,
            separator: true,
        };
        this.configureAccessItem = {
            text: "Configure access levels",
            icon: "fal fa-fw fa-keyboard",
            onClick: this.configureAccessAction,
        };
        this.sendInvitationEmailItem = {
            text: "Send invitation email",
            icon: "fal fa-fw fa-envelope",
            onClick: this.sendWelcomeEmailAction,
            separator: true,
        };
        this.disallowLogin = {
            text: "Disallow login",
            icon: "fal fa-fw fa-user-times",
            onClick: this.disallowLoginAction,
            separator: true,
        };
        this.allowLogin = {
            text: "Allow login",
            icon: "fal fa-fw fa-user-check",
            onClick: this.allowLoginAction,
        };
        this.generateActivationUrl = {
            text: "Get activation URL",
            icon: "fal fa-fw fa-link",
            onClick: this.generateActivationUrlAction,
        };
        this.deactivateAccount = {
            text: "Deactivate account",
            icon: "fal fa-fw fa-times-circle",
            onClick: this.deactivateAccountAction,
        };
        this.reactivateAccount = {
            text: "Reactivate account",
            icon: "fal fa-fw fa-power-off",
            onClick: this.reactivateAccountAction,
        };

        this.convertEmployeeAccount = {
            text: "Convert employee account to stakeholder",
            onClick: this.convertEmployeeStakeholderAction,
            separator: true,
        };
        this.convertStakeholderAccount = {
            text: "Convert stakeholder account to employee",
            onClick: this.convertEmployeeStakeholderAction,
            separator: true,
        };

        this.convertToLeaderUser = {
            text: `Change user type to ${UserTypeExtensions.singularLabel(UserType.Leader).toLowerCase()}`,
            onClick: () => this.convertAccess(UserType.Leader),
        };

        this.convertToCollaboratorUser = {
            text: `Change user type to ${UserTypeExtensions.singularLabel(UserType.Collaborator).toLowerCase()}`,
            onClick: () => this.convertAccess(UserType.Collaborator),
        };

        this.convertToViewerUser = {
            text: `Change user type to ${UserTypeExtensions.singularLabel(UserType.Viewer).toLowerCase()}`,
            onClick: () => this.convertAccess(UserType.Viewer),
        };

        this.convertToNoneUser = {
            text: `Change user type to ${UserTypeExtensions.singularLabel(UserType.None).toLowerCase()}`,
            onClick: () => this.convertAccess(UserType.None),
        };

        this.convertAccessMenu = {
            text: "Change user type",
            items: [
                this.convertToLeaderUser,
                this.convertToCollaboratorUser,
                this.convertToViewerUser,
                this.convertToNoneUser,
            ],
        };

        this.changeRoleToOwnerLeader = {
            text: `Change role to ${RoleInOrganisationLabel[RoleInOrganisation.OwnerLeader]}`,
            onClick: () => this.changeRoleInOrganisation(RoleInOrganisation.OwnerLeader),
        };
        this.changeRoleToLeader = {
            text: `Change role to ${RoleInOrganisationLabel[RoleInOrganisation.Leader]}`,
            onClick: () => this.changeRoleInOrganisation(RoleInOrganisation.Leader),
        };
        this.changeRoleToOther = {
            text: `Change role to ${RoleInOrganisationLabel[RoleInOrganisation.Other]}`,
            onClick: () => this.changeRoleInOrganisation(RoleInOrganisation.Other),
        };

        this.changeRoleInOrganisationMenu = {
            separatorTop: true,
            text: "Change role in organisation",
            icon: "fal fa-fw fa-user-gear",
            items: [
                this.changeRoleToOwnerLeader,
                this.changeRoleToLeader,
                this.changeRoleToOther,
            ],
        };

        this.actionMenu[0].items = [
            this.configureRolesItem,
            this.viewAccessItem,
            this.configureAccessItem,
            this.convertAccessMenu,
            this.sendInvitationEmailItem,
            this.generateActivationUrl,
            this.disallowLogin,
            this.allowLogin,
            this.deactivateAccount,
            this.reactivateAccount,
            this.convertEmployeeAccount,
            this.convertStakeholderAccount,
            this.changeRoleInOrganisationMenu,
        ];

        this.authNotificationService.authorisationChanged$.pipe(
            switchMap(() => this.checkCurrentPersonChangeToManageAccess()),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public get isNotAlto() {
        return AdaptClientConfiguration.AdaptProjectName !== AdaptProject.Alto;
    }

    public ngOnInit() {
        this.rxjsBreezeService.entityTypeChanged(Connection).pipe(
            filter((conn) => conn === this.connection),
            debounceTime(100),
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            this.updateAccessActions();
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.connection && this.connection) {
            this.updateAccessActions();
        }
    }

    public ngAfterViewInit() {
        const element = this.elementRef!.nativeElement as HTMLElement;
        const parentTableCell = element.closest<HTMLElement>("td");
        if (parentTableCell) {
            this.renderer.setStyle(parentTableCell, "overflow", "visible");
        }
    }

    private get isCurrentPerson() {
        const currentPersonId = this.userService.getCurrentPersonId();
        return currentPersonId === this.connection.personId;
    }

    @Autobind
    private async updateAccessActions() {
        const isCurrentPerson = this.isCurrentPerson;
        const isInactive = !this.connection.isActive();
        const roleConnectionsWithPermissions = this.connection.roleConnections
            .filter((roleConnection) => roleConnection.isActive())
            .filter(this.userManagementService.roleConnectionHasPermissionFilter);


        this.viewAccessItem.visible = !!this.userRoleActions?.viewAccess && !isInactive && roleConnectionsWithPermissions.length > 0;

        // configure access and roles
        this.configureAccessItem.visible = !!this.userRoleActions?.configureAccess && !isInactive && this.connection.userType === UserType.Leader && this.connection.connectionType !== ConnectionType.Coach;
        this.configureRolesItem.visible = !!this.userRoleActions?.configureRoles && !isInactive;

        // resend invitation invite
        this.sendInvitationEmailItem.visible = !isInactive && this.connection.hasAccess;
        this.sendInvitationEmailItem.separator = this.configureRolesItem.visible;
        this.generateActivationUrl.visible = this.sendInvitationEmailItem.visible;

        // allow / disallow login
        this.disallowLogin.visible = this.isNotAlto && !isInactive && this.connection.hasAccess && !isCurrentPerson;
        this.allowLogin.visible = this.isNotAlto && !isInactive && !this.connection.hasAccess && roleConnectionsWithPermissions.length > 0;

        // de-activation/ activation of account (start/end connection)
        this.deactivateAccount.visible = !isInactive && !isCurrentPerson;
        this.reactivateAccount.visible = isInactive;

        // convert employee <--> stakeholder
        this.convertEmployeeAccount.visible = this.isNotAlto && !isInactive && this.connection.isEmployeeConnection();
        this.convertStakeholderAccount.visible = this.isNotAlto && !isInactive && this.connection.isStakeholderConnection();
        const activeConnectionCount = this.connection.person.connections.filter((i) => i.isActive()).length;
        if (activeConnectionCount > 1) {
            this.convertEmployeeAccount.disabled = true;
            this.convertEmployeeAccount.tooltip = "This person has more than 1 connection to this organisation and cannot be converted";
            this.convertStakeholderAccount.disabled = true;
            this.convertStakeholderAccount.tooltip = this.convertEmployeeAccount.tooltip;
        } else {
            this.convertEmployeeAccount.disabled = false;
            this.convertEmployeeAccount.tooltip = undefined;
            this.convertStakeholderAccount.disabled = false;
            this.convertStakeholderAccount.tooltip = undefined;
        }

        // change role in organisation
        this.changeRoleInOrganisationMenu.visible = this.isAlto && !isInactive && this.connection.isEmployeeConnection();
        this.changeRoleToOwnerLeader.visible = this.connection.roleInOrganisation !== RoleInOrganisation.OwnerLeader;
        this.changeRoleToLeader.visible = this.connection.roleInOrganisation !== RoleInOrganisation.Leader;
        // For alto, we are not having employee, so if not leader or owner, is considered as other
        this.changeRoleToOther.visible = (this.connection.roleInOrganisation === RoleInOrganisation.OwnerLeader) || (this.connection.roleInOrganisation === RoleInOrganisation.Leader);

        // convert to full/team/view/none accounts
        this.convertAccessMenu.visible = this.isNotAlto && !isInactive && (this.connection.isEmployeeConnection() || this.connection.isStakeholderConnection());
        this.convertToLeaderUser.visible = this.connection.userType !== UserType.Leader;
        this.convertToCollaboratorUser.visible = this.connection.userType !== UserType.Collaborator;
        this.convertToViewerUser.visible = this.connection.userType !== UserType.Viewer;
        this.convertToNoneUser.visible = this.connection.userType !== UserType.None && this.connection.isStakeholderConnection();
    }

    @Autobind
    private async disallowLoginAction() {
        await this.directorySharedService.promiseToRemoveAccess(this.connection);
        this.updateAccessActions();
        this.notifyGridChangedEvent();
    }

    @Autobind
    private async allowLoginAction() {
        const sendEmail = await this.directorySharedService.promiseToEnableAccess(this.connection);
        if (sendEmail) {
            await this.userManagementService.promiseToSendWelcomeEmail(this.connection.person.personId);
        }

        this.updateAccessActions();
        this.notifyGridChangedEvent();
    }

    @Autobind
    private async generateActivationUrlAction() {
        this.commonDialogService.open(ActivationUrlDialogComponent, this.connection.person).subscribe();
    }

    @Autobind
    private deactivateAccountAction() {
        this.commonDialogService.open(EndConnectionDialogComponent, { connection: this.connection })
            .subscribe(() => this.updateAccessActions());
    }

    @Autobind
    private async reactivateAccountAction() {
        // new dialog returning person if accepted, undefined otherwise
        let result: Person | undefined;

        if (this.isNotAlto) {
            result = await lastValueFrom(this.commonDialogService.open(ReactivateAccountDialogComponent, this.connection.person), { defaultValue: undefined });
        } else {
            const latestConnection = this.connection.person.getLatestConnection([ConnectionType.Employee, ConnectionType.Stakeholder]) as Connection | undefined;
            if (latestConnection) {
                const leaderRole = await this.userManagementService.promiseToGetDefaultAccessLevel(UserType.Leader);
                if (leaderRole) {
                    await this.userManagementService.reactivateAccount(latestConnection, latestConnection.connectionType, UserType.Leader, [leaderRole], true);
                    result = this.connection.person;
                }
            }
        }

        if (result) {
            this.connectionChanged.emit(result.getLatestConnection());
        }
    }

    @Autobind
    private async sendWelcomeEmailAction() {
        this.userManagementService.confirmSendWelcomeEmail(this.connection.person.personId).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.notifyGridChangedEvent());
    }

    @Autobind
    private async viewAccessAction() {
        if (this.userRoleActions?.viewAccess) {
            await this.userRoleActions.viewAccess(this.injector, this.connection);
        }
    }

    @Autobind
    private async configureAccessAction() {
        if (this.userRoleActions?.configureAccess) {
            await this.userRoleActions.configureAccess(this.injector, this.connection);
        }

        this.updateAccessActions();
        this.notifyGridChangedEvent();
    }

    private async checkCurrentPersonChangeToManageAccess() {
        if (this.isCurrentPerson) {
            const canManageAccess = await this.authService.promiseToGetHasAccess(ConfigurationAuthService.ManageAccess);
            if (!canManageAccess) {
                // no longer access the page due to user action to itself
                if (this.isAlto) {
                    // Cumulus is already well taken care of - not using a special MenuTab. Just need this for alto
                    this.injector.get(ShellUiService).focusTab(MenuTabId);
                }

                await this.routeService.gotoHome();
            }
        }
    }

    @Autobind
    private async configureRolesAction() {
        if (this.userRoleActions?.configureRoles) {
            await this.userRoleActions.configureRoles(this.injector, this.connection);
        }

        this.updateAccessActions();
        this.notifyGridChangedEvent();
    }

    @Autobind
    private convertEmployeeStakeholderAction() {
        this.commonDialogService.open(ConvertConnectionTypeDialogComponent, this.connection)
            .subscribe(() => this.notifyGridChangedEvent());
    }

    private convertAccess(userType: UserType) {
        const dialogData: IConvertUserTypeDialogData = {
            userType,
            connection: this.connection,
        };

        this.commonDialogService.open(ConvertUserTypeDialogComponent, dialogData)
            .subscribe(() => this.notifyGridChangedEvent());
    }

    private changeRoleInOrganisation(role: RoleInOrganisation) {
        let additionalImpact = "";
        let addToLeadershipTeam = false;
        let removeFromLeadershipTeam = false;
        let newAccessLevelLabels: DefaultRoleLabel[] | undefined;
        if (this.connection.roleInOrganisation === RoleInOrganisation.OwnerLeader || this.connection.roleInOrganisation === RoleInOrganisation.Leader) {
            if (role === RoleInOrganisation.Other) {
                additionalImpact = "<p>Note that this will <b>remove</b> the person from the leadership team and change their access level to viewer.</p>";
                removeFromLeadershipTeam = true;
                newAccessLevelLabels = [DefaultRoleLabel.Viewer];
            }
        } else if (role === RoleInOrganisation.OwnerLeader || role === RoleInOrganisation.Leader) {
            additionalImpact = "<p>Note that this will <b>add</b> the person to the leadership team and change their access level to leader.</p>";
            addToLeadershipTeam = true;
            newAccessLevelLabels = [DefaultRoleLabel.Leader, DefaultRoleLabel.Administrator];
        }

        const dialogData: IConfirmationDialogData = {
            title: "Changing role in organisation",
            message: `<p>You are about to assign ${this.connection.person.fullName} to the role of
                <b>${RoleInOrganisationLabel[role]}</b> in the organisation</p>
                ${additionalImpact}
                <p>Are you sure you want to proceed with this change?</p>`,
            confirmButtonText: "Change & save",
        };
        this.commonDialogService.openConfirmationDialog(dialogData).pipe(
            switchMap(async () => {
                this.connection.roleInOrganisation = role;
                if (addToLeadershipTeam || removeFromLeadershipTeam) {
                    const leadershipTeam = await this.teamsService.promiseToGetLeadershipTeam();
                    if (leadershipTeam) {
                        const existingTeamRoleConnection = this.connection.roleConnections
                            .filter((rc) => rc.teamId === leadershipTeam.teamId && rc.isActive())[0];
                        if (addToLeadershipTeam && !existingTeamRoleConnection) {
                            await this.teamsService.promiseToAddTeamMember(leadershipTeam, this.connection, UserType.Leader);
                        } else if (removeFromLeadershipTeam && existingTeamRoleConnection) {
                            this.teamsService.removeTeamMember(existingTeamRoleConnection);
                        }
                    }

                    if (newAccessLevelLabels && newAccessLevelLabels.length > 0) {
                        const accessLevelRoles = (await Promise.all(newAccessLevelLabels.map((label) =>
                            this.userManagementService.promiseToGetAccessLevelRoleWithLabel(UserType.Leader, label))))
                            .filter((accessLevelRole) => !!accessLevelRole) as Role[];
                        if (accessLevelRoles.length > 0) {
                            const accessLevelRoleIds = accessLevelRoles.map((accessLevelRole) => accessLevelRole.roleId);
                            const activeRoleConnections = this.connection.roleConnections.filter((rc) => rc.isActive());
                            const now = new Date();
                            // ending role connections for not currently wanted role, excluding team as that's already taken care above if necessary
                            activeRoleConnections
                                .filter((rc) => !accessLevelRoleIds.includes(rc.roleId) && !rc.teamId)
                                .forEach((rc) => rc.endDate = now);
                            await Promise.all(accessLevelRoles.map((accessLevelRole) =>
                                !activeRoleConnections.find((rc) => rc.roleId === accessLevelRole.roleId)
                                    ? lastValueFrom(this.userManagementService.create(RoleConnectionBreezeModel, {
                                        role: accessLevelRole,
                                        connection: this.connection,
                                        startDate: now,
                                    }))
                                    : Promise.resolve()));
                        }
                    }
                }
            }),
            switchMap(() => this.teamsService.save()),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    private notifyGridChangedEvent() {
        // this is required as we are using a fixed column which height cannot be changed without updateDimensions call
        // causing cell within the row to have different heights and can't match up which row the action corresponds to
        // https://www.devexpress.com/Support/Center/Question/Details/T342376/dxdatagrid-the-row-height-is-incorrect-if-the-grid-has-a-fixed-column-and-one-of-its
        this.refreshRequired.emit();
    }
}

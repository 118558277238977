@if (runData.meeting; as meeting) {
    <h3>Title</h3>
    <dx-text-box #focus
                 [value]="meeting.name"
                 (valueChange)="onMeetingNameChange($event)"
                 placeholder="Enter meeting title"
                 [adaptValidateEntity]="meeting"
                 adaptValidateEntityProperty="name"
                 [disabled]="submitting"
                 data-test="schedule-meeting-title"></dx-text-box>

    @if (meeting.supplementaryData) {
        <h3 class="mt-3">Description</h3>
        <adapt-html-editor [contents]="meeting.supplementaryData.purpose"
                           (contentsChange)="onDescriptionChange($event)"
                           [readonly]="submitting"
                           (isValidChange)="isEditorValid = $event"
                           placeholderText="Describe any desired outcomes of the meeting (optional)"
                           forceSize="compact"
                           data-test="meeting-description"></adapt-html-editor>
    }

    <h3 class="mt-3">Agenda</h3>
    @if (agendaIsReadonly || agendaIsLocked) {
        @if (agendaIsReadonly) {
            <div class="alert alert-info">
                The agenda for this meeting can only be modified from the live meeting sidebar or page.
            </div>
        }
        @if (agendaIsLocked) {
            <div class="d-flex alert alert-warning align-items-center">
                The agenda for this meeting is read-only as the meeting has ended.
                <button class="btn btn-link ms-auto"
                        adaptButtonIcon="fal fa-money-check-edit"
                        [adaptBlockingClick]="allowAgendaEdit">Allow editing
                </button>
            </div>
        }
        <adapt-display-agenda-items [agendaItems]="meeting.meetingAgendaItems"></adapt-display-agenda-items>
    } @else {
        <adapt-edit-agenda [meeting]="meeting"
                           [disabled]="submitting"
                           [saveOnChange]="false"
                           (isAddingNewAgendaItem)="addingNewAgendaItem = $event"
                           (agendaItemsChange)="onAgendaItemsChange()"></adapt-edit-agenda>
    }
}

<ng-template #startMeetingNowButton>
    <div [adaptTooltip]="nonStartableMeetingInfo"
         [class.d-none]="!showRunMeetingNowButton">
        <button type="button"
                class="btn btn-secondary"
                adaptButtonIcon="runMeetingNow"
                data-test="run-meeting-now-button"
                [disabled]="!isValid.value || !!nonStartableMeetingInfo"
                [adaptBlockingClick]="runMeetingNow"
                [adaptBlockingClickParam]="true">{{runMeetingNowButtonText}}</button>
    </div>
</ng-template>

<div class="display-accordion-hierarchy">
    <adapt-display-navigation-node class="heading-node"
                                   *ngIf="rootNode$.value && rootVisible"
                                   [node]="rootNode$.value"></adapt-display-navigation-node>
    <ul *ngIf="nonAccordionNodes.length > 0">
        <li *ngFor="let node of nonAccordionNodes">
            <adapt-display-link-hierarchy [hierarchy]="node"
                                          [expandAllLinks]="(isMobileSize$ | async)">
            </adapt-display-link-hierarchy>
        </li>
    </ul>

    <dx-accordion [dataSource]="accordionNodes"
                  itemTemplate="detail"
                  [collapsible]="true"
                  [multiple]="true"
                  keyExpr="$id">
        <div *dxTemplate="let node of 'title'">
            {{node.title}}
        </div>
        <div *dxTemplate="let node of 'detail'">
            <ul>
                <li *ngFor="let nodeChild of node.children">
                    <adapt-display-link-hierarchy [hierarchy]="nodeChild"
                                                  [expandAllLinks]="(isMobileSize$ | async)">
                    </adapt-display-link-hierarchy>
                </li>
            </ul>
        </div>
    </dx-accordion>
</div>

import { ObjectUtilities } from "./object-utilities";

export class SortUtilities {
    public static sortArrayReverseChronological<T>(array: T[], getDate: (t: T) => Date): T[] {
        const sortFn = (a: T, b: T) => getDate(a) < getDate(b) ? 1 : -1;
        array.sort(sortFn);
        return array;
    }

    /**
     * Gets a function to be used to sort an array by the given fields. i.e.
     * You can call this function with getSortByFieldFunction('ordinal', 'name')
     * to sort an array by ordinal first, then name.
     *
     * @param fieldNames - The names of the fields to use for sorting
     * @returns A function to be used to sort an array by the provided fields
     */
    public static getSortByFieldFunction<TObj>(...sortFields: string[] | ((o: TObj) => any)[]) {
        return sortByField;

        function sortByField(a: TObj, b: TObj) {
            let comparison: number;
            let sortFieldIndex = 0;

            do {
                let getField = sortFields[sortFieldIndex];
                sortFieldIndex++;

                if (typeof getField === "string") {
                    const field = getField;
                    getField = (o: TObj) => ObjectUtilities.getObjectByPath(o, field);
                }

                const resultA = getField(a);
                const resultB = getField(b);
                comparison = compareValues(resultA, resultB);
            } while (comparison === 0 && sortFieldIndex < sortFields.length);

            return comparison;
        }

        function compareValues(resultA: any, resultB: any) {
            if (ObjectUtilities.isNullOrUndefined(resultA) && ObjectUtilities.isNullOrUndefined(resultB)) {
                return 0;
            }

            let comparison: number;

            if (ObjectUtilities.isNullOrUndefined(resultB)) {
                comparison = -1; // put A before B
            } else if (ObjectUtilities.isNullOrUndefined(resultA)) {
                comparison = 1; // put B before A
            } else {
                comparison = resultA - resultB;
            }

            if (isNaN(comparison)) {
                if (typeof resultA === "string" && typeof resultB === "string") {
                    comparison = resultA.localeCompare(resultB);
                } else {
                    comparison = -1; // Default to A then B (original order)
                }
            }

            return comparison;
        }
    }

    /**
     * Update index values in an integer sorted array when an item is removed
     *
     * @param {Array} array - The Array to be updated
     * @param {String} fieldName - The name of the index field
     * @param {Integer} indexRemoved - The value of the index for the removed item
     */
    public static updateIntegerSortedArrayAfterItemRemoval<T>(array: T[], fieldName: keyof T, indexRemoved?: number) {
        array.sort(SortUtilities.getSortByFieldFunction(String(fieldName)));

        if (!indexRemoved || indexRemoved < 0) {
            indexRemoved = 0;
        }

        for (let i = indexRemoved; i < array.length; i++) {
            array[i][fieldName] = i as any;
        }
    }

    /** Moves an array element from the specified index to the specified index.
     * If ordinalField is specified, it will also update the ordinals of the
     * array elements to match their final positions in the array.
     */
    public static moveItemInArray<T>(array: T[], fromIndex: number, toIndex: number, ordinalField?: keyof T) {
        const removedItem = array.splice(fromIndex, 1)[0];
        array.splice(toIndex, 0, removedItem);

        if (ordinalField) {
            SortUtilities.reorderItemInIntegerSortedArray(array, ordinalField, fromIndex, toIndex);
        }
    }

    /**
     * Set the ordinal field sequentially according to the order of the array
     */
    public static sequenceNumberFieldInArray<T>(array: T[], ordinalField: keyof T) {
        if (!Array.isArray(array)) {
            throw new TypeError("Array parameter not of type Array");
        }

        let ordinal = 0;
        for (const element of array) {
            element[ordinalField] = ordinal as any;
            ordinal++;
        }
    }

    /**
     * Reorder index values in an integer sorted array when an item is moved
     *
     * @param {Array} array - The array to be updated
     * @param {String} fieldName - The name of the index field
     * @param {Integer} fromIndex - The value of the index for the moved item
     * @param {Integer} toIndex - The value of the index where the item has been moved to
     */
    public static reorderItemInIntegerSortedArray<T>(array: T[], fieldName: keyof T, fromIndex: number, toIndex: number) {
        if (!Array.isArray(array)) {
            throw new TypeError("Array parameter not of type Array");
        }

        if (!array.length) {
            return;
        }

        // regardless of whether the array has been moved by dx, just restore the order and then followed by doing the move again
        // and resequence
        array.sort(SortUtilities.getSortByFieldFunction(String(fieldName)));

        if (!fromIndex || fromIndex < 0) {
            fromIndex = 0;
        }

        if (fromIndex >= array.length) {
            fromIndex = array.length - 1;
        }

        if (!toIndex || toIndex < 0) {
            toIndex = 0;
        }

        if (toIndex >= array.length) {
            toIndex = array.length - 1;
        }

        SortUtilities.moveItemInArray(array, fromIndex, toIndex);
        SortUtilities.sequenceNumberFieldInArray(array, fieldName);
    }

    /**
     * Update index values in an integer sorted array
     *
     * @param {Array} array - The Array to be updated
     * @param {String} fieldName - The name of the index field
     */
    public static updateIntegerSortedArray<T>(array: T[], fieldName: keyof T) {
        array.forEach(resetField);

        function resetField(item: T, index: number) {
            if (!ObjectUtilities.isObject(item)) {
                throw new TypeError("Array parameter item not of type Object");
            }

            item[fieldName] = index as any;
        }
    }
}

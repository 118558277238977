import { Validator } from "breeze-client";
import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Organisation } from "./organisation";

export enum SystemDocumentType {
    RichText = "RichText",
    ExternalLink = "ExternalLink",
    Image = "Image",
    Video = "Video",
    Document = "Document",
}

export class SystemDocument extends BaseEntity<SystemDocument> {
    public systemDocumentId!: number;
    public organisationId!: number;
    public url!: string;
    public content!: string;
    public type!: SystemDocumentType;

    // navigation
    public organisation!: Organisation;
}

const ValidDocumentSelectorValidator = new Validator(
    "validDocumentSelectorValidator",
    (systemDocument: SystemDocument) => systemDocument.type !== SystemDocumentType.Document || (!!systemDocument.url && systemDocument.url.length > 1),
    { messageTemplate: "Valid url must be defined for document type" },
);

export const SystemDocumentBreezeModel = new BreezeModelBuilder("SystemDocument", SystemDocument)
    .hasSource()
    .withIdField("systemDocumentId")
    .withEntityValidator(ValidDocumentSelectorValidator)
    .isOrganisationEntity()
    .persistChangedEntity()
    .build();

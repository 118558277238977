<adapt-context-sidebar-implementation-kit [articleId]="PurposeVisionIds"
                                          title="Purpose & vision"
                                          context="page"
                                          [fullScreen]="true"></adapt-context-sidebar-implementation-kit>

<div *ngIf="guidingPhilosophyHasData; else noGuidingPhilosophyTemplate"
     class="row">
    <div class="col-12 col-lg-6">
        <h2 class="text-center text-center-print-inherit mb-4"><i class="fal fa-fw fa-compass"></i> Purpose</h2>

        <div class="card"
             data-tour="purpose-statement-card">
            <h3 class="card-header pe-2">
                Purpose statement
                <button *adaptIfAuthorised="EditPurposeVision"
                        adaptButton="editBorderless"
                        [adaptBlockingClick]="editPurpose"
                        class="ms-auto"
                        data-tour="purpose-statement-edit"></button>
            </h3>
            <div class="card-body">
                <adapt-see-more [maxHeight]="350"
                                gradient>
                    <div *ngIf="organisationPurpose?.content; else noPurposeTemplate">
                        <p [froalaView]="organisationPurpose?.content"
                           data-test="guiding-philosophy-purpose"></p>
                        <div class="mt-2"
                             *ngIf="organisationPurpose?.notes as purposeNotes">
                            <label>Purpose notes</label>
                            <p [froalaView]="purposeNotes"
                               data-test="guiding-philosophy-purpose"></p>
                        </div>
                    </div>

                    <ng-template #noPurposeTemplate>
                        <p>No purpose has been defined.</p>
                    </ng-template>
                </adapt-see-more>
            </div>
        </div>

        <div class="card"
             data-tour="owners-why-card">
            <h3 class="card-header pe-2">
                Owners ‘why’
                <button *adaptIfAuthorised="EditPurposeVision"
                        adaptButton="editBorderless"
                        [adaptBlockingClick]="editOwnersStory"
                        class="ms-auto"
                        data-tour="owners-why-edit"></button>
            </h3>
            <div class="card-body">
                <adapt-display-seeds [seedType]="SeedEntity.Purpose"></adapt-display-seeds>
            </div>
        </div>
    </div>

    <div class="col-12 col-lg-6">
        <h2 class="text-center text-center-print-inherit mb-4"><i class="fal fa-fw fa-binoculars"></i> Vision</h2>

        <div class="card"
             data-tour="vision-statement-card">
            <h3 class="card-header pe-2">
                Vision statement
                <button *adaptIfAuthorised="EditPurposeVision"
                        adaptButton="editBorderless"
                        [adaptBlockingClick]="editVision"
                        class="ms-auto"
                        data-tour="vision-statement-edit"></button>
            </h3>
            <div class="card-body">
                <adapt-see-more [maxHeight]="350"
                                gradient>
                    <p *ngIf="guidingPhilosophy?.vision as vision; else noVisionTemplate"
                       [froalaView]="vision"
                       data-test="guiding-philosophy-vision"></p>
                    <ng-template #noVisionTemplate>
                        <p>No vision has been defined.</p>
                    </ng-template>
                </adapt-see-more>
            </div>
        </div>

        <div class="card"
             data-tour="owners-vision-alignment-card">
            <h3 class="card-header pe-2">
                Vision
                <button *adaptIfAuthorised="EditPurposeVision"
                        adaptButton="editBorderless"
                        [adaptBlockingClick]="editOwnersAlignment"
                        class="ms-auto"
                        data-tour="owners-vision-alignment-edit">
                </button>
            </h3>
            <div class="card-body">
                <adapt-display-seeds [seedType]="SeedEntity.Vision"></adapt-display-seeds>
            </div>
        </div>
    </div>
</div>

<adapt-entity-timestamp *ngIf="lastUpdated as updated"
                        class="text-muted"
                        [timestamp]="updated"
                        prefix="Last updated"></adapt-entity-timestamp>

<ng-template #noGuidingPhilosophyTemplate>
    <adapt-call-to-action [learnMoreUrl]="learnMoreUrl">
        <adapt-call-to-action-title>Purpose &amp; Vision</adapt-call-to-action-title>

        <p>
            Having a clear purpose and well-defined vision helps keep everyone driving
            in the same direction.
        </p>
        <p>
            Your purpose answers the question of why this business exists. It should
            talk to your cause, your passion, what you care about.
        </p>
        <p>
            The point of having a vision is to create something bigger than yourself, something that people can believe
            in and rally behind.
            A picture of a future that provides meaning and direction.
        </p>

        <button adaptCallToActionButton
                (click)="gotoStrategyWorkflows()">Explore related pathways
        </button>
    </adapt-call-to-action>
</ng-template>

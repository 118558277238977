import { Injectable, Injector } from "@angular/core";
import { Zone } from "@common/ADAPT.Common.Model/methodology/zone";
import { Theme, ThemeBreezeModel } from "@common/ADAPT.Common.Model/organisation/theme";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { map, Subject, switchMap, tap } from "rxjs";
import { BaseOrganisationService } from "../organisation/base-organisation.service";
import { EditThemeDialogComponent } from "./edit-theme-dialog/edit-theme-dialog.component";

@Injectable({
    providedIn: "root",
})
export class StrategyService extends BaseOrganisationService {
    private themeAdded = new Subject<void>();

    public constructor(
        injector: Injector,
        private dialogService: AdaptCommonDialogService,
    ) {
        super(injector);
    }

    public get themeAdded$() {
        return this.themeAdded.asObservable();
    }

    public editTheme(theme: Theme) {
        return this.dialogService.open(EditThemeDialogComponent, theme);
    }

    public editThemeAfterCreate(zone?: Zone) {
        return this.createTheme().pipe(
            switchMap((theme) => {
                if (zone) {
                    theme.zone = zone;
                }

                return this.editTheme(theme);
            }),
            tap(() => this.themeAdded.next()),
        );
    }

    public getAllThemes() {
        return this.commonDataService.getAll(ThemeBreezeModel);
    }

    public getThemesByZone(zone?: Zone) {
        // only a few themes expected - no point doing separate queries for each zone
        return this.getAllThemes().pipe(
            map((themes) => themes
                .filter((theme) => !zone || theme.zone === zone)
                .sort((t1, t2) => t1.ordinal - t2.ordinal)),
        );
    }

    public createTheme() {
        return this.commonDataService.create(ThemeBreezeModel, {
            organisationId: this.organisationId,
            zone: Zone.EconomicEngine, // default to EE instead of leaving it blank
            ordinal: 0,
        });
    }

    public createThemeForZone(themeName: string, zone: Zone) {
        return this.createTheme().pipe(
            tap((newTheme) => {
                newTheme.name = themeName;
                newTheme.zone = zone;
            }),
            switchMap((newTheme) => this.appendThemeOrdinalInZone(newTheme, zone)),
        );
    }

    public appendThemeOrdinalInZone(theme: Theme, zone: Zone) {
        return this.getThemesByZone(zone).pipe(
            map((existingThemes) => {
                const maxOrdinal = existingThemes
                    .filter((t) => t !== theme)
                    .reduce((max, t) => t.ordinal > max ? t.ordinal : max, 0);
                theme.ordinal = maxOrdinal + 1;
                return theme;
            }),
        );
    }
}

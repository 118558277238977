<ng-container *ngIf="seedAnswers.length > 0; else noSeedAnswers">
    <div *ngFor="let seedAnswer of seedAnswers; let last = last; let index = index"
         [ngClass]="{'mb-3': !last}">
        <h4>{{seedAnswer.question}}</h4>
        <adapt-see-more [maxHeight]="350"
                        gradient>
            <div [froalaView]="seedAnswer.answer"
                 [attr.data-test]="'answerTo-' + seedType + '-' + index"></div>
        </adapt-see-more>
    </div>
</ng-container>
<ng-template #noSeedAnswers>
    <p>No answers have been given.</p>
</ng-template>

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";
import { ServiceUri } from "@common/configuration/service-uri";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { BehaviorSubject, of } from "rxjs";
import { catchError, delay, first, map, switchMap, take, tap } from "rxjs/operators";
import { AccountService } from "../organisation/account/account.service";

@Injectable({
    providedIn: "root",
})
export class EulaService {
    private triggerUpdate$ = new BehaviorSubject<void>(undefined);

    public constructor(
        private organisationService: OrganisationService,
        private httpClient: HttpClient,
        private accountService: AccountService,
    ) {
    }

    /** A hot observable that emits with the current organisation's eula acceptance status
     * If there is no current organisation (i.e. user logged out), then it is considered accepted.
     */
    public get eulaHasBeenAccepted$() {
        // can't use currentOrganisation$ as does not trigger for no organisation
        return this.organisationService.organisationEntityUpdated$.pipe(
            switchMap((org) => {
                // Organisations subscribing to Alto automatically agree to the terms as part of the sign up process, so this can never be false
                if (AdaptClientConfiguration.AdaptProjectName === AdaptProject.Alto) {
                    return of(true);
                }

                if (!org) {
                    return of(true);
                }

                // this just isn't that important, so delay the request for a while so that it occurs later in the application init (and other more important queries are not queued)
                return of(false).pipe(
                    delay(10000),
                    // need to check the organisation again, it could have changed in the meantime
                    switchMap(() => this.organisationService.currentOrganisation$),
                    take(1),
                    switchMap((newOrg) => this.triggerUpdate$.pipe(map(() => newOrg))),
                    switchMap((newOrg) => newOrg
                        ? this.getEulaIsAccepted(newOrg)
                        : of(true)),
                );
            }),
            catchError(() => of(false)),
        );
    }

    public getEulaIsAccepted(org: Organisation) {
        return this.getEulaIsAcceptedForOrgId(org.organisationId);
    }

    public getEulaIsAcceptedForOrgId(orgId: number) {
        const endpoint = `${ServiceUri.AccountServiceUri}/EulaIsAccepted`;
        return this.httpClient.get<boolean>(endpoint, {
            params: { organisationId: String(orgId) },
        });
    }

    public sendEulaInvite(person: Person) {
        return this.organisationService.currentOrganisation$.pipe(
            emptyIfUndefinedOrNull(),
            first(),
            switchMap((org) => {
                const endpoint = `${ServiceUri.AccountServiceUri}/SendEulaInvite`;
                return this.httpClient.post(endpoint, null, {
                    params: {
                        organisationId: String(org.organisationId),
                        personId: String(person.personId),
                    },
                });
            }),
        );
    }

    public acceptEulaAsLoggedInPerson() {
        return this.organisationService.currentOrganisation$.pipe(
            emptyIfUndefinedOrNull(),
            first(),
            switchMap((org) => this.httpClient.post(`${ServiceUri.AccountServiceUri}/AcceptEulaAsLoggedInPerson`, null, {
                params: {
                    organisationId: String(org.organisationId),
                },
            })),
            switchMap(() => this.accountService.getAccount()), // reprime account on update
            tap(() => this.triggerUpdate$.next()),
        );
    }

    public acceptEula(token: string) {
        const endpoint = `${ServiceUri.AccountServiceUri}/AcceptEula`;
        return this.httpClient.post(endpoint, null, {
            params: { token },
        }).pipe(
            tap(() => this.triggerUpdate$.next()),
        );
    }

    public validateEulaToken(token: string) {
        const endpoint = `${ServiceUri.AccountServiceUri}/ValidateEulaToken`;
        return this.httpClient.get<boolean>(endpoint, {
            params: { token },
        })
            .pipe(
                map((response) => !!response),
                catchError(() => of(false)),
            );
    }
}

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";

export interface IChainUpdate<TEntity> {
    readonly updateEntity: TEntity;
    readonly person: Person;
    readonly dateTime: Date;
    readonly text: string;
    readonly iconClass: string;
    readonly canUpdate: boolean;
    hasComment?: boolean;
}

@Component({
    selector: "adapt-chain-update",
    templateUrl: "./chain-update.component.html",
    styleUrls: ["./chain-update.component.scss"],
})
export class ChainUpdateComponent<TEntity extends IBreezeEntity<TEntity>> {
    @Input() public chainUpdate!: IChainUpdate<TEntity>;
    @Output() public itemDeleted = new EventEmitter<IChainUpdate<TEntity>>();
    @Output() public itemEdited = new EventEmitter<IChainUpdate<TEntity>>();
}

import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { EntityExtensions } from "../entity-extensions.decorator";
import { EventCadenceCycleExtensions } from "./event-cadence-cycle-extensions";
import { Organisation } from "./organisation";

export class EventCadenceCycle extends BaseEntity<EventCadenceCycle> {
    public organisationId!: number;
    public month!: number;

    @EntityExtensions(EventCadenceCycleExtensions)
    public extensions!: EventCadenceCycleExtensions;

    public organisation!: Organisation;
}

export const EventCadenceCycleBreezeModel = new BreezeModelBuilder("EventCadenceCycle", EventCadenceCycle)
    .hasSource()
    .withIdField("organisationId")
    .isOrganisationEntity()
    .build();

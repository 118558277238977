import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import moment from "moment";
import { ICycle, IHeader, IItem, IItemEvent, IItemProperties, IPeriod, ISection, ISectionItem, ITimeFrame } from "../time-scheduler.interface";

interface ICurrentTime {
    visible: boolean;
    positionPercentage: number;
    title: string;
}

const addTimeFramesToDate = (date: moment.Moment | Date, timeFrames: ITimeFrame | ITimeFrame[], direction = 1) => {
    timeFrames = Array.isArray(timeFrames)
        ? timeFrames
        : [timeFrames];
    return timeFrames.reduce((acc, timeFrame) => acc.add(timeFrame.amount * direction, timeFrame.unit), moment(date));
};

@Component({
    selector: "adapt-time-scheduler[items][periods][sections]",
    templateUrl: "./time-scheduler.component.html",
    styleUrls: ["./time-scheduler.component.scss"],
})
export class TimeSchedulerComponent implements OnInit, OnChanges {
    @ViewChild("sectionTd")
    public set SectionTd(elementRef: ElementRef | undefined) {
        if (elementRef) {
            // element doesn't have clientWidth until it has fully rendered, but this triggers before that
            // so use an interval to wait for the width to be set.
            const interval = setInterval(() => {
                if (elementRef.nativeElement.clientWidth > 0) {
                    this.sectionLeftWidth = elementRef.nativeElement.clientWidth;
                    clearInterval(interval);
                }
            }, 10);
        }
    }

    // non-optional inputs
    @Input() public items!: IItem[];
    @Input() public sections!: ISection[];
    @Input() public periods!: IPeriod[];

    // optional inputs
    @Input() public currentTimeFormat = "DD-MMM-YYYY HH:mm";
    @Input() public showCurrentTime = true;
    @Input() public showSections = false;
    @Input() public minRowHeight = 24;
    @Input() public start = moment().startOf("day");
    @Input() public cycle?: ICycle;

    @Output() public initialised = new EventEmitter<TimeSchedulerComponent>();
    @Output() public itemClick = new EventEmitter<IItemEvent>();
    @Output() public itemMouseEnter = new EventEmitter<IItemEvent>();
    @Output() public itemMouseLeave = new EventEmitter<IItemEvent>();

    @Input() public selectedItem?: IItem;
    @Output() public selectedItemChange = new EventEmitter<IItem>();

    public currentPeriod!: IPeriod;
    public originalStart?: moment.Moment;
    public end = moment().endOf("day");
    public currentTimeDetails: ICurrentTime = { visible: false, positionPercentage: 0, title: "" };
    public sectionLeftWidth = 150;
    public headers: IHeader[][] = [];
    public sectionItems: ISectionItem[] = [];

    private ShowCurrentTimeHandle?: NodeJS.Timeout;
    private currentPeriodMinuteDiff = 0;

    private isInitialised = false;

    public static centreTime(period: IPeriod, inputTime: moment.Moment) {
        if (period?.timeFrameIncrement) {
            return addTimeFramesToDate(inputTime.clone(), period.timeFrameIncrement)
                // round down to start of the month
                .startOf("month");
        }

        return inputTime;
    }

    public ngOnInit() {
        this.originalStart = this.start.clone();

        this.currentPeriod = this.periods[0];
        this.refreshView();

        this.isInitialised = true;
        this.initialised.emit(this);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.start && !changes.start.isFirstChange()) {
            this.originalStart = moment(changes.start.currentValue);
            this.start = this.originalStart.clone();
        }

        if (this.isInitialised) {
            this.refreshView();
        }
    }

    public refreshView() {
        this.sectionItems = this.createSectionItems();
        this.changePeriod(this.currentPeriod);
    }

    public itemIsVisible(item: IItem) {
        return this.sectionItems.some((s) => s.itemProperties.some((prop) => prop.item === item));
    }

    public scrollItemIntoView(item: IItem) {
        if (this.items.includes(item)) {
            while (!this.itemIsVisible(item)) {
                if (item.start.isAfter(this.end)) {
                    this.nextIncrement();
                } else if (item.end.isBefore(this.start)) {
                    this.previousIncrement();
                }
            }
        }
    }

    public trackByFn(index: number) {
        return index;
    }

    public changePeriod(period: IPeriod) {
        this.currentPeriod = period;

        this.end = addTimeFramesToDate(moment(this.start), this.currentPeriod.timeFrame)
            // we don't want the last day to be visible (will overflow)
            // this should make sure we aren't in the next day
            .startOf("day")
            .subtract(1, "second");

        this.currentPeriodMinuteDiff = Math.abs(this.start.diff(this.end, "minutes"));

        this.headers = this.currentPeriod.timeFrameHeaders
            .map((format, index) => this.getDatesBetweenTwoDates(format, index));

        this.populateSectionItems();
        this.showCurrentTimeIndicator();
    }

    public gotoToday() {
        this.start = moment().startOf("day");
        this.changePeriod(this.currentPeriod);
    }

    public nextIncrement() {
        if (this.currentPeriod.timeFrameIncrement) {
            this.start = addTimeFramesToDate(this.start.clone(), this.currentPeriod.timeFrameIncrement);
            this.changePeriod(this.currentPeriod);
        }
    }

    public previousIncrement() {
        if (this.currentPeriod.timeFrameIncrement) {
            this.start = addTimeFramesToDate(this.start.clone(), this.currentPeriod.timeFrameIncrement, -1);
            this.changePeriod(this.currentPeriod);
        }
    }

    public nextPeriod() {
        this.start = addTimeFramesToDate(this.start.clone(), this.currentPeriod.timeFrame);
        this.changePeriod(this.currentPeriod);
    }

    public previousPeriod() {
        this.start = addTimeFramesToDate(this.start.clone(), this.currentPeriod.timeFrame, -1);
        this.changePeriod(this.currentPeriod);
    }

    public gotoOriginalDate() {
        this.gotoDate(this.originalStart!);
    }

    public gotoDate(event: Date | moment.Moment) {
        this.start = moment(event).startOf("day");
        this.changePeriod(this.currentPeriod);
    }

    public onItemClick(itemMeta: IItemProperties, event: MouseEvent) {
        // don't register clicks/selection for disabled items
        if (itemMeta.item.disabled) {
            return;
        }

        // unselect current item if clicked again
        this.selectedItem = this.selectedItem === itemMeta.item
            ? undefined
            : itemMeta.item;
        this.selectedItemChange.emit(this.selectedItem);
        this.itemClick.emit(this.getItemEvent(itemMeta, event));
    }

    public onItemMouseMovement(itemMeta: IItemProperties, event: MouseEvent, leave = false) {
        const eventEmitter = leave ? this.itemMouseLeave : this.itemMouseEnter;
        eventEmitter.emit(this.getItemEvent(itemMeta, event));
    }

    private getItemEvent(itemMeta: IItemProperties, event: MouseEvent) {
        return {
            item: itemMeta.item,
            element: (event.target) as HTMLDivElement,
            event,
        } as IItemEvent;
    }

    private createSectionItems() {
        return this.sections
            .filter((section) => section.visible)
            .map((section) => ({
                section,
                minRowHeight: this.minRowHeight,
                itemProperties: [],
            }));
    }

    private populateSectionItems() {
        const itemProperties = this.sectionItems.flatMap((sectionItem) => {
            sectionItem.minRowHeight = this.minRowHeight;
            sectionItem.itemProperties = this.items
                // get items that are in this section
                .filter((item) => item.sectionId === sectionItem.section.id
                    && item.start <= this.end
                    && item.end >= this.start)
                .map((item) => {
                    const props = this.calculateItemOffsets({
                        item,
                        cssTop: 2,
                        cssLeft: 0,
                        cssWidth: 0,
                    });
                    sectionItem.itemProperties.push(props);
                    return props;
                });
            return sectionItem.itemProperties;
        });

        const itemsInSection = itemProperties.reduce((sortItems, props) => {
            const index = this.sectionItems.findIndex(({ section }) => section.id === props.item.sectionId);
            if (!sortItems[index]) {
                sortItems[index] = [];
            }
            sortItems[index].push(props);
            return sortItems;
        }, {} as Record<number, IItemProperties[]>);

        this.calculateItemStackingOffsets(itemsInSection);
    }

    private calculateItemOffsets(itemProperties: IItemProperties) {
        const itemStart = moment.max(itemProperties.item.start, this.start);
        const itemEnd = moment.min(itemProperties.item.end, this.end);

        const widthMinuteDiff = Math.abs(itemStart.diff(itemEnd, "minutes"));
        const leftMinuteDiff = itemStart.diff(this.start, "minutes");

        itemProperties.cssWidth = (widthMinuteDiff / this.currentPeriodMinuteDiff) * 100;
        itemProperties.cssLeft = (leftMinuteDiff / this.currentPeriodMinuteDiff) * 100;

        return itemProperties;
    }

    // offset overlapping times vertically and enlarges the row height to fit all the items.
    private calculateItemStackingOffsets(itemsInSection: Record<number, IItemProperties[]>) {
        Object.keys(itemsInSection).map(Number).forEach((sectionId) => {
            itemsInSection[sectionId].forEach((itemProperties, idx, sectionItems) => {
                let itemCssBottom = itemProperties.cssTop + this.minRowHeight;

                // check the previously seen items so the current item cssTop is set correctly
                for (let lastItemIdx = 0; lastItemIdx < idx; lastItemIdx++) {
                    const lastItemProperties = sectionItems[lastItemIdx];
                    const lastItemCssBottom = lastItemProperties.cssTop + this.minRowHeight;

                    // check if cssTop needs updating
                    if (this.itemsHaveOverlap(lastItemProperties.item, itemProperties.item) && (
                        (lastItemProperties.cssTop <= itemProperties.cssTop && itemProperties.cssTop <= lastItemCssBottom) ||
                        (lastItemProperties.cssTop <= itemCssBottom && itemCssBottom <= lastItemCssBottom)
                    )) {
                        itemProperties.cssTop = lastItemCssBottom + 1;
                    }
                }

                // update section row to have the correct height for the items
                const section = this.sectionItems[sectionId];
                itemCssBottom = itemProperties.cssTop + this.minRowHeight + 1;
                section.minRowHeight = Math.max(itemCssBottom, section.minRowHeight);
            });
        });
    }

    private itemsHaveOverlap(first: IItem, second: IItem) {
        return (first.start <= second.start && second.start <= first.end)
            || (first.start <= second.end && second.end <= first.end)
            || (first.start >= second.start && second.end >= first.end)
            // stack items that land within a week of each other
            || (Math.abs(first.start.diff(second.start, "week", true)) <= 1.0);
    }

    private showCurrentTimeIndicator() {
        if (this.ShowCurrentTimeHandle) {
            clearTimeout(this.ShowCurrentTimeHandle);
        }

        const currentTime = moment();
        if (currentTime >= this.start && currentTime <= this.end) {
            this.currentTimeDetails.visible = true;
            this.currentTimeDetails.positionPercentage = (Math.abs(this.start.diff(currentTime, "minutes")) / this.currentPeriodMinuteDiff) * 100;
            this.currentTimeDetails.title = currentTime.format(this.currentTimeFormat);
        } else {
            this.currentTimeDetails.visible = false;
        }

        // update current time indicator every 30 seconds
        this.ShowCurrentTimeHandle = setTimeout(() => this.showCurrentTimeIndicator(), 30_000);
    }

    private getDatesBetweenTwoDates(format: string, index: number): IHeader[] {
        const now = moment(this.start);
        const dates: IHeader[] = [];
        let prevHeader: string | undefined;
        let colspan = 0;

        while (now.isSameOrBefore(this.end)) {
            const name = now.format(format);
            if (prevHeader && prevHeader !== name) {
                colspan = 1;
            } else {
                colspan++;
                dates.pop();
            }
            prevHeader = name;

            let cycle: number | undefined;
            if (this.cycle) {
                if (now.isBetween(this.cycle.start, this.cycle.end)) {
                    // cycle 1 as the after cycle period starts with 0
                    cycle = 1;
                } else if (now.isAfter(this.cycle.end)) {
                    const months = moment(this.cycle.end).diff(now, "months", true);
                    // will alternate between 0 and 1 every 12 months after the cycle end
                    cycle = Math.floor(Math.abs(months) / 12) % 2;
                }
            }

            const headerDetails: IHeader = {
                name: now.format(format),
                colspan,
                cycle,
                tooltip: this.currentPeriod!.timeFrameHeadersTooltip && this.currentPeriod!.timeFrameHeadersTooltip[index]
                    ? now.format(this.currentPeriod!.timeFrameHeadersTooltip[index])
                    : "",
            };
            dates.push(headerDetails);
            now.add(this.currentPeriod!.timeFramePeriod, "minutes");
        }
        return dates;
    }
}

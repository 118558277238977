import { Component, Inject } from "@angular/core";
import { Survey } from "@common/ADAPT.Common.Model/organisation/survey";
import { SurveySupplementaryData } from "@common/ADAPT.Common.Model/organisation/survey-supplementary-data";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { Observable } from "rxjs";
import { SurveyService } from "../survey.service";

@Component({
    selector: "adapt-view-survey-respondents-dialog",
    templateUrl: "./view-survey-respondents-dialog.component.html",
    styleUrls: ["./view-survey-respondents-dialog.component.scss"],
})
export class ViewSurveyRespondentsDialogComponent extends BaseDialogComponent<Survey> {
    public readonly dialogName = "ViewSurveyRespondents";

    public surveySuppData$: Observable<SurveySupplementaryData | undefined>;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public survey: Survey,
        private surveyService: SurveyService,
    ) {
        super();

        this.surveySuppData$ = this.surveyService.getSupplementaryDataForSurvey(survey.surveyId);
    }
}

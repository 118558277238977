<adapt-dialog [maxWidth]="800">
    <h3 adapt-dialog-title>{{dialogName}}</h3>
    <div adapt-dialog-content
         class="d-flex flex-column align-items-center">
        <adapt-select-payment-frequency title="Change your payment frequency"
                                        [account]="account"
                                        (selectionChanged)="onSelect($event)"></adapt-select-payment-frequency>
        <b class="text-center">The change to your payment frequency will come into effect at your next billing
            cycle as of {{account.nextSubscriptionInvoiceDate | adaptDate}}</b>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveButtonPreset="primary"
                                   saveText="Update subscription"
                                   cancelText="Cancel"
                                   [discardConfirmationInstance]="this">
    </adapt-standard-dialog-buttons>
</adapt-dialog>

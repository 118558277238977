// eslint-disable-next-line max-classes-per-file
import { ElementRef } from "@angular/core";
import { ShellStyleConstants } from "@common/shell/shell-style.constants";
import { BehaviorSubject, combineLatest, Subject, Subscription } from "rxjs";
import { debounceTime, filter, tap } from "rxjs/operators";

const InMemoryScrollPositions: { [componentIdentifier: string]: { scrollTop: number; scrollLeft: number; } } = {};

export class ScrollPersistenceHelper {
    private scrollPositionKey?: string;
    private scrollContainer: HTMLElement | null = null; // HTMLElement.closest returned HTMLElement | null - inconvenient with undefined
    private deferScrollPositions = false;

    private restoreScrollPositionTrigger$ = new Subject<void>();
    private restoreScrollPositionTriggerSubscription?: Subscription; // only subscribed to if component wants to remember

    // a mechanism by which the component can block the position restore until it is ready to do so
    // (e.g. in the case of content on the page taking some time to load & render)
    private restoreBlocked$ = new BehaviorSubject<boolean>(false);

    private isRestoringScrollPosition = false; // need this so that scroll position restoration won't overlap with another scroll and save, e.g. from routemediator

    constructor(private elementRef: ElementRef) {
    }

    public blockRestore() {
        this.restoreBlocked$.next(true);
    }

    public unblockRestore() {
        this.restoreBlocked$.next(false);
    }

    public onDestroy() {
        // takeUntilDestory should complete this but no harm to unsubscribe again
        this.restoreScrollPositionTriggerSubscription?.unsubscribe();
    }

    public afterViewInit() {
        if (this.scrollPositionKey && this.elementRef) {
            const element = this.elementRef.nativeElement as HTMLElement;
            // there can be 3 scroll container, right/left-column or mainview. If left/right column, mainview won't scroll
            const rightColumnContainer = element.closest<HTMLElement>(ShellStyleConstants.RightColumnClassSelect);
            if (rightColumnContainer) {
                // this component is within a right column container
                this.scrollContainer = rightColumnContainer;
            } else {
                const leftColumnContainer = element.closest<HTMLElement>(ShellStyleConstants.LeftColumnClassSelect);
                if (leftColumnContainer) {
                    this.scrollContainer = leftColumnContainer;
                } else {
                    this.scrollContainer = element.closest<HTMLElement>(ShellStyleConstants.MainViewClassSelect);
                }
            }

            // somehow right-column has display none on startup - offsetParent will indicate if the container
            // is visible (HTMLElement.hidden/visible not reflecting that) by returning null if not visible.
            if (this.scrollContainer?.offsetParent) {
                this.restoreScrollPositionTrigger$.next();
            } else if (this.scrollContainer) {
                this.deferScrollPositions = true;
            }
        }
    }

    public afterViewChecked() {
        if (this.scrollPositionKey && this.scrollContainer?.offsetParent) {
            if (this.deferScrollPositions) {
                this.deferScrollPositions = false;
                this.restoreScrollPositionTrigger$.next();
            } else if (!this.isRestoringScrollPosition && !this.restoreBlocked$.value) { // only store scroll position if not in the middle of restoring
                const lastRememberedPositions = InMemoryScrollPositions[this.scrollPositionKey];
                if (this.scrollContainer.scrollTop > 0 || this.scrollContainer.scrollLeft > 0 || lastRememberedPositions) {
                    if (!lastRememberedPositions
                        || lastRememberedPositions.scrollLeft !== this.scrollContainer.scrollLeft
                        || lastRememberedPositions.scrollTop !== this.scrollContainer.scrollTop) {
                        InMemoryScrollPositions[this.scrollPositionKey] = {
                            scrollTop: this.scrollContainer.scrollTop,
                            scrollLeft: this.scrollContainer.scrollLeft,
                        };
                    }
                }
            }
        }
    }

    /**
     * Calling this will result in the scroll position of the parent mainview/left/right-column to be remembered
     * till session refresh.
     * @param key The key use to store the scroll position - gives you control over if all component of the same type
     *  share a single position or component's input.
     */
    public rememberScrollPosition(key: string) {
        this.scrollPositionKey = key;
        if (!this.restoreScrollPositionTriggerSubscription) {
            this.restoreScrollPositionTriggerSubscription = combineLatest([this.restoreScrollPositionTrigger$, this.restoreBlocked$]).pipe(
                filter(([_t1, t2]) => t2 === false),
                tap(() => this.isRestoringScrollPosition = true),
                debounceTime(100),
                //    this.takeUntilDestroyed(),
            ).subscribe(() => this.restoreScrollPositions());
        }
    }

    private restoreScrollPositions() {
        if (this.scrollContainer?.offsetParent && this.scrollPositionKey) {
            const lastRememberedPositions = InMemoryScrollPositions[this.scrollPositionKey];
            if (lastRememberedPositions) {
                this.scrollContainer.scrollTop = lastRememberedPositions.scrollTop;
                this.scrollContainer.scrollLeft = lastRememberedPositions.scrollLeft;
            }
        }

        this.isRestoringScrollPosition = false;
    }
}

import { Component, Injector } from "@angular/core";
import { MeetingAgendaItemComponent } from "@org-common/lib/meetings/meeting-agenda-component-registry";
import { WorkshopSetCadenceBaseComponent } from "../workshop-set-cadence-base.component";

@MeetingAgendaItemComponent("adapt-pys-workshop-set-cadence")
@Component({
    selector: "adapt-pys-workshop-set-cadence",
    templateUrl: "./pys-workshop-set-cadence.component.html",
})
export class PysWorkshopSetCadenceComponent extends WorkshopSetCadenceBaseComponent {
    public constructor(injector: Injector) {
        super(injector);
    }
}

import { Component, Input } from "@angular/core";
import { Value } from "@common/ADAPT.Common.Model/organisation/value";
import { OrganisationFeatureFlag } from "../../features/organisation-feature-flag.enum";

@Component({
    selector: "adapt-value-description",
    templateUrl: "./value-description.component.html",
    styleUrls: ["./value-description.component.scss"],
})
export class ValueDescriptionComponent {
    public readonly OrganisationFeatureFlag = OrganisationFeatureFlag;

    @Input() public set value(v: Value | undefined) {
        this._value = v;
        this.updateValueQuestionOrdinals();
    }

    public get value() {
        return this._value;
    }

    private _value?: Value;

    public updateValueQuestionOrdinals() {
        if (!this.value) {
            return;
        }

        this.value.valueQuestions.sort((a, b) => a.ordinal - b.ordinal);
        this.value.valueQuestions.sort((a, b) => {
            if (a.goodBehaviour === b.goodBehaviour) {
                return 0;
            } else if (a.goodBehaviour) {
                return -1;
            } else {
                return 1;
            }
        });
    }
}

import { Component, Input, OnInit } from "@angular/core";
import { OrganisationCategoryValue, Workflow, WorkflowScope } from "@common/ADAPT.Common.Model/embed/workflow";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { WorkflowConnection } from "@common/ADAPT.Common.Model/organisation/workflow-connection";
import { WorkflowStatus } from "@common/ADAPT.Common.Model/organisation/workflow-status";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { IGroupedData } from "@common/lib/utilities/grouped-data.interface";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { UserService } from "@common/user/user.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { lastValueFrom, merge, Observable, switchMap } from "rxjs";
import { debounceTime, startWith } from "rxjs/operators";
import { WorkflowService } from "../workflow.service";
import { WorkflowsPageRoute } from "../workflows-page/workflows-page.component";

const MAX_WORKFLOWS = 3;

@Component({
    selector: "adapt-workflows-dashboard-element",
    templateUrl: "./workflows-dashboard-element.component.html",
    styleUrls: ["./workflows-dashboard-element.component.scss"],
})
export class WorkflowsDashboardElementComponent extends BaseComponent implements OnInit {
    public readonly categories = Object.values(OrganisationCategoryValue)
        .filter((category: string) => category !== OrganisationCategoryValue.Others);
    public categoriesWorkflows: IGroupedData<OrganisationCategoryValue | undefined, Workflow>[] = [];

    @Input() public person?: Person;
    @Input() public team?: Team;

    public url$?: Observable<string>;
    public recommendedWorkflows: Workflow[] = [];
    public outstandingWorkflowConnections: WorkflowConnection[] = [];
    public outstandingWorkflowConnectionsCount = 0;

    public constructor(
        rxjsBreezeService: RxjsBreezeService,
        private userService: UserService,
        private workflowService: WorkflowService,
        private teamsService: CommonTeamsService,
    ) {
        super();

        merge(
            rxjsBreezeService.entityTypeChanged(WorkflowConnection),
            rxjsBreezeService.entityTypeChanged(WorkflowStatus),
        ).pipe(
            startWith(undefined),
            debounceTime(100),
            switchMap(() => this.updateFlowConnections()),
            switchMap(() => this.setRecommendedPathways()),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public async ngOnInit() {
        this.url$ = WorkflowsPageRoute.getRoute();
    }

    public get recommendationCount() {
        return Math.max(0, MAX_WORKFLOWS - this.outstandingWorkflowConnections.length);
    }

    public get activePathwaysTooltip() {
        return this.outstandingWorkflowConnectionsCount > this.outstandingWorkflowConnections.length
            ? `You have ${this.outstandingWorkflowConnectionsCount} pathways active. Only the ${MAX_WORKFLOWS} most recently started are shown here.`
            : undefined;
    }

    private async updateFlowConnections() {
        if (!this.person) {
            this.person = this.userService.currentPerson;
        }

        // only get team connections if this dashboard element is shown for a team
        const outstandingWorkflows = this.team
            ? await lastValueFrom(this.workflowService.getOutstandingWorkflowConnectionsForTeam(this.team.teamId))
            : await lastValueFrom(this.workflowService.getOutstandingWorkflowConnectionsForPerson(this.person!.personId));

        this.outstandingWorkflowConnections = outstandingWorkflows.slice(0, MAX_WORKFLOWS);
        this.outstandingWorkflowConnectionsCount = outstandingWorkflows.length;
    }

    private async setRecommendedPathways() {
        this.recommendedWorkflows = [];

        const leadershipTeam = await this.teamsService.promiseToGetLeadershipTeam();
        const isInTeam = this.person && (this.team || leadershipTeam)
            ? await this.teamsService.promiseToVerifyPersonActiveInTeam(this.person!, this.team ?? leadershipTeam)
            : false;

        for (const category of this.categories) {
            const categoryWorkflows = this.workflowService.getWorkflowsByCategory(category)
                .filter((i) => this.shouldRecommendWorkflow(i, isInTeam))
                .sort(SortUtilities.getSortByFieldFunction<Workflow>("ordinal"));

            for (const workflow of categoryWorkflows) {
                if (this.recommendedWorkflows.length >= this.recommendationCount) {
                    return;
                }

                if (!workflow.extensions.isComingSoon) {
                    const prerequisitesSatisfied = await lastValueFrom(this.workflowService.areWorkflowPrerequisitesSatisfied(workflow));
                    if (prerequisitesSatisfied) {
                        const workflowConnection = await lastValueFrom(this.workflowService.getLatestWorkflowConnectionForWorkflow(workflow));
                        if (!workflowConnection) {
                            this.recommendedWorkflows.push(workflow);
                        }
                    }
                }
            }
        }
    }

    private shouldRecommendWorkflow(workflow: Workflow, isInTeam: boolean) {
        // stateless should never be recommended
        if (workflow.isStateless) {
            return false;
        }

        // if this person is not in the team, then never recommend a next pathway
        if ((workflow.scope == WorkflowScope.Team) && !isInTeam) {
            return false;
        }

        if (workflow.category === OrganisationCategoryValue.Onboarding) {
            if (this.team) {
                // only show team scoped onboarding if team is given
                return workflow.scope === WorkflowScope.Team;
            }

            // otherwise show onboarding always
            return true;
        }

        // private should not be recommended (unless onboarding as above)
        return !workflow.private;
    }
}

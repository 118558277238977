<div *ngIf="survey$ | async as survey"
     class="card survey-result-card">
    <div class="survey-result-container hidden-panel-container">
        <div class="survey-result-details"
             *ngIf="!hideDate || !hideName">
            <div class="d-inline-flex align-self-baseline"
                 *ngIf="!hideName">

                <div class="mt-1 survey-title"
                     [class.h3]="!isAlto"
                     [class.h5]="isAlto">
                    <i class="far fa-square-poll-vertical me-2"></i>
                    <a [routerLink]="analyseSurveyPageObject?.path"
                       [queryParams]="analyseSurveyPageObject?.queryParams">{{survey.name}}</a>
                </div>
                <div class="hidden-panel">
                    <button *ngIf="canRestoreSurvey"
                            adaptButtonIcon="fal fa-reply-all"
                            class="bg-transparent override-button-color p-0 ms-2"
                            (click)="restoreSurvey(survey)"
                            adaptTooltip="Re-open ended survey"></button>
                    <ng-container *ngIf="allowRestore">
                        <button adaptButtonIcon="browse"
                                class="bg-transparent override-button-color p-0 ms-2"
                                adaptTooltip="Show participants"
                                [adaptBlockingClick]="showRespondents"
                                [adaptBlockingClickParam]="survey"></button>
                        <button adaptButtonIcon="delete"
                                class="bg-transparent override-button-color p-0 ms-2"
                                adaptTooltip="Delete survey"
                                (click)="promptToDeleteSurvey(survey)"></button>
                    </ng-container>
                </div>
            </div>
            <span *ngIf="!hideDate"
                  class="small align-self-baseline text-nowrap ms-2">Closed: {{survey.endTime | adaptDate}}</span>
        </div>

        <div class="flex-grow-1">
            <adapt-progress-bar [percentageProgress]="surveyResult?.averagePercentageScore"
                                [colour]="scoreColor"
                                [backgroundColour]="scoreBackground"
                                [leftAligned]="true"
                                class="d-flex mt-1 mb-1"
                                [overrideText]="scoreText"></adapt-progress-bar>

            <adapt-progress-bar [percentageProgress]="surveyResult?.percentageParticipation"
                                [colour]="participationColor"
                                [backgroundColour]="participationBackground"
                                [leftAligned]="true"
                                [overrideText]="participationText"></adapt-progress-bar>
        </div>
    </div>
</div>

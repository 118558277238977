export class AdaptError extends Error {
    public cause?: string;

    public constructor(message?: string) {
        super(message);

        // Manually set the prototype so we get the correct instanceof behaviour as documented in
        // https://github.com/Microsoft/TypeScript-wiki/blob/master/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
        Object.setPrototypeOf(this, new.target.prototype);
    }

    public static fromError(error: Error) {
        const adaptError = new AdaptError(error.message);
        adaptError.name = error.name;
        adaptError.stack = error.stack;
        return adaptError;
    }

    /** Should this error be logged? */
    public get shouldBeLogged() {
        return true;
    }
}

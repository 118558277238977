import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { EntityExtensions } from "../entity-extensions.decorator";
import { Input } from "./input";
import { InputGroupExtensions } from "./input-group-extensions";
import { InputsCanvas } from "./inputs-canvas";

export enum InputGroupType {
    Competitor = "Competitor",
}

export class InputGroup extends BaseEntity<InputGroup> {
    public inputGroupId!: number;
    public name!: string;
    public description!: string;
    public canvasId!: number;
    public type!: InputGroupType;

    public canvas!: InputsCanvas;
    public inputs!: Input[];

    @EntityExtensions(InputGroupExtensions)
    public extensions!: InputGroupExtensions;
}

// entity without an endpoint as this will come through a nav property from canvas
export const InputGroupBreezeModel = new BreezeModelBuilder("InputGroup", InputGroup)
    .build();

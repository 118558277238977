import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { ProgressBarComponent } from "./progress-bar.component";

@NgModule({
    imports: [
        CommonModule,
        AdaptTooltipModule,
    ],
    exports: [
        ProgressBarComponent,
    ],
    declarations: [
        ProgressBarComponent,
    ],
})
export class AdaptProgressBarModule {
}

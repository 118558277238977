import { FeaturePermission, FeaturePermissionBreezeModel } from "@common/ADAPT.Common.Model/embed/feature-permission";
import { ConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/connection";
import { Role, RoleBreezeModel } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleConnection, RoleConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { RoleFeaturePermissionBreezeModel } from "@common/ADAPT.Common.Model/organisation/role-feature-permission";
import { RoleType, RoleTypeBreezeModel } from "@common/ADAPT.Common.Model/organisation/role-type";
import { BaseQueryUtilities } from "@common/lib/data/base-query-utilities";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import moment from "moment";
import { map, switchMap } from "rxjs/operators";

// Data utils class (used to be in data service) to workaround circular dependency issue.
export class IntegratedArchitectureFrameworkQueryUtilities extends BaseQueryUtilities {
    public getRoleTypeByCode(code: string) {
        const predicate = new MethodologyPredicate<RoleType>("code", "==", code);

        return this.commonDataService.getByPredicate(RoleTypeBreezeModel, predicate).pipe(
            map(ArrayUtilities.getSingleFromArray),
        );
    }

    public createRolePermission(role: Role, featurePermissionName: any) {
        const predicate = new MethodologyPredicate<FeaturePermission>("name", "==", featurePermissionName);
        return this.commonDataService.getByPredicate(FeaturePermissionBreezeModel, predicate).pipe(
            map(ArrayUtilities.getSingleFromArray),
            switchMap((featurePermission) => this.commonDataService.create(RoleFeaturePermissionBreezeModel, { role, featurePermission })),
        );
    }

    /**
     * Promise to get all roles in an organisation.
     * @param {MethodologyPredicate} predicate A predicate to filter active roles by
     * @returns {Promise} A promise that resolves with all roles.
     */
    public getActiveRolesByPredicate(predicate?: MethodologyPredicate<Role>) {
        return this.commonDataService.getByPredicate(RoleBreezeModel, predicate).pipe(
            map((roles) => roles.filter((role) => role.isActive())),
        );
    }

    /**
     * Promise to create a role connection that starts now.
     * @param {object} values The default values with which to create the role connection.
     * @returns {Promise} A Promise that is resolved with the newly created roleConnection.
     */
    public createRoleConnection(values: Partial<RoleConnection>) {
        const defaults = {
            startDate: moment.utc().toDate(),
        };
        return this.commonDataService.create(RoleConnectionBreezeModel, Object.assign(defaults, values));
    }

    /**
     * Promise to get all role connections in an organisation.
     * @param {bool} activeOnly A boolean representation of whether to get active role connections only
     * @returns {Promise} A promise that resolves with all role connections.
     */
    public getAllRoleConnections(activeOnly?: boolean) {
        const key: any = activeOnly
            ? "activeRoleConnections"
            : "allRoleConnections";

        const options: any = {
            encompassingKey: ConnectionBreezeModel.identifier,
            namedParams: {
                activeOnly,
            },
        };

        return this.commonDataService.getWithOptions(RoleConnectionBreezeModel, key, options);
    }

    /**
     * Promise to get the role connections for a person.
     * @param {int} personId The identifier for the person to retrieve role connections for.
     * @param {boolean} activeOnly Only get active role connections.
     * @returns {Promise} A Promise that resolves with the role connections.
     */
    public getRoleConnectionsForPersonId(personId: number, activeOnly?: boolean) {
        const key = activeOnly
            ? "activeRoleConnections" + personId
            : "allRoleConnections" + personId;

        const predicate = new MethodologyPredicate<RoleConnection>("connection.personId", "==", personId);
        const options = {
            predicate,
            encompassingKey: ConnectionBreezeModel.identifier,
            namedParams: {
                activeOnly,
            },
        };

        return this.commonDataService.getWithOptions(RoleConnectionBreezeModel, key, options);
    }

    /**
     * Promise to get the role connection for a person and role.
     * @param {int} roleId The identifier of the role.
     * @param {int} personId The identifier of the person.
     * @returns {promise} Promise that resolves with the role connection.
     */
    public getActiveRoleConnectionForRoleIdAndPersonId(roleId: number, personId: number) {
        const key = RoleConnectionBreezeModel.identifier + "ForRoleId" + roleId + "AndPersonId" + personId;
        const predicate = new MethodologyPredicate<RoleConnection>("connection.personId", "==", personId)
            .and(new MethodologyPredicate<RoleConnection>("roleId", "==", roleId));

        const options = {
            predicate,
            encompassingKey: ConnectionBreezeModel.identifier,
            namedParams: {
                activeOnly: true,
            },
        };

        return this.commonDataService.getWithOptions(RoleConnectionBreezeModel, key, options).pipe(
            map(ArrayUtilities.getSingleFromArray),
        );
    }

    /**
     * Promise to get the role connections for a role.
     * @param {int} roleId The identifier for the role to retrieve role connections for.
     * @param {boolean} activeOnly A boolean representation of whether to get active role connections only.
     * @returns {Promise} A promise that resolves with the people performing a role.
     */
    public getRoleConnectionsForRoleId(roleId: number, activeOnly?: boolean) {
        const roleConnectionPredicate = new MethodologyPredicate<RoleConnection>("roleId", "==", roleId);

        const options = {
            predicate: roleConnectionPredicate,
            navProperty: "connection.person",
            encompassingKey: ConnectionBreezeModel.identifier,
            namedParams: {
                activeOnly,
            },
        };

        const requestKey = "peopleForRole" + roleId;

        return this.commonDataService.getWithOptions(RoleConnectionBreezeModel, requestKey, options);
    }
}

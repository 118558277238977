import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DxAccordionModule, DxCheckBoxModule } from "devextreme-angular";
import { EditOrganisationFeaturesComponent } from "./edit-organisation-features/edit-organisation-features.component";
import { IfAltoDirective } from "./if-alto.directive";
import { SelectFeaturesComponent } from "./select-features/select-features.component";

@NgModule({
    imports: [
        CommonModule,
        DxAccordionModule,
        DxCheckBoxModule,
    ],
    exports: [
        EditOrganisationFeaturesComponent,
        IfAltoDirective,
    ],
    declarations: [
        SelectFeaturesComponent,
        EditOrganisationFeaturesComponent,
        IfAltoDirective,
    ],
})
export class AdaptFeatureModule { }

import { Component, ElementRef, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { ItemStatus, ItemStatusMetadata } from "@common/ADAPT.Common.Model/organisation/item-status";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DxGridWrapperHelper } from "@common/ux/base.component/dx-component-wrapper-builder";
import { DateFormats } from "@common/ux/date-formats";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { ContentReadyEvent, InitializedEvent, SelectionChangedEvent } from "devextreme/ui/data_grid";

@Component({
    selector: "adapt-item-grid",
    templateUrl: "./item-grid.component.html",
    styleUrl: "./item-grid.component.scss",
})
export class ItemGridComponent extends BaseComponent implements OnChanges {
    public readonly ItemStatusMetadata = ItemStatusMetadata;
    public readonly dateFormat = DateFormats.globalize.shortdatetime;

    @Input() public items: Item[] = [];
    @Input() public allowSelection = false;
    @Input() public allowMultipleSelection = true;
    @Input() public allowHeaderActions = true;

    @Input() public selectedItems: Item[] = [];
    @Output() public selectedItemsChange = new EventEmitter<Item[]>();

    public dxGridWrapperHelper: DxGridWrapperHelper;

    public statusHeaderItems: { text: string, value: string }[] = [];
    public codeHeaderItems: { text: string, value: (string | ((item: Item) => string))[] }[] = [];

    constructor(
        elementRef: ElementRef,
        private orgService: OrganisationService,
    ) {
        super();
        this.dxGridWrapperHelper = new DxGridWrapperHelper("adapt-item-grid", jQuery(elementRef.nativeElement));
    }

    public ngOnChanges() {
        const inUseStatuses = this.items.reduce((statuses, item) => {
            if (!statuses.includes(item.status)) {
                statuses.push(item.status);
            }
            return statuses;
        }, [] as ItemStatus[]);

        this.statusHeaderItems = ItemStatusMetadata.All
            .filter((status) => inUseStatuses.includes(status.status))
            .map((status) => ({
                text: status.name,
                value: status.name,
            }));

        this.codeHeaderItems = ArrayUtilities.distinct(this.items.flatMap((item) => item.board))
            .filter((board) => !!board)
            .map((board) => ({
                text: `${board!.itemPrefix}: ${board!.name}`,
                value: [this.calculateCodeCellValue, "=", board!.itemPrefix],
            }));

        this.dxGridWrapperHelper.callGrid((g) => g.setColumnsReady());
    }

    public onGridInitialized(e: InitializedEvent) {
        this.dxGridWrapperHelper.saveGridState(`-${this.orgService.getOrganisationId()}-${this.allowSelection}`);

        this.dxGridWrapperHelper.initialiseGrid(e);

        // add reset if allowed
        if (this.allowHeaderActions) {
            e.component!.option().onToolbarPreparing = this.dxGridWrapperHelper.addResetToolbarButton;
        }
    }

    public onGridContentReady(e: ContentReadyEvent) {
        // set the width of the select column
        // see: https://supportcenter.devexpress.com/ticket/details/t154293/dxdatagrid-change-width-of-the-select-column
        e.component.columnOption("command:select", "width", 40);
        e.component.updateDimensions();
    }

    public onSelectionChanged(e: SelectionChangedEvent<Item>) {
        this.selectedItemsChange.emit(e.selectedRowsData);
    }

    public asItem(item?: Item) {
        if (item instanceof Item) {
            return item as Item;
        }

        return undefined;
    }

    public calculateStatusCellValue(item: Item) {
        return ItemStatusMetadata.ByStatus[item.status].name;
    }

    public calculateCodeCellValue(item: Item) {
        return item.board?.itemPrefix ?? "";
    }
}

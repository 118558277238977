import { Component, ElementRef, Input } from "@angular/core";
import { Survey, SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { SurveyResponse } from "@common/ADAPT.Common.Model/organisation/survey-response";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ChartUtils } from "@common/ux/base-ui.service/chart-utils";
import { IDxChartCustomizePoint, IDxChartTooltipPointInfo, IDxPieChartCustomizeText } from "@common/ux/dx.types";
import { DoneEvent } from "devextreme/viz/pie_chart";
import { ReplaySubject } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { SurveyService } from "../survey.service";
import { SurveyUtils } from "../survey-utils";

interface IChartData {
    label: string;
    color: string;
    count: number;
}

@Component({
    selector: "adapt-display-survey-category-chart",
    styleUrls: ["./display-survey-category-chart.component.scss"],
    templateUrl: "./display-survey-category-chart.component.html",
})
export class DisplaySurveyCategoryChartComponent extends BaseComponent {
    private survey$ = new ReplaySubject<Survey>(1);
    @Input() public set survey(value: Survey) {
        this.survey$.next(value);
    }
    @Input() public displayCompact = false;

    public chartData: IChartData[] = [];

    private totalResponses = 0;
    private surveyType = SurveyType.EmployeeEngagement;

    public constructor(
        elementRef: ElementRef,
        surveyService: SurveyService,
    ) {
        super(elementRef);

        this.survey$.pipe(
            switchMap((survey) => {
                this.surveyType = survey.surveyType;
                return surveyService.getSurveyResponsesByPredicate(new MethodologyPredicate<SurveyResponse>("surveyId", "==", survey.surveyId));
            }),
            tap((responses) => this.createChartData(responses)),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public updateChartDimensionAndSubscribeSizeChange(e: DoneEvent) {
        ChartUtils.updateChartDimension(e);
        this.sizeChange$.subscribe(() => ChartUtils.updateChartDimension(e));
    }

    @Autobind
    public customiseChartTooltip(info: IDxChartTooltipPointInfo) {
        const resultCategory = String(info.point.data.label).toLowerCase();
        return {
            text: SurveyUtils.forSurveyType(this.surveyType).getResultCategoryChartTooltipText(info.point.data.count, this.totalResponses, resultCategory),
        };
    }

    @Autobind
    public customiseLabel(e: IDxPieChartCustomizeText) {
        if (e.value === 0) {
            return ""; // this will hide the label according to https://supportcenter.devexpress.com/Ticket/Details/T804043/piechart-how-to-hide-labels-for-zero-values
        } else {
            const surveyUtils = SurveyUtils.forSurveyType(this.surveyType);
            const unit = e.value === 1 ? surveyUtils.singularParticipant : surveyUtils.pluralParticipant;

            return `${e.valueText} ${unit}`;
        }
    }

    public customiseChartPoint(point: IDxChartCustomizePoint) {
        if (point.data.color) {
            return { color: point.data.color };
        } else {
            return {};
        }
    }

    private createChartData(responses: SurveyResponse[]) {
        this.chartData = [];
        const categories = SurveyUtils.forSurveyType(this.surveyType).resultCategories;
        for (const category of categories) {
            this.chartData.push({
                label: category.name,
                color: category.color,
                count: 0,
            });
        }

        responses.forEach((response) => {
            const scorePercentage = response.percentageScore!;
            for (let i = 0; i < categories.length; i++) {
                const category = categories[i];
                if (scorePercentage >= category.percentageThreshold) {
                    this.chartData[i].count++;
                    break;
                }
            }
        });

        this.totalResponses = responses.length;
    }
}

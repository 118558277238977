import { ValueStream } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { Logger } from "@common/lib/logger/logger";
import { Zone, ZoneMetadata } from "../methodology/zone";

export interface IKeyFunctionLocation {
    zone: Zone | null;
    valueStreamId: number | null;
}

export interface IPrimedKeyFunctionLocation extends IKeyFunctionLocation {
    valueStream: ValueStream | null;
}

const log = Logger.getLogger("KeyFunctionLocation");

export class KeyFunctionLocation implements IPrimedKeyFunctionLocation {
    public static fromZone(zone: Zone) {
        return new KeyFunctionLocation(zone, null);
    }

    public static fromValueStream(valueStream: ValueStream) {
        return new KeyFunctionLocation(null, valueStream);
    }

    public static fromLocation(location: IPrimedKeyFunctionLocation) {
        if (location.valueStreamId && !location.valueStream) {
            throw new Error("Expected value stream to be primed on key function location");
        }
        if (!location.zone && !location.valueStreamId) {
            throw new Error("At least one location field must be set");
        }

        return new KeyFunctionLocation(location.zone, location.valueStream);
    }

    public static areEqual(a: IKeyFunctionLocation, b: IKeyFunctionLocation) {
        return a.zone === b.zone && a.valueStreamId === b.valueStreamId;
    }

    public static isValid(location: IKeyFunctionLocation) {
        // Zone XOR Value Stream should be set
        return !!location.zone !== !!location.valueStreamId;
    }

    public static compare(a: IPrimedKeyFunctionLocation, b: IPrimedKeyFunctionLocation) {
        if (a.valueStream && b.valueStream) {
            return a.valueStream.ordinal - b.valueStream.ordinal;
        } else if (a.valueStreamId && !b.valueStreamId) {
            return -1;
        } else if (!a.valueStreamId && b.valueStreamId) {
            return 1;
        } else if (a.zone && b.zone) {
            return ZoneMetadata.Ordinal[a.zone] - ZoneMetadata.Ordinal[b.zone];
        } else {
            // Unexpected - both a and b only have valueStreamId
            return 0;
        }
    }

    public static sortKey(a: IPrimedKeyFunctionLocation) {
        const valueStreamOrdinal = a.valueStream
            ? a.valueStream.ordinal
            : "9";                                                  // if no value stream, then push to the end
        const zoneOrdinal = a.zone
            ? ZoneMetadata.Ordinal[a.zone]
            : "9";                                                  // if no zone, then push to the end
        return `${valueStreamOrdinal}_${zoneOrdinal}`;
    }

    private constructor(
        public zone: Zone | null,
        public valueStream: ValueStream | null,
    ) { }

    public get valueStreamId() {
        return this.valueStream?.valueStreamId ?? null;
    }

    public equals(location: IKeyFunctionLocation) {
        return KeyFunctionLocation.areEqual(this, location);
    }

    public get isValid() {
        return KeyFunctionLocation.isValid(this);
    }

    public compare(location: IPrimedKeyFunctionLocation) {
        return KeyFunctionLocation.compare(this, location);
    }

    public get name() {
        if (this.zone) {
            return ZoneMetadata.Name[this.zone];
        } else if (this.valueStream) {
            return this.valueStream.name;
        } else {
            log.error("Key Function Location had no location set");
            return "Unknown Location";
        }
    }

    // When this is used as a DxDataSource group, we need this to be defined
    // for the grouping to actually group, and we use the sort key as DX sorts
    // the groups by the string representation.
    public toString() {
        return KeyFunctionLocation.sortKey(this);
    }
}

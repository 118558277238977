<div *adaptLoadingUntilFirstEmit="let categories of categories$">
    <ng-container *ngFor="let category of categories">
        <adapt-dashboard-element *ngIf="editMode || !category.isEmpty"
                                 name="{{category.category.label}}">
            <div adapt-dashboard-title-buttons
                 *ngIf="editMode">
                <button adaptButton="editBorderless"
                        [disabled]="disabled"
                        (click)="editProfileItem(category)"></button>
            </div>

            <ng-container *ngFor="let item of category.items">
                <div *ngIf="editMode || (item.value && !item.value.isEmpty)"
                     class="profile-category-item">
                    <h4>{{item.item.label}}
                        <small *ngIf="item.item.isPrivate"
                               class="fal fa-lock-alt"
                               adaptTooltip="This information is only visible to you and those with permission to view private profile data"></small>
                    </h4>

                    <div *ngIf="item.value && !item.value.isEmpty;else noValueSet">
                        <div *ngIf="item.value.text"
                             [froalaView]="item.value.text"></div>
                        <div *ngIf="item.value.date">{{item.value.date | adaptDate}}</div>
                    </div>

                    <ng-template #noValueSet>
                        <small>No value set</small>
                    </ng-template>
                </div>
            </ng-container>
        </adapt-dashboard-element>
    </ng-container>
</div>

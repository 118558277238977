<adapt-dialog [maxWidth]="700">
    <h3 adapt-dialog-title>Generate Activation URL</h3>
    <div adapt-dialog-content>
        <div *adaptLoading="hasConfirmedEmail === undefined">
            <ng-container [ngSwitch]="hasConfirmedEmail">
                <ng-container *ngSwitchCase="false">
                    <h3>Activation URL</h3>
                    <div class="d-flex form-group">
                        <dx-text-box #tokenInput
                                     class="flex-grow-1"
                                     [disabled]="!url"
                                     [value]="url ?? 'Loading...'"
                                     [readOnly]="true"
                                     data-test="activation-url"></dx-text-box>
                        <button type="button"
                                class="ms-2 btn border border-primary"
                                [disabled]="!url"
                                [ngClass]="{'btn-outline-primary': copiedValue, 'btn-primary': !copiedValue}"
                                [adaptTooltip]="copiedValue ? 'Copied' : 'Copy to clipboard'"
                                [adaptButtonIcon]="'fal fa-fw ' + (copiedValue ? 'fa-check' : 'fa-copy')"
                                (click)="copyToClipboard(url!)"></button>
                    </div>

                    <p>If a user is having trouble receiving the welcome email, this URL can be given to them to allow their account to be
                        activated.</p>
                </ng-container>
                <p *ngSwitchDefault>This user already has a confirmed email. You cannot generate an activation URL for them.</p>
            </ng-container>
        </div>
    </div>

    <div adapt-dialog-footer
         class="ms-auto">
        <button #focus
                class="btn btn-default"
                (click)="resolve(person)">OK
        </button>
    </div>
</adapt-dialog>

import { Component, ViewChild } from "@angular/core";
import { PersonFlag } from "@common/ADAPT.Common.Model/person/person-flag.enum";
import { RouteService } from "@common/route/route.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DismissibleCtaComponent } from "@org-common/lib/call-to-action/dismissible-cta/dismissible-cta.component";
import { coachAccessPageRoute } from "../../coach-access/coach-access-page/coach-access-page.component";

@Component({
    selector: "adapt-alto-coach-prompt-dashboard-element",
    templateUrl: "./alto-coach-prompt-dashboard-element.component.html",
    styles: [":host { display: contents }"],
})
export class AltoCoachPromptDashboardElementComponent extends BaseComponent {
    public readonly PersonFlag = PersonFlag;

    @ViewChild(DismissibleCtaComponent) public ctaComponent?: DismissibleCtaComponent;

    constructor(
        private routeService: RouteService,
    ) {
        super();
    }

    public gotoCoachRequestPage() {
        this.routeService.gotoRoute(coachAccessPageRoute);
    }
}

import { Component } from "@angular/core";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { OrganisationHierarchyNavigationService } from "../../navigation/organisation-hierarchy-navigation.service";
import { PersonNavigationService } from "../../navigation/person-navigation.service";
import { TeamHierarchyNavigationService } from "../../navigation/team-hierarchy-navigation.service";

@Component({
    selector: "adapt-menu-tab-content",
    styleUrls: ["./menu-tab-content.component.scss"],
    templateUrl: "./menu-tab-content.component.html",
})
export class MenuTabContentComponent extends BaseComponent {
    public readonly organisationHierarchyId = OrganisationHierarchyNavigationService.HierarchyId;
    public readonly personHierarchyId = PersonNavigationService.HierarchyId;
    public readonly teamHierarchyId = TeamHierarchyNavigationService.HierarchyId;

    public constructor() {
        super();
    }
}

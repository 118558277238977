<div *adaptLoading="!inputGroup"
     class="card"
     [id]="inputGroup?.name"
     [class.pulsing-element]="pulseElementName === inputGroup?.name">
    <div class="row card-body g-1">
        <div class="col-12 mb-3 mb-xl-0 col-xl d-flex flex-column">
            <h2>{{inputGroup!.name}}
                <button *adaptIfAuthorised="EditStrategicInputs"
                    adaptButton="editBorderless"
                    class="ms-auto me-2"
                    [attr.data-tour]="'edit-input'"
                    [adaptBlockingClick]="editInputGroup"></button></h2>
            <div [froalaView]="inputGroup?.description"></div>
        </div>
        <div class="col-12 col-xl-7 col-xxl-8">
            <div class="row g-3">
                <adapt-collapsible-dashboard-element *ngFor="let inputType of inputTypes"
                                                     class="col-12 col-lg-4 d-flex flex-column"
                                                     [name]="InputTypeMetadata[inputType].singularLabel"
                                                     [iconClass]="InputTypeMetadata[inputType].iconClass"
                                                     [iconTooltip]="InputTypeMetadata[inputType].tooltip"
                                                     [ngClass]="inputType.toLowerCase()"
                                                     [collapsed]="!inputsOfType.get(inputType)?.length"
                                                     [isCollapsible]="false">
                    <button *adaptIfAuthorised="EditStrategicInputs"
                            adapt-collapsible-header-action-right
                            adaptButton="addBorderless"
                            [attr.data-tour]="'add-input-' + inputType.toLowerCase()"
                            [adaptBlockingClick]="createInputOfType"
                            [adaptBlockingClickParam]="inputType"></button>
                    <ng-container *ngIf="inputsOfType.get(inputType) as inputs">
                        <adapt-strategic-input *ngFor="let input of inputs; let first = first"
                                            [input]="input"
                                            [isCollapsible]="true"
                                            [isEditing]="true"></adapt-strategic-input>
                    </ng-container>
                </adapt-collapsible-dashboard-element>
            </div>
        </div>
    </div>
</div>

import { DefaultBackFinishButtons, DefaultBackNextButtons, DefaultNextButtons, GuidedTour } from "@common/lib/guided-tour/guided-tour.service";
import { CommonTourSteps } from "./common-tour-steps";

export const VisionStatementTour: GuidedTour = {
    steps: [CommonTourSteps.openOrgMenuToShowSidebarNavNodeStep("Vision answers", "Purpose & Vision"), {
        waitForAndAttachToElementSelector: "adapt-display-link-hierarchy adapt-display-navigation-node span",
        elementSelectorTextContent: "Purpose & Vision", // looking for <adapt-display-navigation-node> with span content of this text
        advanceOnAttachedElementEvent: "click",
        attachTo: {
            on: "bottom-end",
        },
        title: `Access purpose & vision`,
        text: `<p>Your organisation's vision statement answers are available here.</p>
        <p><b>Click on it now</b> to show it.</p>`,
        modalOverlayOpeningPadding: 3,
    }, {
        title: "See vision statement answers",
        text: "Here are the answers you just provided.",
        canClickTarget: false,
        waitForAndAttachToElementSelector: `[data-tour="vision-statement-card"]`,
        attachTo: {
            on: "bottom",
        },
        buttons: DefaultNextButtons,
    }, {
        title: "Edit vision statement answers",
        text: "In the future, you can edit your vision statement answers using this option, which will run you through the pathway again.",
        canClickTarget: false,
        waitForAndAttachToElementSelector: `[data-tour="vision-statement-edit"]`,
        attachTo: {
            on: "bottom",
        },
        buttons: DefaultBackNextButtons,
    }, {
        title: "Continue",
        text: `<p>You can continue your journey by going to your <b>Personal Dashboard</b>.</p>`,
        waitForAndAttachToElementSelector: `[id="sidebar-personal"]`,
        attachTo: {
            on: "right",
        },
        buttons: DefaultBackFinishButtons,
    }],
};

import { Component, Inject, Injector, Input, Optional, ViewChild } from "@angular/core";
import { UserType } from "@common/ADAPT.Common.Model/embed/user-type";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { RoleTypeCode } from "@common/ADAPT.Common.Model/organisation/role-type-code";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { TEAM_DASHBOARD_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { UserService } from "@common/user/user.service";
import { IDxListItemDeletedEvent } from "@common/ux/dx.types";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { ConfigurationAuthService } from "@org-common/lib/configuration/configuration-auth.service";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { SelectPersonComponent } from "@org-common/lib/directory-shared/select-person/select-person.component";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";
import { ADD_PEOPLE_ACTION, AddPeopleAction } from "@org-common/lib/user-management/manage-people-page/manage-people-page.component";
import { Subscription } from "rxjs";
import { debounceTime, switchMap } from "rxjs/operators";

@Component({
    selector: "adapt-configure-team-membership",
    templateUrl: "./configure-team-membership.component.html",
})
export class ConfigureTeamMembershipComponent extends ConfigureFeatureBase {
    public readonly allowedLeaderUserTypes = [UserType.Collaborator, UserType.Leader, UserType.Coach];
    public readonly ConfigureOrganisation = ConfigurationAuthService.ConfigureOrganisation;

    @Input() public team!: Team;
    @Input() public configItem!: IConfigItem;

    private allTeamMembers: RoleConnection[] = [];
    public teamMembers: RoleConnection[] = [];
    public newMember?: Person;
    public teamLeaderConnection?: Connection;
    public warnAboutTeamLeaderChange = false;

    public teamLeaderLabel$ = this.teamsService.getTeamLeaderLabel$();
    public teamMemberLabel$ = this.teamsService.getTeamMemberLabelPlural$();

    @ViewChild("selectTeamMember") public selectTeamMemberComponent?: SelectPersonComponent;

    private updateDetails?: Subscription;

    public constructor(
        configurationService: ConfigurationService,
        private injector: Injector,
        private teamsService: CommonTeamsService,
        private userService: UserService,
        private authService: AuthorisationService,
        private rxjsBreezeService: RxjsBreezeService,
        private directorySharedService: DirectorySharedService,
        @Inject(TEAM_DASHBOARD_PAGE) private teamDashboardPageRoute: IAdaptRoute<{ teamId: number }>,
        @Optional() @Inject(ADD_PEOPLE_ACTION) public configureAddPeopleAction?: AddPeopleAction,
    ) {
        super(configurationService);
    }

    public get isActive() {
        return this.team && this.team.isActive();
    }

    @Autobind
    public async initialiseData() {
        if (!this.updateDetails) {
            // update the team members when role connections update
            this.updateDetails = this.rxjsBreezeService.entityTypeChanged(RoleConnection).pipe(
                debounceTime(100),
                switchMap(() => this.initialiseData()),
                this.takeUntilDestroyed(),
            ).subscribe();
        }

        this.allTeamMembers = await this.teamsService.promiseToGetTeamMemberRoleConnections(this.team, true);
        this.teamMembers = this.allTeamMembers.filter((roleConnection: RoleConnection) =>
            roleConnection.role?.roleType!.code !== RoleTypeCode.TeamLeader);

        const teamLeaderRoleConnection = await this.teamsService.promiseToGetTeamLeaderRoleConnection(this.team);
        this.teamLeaderConnection = teamLeaderRoleConnection ? teamLeaderRoleConnection.connection : undefined;
        this.warnAboutTeamLeaderChange = false;

        this.resetPersonSelectors();
    }

    @Autobind
    public filterAvailable(person: Person) {
        return this.allTeamMembers
            && !this.allTeamMembers.find((roleConnection) => roleConnection.connection.person === person);
    }

    @Autobind
    public async onTeamLeaderPersonChange(person?: Person) {
        if (!person) {
            return;
        }

        const oldTeamLeaderConnection = this.teamLeaderConnection;
        const newTeamLeaderConnection = person.getLatestConnection();

        if (oldTeamLeaderConnection === newTeamLeaderConnection || !newTeamLeaderConnection) {
            return;
        }

        this.team.teamLeaderPerson = newTeamLeaderConnection.person;
        await this.teamsService.promiseToUpdateRoleConnectionsForTeamLeaderChange(this.team, newTeamLeaderConnection, oldTeamLeaderConnection);
        // team leader becomes member not shown even after save without this
        await this.initialiseData();

        const canConfigureAllTeams = await this.authService.promiseToGetHasAccess(CommonTeamsAuthService.ConfigureTeam);
        const currentPerson = await this.userService.getCurrentPerson();
        this.warnAboutTeamLeaderChange = !canConfigureAllTeams && (this.team.teamLeaderPerson !== currentPerson);
    }

    @Autobind
    public async onTeamMemberPersonChange(person?: Person) {
        if (!person) {
            return;
        }

        const personConnection = person.getLatestConnection();

        if (personConnection) {
            const roleConnection = await this.teamsService.promiseToAddTeamMember(this.team, personConnection, personConnection.userType);
            this.teamMembers.push(roleConnection);
            this.allTeamMembers.push(roleConnection);

            this.resetPersonSelectors();
        }
    }

    public async removeMember(e: IDxListItemDeletedEvent<RoleConnection>) {
        this.teamsService.removeTeamMember(e.itemData!);
        await this.initialiseData();
    }

    public async addPeople() {
        await this.configureAddPeopleAction?.(this.injector, { teamId: this.team.teamId });

        // update all people so we get the added people
        await this.directorySharedService.promiseToGetAllPeople(true);
        await this.initialiseData();
    }

    public onSave() {
        if (this.warnAboutTeamLeaderChange) {
            setTimeout(() => this.teamDashboardPageRoute.gotoRoute({ teamId: this.team.teamId }).subscribe());
        }

        return super.onSave();
    }

    private resetPersonSelectors() {
        this.newMember = undefined;
        this.selectTeamMemberComponent?.reset();
    }
}

<dx-pie-chart [dataSource]="chartData"
              [animation]="false"
              type="doughnut"
              centerTemplate="centerTemplate"
              [customizePoint]="customiseChartPoint"
              [startAngle]="90"
              [innerRadius]="0.65">
    <dxi-series argumentField="category"
                valueField="percentage">
        <dxo-label [visible]="false"></dxo-label>
    </dxi-series>
    <dxo-size [height]="140"
              [width]="140"></dxo-size>
    <dxo-legend [visible]="false"></dxo-legend>
    <svg *dxTemplate="let pieChart of 'centerTemplate'">
        <circle cx="50"
                cy="50"
                [attr.r]="pieChart.getInnerRadius() - 4"
                fill="#eee"></circle>
        <text text-anchor="middle"
              x="50"
              y="50"
              fill="#494949">
            <tspan x="50"
                   style="font-size: 28px">{{percentage.toFixed(0)}}%</tspan>
            <tspan x="50"
                   dy="20px"
                   style="font-size: 14px">{{text}}</tspan>
        </text>
    </svg>
</dx-pie-chart>

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptIconComponent } from "./icon.component";

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        AdaptIconComponent,
    ],
    declarations: [
        AdaptIconComponent,
    ],
})
export class AdaptIconModule { }

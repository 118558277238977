<adapt-collapsible-search-row #row
                              [result]="result"
                              [matches]="matches"
                              [href$]="href$">
    <div class="d-flex flex-grow-1 align-items-center justify-content-between">
        <div class="d-flex flex-grow-0 me-2 align-items-center text-muted">
            <div class="d-flex align-items-center result-icon"
                 adaptTooltip="Purpose & vision">
                <i [ngClass]="icon"
                   class="fa-inverse"></i>
            </div>

            <div>
                <ng-container *ngIf="result.teamId; else orgText">
                    <adapt-link-team class="ms-2 me-2"
                                     [labelLinkDisabled]="true"
                                     [teamId]="result.teamId"></adapt-link-team>&gt;
                </ng-container>
                <ng-template #orgText>
                    <!-- only org-wide p&v exist in alto -->
                    <ng-container *adaptIfAlto="false">
                        <span class="ms-2 me-2">Organisation</span>&gt;
                    </ng-container>
                </ng-template>
            </div>
        </div>

        <div class="flex-grow-1 text-break d-flex align-items-center">{{title}}</div>
    </div>
</adapt-collapsible-search-row>

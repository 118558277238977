import { Component, Inject } from "@angular/core";
import { Account } from "@common/ADAPT.Common.Model/account/account";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { ICoachRequest, PaymentProcessingService } from "@common/payment-processing/payment-processing.service";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { EMPTY, finalize } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { ICoachOption } from "../coach-option.interface";

export interface ICoachAccessPaymentDialogData {
    account: Account;
    coachRequest: ICoachRequest,
    coachOption: ICoachOption,
}

@Component({
    selector: "adapt-coach-access-payment-dialog",
    templateUrl: "./coach-access-payment-dialog.component.html",
})
export class CoachAccessPaymentDialogComponent extends BaseDialogComponent<ICoachAccessPaymentDialogData, number> {
    public readonly dialogName = "CoachAccessPaymentDialog";

    public canRequestCoach = false;
    public submitting = false;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public data: ICoachAccessPaymentDialogData,
        private paymentProcessingService: PaymentProcessingService,
    ) {
        super();
    }

    @Autobind
    public requestCoach() {
        this.submitting = true;
        return this.paymentProcessingService.requestCoach(this.data.account.organisationId, this.data.coachRequest).pipe(
            tap((coachSessionId) => this.resolve(coachSessionId)),
            catchError((e) => {
                this.setErrorMessage(ErrorHandlingUtilities.getHttpResponseMessage(e));
                return EMPTY;
            }),
            finalize(() => this.submitting = false),
        );
    }
}

import { Component, Inject } from "@angular/core";
import { Account } from "@common/ADAPT.Common.Model/account/account";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { PaymentProcessingService } from "@common/payment-processing/payment-processing.service";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { AccountService } from "@org-common/lib/organisation/account/account.service";
import { EMPTY } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";

@Component({
    template: `
        <adapt-dialog [maxWidth]="600">
            <h3 adapt-dialog-title>Confirm Commencement of Subscription</h3>
            <ng-container adapt-dialog-content>
                <p>Proceeding will commence a subscription to the ADAPT Platform for this organisation.

                <p>The first charge will be on the next day at 10am Australian Western Standard Time
                and payments will be {{account.extensions.isBilledUsingCreditCard ? 'charged to the credit card on file' : 'manually invoiced'}}.
                </p>

                Are you sure you want to continue?
            </ng-container>
            <div adapt-dialog-footer
            class="ms-auto">
                <button adaptButton="ok"
                        [adaptBlockingClick]="commenceSubscription"></button>
                <button adaptButton="cancel"
                        (click)="cancel()"></button>
            </div>
        </adapt-dialog>
    `,
})
export class ConfirmCommenceSubscriptionDialogComponent extends BaseDialogComponent<Account> {
    public readonly dialogName = "ConfirmCommenceSubscription";

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public account: Account,
        private paymentProcessingService: PaymentProcessingService,
        private accountService: AccountService,
    ) {
        super();
    }

    @Autobind
    public commenceSubscription() {
        return this.paymentProcessingService.commenceSubscription(this.account.organisationId).pipe(
            switchMap(() => this.accountService.forceUpdateAccount(this.account)),
            tap(() => this.resolve(this.account)),
            catchError((e) => {
                this.setErrorMessage(ErrorHandlingUtilities.getHttpResponseMessage(e));
                return EMPTY;
            }),
        );
    }
}

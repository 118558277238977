import { Component, Input, ViewChild } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { PersonFlag } from "@common/ADAPT.Common.Model/person/person-flag.enum";
import { GuidedTourService } from "@common/lib/guided-tour/guided-tour.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DismissibleCtaComponent } from "@org-common/lib/call-to-action/dismissible-cta/dismissible-cta.component";
import { AltoShellTour, AltoShellTourButton } from "../../tours/alto-shell-tour";

@Component({
    selector: "adapt-alto-shell-tour-prompt-dashboard-element",
    templateUrl: "./alto-shell-tour-prompt-dashboard-element.component.html",
    styles: [":host { display: contents }"],
})
export class AltoShellTourPromptDashboardElementComponent extends BaseComponent {
    public readonly PersonFlag = PersonFlag;

    @Input() public person!: Person;

    @ViewChild(DismissibleCtaComponent) public ctaComponent?: DismissibleCtaComponent;

    constructor(private guidedTourService: GuidedTourService) {
        super();
    }

    public runTour() {
        this.guidedTourService.run(AltoShellTour);
        this.ctaComponent?.dismiss(true);
    }

    public onDismiss(triggeredByRun: boolean) {
        if (!triggeredByRun) {
            this.guidedTourService.run(AltoShellTourButton);
        }
    }
}

import { Component, Injector } from "@angular/core";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { BehaviorSubject, of, switchMap } from "rxjs";
import { EditStrategicGoalBase } from "../edit-strategic-goal-base/edit-strategic-goal-base";

@WorkflowStepComponent("adapt-edit-goal-review")
@Component({
    selector: "adapt-edit-goal-review",
    templateUrl: "./edit-goal-review.component.html",
    styleUrls: ["./edit-goal-review.component.scss"],
})
export class EditGoalReviewComponent extends EditStrategicGoalBase {
    public workflowGoToStep = new BehaviorSubject<number | undefined>(undefined);

    public constructor(injector: Injector) {
        super(injector);
    }

    public workflowStepOnInit() {
        super.workflowStepOnInit();
        this.workflowStepCompleted.next(!!this.goal?.extensions.isComplete);
    }

    public workflowStepNext() {
        const updateAction = this.goal!.entityAspect.entityState.isAdded() ||
            Object.prototype.hasOwnProperty.call(this.goal!.entityAspect.originalValues, "zone") ||
            Object.prototype.hasOwnProperty.call(this.goal!.entityAspect.originalValues, "themeId")
            ? this.strategicGoalsService.updateGoalOrdinal(this.goal!)
            : of([]);
        return updateAction.pipe(
            switchMap(() => this.strategicGoalsService.save()),
        );
    }

    public navigateToStep(stepIndex: number) {
        this.workflowGoToStep.next(stepIndex);
    }
}

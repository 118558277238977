import { BrowserAuthOptions } from "@azure/msal-browser/dist/config/Configuration";
import { PopupRequest } from "@azure/msal-browser/dist/request/PopupRequest";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { AuthCodeMSALBrowserAuthenticationProviderOptions } from "@microsoft/microsoft-graph-client/src/authentication/msalOptions/MSALAuthenticationProviderOptions";

export const OAuthSettings: BrowserAuthOptions & Partial<AuthCodeMSALBrowserAuthenticationProviderOptions> & PopupRequest = {
    clientId: AdaptClientConfiguration.MicrosoftClientId,
    authority: "https://login.microsoftonline.com/organizations/",
    redirectUri: `${window.location.origin}/blank.html`,
    scopes: ["offline_access", "openid", "profile", "email", "user.read", "calendars.read", "calendars.readwrite.shared", "place.read.all"],
    navigateToLoginRequestUrl: false,
};

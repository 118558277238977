<ng-container *ngIf="workflow">
    <div class="outcome-header {{style}}"
         [class.outcome-header-sm]="small">
        <div class="outcome-header-content">
            <h1>{{workflow.name}}</h1>

            <div class="workflow-info">
                <!-- hidden for now -->
                <div *ngIf="workflow.time && false">
                    <i class="far fa-timer me-2"></i>Time required: {{workflow.time}}
                </div>
                <div *ngIf="workflow.people">
                    <i class="far fa-user-group me-2"></i>People required: {{workflow.people}}
                </div>
                <div *ngIf="workflow.mindset">
                    <i class="far fa-head-side-brain me-2"></i>Mindset: {{workflow.mindset}}
                </div>
            </div>

            <div *ngIf="showStartButton"
                 [adaptTooltip]="startWorkflowButtonTooltip"
                 [adaptTooltipIsHtml]="true"
                 [tooltipEnabled]="isLocked || state === WorkflowActionState.Completed"
                 tooltipContentClasses="text-start">
                <button type="button"
                        [adaptBlockingClick]="startWorkflow"
                        [disabled]="isLocked"
                        [attr.data-test]="'workflow-page-outcome-action-' + workflow.workflowId"
                        class="btn btn-primary">
                    <i *ngIf="startWorkflowButtonIcon"
                       class="me-2"
                       [ngClass]="startWorkflowButtonIcon"></i>
                    {{startWorkflowButtonText}}
                </button>
            </div>
        </div>

        <img class="outcome-header-image"
             height="256"
             alt=""
             [src]="
             personImage" />
    </div>
</ng-container>

import { Injectable, Injector } from "@angular/core";
import { Value, ValueBreezeModel } from "@common/ADAPT.Common.Model/organisation/value";
import { ValueQuestion, ValueQuestionBreezeModel } from "@common/ADAPT.Common.Model/organisation/value-question";
import { ValuesConstitution, ValuesConstitutionBreezeModel } from "@common/ADAPT.Common.Model/organisation/values-constitution";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { of, Subject } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";
import { BaseOrganisationService } from "../organisation/base-organisation.service";

@Injectable({
    providedIn: "root",
})
@Autobind
export class ValuesConstitutionService extends BaseOrganisationService {
    public readonly valueAdded$ = new Subject<Value>();

    public constructor(
        injector: Injector,
        private rxjsBreezeService: RxjsBreezeService,
    ) {
        super(injector);
    }

    public getValuesConstitution(teamId: number | null = null) {
        // adding this so that organisation values don't end up getting the last team values
        const teamPredicate = new MethodologyPredicate<ValuesConstitution>("teamId", "==", teamId);

        return this.commonDataService.getTopByPredicate(
            ValuesConstitutionBreezeModel,
            1,
            teamPredicate,
        ).pipe(
            map(ArrayUtilities.getSingleFromArray),
        );
    }

    /** Delete the value, adjusting other values' ordinals */
    public deleteValue(value: Value) {
        const ordinal = value.ordinal;
        const vc = value.valuesConstitution;

        return this.commonDataService.remove(value).pipe(
            tap(() => SortUtilities.updateIntegerSortedArrayAfterItemRemoval(vc.values, ValueBreezeModel.sortField as keyof Value, ordinal)),
        );
    }

    /** Delete the value question, adjusting other questions' ordinals. */
    public deleteValueQuestion(valueQuestion: ValueQuestion) {
        const ordinal = valueQuestion.ordinal;
        const value = valueQuestion.value;

        return this.commonDataService.remove(valueQuestion).pipe(
            tap(() => SortUtilities.updateIntegerSortedArrayAfterItemRemoval(value.valueQuestions, ValueQuestionBreezeModel.sortField as keyof ValueQuestion, ordinal)),
        );
    }


    /**
     * Creates a hot observable that will emit each time the organisation's values,
     * or team's if teamId is specified, is updated. If the VC is deleted, then this will emit 'undefined'
     */
    public valuesConstitutionUpdate(teamId: number | null = null) {
        return this.rxjsBreezeService.entityTypeChanged(ValuesConstitution).pipe(
            filter((vc) => vc.teamId === teamId),
            map((vc) => vc.entityAspect.entityState.isUnchanged() ? vc : undefined),
        );
    }

    /** Creates a hot observable which will emit each time a value for the specified values is updated */
    public valueUpdate(valuesConstitution: ValuesConstitution) {
        return this.rxjsBreezeService.entityChangeCommitted$.pipe(
            filter(ObjectUtilities.createIsInstanceFilter(Value)),
            filter((value) => value.valuesConstitutionId === valuesConstitution.valuesConstitutionId),
        );
    }

    public getOrCreateValuesConstitution(teamId?: number) {
        return this.getValuesConstitution(teamId).pipe(
            switchMap((vc) => vc
                ? of(vc)
                : this.createValuesConstitution(teamId)),
        );
    }

    private createValuesConstitution(teamId?: number) {
        return this.currentOrganisation$.pipe(
            switchMap((org) => this.commonDataService.create(ValuesConstitutionBreezeModel, {
                organisation: org,
                teamId,
            })),
        );
    }

    public createValue(valuesConstitution: ValuesConstitution) {
        return this.commonDataService.create(ValueBreezeModel, {
            valuesConstitutionId: valuesConstitution.valuesConstitutionId,
            ordinal: valuesConstitution.values.length,
        });
    }

    public createQuestionInValue(value: Value) {
        return this.commonDataService.create(ValueQuestionBreezeModel, {
            valueId: value.valueId,
            ordinal: value.valueQuestions.length,
        });
    }
}

import { Component, Input, OnChanges } from "@angular/core";

@Component({
  selector: "adapt-schedule-meeting-display-availability",
  templateUrl: "./schedule-meeting-display-availability.component.html",
})
export class ScheduleMeetingDisplayAvailabilityComponent implements OnChanges {
    @Input() public loading = false;
    @Input() public names: string[] = [];
    @Input() public available?: boolean;
    @Input() public message?: string;

    public text = "";

    public ngOnChanges() {
        let text = this.names.join(", ") + " ";
        if (this.message) {
            this.text = text + this.message;
        } else {
            text += this.names.length > 1 ? "are" : "is";
            text += this.available ? "" : " not";
            this.text = `${text} available`;
        }
   }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptFeatureModule } from "@common/feature/feature.module";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptSeeMoreModule } from "@common/ux/see-more/see-more.module";
import { AdaptStyledDashboardElementModule } from "@common/ux/styled-dashboard-element/styled-dashboard-element.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AdaptCallToActionModule } from "@org-common/lib/call-to-action/call-to-action.module";
import { DxPopupModule, DxScrollViewModule, DxTabPanelModule } from "devextreme-angular";
import { CommonPurposeVisionAuthService } from "./common-purpose-vision-auth.service";
import { PurposeVisionPartDashboardElementComponent } from "./purpose-vision-part-dashboard-element/purpose-vision-part-dashboard-element.component";

@NgModule({
    imports: [
        CommonModule,
        AdaptButtonModule,
        AdaptShellModule,
        AdaptHtmlEditorModule,
        AdaptCommonDialogModule,
        AdaptLoadingSpinnerModule,
        AdaptDateModule,
        AdaptSeeMoreModule,
        AdaptTooltipModule,
        DxTabPanelModule,
        DxPopupModule,
        DxScrollViewModule,
        AdaptCallToActionModule,
        AdaptDashboardElementModule,
        AdaptFeatureModule,
        AdaptStyledDashboardElementModule,
    ],
    declarations: [
        PurposeVisionPartDashboardElementComponent,
    ],
    exports: [
        PurposeVisionPartDashboardElementComponent,
    ],
})
export class AdaptCommonPurposeVisionModule {
    constructor(authorisationService: AuthorisationService) {
        CommonPurposeVisionAuthService.registerAccess(authorisationService);
    }
}

import { getMethodTransformer } from "@common/lib/autobind.decorator/get-method-transformer";
import { BaseInitialisationService } from "@common/service/base-initialisation.service";

export const AfterInitialisationObservable: <T>(target: T, key: PropertyKey, descriptor: PropertyDescriptor) => PropertyDescriptor
    = getMethodTransformer(<T>(fn: (...args: any) => any, thisValue: T) => {
        // @ts-ignore ignore protected
        return (thisValue as BaseInitialisationService).wrapAfterInitialisation(() => fn.bind(thisValue));
    });

export const AfterInitialisationAsync: <T>(target: T, key: PropertyKey, descriptor: PropertyDescriptor) => PropertyDescriptor
    = getMethodTransformer(<T>(fn: (...args: any) => any, thisValue: T) => {
        // @ts-ignore ignore protected
        return (thisValue as BaseInitialisationService).promiseAfterInitialisation(fn.bind(thisValue));
    });

import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseConnectionState } from "./base-connection-state";
import { ConnectedState } from "./connected-state";
import { ConnectionEvent } from "./connection-event.enum";
import { ConnectionInterruptedState } from "./connection-interrupted-state";
import { IConnectionState } from "./connection-state.interface";
import { SignalRConnectionContext } from "./signalr-connection-context";
import Timeout = NodeJS.Timeout;

export class ReconnectingState extends BaseConnectionState {
    // TODO Exponential Back off?
    public static readonly ReconnectIntervalMs = 10000;

    public readonly event = ConnectionEvent.Reconnecting;

    // TODO: replace with non ajs alternative
    private timeout?: Timeout;
    private userDisconnected = false;

    public constructor(context: SignalRConnectionContext, previousState: IConnectionState) {
        super(context);
        this.promiseToReconnectAfterTimeout();

        const msg = `Disconnected from SignalR endpoint ${this.signalREndpoint}. `
            + `Attempting reconnection after ${ReconnectingState.ReconnectIntervalMs}ms`;

        if (previousState instanceof ConnectionInterruptedState) {
            this.log.warn(msg);
        } else {
            this.context.resetConnectionPromise(msg);
            this.context.raiseConnectionEvent(ConnectionEvent.Lost);
        }
    }

    public disconnect(): void {
        this.userDisconnected = true;
        if (this.timeout !== undefined) {
            clearTimeout(this.timeout);
        }
    }

    private promiseToReconnectAfterTimeout() {
        this.timeout = setTimeout(this.promiseToReconnect, ReconnectingState.ReconnectIntervalMs);
    }

    @Autobind
    private promiseToReconnect(): Promise<void> {
        return this.context.promiseToStartConnection()
            .then(() => {
                this.context.state = new ConnectedState(this.context, this);
            })
            .catch(() => {
                if (this.userDisconnected) {
                    return;
                }

                this.promiseToReconnectAfterTimeout();
            });
    }
}

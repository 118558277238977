import { DefaultBackFinishButtons, DefaultBackNextButtons, DefaultNextButtons, DefaultProceedButtons, GuidedTour } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourUtils } from "@common/lib/guided-tour/guided-tour.utils";
import { CommonTourSteps } from "./common-tour-steps";

export const StrategicAnchorsWrapUpTour: GuidedTour = {
    steps: [{
        title: "Introduction",
        text: "This tour will walk you through what you have just defined in your strategic anchors pathway.",
        buttons: DefaultProceedButtons,
    }, CommonTourSteps.openOrgMenuToShowSidebarNavNodeStep("Your strategic anchors", "Strategic Anchors"), {
        waitForAndAttachToElementSelector: "adapt-display-link-hierarchy adapt-display-navigation-node span",
        elementSelectorTextContent: "Strategic Anchors",
        advanceOnAttachedElementEvent: "click",
        attachTo: {
            on: "bottom-end",
        },
        title: "Access strategic anchors",
        text: `<p>Your organisation's strategic anchors are available here.</p>
        <p><b>Click on it now</b> to show it.</p>`,
        modalOverlayOpeningPadding: 3,
    }, {
        title: "Your strategic anchors",
        text: `<p>This is where your organisation's strategic anchors are recorded.</p>`,
        buttons: DefaultNextButtons,
    }, {
        title: "Strategic anchor",
        waitForAndAttachToElementSelector: "adapt-strategic-anchor",
        attachTo: {
            on: "bottom",
        },
        canClickTarget: false,
        text: "This is one of the strategic anchors that you have defined for your organisation.",
        showOn: () => {
            const strategicAnchorNode = GuidedTourUtils.getFirstElementWithTextContent("adapt-strategic-anchor");
            return GuidedTourUtils.isElementVisible(strategicAnchorNode);
        },
        buttons: DefaultBackNextButtons,
    }, {
        title: "Edit strategic anchor",
        text: `<p>Use this button to edit or delete the strategic anchor.</p>`,
        canClickTarget: false,
        waitForAndAttachToElementSelector: `[data-tour="edit-anchor-button"]`,
        attachTo: {
            on: "bottom",
        },
        showOn: () => {
            // this step will only be shown if you have an anchor
            const editAnchorButton = GuidedTourUtils.getFirstElementWithTextContent(`[data-tour="edit-anchor-button"]`);
            return GuidedTourUtils.isElementVisible(editAnchorButton);
        },
        buttons: DefaultBackNextButtons,
    }, {
        title: "Adding a new anchor",
        text: `<p>To add an anchor, use the <b>'Add Anchor'</b> from the toolbar here.</p>`,
        waitForAndAttachToElementSelector: `[data-tour="add-anchor-button"]`,
        attachTo: {
            on: "bottom",
        },
        modalOverlayOpeningPadding: 3,
        canClickTarget: false,
        buttons: DefaultBackNextButtons,
    }, {
        title: "Reordering anchors",
        text: `<p>Reorder your strategic anchors to an order that makes sense for your organisation.</p>`,
        waitForAndAttachToElementSelector: `[data-tour="reorder-anchors-button"]`,
        attachTo: {
            on: "bottom",
        },
        canClickTarget: false,
        modalOverlayOpeningPadding: 3,
        showOn: () => {
            const cancelButton = GuidedTourUtils.getFirstElementWithTextContent(`[data-tour="reorder-anchors-button"]`);
            return GuidedTourUtils.isElementVisible(cancelButton);
        },
        buttons: DefaultBackNextButtons,
    }, {
        title: "Continue",
        text: `<p>You can continue your journey by going to your <b>Personal Dashboard</b>.</p>`,
        waitForAndAttachToElementSelector: `[id="sidebar-personal"]`,
        attachTo: {
            on: "right",
        },
        canClickTarget: false,
        buttons: DefaultBackFinishButtons,
    }],
};

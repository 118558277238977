import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DxCheckBoxModule, DxPopoverModule } from "devextreme-angular";
import { AdaptDateModule } from "../date/date.module";
import { AdaptTooltipModule } from "../tooltip.directive/tooltip.module";
import { TimeSchedulerComponent } from "./time-scheduler/time-scheduler.component";
import { TimeSchedulerWrapperComponent } from "./time-scheduler-wrapper/time-scheduler-wrapper.component";


@NgModule({
    imports: [
        CommonModule,
        DxPopoverModule,
        DxCheckBoxModule,
        AdaptDateModule,
        AdaptTooltipModule,
    ],
    declarations: [
        TimeSchedulerComponent,
        TimeSchedulerWrapperComponent,
    ],
    exports: [
        TimeSchedulerComponent,
        TimeSchedulerWrapperComponent,
    ],
})
export class AdaptTimeSchedulerModule { }

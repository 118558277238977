import { AfterViewChecked, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { ShellUiService } from "@common/shell/shell-ui.service";
import { Subject } from "rxjs";
import { BaseComponent } from "../base.component/base.component";

@Component({
    selector: "adapt-two-column-layout",
    templateUrl: "./two-column-layout.component.html",
    styleUrls: ["./two-column-layout.component.scss"],
})
export class TwoColumnLayoutComponent extends BaseComponent implements OnChanges, OnInit, OnDestroy, AfterViewChecked {
    @Input() public fullPage = false;
    @Input() public useFullPageColumns = false;
    @Input() public removeColumnPadding = false;
    @Input() public leftColumnWidthClasses = "col-12 col-lg-5 col-xl-4";
    @Input() public rightColumnWidthClasses = "col-12 col-lg-7 col-xl-8";

    @Input() public whiteBackground = false;

    @ViewChild("leftColumn", { read: ElementRef }) public leftColumn?: ElementRef<HTMLElement>;
    public leftColumnIsEmpty = false;
    @ViewChild("rightColumn", { read: ElementRef }) public rightColumn?: ElementRef<HTMLElement>;
    public rightColumnIsEmpty = false;

    public leftColumnClasses = this.leftColumnWidthClasses;
    public rightColumnClasses = this.rightColumnWidthClasses;

    private triggerColumnCheck: Subject<void>;

    public constructor(
        private shellUiService: ShellUiService,
    ) {
        super();

        // prevent ExpressionChangedAfterItHasBeenCheckedError - changing value used by template in AfterViewChecked will trigger it.
        // Also it is not necessary to call jquery check for empty every digest cycle -> .1 second should be enough
        this.triggerColumnCheck = this.createThrottledUpdater(() => {
            this.leftColumnIsEmpty = jQuery(this.leftColumn!.nativeElement).is(":empty");
            this.rightColumnIsEmpty = jQuery(this.rightColumn!.nativeElement).is(":empty");
        });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.whiteBackground || changes.leftColumnWidthClasses || changes.rightColumnWidthClasses) {
            this.leftColumnClasses = `${this.leftColumnWidthClasses} ${this.whiteBackground ? "white-background" : ""}`;
            this.rightColumnClasses = this.rightColumnWidthClasses;
        }

        if (changes.fullPage) {
            this.updateShellPadding();
        }
    }

    public ngOnInit() {
        this.updateShellPadding();

        // updateShellPadding each time rawContainer status changed (resolve issue with navigationEnd callback in common-shell container
        // overriding the shell padding override here).
        let isRawContainer: boolean | undefined;
        this.shellUiService.rawContainerChange$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((result) => {
            if (isRawContainer !== result) {
                isRawContainer = result;
                this.updateShellPadding();
            }
        });
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        this.shellUiService.resetToDefaultPadding();
    }

    public ngAfterViewChecked() {
        this.triggerColumnCheck.next();
    }

    private updateShellPadding() {
        if (this.fullPage) {
            this.shellUiService.removeDefaultShellPadding();
        } else {
            this.shellUiService.resetToDefaultPadding();
        }
    }
}

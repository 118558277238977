import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { KanbanPageComponent, KanbanPageSelector, WorkPageTitle } from "./kanban-page.component";

export const teamKanbanPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent(KanbanPageSelector, KanbanPageComponent)
    .atOrganisationUrl("/team/:teamId/actions")
    .redirectToThisRouteFromOrganisationUrl("/team/:teamId/work")
    .redirectToThisRouteFromOrganisationUrl("/people/teams/:teamId/work")
    .verifyingFeatures(FeatureName.StewardshipWorkKanban)
    .withTitle(WorkPageTitle)
    .withSearchKeyword("kanban")
    .withSearchKeyword("work")
    .reloadOnSearch(false)
    .build();

export const myKanbanPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent(KanbanPageSelector, KanbanPageComponent)
    .atOrganisationUrl("/people/actions")
    .redirectToThisRouteFromOrganisationUrl("/people/work")
    .verifyingFeatures(FeatureName.StewardshipWorkKanban)
    .withTitle(WorkPageTitle)
    .withSearchKeyword("kanban")
    .withSearchKeyword("work")
    .reloadOnSearch(false)
    .build();

export const personalKanbanPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent(KanbanPageSelector, KanbanPageComponent)
    .atOrganisationUrl("/people/:personId/actions")
    .redirectToThisRouteFromOrganisationUrl("/people/:personId/work")
    .verifyingFeatures(FeatureName.StewardshipWorkKanban)
    .withTitle(WorkPageTitle)
    .withSearchKeyword("kanban")
    .withSearchKeyword("work")
    .reloadOnSearch(false)
    .build();

import { Component, Input, OnDestroy } from "@angular/core";
import { PositionConfig } from "devextreme/animation/position";
import { BehaviorSubject, Subscription } from "rxjs";
import { IShellPopover, IShellPopoverLinkItem } from "./shell-popover-link-item";

export type ShellPopoverLocation = "bottom" | "right";

const locationToPosition: { [type in ShellPopoverLocation]: PositionConfig } = {
    bottom: {
        my: "top",
        at: "center",
    },
    right: {
        my: "left bottom",
        at: "right",
    },
};

@Component({
    selector: "adapt-shell-popover-link-item",
    templateUrl: "./shell-popover-link-item.component.html",
    styleUrls: ["./shell-popover-link-item.component.scss"],
})
export class ShellPopoverLinkItemComponent implements OnDestroy {
    @Input() public item?: IShellPopoverLinkItem;
    @Input() public popoverLocation: ShellPopoverLocation = "bottom";
    @Input() public isInternal = false;

    public popoverVisible$ = new BehaviorSubject(false);
    public target?: HTMLElement;

    private subscription?: Subscription;

    public ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public onPopoverContentRendered(instance: IShellPopover) {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }

        this.subscription = instance.isVisible$.subscribe(this.popoverVisible$);
    }

    public togglePopover(e: MouseEvent) {
        this.target = e.target as HTMLElement;
        this.popoverVisible$.next(!this.popoverVisible$.value);
    }

    public onPopoverContentReady(e: any) {
        const html: JQuery = e.component.content();
        html.css("padding", 0);
    }

    public get position() {
        return locationToPosition[this.popoverLocation];
    }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptLinkDisabledModule } from "@common/ux/link-disabled/link-disabled.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptMenuModule } from "@common/ux/menu/menu.module";
import { DxMenuModule, DxTagBoxModule } from "devextreme-angular";
import { DisplayLabelComponent } from "./display-label/display-label.component";
import { DisplayLabelLocationsComponent } from "./display-labels/display-labels.component";
import { RecentLabelSubmenuContentComponent } from "./recent-label-submenu-content/recent-label-submenu-content.component";
import { SelectLabelComponent } from "./select-label/select-label.component";
import { SelectLabelForRelatedEntityComponent } from "./select-label-for-related-entity/select-label-for-related-entity.component";

@NgModule({
    imports: [
        CommonModule,
        DxTagBoxModule,
        RouterModule,
        AdaptLinkDisabledModule,
        AdaptLoadingSpinnerModule,
        DxMenuModule,
        AdaptMenuModule,
    ],
    declarations: [
        SelectLabelComponent,
        DisplayLabelComponent,
        DisplayLabelLocationsComponent,
        SelectLabelForRelatedEntityComponent,
        RecentLabelSubmenuContentComponent,
    ],
    exports: [
        SelectLabelComponent,
        SelectLabelForRelatedEntityComponent,
        DisplayLabelComponent,
        DisplayLabelLocationsComponent,
        RecentLabelSubmenuContentComponent,
    ],
})
export class AdaptLabellingModule {
}

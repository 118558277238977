import { UserType } from "@common/ADAPT.Common.Model/embed/user-type";
import { Connection, ConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/connection";
import { BaseQueryUtilities } from "@common/lib/data/base-query-utilities";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { lastValueFrom } from "rxjs";
import { map } from "rxjs/operators";

export class PeopleQueryUtilities extends BaseQueryUtilities {
    public getAllPeople(forceRemote?: boolean) {
        return this.commonDataService.getAll(ConnectionBreezeModel, forceRemote).pipe(
            map((connections) => this.mapConnectionsToUniquePeople(connections)),
        );
    }

    /**
     * Promise to get all active people via connections to avoid leaking of connection data from other
     * organisations and ensure active connection checks work locally.
     * This also filters out any people with usertype = none, as they are not classed as active.
     * @param {boolean} forceRemote A boolean representation of whether to force a remote data call
     * @returns {Promise} A promise that resolves with an array of active people
     */
    public async promiseToGetActivePeople(forceRemote?: boolean) {
        let connections = await this.promiseToGetActiveConnections(forceRemote);
        connections = connections.filter((conn) => conn.userType !== UserType.None);
        return this.mapConnectionsToUniquePeople(connections);
    }

    public promiseToGetActiveConnections(forceRemote?: boolean) {
        return lastValueFrom(this.commonDataService.getActive(ConnectionBreezeModel, forceRemote));
    }

    private mapConnectionsToUniquePeople(connections: Connection[]) {
        // as a person may have multiple connections we must first map the connections to people, then remove any duplicate people
        const nonUniqueArray = connections.map((connection: Connection) => connection.person);
        return ArrayUtilities.distinct(nonUniqueArray);
    }
}

<adapt-dialog [maxWidth]="800"
              [padding]="false">
    <h3 adapt-dialog-title>Reorder objectives</h3>
    <div adapt-dialog-content
         class="h-100 m-2">
        <div class="alert alert-info">
            <p>You can rearrange the objectives by dragging the handle to the right of each item.</p>
            Please note:
            <ul>
                <li>Closed objectives and their supporting objectives cannot be reordered and will not be displayed here.</li>
                <li *ngIf="!data.teamId">Team objectives can be reordered on the team page.</li>
            </ul>
        </div>
        <adapt-sortable-objective-groups [objectiveGroups]="data.objectiveGroups"
                                         [teamId]="data.teamId"
                                         (objectivesReordered)="onObjectivesChanged($event)"></adapt-sortable-objective-groups>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [discardConfirmationInstance]="instance">
    </adapt-standard-dialog-buttons>
</adapt-dialog>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Optional } from "@angular/core";
import { FunctionUtilities } from "@common/lib/utilities/function-utilities";
import { RouteService } from "@common/route/route.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { combineLatest, finalize, Subject } from "rxjs";
import { Autobind } from "../../lib/autobind.decorator/autobind.decorator";
import { ShellUiService } from "../../shell/shell-ui.service";
import { IAdaptMenuItem } from "../../ux/menu/menu.component";
import { IShellPopover } from "../shell-popover-link-item/shell-popover-link-item";
import { IUserMenuItem, USER_MENU_ITEMS } from "./user-menu-item";

@Component({
    selector: "adapt-application-bar-user-item-content",
    templateUrl: "./application-bar-user-item-content.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationBarUserItemContentComponent extends BaseComponent implements IShellPopover {
    public isVisible$ = new Subject<boolean>();

    public profileMenuItems: IAdaptMenuItem[] = [];

    public constructor(
        @Inject(USER_MENU_ITEMS) @Optional() profileMenuItems: IUserMenuItem[] | null,
        private shellUiService: ShellUiService,
        private changeDetectorRef: ChangeDetectorRef,
        routeService: RouteService,
    ) {
        super();
        if (profileMenuItems) {
            this.profileMenuItems = profileMenuItems
                .map((item) => {
                    item.visible = false;
                    // only do navigation handler if onClick is not defined
                    if (!FunctionUtilities.isFunction(item.onClick)) {
                        // item.href$ is a hot observable, which will emit when url changes, e.g. org changes. single subscribe here
                        // which will change where item.onClick navigate to
                        let destUrl = "/";
                        combineLatest([item.href$, item.isShown$]).pipe(
                            // this component will be destroyed when you logout - do this or the number of subscription will increase each logout/login
                            this.takeUntilDestroyed(),
                            finalize(() => item.onClick = undefined), // clear this when component is destroyed or it won't resubscribe since onClick is defined
                        ).subscribe(([url, isShown]) => {
                            destUrl = url;
                            item.visible = isShown;
                            this.changeDetectorRef.markForCheck();
                        });

                        // onClick has to be defined once off here or dxMenuItem won't pick it up automatically
                        item.onClick = async () => {
                            await routeService.navigateByUrl(destUrl);
                            this.hidePopover();
                        };
                    } else {
                        // this is when onClick is defined just check for when this will be shown or not
                        item.isShown$.pipe(
                            this.takeUntilDestroyed(),
                        ).subscribe((isShown) => {
                            item.visible = isShown;
                            this.changeDetectorRef.markForCheck();
                        });
                    }
                    return item;
                });
        }

        this.profileMenuItems.push({
            text: "Logout",
            icon: "fal fa-sign-out",
            visible: true,
            onClick: this.promiseToLogout,
        });
    }

    @Autobind
    public async promiseToLogout() {
        this.hidePopover();
        await this.shellUiService.promiseToLogout();
    }

    public hidePopover() {
        this.isVisible$.next(false);
    }
}

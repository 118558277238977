import { Component, Inject, Injector } from "@angular/core";
import { UserType, UserTypeExtensions } from "@common/ADAPT.Common.Model/embed/user-type";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { CulturalRelationship } from "@common/ADAPT.Common.Model/organisation/cultural-relationship";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleConnection, RoleConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { RoleTypeCode } from "@common/ADAPT.Common.Model/organisation/role-type-code";
import { Team, TeamBreezeModel } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { lastValueFrom } from "rxjs";
import { CulturalLeadershipQueryUtilities } from "../../cultural-leadership/cultural-leadership-query-utilities";
import { CommonTeamsService } from "../../teams/common-teams.service";
import { UserManagementService } from "../user-management.service";

export interface IConvertUserTypeDialogData {
    userType: UserType;
    connection: Connection;
}

@Component({
    selector: "adapt-convert-user-type-dialog",
    templateUrl: "./convert-user-type-dialog.component.html",
    styleUrls: ["./convert-user-type-dialog.component.scss"],
})
export class ConvertUserTypeDialogComponent extends BaseDialogWithDiscardConfirmationComponent<IConvertUserTypeDialogData, Connection> {
    public readonly dialogName = "ConvertUserType";
    public readonly UserTypeExtensions = UserTypeExtensions;
    public readonly UserType = UserType;

    public title = "";
    public confirmed?: boolean | null = false; // making this optional as dx-select-box value being optional - won't be able to use [()] without it
    public selectedAccessLevel?: Role;
    private roleConnection?: RoleConnection;

    public changeToNone: boolean;
    public isTier1Leader = false;
    public priceDecrease: boolean;
    public changingToFullAccess: boolean;
    public noCulturalLeadershipFrameworkAccess: boolean;
    public entitiesToConfirm: IBreezeEntity[] = [];
    public existingUserType: UserType;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public dialogData: IConvertUserTypeDialogData,
        private userManagementService: UserManagementService,
        private commonTeamsService: CommonTeamsService,
        injector: Injector,
    ) {
        super(injector);

        this.title = `Change user type and access for ${this.dialogData.connection.person.fullName}`;
        this.existingUserType = this.dialogData.connection.userType;
        this.changingToFullAccess = dialogData.userType === UserType.Leader;
        this.priceDecrease = UserTypeExtensions.priceOrdinal(this.dialogData.userType) > UserTypeExtensions.priceOrdinal(this.existingUserType);
        this.changeToNone = this.dialogData.userType === UserType.None;
        this.noCulturalLeadershipFrameworkAccess = dialogData.userType === UserType.Viewer || (this.changeToNone && this.existingUserType !== UserType.Viewer);
        this.updateChangedEntities();
        this.isInitialised = true;
    }

    @Autobind
    public roleFilter(role: Role) {
        if (this.dialogData.userType === UserType.Leader) {
            return role.extensions.isLeaderAccessRole();
        } else if (this.dialogData.userType === UserType.Collaborator) {
            return role.extensions.isCollaboratorAccessRole();
        } else {
            throw new Error("User type not set");
        }
    }

    public get isValid() {
        if ((this.dialogData.userType === UserType.Leader) || (this.dialogData.userType === UserType.Collaborator)) {
            return !!this.selectedAccessLevel;
        }

        return true;
    }

    @Autobind
    public onAccessLevelChange() {
        this.roleConnection!.role = this.selectedAccessLevel!;
    }

    private async updateChangedEntities() {
        this.autoResolveData = this.dialogData.connection;
        this.dialogData.connection.userType = this.dialogData.userType;

        // remove existing connections with access
        const roleConnectionsWithAccessThatArentTeamRoles = [...this.dialogData.connection.roleConnections
            .filter((roleConnection) => roleConnection.role?.extensions.hasAccessPermissions() && !roleConnection.role?.extensions.isCulturalLeaderRole())
            .filter((roleConnection) => !roleConnection?.role?.teamId)];
        const removedRoleConnections = await Promise.all(roleConnectionsWithAccessThatArentTeamRoles.map((roleConnection) =>
            lastValueFrom(this.commonDataService.remove(roleConnection)) as Promise<RoleConnection>));

        this.entitiesToConfirm = [
            this.dialogData.connection,
            ...this.dialogData.connection.roleConnections,
            ...removedRoleConnections,
        ];

        // add new role connection for collaborator, viewer & leader user types
        if (this.dialogData.userType === UserType.None) {
            this.dialogData.connection.hasAccess = false;

            // remove person from team leader person field
            const changedTeams = await lastValueFrom(this.commonDataService.getByPredicate(TeamBreezeModel,
                new MethodologyPredicate<Team>("teamLeaderPersonId", "==", this.dialogData.connection.personId)));
            if (changedTeams.length > 0) {
                changedTeams.forEach((team) => team.teamLeaderPersonId = undefined);
                this.entitiesToConfirm.push(...changedTeams);
            }

            // remove all team roles
            const teamRoleConnections = this.dialogData.connection.roleConnections
                .filter((roleConnection) => !!roleConnection?.teamId);
            await Promise.all(teamRoleConnections.map((roleConnection) => lastValueFrom(this.commonDataService.remove(roleConnection))));

            // cleanup tier1 role
            const tier1RoleConnections = this.dialogData.connection.roleConnections
                .filter((roleConnection) => roleConnection.role?.extensions.isTier1Role());
            this.isTier1Leader = tier1RoleConnections.length > 0;
            if (this.isTier1Leader) {
                await Promise.all(tier1RoleConnections.map((roleConnection) => lastValueFrom(this.commonDataService.remove(roleConnection))));
            }
        } else {
            const roleConnectionData: Partial<RoleConnection> = {
                connection: this.dialogData.connection,
                startDate: this.dialogData.connection.startDate,
            };

            roleConnectionData.role = await this.userManagementService.promiseToGetDefaultAccessLevel(this.dialogData.userType);
            this.selectedAccessLevel = roleConnectionData.role;

            this.roleConnection = await lastValueFrom(this.commonDataService.create<RoleConnection>(RoleConnectionBreezeModel, roleConnectionData));
            this.entitiesToConfirm.push(this.roleConnection);

            await this.cleanupTeamMemberships();
        }

        if (this.dialogData.userType !== UserType.Leader) {
            // only leaders can have connection to tier1 role
            await Promise.all(this.dialogData.connection.roleConnections
                .filter((rc) => rc.role?.roleType?.code === RoleTypeCode.Tier1)
                .map((rc) => lastValueFrom(this.commonDataService.remove(rc))));
        }

        await this.cleanupCulturalRelationships();
    }

    private async cleanupTeamMemberships() {
        // if converting to or from participant, will need to toggle active team member/participant
        let targetRoleTypeCode: RoleTypeCode | undefined;
        if (this.existingUserType === UserType.Viewer) {
            // team participant to team member
            targetRoleTypeCode = RoleTypeCode.TeamMember;
        } else if (this.dialogData.userType === UserType.Viewer) {
            // team member to team participant
            targetRoleTypeCode = RoleTypeCode.TeamParticipant;
        }

        // Don't have to add to entitiesToConfirm again as connection.roleConnections are already added
        if (targetRoleTypeCode) {
            let roleConnectionFilter: (rc: RoleConnection) => boolean;
            if (targetRoleTypeCode === RoleTypeCode.TeamParticipant) {
                // if changing to team participant, can be from a team leader or team member
                roleConnectionFilter = (rc: RoleConnection) => rc.isActive() && !!rc.teamId &&
                    (rc.role?.roleType?.code === RoleTypeCode.TeamMember || rc.role?.roleType?.code === RoleTypeCode.TeamLeader);
            } else {
                // if changing from team participant, will only convert to team member (participant is team member)
                roleConnectionFilter = (rc: RoleConnection) => rc.isActive() && !!rc.teamId &&
                    rc.role?.roleType?.code === RoleTypeCode.TeamParticipant;
            }

            const toggledTeamConnections = this.dialogData.connection.roleConnections.filter(roleConnectionFilter);
            if (toggledTeamConnections.length > 0) {
                for (const rc of toggledTeamConnections) {
                    // each team will have a different member/participant role
                    const targetRole = await this.commonTeamsService.promiseToGetTeamRoleByRoleTypeCode(rc.teamId!, targetRoleTypeCode);
                    rc.role = targetRole!;
                }

                // remove person from team leader person field
                const leaderTeams = await lastValueFrom(this.commonDataService.getByPredicate(TeamBreezeModel,
                    new MethodologyPredicate<Team>("teamLeaderPersonId", "==", this.dialogData.connection.personId)));
                if (leaderTeams.length > 0) {
                    leaderTeams.forEach((team) => team.teamLeaderPersonId = undefined);
                    this.entitiesToConfirm.push(...leaderTeams);
                }
            }
        }
    }

    private async cleanupCulturalRelationships() {
        if ((this.existingUserType === UserType.Leader || this.existingUserType === UserType.Collaborator) &&
            this.dialogData.userType === UserType.Viewer) {
            // Don't have to worry about none as that's only for stakeholder and stakeholder won't have CL relationships.
            // Only handle case from leader/collab to participant
            const culturalLeadershipQueryUtilities = new CulturalLeadershipQueryUtilities(this.commonDataService);
            // if one of the role connections is connected to a leader role -> end cultural cohort
            const activeCLRoleConnections = this.dialogData.connection.roleConnections.filter(
                (i) => i.isActive() && i.role?.extensions.isCulturalLeaderRole());
            let changedCulturalRelationships: CulturalRelationship[] = [];
            if (activeCLRoleConnections.length) {
                changedCulturalRelationships = await culturalLeadershipQueryUtilities.promiseToGetCulturalRelationshipsForCulturalLeaderId(this.dialogData.connection.personId);
            }

            // this includes both leader and non-leader relationships
            // - delete them instead of ending as we do not keep history
            await Promise.all(changedCulturalRelationships.map((relationship) => lastValueFrom(this.commonDataService.remove(relationship))));

            // remove cl activeRoleConnection
            await Promise.all(activeCLRoleConnections.map((rc) => lastValueFrom(this.commonDataService.remove(rc))));

            // just need to add culturalRelationships as activeCLRoleConnections were already added from connection.roleConnections
            this.entitiesToConfirm.push(...changedCulturalRelationships);
        }
    }
}

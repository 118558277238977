import { Inject, Injectable } from "@angular/core";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { ItemStatus } from "@common/ADAPT.Common.Model/organisation/item-status";
import { PERSONAL_DASHBOARD_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { UserService } from "@common/user/user.service";
import { switchMap } from "rxjs/operators";
import { itemPageRoute } from "./items/item-page/item-page.route";
import { KanbanService } from "./kanban.service";
import { myKanbanPageRoute, personalKanbanPageRoute, teamKanbanPageRoute } from "./kanban-page/kanban-page.route";

@Injectable({
    providedIn: "root",
})
export class KanbanNavigationService {
    constructor(
        private kanbanService: KanbanService,
        private userService: UserService,
        @Inject(PERSONAL_DASHBOARD_PAGE) private personalDashboardPageRoute: IAdaptRoute<{}>,
    ) { }

    public getItemUrl(item: Item) {
        // we want to get a 'permalink' for the item so the link works even if the item has changed teams or the original team was deleted
        return itemPageRoute.getRoute(item);
    }

    public gotoItemPage(item: Item, openDialog = true) {
        const searchParams = {
            item: item.itemId,
            openDialog: String(openDialog),
            showBacklog: String(item.status === ItemStatus.Backlog),
        };

        // not assuming that item board is already primed
        return this.kanbanService.getBoardById(item.boardId).pipe(
            switchMap((board) => {
                if (!board) {
                    return this.personalDashboardPageRoute.gotoRoute();
                }

                if (board.teamId) {
                    return teamKanbanPageRoute.gotoRoute({ teamId: board.teamId }, searchParams);
                } else if (this.userService.getCurrentPersonId() === board.personId) {
                    return myKanbanPageRoute.gotoRoute(undefined, searchParams);
                } else {
                    return personalKanbanPageRoute.gotoRoute({ personId: board.personId }, searchParams);
                }
            }),
        );
    }
}

import { Component, Input, OnDestroy } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { Observable, of, ReplaySubject } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { IObjectiveTeamGroup, ObjectivesService } from "../objectives.service";
import { ObjectivesRouteService } from "../objectives-route.service";

@Component({
    selector: "adapt-personal-objectives-dashboard-element",
    templateUrl: "./personal-objectives-dashboard-element.component.html",
    styleUrls: ["./personal-objectives-dashboard-element.component.scss"],
})
export class PersonalObjectivesDashboardElementComponent implements OnDestroy {
    @Input()
    public set person(value: Person | undefined) {
        this.person$.next(value);
    }

    public personalObjectivesGroups$: Observable<IObjectiveTeamGroup[]>;
    public organisationHref$ = this.objectivesRouteService.getObjectivePageRoute();

    private person$ = new ReplaySubject<Person | undefined>(1);

    public constructor(
        public organisationService: OrganisationService,
        private objectivesRouteService: ObjectivesRouteService,
        objectivesService: ObjectivesService,
        labellingService: LabellingService,
    ) {
        this.personalObjectivesGroups$ = this.person$.pipe(
            switchMap((person) => person ? objectivesService.getInProgressObjectivesForPerson(person) : of([])),
            switchMap((objectives) => labellingService.primeLabelLocationsForObjectives(objectives).pipe(
                map(() => objectives),
            )),
            map((objectives) => objectivesService.groupObjectivesByTeam(objectives)),
        );
    }

    public ngOnDestroy() {
        this.person$.complete();
    }
}

import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { KeyFunction } from "./key-function";
import { Role } from "./role";

export class KeyFunctionRole extends BaseEntity<KeyFunctionRole> {
    public keyFunctionRoleId!: number;
    public keyFunctionId!: number;
    public roleId!: number;
    public ordinal!: number;

    public keyFunction!: KeyFunction | null;
    public role!: Role | null;
}

export const KeyFunctionRoleBreezeModel = new BreezeModelBuilder("KeyFunctionRole", KeyFunctionRole)
    .hasSource()
    .isOrganisationEntity()
    .build();

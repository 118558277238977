import { DOCUMENT, ViewportScroller } from "@angular/common";
import { Component, Inject, Input, OnInit } from "@angular/core";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { lastValueFrom } from "rxjs";
import { IThemeTestPage, THEME_TEST_PAGES } from "../theme-test-page";

@Component({
    selector: "adapt-theme-test-container",
    templateUrl: "./theme-test-container.component.html",
    styleUrls: ["./theme-test-container.component.scss"],
})
export class ThemeTestContainerComponent implements OnInit {
    @Input() public label?: string;

    public pageLinks: {url: string; title: string, sections?: IThemeTestPage["sections"]}[] = [];

    public constructor(
        @Inject(THEME_TEST_PAGES) public pages: IThemeTestPage[],
        public viewportScroller: ViewportScroller,
        @Inject(DOCUMENT) private document: Document,
    ) {}

    public isCurrentPage(url: string) {
        return window.location.toString().includes(url);
    }

    public scrollToSection(sectionId: string) {
        this.document.getElementById(sectionId)?.scrollIntoView();
    }

    public async ngOnInit() {
        this.pageLinks = await Promise.all(this.pages.map(async (p) => {
            const route = await lastValueFrom(p.route.getRoute());
            return ({ url: route, title: p.title, sections: p.sections });
        }));

        this.pageLinks = this.pageLinks.sort(SortUtilities.getSortByFieldFunction("title"));
    }
}

import { PricingModel } from "./pricing-model";

export class PricingModelExtensions {

    public constructor(private pricingModel: PricingModel) { }

    public get isFree() {
        return this.pricingModel.monthlyFeeDollars === 0
            && this.pricingModel.pricingModelUsers.length === 0;
    }
}

<dx-select-box [dataSource]="userTypes"
               [(value)]="userType"
               itemTemplate="itemTemplate"
               fieldTemplate="fieldTemplate"
               [isValid]="!!userType"
               (valueChange)="userTypeChange.emit($event)">
    <div *dxTemplate="let item of 'fieldTemplate'"
         class="m-2">
        <dx-text-box class="d-none"
                     value="hidden element required by dxTemplate"
                     [readOnly]="true"></dx-text-box>
        {{UserTypeExtensions.singularLabel(item)}}
    </div>
    <div *dxTemplate="let item of 'itemTemplate'">
        {{UserTypeExtensions.singularLabel(item)}}
    </div>
</dx-select-box>

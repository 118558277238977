<adapt-theme-test-container label="Components"
                            *ngIf="isActivated">
    <div class="section"
         id="zones">
        <h1>4 Zones</h1>

        <h4>Map</h4>
        <adapt-render-zone-map></adapt-render-zone-map>

        <h4>Labels</h4>
        <div>
            <adapt-zone-label class="mb-1"
                              [zone]="zone1"></adapt-zone-label>
            <adapt-zone-label class="mb-1"
                              [zone]="zone2"></adapt-zone-label>
            <adapt-zone-label class="mb-1"
                              [zone]="zone3"></adapt-zone-label>
            <adapt-zone-label class="mb-1"
                              [zone]="zone4"></adapt-zone-label>
            <adapt-zone-label class="mb-1"
                              [zone]="zone5"></adapt-zone-label>
        </div>
    </div>

    <div class="section"
         id="surveys">
        <h1>Surveys</h1>

        <h4>Survey Result</h4>
        <adapt-display-survey-result [survey]="survey"></adapt-display-survey-result>

        <h4>Multi-Group Survey Result</h4>
        <adapt-display-multi-group-survey-result [survey]="survey"
                                                 [displayCompact]="true"></adapt-display-multi-group-survey-result>

        <h4>Personal Ongoing Survey Card</h4>
        <adapt-personal-ongoing-survey [outstandingSurveyResponse]="outstandingSurveyResponse"></adapt-personal-ongoing-survey>
    </div>

    <div class="section"
         id="meetings">
        <h1>Meetings</h1>

        <h4>Meeting Dashboard Card</h4>
        <adapt-meeting-dashboard-card [meeting]="ongoingMeeting"
                                      [showTeamName]="true"></adapt-meeting-dashboard-card>

        <h4>Meeting Side Bar</h4>
        TODO
    </div>

    <div class="section"
         id="key-functions">
        <h1>Key Functions</h1>

        <h4>Card</h4>
        <adapt-key-function-card [keyFunction]="keyFunction"></adapt-key-function-card>

        <h4>Link</h4>
        <adapt-link-key-function [keyFunction]="keyFunction"></adapt-link-key-function>
    </div>

    <div class="section"
         id="links">
        <h1>Links</h1>

        <div class="mb-1">
            <adapt-link-team [team]="team"
                             [showIcon]="true"></adapt-link-team>
        </div>

        <h4>Person Links With Various Sizing</h4>
        <div class="row flex-nowrap align-items-center mb-1">
            <div class="col-1">XS</div>
            <adapt-link-person imageSize="xs"
                               [person]="person"
                               [showImage]="true"></adapt-link-person>
        </div>
        <div class="row flex-nowrap align-items-center mb-1">
            <div class="col-1">SM</div>
            <adapt-link-person imageSize="sm"
                               [person]="person"
                               [showImage]="true"></adapt-link-person>
        </div>
        <div class="row flex-nowrap align-items-center mb-1">
            <div class="col-1">MD</div>
            <adapt-link-person imageSize="md"
                               [person]="person"
                               [showImage]="true"></adapt-link-person>
        </div>
        <div class="row flex-nowrap align-items-center mb-1">
            <div class="col-1">LG</div>
            <adapt-link-person imageSize="lg"
                               [person]="person"
                               [showImage]="true"></adapt-link-person>
        </div>
    </div>

    <div class="section"
         id="objectives">
        <h1>Objectives</h1>

        <p>
            <adapt-display-tree-objective *ngFor="let annualObjective of annualObjectives"
                                          [objective]="annualObjective"
                                          class="mb-1"></adapt-display-tree-objective>
        </p>
        <p>
            <adapt-display-tree-objective [objective]="quarterlyObjective"></adapt-display-tree-objective>
        </p>

        <h4>Status Badges</h4>
        <div *ngFor="let status of objectiveStatus"
             class="mb-1">
            <adapt-display-objective-status [objectiveStatus]="status"></adapt-display-objective-status>
        </div>
    </div>

    <div class="section"
         id="kanban">
        <h1>Actions</h1>

        <div class="row mb-3">
            <div *ngFor="let kanbanItem of [item, personalItem]"
                 class="col">
                <h4>Kanban ({{kanbanItem.board?.isPersonalBoard ? 'Personal' : 'Team'}})</h4>
                <h5>Card</h5>
                <adapt-kanban-card [item]="kanbanItem"></adapt-kanban-card>
                <h5>Link</h5>
                <p>
                    <adapt-item-link [item]="kanbanItem"></adapt-item-link>
                </p>
                <h5>Link w/ Status</h5>
                <p>
                    <adapt-item-link [item]="kanbanItem"
                                     [showStatus]="true"></adapt-item-link>
                </p>
            </div>
        </div>

        <h4>Status Badges</h4>
        <div *ngFor="let statusItem of itemsByStatus"
             class="row flex-nowrap align-items-center">
            <div class="col-2 py-1">{{statusItem.status.name}}</div>
            <adapt-display-item-status class="col-3"
                                       [itemStatus]="statusItem.status.status"></adapt-display-item-status>
            <adapt-item-link [item]="statusItem.item"
                             [showStatus]="true"></adapt-item-link>
        </div>
    </div>

    <div class="section"
         id="labels">
        <h1>Labels</h1>

        <h4>Single</h4>
        <p>
            <adapt-display-label [label]="label"></adapt-display-label>
        </p>

        <h4>Multiple</h4>
        <adapt-display-labels [labels]="[label, label, label, label]"></adapt-display-labels>
    </div>

    <div class="section">
        <h1>Dismissable CTA</h1>

        <adapt-dismissible-cta>
            <h2 adapt-cta-title>Call-to-action Title</h2>

            <button adapt-cta-action
                    class="btn btn-primary px-4">
                Action button
                <i class="fal fa-fw fa-arrow-right ms-2"></i>
            </button>

            <div>Content for the dismissible call-to-action is projected here.</div>
        </adapt-dismissible-cta>
    </div>
</adapt-theme-test-container>

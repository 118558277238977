<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.InputsOverview"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<adapt-toolbar-content *adaptIfAuthorised="EditStrategicInputs"
                       alignment="right">
    <button adaptButton="add"
            [adaptBlockingClick]="addCompetitor"
            data-tour="add-competitor">competitor</button>
</adapt-toolbar-content>
<adapt-toolbar-content alignment="center">
    <div class="d-flex gap-2 align-items-center">
        <i class="fas fa-swords pt-2 fa-lg"></i>
        <h2 class="text-center">Our competitors</h2>
    </div>
</adapt-toolbar-content>
<ng-container *ngIf="this.canvas; else showAlert">
    <div *ngIf="competitors.length > 0; else showAlert">
        <adapt-input-group *ngFor="let competitor of competitors"
                           [inputGroup]="competitor"></adapt-input-group>
    </div>
</ng-container>

<ng-template #showAlert>
    <div class="alert alert-secondary mt-3">
        There are currently no competitors recorded.
        <ng-container *adaptIfAuthorised="EditStrategicInputs">
            Please use the add competitor button above to get started.
        </ng-container>
    </div>
</ng-template>

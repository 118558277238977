import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Connection } from "./connection";
import { Organisation } from "./organisation";
import { Team } from "./team";
import { WorkflowStatus } from "./workflow-status";

export class WorkflowConnection extends BaseEntity<WorkflowConnection> {
    public workflowConnectionId!: number;
    public organisationId!: number;
    public workflowId!: string;
    public teamId?: number;
    public connectionId?: number;

    // navigation
    public connection?: Connection;
    public team?: Team;
    public organisation!: Organisation;
    public statuses!: WorkflowStatus[];
}

export const WorkflowConnectionBreezeModel = new BreezeModelBuilder("WorkflowConnection", WorkflowConnection)
    .hasSource()
    .withIdField() // needed for entity sync
    .isOrganisationEntity()
    .alwaysFetchingNavigationProperty("statuses")
    .build();

<span [attr.id]="meetingAgendaTemplateIdAttr">{{meetingAgendaTemplate.name}}</span>

<!-- dxhover* events are better which will work for tablet too - pointerenter/end not working properly for tablet -->
<dx-popover [target]="'#' + meetingAgendaTemplateIdAttr"
            [showEvent]="{name: 'dxhoverstart', delay: 100}"
            [hideEvent]="{name: 'dxhoverend', delay: 250}"
            [(visible)]="popoverVisible"
            (onHiding)="onPopoverHiding($event)"
            [width]="250"
            height="auto"
            maxHeight="70vh">
    <div *dxTemplate="let data of 'content'"
         class="p-2"
         (pointerenter)="preventHide()"
         (pointerleave)="allowHide()"
         (pointerdown)="allowHide()">
        <div class="d-flex flex-column gap-2">
            @if (meetingAgendaTemplate.meetingDescription) {
                <div [froalaView]="meetingAgendaTemplate.meetingDescription"></div>
                <hr class="m-0" />
            }

            <div>
                <h4 class="mb-1">Agenda items</h4>
                <ul *adaptLoadingUntilFirstEmit="let items of agendaItems$"
                    class="list-content m-0">
                    @for (item of items; track item.meetingAgendaItemId) {
                        <li>{{item.name}}</li>
                    } @empty {
                        <li>No agenda items have been defined for this template.</li>
                    }
                </ul>
            </div>
        </div>
    </div>
</dx-popover>

import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { LoadingSpinnerComponent } from "./loading-spinner.component";

export interface ILoadingUntilFirstEmitContext<T> {
    $implicit: T;
}

@Directive({
    selector: "[adaptLoadingUntilFirstEmit]",
})
export class LoadingUntilFirstEmitDirective<T> implements OnDestroy {
    private subscription?: Subscription;
    private loadingSpinnerShown = false;

    public constructor(
        private templateRef: TemplateRef<ILoadingUntilFirstEmitContext<T>>,
        private viewContainer: ViewContainerRef,
    ) { }

    public static ngTemplateContextGuard<T>(
        _directive: LoadingUntilFirstEmitDirective<T>,
        _context: unknown,
    ): _context is ILoadingUntilFirstEmitContext<T> {
        return true;
    }

    @Input("adaptLoadingUntilFirstEmitOf")
    public set loadingObservable$(value$: Observable<T>) {
        // Unsubscribe in case of second observable set before first emits
        this.unsubscribe();

        if (!this.loadingSpinnerShown) {
            this.loadingSpinnerShown = true;
            this.viewContainer.clear();
            this.viewContainer.createComponent(LoadingSpinnerComponent);
        }

        if (!(value$ instanceof Observable)) {
            return;
        }

        this.subscription = value$.subscribe((v) => {
            this.loadingSpinnerShown = false;
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.templateRef, {
                $implicit: v,
            });
        });
    }

    public ngOnDestroy() {
        this.unsubscribe();
    }

    private unsubscribe() {
        if (this.subscription) {
            this.subscription.unsubscribe();
            delete this.subscription;
        }
    }
}


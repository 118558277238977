import { Component, Input } from "@angular/core";
import { Account } from "@common/ADAPT.Common.Model/account/account";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { PaymentProcessingService } from "@common/payment-processing/payment-processing.service";
import { ISimpleInvoice } from "@common/payment-processing/simple-invoice.interface";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { ConfigurationAuthService } from "@org-common/lib/configuration/configuration-auth.service";
import { AccountService } from "@org-common/lib/organisation/account/account.service";
import { Observable, of } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { ChangePaymentFrequencyDialogComponent } from "../change-payment-frequency-dialog/change-payment-frequency-dialog.component";

@Component({
    selector: "adapt-configure-subscription",
    templateUrl: "./configure-subscription.component.html",
})
export class ConfigureSubscriptionComponent extends ConfigureFeatureBase {
    public readonly AdaptProjectLabel = AdaptClientConfiguration.AdaptProjectLabel;

    @Input() public configItem?: IConfigItem;

    public account$: Observable<Account | undefined> = of(undefined);
    public invoice$: Observable<ISimpleInvoice | undefined> = of(undefined);
    public canChargeOrganisation$: Observable<boolean>;
    public billingPeriodLabel$: Observable<string> = of("");
    public error?: string;

    constructor(
        configurationService: ConfigurationService,
        authorisationService: AuthorisationService,
        public accountService: AccountService,
        private paymentProcessingService: PaymentProcessingService,
        private dialogService: AdaptCommonDialogService,
    ) {
        super(configurationService);
        this.canChargeOrganisation$ = authorisationService.getHasAccess(ConfigurationAuthService.ConfigureOrganisationBilling);
    }

    @Autobind
    public changePaymentFrequency() {
        return this.account$.pipe(
            emptyIfUndefinedOrNull(),
            take(1),
            switchMap((account) => this.dialogService.open(ChangePaymentFrequencyDialogComponent, account)),
        );
    }

    @Autobind
    public async initialiseData() {
        this.account$ = this.accountService.getAccount();
        this.billingPeriodLabel$ = this.account$.pipe(
            emptyIfUndefinedOrNull(),
            map((a) => a.extensions.billingMethodAndPeriodLabel),
            map((l) => l[0].toLocaleUpperCase() + l.substring(1)),
        );
        this.invoice$ = this.account$.pipe(
            emptyIfUndefinedOrNull(),
            switchMap((account) => {
                return this.paymentProcessingService.getNextInvoice(account.organisationId);
            }),
        );
    }
}

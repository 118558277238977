<div adaptToolbarContent
     adaptBlockingButtonGroup
     *ngIf="canCaptureSurvey$ | async">
    <button adaptButtonIcon="add"
            [adaptBlockingClick]="captureNewSurvey"
            data-test="add-survey-button">Add survey
    </button>
</div>

<adapt-two-column-page *ngIf="atLeastOneSurvey; else noSurveysTemplate">
    <div left-column
         class="d-flex flex-column">
        <dx-scroll-view>
            <div class="d-flex flex-column gap-3 me-2">
                <adapt-universal-dashboard-element name="Active survey"
                                                   class="active-surveys"
                                                   *ngIf="ongoingSurveys.length > 0"
                                                   data-test="active-surveys">
                    <div class="d-flex flex-column gap-3">
                        <div *ngFor="let survey of ongoingSurveys"
                             class="card ongoing-survey-card">
                            <adapt-display-survey class="w-100"
                                                  [canEdit]="canCaptureSurvey$ | async"
                                                  [survey]="survey"
                                                  data-test="survey-active"
                                                  (surveyChange)="updateData()"></adapt-display-survey>
                        </div>
                    </div>
                </adapt-universal-dashboard-element>
                <adapt-universal-dashboard-element name="Upcoming survey"
                                                   *ngIf="upcomingSurveys.length > 0"
                                                   data-test="upcoming-surveys">
                    <div class="d-flex flex-column gap-3">
                        <div *ngFor="let survey of upcomingSurveys"
                             class="card survey-result-card">
                            <adapt-display-survey [survey]="survey"
                                                  [canEdit]="canCaptureSurvey$ | async"
                                                  (surveyChange)="updateData()"
                                                  class="w-100"></adapt-display-survey>
                        </div>
                    </div>
                </adapt-universal-dashboard-element>
                <adapt-universal-dashboard-element name="Completed surveys"
                                                   *ngIf="previousSurveys.length > 0"
                                                   data-test="completed-surveys">
                    <div class="d-flex flex-column gap-3">
                        <adapt-display-survey-result *ngFor="let survey of previousSurveys"
                                                     [survey]="survey"
                                                     [allowRestore]="canCaptureSurvey$ | async"
                                                     (surveyDeleted)="updateData()"></adapt-display-survey-result>
                    </div>
                </adapt-universal-dashboard-element>
            </div>
        </dx-scroll-view>
    </div>

    <div right-column>
        <adapt-universal-dashboard-element name="{{surveyTypeLabel}} history">
            <ng-container adapt-dashboard-title-buttons>
                <a *ngIf="previousSurveys.length > 0"
                   [routerLink]="analysePageObj?.path"
                   [queryParams]="analysePageObj?.queryParams"
                   adaptButtonIcon="fal fa-fw fa-chart-bar"
                   class="btn"
                   data-test="analyse-survey-button">Analyse</a>
            </ng-container>
            <div class="chart-wrapper">
                <adapt-display-survey-history *ngIf="previousSurveys.length > 0; else noCompletedSurvey"
                                              [surveys]="previousSurveys">
                </adapt-display-survey-history>
            </div>
            <ng-template #noCompletedSurvey>
                There are no completed surveys
            </ng-template>
        </adapt-universal-dashboard-element>
    </div>
</adapt-two-column-page>

<ng-template #noSurveysTemplate>
    <adapt-call-to-action *ngIf="isInitialised"
                          [learnMoreUrl]="learnMoreUrl$ | async">
        <adapt-call-to-action-title>{{surveyTypeLabel}}</adapt-call-to-action-title>

        <ng-container [ngTemplateOutlet]="callToActionTextTemplate"></ng-container>

        <button *ngIf="canCaptureSurvey$ | async"
                adaptCallToActionButton
                adaptButtonIcon="add"
                [adaptBlockingClick]="captureNewSurvey">
            Add survey
        </button>
    </adapt-call-to-action>
</ng-template>

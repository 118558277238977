import { OrganisationCategoryValue, WorkflowDialogWidth, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { buildLocalWorkflow } from "@org-common/lib/workflow/workflow.interface";
import { WorkflowIdentifier } from "@org-common/lib/workflow/workflow-identifier.enum";

export const applyGoodStewardshipWorkflowName = "Apply good stewardship";

export const applyGoodStewardshipWorkflow = buildLocalWorkflow({
    name: applyGoodStewardshipWorkflowName,
    workflowId: WorkflowIdentifier.ApplyGoodStewardship,
    type: WorkflowType.Journey,
    dialogWidth: WorkflowDialogWidth.ExtraLarge,
    overview: "Learn how to apply good stewardship to your leadership team. This includes actions, accountabilities, meeting structure and good habits for your team.",
    category: OrganisationCategoryValue.EffectiveLeadershipTeam,
    articleSlug: ImplementationKitArticle.ComingSoon, // a coming soon HelpJuice document
    //    articleSlug: ImplementationKitArticle.ApplyGoodStewardshipOutcomes,
    wrapUpSlug: ImplementationKitArticle.ApplyGoodStewardshipWrapUp,
    ordinal: 10,
    //compulsoryPrerequisites: [WorkflowIdentifier.Purpose, WorkflowIdentifier.CraftVision],
    // workflows: [
    //     createActivityBriefWorkflow(applyGoodStewardshipWorkflowName, WorkflowIdentifier.ApplyGoodStewardship, undefined, ImplementationKitArticle.ApplyGoodStewardshipMeetingDescription, ImplementationKitArticle.ApplyGoodStewardshipMeetingPreWork),
    //     ...createWorkshopScheduleWorkflows(applyGoodStewardshipWorkflowName, WorkflowIdentifier.ApplyGoodStewardship),
    // ],
});

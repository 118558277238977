import { Injectable, Injector } from "@angular/core";
import { GuidingPhilosophy, GuidingPhilosophyBreezeModel } from "@common/ADAPT.Common.Model/organisation/guiding-philosophy";
import { Purpose, PurposeBreezeModel } from "@common/ADAPT.Common.Model/organisation/purpose";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { ValueStream } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { BaseOrganisationService } from "@org-common/lib/organisation/base-organisation.service";
import { lastValueFrom, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";

export enum SeedEntity {
    Vision,
    Purpose
}

export interface ISeedEntityConfig<T, TKey extends keyof T> {
    entity?: T;
    field: TKey;
}

export type SeedEntityConfigs =
    | ISeedEntityConfig<GuidingPhilosophy, "seeds">
    | ISeedEntityConfig<Purpose, "seeds">;

@Injectable({
    providedIn: "root",
})
export class CommonPurposeVisionService extends BaseOrganisationService {
    public constructor(
        injector: Injector,
    ) {
        super(injector);
    }

    public async getSeedEntity(entity: SeedEntity, create = false): Promise<SeedEntityConfigs> {
        switch (entity) {
            case SeedEntity.Purpose:
                const purposeEntity = await lastValueFrom(create ? this.getOrCreatePurpose() : this.getPurpose());
                return { entity: purposeEntity, field: "seeds" };
            case SeedEntity.Vision:
                const visionEntity = await lastValueFrom(create ? this.getOrCreateVision() : this.getVision());
                return { entity: visionEntity, field: "seeds" };
        }
    }

    public getVision() {
        return this.commonDataService.getAll(GuidingPhilosophyBreezeModel).pipe(
            map(ArrayUtilities.getSingleFromArray),
        );
    }

    public getOrCreateVision() {
        return this.getVision().pipe(
            switchMap((guidingPhilosophy) => guidingPhilosophy
                ? of(guidingPhilosophy)
                : this.createVision()),
        );
    }

    private createVision() {
        return this.currentOrganisation$.pipe(
            switchMap((organisation) => this.commonDataService.create(GuidingPhilosophyBreezeModel, { organisation })),
        );
    }

    public getPurpose(teamOrValueStream?: Team | ValueStream) {
        const teamId = teamOrValueStream instanceof Team ? teamOrValueStream.teamId : null;
        const valueStreamId = teamOrValueStream instanceof ValueStream ? teamOrValueStream.valueStreamId : null;
        const predicate = new MethodologyPredicate<Purpose>("teamId", "==", teamId)
            .and(new MethodologyPredicate<Purpose>("valueStreamId", "==", valueStreamId));

        return this.commonDataService.getTopByPredicate(PurposeBreezeModel, 1, predicate).pipe(
            map(ArrayUtilities.getSingleFromArray),
        );
    }

    public getOrCreatePurpose(teamOrVs?: Team | ValueStream) {
        return this.getPurpose(teamOrVs).pipe(
            switchMap((purpose) => purpose
                ? of(purpose)
                : this.createPurpose(teamOrVs)),
        );
    }

    private createPurpose(teamOrVs?: Team | ValueStream) {
        return this.currentOrganisation$.pipe(
            switchMap((org) => this.commonDataService.create(PurposeBreezeModel, {
                organisation: org,
                teamId: teamOrVs instanceof Team ? teamOrVs.teamId : undefined,
                valueStreamId: teamOrVs instanceof ValueStream ? teamOrVs.valueStreamId : undefined,
            })),
        );
    }
}

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { QueuedCaller } from "@common/lib/queued-caller/queued-caller";
import { DefaultTimeSchedulerPeriods, ICycle, IItem, IItemEvent, IPeriod, ISection, TimeSchedulerPeriodYearPlusHalf } from "@common/ux/time-scheduler/time-scheduler.interface";
import { TimeSchedulerComponent } from "@common/ux/time-scheduler/time-scheduler/time-scheduler.component";
import { ContentReadyEvent } from "devextreme/ui/check_box";
import moment from "moment";

@Component({
    selector: "adapt-time-scheduler-wrapper[items][sections]",
    templateUrl: "./time-scheduler-wrapper.component.html",
    styleUrls: ["./time-scheduler-wrapper.component.scss"],
})
export class TimeSchedulerWrapperComponent {
    public queuedScheduler = new QueuedCaller<TimeSchedulerComponent>();
    @Output() public initialised = new EventEmitter<TimeSchedulerComponent>();

    // non-optional inputs
    @Input() public items!: IItem[];
    @Input() public sections!: ISection[];

    @Input() public periods: IPeriod[] = DefaultTimeSchedulerPeriods;

    @Input() public cycle?: ICycle;

    @Input() public set currentPeriod(period: IPeriod | undefined) {
        this.queuedScheduler.call((scheduler) => scheduler.changePeriod(period ?? TimeSchedulerPeriodYearPlusHalf));
    }

    @Input() public start = moment().startOf("day");

    @Input() public showSectionToggle = true;

    @Input() public selectedItem?: IItem;
    @Output() public selectedItemChange = new EventEmitter<IItem>();

    @Output() public selectedSectionsChange = new EventEmitter<void>();

    public popupItem?: IItemEvent<Meeting>;
    public popupVisible = false;

    public onItemHover(event: IItemEvent, leave = false) {
        this.popupItem = event;
        this.popupVisible = !leave;
    }

    public formatTime(item: IItem) {
        const diff = Math.abs(item.start.diff(item.end, "minutes"));
        const startFormat = item.start.format("h:mm a");
        const duration = moment.duration(diff, "minutes").humanize();

        // ends on a different day
        if (item.start.date() !== item.end.date()) {
            return `${startFormat} (${duration})`;
        }

        return `${startFormat} - ${item.end.format("h:mm a")} (${duration})`;
    }

    public toggleSection(section: ISection) {
        section.visible = !section.visible;
        this.queuedScheduler.call((scheduler) => {
            scheduler.refreshView();
            setTimeout(() => this.selectedSectionsChange.emit());
        });
    }

    public isSectionConfigured(section: ISection) {
        return this.items.filter((i) => i.sectionId === section.id).length > 0;
    }

    public onCheckBoxReady(event: ContentReadyEvent, section: ISection) {
        if (event.element && section.colour) {
            // DX jquery...
            const elm = jQuery(event.element).get(0);
            if (elm) {
                const icon = elm.querySelector(".dx-checkbox-icon") as HTMLSpanElement | undefined;
                if (icon) {
                    icon.style.backgroundColor = section.colour;

                    // check colour
                    icon.style.color = "#ffffff";
                    icon.style.borderColor = "#ffffff";
                }
            }
        }
    }
}

import { Injector } from "@angular/core";
import { WorkflowStep } from "@common/ADAPT.Common.Model/embed/workflow-step";
import { WorkflowConnection } from "@common/ADAPT.Common.Model/organisation/workflow-connection";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { EntityAction } from "breeze-client";
import { BehaviorSubject, Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { IWorkflowStepComponent } from "./workflow-component-registry";

/**
 * Convenient adapt class for IWorkflowStepComponent to save every single / multiple
 * implementations from declaring properties from the interface.
 * Add more common declarations as needed
 */
export class WorkflowStepComponentAdapter extends BaseComponent implements IWorkflowStepComponent {
    public workflowStep?: WorkflowStep;
    public workflowConnection?: WorkflowConnection;

    // ignore private before public: or we will get property used before it is initialized error
    private entityChange = new Subject<IBreezeEntity<any>>();

    public workflowStepEntityChange = this.entityChange.asObservable();

    /**
     * Emit to this subject if there is any error message to be displayed by workflow-run dialog
     */
    public workflowStepErrorMessage = new BehaviorSubject<string | undefined>(undefined);

    /**
     * Emit entity change to be added to entitiesToConfirm for the workflow run dialog.
     * This is intentionally using public accessor to allow it to be called from template if required.
     */
    public emitEntityChange(changedEntity: IBreezeEntity<any>) {
        this.entityChange.next(changedEntity);
    }

    public subscribeToEmitForEntityTypeChange<T extends IBreezeEntity<T>>(injector: Injector, entityType: new (...args: any[]) => T) {
        const rxjsBreezeService = injector.get(RxjsBreezeService);
        rxjsBreezeService.breezeEntityChanged$.pipe(
            filter((e) => (e.entity instanceof entityType) && (e.entityAction !== EntityAction.Detach)),
            this.takeUntilDestroyed(),
        ).subscribe((e) => this.emitEntityChange(e.entity as IBreezeEntity<T>));
    }
}

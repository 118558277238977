<adapt-confirmation-dialog (closed)="onClosed($event)"
                           (initialised)="onInitialised($event)">
    <div role="alert"
         class="alert alert-danger">
        <p>Deactivating {{connection.person.fullName}}'s account will remove the ability for {{connection.person.firstName}} to
            log in to {{platformName}} as well as remove any assigned roles and access levels.</p>
        <p>We currently do not support deactivation in the future as that will result in uncertainty with the account before the
            deactivation date. End date also has to be after start date.</p>
        <p *ngIf="endPendingConnection">
            As the account you are going to deactivate is still in the pending state (before the account start date),
            deactivating the account will also change the start date to the current date.
        </p>
    </div>

    <div>
        <label>Start date</label>
        <p>{{connection.startDate | adaptDate}}</p>
    </div>

    <div>
        <label>End date</label>

        <div *ngIf="connection.endDate">
            <div *ngIf="!canEdit || connection.endDate">
                <p>{{connection.endDate | adaptDate}}</p>
            </div>
        </div>

        <div *ngIf="canEdit && !connection.endDate && !startDateIsToday && !endPendingConnection; else singleEndDate">
            <dx-date-box [disabled]="!!connection.endDate"
                         type="date"
                         [displayFormat]="displayFormat"
                         [min]="connection.startDate"
                         [max]="maxEndDate"
                         [value]="endDate"
                         (valueChange)="endDate = $any($event)"
                         [width]="200"></dx-date-box>
        </div>
        <ng-template #singleEndDate>
            <div *ngIf="startDateIsToday || endPendingConnection">
                <p>{{endDate | adaptDate}}</p>
            </div>
        </ng-template>
    </div>
</adapt-confirmation-dialog>

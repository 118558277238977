import { UserType, UserTypeExtensions } from "../embed/user-type";

export enum RoleTypeCode {
    Leader = "LEADER",
    Collaborator = "COLLABORATOR",
    Viewer = "VIEWER",
    Tier1 = "TIER1",                            // system role - can edit description
    CulturalLeader = "CULTURALLEADER",          // system role - can edit description
    TeamLeader = "TEAMLEADER",                  // system role
    TeamMember = "TEAMMEMBER",                  // system role
    TeamParticipant = "TEAMPARTICIPANT",        // system role
    Coach = "COACH",                            // system role
}

export class RoleTypeCodeLabel {
    public static label(code?: RoleTypeCode) {

        if (!code) {
            return "";
        }

        switch (code) {
            case RoleTypeCode.Leader:
            case RoleTypeCode.Tier1:
            case RoleTypeCode.CulturalLeader:
                return UserTypeExtensions.pluralLabel(UserType.Leader);
            case RoleTypeCode.Collaborator:
                return UserTypeExtensions.pluralLabel(UserType.Collaborator);
            case RoleTypeCode.Viewer:
                return UserTypeExtensions.pluralLabel(UserType.Viewer);
            case RoleTypeCode.Coach:
                return UserTypeExtensions.pluralLabel(UserType.Coach);
            default:
                throw new Error("Unknown role type code (1)");
        }
    }

    public static ordinal(code?: RoleTypeCode) {

        if (!code) {
            return 99;
        }

        switch (code) {
            case RoleTypeCode.Leader:
            case RoleTypeCode.Tier1:
            case RoleTypeCode.CulturalLeader:
                return 0;
            case RoleTypeCode.Collaborator:
                return 1;
            case RoleTypeCode.Viewer:
                return 2;
            case RoleTypeCode.Coach:
                return 3;
            default:
                throw new Error("Unknown role type code (2)");
        }
    }
}

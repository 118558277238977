import { Component, EventEmitter, Input, Output, QueryList, ViewChildren, ViewEncapsulation } from "@angular/core";
import { MeetingAgendaItem } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { EditAgendaItemComponent } from "../edit-agenda-item/edit-agenda-item.component";
import { MeetingsService } from "../meetings.service";

@Component({
    selector: "adapt-display-agenda-items",
    templateUrl: "./display-agenda-items.component.html",
    styleUrls: ["./display-agenda-items.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class DisplayAgendaItemsComponent extends BaseComponent {
    @Input() public agendaItems!: MeetingAgendaItem[];
    @Input() public expandItemsOnInit = false;
    @Input() public hideProgressIcon = false;
    @Input() public hideNotesAndItems = false;
    @Output() public expandChange = new EventEmitter<boolean>();
    @Output() public canExpandChange = new EventEmitter<boolean>();

    @ViewChildren(EditAgendaItemComponent) private agendaItemComponents!: QueryList<EditAgendaItemComponent>;

    public canExpand = false;

    public get totalPlannedDurationInMinutes() {
        return MeetingsService.getMeetingAgendaItemsTotalPlannedDurationInMinutes(this.agendaItems);
    }

    public constructor() {
        super();
    }

    public canExpandChanged(canExpand: boolean) {
        if (this.agendaItemComponents) {
            this.canExpand = this.agendaItemComponents.some((i) => i.canExpand);
        } else {
            this.canExpand = canExpand;
        }

        this.canExpandChange.emit(this.canExpand);
    }

    public expandChanged(expanded: boolean) {
        if (expanded) {
            this.expandChange.emit(expanded);
        } else {
            this.expandChange.emit(this.canExpand && this.agendaItemComponents.some((i) => i.expandDetails));
        }
    }

    public setExpandAll(expanded: boolean) {
        this.agendaItemComponents.forEach((i) => i.expandDetails = expanded);
    }
}

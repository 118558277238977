
export enum UserType {
    Leader = "Leader",
    Collaborator = "Collaborator",
    Viewer = "Viewer",
    None = "None",
    Coach = "Coach",
}

export const NonParticipantUserTypes = [ UserType.Leader, UserType.Coach, UserType.Collaborator ];

export class UserTypeExtensions {
    private static singularLabels: { [type in UserType]: string } = {
        Leader: "Leader",
        Collaborator: "Collaborator",
        Viewer: "Participant",
        None: "None",
        Coach: "Coach",
    };

    private static pluralLabels: { [type in UserType]: string } = {
        Leader: "Leaders",
        Collaborator: "Collaborators",
        Viewer: "Participants",
        None: "None",
        Coach: "Coaches",
    };

    public static singularLabel(userType: UserType): string {
        return UserTypeExtensions.singularLabels[userType];
    }

    public static pluralLabel(userType: UserType): string {
        return UserTypeExtensions.pluralLabels[userType];
    }

    /**
     * The pricing ordinal for a user type. Lower is more expensive.
     */
    public static priceOrdinal(userType: UserType) {
        switch (userType) {
            case UserType.Leader:
                return 0;
            case UserType.Collaborator:
                return 1;
            case UserType.Viewer:
                return 2;
            case UserType.None:
                return 3;
            case UserType.Coach:
            default:
                throw new Error("unknown user type in ordinal");
        }
    }
}

import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { ItemComment } from "@common/ADAPT.Common.Model/organisation/item-comment";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ObjectiveItemLink } from "@common/ADAPT.Common.Model/organisation/objective-item-link";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { Observable, of, Subject } from "rxjs";
import { debounceTime, filter, finalize, map, switchMap, tap } from "rxjs/operators";
import { INewComment } from "../../add-comment-place-holder/add-comment-place-holder.component";
import { KanbanService } from "../../kanban.service";
import { KanbanAuthService } from "../../kanban-auth.service";
import { KanbanUiService } from "../../kanban-ui.service";
import { ItemChainComment } from "./item-chain-comment";

@Component({
    selector: "adapt-item-preview",
    templateUrl: "./item-preview.component.html",
    styleUrls: ["./item-preview.component.scss"],
})
export class ItemPreviewComponent extends BaseComponent implements OnChanges {
    @Input() public item!: Item;
    @Output() public closeClicked = new EventEmitter<void>();
    @Output() public itemDialogOpened = new EventEmitter<Item>();
    @Output() public itemDialogClosed = new EventEmitter<Item>();

    public itemComments: ItemChainComment[] = [];
    public newComment$?: Observable<ItemChainComment>;
    public hasEditAccessToItem$ = of(false);
    public linkedObjectives: Objective[] = [];

    private lastAddedItemChainComment?: ItemChainComment;
    private triggerCommentChainUpdate$ = new Subject<void>();

    public constructor(
        elementRef: ElementRef,
        private kanbanService: KanbanService,
        private kanbanAuthService: KanbanAuthService,
        private kanbanUiService: KanbanUiService,
        private dialogService: AdaptCommonDialogService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super(elementRef);

        this.triggerCommentChainUpdate$.pipe(
            filter(() => !!this.item),
            debounceTime(100), // debounce emits from entityTypeChanged
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            this.itemComments = this.item.comments
                .filter((c) => !c.entityAspect.entityState.isAdded()) // excluded newly added unsaved comment as that's already in lastAddedItemChainComment
                .map((c) => new ItemChainComment(c, false));
            if (this.lastAddedItemChainComment) {
                if (this.lastAddedItemChainComment.entity.entityAspect.entityState.isAdded()) {
                    // there was a new comment added and not saved -> reuse it only if that's an added entity
                    this.itemComments.push(this.lastAddedItemChainComment);
                } else {
                    // last added item can be detached or saved (no longer just newly added -> not suitable to be in this space anymore)
                    this.lastAddedItemChainComment = undefined;
                    this.newComment$ = undefined;
                }
            } else {
                const restoreComment = this.item.comments.find((c) => c.entityAspect.entityState.isAdded());
                if (restoreComment) {
                    // import from previous saved changed entity
                    this.lastAddedItemChainComment = new ItemChainComment(restoreComment, true);
                    this.newComment$ = of(this.lastAddedItemChainComment);
                }
            }
        });

        rxjsBreezeService.entityTypeChanged(ItemComment).pipe(
            filter((comment) => !!this.item && comment.itemId === this.item.itemId), // only care about comments for this item after item is initialised
            this.takeUntilDestroyed(),
        ).subscribe((comment) => {
            if (comment.entityAspect.entityState.isDetached() || // comment deleted
                !this.itemComments.find((i) => i.entity === comment)) { // comment added
                // only need to rerun query for added/deleted - changed comment or entity sync already updated in the entity manager
                this.triggerCommentChainUpdate$.next();
            }
        });

        // handle the case where item dialog is opened after typing in the add comment field,
        // and then the newly added comment is cancelled from the dialog
        // (without this, the entity in this preview panel becomes invalid and causes errors)
        rxjsBreezeService.entityTypeDetached(ItemComment).pipe(
            filter((comment) => !!this.item && comment.itemId === this.item.itemId),
            this.takeUntilDestroyed(),
        ).subscribe((comment) => {
            if (this.lastAddedItemChainComment?.entity === comment) {
                this.lastAddedItemChainComment = undefined;
                this.newComment$ = undefined;
            }
        });

        rxjsBreezeService.entityAttachedOnImport(ItemComment).pipe(
            filter((itemComment) => !!this.item && itemComment.itemId === this.item.itemId && itemComment.entityAspect.entityState.isAdded()),
            debounceTime(100),
            this.takeUntilDestroyed(),
        ).subscribe((itemComment) => {
            // import from previous saved changed entity
            if (!this.lastAddedItemChainComment) {
                this.lastAddedItemChainComment = new ItemChainComment(itemComment, true);
                this.newComment$ = of(this.lastAddedItemChainComment);
            }
        });

        rxjsBreezeService.entityTypeChanged(ObjectiveItemLink).pipe(
            filter((link) => link.itemId === this.item?.itemId),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updateLinkedObjectives());
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.item && this.item) {
            this.isInitialised = true;
            this.hasEditAccessToItem$ = this.kanbanAuthService.hasEditAccessToItem(this.item);
            if (this.lastAddedItemChainComment) {
                if (this.lastAddedItemChainComment.entity.comment) {
                    this.dialogService.openConfirmationDialogWithBoolean({
                        title: "Unsaved Comment",
                        message: `<p>There is an unsaved comment for the previously selected item with summary:
                            <br/>${this.lastAddedItemChainComment.entity.item.summary}<p>
                            <p>Do you want to discard or save the comment?<p>`,
                        confirmButtonText: "Discard",
                        cancelButtonText: "Save",
                    }).pipe(
                        switchMap((discard) => {
                            if (discard) {
                                return this.kanbanService.rejectChanges([this.lastAddedItemChainComment!.entity]);
                            } else {
                                return this.kanbanService.saveEntities([this.lastAddedItemChainComment!.entity]);
                            }
                        }),
                        this.takeUntilDestroyed(),
                    ).subscribe(() => {
                        this.lastAddedItemChainComment = undefined;
                        this.newComment$ = undefined;
                    });
                } else {
                    this.lastAddedItemChainComment.entity.entityAspect.rejectChanges();
                    this.lastAddedItemChainComment = undefined;
                    this.newComment$ = undefined;
                }
            }

            this.triggerCommentChainUpdate$.next();
            this.updateLinkedObjectives();
        }
    }

    private updateLinkedObjectives() {
        this.linkedObjectives = this.item?.objectiveItemLinks.map((l) => l.objective) ?? [];
    }

    public onNewCommentAdded(newComment: INewComment) {
        this.newComment$ = this.kanbanService.createItemComment(newComment.item, newComment.person).pipe(
            map((itemComment) => new ItemChainComment(itemComment, true)),
            tap((itemChainComment) => this.lastAddedItemChainComment = itemChainComment),
        );
    }

    public onNewCommentSaved() {
        if (this.lastAddedItemChainComment) {
            this.lastAddedItemChainComment.canUpdate = false;
            this.lastAddedItemChainComment = undefined;
        }

        this.newComment$ = undefined;
    }

    @Autobind
    public openItem() {
        this.itemDialogOpened.emit(this.item);
        return this.kanbanUiService.openItem(this.item).pipe(
            finalize(() => this.itemDialogClosed.emit(this.item)),
        );
    }
}

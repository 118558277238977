<adapt-feature-guidance [configItem]="configItem">
    <p>Configure {{projectLabel}} access via external accounts.</p>
</adapt-feature-guidance>

<div *ngIf="errorMessage"
     class="alert alert-danger"
     role="alert">{{errorMessage}}</div>

<div *adaptLoading="!configuredProviders"
     class="d-flex flex-column gap-3">
    <div *ngIf="!canRemoveProvider"
         class="alert alert-warning m-0">
        <p>You cannot remove your last remaining external account as your account does not have a password set.</p>
        <p>You can set a password for your account via the "Change Password" option on your <a [routerLink]="profileUrl">user profile</a>.</p>
    </div>

    <div *ngIf="configuredProviders!.length > 0; else noConfiguredProviders">
        <p>Your account is configured to allow sign in using the following external accounts:</p>
        <div class="d-flex flex-column gap-2">
            <ng-container *ngFor="let provider of configuredProviders">
                <ng-template #buttonTemplate
                             let-provider="provider">
                    <button type="button"
                            class="btn btn-danger"
                            [attr.data-test]="'remove-' + provider.provider.toLowerCase()"
                            [disabled]="!canRemoveProvider"
                            [adaptBlockingClick]="removeProvider"
                            [adaptBlockingClickParam]="provider"
                            [unsubscribeOnDestroy]="false">Remove access</button>
                </ng-template>

                <ng-container [ngTemplateOutlet]="providerTemplate"
                              [ngTemplateOutletContext]="{provider: ExternalLoginProviders[provider.provider], button: buttonTemplate}"></ng-container>
            </ng-container>
        </div>
    </div>

    <ng-template #noConfiguredProviders>
        <div>You have not configured any external accounts yet.</div>
    </ng-template>

    <div *ngIf="otherProviders!.length > 0">
        <p>You can sign in using the following external accounts:</p>
        <div class="d-flex flex-column gap-2">
            <ng-container *ngFor="let provider of otherProviders">
                <ng-template #buttonTemplate
                             let-provider="provider">
                    <button type="button"
                            class="btn btn-primary"
                            [attr.data-test]="'sign-in-' + provider.provider.toLowerCase()"
                            [adaptBlockingClick]="signInProvider"
                            [adaptBlockingClickParam]="provider"
                            [unsubscribeOnDestroy]="false">Sign in using {{provider.providerDisplayName}}</button>
                </ng-template>

                <ng-container [ngTemplateOutlet]="providerTemplate"
                              [ngTemplateOutletContext]="{provider: ExternalLoginProviders[provider.provider], button: buttonTemplate}"></ng-container>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #providerTemplate
             let-provider="provider"
             let-button="button">
    <div *ngIf="provider"
         class="row provider">
        <div class="col d-flex gap-2 align-items-center">
            <img [src]="provider.iconPath"
                 [alt]="provider.providerDisplayName"
                 style="height: 40px; width: 40px;" />

            <h2 class="mb-0">{{provider.providerDisplayName}}</h2>
        </div>
        <div class="col-auto">
            <ng-container [ngTemplateOutlet]="button"
                          [ngTemplateOutletContext]="{provider}"></ng-container>
        </div>
    </div>
</ng-template>

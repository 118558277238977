<dx-list [activeStateEnabled]="false"
         [hoverStateEnabled]="false"
         [focusStateEnabled]="false"
         [dataSource]="keyResults"
         pageLoadMode="scrollBottom"
         noDataText="There is no key result associated with this objective."
         (onItemReordered)="reorderKeyResults($event, keyResults)">
    <dxo-item-dragging [data]="keyResults"
                       [allowReordering]="hasEditPermissions && keyResults.length > 1"></dxo-item-dragging>
    <div *dxTemplate="let keyResult of 'item'">
        <adapt-key-result-item-with-comments [keyResult]="keyResult"
                                             [isEditing]="hasEditPermissions"
                                             [comments]="comments"
                                             [updates]="updates"
                                             (chainUpdateDeleted)="chainUpdateDeleted.emit($event)"
                                             (chainUpdateEdited)="chainUpdateEdited.emit($event)"
                                             (keyResultUpdated)="keyResultUpdated.emit($event)"></adapt-key-result-item-with-comments>
    </div>
</dx-list>

import { Component, Input } from "@angular/core";
import { Survey, SurveyStatus } from "@common/ADAPT.Common.Model/organisation/survey";
import { WorkflowConnection } from "@common/ADAPT.Common.Model/organisation/workflow-connection";
import { OrganisationDiagnosticAuthService } from "@org-common/lib/survey/organisation-diagnostic/organisation-diagnostic-auth.service";
import { SurveyService } from "@org-common/lib/survey/survey.service";
import { ISurveyQuestions } from "@org-common/lib/survey/survey-questions.interface";
import { organisationDiagnosticSurveySummaryPage } from "@org-common/lib/survey/survey-summary-page/survey-summary-page.component";
import { WorkflowService } from "@org-common/lib/workflow/workflow.service";
import { IWorkflowStepOverviewComponent, WorkflowStepOverviewComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { forkJoin, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { IOrganisationDiagnosticStatusCustomData } from "../organisation-diagnostic-status-custom-data";

@WorkflowStepOverviewComponent("adapt-show-survey-meeting")
@Component({
    selector: "adapt-show-survey-meeting",
    templateUrl: "./show-survey-meeting.component.html",
    styleUrls: ["./show-survey-meeting.component.scss"],
})
export class ShowSurveyMeetingComponent implements IWorkflowStepOverviewComponent {
    public readonly SurveyStatus = SurveyStatus;

    @Input() public workflowConnection?: WorkflowConnection;

    public survey?: Survey;
    public surveyQuestions?: ISurveyQuestions;
    public isInitialised = false;

    public constructor(
        private workflowService: WorkflowService,
        private surveyService: SurveyService,
        private orgDiagAuthService: OrganisationDiagnosticAuthService,
    ) { }

    public onDataChanged() {
        if (this.workflowConnection) {
            forkJoin([
                this.workflowService.getWorkflowCustomData<IOrganisationDiagnosticStatusCustomData>(this.workflowConnection),
                this.orgDiagAuthService.hasReadAccessToSurveys$(),
            ]).pipe(
                switchMap(([customData, canReadSurvey]) => {
                    if (customData.surveyId && canReadSurvey) {
                        return this.surveyService.getSurveyById(customData.surveyId);
                    }

                    return of(undefined);
                }),
            ).subscribe((survey) => {
                if (survey) {
                    this.surveyQuestions = this.surveyService.getQuestionsForSurvey(survey);
                }

                this.survey = survey;
                this.isInitialised = true;
            });
        }
    }

    public viewSurveySummary() {
        organisationDiagnosticSurveySummaryPage.gotoRoute()
            .subscribe();
    }
}

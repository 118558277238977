import { HttpErrorResponse } from "@angular/common/http";
import { Component, Injector, OnDestroy, OnInit } from "@angular/core";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { IdentityUxService, ReasonCode } from "@common/identity/ux/identity-ux.service";
import { loginPageRoute } from "@common/identity/ux/login-page/login-page.route";
import { ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { lastValueFrom } from "rxjs";
import { IdentityService } from "../../identity.service";
import { ISessionData } from "../../identity-storage.service";
import { ExternalLoginProvider, ExternalLoginProviders, IExternalLoginProvider } from "../external-login-provider.interface";
import { IExternalLoginProviderStatus } from "../external-login-status.interface";

@Component({
    selector: "adapt-login-external-page",
    templateUrl: "./login-external-page.component.html",
})
export class LoginExternalPageComponent extends BaseRoutedComponent implements OnInit, OnDestroy {
    public readonly projectLabel = AdaptClientConfiguration.AdaptProjectLabel;

    public ssoArticleLink = ImplementationKitService.GetArticleLink(ImplementationKitArticle.SingleSignOnOverview);

    public provider?: IExternalLoginProvider;
    public providerStatus?: IExternalLoginProviderStatus;

    public constructor(
        protected injector: Injector,
        private identityService: IdentityService,
        private identityUxService: IdentityUxService,
    ) {
        super(injector);

        const provider = this.getSearchParameterValue("provider") as ExternalLoginProvider;
        this.updateProvider(provider);
    }

    public get providerName() {
        return this.provider?.providerDisplayName
            ?? this.provider?.provider
            ?? this.providerStatus?.provider;
    }

    public async ngOnInit() {
        this.shellUiService.removeDefaultShellPadding();

        // if we have an error already in the URL we don't need to reach out to the backend
        const error = this.getSearchParameterValue("error");
        if (error) {
            let reasonCode = ReasonCode.externalLoginFailed;

            switch (error) {
                case "consent_required":
                    reasonCode = ReasonCode.externalLoginConsent;
                    break;
                case "access_denied":
                    reasonCode = ReasonCode.externalLoginDenied;
                    break;
            }

            await lastValueFrom(loginPageRoute.gotoRoute(undefined, { reasonCode }, true));
            return;
        }

        await this.getCallbackData();
    }

    @Autobind
    public cancel() {
        return this.routeService.gotoHome();
    }

    private async getCallbackData() {
        try {
            const response = await this.identityService.externalLoginCallback();
            if (!response.body) {
                throw new Error("Unable to load external account information");
            }

            await this.processCallbackResponse(response.body);
        } catch (e) {
            const errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(e);

            // redirect if already logged in
            // else attempt login with given token
            await this.identityService.promiseToDoIfLoggedIn(
                () => this.routeService.gotoRedirect(),
                async () => {
                    const reasonCode = ReasonCode.externalLoginFailed;
                    await lastValueFrom(loginPageRoute.gotoRoute(undefined, { reasonCode }, true));
                });

            // need to wait for next tick as we need to emit the navigation error *after* the login page has initialised
            setTimeout(() => {
                // only want to report the error message if there is one in the response
                // otherwise going to the external-login page with no cookie
                // = "Http failure response for http://localhost:4301/api/Account/ExternalLoginCallback: 400 Bad Request"
                // no real point showing that to the user
                if (e instanceof HttpErrorResponse && e.error) {
                    this.routeEventsService.emitUserNavigationError(errorMessage);
                    
                    // also log as a toaster so the user will probably see it
                    this.log.error(errorMessage);
                }
            }, 1_000);
        }
    }

    private async processCallbackResponse(data: ISessionData | IExternalLoginProviderStatus) {
        if ("access_token" in data) {
            // redirect if already logged in
            // else attempt login with given token
            return this.identityService.promiseToDoIfLoggedIn(
                () => this.routeService.gotoRedirect(),
                () => {
                    this.shellUiService.setViewIsLoading(true);
                    return this.identityUxService.promiseToLoginWithResponse(data);
                });
        } else if ("linked" in data && data.linked === true) {
            // already linked with provider, we should just redirect
            this.log.success(`Successfully linked your ${data.provider} account`);
            return this.routeService.gotoRedirect();
        }

        this.providerStatus = data;

        // we're on the wrong page somehow, update it here
        if (this.providerStatus && this.providerStatus.provider !== this.provider?.provider) {
            await this.setSearchParameterValue("provider", this.providerStatus.provider);
            this.updateProvider(this.providerStatus.provider);
        }

        this.notifyActivated();
    }

    private updateProvider(provider: ExternalLoginProvider) {
        const registeredProvider = ExternalLoginProviders[provider];
        if (registeredProvider && registeredProvider.enabled) {
            this.provider = registeredProvider;
        } else {
            // unknown provider, redirect to login page with an error
            const reasonCode = ReasonCode.externalLoginFailed;
            loginPageRoute.gotoRoute(undefined, { reasonCode }, true).subscribe();
        }
    }
}

import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Connection } from "./connection";
import { Survey } from "./survey";

export enum OutstandingSurveyResponseStatus {
    New = "New",
    InvitationProcessed = "InvitationProcessed",
    ReminderProcessed = "ReminderProcessed",
    InvitationFailed = "InvitationFailed",
    ReminderFailed = "ReminderFailed",
}

export class OutstandingSurveyResponse extends BaseEntity<OutstandingSurveyResponse> {
    public outstandingSurveyResponseId!: number;
    public surveyId!: number;
    public connectionId!: number;
    public token!: string;
    public status!: OutstandingSurveyResponseStatus;
    public statusMessage!: string;

    // nav properties
    public survey!: Survey;
    public connection?: Connection;
}

export const OutstandingSurveyResponseBreezeModel = new BreezeModelBuilder("OutstandingSurveyResponse", OutstandingSurveyResponse)
    .hasSource()
    .withIdField()
    .isOrganisationEntity()
    .alwaysFetchingNavigationProperty("survey")
    .build();

<div [ngClass]="{'select-document-component-inline': readOnly}"
     [ngSwitch]="!!readOnly"
     class="select-document-component">
    <div *ngSwitchCase="false"
         class="edit-contents">
        <div [ngClass]="{'form-control': useEditableDisplay}"
             [ngSwitch]="useEditableDisplay"
             class="selection-display">
            <!-- has a value already -->
            <ng-container *ngSwitchCase="true">
                <i [adaptTooltip]="selectedStorageType?.getDisplayName()"
                   [ngClass]="selectedDocument?.getIconClass()"
                   class="storage-icon"></i>

                <div class="display-url">
                    <a [href]="getAbsoluteUrl(selectedDocument?.getUrl() || '')"
                       [ngClass]="{'line-through': !isCookiesEnabled}"
                       rel="noreferrer noopener"
                       target="_blank">{{selectedDocument?.getName()}}</a>
                    <i *ngIf="!isCookiesEnabled"
                       adaptTooltip="Please enable 3rd party cookies and refresh to access this link"
                       class="fal fa-fw fa-exclamation error"></i>
                </div>

                <div class="display-buttons">
                    <button (click)="$event.preventDefault(); editLink()"
                            [disabled]="selectionDisabled"
                            adaptButtonIcon="edit"
                            adaptTooltip="Edit link"
                            class="btn btn-link edit-button"></button>
                    <button (click)="$event.preventDefault(); resetLink()"
                            [disabled]="selectionDisabled"
                            adaptButtonIcon="fal fa-times-circle"
                            adaptTooltip="Clear link. Note: this will not delete the file"
                            class="btn btn-link edit-button"></button>
                </div>
            </ng-container>

            <ng-container *ngSwitchDefault>
                <dx-text-box [(value)]="url"
                             [disabled]="!isCustomUrlService(selectedStorageType)"
                             class="flex-grow-1"
                             placeholder="Enter location URL"></dx-text-box>

                <dx-select-box (onInitialized)="onSelectBoxInitialized($event)"
                               (onContentReady)="onSelectBoxContentReady($event)"
                               (onItemClick)="onStorageTypeSelected($event)"
                               (onSelectionChanged)="onSelectionChanged($event)"
                               [dataSource]="storageProviders"
                               [grouped]="true"
                               [disabled]="selectionDisabled || !isInitialised"
                               [displayExpr]="storageDisplayExpr"
                               [value]="selectedStorageType"
                               [placeholder]="selectedStorageType ? selectedStorageType.getDisplayName() : 'Select location...'"
                               class="selection-choices"
                               groupTemplate="group">
                    <div *dxTemplate="let item of 'item'"
                         class="dx-item-content dx-list-item-content">
                        <i [ngClass]="item.getIconClass()"></i>
                        {{item.getDisplayName()}}
                    </div>
                </dx-select-box>
            </ng-container>
        </div>
    </div>
    <div *ngIf="selectionDisabled"
         class="alert alert-info message"
         role="alert">
        <i aria-hidden="true"
           class="fal fa-spinner fa-spin"></i>
        {{selectedStorageType?.getSelectionInProgressText()}}
    </div>
    <div *ngIf="errorMessage"
         class="alert alert-danger message">
        {{errorMessage}}
    </div>
    <div *ngSwitchDefault>
        <div *ngIf="selectedDocument?.getUrl() && selectedStorageType"
             class="selected-document-container">
            <i [adaptTooltip]="selectedStorageType.getDisplayName()"
               [ngClass]="selectedDocument?.getIconClass()"></i>
            <a [href]="getAbsoluteUrl(selectedDocument?.getUrl() || '')"
               [ngClass]="{'line-through': !isCookiesEnabled}"
               rel="noreferrer noopener"
               target="_blank">
                {{selectedDocument?.getName()}}
            </a>
            <i *ngIf="!isCookiesEnabled"
               adaptTooltip="Please enable 3rd party cookies and refresh to access this link"
               class="fal fa-fw fa-exclamation error"></i>
        </div>
    </div>
</div>

import { OrganisationCategoryValue, WorkflowDialogWidth, WorkflowScope, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { WorkflowStepGuidancePosition } from "@common/ADAPT.Common.Model/embed/workflow-step";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { buildLocalWorkflow } from "@org-common/lib/workflow/workflow.interface";
import { WorkflowIdentifier } from "@org-common/lib/workflow/workflow-identifier.enum";
import { SetUpProfileComponentSelectorName } from "./set-up-profile/set-up-profile.component";

export const onboardingWorkflow = buildLocalWorkflow({
    name: "Welcome to adapt HQ",
    workflowId: WorkflowIdentifier.WelcomeOnboarding,
    type: WorkflowType.Journey,
    iconStyle: "fal fa-compass",
    overview: `This pathway will introduce you to the adapt HQ platform, and guide you through setting up your profile.`,
    private: true,
    scope: WorkflowScope.Personal,
    category: OrganisationCategoryValue.Onboarding,
    continueOnFinish: true,
    hideIfCompleted: true,
    isDismissible: false,
    dialogWidth: WorkflowDialogWidth.Large,
    workflows: [{
        name: "Welcome",
        workflowId: "SetUpProfile",
        type: WorkflowType.Workflow,
        dialogWidth: WorkflowDialogWidth.Large,
        continueOnFinish: true,
        steps: [{
            name: "Welcome",
            ordinal: 0,
            allowBack: false,
            canSkip: false,
            articleSlug: ImplementationKitArticle.OnboardingWelcome,
        }, {
            name: "Set up your profile",
            ordinal: 1,
            allowBack: true,
            canSkip: false,
            articleSlug: ImplementationKitArticle.OnboardingSetUpProfile,
            componentSelector: SetUpProfileComponentSelectorName,
            articlePosition: WorkflowStepGuidancePosition.Left,
        }],
    }],
});

import { Injectable } from "@angular/core";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { AuthorisationService } from "../authorisation/authorisation.service";

@Injectable({
    providedIn: "root",
})
export class OrganisationAuthService {
    public static readonly ChangePasswordForPerson = "changePasswordForPerson";
    public static readonly ViewAccessForPerson = "viewAccessForPerson";
    public static readonly ViewExternalDashboard = "viewExternalDashboard";

    public static registerAccess(authorisationService: AuthorisationService) {
        authorisationService.registerAccessVerifier(
            OrganisationAuthService.ViewExternalDashboard,
            {
                requirePermissions: [
                    FeaturePermissionName.OrganisationExternalDashboardRead,
                    FeaturePermissionName.OrganisationExternalDashboardPublicRead,
                ],
            },
        );

        authorisationService.registerAccessVerifier(
            OrganisationAuthService.ChangePasswordForPerson,
            {
                invokeToGetEntityAccessVerifier: () => getVerifyAccessToChangePasswordForPerson(),
            },
        );

        authorisationService.registerAccessVerifier(
            OrganisationAuthService.ViewAccessForPerson,
            {
                invokeToGetEntityAccessVerifier: () => getVerifyAccessToViewAccessForPerson(),
            },
        );

        function getVerifyAccessToChangePasswordForPerson() {
            return (currentPerson: Person, entity: IBreezeEntity) => {
                return currentPerson.personId === entity.personId
                    ? Promise.resolve()
                    : Promise.reject();
            };
        }

        function getVerifyAccessToViewAccessForPerson() {
            return (currentPerson: Person, destPerson?: Person) => {
                return destPerson
                    && destPerson.isActive()
                    && authorisationService.personHasPermission(currentPerson, FeaturePermissionName.OrganisationAccessManagementConfigure)
                    ? Promise.resolve()
                    : Promise.reject();
            };
        }
    }
}

import { IBreezeQueryOptions } from "@common/lib/data/breeze-query-options.interface";
import moment from "moment";
import { Logger } from "../logger/logger";
import { ObjectUtilities } from "../utilities/object-utilities";

export class FilterUtilities {
    private static readonly Logger = Logger.getLogger("FilterUtilities");

    public static hasDateOption(options?: IBreezeQueryOptions): boolean {
        return options?.namedParams?.date;
    }

    /**
     * Filters data for the latest record per group.
     * NB: Requires that data already sorted (using the orderBy parameter in the model), this function takes the first element of each group.
     * For multiple group by fields, the data is fetched for each field and then sorted to form the group key.
     *
     * @param {Array} data - The data that should be filtered.
     * @param {string} groupByField - The field (or fields) that data should be grouped by.
     * @returns {Array} A filtered set of data with only the latest for each group.
     */
    public static filterByDate(data: any[], options: any): any[] {
        const dateField = options.dateField;

        if (!dateField) {
            FilterUtilities.Logger.warn("filterByDate: dateField is empty", null);

            return data;
        }

        return data.filter((item: any) => moment(item[dateField]).isBefore(options.namedParams.date));
    }

    public static hasLatestOnlyOption(options?: IBreezeQueryOptions) {
        return !!options?.namedParams?.latestOnly;
    }

    /**
     * Filters data for the latest record per group.
     * NB: Requires that data already sorted (using the orderBy parameter in the model), this function takes the first element of each group.
     * For multiple group by fields, the data is fetched for each field and then sorted to form the group key.
     *
     * @param {Array} data - The data that should be filtered.
     * @param {string} groupByField - The field (or fields) that data should be grouped by.
     * @returns {Array} A filtered set of data with only the latest for each group.
     */
    public static filterByLatestOnly(data: any[], options: IBreezeQueryOptions): any[] {
        const groupByField = options.latestGroupByField;
        const allowPartials = options.isPartial;

        if (!groupByField) {
            FilterUtilities.Logger.warn("filterByLatestOnly: groupByField is empty", null);

            return data;
        }

        const groupFieldKeys: any = groupByField.split(",");
        const groups: any = {};

        return data.filter(isFirstOfGroup);

        function isFirstOfGroup(result: any) {
            const groupFieldKeyValues: any = groupFieldKeys.map(getGroupFieldResult);

            if (groupFieldKeyValues.every(isFalsey)) {
                return allowPartials;
            }

            const groupByKeyValues: number[] = options.latestGroupByGenerateKeyFunction
                ? options.latestGroupByGenerateKeyFunction(result)
                : groupFieldKeyValues;
            const groupByKey = groupByKeyValues.join(",");

            if (!groups[groupByKey]) {
                groups[groupByKey] = true;

                return true;
            }

            return false;

            function getGroupFieldResult(groupField: string) {
                return ObjectUtilities.getObjectByPath(result, groupField);
            }

            function isFalsey(theValue: any) {
                return !theValue;
            }
        }
    }
}

export interface IWorkflowRating {
    rating: number; // out of 5
    liked: string;
    improveOn: string;
    workflowId: string;
    workflowName: string;
    workflowConnectionId?: number;
    organisationId?: number;
    organisationName?: string;
    status: PathwayRatingStatus;
    comment?: string,
}

export enum PathwayRatingStatus {
    awaiting_review = "Awaiting review",
    actioned = "Actioned"
}

export const PathwayRatingStatusCssClassMap: Record<PathwayRatingStatus, string> = {
    [PathwayRatingStatus.awaiting_review]: "pathway-review-awaiting-review",
    [PathwayRatingStatus.actioned]: "pathway-review-actioned",
};

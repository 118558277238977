import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { ObjectivesAuthService } from "../objectives-auth.service";
import { ObjectivesPageComponent } from "./objectives-page.component";

export const teamObjectivesPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-objectives-page", ObjectivesPageComponent)
    .atOrganisationUrl("/team/:teamId/objectives")
    .redirectToThisRouteFromOrganisationUrl("/people/teams/:teamId/objectives")
    .verifyingFeatures(FeatureName.StewardshipObjectives)
    .withTitle("Objectives")
    .withSearchKeyword("Key Results")
    .withSearchKeyword("OKRs")
    .reloadOnSearch(false)
    .build();

export const organisationObjectivesPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-objectives-page", ObjectivesPageComponent)
    .atOrganisationUrl("/leadership/objectives")
    .verifyingFeatures(FeatureName.StewardshipObjectives)
    .verifyingAccess(ObjectivesAuthService.ReadPublicObjectives)
    .withTitle("Objectives")
    .withSearchKeyword("Key Result")
    .withSearchKeyword("OKR")
    .reloadOnSearch(false)
    .build();

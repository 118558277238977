<adapt-dialog [maxWidth]="600">
    <h3 adapt-dialog-title>Move objective</h3>
    <div adapt-dialog-content
         *ngIf="!isDialogClosing && isInitialised">
        <div class="alert"
             [ngClass]="{'alert-info': canMove, 'alert-danger': !canMove}">
            <ng-container *ngIf="originTeam">
                <ng-container *ngIf="canMove">
                    Team objectives can be moved
                    <span *ngIf="canMoveBetweenTeams">between teams{{canMoveToOrg? "" : "."}}</span>
                    <span *ngIf="canMoveToOrg">{{canMoveBetweenTeams ? ", or moved" : ""}} to become an organisational objective.</span>
                </ng-container>
                <!-- Additional info on why you cannot move in certain cases -->
                <p *ngIf="!canMoveBetweenTeams">
                    Team objectives cannot be moved between teams if they are supported by other child objectives
                    or currently supporting another team objective.
                </p>
                <p *ngIf="!canMoveToOrg">Team objectives cannot be moved to become an organisational objective
                    if they are currently supporting another objective.</p>
            </ng-container>
            <ng-container *ngIf="!originTeam">
                <p *ngIf="canMoveToTeam">Organisational objectives can be only moved to another team.</p>
                <p *ngIf="!canMoveToTeam">Organisational objectives with supporting child objectives cannot be moved.</p>
            </ng-container>
        </div>

        <ng-container *ngIf="canMove">
            <div class="form-group"
                 *ngIf="canMoveToTeam || canMoveBetweenTeams">
                <label class="mb-2"
                       for="objectiveTeam">Destination Team</label>
                <adapt-select-team id="objectiveTeam"
                                   [(team)]="objective.team"
                                   (teamChange)="onTeamChange()"
                                   [filter]="hasObjectivesEnabled"
                                   [required]="true"
                                   data-test="select-objective-team">
                </adapt-select-team>
            </div>
            <ng-container *ngIf="originTeam && canMoveToOrg">
                <dx-check-box class="mb-3"
                              (onValueChanged)="onMakeOrgObjectiveChange()"
                              [(value)]="makeOrganisationObjective"
                              data-test="make-objective-global"
                              text="Make this an organisation objective">
                </dx-check-box>
            </ng-container>

            <ul class="mb-0 pt-3 border-top">
                <li *ngIf="objective.team">This will move the objective to the <strong>{{objective.team.name}}</strong> team.</li>
                <li *ngIf="!objective.team && originTeam && makeOrganisationObjective">This will move the objective to the
                    <strong>Organisation</strong> from the
                    <strong>{{originTeam.name}}</strong> team.
                </li>
                <li>Once the objective is moved, you will be navigated to the new objective location.</li>
            </ul>
        </ng-container>
    </div>
    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveText="Move objective"
                                   [saveIsDisabled]="saveIsDisabled()"
                                   (saveClick)="save()"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptImplementationKitModule } from "@common/implementation-kit/implementation-kit.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { DxPopupModule, DxScrollViewModule, DxTabPanelModule } from "devextreme-angular";
import { AdaptToolbarModule } from "../toolbar/toolbar.module";
import { ContextSidebarComponent } from "./context-sidebar/context-sidebar.component";
import { ContextSidebarContentComponent } from "./context-sidebar-content/context-sidebar-content.component";
import { ContextSidebarImplementationKitComponent } from "./context-sidebar-implementation-kit/context-sidebar-implementation-kit.component";

@NgModule({
    imports: [
        CommonModule,
        DxScrollViewModule,
        DxPopupModule,
        DxTabPanelModule,
        AdaptImplementationKitModule,
        AdaptToolbarModule,
        AdaptButtonModule,
    ],
    declarations: [
        ContextSidebarComponent,
        ContextSidebarContentComponent,
        ContextSidebarImplementationKitComponent,
    ],
    exports: [
        ContextSidebarComponent,
        ContextSidebarContentComponent,
        ContextSidebarImplementationKitComponent,
    ],
})
export class AdaptContextSidebarModule { }

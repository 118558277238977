import { Component, Input } from "@angular/core";
import { RoleType } from "@common/ADAPT.Common.Model/organisation/role-type";
import { RoleTypeCode } from "@common/ADAPT.Common.Model/organisation/role-type-code";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";

@Component({
    selector: "adapt-configure-teams",
    templateUrl: "./configure-teams.component.html",
})
export class ConfigureTeamsComponent extends ConfigureFeatureBase {
    @Input() public configItem?: IConfigItem;
    public teamRoleTypes!: RoleType[];

    public constructor(
        configurationService: ConfigurationService,
        private teamsService: CommonTeamsService,
    ) {
        super(configurationService);
    }

    @Autobind
    public async initialiseData() {
        this.teamRoleTypes = await this.teamsService.promiseToGetTeamRoleTypes();
    }

    public inputLabelForRoleTypeCode(code: RoleTypeCode) {
        switch (code) {
            case RoleTypeCode.TeamMember:
                return "What should team members be called?";
            case RoleTypeCode.TeamLeader:
                return "What should team leaders be called?";
            default:
                return "";
        }
    }
}

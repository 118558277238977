<ng-container *adaptLoading="!initialised">
    <dx-accordion class="sidebar-agenda-accordion"
                  [dataSource]="agendaItems"
                  [selectedItem]="selectedItem"
                  [collapsible]="true"
                  [disabled]="!canParticipateInMeeting"
                  (onInitialized)="accordionInstance = $event.component"
                  (selectedItemChange)="itemSelectionChanged($event)"
                  [ngClass]="{'cannot-edit-meeting': !canParticipateInMeeting}">
        <div *dxTemplate="let item of 'title'"
             class="d-flex sidebar-agenda-item"
             data-tour="meeting-agenda-item"
             [attr.id]="AgendaItemIdPrefix + item.meetingAgendaItemId"
             [ngClass]="getSelectedClass(item)">
            <h3 class="d-flex align-self-center mb-0">
                <button class="align-self-center agenda-check-box"
                        [disabled]="selectedItem !== item || !canParticipateInMeeting"
                        [adaptButton]="getStatusButtonPresetType(item)"
                        data-tour="toggle-agenda-item"
                        (click)="toggleAgendaItem(item, $event)"></button>
                <span class="align-self-center">{{item.name}}

                </span>
            </h3>
            <adapt-agenda-item-meta-data-count class="d-flex flex-grow-1 align-self-center ms-1"
                                               (contentReady)="onContentReady()"
                                               [agendaItem]="item"
                                               [showTotalBadgeOnly]="true"></adapt-agenda-item-meta-data-count>
            <div class="ms-2 align-self-center agenda-item-duration"
                 *ngIf="item.plannedDurationInMinutes">
                <i class="fal fa-clock me-1"></i>
                <span>{{item.plannedDurationInMinutes}} </span>
                <span>mins</span>
            </div>
        </div>
        <div *dxTemplate="let item of 'item'"
             class="sidebar-agenda-item"
             data-tour="meeting-agenda-item"
             [ngClass]="getSelectedClass(item)">
            <adapt-meeting-sidebar-agenda-item [agendaItem]="item"></adapt-meeting-sidebar-agenda-item>
        </div>
    </dx-accordion>

    <ng-container *ngIf="canParticipateInMeeting && (meetingsUiService.isUsingActiveMeetingPage$ | async)">
        <div *ngIf="meeting.extensions.shouldBeEnded"
             class="d-flex mt-2 align-items-center">
            <span class="ms-auto">Your meeting agenda items are finished. You can end the meeting now.</span>
            <button #endMeetingButton
                    (click)="endMeetingCalled.emit()"
                    *ngIf="responsiveService.currentBreakpoint.isMobileSize"
                    class="ms-3 btn btn-primary text-nowrap"
                    [ngClass]="{'end-meeting-pulse': meeting.extensions.shouldBeEnded}"
                    adaptButtonIcon="fal fa-fw fa-stop-circle"
                    data-test="stop-meeting-button">End meeting</button>
        </div>
    </ng-container>
</ng-container>

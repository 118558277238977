import { Injectable } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { OrganisationPageRouteBuilder } from "../route/organisation-page-route-builder";
import { IOrganisationEntityUpdatedEventHandler } from "./organisation-event-handler.interface";

@Injectable({
    providedIn: "root",
})
export class OrganisationIdentifierTracker implements IOrganisationEntityUpdatedEventHandler {
    public organisationEntityUpdated(organisation: Organisation | undefined) {
        OrganisationPageRouteBuilder.emitOrganisationUrlIdentifier(organisation?.urlIdentifier);
    }
}

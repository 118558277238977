import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptSeeMoreModule } from "@common/ux/see-more/see-more.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AdaptStrategyModule } from "@org-common/lib/strategy/strategy.module";
import { AdaptStrategyBoardModule } from "@org-common/lib/strategy-board/strategy-board.module";
import { ThemesAgendaItemComponent } from "./themes-agenda-item/themes-agenda-item.component";


@NgModule({
    declarations: [
        ThemesAgendaItemComponent,
    ],
    imports: [
        CommonModule,
        AdaptAuthorisationModule,
        AdaptButtonModule,
        AdaptStrategyModule,
        AdaptStrategyBoardModule,
        AdaptSeeMoreModule,
    ],
})
export class AdaptStrategicThemesWorkflowModule {
    // prevent being optimised by tree-shaker
    public readonly workflowStepComponents = [
        ThemesAgendaItemComponent,
    ];
}

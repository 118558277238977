import { WorkflowDialogWidth, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { IMeetingLocation, Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { MeetingAgendaItem } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { MeetingAgendaItemSupplementaryData } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item-supplementary-data";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { IMeetingOrganiserInfo } from "../../calendar/calendar.interface";
import { buildLocalWorkflow, IWorkflowPersistenceConfig } from "../../workflow/workflow.interface";

export interface IScheduleMeetingWorkflowRunData {
    meeting: Meeting;
    entitiesToConfirm: IBreezeEntity[];

    // calendar integration
    meetingLocation?: IMeetingLocation;
    hasConflicts?: boolean;
    organiserInfo?: IMeetingOrganiserInfo;
    sendInvitations?: boolean;
    createTeamsMeeting?: boolean;

    // new schedule flow state
    hasDiscardedAgenda?: boolean;
    hasGoneBack?: boolean;
    selectedTemplateId?: number;
    rescheduling?: boolean;
    initialAgendaItems?: (MeetingAgendaItem | MeetingAgendaItemSupplementaryData)[];
    initialDescription?: string;
}

const ScheduleMeetingWorkflowId = "ScheduleMeetingLocal";
export const scheduleMeetingWorkflow = buildLocalWorkflow({
    name : "Schedule meeting",
    workflowId: ScheduleMeetingWorkflowId,
    type: WorkflowType.Workflow,
    dialogWidth: WorkflowDialogWidth.SemiLarge,
    private: true,
    isStateless: true,
    persistenceId: ScheduleMeetingWorkflowId,
    steps: [{
        name: "Choose an agenda for your meeting",
        ordinal: 0,
        canSkip: false,
        componentSelector: "adapt-schedule-meeting-select-agenda-step",
        workflowStepNextText: "Customise agenda",
    }, {
        name: "Customise meeting agenda",
        ordinal: 1,
        // prompt user that agenda will be discarded when going back.
        allowBack: true,
        canSkip: false,
        componentSelector: "adapt-schedule-meeting-customise-agenda-step",
        backButtonText: "Back - Choose agenda",
        workflowStepNextText: "Next - Schedule meeting",
    }, {
        name: "Schedule meeting",
        ordinal: 2,
        allowBack: true,
        canSkip: false,
        componentSelector: "adapt-schedule-meeting-schedule-step",
        backButtonText: "Back - Meeting agenda",
        workflowStepNextText: "Schedule meeting",
    }],
});

export const scheduleMeetingWorkflowPersistenceConfig: IWorkflowPersistenceConfig<IScheduleMeetingWorkflowRunData> = {
    id: ScheduleMeetingWorkflowId,
    encode: (workflowRunData) => workflowRunData.runData,
    decode: (runData) => {
        // runData is required for starting the meeting workflow
        if (!runData) {
            return undefined;
        }

        return {
            workflow: scheduleMeetingWorkflow,
            runData: {
                ...runData,
                restoredDialog: true,
                hasGoneBack: false,
            },
        };
    },
};

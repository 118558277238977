import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { Logger } from "@common/lib/logger/logger";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { NavigationNode } from "./navigation-node";
import { DynamicCallbackFn, INavigationNode, INavigationNodeCustomData, NavigationNodeType } from "./navigation-node.interface";

export class NavigationNodeBuilder {
    private log = Logger.getLogger("NavigationNodeBuilder");

    private newNode: INavigationNode;
    private buildPromises: Promise<any>[] = [];
    private autoOrdinal = -1;

    // TODO, is there a better way of doing this?
    public setId = this.builderFunctionForProperty("id");
    public setTitle = this.builderFunctionForProperty("title");
    public setUrl = this.builderFunctionForProperty("url");
    public setOrdinal = this.builderFunctionForProperty("ordinal");
    public setController = this.builderFunctionForProperty("controller");
    public promiseToSetTitle = this.builderFunctionForPropertyFromPromise("title");
    public promiseToSetUrl = this.builderFunctionForPropertyFromPromise("url");

    public setParentDynamicValueCallback = this.builderFunctionForDynamicValueCallback("parent");
    public setTitleDynamicValueCallback = this.builderFunctionForDynamicValueCallback("title");

    public constructor() {
        this.newNode = new NavigationNode();
    }

    public build() {
        this.sortChildren();

        return this.newNode;
    }

    @Autobind
    public promiseToBuild() {
        return Promise.all(this.buildPromises)
            .then(this.sortChildren)
            .then(() => this.newNode);
    }

    @Autobind
    public sortChildren() {
        const sortFields: (keyof INavigationNode)[] = ["ordinal", "title"];
        const ordinalThenTitleSortFunction = SortUtilities.getSortByFieldFunction(...sortFields);

        this.newNode.children.sort(ordinalThenTitleSortFunction);
    }

    /**
     * This node will be built if the given promise resolves.
     * If the given promise rejects, then promiseToBuild will reject too.
     * @param promise The promise to check for resolution
     * @returns {Builder} The builder, to use again
     */
    public ifResolves(promise: Promise<any>) {
        this.buildPromises.push(promise);

        return this;
    }

    /**
     * Maintains the order of children as declared in the builder.
     * i.e. builder
     *          .addChild(child1)
     *          .promiseToAddChild(child2)
     *          .addChild(child3)
     * will return a node with children: child1, child2, child3
     * Normally, children will first be sorted by their provided ordinals, and then by title.
     * @returns {Builder} The builder, ready to be used again
     */
    public keepChildrenInAddedOrder() {
        this.autoOrdinal = 0;

        return this;
    }

    public getAndIncrementAutoOrdinal() {
        this.autoOrdinal++;

        return this.autoOrdinal;
    }

    @Autobind
    private addChildWithAutoOrdinalFunction() {
        if (this.autoOrdinal < 0) {
            return this.addChild;
        }

        const self = this;
        const ordinal = this.getAndIncrementAutoOrdinal();

        return addChildWithOrdinal;

        function addChildWithOrdinal(child: INavigationNode) {
            child.ordinal = ordinal;

            // We don't use addChild as that will overwrite the above ordinal
            self.newNode.addChild(child);

            return self;
        }
    }

    @Autobind
    public addChild(child: INavigationNode) {
        if (this.autoOrdinal >= 0) {
            child.ordinal = this.getAndIncrementAutoOrdinal();
        }

        this.newNode.addChild(child);

        return this;
    }

    @Autobind
    public promiseToAddChild(promise: Promise<INavigationNode>) {
        let addPromise: Promise<any>;

        if (this.autoOrdinal >= 0) {
            addPromise = promise.then(this.addChildWithAutoOrdinalFunction());
        } else {
            addPromise = promise.then(this.addChild);
        }

        addPromise = addPromise.catch(this.logUnableToAddChild);

        this.buildPromises.push(addPromise);

        return this;
    }

    @Autobind
    public promiseToAddChildren(promises: Promise<INavigationNode>[]) {
        for (const promise of promises) {
            this.promiseToAddChild(promise);
        }

        return this;
    }

    @Autobind
    public addParent(parent?: INavigationNode) {
        if (parent) {
            this.newNode.setParent(parent);
        }

        return this;
    }

    public promiseToAddParent(promise: Promise<INavigationNode | undefined>) {
        this.buildPromises.push(promise);
        promise.then(this.addParent);

        return this;
    }

    public setIconClass(iconClass: string) {
        return this.setCustomKeyValue("iconClass", iconClass);
    }

    public setCustomKeyValue<T extends keyof INavigationNodeCustomData>(key: T, value: INavigationNodeCustomData[T]) {
        this.newNode.customData[key] = value;

        return this;
    }

    public setHideIconInBreadcrumb(hide: boolean) {
        this.newNode.hideIconInBreadcrumb = hide;
        return this;
    }

    public setIconPositionRight(right: boolean) {
        this.newNode.iconPositionRight = right;
        return this;
    }

    /**
     * Specifies a function that will be called which a dynamic build on this node
     * is initiated. The callee is responsible for completely building the node and
     * generating all relationships. This callback should set the node as a dynamic node
     * by using the setIsDynamic builder method.
     * @param {Function} dynamicNodeCallback The callback used to create the node.
     *      This callback will be passed the named Parameters to use to build the node.
     * @returns {NodeBuilder} The builder ready to be used again
     */
    public setDynamicNodeCallback(dynamicNodeCallback: any) {
        this.newNode.dynamicNodeCallback = dynamicNodeCallback;

        return this;
    }

    public setIsDynamic() {
        this.newNode.type = NavigationNodeType.Dynamic;

        return this;
    }

    public setIsExternalLink() {
        this.newNode.openInNewTab = true;

        return this;
    }

    private builderFunctionForProperty<K extends keyof INavigationNode>(property: K) {
        const self = this;
        return builderFunction;

        function builderFunction(value: INavigationNode[K] | (() => INavigationNode[K])) {
            if (typeof value === "function") {
                // Compiler seems to have a bug here as type of value is
                // (referenceNode: INavigationNode, params: any) => void
                // not () => INavigationNode[K] as you would expect
                const getValue = value as () => INavigationNode[K];
                self.newNode[property] = getValue();
            } else {
                self.newNode[property] = value;
            }

            return self;
        }
    }

    private builderFunctionForPropertyFromPromise<K extends keyof INavigationNode>(property: K) {
        const self = this;
        return builderFunction;

        function builderFunction(promise: Promise<INavigationNode[K]>) {
            self.buildPromises.push(promise.then(setValue));
            return self;

            function setValue(value: any) {
                self.newNode[property] = value;
            }
        }
    }

    private builderFunctionForDynamicValueCallback<K extends keyof INavigationNode>(property: K) {
        const self = this;
        return builderFunction;

        function builderFunction(callback: DynamicCallbackFn<K>) {
            (self.newNode.dynamicCallback[property] as any) = callback;

            return self;
        }
    }

    @Autobind
    private logUnableToAddChild(reason: any) {
        this.log.debug("Unable to add child to node:", reason);
    }
}

import { Injector } from "@angular/core";
import { UserType } from "@common/ADAPT.Common.Model/embed/user-type";
import { SurveyResponseGroup } from "@common/ADAPT.Common.Model/organisation/survey-response";
import { UserService } from "@common/user/user.service";
import { DirectorySharedService } from "../../directory-shared/directory-shared.service";
import { analyseOrganisationDiagnosticPageRoute } from "../analyse-survey-page/analyse-survey-page.route";
import { SurveyDetails } from "../survey-details";
import { ISurveyTypeUtils } from "../survey-type-utils.interface";
import { manageOrganisationDiagnosticsPageRoute } from "./manage-organisation-diagnostics-page/manage-organisation-diagnostics-page.component";
import { OrganisationDiagnosticConstants } from "./organisation-diagnostic.constants";
import { OrganisationDiagnosticQuestions } from "./organisation-diagnostic-questions";

export class OrganisationDiagnosticUtils implements ISurveyTypeUtils {
    public resultCategories = OrganisationDiagnosticConstants.Categories;
    public singularParticipant = "person";
    public pluralParticipant = "people";
    public analyseTargetLabel = "Outcome";
    public responseGroups = [SurveyResponseGroup.All];

    // this will get overwritten by the corresponding project module constructor using
    // - SurveyService.registerLearnMoreUrlForSurveyType()
    // If that's not called, the CTA simply won't show the learn more button.
    public learnMoreUrl = "";

    public surveyDescription = `<p>The survey will take about 10 minutes to complete.</p>
            <p>It's anonymous so please be open and honest. It helps us learn where we are strong and where we can improve.</p>`;

    public goodPercentageThreshold = OrganisationDiagnosticConstants.GoodPercentageThreshold;

    public filterQuestionResponse = () => true;

    public getManageSurveyPageRoute$() {
        return manageOrganisationDiagnosticsPageRoute.getRoute();
    }

    public gotoManageSurveyPageRoute$() {
        return manageOrganisationDiagnosticsPageRoute.gotoRoute();
    }

    public getAnalyseSurveyPageRoute$(surveyId?: number) {
        return analyseOrganisationDiagnosticPageRoute.getRouteObject(undefined, { surveyId });
    }

    public gotoAnalyseSurveyPageRoute$(surveyId?: number) {
        return analyseOrganisationDiagnosticPageRoute.gotoRoute(undefined, { surveyId });
    }

    public convertScoreToPercentage(score: number) {
        // Rounded up to whole number
        return Number(((score - 1) * 100 / (OrganisationDiagnosticConstants.MaxScore - 1)).toFixed(0));
    }

    public getStatusColorFromPercentage(percentage: number) {
        if (percentage >= OrganisationDiagnosticConstants.GoodPercentageThreshold) {
            return OrganisationDiagnosticConstants.GoodColor;
        } else if (percentage >= OrganisationDiagnosticConstants.WarningPercentageThreshold) {
            return OrganisationDiagnosticConstants.WarningColor;
        } else {
            return OrganisationDiagnosticConstants.BadColor;
        }
    }

    public getStatusBackgroundFromPercentage(percentage: number) {
        if (percentage >= OrganisationDiagnosticConstants.GoodPercentageThreshold) {
            return OrganisationDiagnosticConstants.GoodBackgroundColor;
        } else if (percentage >= OrganisationDiagnosticConstants.WarningPercentageThreshold) {
            return OrganisationDiagnosticConstants.WarningBackgroundColor;
        } else {
            return OrganisationDiagnosticConstants.BadBackgroundColor;
        }
    }

    public getSurveyQuestions(surveyDetails: SurveyDetails) {
        return new OrganisationDiagnosticQuestions(surveyDetails);
    }

    public getResultCategoryChartTooltipText(count: number, totalResponses: number, resultCategoryText: string): string {
        return `${count} out of ${totalResponses} ${this.pluralParticipant} assessed the business as ${resultCategoryText}`;
    }

    public getScoreGrade(percentageScore: number) {
        for (const resultCategory of OrganisationDiagnosticConstants.Categories) {
            if (percentageScore >= resultCategory.percentageThreshold) {
                return resultCategory.name;
            }
        }

        return "No Grade";
    }

    public async getPotentialSurveyInvitees(injector: Injector) {
        const connection = await injector.get(DirectorySharedService).promiseToGetActiveConnectionsByPredicate();
        const currentPersonId = injector.get(UserService).getCurrentPersonId();
        return connection.filter((c) => c.userType === UserType.Leader && c.personId !== currentPersonId);
    }
}

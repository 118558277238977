import { HttpClient } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { IStorageUriParams, StorageService } from "@common/storage/storage.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { HtmlEditorService } from "@common/ux/html-editor/html-editor.service";
import { EMPTY, lastValueFrom } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { DocumentDescriptor } from "./document-descriptor";
import { DocumentSelectorFroalaAction } from "./document-selector-froala-action";
import { SelectDocumentDialogComponent } from "./select-document-dialog/select-document-dialog.component";
import { IStorageProvider, STORAGE_PROVIDERS } from "./storage-providers/storage-provider.interface";

@Injectable({
    providedIn: "root",
})
export class DocumentSelectorService {
    private organisationId?: number;
    private cookiesEnabled: boolean = true;
    private isSafari: boolean = false;
    private _storageProviders?: IStorageProvider[];

    constructor(
        private injector: Injector,
        private httpClient: HttpClient,
        private dialogService: AdaptCommonDialogService,
        private storageFactory: StorageService,
        htmlEditorService: HtmlEditorService,
    ) {
        // from https://www.denisbouquet.com/javascript-targeting-safari-only/
        this.isSafari = window.navigator.userAgent.indexOf("Safari") !== -1 && window.navigator.userAgent.indexOf("Chrome") === -1;

        htmlEditorService.registerEditorActionCallback(() => DocumentSelectorFroalaAction.registerAction(this));
    }

    public get storageProviders() {
        // Lazy load this property to get around circular constructor injections
        // (where storage providers inject this service)
        if (!this._storageProviders) {
            this._storageProviders = this.injector.get(STORAGE_PROVIDERS);
        }

        return this._storageProviders;
    }

    public openSelectionDialog(doc: DocumentDescriptor) {
        return lastValueFrom(this.dialogService.open(SelectDocumentDialogComponent, doc));
    }

    public getOrganisationId(): number | undefined {
        return this.organisationId;
    }

    public setOrganisationId(organisationId?: number) {
        this.organisationId = organisationId;

        for (const storageProvider of this.storageProviders) {
            storageProvider.onOrganisationChanged(organisationId);
        }

        if (organisationId) {
            // when organisationId is set, user would already have been logged on, issue validating request
            // to RetrieveFile to see if 3rd party cookie is enabled
            const testUrl = this.storageFactory.retrieveFileUri({
                itemId: "adaptbydesign_item_test_id",
                ownerId: organisationId,
            } as IStorageUriParams);

            this.httpClient.get(testUrl, { withCredentials: true }).pipe(
                tap(() => this.cookiesEnabled = true),
                catchError(() => {
                    this.cookiesEnabled = false;
                    return EMPTY;
                }),
            ).subscribe();
        }
    }

    public clearOrganisationId() {
        this.setOrganisationId(undefined);
    }

    public isCookiesEnabled(): boolean {
        return this.cookiesEnabled;
    }

    public isSafariBrowser(): boolean {
        return this.isSafari;
    }
}

import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Organisation } from "./organisation";

export enum OrganisationDetailNames {
    CalendarIntegration = "CalendarIntegration",
    Referrer = "Referrer",
}

export enum CalendarIntegrationProvider {
    Microsoft = "MICROSOFT",
    Google = "GOOGLE",
    Local = "LOCAL",
    None = "NONE",
}

export class OrganisationDetail extends BaseEntity<OrganisationDetail> {
    public organisationId!: number;
    public name!: string;
    public value!: string;
    public organisation!: Organisation;
}

export const OrganisationDetailBreezeModel = new BreezeModelBuilder("OrganisationDetail", OrganisationDetail)
    .isOrganisationEntity()
    .hasSource()
    .build();

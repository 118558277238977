import { Component, Input } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";

@Component({
    selector: "adapt-configure-objectives",
    templateUrl: "./configure-objectives.component.html",
})
export class ConfigureObjectivesComponent extends ConfigureFeatureBase {
    @Input() public configItem?: IConfigItem;
    @Input() public team?: Team;

    public readonly TeamReadAccessOptions = [
        { value: false, text: "Everyone in the team only" },
        { value: true, text: "Everyone in the team and anyone with read access to other team objectives" },
    ];

    constructor(
        configurationService: ConfigurationService,
    ) {
        super(configurationService);
    }

    public async initialiseData() {
        return Promise.resolve();
    }
}

import { Component, Input } from "@angular/core";

@Component({
    selector: "adapt-entity-timestamp",
    template: `
        <span adaptTooltip="At {{timestamp | adaptTime}}, approximately {{timestamp | timeAgo}}">
            <i class="fal fa-clock"></i>
            {{prefix}} {{timestamp | adaptDate}} {{suffix}}
        </span>
    `,
})
export class EntityTimestampComponent {
    @Input() public timestamp?: Date;
    @Input() public prefix?: string;
    @Input() public suffix?: string;
}

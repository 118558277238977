import { UrlUtilities } from "@common/lib/utilities/url-utilities";
import { FroalaActionUtilities, IRangeOffset } from "@common/ux/html-editor/html-editor-froala/froala-action-utilities";
import FroalaEditor from "froala-editor";
import { DocumentDescriptor } from "./document-descriptor";
import { DocumentSelectorService } from "./document-selector.service";

export class DocumentSelectorFroalaAction {

    public static registerAction(documentSelectorService: DocumentSelectorService) {
        // There is no angular way of doing this - has to call $.FroalaEditor
        FroalaEditor.DefineIcon("insertDocLink", { NAME: "link" });
        FroalaEditor.RegisterShortcut(FroalaEditor.KEYCODE.K, "insertDocLink", null, "K"); // Use same shortcut as previous insert link from link.min.js
        FroalaEditor.RegisterCommand("insertDocLink", {
            title: "Insert Link", // The previous froala link plugin is calling this but it can also be used to replace - not entirely insert.
            focus: true,
            undo: false,
            refreshAfterCallback: false,
            callback: selectDocLink,
        });

        FroalaEditor.DefineIcon("editDocLink", { NAME: "edit" });
        FroalaEditor.RegisterCommand("editDocLink", {
            title: "Edit Link",
            focus: true,
            undo: false,
            refreshAfterCallback: false,
            callback: selectDocLink,
        });

        function selectDocLink() {
            const editor: FroalaEditor = this; // from the callback context, this is the editor (not getting passed through argument???!!!)

            if (!editor) {
                // without editor instance, we can do nothing on the editor!
                // - this happens if we try to trigger link without link in the toolbar!
                return;
            }

            editor.events.disableBlur();
            let selectedText = "";
            const linkElement = editor.link.get();
            let linkUrl: string | undefined;

            if (!linkElement) { // current link not selected - use selected text
                selectedText = editor.selection.text();
            } else {
                selectedText = linkElement.textContent ?? "";
                linkUrl = linkElement.getAttribute("href") ?? undefined;
            }

            const anchorNode = editor.selection.element();
            const range = editor.selection.ranges(0) as Range;
            const offsets: IRangeOffset = {
                startOffset: 0,
                endOffset: 0,
            };
            if (range) {
                offsets.startOffset = range.startOffset;
                offsets.endOffset = range.endOffset;
            }
            // hide all popups or it will look weird having 2 active dialogs with ours popup later
            editor.popups.hideAll();

            // need to save selection here as after the dialog is closed, the cursor can be moved anywhere!
            editor.selection.save();

            documentSelectorService.openSelectionDialog(new DocumentDescriptor(selectedText, linkUrl))
                .then(insertLink)
                .catch(() => FroalaActionUtilities.restoreCursor(editor, anchorNode, offsets))
                .finally(() => editor.events.enableBlur());

            function insertLink(doc: DocumentDescriptor) {
                FroalaActionUtilities.restoreCursor(editor, anchorNode, offsets);

                if (doc) {
                    if (linkElement && !editor.link.get()) {
                        // started with editing a link but there is no link now -> selection restore() not
                        // restoring -> set the selection back to the previous element
                        editor.selection.setAtStart(linkElement);
                        editor.selection.setAtEnd(linkElement);
                        editor.selection.restore(); // restore() always required following a setAtStart according to doc https://www.froala.com/wysiwyg-editor/docs/methods#selection.setAtStart
                    }

                    const url = doc.getUrl();
                    if (url) {
                        // inserting a link using: https://www.froala.com/wysiwyg-editor/docs/methods#link.insert
                        editor.link.insert(UrlUtilities.getAbsoluteUrl(url), doc.getName(), { target: "_blank" });
                    } else if (linkElement) { // remove for previously selected link
                        editor.link.remove();
                    }
                }
            }
        }
    }
}

import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { CommonPurposeVisionAuthService } from "@org-common/lib/purpose-vision/common-purpose-vision-auth.service";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { GuidingPhilosophyPageComponent } from "./guiding-philosophy-page.component";

export const guidingPhilosophyRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-guiding-philosophy-page", GuidingPhilosophyPageComponent)
    .atOrganisationUrl("/purpose-and-vision")
    .verifyingFeatures(FeatureName.PurposeAndVision)
    .verifyingAccess(CommonPurposeVisionAuthService.ReadPurposeVision)
    .withTitle("Purpose & vision")
    .withSearchKeyword("Founding Story")
    .withSearchKeyword("Value Stream Purpose")
    .build();

import { Injectable } from "@angular/core";
import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { ExternalDashboard } from "@common/ADAPT.Common.Model/organisation/external-dashboard";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { NavigationNodeBuilder } from "@common/route/navigation-node-builder";
import { NavigationUtilitiesService } from "@common/route/navigation-utilities.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AuthorisationNotificationService } from "@org-common/lib/authorisation/authorisation-notification.service";
import { KanbanService } from "@org-common/lib/kanban/kanban.service";
import { teamKanbanPageRoute } from "@org-common/lib/kanban/kanban-page/kanban-page.route";
import { TeamMeetingsWithCadencePageRoute } from "@org-common/lib/meetings/team-meetings-with-cadence-page/team-meetings-with-cadence-page.component";
import { ObjectivesAuthService } from "@org-common/lib/objectives/objectives-auth.service";
import { teamObjectivesPageRoute } from "@org-common/lib/objectives/objectives-page/objectives-page.route";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { OrganisationAuthService } from "@org-common/lib/organisation/organisation-auth.service";
import { BaseOrgCommonNavigationHierarchyService } from "@org-common/lib/sidebar/base-org-common-navigation-hierarchy.service";
import { analyseTeamAssessmentSurveyPageRoute } from "@org-common/lib/survey/analyse-survey-page/analyse-survey-page.route";
import { manageTeamAssessmentsPageRoute } from "@org-common/lib/survey/team-assessment/manage-team-assessments-page/manage-team-assessments-page.route";
import { TeamAssessmentAuthService } from "@org-common/lib/survey/team-assessment/team-assessment-auth.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";
import { TeamPrivateIconComponent } from "@org-common/lib/teams/team-dashboard-shared/team-private-icon/team-private-icon.component";
import { filter, lastValueFrom, merge, switchMap } from "rxjs";
import { debounceTime, tap } from "rxjs/operators";
import { teamDashboardPageRoute } from "../team/team-dashboard-page/team-dashboard-page.route";

@Injectable()
export class TeamHierarchyNavigationService extends BaseOrgCommonNavigationHierarchyService {
    public static readonly HierarchyId = "ADAPT.Teams.Navigation.Hierarchy";
    public static readonly IconClass = "fal fa-fw fa-users";

    public id = TeamHierarchyNavigationService.HierarchyId;

    // store the count of boards the user has access to
    // so we can refresh the team hierarchy when this number changes
    private boardCount = 0;

    public constructor(
        private navUtilitiesFactory: NavigationUtilitiesService,
        private authService: AuthorisationService,
        private orgService: OrganisationService,
        authNotification: AuthorisationNotificationService,
        rxjsBreezeService: RxjsBreezeService,
        private teamsService: CommonTeamsService,
        private kanbanService: KanbanService,
    ) {
        super(orgService, authNotification);
        this.rebuildHierarchy$ = merge(
            rxjsBreezeService.entityTypeChanged(Team), // still need this for team name change
            rxjsBreezeService.entityTypeChanged(ExternalDashboard),
            // this will determine if work node will be shown
            rxjsBreezeService.entityTypeChanged(Board).pipe(
                switchMap(() => kanbanService.getAllAccessibleBoards()),
                filter((boards) => this.boardCount !== boards.length),
                tap((boards) => this.boardCount = boards.length),
            ),
        ).pipe(
            debounceTime(100), // only a single rebuild required for multiple boards loaded
        );
    }

    protected async buildHierarchy() {
        const team = await this.teamsService.promiseToGetLeadershipTeam();
        if (!team) {
            // don't build anything if no teams
            return new NavigationNodeBuilder().build();
        }

        const teamParams = {
            teamId: team.teamId,
        };

        const builder = this.navUtilitiesFactory.nodeBuilderForControllerAndParams(teamDashboardPageRoute.id, teamParams)
            .setTitle(`${team.name} Team`)
            .keepChildrenInAddedOrder()
            .setHideIconInBreadcrumb(true)
            .setCustomKeyValue("displayAsAccordionInHierarchy", true)
            .setCustomKeyValue("expandOnLoad", true);

        const hasExternalDashboardAccess = await this.authService.promiseToGetHasAccess(OrganisationAuthService.ViewExternalDashboard, team);
        if (hasExternalDashboardAccess) {
            const externalDashboards = await this.orgService.promiseToGetExternalDashboards(team.teamId);
            const externalDashboardNodes = externalDashboards.map(
                (externalDashboard) => this.promiseToBuildExternalDashboardNode(externalDashboard, this.navUtilitiesFactory, team));
            builder.promiseToAddChildren(externalDashboardNodes);
        }

        const promiseToBuildTeamObjectivesNode = this.navUtilitiesFactory
            .nodeBuilderForControllerAndParams(teamObjectivesPageRoute.id, teamParams)
            .ifAuthorised(ObjectivesAuthService.ReadObjectivesForTeam, team)
            .promiseToBuild();
        const promiseToBuildTeamWorkNode = this.navUtilitiesFactory
            .nodeBuilderForControllerAndParams(teamKanbanPageRoute.id, teamParams)
            .ifAuthorised(CommonTeamsAuthService.ViewTeamKanban, team)
            .promiseToBuild();
        const promiseToBuildTeamMeetingsNode = this.navUtilitiesFactory
            .nodeBuilderForControllerAndParams(TeamMeetingsWithCadencePageRoute.id, teamParams)
            .ifAuthorised(CommonTeamsAuthService.ViewTeamMeetings, team)
            .promiseToBuild();

        const promiseToBuildAnalyseTeamAssessmentNode = this.navUtilitiesFactory
            .nodeBuilderForControllerAndParams(analyseTeamAssessmentSurveyPageRoute.id, teamParams)
            .ifAuthorised(TeamAssessmentAuthService.ReadTeamAssessment, team)
            .promiseToBuild();

        const promiseToBuildTeamAssessmentNode = this.navUtilitiesFactory
            .nodeBuilderForControllerAndParams(manageTeamAssessmentsPageRoute.id, teamParams)
            .ifAuthorised(TeamAssessmentAuthService.ReadTeamAssessment, team)
            .promiseToAddChild(promiseToBuildAnalyseTeamAssessmentNode)
            .promiseToBuild();

        builder
            .promiseToAddChild(promiseToBuildTeamObjectivesNode)
            .promiseToAddChild(promiseToBuildTeamWorkNode)
            .promiseToAddChild(promiseToBuildTeamMeetingsNode)
            .promiseToAddChild(promiseToBuildTeamAssessmentNode);

        if (team.isPrivate) {
            builder.setIconClass(TeamPrivateIconComponent.IconClass)
                .setIconPositionRight(true);
        }

        // get this after all the teams have been fetched by the above
        const accessibleBoards = await lastValueFrom(this.kanbanService.getAllAccessibleBoards());
        this.boardCount = accessibleBoards.length;

        return builder.promiseToBuild();
    }
}

<span *ngIf="showIcon"
      class="role-icon">
    <i class="fal fa-fw fa-inverse fa-user-tag"
       aria-hidden="true"></i>
</span>

<!-- Text alignment is best if there aren't any new lines in this inline element
     (same for ng-template below) -->
<a *ngIf="(role$ | async) as role; else noRoleTemplate"
   [routerLink]="roleDescriptionUrl$ | async">
    <span adaptTooltip="This is a new / unsaved role"
          [tooltipEnabled]="role!.roleId < 1">{{role!.label}}</span></a>

<ng-template #noRoleTemplate>(No role allocated)</ng-template>

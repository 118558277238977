<div class="existing-comments"
     *ngIf="commentItems.length > 0; else noCommentTemplate">
    <ng-container *ngFor="let item of commentItems">
        <adapt-chain-comment *ngIf="$any(item).entity"
                             [chainItem]="$any(item)"
                             [justAdded]="item === justAddedComment"
                             (saving)="saving.emit($event.entity)"
                             (saved)="saved.emit($event.entity)"
                             (saveFailed)="saveFailed.emit({e: $event.e, entity: $event.item.entity})"
                             (deleted)="onDeleted($event)">
        </adapt-chain-comment>
        <adapt-chain-update *ngIf="$any(item).text"
                            [chainUpdate]="$any(item)"
                            (itemEdited)="chainUpdateEdited.emit($event)"
                            (itemDeleted)="chainUpdateDeleted.emit($event)">
        </adapt-chain-update>
    </ng-container>
</div>
<ng-template #noCommentTemplate>
    <div class="no-comment-text">
        {{noCommentsText}}
    </div>
</ng-template>

<ng-container *ngIf="newComment && newComment.canUpdate">
    <adapt-add-comment [(newComment)]="newComment!.comment"
                       [commentingPerson]="newComment!.person"
                       [allowSave]="allowNewCommentSave"
                       [doSave]="saveNewComment"
                       [focusOnInit]="focusAddCommentOnInit"
                       data-test="add-comment"></adapt-add-comment>
</ng-container>

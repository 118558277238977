import { Component, Input, ViewEncapsulation } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { BehaviorSubject, EMPTY, Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { CommonTeamsService } from "../common-teams.service";

@Component({
    selector: "adapt-team-membership",
    templateUrl: "./team-membership.component.html",
    styleUrls: ["./team-membership.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class TeamMembershipComponent {
    @Input()
    public set team(value: Team | undefined) {
        this.team$.next(value);
    }

    public team$ = new BehaviorSubject<Team | undefined>(undefined);
    public activeTeamMembers$: Observable<Person[]>;

    public constructor(
        private teamsService: CommonTeamsService,
    ) {
        this.activeTeamMembers$ = this.team$.pipe(
            switchMap((team) => {
                return team
                    ? this.teamsService.promiseToGetLatestTeamMembers(team)
                    : EMPTY;
            }, (team, teamMembers) => [team, teamMembers] as const),
            map(([team, teamMembers]) => {
                return teamMembers
                    .filter((p) => p !== team!.teamLeaderPerson)
                    .sort(SortUtilities.getSortByFieldFunction((p) => p.fullName));
            }),
        );
    }
}

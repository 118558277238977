import { Component, ElementRef } from "@angular/core";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import { BehaviorSubject, Subject } from "rxjs";

export const ObjectiveDescriptionFormationStepSelector = "adapt-objective-description-formation-step";
export enum ObjectiveDescriptionFormationStep {
    WhatToAchieveAndWhyImportant,
    HowWeKnowSuccess,
    Review,
}

const WhatToAchieveStartTag = "<!-- What to achieve (start) -->";
const WhatToAchieveEndTag = "<!-- What to achieve (end) -->";
const WhyImportantStartTag = "<!-- Why important (start) -->";
const WhyImportantEndTag = "<!-- Why important (end) -->";
const HowWeKnowSuccessStartTag = "<!-- How we know success (start) -->";
const HowWeKnowSuccessEndTag = "<!-- How we know success (end) -->";

@WorkflowStepComponent(ObjectiveDescriptionFormationStepSelector)
@Component({
    selector: "adapt-objective-description-formation-step",
    templateUrl: "./objective-description-formation-step.component.html",
    styleUrl: "./objective-description-formation-step.component.scss",
})
export class ObjectiveDescriptionFormationStepComponent extends WorkflowStepComponentAdapter {
    public readonly ObjectiveDescriptionFormationStep = ObjectiveDescriptionFormationStep;

    public workflowGoToStep = new Subject<number>();
    public workflowStepCompleted = new BehaviorSubject<boolean>(true);
    public whatToAchieve = "";
    public whyImportantAndWhyNow = "";
    public howWeKnowSuccess = "";

    public isAchieveEditorValid = true;
    public isImportantEditorValid = true;
    public isSuccessEditorValid = true;


    private objective?: Objective;

    public constructor(
        elementRef: ElementRef,
    ) {
        super(elementRef);
    }

    public workflowStepOnInit() {
        this.objective = this.workflowStep?.workflow.runData as Objective;
        if (this.objective) {
            // this will emit to entitiesToConfirm to be handled by dialog discard
            this.emitEntityChange(this.workflowStep?.workflow.runData);

            if (this.objective.description) {
                this.whatToAchieve = StringUtilities.substringBetweenTags(this.objective.description, WhatToAchieveStartTag, WhatToAchieveEndTag);
                this.whyImportantAndWhyNow = StringUtilities.substringBetweenTags(this.objective.description, WhyImportantStartTag, WhyImportantEndTag);
                this.howWeKnowSuccess = StringUtilities.substringBetweenTags(this.objective.description, HowWeKnowSuccessStartTag, HowWeKnowSuccessEndTag);
            }
        }
    }

    public updateObjectiveDescription() {
        if (this.objective) {
            this.objective.description = "";
            if (this.whatToAchieve) {
                this.objective.description += `
                    <h4>What are you trying to achieve?</h4>
                    ${WhatToAchieveStartTag}
                    ${this.whatToAchieve}
                    ${WhatToAchieveEndTag}`;
            }

            if (this.whyImportantAndWhyNow) {
                this.objective.description += `
                    <h4>Why is it important and why now?</h4>
                    ${WhyImportantStartTag}
                    ${this.whyImportantAndWhyNow}
                    ${WhyImportantEndTag}`;
            }

            if (this.howWeKnowSuccess) {
                this.objective.description += `
                    <h4>How will we know we have been successful?</h4>
                    ${HowWeKnowSuccessStartTag}
                    ${this.howWeKnowSuccess}
                    ${HowWeKnowSuccessEndTag}`;
            }
        }
    }

    public areEditorsValid() {
        if (this.workflowStep?.customData === ObjectiveDescriptionFormationStep.WhatToAchieveAndWhyImportant) {
            this.workflowStepCompleted.next(this.isAchieveEditorValid && this.isImportantEditorValid);
        } else if (this.workflowStep?.customData === ObjectiveDescriptionFormationStep.HowWeKnowSuccess) {
            this.workflowStepCompleted.next(this.isSuccessEditorValid);
        }
    }
}

import { DefaultFinishButtons, DefaultNextButtons, GuidedTour } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourUtils } from "@common/lib/guided-tour/guided-tour.utils";

export const ProfilePageEditTourIdentifier = "ProfilePageEditTour";

export const ProfilePageEditTour: GuidedTour = {
    steps: [{
    title: "Start Editing",
    text: `<p>You can click on the <b>Edit</b> button to toggle between edit and view mode.</p>
        <p><b>Click on here now</b> to enter edit mode.</p>`,
    waitForAndAttachToElementSelector: `[data-tour="edit-mode-button"]`,
    attachTo: {
        on: "bottom-start",
    },
    advanceOnAttachedElementEvent: "click",
    showOn: () => {
        // this step will only be shown if in view mode
        const orgDiagNode = GuidedTourUtils.getFirstElementWithTextContent(
            `[data-tour="view-mode-button"]`);
        return !GuidedTourUtils.isElementVisible(orgDiagNode);
    },
}, {
    title: "Change profile picture",
    text: "You can click on the profile picture to upload your own picture.",
    canClickTarget: false,
    waitForAndAttachToElementSelector: "adapt-select-image",
    attachTo: {
        on: "bottom-start",
    },
    buttons: DefaultNextButtons,
}, {
    title: "Change profile name",
    text: "You can click on the Edit icon to change your name.",
    canClickTarget: false,
    waitForAndAttachToElementSelector: `[data-tour="edit-profile-name"]`,
    attachTo: {
        on: "bottom-start",
    },
    buttons: DefaultNextButtons,
}, {
    title: "Change contact details",
    text: "You can click on the Edit icon to update your contact details, such as your email or phone number.",
    canClickTarget: false,
    waitForAndAttachToElementSelector: `[data-tour="edit-contact-details-button"]`,
    attachTo: {
        on: "bottom-start",
    },
    buttons: DefaultNextButtons,
}, {
    title: "Finish Editing",
    text: `<p>You can click on the <b>View</b> button to finish editing.</p>
        <p><b>Click on here now</b> to exit edit mode.</p>`,
    waitForAndAttachToElementSelector: `[data-tour="view-mode-button"]`,
    attachTo: {
        on: "bottom-start",
    },
    advanceOnAttachedElementEvent: "click",
}, {
    title: "The End",
    text: "This concludes the tour about editing your profile.",
    buttons: DefaultFinishButtons,
}],
};

import { Component, EventEmitter, Output, ViewEncapsulation } from "@angular/core";

@Component({
    selector: "adapt-collapsible-button",
    templateUrl: "./collapsible-button.component.html",
    styleUrl: "./collapsible-button.component.scss",
    // classes from this component can be applied to collapsible element and its parent container by directive - so no encapsulation
    encapsulation: ViewEncapsulation.None,
})
export class CollapsibleButtonComponent {
    public isCollapsed = false;
    public isVertical = false;

    @Output() public buttonClicked = new EventEmitter<void>();
}

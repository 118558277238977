import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptFeatureModule } from "@common/feature/feature.module";
import { AdaptImplementationKitModule } from "@common/implementation-kit/implementation-kit.module";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptStyledDashboardElementModule } from "@common/ux/styled-dashboard-element/styled-dashboard-element.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptTwoColumnModule } from "@common/ux/two-column/two-column.module";
import { DxButtonGroupModule, DxListModule, DxSortableModule, DxTextAreaModule, DxTextBoxModule, DxValidationGroupModule, DxValidationSummaryModule } from "devextreme-angular";
import { AuthorisationService } from "../authorisation/authorisation.service";
import { AdaptCallToActionModule } from "../call-to-action/call-to-action.module";
import { AdaptFeaturesModule } from "../features/features.modules";
import { AdaptCreateValueWorkflowModule } from "./create-value-workflow/create-value.module";
import { EditValueDialogComponent } from "./edit-value-dialog/edit-value-dialog.component";
import { EditValueQuestionDialogComponent } from "./edit-value-question-dialog/edit-value-question-dialog.component";
import { ValueDescriptionComponent } from "./value-description/value-description.component";
import { ValuesConstitutionComponent } from "./values-constitution/values-constitution.component";
import { ValuesConstitutionAuthService } from "./values-constitution-auth.service";
import { AdaptValuesConstitutionCommonModule } from "./values-constitution-common.module";
import { ValuesConstitutionDashboardElementComponent } from "./values-constitution-dashboard-element/values-constitution-dashboard-element.component";
import { ValuesConstitutionListViewComponent } from "./values-constitution-list-view/values-constitution-list-view.component";
import { ValuesConstitutionPageComponent } from "./values-constitution-page/values-constitution-page.component";
import { teamValuesRoute, valuesConstitutionRoute } from "./values-constitution-page/values-constitution-page.route";
import { ValuesConstitutionWideViewComponent } from "./values-constitution-wide-view/values-constitution-wide-view.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,

        AdaptButtonModule,
        AdaptCallToActionModule,
        AdaptCommonDialogModule,
        AdaptValuesConstitutionCommonModule,
        AdaptCreateValueWorkflowModule,
        AdaptStyledDashboardElementModule,
        AdaptDashboardElementModule,
        AdaptDateModule,
        AdaptEntityValidatorModule,
        AdaptFeatureModule,
        AdaptFeaturesModule,
        AdaptHtmlEditorModule,
        AdaptImplementationKitModule,
        AdaptLoadingSpinnerModule,
        AdaptShellModule,
        AdaptTooltipModule,
        AdaptTwoColumnModule,

        DxButtonGroupModule,
        DxListModule,
        DxSortableModule,
        DxTextAreaModule,
        DxTextBoxModule,
        DxValidationGroupModule,
        DxValidationSummaryModule,
    ],
    declarations: [
        EditValueDialogComponent,
        EditValueQuestionDialogComponent,
        ValueDescriptionComponent,
        ValuesConstitutionComponent,
        ValuesConstitutionDashboardElementComponent,
        ValuesConstitutionListViewComponent,
        ValuesConstitutionPageComponent,
        ValuesConstitutionWideViewComponent,
    ],
    exports: [
        ValuesConstitutionComponent,
        ValuesConstitutionDashboardElementComponent,
        ValuesConstitutionListViewComponent,
        ValuesConstitutionWideViewComponent,
    ],
})
export class AdaptValuesConstitutionModule {
    public static readonly Routes = [
        ...valuesConstitutionRoute.routes,
        ...teamValuesRoute.routes,
    ];

    constructor(authorisationService: AuthorisationService) {
        ValuesConstitutionAuthService.registerAccess(authorisationService);
    }
}

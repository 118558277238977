import { Component, ElementRef, Injector } from "@angular/core";
import { IAdaptLinkObject } from "@common/route/route.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { setReturnPathSearchParam } from "@common/ux/base-routed.component";
import { MeetingAgendaItemComponent } from "@org-common/lib/meetings/meeting-agenda-component-registry";
import { StrategyGoalExpandParam } from "@org-common/lib/strategic-goals/strategic-goals/strategic-goals.component";
import { StrategyGoalsPageRoute } from "@org-common/lib/strategic-goals/strategic-goals-page/strategic-goals-page.component";
import { StrategyAuthService } from "@org-common/lib/strategy/strategy-auth.service";

@MeetingAgendaItemComponent("adapt-strategy-goal-agenda-item")
@Component({
    selector: "adapt-strategy-goal-agenda-item",
    templateUrl: "./strategy-goal-agenda-item.component.html",
})
export class StrategyGoalAgendaItemComponent extends BaseComponent {
    public readonly ReadStrategyGoal = StrategyAuthService.ReadStrategicGoals;

    public route?: IAdaptLinkObject;

    public constructor(
        elementRef: ElementRef,
        private injector: Injector,
    ) {
        super(elementRef);

        const searchParams = setReturnPathSearchParam(
            this.injector,
            { [StrategyGoalExpandParam]: true },
            StrategyGoalsPageRoute.id,
            "Return to meeting");
        StrategyGoalsPageRoute.getRouteObject(undefined, searchParams)
            .pipe(this.takeUntilDestroyed())
            .subscribe((route) => this.route = route);
    }
}

import { Component, Inject, OnInit } from "@angular/core";
import { PersonProfileCategory } from "@common/ADAPT.Common.Model/person/person-profile-category";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { IDxCustomRuleValidationCallbackEvent } from "@common/ux/dx.types";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";

@Component({
    selector: "adapt-edit-personal-profile-category-dialog",
    templateUrl: "./edit-personal-profile-category-dialog.component.html",
})
export class EditPersonalProfileCategoryDialogComponent extends BaseDialogWithDiscardConfirmationComponent<PersonProfileCategory> implements OnInit {
    public readonly dialogName = "EditPersonalProfileCategory";

    public title: string;

    private categories: PersonProfileCategory[] = [];

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public profileCategory: PersonProfileCategory,
        private directorySharedService: DirectorySharedService,
    ) {
        super();
        this.title = profileCategory.entityAspect.entityState.isAdded()
            ? "Add profile category"
            : "Edit profile category";
        this.autoResolveData = profileCategory;
    }

    public get entitiesToConfirm() {
        return [this.profileCategory];
    }

    public async ngOnInit() {
        this.categories = await this.directorySharedService.promiseToGetAllPersonProfileCategories();
    }

    @Autobind
    public validateDuplicates({ value }: IDxCustomRuleValidationCallbackEvent) {
        if (typeof value === "string") {
            const labelExists = this.categories
                .filter((category) => category !== this.profileCategory)
                .some((category) => category.label.trim().toLowerCase() === value.trim().toLowerCase());
            return !labelExists;
        }
        return false;
    }

    public get isValid() {
        return this.profileCategory.label
            && !this.profileCategory.entityAspect.hasValidationErrors
            && this.validateDuplicates({ value: this.profileCategory.label });
    }
}

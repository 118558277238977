import { Component, Inject, Injector } from "@angular/core";
import { Measurement, MeasurementType } from "@common/ADAPT.Common.Model/organisation/measurement";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { switchMap } from "rxjs";
import { StrategicGoalsService } from "../strategic-goals.service";

@Component({
    selector: "adapt-edit-measurement-score-dialog",
    templateUrl: "./edit-measurement-score-dialog.component.html",
    styleUrls: ["./edit-measurement-score-dialog.component.scss"],
})
export class EditMeasurementScoreDialogComponent extends BaseDialogWithDiscardConfirmationComponent<Measurement> {
    public readonly dialogName = "EditMeasurementScore";

    public title = "Edit Measurement Value";
    public firstTargetTimestamp: Date;
    protected entitiesToConfirm: IBreezeEntity<any>[];

    public constructor(
        injector: Injector,
        @Inject(ADAPT_DIALOG_DATA) public measurement: Measurement,
        private strategicGoalsService: StrategicGoalsService,
        private dialogService: AdaptCommonDialogService,
    ) {
        super(injector);
        this.entitiesToConfirm = [measurement];
        this.autoResolveData = measurement;

        if (this.isNew) {
            this.title = "Add measurement";
        }

        const targets = this.measurement.goal!.measurements.filter((i) => i.type === MeasurementType.Target);
        this.strategicGoalsService.orderMeasurementsByTimestamp(targets);
        this.firstTargetTimestamp = targets.length > 0 ? targets[0].timestamp : new Date();
        if (this.measurement.timestamp < this.firstTargetTimestamp) {
            this.measurement.timestamp = this.firstTargetTimestamp;
        }
    }

    public get isNew() {
        return this.measurement.entityAspect.entityState.isAdded();
    }

    @Autobind
    public deleteMeasurement() {
        const dialogData: IConfirmationDialogData = {
            title: "Deleting Measurement",
            message: `<p>You are about to delete this measurement from strategic goal with name:</p>
                <blockquote><b>${this.measurement.goal?.name}</b></blockquote>
                <p>If you choose to delete, they can no longer be recovered.</p>
                <p>Are you sure you want to continue?</p>`,
            confirmButtonText: "Confirm & Delete",
            cancelButtonText: "Cancel",
            checkboxMessage: "Check here to confirm that you want to delete the measurement permanently",
        };
        return this.dialogService.openConfirmationDialog(dialogData).pipe(
            switchMap(() => this.strategicGoalsService.remove(this.measurement)),
            switchMap(() => this.saveAndClose()),
        );
    }
}

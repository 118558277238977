import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptImplementationKitModule } from "@common/implementation-kit/implementation-kit.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptCollapsibleModule } from "@common/ux/collapsible/collapsible.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptSeeMoreModule } from "@common/ux/see-more/see-more.module";
import { AdaptTimeSchedulerModule } from "@common/ux/time-scheduler/time-scheduler.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AdaptMeetingsSharedModule } from "@org-common/lib/meetings/meetings-shared.module";
import { AdaptScheduleModule } from "@org-common/lib/schedule/schedule.module";
import { provideCadenceWorkflowFunction } from "@org-common/lib/schedule/schedule-ui.service";
import { AdaptValuesConstitutionModule } from "@org-common/lib/values-constitution/values-constitution.module";
import { DxCheckBoxModule, DxPopoverModule, DxProgressBarModule, DxSelectBoxModule, DxTemplateModule } from "devextreme-angular";
import { AnnualWorkshopSetCadenceComponent } from "./annual-workshop-set-cadence/annual-workshop-set-cadence.component";
import { CalendarIntegrationStepComponent } from "./calendar-integration-step/calendar-integration-step.component";
import { buildEstablishCadenceWorkflow } from "./establish-cadence-workflows";
import { FinishCadenceStepComponent } from "./finish-cadence-step/finish-cadence-step.component";
import { PysWorkshopSetCadenceComponent } from "./pys-workshop-set-cadence/pys-workshop-set-cadence.component";
import { ReviewCadenceStepComponent } from "./review-cadence-step/review-cadence-step.component";
import { SetCadenceCycleStepComponent } from "./set-cadence-cycle-step/set-cadence-cycle-step.component";
import { SetCadenceStepComponent } from "./set-cadence-step/set-cadence-step.component";

@NgModule({
    imports: [
        CommonModule,
        AdaptDateModule,
        AdaptTimeSchedulerModule,
        AdaptTooltipModule,
        AdaptScheduleModule,
        DxPopoverModule,
        DxTemplateModule,
        DxCheckBoxModule,
        DxSelectBoxModule,
        AdaptMeetingsSharedModule,
        AdaptButtonModule,
        AdaptSeeMoreModule,
        AdaptValuesConstitutionModule,
        AdaptLoadingSpinnerModule,
        AdaptAuthorisationModule,
        DxProgressBarModule,
        AdaptImplementationKitModule,
        AdaptCollapsibleModule,
    ],
    declarations: [
        SetCadenceStepComponent,
        ReviewCadenceStepComponent,
        CalendarIntegrationStepComponent,
        PysWorkshopSetCadenceComponent,
        AnnualWorkshopSetCadenceComponent,
        SetCadenceCycleStepComponent,
        FinishCadenceStepComponent,
    ],
    providers: [
        // schedule-ui service is needed in org-common for team-meetings-with-cadence-page component.
        // workflow resides in alto only so need to provide it
        provideCadenceWorkflowFunction(buildEstablishCadenceWorkflow),
    ],
})
export class AdaptPlanYourStrategyWorkflowModule {
    // prevent being optimised by tree-shaker
    public readonly workflowStepComponents = [
        CalendarIntegrationStepComponent,
        SetCadenceCycleStepComponent,
        SetCadenceStepComponent,
        ReviewCadenceStepComponent,
        FinishCadenceStepComponent,
    ];

    // prevent being optimised by tree-shaker
    public readonly meetingAgendaItemComponents = [
        PysWorkshopSetCadenceComponent,
        AnnualWorkshopSetCadenceComponent,
    ];
}

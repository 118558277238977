import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { behaviourMetadata, ValueQuestion } from "@common/ADAPT.Common.Model/organisation/value-question";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { DxValidationGroupComponent } from "devextreme-angular";

@Component({
    selector: "adapt-edit-behaviour",
    templateUrl: "./edit-behaviour.component.html",
    styleUrls: ["./edit-behaviour.component.scss"],
})
export class EditBehaviourComponent implements OnInit {
    @Input() public behaviour!: ValueQuestion;
    @Input() public readonly = false;
    @Output() public behaviourChange = new EventEmitter<ValueQuestion>();

    @ViewChild("validationGroup", { static: false }) public validationGroup?: DxValidationGroupComponent;

    public selectedBehaviour: boolean[] = [];
    public behaviourOptions = [behaviourMetadata.good, behaviourMetadata.bad];

    public get currentBehaviour() {
        switch (this.behaviour.goodBehaviour) {
            case true:
                return behaviourMetadata.good;
            case false:
                return behaviourMetadata.bad;
            default:
                return undefined;
        }
    }

    public ngOnInit() {
        this.selectedBehaviour = this.behaviour.goodBehaviour !== null ? [this.behaviour.goodBehaviour!] : [];

        // validate the input next digest
        setTimeout(() => this.validationGroup?.instance.validate());
    }

    @Autobind
    public validateBehaviourTypeSelected() {
        return this.behaviour.goodBehaviour !== null;
    }

    public updateBehaviourType(isGood: boolean) {
        this.behaviour.goodBehaviour = isGood;
        this.behaviourChange.emit(this.behaviour);
        this.validationGroup?.instance.validate();
    }
}

import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { DateFormats } from "@common/ux/date-formats";

@Pipe({
    name: "adaptDate",
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
    public transform(value: any): any {
        return super.transform(value, DateFormats.globalize.short);
    }
}

import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IEntityWithLabelLocations } from "@common/ADAPT.Common.Model/organisation/entity-with-label-locations";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { Label } from "@common/ADAPT.Common.Model/organisation/label";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxPositionUtilities } from "@common/ux/dx.types";
import { IAdaptMenuItem } from "@common/ux/menu/menu.component";
import animationPosition from "devextreme/animation/position";
import { forkJoin, lastValueFrom } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { LabellingService } from "../labelling.service";

@Component({
    selector: "adapt-recent-label-submenu-content",
    templateUrl: "./recent-label-submenu-content.component.html",
    styleUrls: ["./recent-label-submenu-content.component.scss"],
})
export class RecentLabelSubmenuContentComponent extends BaseComponent implements OnInit {
    // only set one of these input
    @Input() public set item(value: Item) {
        this.setEntity(value);
    }

    @Input() public set objective(value: Objective) {
        this.setEntity(value);
    }

    @Output() public itemClicked = new EventEmitter<void>();

    private relatedEntity?: IEntityWithLabelLocations;
    public itemMenu?: IAdaptMenuItem[];

    private submenuElement?: HTMLElement | null;

    public constructor(
        protected elementRef: ElementRef<HTMLElement>,
        private commonDataService: CommonDataService,
        private labellingService: LabellingService,
    ) {
        super(elementRef);

        this.labellingService.labelEntityChanged$.pipe(
            debounceTime(100),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.loadMenuItems());
    }

    public async ngOnInit() {
        this.submenuElement = this.elementRef.nativeElement.closest<HTMLElement>(".dx-submenu");
        if (this.submenuElement) {
            // only want the fade in animation. if we don't change it from "all", then the translate will also animate
            this.submenuElement.classList.add("label-submenu-no-animation");
        }

        await this.loadMenuItems();
    }

    public async loadMenuItems() {
        if (!this.relatedEntity) {
            throw new Error("At least one of related entity is expected to be set");
        }

        const labelMenuItems = await this.getLabelSubMenuForEntity(this.relatedEntity);
        this.itemMenu = labelMenuItems;

        setTimeout(() => {
            // attempt to reposition the submenu once opened
            // if you open at the right edge of screen, without this it will clip off the screen if the content is too large
            const submenuParent = this.submenuElement?.parentElement;
            if (this.submenuElement && submenuParent) {
                // dx doesn't expose this method in typescript, but it's there in JS land.
                // setup will calculate the correct new position for the submenu once the content has loaded
                (animationPosition as IDxPositionUtilities).setup(this.submenuElement!, {
                    at: "right top",
                    my: "left top",
                    collision: "flip",
                    of: submenuParent,
                });
            }
        });
    }

    private setEntity(entity: IEntityWithLabelLocations) {
        // Edit objective page: Moving from one objective to another, if submenu was loaded before, even if
        // we rebuild the menu, the instance in memory will still get the objective instance change.
        // As the menu is rebuilt, the old instance is going to be destroyed a few cycles later. This is not visible
        // on screen anymore - so don't really care if it is set or not. Don't have to throw error.
        if (!this.relatedEntity) {
            this.relatedEntity = entity;
        }
    }

    private getCurrentEntityLabelMenuItem(label: Label, entity: IEntityWithLabelLocations) {
        return {
            text: label.name,
            isChecked: true,
            onClick: () => this.removeLabelFromEntity(label, entity),
        } as IAdaptMenuItem;
    }

    private getUnusedLabelMenuItem(label: Label, entity: IEntityWithLabelLocations) {
        return {
            text: label.name,
            isChecked: false,
            onClick: () => this.addLabelToEntity(label, entity),
        } as IAdaptMenuItem;
    }

    private async addLabelToEntity(label: Label, entity: IEntityWithLabelLocations) {
        const labelLocation = await lastValueFrom(this.labellingService.createLabelLocationFromLabelForEntity(label, entity));
        await lastValueFrom(this.commonDataService.saveEntities([labelLocation]));
        this.itemClicked.emit();
    }

    private async removeLabelFromEntity(label: Label, entity: IEntityWithLabelLocations) {
        const labelLocation = entity.labelLocations.find((ll) => ll.label === label);
        if (labelLocation) {
            // update the last used of the label so that it will be top of the list once removed
            label.lastUsed = new Date();
            await lastValueFrom(this.commonDataService.remove(labelLocation));
            await lastValueFrom(this.commonDataService.saveEntities([labelLocation, label]));
        }
        this.itemClicked.emit();
    }

    private async getLabelSubMenuForEntity<T extends IEntityWithLabelLocations>(entity: T) {
        const [entityLabels, allLabels] = await lastValueFrom(forkJoin([
            this.labellingService.getLabelsForEntity(entity),
            this.labellingService.getAllLabels(),
        ]));

        const currentEntityLabelMenuItems = entityLabels
            .map((label) => this.getCurrentEntityLabelMenuItem(label, entity));

        const unusedLabels = SortUtilities.sortArrayReverseChronological(
            allLabels.filter((label) => !entityLabels.includes(label)),
            ({ lastUsed }) => lastUsed ?? new Date(0),
        );
        const unusedLabelMenuItems = unusedLabels
            .slice(0, 10)
            .map((label) => this.getUnusedLabelMenuItem(label, entity));

        // first label in unused labels should have a separator from the current labels
        if (currentEntityLabelMenuItems.length > 0 && unusedLabelMenuItems.length > 0) {
            unusedLabelMenuItems[0].separatorTop = true;
        }

        const items = currentEntityLabelMenuItems.concat(unusedLabelMenuItems);
        if (items.length === 0) {
            return [{
                text: "No labels are defined",
                disabled: true,
            }];
        }

        return items;
    }
}

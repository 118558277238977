<adapt-collapsible-search-row #row
                              [result]="result"
                              [matches]="matches"
                              [href$]="href$"
                              [crumbTemplate]="crumbs"
                              [snippetTemplate]="snippet"
                              [showFirstCrumb]="!isKeyResult(firstMatch)">
    <div class="d-flex flex-grow-1 align-items-center justify-content-between">
        <div class="d-flex align-items-center me-2">
            <adapt-link-person [showImage]="true"
                               [showText]="false"
                               imageSize="sm"
                               [personId]="result.assigneePersonId"></adapt-link-person>
            <adapt-link-objective class="ms-2"
                                  [objective]="objective"
                                  [generateHref]="false"
                                  [showStatusIcon]="false"
                                  [showStatus]="false"></adapt-link-objective>
            <ng-container *ngIf="objective?.teamId">
                <adapt-link-team class="ms-2 me-n1"
                                 [labelLinkDisabled]="true"
                                 [teamId]="objective!.teamId"></adapt-link-team>
            </ng-container>
        </div>

        <div class="flex-grow-1 text-break d-flex align-items-center">
            <adapt-highlighted-text [needle]="keyword"
                                    [haystack]="result.title"></adapt-highlighted-text>
            <adapt-display-labels class="ms-2"
                                  [linkDisabled]="true"
                                  [labelLocations]="labelLocations"></adapt-display-labels>
        </div>
    </div>
</adapt-collapsible-search-row>

<ng-template #snippet
             let-match="match">
    <!-- custom styling for key result previews -->
    <!-- can't just use ngIf as the match context needs to be passed to the default template -->
    <ng-container *ngTemplateOutlet="isKeyResult(match) ? keyResultTemplate : row.defaultSnippetTemplate; context: {match: match}">
    </ng-container>

    <ng-template #keyResultTemplate>
        <div class="flex-basis-100">
            <adapt-highlighted-text [needle]="keyword"
                                    [haystack]="match.snippet"></adapt-highlighted-text>
            <i class="ms-1 fal fa-key key-result-icon-sm"></i>
        </div>
    </ng-template>
</ng-template>


<ng-template #crumbs
             let-matched="match">
    <ng-container *ngIf="asMatch(matched) as match">
        <ng-container *ngFor="let crumb of match.field; let last = last">
            <span *ngIf="last && isComment(match) && match.extra; else crumbText">
                <adapt-link-person [showImage]="false"
                                   [showText]="true"
                                   [personId]="match.extra.personId"
                                   imageSize="xs"></adapt-link-person>
            </span>
            <ng-template #crumbText><span>{{crumb}}</span></ng-template>
            <span *ngIf="!last"
                  class="px-1">&gt;</span>
        </ng-container>
    </ng-container>
</ng-template>

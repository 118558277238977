import { BreezeModelBuilder } from "@common/ADAPT.Common.Model/breeze-model-builder";
import { BaseEntity } from "../base-entity";

export class Layout extends BaseEntity<Layout> {
    public layoutId!: number;
    public organisationId!: number;
    public configuration!: string;
}

export const LayoutBreezeModel = new BreezeModelBuilder("Layout", Layout)
    .withIdField()
    .isOrganisationEntity()
    .build();

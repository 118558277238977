<dx-data-grid id="allOrganisationsGrid"
              [dataSource]="gridData"
              [stateStoring]="gridHelper?.stateStoringOptions"
              [columnAutoWidth]="true"
              [allowColumnResizing]="true"
              [allowColumnReordering]="true"
              [rowAlternationEnabled]="true"
              [hoverStateEnabled]="true"
              [showRowLines]="true"
              [showBorders]="true"
              (onInitialized)="onGridInitialized($event)">
    <dxo-filter-row [visible]="true"></dxo-filter-row>
    <dxo-load-panel [enabled]="true"></dxo-load-panel>
    <dxo-paging [pageSize]="15"></dxo-paging>
    <dxo-pager [showPageSizeSelector]="true"
               [allowedPageSizes]="[15, 25, 50, 100, 250]"></dxo-pager>
    <dxo-column-fixing [enabled]="true"></dxo-column-fixing>

    <dxi-column caption="Name"
                dataField="organisation.name"
                dataType="string"
                sortOrder="asc"
                cellTemplate="nameCellTemplate"></dxi-column>

    <dxi-column caption="Coach"
                dataField="coaches"
                dataType="string"
                cellTemplate="coachCellTemplate"></dxi-column>

    <dxi-column caption="Active Customers"
                dataField="active"
                dataType="boolean"></dxi-column>

    <div *dxTemplate="let item of 'nameCellTemplate'">
        <button class="btn btn-link"
                (click)="switchToOrganisation(item.data.organisation)"
                [disabled]="isDisabled">{{item.data.organisation.name}}
        </button>
    </div>

    <div *dxTemplate="let item of 'coachCellTemplate'">
        <ul>
            <li *ngFor="let coachName of item.data.coaches">
                {{coachName}}
            </li>
        </ul>
    </div>
</dx-data-grid>

import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { ItemStatusMetadata } from "@common/ADAPT.Common.Model/organisation/item-status";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import moment from "moment";
import { BehaviorSubject, lastValueFrom } from "rxjs";
import { debounceTime, map, switchMap, tap } from "rxjs/operators";
import { KanbanService } from "../../kanban.service";
import { KanbanUiService } from "../../kanban-ui.service";
import { IItemFilterParameters } from "../item-filter-parameters.interface";
import { ItemGridComponent } from "../item-grid/item-grid.component";

export interface ISelectItemDialogInputData {
    team?: Team;
    linking?: boolean;
    linkSingle?: boolean;
    excludedItemIds?: number[];
}

export interface ISelectItemDialogResolveData {
    items: Item[];
    isNew?: boolean;
}

@Component({
    selector: "adapt-select-item-dialog",
    templateUrl: "./select-item-dialog.component.html",
    styleUrls: ["./select-item-dialog.component.scss"],
})
export class SelectItemDialogComponent extends BaseDialogComponent<ISelectItemDialogInputData, ISelectItemDialogResolveData> implements OnInit {
    public readonly dialogName = "SelectItemDialog";

    public readonly maxSearchResults = 250;
    public readonly maxMostRecentCount = 10;
    public readonly searchDebounceTime = 250;

    public readonly allStatusItems = [
        ItemStatusMetadata.Backlog,
        ItemStatusMetadata.ToDo,
        ItemStatusMetadata.InProgress,
        ItemStatusMetadata.Done,
        ItemStatusMetadata.Closed,
    ];

    private readonly defaultStatusItems = [
        ItemStatusMetadata.Backlog,
        ItemStatusMetadata.ToDo,
        ItemStatusMetadata.InProgress,
        ItemStatusMetadata.Done,
    ];

    @ViewChild(ItemGridComponent) public itemGridComponent?: ItemGridComponent;

    public searchTerm = "";
    public team?: Team;
    public selectedStatusItems = [...this.defaultStatusItems];
    public selectedPerson?: Person;
    public dates = [
        { text: "7 days", value: moment().startOf("day").subtract(7, "days").toDate() },
        { text: "2 weeks", value: moment().startOf("day").subtract(2, "weeks").toDate() },
        { text: "1 month", value: moment().startOf("day").subtract(1, "month").toDate() },
        { text: "3 months", value: moment().startOf("day").subtract(3, "months").toDate() },
        { text: "6 months", value: moment().startOf("day").subtract(6, "months").toDate() },
        { text: "1 year", value: moment().startOf("day").subtract(1, "year").toDate() },
        { text: "2 years", value: moment().startOf("day").subtract(2, "years").toDate() },
        { text: "3 years", value: moment().startOf("day").subtract(3, "years").toDate() },
    ];
    public updatedDate?: Date;
    public includeArchivedBoards = false;

    public searchChanged$ = new BehaviorSubject<boolean>(true);

    public showMostRecent = false;
    public loadingItems = false;
    public showAdvanced = false;

    public items: Item[] = [];
    public selectedItems: Item[] = [];

    public title: string;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public dialogData: ISelectItemDialogInputData,
        private kanbanService: KanbanService,
        private kanbanUiService: KanbanUiService,
    ) {
        super();

        this.title = this.dialogData.linking
            ? "Link action"
            : "Search actions";

        this.team = this.dialogData.team;

        this.searchChanged$.pipe(
            debounceTime(this.searchDebounceTime),
            tap(() => this.loadingItems = true),
            tap(async () => {
                const filterParameters: IItemFilterParameters = {
                    text: this.searchTerm,
                    team: this.team,
                    excludedItemIds: this.dialogData.excludedItemIds,
                    assignee: this.selectedPerson,
                    statuses: this.selectedStatusItems.map((status) => status.status),
                    updatedDate: this.updatedDate,
                    includeArchivedBoards: this.includeArchivedBoards,
                };

                // no team in default filter options
                this.showMostRecent = !this.team && !this.hasFilterChanged;
                const additionalOptions = {
                    top: this.showMostRecent ? this.maxMostRecentCount : this.maxSearchResults,
                };

                this.items = await lastValueFrom(this.kanbanService.getItemsByFilterParameters(filterParameters, additionalOptions));
                this.items = this.items
                    .filter((i) => !i.entityAspect.entityState.isAdded())
                    .sort((a, b) => b.createdDateTime.valueOf() - a.createdDateTime.valueOf());
            }),
            tap(() => this.loadingItems = false),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public get hasFilterChanged() {
        return this.team !== this.dialogData.team
            || this.searchTerm !== ""
            || this.selectedPerson
            || this.updatedDate
            || this.selectedStatusItems.length !== this.defaultStatusItems.length;
    }

    public async ngOnInit() {
        this.searchChanged$.next(true);
    }

    public selectionChanged(items: Item[]) {
        this.selectedItems = items;
    }

    @Autobind
    public async select() {
        this.resolve({ items: this.selectedItems });
    }

    public onValueChange() {
        this.searchChanged$.next(true);
    }

    public resetFilter() {
        this.team = this.dialogData.team;
        this.searchTerm = "";
        this.selectedPerson = undefined;
        this.updatedDate = undefined;
        this.selectedStatusItems = [...this.defaultStatusItems];
        this.showAdvanced = false;

        // reset the item grid as well, as when linking, they cannot reset it themselves
        this.itemGridComponent?.dxGridWrapperHelper.callGrid((g) => g.resetState());
    }

    public createItem() {
        const hasTeam = !!this.team;
        this.kanbanService.getAllAccessibleBoards().pipe(
            // kanbanUiService.createItem by default creates an item with the first board in a list of all the users accessible boards as a result even if
            // hidePersonalBoards is true the item will be created with a personal board as the default selected board
            map((boards) => boards.filter((board) => hasTeam ? board.teamId === this.team!.teamId : !board.isPersonalBoard)),
            switchMap((boards) => this.kanbanUiService.createItem(boards, {
                forceAllBoards: !hasTeam,
                hidePersonalBoards: !hasTeam,
                // don't want to prompt to add this to the current active meeting (too many dialogs and prompts...)
                skipMeetingAgendaItemLinkPrompt: true,
            })),
            emptyIfUndefinedOrNull(),
        ).subscribe((item) => this.resolve({ items: [item], isNew: true }));
        // Checked with Steve, he said keep this the same as before or I would have added ...this.selectedItems to the items above
        // to not lose selections before you create a new item.
        // Also he doesn't like the additional click if return back to the link item dialog after item creation with the created item selected.
        // As before, if you select a row to link, create a new item and discard all the previously selected items.
    }
}

import { Component, Input, OnInit } from "@angular/core";
import { GuidingPhilosophy } from "@common/ADAPT.Common.Model/organisation/guiding-philosophy";
import { Purpose } from "@common/ADAPT.Common.Model/organisation/purpose";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { CommonPurposeVisionService, SeedEntity, SeedEntityConfigs } from "@org-common/lib/purpose-vision/common-purpose-vision.service";
import { merge } from "rxjs";
import { startWith } from "rxjs/operators";
import { VisionSeeds } from "../../workflows/build-strategy-for-success/align-owners-vision/vision-seeds";
import { PurposeSeeds } from "../../workflows/build-strategy-for-success/define-owners-why/purpose-seeds";
import { ISeed, ISeedAnswers } from "../../workflows/shared/define-seed/seed.interface";

@Component({
    selector: "adapt-display-seeds",
    templateUrl: "./display-seeds.component.html",
    styleUrls: ["./display-seeds.component.scss"],
})
export class DisplaySeedsComponent extends BaseComponent implements OnInit {
    @Input() public seedType!: SeedEntity;

    public seeds!: ISeed<string>[];
    public seedAnswers: { question: string, answer: string }[] = [];

    private entityConfiguration?: SeedEntityConfigs;

    public constructor(
        private commonPurposeVisionService: CommonPurposeVisionService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        merge(
            rxjsBreezeService.entityTypeChanged(Purpose),
            rxjsBreezeService.entityTypeChanged(GuidingPhilosophy),
        ).pipe(
            startWith(undefined),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.seedAnswers = this.parseSeedAnswers());
    }

    public async ngOnInit() {
        this.entityConfiguration = await this.commonPurposeVisionService.getSeedEntity(this.seedType, false);

        switch (this.seedType) {
            case SeedEntity.Vision:
                this.seeds = VisionSeeds;
                break;
            case SeedEntity.Purpose:
                this.seeds = PurposeSeeds;
                break;
        }

        this.seedAnswers = this.parseSeedAnswers();
    }

    private parseSeedAnswers() {
        if (this.entityConfiguration && this.entityConfiguration.entity) {
            try {
                const value = this.entityConfiguration.entity[this.entityConfiguration.field];
                if (value) {
                    const seedAnswers: ISeedAnswers = JSON.parse(value);
                    const answerKeys = Object.keys(seedAnswers);
                    return this.seeds
                        .filter((seed) => answerKeys.includes(seed.key) && !!seedAnswers[seed.key])
                        .map((seed) => ({ question: seed.question, answer: seedAnswers[seed.key] }));
                }
            } catch (e) {
                return [];
            }
        }

        return [];
    }
}

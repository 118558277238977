// Descriptor for selected document
export class DocumentDescriptor {
    private iconClass: string;

    constructor(private name: string, private url?: string) {
        this.iconClass = "";
    }

    public setIconClass(iconClass: string) {
        this.iconClass = iconClass;
    }

    public getIconClass() {
        return this.iconClass;
    }

    public setName(name: string) {
        this.name = name;
    }

    public getName() {
        return this.name;
    }

    public getUrl() {
        return this.url;
    }

    public equals(other: DocumentDescriptor) {
        return this.iconClass === other.getIconClass()
            && this.name === other.getName()
            && this.url === other.getUrl();
    }
}

import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Person } from "../person/person";
import { Objective } from "./objective";

export class ObjectiveComment extends BaseEntity<ObjectiveComment> {
    public objectiveCommentId!: number;
    public objectiveId!: number;
    public comment!: string;
    public personId!: number;
    public dateTime!: Date;

    public objective!: Objective;
    public person!: Person;
}

export const ObjectiveCommentBreezeModel = new BreezeModelBuilder("ObjectiveComment", ObjectiveComment)
    .hasSource()
    .isOrganisationEntity()
    .persistChangedEntity()
    .build();

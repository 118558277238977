import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { ContentWithSplashImageModule } from "@common/ux/content-with-splash-image/content-with-splash-image.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptDirectorySharedModule } from "../directory-shared/directory-shared.module";
import { CallToActionComponent } from "./call-to-action.component";
import { CallToActionButtonDirective } from "./call-to-action-button.directive";
import { CallToActionInlineComponent } from "./call-to-action-inline.component";
import { CallToActionTitleComponent } from "./call-to-action-title.component";
import { DismissibleCtaComponent } from "./dismissible-cta/dismissible-cta.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AdaptButtonModule,
        ContentWithSplashImageModule,
        AdaptDashboardElementModule,
        AdaptDirectorySharedModule,
        AdaptTooltipModule,
    ],
    exports: [
        CallToActionComponent,
        CallToActionInlineComponent,
        CallToActionTitleComponent,
        CallToActionButtonDirective,
        DismissibleCtaComponent,
    ],
    declarations: [
        CallToActionComponent,
        CallToActionInlineComponent,
        CallToActionTitleComponent,
        CallToActionButtonDirective,
        DismissibleCtaComponent,
    ],
})
export class AdaptCallToActionModule { }

<adapt-feature-guidance [configItem]="configItem">
    <p>A healthy organisational culture is at the core of the adapt approach.
        Having well-defined values means everyone has an agreed upon set of behaviours.</p>
</adapt-feature-guidance>

<adapt-feature-configuration [team]="team"
                             [configItem]="configItem">
    <button *ngIf="!usingValuesV2 && !team"
            class="btn btn-primary"
            adaptButtonIcon="merge"
            data-test="migrate-values"
            (click)="migrateValues()">Migrate to behaviour-based values</button>
</adapt-feature-configuration>

import { AfterViewInit, Component, ComponentFactoryResolver, Input, OnChanges, OnInit, SimpleChanges, Type, ViewChild, ViewContainerRef } from "@angular/core";
import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";
import { ContentWithSplashImageComponent } from "@common/ux/content-with-splash-image/content-with-splash-image.component";

@Component({
    selector: "adapt-call-to-action",
    templateUrl: "./call-to-action.component.html",
    styleUrls: ["./call-to-action.component.scss"],
})
export class CallToActionComponent implements OnInit, AfterViewInit, OnChanges {
    @Input() public fullPage = true;
    @Input() public showGetStartedBadge = true;
    @Input() public learnMoreUrl?: string;
    @Input() public image?: string;
    @Input() public blurImage = false;
    @Input() public contentComponent?: Type<unknown>;
    @Input() public noImage = false;

    // default to white background in cumulus
    @Input() public whiteBackground = AdaptClientConfiguration.AdaptProjectName !== AdaptProject.Alto;

    @ViewChild("contentContainerViewChild", { read: ViewContainerRef }) public vc?: ViewContainerRef;

    public imageStyles?: Partial<CSSStyleDeclaration>;
    @Input() public leftColumnWidthClasses = ContentWithSplashImageComponent.defaultLeftColumnWidthClasses;

    public constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
    ) { }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.noImage) {
            this.leftColumnWidthClasses = this.noImage
                ? "col-12"
                : ContentWithSplashImageComponent.defaultLeftColumnWidthClasses;
        }
    }

    public ngOnInit() {
        if (this.blurImage) {
            this.imageStyles = {
                filter: "blur(12px)",
                backgroundSize: "auto",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
            };
        }
    }

    public ngAfterViewInit() {
        if (this.contentComponent && this.vc) {
            this.vc.clear();

            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.contentComponent);
            this.vc.createComponent(componentFactory);
        }
    }
}

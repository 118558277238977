// eslint-disable-next-line max-classes-per-file
import { EntityExtensions } from "@common/ADAPT.Common.Model/entity-extensions.decorator";
import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Person } from "../person/person";
import { Board } from "./board";
import { IEntityWithLabelLocations } from "./entity-with-label-locations";
import { ItemComment } from "./item-comment";
import { ItemExtensions } from "./item-extensions";
import { ItemLink } from "./item-link";
import { ItemStatus } from "./item-status";
import { LabelLocation } from "./label-location";
import { MeetingItem } from "./meeting-item";
import { ObjectiveItemLink } from "./objective-item-link";
import { Organisation } from "./organisation";

export class Item extends BaseEntity<Item> implements IEntityWithLabelLocations {
    public itemId!: number;
    public organisationId!: number;
    public boardId!: number;
    public boardIndex!: number;
    public createdById!: number;
    public createdDateTime!: Date;
    public summary!: string;
    public description?: string;
    public assigneeId?: number;
    public status!: ItemStatus;
    public rank!: number;
    public dueDate?: Date;
    public lastUpdatedDateTime!: Date;

    public assignee?: Person;
    public board?: Board; // nav properties will have to be optional as they will go away if the current entity is detached
    public comments!: ItemComment[];
    public createdBy?: Person;
    public meetingItem?: MeetingItem;
    public organisation!: Organisation;
    public parentItem?: Item;
    public links!: ItemLink[];
    public objectiveItemLinks!: ObjectiveItemLink[];
    public labelLocations!: LabelLocation[];

    public get code() {
        return this.board
            ? (this.boardIndex
                ? this.board.itemPrefix + "-" + this.boardIndex
                : this.board.itemPrefix)
            : "";
    }

    @EntityExtensions(ItemExtensions)
    public extensions!: ItemExtensions;
}

export class ItemMetadata {
    public static readonly IconClass = "fal fa-fw fa-columns";
}

export const ItemBreezeModel = new BreezeModelBuilder("Item", Item)
    .withSingularName("Item")
    .withPluralName("Items")
    .hasSource()
    .withIdField("itemId")
    .isOrganisationEntity()
    .orderBy("rank")
    .withSortField("rank")
    .withStartsWithColumn("boardIndex")
    .persistChangedEntity()
    .addExpandSource("Board", "boardId")
    .addExpandSource("MeetingItem", "itemId")
    .addExpandSource("Person", "assigneeId")
    .addExpandSource("Person", "createdById")
    .build();

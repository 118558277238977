import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { MeetingAgendaTemplate } from "./meeting-agenda-template";
import { Organisation } from "./organisation";
import { Team } from "./team";

// these have to be in sync with DefaultEventType.cs
export enum EventTypePreset {
    SetFirstObjectives = "SFO",
    AnnualStrategy = "AS",
    QuarterlyStrategy = "QS",
    MonthlyStrategy = "MS",
    OKRCheckIn = "OCI",
}

export const AllEventTypePresets = [
    EventTypePreset.SetFirstObjectives,
    EventTypePreset.AnnualStrategy,
    EventTypePreset.MonthlyStrategy,
    EventTypePreset.QuarterlyStrategy,
    EventTypePreset.OKRCheckIn,
];

export enum EventTypeEntityType {
    Unknown = "Unknown",
    Meeting = "Meeting",
    Objective = "Objective",
    Workflow = "Workflow",
}

export class EventType extends BaseEntity<EventType> {
    public eventTypeId!: number;
    public organisationId!: number;
    public meetingAgendaTemplateId?: number;
    public teamId?: number;
    public eventEntityType!: EventTypeEntityType;
    public name!: string;
    public code!: string;
    public colour?: string;
    public durationInMinutes!: number;

    public organisation!: Organisation;
    public team?: Team;
    public meetingAgendaTemplate?: MeetingAgendaTemplate;
}

export const EventTypeBreezeModel = new BreezeModelBuilder("EventType", EventType)
    .hasSource()
    .withIdField()
    .isOrganisationEntity()
    .build();

import { Component, Input } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { BehaviorSubject, Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { teamValuesRoute, valuesConstitutionRoute } from "../values-constitution-page/values-constitution-page.route";

@Component({
    selector: "adapt-values-constitution-dashboard-element",
    templateUrl: "./values-constitution-dashboard-element.component.html",
})
export class ValuesConstitutionDashboardElementComponent {
    @Input() public set team(value: Team | undefined) {
        this.team$.next(value);
    }

    @Input() public mode?: "wide" | "list" = "list";

    public team$ = new BehaviorSubject<Team | undefined>(undefined);
    public vcUrl$: Observable<string>;

    public constructor() {
        this.vcUrl$ = this.team$.pipe(
            switchMap((team) => {
                return team
                    ? teamValuesRoute.getRoute({ teamId: team.teamId })
                    : valuesConstitutionRoute.getRoute();
            }),
        );
    }
}

import { Component, Injector } from "@angular/core";
import { SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";

@Component({
    selector: "adapt-manage-employee-engagements-page",
    templateUrl: "./manage-employee-engagements-page.component.html",
})
export class ManageEmployeeEngagementsPageComponent extends BaseRoutedComponent {
    public readonly surveyType = SurveyType.EmployeeEngagement;

    public constructor(injector: Injector) {
        super(injector);
    }

    public onInitialised() {
        this.notifyActivated(); // <-- this protected method cannot be accessed from template
    }
}

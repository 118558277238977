import { Injector, NgZone } from "@angular/core";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { Logger } from "@common/lib/logger/logger";
import { IDeferred, PromiseUtilities } from "@common/lib/utilities/promise-utilities";
import { HubConnection, HubConnectionState } from "@microsoft/signalr";
import { Observable, Subject, Subscription } from "rxjs";
import { filter } from "rxjs/operators";
import { SignalRInvokeError } from "../signalr-invoke-error";
import { ConnectionEvent } from "./connection-event.enum";
import { IConnectionState } from "./connection-state.interface";
import { DisconnectedState } from "./disconnected-state";

export type ConnectionEventHandler = (state: ConnectionEvent) => void;

export interface ISignalRConnectionContext {
    readonly hubName: string;
    readonly connection: HubConnection;

    promiseToConnect(): Promise<string>;
    promiseToInvokeServerHubMethod<T>(methodName: string, ...args: any[]): Promise<T>;
    disconnect(): void;
    subscribeToConnectionEvent(targetEvent: ConnectionEvent, handler: () => void): Subscription;
    connectionStateChanged$: Observable<ConnectionEvent>;
}

export class SignalRConnectionContext implements ISignalRConnectionContext {
    private static readonly Name = "SignalRConnectionContext";

    // deferred promise used so that we can invoke server methods once the connection is successful
    public connectionDeferred!: IDeferred<string>;
    public readonly log = Logger.getLogger(`${SignalRConnectionContext.Name}::${this.hubName}`);

    private zone: NgZone;
    private disconnectRequested = false;
    private _state: IConnectionState;

    private connectionStateChanged = new Subject<ConnectionEvent>();
    public connectionStateChanged$ = this.connectionStateChanged.asObservable();

    public constructor(
        public readonly injector: Injector,
        public readonly connection: HubConnection,
        public readonly hubName: string,
    ) {
        this.zone = injector.get<NgZone>(NgZone);

        this.setupConnectionHooks();

        // connectionDeferred set in here
        this._state = new DisconnectedState(this);
    }

    public get state() {
        return this._state;
    }

    public set state(newState: IConnectionState) {
        this.log.info(`Leaving ${ConnectionEvent[this._state.event]} state`);
        this.log.info(`Entering ${ConnectionEvent[newState.event]} state`);
        this._state = newState;
        this.raiseConnectionEvent(newState.event);
    }

    public subscribeToConnectionEvent(targetEvent: ConnectionEvent, handler: () => void) {
        return this.connectionStateChanged$.pipe(
            filter((event) => event === targetEvent),
        ).subscribe(() => handler());
    }

    public promiseToConnect() {
        this.state.connect();
        return this.connectionDeferred.promise;
    }

    public promiseToInvokeServerHubMethod<T>(methodName: string, ...args: any[]) {
        const deferred = PromiseUtilities.defer<T>();

        this.connectionDeferred.promise.then(() => {
            this.connection.invoke(methodName, ...args)
                .then((result) => deferred.resolve(result))
                .catch((e) => deferred.reject(new SignalRInvokeError(methodName, this.connection.state, e)));
        });

        return deferred.promise;
    }

    public disconnect() {
        this.disconnectRequested = true;

        this.state.disconnect();
        this.state = new DisconnectedState(this);

        this.disconnectRequested = false;
    }

    public raiseConnectionEvent(eventName: ConnectionEvent) {
        // Force a digest as our SignalR events occur outside of AngularJS
        this.zone.run(() => {
            this.connectionStateChanged.next(eventName);
        });
    }

    public resetConnectionPromise(rejectReason?: string) {
        if (rejectReason) {
            this.log.warn(rejectReason);
            this.connectionDeferred.reject(rejectReason);
        }

        this.connectionDeferred = PromiseUtilities.defer();
    }

    public promiseToStartConnection() {
        const deferred = PromiseUtilities.defer<void>();

        this.connection.start()
            .then(() => {
                if (this.connection.state === HubConnectionState.Disconnected) {
                    const error = new AdaptError("SignalR Connection promise resolved but is in the DisconnectedState");
                    deferred.reject(error);
                    return;
                }

                deferred.resolve();
            })
            .catch((error) => deferred.reject(error));

        return deferred.promise;
    }

    private setupConnectionHooks() {
        this.connection.onreconnecting(() => this.state.interruptConnection());
        this.connection.onreconnected(() => this.state.reestablishExistingConnection());
        this.connection.onclose(() => {
            if (!this.disconnectRequested) {
                this.state.startReconnecting();
            }
        });
    }
}

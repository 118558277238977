import { Component, ElementRef, Injector, Input } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { setPageActivationSearchParam, setReturnPathSearchParam } from "@common/ux/base-routed.component";
import { ObjectivesAuthService } from "@org-common/lib/objectives/objectives-auth.service";
import { organisationObjectivesPageRoute } from "@org-common/lib/objectives/objectives-page/objectives-page.route";
import { TourIdentifier } from "../../../tours/tour-identifier.enum";

@Component({
    selector: "adapt-objectives-page-navigator",
    templateUrl: "./objectives-page-navigator.component.html",
})
export class ObjectivesPageNavigatorComponent extends BaseComponent {
    @Input() public buttonText = "See the Objectives Page";
    @Input() public pageActivationTours: TourIdentifier[] = [];

    public readonly EditObjectives = ObjectivesAuthService.EditObjectives;

    public constructor(
        elementRef: ElementRef,
        private injector: Injector,
    ) {
        super(elementRef);
    }

    @Autobind
    public gotoObjectivesPage() {
        let searchParams = setReturnPathSearchParam(
            this.injector,
            {},
            organisationObjectivesPageRoute.id,
            "Return to meeting");
        if (this.pageActivationTours?.length) {
            searchParams = setPageActivationSearchParam(searchParams, organisationObjectivesPageRoute.id, this.pageActivationTours);
        }

        return organisationObjectivesPageRoute.gotoRoute(undefined, searchParams);
    }
}

<adapt-collapsible-search-row #row
                              [result]="result"
                              [matches]="matches"
                              [href$]="href$"
                              [snippetTemplate]="snippet">
    <div class="d-flex flex-grow-1 align-items-center justify-content-between">
        <div class="d-flex flex-grow-0 me-2 align-items-center text-muted">
            <div class="d-flex align-items-center result-icon"
                 adaptTooltip="Value">
                <i class="fal fa-fw fa-heart fa-inverse"></i>
            </div>

            <div>
                <ng-container *ngIf="result.teamId; else orgText">
                    <adapt-link-team class="ms-2 me-2"
                                     [labelLinkDisabled]="true"
                                     [teamId]="result.teamId"></adapt-link-team>&gt;
                </ng-container>
                <ng-template #orgText>
                    <!-- only org-wide p&v exist in alto -->
                    <ng-container *adaptIfAlto="false">
                        <span class="ms-2 me-2">Organisation</span>&gt;
                    </ng-container>
                </ng-template>
            </div>
        </div>

        <div class="flex-grow-1 text-break d-flex align-items-center">
            <adapt-highlighted-text [needle]="keyword"
                                    [haystack]="result.name"></adapt-highlighted-text>
        </div>
    </div>
</adapt-collapsible-search-row>

<ng-template #snippet
             let-match="match">
    <!-- custom styling for behaviours -->
    <ng-container *ngIf="isBehaviour(match); else defaultTemplate">
        <em class="flex-basis-100">
            <i *ngIf="getBehaviour(match) as currBehaviour"
               [adaptTooltip]="currBehaviour.tooltip"
               [ngClass]="['text-' + currBehaviour.type, currBehaviour.icon, 'behaviour-icon']"></i>
            &ldquo;<adapt-highlighted-text [needle]="keyword"
                                           [haystack]="match.snippet"></adapt-highlighted-text>&rdquo;
        </em>
    </ng-container>

    <ng-template #defaultTemplate>
        <ng-container *ngTemplateOutlet="defaultSnippetTemplate; context: { match }"></ng-container>
    </ng-template>
</ng-template>

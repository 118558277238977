import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { providePurposeVisionPageRoute } from "@common/page-route-providers";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDashboardElementModule } from "@common/ux/dashboard-element/dashboard-element.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptEditSimpleValueBreezeEntityDialogModule } from "@common/ux/edit-simple-value-breeze-entity-dialog/edit-simple-value-breeze-entity-dialog.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptSeeMoreModule } from "@common/ux/see-more/see-more.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AdaptCallToActionModule } from "@org-common/lib/call-to-action/call-to-action.module";
import { AdaptCommonPurposeVisionModule } from "@org-common/lib/purpose-vision/common-purpose-vision.module";
import { provideShowOwnersAlignmentAction, provideShowOwnersWhyAction } from "@org-common/lib/strategy/strategic-guidance/strategic-guidance.component";
import { DxPopupModule, DxScrollViewModule, DxTabPanelModule } from "devextreme-angular";
import { DisplaySeedsComponent } from "./display-seeds/display-seeds.component";
import { PurposeVisionPageComponent } from "./purpose-vision-page/purpose-vision-page.component";
import { purposeVisionPageRoute } from "./purpose-vision-page/purpose-vision-page.route";
import { PurposeVisionUiService } from "./purpose-vision-ui.service";
import { SeedsDialogComponent } from "./seeds-dialog/seeds-dialog.component";

@NgModule({
    imports: [
        CommonModule,

        AdaptAuthorisationModule,
        AdaptButtonModule,
        AdaptCallToActionModule,
        AdaptCommonDialogModule,
        AdaptCommonPurposeVisionModule,
        AdaptDashboardElementModule,
        AdaptDateModule,
        AdaptEditSimpleValueBreezeEntityDialogModule,
        AdaptHtmlEditorModule,
        AdaptLoadingSpinnerModule,
        AdaptSeeMoreModule,
        AdaptShellModule,
        AdaptTooltipModule,

        DxPopupModule,
        DxScrollViewModule,
        DxTabPanelModule,
    ],
    declarations: [
        PurposeVisionPageComponent,
        DisplaySeedsComponent,
        SeedsDialogComponent,
    ],
    exports: [
        DisplaySeedsComponent,
    ],
    providers: [
        providePurposeVisionPageRoute(purposeVisionPageRoute),
        provideShowOwnersWhyAction((injector) => injector.get(PurposeVisionUiService).showOwnersWhy()),
        provideShowOwnersAlignmentAction((injector) => injector.get(PurposeVisionUiService).showOwnersAlignment()),
    ],
})
export class AdaptPurposeVisionModule {
    public static readonly Routes = [
        ...purposeVisionPageRoute.routes,
    ];
}

import { DefaultCancelProceedButtons, DefaultFinishButtons, GuidedTour } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourUtils } from "@common/lib/guided-tour/guided-tour.utils";
import { HasAnnualObjectiveFunction } from "./objective-feature-tour";

export const HasAddKeyResultButton = () => {
    const addKeyResultButton = GuidedTourUtils.getFirstElementWithTextContent(`button[title="Add key result"]`);
    return GuidedTourUtils.isElementVisible(addKeyResultButton);
};

export const CreateKeyResultsForObjectiveFeatureTour: GuidedTour = {
    id: "CreateKeyResultsForObjectiveFeatureTour",
    saveRunStatus: true,
    steps: [{
        title: "Create key results for objectives",
        text: `<p>This tour will guide you through the process of creating key results for an objective.</p>`,
        buttons: DefaultCancelProceedButtons,
    }, {
        title: "Navigate to objective page",
        text: `<p>To create key results for an objective, you will need to navigate to the objective page first.</p>
        <p>This can be accomplished by clicking on the objective title.</p>
        <p><b>Click on the title now</b> to navigate to the objective page.</p>`,
        waitForAndAttachToElementSelector: `.display-tree-objective adapt-link-objective a`,
        attachTo: {
            on: "bottom",
        },
        advanceOnAttachedElementEvent: "click",
        waitForPageActivationAfterEvent: true,
        showOn: HasAnnualObjectiveFunction,
    }, {
        title: "Adding key result",
        text: `<p>Click on this button to add a key result.</p>`,
        waitForAndAttachToElementSelector: `[data-tour="add-key-result"]`,
        canClickTarget: false,
        attachTo: {
            on: "left",
        },
        showOn: HasAddKeyResultButton,
        buttons: DefaultFinishButtons,
    }],
};

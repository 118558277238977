import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent, DialogResolveData } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { SelectPersonComponent } from "@org-common/lib/directory-shared/select-person/select-person.component";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { KanbanAuthService } from "../../kanban-auth.service";

export interface IItemBatchSelectPersonData {
    boards: Board[];
    isPersonalBoardView: boolean;
}

@Component({
    selector: "adapt-item-batch-select-person-dialog",
    templateUrl: "./item-batch-select-person-dialog.component.html",
})
export class ItemBatchSelectPersonDialogComponent extends BaseDialogComponent<IItemBatchSelectPersonData, Person | undefined> implements OnInit {
    public readonly dialogName = "ItemBatchSelectPersonDialog";

    public selectedPerson?: Person;
    private boardTeamMembers = new Map<Board, Person[]>();

    @ViewChild(SelectPersonComponent) public selectPersonComponent?: SelectPersonComponent;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public data: IItemBatchSelectPersonData,
        private commonTeamsService: CommonTeamsService,
        private kanbanAuthService: KanbanAuthService,
    ) {
        // if we set this to Required, it will error on undefined person
        super(DialogResolveData.NotRequired);
    }

    public async ngOnInit() {
        for (const board of this.data.boards) {
            if (board.isTeamBoard) {
                this.boardTeamMembers.set(board, await this.commonTeamsService.promiseToGetActiveTeamMembers(board.team!));
            } else if (board.isPersonalBoard) {
                this.boardTeamMembers.set(board, [board.person]);
            }
        }

        if (this.selectPersonComponent) {
            this.selectPersonComponent.reload();
        }
    }

    @Autobind
    public hasAccessToView(person: Person) {
        const isActiveCoach = !!person.getLatestConnection(ConnectionType.Coach)?.isActive();
        return this.data.boards.every((board) => {
            const boardMembers = this.boardTeamMembers.get(board) ?? [];
            return this.kanbanAuthService.personCanViewBoard(person, board)
                // Filter out people like TSLs who have Global write but we don't want them in the list
                && (board.isPublicReadAccess || boardMembers.includes(person) || isActiveCoach);
        });
    }
}

import { ElementRef, Injector } from "@angular/core";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { EMPTY, forkJoin, Observable } from "rxjs";
import { catchError, filter, map, switchMap, tap } from "rxjs/operators";
import { ShellContentElementHeightTracker } from "./shell-content-element-height-tracker";

export abstract class BaseUiService {
    protected dialogService: AdaptCommonDialogService;
    protected commonDataService: CommonDataService;

    protected constructor(injector: Injector) {
        this.dialogService = injector.get(AdaptCommonDialogService);
        this.commonDataService = injector.get(CommonDataService);
    }

    public trackShellContentElementHeight(
        el: ElementRef,
        contentElementSelector: string, // e.g. dx-list dx-scroll-view div#id
        minElementHeight: number,
        bottomMargin?: number,
        maxElementHeight?: () => number,
    ) {
        return new ShellContentElementHeightTracker(el, contentElementSelector, minElementHeight, bottomMargin, maxElementHeight);
    }

    protected promptToDeleteAndSaveEntities(
        entities: IBreezeEntity[],
        title: string,
        message: string,
        checkboxMessage?: string,
    ) {
        const confirmationDialog$ = this.dialogService.openConfirmationDialogWithBoolean({
            title,
            message,
            confirmButtonText: "Delete",
            checkboxMessage,
        });

        return this.deleteAndSaveEntities(
            confirmationDialog$,
            entities,
            "Error " + title,
        );
    }

    protected deleteAndSaveEntities(
        confirmationDialog$: Observable<boolean | undefined>,
        entities: IBreezeEntity[],
        errorTitle: string,
    ) {
        return confirmationDialog$.pipe(
            filter((acceptDelete) => !!acceptDelete),
            switchMap(() => forkJoin(entities.map((entity) => this.commonDataService.remove(entity)))),
            switchMap(() => this.commonDataService.saveEntities(entities)),
            map(() => entities), // resolve with deleted obj
            catchError((err: AdaptError) => this.dialogService.showMessageDialog(
                errorTitle,
                `<p>Message: ${err.message}<p>
                 <p>Please try again.</p>`,
            ).pipe(
                tap(() => entities.map((entity) => entity.entityAspect.rejectChanges())),
                switchMap(() => EMPTY), // won't be triggering next for error
            )),
        );
    }
}

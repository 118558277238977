import { Component, ViewContainerRef } from "@angular/core";
import { ReplaySubject } from "rxjs";

// this is just a placeholder, which will be used to insert popup dialog - there is no content
@Component({
    selector: "adapt-dialog-root",
    template: "",
})
export class DialogRootComponent {
    public static readonly Id = "adaptDialogRoot";
    public static readonly ViewContainerInitialised = new ReplaySubject<ViewContainerRef>(1);

    public constructor(
        viewContainerRef: ViewContainerRef,
    ) {
        DialogRootComponent.ViewContainerInitialised.next(viewContainerRef);
    }
}

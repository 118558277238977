import "@common/vendor/scripts/box.js";
import { Injectable } from "@angular/core";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { UrlUtilities } from "@common/lib/utilities/url-utilities";
import { DocumentDescriptor } from "../document-descriptor";
import { IStorageProvider, StorageProviderGroup } from "./storage-provider.interface";
import { StorageProviderUtilities } from "./storage-provider-utilities";

declare const BoxSelect: any;

@Injectable()
export class BoxProviderService implements IStorageProvider {
    private static readonly ServiceName = "Box";

    public disabled: boolean = false;
    private clientId: string;
    private boxSelect?: any = undefined;

    constructor() {
        this.clientId = AdaptClientConfiguration.BoxClientId;
    }

    public setupComponent = () => Promise.resolve(); /* Nothing to do */
    public cleanupComponent(): void { /* Nothing to do */ }
    public onOrganisationChanged(): void { /* Nothing to do */ }

    public getName(): string {
        return BoxProviderService.ServiceName;
    }

    public getDisplayName(): string {
        return "Box";
    }

    public getGroupName = () => StorageProviderGroup.CLOUD;

    public getIconClass(): string {
        return "fal fa-fw fa-cube";
    }

    public getSelectionInProgressText(): string {
        return "Complete selection in the popup Box file chooser";
    }

    public getDocument(url: string): DocumentDescriptor | null {
        if (StringUtilities.isString(url)) {
            if (StorageProviderUtilities.getUrlServiceNameIdentifier(url) === BoxProviderService.ServiceName
                || url.indexOf(".box.com") > 0) {
                let name = UrlUtilities.getQueryParamValue(url, "name");

                if (!name) { // if link not created from our app, we won't be able to figure out the file name - just use filename from path
                    name = UrlUtilities.getFileName(url);
                }

                const result = new DocumentDescriptor(decodeURIComponent(name), url);

                result.setIconClass(this.getIconClass());
                return result;
            }
        }

        return null;
    }

    public openChooser(): Promise<DocumentDescriptor | null> {
        if (this.boxSelect) { // previously left behind without cancel or select
            this.boxSelect.closePopup();
            this.cleanup();
        }

        return new Promise((resolve, reject) => this.doChoose(resolve, reject));
    }

    private doChoose(resolve: (document: DocumentDescriptor | null) => void, reject: (e: any) => void) {
        const self = this;

        const options = {
            clientId: this.clientId,
            linkType: "shared", // shared link won't expire
            multiselect: false,
        };

        this.boxSelect = new BoxSelect(options);
        this.boxSelect.success(onSuccess);
        this.boxSelect.cancel(onCancel);
        this.boxSelect.launchPopup();

        function onSuccess(files: any[]) {
            self.cleanup();

            if (files.length === 1) {
                // with multiselect set to false, can only select a single file
                let link = files[0].url;
                const name = files[0].name;

                link = StorageProviderUtilities.addUrlServiceNameIdentifier(link, self);
                link = UrlUtilities.setQueryParam(link, "name", name);
                resolve(new DocumentDescriptor(name, link));
            } else {
                reject(null);
            }
        }

        function onCancel() {
            self.cleanup();
            reject(null);
        }
    }

    private cleanup() {
        if (this.boxSelect) {
            // cleanup according to: https://developer.box.com/docs/the-box-file-picker#section-using-javascript-apis
            this.boxSelect.unregister(this.boxSelect.SUCCESS_EVENT_TYPE);
            this.boxSelect.unregister(this.boxSelect.CANCEL_EVENT_TYPE);
            this.boxSelect = undefined;
        }
    }
}

import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { CommonComponentsPageComponent } from "./common-components-page.component";

export const commonComponentsPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-common-components-page", CommonComponentsPageComponent)
    .atUrl("/about/theme-test/common-components")
    .redirectToThisRouteFromUrl("/about/themetest")
    .redirectToThisRouteFromUrl("/about/theme-test")
    .requiresLogin(false)
    .build();

<div class="tab-container d-flex flex-column">
    <div class="tab-header py-1 px-2"
         [style]="{width: headerWidth, 'border-color': borderColour, 'background': backgroundColour}"
         [attr.data-tour]="tourName">
        <ng-content select="[header]"></ng-content>
    </div>
    <div class="tab-body"
         [style]="{'border-color': borderColour, 'background': backgroundColour}">
        <ng-content select="[body]"></ng-content>
    </div>
</div>

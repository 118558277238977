import { Component, Injector, OnInit } from "@angular/core";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { PageRouteBuilder } from "@common/route/page-route-builder";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";

@Component({
    selector: "adapt-error-page",
    templateUrl: "./error-page.component.html",
})
export class ErrorPageComponent extends BaseRoutedComponent implements OnInit {
    public page?: string;
    public errorMessage?: string;
    public platformName = AdaptClientConfiguration.AdaptProjectLabel;

    public constructor(injector: Injector) {
        super(injector);

        this.page = this.getSearchParameterValue("attemptedUrl")!;
        this.errorMessage = this.getSearchParameterValue("errorMessage");
    }

    public ngOnInit() {
        this.notifyActivated();
    }
}

export const ErrorPageRoute = new PageRouteBuilder()
    .usingNgComponent("adapt-error-page", ErrorPageComponent)
    .atUrl("/error")
    .requiresLogin(false)
    .requiresAnonymous(false)
    .build();

import { IBreezeModel } from "@common/lib/data/breeze-model.interface";
import { ModelRegistrar } from "@common/lib/data/model-registrar";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { Validator } from "breeze-client";
import { BaseEntity } from "./base-entity";

type EntityConstructor<T> = new (...args: any[]) => T;

/**
 * Take some of the pain out of building Breeze Models with this class
 * which automates some of the pain points and duplication.
 */
export class BreezeModelBuilder<T extends BaseEntity<T>> {
    private model: IBreezeModel<T>;
    private navigationProperties: string[] = [];

    /**
     * Creates a new builder based on the given type
     * @param typeName The name of the type in pascal case. The identifier (camel cased),
     * singularName (has spaces at capital letters), pluralName (singularName + 's'),
     * and toType (this value) fields will be auto generated from this value.
     * @param type The entity class to use for this model
     */
    public constructor(typeName: string, type: EntityConstructor<T>) {
        const firstLetter = typeName.substr(0, 1);
        if (firstLetter === firstLetter.toLocaleLowerCase()
            || typeName.match(/\s/)) {
            throw new Error("typeName must be PascalCase");
        }

        this.model = this.generateModelFromName(typeName);
        this.model.register = type;
    }

    public get modelReference() {
        return this.model;
    }

    /** Override the default generated singular name */
    public withSingularName(singularName: string) {
        this.model.singularName = singularName;
        return this;
    }

    /** Overrides the default generated plural name, also updating the source to be the
     * new plural name with no spaces
     */
    public withPluralName(pluralName: string) {
        if (this.model.source && this.model.source === StringUtilities.stripSpace(this.model.pluralName)) {
            this.model.source = StringUtilities.stripSpace(pluralName);
        }

        this.model.pluralName = pluralName;

        return this;
    }

    public withPluralNameAndNoSourceOverride(pluralName: string) {
        this.model.pluralName = pluralName;
        return this;
    }

    public overridePropertyDisplayName(propertyName: keyof T, displayName: string) {
        if (!this.model.propertyDisplayNameOverrides) {
            this.model.propertyDisplayNameOverrides = new Map<string, string>();
        }

        this.model.propertyDisplayNameOverrides.set(`${String(propertyName)}`, displayName);
        return this;
    }

    public withIdField(idFieldOverride?: keyof T) {
        if (idFieldOverride) {
            this.model.idField = `${String(idFieldOverride)}`;
        } else {
            this.model.idField = this.model.identifier + "Id";
        }

        return this;
    }

    // labelField can be something like "person.fullName", not just keyof T
    public withLabelField(labelField: keyof T | string) {
        this.model.labelField = String(labelField);
        return this;
    }

    public withSortField(sortField: keyof T | string) {
        this.model.sortField = String(sortField);
        return this;
    }

    public withSearchField(searchField: string) {
        this.model.searchField = searchField;
        return this;
    }

    /**
     * Flag that this model can be directly queried.
     * @param sourceEndpoint The name of the endpoint where queries can occur.
     * Will use the model's plural name with all spaces stripped by default.
     */
    public hasSource(sourceEndpoint?: string) {
        if (!sourceEndpoint) {
            sourceEndpoint = StringUtilities.stripSpace(this.model.pluralName);
        }

        this.model.source = sourceEndpoint;
        return this;
    }

    public isOrganisationEntity() {
        this.model.useOrganisationParam = true;
        return this;
    }

    public orderBy(property: keyof T) {
        this.model.orderBy = `${String(property)} ASC`;
        return this;
    }

    public orderByDesc(property: keyof T) {
        this.model.orderBy = `${String(property)} DESC`;
        return this;
    }

    public alwaysFetchingNavigationProperty(property: keyof T) {
        this.navigationProperties.push(String(property));
        return this;
    }

    public alwaysFetchingNestedNavigationProperty(nestedProperty: string) {
        this.navigationProperties.push(String(nestedProperty));
        return this;
    }

    public withPropertyValidator(name: keyof T, validator: Validator) {
        if (!this.model.validators) {
            this.model.validators = {};
        }

        this.model.validators[String(name)] = validator;
        return this;
    }

    public withEntityValidator(validator: Validator) {
        if (!this.model.validators) {
            this.model.validators = {};
        }

        this.model.validators[`entity${validator.name}`] = validator;
        return this;
    }

    public withLocationPath(path: keyof T | string) {
        this.model.locationPath = String(path);
        return this;
    }

    public withStartsWithColumn(column: keyof T) {
        this.model.startsWithColumn = String(column);
        return this;
    }

    // This is used by entity persistent service - defines how navProperties can be loaded.
    // navProperty won't be expanded if restoring from entity manager import - need to prime
    // immediately by editItem component (when other navPropery are not required,
    // primed in the component and allowed to be loaded later)
    public addExpandSource(modelShortName: string, idField: keyof T) {
        if (!this.model.expandSources) {
            this.model.expandSources = [];
        }

        this.model.expandSources.push({
            expandType: modelShortName,
            expandId: String(idField),
        });

        return this;
    }

    // This is used by entity persistent when a changed entity has no source (not endpoint to query)
    // and entity is acquired as part of the nav property of another source
    public setPrimeSource(sourceType: string, sourceId: string) {
        this.model.primeSource = {
            sourceType,
            sourceId,
        };

        return this;
    }

    public excludePropertyChangeEventForProperty(field: keyof T) {
        if (!this.model.excludePropertyChangeEventForProperties) {
            this.model.excludePropertyChangeEventForProperties = [];
        }

        this.model.excludePropertyChangeEventForProperties.push(String(field));
        return this;
    }

    public instanceIsActivePath() {
        return this.withActivePath("self");
    }

    public withActivePath(path: keyof T | string) {
        if (!this.model.activePaths) {
            this.model.activePaths = [];
        }

        this.model.activePaths.push(String(path));
        return this;
    }

    public persistChangedEntity() {
        this.model.persistChangedEntity = true;
        return this;
    }

    public withUniqueKeys(...uniqueKeys: (keyof T)[]) {
        this.model.uniqueKeys = uniqueKeys.map((k) => String(k));
        return this;
    }

    public withGroupByField(...fields: (keyof T | string)[]) {
        this.model.latestGroupByField = String(fields);
        return this;
    }

    public withGroupByGenerateKeyFunction(fn: (entity: T) => any[]) {
        this.model.latestGroupByGenerateKeyFunction = fn;
        return this;
    }

    public withDateField(field: keyof T | string) {
        this.model.dateField = String(field);
        return this;
    }

    public build() {
        if (this.navigationProperties.length > 0) {
            this.model.navProperty = this.navigationProperties.join(",");
        }

        ModelRegistrar.registerModel(this.model);

        return this.model;
    }

    private generateModelFromName(typeName: string): IBreezeModel<T> {
        const spacedTypeName = typeName.replace(/[A-Z]/g, (letter, index) => {
            return index > 0
                ? " " + letter
                : letter;
        });
        const camelCasedName = typeName[0].toLocaleLowerCase() + typeName.substring(1);

        return {
            identifier: camelCasedName,
            singularName: spacedTypeName,
            pluralName: spacedTypeName + "s",
            toType: typeName,
        };
    }
}

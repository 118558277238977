import { Component, ElementRef, Injector, OnInit } from "@angular/core";
import { Workflow, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { Subject } from "rxjs";
import { startWith } from "rxjs/operators";
import { OrganisationPageRouteBuilder } from "../../route/organisation-page-route-builder";
import { WorkflowService } from "../workflow.service";

export const RunWorkflowSearchParam = "runWorkflow";
export const WrapUpText = "Wrap up";
export const WorkflowIdSearchParam = "workflowId";

@Component({
    selector: "adapt-workflow-journey-page",
    templateUrl: "./workflow-journey-page.component.html",
    styleUrls: ["./workflow-journey-page.component.scss"],
})
export class WorkflowJourneyPageComponent extends BaseRoutedComponent implements OnInit {
    public workflow?: Workflow;
    public errorMessage?: string;
    public isCompleted = false;

    public startWorkflow$ = new Subject<void>();

    public constructor(
        elementRef: ElementRef,
        injector: Injector,
        private workflowService: WorkflowService,
    ) {
        super(injector, elementRef);
    }

    public async ngOnInit() {
        this.navigationEnd.pipe(
            startWith(undefined),
        ).subscribe(() => {
            this.shellUiService.removeDefaultShellPadding();
            this.updatePageWorkflow();
        });
    }

    public async onWorkflowCompletionStatus(isCompleted: boolean) {
        this.isCompleted = isCompleted;

        const runWorkflow = this.getSearchParameterBoolValue(RunWorkflowSearchParam);
        if (runWorkflow && this.workflow) {
            if (isCompleted) {
                await this.routeService.updateSearchParameterValue(RunWorkflowSearchParam, undefined, true);
            } else {
                this.startWorkflow$.next();
            }
        }
    }

    private updatePageWorkflow() {
        this.workflow = undefined;

        const workflowId = this.getRouteParam(WorkflowIdSearchParam);
        if (workflowId) {
            const localWorkflow = this.workflowService.getWorkflowById(workflowId);
            if (localWorkflow) {
                this.workflow = localWorkflow;
            } else {
                // we no longer want to implement server side workflow - if can't find local workflow, redirect
                // to workflows page
                this.errorMessage = `Workflow with id ${workflowId} cannot be found.`;
                this.workflow = undefined;
            }

            const isJourney = this.workflow?.type === WorkflowType.Journey;
            if (this.workflow?.isStateless) {
                // stateless workflow is not allowed on this page
                this.errorMessage = "Stateless workflow cannot be shown on this page";
                this.workflow = undefined;
            } else if (!isJourney) {
                this.errorMessage = "Only journey is allowed on this page";
                this.workflow = undefined;
            }

            this.notifyActivated();
        }
    }
}

export const WorkflowJourneyPageRoute = new OrganisationPageRouteBuilder<{ workflowId: string }>()
    .usingNgComponent("adapt-workflow-journey-page", WorkflowJourneyPageComponent)
    .atOrganisationUrl("/pathways/:workflowId")
    .withTitle("Pathway")
    .reloadOnSearch(false)
    .build();

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DxPopupModule, DxScrollViewModule, DxTemplateModule } from "devextreme-angular";
import { SeeMoreComponent } from "./see-more/see-more.component";
import { SeeMorePopupComponent } from "./see-more-popup/see-more-popup.component";

@NgModule({
    imports: [
        CommonModule,
        DxPopupModule,
        DxScrollViewModule,
        DxTemplateModule,
    ],
    exports: [
        SeeMoreComponent,
        SeeMorePopupComponent,
    ],
    declarations: [
        SeeMoreComponent,
        SeeMorePopupComponent,
    ],
})
export class AdaptSeeMoreModule {}

<ng-container [ngSwitch]="hasBeenMeasured">
    <adapt-progress-bar *ngSwitchCase="true"
                        [percentageProgress]="currentProgress"
                        [scaleHeight]="scaleHeight"></adapt-progress-bar>
    <div *ngSwitchCase="false"
         class="progress no-score-progress"
         [ngStyle]="{'height.rem': scaleHeight + 0.4}"> <!-- consistent progress-bar height.rem = scaleHeight + 0.4 -->
        This objective is yet to be scored.
    </div>
</ng-container>

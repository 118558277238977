import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input } from "@angular/core";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { BehaviorSubject, combineLatest, from, Observable, of } from "rxjs";
import { debounceTime, map, switchMap, tap } from "rxjs/operators";
import { NavigationHierarchyService } from "../navigation-hierarchy.service";
import { INavigationNode, INavigationNodeCustomData } from "../navigation-node.interface";
import { IAdaptLinkObject, RouteService } from "../route.service";

@Component({
    selector: "adapt-display-navigation-node",
    templateUrl: "./display-navigation-node.component.html",
    styleUrls: ["./display-navigation-node.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayNavigationNodeComponent extends BaseComponent {

    @Input() public set node(value: INavigationNode | undefined) {
        this.node$.next(value);
    }

    // TODO Can this be removed?
    @HostBinding("class.navigation-node") public isNavigationNode = true;
    @HostBinding("class.current-page") public isCurrentPage = false;
    @Input() public linkToPage = true;
    @Input() public isInBreadcrumb = false;
    @Input() public iconPositionRight = false;

    public node$ = new BehaviorSubject<INavigationNode | undefined>(undefined);
    public url$: Observable<IAdaptLinkObject | undefined>;

    public currentState: { node$id?: number } = {};

    public constructor(
        private routeService: RouteService,
        private navigationHierarchyService: NavigationHierarchyService,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        super();

        this.url$ = this.node$.pipe(
            switchMap((node) => {
                this.currentState = {
                    node$id: node?.$id,
                };
                if (this.linkToPage && node?.url) {
                    return of(node.url);
                } else if (node && this.isRedirectLink(node)) {
                    return from(this.routeService.getControllerRoute(node.customData.onClickGoToController)).pipe(
                        tap((url) => {
                            // change node$id for state (which will be used to determine next activeNode when this is clicked)
                            const destNode = navigationHierarchyService.getNodeByUrl(url);
                            if (destNode) {
                                this.currentState.node$id = destNode.$id;
                            }
                        }),
                    );
                } else {
                    return of(undefined);
                }
            }),
            map((urlString) => this.routeService.parseRouterObject(urlString)),
        );

        combineLatest([
            this.node$,
            navigationHierarchyService.activeNode$,
        ]).pipe(
            debounceTime(100), // added a little debounce here to workaround timing issue where active node is not selected
            this.takeUntilDestroyed(),
        ).subscribe(([thisNode, activeNode]) => {
            this.isCurrentPage = thisNode === activeNode;
            if (this.isCurrentPage) {
                thisNode?.setCurrentSelection();
            }
            
            this.changeDetectorRef.markForCheck();
        });
    }

    public onNodeClicked(node: INavigationNode) {
        this.navigationHierarchyService.processActiveNode(node);
    }

    private isRedirectLink(node: INavigationNode): node is INavigationNode & {
        customData: INavigationNodeCustomData & { onClickGoToController: string }
    } {
        return node && !node.url && node.customData && !!node.customData.onClickGoToController;
    }
}

import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ContactTypeMetadata } from "@common/ADAPT.Common.Model/embed/contact-type";
import { PersonContact } from "@common/ADAPT.Common.Model/person/person-contact";

@Component({
    selector: "adapt-contact-detail",
    templateUrl: "./contact-detail.component.html",
    styleUrls: ["./contact-detail.component.scss"],
})
export class ContactDetailComponent implements OnChanges {
    @Input() public detail!: PersonContact;
    @Input() public showIcon = true;
    public iconClass?: string;
    public url: string | null = null;

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.detail) {
            this.iconClass = ContactTypeMetadata.ByType[this.detail.contactType].iconClass;
            this.url = this.detail.url ? this.detail.url : null;
        }
    }
}

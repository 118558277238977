export enum EventSeriesDayOfWeek {
    Sunday = "Sunday",
    Monday = "Monday",
    Tuesday = "Tuesday",
    Wednesday = "Wednesday",
    Thursday = "Thursday",
    Friday = "Friday",
    Saturday = "Saturday",
}

export class EventSeriesDayOfWeekMetadata {
    public static readonly Sunday = new EventSeriesDayOfWeekMetadata(EventSeriesDayOfWeek.Sunday, "Sunday", 1);
    public static readonly Monday = new EventSeriesDayOfWeekMetadata(EventSeriesDayOfWeek.Monday, "Monday", 2);
    public static readonly Tuesday = new EventSeriesDayOfWeekMetadata(EventSeriesDayOfWeek.Tuesday, "Tuesday", 3);
    public static readonly Wednesday = new EventSeriesDayOfWeekMetadata(EventSeriesDayOfWeek.Wednesday, "Wednesday", 4);
    public static readonly Thursday = new EventSeriesDayOfWeekMetadata(EventSeriesDayOfWeek.Thursday, "Thursday", 5);
    public static readonly Friday = new EventSeriesDayOfWeekMetadata(EventSeriesDayOfWeek.Friday, "Friday", 6);
    public static readonly Saturday = new EventSeriesDayOfWeekMetadata(EventSeriesDayOfWeek.Saturday, "Saturday", 7);

    public static readonly All = [
        EventSeriesDayOfWeekMetadata.Sunday,
        EventSeriesDayOfWeekMetadata.Monday,
        EventSeriesDayOfWeekMetadata.Tuesday,
        EventSeriesDayOfWeekMetadata.Wednesday,
        EventSeriesDayOfWeekMetadata.Thursday,
        EventSeriesDayOfWeekMetadata.Friday,
        EventSeriesDayOfWeekMetadata.Saturday,
    ];

    public static readonly ByDayOfWeek: { [status in EventSeriesDayOfWeek]: EventSeriesDayOfWeekMetadata } = {
        [EventSeriesDayOfWeek.Sunday]: EventSeriesDayOfWeekMetadata.Sunday,
        [EventSeriesDayOfWeek.Monday]: EventSeriesDayOfWeekMetadata.Monday,
        [EventSeriesDayOfWeek.Tuesday]: EventSeriesDayOfWeekMetadata.Tuesday,
        [EventSeriesDayOfWeek.Wednesday]: EventSeriesDayOfWeekMetadata.Wednesday,
        [EventSeriesDayOfWeek.Thursday]: EventSeriesDayOfWeekMetadata.Thursday,
        [EventSeriesDayOfWeek.Friday]: EventSeriesDayOfWeekMetadata.Friday,
        [EventSeriesDayOfWeek.Saturday]: EventSeriesDayOfWeekMetadata.Saturday,
    };

    public static readonly ByIntValue: { [value: number]: EventSeriesDayOfWeekMetadata } = {
        [EventSeriesDayOfWeekMetadata.Sunday.value]: EventSeriesDayOfWeekMetadata.Sunday,
        [EventSeriesDayOfWeekMetadata.Monday.value]: EventSeriesDayOfWeekMetadata.Monday,
        [EventSeriesDayOfWeekMetadata.Tuesday.value]: EventSeriesDayOfWeekMetadata.Tuesday,
        [EventSeriesDayOfWeekMetadata.Wednesday.value]: EventSeriesDayOfWeekMetadata.Wednesday,
        [EventSeriesDayOfWeekMetadata.Thursday.value]: EventSeriesDayOfWeekMetadata.Thursday,
        [EventSeriesDayOfWeekMetadata.Friday.value]: EventSeriesDayOfWeekMetadata.Friday,
        [EventSeriesDayOfWeekMetadata.Saturday.value]: EventSeriesDayOfWeekMetadata.Saturday,
    };

    private constructor(
        public readonly dayOfWeek: EventSeriesDayOfWeek,
        public readonly name: keyof typeof EventSeriesDayOfWeek,
        public readonly value: number,
    ) { }
}
import { Component, Injector } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { SurveyType, SurveyTypeManageLabel } from "@common/ADAPT.Common.Model/organisation/survey";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { OrganisationDiagnosticAuthService } from "../organisation-diagnostic-auth.service";

@Component({
    selector: "adapt-manage-organisation-diagnostics-page",
    templateUrl: "./manage-organisation-diagnostics-page.component.html",
})
export class ManageOrganisationDiagnosticsPageComponent extends BaseRoutedComponent {
    public readonly surveyType = SurveyType.OrganisationDiagnostic;

    public constructor(injector: Injector) {
        super(injector);
    }

    public onInitialised() {
        this.notifyActivated();
    }
}

export const manageOrganisationDiagnosticsPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-manage-organisation-diagnostics-page", ManageOrganisationDiagnosticsPageComponent)
    .atOrganisationUrl("/health-check/history")
    .verifyingFeatures(FeatureName.OrganisationDiagnostic)
    .verifyingAccess(OrganisationDiagnosticAuthService.ReadOrganisationDiagnostic)
    .withTitle(SurveyTypeManageLabel)
    .reloadOnSearch(false)
    .build();

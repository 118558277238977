import { ItemStatus } from "@common/ADAPT.Common.Model/organisation/item-status";
import { Item } from "./item";

export class ItemExtensions {
    // This is set by kanban-card and checked in board.component when an item is dragged
    // (onDragStart argument's cancel property does not accept promise!)
    public currentPersonCanEdit?: boolean;

    public constructor(private item: Item) {
    }

    public get isOverdue() {
        const now = new Date();

        return this.item.status !== ItemStatus.Done
            && this.item.status !== ItemStatus.Closed
            && this.item.dueDate && this.item.dueDate < now;
    }

    public getSummaryHtml() {
        return `<strong>${this.item.code}</strong> - <i>"${this.item.summary}"</i>`;
    }
}

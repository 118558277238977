import { AfterViewInit, Component, ElementRef, HostBinding, Inject, OnInit, Optional, Type, ViewChild, ViewEncapsulation } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { NavigationHierarchyService } from "@common/route/navigation-hierarchy.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { Breakpoint } from "@common/ux/responsive/breakpoint";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { BehaviorSubject, combineLatest, from, Observable } from "rxjs";
import { debounceTime, filter, map, mergeMap, pairwise, startWith, switchMap, take, tap } from "rxjs/operators";
import { IShellPopoverLinkItem } from "../shell-popover-link-item/shell-popover-link-item";
import { ShellUiService } from "../shell-ui.service";
import { ISidebar, SidebarState } from "./sidebar.interface";
import { SIDEBAR_POPOVERS } from "./sidebar-popover";
import { ISidebarTab, SIDEBAR_TABS, SidebarBottomMostOrdinal, SidebarTabPosition } from "./sidebar-tab";

// Keep this in sync with adapt-common-shell-variables.scss
const sidebarTransitionTimeSeconds = 0.5;

const stateClass: { [state in SidebarState]: string } = {
    [SidebarState.Open]: "sidebar-open",
    [SidebarState.Transitioning]: "",
    [SidebarState.Closed]: "sidebar-closed",
};

const toggleIconClass: { [state in SidebarState]: string } = {
    [SidebarState.Open]: "fa-fw fal fa-angle-left",
    [SidebarState.Transitioning]: "",
    [SidebarState.Closed]: "fa-fw fal fa-angle-right",
};

@Component({
    selector: "adapt-common-sidebar",
    templateUrl: "./common-sidebar.component.html",
    styleUrls: ["./common-sidebar.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class CommonSidebarComponent extends BaseComponent implements OnInit, AfterViewInit, ISidebar {

    @HostBinding("class") public sidebarClass = "";
    private sidebarClassUpdater = this.createThrottledUpdater((className: string) => this.sidebarClass = className);

    public readonly SidebarState = SidebarState;

    // starts off with transitioning so that it can be changed to opened or closed correspondingly onInit
    // with initial value of breakpoint - otherwise sidebar class won't be set properly on initialised
    public state$ = new BehaviorSubject<SidebarState>(SidebarState.Transitioning);
    public activeTab$ = new BehaviorSubject<ISidebarTab | undefined>(undefined);
    public activeTab?: ISidebarTab;

    public toggleIconClass = toggleIconClass[this.state$.value];
    private toggleIconClassUpdater = this.createThrottledUpdater((className: string) => this.toggleIconClass = className);

    public tabContentComponents: Type<unknown>[];
    public popovers$: Observable<IShellPopoverLinkItem[]>;

    public isMobileSize = this.responsiveService.currentBreakpoint.isMobileSize;

    // @ViewChild can't use class selector - using id here instead
    @ViewChild("tabContent") public tabContent!: ElementRef<HTMLElement>;

    // These extra declarations to fix ExpressionChangedAfterItHasBeenCheckedError
    public tabIsLoading = true; // start with spinner before any active tab is set
    private tabIsLoadingUpdater = this.createThrottledUpdater((isLoading: boolean) => this.tabIsLoading = isLoading);
    // this will trigger the check for the conditions to update tabIsLoading
    private triggerTabIsLoadingUpdate = new BehaviorSubject<void>(undefined);
    private activeTabUpdater = this.createThrottledUpdater((tab: ISidebarTab) => {
        const previousTab = this.activeTab;
        this.activeTab = tab; // need to throttle activeTab assignment as well as that's also checked directly in the template
        this.updateTabContentStyles();
        if (previousTab !== tab) { // only want to trigger loading if the tab is different
            this.triggerTabIsLoadingUpdate.next();
        }
    });

    public hasPopover = false;

    public constructor(
        @Inject(SIDEBAR_TABS) public tabs: ISidebarTab[],
        @Optional() @Inject(SIDEBAR_POPOVERS) injectedPopovers: IShellPopoverLinkItem[] | null,
        private shellUiService: ShellUiService,
        private responsiveService: ResponsiveService,
        private navHierarchyService: NavigationHierarchyService,
    ) {
        super();

        this.tabs.sort((a, b) => a.ordinal - b.ordinal);
        this.tabContentComponents = this.tabs
            .filter((i) => !!i.content)
            .map((i) => i.content!.component);

        const popovers = injectedPopovers ?? [];
        popovers.sort((a, b) => b.ordinal - a.ordinal); // 0 (help) will go to the bottom

        this.popovers$ = combineLatest(popovers.map((i) => i.isShown$)).pipe(
            map((visible) => popovers.filter((_value, index) => visible[index])),
        );

        this.popovers$.pipe(
            take(1),
            this.takeUntilDestroyed(),
        ).subscribe((visiblePopovers) => this.hasPopover = (visiblePopovers.length > 0));

        this.triggerTabIsLoadingUpdate.pipe(
            filter(() => !!this.activeTab),
            switchMap(() => this.activeTab!.isLoading$),
            // debounce stops the spinner showing up if taking under 250ms
            debounceTime(250),
            this.takeUntilDestroyed(),
        ).subscribe((isLoading) => this.tabIsLoadingUpdater.next(isLoading));

        if (this.tabs.length < 1) {
            this.tabIsLoadingUpdater.next(false);
        }
    }

    public ngAfterViewInit() {
        if (!this.responsiveService.currentBreakpoint.is(Breakpoint.XL) || this.tabs.length < 1) {
            this.closeSidebar();
        }
    }

    public ngOnInit() {
        this.responsiveService.currentBreakpoint$.pipe(
            startWith(this.responsiveService.currentBreakpoint),
            pairwise(),
            this.takeUntilDestroyed(),
        ).subscribe(([oldBreakpoint, newBreakpoint]) => {
            this.handleBreakpointChange(oldBreakpoint, newBreakpoint);
            this.updateTabContentStyles();
        });

        this.navHierarchyService.activeNode$
            .pipe(this.takeUntilDestroyed())
            .subscribe(this.closeIfMobileSize);

        // If everything has finished loading and we still don't have an active tab,
        // then just choose the first one so it doesn't look like it is broken
        combineLatest(this.tabs.map((t) => t.isLoading$)).pipe(
            filter((loading) => loading.every((i) => !i)),
            filter(() => !this.activeTab),
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            this.activeTabUpdater.next(this.tabs[0]);
        });
        from(this.tabs).pipe(
            mergeMap((tab) => tab.focusTab$.pipe(
                map(() => tab),
            )),
            debounceTime(100),
            this.takeUntilDestroyed(),
        ).subscribe((tabToFocus) => {
            // this is only hit when changing focus tab from code
            this.activeTabUpdater.next(tabToFocus);
        });

        this.shellUiService.registerSidebar(this);
    }

    public get currentState() {
        return this.state$.value;
    }

    public get tabsTop() {
        return this.tabs.filter((t) => t.position !== SidebarTabPosition.Bottom && t.icon);
    }

    public get tabsBottom() {
        return this.tabs.filter((t) => t.position === SidebarTabPosition.Bottom && t.ordinal < SidebarBottomMostOrdinal);
    }

    public get tabsBottomMost() {
        return this.tabs.filter((t) => t.position === SidebarTabPosition.Bottom && t.ordinal >= SidebarBottomMostOrdinal);
    }

    public tabIsEnabled(tabId: string) {
        const tab = this.tabs.find((t) => t.id === tabId);
        return !!tab;
    }

    public focusTab(tabId: string) {
        const tab = this.tabs.find((t) => t.id === tabId);
        if (tab) {
            this.activateTab(tab);
        }
    }

    private activateTab(tab: ISidebarTab) {
        this.activeTabUpdater.next(tab);
        if (!this.isMobileSize) {
            this.openSidebar();
        }
    }

    public tabClick(tab: ISidebarTab) {
        if (this.activeTab === tab) {
            this.toggleSidebarOpenClosed();
        } else {
            // this is hit when changing tab from UI
            if (tab.onClick) {
                const event = new CustomEvent("tabClick", { cancelable: true });
                tab.onClick(event).pipe(
                    tap(() => {
                        if (!event.defaultPrevented) {
                            this.activateTab(tab);
                        }
                    }),
                ).subscribe();
            } else {
                this.activateTab(tab);
            }
        }
    }

    public promiseToLogout() {
        // We don't need to check for XS as the logout button is only shown on XS
        this.closeSidebar();
        this.shellUiService.promiseToLogout();
    }

    private updateTabContentStyles() {
        if (this.activeTab && this.tabContent) {
            this.activeTab$.next(this.activeTab);

            if (this.responsiveService.currentBreakpoint.isMobileSize) {
                this.tabContent.nativeElement.style.width = "100%";
            } else {
                this.tabContent.nativeElement.style.width = `${this.activeTab.maxWidth}px`;
            }

            this.updateSidebarMargin(this.state$.value);
        }
    }

    @Autobind
    private handleBreakpointChange(oldBreakpoint: Breakpoint, newBreakpoint: Breakpoint) {
        this.isMobileSize = newBreakpoint.isMobileSize;

        const enteringXL = newBreakpoint.is(Breakpoint.XL);
        if (enteringXL || !this.isAllowedToCollapse) {
            if (this.tabs.length > 0) {
                this.openSidebar();
            }
            return;
        }

        const goingIntoMobileFromNonMobile = newBreakpoint.isMobileSize && !oldBreakpoint.isMobileSize;
        const leavingXL = oldBreakpoint === Breakpoint.XL;

        if (goingIntoMobileFromNonMobile || leavingXL) {
            this.closeSidebar();
        }
    }

    @Autobind
    private closeIfMobileSize() {
        if (this.responsiveService.currentBreakpoint.isMobileSize) {
            this.closeSidebar();
        }
    }

    @Autobind
    public toggleSidebarOpenClosed() {
        if (this.currentState === SidebarState.Closed) {
            this.openSidebar();
        } else {
            this.closeSidebar();
        }
    }

    private openSidebar() {
        this.setSidebarState(SidebarState.Open);
        // setSidebarState above is setting this.state$ to transition first and then to Open after a while
        // - so can't make use of that implicitly in updateSidebarMargin
        this.updateSidebarMargin(SidebarState.Open);
    }

    private closeSidebar() {
        this.setSidebarState(SidebarState.Closed);
        this.updateSidebarMargin(SidebarState.Closed);
    }

    private updateSidebarMargin(state: SidebarState) {
        if (this.tabContent) {
            if (state === SidebarState.Open) {
                // as we need to use negative margin for the animation and the -ve is now set in code rather than the sidebar-close
                // class, that's not going to be removed when sidebar-close class is removed when the sidebar is opened again.
                // Reset margin-left here to bring the tabContent back into view
                this.tabContent.nativeElement.style.marginLeft = "0";
            } else if (state === SidebarState.Closed) {
                // as tabContent width is no longer constant - meeting tab has wider width, setting a fixed negative margin no longer
                // works. This is setting a negative margin for the tab content to hide it on closed.
                this.tabContent.nativeElement.style.marginLeft = `-${this.tabContent.nativeElement.getBoundingClientRect().width}px`;
            }
        }
    }

    private setSidebarState(state: SidebarState) {
        if (state === this.state$.value) {
            return;
        }

        this.state$.next(SidebarState.Transitioning);

        this.sidebarClassUpdater.next(stateClass[state]);
        this.toggleIconClassUpdater.next(toggleIconClass[state]);

        if (this.responsiveService.currentBreakpoint.isMobileSize) {
            this.state$.next(state);
        } else {
            setTimeout(() => this.state$.next(state), sidebarTransitionTimeSeconds * 1000);
        }
    }

    public get isAllowedToCollapse() {
        return this.isMobileSize || this.tabs.length > 1;
    }
}

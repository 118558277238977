<adapt-dialog [maxWidth]="600"
              [expandable]="false"
              [showHelpButton]="false"
              *ngIf="dialogData">
    <h3 adapt-dialog-title
        [innerHtml]="dialogData.title"></h3>
    <div adapt-dialog-content>
        <div *ngIf="dialogData.message"
             [froalaView]="dialogData.message"></div>
        <ng-content></ng-content>
        <div *ngIf="dialogData.checkboxMessage"
             class="mt-3">
            <dx-check-box [value]="checkboxConfirmation"
                          (valueChange)="checkboxConfirmation = $any($event)"
                          [text]="dialogData.checkboxMessage"
                          data-test="confirm-checkbox"></dx-check-box>
        </div>
    </div>
    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveButtonPreset]="dialogData.confirmButtonPreset || 'confirmationOk'"
                                   [saveText]="dialogData.confirmButtonText"
                                   [saveIsDisabled]="!checkboxConfirmation"
                                   [cancelButtonPreset]="dialogData.cancelButtonPreset"
                                   [cancelText]="dialogData.cancelButtonText"
                                   [hideCancelButton]="dialogData.hideCancelButton"
                                   (saveClick)="confirm()"
                                   (cancelClick)="cancel()"></adapt-standard-dialog-buttons>
</adapt-dialog>

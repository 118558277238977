export enum RouteEvent {
    NavigationStart,
    NavigationEnd,
    NavigationError,
    NavigationCancel,
    ComponentActivated,
    // corresponding to https://angular.io/api/router/ResolveStart (component instance will be constructed after ResolveEnd)
    // - this will not be emitted if the same component is already loaded (i.e. change of search param)
    ResolveStart,
    Other,
}

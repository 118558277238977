<button [ngClass]="{'guidance-toggle-horizontal': !isVertical, 'guidance-toggle-vertical': isVertical}"
        [adaptTooltip]="isCollapsed ? 'Show guidance' : 'Hide guidance'"
        (click)="buttonClicked.emit()">
    <i class="fa-fw fal fa-angle-left"
       [ngClass]="{
        'fa-angle-left': !isCollapsed && !isVertical,
        'fa-angle-right': isCollapsed && !isVertical,
        'fa-angle-up': !isCollapsed && isVertical,
        'fa-angle-down': isCollapsed && isVertical}"></i>
</button>

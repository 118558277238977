<div *ngIf="person$ | async as person"
     class="px-0 px-md-3">
    <div adaptToolbarContent
         alignment="right">
        <button *ngIf="!showTourPrompt"
                data-tour="personal-dashboard-tour"
                adaptButton="tour"
                (click)="runTour()"></button>

        <a *adaptIfAuthorised="CanEditPersonalStewardship"
           [routerLink]="personalConfigLink$ | async"
           adaptButton="configureLink"
           adaptTooltip="Go to personal configuration"></a>
    </div>

    <!-- Just a way to check for email confirmed  -->
    <div class="my-4 alert alert-info alert-dismissible d-none"
         role="alert"
         *ngIf="!emailConfirmed"
         left-column>
        <!-- bs alert dismissible not working anymore?? tried both bs-dismiss and dismiss - both not working
            ended up just using a (click) instead -->
        <span type="button"
              (click)="emailConfirmed = true"
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"></span>
        Please confirm your account - a confirmation email has been sent
    </div>

    <div *ngIf="showTourPrompt || showCoachSessionPrompt"
         class="mb-3">
        <div *ngIf="showTourPrompt"
             class="mb-3">
            <adapt-alto-shell-tour-prompt-dashboard-element [person]="person"></adapt-alto-shell-tour-prompt-dashboard-element>
        </div>
        <adapt-alto-coach-prompt-dashboard-element *ngIf="showCoachSessionPrompt"></adapt-alto-coach-prompt-dashboard-element>
    </div>

    <div class="dashboard">

        <adapt-workflows-dashboard-element [person]="person"></adapt-workflows-dashboard-element>

        <div class="split">
            <adapt-display-ongoing-survey-personal-dashboard-element [person]="person">
            </adapt-display-ongoing-survey-personal-dashboard-element>
            <adapt-team-meetings-dashboard-element *adaptIfAuthorised="CanReadMeetings"
                                                   [person]="person"></adapt-team-meetings-dashboard-element>

            <adapt-personal-objectives-dashboard-element *adaptIfAuthorised="ReadObjectives"
                                                         [person]="person"></adapt-personal-objectives-dashboard-element>
        </div>
    </div>
    <adapt-marketing-tracker *ngIf="firstSubscriptionPaymentReceived$ | async"
                             pageName="/initial-subscription-payment-received"></adapt-marketing-tracker>
</div>

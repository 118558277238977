import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { FeaturePermission } from "../embed/feature-permission";
import { Role } from "./role";

export class RoleFeaturePermission extends BaseEntity<RoleFeaturePermission> {
    public roleFeaturePermissionId!: number;
    public roleId!: number;
    public featurePermissionId!: number;
    public featurePermission!: FeaturePermission;
    public role!: Role;
}

export const RoleFeaturePermissionBreezeModel = new BreezeModelBuilder("RoleFeaturePermission", RoleFeaturePermission)
    .build();

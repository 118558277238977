import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MarketingTrackerComponent } from "./marketing-tracker/marketing-tracker.component";

@NgModule({
    declarations: [
        MarketingTrackerComponent,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        MarketingTrackerComponent,
    ],
})
export class AdaptMarketingTrackerModule { }

import { Component, Input, ViewChild } from "@angular/core";
import { SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { DisplayImpactSurveysComponent } from "../display-impact-surveys/display-impact-surveys.component";

@Component({
    selector: "adapt-configure-team-assessment",
    templateUrl: "./configure-team-assessment.component.html",
})
export class ConfigureTeamAssessmentComponent extends ConfigureFeatureBase {
    @Input() public configItem?: IConfigItem;
    @Input() public team?: Team;

    public currentStatus?: boolean;
    public surveyType = SurveyType.TeamAssessment;

    @ViewChild(DisplayImpactSurveysComponent) private impactedSurveyInstance?: DisplayImpactSurveysComponent;

    constructor(
        configurationService: ConfigurationService,
    ) {
        super(configurationService);
    }

    public async initialiseData() {
        return Promise.resolve();
    }

    public onSave() {
        if (this.impactedSurveyInstance) {
            this.impactedSurveyInstance.onSave();
        }

        return super.onSave();
    }
}

import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { KeyResult } from "@common/ADAPT.Common.Model/organisation/key-result";
import { KeyResultValue } from "@common/ADAPT.Common.Model/organisation/key-result-value";
import { ObjectiveComment } from "@common/ADAPT.Common.Model/organisation/objective-comment";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IChainComment } from "@org-common/lib/comment-chain/chain-comment/chain-comment.component";
import { IChainUpdate } from "@org-common/lib/comment-chain/chain-update/chain-update.component";
import { ObjectivesService } from "@org-common/lib/objectives/objectives.service";
import { ItemReorderedEvent } from "devextreme/ui/list";

@Component({
    selector: "adapt-key-results-list",
    templateUrl: "./key-results-list.component.html",
    styleUrl: "./key-results-list.component.scss",
})
export class KeyResultsListComponent extends BaseComponent implements OnChanges {
    @Input() public keyResults: KeyResult[] = [];
    @Input() public hasEditPermissions = false;
    @Input() public comments?: IChainComment<ObjectiveComment>[];
    @Input() public updates?: IChainUpdate<KeyResultValue>[];
    @Output() public chainUpdateDeleted = new EventEmitter<IChainUpdate<KeyResultValue>>();
    @Output() public chainUpdateEdited = new EventEmitter<IChainUpdate<KeyResultValue>>();
    @Output() public keyResultUpdated = new EventEmitter<KeyResult>();

    public constructor(
        private objectiveService: ObjectivesService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        // handle ordinal changed from another sessions
        rxjsBreezeService.entityTypeChanged(KeyResult).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.ngOnChanges());
    }

    public ngOnChanges() {
        if (this.keyResults) {
            this.keyResults.sort(SortUtilities.getSortByFieldFunction("ordinal"));
        }
    }

    public reorderKeyResults(e: ItemReorderedEvent, keyResults: KeyResult[]) {
        SortUtilities.sequenceNumberFieldInArray(keyResults, "ordinal"); // need this as ordinal wasn't used previously
        SortUtilities.reorderItemInIntegerSortedArray(keyResults, "ordinal", e.fromIndex, e.toIndex);

        // Don't have to lock as save entities will trigger a spinner with curtain and prevent any other actions during the save
        this.objectiveService.saveEntities(keyResults)
            .subscribe();
    }
}

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Survey, SurveyEmailStatus, SurveyStatus, SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { SurveyResponseGroup } from "@common/ADAPT.Common.Model/organisation/survey-response";
import { SurveyResult } from "@common/ADAPT.Common.Model/organisation/survey-result";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IAdaptLinkObject } from "@common/route/route.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { combineLatest, lastValueFrom, of, ReplaySubject, Subject } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { SurveyService } from "../survey.service";
import { SurveyUiService } from "../survey-ui.service";
import { SurveyUtils } from "../survey-utils";

const ScoreProgressText: { [type in SurveyType]: string } = {
    EmployeeEngagement: "Engagement",
    TeamAssessment: "Assessment",
    ResilientBusinessAssessment: "Assessment",
    OrganisationDiagnostic: "Score",
};

@Component({
    selector: "adapt-display-survey-result",
    templateUrl: "./display-survey-result.component.html",
    styleUrls: ["../survey-global-styles.scss"],
})
export class DisplaySurveyResultComponent extends BaseComponent implements OnChanges {
    @Input() public hideName = false;
    @Input() public hideDate = false;
    @Input() public allowRestore = false;
    @Output() public surveyDeleted = new EventEmitter<Survey>();

    public survey$ = new ReplaySubject<Survey>(1);
    public scoreText?: string;
    public participationText?: string;
    public scoreColor?: string;
    public scoreBackground?: string;
    public participationColor?: string;
    public participationBackground?: string;

    public analyseSurveyPageObject?: IAdaptLinkObject;
    public surveyResult?: SurveyResult;
    public canRestoreSurvey = false;

    @Input() public set survey(survey: Survey) {
        this.survey$.next(survey);
    }

    private allowRestoreChanged$ = new Subject<boolean>();

    public constructor(
        private surveyService: SurveyService,
        private surveyUiService: SurveyUiService,
    ) {
        super();
        this.survey$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe(async (survey) => {
            this.surveyResult = SurveyUtils.getSurveyResultForResponseGroup(survey, SurveyResponseGroup.All)!;
            const scorePercentage = this.surveyResult.averagePercentageScore!;
            const participationPercentage = this.surveyResult.percentageParticipation!;

            this.scoreText = `${ScoreProgressText[survey.surveyType]}: ${scorePercentage.toFixed(0)}%`;
            this.participationText = `Participation: ${participationPercentage.toFixed(0)}%`;

            const surveyTypeUtils = SurveyUtils.forSurveyType(survey.surveyType);
            this.scoreColor = surveyTypeUtils.getStatusColorFromPercentage(scorePercentage);
            this.scoreBackground = surveyTypeUtils.getStatusBackgroundFromPercentage(scorePercentage);
            this.participationColor = surveyTypeUtils.getStatusColorFromPercentage(participationPercentage);
            this.participationBackground = surveyTypeUtils.getStatusBackgroundFromPercentage(participationPercentage);

            this.analyseSurveyPageObject = await lastValueFrom(surveyTypeUtils.getAnalyseSurveyPageRoute$(survey.surveyId, survey.teamId));
        });

        combineLatest([this.allowRestoreChanged$, this.survey$]).pipe(
            switchMap(([allowRestore, survey]) => {
                if (allowRestore && survey.status === SurveyStatus.Ended && survey.emailStatus !== SurveyEmailStatus.CleanupCompleted) {
                    return surveyService.getOutstandingSurveyResponseCountForSurvey(survey.surveyId).pipe(
                        map((count) => count > 0),
                    );
                } else {
                    return of(false);
                }
            }),
            this.takeUntilDestroyed(),
        ).subscribe((canRestoreSurvey) => this.canRestoreSurvey = canRestoreSurvey);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.allowRestore) {
            this.allowRestoreChanged$.next(this.allowRestore);
        }
    }

    public restoreSurvey(survey: Survey) {
        this.surveyService.getSupplementaryDataForSurvey(survey.surveyId).pipe(
            switchMap(() => this.surveyUiService.editSurvey(survey)),
        ).subscribe();
    }

    @Autobind
    public showRespondents(survey: Survey) {
        return this.surveyUiService.viewRespondentsForSurvey(survey);
    }

    public promptToDeleteSurvey(survey: Survey) {
        return this.surveyUiService.promptToDeleteSurvey(survey).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.surveyDeleted.emit(survey));
    }
}

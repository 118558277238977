import { Component, Input } from "@angular/core";
import { AdaptClientConfiguration, AdaptProject } from "../../configuration/adapt-client-configuration";
import { ContentWithSplashImageComponent } from "./content-with-splash-image.component";

@Component({
    selector: "adapt-page-with-splash-image",
    template: `
        <adapt-content-with-splash-image [fullPage]="true"
                                         [whiteBackground]="whiteBackground"
                                         [leftColumnWidthClasses]="leftColumnWidthClasses"
                                         [rightColumnWidthClasses]="rightColumnWidthClasses">
            <ng-content></ng-content>
        </adapt-content-with-splash-image>
    `,
})
export class PageWithSplashImageComponent {
    // default to white background in cumulus
    @Input() public whiteBackground = AdaptClientConfiguration.AdaptProjectName !== AdaptProject.Alto;

    @Input() public leftColumnWidthClasses = ContentWithSplashImageComponent.defaultLeftColumnWidthClasses;
    @Input() public rightColumnWidthClasses = ContentWithSplashImageComponent.defaultRightColumnWidthClasses;
}

<div class="multiple-selection-header">
    <div class="d-flex align-items-center gap-2"
         adaptBlockingButtonGroup>
        <div class="d-flex align-items-center ps-1 pe-3 border-end">
            <span class="badge bg-info me-1">{{items.length}}</span> actions selected
        </div>

        <adapt-menu [items]="statusChangeMenu"
                    [showOnHover]="true"></adapt-menu>

        <div [adaptTooltip]="personalItemSelected ? 'Your personal action cannot be assigned to another person': undefined">
            <button adaptButtonIcon="fal fa-fw fa-user"
                    [disabled]="!itemsAreSelected || personalItemSelected"
                    [adaptBlockingClick]="assignPerson">Assign person</button>
        </div>

        <button adaptButtonIcon="fal fa-fw fa-share"
                [disabled]="!itemsAreSelected"
                [adaptBlockingClick]="moveItems">Move to another board</button>
    </div>

    <div class="d-flex align-items-center ms-auto">
        <button adaptButtonIcon="fal fa-fw fa-square-dashed"
                (click)="exitSelectionMode.emit()">Exit selection mode</button>
    </div>
</div>

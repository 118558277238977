import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { Survey, SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { SurveyResponseGroup, SurveyResponseGroupLabel } from "@common/ADAPT.Common.Model/organisation/survey-response";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ChartUtils } from "@common/ux/base-ui.service/chart-utils";
import { DxGaugePointType, IDxGaugeTooltipPointInfo } from "@common/ux/dx.types";
import { Breakpoint } from "@common/ux/responsive/breakpoint";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { DisposingEvent, InitializedEvent } from "devextreme/viz/linear_gauge";
import { forkJoin, ReplaySubject } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { EmployeeEngagementConstants } from "../employee-engagement/employee-engagement.constants";
import { ISurveyResponseStats, SurveyService } from "../survey.service";
import { ISurveyQuestions } from "../survey-questions.interface";
import { SurveyUtils } from "../survey-utils";

interface IGaugeRange {
    startValue: number;
    endValue: number;
    color: string;
}

@Component({
    selector: "adapt-display-survey-question-stats",
    templateUrl: "./display-survey-question-stats.component.html",
    styleUrls: ["./display-survey-question-stats.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplaySurveyQuestionStatsComponent extends BaseComponent implements OnInit, AfterViewChecked, OnChanges {
    public readonly Constants = EmployeeEngagementConstants;

    public survey$ = new ReplaySubject<Survey>(1);
    @Input() public set survey(value: Survey) {
        this.survey$.next(value);
    }
    @Input() public questionId!: number;
    @Input() public maxResponseCount = 0;
    @Input() public surveyQuestions!: ISurveyQuestions;
    @Input() public showQuestionResponseDistribution = true;
    @Input() public forceInitialise = false;
    @Output() public maxResponseCountChange = new EventEmitter<number>();
    public isDrawn = false;

    public readonly GroupLabel = SurveyResponseGroupLabel;
    public surveyQuestion?: string;
    public groupStats: { [group in SurveyResponseGroup]?: ISurveyResponseStats } = {};
    public getStatusColorFromPercentage?: (percentage: number) => string;
    public gaugeRanges: IGaugeRange[] = [];
    public tickValues: number[] = [];
    public presentResponseGroups: SurveyResponseGroup[] = [];

    public isXL = false;

    private gaugeInitInstances: InitializedEvent[] = [];

    public constructor(
        elementRef: ElementRef,
        private changeDetectorRef: ChangeDetectorRef,
        responsiveService: ResponsiveService,
        private surveyService: SurveyService,
    ) {
        super(elementRef);
        this.sizeChange$.subscribe(() => this.gaugeInitInstances.forEach((e) => ChartUtils.updateChartDimension(e)));
        responsiveService.currentBreakpoint$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((breakpoint) => this.isXL = breakpoint === Breakpoint.XL);
    }

    public ngAfterViewChecked() {
        if (!this.forceInitialise) {
            this.setInitialiseWhenElementComesIntoView(() => this.changeDetectorRef.detectChanges());
        }
    }

    public ngOnChanges(): void {
        if (this.forceInitialise) {
            this.forceInitialiseElement(() => this.changeDetectorRef.detectChanges());
        }
    }

    public ngOnInit() {
        if (!this.questionId) {
            throw new AdaptError("Expected questionId not found");
        }

        this.survey$.pipe(
            tap((survey) => this.updateChartDecorators(survey.surveyType)),
            tap((survey) => {
                this.isInitialised = this.forceInitialise; // new survey -> only render if it gets into view or if forced
                this.presentResponseGroups = SurveyUtils.forSurveyType(survey.surveyType).responseGroups;
            }),
            switchMap((survey) => {
                this.groupStats = {};
                return forkJoin(this.presentResponseGroups.map((group) =>
                    this.surveyService.getSurveyQuestionResponseStats(survey, this.questionId, group)
                        .pipe(
                            tap((stats) => this.groupStats[group] = stats),
                        )));
            }),
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            this.surveyQuestion = this.surveyQuestions.getQuestion(this.questionId);
            this.changeDetectorRef.detectChanges();
        });
    }

    public onInitialized(e: InitializedEvent) {
        this.gaugeInitInstances.push(e);
    }

    public onDisposing(e: DisposingEvent) {
        let foundIndex = -1;
        for (let i = 0; i < this.gaugeInitInstances.length; i++) {
            if (e.component === this.gaugeInitInstances[i].component) {
                foundIndex = i;
                break;
            }
        }

        if (foundIndex >= 0) {
            this.gaugeInitInstances.splice(foundIndex, 1);
        }
    }

    public customiseChartTooltip(e: IDxGaugeTooltipPointInfo) {
        if (e.type === DxGaugePointType.ValueIndicator) {
            return {
                text: `Average: ${e.value.toFixed(1)}%`,
            };
        } else if (e.type === DxGaugePointType.SubvalueIndicator) {
            if (e.index === 1) {
                return {
                    text: `Max: ${e.value.toFixed(1)}%`,
                };
            } else {
                return {
                    text: `Min: ${e.value.toFixed(1)}%`,
                };
            }
        }
    }

    public customiseScaleText(e: IDxGaugeTooltipPointInfo) {
        return `${e.value.toFixed(0)}%`;
    }

    private updateChartDecorators(surveyType: SurveyType) {
        const resultCategories = SurveyUtils.forSurveyType(surveyType).resultCategories;
        this.getStatusColorFromPercentage = SurveyUtils.forSurveyType(surveyType).getStatusColorFromPercentage;
        this.gaugeRanges = [];
        let endValue = 100;
        for (const category of resultCategories) {
            this.gaugeRanges.push({
                color: category.color,
                startValue: category.percentageThreshold,
                endValue,
            });
            endValue = category.percentageThreshold;
        }

        this.tickValues = resultCategories
            .filter((c) => c.percentageThreshold)
            .map((c) => c.percentageThreshold!)
            .concat([0, 100]);
    }
}

import { Component, ElementRef, Injector, OnInit } from "@angular/core";
import { PageRouteBuilder } from "@common/route/page-route-builder";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { InitializedEvent } from "devextreme/ui/tab_panel";
import { SelectionChangedEvent } from "devextreme/ui/tabs";
import { startWith } from "rxjs";

// these values should match the index in the items array in the class
enum Section {
    TermsConditions = "0",
    PrivacyPolicy = "1",
}

// NOTE: if this changes, then update the link in login-page.component.html
const SectionKey = "section";

interface ITabItem {
    title: string;
    template: string;
}

@Component({
    selector: "adapt-privacy-terms-conditions-page",
    templateUrl: "./privacy-terms-conditions-page.component.html",
    styleUrls: ["./privacy-terms-conditions-page.component.scss"],
})
export class PrivacyTermsConditionsPageComponent extends BaseRoutedComponent implements OnInit {
    public readonly items: ITabItem[] = [
        {
            title: "Terms and conditions",
            template: "termsConditionsTemplate",
        },
        {
            title: "Privacy policy",
            template: "privacyPolicyTemplate",
        },
    ];

    public isMobileSize = this.responsiveService.currentBreakpoint.isMobileSize;

    public privacyLoading = false;

    public constructor(
        injector: Injector,
        elementRef: ElementRef,
        private responsiveService: ResponsiveService,
    ) {
        super(injector, elementRef);
    }

    public ngOnInit() {
        this.notifyActivated();
    }

    public onInitialised(e: InitializedEvent) {
        this.searchParameterChanged.pipe(
            // set the initial tab
            startWith(undefined),
        ).subscribe(() => e.component?.option("selectedIndex", this.getSearchParameterIntValue(SectionKey)));
    }

    public selectionChanged(e: SelectionChangedEvent) {
        const selection = e.addedItems[0];
        this.setSearchParameterValue(SectionKey, this.items.indexOf(selection));
    }
}

export const PrivacyTermConditionsPageRoute = new PageRouteBuilder()
    .usingNgComponent("adapt-privacy-terms-conditions-page", PrivacyTermsConditionsPageComponent)
    .atUrl("/about/privacy-terms-conditions")
    .reloadOnSearch(false)
    .requiresLogin(false)
    .requiresAnonymous(false)
    .build();

// have to make these functions as global injector is not yet set when this is initialised which is needed to access route service from getRouteObject
export const TermsConditionsPageRoute$ = () => PrivacyTermConditionsPageRoute.getRouteObject(undefined, { section: Section.TermsConditions });
export const PrivacyPolicyPageRoute$ = () => PrivacyTermConditionsPageRoute.getRouteObject(undefined, { section: Section.PrivacyPolicy });

import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
    selector: "adapt-eula",
    templateUrl: "./eula.component.html",
    //styles: `:host { display: contents; }`,   // used for dynamic Enzuzo only
    styleUrl: "./eula.component.scss",
})
export class EulaComponent implements OnInit {
    public loaded = false;

    @Output() public loadedChange = new EventEmitter<boolean>();

    public onLoaded() {
        this.loaded = true;
        this.loadedChange.emit(true);
    }

    public ngOnInit() {
        this.onLoaded();
    }
}

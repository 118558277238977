import { Component, Injector, OnInit } from "@angular/core";
import { SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { Team, TeamBreezeModel } from "@common/ADAPT.Common.Model/organisation/team";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { tap } from "rxjs/operators";
import { TeamAssessmentAuthService } from "../team-assessment-auth.service";

@Component({
    selector: "adapt-manage-team-assessments-page",
    templateUrl: "./manage-team-assessments-page.component.html",
})
export class ManageTeamAssessmentsPageComponent extends BaseRoutedComponent implements OnInit {
    public surveyType = SurveyType.TeamAssessment;
    public team?: Team;

    public constructor(
        injector: Injector,
        private authService: AuthorisationService,
        private commonDataService: CommonDataService,
    ) {
        super(injector);
    }

    public async ngOnInit() {
        this.updateFromPageParams();
        this.navigationEnd.subscribe(() => this.updateFromPageParams());
    }

    private async updateFromPageParams() {
        const teamId = this.getRouteParamInt("teamId");
        if (teamId) {
            this.commonDataService.getById(TeamBreezeModel, teamId).pipe(
                tap((team) => {
                    if (team) {
                        this.team = team;
                        this.verifyHasAccessToRoute(this.authService.getHasAccess(TeamAssessmentAuthService.ReadTeamAssessment, this.team));
                    } else {
                        this.routeService.gotoHome();
                    }
                }),
                this.takeUntilDestroyed(),
            ).subscribe();
        } else {
            this.routeService.gotoHome();
        }
    }

    public onInitialised() {
        this.notifyActivated();
    }
}

import { Inject, Injectable, Injector } from "@angular/core";
import { CalendarIntegrationProvider, OrganisationDetail, OrganisationDetailNames } from "@common/ADAPT.Common.Model/organisation/organisation-detail";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { LocalStorage } from "@common/lib/storage/local-storage";
import { BehaviorSubject, combineLatest, EMPTY, map, Observable, of, Subject, Subscription, throwError } from "rxjs";
import { catchError, filter, switchMap, take, tap } from "rxjs/operators";
import { CALENDAR_PROVIDERS, ICalendarProvider } from "../calendar/calendar.interface";
import { OrganisationService } from "../organisation/organisation.service";
import { IOAuthService } from "./oauth.interface";

// used to check if we need to prompt for account when logging in with a provider
// if the org hasn't changed since last login, we probably don't need to...
const LastProviderOrganisationIdKey = "lastProviderOrganisationId";

@Injectable({
    providedIn: "root",
})
export class OAuthService implements IOAuthService {
    private providerRecord?: OrganisationDetail;
    private provider?: ICalendarProvider;
    private isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
    private isAuthenticatedSubscription?: Subscription;
    private errorMessageSubject = new Subject<string>();
    private errorMessageSubscription?: Subscription;
    private providerSubject = new BehaviorSubject<ICalendarProvider | undefined>(undefined);

    public constructor(
        private injector: Injector,
        private commonDataService: CommonDataService,
        private organisationService: OrganisationService,
        @Inject(CALENDAR_PROVIDERS) public calendarProviders?: ICalendarProvider[],
    ) {
        this.organisationService.currentOrganisation$.pipe(
            filter((organisation) => !!organisation),
            map((organisation) => organisation!.getDetailRecord(OrganisationDetailNames.CalendarIntegration)),
        ).subscribe((detail) => this.setProviderFromOrganisationDetail(detail));

        this.organisationService.organisationEntityUpdated$.subscribe((newOrg) => {
            if (this.provider) {
                const lastLoginOrgId = LocalStorage.get<number>(OAuthService.GetLastProviderOrganisationLocalStorageKey(this.provider.id));
                // log out of calendar integration if org is not the same
                if (newOrg && newOrg.organisationId !== lastLoginOrgId) {
                    this.logout().subscribe();
                }
            }
        });
    }

    public static GetLastProviderOrganisationLocalStorageKey(provider: CalendarIntegrationProvider) {
        return `${LastProviderOrganisationIdKey}${provider}`;
    }

    public get isAuthenticated$() {
        return this.isAuthenticatedSubject.asObservable();
    }

    public get errorMessage$() {
        return this.errorMessageSubject.asObservable();
    }

    public get authProvider$() {
        return this.providerSubject.asObservable();
    }

    public get user() {
        const service = this.authService;
        if (!service) {
            return undefined;
        }

        return service.user;
    }

    private get authService() {
        if (!this.provider) {
            return undefined;
        }

        return this.injector.get(this.provider.authService);
    }

    public getPromptText(personal = true) {
        if (personal) {
            return `You can integrate your meetings with a calendar of your choice.
                If you have chosen calendar integration, any meetings you create in ${AdaptClientConfiguration.AdaptProjectLabel} will
                automatically be created in your calendar.`;
        }

        return `Users can integrate their meetings with a calendar of their choice.
            If you have chosen calendar integration, any meetings users create in ${AdaptClientConfiguration.AdaptProjectLabel} will
            automatically be created in your calendar.`;
    }

    public isAuthedWithProvider(provider: CalendarIntegrationProvider) {
        return this.isAuthedWithProvider$(provider).pipe(take(1));
    }

    public isAuthedWithProvider$(provider: CalendarIntegrationProvider) {
        return this.authenticationStatusWithProvider$.pipe(
            switchMap(([authed, authProvider]) => of(authed && authProvider?.id === provider)),
        );
    }

    public get authenticationStatusWithProvider() {
        return this.authenticationStatusWithProvider$.pipe(take(1));
    }

    public get authenticationStatusWithProvider$() {
        return combineLatest([this.isAuthenticated$, this.authProvider$]);
    }

    public hasPreviouslyLoggedInWithProvider(provider?: CalendarIntegrationProvider) {
        if (!provider) {
            return false;
        }

        const localStorageKey = OAuthService.GetLastProviderOrganisationLocalStorageKey(provider);
        return LocalStorage.get<number>(localStorageKey) !== undefined;
    }

    @Autobind
    public login(provider?: CalendarIntegrationProvider): Observable<any> {
        const previousProvider = this.provider;

        const foundProvider = this.calendarProviders?.find((p) => p.id === provider);
        const providerToUse = foundProvider ?? this.provider;
        if (!providerToUse) {
            return EMPTY;
        }

        const service = this.injector.get(providerToUse.authService);
        if (!service) {
            return EMPTY;
        }

        return service.login().pipe(
            tap(() => this.getCurrentProvider(providerToUse)),
            catchError((err) => {
                // set back to the previous provider if logging in failed
                this.provider = previousProvider;
                return throwError(() => err);
            }),
        );
    }

    @Autobind
    public logout() {
        const service = this.authService;
        if (!service) {
            return EMPTY;
        }

        return service.logout();
    }

    public resetDefaultProvider() {
        if (this.providerRecord) {
            return this.commonDataService.remove(this.providerRecord).pipe(
                switchMap(() => this.commonDataService.saveEntities(this.providerRecord)),
                // make sure to log-out of the integration else it will set the integration again
                switchMap(() => this.logout()),
                tap(() => {
                    this.providerRecord = undefined;
                    this.providerSubject.next(undefined);
                }),
            );
        }

        return of(undefined);
    }

    private setProviderFromOrganisationDetail(savedProvider?: OrganisationDetail) {
        if (savedProvider) {
            this.providerRecord = savedProvider;
            this.provider = this.calendarProviders?.find((p) => p.id === savedProvider.value as CalendarIntegrationProvider | undefined);
            this.providerSubject.next(this.provider);

            if (this.provider) {
                this.getCurrentProvider(this.provider);
            }
        }
    }

    private getCurrentProvider(provider: ICalendarProvider) {
        const service = this.injector.get(provider.authService);

        this.errorMessageSubscription?.unsubscribe();
        this.errorMessageSubscription = service.errorMessage$.subscribe(this.errorMessageSubject);

        this.isAuthenticatedSubscription?.unsubscribe();
        this.isAuthenticatedSubscription = service.isAuthenticated$.pipe(
            tap((authed) => this.isAuthenticatedSubject.next(authed)),
            filter((authed) => authed),
            switchMap(() => this.organisationService.currentOrganisation$.pipe(take(1))),
            tap((organisation) => {
                if (organisation) {
                    LocalStorage.set(OAuthService.GetLastProviderOrganisationLocalStorageKey(provider.id), organisation.organisationId);
                }
            }),
            switchMap((organisation) => organisation
                ? this.organisationService.getOrCreateOrganisationDetail(OrganisationDetailNames.CalendarIntegration)
                : of(undefined)),
            switchMap((detailRecord) => {
                // only allow new detail records for calendar integration to be set
                if (detailRecord?.entityAspect.entityState.isAdded()) {
                    detailRecord.value = provider!.id;
                    return this.commonDataService.saveEntities([detailRecord]).pipe(map(() => detailRecord));
                }
                return of(detailRecord);
            }),
            tap((detailRecord) => {
                this.providerRecord = detailRecord;
                this.provider = provider;
                this.providerSubject.next(this.provider);
            }),
        ).subscribe();
    }
}

import { OrganisationCategoryValue, WorkflowDialogWidth, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { buildLocalWorkflow } from "@org-common/lib/workflow/workflow.interface";
import { WorkflowIdentifier } from "@org-common/lib/workflow/workflow-identifier.enum";

export const designFocusAccountabilityWorkflowName = "Design for focus & accountability";

export const designFocusAccountabilityWorkflow = buildLocalWorkflow({
    name: designFocusAccountabilityWorkflowName,
    workflowId: WorkflowIdentifier.DesignForFocusAndAccountability,
    type: WorkflowType.Journey,
    dialogWidth: WorkflowDialogWidth.ExtraLarge,
    overview: "Understand how your organisation is structured and set some measurable accountabilities for the key functions in your organisation.",
    category: OrganisationCategoryValue.FocusAccountability,
    articleSlug: ImplementationKitArticle.ComingSoon, // a coming soon HelpJuice document
    /*
    articleSlug: "1877934",
    wrapUpSlug: "2076631",
    workflows: [
        ...createWorkshopScheduleWorkflows(designFocusAccountabilityWorkflowName, WorkflowIdentifier.DesignForFocusAndAccountability),
    ],
    */
});

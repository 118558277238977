import { Component, ElementRef, HostListener } from "@angular/core";

@Component({
    template: "",
})
export abstract class BaseButtonComponent {
    public constructor(
        protected elementRef: ElementRef<HTMLElement>,
    ) {
    }

    // Prevent focus state remaining after clicking button, but ensure that it is only
    // when clicked (as opposed to keyboard navigation, which is desired for accessibility)
    // See https://stackoverflow.com/questions/19053181
    @HostListener("pointerup")
    public blurButton() {
        this.elementRef.nativeElement.blur();
    }


    public scrollIntoView() {
        this.elementRef.nativeElement.scrollIntoView({ behavior: "smooth" });
    }
}

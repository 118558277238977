import { Injectable } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { Board, BoardBreezeModel } from "@common/ADAPT.Common.Model/organisation/board";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { IEntityWithOptionalTeam } from "@common/ADAPT.Common.Model/organisation/team-entity.interface";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { lastValueFrom } from "rxjs";
import { take } from "rxjs/operators";

@Injectable({
    providedIn: "root",
})
export class KanbanAuthService {
    public static readonly EditItem = "editItem";
    public static readonly EditPersonalStewardshipKanban = "editPersonalStewardshipKanban";
    public static readonly EditAllStewardship = "editAllStewardship";
    public static readonly ViewAllStewardship = "viewAllStewardship";
    public static readonly ViewAnyBoard = "viewAnyBoard";
    public static readonly EditAnyBoard = "editAnyBoard";
    public static readonly ViewBoardForPerson = "viewBoardForPerson";
    public static readonly ViewBoard = "viewBoard";
    public static readonly EditBoard = "editBoard";

    public constructor(
        private authorisationService: AuthorisationService,
        private featuresService: FeaturesService,
    ) { }

    public static registerAccess(authorisationService: AuthorisationService) {
        authorisationService.registerAccessVerifier(
            KanbanAuthService.EditItem,
            {
                invokeToGetEntityAccessVerifier: (injector) =>
                    getVerifyItemAccessEditFunction(
                        injector.get(CommonDataService),
                    ),
            },
        );

        authorisationService.registerAccessVerifier(
            KanbanAuthService.EditAllStewardship,
            {
                requirePermissions: [
                    FeaturePermissionName.StewardshipWorkKanbanEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            KanbanAuthService.ViewAllStewardship,
            {
                requirePermissions: [
                    FeaturePermissionName.StewardshipWorkKanbanRead,
                    FeaturePermissionName.StewardshipWorkKanbanEdit,
                ],
            },
        );

        authorisationService.registerAccessVerifier(
            KanbanAuthService.EditPersonalStewardshipKanban,
            {
                requirePermissions: [
                    FeaturePermissionName.StewardshipWorkKanbanPersonalEdit,
                ],
            },
        );

        authorisationService.registerAccessVerifier(
            KanbanAuthService.ViewAnyBoard,
            {
                invokeToGetAccessVerifier: (injector) =>
                    getVerifyAccessToViewAnyBoard(
                        injector.get(CommonDataService),
                        injector.get(KanbanAuthService),
                    ),
            },
        );

        authorisationService.registerAccessVerifier(
            KanbanAuthService.EditAnyBoard,
            {
                invokeToGetAccessVerifier: (injector) =>
                    getVerifyAccessToEditAnyBoard(
                        injector.get(CommonDataService),
                        injector.get(KanbanAuthService),
                    ),
            },
        );

        authorisationService.registerAccessVerifier(
            KanbanAuthService.ViewBoard,
            {
                invokeToGetEntityAccessVerifier: (injector) =>
                    getVerifyAccessToViewBoard(
                        injector.get(KanbanAuthService),
                    ),
            },
        );

        authorisationService.registerAccessVerifier(
            KanbanAuthService.ViewBoardForPerson,
            {
                invokeToGetEntityAccessVerifier: (injector) =>
                    getVerifyAccessToViewBoardForPerson(
                        injector.get(KanbanAuthService),
                    ),
            },
        );

        authorisationService.registerAccessVerifier(
            KanbanAuthService.EditBoard,
            {
                invokeToGetEntityAccessVerifier: (injector) =>
                    getVerifyAccessToEditBoard(
                        injector.get(KanbanAuthService),
                    ),
            },
        );

        function getVerifyAccessToEditBoard(kanbanAuthService: KanbanAuthService) {
            return promiseToVerifyAccessToEditBoard;

            function promiseToVerifyAccessToEditBoard(currentPerson: Person, board: Board) {
                return kanbanAuthService.personCanEditBoard(currentPerson, board)
                    ? Promise.resolve()
                    : Promise.reject();
            }
        }

        function getVerifyAccessToViewBoard(kanbanAuthService: KanbanAuthService) {
            return verifyAccessToViewBoardFn;

            function verifyAccessToViewBoardFn(currentPerson: Person, board?: Board) {
                return kanbanAuthService.personCanViewBoard(currentPerson, board)
                    ? Promise.resolve()
                    : Promise.reject();
            }
        }

        function getVerifyAccessToViewBoardForPerson(kanbanAuthService: KanbanAuthService) {
            return verifyAccessToViewBoardForPersonFn;

            function verifyAccessToViewBoardForPersonFn(currentPerson: Person, person: Person) {
                // person we're trying to view boards for doesn't have boards permissions
                if (!kanbanAuthService.personCanReadBoards(person)) {
                    return Promise.reject();
                }

                if (currentPerson === person) {
                    return kanbanAuthService.authorisationService.promiseToVerifyAccess(KanbanAuthService.ViewAnyBoard);
                }

                return kanbanAuthService.authorisationService.promiseToVerifyAccess(KanbanAuthService.ViewAllStewardship);
            }
        }

        function getVerifyAccessToViewAnyBoard(dataService: CommonDataService, kanbanAuthService: KanbanAuthService) {
            return verifyAccessToViewAnyBoardFn;

            function verifyAccessToViewAnyBoardFn(currentPerson: Person) {
                if (!kanbanAuthService.personCanReadBoards(currentPerson)) {
                    return Promise.reject();
                }

                return lastValueFrom(dataService.getAll(BoardBreezeModel))
                    .then((boards: Board[]) => {
                        // API won't return boards we don't have access to
                        return boards.length > 0
                            ? Promise.resolve()
                            : Promise.reject();
                    });
            }
        }

        function getVerifyAccessToEditAnyBoard(dataService: CommonDataService, kanbanAuthService: KanbanAuthService) {
            return verifyAccessToEditAnyBoardFn;

            function verifyAccessToEditAnyBoardFn(currentPerson: Person) {
                if (!kanbanAuthService.personCanReadBoards(currentPerson)) {
                    return Promise.reject();
                }

                return lastValueFrom(dataService.getAll(BoardBreezeModel))
                    .then((boards: Board[]) => {
                        return boards.some((b) => kanbanAuthService.personCanEditBoard(currentPerson, b))
                            ? Promise.resolve()
                            : Promise.reject();
                    });
            }
        }

        function getVerifyItemAccessEditFunction(commonDataService: CommonDataService) {
            return (_currentPerson: any, item?: Item) => {
                if (!item) {
                    return Promise.reject();
                }

                if (!item.board) {
                    return lastValueFrom(commonDataService.getById(BoardBreezeModel, item.boardId))
                        .then((board: any) => authorisationService.promiseToVerifyAccess(KanbanAuthService.EditBoard, board));
                }

                return authorisationService.promiseToVerifyAccess(KanbanAuthService.EditBoard, item.board);
            };
        }
    }

    public canEditPersonalStewardshipKanban() {
        return this.authorisationService.getHasAccess(KanbanAuthService.EditPersonalStewardshipKanban).pipe(
            take(1),
        );
    }

    public canEditAllStewardship() {
        return this.authorisationService.getHasAccess(KanbanAuthService.EditAllStewardship).pipe(
            take(1),
        );
    }

    public hasEditAccessToItem(item: Item) {
        return this.authorisationService.getHasAccess(KanbanAuthService.EditItem, item).pipe(
            take(1),
        );
    }

    public currentPersonCanReadBoards() {
        if (!this.authorisationService.currentPerson) {
            return false;
        }

        return this.personCanReadBoards(this.authorisationService.currentPerson);
    }

    // these whole bunch are moved from stewardship auth service
    public personCanReadBoards(person: Person) {
        return this.authorisationService.personHasPermission(person, FeaturePermissionName.StewardshipWorkKanbanPersonalEdit)
            || this.authorisationService.personHasPermissionInAtLeastOneTeam(person, FeaturePermissionName.StewardshipWorkKanbanRead)
            || this.authorisationService.personHasPermission(person, FeaturePermissionName.StewardshipWorkKanbanPublicRead);
    }

    public personCanReadBoardsForTeam(person: Person, team: Team) {
        return this.authorisationService.personHasPermission(person, FeaturePermissionName.StewardshipWorkKanbanEdit, team)
            || this.authorisationService.personHasPermission(person, FeaturePermissionName.StewardshipWorkKanbanRead, team)
            || (this.authorisationService.personHasPermission(person, FeaturePermissionName.StewardshipWorkKanbanPublicRead) &&
                this.isTeamKanbanEnabled(team) &&
                team.boards.some((i) => i.isPublicReadAccess));
    }

    public personCanReadNonPublicBoardsForTeam(person: Person, team: Team) {
        return this.authorisationService.personHasPermission(person, FeaturePermissionName.StewardshipWorkKanbanEdit, team)
            || this.authorisationService.personHasPermission(person, FeaturePermissionName.StewardshipWorkKanbanRead, team);
    }

    /** Duplicates logic on backend in BoardRepositoryEntity.cs */
    public personCanViewBoard(person: Person, board?: Board) {
        if (!board) {
            return false;
        }

        const hasPersonalStewardshipRead = this.authorisationService.personHasPermission(person, FeaturePermissionName.StewardshipWorkKanbanPersonalEdit);
        const hasPublicKanbanRead = this.authorisationService.personHasAtLeastOnePermission(person, [
            FeaturePermissionName.StewardshipWorkKanbanPublicRead,
            FeaturePermissionName.StewardshipWorkKanbanPublicEdit,
        ]);
        // This check will include pending active connection permissions - don't have to do this on server and this person cannot login yet.
        // This is to allow action items to be assigned to team members who are coming aboard soon.
        const hasPermissionToTeamKanban = this.authorisationService.personHasPermission(person, FeaturePermissionName.StewardshipWorkKanbanRead, board, true);

        const hasAccess = (hasPersonalStewardshipRead && board.personId === person.personId)
            || (board.teamId && hasPermissionToTeamKanban)
            || (hasPublicKanbanRead && board.isPublicReadAccess && this.isTeamKanbanEnabled(board)); // needs team feature to be enabled to read team public board

        return hasAccess;
    }

    public currentPersonCanViewBoard(board?: Board) {
        if (!this.authorisationService.currentPerson) {
            return false;
        }

        return this.personCanViewBoard(this.authorisationService.currentPerson, board);
    }

    public currentPersonCanEditBoard(board?: Board) {
        if (!this.authorisationService.currentPerson) {
            return false;
        }

        return this.personCanEditBoard(this.authorisationService.currentPerson, board);
    }

    public canEditPrivateBoardsForTeam(team: Team) {
        if (!this.authorisationService.currentPerson) {
            return false;
        }

        // need edit kanban to team (auth service personHasPermission already check both global org permission and team)
        return this.authorisationService.personHasPermission(this.authorisationService.currentPerson, FeaturePermissionName.StewardshipWorkKanbanEdit, team);
    }

    /** Duplicates logic on backend in BoardRepositoryEntity.cs */
    public personCanEditBoard(person: Person, board?: Board) {
        if (!board) {
            return false;
        }

        const hasPersonalStewardshipEdit = this.authorisationService.personHasPermission(person, FeaturePermissionName.StewardshipWorkKanbanPersonalEdit);
        const hasPublicKanbanEdit = this.authorisationService.personHasPermission(person, FeaturePermissionName.StewardshipWorkKanbanPublicEdit);
        const hasPermissionToEditTeamKanban = this.authorisationService.personHasPermission(person, FeaturePermissionName.StewardshipWorkKanbanEdit, board);

        const hasAccess = !board.isArchived
            && ((hasPersonalStewardshipEdit && board.personId === person.personId)
                || (board.teamId && hasPermissionToEditTeamKanban)
                || (hasPublicKanbanEdit && board.isPublicWriteAccess && this.isTeamKanbanEnabled(board)));

        return hasAccess;
    }

    private isTeamKanbanEnabled(team: IEntityWithOptionalTeam) {
        return this.featuresService.isFeatureActive(FeatureName.StewardshipWorkKanban, team);
    }
}

import { Component, Injector, TemplateRef, ViewChild } from "@angular/core";
import { MeetingAgendaItem, MeetingAgendaItemType } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { MeetingAgendaTemplate } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-template";
import { Team as TeamEntity } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { MeetingsService } from "@org-common/lib/meetings/meetings.service";
import { IWorkflowStepFooterTemplate, WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { lastValueFrom, of, Subject } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { ScheduleMeetingWorkflowStepBaseComponent } from "../schedule-meeting-workflow-step-base.component";

enum MeetingAgendaType {
    AdHoc,
    System,
    Organisation,
    Team,
}

interface IMeetingAgendaDetail {
    template: MeetingAgendaTemplate;
    type: MeetingAgendaType;
}

@WorkflowStepComponent("adapt-schedule-meeting-select-agenda-step")
@Component({
    selector: "adapt-schedule-meeting-select-agenda-step",
    templateUrl: "./schedule-meeting-select-agenda-step.component.html",
    styleUrls: ["./schedule-meeting-select-agenda-step.component.scss"],
})
export class ScheduleMeetingSelectAgendaStepComponent extends ScheduleMeetingWorkflowStepBaseComponent {
    public readonly MeetingAgendaType = MeetingAgendaType;

    public workflowGoToStep = new Subject<number>();
    public workflowStepFinishCurrent = new Subject<void>();

    public globalTemplates: IMeetingAgendaDetail[] = [];
    public teamAgendaTemplates: IMeetingAgendaDetail[] = [];
    public pathwayTemplates: IMeetingAgendaDetail[] = [];
    public otherTeamAgendaTemplates = new Map<TeamEntity, IMeetingAgendaDetail[]>();
    public selectedAgendaTemplate?: IMeetingAgendaDetail;
    private allAgendaTemplates: IMeetingAgendaDetail[] = [];

    public agendaItems: MeetingAgendaItem[] = [];

    public loading = true;
    public otherTeamsCollapsed = true;
    public pathwaysCollapsed = true;

    @ViewChild("startMeetingNowButton")
    public set startMeetingNowButton(ref: TemplateRef<any>) {
        if (!this.allFooterTemplates.find((t) => t.template === ref)) {
            this.allFooterTemplates.push({ alignment: "right", template: ref });
            this.workflowStepFooterTemplates.next(this.allFooterTemplates);
        }
    }

    @ViewChild("createAgendaButton")
    public set createAgendaButton(ref: TemplateRef<any>) {
        if (!this.allFooterTemplates.find((t) => t.template === ref)) {
            this.allFooterTemplates.push({ alignment: "left", template: ref });
            this.workflowStepFooterTemplates.next(this.allFooterTemplates);
        }
    }

    private allFooterTemplates: IWorkflowStepFooterTemplate[] = [];

    public constructor(
        injector: Injector,
        protected meetingsService: MeetingsService,
    ) {
        super(injector);
    }

    public get agendaHasItems() {
        return this.agendaItems.length > 0;
    }

    public get startMeetingNowTooltip() {
        if (!this.selectedAgendaTemplate) {
            return "Select a meeting agenda first";
        }

        if (this.agendaHasItems) {
            return this.nonStartableMeetingInfo;
        }

        return undefined;
    }

    public get stepIsValid() {
        return this.agendaHasItems && this.allEntitiesAreValid;
    }

    public async workflowStepOnInit() {
        super.workflowStepOnInit();

        // don't kick the user to the right step if they've manually gone back
        if (!this.runData.hasGoneBack) {
            // rescheduling, need to jump to the schedule step
            if (this.runData.rescheduling) {
                this.workflowGoToStep.next(2);
                return;
            }

            // check if there is an agenda already, if so it could be a restored dialog.
            const hasAgenda = this.runData.meeting.meetingAgendaItems.length > 0;
            const isNewMeeting = this.runData.meeting.entityAspect.entityState.isAdded();

            // editing an already existing meeting, skip to the customise agenda step
            if (!isNewMeeting || hasAgenda) {
                this.workflowGoToStep.next(1);
                return;
            }
        }

        await lastValueFrom(this.meetingsService.initialiseMeeting(this.runData.meeting));

        // add the meeting entities to changes so we can discard them
        this.emitMeetingEntitiesChanged();

        this.runData.hasGoneBack = false;
        await this.updateTemplates();

        if (this.runData.selectedTemplateId) {
            // find the selected template from all the templates
            const foundTemplate = this.allAgendaTemplates.find(({ template }) => template.meetingAgendaTemplateId === this.runData.selectedTemplateId);
            if (foundTemplate) {
                await this.onAgendaSelected(foundTemplate);

                // template is in another team, so auto-open the collapse
                if (foundTemplate.template.team !== this.runData.meeting.team) {
                    this.otherTeamsCollapsed = false;
                }

                // template has an article, probably a pathway
                if (foundTemplate.template.meetingDescriptionArticleSlug) {
                    this.pathwaysCollapsed = false;
                }
            }
        }

        this.loading = false;
    }

    public workflowStepNext() {
        return this.populateMeetingAgenda();
    }

    public createOwnAgenda() {
        this.selectedAgendaTemplate = undefined;
        this.workflowStepFinishCurrent.next();
    }

    @Autobind
    public populateAndRunMeetingNow() {
        return this.populateMeetingAgenda().pipe(
            switchMap(() => this.runMeetingNow(true)),
        );
    }

    @Autobind
    public createNewAgenda() {
        return this.meetingsService.createAgendaTemplate(this.runData.meeting.teamId).pipe(
            switchMap((agendaTemplate) => this.meetingsUiService.editAgendaTemplate(agendaTemplate)),
            switchMap(async (agendaTemplate) => {
                await this.updateTemplates();

                // select the newly added template by default
                const agendaDetails = this.teamAgendaTemplates.find((details) => details.template === agendaTemplate);
                if (agendaDetails) {
                    await this.onAgendaSelected(agendaDetails);
                }
            }),
            this.takeUntilDestroyed(),
        );
    }

    public async onAgendaSelected(agenda: IMeetingAgendaDetail) {
        if (!this.submitting) {
            this.selectedAgendaTemplate = agenda;
            this.runData.selectedTemplateId = agenda.template.meetingAgendaTemplateId;
            this.runData.meeting.name = agenda.template.name;
            if (this.runData.meeting.supplementaryData) {
                this.runData.meeting.supplementaryData.purpose = agenda.template.meetingDescription ?? "";
            }
            this.agendaItems = await lastValueFrom(this.getAgendaItemsForSelectedTemplate());
            this.validateEntities();
        }
    }

    private async updateTemplates() {
        this.otherTeamAgendaTemplates.clear();
        const teamAgendaTemplates: IMeetingAgendaDetail[] = [];
        const globalTemplates: IMeetingAgendaDetail[] = [];
        const pathwayTemplates: IMeetingAgendaDetail[] = [];
        const allAgendaTemplates: IMeetingAgendaDetail[] = [];

        const allTemplates = await lastValueFrom(this.meetingsService.getMeetingAgendaTemplates());
        for (const template of allTemplates) {
            const isTeam = !!template.teamId;
            const isSystem = !!template.code;
            const isPathway = !!template.meetingDescriptionArticleSlug;

            let type = MeetingAgendaType.Organisation;
            if (isSystem) {
                type = MeetingAgendaType.System;
            } else if (isTeam) {
                type = MeetingAgendaType.Team;
            }

            const detail = { template, type };

            if (isPathway) {
                pathwayTemplates.push(detail);
            } else if (template.team) {
                if (template.team === this.runData.meeting.team) {
                    teamAgendaTemplates.push(detail);
                } else {
                    const teamItems = this.otherTeamAgendaTemplates.get(template.team) ?? [];
                    teamItems.push(detail);
                    this.otherTeamAgendaTemplates.set(template.team, teamItems);
                }
            } else {
                globalTemplates.push(detail);
            }

            allAgendaTemplates.push(detail);
        }

        this.globalTemplates = globalTemplates.sort(this.sortTemplates);
        this.teamAgendaTemplates = teamAgendaTemplates.sort(this.sortTemplates);
        this.pathwayTemplates = pathwayTemplates.sort(this.sortTemplates);
        for (const teamTemplates of this.otherTeamAgendaTemplates.values()) {
            teamTemplates.sort(this.sortTemplates);
        }

        // doesn't need to be sorted as order doesn't matter
        this.allAgendaTemplates = allAgendaTemplates;
    }

    // sort by the type enum, then alphabetically
    private sortTemplates(a: IMeetingAgendaDetail, b: IMeetingAgendaDetail) {
        return a.type - b.type || a.template.name.localeCompare(b.template.name);
    }

    private getAgendaItemsForSelectedTemplate() {
        if (!this.selectedAgendaTemplate) {
            return of([]);
        }

        if (this.selectedAgendaTemplate.type === MeetingAgendaType.AdHoc) {
            const adHocAgendaItem = new MeetingAgendaItem();
            adHocAgendaItem.ordinal = 0;
            adHocAgendaItem.name = "Ad-hoc meeting";
            adHocAgendaItem.type = MeetingAgendaItemType.AgendaItem;
            return of([adHocAgendaItem]);
        }

        return this.meetingsService.getAgendaItemsForMeetingAgendaTemplate(this.selectedAgendaTemplate.template.meetingAgendaTemplateId);
    }

    private populateMeetingAgenda() {
        if (!this.selectedAgendaTemplate) {
            return of([]);
        }

        this.submitting = true;

        return this.meetingsService.importAgendaItems(this.agendaItems, this.runData.meeting.meetingId).pipe(
            tap(() => this.emitMeetingEntitiesChanged()),
        );
    }
}

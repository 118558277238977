import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { IdentityInterceptor } from "./identity.interceptor";

@NgModule({
    imports: [],
    declarations: [],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: IdentityInterceptor,
            multi: true,
        },
    ],
})
export class AdaptIdentityModule {}

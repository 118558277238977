import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptMenuModule } from "@common/ux/menu/menu.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptTeamDashboardSharedModule } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.module";
import { DxSelectBoxModule, DxTextBoxModule } from "devextreme-angular";
import { AdaptDirectorySharedModule } from "../directory-shared/directory-shared.module";
import { AdaptLabellingModule } from "../labelling/labeling.module";
import { DisplayLinkedObjectivesComponent } from "./display-linked-objectives/display-linked-objectives.component";
import { DisplayObjectiveStatusComponent } from "./display-objective-status/display-objective-status.component";
import { LinkObjectiveComponent } from "./link-objective.component/link-objective.component";
import { LinkObjectivesPageComponent } from "./link-objectives-page/link-objectives-page.component";
import { ObjectiveActionsComponent } from "./objective-actions/objective-actions.component";
import { SelectObjectiveStatusComponent } from "./select-objective-status/select-objective-status.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AdaptTooltipModule,
        AdaptDateModule,
        AdaptMenuModule,
        AdaptTeamDashboardSharedModule,
        AdaptDirectorySharedModule,
        AdaptButtonModule,
        DxSelectBoxModule,
        DxTextBoxModule,
        AdaptLabellingModule,
    ],
    declarations: [
        LinkObjectiveComponent,
        LinkObjectivesPageComponent,
        ObjectiveActionsComponent,
        DisplayObjectiveStatusComponent,
        SelectObjectiveStatusComponent,
        DisplayLinkedObjectivesComponent,
    ],
    exports: [
        LinkObjectiveComponent,
        LinkObjectivesPageComponent,
        ObjectiveActionsComponent,
        DisplayObjectiveStatusComponent,
        SelectObjectiveStatusComponent,
        DisplayLinkedObjectivesComponent,
    ],
})
export class AdaptObjectivesSharedModule { }

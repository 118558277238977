import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { SurveyDetails } from "../survey-details";
import { ISurveyQuestions } from "../survey-questions.interface";
import { ISurveyResponseChoice } from "../survey-response-choice.interface";

const orgName = "ORGANISATION_NAME";
const EmployeeEngagementQuestionList: { [surveyQuestionId: number]: string } = {
    [1]: `I would recommend ${orgName} as a great place to work`,
    [2]: `I see myself working at ${orgName} in 2 years time`,
    [3]: `I am not actively looking for a job at another organisation`,
    [4]: `I am proud to tell people I work at ${orgName}`,
    [5]: `At ${orgName} I am motivated to try my best`,
};

const EmployeeEngagementQuestionChoices: { [score: number]: string } = {
    [1]: "Strongly disagree",
    [2]: "Disagree",
    [3]: "Neutral",
    [4]: "Agree",
    [5]: "Strongly agree",
};

export class EmployeeEngagementQuestions implements ISurveyQuestions {
    public constructor(private surveyDetails: SurveyDetails) { }

    public getQuestion(questionId: number) {
        const question = EmployeeEngagementQuestionList[questionId];
        if (question) {
            return question.replace(orgName, this.surveyDetails.organisationName);
        } else {
            return undefined;
        }
    }

    public get questionIds() {
        return Object.keys(EmployeeEngagementQuestionList).map(Number);
    }

    public get orderedQuestionIds() {
        return this.questionIds;
    }

    public get categoryIds() {
        return undefined;
    }

    public get surveyResponseChoices() {
        return Object.keys(EmployeeEngagementQuestionChoices).map(Number)
            .map((value) => ({ text: EmployeeEngagementQuestionChoices[value], value } as ISurveyResponseChoice));
    }

    public getQuestionIdsForCategory(): undefined {
        throw new AdaptError("No category expected for employee engagement survey");
    }

    public getCategory() {
        return undefined;
    }
}

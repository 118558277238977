import { InjectionToken, Provider, Type } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";

export const ORGANISATION_EVENT_HANDLERS = new InjectionToken<IOrganisationEventHandler[]>("ORGANISATION_EVENT_HANDLERS");
export const ORGANISATION_ENTITY_UPDATED_EVENT_HANDLERS = new InjectionToken<IOrganisationEntityUpdatedEventHandler[]>("ORGANISATION_ENTITY_UPDATED_EVENT_HANDLERS");

export interface IOrganisationEventHandler {
    organisationChanged(organisation: Organisation): void;
}

export interface IOrganisationEntityUpdatedEventHandler {
    organisationEntityUpdated(organisation: Organisation | undefined): void;
}

export function provideOrganisationEventHandler(handler: Type<IOrganisationEventHandler>): Provider {
    return {
        provide: ORGANISATION_EVENT_HANDLERS,
        useExisting: handler,
        multi: true,
    };
}

export function provideOrganisationEntityUpdatedEventHandler(handler: Type<IOrganisationEntityUpdatedEventHandler>): Provider {
    return {
        provide: ORGANISATION_ENTITY_UPDATED_EVENT_HANDLERS,
        useExisting: handler,
        multi: true,
    };
}

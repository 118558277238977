<dx-select-box [dataSource]="surveys$ | async"
               [value]="survey"
               (valueChange)="surveyChange.emit($event)"
               fieldTemplate="field">
    <div *dxTemplate="let data of 'field'">
        <div class="d-flex justify-content-between align-items-baseline">
            <dx-text-box [visible]="false"
                         [readOnly]="true"></dx-text-box>
            <span class="display-survey-name m-2">{{data?.name}}</span>
            <small class="text-nowrap pe-2">{{data?.endTime | adaptDate}}</small>
        </div>
    </div>
    <div *dxTemplate="let data of 'item'">
        <div class="d-flex justify-content-between">
            <span class="display-survey-name">{{data?.name}}</span>
            <small class="text-nowrap">{{data?.endTime | adaptDate}}</small>
        </div>
    </div>
</dx-select-box>

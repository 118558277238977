<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.InputsOverview"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<adapt-toolbar-content alignment="left"
                       *ngIf="currentCanvas">
    <div class="d-flex align-items-center">
        <adapt-select-canvas [(canvas)]="currentCanvas"
                             (canvasChange)="onCanvasChanged($event)"
                             data-tour="inputs-canvas-select"></adapt-select-canvas>
    </div>
</adapt-toolbar-content>

<adapt-toolbar-content *adaptIfAuthorised="EditStrategicInputs"
                       alignment="right">
    <button *ngIf="!currentCanvas"
            adaptButton="add"
            data-tour="add-swt-canvas"
            (click)="addSWTCanvas()">canvas</button>
    <adapt-menu *ngIf="currentCanvas"
                [items]="menuItems"
                data-tour="inputs-menu"></adapt-menu>
</adapt-toolbar-content>

<adapt-input-types-map [canvas]="currentCanvas"></adapt-input-types-map>

<div *ngIf="!currentCanvas"
     class="alert alert-secondary mt-3">
    There are currently no canvases recorded.
    <ng-container *adaptIfAuthorised="EditStrategicInputs">Please use the add canvas button above to get started.</ng-container>
</div>

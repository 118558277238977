import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Label } from "@common/ADAPT.Common.Model/organisation/label";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DurationSelector, IDuration } from "@common/ux/duration-selector";
import isEqual from "lodash.isequal";
import { KanbanFilterParamKeys } from "../kanban-page/kanban-page.component";

// TODO: move this closer to query where this is used
export enum FilterDueDate {
    AllItems = 0,
    OverdueItems = 1,
    OnScheduleItems = 2,
}

export interface IFilterParams {
    assignee?: Person;
    dueDate: FilterDueDate;
    createdWithin?: IDuration;
    updatedWithin?: IDuration;
    labels?: Label[];
}

@Component({
    selector: "adapt-kanban-filter",
    templateUrl: "./kanban-filter.component.html",
})
export class KanbanFilterComponent extends BaseComponent {
    public readonly DurationOptions = DurationSelector.DataSource;
    private readonly DefaultFilter = { dueDate: FilterDueDate.AllItems };
    public readonly KanbanFilterParamKeys = Object.values(KanbanFilterParamKeys);

    @Input() public team?: Team;

    @Input() public set filter(value: IFilterParams) {
        this.filterUpdater.next(value);
    }

    public get filter() {
        return this._filter;
    }

    @Output() public filterChange = new EventEmitter<IFilterParams>();

    public isDefaultFilter = true;
    private isDefaultFilterUpdater = this.createThrottledUpdater((isDefault: boolean) => this.isDefaultFilter = isDefault);

    public itemDueDateDataSource = [{
        label: "All actions",
        value: FilterDueDate.AllItems,
    }, {
        label: "Overdue actions",
        value: FilterDueDate.OverdueItems,
    }, {
        label: "On schedule actions",
        value: FilterDueDate.OnScheduleItems,
    }];

    private _filter: IFilterParams = this.DefaultFilter;
    private filterUpdater = this.createThrottledUpdater((filter: IFilterParams) => {
        if (!filter) {
            this.resetFilterOptions(false);
        } else {
            // clone the incoming filter so that we can trigger a change on the caller when something changes
            const newFilter = ObjectUtilities.cleanNullAndUndefinedValues(filter);
            if (!isEqual(this.filter, newFilter)) {
                this._filter = newFilter;
                this.filterChange.emit(this._filter);
            }
        }
    });

    public constructor() {
        super();

        this.filterChange.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((f) => this.isDefaultFilterUpdater.next(isEqual(f, this.DefaultFilter)));
    }

    public resetFilterOptions(emit = true) {
        this._filter = { ...this.DefaultFilter };
        if (emit) {
            this.filterChange.emit(this._filter);
        }
    }

    public assigneeChanged(person?: Person) {
        this._filter.assignee = person ?? undefined; // clearing in select-person component will return null
        this.filterChange.emit(this._filter);
    }

    public onDueDateChanged(value: FilterDueDate) {
        this._filter.dueDate = value;
        this.filterChange.emit(this._filter);
    }

    public onCreatedDateChanged(value: string) {
        this._filter.createdWithin = this.DurationOptions.find((i) => i.slug === value);
        this.filterChange.emit(this._filter);
    }

    public onUpdatedDateChanged(value: string) {
        this._filter.updatedWithin = this.DurationOptions.find((i) => i.slug === value);
        this.filterChange.emit(this._filter);
    }

    public onLabelsChanged(labels: Label[]) {
        if (!labels || labels.length < 1) {
            delete this._filter.labels;
        } else {
            this._filter.labels = labels;
        }

        this.filterChange.emit(this._filter);
    }
}

import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { SurveyTypeLabel } from "@common/ADAPT.Common.Model/organisation/survey";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { EmployeeEngagementAuthService } from "../employee-engagement-auth.service";
import { ManageEmployeeEngagementsPageComponent } from "./manage-employee-engagements-page.component";

export const manageEmployeeEngagementsPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-manage-employee-engagements-page", ManageEmployeeEngagementsPageComponent)
    .atOrganisationUrl("/culture/employee-engagement")
    .verifyingFeatures(FeatureName.CulturalNetworkEmployeeEngagement)
    .verifyingAccess(EmployeeEngagementAuthService.ReadEmployeeEngagement)
    .withTitle(SurveyTypeLabel.EmployeeEngagement)
    .reloadOnSearch(false)
    .build();

<div *adaptIfAlto="true"
     class="d-flex pt-5 w-100 flex-column align-items-center h-100 justify-content-center">
    <ng-container *ngTemplateOutlet="pageContent"></ng-container>
</div>
<adapt-page-with-splash-image *adaptIfAlto="false">
    <ng-container *ngTemplateOutlet="pageContent"></ng-container>
</adapt-page-with-splash-image>

<ng-template #pageContent>
    <a href="https://theadaptway.com"
       aria-label="Go to adapt website">
        <img src="/content/shell/images/adaptplatformlogo.svg"
             class="mb-4"
             alt="adapt logo"
             width="140" />
    </a>

    <div *adaptIfAlto="false"
         class="w-100 justify-content-center">
        <h1>Login</h1>
        <p>Please login below using your {{projectLabel}} credentials.
            <ng-container *ngIf="isNimbus">
                <br>
                <a class="btn btn-link"
                   (click)="changeBaseApi()">Switch to {{swapProjectLabel}}</a>
            </ng-container>
        </p>
        <ng-container *ngTemplateOutlet="formContent"></ng-container>
    </div>

    <div *adaptIfAlto="true"
         class="row w-100 justify-content-center">
        <div class="col-12 col-sm-9 col-lg-6 col-xl-5 col-xxl-4">
            <ng-container *ngTemplateOutlet="formContent"></ng-container>
        </div>
    </div>

    <ng-template #formContent>
        <div role="alert"
             *ngIf="reason"
             [ngClass]="'alert alert-' + reason.type">{{reason.message}}</div>

        <div *ngIf="submitMessage"
             role="alert"
             class="alert alert-danger my-3">{{submitMessage}}</div>

        <!-- form with no submit event required to handle enter keypress on inputs -->
        <form>
            <dx-validation-group #validationGroup>
                <div class="form-group">
                    <dx-text-box id="email"
                                 mode="email"
                                 [inputAttr]="{ 'aria-label': 'email' }"
                                 [(value)]="email"
                                 (onValueChanged)="validateGroup()"
                                 placeholder="Email address"
                                 data-test="email-input">
                        <dx-validator>
                            <dxi-validation-rule type="email"></dxi-validation-rule>
                            <dxi-validation-rule type="required"
                                                 message="Email is required"></dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>

                <div class="form-group">
                    <dx-text-box id="password"
                                 mode="password"
                                 [inputAttr]="{ 'aria-label': 'password' }"
                                 [(value)]="password"
                                 (onValueChanged)="validateGroup()"
                                 placeholder="Password"
                                 data-test="password-input">
                        <dx-validator>
                            <dxi-validation-rule type="required"
                                                 message="Password is required"></dxi-validation-rule>
                        </dx-validator>
                    </dx-text-box>
                </div>

                <button [adaptBlockingClick]="submit"
                        [unsubscribeOnDestroy]="false"
                        type="submit"
                        adaptButtonIcon="fal fa-fw fa-sign-in"
                        data-test="submit-btn"
                        class="btn btn-primary w-100"
                        [disabled]="isDisabled">
                    Login
                </button>
            </dx-validation-group>
        </form>

        <!-- TODO: alto support -->
        <ng-container *adaptIfAlto="false">
            <div *ngIf="ExternalLoginProviders.length > 0"
                 class="clearfix d-flex flex-column w-100 align-items-center gap-2 my-3">
                <strong class="text-muted">Or login with:</strong>
                <div *ngFor="let provider of ExternalLoginProviders"
                     class="w-100">
                    <button type="button"
                            class="btn btn-outline-secondary w-100 d-flex align-items-center justify-content-center gap-2"
                            [attr.data-test]="'sign-in-' + provider.provider.toLowerCase()"
                            [adaptBlockingClick]="signInProvider"
                            [adaptBlockingClickParam]="provider">
                        <img [src]="provider.iconPath"
                             [alt]="provider.providerDisplayName"
                             style="height: 24px; width: 24px;" />
                        <strong>{{provider.providerDisplayName}}</strong>
                    </button>
                </div>
            </div>
        </ng-container>

        <div class="clearfix d-flex w-100 align-items-center mt-2">
            <a [routerLink]="forgotPasswordUrl$ | async">
                <small>Forgot password?</small>
            </a>
            <div *adaptIfAlto="true"
                 class="ms-auto d-flex align-items-center">
                <small>Don't have an account?</small>
                <a routerLink="/account/signup"
                   class="ms-2 d-flex align-items-center">
                    <small>Sign up</small>
                </a>
            </div>
        </div>

        <ng-container *ngIf="!isNimbus">
            <hr>
            <p>Click
                <a routerLink="/about/privacy-terms-conditions"
                   [queryParams]="{ section: '1' }">here</a> for our privacy statement.
            </p>
        </ng-container>
    </ng-template>
</ng-template>

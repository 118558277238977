import moment from "moment";
import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Input, InputType } from "./input";
import { InputGroup } from "./input-group";
import { Organisation } from "./organisation";

export enum CanvasType {
    StrengthsWeaknessesTrends = "StrengthsWeaknessesTrends",
    StopStartKeepDoing = "StopStartKeepDoing",
    CompetitorAnalysis = "CompetitorAnalysis",
}

export const CanvasTypeLabel: { [key in CanvasType]: string } = {
    [CanvasType.StrengthsWeaknessesTrends]: "Strengths/Weaknesses/Trends",
    [CanvasType.StopStartKeepDoing]: "Stop/Start/Keep-doing",
    [CanvasType.CompetitorAnalysis]: "Competitor Analysis",
};

export const CanvasInputTypes: { [canvasType in CanvasType]: InputType[] } = {
    [CanvasType.StrengthsWeaknessesTrends]: [InputType.Strength, InputType.Weakness, InputType.Trend],
    [CanvasType.StopStartKeepDoing]: [InputType.Stop, InputType.Start, InputType.KeepDoing],
    [CanvasType.CompetitorAnalysis]: [InputType.Win, InputType.Lose, InputType.Compete],
};

export class InputsCanvas extends BaseEntity<InputsCanvas> {
    public inputsCanvasId!: number;
    public type!: CanvasType;
    public canvasDate!: Date;
    public organisationId!: number;

    public organisation!: Organisation;
    public inputs!: Input[];
    public inputGroups!: InputGroup[];

    public static GetCanvasTitle(canvasType: CanvasType, canvasDate?: moment.MomentInput) {
        if (canvasType === CanvasType.StrengthsWeaknessesTrends) {
            if (!canvasDate) {
                throw new Error("Canvas date is required for SWT canvas title");
            }

            return `${CanvasTypeLabel[canvasType]} - ${moment(canvasDate).format("DD/MM/YYYY")}`;
        }

        return CanvasTypeLabel[canvasType];
    }
}

export const InputsCanvasBreezeModel = new BreezeModelBuilder("InputsCanvas", InputsCanvas)
    .hasSource()
    .withPluralName("InputsCanvases")
    .isOrganisationEntity()
    .withIdField()
    .alwaysFetchingNavigationProperty("inputs")
    .alwaysFetchingNavigationProperty("inputGroups")
    .build();

<adapt-display-item-status [itemStatus]="itemStatusSelection"
                           *ngIf="disabled; else selectStatus"></adapt-display-item-status>
<ng-template #selectStatus>
    <dx-select-box class="h-100 transparent-background"
                   fieldTemplate="field"
                   [showDropDownButton]="false"
                   [items]="itemStatuses"
                   [dropDownOptions]="{minWidth: 130}"
                   [value]="itemStatusSelection"
                   (onValueChanged)="itemChange($event)"
                   [(opened)]="dropDownOpened">
        <div *dxTemplate="let itemStatus of 'item'"
             class="d-flex">
            <adapt-display-item-status [itemStatus]="itemStatus"></adapt-display-item-status>
        </div>
        <div *dxTemplate="let itemStatus of 'field'">
            <adapt-display-item-status [itemStatus]="itemStatus"
                                       [showRightChevronDown]="!dropDownOpened"
                                       [showRightChevronUp]="dropDownOpened"
                                       data-test="select-item-status-field"></adapt-display-item-status>
            <!-- Just need dx-text-box here to avoid dx error - not used for anything else -->
            <dx-text-box class="d-none"
                         [readOnly]="true"></dx-text-box>
        </div>
    </dx-select-box>
</ng-template>

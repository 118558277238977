import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { MeetingAgendaTemplate } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-template";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { MeetingsService } from "@org-common/lib/meetings/meetings.service";
import { MeetingsUiService } from "@org-common/lib/meetings/meetings-ui.service";
import { BehaviorSubject } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";

@Component({
    selector: "adapt-configure-meeting-agenda-templates",
    templateUrl: "./configure-meeting-agenda-templates.component.html",
    styleUrls: ["../../../meetings/meeting-styles.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ConfigureMeetingAgendaTemplatesComponent extends BaseComponent implements OnInit {
    @Input() public team?: Team;

    public agendaTemplates: MeetingAgendaTemplate[] = [];
    public loadingTemplates = true;
    private updateTemplates$ = new BehaviorSubject<void>(undefined);

    constructor(
        private meetingsService: MeetingsService,
        private meetingsUiService: MeetingsUiService,
        private commonDialogService: AdaptCommonDialogService) {
        super();
    }

    public ngOnInit() {
        this.loadingTemplates = true;
        this.updateTemplates$.pipe(
            switchMap(() => this.meetingsService.getMeetingAgendaTemplates()),
            map((templates) => templates
                .filter((t) => this.team ? t.teamId === this.team.teamId : !t.teamId)
                .sort(this.meetingsService.meetingAgendaTemplateSortFunction)),
            tap(() => this.loadingTemplates = false),
            this.takeUntilDestroyed(),
        ).subscribe((templates) => this.agendaTemplates = templates);
    }

    @Autobind
    public addNewAgendaTemplate() {
        return this.meetingsService.createAgendaTemplate(this.team?.teamId).pipe(
            switchMap((agendaTemplate) => this.meetingsUiService.editAgendaTemplate(agendaTemplate)),
            tap(() => this.updateTemplates$.next()),
            this.takeUntilDestroyed(),
        );
    }

    @Autobind
    public deleteAgendaTemplate(agendaTemplate: MeetingAgendaTemplate) {
        const dialogData: IConfirmationDialogData = {
            title: "Delete meeting agenda template",
            message: `Are you sure you want to delete agenda template <b>${agendaTemplate.name}</b>?`,
            confirmButtonText: "Delete",
            cancelButtonText: "Cancel",
        };
        return this.commonDialogService.openConfirmationDialogWithBoolean(dialogData).pipe(
            filter((confirmDelete) => !!confirmDelete),
            switchMap(() => this.meetingsService.remove(agendaTemplate)),
            switchMap(() => this.meetingsService.saveEntities([agendaTemplate])),
            tap(() => this.updateTemplates$.next()),
            this.takeUntilDestroyed(),
        );
    }

    @Autobind
    public editAgendaTemplate(agendaTemplate: MeetingAgendaTemplate) {
        return this.meetingsUiService.editAgendaTemplate(agendaTemplate).pipe(
            tap(() => this.updateTemplates$.next()),
            this.takeUntilDestroyed(),
        );
    }
}

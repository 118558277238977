import FroalaEditor from "froala-editor";

export class LoomFroala {
    public static registerConfig() {
        FroalaEditor.VIDEO_PROVIDERS?.push({
            test_regex: /^.+(loom.com)\/[^_&]+/,
            url_regex: /(?:https?:\/\/)?(?:www\.)?(?:loom\.com)\/(?:share|embed)?\/?(.+)/g,
            url_text: "https://loom.com/embed/$1",
            html: '<iframe width="640" height="360" src="{url}?hide_share=true&hideEmbedTopBar=true" frameborder="0" allowfullscreen></iframe>',
            provider: "loom",
        });
    }
}

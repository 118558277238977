import { Injectable } from "@angular/core";
import { forkJoin, map, ObservableInput, of } from "rxjs";
import { ArrayUtilities } from "../lib/utilities/array-utilities";
import { IDeactivateGuard } from "./guard.interface";

export type CanNavigateCallback = () => ObservableInput<boolean>;

@Injectable({
    providedIn: "root",
})
export class PreventNavigationGuard implements IDeactivateGuard {
    public static readonly Id = "PreventNavigationGuard";

    private canNavigateCallbacks: CanNavigateCallback[] = [];

    /**
     * Registers a callback to be run when navigation occurs.
     * Callbacks are expected to return an `ObservableInput<boolean>`.
     * Returning true will allow navigation.
     *
     * @param callback Function which returns an ObservableInput<boolean>
     */
    public registerCanNavigateCallback(callback: CanNavigateCallback) {
        this.canNavigateCallbacks.push(callback);

        return () => ArrayUtilities.removeElementFromArray(callback, this.canNavigateCallbacks);
    }

    public canDeactivate<T>(_component: T) {
        const observables = forkJoin(this.canNavigateCallbacks
            .map((cb) => cb())
            // default for if no callbacks are provided
            .concat(of(true)),
        );
        return observables.pipe(
            map((results) => results.every((result) => result)),
        );
    }
}

import { Component, ElementRef, Injector } from "@angular/core";
import { Meeting, MeetingStatus } from "@common/ADAPT.Common.Model/organisation/meeting";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { MeetingsService } from "@org-common/lib/meetings/meetings.service";
import { MeetingsUiService } from "@org-common/lib/meetings/meetings-ui.service";
import { WorkflowService } from "@org-common/lib/workflow/workflow.service";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { IRunWorkshopCustomData } from "@org-common/lib/workflow/workflow-meeting.service";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import { BehaviorSubject, of, Subject } from "rxjs";
import { filter, switchMap, tap } from "rxjs/operators";
import { ScheduleMeetingStepComponent } from "../schedule-meeting-step/schedule-meeting-step.component";

export interface IRunWorkshopStepData {
    notStartedContent?: string;
    inProgressContent?: string;
    endedContent?: string;
}

@WorkflowStepComponent("adapt-run-workshop-step")
@Component({
    selector: "adapt-run-workshop-step",
    templateUrl: "./run-workshop-step.component.html",
})
export class RunWorkshopStepComponent extends WorkflowStepComponentAdapter {
    public readonly MeetingStatus = MeetingStatus;
    public meeting?: Meeting;
    public workflowStepCompleted = new BehaviorSubject<boolean>(true);
    public canStart = false;

    public runWorkshopStepData?: IRunWorkshopStepData;
    public workflowStepFinishCurrent = new Subject<void>();

    public constructor(
        elementRef: ElementRef,
        injector: Injector,
        private workflowService: WorkflowService,
        private meetingsService: MeetingsService,
        private meetingsUiService: MeetingsUiService,
        rxjsBreezeService: RxjsBreezeService,
        private dialogService: AdaptCommonDialogService,
    ) {
        super(elementRef);
        this.subscribeToEmitForEntityTypeChange(injector, Meeting);
        rxjsBreezeService.entityTypeChanged(Meeting).pipe(
            filter((meeting) => meeting.meetingId === this.meeting?.meetingId),
            this.takeUntilDestroyed(),
        ).subscribe((meeting) => {
            this.meeting = meeting;
        });
    }

    public workflowStepOnInit() {
        if (this.workflowStep && this.workflowConnection) {
            if (!this.workflowStep.customData) {
                throw new Error("Expecting step data of type IRunWorkshopStepData to be defined in the customData field of the workflow step.");
            }

            this.runWorkshopStepData = this.workflowStep.customData;
            this.workflowService.getWorkflowCustomData<IRunWorkshopCustomData>(this.workflowConnection).pipe(
                switchMap((customData) => {
                    if (!customData.meetingId) {
                        throw new Error("Expected meeting Id not found from customData");
                    }

                    return this.meetingsService.getMeetingById(customData.meetingId);
                }),
                this.takeUntilDestroyed(),
            ).subscribe((meeting) => {
                if (!meeting) {
                    throw new Error("Expected meeting is not found");
                }

                this.meeting = meeting;

                if (ScheduleMeetingStepComponent.RUN_IMMEDIATELY) {
                    ScheduleMeetingStepComponent.RUN_IMMEDIATELY = false;

                    // logic to run the meeting is in workflowStepNext, this will trigger that
                    this.workflowStepFinishCurrent.next();
                }

                if (this.meeting.status === MeetingStatus.Ended) {
                    // this meeting is ended -> emit this to result in the workflow-run dialog to progress onto the next step
                    this.workflowStepFinishCurrent.next();
                }
            });
        }
    }

    public workflowStepNextText() {
        switch (this.meeting?.status) {
            case MeetingStatus.NotStarted:
                return "Start meeting";
            case MeetingStatus.InProgress:
                return "Go to meeting";
            default:
                break;
        }

        return undefined;
    }

    public workflowStepNext(interruptShortcut: Subject<void>) {
        if (this.meeting?.status === MeetingStatus.NotStarted) {
            return this.meetingsService.getNonStartableMeetingInfo(this.meeting, true).pipe(
                switchMap((meetingInfo) => {
                    if (meetingInfo) {
                        return this.dialogService.showErrorDialog("Cannot start meeting", meetingInfo);
                    }

                    return this.meetingsService.saveEntities(this.meeting!.extensions.startMeeting()).pipe(
                        tap(() => {
                            setTimeout(() => this.gotoMeetingPage());
                            interruptShortcut.next();
                        }),
                    );
                }),
            );
        } else if (this.meeting?.status === MeetingStatus.InProgress) {
            setTimeout(() => this.gotoMeetingPage());
            return of(undefined).pipe(
                tap(() => interruptShortcut.next()),
            );
        } else {
            return of(undefined);
        }
    }

    private gotoMeetingPage() {
        this.meetingsUiService.moveMeetingToActivePage(this.meeting!, undefined, true).subscribe();
    }
}

import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { EntityManager, EntityQuery, HttpResponse } from "breeze-client";

// taken from breeze.ExecuteQueryErrorCallback
export interface IBreezeExecuteQueryError {
    query: EntityQuery;
    httpResponse: HttpResponse;
    entityManager: EntityManager;
    message?: string;
    stack?: string;
    status: number;
}

export class BreezeQueryError extends AdaptError {
    private readonly queryError: IBreezeExecuteQueryError;

    public constructor(
        queryName: string,
        queryError: IBreezeExecuteQueryError,
    ) {
        let msg = `Error retrieving [${queryName}] from remote data source: `;

        if (queryError.status < 100) {
            msg += "Possibly caused by bad network connection";
        } else {
            msg += queryError.message;
        }

        if (queryError.status === 401) {
            msg += " [Unauthorized]";
        } else if (queryError.status === 403) {
            msg += " [Insufficient Permissions]";
        }

        super(msg);
        this.queryError = queryError;
        this.name = "BreezeQueryError";
    }

    public get shouldBeLogged() {
        // Don't log unauthorised as an error as it may happen in normal operation
        // e.g. when token expires and user refreshes the page
        return this.queryError.status !== 401;
    }
}

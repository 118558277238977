<dx-chart class="mb-3"
          [pathModified]="true"
          [dataSource]="itemStatuses"
          [customizePoint]="customizePoint">
    <dxo-size [height]="200"></dxo-size>
    <dxo-common-series-settings type="stackedbar">
    </dxo-common-series-settings>

    <dxo-tooltip [enabled]="true"
                 [customizeTooltip]="customizeTooltip"
                 [paddingLeftRight]="8"
                 [paddingTopBottom]="8"
                 color="black">
        <dxo-font [weight]="500"
                  color="white"></dxo-font>
        <dxo-border [visible]="false"></dxo-border>
    </dxo-tooltip>

    <dxi-series valueField="onScheduleCount"
                argumentField="name"
                [name]="onScheduleSeriesName">
    </dxi-series>

    <dxi-series valueField="overdueCount"
                argumentField="name"
                [name]="overdueSeriesName">
    </dxi-series>

    <dxi-value-axis [visible]="false">
        <dxo-label [visible]="false"></dxo-label>
        <dxo-grid [visible]="false"></dxo-grid>
    </dxi-value-axis>

    <dxo-argument-axis [visible]="false">
        <dxo-label overlappingBehavior="stagger"
                   wordWrap="none">
        </dxo-label>
    </dxo-argument-axis>

    <dxo-legend [visible]="false"></dxo-legend>
</dx-chart>

<div *adaptLoading="isProcessing"
     class="m-4">
    <ng-container *ngIf="emailConfirmed; else notConfirm">
        <h1>Email confirmed!</h1>
    </ng-container>
    <ng-template #notConfirm>
        <h2>Email confirmation failed</h2>
    </ng-template>

    <div class="my-3 alert alert-danger"
         *ngIf="errorMessage">{{errorMessage}}</div>

    <button adaptButtonIcon="previous"
            class="btn-primary"
            (click)="goHome()">Back to home</button>
</div>

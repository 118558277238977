import { Component, Inject, Injector } from "@angular/core";
import { Zone } from "@common/ADAPT.Common.Model/methodology/zone";
import { Theme } from "@common/ADAPT.Common.Model/organisation/theme";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { Observable, of, switchMap, tap } from "rxjs";
import { StrategyService } from "../strategy.service";

@Component({
    selector: "adapt-edit-theme-dialog",
    templateUrl: "./edit-theme-dialog.component.html",
    styleUrls: ["./edit-theme-dialog.component.scss"],
})
export class EditThemeDialogComponent extends BaseDialogWithDiscardConfirmationComponent<Theme> {
    public readonly dialogName = "EditTheme";

    public isEditorValid = true;

    protected entitiesToConfirm: IBreezeEntity<any>[];

    public constructor(
        injector: Injector,
        @Inject(ADAPT_DIALOG_DATA) public theme: Theme,
        private strategyService: StrategyService,
        private dialogService: AdaptCommonDialogService,
    ) {
        super(injector);
        this.entitiesToConfirm = [theme];
        this.autoResolveData = theme;
    }

    public get isNew() {
        return this.theme.entityAspect.entityState.isAdded();
    }

    public filterZone(zone: Zone) {
        return zone !== Zone.ResearchAndDevelopment;
    }

    @Autobind
    public saveAndClose() {
        const beforeSave: Observable<unknown> = this.isNew
            ? this.strategyService.appendThemeOrdinalInZone(this.theme, this.theme.zone)
            : of(undefined);
        return beforeSave.pipe(
            switchMap(() => super.saveAndClose()),
        );
    }

    @Autobind
    public deleteTheme() {
        const dialogData: IConfirmationDialogData = {
            title: "Deleting strategic theme",
            message: `<p>You are about to delete the strategic theme:</p>
                <blockquote><b>${this.theme.name}</b></blockquote>
                <p>All the corresponding goal associations will also be deleted. If you choose to delete, they can no longer be recovered.</p>
                <p>Are you sure you want to continue?</p>`,
            confirmButtonText: "Confirm & Delete",
            cancelButtonText: "Cancel",
            checkboxMessage: "Check here to confirm that you want to delete the theme permanently",
        };
        return this.dialogService.openConfirmationDialog(dialogData).pipe(
            // don't have to change affected goals as this is already taken care by ThemeRepositoryEntity
            switchMap(() => this.strategyService.remove(this.theme)),
            switchMap(() => this.strategyService.saveEntities(this.theme)),
            tap(() => this.resolve(this.theme)),
        );

    }
}

import { InitializedEvent as ChartInitializedEvent } from "devextreme/viz/chart";
import { InitializedEvent as CircularGaugeInitializedEvent } from "devextreme/viz/circular_gauge";
import BaseWidget from "devextreme/viz/core/base_widget";
import { InitializedEvent as LinearGaugeInitializedEvent } from "devextreme/viz/linear_gauge";
import { InitializedEvent as PieChartInitializedEvent } from "devextreme/viz/pie_chart";
import { InitializedEvent as PolarChartInitializedEvent } from "devextreme/viz/polar_chart";
import { timer } from "rxjs";

export class ChartUtils {
    public static updateChartDimension(e: ChartInitializedEvent | PolarChartInitializedEvent | PieChartInitializedEvent | CircularGaugeInitializedEvent | LinearGaugeInitializedEvent) {
        const chart = e.component;
        if (chart) {
            timer(0).subscribe(() => {
                // this is required to ensure the chart is resized properly
                if (!ChartUtils.isChartDisposed(chart)) {
                    chart.render();
                }
            });
        }
    }

    public static isChartDisposed(chart?: BaseWidget<any>) {
        if (!chart) {
            return true;
        }

        // - NOT ideal using _disposed but there is no other way to determine if it has been disposed
        // - calling render() or showLoadingIndicator() on disposed chart will cause error to be logged
        return !!(chart as any)._disposed;
    }
}

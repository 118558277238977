import { BaseEntity } from "@common/ADAPT.Common.Model/base-entity";
import { ICommonDataBase } from "@common/lib/data/common-data.interface";
import { Entity } from "breeze-client";
import { forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { IBreezeEntity } from "./breeze-entity.interface";
import { IBreezeModel } from "./breeze-model.interface";


export class BreezeEntityUtilities {
    public static filterEntitiesByModel<T extends Entity = IBreezeEntity>(entities: Entity[], model: IBreezeModel<T>): T[] {
        return entities.filter((e) => e.entityType.shortName === model.toType) as T[];
    }

    /**
     * Merge multiple entities into one entity of the same type.
     * Handles the updating of related entities, such as updating ValueStreamProducts when merging Products.
     *
     * @param removeFn function (that returns an observable) called on items to be removed (normally `commonDataService.remove`)
     * @param sources array of entities to merge
     * @param destination entity to merge into
     * @param relatedEntities entities that are related to the merge source/destination
     * @param sourceIdGetter function which gets the ID from the source entity.
     *     The same ID field must be present on the related entities.
     * @param relatedEntityIdGetter function which gets the ID from the related entities
     * @param relatedEntityIdSetter function which sets the source ID for the related entities
     * @return list of entities to save that should be passed to `saveEntities`
     */
    public static mergeEntities<TSource extends BaseEntity<TSource>, TRelated extends BaseEntity<TRelated>>(
        removeFn: ICommonDataBase["remove"],
        sources: TSource[],
        destination: TSource,
        relatedEntities: TRelated[],
        sourceIdGetter: (entity: TSource) => number,
        relatedEntityIdGetter: (entity: TRelated) => number,
        relatedEntityIdSetter: (entity: TRelated, value: number) => void,
    ): Observable<(TSource | TRelated)[]> {
        const targetId = sourceIdGetter(destination);

        const existingRelatedId = relatedEntities
            .filter((i) => sourceIdGetter(i as unknown as TSource) === targetId)
            .map((i) => relatedEntityIdGetter(i));
        const changedRelatedEntities = relatedEntities
            .filter((i) => sourceIdGetter(i as unknown as TSource) !== targetId);

        const removedRelatedEntities: TRelated[] = [];
        for (const relatedEntity of changedRelatedEntities) {
            if (existingRelatedId.includes(relatedEntityIdGetter(relatedEntity))) {
                removedRelatedEntities.push(relatedEntity);
            } else {
                // previous reference to the merged entity will point to new target
                relatedEntityIdSetter(relatedEntity, targetId);
                existingRelatedId.push(relatedEntityIdGetter(relatedEntity));
            }
        }

        const removedEntities = sources.filter((i) => sourceIdGetter(i) !== targetId);
        return forkJoin([...removedEntities, ...removedRelatedEntities].map(removeFn)).pipe(
            map(() => [...changedRelatedEntities, ...removedEntities]),
        );
    }
}

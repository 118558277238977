<adapt-dashboard-element *adaptIfAlto="false"
                         name="Objectives &amp; key results">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
</adapt-dashboard-element>

<adapt-styled-dashboard-element *adaptIfAlto="true"
                            name="Objectives"
                            linkText="View all objectives"
                            [url]="organisationHref$ | async">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
</adapt-styled-dashboard-element>

<ng-template #content>
    <div *adaptLoadingUntilFirstEmit="let objectiveTeamGroups of personalObjectivesGroups$"
         class="d-flex flex-column gap-3">
        <div *ngFor="let objectiveTeamGroup of objectiveTeamGroups">
            <!-- alto only has org objectives for now, don't need to show link here -->
            <adapt-link-objectives-page *adaptIfAlto="false"
                                        [team]="objectiveTeamGroup.team"
                                        class="objectives-team-label"></adapt-link-objectives-page>

            <div class="d-flex flex-column objectives">
                <adapt-objective-dashboard-summary *ngFor="let childObjective of objectiveTeamGroup.objectives"
                                                   [objective]="childObjective"
                                                   [displayAssignee]="false"></adapt-objective-dashboard-summary>
            </div>
        </div>

        <div *ngIf="!objectiveTeamGroups.length">
            You have no objectives in progress.
        </div>
    </div>
</ng-template>

<div class="d-flex align-items-center">
    <ng-container *ngIf="(isAuthed$ | async); else notLoggedIn">
        <div class="d-flex flex-column align-items-end">
            <i class="fas fa-calendar-xmark"></i>
        </div>

        <div class="ms-2">Calendar integration is not enabled.</div>
    </ng-container>

    <ng-template #notLoggedIn>
        <div class="d-flex flex-column align-items-end integration-icon">
            <i class="fas fa-calendar-xmark integration-disabled-icon"></i>
        </div>

        <button role="button"
                type="button"
                class="btn btn-primary ms-4"
                [disabled]="disabled"
                [adaptBlockingClick]="login">Disable calendar integration</button>
    </ng-template>
</div>


import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { provideOrganisationDashboardPageRoute, provideOrganisationPurposeVisionPageRoute, providePersonalDashboardPageRoute, provideTeamDashboardPageRoute, provideTeamGuidancePageRoute } from "@common/page-route-providers";
import { AdaptCommonRouteModule } from "@common/route/common-route.module";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { IComponentRender } from "@common/ux/render-component/component-render.interface";
import { AdaptRerenderModule } from "@common/ux/rerender/rerender.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { AdaptLabellingModule } from "@org-common/lib/labelling/labeling.module";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { AdaptSearchCommonModule } from "@org-common/lib/search/search.module";
import { SearchService } from "@org-common/lib/search/search.service";
import { AdaptTeamDashboardSharedModule } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.module";
import { DxAccordionModule, DxAutocompleteModule, DxCheckBoxModule, DxSelectBoxModule, DxTextBoxModule, DxTooltipModule } from "devextreme-angular";
import { organisationDashboardPageRoute } from "../organisation/organisation-dashboard-page/organisation-dashboard-page.route";
import { personalDashboardPageRoute } from "../person/personal-dashboard-page/personal-dashboard-page.route";
import { purposeVisionPageRoute } from "../purpose-vision/purpose-vision-page/purpose-vision-page.route";
import { teamDashboardPageRoute } from "../team/team-dashboard-page/team-dashboard-page.route";
import { SearchRowElementsComponent } from "./search-row-elements/search-row-elements.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AdaptTooltipModule,
        AdaptLoadingSpinnerModule,
        AdaptDirectorySharedModule,
        DxAccordionModule,
        AdaptTeamDashboardSharedModule,
        AdaptShellModule,
        AdaptCommonRouteModule,
        AdaptButtonModule,
        DxAutocompleteModule,
        DxCheckBoxModule,
        DxTooltipModule,
        DxTextBoxModule,
        FormsModule,
        DxSelectBoxModule,
        AdaptLabellingModule,
        AdaptRerenderModule,
        AdaptSearchCommonModule,
    ],
    declarations: [
        SearchRowElementsComponent,
    ],
    providers: [
        provideTeamDashboardPageRoute(teamDashboardPageRoute),
        provideOrganisationDashboardPageRoute(organisationDashboardPageRoute),
        providePersonalDashboardPageRoute(personalDashboardPageRoute),
        provideTeamGuidancePageRoute(teamDashboardPageRoute),
        provideOrganisationPurposeVisionPageRoute(purposeVisionPageRoute),
    ],
})
export class AdaptSearchModule {
    public constructor(labellingService: LabellingService, searchService: SearchService) {
        labellingService.registerLabelLinkProvider((label) => searchService.getSearchUrl$({
            keyword: undefined,
            labelIds: new Set<number>([label.labelId]),
        }));

        searchService.registerSearchGroup(SearchService.PageSearchGroup);
        searchService.registerSearchGroup(SearchService.PeopleTeamsRolesSearchGroup);
        searchService.registerSearchGroup(SearchService.KanbanSearchGroup);
        searchService.registerSearchGroup(SearchService.MeetingSearchGroup);
        searchService.registerSearchGroup(SearchService.ObjectivesSearchGroup);
        searchService.registerSearchGroup(SearchService.GuidanceSearchGroup);
        searchService.registerSearchGroup(SearchService.ImplementationKitSearchGroup);

        const searchElementComponent: IComponentRender<SearchRowElementsComponent> = {
            component: SearchRowElementsComponent,
        };

        SearchService.registerSearchElementComponent(searchElementComponent);
    }
}

import { Injector } from "@angular/core";
import { WorkflowDialogWidth, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { IButtonDefinition, WorkflowStepGuidancePosition } from "@common/ADAPT.Common.Model/embed/workflow-step";
import { PersonFlag } from "@common/ADAPT.Common.Model/person/person-flag.enum";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { UserService } from "@common/user/user.service";
import { buttonPreset } from "@common/ux/button/button-preset";
import { switchMap } from "rxjs";
import { PersonFlagService } from "../person/person-flag.service";
import { buildLocalWorkflow } from "../workflow/workflow.interface";
import { WorkflowRunDialogComponent } from "../workflow/workflow-run-dialog/workflow-run-dialog.component";
import { ObjectiveDescriptionFormationStep, ObjectiveDescriptionFormationStepSelector } from "./objective-description-formation-step/objective-description-formation-step.component";
import { ObjectiveMetadataStep, ObjectiveMetadataStepSelector } from "./objective-metadata-step/objective-metadata-step.component";
import { ObjectivesUiService } from "./objectives-ui.service";

const AddObjectiveWorkflowId = "AddObjectiveWorkflow";
const AddObjectiveWorkflowName = "Add objective";

const switchToObjectiveDialogButton: IButtonDefinition = {
    buttonText: "Use objective dialog",
    icon: "fal fa-comment",
    onClick: (injector: Injector, workflowRunDialog: WorkflowRunDialogComponent) => {
        const objectivesUiService = injector.get(ObjectivesUiService);
        const personFlagService = injector.get(PersonFlagService);
        const userService = injector.get(UserService);
        return personFlagService.setFlagAndSave(userService.getCurrentPersonId()!, PersonFlag.UseLegacyObjectiveCreation, true).pipe(
            switchMap(() => {
                workflowRunDialog.close();
                return objectivesUiService.editObjective(workflowRunDialog.data.runData);
            }),
        );
    },
};

export const AddObjectiveWorkflow = buildLocalWorkflow({
    name: AddObjectiveWorkflowName,
    workflowId: AddObjectiveWorkflowId,
    persistenceId: AddObjectiveWorkflowId,
    type: WorkflowType.Workflow,
    dialogWidth: WorkflowDialogWidth.Large,
    steps: [
        {
            ordinal: 0,
            name: `${AddObjectiveWorkflowName} - owner, type and due date`,
            articleSlug: ImplementationKitArticle.ObjectiveMetadataGuidance,
            articlePosition: WorkflowStepGuidancePosition.Left,
            componentSelector: ObjectiveMetadataStepSelector,
            customData: ObjectiveMetadataStep.Others,
            workflowStepNextText: "Next",
            titleRightButtons: [switchToObjectiveDialogButton],
        },
        {
            ordinal: 1,
            name: `${AddObjectiveWorkflowName} - why is it important?`,
            articleSlug: ImplementationKitArticle.ObjectiveWhyImportant,
            articlePosition: WorkflowStepGuidancePosition.Left,
            componentSelector: ObjectiveDescriptionFormationStepSelector,
            customData: ObjectiveDescriptionFormationStep.WhatToAchieveAndWhyImportant,
            allowBack: true,
            backButtonText: "Back",
            workflowStepNextText: "Next",
            titleRightButtons: [switchToObjectiveDialogButton],
        },
        {
            ordinal: 2,
            name: `${AddObjectiveWorkflowName} - how will we know we have been successful?`,
            articleSlug: ImplementationKitArticle.ObjectiveHowWeKnowSuccess,
            articlePosition: WorkflowStepGuidancePosition.Left,
            componentSelector: ObjectiveDescriptionFormationStepSelector,
            customData: ObjectiveDescriptionFormationStep.HowWeKnowSuccess,
            allowBack: true,
            backButtonText: "Back",
            workflowStepNextText: "Next",
            titleRightButtons: [switchToObjectiveDialogButton],
        },
        {
            ordinal: 3,
            name: `${AddObjectiveWorkflowName} - review objective description`,
            articleSlug: ImplementationKitArticle.ObjectiveCreationReview,
            articlePosition: WorkflowStepGuidancePosition.Left,
            componentSelector: ObjectiveDescriptionFormationStepSelector,
            customData: ObjectiveDescriptionFormationStep.Review,
            allowBack: true,
            backButtonText: "Back",
            workflowStepNextText: "Next",
            titleRightButtons: [switchToObjectiveDialogButton],
        },
        {
            ordinal: 4,
            name: `${AddObjectiveWorkflowName} - objective title`,
            articleSlug: ImplementationKitArticle.ObjectiveTitleGuidance,
            articlePosition: WorkflowStepGuidancePosition.Left,
            componentSelector: ObjectiveMetadataStepSelector,
            customData: ObjectiveMetadataStep.Title,
            allowBack: true,
            backButtonText: "Back",
            workflowStepNextText: buttonPreset.saveAndClose.buttonText,
            workflowStepNextIcon: `${buttonPreset.saveAndClose.iconClass} fa-fw`,
            titleRightButtons: [switchToObjectiveDialogButton],
        },
    ],
});

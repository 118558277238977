import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ItemStatus, ItemStatusMetadata } from "@common/ADAPT.Common.Model/organisation/item-status";
import { IDxSelectBoxValueChangedEvent } from "@common/ux/dx.types";

@Component({
    selector: "adapt-select-item-status",
    templateUrl: "./select-item-status.component.html",
})
export class SelectItemStatusComponent {
    @Input() public set status(value: ItemStatus) {
        this.itemStatusSelection = value;
    }

    @Output() public statusChange = new EventEmitter<ItemStatus>();
    @Input() public disabled?: boolean;

    public itemStatusSelection?: ItemStatus;
    public dropDownOpened = false;
    public itemStatuses = ItemStatusMetadata.All.map((metadata) => metadata.status);

    public itemChange(e: IDxSelectBoxValueChangedEvent<ItemStatus>) {
        // no event will be present if change comes from other source
        // i.e. edit-objective-dialog updates status, which causes the value to change for this select box
        if (e.event) {
            this.itemStatusSelection = e.value;
            this.statusChange.emit(this.itemStatusSelection);
        }
    }
}

<div class="edit-item-comment-component"
     [ngClass]="{
        'item-comment-current-person': (isCurrentPerson | async),
        'item-comment-not-current-person': !(isCurrentPerson | async),
        'item-comment-just-added': justAdded,
        'item-comment-has-update': chainItem.hasUpdate
     }">
    <adapt-person-image class="item-comment-person"
                        *ngIf="!chainItem.hasUpdate"
                        [person]="chainItem.person"></adapt-person-image>
    <div class="item-comment-container">
        <div class="item-comment-meta"
             *ngIf="!chainItem.hasUpdate">
            <adapt-link-person [person]="chainItem.person"></adapt-link-person>
            <adapt-entity-timestamp class="item-comment-date-time"
                                    [timestamp]="chainItem.dateTime"></adapt-entity-timestamp>
            <span class="item-comment-editing-buttons"
                  *ngIf="chainItem.canUpdate && !isEditing">
                <button adaptButtonIcon="edit"
                        class="btn btn-link"
                        (click)="toggleEditing()"></button>
                <button adaptButtonIcon="delete"
                        class="btn btn-link"
                        [adaptBlockingClick]="delete"></button>
            </span>
        </div>
        <div class="item-comment-text"
             [ngClass]="{'with-update': chainItem.hasUpdate, 'without-update': !chainItem.hasUpdate}"
             [ngSwitch]="isEditing">
            <adapt-html-editor *ngSwitchCase="true"
                               [(contents)]="chainItem.comment"
                               forceSize="compact"
                               [required]="true"
                               (isValidChange)="validateComment($event)"></adapt-html-editor>
            <div class="mt-1"
                 *ngSwitchCase="true">
                <button class="btn btn-primary btn-small"
                        [disabled]="commentIsInvalid"
                        [adaptBlockingClick]="save">Save comment</button>
                <button class="btn btn-default btn-small"
                        [adaptBlockingClick]="cancel">Cancel</button>
            </div>
            <div *ngSwitchCase="false"
                 [froalaView]="chainItem.comment"></div>
        </div>
    </div>
</div>

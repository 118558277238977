/* eslint-disable max-classes-per-file */
import { Injectable } from "@angular/core";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { UrlUtilities } from "@common/lib/utilities/url-utilities";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { DocumentDescriptor } from "../document-descriptor";
import { LazyLoadLibrary } from "./lazy-load-library";
import { IStorageProvider, StorageProviderGroup } from "./storage-provider.interface";
import { StorageProviderUtilities } from "./storage-provider-utilities";

@Injectable()
export class DropboxProviderService implements IStorageProvider {
    private static readonly ServiceName = "Dropbox";

    public disabled: boolean = false;
    private dropboxLazyLoad = new DropboxLazyLoadLibrary();

    public async setupComponent() {
        // this is called when select-document component is initialised and the initial selection from select-document
        // is custom URL
        // - so by the time openChooser is called, the library will already be loaded
        try {
            await this.dropboxLazyLoad.promiseToInitialise();
        } catch (e) {
            this.disabled = true;
        }
    }

    public cleanupComponent(): void { /* Nothing to do */ }
    public onOrganisationChanged(): void { /* Nothing to do */ }

    public getName(): string {
        return DropboxProviderService.ServiceName;
    }

    public getDisplayName(): string {
        return "Dropbox";
    }

    public getIconClass(): string {
        return "fab fa-fw fa-dropbox";
    }

    public getGroupName = () => StorageProviderGroup.CLOUD;

    public getSelectionInProgressText(): string {
        return "Complete selection in the popup Dropbox file chooser.";
    }

    public getDocument(url: string): DocumentDescriptor | null {
        if (StringUtilities.isString(url)) {
            if (StorageProviderUtilities.getUrlServiceNameIdentifier(url) === DropboxProviderService.ServiceName
                // url may not be added using embed dropbox chooser (otherwise, it would have the augmented service name identifier)
                || url.indexOf("dropbox.com") > 0) {
                const result = new DocumentDescriptor(
                    decodeURIComponent(UrlUtilities.getFileName(url)),
                    url,
                );

                result.setIconClass(this.getIconClass());
                return result;
            }
        }

        return null;
    }

    // returns open dialog promise which gets resolved to a URL or rejected selection is cancelled
    public openChooser(): Promise<DocumentDescriptor | null> {
        return new Promise((resolve, reject) => this.doChoose(resolve, reject));
    }

    private doChoose(resolve: (document: DocumentDescriptor | null) => void, reject: (e: any) => void) {
        const options: Dropbox.ChooserOptions = {
            success: onSuccess,
            cancel: onCancel,
            linkType: "preview", // preview or direct - direct expires after a few hours
            multiselect: false,
            folderselect: true,
        };
        const self = this;

        Dropbox.cancelChooser();
        Dropbox.choose(options);

        function onSuccess(files: any[]) { // Dropbox not using File! but their customized object
            if (files.length === 1) {
                let link = files[0].link;

                link = StorageProviderUtilities.addUrlServiceNameIdentifier(link, self);
                // With reference to: https://stackoverflow.com/questions/14959290/non-expiring-download-link-through-dropbox-chooser
                // changing the dl=0 to dl=1 will make this a download link
                // - Don't need a download link anymore - so use whatever dropbox provides.
                // link = self.helperService.addQueryParam(link, "dl", "1");
                resolve(new DocumentDescriptor(files[0].name, link));
            } else {
                // unexpected result - multiselect false!
                reject(null);
            }
        }

        function onCancel() {
            reject(null);
        }
    }
}

class DropboxLazyLoadLibrary extends LazyLoadLibrary {
    public constructor() {
        super("https://www.dropbox.com/static/api/2/dropins.js");
    }

    protected doInitialisation(source: Observable<void>) {
        return source.pipe(
            tap(() => Dropbox.appKey = AdaptClientConfiguration.DropboxAppKey),
        );
    }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptIdentityModule } from "@common/identity/identity.module";
import { DxLoadPanelModule, DxMenuModule, DxPopoverModule, DxPopupModule, DxScrollViewModule, DxTextBoxModule } from "devextreme-angular";
import { AdaptFeatureModule } from "../feature/feature.module";
import { AdaptCommonRouteModule } from "../route/common-route.module";
import { AdaptStorageModule } from "../storage/storage.module";
import { AdaptCommonUserModule } from "../user/adapt-common-user.module";
import { AdaptCommonDialogModule } from "../ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "../ux/button/button.module";
import { AdaptIconModule } from "../ux/icon/icon.module";
import { AdaptLoadingSpinnerModule } from "../ux/loading-spinner/loading-spinner.module";
import { AdaptMenuModule } from "../ux/menu/menu.module";
import { AdaptRenderComponentModule } from "../ux/render-component/render-component.module";
import { AdaptTooltipModule } from "../ux/tooltip.directive/tooltip.module";
import { AdaptCommonWorkflowModule } from "../workflow/workflow.module";
import { ApplicationBarComponent, provideApplicationBarItemType } from "./application-bar/application-bar.component";
import { ApplicationBarUserItem } from "./application-bar-user-item/application-bar-user-item";
import { ApplicationBarUserItemComponent } from "./application-bar-user-item/application-bar-user-item.component";
import { ApplicationBarUserItemContentComponent } from "./application-bar-user-item-content/application-bar-user-item-content.component";
import { CommonShellComponent } from "./common-shell/common-shell.component";
import { CommonSidebarComponent } from "./common-sidebar/common-sidebar.component";
import { AdaptContextSidebarModule } from "./context-sidebar/context-sidebar.module";
import { FilterComponent } from "./filter/filter.component";
import { FilterContentComponent } from "./filter/filter-content/filter-content.component";
import { NavigationBreadcrumbsComponent } from "./navigation-breadcrumbs.component/navigation-breadcrumbs.component";
import { ShellPopoverLinkItemComponent } from "./shell-popover-link-item/shell-popover-link-item.component";
import { SidebarTabIconComponent } from "./sidebar-tab-icon/sidebar-tab-icon.component";
import { SupportApplicationBarItemComponent } from "./support-application-bar-item/support-application-bar-item.component";
import { AdaptToolbarModule } from "./toolbar/toolbar.module";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        DxScrollViewModule,
        DxPopoverModule,
        DxTextBoxModule,
        DxPopupModule,
        DxLoadPanelModule,
        DxMenuModule,
        AdaptButtonModule,
        AdaptTooltipModule,
        AdaptLoadingSpinnerModule,
        AdaptRenderComponentModule,
        AdaptStorageModule,
        AdaptCommonRouteModule,
        AdaptCommonUserModule,
        AdaptIconModule,
        AdaptCommonDialogModule,
        AdaptMenuModule,
        AdaptCommonWorkflowModule,
        AdaptToolbarModule,
        AdaptContextSidebarModule,
        AdaptIdentityModule, // shell is using identity service but without importing module, the identity intercept won't be registered!
        AdaptFeatureModule,
    ],
    declarations: [
        FilterContentComponent,
        ApplicationBarComponent,
        ShellPopoverLinkItemComponent,
        ApplicationBarUserItemComponent,
        ApplicationBarUserItemContentComponent,
        FilterComponent,
        CommonShellComponent,
        CommonSidebarComponent,
        NavigationBreadcrumbsComponent,
        SidebarTabIconComponent,
        SupportApplicationBarItemComponent,
    ],
    exports: [
        FilterContentComponent,
        CommonShellComponent,
        SidebarTabIconComponent,
        AdaptContextSidebarModule,
        AdaptToolbarModule,
    ],
    providers: [
        provideApplicationBarItemType(ApplicationBarUserItem),
    ],
})
export class AdaptShellModule { }


import { Component } from "@angular/core";
import { NavigationHierarchyService } from "@common/route/navigation-hierarchy.service";
import { ConfigurationOrganisationHierarchyNavigationService } from "./configuration-organisation-hierarchy-navigation.service";
import { ConfigurationPersonalHierarchyNavigationService } from "./configuration-personal-hierarchy-navigation.service";
import { ConfigurationTeamHierarchyNavigationService } from "./configuration-team-hierarchy-navigation.service";

@Component({
    selector: "adapt-configuration-tab-content",
    templateUrl: "./configuration-tab-content.component.html",
    styleUrls: ["./configuration-tab-content.component.scss"],
})
export class ConfigurationTabContentComponent {
    public readonly personalHierarchyId = ConfigurationPersonalHierarchyNavigationService.HierarchyId;
    public readonly teamHierarchyId = ConfigurationTeamHierarchyNavigationService.HierarchyId;
    public readonly organisationHierarchyId = ConfigurationOrganisationHierarchyNavigationService.HierarchyId;

    public constructor(private navigationHierarchyService: NavigationHierarchyService) {}

    public hasNodes(hierarchyId: string) {
        const rootNode = this.navigationHierarchyService.getNodeByHierarchy(hierarchyId);
        return (rootNode?.children.length ?? 0) > 0;
    }
}

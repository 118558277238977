import { Component, Inject, Injector, OnInit } from "@angular/core";
import { UserType } from "@common/ADAPT.Common.Model/embed/user-type";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { Survey, SurveyStatus, SurveyType, SurveyTypeLabel } from "@common/ADAPT.Common.Model/organisation/survey";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { DateFormats } from "@common/ux/date-formats";
import { PreviewEmailDialogComponent } from "@org-common/lib/email/preview-email-dialog-component/preview-email-dialog-component.component";
import moment from "moment";
import { Observable, throwError } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { SurveyService } from "../survey.service";
import { SurveyUtils } from "../survey-utils";

@Component({
    selector: "adapt-edit-survey-dialog",
    templateUrl: "./edit-survey-dialog.component.html",
    styleUrls: ["./edit-survey-dialog.component.scss"],
})
export class EditSurveyDialogComponent extends BaseDialogWithDiscardConfirmationComponent<Survey> implements OnInit {
    public readonly dialogName = "EditSurvey";

    public readonly allowedBehalfUserTypes = [UserType.Leader, UserType.Collaborator, UserType.Coach];
    public SurveyType = SurveyType;

    public showNotification = false;

    public minStartTime: Date;
    public minEndTime!: Date;
    public dateTimeDisplayFormat = DateFormats.globalize.shortdatetime;
    public overlappingSurvey?: Survey;
    public surveyTypeLabel: string;
    public useCustomMessage?: boolean | null = false;

    public leaderTeam$?: Observable<Team | undefined>;
    private connections: Connection[] = [];

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public survey: Survey,
        private injector: Injector,
        private surveyService: SurveyService,
        private dialogService: AdaptCommonDialogService,
    ) {
        super(injector);

        this.surveyTypeLabel = SurveyTypeLabel[survey.surveyType];
        this.minStartTime = moment().startOf("day").toDate();
        this.updateMinEndTime();
        this.detectOverlappingSurvey();

        if (survey.surveyType === SurveyType.ResilientBusinessAssessment) {
            this.setLeaderTeam();
        }

        if (survey.supplementaryData?.message) {
            this.useCustomMessage = true;
        }
    }

    public async ngOnInit() {
        this.connections = await SurveyUtils.forSurveyType(this.survey.surveyType).getPotentialSurveyInvitees(this.injector, this.survey.team);
        this.showNotification = this.connections.length > 0;
    }

    public get entitiesToConfirm() {
        return [this.survey.supplementaryData, this.survey];
    }

    public get isNew() {
        return this.survey.entityAspect.entityState.isAdded();
    }

    public get isStarted() {
        return this.survey.status !== SurveyStatus.NotStarted;
    }

    public get isEnded() {
        return this.survey.status === SurveyStatus.Ended;
    }

    public get hasValidationError() {
        return this.survey.entityAspect.hasValidationErrors
            || (this.survey.supplementaryData && this.survey.supplementaryData.entityAspect.hasValidationErrors);
    }

    public setLeaderTeam() {
        this.leaderTeam$ = this.surveyService.getResilientBusinessSurveyConfigurations()
            .pipe(
                map((rbConfigs) => rbConfigs.length > 0 ? rbConfigs[0].leaderTeam : undefined),
            );
    }

    public onStartTimeChanged(startTime: Date) {
        this.survey.startTime = startTime;
        this.updateMinEndTime();
        const minEndMoment = moment(this.minEndTime);
        if (minEndMoment.isAfter(this.survey.endTime)) {
            this.survey.endTime = SurveyUtils.endOfWorkingDay(minEndMoment);
        }

        this.detectOverlappingSurvey();
    }

    public onEndTimeChanged(endTime: Date) {
        this.survey.endTime = endTime;
        this.detectOverlappingSurvey();
    }

    public detectOverlappingSurvey() {
        this.surveyService.getFirstOverlappingSurvey(this.survey)
            .subscribe((overlappingSurvey) => this.overlappingSurvey = overlappingSurvey);
    }

    @Autobind
    public save() {
        if (this.survey.status === SurveyStatus.Ended) {
            // restoring ended survey
            this.survey.status = SurveyStatus.Started;
        }

        return this.surveyService.saveEntities([this.survey, this.survey.supplementaryData])
            .pipe(
                tap(() => this.resolve(this.survey)),
                catchError((err: AdaptError) => {
                    this.setErrorMessage(err.message);
                    return throwError(() => err);
                }),
            );
    }

    private updateMinEndTime() {
        const dayAfterStart = moment(this.survey.startTime).add(1, "d").startOf("day");
        const today = moment().startOf("day");
        this.minEndTime = dayAfterStart.isAfter(today) ? dayAfterStart.toDate() : today.toDate();

        // move end time or dxDateBox will look strange with double highlight
        if (this.survey.endTime && moment(this.survey.endTime).isBefore(this.minEndTime)) {
            this.survey.endTime = this.minEndTime;
        }
    }

    @Autobind
    public previewEmail() {
        return this.surveyService.getSurveyTemplate(this.survey, this.useCustomMessage || undefined, this.connections[0].personId).pipe(
            catchError((err) => this.dialogService.showErrorDialog(
                "Unable to show email preview",
                `<p>The following error has been thrown from the server while attempting to preview email:</p>
                 <blockquote>${ErrorHandlingUtilities.getHttpResponseMessage(err)}</blockquote>`,
            )),
            switchMap((data) => this.dialogService.open(PreviewEmailDialogComponent, data)),
        );
    }
}

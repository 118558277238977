<adapt-two-column-layout [fullPage]="fullPage"
                         [whiteBackground]="whiteBackground"
                         [removeColumnPadding]="true"
                         [leftColumnWidthClasses]="leftColumnWidthClasses"
                         [rightColumnWidthClasses]="rightColumnWidthClasses">
    <div left-column
         class="d-flex h-100">
        <div class="my-auto p-4 p-sm-5 w-100">
            <ng-content></ng-content>
        </div>
    </div>
    <div right-column
         [ngStyle]="styles">
    </div>
</adapt-two-column-layout>

import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { EntityExtensions } from "../entity-extensions.decorator";
import { LocationExtensions } from "./location-extensions";
import { Organisation } from "./organisation";
import { ValueStream } from "./value-stream";

export class Location extends BaseEntity<Location> {

    public static readonly LocationFields = [
        "valueStreamId",
    ];

    public locationId!: number;
    public organisationId!: number;
    public valueStreamId?: number;

    public organisation!: Organisation;
    public valueStream?: ValueStream;

    @EntityExtensions(LocationExtensions)
    public extensions!: LocationExtensions;
}

export const LocationBreezeModel = new BreezeModelBuilder("Location", Location)
    .hasSource()
    .withIdField("locationId")
    .isOrganisationEntity()
    .build();

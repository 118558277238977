import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";

export class Diagram extends BaseEntity<Diagram> {
    public diagramId!: number;
    public organisationId!: number;
    public content!: string;
}

export const DiagramBreezeModel = new BreezeModelBuilder("Diagram", Diagram)
    .hasSource()
    .withIdField()
    .isOrganisationEntity()
    .persistChangedEntity()
    .build();

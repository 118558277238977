<table class="table table-bordered table-hover schedule-table">
    <tbody>
        <tr *ngFor="let recurrence of recurrences">
            <th scope="row">
                <i class="fal {{recurrence.completed ? 'fa-square-check' : 'fa-square'}} me-1"
                   [style.color]="recurrence.section.colour"></i>
                {{recurrence.section.code}}
            </th>
            <td>
                <ng-container *ngIf="recurrence.completed">completed</ng-container>
                <ng-container *ngIf="!recurrence.completed">to be done {{recurrence.scheduleText}}</ng-container>
                <div *ngIf="!recurrence.hasMeetings && !recurrence.completed">
                    <small>There are no scheduled meetings for your {{recurrence.section.name}} sessions.</small>
                </div>
            </td>
        </tr>
    </tbody>
</table>

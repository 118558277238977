import { Layout } from "@common/ADAPT.Common.Model/organisation/layout";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";

/** Options for column which has not been loaded yet */
export interface ILayoutBaseColumnOptions {
    enabled: boolean;
    manuallyEnabled?: boolean;
    components: ILayoutBaseComponent[];

    [option: string]: any;
}

/** Component that has not been loaded yet */
export interface ILayoutBaseComponent {
    id: string;
    size?: string;
    element?: HTMLElement;
    ref?: ILayoutUpdateListener<ILayoutBaseComponent>;
    options?: ILayoutComponentOptions;
}

/** Column that has not been loaded yet */
export interface ILayoutBaseColumn {
    size?: string;
    element?: HTMLElement;
    ref?: ILayoutUpdateListener<ILayoutBaseColumn>;
    options: ILayoutBaseColumnOptions;
}

/** Interface a column or component can implement to react to layout updates (normally sizing) */
export interface ILayoutUpdateListener<T> {
    onUpdate(data: T): void;
}

/** Options for a layout component */
export interface ILayoutComponentOptions {
    enabled?: boolean;
    userHidden?: boolean;

    [option: string]: any;
}

/** Options for a layout column */
export interface ILayoutColumnOptions {
    enabled: boolean;
    components: ILayoutComponent[];

    [option: string]: any;
}

/** A layout component */
export interface ILayoutComponent extends ILayoutBaseComponent {
    element: HTMLElement;
    ref: ILayoutUpdateListener<ILayoutComponent>;
}

/** A layout column */
export interface ILayoutColumn extends ILayoutBaseColumn {
    ref: ILayoutUpdateListener<ILayoutColumn>;
    element: HTMLElement;
    options: ILayoutColumnOptions;
}

/** Gets the component type given the layout type */
export type TComponentFromLayout<TLayout extends ILayoutBaseColumn> = TLayout["options"]["components"][number];

export interface ILayoutBase<TColumn = ILayoutBaseColumn> {
    columns: TColumn[];
    meta: {
        version: string;
    };
}

export type ILayout = ILayoutBase<ILayoutColumn>;
export type EntityWithLayout = IBreezeEntity & { layout: Layout | null, layoutId: number | null, organisationId: number };

export enum ColumnSize {
    FullWidth = "Full width (100%)",
    NarrowLeftHand = "Narrow left-hand column (25 / 75%)",
    NarrowRightHand = "Narrow right-hand column (75 / 25%)",
    Equal = "Equal columns (50 / 50%)",
}

export const ColumnSizePresets: Record<ColumnSize, string[]> = {
    [ColumnSize.FullWidth]: ["col-md-12"],
    [ColumnSize.NarrowLeftHand]: ["col-md-3", "col-md-9"],
    [ColumnSize.NarrowRightHand]: ["col-md-9", "col-md-3"],
    [ColumnSize.Equal]: ["col-md-6", "col-md-6"],
};

export enum ComponentSize {
    Full = "Full Width (100%)",
    ThreeQuarter = "Three Quarter Width (75%)",
    Half = "Half Width (50%)",
    Quarter = "Quarter Width (25%)",
}


export const ComponentSizePresets: Record<ComponentSize, string[]> = {
    // [class name to use, ...matching values]
    // will match this layout preset by either the class name, or any provided matching values
    // an empty string ("") is the default size.
    [ComponentSize.Full]: ["col-md-12", ""],
    [ComponentSize.ThreeQuarter]: ["col-md-9"],
    [ComponentSize.Half]: ["col-md-6"],
    [ComponentSize.Quarter]: ["col-md-3"],
};

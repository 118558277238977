import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptTwoColumnModule } from "../two-column/two-column.module";
import { ContentWithSplashImageComponent } from "./content-with-splash-image.component";
import { PageWithSplashImageComponent } from "./page-with-splash-image.component";

@NgModule({
    declarations: [
        ContentWithSplashImageComponent,
        PageWithSplashImageComponent,
    ],
    exports: [
        ContentWithSplashImageComponent,
        PageWithSplashImageComponent,
    ],
    imports: [
        CommonModule,
        AdaptTwoColumnModule,
    ],
})
export class ContentWithSplashImageModule { }

/* eslint-disable max-classes-per-file */
import { Logger } from "@common/lib/logger/logger";
import { ILogger } from "@common/lib/logger/logger.interface";
import { ConnectionEvent } from "./connection-state/connection-event.enum";
import { ISignalRConnectionContext } from "./connection-state/signalr-connection-context";
import { ISignalRHubImplementation } from "./signalr-hub-implementation.interface";
import { SignalRHubResubscribeError } from "./signalr-hub-resubscribe-error";
import { SignalRInvokeError } from "./signalr-invoke-error";
import { ISignalRSubscriptionHandler } from "./signalr-subscription-handler.interface";

interface IInternalSignalRSubscriptionHandler<TParams extends unknown[]> extends ISignalRSubscriptionHandler<TParams> {
    subscribeToReconnect(): void;
}

export abstract class SignalRHub implements ISignalRHubImplementation {
    protected log: ILogger;

    private initialised = false;
    private connectionContext!: ISignalRConnectionContext;

    // Since we need to wait until the signalRService is set before creating these
    // have this queue as implementations of hubs may try to create handlers in their
    // constructor
    private queuedSubscriptionHandlers: IInternalSignalRSubscriptionHandler<unknown[]>[] = [];

    public constructor(serviceName: string) {
        this.log = Logger.getLogger(serviceName);
    }

    public initialise() {
        this.initialised = true;

        this.queuedSubscriptionHandlers.forEach((h) => h.subscribeToReconnect());
        this.queuedSubscriptionHandlers = [];
    }

    public setConnectionContext(connectionContext: ISignalRConnectionContext): void {
        this.connectionContext = connectionContext;
    }

    public abstract getHandlingMethods(): { [key: string]: (...args: any[]) => void; };

    protected get connectionId() {
        return this.connectionContext?.connection?.connectionId ?? undefined;
    }

    protected createSubscriptionHandler<T extends unknown[]>(subscribeMethod: string, unsubscribeMethod: string): ISignalRSubscriptionHandler<T> {
        const handler = new this.SubscriptionHandler<T>(this, subscribeMethod, unsubscribeMethod);

        if (this.initialised) {
            handler.subscribeToReconnect();
        } else {
            this.queuedSubscriptionHandlers.push(handler);
        }

        return handler;
    }

    // eslint-disable-next-line @typescript-eslint/member-ordering
    private SubscriptionHandler = class <TParams extends unknown[]> implements IInternalSignalRSubscriptionHandler<TParams> {
        private subscriptions = new Set<string>();

        public constructor(
            private hub: SignalRHub,
            private subscribeMethod: string,
            private unsubscribeMethod: string,
        ) {
        }

        public subscribeToReconnect() {
            // Technically this is only required when transitioning between Reconnecting -> Connected
            // (as opposed to ConnectionInterrupted -> Connected) but SignalR will ignore multiple
            // subscriptions which allows us to simplify the logic greatly to just this
            this.hub.connectionContext.subscribeToConnectionEvent(ConnectionEvent.Reestablished, () => this.resubscribeAll());
        }

        public async promiseToSubscribe(...params: TParams) {
            try {
                await this.hub.connectionContext.promiseToInvokeServerHubMethod<void>(this.subscribeMethod, ...params);
                this.subscriptions.add(JSON.stringify(params));
            } catch (e) {
                if (e instanceof SignalRInvokeError) {
                    throw new SignalRHubResubscribeError(e);
                }
            }
        }

        public async promiseToUnsubscribe(...params: TParams) {
            this.subscriptions.delete(JSON.stringify(params));
            await this.hub.connectionContext.promiseToInvokeServerHubMethod<void>(this.unsubscribeMethod, ...params);
        }

        private resubscribeAll() {
            this.hub.log.info(`SignalR is now connected, resubscribing to ${this.subscribeMethod}`, this.subscriptions);
            this.subscriptions.forEach((k) => {
                const params = JSON.parse(k) as TParams;
                this.promiseToSubscribe(...params);
            });
        }
    };
}

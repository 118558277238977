import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";

export enum ActiveState {
    Active,
    Inactive,
    PendingActive,
}

export const ActiveStateLabel: Record<ActiveState, string> = {
    [ActiveState.Active]: "Active",
    [ActiveState.Inactive]: "Inactive",
    [ActiveState.PendingActive]: "Pending Active",
};

export interface IActiveEntityBase extends IBreezeEntity {
    isActive: (() => boolean);
    activeState: ActiveState;
}

export interface ITemporalEntity {
    startDate: Date;
    endDate?: Date;
}

export interface IActiveEntity extends ITemporalEntity, IActiveEntityBase {
    isActiveAt: ((date: Date) => boolean);
}

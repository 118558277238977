import { Injectable } from "@angular/core";
import { NavigationNodeBuilder } from "@common/route/navigation-node-builder";
import { AuthorisationNotificationService } from "@org-common/lib/authorisation/authorisation-notification.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { BaseOrgCommonNavigationHierarchyService } from "@org-common/lib/sidebar/base-org-common-navigation-hierarchy.service";
import { EMPTY } from "rxjs";

@Injectable()
export class AltoNavigationService extends BaseOrgCommonNavigationHierarchyService {
    public static readonly HierarchyId = "ADAPT.Alto.Navigation.Hierarchy";
    public static readonly IconClass = "fal fa-fw fa-bars";

    public id = AltoNavigationService.HierarchyId;
    public activeNodeShouldBeRebuilt$ = EMPTY;

    public constructor(
        orgService: OrganisationService,
        authNotification: AuthorisationNotificationService,
    ) {
        super(orgService, authNotification);
    }

    public async buildHierarchy() {
        return new NavigationNodeBuilder().build();
    }
}

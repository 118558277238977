import { Component, Injector, OnInit } from "@angular/core";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { SearchParamValue } from "@common/route/route.service";
import { UrlFilterService } from "@common/shell/filter/url-filter.service";
import { UserService } from "@common/user/user.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { ChangeManagerService } from "@common/ux/change-manager/change-manager.service";
import { DurationSelector } from "@common/ux/duration-selector";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { PersonService } from "@org-common/lib/person/person.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";
import { Observable, of } from "rxjs";
import { map, switchMap, tap } from "rxjs/operators";
import { KanbanService } from "../kanban.service";
import { FilterDueDate, IFilterParams } from "../kanban-filter/kanban-filter.component";
import { KanbanGroup } from "../kanban-grouping.enum";

// TODO: when doing personal work, the contents of this page will be moved to a shared component, leaving only the query
// to get items (which will be different for personal and team)

export const KanbanPageSelector = "adapt-kanban-page";
export const WorkPageTitle = "Actions";

export enum KanbanFilterParamKeys {
    DueDate = "dueDate",
    AssigneeId = "assigneeId",
    LabelIds = "labelIds",
    CreatedWithin = "createdWithin",
    UpdatedWithin = "updatedWithin",
}
@Component({
    selector: KanbanPageSelector,
    templateUrl: "./kanban-page.component.html",
})
export class KanbanPageComponent extends BaseRoutedComponent implements OnInit {
    public items$?: Observable<Item[]>;
    public team?: Team;
    public person?: Person;
    public filter?: IFilterParams;

    public boardIds: number[] = [];
    public focusItemId?: number;
    public openDialog = false;
    public showBacklog = false;
    public columnGroup?: KanbanGroup;

    private readonly BoardIdParamKey = "boardId";
    private readonly FocusItemParamKey = "item";
    private readonly OpenDialogKey = "openDialog";
    private readonly ShowBacklogKey = "showBacklog";
    private readonly ColumnGroupKey = "groupBy";

    private isRestoringPage = false;
    private filterUpdater = this.createThrottledUpdater((filter: IFilterParams) => this.filter = filter);

    public constructor(
        injector: Injector,
        private kanbanService: KanbanService,
        private userService: UserService,
        private authService: AuthorisationService,
        private labellingService: LabellingService,
        private teamsService: CommonTeamsService,
        private personService: PersonService,
        private urlFilterService: UrlFilterService,
        changeManager: ChangeManagerService,
    ) {
        super(injector);
        changeManager.unblockOnQueryParamChanges(); // not going to trigger change manager check if query param changes
        this.isRestoringPage = !!this.routeService.currentNavigationState?.restoreData;

        this.searchParameterChanged.pipe(
            switchMap(() => {
                const filterByLabels = this.getSearchParameterValue(KanbanFilterParamKeys.LabelIds);
                if (filterByLabels) {
                    return this.labellingService.getLabelsWithIds(filterByLabels.split(",").map((s) => Number(s)));
                } else {
                    return of([]);
                }
            }),
            switchMap((filterByLabels) => {
                const filterAssigneeIdValue = this.getSearchParameterValue(KanbanFilterParamKeys.AssigneeId);
                // need to get person entity if assigneeId is passed in from query param
                if (filterAssigneeIdValue) {
                    return this.personService.getPerson(Number(filterAssigneeIdValue)).pipe(
                        map((filterByAssignee) => ({ filterByAssignee, filterByLabels })),
                    );
                } else {
                    return of({ filterByAssignee: undefined, filterByLabels });
                }
            }),
            this.takeUntilDestroyed(),
        ).subscribe(({ filterByAssignee, filterByLabels }) => {
            const boardIdParamValue = this.getSearchParameterValue(this.BoardIdParamKey);
            if (boardIdParamValue) {
                const boardIds = boardIdParamValue.split(",");
                this.boardIds = boardIds.map((i) => Number(i));
            } else {
                this.boardIds = [];
            }

            const focusItemIdParamValue = this.getSearchParameterValue(this.FocusItemParamKey);
            if (focusItemIdParamValue) {
                this.focusItemId = Number(focusItemIdParamValue);
            } else {
                this.focusItemId = undefined;
            }

            const filter: IFilterParams = { dueDate: FilterDueDate.AllItems };
            const dueDateParamValue = this.getSearchParameterValue(KanbanFilterParamKeys.DueDate);
            if (dueDateParamValue) {
                filter.dueDate = Number(dueDateParamValue) as FilterDueDate;
            }

            filter.assignee = filterByAssignee;
            if (filterByLabels.length) {
                filter.labels = filterByLabels;
            }

            const createdWithinValue = this.getSearchParameterValue(KanbanFilterParamKeys.CreatedWithin);
            if (createdWithinValue) {
                filter.createdWithin = DurationSelector.DataSource.find((i) => i.slug === createdWithinValue);
            }

            const updatedWithinValue = this.getSearchParameterValue(KanbanFilterParamKeys.UpdatedWithin);
            if (updatedWithinValue) {
                filter.updatedWithin = DurationSelector.DataSource.find((i) => i.slug === updatedWithinValue);
            }

            this.filterUpdater.next(ObjectUtilities.cleanNullAndUndefinedValues(filter));
        });
    }

    public async ngOnInit() {
        this.updateData();
        if (!this.isRestoringPage) {
            const openDialogValue = this.getSearchParameterValue(this.OpenDialogKey);
            if (openDialogValue) {
                this.openDialog = JSON.parse(openDialogValue);
            }
        } else {
            await this.deleteSearchParameter(this.OpenDialogKey);
        }

        const showBacklogValue = this.getSearchParameterValue(this.ShowBacklogKey);
        if (showBacklogValue) {
            this.showBacklog = JSON.parse(showBacklogValue);
        }

        const columnGroupValue = this.getSearchParameterValue(this.ColumnGroupKey);
        if (columnGroupValue) {
            this.columnGroup = parseInt(columnGroupValue, 10);
        }

        this.navigationEnd.subscribe(() => this.updateData());
    }

    private updateData() {
        const teamId = this.getRouteParamInt("teamId");
        this.items$ = undefined;
        if (teamId) {
            this.items$ = this.kanbanService.getItemsByTeamId(teamId);
            this.teamsService.getTeamById(teamId).pipe(
                this.takeUntilDestroyed(),
            ).subscribe((team) => {
                this.team = team;
                if (!team) {
                    // invalid team
                    this.routeService.gotoHome();
                } else {
                    this.verifyHasAccessToRoute(this.authService.getHasAccess(CommonTeamsAuthService.ViewTeamKanban, team));
                }
            });
        } else {
            let personId = this.getRouteParamInt("personId");
            if (!personId) {
                personId = this.userService.getCurrentPersonId()!;
            }

            if (personId) {
                this.items$ = this.kanbanService.getItemsByPersonId(personId);
                this.personService.getPerson(personId).pipe(
                    this.takeUntilDestroyed(),
                ).subscribe(async (person) => {
                    this.person = person;
                    if (!person) {
                        // invalid person
                        await this.routeService.gotoHome();
                    }
                });
            }

            this.verifyHasAccessToRoute(this.kanbanService.getAllAccessibleBoards().pipe(
                map((boards) => boards.length > 0),
            ));
        }

        if (this.items$) {
            this.items$ = this.items$.pipe(
                // only get rid of the spinner after items have been queried
                tap(() => this.notifyActivated()),
            );
        }
    }

    public async onBoardSelectionChanged(boardIds: number[]) {
        await this.setSearchParameterValue(this.BoardIdParamKey, boardIds.join(","));
        this.boardIds = boardIds;
    }

    public async onFilterChanged(f: IFilterParams) {
        this.filter = f;

        const fil: Record<KanbanFilterParamKeys, SearchParamValue> = {
            [KanbanFilterParamKeys.DueDate]: this.filter.dueDate === FilterDueDate.AllItems ? undefined : this.filter.dueDate,
            [KanbanFilterParamKeys.AssigneeId]: this.filter.assignee?.personId,
            [KanbanFilterParamKeys.CreatedWithin]: this.filter.createdWithin?.slug,
            [KanbanFilterParamKeys.UpdatedWithin]: this.filter.updatedWithin?.slug,
            [KanbanFilterParamKeys.LabelIds]: this.filter.labels?.length ? this.filter.labels.map((l) => l.labelId).join(",") : undefined,
        };
        await this.urlFilterService.setFilter(fil);
    }

    public async onSelectedItemChanged(item?: Item) {
        if (!item) {
            // no selection -> clear query param
            await this.setSearchParameterValue(this.FocusItemParamKey, undefined);
            this.focusItemId = undefined;
        } else {
            await this.setSearchParameterValue(this.FocusItemParamKey, item.itemId);
            this.focusItemId = item.itemId;
        }
    }

    public async itemDialogClosed() {
        await this.deleteSearchParameter(this.OpenDialogKey);
    }

    public async itemDialogOpened(item: Item) {
        if (item.itemId === this.focusItemId) {
            await this.setSearchParameterValue(this.OpenDialogKey, true);
        }
    }

    public async onShowBacklogChanged(shown: boolean) {
        this.showBacklog = shown;
        if (shown) {
            await this.setSearchParameterValue(this.ShowBacklogKey, true);
        } else {
            await this.deleteSearchParameter(this.ShowBacklogKey);
        }
    }

    public async onColumnGroupChanged(columnGroup?: KanbanGroup) {
        this.columnGroup = columnGroup;
        if (columnGroup !== undefined) {
            await this.setSearchParameterValue(this.ColumnGroupKey, columnGroup);
        } else {
            await this.deleteSearchParameter(this.ColumnGroupKey);
        }
    }
}

import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Zone, ZoneMetadata } from "../methodology/zone";
import { KeyFunction } from "./key-function";
import { Team } from "./team";

export class TeamLocation extends BaseEntity<TeamLocation> {
    public teamLocationId!: number;
    public teamId!: number;
    public keyFunctionId?: number;
    public ordinal!: number;
    public zone!: Zone | null;

    public keyFunction?: KeyFunction;
    public team!: Team;

    public static getAsZoneLocation(location?: Partial<TeamLocation>) {
        if (location && location.zone !== null && location.zone !== undefined) {
            return location.zone;
        }
        return undefined;
    }

    public static getAsKeyFunctionLocation(location?: Partial<TeamLocation>) {
        if (location && location.keyFunction !== null && location.keyFunction !== undefined) {
            return location.keyFunction;
        }
        return undefined;
    }

    public static compare(a: TeamLocation, b: TeamLocation) {
        if (a.zone && b.zone) {
            return ZoneMetadata.Ordinal[a.zone] - ZoneMetadata.Ordinal[a.zone];
        } else if (a.zone && !b.zone) {
            return -1;
        } else if (!a.zone && b.zone) {
            return 1;
        } else if (a.keyFunction && b.keyFunction) {
            return a.keyFunction.ordinal - b.keyFunction.ordinal;
        } else {
            return 0;
        }
    }

    public static group(location?: Partial<TeamLocation>) {
        if (TeamLocation.getAsZoneLocation(location)) return "Zones";
        if (TeamLocation.getAsKeyFunctionLocation(location)) return "Key Functions";
        return undefined;
    }
}

export const TeamLocationBreezeModel = new BreezeModelBuilder("TeamLocation", TeamLocation)
    .hasSource()
    .withSortField("ordinal")
    .isOrganisationEntity()
    .alwaysFetchingNestedNavigationProperty("team.teamLeaderPerson")
    .build();

<div class="chain-update"
     [ngClass]="{'chain-update-has-comment': chainUpdate.hasComment}">
    <adapt-person-image class="chain-update-person"
                        [ngClass]="{'with-comment': chainUpdate.hasComment, 'without-comment': !chainUpdate.hasComment}"
                        [person]="chainUpdate.person"></adapt-person-image>
    <div class="chain-update-container">
        <div class="chain-update-meta">
            <adapt-link-person [person]="chainUpdate.person"></adapt-link-person>
            <adapt-entity-timestamp class="chain-update-date-time"
                                    [timestamp]="chainUpdate.dateTime"></adapt-entity-timestamp>
            <span class="chain-update-editing-buttons"
                  *ngIf="chainUpdate.canUpdate">
                <button adaptButtonIcon="edit"
                        class="btn btn-link"
                        (click)="itemEdited.emit(chainUpdate)"></button>
                <button adaptButtonIcon="delete"
                        class="btn btn-link"
                        (click)="itemDeleted.emit(chainUpdate)"></button>
            </span>
        </div>
        <div class="chain-update-text">
            <i *ngIf="chainUpdate.iconClass"
               aria-hidden="true"
               class="chain-update-icon"
               [ngClass]="chainUpdate.iconClass"></i>
            <span>{{chainUpdate.text}}</span>
        </div>
    </div>
</div>

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Survey } from "@common/ADAPT.Common.Model/organisation/survey";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ReplaySubject } from "rxjs";

@Component({
    selector: "adapt-survey-selection-box",
    templateUrl: "./survey-selection-box.component.html",
    styleUrls: ["./survey-selection-box.component.scss"],
})
export class SurveySelectionBoxComponent extends BaseComponent {
    public surveys$ = new ReplaySubject<Survey[]>(1);
    @Input() public set surveys(values: Survey[]) {
        this.surveys$.next(values);
    }
    @Input() public survey!: Survey;
    @Output() public surveyChange = new EventEmitter<Survey>();

    public constructor() {
        super();
    }
}

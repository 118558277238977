<div *ngIf="survey"
     class="w-100 hidden-panel-container">
    <div class="d-flex">
        <div class="me-1 mt-1 align-self-baseline survey-title"
             [class.upcoming-survey-text]="notStarted || forceLightBackground"
             [class.ongoing-survey-text]="!(notStarted || forceLightBackground)"
             [class.h3]="!isAlto"
             [class.h5]="isAlto">
            <i class="far fa-square-poll-vertical me-1"></i>{{survey.name}}
        </div>
        <span class="badge align-self-baseline ms-2"
              [ngClass]="badgeClass">{{statusText}}</span>
        <div *ngIf="canEdit"
             class="hidden-panel align-self-baseline ms-2">
            <ng-container *ngIf="notStarted; else ongoingActions">
                <button adaptButtonIcon="edit"
                        class="bg-transparent override-button-color p-0"
                        adaptTooltip="Edit survey details"
                        (click)="editSurvey()"
                        data-test="edit-survey-button"></button>
            </ng-container>
            <ng-template #ongoingActions>
                <button adaptButtonIcon="edit"
                        class="bg-transparent p-0 ongoing-survey-text"
                        adaptTooltip="Edit survey details"
                        (click)="editSurvey()"
                        data-test="edit-active-survey-button"></button>
                <button adaptButtonIcon="browse"
                        *ngIf="participationRate < 100"
                        class="bg-transparent p-0 ms-2 ongoing-survey-text"
                        adaptTooltip="Show outstanding responses"
                        (click)="viewOutstandingResponses()"></button>
            </ng-template>
            <button adaptButtonIcon="delete"
                    class="bg-transparent p-0 ms-2"
                    [ngClass]="{'override-button-color': notStarted, 'ongoing-survey-text': !notStarted}"
                    adaptTooltip="Delete survey"
                    (click)="promptToDeleteSurvey()"
                    data-test="delete-survey-button"></button>
        </div>
    </div>
    <div *ngIf="notStarted; else showClosingDate"
         class="small">
        <span [adaptTooltip]="'Starting ' + (survey.startTime | adaptDateTime)">{{survey.startTime | adaptDate}}</span> &mdash;
        <span [adaptTooltip]="'Closing ' + (survey.endTime | adaptDateTime)">{{survey.endTime | adaptDate}}</span>
    </div>
    <ng-template #showClosingDate>
        <div class="d-flex flex-column">
            <div class="small me-3">Closing: {{survey.endTime | adaptDateTime}}</div>
            <adapt-progress-bar class="mt-2"
                                *ngIf="!hideParticipation"
                                [leftAligned]="true"
                                [percentageProgress]="participationRate"
                                [colour]="getStatusColorFromPercentage(participationRate)"
                                [backgroundColour]="getStatusBackgroundFromPercentage(participationRate)"
                                [overrideText]="participationText"></adapt-progress-bar>
        </div>
    </ng-template>
</div>

import { Component, Input } from "@angular/core";
import { OutstandingSurveyResponse } from "@common/ADAPT.Common.Model/organisation/outstanding-survey-response";
import { Survey, SurveyType, SurveyTypeLabel } from "@common/ADAPT.Common.Model/organisation/survey";
import { SurveyResult } from "@common/ADAPT.Common.Model/organisation/survey-result";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { SurveyService } from "@org-common/lib/survey/survey.service";
import { EMPTY, forkJoin, ReplaySubject } from "rxjs";
import { switchMap } from "rxjs/operators";

@Component({
    selector: "adapt-display-impact-surveys",
    templateUrl: "./display-impact-surveys.component.html",
})
export class DisplayImpactSurveysComponent extends BaseComponent {
    @Input() public surveyType!: SurveyType;
    @Input() public team?: Team;
    @Input() public hasChanges = false;

    @Input() public set currentFeatureStatus(currentStatus: boolean) {
        this.currentFeatureStatus$.next(currentStatus);
    }

    public openSurveys: Survey[] = [];
    public surveyTypeLabel = "";

    private affectedSurveyResults: SurveyResult[] = [];
    private affectedOutstandingResponses: OutstandingSurveyResponse[] = [];
    private currentFeatureStatus$ = new ReplaySubject<boolean>(1);
    private lastSavedStatus?: boolean;
    private currentStatus?: boolean;

    public constructor(
        surveyService: SurveyService,
    ) {
        super();

        this.currentFeatureStatus$.pipe(
            switchMap((status) => {
                if (this.lastSavedStatus === undefined) {
                    this.lastSavedStatus = status;
                }

                this.currentStatus = status;
                return surveyService.rejectChanges([...this.openSurveys, ...this.affectedSurveyResults, ...this.affectedOutstandingResponses]).pipe(
                    switchMap(() => {
                        if (!this.currentStatus && this.lastSavedStatus) {
                            // only do this if last saved status is enabled to avoid permission denied from server
                            // as we cannot use the auth check here due to feature status already changed in feature-configuration component
                            // (race condition as that's a promise to set feature status regardless or when output emit is called)
                            return forkJoin(
                                surveyService.getOngoingSurveysWithoutAuthCheck(this.surveyType, this.team),
                                surveyService.getUpcomingSurveysWithoutAuthCheck(this.surveyType, this.team),
                            );
                        } else {
                            this.openSurveys = [];
                            this.affectedSurveyResults = [];
                            this.affectedOutstandingResponses = [];
                            return EMPTY;
                        }
                    }),
                    // this part onwards is only reached from the forJoin branch above
                    switchMap((results) => {
                        this.openSurveys = results[0].concat(results[1]);
                        if (this.openSurveys.length > 0) {
                            this.surveyTypeLabel = SurveyTypeLabel[this.openSurveys[0].surveyType].toLowerCase();
                        } else {
                            this.surveyTypeLabel = "";
                        }

                        // after removal of survey, results will be modified but can no longer be accessible through survey entity
                        this.affectedSurveyResults = this.openSurveys.map((s) => s.surveyResults).flat();
                        this.affectedOutstandingResponses = this.openSurveys.map((s) => s.outstandingSurveyResponses).flat();
                        return forkJoin(...this.openSurveys.map((s) => surveyService.remove(s)));
                    }),
                );
            }),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public onSave() {
        this.lastSavedStatus = this.currentStatus;
    }
}

<div class="card p-0"
     [ngClass]="{highlight: highlight, editable: editable, 'deny-drag': denyDrag}">
    <ng-container *ngIf="!isInitialised; else displayCardDetails">
        <!-- swap this in with min height to not render content when not in view to resolve performance issue -->
        <div class="card-body p-0">
            <div class="card-placeholder"></div>
        </div>
    </ng-container>
    <ng-template #displayCardDetails>
        <div class="card-body d-flex flex-column gap-1">
            <div class="kanban-header"
                 data-test="kanban-header">
                <adapt-link-person *ngIf="showAssignee"
                                   class="me-2"
                                   [person]="item.assignee"
                                   [imageOnly]="true"></adapt-link-person>
                <adapt-item-link [item]="item"
                                 [showSummary]="true"
                                 [ngClass]="{'flex-grow-1 blurred-summary': blurSensitiveContent && !item.board?.isPublicReadAccess}"
                                 (itemDialogOpened)="dialogOpened.emit($event)"
                                 (itemDialogClosed)="dialogClosed.emit($event)"></adapt-item-link>
                <adapt-item-action-menu *ngIf="!inMultipleSelectionMode"
                                        class="ms-auto me-n1 action-menu"
                                        [item]="item"
                                        [showOpenOption]="true"
                                        [showColumnMoveOptions]="!!columnItems"
                                        [columnItems]="columnItems"
                                        (itemClosed)="itemSelected.emit(undefined)"
                                        (itemDeleted)="itemSelected.emit(undefined)"
                                        (dialogOpened)="dialogOpened.emit($event)"
                                        (dialogClosed)="dialogClosed.emit($event)"></adapt-item-action-menu>
                <dx-check-box *ngIf="inMultipleSelectionMode"
                              class="selection-checkbox"
                              [value]="inMultipleSelection"
                              (valueChange)="onSelectionChange($any($event))"></dx-check-box>
            </div>
            <div *ngIf="item.dueDate || (item.labelLocations && item.labelLocations.length > 0) || (item.comments && item.comments.length > 0)"
                 class="d-flex flex-row flex-wrap align-items-center row-gap-1 column-gap-2">
                <span *ngIf="item.dueDate"
                      class="small"><b>Due</b> {{item.dueDate | adaptDate}}</span>
                <adapt-display-labels [labelLocations]="item.labelLocations"
                                      [class.blurred-labels]="blurSensitiveContent"></adapt-display-labels>
                <div *ngIf="item.comments && item.comments.length > 0"
                     adaptTooltip="{{item.comments.length}} comments"
                     class="ms-auto flex-shrink-0">
                    <span class="fal fa-fw fa-comment-dots"></span>
                    <span>{{item.comments.length}}</span>
                </div>
            </div>
        </div>
        <div *ngIf="sortedLinks?.length || item.objectiveItemLinks?.length"
             class="card-footer p-0">
            <div class="d-flex flex-column item-links-section gap-1">
                <ng-container *ngIf="item.objectiveItemLinks?.length">
                    <small>Linked objectives</small>
                    <adapt-display-linked-objectives [objectives]="linkedObjectives"
                                                     [blurTitle]="blurSensitiveContent"
                                                     [showGroupObjectiveTextSuffix]="false"></adapt-display-linked-objectives>
                </ng-container>
                <small *ngIf="sortedLinks?.length">Linked actions</small>
                <adapt-item-link *ngFor="let link of sortedLinks"
                                 [item]="link.secondaryItem"
                                 [showDialogLink]="true"
                                 [showStatus]="true"
                                 [showSummary]="true"
                                 [showPersonImageLink]="true"
                                 [ngClass]="{'flex-grow-1 blurred-summary': blurSensitiveContent && !link.secondaryItem.board?.isPublicReadAccess}"></adapt-item-link>
            </div>
        </div>
    </ng-template>
</div>

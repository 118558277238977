import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { InputType } from "./input";
import { InputGroupType } from "./input-group";

export class InputTypeMetadataClass {
    public constructor(
        public articleId: ImplementationKitArticle,
        public singularLabel: string,
        public pluralLabel: string,
        public iconClass: string,
        public tooltip?: string,
        public dialogLabel?: string,
    ) { }
}

export const InputTypeMetadata: { [inputType in InputType]: InputTypeMetadataClass } = {
    [InputType.Strength]: new InputTypeMetadataClass(ImplementationKitArticle.StrategicInputStrength, "Strength", "Strengths", "fal fa-fw fa-bolt", "Where we are strong"),
    [InputType.Weakness]: new InputTypeMetadataClass(ImplementationKitArticle.StrategicInputWeakness, "Weakness", "Weaknesses", "fal fa-fw fa-circle-exclamation", "Where we are weak"),
    [InputType.Trend]: new InputTypeMetadataClass(ImplementationKitArticle.StrategicInputTrend, "Trend", "Trends", "fal fa-fw fa-chart-line", "An industry trend"),

    [InputType.Stop]: new InputTypeMetadataClass(ImplementationKitArticle.StrategicInputSSKDTodo, "Stop", "Stops", "fal fa-fw fa-question", InputType.Stop),
    [InputType.Start]: new InputTypeMetadataClass(ImplementationKitArticle.StrategicInputSSKDTodo, "Start", "Starts", "fal fa-fw fa-question", InputType.Start),
    [InputType.KeepDoing]: new InputTypeMetadataClass(ImplementationKitArticle.StrategicInputSSKDTodo, "Keep Doing", "Keep Doings", "fal fa-fw fa-question", InputType.KeepDoing),

    [InputType.Win]: new InputTypeMetadataClass(ImplementationKitArticle.CompetitorAnalysisWin, "Win", "Wins", "fal fa-fw fa-crown", "Why do they win", "How they win against us"),
    [InputType.Lose]: new InputTypeMetadataClass(ImplementationKitArticle.CompetitorAnalysisLose, "Lose", "Loses", "fal fa-fw fa-thumbs-down", "Why do they lose", "How they lose against us"),
    [InputType.Compete]: new InputTypeMetadataClass(ImplementationKitArticle.CompetitorAnalysisCompete, "Compete", "Competes", "fal fa-fw fa-hand-fist", "Where do they compete", "Where they compete with us"),
};

export const InputGroupTypeMetadata: { [inputGroupType in InputGroupType]: InputTypeMetadataClass } = {
    [InputGroupType.Competitor]: new InputTypeMetadataClass(ImplementationKitArticle.CompetitorAddIntroduction, "Competitor", "Competitors", "fal fa-fw fa-user-check"),
};

import { Component, Input, OnChanges } from "@angular/core";

@Component({
    selector: "adapt-highlighted-text",
    templateUrl: "./highlighted-text.component.html",
    styleUrls: ["./highlighted-text.component.scss"],
})
export class HighlightedTextComponent implements OnChanges {
    @Input() public needle?: string;
    @Input() public haystack!: string;
    public segments: string[] = [];

    public ngOnChanges() {
        if (this.needle) {
            // use regex to case-insensitive split haystack string based on needle string
            const regEx = new RegExp(`(${this.escapeRegExp(this.needle)})`, "i");
            this.segments = this.haystack.split(regEx);
        } else {
            this.segments = [this.haystack];
        }
    }

    // From https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
    private escapeRegExp(str: string) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        // $& means the whole matched string
    }
}

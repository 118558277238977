<ng-container *ngIf="(childTeams$ | async) as childTeams">
    <div *ngIf="(team$ | async) as team"
         [ngClass]="{'standard-font-weight': !showIcon, 'has-children': showChildren && childTeams.length > 0}">
        <div class="align-items-baseline d-flex flex-grow-1">
            <div class="d-flex flex-wrap align-items-center gap-2">
                <div class="align-items-baseline d-flex">
                    <span *ngIf="showIcon"
                          [ngClass]="{'icon-border': showIconBorder}"
                          class="team-icon fa-layers">
                        <i class="fas fa-fw fa-inverse fa-users"
                           aria-hidden="true"></i>
                        <span *ngIf="childTeams.length > 0 && shouldShowChildCount"
                              class="fa-layers-counter">{{childTeams.length}}</span>
                    </span>

                    <a [routerLink]="dashboardUrl$ | async"
                       [ngClass]="{'bold-font-weight': showIcon}">{{team.name}}</a>

                    <adapt-team-private-icon [team]="team"></adapt-team-private-icon>
                </div>

                <adapt-display-labels *ngIf="showLabels"
                                      [linkDisabled]="labelLinkDisabled"
                                      [labelLocations]="team.labelLocations"></adapt-display-labels>
            </div>

            <span *ngIf="showChildren && childTeams.length > 0"
                  (click)="childrenExpanded = !childrenExpanded"
                  [adaptTooltip]="'Click to ' + (childrenExpanded ? 'hide' : 'show') + ' child teams'"
                  class="flex-grow-1 text-end me-2 expand-children"
                  data-test="expand-child-teams">
                <i class="fa"
                   [ngClass]="childrenExpanded ? 'fa-chevron-up': 'fa-chevron-down'"></i>
            </span>
        </div>

        <div *ngIf="childrenExpanded && showChildren && childTeams.length > 0"
             class="d-flex flex-column flex-grow-1 ms-2 me-0 expanded-children">
            <adapt-link-team *ngFor="let team of childTeams"
                             [team]="team"
                             [showIcon]="true"
                             [showLabels]="showLabels"
                             [showChildren]="showAllChildren"
                             [showAllChildren]="showAllChildren"
                             [filterTeams]="filterTeams"
                             class="mt-1"></adapt-link-team>
        </div>
    </div>
    <span *ngIf="showTeamLeaderIcon$ | async"
          class="fa-stack team-leader-icon"
          [adaptTooltip]="teamLeaderLabel$ | async">
        <i class="fas fa-circle fa-stack-2x"></i>
        <i class="fal fa-star fa-stack-1x fa-inverse"></i>
    </span>
</ng-container>

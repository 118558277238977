import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { buttonPreset, IButtonType } from "@common/ux/button/button-preset";
import { ObservableInput, of } from "rxjs";
import { BaseDialogWithDiscardConfirmationComponent } from "../base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { FocusableElement, IFocusable } from "../focusable";

type ButtonPreset = keyof typeof buttonPreset | IButtonType;

@Component({
    selector: "adapt-standard-dialog-buttons",
    templateUrl: "./standard-dialog-buttons.component.html",
    styleUrls: ["./standard-dialog-buttons.component.scss"],
})
export class StandardDialogButtonsComponent implements OnChanges, IFocusable {
    @Input() public saveButtonPreset: ButtonPreset = "saveAndClose";
    @Input() public saveText?: string;
    @Input() public cancelButtonPreset: ButtonPreset = "iconlessCancel";
    @Input() public cancelText?: string;
    @Input() public hideCancelButton: boolean = false;
    @Input("saveIsDisabled") public saveIsDisabledInput = false;
    @Input() public saveBlockingClick?: () => ObservableInput<unknown>;
    @Input() public discardConfirmationInstance?: BaseDialogWithDiscardConfirmationComponent<unknown>;
    @Output() public saveClick = new EventEmitter<void>();
    @Output() public cancelClick = new EventEmitter<void>();

    @ViewChild("cancelButton") public cancelButton: FocusableElement;

    public saveButtonType?: IButtonType;
    public cancelButtonType?: IButtonType;

    public ngOnChanges() {
        this.saveButtonType = this.getButtonProperties(this.saveButtonPreset, buttonPreset.saveAndClose, this.saveText);
        this.cancelButtonType = this.getButtonProperties(this.cancelButtonPreset, buttonPreset.iconlessCancel, this.cancelText);

        if (typeof this.saveButtonPreset !== "object") {
            if (this.saveButtonType.buttonClass.indexOf("btn-default") >= 0) {
                this.saveButtonType.buttonClass = "btn btn-primary";
            }
        }
    }

    public getElementToFocus() {
        return this.cancelButton;
    }

    public get saveIsDisabled() {
        if (this.discardConfirmationInstance) {
            // even if we set dialog instance, we can still disable save button using saveIsDisabledInput
            return this.discardConfirmationInstance.entitiesAreUnmodifiedOrInvalid || this.saveIsDisabledInput;
        } else {
            return this.saveIsDisabledInput;
        }
    }

    public get useBlockingClick() {
        return !!this.saveBlockingClick || !!this.discardConfirmationInstance;
    }

    @Autobind
    public onBlockingSaveClick() {
        this.saveClick.emit();

        if (this.saveBlockingClick) {
            return this.saveBlockingClick();
        } else if (this.discardConfirmationInstance) {
            return this.discardConfirmationInstance.saveAndClose();
        } else {
            return of(undefined);
        }
    }

    public onCancelClick() {
        if (this.cancelClick.observed) {
            this.cancelClick.emit();
        } else if (this.discardConfirmationInstance) {
            this.discardConfirmationInstance.cancel();
        }
    }

    private getButtonProperties(preset: ButtonPreset, defaultPreset?: IButtonType, customText?: string) {
        const type = typeof preset === "object"
            ? preset
            : buttonPreset[preset] ?? defaultPreset;

        const buttonType = { ...type };

        if (customText) {
            buttonType.buttonText = customText;
        }

        return buttonType;
    }
}

import { Component, Inject, Injector } from "@angular/core";
import { InputGroup } from "@common/ADAPT.Common.Model/organisation/input-group";
import { InputGroupTypeMetadata } from "@common/ADAPT.Common.Model/organisation/input-type-metadata";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { switchMap, tap } from "rxjs";
import { StrategicInputsService } from "../strategic-inputs.service";

@Component({
    selector: "adapt-edit-input-group-dialog",
    templateUrl: "./edit-input-group-dialog.component.html",
    styleUrls: ["../edit-input-dialog/edit-input-dialog.component.scss"],
})
export class EditInputGroupDialogComponent extends BaseDialogWithDiscardConfirmationComponent<InputGroup> {
    public readonly dialogName = "EditInputGroup";
    public articleId: ImplementationKitArticle;
    public inputGroupTypeLabel: string;
    public isEditorValid = true;

    protected entitiesToConfirm: InputGroup[];

    public constructor(
        injector: Injector,
        @Inject(ADAPT_DIALOG_DATA) public inputGroup: InputGroup,
        private inputsService: StrategicInputsService,
        private dialogService: AdaptCommonDialogService,
    ) {
        super(injector);
        this.entitiesToConfirm = [inputGroup];
        this.autoResolveData = inputGroup;
        this.articleId = InputGroupTypeMetadata[inputGroup.type].articleId;
        this.inputGroupTypeLabel = InputGroupTypeMetadata[inputGroup.type].singularLabel;
    }

    public get isNew() {
        return this.inputGroup.entityAspect.entityState.isAdded();
    }

    @Autobind
    public deleteInputGroup() {
        const dialogData: IConfirmationDialogData = {
            title: `Deleting ${this.inputGroupTypeLabel}...`,
            message: `<p>You are about to delete ${this.inputGroupTypeLabel} with name:</p>
                <blockquote><b>${this.inputGroup.name}</b></blockquote>
                <p>All win, lose and compete attributes will be deleted together with their association to the strategic working board.
                If you choose to delete, they can no longer be recovered.</p>
                <p>Are you sure you want to continue?</p>`,
            confirmButtonText: "Confirm & Delete",
            cancelButtonText: "Cancel",
            checkboxMessage: `Check here to confirm that you want to delete the ${this.inputGroupTypeLabel} permanently`,
        };
        return this.dialogService.openConfirmationDialog(dialogData).pipe(
            // InputGroupRepositoryEntity will handle that and return deleted associated entities
            // in saveMap to clean up our breeze manager cache
            switchMap(() => this.inputsService.remove(this.inputGroup)),
            switchMap(() => this.inputsService.saveEntities(this.inputGroup)),
            tap(() => this.resolve(this.inputGroup)),
        );
    }
}

<div class="key-result-list-item"
     [ngClass]="layoutClass"
     [class.editing]="isEditing">
    <div class="key-result-heading"
         [ngStyle]="headingStyles">
        <i class="key-result-icon"
           [ngClass]="keyResult.iconClass"></i>
        <div class="key-result-title"
             [ngStyle]="titleStyles">
            {{keyResult.title}}
        </div>
        <div class="edit-button-group d-flex ms-auto"
             *ngIf="isEditing && !isClosed">
            <button class="btn btn-link"
                    adaptButtonIcon="edit"
                    adaptTooltip="Edit key result"
                    [adaptBlockingClick]="editKeyResult"
                    data-test="edit-key-result-button"></button>
            <button class="btn btn-link"
                    adaptButtonIcon="delete"
                    adaptTooltip="Delete key result"
                    [adaptBlockingClick]="promptToDeleteKeyResult"
                    [unsubscribeOnDestroy]="false"
                    data-test="delete-key-result-button"></button>
        </div>
    </div>
    <div class="key-result-progress">
        <div class="progress-container"
             [ngClass]="{ 'editable-value': isEditable }">
            <adapt-display-key-result-progress [keyResult]="keyResult"
                                               (click)="isEditable && updateKeyResultValue()"></adapt-display-key-result-progress>
        </div>
        <button *ngIf="isEditable"
                adaptButton="primary"
                adaptTooltip="Update key result value"
                (click)="updateKeyResultValue()"
                data-test="update-key-result-button">Update</button>
    </div>
</div>

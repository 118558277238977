<div *ngIf="data?.value as value">
    <h3>Name of the value</h3>
    <dx-text-box #focus
                 [(value)]="value.name"
                 (valueChange)="validateEntities()"
                 placeholder="Enter value name"
                 data-test="value-name-input">
        <dx-validator>
            <dxi-validation-rule [adaptEntity]="value"
                                 adaptEntityProperty="name"></dxi-validation-rule>
            <dxi-validation-rule [adaptEntity]="value"
                                 [adaptBreezeValidator]="uniqueValueNameValidator"></dxi-validation-rule>
        </dx-validator>
    </dx-text-box>
</div>

import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { Label } from "@common/ADAPT.Common.Model/organisation/label";
import { LabelLocation } from "@common/ADAPT.Common.Model/organisation/label-location";
import { LabellingService } from "../labelling.service";

@Component({
    selector: "adapt-display-labels",
    templateUrl: "./display-labels.component.html",
})
export class DisplayLabelLocationsComponent implements OnChanges {
    @Input() public labelLocations?: LabelLocation[];
    @Input() public labels?: Label[]; // alternative to display label besides the above
    @Input() public linkDisabled = false;

    public constructor(private labellingService: LabellingService) { }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.labelLocations && this.labelLocations?.find((l) => !l.label)) {
            // need to prime labels
            this.labellingService.getAllLabels().subscribe();
        }
    }
}

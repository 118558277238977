import { NgModule } from "@angular/core";
import { AdaptIfFeatureActiveDirective } from "./if-feature-active.directive";
import { AdaptIfFeatureFlagActiveDirective } from "./if-feature-flag-active.directive";

@NgModule({
    exports: [
        AdaptIfFeatureActiveDirective,
        AdaptIfFeatureFlagActiveDirective,
    ],
    declarations: [
        AdaptIfFeatureActiveDirective,
        AdaptIfFeatureFlagActiveDirective,
    ],
})
export class AdaptFeaturesModule { }

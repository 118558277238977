import { Component, Injector, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Account } from "@common/ADAPT.Common.Model/account/account";
import { CoachSessionBreezeModel } from "@common/ADAPT.Common.Model/organisation/coach-session";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { ICoachRequest } from "@common/payment-processing/payment-processing.service";
import { PreventNavigationGuard } from "@common/route/prevent-navigation.guard";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { AccountService } from "@org-common/lib/organisation/account/account.service";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { catchError, defer, EMPTY, finalize, of, switchMap } from "rxjs";
import { map, tap } from "rxjs/operators";
import { CoachAccessFeedbackDialogComponent } from "../coach-access-feedback-dialog/coach-access-feedback-dialog.component";
import { CoachAccessPaymentDialogComponent } from "../coach-access-payment-dialog/coach-access-payment-dialog.component";
import { CoachAccessScheduleDialogComponent, ICoachAccessScheduleDialogData } from "../coach-access-schedule-dialog/coach-access-schedule-dialog.component";
import { CoachAccessSessionSelectComponent } from "../coach-access-session-select/coach-access-session-select.component";
import { CoachAccessSessionsDialogComponent } from "../coach-access-sessions-dialog/coach-access-sessions-dialog.component";
import { ICoachOption } from "../coach-option.interface";

const FeedbackSearchParam = "feedback";
const CoachSessionIdSearchParam = "coachSessionId";

@Component({
    selector: "adapt-coach-access-page",
    templateUrl: "./coach-access-page.component.html",
    styleUrl: "./coach-access-page.component.scss",
})
export class CoachAccessPageComponent extends BaseRoutedComponent implements OnInit, OnDestroy {
    public readonly ImplementationKitArticle = ImplementationKitArticle;

    @ViewChild(CoachAccessSessionSelectComponent) public readonly coachAccessPaymentComponent!: CoachAccessSessionSelectComponent;

    public canRequestCoach = false;
    public coachRequest?: ICoachRequest;
    public coachOption?: ICoachOption;
    public coachOptionUpdater = this.createThrottledUpdater<ICoachOption>((coachOption) => this.coachOption = coachOption);

    public account?: Account;
    public submitting = false;

    private providingFeedback = false;

    private removePreventNavigationCallback?: () => void;

    public constructor(
        injector: Injector,
        private accountService: AccountService,
        private dialogService: AdaptCommonDialogService,
        private commonDataService: CommonDataService,
        private preventNavigationGuard: PreventNavigationGuard,
    ) {
        super(injector);

        if (!AdaptClientConfiguration.CalendlyBaseUri) {
            throw new Error("Calendly URI is not set. Scheduling the session will not work!");
        }

        this.removePreventNavigationCallback = this.preventNavigationGuard.registerCanNavigateCallback(() => defer(() => {
            // don't allow any navigation while submitting
            if (this.submitting) {
                return of(false);
            }

            // prompt for confirming discard if they've entered a description
            if (this.coachRequest?.Description || this.providingFeedback) {
                return this.dialogService.openConfirmDiscardDialog().pipe(
                    map((result) => !!result),
                );
            }

            // otherwise allow navigation
            return of(true);
        }));
    }

    public ngOnInit() {
        this.accountService.getAccount().pipe(
            this.takeUntilDestroyed(),
        ).subscribe((account) => this.account = account);

        this.notifyActivated();

        const doFeedback = this.getSearchParameterValue(FeedbackSearchParam);
        const coachSessionId = this.getSearchParameterIntValue(CoachSessionIdSearchParam);
        if (doFeedback && coachSessionId) {
            this.providingFeedback = true;
            this.provideFeedback(coachSessionId).pipe(
                this.takeUntilDestroyed(),
            ).subscribe();
        } else {
            this.routeService.deleteSearchParameters([FeedbackSearchParam, CoachSessionIdSearchParam]);
        }

    }

    private provideFeedback(coachSessionId: number) {
        return this.commonDataService.getById(CoachSessionBreezeModel, coachSessionId).pipe(
            switchMap((coachSession) => {
                if (!coachSession) {
                    throw new Error("Failed to get your coach session details.");
                }

                return this.dialogService.open(CoachAccessFeedbackDialogComponent, coachSession);
            }),
            catchError((e) => this.dialogService.showErrorDialog("Failed to provide feedback", ErrorHandlingUtilities.getHttpResponseMessage(e))),
            finalize(() => {
                this.providingFeedback = false;
                this.routeService.deleteSearchParameters([FeedbackSearchParam, CoachSessionIdSearchParam]);
            }),
            this.takeUntilDestroyed(),
        );
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        this.removePreventNavigationCallback?.();
    }

    @Autobind
    public requestCoach() {
        if (this.account && this.coachRequest && this.coachOption) {
            return this.dialogService.open(CoachAccessPaymentDialogComponent, {
                account: this.account,
                coachOption: this.coachOption,
                coachRequest: this.coachRequest,
            }).pipe(
                tap(() => this.submitting = true),
                switchMap((coachSessionId) => this.dialogService.open(CoachAccessScheduleDialogComponent, {
                    coachOption: this.coachOption,
                    coachRequest: this.coachRequest,
                    coachSessionId,
                } as ICoachAccessScheduleDialogData)),
                catchError((e) => this.dialogService.showErrorDialog("Failed to request coach", ErrorHandlingUtilities.getHttpResponseMessage(e))),
                // only reset in tap, so won't reset form if user cancels dialog
                tap(() => this.reset()),
                finalize(() => this.submitting = false),
                this.takeUntilDestroyed(),
            );
        }

        return EMPTY;
    }

    public viewCoachingSessionHistory() {
        this.dialogService.open(CoachAccessSessionsDialogComponent).subscribe();
    }

    private reset() {
        this.submitting = false;
        this.coachAccessPaymentComponent.reset();
    }
}

export const coachAccessPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-coach-access-page", CoachAccessPageComponent)
    .atOrganisationUrl("/coach-access")
    .withTitle("Talk to a coach")
    .build();

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptIconModule } from "../icon/icon.module";
import { BlockingButtonGroupDirective } from "./blocking-button-group.directive";
import { BlockingClickDirective } from "./blocking-click.directive";
import { ButtonComponent } from "./button.component";
import { ButtonIconComponent } from "./button-icon.component";
import { ButtonToggleDirective } from "./button-toggle.directive";
import { ButtonToggleGroupComponent } from "./button-toggle-group/button-toggle-group.component";
import { ToggleGroupButtonDirective } from "./button-toggle-group/toggle-group-button.directive";
import { CollapsibleButtonComponent } from "./collapsible-button.component";

@NgModule({
    imports: [
        CommonModule,
        AdaptIconModule,
    ],
    declarations: [
        ButtonComponent,
        ButtonIconComponent,
        BlockingClickDirective,
        BlockingButtonGroupDirective,
        ButtonToggleDirective,
        ButtonToggleGroupComponent,
        ToggleGroupButtonDirective,
        CollapsibleButtonComponent,
    ],
    exports: [
        ButtonComponent,
        ButtonIconComponent,
        BlockingClickDirective,
        BlockingButtonGroupDirective,
        ButtonToggleDirective,
        ButtonToggleGroupComponent,
        ToggleGroupButtonDirective,
        CollapsibleButtonComponent,
    ],
})
export class AdaptButtonModule { }

<div adaptToolbarContent
     *ngIf="canSubscribeOrganisation$ | async">
    <a class="btn btn-primary"
       adaptButtonIcon="fal fa-marker"
       [routerLink]="subscribeOrganisationHref$ | async">
        Subscribe an organisation
    </a>
</div>

<ng-container *ngIf="(myOrganisationsData$ | async) as myOrganisationsData">
    <div *ngIf="myOrganisationsData.length > 1"
         class="mb-3 organisation-cards-container">
        <adapt-organisation-card *ngFor="let orgData of myOrganisationsData"
                                 [cardData]="orgData"></adapt-organisation-card>
    </div>

    <dx-accordion *ngIf="isStakeholderManager$ | async"
                  (onContentReady)="onAccordionReady($event)"
                  [items]="['dummyItem']"
                  [collapsible]="true"
                  [selectedIndex]="(myOrganisationsData.length > 1) ? -1 : 0">
        <div *dxTemplate="let item of 'title'">
            <h2 class="mb-0">All Organisations</h2>
        </div>
        <div *dxTemplate="let item of 'item'"
             class="px-1 py-0">
            <adapt-all-organisations-grid></adapt-all-organisations-grid>
        </div>
    </dx-accordion>

    <adapt-call-to-action *ngIf="myOrganisationsData.length <= 1 && !(isStakeholderManager$ | async)"
                          learnMoreUrl="https://support.adaptbydesign.com.au/coach-kit">
        <adapt-call-to-action-title>Your journey as an adapt coach</adapt-call-to-action-title>

        <p>
            The adapt way is a proven process to build the leadership, culture, systems
            and habits customers need to build a successful business.
        </p>
        <p *ngIf="!isAlto">
            Begin your journey with a customer by subscribing an organisation.
        </p>

        <ng-container *ngIf="canSubscribeOrganisation$ | async">
            <a adaptCallToActionButton
               adaptButtonIcon="fal fa-marker"
               [routerLink]="subscribeOrganisationHref$ | async">
                Subscribe an organisation
            </a>
        </ng-container>
    </adapt-call-to-action>
</ng-container>

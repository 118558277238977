import { Component, Input } from "@angular/core";
import { ActiveState } from "@common/ADAPT.Common.Model/activeEntity.interface";
import { ContactTypeMetadata } from "@common/ADAPT.Common.Model/embed/contact-type";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { PersonContact } from "@common/ADAPT.Common.Model/person/person-contact";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { combineLatest, EMPTY, map, Observable, of, ReplaySubject, switchMap } from "rxjs";
import { DirectoryAuthService } from "../directory-auth.service";
import { DirectorySharedService } from "../directory-shared.service";

@Component({
    selector: "adapt-link-person-contents",
    templateUrl: "./link-person-contents.component.html",
    styleUrls: ["./link-person-contents.component.scss"],
})
export class PersonLinkContentsComponent extends BaseComponent {
    public readonly ActiveState = ActiveState;

    @Input() public set person(value: Person | undefined) {
        if (value) {
            this.person$.next(value);
        }
    }
    public person$ = new ReplaySubject<Person>(1);

    @Input() public set personId(value: number | undefined) {
        if (value) {
            this.personId$.next(value);
        }
    }
    public personId$ = new ReplaySubject<number>(1);

    public hasPermissionToReadContactDetails$: Observable<boolean>;
    public profileUrl$: Observable<string | undefined> = of(undefined);
    public contactDetails$: Observable<PersonContact[]>;
    public positionName$: Observable<string>;
    public isStakeholderManager$: Observable<boolean>;

    public constructor(
        directorySharedService: DirectorySharedService,
        directoryAuthService: DirectoryAuthService,
    ) {
        super();

        this.personId$.pipe(
            switchMap((personId) => directorySharedService.promiseToGetPersonById(personId) as Promise<Person>),
            this.takeUntilDestroyed(),
        ).subscribe((person) => {
            this.person$.next(person);
        });

        this.hasPermissionToReadContactDetails$ = this.person$.pipe(
            map((person) => directoryAuthService.currentPersonCanReadPersonsProfile(person)),
        );

        this.profileUrl$ = this.person$.pipe(
            switchMap((person) => directorySharedService.promiseToGetProfileUrl(person) as Promise<string | undefined>),
        );

        this.contactDetails$ = combineLatest([this.hasPermissionToReadContactDetails$, this.person$])
            .pipe(
                switchMap(([hasPermission, person]) => {
                    if (!hasPermission) {
                        return EMPTY;
                    }

                    return directorySharedService.promiseToGetContactDetailsByPersonId(person.personId);
                }),
                map((contacts) => contacts.sort((a, b) => ContactTypeMetadata.ByType[a.contactType].ordinal - ContactTypeMetadata.ByType[b.contactType].ordinal)),
            );

        this.positionName$ = this.person$.pipe(
            switchMap(async (person) => {
                await directorySharedService.promiseToGetConnectionsForPersonId(person.personId);
                const latestConnection = person.getLatestConnection();
                return latestConnection?.isEmployeeConnection() ? latestConnection : undefined;
            }),
            map((connection) => {
                const position = connection && connection.getLatestPosition();
                return position
                    ? position.name
                    : "";
            }),
        );

        this.isStakeholderManager$ = this.person$.pipe(
            map((person) => person.isStakeholderManager),
        );
    }
}

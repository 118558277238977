import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AdaptImplementationKitModule } from "@common/implementation-kit/implementation-kit.module";
import { AdaptContextSidebarModule } from "@common/shell/context-sidebar/context-sidebar.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptEditorViewModule } from "@common/ux/html-editor/editor-view.module";
import { DxCheckBoxModule, DxPopupModule, DxScrollViewModule } from "devextreme-angular";
import { AdaptMenuModule } from "../menu/menu.module";
import { AdaptTooltipModule } from "../tooltip.directive/tooltip.module";
import { AdaptCommonDialogService } from "./adapt-common-dialog.service";
import { AdaptDialogComponent } from "./adapt-dialog.component/adapt-dialog.component";
import { ConfirmationDialogComponent } from "./confirmation-dialog.component/confirmation-dialog.component";
import { DialogRootComponent } from "./dialog-root.component/dialog-root.component";
import { FullscreenDialogComponent } from "./fullscreen-dialog/fullscreen-dialog.component";
import { HandleFailedSaveDialogComponent } from "./handle-failed-save-dialog/handle-failed-save-dialog.component";
import { StandardDialogButtonsComponent } from "./standard-dialog-buttons/standard-dialog-buttons.component";
import { TutorialDialogComponent } from "./tutorial-dialog/tutorial-dialog.component";

@NgModule({
    imports: [
        AdaptEditorViewModule,
        CommonModule,
        FormsModule,
        AdaptButtonModule,
        AdaptTooltipModule,
        AdaptMenuModule,
        AdaptContextSidebarModule,
        AdaptImplementationKitModule,
        DxPopupModule,
        DxCheckBoxModule,
        DxScrollViewModule,
    ],
    declarations: [
        AdaptDialogComponent,
        DialogRootComponent,
        ConfirmationDialogComponent,
        StandardDialogButtonsComponent,
        TutorialDialogComponent,
        FullscreenDialogComponent,
        HandleFailedSaveDialogComponent,
    ],
    exports: [
        DialogRootComponent,
        AdaptDialogComponent,
        ConfirmationDialogComponent,
        StandardDialogButtonsComponent,
        HandleFailedSaveDialogComponent,
    ],
})
export class AdaptCommonDialogModule {
    public constructor(
        dialogService: AdaptCommonDialogService,
    ) {
        dialogService.initialise();
    }
}

import { AdaptClientConfiguration, AdaptEnvironment } from "@common/configuration/adapt-client-configuration";
import { buildInfo } from "@common/lib/build-info/ADAPT.BuildInfo";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import * as Sentry from "@sentry/angular";

enum SentryEnvironment {
    Production = "Production",
    Staging = "Staging",
    Devcloud = "Devcloud",
    Local = "Local",
}

const mapToSentryEnvironment: { [environment in AdaptEnvironment]: SentryEnvironment } = {
    [AdaptEnvironment.Production]: SentryEnvironment.Production,
    [AdaptEnvironment.ProductionBeta]: SentryEnvironment.Production,
    [AdaptEnvironment.Staging]: SentryEnvironment.Staging,
    [AdaptEnvironment.Devcloud]: SentryEnvironment.Devcloud,
    [AdaptEnvironment.Local]: SentryEnvironment.Local,
};

export function configureSentry(sentryDsn?: string) {
    if (!sentryDsn) {
        return;
    }

    // Map to internal enum so we have consistent environment strings even if the AdaptEnvironment
    // strings are changed
    const sentryEnvironment = mapToSentryEnvironment[AdaptClientConfiguration.AdaptEnvironment];
    Sentry.init({
        dsn: sentryDsn,
        release: buildInfo.sourceTag,
        environment: sentryEnvironment,
        // for debug code we normally don't care about sending data back to sentry.
        // sometimes, however, we might want to do this (testing our integrations, error handling, etc),
        // so allow this to be overridden
        enabled: sentryEnvironment !== SentryEnvironment.Local,
        beforeSend(event, hint) {
            // CM-4007: Sometimes a promise gets rejected which isn't running in Angular's zone
            // We do want these to be handled by Sentry, but if it happens to be an error that
            // we normally suppress then don't allow it to be logged with Sentry.
            const originalError = hint && hint.originalException;
            if (originalError instanceof AdaptError && !originalError.shouldBeLogged) {
                return null;
            }

            return event;
        },
        // The first ignore below is related to CM-4236. Froala seems to cause this, of which we have no control
        // Just suppress it, it doesn't indicate any issues in any case
        // We don't need to suppress normal logging since it is output on the
        // window error event (which Sentry listens to).
        //
        // The second ignore is an error that seems to happen when network connectivitiy is bad, but we cant (and dont) do anything about it.
        // In this case, it adds a lot of noise and uses up our Sentry quota faster than it needs to be
        ignoreErrors: ["ResizeObserver loop limit exceeded", "Error: Metadata query failed for: (Methodology/Metadata; undefined)"],
    });
    Sentry.setExtras({
        "Source Hash": buildInfo.sourceHash,
        "Source Date": buildInfo.sourceDate,
    });
}

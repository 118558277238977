import { Injectable } from "@angular/core";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";

@Injectable({
    providedIn: "root",
})
export class CommonPurposeVisionAuthService {
    public static readonly ReadPurposeVision = "readPurposeVision";
    public static readonly EditPurposeVision = "editPurposeVision";

    public static registerAccess(authorisationService: AuthorisationService) {
        authorisationService.registerAccessVerifier(
            CommonPurposeVisionAuthService.ReadPurposeVision,
            {
                requirePermissions: [
                    FeaturePermissionName.CompassGuidingPhilosophyRead,
                    FeaturePermissionName.CompassGuidingPhilosophyEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            CommonPurposeVisionAuthService.EditPurposeVision,
            {
                requirePermissions: [
                    FeaturePermissionName.CompassGuidingPhilosophyEdit,
                ],
            },
        );
    }
}

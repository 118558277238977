import { Injector } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationCategoryValue, WorkflowDialogWidth, WorkflowScope, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { WorkflowStep } from "@common/ADAPT.Common.Model/embed/workflow-step";
import { WorkflowConnection } from "@common/ADAPT.Common.Model/organisation/workflow-connection";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { UserService } from "@common/user/user.service";
import { SurveyService } from "@org-common/lib/survey/survey.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { buildLocalWorkflow } from "@org-common/lib/workflow/workflow.interface";
import { WorkflowService } from "@org-common/lib/workflow/workflow.service";
import { WorkflowIdentifier } from "@org-common/lib/workflow/workflow-identifier.enum";
import { forkJoin, of, switchMap, tap } from "rxjs";
import { TourIdentifier } from "../../tours/tour-identifier.enum";
import { createActivityBriefWorkflowStep, createWorkshopScheduleWorkflows } from "../workflow-utilities";
import { LaunchOrganisationDiagnosticSurveySelector } from "./launch-organisation-diagnostic-survey/launch-organisation-diagnostic-survey.component";
import { IOrganisationDiagnosticStatusCustomData } from "./organisation-diagnostic-status-custom-data";


export const UnderstandJourneyWorkflowName = "Understand the journey";
export const UnderstandJourneyWorkflow = buildLocalWorkflow({
    name: UnderstandJourneyWorkflowName,
    workflowId: WorkflowIdentifier.UnderstandJourney,
    type: WorkflowType.Journey,
    iconStyle: "fal fa-compass",
    overview: `Run a health check survey on your business to determine your current state. Examine the results in the platform, and get your team to commit to follow the adapt way.`,
    articleSlug: ImplementationKitArticle.UnderstandJourneyOutcomes,
    wrapUpSlug: ImplementationKitArticle.UnderstandJourneyWrapUp,
    wrapUpGuidedTourIdentifier: TourIdentifier.HealthCheckWrapUpTour,
    scope: WorkflowScope.Team,
    featuresToEnable: [FeatureName.OrganisationDiagnostic],
    category: OrganisationCategoryValue.Onboarding,
    time: "3 hours",
    people: "Leadership team",
    mindset: "Collaborative",
    workflows: [
        {
            name: UnderstandJourneyWorkflowName,
            workflowId: "ODInitiateSurvey",
            type: WorkflowType.Workflow,
            dialogWidth: WorkflowDialogWidth.Large,
            continueOnFinish: true,
            articleSlug: ImplementationKitArticle.OrganisationHealthCheckInitiateSurvey,
            showBreadcrumbs: true,
            preProcessSteps: (injector: Injector, workflowConnection: WorkflowConnection, workflowSteps: WorkflowStep[]) => {
                const launchSurveyIndex = workflowSteps.findIndex((step) => step.componentSelector === LaunchOrganisationDiagnosticSurveySelector);
                const beforeLaunchStep = workflowSteps[launchSurveyIndex - 1];
                if (beforeLaunchStep) {
                    beforeLaunchStep.workflowStepNextText = undefined; // restore default
                }

                const completedHealthCheck = SurveyService.getCompletedHealthCheckPersonSurvey(injector);
                if (completedHealthCheck && workflowConnection) {
                    const commonTeamsService = injector.get(CommonTeamsService);
                    const workflowService = injector.get(WorkflowService);
                    // -> add surveyId into customData and won't start another new survey
                    return forkJoin([
                        commonTeamsService.promiseToGetLeadershipTeam(),
                        workflowService.getWorkflowCustomData<IOrganisationDiagnosticStatusCustomData>(workflowConnection),
                    ]).pipe(
                        switchMap(([leadershipTeam, customData]) => {
                            const userService = injector.get(UserService);
                            const currentPersonId = userService.getCurrentPersonId();
                            const otherTeamMembers = leadershipTeam.roleConnections.filter((i) => i.isActive() && i.connection.personId !== currentPersonId);
                            if (completedHealthCheck.personId === currentPersonId && otherTeamMembers.length === 0) {
                                // don't have anyone else besides current person - can remove the 2 steps starting from adapt-launch-organisation-diagnostic-survey
                                // - note that the workflow may add outcomes at the start or wrap up at the end - so need to search for the right step to exclude
                                if (launchSurveyIndex > 0) {
                                    workflowSteps.splice(launchSurveyIndex, 2);
                                    if (beforeLaunchStep) {
                                        beforeLaunchStep.workflowStepNextText = "Next - Schedule debrief";
                                    }
                                }
                            }

                            customData.surveyId = completedHealthCheck.surveyId;
                            return workflowService.updateWorkflowCustomData(workflowConnection, customData);
                        }),
                    );
                }

                return of(undefined);
            },
            steps: [
                createActivityBriefWorkflowStep(UnderstandJourneyWorkflowName, ImplementationKitArticle.UnderstandJourneyActivityBrief, undefined, undefined),
                {
                    name: "The adapt way",
                    ordinal: 1,
                    allowBack: true,
                    canSkip: false,
                    articleSlug: ImplementationKitArticle.UnderstandJourneyActivityBriefPart2,
                }, {
                    name: "Building & implementing strategy",
                    ordinal: 2,
                    allowBack: true,
                    canSkip: false,
                    articleSlug: ImplementationKitArticle.UnderstandJourneyActivityBriefPart3,
                }, {
                    name: "Health check - start survey",
                    ordinal: 3,
                    allowBack: true,
                    canSkip: false,
                    articleSlug: ImplementationKitArticle.OrganisationHealthCheckLaunchSurveyIntro,
                    componentSelector: LaunchOrganisationDiagnosticSurveySelector,
                    workflowStepNextText: "Send survey invitations",
                }, {
                    name: "Pending survey",
                    ordinal: 4,
                    allowBack: false,
                    canSkip: false,
                    hideTitle: true,
                    componentSelector: "adapt-pending-organisation-diagnostic-survey",
                    overviewComponentSelector: "adapt-show-survey-progress",
                    workflowStepNextText: "Schedule meeting",
                },
            ],
        },
        ...createWorkshopScheduleWorkflows("Health check debrief", WorkflowIdentifier.UnderstandJourney, {
            name: "Health check debrief",
            scheduleArticleSlug: ImplementationKitArticle.OrganisationHealthCheckScheduleDebrief,
            runArticleSlug: ImplementationKitArticle.OrganisationHealthCheckRunDebrief,
            scheduleOverviewComponentSelector: "adapt-show-survey-meeting",
            runOverviewComponentSelector: "adapt-show-survey-meeting",
            customData: {
                meetingCustomData: (workflowCustomData?: IOrganisationDiagnosticStatusCustomData) => ({ surveyId: workflowCustomData?.surveyId }),
            },
        }),
    ],
    finalizeWorkflow: (injector: Injector, workflowConnection: WorkflowConnection) => {
        const workflowService = injector.get(WorkflowService);
        return workflowService.getWorkflowCustomData<IOrganisationDiagnosticStatusCustomData>(workflowConnection).pipe(
            tap((customData: IOrganisationDiagnosticStatusCustomData) => { // customData becomes unknown if don't declare the type here - ts inference limitation with single tap in pipe
                // clear this after the pathway is cancelled or completed
                const completedHealthCheckSurvey = SurveyService.getCompletedHealthCheckPersonSurvey(injector);
                if (customData.surveyId && customData.surveyId === completedHealthCheckSurvey?.surveyId) {
                    SurveyService.clearCompletedHealthCheckPersonSurvey(injector);
                }
            }),
        );
    },
});

export enum CoachSessionStatus {
    Paid = "Paid",
    Scheduled = "Scheduled",
    Complete = "Complete",
    FeedbackRequested = "FeedbackRequested",
    FeedbackComplete = "FeedbackComplete",
    Cancelled = "Cancelled",
}

export class CoachSessionStatusMetadata {
    public static readonly Paid = new CoachSessionStatusMetadata(CoachSessionStatus.Paid, "Paid (unscheduled)", "bg-warning");
    public static readonly Scheduled = new CoachSessionStatusMetadata(CoachSessionStatus.Scheduled, "Paid & scheduled", "bg-success");
    public static readonly Complete = new CoachSessionStatusMetadata(CoachSessionStatus.Complete, "Complete", "bg-success");
    public static readonly FeedbackRequested = new CoachSessionStatusMetadata(CoachSessionStatus.FeedbackRequested, "Feedback Requested", "bg-warning");
    public static readonly FeedbackComplete = new CoachSessionStatusMetadata(CoachSessionStatus.FeedbackComplete, "Feedback Complete", "bg-success");
    public static readonly Cancelled = new CoachSessionStatusMetadata(CoachSessionStatus.Cancelled, "Cancelled", "bg-secondary");

    public static readonly All = [
        CoachSessionStatusMetadata.Paid,
        CoachSessionStatusMetadata.Scheduled,
        CoachSessionStatusMetadata.Complete,
        CoachSessionStatusMetadata.FeedbackRequested,
        CoachSessionStatusMetadata.FeedbackComplete,
        CoachSessionStatusMetadata.Cancelled,
    ];

    public static readonly CoachRequiredStatuses = [
        CoachSessionStatusMetadata.Complete,
        CoachSessionStatusMetadata.FeedbackRequested,
        CoachSessionStatusMetadata.FeedbackComplete,
    ];

    public static readonly ByType: { [status in CoachSessionStatus]: CoachSessionStatusMetadata } = {
        [CoachSessionStatus.Paid]: CoachSessionStatusMetadata.Paid,
        [CoachSessionStatus.Scheduled]: CoachSessionStatusMetadata.Scheduled,
        [CoachSessionStatus.Complete]: CoachSessionStatusMetadata.Complete,
        [CoachSessionStatus.FeedbackRequested]: CoachSessionStatusMetadata.FeedbackRequested,
        [CoachSessionStatus.FeedbackComplete]: CoachSessionStatusMetadata.FeedbackComplete,
        [CoachSessionStatus.Cancelled]: CoachSessionStatusMetadata.Cancelled,
    };

    public static readonly ByIntValue: {[value: number]: CoachSessionStatusMetadata} = {
        0: CoachSessionStatusMetadata.Paid,
        1: CoachSessionStatusMetadata.Scheduled,
        2: CoachSessionStatusMetadata.Complete,
        3: CoachSessionStatusMetadata.FeedbackRequested,
        4: CoachSessionStatusMetadata.FeedbackComplete,
        5: CoachSessionStatusMetadata.Cancelled,
    };

    private constructor(
        public readonly status: CoachSessionStatus,
        public readonly name: string,
        public readonly badgeClass: string,
    ) {
    }
}

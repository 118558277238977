import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { AutoUpdaterMergeStrategy } from "./auto-updater-merge-strategy";
import { BaseEntityAutoUpdater } from "./base-entity-auto-updater";

export class DeletedEntityAutoUpdater extends BaseEntityAutoUpdater {
    public userIsAllowedToIgnoreServerUpdates = false;

    public hasConflicts(): boolean {
        return !!this.localEntity && !this.localEntity.entityAspect.entityState.isUnchanged();
    }

    public updatesShouldBeIntegrated(): boolean {
        return !!this.localEntity;
    }

    public promiseToIntegrateUpdates(_mergeStrategy: AutoUpdaterMergeStrategy): Promise<IBreezeEntity | void> {
        this.commonDataService.detachEntityFromBreeze(this.localEntity!);
        return Promise.resolve();
    }

    public postProcessHumanReadableSummary(summary: string): string {
        return `Has deleted ${summary}`;
    }
}

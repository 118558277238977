import { AfterViewChecked, Component, ContentChild, ElementRef, Input, OnInit, TemplateRef, ViewEncapsulation } from "@angular/core";
import { BaseComponent } from "../../base.component/base.component";

@Component({
    selector: "adapt-see-more-popup",
    templateUrl: "./see-more-popup.component.html",
    styleUrls: ["./see-more-popup.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class SeeMorePopupComponent extends BaseComponent implements OnInit, AfterViewChecked {
    @Input() public container?: ElementRef<any>;
    @Input() public minWidth: number = 200;
    @Input() public desiredWidth: number | string = "90%";
    @Input() public buttonText = "Show content";
    @Input() public popupTitle = "Content";

    @ContentChild(TemplateRef, { static: true }) public template?: TemplateRef<any>;

    public contentOverflows = false;
    public popupVisible = false;

    private lastWidth = 0;
    private width$ = this.createThrottledUpdater((width: number) => {
        this.contentOverflows = width < this.minWidth;
        this.lastWidth = width;
    });

    public constructor(elementRef?: ElementRef<HTMLElement>) {
        super(elementRef);

        this.sizeChange$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updateWidth());
    }

    public ngOnInit() {
        if (!this.template) {
            throw new Error("adapt-see-more-popup must contain an ng-template");
        }
    }

    public ngAfterViewChecked() {
        this.updateWidth();
    }

    private updateWidth() {
        // use parent for width, or container if provided
        const projectedContent = this.container?.nativeElement ?? this.elementRef?.nativeElement?.parentElement;

        // only update when there is actually some difference
        const scrollWidth = Math.floor(projectedContent.scrollWidth);
        if (projectedContent && scrollWidth !== this.lastWidth) {
            this.width$.next(scrollWidth);
        }
    }
}

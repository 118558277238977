export class QueuedCaller<T> {
    private callee?: T;
    private queuedCalls: ((callee: T) => void)[] = [];

    private resolveCallee!: (callee: T) => void;
    private calleePromise = new Promise<T>((resolve) => this.resolveCallee = resolve);

    /** Sets the callee, flushes any pending calls and returns the callee further use */
    public setCallee(callee: T) {
        this.callee = callee;
        this.resolveCallee(callee);

        if (callee) {
            for (const call of this.queuedCalls) {
                call(this.callee);
            }

            this.clearQueuedCalls();
        }

        return callee;
    }

    /** Executes the call against the callee, queueing it if setCallee has not been called yet */
    public call(execute: (callee: T) => void) {
        if (this.callee) {
            execute(this.callee);
        } else {
            this.queuedCalls.push(execute);
        }
    }

    public async promiseToCall<TReturn>(execute: (callee: T) => TReturn) {
        const callee = await this.calleePromise;
        return execute(callee);
    }

    public promiseToWaitUntilCalleeSet() {
        return this.calleePromise;
    }

    /** Clears any calls that have been queued before the callee is set */
    public clearQueuedCalls() {
        if (this.queuedCalls.length > 0) {
            this.queuedCalls = [];
        }
    }

    /** Get the instance of the callee. Will throw if setCallee has not be called yet */
    public get definedCallee(): T {
        if (!this.callee) {
            throw new Error("Callee was not defined when attempted to be accessed");
        }

        return this.callee;
    }

    public get isSet() {
        return !!this.callee;
    }
}

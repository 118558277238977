<adapt-dialog [maxWidth]="500">
    <h3 adapt-dialog-title>Update billing details</h3>

    <ng-container adapt-dialog-content>
        <div role="alert"
             class="alert alert-info">We need somewhere to send any subscription and billing related emails.</div>
        <adapt-update-billing-details [account]="account"></adapt-update-billing-details>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveText="Save & close"
                                   (saveClick)="saveAndClose()"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>

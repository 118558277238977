
export enum ContactType {
    Phone = "Phone",
    Email = "Email",
    Address = "Address",
    Messaging = "Messaging",
}

export class ContactTypeMetadata {
    public static readonly Phone = new ContactTypeMetadata(ContactType.Phone, "Phone", 0, "fal fa-fw fa-phone-alt", "tel:");
    public static readonly Email = new ContactTypeMetadata(ContactType.Email, "Email", 1, "fal fa-fw fa-envelope", "mailto:");
    public static readonly Address = new ContactTypeMetadata(ContactType.Address, "Address", 2, "fal fa-fw fa-map-marker", undefined);
    public static readonly Messaging = new ContactTypeMetadata(ContactType.Messaging, "Messaging", 3, "fal fa-fw fa-comment", undefined);

    public static readonly All = [
        ContactTypeMetadata.Phone,
        ContactTypeMetadata.Email,
        ContactTypeMetadata.Address,
        ContactTypeMetadata.Messaging,
    ];

    public static readonly ByType: { [type in ContactType]: ContactTypeMetadata } = {
        [ContactType.Phone]: ContactTypeMetadata.Phone,
        [ContactType.Email]: ContactTypeMetadata.Email,
        [ContactType.Address]: ContactTypeMetadata.Address,
        [ContactType.Messaging]: ContactTypeMetadata.Messaging,
    };

    public static readonly ByIntValue: { [value: number]: ContactTypeMetadata } = {
        0: ContactTypeMetadata.Phone,
        1: ContactTypeMetadata.Email,
        2: ContactTypeMetadata.Address,
        3: ContactTypeMetadata.Messaging,
    };

    private constructor(
        public readonly type: ContactType,
        public readonly name: string,
        public readonly ordinal: number,
        public readonly iconClass: string,
        public readonly urlPrefix?: string,
    ) { }
}

import { Injectable, Injector } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { EntityPersistentService } from "@common/lib/data/entity-persistent.service";
import { Logger } from "@common/lib/logger/logger";
import { BaseService } from "@common/service/base.service";
import { InterpretedService } from "@common/xstate-angular/types";
import { XstateAngular } from "@common/xstate-angular/useMachine";
import { defer, lastValueFrom, of } from "rxjs";
import { first, map, startWith, switchMap, takeWhile, tap } from "rxjs/operators";
import { EventData } from "xstate";
import { IOrganisationContext, IOrganisationSchema, OrganisationActions, OrganisationEvent, OrganisationEventActionParameters, OrganisationEventActions, OrganisationEvents, OrganisationMachine, OrganisationState } from "./organisation-machine";
import { IOrganisationService } from "./organisation-service.interface";

// This is the state machine context of the organisation state transitions as defined here:
// https://adaptbydesign.atlassian.net/wiki/spaces/DEV/pages/556564483/2018-10+Organisation+Service+Initialisation+Sequence+and+State+Transitions
@Injectable({
    providedIn: "root",
})
export class OrganisationStateService extends BaseService {
    public readonly log = Logger.getLogger(this.constructor.name);

    private orgService?: IOrganisationService;

    public readonly stateActions: OrganisationEventActions = {
        setOrganisationEntity: this.generateAction(OrganisationEvent.SetOrganisationEntity),
        updateOrganisationEntity: this.generateAction(OrganisationEvent.UpdateOrganisationEntity),
        waitForPerson: this.generateAction(OrganisationEvent.WaitForPerson),
        logout: this.generateAction(OrganisationEvent.Logout),
        initialise: this.generateAction(OrganisationEvent.Initialise),
        doPostInitialisation: this.generateAction(OrganisationEvent.DoPostInitialisation),
        switchOrganisation: this.generateAction(OrganisationEvent.SwitchOrganisation),
        fallbackSwitch: this.generateAction(OrganisationEvent.FallbackSwitch),
    };

    private machineService: InterpretedService<IOrganisationContext, IOrganisationSchema, OrganisationEvents>;

    public constructor(
        injector: Injector,
        private entityPersistentService: EntityPersistentService,
        private readonly xstateAngular: XstateAngular<IOrganisationContext, IOrganisationSchema, OrganisationEvents>,
    ) {
        super(injector);

        this.machineService = this.xstateAngular.useMachine(OrganisationMachine, {
            devTools: false,
            actions: {
                [OrganisationActions.InitialiseWithOrganisationEntity]: () => this.entityPersistentService.initialise(),
                [OrganisationActions.NotifyOrganisationEntityUpdatedEvent]: () => this.organisationService!.notifyOrganisationEntityUpdatedEvent(),
                [OrganisationActions.NotifyOrganisationChangingEvent]: () => this.organisationService!.notifyOrganisationChangingEvent(),
                [OrganisationActions.NotifyOrganisationChangedEvent]: () => this.organisationService!.notifyOrganisationChangedEvent(),
                [OrganisationActions.LogEntry]: (_ctx, _evt, actionMeta) => this.log.info("Entry: Organisation state changed to: " + actionMeta.state.value),
            },
            services: {
                [OrganisationActions.PromiseToPerformOrganisationSwitch]: (_ctx, event) => {
                    if (event.type === OrganisationEvent.SwitchOrganisation) {
                        return this.orgService!.promiseToPerformOrganisationSwitch(event);
                    }
                    return Promise.reject("No organisation parameters provided for switching");
                },
            },
        });
    }

    // used internally for unit test
    public get stateTransitioned$() {
        return this.machineService.state$;
    }

    public get currentState() {
        return this.machineService.service.getSnapshot();
    }

    public get organisation() {
        return this.currentState.context.organisation;
    }

    public get organisationService() {
        return this.orgService;
    }

    public set organisationService(value: IOrganisationService | undefined) {
        this.orgService = value;
    }

    public isStateWithOrganisationEntity() {
        return !this.currentState.matches(OrganisationState.InitialState)
            && !this.currentState.matches(OrganisationState.AwaitingUserInitialisationState);
    }

    public isOrganisationReady() {
        return this.currentState.matches(OrganisationState.OrganisationReadyState);
    }

    @Autobind
    public waitForOrganisationEntity() {
        return defer(() => {
            return this.isStateWithOrganisationEntity()
                ? of(void 0)
                : this.machineService.state$.pipe(
                    startWith(undefined),
                    takeWhile((s) => !s || s.matches(OrganisationState.InitialState) || s.matches(OrganisationState.AwaitingUserInitialisationState)),
                    map(() => void 0),
                );
        });
    }

    @Autobind
    public promiseToWaitForOrganisationReady() {
        return lastValueFrom(defer(() => {
            return this.isOrganisationReady()
                ? of(void 0)
                : this.machineService.state$.pipe(
                    // need this 'startWith' so that we still get an emit even if the first emit from state$ not matching the takeWhile condition
                    // - or the promise will throw an error (no element from sequence) - and org validation will fail!
                    startWith(undefined),
                    takeWhile((s) => !s || !s.matches(OrganisationState.OrganisationReadyState)),
                    map(() => void 0),
                );
        })) as Promise<void>;
    }

    @Autobind
    public promiseToWaitForOrganisationSwitchCompletion() {
        return lastValueFrom(this.machineService.state$.pipe(
            first((s) => s.matches(OrganisationState.OrganisationReadyState)),
            tap(() => this.log.debug("Switched organisation")),
            switchMap((s) => s.context.error
                ? Promise.reject(s.context.error)
                : Promise.resolve()),
        ));
    }

    private generateAction<T extends OrganisationEvent>(type: T) {
        return (data?: OrganisationEventActionParameters<T>) => {
            this.log.debug(`Sending event to machine: "${type}"`, data ?? "");
            return this.machineService.send(type, data as EventData | undefined);
        };
    }
}

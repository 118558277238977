import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AboutPageComponent } from "./about-page/about-page.component";
import { aboutPageRoute } from "./about-page/about-page.route";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
    ],
    declarations: [
        AboutPageComponent,
    ],
    exports: [],
    providers: [],
})
export class AdaptCommonPagesModule {
    public static readonly Routes = [
        ...aboutPageRoute.routes,
    ];
}

<adapt-feature-guidance [configItem]="configItem">
    <p>
        A meeting framework is provided for teams that would benefit from tracking meeting attendance and minutes.
    </p>
</adapt-feature-guidance>

<adapt-feature-configuration [team]="team"
                             [configItem]="configItem">
    <adapt-configure-meeting-agenda-templates [team]="team"></adapt-configure-meeting-agenda-templates>
</adapt-feature-configuration>

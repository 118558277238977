import { Component, Inject, Input, TemplateRef } from "@angular/core";
import { PERSONAL_CONFIGURATION_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { IAdaptLinkObject } from "@common/route/route.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ConfigArea } from "@org-common/lib/configuration/configuration-types";
import { ICalendarProvider } from "../../calendar/calendar.interface";
import { OAuthService } from "../../oauth/oauth.service";

@Component({
    selector: "adapt-meeting-integration-prompt",
    templateUrl: "./meeting-integration-prompt.component.html",
})
export class MeetingIntegrationPromptComponent extends BaseComponent {
    @Input() public disabled = false;
    @Input() public alwaysShown = false;
    @Input() public showLocal = false;
    @Input() public linkDisabled = false;
    @Input() public contentTemplate?: TemplateRef<any>;

    public readonly ConfigArea = ConfigArea;
    public calendarProvider?: ICalendarProvider;
    public showTextPrompt = true;
    public personalConfigRoute?: IAdaptLinkObject;

    public constructor(
        public oauthService: OAuthService,
        @Inject(PERSONAL_CONFIGURATION_PAGE) public personalConfigurationPageRoute: IAdaptRoute<{}>,
    ) {
        super();

        this.personalConfigurationPageRoute.getRouteObject().subscribe((route) => {
            this.personalConfigRoute = route;
        });

        this.oauthService.authProvider$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((provider) => {
            this.calendarProvider = provider;
            this.showTextPrompt = !this.oauthService.hasPreviouslyLoggedInWithProvider(provider?.id);
        });
    }
}

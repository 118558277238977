import { Component } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { MeetingAgendaItemComponent } from "@org-common/lib/meetings/meeting-agenda-component-registry";
import { StrategicGoalsService } from "@org-common/lib/strategic-goals/strategic-goals.service";
import { StrategyAuthService } from "@org-common/lib/strategy/strategy-auth.service";

@MeetingAgendaItemComponent("adapt-add-strategic-goals-agenda-item")
@Component({
    selector: "adapt-add-strategic-goals-agenda-item",
    templateUrl: "./add-strategic-goals-agenda-item.component.html",
})
export class AddStrategicGoalsAgendaItemComponent {
    public readonly EditStrategyGoal = StrategyAuthService.EditStrategicGoals;

    constructor(private goalsService: StrategicGoalsService) {}

    @Autobind
    public addGoal() {
        return this.goalsService.editGoalAfterCreate();
    }
}

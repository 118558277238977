import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { TeamMeetingGuidance } from "@common/ADAPT.Common.Model/organisation/team-meeting-guidance";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { MeetingsService } from "@org-common/lib/meetings/meetings.service";
import { BehaviorSubject, combineLatest, lastValueFrom } from "rxjs";

@Component({
    selector: "adapt-configure-team-stewardship",
    templateUrl: "./configure-team-stewardship.component.html",
})
export class ConfigureTeamStewardshipComponent extends BaseComponent implements OnChanges, OnInit {
    @Input() public team?: Team;
    @Output() public areAllEditorsValid = new EventEmitter<boolean>();

    public teamMeetingGuidance?: TeamMeetingGuidance;
    public intentEditorValid = new BehaviorSubject(true);
    public guidelinesEditorValid = new BehaviorSubject(true);
    public frequencyEditorValid = new BehaviorSubject(true);
    public processEditorValid = new BehaviorSubject(true);


    public constructor(
        private meetingsService: MeetingsService,
    ) {
        super();
    }


    public ngOnInit(): void {
        combineLatest([
            this.frequencyEditorValid,
            this.guidelinesEditorValid,
            this.intentEditorValid,
            this.processEditorValid,
        ]).subscribe(([frequency,
                          guidelines,
                          intent,
                          process]) => {
            const a = frequency && guidelines && intent && process;
            this.areAllEditorsValid.emit(a);
        });
    }

    public async ngOnChanges(changes: SimpleChanges) {
        if (changes.team) {
            await this.initialiseTeamMeetingGuidance(changes.team.currentValue);
        }
    }

    private async initialiseTeamMeetingGuidance(team: Team) {
        if (team.teamMeetingGuidance) {
            this.teamMeetingGuidance = team.teamMeetingGuidance;
        } else {
            // team meeting guidance entity is created from server on team entity creation
            this.teamMeetingGuidance = await lastValueFrom(this.meetingsService.getMeetingGuidanceForTeam(team.teamId));
        }
    }
}

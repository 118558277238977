import { Component, Injector } from "@angular/core";
import { EventTypePreset } from "@common/ADAPT.Common.Model/organisation/event-type";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { MeetingAgendaItem } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { MeetingAgendaItemComponent } from "@org-common/lib/meetings/meeting-agenda-component-registry";
import { IReviewRecurrencesMap } from "@org-common/lib/schedule/review-recurrences/review-recurrences.component";
import { defer, EMPTY, lastValueFrom, switchMap } from "rxjs";
import { WorkshopSetCadenceBaseComponent } from "../workshop-set-cadence-base.component";

@MeetingAgendaItemComponent("adapt-annual-workshop-set-cadence")
@Component({
    selector: "adapt-annual-workshop-set-cadence",
    templateUrl: "./annual-workshop-set-cadence.component.html",
})
export class AnnualWorkshopSetCadenceComponent extends WorkshopSetCadenceBaseComponent {
    public loading = true;
    public meetingAgendaItem?: MeetingAgendaItem;
    public recurrences?: IReviewRecurrencesMap;

    protected creatingNextCadence = true;
    protected eventTypePresets = [
        EventTypePreset.AnnualStrategy,
        EventTypePreset.QuarterlyStrategy,
        EventTypePreset.MonthlyStrategy,
        EventTypePreset.OKRCheckIn,
    ];

    public constructor(injector: Injector) {
        super(injector);
    }

    protected async updateData() {
        await super.updateData();

        // grab the latest AS event series so we can check if it's complete
        // if the event series we grab isn't the same series as the meeting we're currently doing this within then we've already scheduled the next one
        const annualEventSeries = await lastValueFrom(this.scheduleService.getLatestMeetingAndSeriesForEventTypePreset(EventTypePreset.AnnualStrategy, true));
        this.creatingNextCadence = annualEventSeries?.series === this.meetingAgendaItem?.meeting?.eventSeries;
    }

    @Autobind
    public setCadence() {
        return defer(async () => {
            const meetingEventSeries = this.meetingAgendaItem?.meeting?.eventSeries;
            const allEventSeries = Array.from(this.recurrences?.values() ?? []);

            if (allEventSeries.length !== 0 && this.creatingNextCadence) {
                // don't want to delete the meeting we're currently looking at!
                const eventListItems = allEventSeries
                    .filter((es) => es !== meetingEventSeries && !es.meetings.every((m) => m.extensions.isEnded))
                    .map((es) => {
                        const icon = `<i class="fas fa-square me-1" style="color: ${es.eventType.colour}"></i>`;
                        return `<li>${icon} ${es.eventType.name}</li>`;
                    })
                    .join("\n");

                // all events aside from the AS are done/gone, don't need to prompt
                if (eventListItems.length !== 0) {
                    const shouldContinue = await lastValueFrom(this.dialogService.openConfirmationDialogWithBoolean({
                        title: "Confirm new cadence",
                        message: `<p class="mb-1">Creating your new cadence will delete all <b>non-started</b> meetings for each of the following events:</p>
                            <ul class="mb-1">${eventListItems}</ul>
                            <p>Any changes you have made to the affected meetings will be lost. Meetings that are in progress or ended will not be affected.</p>`,
                        checkboxMessage: "Confirm that you understand these meetings will be deleted",
                        confirmButtonPreset: "deleteAndSave",
                    }));

                    if (!shouldContinue) {
                        return false;
                    }
                }

                const deletedMeetings: Meeting[] = [];
                for (const eventSeries of allEventSeries) {
                    eventSeries.endDate = new Date();

                    for (const meeting of Array.from(eventSeries.meetings)) {
                        // don't want to delete the AS meeting we're currently in...
                        if (meeting !== this.meetingAgendaItem?.meeting && meeting.extensions.isNotStarted) {
                            await lastValueFrom(this.commonDataService.remove(meeting));
                            deletedMeetings.push(meeting);
                        }
                    }
                }

                await lastValueFrom(this.commonDataService.saveEntities([...deletedMeetings, ...allEventSeries]));
            }

            return true;
        }).pipe(
            switchMap((shouldContinue) => shouldContinue
                ? super.setCadence()
                : EMPTY),
        );
    }
}

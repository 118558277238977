import { Injectable, Injector } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { BaseService } from "@common/service/base.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { take } from "rxjs";
import { ISurveyAuthService } from "../survey-auth.service.interface";

@Injectable({
    providedIn: "root",
})
export class OrganisationDiagnosticAuthService extends BaseService implements ISurveyAuthService {
    public static readonly EditOrganisationDiagnostic = "editOrganisationDiagnostic";
    public static readonly ReadOrganisationDiagnostic = "readOrganisationDiagnostic";

    public constructor(
        injector: Injector,
        private authService: AuthorisationService,
        private featuresService: FeaturesService,
    ) {
        super(injector);
    }

    public static registerAccessVerifiers(authService: AuthorisationService) {
        authService.registerAccessVerifier(
            OrganisationDiagnosticAuthService.EditOrganisationDiagnostic,
            {
                requirePermissions: [
                    FeaturePermissionName.OrganisationDiagnosticEdit,
                ],
            },
        );
        authService.registerAccessVerifier(
            OrganisationDiagnosticAuthService.ReadOrganisationDiagnostic,
            {
                requirePermissions: [
                    FeaturePermissionName.OrganisationDiagnosticEdit,
                    FeaturePermissionName.OrganisationDiagnosticRead,
                ],
            },
        );
    }

    public isFeatureActive() {
        return this.featuresService.isFeatureActive(FeatureName.OrganisationDiagnostic);
    }

    public hasReadAccessToSurveys$() {
        return this.authService.getHasAccess(OrganisationDiagnosticAuthService.ReadOrganisationDiagnostic).pipe(
            take(1),
        );
    }

    public hasEditAccessToSurveys$() {
        return this.authService.getHasAccess(OrganisationDiagnosticAuthService.EditOrganisationDiagnostic).pipe(
            take(1),
        );
    }
}

import { Component, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChildren } from "@angular/core";
import { Survey, SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DisplayCategoryQuestionsComponent } from "../display-category-questions/display-category-questions.component";
import { SurveyService } from "../survey.service";
import { ISurveyQuestions } from "../survey-questions.interface";
import { SurveyUtils } from "../survey-utils";

export interface IQuestionMaxResponses {
    [questionId: number]: number;
}

export interface ICategorySummaryHeights {
    [categoryId: number]: number;
}

@Component({
    selector: "adapt-analyse-survey",
    templateUrl: "./analyse-survey.component.html",
    styleUrls: ["./analyse-survey.component.scss"],
})
export class AnalyseSurveyComponent extends BaseComponent implements OnChanges {
    public readonly SurveyType = SurveyType;

    @ViewChildren(DisplayCategoryQuestionsComponent) private allCategories = new QueryList<DisplayCategoryQuestionsComponent>();
    public templateStyleClass = "analyse-survey-page";
    public questionIds: number[] = [];
    public surveyQuestions?: ISurveyQuestions;

    @Input() public currentSurvey?: Survey;
    @Input() public compareMode = false;
    @Input() public forceInitialise = false;

    @Input() public maxResponseCounts: IQuestionMaxResponses = {};
    @Output() public maxResponseCountsChange = new EventEmitter<IQuestionMaxResponses>();

    @Input() public otherCategorySummaryHeights: ICategorySummaryHeights = {};
    @Output() public categorySummaryHeights = new EventEmitter<ICategorySummaryHeights>();

    private _categorySummaryHeights: ICategorySummaryHeights = {};

    constructor(private surveyService: SurveyService) {
        super();
    }

    public get isDrawn() {
        return !this.allCategories.some((s) => !s.isDrawn);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.compareMode) {
            this.templateStyleClass = this.compareMode ? "analyse-survey-compare-page" : "analyse-survey-page";
        }

        if (changes.currentSurvey && this.currentSurvey) {
            this.surveyQuestions = this.surveyService.getQuestionsForSurvey(this.currentSurvey);
            this.questionIds = this.surveyQuestions.orderedQuestionIds;
            this.questionIds.forEach((id) => this.maxResponseCounts[id] = 0);
        }
    }

    public getAnalyseTargetLabel(surveyType: SurveyType) {
        return SurveyUtils.forSurveyType(surveyType).analyseTargetLabel.toLowerCase();
    }

    public onMaxResponseCountChanged(count: number, questionId: number) {
        // do this next digest cycle as this is from callback after view checked - ExpressionChangedAfterItHasBeenCheckedError
        setTimeout(() => {
            this.maxResponseCounts[questionId] = count;
            this.maxResponseCountsChange.emit(this.maxResponseCounts);
        });
    }

    public categorySummaryHeightChanged(categoryId: number, height: number) {
        this._categorySummaryHeights[categoryId] = height;
        this.categorySummaryHeights.emit(this._categorySummaryHeights);
    }
}

import { Component, Input, TemplateRef } from "@angular/core";
import { Zone } from "@common/ADAPT.Common.Model/methodology/zone";
import { Breakpoint } from "@common/ux/responsive/breakpoint";

const zoneClass: { [zone in Zone]: string } = {
    [Zone.OrganisationDesign]: "organisation-design",
    [Zone.EconomicEngine]: "economic-engine",
    [Zone.HealthyCulture]: "healthy-culture",
    [Zone.FinanceAndOwnership]: "finance-and-ownership",
    [Zone.ResearchAndDevelopment]: "research-development",
};

@Component({
    selector: "adapt-render-zone-map",
    templateUrl: "./render-zone-map.component.html",
    styleUrls: ["./render-zone-map.component.scss"],
})
export class RenderZoneMapComponent {
    @Input() public verticalLayout = false;
    @Input() public zoneContentTemplate?: TemplateRef<unknown>;
    @Input() public fullLayoutBreakpoint?: Breakpoint;
    @Input() public zoneMenuTemplate?: TemplateRef<unknown>;

    public zoneContentClass(zone: Zone) {
        return zoneClass[zone];
    }
}

<ng-container *ngIf="item">
    <ng-container *ngIf="(item!.link?.href$ | async) as href; else noLink">
        <a *ngIf="isInternal; else isExternal"
           [id]="item!.id || ''"
           [routerLink]="href"
           [attr.title]="item!.tooltip ? item!.tooltip : null"
           (click)="togglePopover($event)">
            <adapt-render-component *ngIf="item?.linkContent"
                                    [component]="item!.linkContent!.component"
                                    [componentInputs]="item!.linkContent!.params"></adapt-render-component>
        </a>
        <ng-template #isExternal>
            <a [id]="item!.id || ''"
               [attr.href]="href || '#'"
               [attr.target]="item!.link?.openInNewTab ? '_blank' : null"
               [attr.rel]="item!.link?.openInNewTab ? 'noreferrer noopener' : null"
               [adaptTooltip]="item!.tooltip ? item!.tooltip : null"
               tooltipPlacement="right"
               (click)="togglePopover($event)">
                <adapt-render-component *ngIf="item?.linkContent"
                                        [component]="item!.linkContent!.component"
                                        [componentInputs]="item!.linkContent!.params"></adapt-render-component>
            </a>
        </ng-template>
    </ng-container>
    <!-- Angular 12 does not support setting routerLink to null|undefined. we can revert this once we upgrade to 13. -->
    <ng-template #noLink>
        <a [id]="item!.id || ''"
           [attr.title]="item!.tooltip ? item!.tooltip : null"
           [ngStyle]="{cursor: 'pointer'}"
           (click)="togglePopover($event)">
            <adapt-render-component *ngIf="item?.linkContent"
                                    [component]="item!.linkContent!.component"
                                    [componentInputs]="item!.linkContent!.params"></adapt-render-component>
        </a>
    </ng-template>
    <!-- Without deferRendering=false the popover, the first display of it goes off screen -->
    <dx-popover *ngIf="item!.popover"
                (onContentReady)="onPopoverContentReady($event)"
                [target]="target"
                [visible]="popoverVisible$ | async"
                (visibleChange)="popoverVisible$.next($event)"
                [deferRendering]="false"
                [position]="popoverLocation">
        <adapt-render-component *dxTemplate="let model of 'content'"
                                [component]="item!.popover!.component"
                                [componentInputs]="item!.popover!.params"
                                (componentRendered)="onPopoverContentRendered($event)"></adapt-render-component>
    </dx-popover>
</ng-container>

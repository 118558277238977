import { DOCUMENT } from "@angular/common";
import { Component, Inject, Input } from "@angular/core";
import { IdentityService } from "@common/identity/identity.service";
import { Observable } from "rxjs";

@Component({
    selector: "adapt-support-application-bar-item",
    templateUrl: "./support-application-bar-item.component.html",
})
export class SupportApplicationBarItemComponent {
    @Input() public canLoginToSupport$!: Observable<boolean>;
    @Input() public supportUrl!: string;

    public isLoading = false;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private identityService: IdentityService,
    ) { }

    public get iconClass() {
        return "fal fa-fw " + (this.isLoading ? "fa-spinner fa-pulse" : "fa-circle-question");
    }

    public async navigateToSupport() {
        this.isLoading = true;

        // wait for spinner
        await new Promise(requestAnimationFrame);

        try {
            const token = await this.identityService.getHelpjuiceToken();
            if (token && token.body) {
                this.openLink(`https://helpjuice.com/jwt/adaptbydesign?jwt=${token.body}`);
            }
        } catch {
            this.openLink(this.supportUrl);
        }

        setTimeout(() => this.isLoading = false, 250);
    }

    private openLink(href: string) {
        const link = this.document.createElement("a");
        link.target = "_blank";
        link.href = href;
        link.click();
        link.remove();
    }
}

import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Item } from "./item";
import { KeyFunction } from "./key-function";
import { Label } from "./label";
import { Objective } from "./objective";
import { ProcessStep } from "./process-step";
import { Role } from "./role";
import { SystemEntity } from "./system-entity";
import { Team } from "./team";

export class LabelLocation extends BaseEntity<LabelLocation> {
    public labelLocationId!: number;
    public labelId!: number;

    // only one of these will be defined
    public itemId?: number;
    public keyFunctionId?: number;
    public teamId?: number;
    public objectiveId?: number;
    public systemId?: number;
    public processStepId?: number;
    public roleId?: number;

    // navigation properties
    public label!: Label;
    public item?: Item;
    public keyFunction?: KeyFunction;
    public team?: Team;
    public objective?: Objective;
    public system?: SystemEntity;
    public processStep?: ProcessStep;
    public role?: Role;
}

export const LabelLocationBreezeModel = new BreezeModelBuilder("LabelLocation", LabelLocation)
    .hasSource()
    .withIdField()
    .isOrganisationEntity()
    .persistChangedEntity()
    .build();

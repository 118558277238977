import { Component, ElementRef } from "@angular/core";
import { MeetingAgendaItem } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { Survey } from "@common/ADAPT.Common.Model/organisation/survey";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IMeetingAgendaItemComponent, MeetingAgendaItemComponent } from "@org-common/lib/meetings/meeting-agenda-component-registry";
import { OrganisationDiagnosticAuthService } from "@org-common/lib/survey/organisation-diagnostic/organisation-diagnostic-auth.service";
import { SurveyService } from "@org-common/lib/survey/survey.service";
import { ISurveyQuestions } from "@org-common/lib/survey/survey-questions.interface";
import { of } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { IOrganisationDiagnosticMeetingCustomData } from "../organisation-diagnostic-meeting-custom-data";

@MeetingAgendaItemComponent("adapt-organisation-diagnostic-workshop-analyse-results")
@Component({
    selector: "adapt-organisation-diagnostic-workshop-analyse-results",
    templateUrl: "./organisation-diagnostic-workshop-analyse-results.component.html",
})
export class OrganisationDiagnosticWorkshopAnalyseResultsComponent extends BaseComponent implements IMeetingAgendaItemComponent {
    public meetingAgendaItem?: MeetingAgendaItem;
    public survey?: Survey;
    public surveyQuestions?: ISurveyQuestions;
    public categoryIdSelected?: number;

    public constructor(
        private surveyService: SurveyService,
        private orgDiagAuthService: OrganisationDiagnosticAuthService,
        elementRef: ElementRef,
    ) {
        super(elementRef);
    }

    public onDataChanged() {
        const meetingCustomData = this.meetingAgendaItem?.meeting?.extensions.getCustomData<IOrganisationDiagnosticMeetingCustomData>();
        if (meetingCustomData?.surveyId) {
            this.isInitialised = false;
            this.orgDiagAuthService.hasReadAccessToSurveys$().pipe(
                switchMap((canRead) => canRead
                    ? this.surveyService.getSurveyById(meetingCustomData.surveyId!)
                    : of(undefined)),
                tap((survey) => {
                    this.survey = survey;
                    this.surveyQuestions = survey ? this.surveyService.getQuestionsForSurvey(survey) : undefined;
                }),
                this.takeUntilDestroyed(),
            ).subscribe(() => this.isInitialised = true);
        }
    }

    public onCategoryIdChanged(categoryId?: number) {
        this.categoryIdSelected = categoryId;
    }
}

import { Component, Input, OnChanges } from "@angular/core";
import { ActiveState, IActiveEntityBase } from "@common/ADAPT.Common.Model/activeEntity.interface";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { interval, merge, Subject } from "rxjs";

@Component({
    selector: "adapt-toolbar-inactive",
    templateUrl: "./toolbar-inactive.component.html",
})
export class ToolbarInactiveComponent extends BaseComponent implements OnChanges {
    public readonly ActiveState = ActiveState;

    @Input() public entity?: IActiveEntityBase;
    @Input() public type?: string;

    // assume entities are active by default, and as its likely that the entity will arrive from a remote data source, it could take some time to arrive.
    // we dont want to indicate to the user that it is inactive, when in fact we dont really know yet.
    public activeState = ActiveState.Active;
    public tooltipText = "";
    public badgeText = "";

    private triggerUpdate$ = new Subject<void>();

    public constructor() {
        super();

        // this will trigger check every second or immediately if the @Input() changes
        merge(
            interval(1000),
            this.triggerUpdate$,
        ).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.checkIsActive());
    }

    public ngOnChanges() {
        // any change to the input will manually trigger it
        this.triggerUpdate$.next();
    }

    private checkIsActive() {
        const currentActiveState = this.entity ? this.entity?.activeState : ActiveState.Active;
        if (currentActiveState !== this.activeState) {
            this.activeState = currentActiveState;

            switch (currentActiveState) {
                case ActiveState.Inactive:
                    this.badgeText = `${this.type} inactive`;
                    this.tooltipText = `This ${this.type} is no longer active in the organisation.`;
                    break;
                case ActiveState.PendingActive:
                    this.badgeText = `${this.type} pending active`;
                    this.tooltipText = `This ${this.type} is pending active in the organisation.`;
                    break;
                default:
                    this.badgeText = "";
                    this.tooltipText = "";
                    break;
            }
        }
    }
}

import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { BehaviorSubject, combineLatest, of, Subject } from "rxjs";
import { switchMap, takeUntil } from "rxjs/operators";
import { AuthorisationService } from "./authorisation.service";

@Directive({
    selector: "[adaptIfAuthorised]",
})
export class AdaptIfAuthorisedDirective implements OnInit, OnDestroy {
    @Input("adaptIfAuthorised") public set accessVerifierId(value: string | undefined) {
        this.accessVerifierId$.next(value);
    }
    @Input("adaptIfAuthorisedEntity") public set entity(value: unknown) {
        this.entity$.next(value);
    }

    @Input("adaptIfAuthorisedElse") public elseTemplate?: TemplateRef<unknown>;

    private accessVerifierId$ = new BehaviorSubject<string | undefined>(undefined);
    private entity$ = new BehaviorSubject<unknown>(undefined);
    private destroyed$ = new Subject<void>();

    public constructor(
        private templateRef: TemplateRef<unknown>,
        private viewContainer: ViewContainerRef,
        private authService: AuthorisationService,
    ) {
    }

    public ngOnInit() {
        combineLatest([this.accessVerifierId$, this.entity$]).pipe(
            switchMap(([accessVerifierId, entity]) => {
                if (!accessVerifierId) {
                    return of(false);
                }

                return this.authService.getHasAccess(accessVerifierId, entity);
            }),
            takeUntil(this.destroyed$),
        ).subscribe((hasAuthorisation) => {
            this.viewContainer.clear();

            if (hasAuthorisation) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else if (this.elseTemplate) {
                this.viewContainer.createEmbeddedView(this.elseTemplate);
            }
        });
    }

    public ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}

import { Component, ElementRef } from "@angular/core";
import { GuidedTourService } from "@common/lib/guided-tour/guided-tour.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { MeetingAgendaItemComponent } from "@org-common/lib/meetings/meeting-agenda-component-registry";
import { ObjectivesAuthService } from "@org-common/lib/objectives/objectives-auth.service";
import { ObjectiveFeatureTour } from "../../../tours/objective-feature-tour";

@MeetingAgendaItemComponent("adapt-objectives-tour-agenda-item")
@Component({
  selector: "adapt-objectives-tour-agenda-item",
  templateUrl: "./objectives-tour-agenda-item.component.html",
})
export class ObjectivesTourAgendaItemComponent extends BaseComponent {
    public readonly EditObjectives = ObjectivesAuthService.EditObjectives;

    public constructor(
        elementRef: ElementRef,
        private guidedTourService: GuidedTourService,
    ) {
        super(elementRef);
    }

    public runTour() {
        this.guidedTourService.run(ObjectiveFeatureTour);
    }
}

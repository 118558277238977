import { Component, Input } from "@angular/core";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { IAdaptLinkObject } from "@common/route/route.service";
import { Observable, ReplaySubject } from "rxjs";
import { switchMap } from "rxjs/operators";
import { MeetingsService } from "../meetings.service";

@Component({
    selector: "adapt-link-team-meeting",
    templateUrl: "./link-team-meeting.component.html",
    styleUrls: ["./link-team-meeting.component.scss"],
})
export class LinkTeamMeetingComponent {
    @Input() public set meeting(value: Meeting) {
        this.meeting$.next(value);
    }
    public meeting$ = new ReplaySubject<Meeting>(1);
    public meetingUrl$: Observable<IAdaptLinkObject>;

    public constructor(meetingsService: MeetingsService) {
        this.meetingUrl$ = this.meeting$.pipe(
            switchMap((meeting) => meetingsService.getTeamMeetingsPageObj(meeting.teamId, meeting.meetingId)),
        );
    }
}

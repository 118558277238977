<div class="d-flex flex-column flex-lg-row">
    <div *ngIf="stepData?.guidanceArticleSlug"
         [adaptCollapsible]="stepData?.guidanceArticleSlug"
         class="d-block guidance-column pe-3 me-lg-4 mb-4 mb-lg-0">
        <adapt-styled-implementation-kit [articleId]="stepData?.guidanceArticleSlug"
                                         contentStyle="embedded"></adapt-styled-implementation-kit>
    </div>
    <div class="d-flex flex-fill justify-content-center">
        <div class="set-bullseye-step-bullseye"
             [ngClass]="{'fullscreen': isFullscreen, 'fit-height': fitHeight}">
            <adapt-bullseye-view class="pb-1"
                                 [quadrants]="quadrants"
                                 (bullseyeStatementChange)="onBullseyeStatementChanged($event)"
                                 [isEditing]="true"></adapt-bullseye-view>
        </div>
    </div>
</div>

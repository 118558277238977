import { Component, Input } from "@angular/core";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";
import { BehaviorSubject, combineLatest, EMPTY, from, Observable, of } from "rxjs";
import { debounceTime, map, switchMap, take } from "rxjs/operators";
import { CommonTeamsService } from "../../teams/common-teams.service";
import { MeetingsService } from "../meetings.service";
import { MeetingsUiService } from "../meetings-ui.service";

@Component({
    selector: "adapt-team-meetings-dashboard-element",
    templateUrl: "./team-meetings-dashboard-element.component.html",
    styleUrls: ["./team-meetings-dashboard-element.component.scss"],
})
export class TeamMeetingsDashboardElementComponent extends BaseComponent {
    public readonly editTeamKanban = CommonTeamsAuthService.EditTeamKanban;

    @Input() public set team(value: Team) {
        this.team$.next(value);
    }
    public team$ = new BehaviorSubject<Team | undefined>(undefined);

    @Input() public set person(value: Person) {
        this.person$.next(value);
    }
    private person$ = new BehaviorSubject<Person | undefined>(undefined);

    private meetingUpdates$ = new BehaviorSubject<void>(undefined);

    public ongoingMeetings$: Observable<Meeting[]>;
    public upcomingMeetings$: Observable<Meeting[]>;
    public recentMeetings$: Observable<Meeting[]>;
    public meetingsUrl$: Observable<string>;
    public showTeamName = true;

    public constructor(
        private meetingsService: MeetingsService,
        private meetingsUiService: MeetingsUiService,
        teamsService: CommonTeamsService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        const allRecentMeetings = combineLatest([this.team$, this.person$, this.meetingUpdates$]).pipe(
            debounceTime(10),
            switchMap(([team, person]) => {
                if (team) {
                    return this.meetingsService.getRecentMeetingsForTeam(team.teamId);
                } else if (person) {
                    return this.meetingsService.getRecentMeetingsForPerson(person.personId);
                } else {
                    return EMPTY;
                }
            }),
        );

        this.ongoingMeetings$ = allRecentMeetings.pipe(
            map((meetings) => meetings.filter((m) => m.extensions.isInProgress)),
        );

        this.upcomingMeetings$ = allRecentMeetings.pipe(
            map((meetings) => meetings.filter((m) => m.extensions.isNotStarted)
                .sort(this.meetingsService.ascendingMeetingsSortFunction)
                .slice(0, 2)),
        );

        this.recentMeetings$ = allRecentMeetings.pipe(
            map((meetings) => meetings.filter((m) => m.extensions.isEnded)
                .sort(this.meetingsService.descendingMeetingsSortFunction)
                .slice(0, 2)),
        );

        this.meetingsUrl$ = this.team$.pipe(
            switchMap((team) => {
                if (team) {
                    return of(team);
                }

                // default to leadership team for link if alto
                return this.isAlto
                    ? from(teamsService.promiseToGetLeadershipTeam())
                    : EMPTY;
            }),
            switchMap((team) => team
                ? this.meetingsService.getTeamMeetingsPage(team.teamId)
                : EMPTY),
        );

        this.team$.pipe(
            emptyIfUndefinedOrNull(),
            take(1),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.showTeamName = false);

        rxjsBreezeService.entityTypeChanged(Meeting).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((meeting) => {
            if (!this.team$.value || (meeting.teamId === this.team$.value.teamId)) {
                this.meetingUpdates$.next();
            }
        });
    }

    @Autobind
    public createNewTeamMeeting(team: Team) {
        return this.meetingsUiService.createMeeting(team.teamId).pipe(
            switchMap((newMeeting) => this.meetingsService.gotoTeamMeetingsPage(newMeeting.teamId, newMeeting.meetingId)),
        );
    }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptImplementationKitModule } from "@common/implementation-kit/implementation-kit.module";
import { AdaptContextSidebarModule } from "@common/shell/context-sidebar/context-sidebar.module";
import { AdaptToolbarModule } from "@common/shell/toolbar/toolbar.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptCollapsibleModule } from "@common/ux/collapsible/collapsible.module";
import { AdaptCollapsibleDashboardElementModule } from "@common/ux/collapsible-dashboard-element/collapsible-dashboard-element.module";
import { AdaptEntityValidatorModule } from "@common/ux/entity-validator/entity-validator.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptMenuModule } from "@common/ux/menu/menu.module";
import { AdaptSeeMoreModule } from "@common/ux/see-more/see-more.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { DxListModule, DxSelectBoxModule, DxTextBoxModule } from "devextreme-angular";
import { AdaptAuthorisationModule } from "../authorisation/authorisation.module";
import { AdaptCallToActionModule } from "../call-to-action/call-to-action.module";
import { AdaptStrategyModule } from "../strategy/strategy.module";
import { AdaptZoneMapModule } from "../zone-map/zone-map.module";
import { AddCompetitorAnalysisInputComponent } from "./add-competitor-analysis-workflow/add-competitor-analysis-input/add-competitor-analysis-input.component";
import { AddCompetitorAnalysisNameDescriptionComponent } from "./add-competitor-analysis-workflow/add-competitor-analysis-name-description/add-competitor-analysis-name-description.component";
import { AttachInputsDialogComponent } from "./attach-inputs-dialog/attach-inputs-dialog.component";
import { CompetitorAnalysisPageComponent, CompetitorAnalysisPageRoute } from "./competitor-analysis-page/competitor-analysis-page.component";
import { EditInputDialogComponent } from "./edit-input-dialog/edit-input-dialog.component";
import { EditInputGroupDialogComponent } from "./edit-input-group-dialog/edit-input-group-dialog.component";
import { InputGroupComponent } from "./input-group/input-group.component";
import { InputTypeComponent } from "./input-type/input-type.component";
import { InputTypesMapComponent } from "./input-types-map/input-types-map.component";
import { SelectCanvasComponent } from "./select-canvas/select-canvas.component";
import { SelectInputComponent } from "./select-input/select-input.component";
import { StrategicInputsPageComponent, StrategicInputsPageRoute } from "./strategic-inputs-page/strategic-inputs-page.component";
import { AdaptStrategicInputsSharedModule } from "./strategic-inputs-shared/strategic-inputs-shared.module";

@NgModule({
    declarations: [
        StrategicInputsPageComponent,
        InputTypesMapComponent,
        InputTypeComponent,
        EditInputDialogComponent,
        SelectCanvasComponent,
        AttachInputsDialogComponent,
        SelectInputComponent,
        CompetitorAnalysisPageComponent,
        EditInputGroupDialogComponent,
        InputGroupComponent,
        AddCompetitorAnalysisNameDescriptionComponent,
        AddCompetitorAnalysisInputComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        DxTextBoxModule,
        DxSelectBoxModule,
        DxListModule,
        AdaptLoadingSpinnerModule,
        AdaptCallToActionModule,
        AdaptButtonModule,
        AdaptCommonDialogModule,
        AdaptHtmlEditorModule,
        AdaptEntityValidatorModule,
        AdaptImplementationKitModule,
        AdaptCollapsibleDashboardElementModule,
        AdaptToolbarModule,
        AdaptTooltipModule,
        AdaptMenuModule,
        AdaptStrategyModule,
        AdaptZoneMapModule,
        AdaptStrategicInputsSharedModule,
        AdaptAuthorisationModule,
        AdaptContextSidebarModule,
        AdaptCollapsibleModule,
        AdaptSeeMoreModule,
    ],
})
export class AdaptStrategicInputsModule {
    public static readonly Routes = [
        ...StrategicInputsPageRoute.routes,
        ...CompetitorAnalysisPageRoute.routes,
    ];
    public readonly workflowStepComponents = [
        AddCompetitorAnalysisNameDescriptionComponent,
        AddCompetitorAnalysisInputComponent,
    ];
}

<form role="form">
    <div class="form-group">
        <label for="name">Contact name</label>
        <dx-text-box id="name"
                     placeholder="Set account contact name"
                     data-test="billing-contact-name"
                     [(value)]="account.contactName">
            <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
            </dx-validator>
        </dx-text-box>
    </div>

    <div class="form-group">
        <label for="email">Contact email address</label>
        <dx-text-box id="email"
                     placeholder="Set account contact email address"
                     [(value)]="account.contactEmail">
            <dx-validator>
                <dxi-validation-rule type="required"></dxi-validation-rule>
                <dxi-validation-rule type="email"></dxi-validation-rule>
                <dxi-validation-rule [adaptEntity]="account"
                                     adaptEntityProperty="contactEmail"></dxi-validation-rule>
            </dx-validator>
        </dx-text-box>
    </div>

    <div class="form-group">
        <label for="address">Postal address</label>
        <dx-text-area id="address"
                      [adaptValidateEntity]="account"
                      adaptValidateEntityProperty="contactAddress"
                      placeholder="Set postal address"
                      [height]="105"
                      [(value)]="account.contactAddress"></dx-text-area>
    </div>
</form>

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DeepDive, DeepDiveType } from "@common/ADAPT.Common.Model/embed/deep-dive";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { Observable, ReplaySubject } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { DeepDiveService } from "../../deep-dive.service";
import { WorkflowScormDialogComponent } from "../workflow-scorm-dialog/workflow-scorm-dialog.component";

@Component({
    selector: "adapt-deep-dive",
    templateUrl: "./deep-dive.component.html",
    styleUrls: ["./deep-dive.component.scss"],
})
export class DeepDiveComponent extends BaseComponent {
    @Input() public set deepDive(value: DeepDive | undefined) {
        this.deepDive$.next(value);
    }
    public deepDive$ = new ReplaySubject<DeepDive | undefined>(1);

    @Input() public set deepDiveId(value: number | undefined) {
        if (value) {
            this.deepDiveId$.next(value);
        }
    }

    @Output() public opened = new EventEmitter<void>();

    public imgStyle$: Observable<string | undefined>;
    public deepDiveType = DeepDiveType;

    private deepDiveId$ = new ReplaySubject<number>(1);

    public constructor(
        deepDiveService: DeepDiveService,
        private dialogService: AdaptCommonDialogService,
    ) {
        super();

        this.deepDiveId$.pipe(
            switchMap((deepDiveId) => deepDiveService.getDeepDiveById(deepDiveId)),
            emptyIfUndefinedOrNull(),
            this.takeUntilDestroyed(),
        ).subscribe(this.deepDive$);

        this.imgStyle$ = this.deepDive$.pipe(
            map((deepDive) => deepDive?.extensions.imageUrl ? `url('${deepDive.extensions.imageUrl}')` : undefined),
        );
    }

    public openDialog(deepDive: DeepDive) {
        this.opened.emit();
        if (deepDive.type === DeepDiveType.Video) {
            this.dialogService.openFullscreenDialog({
                iframe: {
                    src: deepDive.extensions.frameUrl,
                    width: 1920,
                    height: 1080,
                },
            });
        } else {
            this.dialogService.open(WorkflowScormDialogComponent, deepDive).subscribe();
        }
    }
}

<adapt-dialog [maxWidth]="700">
    <h3 adapt-dialog-title>Merging Labels</h3>
    <div adapt-dialog-content
         class="d-flex flex-column">
        <div class="row center-content">
            <div class="col-5 d-flex flex-column">
                <h3>Merge:</h3>
                <adapt-display-label *ngFor="let label of sourceLabels"
                                     class="mt-2"
                                     [label]="label"
                                     [adaptLinkDisabled]="true"></adapt-display-label>
            </div>
            <div class="col-2 text-center mt-2">
                <h3>
                    INTO <i class="fal fa-fw fa-long-arrow-right"></i>
                </h3>
            </div>
            <div class="col-5">
                <dx-select-box [dataSource]="sourceLabels"
                               [searchEnabled]="false"
                               fieldTemplate="field"
                               (valueChange)="onSelectedLabelChanged($event)"
                               data-test="label-merge-select">
                    <div *dxTemplate="let item of 'item'">
                        <adapt-display-label [label]="item"
                                             [adaptLinkDisabled]="true"></adapt-display-label>
                    </div>
                    <div *dxTemplate="let item of 'field'">
                        <div *ngIf="item; else noItem"
                             class="m-2">
                            <dx-text-box class="d-none"></dx-text-box> <!-- Required for field template -->
                            <adapt-display-label [label]="item"
                                                 [adaptLinkDisabled]="true"></adapt-display-label>
                        </div>
                        <ng-template #noItem>
                            <dx-text-box [readOnly]="true"
                                         [disabled]="true"
                                         value="Select a destination label..."></dx-text-box>
                        </ng-template>
                    </div>
                </dx-select-box>
            </div>
        </div>
        <ng-container *ngIf="!!selectedDestination">
            <div class="mt-3"
                 *ngIf="sourceItemCount + sourceKeyFunctionCount + sourceTeamCount + sourceObjectiveCount + sourceSystemCount + sourceProcessStepCount + sourceRoleCount > 0">
                The impact of merging labels into
                <adapt-display-label [label]="selectedDestination"
                                     [adaptLinkDisabled]="true"></adapt-display-label>
                will result in:
                <ul class="mt-2">
                    <li>The {{sourceLabels.length - 1}} source {{sourceLabels.length > 2 ? "labels" : "label"}} to be deleted and removed
                        from the following:
                        <ul>
                            <li *ngIf="sourceItemCount > 0">{{sourceItemCount}} items</li>
                            <li *ngIf="sourceKeyFunctionCount > 0">{{sourceKeyFunctionCount}} key functions</li>
                            <li *ngIf="sourceTeamCount > 0">{{sourceTeamCount}} teams</li>
                            <li *ngIf="sourceObjectiveCount > 0">{{sourceObjectiveCount}} objectives</li>
                            <li *ngIf="sourceSystemCount > 0">{{sourceSystemCount}} systems</li>
                            <li *ngIf="sourceProcessStepCount > 0">{{sourceProcessStepCount}} process steps</li>
                            <li *ngIf="sourceRoleCount > 0">{{sourceRoleCount}} roles</li>
                        </ul>
                    </li>
                    <li>The merged label
                        <adapt-display-label [label]="selectedDestination"
                                             [adaptLinkDisabled]="true"></adapt-display-label>
                        to be added to the above.
                    </li>
                </ul>

            </div>
            <dx-check-box class="mt-3"
                          [(value)]="confirmMerge"
                          text="Check to confirm that you understand that this action cannot be undone"
                          data-test="confirm-checkbox"></dx-check-box>
        </ng-container>
    </div>
    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="!selectedDestination || !confirmMerge"
                                   [saveBlockingClick]="saveAndClose"
                                   [discardConfirmationInstance]="instance">
    </adapt-standard-dialog-buttons>
</adapt-dialog>

import { Inject, Injectable, Optional } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { MANAGE_ACCESS_PAGE, ORGANISATION_CONFIGURATION_PAGE } from "@common/page-route-providers";
import { NavigationNodeBuilder } from "@common/route/navigation-node-builder";
import { NavigationUtilitiesService } from "@common/route/navigation-utilities.service";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { AuthorisationNotificationService } from "@org-common/lib/authorisation/authorisation-notification.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { BaseOrgCommonNavigationHierarchyService } from "@org-common/lib/sidebar/base-org-common-navigation-hierarchy.service";
import { merge } from "rxjs";
import { AuthorisationService } from "../../authorisation/authorisation.service";
import { managePeoplePageRoute } from "../../user-management/manage-people-page/manage-people-page.component";
import { ConfigurationAuthService } from "../configuration-auth.service";

@Injectable()
export class ConfigurationOrganisationHierarchyNavigationService extends BaseOrgCommonNavigationHierarchyService {
    public static readonly HierarchyId = "ADAPT.Configuration.Organisation.Navigation.Hierarchy";
    public static readonly AltoIconClass = "fal fa-fw fa-buildings";
    public static readonly CumulusIconClass = "fal fa-fw fa-cubes";

    public id = ConfigurationOrganisationHierarchyNavigationService.HierarchyId;

    public constructor(
        private navUtilitiesFactory: NavigationUtilitiesService,
        private authorisationService: AuthorisationService,
        orgService: OrganisationService,
        authNotification: AuthorisationNotificationService,
        rxjsBreezeService: RxjsBreezeService,
        @Inject(ORGANISATION_CONFIGURATION_PAGE) private organisationConfigurationPageRoute: IAdaptRoute<{}>,
        @Inject(MANAGE_ACCESS_PAGE) @Optional() private manageAccessPageRoute?: IAdaptRoute<{}>,
    ) {
        super(orgService, authNotification);

        this.rebuildHierarchy$ = merge(
            rxjsBreezeService.entityTypeChangedInSave(Organisation),
        );
    }

    protected async buildHierarchy() {
        const hasOrgConfig = await this.authorisationService.promiseToGetHasAccess(ConfigurationAuthService.ConfigureOrganisation);
        const hasBillingConfig = await this.authorisationService.promiseToGetHasAccess(ConfigurationAuthService.ConfigureOrganisationBilling);
        const hasManageAccess = await this.authorisationService.promiseToGetHasAccess(ConfigurationAuthService.ManageAccess);
        if (!hasOrgConfig && !hasBillingConfig && !hasManageAccess) {
            return new NavigationNodeBuilder().build();
        }

        const orgBuilder = this.navUtilitiesFactory.nodeBuilder()
            .setIconClass(AdaptClientConfiguration.AdaptProject === AdaptProject.Alto
                ? ConfigurationOrganisationHierarchyNavigationService.AltoIconClass
                : ConfigurationOrganisationHierarchyNavigationService.CumulusIconClass)
            .setHideIconInBreadcrumb(true)
            .setTitle("Organisation Configuration")
            .setCustomKeyValue("isHiddenInBreadcrumbs", true)
            .promiseToAddChildController(managePeoplePageRoute.id)
            .promiseToAddChildController(this.organisationConfigurationPageRoute.id);

        if (this.manageAccessPageRoute) {
            orgBuilder.promiseToAddChildController(this.manageAccessPageRoute.id);
        }

        return orgBuilder.promiseToBuild();
    }
}

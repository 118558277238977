import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { EntityExtensions } from "../entity-extensions.decorator";
import { BullseyeExtensions } from "./bullseye-extensions";
import { BullseyeQuadrantStatement } from "./bullseye-quadrant-statement";
import { Organisation } from "./organisation";

export class Bullseye extends BaseEntity<Bullseye> {
    public organisationId!: number;
    public statement?: string;

    // navigation
    public organisation!: Organisation;
    public quadrantStatements!: BullseyeQuadrantStatement[];

    @EntityExtensions(BullseyeExtensions)
    public extensions!: BullseyeExtensions;
}

export const BullseyeBreezeModel = new BreezeModelBuilder("Bullseye", Bullseye)
    .hasSource()
    .isOrganisationEntity()
    .withIdField("organisationId")
    .alwaysFetchingNavigationProperty("quadrantStatements")
    .build();

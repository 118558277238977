import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Team } from "./team";

export class ResilientBusinessSurveyConfiguration extends BaseEntity<ResilientBusinessSurveyConfiguration> {
    public resilientBusinessSurveyConfigurationId!: number;
    public organisationId!: number;
    public leaderTeamId?: number;

    public leaderTeam?: Team;
}

export const ResilientBusinessSurveyConfigurationBreezeModel = new BreezeModelBuilder("ResilientBusinessSurveyConfiguration", ResilientBusinessSurveyConfiguration)
    .hasSource()
    .withIdField()
    .isOrganisationEntity()
    .build();

<ng-container *adaptLoading="!selectedSeed && !seedAnswers">
    <label>Your answer</label>
    <adapt-html-editor [contents]="seedAnswers?.[selectedSeed!.key]"
                       (contentsChange)="onAnswerChange(selectedSeed!.key, $event)"
                       (isValidChange)="validateEntity($event)"
                       [minHeight]="200"
                       forceSize="compact"
                       data-test="define-seed-input"
                       placeholderText="Enter your answers here..."></adapt-html-editor>
</ng-container>

import { Injectable, Injector } from "@angular/core";
import { ValueStreamBreezeModel } from "@common/ADAPT.Common.Model/organisation/value-stream";
import { catchError, EMPTY, from, switchMap } from "rxjs";
import { AuthorisationService } from "../authorisation/authorisation.service";
import { BaseOrganisationService } from "../organisation/base-organisation.service";
import { Tier1ArchitectureAuthService } from "./tier1-architecture-auth.service";

@Injectable({
    providedIn: "root",
})
export class CommonTier1ArchitectureService extends BaseOrganisationService {
    public constructor(
        injector: Injector,
        private authorisationService: AuthorisationService,
    ) {
        super(injector);
    }

    protected organisationInitialisationActions() {
        return [
            this.getActiveValueStreams(),
        ];
    }

    public getActiveValueStreams() {
        return from(this.authorisationService.promiseToVerifyAccess(Tier1ArchitectureAuthService.ReadValueStream)).pipe(
            switchMap(() => this.commonDataService.getActive(ValueStreamBreezeModel)),
            catchError(() => EMPTY),
        );
    }
}

import { Component, Input } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";

@Component({
    selector: "adapt-display-meeting-participants",
    templateUrl: "./display-meeting-participants.component.html",
})
export class DisplayMeetingParticipantsComponent {
    @Input() public participants: Person[] = [];
}

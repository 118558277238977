import { Component, Inject } from "@angular/core";
import { Team, TeamBreezeModel } from "@common/ADAPT.Common.Model/organisation/team";
import { TeamMeetingGuidance } from "@common/ADAPT.Common.Model/organisation/team-meeting-guidance";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";

@Component({
    selector: "adapt-configure-team-stewardship-dialog",
    templateUrl: "./configure-team-stewardship-dialog.component.html",
})
export class ConfigureTeamStewardshipDialogComponent extends BaseDialogWithDiscardConfirmationComponent<Team, TeamMeetingGuidance> {
    public readonly dialogName = "ConfigureTeamStewardshipDialog";

    public title: string;
    public disabled?: boolean;

    public autoResolveData = this.team.teamMeetingGuidance;
    public entitiesToConfirm = [this.team.teamMeetingGuidance];
    public areEditorsValid = true;

    public get instance() {
        return this;
    }

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public team: Team,
    ) {
        super();

        this.title = `Configure ${team.name} ${TeamBreezeModel.singularName} Meeting Guidance`;
    }
}

<div class="badge rounded-pill d-flex align-items-center text-nowrap gap-2"
     [ngClass]="badgeClass"
     *ngIf="metadata">
    <i class="fal fa-fw badge-icon"></i>
    {{metadata!.name}}
    <i *ngIf="showRightChevronDown"
       class="fal fa-chevron-down"></i>
    <i *ngIf="showRightChevronUp"
       class="fal fa-chevron-up"></i>
</div>

import { RawClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { clientConfiguration as stagingClientConfiguration } from "@common/configuration/environment.base.staging";
import { clientConfiguration } from "./environment.base";

export const environment = {
    production: true,
    clientConfiguration: Object.assign({}, clientConfiguration, stagingClientConfiguration, {
        AnalyticsProductId: "9cd651a07eefd92be2e05af262f552e6919987a6",
        ApiBaseUri: "https://staging-hq-api.theadaptway.com",
        GoogleClientId: "799098505879-3p8hsiebaktpehbm2hs568fjs21iqb81.apps.googleusercontent.com",
        SentryDsn: "https://94c020686e854fdb91975bea650c22eb@o96030.ingest.sentry.io/4505033724329984",
        ServiceApiBaseUri: "https://staging-hq-service.theadaptway.com",
        CalendlyBaseUri: "https://calendly.com/adapt-gabe",
    } as Partial<RawClientConfiguration>),
};

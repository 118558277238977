import { Component, Injector } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { setPageActivationSearchParam, setReturnPathSearchParam } from "@common/ux/base-routed.component";
import { MeetingAgendaItemComponent } from "@org-common/lib/meetings/meeting-agenda-component-registry";
import { StrategicAnchorsPageRoute } from "@org-common/lib/strategic-anchors/strategic-anchors-page/strategic-anchors-page.component";
import { StrategyAuthService } from "@org-common/lib/strategy/strategy-auth.service";
import { TourIdentifier } from "../../../tours/tour-identifier.enum";

@MeetingAgendaItemComponent("adapt-strategic-anchors-agenda-item")
@Component({
    selector: "adapt-strategic-anchors-agenda-item",
    templateUrl: "./strategic-anchors-agenda-item.component.html",
})
export class StrategicAnchorsAgendaItemComponent {
    public readonly EditStrategicAnchors = StrategyAuthService.EditStrategicAnchors;

    public constructor(private injector: Injector) { }

    @Autobind
    public gotoAnchorsPage() {
        let searchParams = setReturnPathSearchParam(
            this.injector,
            {},
            StrategicAnchorsPageRoute.id,
            "Return to meeting");
        searchParams = setPageActivationSearchParam(searchParams, StrategicAnchorsPageRoute.id, [TourIdentifier.RecordAnchorsFeatureTour]);

        return StrategicAnchorsPageRoute.gotoRoute(undefined, searchParams);
    }
}

import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { QuadrantCorner } from "@common/ADAPT.Common.Model/organisation/bullseye-extensions";
import { BullseyeQuadrant, BullseyeQuadrantStatement, BullseyeQuadrantText } from "@common/ADAPT.Common.Model/organisation/bullseye-quadrant-statement";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { auditTime, debounceTime, interval, Subject, Subscription, switchMap, takeUntil, tap, timer } from "rxjs";
import { BullseyeService } from "../bullseye.service";

interface IStatementContainerData {
    left: number;
    width: number;
    tooLong?: boolean;
}

const QuadrantCornerClass: { [corner in QuadrantCorner]: string } = {
    TopLeft: "top-left",
    TopRight: "top-right",
    BottomLeft: "bottom-left",
    BottomRight: "bottom-right",
};

const MaxBullseyeStatements = 3;
const MaxQuadrantChars = 48;
const FontHeight = 10; // When drawing text in SVG, it is drawn from the baseline - need to offset this to have concave up and down rendering distanced equally
const TitleOffset = 12; // This is to set the gap from the edge of the outer border
const StartStatementGap = 5;
const ImplementRefSize = 300;
const MinTop = 60;
const TitleGap = 32;
const OffsetFromEdge = 5;
const FontScaleDownFactor = 0.99;
const StartupFontSize = 0.8;

@Component({
    selector: "adapt-quadrant-view",
    templateUrl: "./quadrant-view.component.html",
    styleUrls: ["./quadrant-view.component.scss"],
})
export class QuadrantViewComponent extends BaseComponent implements OnInit, AfterViewInit, AfterViewChecked, OnChanges {
    private static uniqueId = 1;

    @Input() public hideOuter = false;
    @Input() public quadrant = BullseyeQuadrant.PassionateAbout;
    @Input() public corner = QuadrantCorner.TopLeft;
    @Input() public isEditing = false;
    @Input() public isSelecting = false;
    @Input() public set hasBullseyeStatement(value: boolean) {
        if (this.othersHaveBullseyeStatement !== value) {
            this.othersHaveBullseyeStatement = value;
            if (this.isInitialised) {
                this.updateBullseyeClasses();
                this.triggerReset.next();
            }
        }
    }
    @Output() public hasBullseyeStatementChange = new EventEmitter<boolean>();
    @Output() public bullseyeStatementChange = new EventEmitter<BullseyeQuadrantStatement>();

    @Input() public selectedStatements: BullseyeQuadrantStatement[] = [];
    @Output() public statementSelect = new EventEmitter<BullseyeQuadrantStatement>();
    @Output() public statementDeselect = new EventEmitter<BullseyeQuadrantStatement>();

    public quadrantText?: string;

    public statements: BullseyeQuadrantStatement[] = [];
    public inBullseyeStatements: BullseyeQuadrantStatement[] = [];
    public cornerClass = QuadrantCornerClass.TopLeft;
    public bullseyeClasses = this.cornerClass;

    public arcPath?: string;

    public arcId: string;
    public arcIdRef: string;
    public startOffset = "50%";

    private currentId: number = QuadrantViewComponent.uniqueId++;
    private statementInitialised = false;
    private passCount = 0; // need 2 passes to get the right height
    private outerStatementGap = StartStatementGap; // self adjusting gap between statements
    private innerStatementGap = StartStatementGap;
    private outerFontSizeRem = StartupFontSize;
    private innerFontSizeRem = StartupFontSize;
    private triggerReset = new Subject<void>();
    private allStatements: BullseyeQuadrantStatement[] = [];
    private bullseyeRatio = 0.5;
    private othersHaveBullseyeStatement = false;
    private scaledMinTop = MinTop;

    private refreshSub?: Subscription;
    private lastWidth = 0;

    private triggerDataUpdate = new Subject<void>();
    private delayedTriggerRender = new Subject<void>();

    public constructor(
        elementRef: ElementRef,
        private changeDetectorRef: ChangeDetectorRef,
        private bullseyeService: BullseyeService,
        private dialogService: AdaptCommonDialogService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super(elementRef);

        this.currentId = QuadrantViewComponent.uniqueId++;
        this.arcId = `arc${this.currentId}`;
        this.arcIdRef = `#${this.arcId}`;

        this.triggerReset.pipe(
            auditTime(100),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.resetView());

        this.delayedTriggerRender.pipe(
            tap(() => {
                // if triggered - stop previous manual refresh
                this.refreshSub?.unsubscribe();
                this.refreshSub = undefined;
            }),
            auditTime(10), // use this instead of simply setTimeout as the latter will result in multiple calls - this is just once
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            this.triggerRender();
            this.manualRefresh(); // for when pointer not in the browser window
        });

        this.triggerDataUpdate.pipe(
            switchMap(() => this.updateData()),
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            this.isInitialised = true;
            this.triggerReset.next();
        });

        rxjsBreezeService.entityTypeChanged(BullseyeQuadrantStatement).pipe(
            debounceTime(100),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.triggerDataUpdate.next());
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.hideOuter) {
            if (this.isInitialised) {
                this.updateBullseyeClasses();
                this.triggerReset.next();
            }
        }
    }

    public ngOnInit() {
        this.triggerDataUpdate.next();
        this.cornerClass = QuadrantCornerClass[this.corner];
        this.updateBullseyeClasses();
    }

    public ngAfterViewInit() {
        const element = this.elementRef!.nativeElement as HTMLElement;
        const topOffset = FontHeight + TitleOffset;
        let arcPath = "";
        switch (this.corner) {
            case QuadrantCorner.TopLeft:
                arcPath = `M${topOffset},${element.offsetHeight - topOffset} A${element.offsetWidth - topOffset},${element.offsetHeight - topOffset} 0 0,1 ${element.offsetWidth - topOffset},${topOffset}`;
                break;
            case QuadrantCorner.TopRight:
                arcPath = `M${topOffset},${topOffset} A${element.offsetWidth - topOffset},${element.offsetHeight - topOffset} 0 0,1 ${element.offsetWidth - topOffset},${element.offsetHeight - topOffset}`;
                break;
            case QuadrantCorner.BottomRight:
                arcPath = `M${TitleOffset},${element.offsetWidth - TitleOffset} A${element.offsetWidth - TitleOffset},${element.offsetHeight - TitleOffset} 0 0,0 ${element.offsetHeight - TitleOffset},${TitleOffset}`;
                break;
            case QuadrantCorner.BottomLeft:
                arcPath = `M${TitleOffset},${TitleOffset} A${element.offsetWidth - TitleOffset},${element.offsetHeight - TitleOffset} 0 0,0 ${element.offsetWidth - TitleOffset},${element.offsetHeight - TitleOffset}`;
                break;
            default:
                throw new Error("Unexpected corner definition: " + this.corner);
        }

        // when implemented this, it was tested on a quadrant size of around 300px (ImplementRefSize), scale here for deployment with other size
        this.scaledMinTop = MinTop * element.offsetHeight / ImplementRefSize;
        // cannot update arcPath here as the 'check' has already been performed
        // - will get ExpressionChangedAfterItHasBeenCheckedError
        // - do it next cycle
        setTimeout(() => this.arcPath = arcPath);
        this.statementInitialised = false;

        const outerContainer = element.querySelector(".text-container-outer") as HTMLElement;
        if (outerContainer) {
            outerContainer.style.fontSize = `${this.outerFontSizeRem}rem`;
        }

        const innerContainer = element.querySelector(".text-container-inner") as HTMLElement;
        if (innerContainer) {
            innerContainer.style.fontSize = `${this.innerFontSizeRem}rem`;
        }

        this.lastWidth = element.offsetWidth;
    }

    public ngAfterViewChecked() {
        const element = this.elementRef!.nativeElement as HTMLElement;
        // statements not rendered in AfterViewInit or AfterContentInit -> will have to check it here and flag it not to check after initialisation
        if (!this.statementInitialised && element.offsetHeight > 0 && element.offsetWidth > 0) {
            // need the element to have some height and width to render something into it (they will be 0 if removed from view)
            const outerStatements = element.querySelectorAll(".statement-text");
            const bullseyeStatements = element.querySelectorAll(".statement-text-in-bullseye");
            if ((outerStatements.length + bullseyeStatements.length) > 0) {
                if (++this.passCount > 2) {
                    this.statementInitialised = true;
                }
            }

            this.renderQuadrantStatements(element, outerStatements, false);
            this.renderQuadrantStatements(element, bullseyeStatements, true);
        } else {
            if (this.refreshSub) {
                // if statement initialised -> view stabilizes -> stop manual refresh
                this.refreshSub.unsubscribe();
                this.refreshSub = undefined;
            }

            // this is to handle component element size changes causing by resize of adjacent elements within the same container
            // e.g. implementation kit loaded article and pushed the bullseye view to the right.
            // This is need to redraw arc texts and rerender the whole lot.
            if (element.offsetWidth !== this.lastWidth) {
                this.lastWidth = element.offsetWidth;
                this.triggerReset.next();
                return;
            }
        }
    }

    @HostListener("window:resize")
    public onWindowResize() {
        this.triggerReset.next();
    }

    public onStatementClicked(statement: BullseyeQuadrantStatement) {
        if (this.isEditing) {
            if (!statement.inBullseye && this.inBullseyeStatements.length >= MaxBullseyeStatements) {
                this.dialogService.showMessageDialog(
                    "Too many statements in bullseye",
                    `There are currently ${this.inBullseyeStatements.length} statements positioned within the bullseye of this quadrant.
                    Managing an excessive number of elements concurrently can be challenging.
                    To maintain optimal focus, kindly remove a statement from the bullseye of this quadrant before adding more.`,
                ).pipe(
                    this.takeUntilDestroyed(),
                ).subscribe();
            } else {
                statement.inBullseye = !statement.inBullseye;
                this.groupStatements();

                this.triggerReset.next();
                this.bullseyeStatementChange.emit(statement);
            }
        } else if (this.isSelecting) {
            if (this.selectedStatements.includes(statement)) {
                ArrayUtilities.removeElementFromArray(statement, this.selectedStatements);
                this.statementDeselect.emit(statement);
            } else {
                this.selectedStatements.push(statement);
                this.statementSelect.emit(statement);
            }
        }
    }

    public isStatementSelected(statement: BullseyeQuadrantStatement) {
        return this.selectedStatements.includes(statement);
    }

    private get isTopQuadrant() {
        return (this.corner === QuadrantCorner.TopLeft || this.corner === QuadrantCorner.TopRight);
    }

    private updateData() {
        return this.bullseyeService.getStatementsForQuadrant(this.quadrant).pipe(
            tap((statements) => {
                this.allStatements = statements;
                this.groupStatements();
            }),
            this.takeUntilDestroyed(),
        );
    }

    private groupStatements() {
        this.statements = this.allStatements.filter((s) => !s.inBullseye);
        this.inBullseyeStatements = this.allStatements.filter((s) => !!s.inBullseye);
        this.hasBullseyeStatementChange.emit(this.inBullseyeStatements.length > 1);
    }

    private updateBullseyeClasses() {
        this.bullseyeClasses = this.cornerClass;
        if (this.hideOuter) {
            this.bullseyeClasses += " hide-outer";
            this.bullseyeRatio = 0.95;
        } else if (this.othersHaveBullseyeStatement) {
            this.bullseyeClasses += " bullseye-has-content";
            this.bullseyeRatio = 0.65;
        } else {
            this.bullseyeRatio = 0.5;
        }
    }

    private resetView() {
        this.outerStatementGap = StartStatementGap; // self adjusting gap between statements
        this.innerStatementGap = StartStatementGap;
        this.outerFontSizeRem = StartupFontSize;
        this.innerFontSizeRem = StartupFontSize;
        this.ngAfterViewInit(); // re-plot the arc text
        this.triggerRender(); // just resetting statement initialisation flag to allow statement rendering in AfterViewChecked
        this.manualRefresh();

        const element = this.elementRef!.nativeElement as HTMLElement;
        const maxCharsFit = MaxQuadrantChars * element.offsetHeight / ImplementRefSize;
        this.quadrantText = BullseyeQuadrantText[this.quadrant];
        if (this.quadrantText.length > maxCharsFit) {
            this.startOffset = "0%";
        } else {
            const offsetPercentage = (maxCharsFit - this.quadrantText.length) * 100 / maxCharsFit / 2;
            this.startOffset = `${offsetPercentage}%`;
        }
    }

    // this is needed for cases where the mouse pointer is not within the browser
    // - AfterViewChecked or OnChecked will only be called when moving pointer within the view
    // - Need this so that the positioning of the statements is still happening even without pointer movement
    private manualRefresh() {
        this.refreshSub?.unsubscribe();
        this.refreshSub = interval(20).pipe(
            takeUntil(timer(2000)), // 2 seconds is enough to render all - it will be unsubscribed if statements are initialised from ngAfterViewChecked
            this.takeUntilDestroyed(),
        ).subscribe(() => this.changeDetectorRef.detectChanges()); // do this instead of calling ngAfterViewChecked directly to avoid overlapping from ng hooks
    }

    private triggerRender() {
        this.statementInitialised = false;
        this.passCount = 0; // goes 2 passes to detect no changes
    }

    private renderQuadrantStatements(element: HTMLElement, statementElements: NodeListOf<Element>, isInBullseye: boolean) {
        if (statementElements.length > 0) {
            let hasTooLong = false;
            let hasOverflowingText = false;
            // this is for $spacer 16 ($spacer-double)
            const circleRadius = element.offsetWidth - TitleGap;
            const bullseyeRadius = circleRadius * this.bullseyeRatio;
            let top = this.isTopQuadrant
                ? (isInBullseye ? circleRadius - bullseyeRadius + this.scaledMinTop : this.scaledMinTop)
                : 2 * OffsetFromEdge; // bottom 2 quadrants starts from 0
            if (this.hideOuter) {
                top = this.isTopQuadrant
                    ? circleRadius - bullseyeRadius + bullseyeRadius / 2
                    : 2 * OffsetFromEdge; // bottom 2 quadrants starts from 0
            }

            if (statementElements.length < 2) {
                // this is for single statement - move it towards the center a bit - don't need single statement to stick to the circle edge
                if (this.hideOuter) {
                    top = this.isTopQuadrant
                        ? circleRadius - bullseyeRadius + (bullseyeRadius / 2)
                        : bullseyeRadius / 2;
                } else {
                    top += this.isTopQuadrant
                        ? this.scaledMinTop / 3
                        : this.scaledMinTop / 2; // move further down for bottom quadrant (start from flat edge with widest rendering estate - will appear too much to the top otherwise)
                }
            }

            const statementGap = isInBullseye ? this.innerStatementGap : this.outerStatementGap;
            statementElements.forEach((e: HTMLElement) => {
                const translatedTop = this.isTopQuadrant
                    ? top - TitleGap // deduct the space for quadrant arced text on top quadrant
                    : top; // mapped to geo coordinate
                const containerData = isInBullseye
                    ? this.getBullseyeStatementContainerData(top, bullseyeRadius, circleRadius, e.offsetHeight)
                    : this.getStatementContainerData(translatedTop, circleRadius, bullseyeRadius, e.offsetHeight);
                e.style.top = `${top}px`;

                if (containerData.tooLong) {
                    hasTooLong = true;
                }

                e.style.visibility = "visible";
                e.style.left = `${containerData.left}px`;
                e.style.width = `${containerData.width - OffsetFromEdge}px`;
                if (e.scrollWidth > (e.clientWidth + 1)) {
                    // this is to detect content of the element has overflow, +1 to allow overflowing a bit with the existing padding
                    hasOverflowingText = true;
                }

                top += e.offsetHeight + statementGap;
            });

            const bottomGap = isInBullseye && !this.isTopQuadrant
                ? bullseyeRadius - top
                : element.offsetHeight - top - TitleGap;
            if (statementElements.length > 1) {
                // only need to increase statement gap if there are more than 1 statement - otherwise no gap!
                let minBottomGap = this.isTopQuadrant
                    ? StartStatementGap // top quadrants - go as closed to bottom as possible
                    : (isInBullseye ? this.scaledMinTop : this.scaledMinTop / 2); // bottom quadrants - narrow bottom -> leave MinTop - smaller width for bullseye -> more gap at the bottom
                if (this.hideOuter) { // only bullseye is shown - want more gap at the bottom
                    minBottomGap += this.scaledMinTop;
                }

                if (bottomGap > minBottomGap) {
                    const additionalGap = Math.floor((bottomGap - minBottomGap) / (statementElements.length - 1));
                    if (additionalGap > 0) {
                        if (isInBullseye) {
                            this.innerStatementGap += additionalGap;
                        } else {
                            this.outerStatementGap += additionalGap;
                        }

                        this.triggerRender();
                        return;
                    }
                }
            }

            if (bottomGap < 0 && -bottomGap > statementGap || hasTooLong || hasOverflowingText) {
                // this is when the text can't fit into the container -> reduce font size
                let currentFontSize = 0;
                if (isInBullseye) {
                    const container = element.querySelector(".text-container-inner") as HTMLElement;
                    this.innerFontSizeRem *= FontScaleDownFactor;
                    currentFontSize = this.innerFontSizeRem;
                    container.style.fontSize = `${this.innerFontSizeRem}rem`;
                } else {
                    const container = element.querySelector(".text-container-outer") as HTMLElement;
                    this.outerFontSizeRem *= FontScaleDownFactor;
                    currentFontSize = this.outerFontSizeRem;
                    container.style.fontSize = `${this.outerFontSizeRem}rem`;
                }

                if (currentFontSize > 0.6) {
                    // only scale down if current font size is above the threshold
                    this.delayedTriggerRender.next();
                }
            }
        }
    }

    private getBullseyeStatementContainerData(top: number, circleRadius: number, quadrantRadius: number, height: number) {
        // translate back to normal geometry coordinate - origin at the center of the circle
        const y = this.isTopQuadrant
            ? TitleGap + quadrantRadius - top
            : -top;
        const bottomY = y - height;
        const minWidth = circleRadius / 2;
        if (this.corner === QuadrantCorner.TopLeft) {
            let x = Math.sqrt(Math.pow(circleRadius, 2) - Math.pow(y, 2));
            let tooLong = false;
            if (isNaN(x) || x < minWidth) {
                x = minWidth;
                tooLong = true;
            }
            return { left: TitleGap + quadrantRadius - x + OffsetFromEdge, width: x - OffsetFromEdge, tooLong } as IStatementContainerData;
        } else if (this.corner === QuadrantCorner.TopRight) {
            let width = Math.sqrt(Math.pow(circleRadius, 2) - Math.pow(y, 2)) - 2 * OffsetFromEdge; // gap on both left and right
            let tooLong = false;
            if (isNaN(width) || width < minWidth) {
                width = minWidth;
                tooLong = true;
            }
            return { left: OffsetFromEdge, width, tooLong } as IStatementContainerData;
        } else if (this.corner === QuadrantCorner.BottomRight) {
            let width = Math.sqrt(Math.pow(circleRadius, 2) - Math.pow(bottomY, 2));
            let tooLong = false;
            if (isNaN(width) || width < minWidth) {
                width = minWidth;
                tooLong = true;
            }

            return { left: OffsetFromEdge, width: width - OffsetFromEdge, tooLong } as IStatementContainerData;
        } else if (this.corner === QuadrantCorner.BottomLeft) {
            let x = Math.sqrt(Math.pow(circleRadius, 2) - Math.pow(bottomY, 2));
            let tooLong = false;
            if (isNaN(x) || x < minWidth) {
                x = minWidth;
                tooLong = true;
            }

            return { left: TitleGap + quadrantRadius - x + OffsetFromEdge, width: x - OffsetFromEdge, tooLong } as IStatementContainerData;
        } else {
            throw new Error(`Unexpected quadrant: ${this.corner}`);
        }
    }

    private getStatementContainerData(top: number, circleRadius: number, bullseyeRadius: number, height: number) {
        // translate back to normal geometry coordinate
        const y = this.isTopQuadrant
            ? circleRadius - top
            : -top;
        const bottomY = y - height;
        let minWidth = this.isTopQuadrant && bottomY > bullseyeRadius
            ? Math.sqrt(Math.pow(circleRadius, 2) - Math.pow(y, 2)) - OffsetFromEdge
            : circleRadius - bullseyeRadius;
        if (this.isTopQuadrant && top > circleRadius / 2 || !this.isTopQuadrant && top < circleRadius / 2) {
            // this is more towards the middle line of the circle vertically
            // - with a rectangle draw there, max will be circleRadius - bullseyeRadius if there is no height
            // - with height, allowing about 6o% of it
            minWidth *= 0.6;
        }
        // each quadrant will have a different equation to calculate the enclosure area!
        if (this.corner === QuadrantCorner.TopLeft) {
            const x = Math.sqrt(Math.pow(circleRadius, 2) - Math.pow(y, 2));
            const xend = (bottomY > bullseyeRadius)
                ? 0
                : Math.sqrt(Math.pow(bullseyeRadius, 2) - Math.pow(bottomY, 2)); // only reach the edge of the inner circle
            const adjustment = limitWidth(x - xend - OffsetFromEdge);
            return { left: OffsetFromEdge + TitleGap + circleRadius - x - adjustment.leftOffset, width: adjustment.width, tooLong: adjustment.tooLong } as IStatementContainerData;
        } else if (this.corner === QuadrantCorner.TopRight) {
            const x = bottomY > bullseyeRadius
                ? 0
                : Math.sqrt(Math.pow(bullseyeRadius, 2) - Math.pow(bottomY, 2)); // start from the edge of the inner circle
            const xend = Math.sqrt(Math.pow(circleRadius, 2) - Math.pow(y, 2)); // and reach the outer circle
            const adjustment = limitWidth(xend - x - OffsetFromEdge);
            const left = x >= adjustment.leftOffset ? OffsetFromEdge + x - adjustment.leftOffset : OffsetFromEdge;
            return { left, width: adjustment.width, tooLong: adjustment.tooLong } as IStatementContainerData;
        } else if (this.corner === QuadrantCorner.BottomRight) {
            const x = y < -bullseyeRadius
                ? 0
                : Math.sqrt(Math.pow(bullseyeRadius, 2) - Math.pow(y, 2));
            const xend = Math.sqrt(Math.pow(circleRadius, 2) - Math.pow(bottomY, 2));
            const adjustment = limitWidth(xend - x - OffsetFromEdge);
            const left = x >= adjustment.leftOffset ? OffsetFromEdge + x - adjustment.leftOffset : OffsetFromEdge;
            return { left, width: adjustment.width, tooLong: adjustment.tooLong } as IStatementContainerData;
        } else if (this.corner === QuadrantCorner.BottomLeft) {
            const x = Math.sqrt(Math.pow(circleRadius, 2) - Math.pow(bottomY, 2));
            const xend = (y > -bullseyeRadius)
                ? Math.sqrt(Math.pow(bullseyeRadius, 2) - Math.pow(y, 2))
                : 0;
            const adjustment = limitWidth(x - xend - OffsetFromEdge);
            return { left: OffsetFromEdge + TitleGap + circleRadius - x - adjustment.leftOffset, width: adjustment.width, tooLong: adjustment.tooLong } as IStatementContainerData;
        } else {
            throw new Error(`Unexpected quadrant: ${this.corner}`);
        }

        function limitWidth(width: number) {
            const tooLong = width < minWidth || undefined;
            const leftOffset = tooLong ? minWidth - width : 0;
            return {
                width: tooLong ? minWidth + leftOffset : width,
                tooLong,
                leftOffset,
            };
        }
    }
}

<adapt-feature-guidance [configItem]="configItem">
    <p>Set the basic information about your organisation.</p>
</adapt-feature-guidance>

<div class="row"
     *ngIf="organisation">
    <div class="col-sm-8 col-md-4">
        <div class="form-group">
            <label for="name">Name</label>
            <dx-text-box id="name"
                         placeholder="Type your organisation name here"
                         [adaptValidateEntity]="organisation"
                         adaptValidateEntityProperty="name"
                         [(value)]="organisation!.name"
                         data-test="organisation-name-input"></dx-text-box>
        </div>

        <div class="form-group">
            <label for="site">Web site</label>
            <dx-text-box id="site"
                         placeholder="Type your organisation's website address here"
                         [adaptValidateEntity]="organisation"
                         adaptValidateEntityProperty="website"
                         [(value)]="organisation!.website">
            </dx-text-box>
        </div>

        <div class="form-group">
            <label for="logo">Logo</label>
            <adapt-select-image id="logo"
                                [imageIdentifier]="organisation!.imageIdentifier"
                                (imageIdentifierChange)="onImageIdentifierChange($event)"
                                [defaultImageSrc]="defaultImageSrcOrg"
                                imageHeight="120px"
                                imageWidth="100%"
                                [resizeHeight]="120"></adapt-select-image>
        </div>
    </div>
</div>

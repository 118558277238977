<div adaptBlockingButtonGroup>
    <button *ngIf="useBlockingClick; else standardButtonClickTemplate"
            [adaptButton]="saveButtonType"
            [disabled]="saveIsDisabled"
            [adaptBlockingClick]="onBlockingSaveClick"
            data-tour="standard-dialog-save-button"
            data-test="standard-dialog-save-button"></button>
    <ng-template #standardButtonClickTemplate>
        <button [adaptButton]="saveButtonType"
                [disabled]="saveIsDisabled"
                (click)="saveClick.emit()"
                data-tour="standard-dialog-save-button"
                data-test="standard-dialog-save-button"></button>
    </ng-template>
    <button *ngIf="!hideCancelButton"
            #cancelButton
            [adaptButton]="cancelButtonType"
            (click)="onCancelClick()"
            data-tour="standard-dialog-cancel-button"
            data-test="standard-dialog-cancel-button"></button>
</div>

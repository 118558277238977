import { Component, Inject, Injector } from "@angular/core";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { DisableEntityPersistent } from "@common/lib/data/persistable-dialog.decorator";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { IObjectiveGroup } from "../objectives.service";

export interface IReorderObjectivesDialogData {
    objectiveGroups: IObjectiveGroup[],
    teamId?: number,
}

@DisableEntityPersistent()
@Component({
    selector: "adapt-reorder-objectives-dialog",
    templateUrl: "./reorder-objectives-dialog.component.html",
})
export class ReorderObjectivesDialogComponent extends BaseDialogWithDiscardConfirmationComponent<IReorderObjectivesDialogData, Objective[]> {
    public readonly dialogName = "ReorderObjectivesDialog";

    public entitiesToConfirm: Objective[] = [];

    public constructor(
        injector: Injector,
        @Inject(ADAPT_DIALOG_DATA) public data: IReorderObjectivesDialogData,
    ) {
        super(injector);
    }

    public onObjectivesChanged(objectives: Objective[]) {
        ArrayUtilities.addElementIfNotAlreadyExists(this.entitiesToConfirm, ...objectives);
        this.autoResolveData = this.entitiesToConfirm;
    }
}

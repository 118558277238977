import { Injectable, Injector } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { IdentityStorageService } from "@common/identity/identity-storage.service";
import { SentrySeverity } from "@common/lib/logger/sentry-log-provider";
import { IUserEventHandler } from "@common/user/user-event-handler.interface";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { IDialogEventHandler } from "@common/ux/adapt-common-dialog/dialog-event-handler.interface";
import * as Sentry from "@sentry/angular";

@Injectable({
    providedIn: "root",
})
export class SentryTracker implements IUserEventHandler, IDialogEventHandler {
    public constructor(
        private injector: Injector,
    ) { }

    public userChanged(person?: Person) {
        if (person && person.personId) {
            const metaData: Sentry.User = {
                id: String(person.personId),
                name: person.fullName,
                email: this.identityStorageService.userName,
            };

            Sentry.setUser(metaData);
        } else {
            Sentry.setUser(null);
        }
    }

    public dialogOpened(dialog: BaseDialogComponent<unknown>, data: unknown) {
        Sentry.addBreadcrumb({
            category: "dialog",
            level: SentrySeverity.Info,
            message: `Dialog opened: ${dialog.dialogName}`,
            data: {
                dialogData: data,
            },
        });
    }

    public dialogResolved(dialog: BaseDialogComponent<unknown>, resolveData?: unknown) {
        Sentry.addBreadcrumb({
            category: "dialog",
            level: SentrySeverity.Info,
            message: `Dialog resolved: ${dialog.dialogName}`,
            data: {
                resolveData,
            },
        });
    }

    public dialogCancelled(dialog: BaseDialogComponent<unknown>) {
        Sentry.addBreadcrumb({
            category: "dialog",
            level: SentrySeverity.Info,
            message: `Dialog cancelled: ${dialog.dialogName}`,
        });
    }

    private get identityStorageService() {
        // This will throw an error if we try to inject in the constructor
        // Should be able to put it up there once the service is converted
        // to angular
        return this.injector.get(IdentityStorageService);
    }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptToolbarModule } from "@common/shell/toolbar/toolbar.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptStyledDashboardElementModule } from "@common/ux/styled-dashboard-element/styled-dashboard-element.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptTwoColumnModule } from "@common/ux/two-column/two-column.module";
import { AdaptAuthorisationModule } from "@org-common/lib/authorisation/authorisation.module";
import { AdaptGuidingPhilosophyModule } from "@org-common/lib/guiding-philosophy/guiding-philosophy.module";
import { AdaptKanbanModule } from "@org-common/lib/kanban/kanban.module";
import { AdaptCommonMeetingsModule } from "@org-common/lib/meetings/common-meetings.module";
import { AdaptObjectivesModule } from "@org-common/lib/objectives/objectives.module";
import { AdaptCommonTeamsModule } from "@org-common/lib/teams/common-teams.module";
import { AdaptValuesConstitutionModule } from "@org-common/lib/values-constitution/values-constitution.module";
import { AdaptWorkflowModule } from "@org-common/lib/workflow/workflow.module";
import { TeamDashboardPageComponent } from "./team-dashboard-page/team-dashboard-page.component";
import { teamDashboardPageRoute } from "./team-dashboard-page/team-dashboard-page.route";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,

        AdaptAuthorisationModule,
        AdaptCommonMeetingsModule,
        AdaptCommonTeamsModule,
        AdaptStyledDashboardElementModule,
        AdaptGuidingPhilosophyModule,
        AdaptKanbanModule,
        AdaptLoadingSpinnerModule,
        AdaptObjectivesModule,
        AdaptButtonModule,
        AdaptToolbarModule,
        AdaptTooltipModule,
        AdaptTwoColumnModule,
        AdaptValuesConstitutionModule,
        AdaptWorkflowModule,
    ],
    declarations: [
        TeamDashboardPageComponent,
    ],
    exports: [
        TeamDashboardPageComponent,
    ],
})
export class AdaptAltoTeamModule {
    public static readonly Routes = [
        ...teamDashboardPageRoute.routes,
    ];
}

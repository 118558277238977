import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Zone } from "../methodology/zone";
import { Organisation } from "./organisation";

export class Theme extends BaseEntity<Theme> {
    public themeId!: number;
    public organisationId!: number;
    public name!: string;
    public zone!: Zone;
    public ordinal!: number;
    public description?: string;

    // navigation properties
    public organisation!: Organisation;
}

export const ThemeBreezeModel = new BreezeModelBuilder("Theme", Theme)
    .hasSource()
    .isOrganisationEntity()
    .withIdField()
    .build();

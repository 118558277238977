<ng-container *ngIf="dataSource; else awaitingCanvas">
    <dx-select-box class="h-100"
                   [placeholder]="placeholderText"
                   [width]="420"
                   [items]="dataSource"
                   displayExpr="name"
                   [value]="input"
                   (valueChange)="onInputChanged($event)"
                   [grouped]="true">
        <div *dxTemplate="let item of 'item'"
             class="d-flex">
            {{item.name}}
            <span *ngIf="item.inputGroup"
                  class="ms-auto">
                <b>{{item.inputGroup.name}}</b>
            </span>
        </div>
    </dx-select-box>
</ng-container>
<ng-template #awaitingCanvas>
    <div class="alert alert-light">Select a canvas first</div>
</ng-template>

import { ModuleWithProviders, NgModule } from "@angular/core";
import { SentryTracker } from "@common/track/sentry-tracker.service";
import { provideUserEventHandler } from "@common/user/user-event-handler.interface";
import { provideDialogEventHandler } from "@common/ux/adapt-common-dialog/dialog-event-handler.interface";
import { provideOrganisationEventHandler } from "@org-common/lib/organisation/organisation-event-handler.interface";
import { IntercomTracker } from "./intercom-tracker.service";
import { SmartLookTracker } from "./smart-look-tracker.service";

@NgModule()
export class AdaptEventTrackingModule {
    constructor(
        intercomTracker: IntercomTracker,
    ) {
        intercomTracker.setAppId();
    }

    public static forRoot(): ModuleWithProviders<AdaptEventTrackingModule> {
        return {
            ngModule: AdaptEventTrackingModule,
            providers: [
                provideUserEventHandler(IntercomTracker),
                provideOrganisationEventHandler(IntercomTracker),

                provideUserEventHandler(SentryTracker),
                provideDialogEventHandler(SentryTracker),

                provideUserEventHandler(SmartLookTracker),
                provideDialogEventHandler(SmartLookTracker),
                provideOrganisationEventHandler(SmartLookTracker),
            ],
        };
    }
}

import { Component, Input, OnChanges } from "@angular/core";
import { EventSeries } from "@common/ADAPT.Common.Model/organisation/event-series";
import { EventTypePreset } from "@common/ADAPT.Common.Model/organisation/event-type";
import { ISection } from "@common/ux/time-scheduler/time-scheduler.interface";
import { EventSeriesDefaults } from "../event-series-defaults";
import { ScheduleUiService } from "../schedule-ui.service";

export type IReviewRecurrencesMap = Map<EventTypePreset, EventSeries>;

interface IRecurrenceDisplay {
    section: ISection;
    scheduleText?: string;
    hasMeetings: boolean;
    completed: boolean;
}

@Component({
    selector: "adapt-review-recurrences",
    templateUrl: "./review-recurrences.component.html",
    styleUrls: ["./review-recurrences.component.scss"],
})
export class ReviewRecurrencesComponent implements OnChanges {
    @Input() public scheduledRecurrences!: IReviewRecurrencesMap;

    public recurrences: IRecurrenceDisplay[] = [];

    public ngOnChanges() {
        this.recurrences = Array.from(this.scheduledRecurrences.entries())
            .map(([eventTypePreset, eventSeries]) => ({
                section: ScheduleUiService.SchedulerSectionFromEventType(eventSeries.eventType),
                scheduleText: ScheduleUiService.RecurrenceConfigToString({
                    eventSeries,
                    config: EventSeriesDefaults[eventTypePreset],
                }),
                hasMeetings: eventSeries.meetings.length > 0,
                completed: eventSeries.extensions.isCompleted,
            }));
    }
}

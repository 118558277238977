import { BaseEntity } from "@common/ADAPT.Common.Model/base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { MeetingAgendaItem } from "./meeting-agenda-item";

export class MeetingAgendaItemSupplementaryData extends BaseEntity<MeetingAgendaItemSupplementaryData> {
    public meetingAgendaItemId!: number;
    public itemDescription!: string;

    public meetingAgendaItem!: MeetingAgendaItem;
}

export const MeetingAgendaItemSupplementaryDataBreezeModel = new BreezeModelBuilder("MeetingAgendaItemSupplementaryData", MeetingAgendaItemSupplementaryData)
    .hasSource()
    .withPluralName("MeetingAgendaItemSupplementaryData")
    .isOrganisationEntity()
    .persistChangedEntity()
    .build();

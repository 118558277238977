import { getMethodTransformer } from "@common/lib/autobind.decorator/get-method-transformer";
import { Observable } from "rxjs";
import { BaseOrganisationService } from "./base-organisation.service";

/**
 * For observable variation, this function will need to be subscribed to or the function will not be executed
 */
export const AfterOrganisationInitialisationObservable: <T extends BaseOrganisationService>(target: T, key: PropertyKey, descriptor: PropertyDescriptor) => PropertyDescriptor
    = getMethodTransformer(<T>(fn: (...args: any) => Observable<any>, thisValue: T) => {
        // Don't want to make wrapAfterOrganisationInitialisation public as that's only usable by inheritance of BaseOrganisationService
        // - have to live with this ignore
        // @ts-ignore protected
        return (thisValue as unknown as BaseOrganisationService).wrapAfterOrganisationInitialisation(() => fn.bind(thisValue));
    });

/**
 * For non-observable one, there is no need to subscribe and the function can be anything - doesn't even need to return a promise
 */
export const AfterOrganisationInitialisation: <T extends BaseOrganisationService>(target: T, key: PropertyKey, descriptor: PropertyDescriptor) => PropertyDescriptor
    = getMethodTransformer(<T>(fn: (...args: any) => any, thisValue: T) => {
        // @ts-ignore protected
        return (thisValue as unknown as BaseOrganisationService).promiseAfterOrganisationInitialisation(fn.bind(thisValue));
    });

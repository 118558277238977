<ng-container *adaptLoading="!isInitialised">
    <div class="d-flex flex-column"
         *ngIf="survey && surveyQuestions; else surveyNotFound">
        <h4>Health Check Summary</h4>
        <div class="mb-4">
            <adapt-see-more-popup [minWidth]="500"
                                  popupTitle="Health Check Summary"
                                  buttonText="View health check summary">
                <ng-template>
                    <adapt-display-categories-summary [survey]="survey"
                                                      [surveyQuestions]="surveyQuestions"
                                                      [selectedCategoryId]="categoryIdSelected"
                                                      (categoryIdClicked)="onCategoryIdChanged($event)"></adapt-display-categories-summary>
                    <adapt-category-summary [survey]="survey"
                                            [surveyQuestions]="surveyQuestions"
                                            [categoryId]="categoryIdSelected"
                                            (categoryIdChange)="onCategoryIdChanged($event)"></adapt-category-summary> </ng-template>
            </adapt-see-more-popup>
        </div>
    </div>
    <ng-template #surveyNotFound>
        <span>Your health check data has been deleted.</span>
    </ng-template>
</ng-container>

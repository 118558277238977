import { Directive, ElementRef, HostBinding, HostListener, Input, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { AdaptRenderer } from "@common/ux/adapt-renderer.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

@Directive({
    selector: "button[adaptToggleGroupButton]",
})
export class ToggleGroupButtonDirective<T> extends BaseComponent implements OnInit, OnDestroy {
    @Input("adaptToggleGroupButton") public value!: T;
    @HostBinding("class.active") public isActiveClass = false;
    public set isActive(value: boolean) {
        this.isActive$.next(value);
    }
    public click = new Subject<T>();

    private renderer: AdaptRenderer;
    private isActive$ = new Subject<boolean>();

    public constructor(
        elementRef: ElementRef<HTMLElement>,
        renderer2: Renderer2,
    ) {
        super(elementRef);
        this.renderer = new AdaptRenderer(renderer2);

        this.isActive$.pipe(
            debounceTime(100), // only update the active class every 0.1 second
            this.takeUntilDestroyed(),
        ).subscribe((isActive) => this.isActiveClass = isActive);
    }

    public ngOnInit() {
        this.renderer.addClass(this.elementRef!.nativeElement, "toggle-group-button");

        if (!this.elementRef!.nativeElement.classList.contains("btn")) {
            this.renderer.addClasses(this.elementRef!.nativeElement, "btn btn-default");
        }
    }

    @HostListener("click")
    public onClick() {
        this.click.next(this.value);
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        this.click.complete();
    }
}

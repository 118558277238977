import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DxMenuModule } from "devextreme-angular";
import { AdaptTooltipModule } from "../tooltip.directive/tooltip.module";
import { MenuComponent } from "./menu.component";

@NgModule({
    imports: [
        CommonModule,
        DxMenuModule,
        AdaptTooltipModule,
    ],
    declarations: [
        MenuComponent,
    ],
    exports: [
        MenuComponent,
    ],
})
export class AdaptMenuModule { }

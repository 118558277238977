import { ClassProvider, Component, Inject, InjectionToken, Input, Type, ViewEncapsulation } from "@angular/core";
import { Observable } from "rxjs";
import { Person } from "../../ADAPT.Common.Model/person/person";
import { ShellUiService } from "../../shell/shell-ui.service";
import { UserService } from "../../user/user.service";
import { IShellPopoverLinkItem } from "../shell-popover-link-item/shell-popover-link-item";

export const APPLICATION_BAR_ITEMS = new InjectionToken<IShellPopoverLinkItem[]>("APPLICATION_BAR_ITEMS");

export function provideApplicationBarItemType(itemType: Type<IShellPopoverLinkItem>): ClassProvider {
    return {
        provide: APPLICATION_BAR_ITEMS,
        useClass: itemType,
        multi: true,
    };
}

export interface IApplicationBarComponentOptions {
    mastheadHref?: string;
    mastheadImageId?: string;
    defaultMastheadImageSrc?: string;
    navClass?: string;
    textAfterLogo?: string;
}

@Component({
    selector: "adapt-application-bar",
    templateUrl: "./application-bar.component.html",
    styleUrls: ["./application-bar.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class ApplicationBarComponent {
    @Input() public options?: IApplicationBarComponentOptions;

    public readonly defaultMastheadHref = "/";
    public readonly defaultMastheadImageSrc = "/content/shell/images/adaptplatformlogo.svg";

    public promiseToLogout = this.shellUiService.promiseToLogout;
    public loggedInUser$: Observable<Person | undefined>;

    public constructor(
        @Inject(APPLICATION_BAR_ITEMS) public items: IShellPopoverLinkItem[],
        private shellUiService: ShellUiService,
        userService: UserService,
    ) {
        items.sort((a, b) => a.ordinal - b.ordinal);
        this.loggedInUser$ = userService.currentPerson$;
    }

    public openApplicationBarMenu() {
        this.shellUiService.toggleSidebar();
    }
}

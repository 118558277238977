import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { InputType } from "@common/ADAPT.Common.Model/organisation/input";
import { CanvasInputTypes, InputsCanvas } from "@common/ADAPT.Common.Model/organisation/inputs-canvas";

@Component({
    selector: "adapt-input-types-map",
    templateUrl: "./input-types-map.component.html",
    styleUrls: ["./input-types-map.component.scss"],
})
export class InputTypesMapComponent implements OnChanges {
    @Input() public canvas?: InputsCanvas;
    public inputTypes: InputType[] = [];

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.canvas) {
            this.inputTypes = this.canvas
                ? CanvasInputTypes[this.canvas.type]
                : [];
        }
    }
}

import { Zone } from "@common/ADAPT.Common.Model/methodology/zone";
import { BullseyeQuadrant } from "@common/ADAPT.Common.Model/organisation/bullseye-quadrant-statement";
import { InputType } from "@common/ADAPT.Common.Model/organisation/input";
import { ObjectiveType } from "@common/ADAPT.Common.Model/organisation/objective-type";
import { ProcessStepType } from "@common/ADAPT.Common.Model/organisation/process-step-type";
import { Role as RoleEntity } from "@common/ADAPT.Common.Model/organisation/role";
import { Team as TeamEntity } from "@common/ADAPT.Common.Model/organisation/team";
import { Person as PersonEntity } from "@common/ADAPT.Common.Model/person/person";
import { INavigationNode } from "@common/route/navigation-node.interface";

export interface ISearchResultMatch<TExtra = Record<string, any>> {
    field: string[];
    snippet: string;
    extra?: TExtra;
}

export interface IBaseSearchResult {
    results: ISearchResultMatch[];
}

export interface ISubGroupedSearchResult<T> extends IBaseSearchResult {
    type: T;
}

export interface ILabelledSearchResult extends IBaseSearchResult {
    labelLocationIds: number[];
}

export interface ITeamedSearchResult extends IBaseSearchResult {
    teamId: number | null;
}

export interface IZonedSearchResult extends IBaseSearchResult {
    zone: Zone | null;
}

export interface IKanbanSearchResult extends IBaseSearchResult, ILabelledSearchResult, ITeamedSearchResult {
    itemId: number;
    boardId: number;
    teamId: number | null;
    assigneeId: number | null;
    summary: string;
}

export interface IMeetingSearchResult extends IBaseSearchResult, ITeamedSearchResult {
    meetingId: number;
    teamId: number;
    name: string;
}

export interface IObjectiveSearchResult extends IBaseSearchResult, ILabelledSearchResult, ITeamedSearchResult {
    objectiveId: number;
    teamId: number | null;
    assigneePersonId: number;
    title: string;
    type: ObjectiveType;
    index: number;
}

export interface ICommentSearchResultExtra {
    commentId: number;
    personId: number;
}

export interface ISystemSearchResult extends IBaseSearchResult, ILabelledSearchResult {
    systemEntityId: number;
    name: string;
}

export interface ISystemProcessStepSearchResultExtra {
    stepType: ProcessStepType;
    labelLocationIds: number[];
    roleId: number | null;
}

export enum Tier1Type {
    ValueStream,
    KeyFunction,
    ProductService,
}

export interface ITier1SearchResult extends ISubGroupedSearchResult<Tier1Type> { }

export interface IValueStreamSearchResult extends ITier1SearchResult {
    valueStreamId: number;
    name: string;
}

export interface IProductServiceSearchResult extends ITier1SearchResult {
    productId: number;
    name: string;
}

export interface IKeyFunctionSearchResult extends ITier1SearchResult, ILabelledSearchResult, IZonedSearchResult {
    keyFunctionId: number;
    zone: Zone | null;
    name: string;
}

export enum GuidanceType {
    PurposeVision,
    Value,
    ResilientBusinessGoal,
    StrategicTheme,
    StrategicGoal,
    StrategicAnchor,
    StrengthWeaknessTrend,
    CompetitorAnalysis,
    Bullseye,
}

export interface IGuidanceSearchResult extends ISubGroupedSearchResult<GuidanceType> { }

export interface IValueSearchResult extends IGuidanceSearchResult, ITeamedSearchResult {
    name: string | null;
    teamId: number | null;
}

export interface IValueQuestionSearchResultExtra {
    valuesV2: boolean;
    goodBehaviour: boolean | null;
}

export interface IResilientBusinessGoalSearchResult extends IGuidanceSearchResult, IZonedSearchResult {
    zone: Zone;
}

export enum PurposeVisionType {
    Purpose,
    Vision,
}

export interface IPurposeVisionSearchResult extends IGuidanceSearchResult, ITeamedSearchResult {
    innerType: PurposeVisionType;
    teamId: number | null;
}

export interface IStrategicThemeSearchResult extends IGuidanceSearchResult, IZonedSearchResult {
    name: string;
    zone: Zone;
    themeId: number;
}

export interface IStrategicGoalSearchResult extends IGuidanceSearchResult, IZonedSearchResult {
    name: string;
    zone: Zone;
    goalId: number;
}

export interface IStrategicAnchorSearchResult extends IGuidanceSearchResult {
    name: string;
    anchorId: number;
}

export interface IStrengthWeaknessTrendSearchResult extends IGuidanceSearchResult {
    name: string;
    canvasId: number;
    canvasDate: string;
    inputId: number;
    inputType: InputType;
}

export interface ICompetitorAnalysisSearchResult extends IGuidanceSearchResult {
    name: string;
    inputGroupId: number;
}

export interface ICompetitorAnalysisInputSearchResultExtra {
    inputId: number;
    inputType: InputType;
}

export interface IBullseyeQuadrantSearchResultExtra {
    quadrant: BullseyeQuadrant;
    inBullseye: boolean;
}

export interface IPageSearchResult extends IBaseSearchResult {
    node: INavigationNode;
}

export interface IHelpJuiceSearchResult extends IBaseSearchResult {
    name: string;
    answer_sample: string;
    long_answer_sample: string;
    host: string;
    url: string;
    parent_categories: number[];
    categories_display_for_views: string;
}

export enum PersonTeamRoleResultType {
    Person,
    Team,
    Role,
}

export interface IPersonTeamRoleSearchResult extends ISubGroupedSearchResult<PersonTeamRoleResultType> { }

export interface IPersonSearchResult extends IPersonTeamRoleSearchResult {
    person: PersonEntity;
}

export interface ITeamSearchResult extends IPersonTeamRoleSearchResult {
    team: TeamEntity;
}

export interface IRoleSearchResult extends IPersonTeamRoleSearchResult {
    role: RoleEntity;
}

export type IPersonTeamRoleResult =
    | IPersonSearchResult
    | ITeamSearchResult
    | IRoleSearchResult;
export type ITier1Result =
    | IValueStreamSearchResult
    | IProductServiceSearchResult
    | IKeyFunctionSearchResult;
export type IGuidanceResult =
    | IPurposeVisionSearchResult
    | IResilientBusinessGoalSearchResult
    | IValueSearchResult
    | IStrategicThemeSearchResult
    | IStrategicGoalSearchResult
    | IStrategicAnchorSearchResult
    | IStrengthWeaknessTrendSearchResult
    | ICompetitorAnalysisSearchResult
    | IGuidanceSearchResult;

export interface ISearchResults {
    Kanban?: IKanbanSearchResult[];
    Meeting?: IMeetingSearchResult[];
    Objective?: IObjectiveSearchResult[];
    System?: ISystemSearchResult[];
    Tier1?: ITier1Result[];
    Guidance?: IGuidanceResult[];
    Page?: IPageSearchResult[];
    PersonTeamRole?: IPersonTeamRoleResult[];
    ImplementationKit?: IHelpJuiceSearchResult[];
}

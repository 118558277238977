<span *ngIf="showIcon"
      class="key-function-icon"
      [adaptTooltip]="iconTooltip$ | async">
    <i class="fa-fw fa-inverse"
       [ngStyle]="{backgroundColor: iconColour$ | async}"
       [ngClass]="iconClass"
       aria-hidden="true"></i>
</span>
<a *ngIf="keyFunction$ | async as kf"
   [routerLink]="href$ | async">{{kf.name}}</a>

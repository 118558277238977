import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { MeetingAttendeeType } from "../embed/meeting-attendee-type";
import { Person } from "../person/person";
import { Meeting } from "./meeting";

export class MeetingAttendee extends BaseEntity<MeetingAttendee> {
    public meetingAttendeeId!: number;
    public meetingId!: number;
    public attendeeId!: number;
    public meetingAttendeeType?: MeetingAttendeeType;
    public attendee!: Person;
    public meeting!: Meeting;
}

export const MeetingAttendeeBreezeModel = new BreezeModelBuilder("MeetingAttendee", MeetingAttendee)
    .hasSource()
    .alwaysFetchingNavigationProperty("attendee")
    .isOrganisationEntity()
    .persistChangedEntity()
    .build();

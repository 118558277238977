import { Component, ElementRef, HostBinding, Input, OnInit, Renderer2 } from "@angular/core";
import { AdaptRenderer } from "../adapt-renderer.service";
import { BaseButtonComponent } from "./base-button.component";
import { buttonPreset } from "./button-preset";

@Component({
    // eslint-disable-next-line  @angular-eslint/component-selector
    selector: "button[adaptButtonIcon],a[adaptButtonIcon]",
    template: `
        <adapt-icon *ngIf="icon"
                    [icon]="icon"></adapt-icon>
        <span class="button-content">
            <ng-content></ng-content>
        </span>
    `,
    styleUrls: ["./common-button.scss"],
})
export class ButtonIconComponent extends BaseButtonComponent implements OnInit {
    @Input("adaptButtonIcon") public icon?: keyof typeof buttonPreset | string;
    @HostBinding("class.adapt-button") public readonly isAdaptButton = true;

    private renderer: AdaptRenderer;
    private addClasses = true;

    public constructor(
        elementRef: ElementRef<HTMLElement>,
        renderer: Renderer2,
    ) {
        super(elementRef);
        this.renderer = new AdaptRenderer(renderer);
    }

    private get nativeElement() {
        return this.elementRef.nativeElement;
    }

    public ngOnInit() {
        if (this.addClasses && this.nativeElement.tagName === "BUTTON" && !this.nativeElement.classList.contains("btn")) {
            this.renderer.addClasses(this.nativeElement, "btn btn-default");
        }
    }

    public preventDefaultButtonClasses() {
        this.addClasses = false;
    }
}

import { Injectable, Injector } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AbstractGuard } from "@common/route/abstract.guard";
import { GuardFailureType } from "@common/route/route-events.service";
import { UserService } from "@common/user/user.service";
import { forkJoin } from "rxjs";
import { map } from "rxjs/operators";
import { AuthorisationService } from "../authorisation/authorisation.service";

@Injectable({
    providedIn: "root",
})
export class CoachGuard extends AbstractGuard {
    public static readonly Id = "CoachGuard";

    constructor(
        private userService: UserService,
        private authorisationService: AuthorisationService,
        injector: Injector,
    ) {
        super(injector);
    }

    public canActivateWithBypassEvent(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, bypassEvent: boolean) {
        const result = forkJoin([
            this.userService.getCurrentPerson(),
            this.authorisationService.promiseToCheckIsStakeholderManager(),
        ]).pipe(
            map(([currentPerson, isStakeholderManager]) => !!currentPerson?.isCoach() || isStakeholderManager),
        );

        return this.wrapActivationResult(result, GuardFailureType.CoachGuardFailed, route, state, bypassEvent);
    }
}

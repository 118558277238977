import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { GuidanceMaterial } from "./guidance-material";
import { ProcessStep } from "./process-step";

export class ProcessStepGuidanceMaterial extends BaseEntity<ProcessStepGuidanceMaterial> {
    public processStepGuidanceMaterialId!: number;
    public processStepId!: number;
    public guidanceMaterialId!: number;
    public ordinal!: number;

    public processStep!: ProcessStep | null;
    public guidanceMaterial!: GuidanceMaterial | null;
}

export const ProcessStepGuidanceMaterialBreezeModel = new BreezeModelBuilder("ProcessStepGuidanceMaterial", ProcessStepGuidanceMaterial)
    .hasSource()
    .isOrganisationEntity()
    .withIdField()
    .orderBy("ordinal")
    .build();

<ng-container *adaptLoading="!meeting">
    <adapt-schedule-meeting *ngIf="!meetingScheduled; else showScheduledMeeting"
                            (entitiesChange)="onEntitiesChanged($event)"
                            (meetingLocationChange)="onMeetingLocationChange($event)"
                            (hasConflictsChange)="hasConflicts = $event"
                            [(sendInvitations)]="sendInvitations"
                            [(createTeamsMeeting)]="createTeamsMeeting"
                            [meeting]="meeting"
                            [editableDetails]="false"
                            [showTitle]="false"
                            [disabled]="submitting"></adapt-schedule-meeting>

    <ng-template #showScheduledMeeting>
        <div class="d-flex flex-column">
            <adapt-implementation-kit-content [articleId]="stepCustomData?.confirmationArticleSlug ?? ImplementationKitArticle.DefaultScheduledMeetingConfirmation">
            </adapt-implementation-kit-content>

            <adapt-styled-dashboard-element>
                <div class="d-flex justify-content-center">
                    <adapt-meeting-dashboard-card [meeting]="meeting"></adapt-meeting-dashboard-card>
                </div>
            </adapt-styled-dashboard-element>
        </div>
    </ng-template>

    <ng-template #buttonTemplate>
        <button type="button"
                adaptButton="runMeetingNow"
                [disabled]="submitting"
                data-test="run-meeting-now-button"
                [adaptBlockingClick]="runMeetingNow"></button>
    </ng-template>
</ng-container>

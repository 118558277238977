import { APP_INITIALIZER, ErrorHandler, ModuleWithProviders, NgModule } from "@angular/core";
import { AdaptClientConfiguration } from "../configuration/adapt-client-configuration";
import { AdaptErrorHandler } from "./error-handler/error-handler";
import { ConsoleLogProvider } from "./logger/console-log-provider";
import { Logger } from "./logger/logger";
import { ToasterLogProvider } from "./logger/toaster-log-provider";
import { initialiseUx } from "./ux-initialiser";

AdaptClientConfiguration.onInitialisation((config) => {
    Logger.registerLogProvider(new ConsoleLogProvider(config.TraceLevel));
    Logger.registerLogProvider(new ToasterLogProvider(config.ToasterLevel));
});

@NgModule()
export class AdaptCommonModule {
    public static forRoot(): ModuleWithProviders<AdaptCommonModule> {
        return {
            ngModule: AdaptCommonModule,
            providers: [
                {
                    provide: ErrorHandler,
                    useClass: AdaptErrorHandler,
                },
                {
                    provide: APP_INITIALIZER,
                    multi: true,
                    useFactory: () => initialiseUx,
                },
            ],
        };
    }

    public static forChild(): ModuleWithProviders<AdaptCommonModule> {
        return {
            ngModule: AdaptCommonModule,
        };
    }
}

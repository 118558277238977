import { Component, Injector, OnInit } from "@angular/core";
import { GuidingPhilosophy } from "@common/ADAPT.Common.Model/organisation/guiding-philosophy";
import { Purpose } from "@common/ADAPT.Common.Model/organisation/purpose";
import { ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { CommonPurposeVisionService, SeedEntity } from "@org-common/lib/purpose-vision/common-purpose-vision.service";
import { CommonPurposeVisionAuthService } from "@org-common/lib/purpose-vision/common-purpose-vision-auth.service";
import { WorkflowRunDialogComponent } from "@org-common/lib/workflow/workflow-run-dialog/workflow-run-dialog.component";
import { WorkflowsPageRoute } from "@org-common/lib/workflow/workflows-page/workflows-page.component";
import { from, lastValueFrom, merge, Observable, switchMap } from "rxjs";
import { startWith, tap } from "rxjs/operators";
import { alignOwnersVisionStandaloneWorkflow } from "../../workflows/build-strategy-for-success/align-owners-vision/align-owners-vision-workflow";
import { defineOwnersWhyStandaloneWorkflow } from "../../workflows/build-strategy-for-success/define-owners-why/define-owners-why-workflow";
import { PurposeVisionUiService } from "../purpose-vision-ui.service";

@Component({
    selector: "adapt-purpose-vision-page",
    templateUrl: "./purpose-vision-page.component.html",
})
export class PurposeVisionPageComponent extends BaseRoutedComponent implements OnInit {
    public readonly SeedEntity = SeedEntity;
    public readonly EditPurposeVision = CommonPurposeVisionAuthService.EditPurposeVision;

    public readonly PurposeVisionIds = [
        this.ImplementationKitArticle.OwnersWhyOverview,
        this.ImplementationKitArticle.PurposeOverview,
        this.ImplementationKitArticle.AlignOwnersVisionOverview,
        this.ImplementationKitArticle.VisionOverview,
    ];

    public guidingPhilosophy?: GuidingPhilosophy;
    public organisationPurpose?: Purpose;

    public canEditGuidingPhilosophy$: Observable<boolean>;
    public learnMoreUrl = ImplementationKitService.GetArticleLink(this.ImplementationKitArticle.ValuePurposeVision);

    public lastUpdated?: Date;

    public constructor(
        private commonPurposeVisionService: CommonPurposeVisionService,
        private purposeVisionUiService: PurposeVisionUiService,
        private commonDialogService: AdaptCommonDialogService,
        private rxjsBreezeService: RxjsBreezeService,
        authService: AuthorisationService,
        injector: Injector,
    ) {
        super(injector);
        this.canEditGuidingPhilosophy$ = from(authService.promiseToGetHasAccess(CommonPurposeVisionAuthService.EditPurposeVision));
    }

    public async ngOnInit() {
        [
            this.guidingPhilosophy,
            this.organisationPurpose,
        ] = await Promise.all([
            lastValueFrom(this.commonPurposeVisionService.getVision()),
            lastValueFrom(this.commonPurposeVisionService.getPurpose()),
        ]);

        // update lastUpdated date as required
        merge(
            this.rxjsBreezeService.entityTypeChanged(GuidingPhilosophy),
            this.rxjsBreezeService.entityTypeChanged(Purpose),
        ).pipe(
            startWith(undefined),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.lastUpdated = this.getLastUpdatedDate());

        this.notifyActivated();
    }

    @Autobind
    public editOwnersStory() {
        return this.commonDialogService.open(WorkflowRunDialogComponent, {
            workflow: defineOwnersWhyStandaloneWorkflow,
        });
    }

    @Autobind
    public editOwnersAlignment() {
        return this.commonDialogService.open(WorkflowRunDialogComponent, {
            workflow: alignOwnersVisionStandaloneWorkflow,
        });
    }

    @Autobind
    public editVision() {
        return this.commonPurposeVisionService.getOrCreateVision().pipe(
            tap((vision) => this.guidingPhilosophy = vision),
            switchMap((vision) => this.purposeVisionUiService.editVision(vision)),
        );
    }

    @Autobind
    public editPurpose() {
        return this.commonPurposeVisionService.getOrCreatePurpose().pipe(
            tap((purpose) => this.organisationPurpose = purpose),
            switchMap((purpose) => this.purposeVisionUiService.editPurpose(purpose)),
        );
    }

    public gotoStrategyWorkflows() {
        WorkflowsPageRoute.gotoRoute()
            .subscribe();
    }

    public get guidingPhilosophyHasData() {
        return !!this.guidingPhilosophy?.hasData
            || !!this.organisationPurpose?.content
            || !!this.organisationPurpose?.seeds;
    }

    private getLastUpdatedDate() {
        const dates = ([this.guidingPhilosophy?.lastUpdated, this.organisationPurpose?.lastUpdated]
            .filter((date) => !!date)) as Date[];
        if (dates.length === 0) {
            return undefined;
        }

        // use the most recent update date from the entities
        return new Date(Math.max.apply(null, dates.map((d) => d.getTime())));
    }
}

<div class="deep-dive d-flex flex-row align-items-stretch justify-content-between"
     *ngIf="(deepDive$ | async) as deepDive"
     (click)="openDialog(deepDive)">
    <div class="title d-flex flex-column flex-grow-1">
        <h4 class="mb-1">Deep dive into:</h4>
        <h2 class="text-wrap">{{deepDive?.title}}</h2>
        <div class="mt-auto">
            <small *ngIf="deepDive.extensions.duration">
                <i class="fal fa-fw fa-clock me-1"></i> {{deepDive.extensions.duration}}
            </small>
            <p [ngSwitch]="deepDive.type">
                <small *ngSwitchCase="deepDiveType.Scorm">
                    <i class="fal fa-fw fa-graduation-cap me-1"></i> Learning Course
                </small>
                <small *ngSwitchCase="deepDiveType.Video">
                    <i class="fal fa-fw fa-video me-1"></i> Video
                </small>
            </p>
        </div>
    </div>
    <div class="img"
         [style.background-image]="imgStyle$ | async"></div>
    <div class="arrow d-flex align-items-center px-3">
        <i class="fal fa-angle-right fa-2x"></i>
    </div>
</div>

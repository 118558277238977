import { IBreezeService } from "@common/lib/data/breeze-service.interface";
import { Logger } from "@common/lib/logger/logger";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { DataProperty, Entity, EntityManager, EntityType } from "breeze-client";
import { IBreezeEntity } from "./breeze-entity.interface";
import { IBreezeModel } from "./breeze-model.interface";

export interface IBreezeReplacementObject {
    isBreezeReplacementObject: boolean;
    keys: string[];
    values: any[];
    toType: string;
    id: number;
}

export interface IImportManager {
    entities: Entity[];
    manager?: EntityManager;
}

export class EntityPersistentBreezeHelper {
    private _models: { [shortName: string]: IBreezeModel };
    private _entityManager?: EntityManager;

    private logger = Logger.getLogger("EntityPersistentBreezeHelper");

    public constructor(
        private breezeService: IBreezeService,
    ) {
        this._models = {};
    }

    public get models() {
        return this._models;
    }

    public shouldPersistChangedEntity(entity: IBreezeEntity) {
        let result = false;

        const model = this.models[this.getEntityShortName(entity)];

        if (model
            && model.persistChangedEntity
            && !this.breezeService.isTransientEntity(entity)) {
            result = true;
        }

        return result;
    }

    public isPropertyChangeEventExcluded(entity: IBreezeEntity, propertyName: string) {
        let result = false;

        const model = this.models[this.getEntityShortName(entity)];

        if (model && model.excludePropertyChangeEventForProperties) {
            result = model.excludePropertyChangeEventForProperties.indexOf(propertyName) >= 0;
        }

        return result;
    }

    public get entityManager() {
        return this._entityManager!;
    }

    public set entityManager(manager: EntityManager | undefined) {
        this._entityManager = manager;
    }

    public getEntityShortName(entity: Entity) {
        if (entity) {
            if (entity.entityType) {
                return entity.entityType.shortName;
            } else if (entity.entityAspect) {
                // for unknown reason, when entity is attached on import, there is no entity.entityType.
                // can only access the type through entityGroup, which is not defined in the interface
                const entityGroup = entity.entityAspect.entityGroup;
                if (entityGroup && entityGroup.entityType) {
                    const entityType = entityGroup.entityType as EntityType;
                    return entityType.shortName;
                }
            }
        }

        return "";
    }

    public modelRegistered(toType: string, model: IBreezeModel) {
        this.models[toType] = model;
    }

    public promiseToPrimeEntity(entity: IBreezeEntity | IBreezeReplacementObject) {
        const self = this;
        let id: number | undefined = -1;
        let toType: string;

        if (entity.isBreezeReplacementObject) {
            id = entity.id;
            toType = entity.toType;
        } else if (this.hasValidEntityDataProperties(entity as IBreezeEntity)) {
            // can be from imported entities - need to prime it into entityManager cache
            id = this.getEntityId(entity as IBreezeEntity);
            toType = this.getEntityShortName((entity as IBreezeEntity));
        } else {
            return Promise.resolve();
        }

        let model: IBreezeModel | undefined = this.models[toType];
        if (id && id < 0) {
            // For newly created entities, it will be loaded straight from import entity manager and won't
            // hit the server for navProperty - so need to check if the model has defined expandSources
            // to prime for the corresponding nav properties
            if (model && model.expandSources) {
                return Promise.all(model.expandSources.map(getPromiseToPrimeExpandSource));
            } else {
                return Promise.resolve();
            }
        } else {
            // check model to make sure that has a source, if not, check for primeSource
            // - this is to enable entities such as PCU rating, which has no controller interface
            //   to be primed (which is primed through the nav properties of a parent entity, e.g. PCU)
            if (!model.source) {
                if (model.primeSource && model.primeSource.sourceId) {
                    id = (entity as any)[model.primeSource.sourceId];
                    model = this.models[model.primeSource.sourceType];
                } else {
                    this.logger.warn("no source and prime source to prime for", entity);
                    model = undefined;
                    id = undefined;
                }
            }

            return promiseToGetEntity(model, id);
        }

        function getPromiseToPrimeExpandSource(expandSource: { expandType: string, expandId: string }) {
            // need expandId and expandType
            if (expandSource.expandId && expandSource.expandType) {
                const expandModel = self.models[expandSource.expandType];
                const expandId = (entity as any)[expandSource.expandId];

                // only prime if model and id is defined and id is > 0 (not newly created which will already be loaded during entity manager import)
                if (expandId > 0) {
                    return promiseToGetEntity(expandModel, expandId);
                }
            } else {
                self.logger.warn("not enough info to prime expandSource", expandSource);
            }

            return Promise.resolve(undefined);
        }

        function promiseToGetEntity(entityModel?: IBreezeModel, entityId?: number): Promise<IBreezeEntity | IBreezeEntity[] | undefined> {
            if (entityModel && entityId) {
                if (entityModel.isReferenceData) {
                    return self.breezeService.promiseToGetAll(entityModel, false);
                } else {
                    return self.breezeService.promiseToGetById(entityModel, entityId, false);
                }
            }

            return Promise.resolve(undefined);
        }
    }

    /**
     * Restoring the breeze entities from object previously replaced for serialisation back into
     * breeze entities again.
     * This is a synchronous call - relying on all entities to be already primed in the entityManager.
     *
     * @param {any} object Object or Array
     * @param {any} unsavedEntities unsaved entities (include entities loaded from localStorage where model is already registered)
     * @param {any} importManager breeze manager used for importing (it will contain loaded entities where model is not yet registered)
     *      Once the model is registered, changed properties from imported entities will be applied to the corresponding entity
     *      in the current entityManager and that imported entity will be removed from importManager.
     * @returns {object} The input object with all the previously replaced breeze entities restored.
     */
    public restoreBreezeEntities(object: any, unsavedEntities: IBreezeEntity[], importManager: IImportManager) {
        if (!ObjectUtilities.isObject(object) && !Array.isArray(object)) {
            return object;
        }

        const self = this;
        let result: any = {};

        if (Array.isArray(object)) {
            result = (object as any).splice();
        }

        const keys = Object.keys(object);
        for (const key of keys) {
            const value = object[key];
            restoreBreezeObj(value, key);
        }

        return result;

        function restoreBreezeObj(value: any, key: string) {
            if (value && ObjectUtilities.isObject(value) && value.isBreezeReplacementObject) {
                result[key] = findBreezeEntity(value);
            } else {
                result[key] = self.restoreBreezeEntities(value, unsavedEntities, importManager);
            }

            function findBreezeEntity(breezeValue: IBreezeReplacementObject) {
                // find from unsaved
                if (Array.isArray(unsavedEntities)) {
                    for (const unsavedEntity of unsavedEntities) {
                        if (matchValue(unsavedEntity)) {
                            return unsavedEntity;
                        }
                    }
                }

                // find from importManager (not real entity - mostly new entities) - need to create entity with entityManager
                const entities = importManager?.entities;
                if (breezeValue.id < 0 && Array.isArray(entities)) {
                    for (const entity of entities) {
                        if (matchValue(entity as IBreezeEntity)) {
                            const newEntity = self.createBreezeEntity(breezeValue.toType, breezeValue.keys, breezeValue.values);
                            importManager.entities.splice(entities.indexOf(entity), 1);
                            return newEntity;
                        }
                    }
                }

                // find from entityManager cache - all relevant entities would have already been primed when persisted
                // dialogs were loaded. This is synchronous call - not a promise.
                // Value for the key need to be an object according to http://breeze.github.io/doc-js/api-docs/classes/EntityManager.html
                return self.entityManager!.getEntityByKey(breezeValue.toType, breezeValue.id.toString());

                function matchValue(entity: IBreezeEntity) {
                    if (breezeValue.toType !== self.getEntityShortName(entity)) {
                        return false;
                    }

                    const entityValues = breezeValue.keys.map((entityKey) => self.extractEntityValue(entity, entityKey));
                    let match = true;
                    for (let i = 0; i < entityValues.length; i++) {
                        if (entityValues[i] !== breezeValue.values[i]) {
                            match = false;
                            break;
                        }
                    }

                    return match;
                }
            }
        }
    }

    public extractEntityValue(entity: IBreezeEntity, key: string) {
        let result = entity[key];

        if (result instanceof Date && !isNaN(+result)) {
            // need this conversion as breeze import manager will automatically convert this for importEntity
            // which causes false negative without
            result = result.toISOString();
        }

        return result;
    }

    public createBreezeEntity(toType: string, keys: string[], values: any[]) {
        if (!Array.isArray(keys) || !Array.isArray(values) || keys.length !== values.length
            || !this.entityManager) {
            return undefined;
        }

        const data: { [key: string]: any } = {};

        for (let j = 0; j < keys.length; j++) {
            data[keys[j]] = values[j];
        }

        return this.entityManager.createEntity(toType, data);
    }

    /**
     * Go through the object, identifying any breeze entities and replace that with a placeholder
     * so that the object can be serialised, stored and restored in a different session
     * @param {any} object The object to be processed
     * @returns {Object} The object after the replacement of breeze entities
     */
    public replaceBreezeEntities(object: any) {
        if (!ObjectUtilities.isObject(object) && !Array.isArray(object)) {
            return object;
        }

        const self = this;
        let result: any = {};

        if (Array.isArray(object)) {
            result = object.slice();
        }

        const keys = Object.keys(object);
        for (const key of keys) {
            const value = object[key];
            replaceBreezeObj(value, key);
        }

        return result;

        function replaceBreezeObj(value: any, key: string) {
            if (self.hasValidEntityDataProperties(value)) {
                result[key] = createBreezeReplacementObject(value);
            } else if (!self.isDetachedOrDeletedBreezeEntity(value)) {
                result[key] = self.replaceBreezeEntities(value);
            }
            // detached or deleted breeze entities will not be added
            // otherwise, there is a chance to end up in an infinite recursion
            // as breeze entities may have cyclic references back to itself
        }

        function createBreezeReplacementObject(entity: IBreezeEntity) {
            return {
                isBreezeReplacementObject: true,
                keys: self.getEntityKeys(entity),
                values: self.getEntityValues(entity),
                toType: self.getEntityShortName(entity),
                id: self.getEntityId(entity),
            } as IBreezeReplacementObject;
        }
    }

    public getEntityId(entity: IBreezeEntity) {
        if (this.hasValidEntityDataProperties(entity)) {
            for (const dataProperty of entity.entityType.dataProperties) {
                if (dataProperty.isPartOfKey) {
                    return entity[dataProperty.name];
                }
            }
        }

        return 0;
    }

    public getEntityKeys(entity: IBreezeEntity) {
        if (this.hasValidEntityDataProperties(entity)) {
            return entity.entityType.dataProperties.filter(this.notInternalDataProperty).map((dataProp) => dataProp.name);
        } else {
            return [];
        }
    }

    public getEntityValues(entity: IBreezeEntity) {
        if (this.hasValidEntityDataProperties(entity)) {
            return entity.entityType.dataProperties.filter(this.notInternalDataProperty).map((dataProp) => entity[dataProp.name]);
        } else {
            return [];
        }
    }

    private hasValidEntityDataProperties(entity: IBreezeEntity) {
        return entity && entity.entityType
            && Array.isArray(entity.entityType.dataProperties);
    }

    private isDetachedOrDeletedBreezeEntity(entity: IBreezeEntity) {
        return entity && entity.entityAspect && entity.entityAspect.entityState
            && (entity.entityAspect.entityState.isDeleted() || entity.entityAspect.entityState.isDetached());
    }

    private notInternalDataProperty(dataProperty: DataProperty) {
        return dataProperty.name[0] !== "_";
    }
}

<ul *ngIf="teamGroupedObjectives"
    class="mb-0">
    <li *ngFor="let group of teamGroupedObjectives; let first = first">
        <ul class="mb-0"
            [ngClass]="{'mt-2': !first}">
            <adapt-link-objectives-page *ngIf="currentPageTeamId !== group.team?.teamId"
                                        [team]="group.team"
                                        [addObjectiveTextSuffix]="showGroupObjectiveTextSuffix"></adapt-link-objectives-page>
            <ul class="related-objectives">
                <li *ngFor="let childObjective of group.objectives"
                    class="d-flex align-items-baseline gap-1">
                    <adapt-link-objective [objective]="childObjective"
                                          [showAssigneeImage]="true"
                                          [ngClass]="{'flex-grow-1 blurred-summary':
                                            blurTitle && !childObjective.isOrganisationObjective && !childObjective.team?.allowObjectivesTeamRead}">
                    </adapt-link-objective>
                    <button adaptButtonIcon="deleteBorderless"
                            *ngIf="!objective?.isClosed && hasEditPermissions"
                            class="btn btn-link"
                            (click)="removeObjectiveLink(childObjective)"
                            adaptTooltip="Delete objective link"></button>
                </li>
            </ul>
        </ul>
    </li>
</ul>

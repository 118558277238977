import { ActiveEntityUtilities } from "@common/lib/data/active-entity-utilities";
import { BreezeModelUtilities } from "@common/lib/data/breeze-model-utilities";
import { BaseActiveEntity } from "../base-active-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Connection } from "./connection";
import { Role } from "./role";
import { Team } from "./team";

export class RoleConnection extends BaseActiveEntity<RoleConnection> {
    public roleConnectionId!: number;
    public connectionId!: number;
    public teamId?: number;
    public roleId!: number;
    public startDate!: Date;
    public endDate?: Date;
    public connection!: Connection;
    public role!: Role;
    public team!: Team;

    public isInactive = () => ActiveEntityUtilities.isInactiveNow(this);
    public isHistoric = () => ActiveEntityUtilities.isHistoric(this);
    public isNotHistoric = () => ActiveEntityUtilities.isNotHistoric(this);
}

export const RoleConnectionBreezeModel = new BreezeModelBuilder("RoleConnection", RoleConnection)
    .hasSource()
    .isOrganisationEntity()
    .alwaysFetchingNestedNavigationProperty("connection.person")
    .withActivePath("self")
    .withActivePath("connection")
    .withPropertyValidator("connectionId", BreezeModelUtilities.EntityIdValidator)
    .withPropertyValidator("roleId", BreezeModelUtilities.EntityIdValidator)
    .build();

<dx-tab-panel class="user-access-summary-tabs"
              [items]="sectionTabs"
              [swipeEnabled]="false">
    <div *dxTemplate="let part of 'title'">
        {{part.title}}
        <i *ngIf="part.tooltip"
           class="fal fa-info-circle ms-1 text-black-50"
           [adaptTooltip]="part.tooltip"></i>
    </div>
    <div *dxTemplate="let part of 'item'"
         class="d-flex flex-column">
        <dx-data-grid *adaptLoading="loading"
                      [dataSource]="part.grid.data"
                      [columnAutoWidth]="true"
                      [allowColumnResizing]="true"
                      [allowColumnReordering]="true"
                      [rowAlternationEnabled]="true"
                      [hoverStateEnabled]="true"
                      [showRowLines]="true"
                      [showBorders]="true"
                      [wordWrapEnabled]="true"
                      [columns]="part.grid.columns"
                      height="100%"
                      width="100%"
                      class="user-access-summary-grid">
            <dxo-filter-row [visible]="false"></dxo-filter-row>
            <dxo-header-filter [visible]="false"></dxo-header-filter>
            <dxo-paging [enabled]="false"></dxo-paging>
            <dxo-column-chooser [enabled]="false"></dxo-column-chooser>
            <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
            <dxo-scrolling showScrollbar="always"></dxo-scrolling>

            <div *dxTemplate="let item of 'moduleGroupCellTemplate'">
                <h3 class="user-access-grid-feature-group">{{item.value}}</h3>
            </div>

            <div *dxTemplate="let item of 'roleHeaderTemplate'"
                 class="role-header-cell">
                <adapt-link-access-level [role]="getRoleFromId(item.column.dataField)"
                                         [showIcon]="false"></adapt-link-access-level>
            </div>

            <div *dxTemplate="let item of 'teamRoleHeaderTemplate'"
                 class="role-header-cell">
                {{getRoleFromId(item.column.dataField)?.label}}
            </div>

            <div *dxTemplate="let item of 'checkboxCellTemplate'">
                <dx-check-box *ngIf="item.value"
                              [value]="true"
                              [readOnly]="true"></dx-check-box>
            </div>
        </dx-data-grid>

        <div>
            <dx-check-box text="Hide rows without any permissions"
                          class="mt-2"
                          [value]="hideEmptyRows"
                          (valueChange)="toggleFilter($event)"></dx-check-box>
        </div>
    </div>
</dx-tab-panel>

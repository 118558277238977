import { Component, Inject } from "@angular/core";
import { ADAPT_DIALOG_DATA } from "../../adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "../../adapt-common-dialog/base-dialog.component/base-dialog.component";
import { ChangeAction } from "../../change-manager/change-manager.service";

export interface IUnsavedChangesDialogData {
    message?: string;
    entitiesValid?: boolean;
}

@Component({
    selector: "adapt-unsaved-changes-dialog",
    templateUrl: "./unsaved-changes-dialog.component.html",
})
export class UnsavedChangesDialogComponent extends BaseDialogComponent<IUnsavedChangesDialogData, ChangeAction> {
    public readonly dialogName = "UnsavedChangesDialog";

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public dialogData: IUnsavedChangesDialogData,
    ) {
        super();
    }

    public discard() {
        this.resolve(ChangeAction.DiscardAndContinue);
    }

    public stay() {
        this.resolve(ChangeAction.Stay);
    }

    public save() {
        this.resolve(ChangeAction.SaveAndContinue);
    }
}

<adapt-dashboard-element *adaptIfAlto="false"
                         name="Meeting guidance"
                         [disableClick]="(canEditTeamGuidance$ | async) === false"
                         (headerClicked)="editTeamMeetingGuidance()">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
</adapt-dashboard-element>

<adapt-styled-dashboard-element *adaptIfAlto="true"
                            name="Meeting guidance"
                            [disableClick]="(canEditTeamGuidance$ | async) === false"
                            (headerClicked)="editTeamMeetingGuidance()"
                            linkText="Define the meeting guidance"
                            [contentCard]="true">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
</adapt-styled-dashboard-element>

<ng-template #content>
    <div *adaptLoadingUntilFirstEmit="let teamMeetingGuidance of teamMeetingGuidance$">
        <div *ngIf="teamMeetingGuidance && teamMeetingGuidance?.containsGuidance; else noGuidanceTemplate">
            <h4 *ngIf="teamMeetingGuidance.meetingIntent">Why do we meet?</h4>
            <div class="mb-3"
                 [froalaView]="teamMeetingGuidance.meetingIntent"></div>

            <h4 *ngIf="teamMeetingGuidance.meetingGuidelines">How will we behave when we meet?</h4>
            <div class="mb-3"
                 [froalaView]="teamMeetingGuidance.meetingGuidelines"></div>

            <h4 *ngIf="teamMeetingGuidance.meetingFrequency">How frequently will we meet?</h4>
            <div class="mb-3"
                 [froalaView]="teamMeetingGuidance.meetingFrequency"></div>

            <h4 *ngIf="teamMeetingGuidance.decisionProcess">How will we make decisions?</h4>
            <div class="mb-3"
                 [froalaView]="teamMeetingGuidance.decisionProcess"></div>
        </div>
        <ng-template #noGuidanceTemplate>
            <adapt-call-to-action-inline>
                <p>Setting your team's meeting guidance allows the team to define:</p>
                <ul class="list-content">
                    <li>The purpose for team meetings</li>
                    <li>The expected behaviour during team meetings</li>
                    <li>How frequently the team meets</li>
                    <li>The decision-making process</li>
                </ul>

                <ng-container *adaptIfAlto="false">
                    <button adaptCallToActionButton
                            adaptButtonIcon="edit"
                            *adaptIfAuthorised="editTeamKanban;Entity:team$ | async"
                            (click)="editTeamMeetingGuidance()">
                        Define the meeting guidance
                    </button>
                </ng-container>
            </adapt-call-to-action-inline>
        </ng-template>
    </div>
</ng-template>

import { Inject, Injectable } from "@angular/core";
import { PERSONAL_DASHBOARD_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { RouteService } from "@common/route/route.service";
import { ToolbarService } from "@common/shell/toolbar/toolbar.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";

@Injectable({
    providedIn: "root",
})
export class OrgCommonShellService {
    public constructor(
        private routeService: RouteService,
        organisationService: OrganisationService,
        toolbarService: ToolbarService,
        @Inject(PERSONAL_DASHBOARD_PAGE) private personalDashboardPageRoute: IAdaptRoute<{}>,
    ) {
        organisationService.organisationChanging$.subscribe(() => toolbarService.resetToolbar());
    }

    public promiseToGetMastheadUrl() {
        return this.routeService.getControllerRoute(this.personalDashboardPageRoute.id);
    }
}

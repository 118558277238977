import { Component, Injector, OnInit } from "@angular/core";
import { DeepDive, DeepDiveType } from "@common/ADAPT.Common.Model/embed/deep-dive";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { buttonPreset, IButtonType } from "@common/ux/button/button-preset";
import { MenuComponent } from "@common/ux/menu/menu.component";

@Component({
    selector: "adapt-common-components-page",
    templateUrl: "./common-components-page.component.html",
    styleUrls: ["./common-components-page.component.scss"],
})
export class CommonComponentsPageComponent extends BaseRoutedComponent implements OnInit {
    public date: Date = new Date();

    public gridDataSource = [
        { name: "Joe", date: "1-May-2015", rank: 4 },
        { name: "Frank", date: "1-Jun-2015", rank: 1 },
        { name: "Mary", date: "1-Feb-2015", rank: 34 },
        { name: "Jane", date: "1-May-2015", rank: 4 },
        { name: "John", date: "1-May-2015", rank: 2 },
    ];

    public menuItems = [{
        text: "A Menu That Contains Items With All Icons",
        icon: MenuComponent.SmallRootMenu.icon,
        items: [
            {
                text: "Open action dialog",
                icon: "fal fa-fw fa-folder-open",
            }, {
                beginGroup: true,
                text: "Close action",
                icon: "fal fa-fw fa-archive",
            }, {
                beginGroup: true,
                text: "Re-open action",
                icon: "fal fa-fw fa-inbox-out",
            }, {
                beginGroup: true,
                text: "Add link",
                icon: "fal fa-fw fa-paperclip",
            }, {
                text: "Toggle association with meeting",
                icon: "fal fa-fw fa-link",
            }, {
                text: "Submenu",
                icon: "fal fa-fw fa-arrows-alt",
                items: [{
                    text: "Board A",
                    icon: "fal fa-fw fa-link",
                }, {
                    text: "Board B",
                    icon: "fal fa-fw fa-link",
                }, {
                    text: "Board C",
                    icon: "fal fa-fw fa-link",
                }],
            }, {
                text: "Duplicate action",
                icon: "fal fa-fw fa-clone",
            }, {
                beginGroup: true,
                text: "Delete action",
                icon: "fal fa-fw fa-trash-alt",
            },

        ],
    }];

    public menuItemsIconLess = [{
        text: "A Menu That Contains Items With No Icons",
        icon: MenuComponent.SmallRootMenu.icon,
        items: [
            {
                text: "Open action dialog",
                icon: "",
            }, {
                beginGroup: true,
                text: "Close action",
                icon: "",
            }, {
                beginGroup: true,
                text: "Re-open action",
                icon: "",
            }, {
                beginGroup: true,
                text: "Add link",
                icon: "",
            }, {
                text: "Toggle association with meeting",
                icon: "",
            }, {
                text: "Submenu",
                icon: "",
                items: [{
                    text: "Board A",
                    icon: "",
                }, {
                    text: "Board B",
                    icon: "",
                }, {
                    text: "Board C",
                    icon: "",
                }],
            }, {
                text: "Duplicate action",
                icon: "",
            }, {
                beginGroup: true,
                text: "Delete action",
                icon: "",
            },

        ],
    }];

    public menuItemsMixed = [{
        text: "A Menu That Contains Items With and Without Icons",
        icon: MenuComponent.SmallRootMenu.icon,
        items: [
            {
                text: "Open action dialog",
                icon: "fal fa-fw fa-folder-open",
            }, {
                beginGroup: true,
                text: "Close action",
                icon: "fal fa-fw fa-archive",
            }, {
                beginGroup: true,
                text: "Re-open action",
                icon: "",
            }, {
                beginGroup: true,
                text: "Add link",
                icon: "fal fa-fw fa-paperclip",
            }, {
                text: "Toggle association with meeting",
                icon: "fal fa-fw fa-arrows-alt",
                isChecked: false,
            }, {
                text: "Submenu",
                icon: "fal fa-fw fa-arrows-alt",
                items: [{
                    text: "Board A",
                    icon: "fal fa-fw fa-cog",
                }, {
                    text: "Board B",
                    icon: "",
                }, {
                    text: "Board C",
                    icon: "",
                }],
            }, {
                text: "Duplicate action",
                icon: "",
            }, {
                beginGroup: true,
                text: "Delete action",
                icon: "fal fa-fw fa-trash-alt",
            },

        ],
    }];
    public deepDiveScorm: DeepDive;

    constructor(
        injector: Injector,
    ) {
        super(injector);

        this.deepDiveScorm = new DeepDive();
        this.deepDiveScorm.type = DeepDiveType.Scorm;
        this.deepDiveScorm.durationMinimum = 5;
        this.deepDiveScorm.durationMaximum = 15;
        this.deepDiveScorm.title = "Overview of the ADAPT Team Model";
        this.deepDiveScorm.image = "Jd8n8lQCqlDecCsF_rUqYiCdp-LKjMXJX.jpg";
        this.deepDiveScorm.location = "tl-overview-of-the-adapt-team-model";
    }

    public ngOnInit() {
        this.notifyActivated();
    }

    public get buttons() {
        // taken from button-preset.ts
        const buttonPresets = [
            "primary", "secondary", "outlinePrimary", "pill", "edit", "help",
            "iconlessLink", "editLink", "editBorderless", "delete", "end", "save",
        ];
        return Object.entries(buttonPreset)
            .map(([preset, data]) => ({ preset, ...data }))
            .filter((i) => buttonPresets.includes(i.preset));
    }

    public buttonTrackBy(_: number, item: IButtonType & { preset: string }) {
        return item.preset;
    }
}

<adapt-dialog class="add-people-dialog"
              [maxWidth]="1050">
    <h3 adapt-dialog-title>Add people</h3>

    <ng-container adapt-dialog-content>
        <adapt-add-people [config]="config"
                          (isValid)="isValid = $event"
                          (addPeopleBindingModelChanged)="addPeopleModel = $event"></adapt-add-people>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   [saveIsDisabled]="!isValid"
                                   [saveBlockingClick]="addAndSave"
                                   (cancelClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>

<adapt-dialog [maxWidth]="800"
              [fitHeight]="true">
    <h3 adapt-dialog-title>Schedule time with a coach</h3>

    <ng-container adapt-dialog-content>
        <ng-container *ngIf="scheduled">
            <h3>You've successfully scheduled your coaching session!</h3>
            <p>Check your email for more information.</p>
        </ng-container>

        <ng-container *ngIf="!scheduled">
            <ng-container *ngIf="!isFree">
                <h3>You have successfully paid for a coaching session!</h3>
                <p>A confirmation email has been sent to you. Please select a time and date to talk with a coach below.</p>
            </ng-container>

            <div *ngIf="!failedToLoad"
                 class="alert alert-info">If you would like to schedule this another time or encounter any issues scheduling your coaching
                session,
                please contact us using Intercom (the chat icon at the bottom right of your screen)
                or email <a href="mailto:connect@theadaptway.com">connect&commat;theadaptway.com</a>.</div>
        </ng-container>

        <adapt-loading-spinner *ngIf="loading"></adapt-loading-spinner>

        <div *ngIf="failedToLoad"
             class="alert alert-danger">
            There was a problem loading our coach scheduler.
            Please contact us to schedule your session using Intercom (the chat icon at the bottom right of your
            screen).
        </div>

        <iframe *ngIf="frameUrl"
                [src]="frameUrl"
                width="100%"
                [style.height]="calendlyFrameHeight"
                frameBorder="0"
                title="Calendly Scheduling Page"
                (load)="loadingUpdater.next(false)"
                (error)="failedToLoad = true"></iframe>
    </ng-container>

    <div adapt-dialog-footer
         class="ms-auto">
        <button class="btn btn-primary"
                (click)="cancel()">
            <ng-container *ngIf="!closeButtonDisabled; else failed">
                <i class="fal fa-arrow-right me-1"></i> Return to dashboard
            </ng-container>
            <ng-template #failed>Schedule later</ng-template>
        </button>
    </div>
</adapt-dialog>

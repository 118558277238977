import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import { distinctUntilChanged, takeUntil } from "rxjs/operators";
import { OrganisationFeatureFlag } from "./organisation-feature-flag.enum";
import { OrganisationFeatureFlagService } from "./organisation-feature-flag.service";

@Directive({
    selector: "[adaptIfFeatureFlagActive]",
})
export class AdaptIfFeatureFlagActiveDirective implements OnInit, OnDestroy {
    @Input("adaptIfFeatureFlagActive")
    public set feature(value: OrganisationFeatureFlag | undefined) {
        this.featureFlag$.next(value);
    }

    @Input("adaptIfFeatureFlagActiveElse") public elseTemplate?: TemplateRef<unknown>;

    private featureFlag$ = new BehaviorSubject<OrganisationFeatureFlag | undefined>(undefined);
    private flagEnabled$ = new Subject<boolean>();
    private destroyed$ = new Subject<void>();

    private featureSubscription?: Subscription;

    public constructor(
        private templateRef: TemplateRef<unknown>,
        private viewContainer: ViewContainerRef,
        private organisationFeatureFlagService: OrganisationFeatureFlagService,
    ) {
    }

    public ngOnInit() {
        this.featureFlag$.pipe(
            distinctUntilChanged(),
            takeUntil(this.destroyed$),
        ).subscribe((flag) => {
            this.featureSubscription?.unsubscribe();
            if (flag) {
                this.featureSubscription = this.organisationFeatureFlagService.isFeatureFlagEnabled$(flag).pipe(
                    takeUntil(this.destroyed$),
                ).subscribe(this.flagEnabled$);
            }
        });

        this.flagEnabled$.pipe(
            distinctUntilChanged(),
            takeUntil(this.destroyed$),
        ).subscribe((isFlagActive) => {
            this.viewContainer.clear();

            if (isFlagActive) {
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else if (this.elseTemplate) {
                this.viewContainer.createEmbeddedView(this.elseTemplate);
            }
        });
    }

    public ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}

<span class="item-link"
      *ngIf="item && item.board"
      [ngClass]="{
          'item-link-inline': inline,
          'item-link-personal-item': item.board.isPersonalBoard,
          'item-link-bold-code': useBoldItemCode
      }">
    <div class="d-flex align-items-center gap-1"
         *ngIf="showStatus || showPersonImageLink || item.board?.isArchived || !hideItemCode">
        <!-- The 2 icons should be centered aligned while the entire div and the rest will be baselined -->
        <span *ngIf="showStatus"
              [ngClass]="itemStatusBadgeClass"
              [adaptTooltip]="itemStatusTooltip">
        </span>
        <span class="text-break d-flex align-items-center justify-content-center"
              *ngIf="showPersonImageLink"
              data-test="item-link-summary">
            <adapt-link-person [person]="item.assignee"
                               [showImage]="true"
                               [showText]="false"
                               [imageSize]="'xs'"></adapt-link-person>
        </span>
        <i *ngIf="item.board?.isArchived"
           class="fal fa-fw fa-archive me-1"
           adaptTooltip="This action is within an archived board"></i>
        <a *ngIf="showDialogLink && !showSummary"
           (click)="openItem($event)"
           class="item-code text-nowrap"
           data-test="item-code"
           [href]="(itemUrl$ | async)"
           target="_blank">
            {{item!.code}}
            <i class="fal fa-lock-alt"
               aria-hidden="true"
               *ngIf="item!.board?.isPersonalBoard; else privateTeamIcon"
               adaptTooltip="Item is only visible to you">
            </i>
        </a>
        <span *ngIf="!hideItemCode && (!showDialogLink || showSummary)"
              class="item-code text-nowrap"
              data-test="item-code">
            {{item!.code}}
            <i class="fal fa-lock-alt"
               aria-hidden="true"
               *ngIf="item!.board?.isPersonalBoard; else privateTeamIcon"
               adaptTooltip="Item is only visible to you">
            </i>
        </span>
        <ng-template #privateTeamIcon>
            <adapt-team-private-icon [team]="item.board.team"></adapt-team-private-icon>
        </ng-template>
    </div>
    <ng-container *ngIf="showSummary && item!.summary && !showDialogLink">
        <span class="text-break item-link-summary"
              data-test="item-link-summary">
            {{ item!.summary }}
        </span>
    </ng-container>
    <ng-container *ngIf="showSummary && item!.summary && showDialogLink">
        <a (click)="openItem($event)"
           class="text-break item-link-summary"
           data-test="item-link-summary"
           [href]="(itemUrl$ | async)"
           target="_blank">
            {{ item!.summary }}
        </a>
    </ng-container>
</span>

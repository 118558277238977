import { ComponentRef, Directive, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, ViewContainerRef } from "@angular/core";
import { LocalStorage } from "@common/lib/storage/local-storage";
import { ElementUtilities } from "@common/lib/utilities/element-utilities";
import { debounceTime, Subject, Subscription } from "rxjs";
import { CollapsibleButtonComponent } from "./collapsible-button/collapsible-button.component";

const CollapsibleParentContainer = "collapsible-parent-container";
const CollapsibleContainer = "collapsible-container";
const AnimateTransitionClass = "animate-transition";
const HorizontalCollapsedClass = "horizontal-collapsed";
const VerticalCollapsedClass = "vertical-collapsed";
const HorizontalCollapsedSibling = "horizontal-collapsed-sibling";
const HideChildrenClass = "hide-children";
const VerticalExpandTransition = "vertical-expand-transition";

@Directive({
    selector: "[adaptCollapsible]",
})
export class CollapsibleDirective implements OnInit, OnDestroy, OnChanges {
    @Input("adaptCollapsible") public storageKey?: string;
    @Output("adaptCollapsibleExpanded") public expanded = new EventEmitter<void>();

    private isVertical = false;
    private lastHeight = 0;
    private isCollapsed = false;
    private buttonComponent?: ComponentRef<CollapsibleButtonComponent>;
    private buttonSub?: Subscription;

    private isDestroyed = false;
    private triggerUpdateCollapseButton$ = new Subject<void>();
    private triggerUpdateCollapseButtonSub: Subscription;
    private observer?: MutationObserver;

    constructor(
        private renderer: Renderer2,
        private viewContainer: ViewContainerRef,
    ) {
        this.triggerUpdateCollapseButtonSub = this.triggerUpdateCollapseButton$.pipe(
            debounceTime(100),
        ).subscribe(() => this.updateCollapseButton());
    }

    public ngOnInit() {
        this.updateAfterVisible();
        this.observeSiblings();
    }

    public ngOnDestroy() {
        this.observer?.disconnect();
        this.buttonSub?.unsubscribe();
        this.isDestroyed = true;
        this.triggerUpdateCollapseButtonSub.unsubscribe();
        this.cleanupButton();
    }

    public ngOnChanges(): void {
        if (this.storageKey) {
            this.isCollapsed = !!LocalStorage.get<boolean>(this.localStorageKey);
            if (this.buttonComponent) { // if not, initialisation of the collapse will pick this up
                this.isCollapsed ? this.collapse(true) : this.expand(true);
                this.buttonComponent.instance.isCollapsed = this.isCollapsed;
            }
        }
    }

    @HostListener("window:resize")
    public onResize() {
        if (this.updateVertical() && this.isCollapsed) {
            // changed layout
            const hostElement = this.viewContainer.element.nativeElement as HTMLElement;
            if (this.isVertical) {
                this.renderer.removeClass(hostElement, HorizontalCollapsedClass);
                hostElement.style.minHeight = ""; // clear previous override to maintain height for horizontal
                this.removeSiblingsFlexGrow(hostElement);

                this.renderer.addClass(hostElement, VerticalCollapsedClass);
                // changed from horizontal to vertical -> lastHeight stored is incorrect -> reset
                this.lastHeight = 0;
            } else {
                this.renderer.removeClass(hostElement, VerticalCollapsedClass);
                this.renderer.addClass(hostElement, HorizontalCollapsedClass);
                this.addSiblingsFlexGrow(hostElement);
            }
        }
    }

    private get localStorageKey() {
        return `Collapsible_${this.storageKey}_isCollapsed`;
    }

    private updateAfterVisible() {
        if (ElementUtilities.isVisible(this.viewContainer.element.nativeElement)) {
            this.updateCollapseButton();
        } else if (!this.isDestroyed) {
            setTimeout(this.updateAfterVisible.bind(this), 50);
        }
    }

    private observeSiblings() {
        const parent = this.viewContainer.element.nativeElement.parentElement;
        if (parent) {
            this.observer = new MutationObserver((mutations) => {
                mutations.forEach((mutation) => {
                    if (mutation.type === "childList") {
                        this.triggerUpdateCollapseButton$.next();
                    }
                });
            });

            this.observer.observe(parent, { childList: true });
        }
    }

    private updateCollapseButton() {
        const hostElement = this.viewContainer.element.nativeElement as HTMLElement;
        this.updateVertical();
        if (this.hasVisibleSibling(hostElement)) {
            if (!this.buttonComponent) {
                if (this.isCollapsed) {
                    this.collapse(true);
                    // transition will be added after collapsed
                } else {
                    this.addTransition(hostElement);
                }

                this.buttonComponent = this.viewContainer.createComponent(CollapsibleButtonComponent);
                // createComponent above creates the button as a sibling of the element this directive is inserted
                // - the next lines move it from sibling to first child
                hostElement.insertBefore(this.buttonComponent.location.nativeElement, hostElement.firstChild);

                this.renderer.addClass(hostElement.parentElement, CollapsibleParentContainer);
                this.renderer.addClass(hostElement, CollapsibleContainer);

                this.buttonSub = this.buttonComponent.instance.buttonClicked.subscribe(() => this.onClick());
                this.buttonComponent.instance.isVertical = this.isVertical;
                this.buttonComponent.instance.isCollapsed = this.isCollapsed;
            }
        } else if (this.buttonComponent) {
            this.cleanupButton();
            if (this.isCollapsed) {
                this.expand(true);
            }
        }
    }

    private cleanupButton() {
        const hostElement = this.viewContainer.element.nativeElement as HTMLElement;
        if (this.buttonComponent) {
            this.renderer.removeClass(hostElement.parentElement, CollapsibleParentContainer);
            this.renderer.removeClass(hostElement, CollapsibleContainer);
            this.removeTransition(hostElement);
            this.buttonComponent.destroy();

            this.buttonComponent = undefined;
        }
    }

    private onClick() {
        this.isCollapsed = !this.isCollapsed;
        this.updateVertical();
        this.isCollapsed ? this.collapse() : this.expand();
        LocalStorage.set(this.localStorageKey, this.isCollapsed);
        if (this.buttonComponent) {
            this.buttonComponent.instance.isCollapsed = this.isCollapsed;
        }
    }

    private collapse(immediate = false) {
        const hostElement = this.viewContainer.element.nativeElement as HTMLElement;
        if (immediate) {
            this.removeTransition(hostElement);
        }

        this.lastHeight = hostElement.getBoundingClientRect().height; // store height before the contents are removed
        // hide immediate or the content of the implementation kit will wrap and make it really high with width decreases
        this.renderer.addClass(hostElement, HideChildrenClass);
        this.renderer.addClass(hostElement, this.isVertical ? VerticalCollapsedClass : HorizontalCollapsedClass);
        if (!this.isVertical) {
            // set this to prevent the entire dialog height contracting while collapsing
            hostElement.style.minHeight = `${this.lastHeight}px`;
            this.addSiblingsFlexGrow(hostElement);
        }

        // Some checks here:
        // - this directive is currently just used in meeting notes and workflow run dialog on top/left most element
        // - not implementing for the case where it is not used yet - e.g. container other than flex
        // - only do some checks here rather than while initializing button as the siblings may not be fully loaded during init
        const parentElement = hostElement.parentElement!;
        const parentDisplay = window.getComputedStyle(parentElement).display;
        if (parentDisplay !== "flex" && parentDisplay !== "inline-flex") {
            // so far this directive is only used for flex containers - if this is used somewhere else, implement the flexGrow equivalent for the container
            window.console.warn("adaptCollapsible directive is used on an element which parent container does not has a flex display type. Siblings won't be stretched correctly.");
        }

        // only warn about this if not immediate - immediate will be collapsed on creation, i.e. siblings not completely initialized and positioned yet.
        if (!ElementUtilities.isElementTopLeftMost(hostElement) && !immediate) {
            window.console.warn("adaptCollapsible directive is used on an element with something left or above - button placement not implemented for this!");
        }

        setTimeout(() => {
            // tasks after animation
            this.updateVertical();
            if (immediate) {
                this.addTransition(hostElement);
            }
        }, 500);
    }

    private expand(immediate = false) {
        const hostElement = this.viewContainer.element.nativeElement as HTMLElement;
        this.renderer.removeClass(hostElement, this.isVertical ? VerticalCollapsedClass : HorizontalCollapsedClass);
        if (immediate || this.isVertical) {
            // without showing children, vertical height won't restore
            // - vertical height is usually auto, i.e. transition animation won't work anyway!
            this.renderer.removeClass(hostElement, HideChildrenClass);
            if (this.isVertical) {
                // want to keep the width or the container will narrow down while animating
                this.renderer.addClass(hostElement, VerticalExpandTransition);
            }

            if (immediate) {
                this.removeTransition(hostElement);
                setTimeout(() => {
                    this.addTransition(hostElement);
                    if (this.isVertical) {
                        this.renderer.removeClass(hostElement, VerticalExpandTransition);
                    }
                    this.updateVertical();
                    this.expanded.emit();
                }, 100);
            }
        }

        if (!immediate) {
            setTimeout(() => {
                // tasks after expand animation
                if (this.isVertical) {
                    this.renderer.removeClass(hostElement, VerticalExpandTransition);
                } else {
                    // only horizontal need to show children after animation
                    this.renderer.removeClass(hostElement, HideChildrenClass);
                    this.removeSiblingsFlexGrow(hostElement);
                    hostElement.style.minHeight = ""; // clear previous override to maintain height
                }
                this.updateVertical();
                this.expanded.emit();
            }, 500);
        }
    }

    private addTransition(element: HTMLElement) {
        this.renderer.addClass(element.parentElement, AnimateTransitionClass);
    }

    private removeTransition(element: HTMLElement) {
        this.renderer.removeClass(element.parentElement, AnimateTransitionClass);
    }

    private addSiblingsFlexGrow(element: HTMLElement) {
        ElementUtilities.getSiblings(element).forEach((sibling) => this.renderer.addClass(sibling, HorizontalCollapsedSibling));
    }

    private removeSiblingsFlexGrow(element: HTMLElement) {
        ElementUtilities.getSiblings(element).forEach((sibling) => this.renderer.removeClass(sibling, HorizontalCollapsedSibling));
    }

    private hasVisibleSibling(element: HTMLElement) {
        const siblings = ElementUtilities.getSiblings(element);
        return siblings.some((sibling) => ElementUtilities.isVisible(sibling));
    }

    private updateVertical() {
        const element = this.viewContainer.element.nativeElement as HTMLElement;
        const previousVertical = this.isVertical;
        this.isVertical = !ElementUtilities.hasSiblingsOnTheSameRow(element);
        if (this.buttonComponent) {
            this.buttonComponent.instance.isVertical = this.isVertical;
        }

        return previousVertical !== this.isVertical;
    }
}

<div *ngIf="readonly; else editable"
     class="d-flex gap-2">
    <i *ngIf="currentBehaviour as currBehaviour"
       class="flex-shrink-0 align-self-baseline"
       [adaptTooltip]="currBehaviour.tooltip"
       [ngClass]="['text-' + currBehaviour.type, currBehaviour.icon, 'behaviour-icon']"></i>
    <div class="behaviour-content align-self-center"
         [froalaView]="behaviour.question"></div>
</div>

<ng-template #editable>
    <div class="d-flex align-items-start gap-1"
         data-test="behaviour-edit">
        <dx-button-group class="d-block"
                         [height]="40"
                         [items]="behaviourOptions"
                         [(selectedItemKeys)]="selectedBehaviour"
                         keyExpr="isGood"
                         stylingMode="outlined"
                         (onItemClick)="updateBehaviourType($event.itemData.isGood)">
            <div *dxTemplate="let data of 'content'"
                 [adaptTooltip]="data.tooltip"
                 [attr.data-test]="data.selector">
                <i class="dx-icon mx-1"
                   [ngClass]="data.icon"></i>
            </div>
        </dx-button-group>

        <dx-validation-group #validationGroup
                             class="d-flex flex-grow-1">
            <dx-text-area class="flex-grow-1"
                          [autoResizeEnabled]="true"
                          [minHeight]="40"
                          [(value)]="behaviour.question"
                          (valueChange)="behaviourChange.emit(behaviour)"
                          placeholder="Enter a behaviour here (e.g. Be polite)"
                          data-test="behaviour-content">
                <dx-validator>
                    <dxi-validation-rule type="required"
                                         message="Please enter a behaviour"></dxi-validation-rule>
                    <dxi-validation-rule type="custom"
                                         [validationCallback]="validateBehaviourTypeSelected"
                                         [reevaluate]="true"
                                         message="Please select a behaviour type"></dxi-validation-rule>
                </dx-validator>
            </dx-text-area>
        </dx-validation-group>
    </div>
</ng-template>

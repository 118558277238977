<div role="alert"
     class="alert alert-danger"
     *ngIf="errorMessage">{{errorMessage}}
</div>
<div *adaptLoading="isLoading">
    <div [ngSwitch]="cardDetails">
        <div *ngSwitchCase="undefined"
             class="alert alert-warning">
            <p>Your organisation is paying by credit card, however, no credit card information has been provided.</p>
        </div>
        <div *ngSwitchDefault>
            <dd class="mb-3">
                <div *ngIf="cardDetails!.name">Name: {{cardDetails!.name}}</div>
                <div>{{cardDetails!.brand}} **** **** **** {{cardDetails!.last4}}</div>
                <div>Exp: {{cardDetails!.expiryMonth}} / {{cardDetails!.expiryYear}}</div>
            </dd>
        </div>
    </div>
    <button *ngIf="allowEditing && !isEditMode"
            adaptButton="primary"
            [adaptBlockingClick]="showUpdateCreditCardDialog">Update payment method</button>
</div>

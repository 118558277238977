import { Component, Inject, OnInit } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { lastValueFrom } from "rxjs";
import { UserManagementService } from "../user-management.service";

@Component({
    selector: "adapt-activation-url-dialog",
    templateUrl: "./activation-url-dialog.component.html",
    styleUrls: ["./activation-url-dialog.component.scss"],
})
export class ActivationUrlDialogComponent extends BaseDialogComponent<Person> implements OnInit {
    public readonly dialogName = "ActivationUrl";

    public copiedValue = false;
    public hasConfirmedEmail?: boolean;
    public url?: string;

    constructor(
        @Inject(ADAPT_DIALOG_DATA) public person: Person,
        private userManagementService: UserManagementService,
    ) {
        super();
    }

    public async ngOnInit() {
        this.hasConfirmedEmail = await lastValueFrom(this.userManagementService.getEmailStatusForPerson(this.person.personId));
        if (!this.hasConfirmedEmail) {
            this.url = await lastValueFrom(this.userManagementService.getActivationUrl(this.person.personId));
        }
    }

    public async copyToClipboard(text: string) {
        await navigator.clipboard.writeText(text);
        this.copiedValue = true;
        // reset back to uncopied so the button looks like it can be pressed again.
        setTimeout(() => this.copiedValue = false, 5000);
    }
}

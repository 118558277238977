import { Component, OnInit } from "@angular/core";
import { GuidingPhilosophy } from "@common/ADAPT.Common.Model/organisation/guiding-philosophy";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { MeetingAgendaItemComponent } from "@org-common/lib/meetings/meeting-agenda-component-registry";
import { CommonPurposeVisionService } from "@org-common/lib/purpose-vision/common-purpose-vision.service";
import { CommonPurposeVisionAuthService } from "@org-common/lib/purpose-vision/common-purpose-vision-auth.service";
import { lastValueFrom } from "rxjs";
import { PurposeVisionUiService } from "../../../../purpose-vision/purpose-vision-ui.service";

@MeetingAgendaItemComponent("adapt-craft-vision-workshop-record-vision")
@Component({
    selector: "adapt-craft-vision-workshop-record-vision",
    templateUrl: "./craft-vision-workshop-record-vision.component.html",
    styleUrls: ["./craft-vision-workshop-record-vision.component.scss"],
})
export class CraftVisionWorkshopRecordVisionComponent extends BaseComponent implements OnInit {
    public guidingPhilosophy?: GuidingPhilosophy;
    public readonly CompassGuidingPhilosophyEdit = CommonPurposeVisionAuthService.EditPurposeVision;

    public constructor(
        private commonPurposeVisionService: CommonPurposeVisionService,
        private purposeVisionUiService: PurposeVisionUiService,
    ) {
        super();
    }

    public async ngOnInit() {
        this.guidingPhilosophy = await lastValueFrom(this.commonPurposeVisionService.getOrCreateVision());
    }

    @Autobind
    public recordVision() {
        return this.purposeVisionUiService.editVision(this.guidingPhilosophy!);
    }
}

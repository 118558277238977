import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { ConnectLocalCalendarIntegrationComponent } from "./connect-local-calendar-integration/connect-local-calendar-integration.component";


@NgModule({
    imports: [
        CommonModule,
        AdaptButtonModule,
    ],
    declarations: [
        ConnectLocalCalendarIntegrationComponent,
    ],
    exports: [
        ConnectLocalCalendarIntegrationComponent,
    ],
})
export class AdaptLocalCalendarIntegrationModule {}

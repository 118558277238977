import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptImplementationKitModule } from "@common/implementation-kit/implementation-kit.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptSeeMoreModule } from "@common/ux/see-more/see-more.module";
import { AdaptStyledDashboardElementModule } from "@common/ux/styled-dashboard-element/styled-dashboard-element.module";
import { AdaptCommonMeetingsModule } from "@org-common/lib/meetings/common-meetings.module";
import { AdaptMeetingsSharedModule } from "@org-common/lib/meetings/meetings-shared.module";
import { ShowWorkflowMeetingComponent } from "@org-common/lib/meetings/show-workflow-meeting/show-workflow-meeting.component";
import { DxTextAreaModule } from "devextreme-angular";
import { DefineSeedComponent } from "./define-seed/define-seed.component";
import { RunWorkshopStepComponent } from "./run-workshop-step/run-workshop-step.component";
import { ScheduleMeetingStepComponent } from "./schedule-meeting-step/schedule-meeting-step.component";

@NgModule({
    declarations: [
        DefineSeedComponent,
        ScheduleMeetingStepComponent,
        RunWorkshopStepComponent,
    ],
    imports: [
        CommonModule,
        AdaptLoadingSpinnerModule,
        AdaptButtonModule,
        AdaptImplementationKitModule,
        AdaptCommonMeetingsModule,
        AdaptMeetingsSharedModule,
        DxTextAreaModule,
        AdaptHtmlEditorModule,
        AdaptSeeMoreModule,
        AdaptStyledDashboardElementModule,
    ],
})
export class AdaptAltoWorkflowsSharedModule {
    // prevent being optimised by tree-shaker
    public readonly workflowStepComponents = [
        ScheduleMeetingStepComponent,
        RunWorkshopStepComponent,
        ShowWorkflowMeetingComponent,
        DefineSeedComponent,
    ];
}

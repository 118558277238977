import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Organisation } from "./organisation";

export class Label extends BaseEntity<Label> {
    public labelId!: number;
    public organisationId!: number;
    public name!: string;
    public lastUsed?: Date;

    public organisation!: Organisation;
}

export const LabelBreezeModel = new BreezeModelBuilder("Label", Label)
    .hasSource()
    .withIdField()
    .withLabelField("name")
    .isOrganisationEntity()
    .build();

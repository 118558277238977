import { WorkflowDialogWidth, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { WorkflowStepGuidancePosition } from "@common/ADAPT.Common.Model/embed/workflow-step";
import { InputType } from "@common/ADAPT.Common.Model/organisation/input";
import { InputGroupType } from "@common/ADAPT.Common.Model/organisation/input-group";
import { InputGroupTypeMetadata, InputTypeMetadata } from "@common/ADAPT.Common.Model/organisation/input-type-metadata";
import { buildLocalWorkflow } from "../../workflow/workflow.interface";
import { IAddCompetitorAnalysisData } from "./add-competitor-analysis-input/add-competitor-analysis-input.component";

export const AddCompetitorAnalysisWorkflow = buildLocalWorkflow({
    name: "Competitor Analysis",
    workflowId: "AddCompetitor",
    type: WorkflowType.Workflow,
    dialogWidth: WorkflowDialogWidth.Large,
    steps: [{
        ordinal: 0,
        name: "Name and description",
        componentSelector: "adapt-add-competitor-analysis-name-description",
        canSkip: false,
        articleSlug: InputGroupTypeMetadata[InputGroupType.Competitor].articleId,
        articlePosition: WorkflowStepGuidancePosition.Left,
    }, {
        ordinal: 1,
        name: "Why do they win",
        componentSelector: "adapt-add-competitor-analysis-input",
        allowBack: true,
        canSkip: true,
        articleSlug: InputTypeMetadata[InputType.Win].articleId,
        articlePosition: WorkflowStepGuidancePosition.Left,
        customData: {
            inputType: InputType.Win,
        } as IAddCompetitorAnalysisData,
    }, {
        ordinal: 2,
        name: "Why do they lose",
        componentSelector: "adapt-add-competitor-analysis-input",
        allowBack: true,
        canSkip: true,
        articleSlug: InputTypeMetadata[InputType.Lose].articleId,
        articlePosition: WorkflowStepGuidancePosition.Left,
        customData: {
            inputType: InputType.Lose,
        } as IAddCompetitorAnalysisData,
    }, {
        ordinal: 3,
        name: "Where do they compete",
        componentSelector: "adapt-add-competitor-analysis-input",
        allowBack: true,
        canSkip: true,
        articleSlug: InputTypeMetadata[InputType.Compete].articleId,
        articlePosition: WorkflowStepGuidancePosition.Left,
        customData: {
            inputType: InputType.Compete,
        } as IAddCompetitorAnalysisData,
    }],
});

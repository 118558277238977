import { AfterViewInit, Component, Input } from "@angular/core";
import { GuidedTourService } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourRegistry } from "@common/lib/guided-tour/guided-tour-registrar";
import { RouteService } from "@common/route/route.service";
import { ReturnPathTourIdentifier } from "./return-path-tour";

@Component({
    selector: "adapt-return-path-button",
    templateUrl: "./return-path-button.component.html",
})
export class ReturnPathButtonComponent implements AfterViewInit {

    @Input() public returnPath?: string;
    @Input() public buttonText = "Return";
    @Input() public returnPathTour = ReturnPathTourIdentifier;
    @Input() public ignoreReturnPathTourCount = false;

    public constructor(
        private routeService: RouteService,
        private guidedTourService: GuidedTourService,
    ) { }

    public ngAfterViewInit() {
        this.guidedTourService.run(GuidedTourRegistry.get(this.returnPathTour), undefined, true);
    }

    public return() {
        this.routeService.navigateByUrl(this.returnPath!);
    }
}

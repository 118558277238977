<adapt-dialog [maxWidth]="600"
              [expandable]="false"
              [showHelpButton]="false"
              [minimal]="true"
              *ngIf="dialogData">
    <div adapt-dialog-content>
        <h1 *ngIf="dialogData.headingIcon"
            class="mb-4">
            <i [ngClass]="dialogData.headingIcon"></i>
        </h1>

        <h2 [innerHtml]="dialogData.title"></h2>
        <div *ngIf="dialogData.message"
             [froalaView]="dialogData.message"></div>

        <ng-content></ng-content>

        <div *ngIf="dialogData.checkboxMessage"
             class="mt-3">
            <dx-check-box [value]="checkboxConfirmation"
                          (valueChange)="checkboxConfirmation = $any($event)"
                          [text]="dialogData.checkboxMessage"
                          data-test="confirm-checkbox"></dx-check-box>
        </div>

        <div class="inner-dialog-footer mt-5">
            <div class="d-flex flex-grow-1 justify-content-center gap-3">
                <button adaptButton="primary"
                        [disabled]="!checkboxConfirmation"
                        (click)="confirm()"
                        data-test="standard-dialog-save-button">{{dialogData.confirmButtonText}}</button>
            </div>
            <div *ngIf="!dialogData.hideCancelButton"
                 class="mt-4 d-flex flex-grow-1 justify-content-center gap-3">
                <a role="button"
                   data-test="standard-dialog-cancel-button"
                   (click)="cancel()">{{dialogData.cancelButtonText}}</a>
            </div>
        </div>
    </div>
</adapt-dialog>

<adapt-theme-test-container label="Components"
                            *ngIf="isActivated">

    <h1>Text</h1>
    <div id="text"
         class="section">
        <h1 class="h1-normal">H1 with a <a href="#">link that goes somewhere</a></h1>
        <h2>H2 with a <a href="#">link that goes somewhere</a></h2>
        <h3>H3 with a <a href="#">link that goes somewhere</a></h3>
        <h4>H4 with a <a href="#">link that goes somewhere</a></h4>
        <h5>H5 with a <a href="#">link that goes somewhere</a></h5>
        <h6>H6 with a <a href="#">link that goes somewhere</a></h6>
        <p>Within a paragraph we have this text and a <a href="#">link that goes somewhere</a>.</p>
    </div>

    <h1>Buttons</h1>
    <div id="buttons"
         class="section">
        <h4>Buttons (enabled)</h4>
        <div>
            <button *ngFor="let button of buttons; trackBy: buttonTrackBy"
                    class="my-1 mx-3"
                    [adaptButton]="button"
                    [adaptTooltip]="button.preset">{{!button.buttonText ? button.preset : ""}}</button>
        </div>
    </div>

    <div class="section">
        <h4>Buttons (disabled)</h4>
        <div>
            <button *ngFor="let button of buttons; trackBy: buttonTrackBy"
                    class="my-1 mx-3"
                    [disabled]="true"
                    [adaptButton]="button"
                    [adaptTooltip]="button.preset">{{!button.buttonText ? button.preset : ""}}</button>
        </div>
    </div>

    <div class="section">
        <h4>Pill Buttons</h4>
        <div>
            <button *ngFor="let button of buttons; trackBy: buttonTrackBy"
                    class="btn-pill my-1 mx-3"
                    [adaptButton]="button"
                    [adaptTooltip]="button.preset">{{!button.buttonText ? button.preset : ""}}</button>
        </div>
    </div>

    <!-- <div class="section">
        <h4>ADAPT Button Icons</h4>
        <div>
            <ng-container *ngFor="let button of buttons; trackBy: buttonTrackBy">
                <button *ngIf="button.iconClass"
                        class="my-1 mx-3"
                        [adaptButton]="button"
                        [adaptTooltip]="button.preset">{{!button.buttonText ? "Placeholder" : ""}}</button>
            </ng-container>
        </div>
    </div> -->

    <div id="alerts"
         class="section">
        <h1>Alerts</h1>
        <div class="mb-3">
            <div *ngFor="let alert of ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark']">
                <div class="alert alert-{{alert}}"
                     role="alert">
                    A simple {{alert}} alert, check it out!
                </div>
            </div>
        </div>
    </div>

    <div id="cards"
         class="section">
        <h1>Cards</h1>
        <div class="card">
            <div class="card-header">
                <h2 class="card-title">Standard Card <a href="#">a card title link</a></h2>
                <div class="card-header-buttons">
                    <button adaptButtonIcon="edit">
                    </button>
                    <button adaptButtonIcon="fal fa-chevron-circle-right">
                        Button
                    </button>
                </div>
            </div>
            <div class="card-body">
                <p>Content here</p>
                <p>When creating, use "card"</p>
            </div>
            <div class="card-footer">
                Footer goes here
            </div>
        </div>

        <div class="card card-dashboard">
            <div class="card-header">
                <h2 class="card-title">Dashboard Card <a href="#">a card title link</a></h2>
                <div class="card-header-buttons">
                    <button adaptButtonIcon="edit"></button>
                    <button adaptButtonIcon="fal fa-chevron-circle-right">
                        Button
                    </button>
                </div>
            </div>
            <div class="card-body">
                <p>Content here</p>
                <p>When creating, use "card card-dashboard"</p>
            </div>
            <div class="card-footer">
                Footer goes here
            </div>
        </div>

        <div class="card card-borderless">
            <div class="card-header">
                <h2 class="card-title">Borderless Card <a href="#">a card title link</a></h2>
                <div class="card-header-buttons">
                    <button adaptButtonIcon="edit"></button>
                    <button adaptButtonIcon="fal fa-chevron-circle-right">
                        Button
                    </button>
                </div>
            </div>
            <div class="card-body">
                <p>Content here</p>
                <p>When creating, use "card card-borderless"</p>
            </div>
            <div class="card-footer">
                Footer goes here
            </div>
        </div>
    </div>

    <div id="devexpress"
         class="section">
        <h1>DevExpress</h1>

        <h2>Grid</h2>
        <dx-data-grid [dataSource]="gridDataSource"
                      [columnAutoWidth]="true"
                      [rowAlternationEnabled]="true"
                      [hoverStateEnabled]="true"
                      [showRowLines]="true"
                      [showBorders]="true">
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-paging [pageSize]="4"></dxo-paging>
            <dxi-column dataField="name"
                        dataType="string"
                        caption="First Name"></dxi-column>
            <dxi-column dataField="date"
                        dataType="date"
                        caption="Date"></dxi-column>
            <dxi-column dataField="rank"
                        dataType="number"
                        caption="Rank"></dxi-column>
        </dx-data-grid>

        <h2>Text Box</h2>
        <dx-text-box></dx-text-box>
        <dx-text-box [isValid]="false"></dx-text-box>

        <h2>Select Box</h2>
        <dx-select-box [items]="[1,2,3,4]"></dx-select-box>

        <h2>List</h2>
        <dx-list [items]="[1,2,3,4]"></dx-list>

        <h2>Tag Box</h2>
        <dx-tag-box [items]="[1,2,3,4]"></dx-tag-box>

        <h2>Progress Bar</h2>
        <adapt-progress-bar [percentageProgress]="45"></adapt-progress-bar>
    </div>

    <div id="dashboardelement"
         class="section">
        <h1>Dashboard Elements</h1>
        <adapt-dashboard-element name="Dashboard Element 1">
            <div adapt-dashboard-title-buttons>
                <button adaptButton="add">Something</button>
            </div>
            <p>Projected dashboard content goes here</p>
        </adapt-dashboard-element>

        <adapt-dashboard-element name="Dashboard Element 2 - With A Header Link"
                                 url="#">
            <div adapt-dashboard-title-buttons>
                <button adaptButton="add">Something</button>
            </div>
            <p>Projected dashboard content goes here</p>
        </adapt-dashboard-element>
    </div>

    <!-- <div class="section">
        <adapt-deep-dive [deepDive]="deepDiveScorm"></adapt-deep-dive>
    </div> -->

    <div id="menus"
         class="section">
        <h1>Menus</h1>
        <adapt-menu [items]="menuItems"></adapt-menu>
        <adapt-menu [items]="menuItemsMixed"></adapt-menu>
        <adapt-menu [items]="menuItemsIconLess"></adapt-menu>
    </div>
</adapt-theme-test-container>

import { NgModule } from "@angular/core";
import { RenderComponentComponent } from "./render-component.component";

@NgModule({
    exports: [
        RenderComponentComponent,
    ],
    declarations: [
        RenderComponentComponent,
    ],
})
export class AdaptRenderComponentModule { }

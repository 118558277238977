<div *adaptLoadingUntilFirstEmit="let values of values$">
    <ul *ngIf="values.length > 0; else noValuesTemplate">
        <li *ngFor="let value of values">
            <a [routerLink]="value.url"
               [queryParams]="value.queryParams">{{value.name}}</a>
        </li>
    </ul>
    <ng-template #noValuesTemplate>
        <adapt-call-to-action-inline *ngIf="showCTA"
                                     [featureUrl]="vcUrl$ | async">
            Adapt's values tool provides a place to capture and maintain your
            {{(team$ | async) ? "team's" : "organisation's"}} values.
        </adapt-call-to-action-inline>

        <span *ngIf="!showCTA">No values have been defined.</span>
    </ng-template>
</div>

import { BaseConnectionState } from "./base-connection-state";
import { ConnectingState } from "./connecting-state";
import { ConnectionEvent } from "./connection-event.enum";
import { ConnectionInterruptedState } from "./connection-interrupted-state";
import { IConnectionState } from "./connection-state.interface";
import { ReconnectingState } from "./reconnecting-state";
import { SignalRConnectionContext } from "./signalr-connection-context";

export class ConnectedState extends BaseConnectionState {
    public readonly event = ConnectionEvent.Connected;

    public constructor(
        context: SignalRConnectionContext,
        previousState: IConnectionState,
        connectionMessage?: string,
    ) {
        super(context);

        if (!connectionMessage) {
            connectionMessage = `SignalR now connected to ${this.signalREndpoint}. Id: ${this.connection.connectionId}`;
        }

        this.log.info(connectionMessage);
        context.connectionDeferred.resolve(this.connection.connectionId ?? undefined);

        if (!(previousState instanceof ConnectingState)) {
            context.raiseConnectionEvent(ConnectionEvent.Reestablished);
        }
    }

    public interruptConnection(): void {
        this.context.state = new ConnectionInterruptedState(this.context);
    }

    public startReconnecting(): void {
        this.context.state = new ReconnectingState(this.context, this);
    }
}

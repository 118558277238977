<adapt-dialog [maxWidth]="500">
    <h1 adapt-dialog-title>Update payment method</h1>

    <ng-container adapt-dialog-content>
        <p>
            To make changes to your {{ProjectLabel}} subscription, please contact our support team:
        </p>
        <p>
            Landline: +61 8 9489 8200
        </p>
        <p>
            Email: <a href="mailto:info@theadaptway.com">info&#64;theadaptway.com</a>
        </p>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveButtonPreset="close"
                                   [hideCancelButton]="true"
                                   (saveClick)="cancel()">
    </adapt-standard-dialog-buttons>
</adapt-dialog>

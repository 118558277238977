<!-- can't use tour on mobile so hide the CTA -->
<adapt-dismissible-cta class="d-none d-md-block"
                       [flag]="PersonFlag.AltoShellTourDismissed"
                       (dismissCta)="onDismiss($event)">
    <h2 adapt-cta-title>Hello {{person.firstName}}, are you ready to take a tour?</h2>

    <button adapt-cta-action
            data-tour="personal-dashboard-tour-cta"
            class="btn btn-primary px-4"
            (click)="runTour()">
        Start tour
        <i class="fal fa-fw fa-arrow-right ms-2"></i>
    </button>

    <div>
        You haven't taken a tour of <b>adapt HQ</b> yet!
        <br>
        Completing the tour will help you understand how to navigate the adapt HQ platform.
    </div>
</adapt-dismissible-cta>

import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationCategoryValue, WorkflowDialogWidth, WorkflowScope, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { buildLocalWorkflow } from "@org-common/lib/workflow/workflow.interface";
import { WorkflowIdentifier } from "@org-common/lib/workflow/workflow-identifier.enum";
import { TourIdentifier } from "../../../tours/tour-identifier.enum";
import { createActivityBriefWorkflow, createWorkshopScheduleWorkflows } from "../../workflow-utilities";

export const purposeWorkflowName = "Agree on purpose";

export const purposeWorkflow = buildLocalWorkflow({
    name: purposeWorkflowName,
    workflowId: WorkflowIdentifier.Purpose,
    type: WorkflowType.Journey,
    time: "3 hours",
    people: "Leadership team",
    mindset: "Collaborative",
    dialogWidth: WorkflowDialogWidth.ExtraLarge,
    overview: "Define and communicate the purpose so that people are connected to something more than just making money for the owner or shareholders.",
    articleSlug: ImplementationKitArticle.PurposeOutcomes,
    wrapUpSlug: ImplementationKitArticle.PurposeWrapUp,
    category: OrganisationCategoryValue.Foundation,
    scope: WorkflowScope.Team,
    featuresToEnable: [FeatureName.PurposeAndVision],
    wrapUpGuidedTourIdentifier: TourIdentifier.PurposeStatementWrapUpTour,
    ordinal: 30,
    compulsoryPrerequisites: [WorkflowIdentifier.DefineOwnersWhy],
    workflows: [
        createActivityBriefWorkflow(purposeWorkflowName, WorkflowIdentifier.Purpose, undefined, ImplementationKitArticle.PurposeMeetingDescription, ImplementationKitArticle.PurposeMeetingPreWork),
        ...createWorkshopScheduleWorkflows(purposeWorkflowName, WorkflowIdentifier.Purpose),
    ],
});

import { Component, Inject } from "@angular/core";
import { UserType } from "@common/ADAPT.Common.Model/embed/user-type";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { lastValueFrom } from "rxjs";
import { UserManagementService } from "../user-management.service";

@Component({
    selector: "adapt-reactivate-account-dialog",
    templateUrl: "./reactivate-account-dialog.component.html",
})
export class ReactivateAccountDialogComponent extends BaseDialogComponent<Person>{
    public readonly dialogName = "ReactivateAccountDialog";

    public connectionType: ConnectionType;
    public userType: UserType;
    public selectedAccessLevel?: Role;
    public allowLogin = true;

    public latestConnection: Connection;
    public UserType = UserType;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public person: Person,
        private userManagementService: UserManagementService,
    ) {
        super();

        this.latestConnection = this.getLatestConnection();
        this.userType = this.latestConnection.userType;
        this.connectionType = this.latestConnection.connectionType;
    }

    public async userTypeChange() {
        this.selectedAccessLevel = undefined;

        if (this.userType === UserType.Collaborator || this.userType === UserType.Viewer) {
            const accessLevels = await lastValueFrom(this.userManagementService.getAccessLevels(this.userType));
            if (accessLevels.length) {
                this.selectedAccessLevel = accessLevels[0];
            }
        }
    }

    @Autobind
    public async reactivate() {
        const roles: Role[] = [];
        if (this.selectedAccessLevel) {
            roles.push(this.selectedAccessLevel);
        }

        this.setErrorMessage(undefined);
        try {
            await this.userManagementService.reactivateAccount(this.latestConnection, this.connectionType, this.userType, roles, this.allowLogin);

            this.resolve(this.person);
        } catch (error) {
            if (error instanceof AdaptError) {
                this.setErrorMessage(error.message);
            }

            // Entities are created/changed by reactivateAccount (not tracked and it just issue a global save after that)
            // Need to cancel here if failed or else reattempting will result in attempting to save those previous failed entity +
            // new entities created/changed by the subsequent reactivateAccount
            this.userManagementService.cancel().subscribe();
        }
    }

    private getLatestConnection() {
        const latestEmployeeConnection = this.person.getLatestEmployeeConnection() as Connection | undefined;
        if (latestEmployeeConnection) {
            return latestEmployeeConnection;
        }

        const latestStakeholderConnection = this.person.getLatestConnection(ConnectionType.Stakeholder) as unknown as Connection;
        return latestStakeholderConnection;
    }
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Workflow } from "@common/ADAPT.Common.Model/embed/workflow";
import { WorkflowConnection } from "@common/ADAPT.Common.Model/organisation/workflow-connection";
import { WorkflowStatus, WorkflowStatusEnum } from "@common/ADAPT.Common.Model/organisation/workflow-status";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { RouteService } from "@common/route/route.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { lastValueFrom, merge, Observable, Subject } from "rxjs";
import { filter, finalize, switchMap, tap } from "rxjs/operators";
import { AuthorisationService } from "../../authorisation/authorisation.service";
import { WorkflowActionableStates, WorkflowActionState } from "../workflow.interface";
import { WorkflowService } from "../workflow.service";
import { WorkflowAuthService } from "../workflow-auth.service";
import { RunWorkflowSearchParam } from "../workflow-journey-page/workflow-journey-page.component";

@Component({
    selector: "adapt-display-workflow-outcome-header",
    templateUrl: "./display-workflow-outcome-header.component.html",
    styleUrls: ["./display-workflow-outcome-header.component.scss"],
})
export class DisplayWorkflowOutcomeHeaderComponent extends BaseComponent implements OnInit {
    public readonly WorkflowActionState = WorkflowActionState;

    @Input({ required: true }) public workflow!: Workflow;

    @Input() public showStartButton: boolean = false;
    @Input() public style: "burgundy" | "wise-lime" = "burgundy";
    @Input() public small = false;
    @Input() public startWorkflow$: Observable<void> = new Subject();
    @Input() public skipOutcomeStep = false;
    @Output() public isCompleted = new EventEmitter<boolean>();

    public workflowConnection?: WorkflowConnection;

    public state: WorkflowActionState = WorkflowActionState.NotStarted;
    public startWorkflowButtonTooltip?: string;
    public startWorkflowButtonText?: string;
    public startWorkflowButtonIcon?: string;

    // keep these numbers in sync with "@common/shell/images/people/person (x).png"
    private personImages = Array(12)
        .fill(0)
        .map((_, idx) => `/content/shell/images/people/person (${idx + 1}).png`);

    // get a random person image each time this component is created
    public personImage = this.personImages[this.personImages.length * Math.random() | 0];

    private workflowRunning = false;

    public constructor(
        private routeService: RouteService,
        private workflowService: WorkflowService,
        authorisationService: AuthorisationService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        merge(
            authorisationService.getHasAccess(WorkflowAuthService.EditWorkflow),
            rxjsBreezeService.entityTypeChanged(WorkflowConnection),
            rxjsBreezeService.entityTypeChanged(WorkflowStatus),
        ).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updateData());
    }

    public async ngOnInit() {
        // get status of workflow
        this.workflowService.getLatestWorkflowConnectionForWorkflow(this.workflow!).pipe(
            tap((workflowConnection) => this.workflowConnection = workflowConnection),
            switchMap(() => this.updateData()),
            this.takeUntilDestroyed(),
        ).subscribe();

        this.startWorkflow$.pipe(
            filter(() => !this.workflowRunning),
            switchMap(() => this.startWorkflow()),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public get isLocked() {
        return !WorkflowActionableStates.includes(this.state);
    }

    @Autobind
    public startWorkflow() {
        return this.workflowService.createWorkflowConnectionForWorkflow(this.workflow!).pipe(
            tap(({ workflowConnection }) => this.workflowConnection = workflowConnection),
            tap(() => {
                // we only show the start button on the journey page, so we should update the runWorkflow param when starting
                if (this.showStartButton) {
                    this.routeService.updateSearchParameterValue(RunWorkflowSearchParam, true, true);
                }
                this.workflowRunning = true;
            }),
            switchMap(({ workflowConnection }) => this.executeJourney(workflowConnection!)),
        );
    }

    private async updateData() {
        if (!this.workflow) {
            return;
        }

        if (!this.workflowConnection) {
            this.workflowConnection = await lastValueFrom(this.workflowService.getLatestWorkflowConnectionForWorkflow(this.workflow));
        }

        const status = await lastValueFrom(this.workflowService.getLatestStatusForWorkflow(this.workflow));
        const currentStatus = status?.status ?? WorkflowStatusEnum.Incomplete;

        this.state = await this.workflowService.getWorkflowState(this.workflowConnection, this.workflow);
        this.startWorkflowButtonTooltip = this.workflowService.getWorkflowStateTooltip(this.state, this.workflow);
        this.startWorkflowButtonText = this.workflowService.getWorkflowStateButtonText(this.state);
        this.startWorkflowButtonIcon = this.workflowService.getWorkflowStateButtonIcon(this.state, undefined, currentStatus);

        this.isCompleted.emit(currentStatus === WorkflowStatusEnum.Completed);
    }

    private executeJourney(connection: WorkflowConnection) {
        return this.workflowService.executeJourneyForConnection(connection, true, this.skipOutcomeStep).pipe(
            finalize(() => { // using 'tap' is not enough - it still flagged the workflow as running after you cancelled
                this.workflowRunning = false;
                this.routeService.updateSearchParameterValue(RunWorkflowSearchParam, undefined, true);
            }),
        );
    }
}

<div class="time-sch-wrapper">
    <div class="d-flex">
        <div class="d-flex align-items-center">
            <i class="fal fa-fw fa-2x"
               [style.cursor]="'pointer'"
               [ngClass]="{'fa-chevron-left': !!currentPeriod.timeFrameIncrement}"
               adaptTooltip="Move to previous time period"
               (click)="previousIncrement()"></i>
        </div>
        <div class="time-sch-table-wrapper">
            <!-- this is absolutely positioned so we can offset the current time indicator depending on if sections are shown or not -->
            <div *ngIf="showCurrentTime"
                 class="time-sch-current-time-wrapper"
                 [style.width]="showSections ? 'calc(100% - ' + sectionLeftWidth + 'px)' : '100%'"
                 [style.left.px]="showSections ? sectionLeftWidth : 0">
                <div class="time-sch-current-time"
                     [title]="currentTimeDetails.title"
                     [style.visibility]="currentTimeDetails.visible ? 'visible' : 'hidden'"
                     [style.left.%]="currentTimeDetails.positionPercentage"></div>
            </div>

            <table class="time-sch-table time-sch-table-header">
                <tr *ngFor="let timeFrameHeader of headers; let i = index; trackBy: trackByFn">
                    <td class="time-sch-section text-center"
                        *ngIf="i === 0 && showSections"
                        [rowSpan]="periods.length"></td>
                    <td class="text-center"
                        *ngFor="let dateTime of timeFrameHeader; trackBy: trackByFn"
                        [colSpan]="dateTime.colspan"
                        [title]="dateTime.tooltip? dateTime.tooltip : ''">{{dateTime.name}}</td>
                </tr>
            </table>

            <div class="time-sch-content-wrap">
                <table class="time-sch-table">
                    <tr *ngFor="let sectionItem of sectionItems; trackBy: trackByFn"
                        [style.height]="sectionItem.minRowHeight + 'px'">
                        <td #sectionTd
                            *ngIf="showSections"
                            [title]="sectionItem.section.tooltip? sectionItem.section.tooltip : ''"
                            [style.background-color]="sectionItem.section.colour"
                            [style.color]="sectionItem.section.colour ? '#FFFFFF' : undefined"
                            class="time-sch-section text-center">{{sectionItem.section.name}}</td>
                        <td *ngFor="let td of headers[headers.length - 1]; trackBy: trackByFn"
                            [ngClass]="td.cycle !== undefined ? 'cycle-' + td.cycle.toString() : undefined"
                            [colSpan]="td.colspan"></td>
                    </tr>
                </table>

                <div class="time-sch-section-wrapper"
                     [style.left.px]="sectionLeftWidth">
                    <div class="time-sch-section-container"
                         *ngFor="let sectionItem of sectionItems; trackBy: trackByFn"
                         [style.height]="sectionItem.minRowHeight + 'px'">
                        <div class="time-sch-item"
                             *ngFor="let itemMeta of sectionItem.itemProperties"
                             [ngClass]="{'time-sch-item-active': selectedItem === itemMeta.item}"
                             (click)="onItemClick(itemMeta, $event)"
                             (mouseenter)="onItemMouseMovement(itemMeta, $event, false)"
                             (mouseleave)="onItemMouseMovement(itemMeta, $event, true)"
                             [style]="itemMeta.item.styles"
                             [style.height]="minRowHeight + 'px'"
                             [style.top]="itemMeta.cssTop + 'px'"
                             [style.left]="itemMeta.cssLeft + '%'"
                             [style.width]="itemMeta.cssWidth + '%'"
                             [style.opacity]="itemMeta.item.disabled || itemMeta.item.transparent ? 0.5 : 1"
                             [style.cursor]="itemMeta.item.disabled ? 'not-allowed' : 'pointer'">
                            <div class="time-sch-item-content"
                                 [title]="itemMeta.item.tooltip? itemMeta.item.tooltip : ''">
                                {{itemMeta.item.name}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="currentPeriod.timeFrameIncrement"
             class="d-flex align-items-center">
            <i class="fal fa-fw fa-2x"
               [style.cursor]="'pointer'"
               [ngClass]="{'fa-chevron-right': !!currentPeriod.timeFrameIncrement}"
               adaptTooltip="Move to next time period"
               (click)="nextIncrement()"></i>
        </div>
    </div>
</div>

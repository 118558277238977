import { Injectable, Injector } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { IdentityStorageService } from "@common/identity/identity-storage.service";
import { IUserEventHandler } from "@common/user/user-event-handler.interface";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { IDialogEventHandler } from "@common/ux/adapt-common-dialog/dialog-event-handler.interface";
import { IOrganisationEventHandler } from "@org-common/lib/organisation/organisation-event-handler.interface";
import smartlookClient from "smartlook-client";

interface ISmartLookProperties {
    [property: string]: number | string | undefined;
}

@Injectable({
    providedIn: "root",
})
/**
 * Smartlook developer API can be found here: https://web.developer.smartlook.com/docs
 * and here: https://web.developer.smartlook.com/reference/getting-started-with-your-api
 */
export class SmartLookTracker implements IUserEventHandler, IOrganisationEventHandler, IDialogEventHandler {
    public constructor(
        private injector: Injector,
    ) {
        if (this.shouldTrack) {
            smartlookClient.init(AdaptClientConfiguration.AnalyticsProductId);
            smartlookClient.record({
                emails: true,
                forms: true,
                numbers: true,
                ips: true,
            });
        }
    }

    public userChanged(person?: Person) {
        if (!this.shouldTrack || !this.shouldTrackUser) {
            smartlookClient.pause();
            return;
        }

        // resume all tracking and identify the user
        smartlookClient.resume();
        if (person && person.personId && this.identityStorageService.isLoggedIn) {
            smartlookClient.identify(person.personId, {
                name: person.fullName,
                email: this.identityStorageService.userName!,
            });
        } else {
            smartlookClient.anonymize();
        }
    }

    public organisationChanged(organisation: Organisation) {
        if (!this.shouldTrack || !this.shouldTrackUser) {
            return;
        }

        if (organisation && organisation.organisationId) {

            // todo: no way to get this info right now
            /*smartlookClient.identify(person.personId, {
                organisationId: organisation.organisationId,
                organisationName: organisation.name,
            });*/
        }
    }

    public dialogOpened(dialog: BaseDialogComponent<any>, _data?: unknown) {
        this.track("Dialog opened", { DialogName: dialog.dialogName });
    }

    public dialogResolved(dialog: BaseDialogComponent<unknown>, _resolveData?: unknown) {
        this.track("Dialog resolved", { DialogName: dialog.dialogName });
    }

    public dialogCancelled(dialog: BaseDialogComponent<unknown>) {
        this.track("Dialog cancelled", { DialogName: dialog.dialogName });
    }

    private get identityStorageService() {
        // This will throw an error if we try to inject in the constructor
        // Should be able to put it up there once the service is converted
        // to angular
        return this.injector.get(IdentityStorageService);
    }

    private track(customEventId: string, properties?: ISmartLookProperties) {
        if (this.shouldTrack && this.shouldTrackUser) {
            smartlookClient.track(customEventId, properties as any);
        }
    }

    /**
     * Helper property to determine Whether this tracker should be activated or not.
     *
     * Note: We don't commonly record analytics for qa or dev environments, because the session count is extremely high and will use up our allowance very quickly.
     */
    private get shouldTrack() {
        return !!AdaptClientConfiguration.AnalyticsProductId;
    }

    /**
     * Helper property to determine whether a particular user should be tracked or not.
     *
     * Helpful when turning on/off classes of users for testing.
     */
    private get shouldTrackUser() {
        // const isAdapt = this.identityStorageService.userName?.endsWith("@adaptbydesign.com.au");
        // if (isAdapt) {
        //     this.log.debug("Smartlook tracking - skipping due to ADAPT email address");
        // }

        // return !isAdapt;

        return true;
    }
}

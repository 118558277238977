import { Component, ElementRef, Input } from "@angular/core";
import { Survey, SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { SurveyResponseGroup, SurveyResponseGroupLabel } from "@common/ADAPT.Common.Model/organisation/survey-response";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ChartUtils } from "@common/ux/base-ui.service/chart-utils";
import { IDxChartCustomizePoint } from "@common/ux/dx.types";
import { DoneEvent } from "devextreme/viz/chart";
import { ReplaySubject } from "rxjs";
import { SurveyUtils } from "../survey-utils";

enum ScoreType {
    AveragePercentageScore = "Assessment",
    PercentageParticipation = "Participation",
}

interface IChartData {
    responseGroup: SurveyResponseGroup;
    valueType: ScoreType;
    value: number;
}

@Component({
    selector: "adapt-display-multi-group-survey-result",
    styleUrls: ["./display-multi-group-survey-result.component.scss"],
    templateUrl: "./display-multi-group-survey-result.component.html",
})
export class DisplayMultiGroupSurveyResultComponent extends BaseComponent {
    @Input() public set survey(survey: Survey) {
        this.survey$.next(survey);
    }
    @Input() public displayCompact = false;

    public chartData: IChartData[] = [];

    private survey$ = new ReplaySubject<Survey>(1);
    private surveyType?: SurveyType;

    public constructor(elementRef: ElementRef) {
        super(elementRef);

        this.survey$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((survey) => {
            this.surveyType = survey.surveyType;

            // this defines the order they are appearing in the chart
            const responseGroups = [
                SurveyResponseGroup.All,
                SurveyResponseGroup.Leader,
                SurveyResponseGroup.NonLeader,
            ];
            this.chartData = [];
            for (const group of responseGroups) {
                const result = SurveyUtils.getSurveyResultForResponseGroup(survey, group);
                if (result) {
                    this.chartData.push({
                        responseGroup: result.responseGroup,
                        value: result.averagePercentageScore!,
                        valueType: ScoreType.AveragePercentageScore,
                    });

                    this.chartData.push({
                        responseGroup: result.responseGroup,
                        value: result.percentageParticipation!,
                        valueType: ScoreType.PercentageParticipation,
                    });
                }
            }
        });
    }

    public updateChartDimensionAndSubscribeSizeChange(e: DoneEvent) {
        ChartUtils.updateChartDimension(e);
        this.sizeChange$.subscribe(() => ChartUtils.updateChartDimension(e));
    }

    @Autobind
    public customizeChartPoint(e: IDxChartCustomizePoint) {
        if (this.surveyType) {
            const color = SurveyUtils.forSurveyType(this.surveyType).getStatusColorFromPercentage(e.value);
            return { color };
        } else {
            return {};
        }
    }

    public customizeArgumentLabel(e: IDxChartCustomizePoint) {
        return `${SurveyResponseGroupLabel[e.value as SurveyResponseGroup]}`;
    }

    public customizeSeriesLabel(e: IDxChartCustomizePoint) {
        return `${e.seriesName} : ${e.value.toFixed(0)}%`;
    }

    public pointToPercentage(e: IDxChartCustomizePoint) {
        return `${e.value.toFixed(0)}%`;
    }
}

import { Injectable } from "@angular/core";
import { NavigationHierarchyService } from "@common/route/navigation-hierarchy.service";
import { SidebarTabIconComponent } from "@common/shell/sidebar-tab-icon/sidebar-tab-icon.component";
import { IComponentRender } from "@common/ux/render-component/component-render.interface";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { MenuTabId } from "@org-common/lib/shell/menu-tab-content/menu-tab-id";
import { OrgCommonNavigationTab } from "@org-common/lib/sidebar/org-common-navigation-tab";
import { of } from "rxjs";
import { AltoNavigationService } from "../../navigation/alto-navigation.service";
import { MenuTabContentComponent } from "./menu-tab-content.component";

@Injectable()
export class MenuTab extends OrgCommonNavigationTab {
    public id = MenuTabId;
    public label = "Menu";
    public ordinal = 0;
    public icon: IComponentRender<SidebarTabIconComponent> = {
        component: SidebarTabIconComponent,
        params: {
            iconClass: AltoNavigationService.IconClass,
        },
    };

    public isDisplayed$ = of(true);

    public content: IComponentRender<MenuTabContentComponent> = {
        component: MenuTabContentComponent,
    };

    public constructor(
        navigationHierarchyService: NavigationHierarchyService,
        organisationService: OrganisationService,
    ) {
        super(navigationHierarchyService, organisationService);
    }

    protected getHierarchyId() {
        return AltoNavigationService.HierarchyId;
    }
}

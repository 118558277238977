import { Component, Input } from "@angular/core";
import { Label } from "@common/ADAPT.Common.Model/organisation/label";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { RouteService } from "@common/route/route.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IDxCustomRuleValidationCallbackEvent } from "@common/ux/dx.types";
import { EditSimpleValueBreezeEntityDialogComponent, IEditSimpleValueBreezeEntityDialogData, SimpleValueType } from "@common/ux/edit-simple-value-breeze-entity-dialog/edit-simple-value-breeze-entity-dialog.component";
import { ConfigureFeatureBase } from "@org-common/lib/configuration/base-components/configure-feature-base";
import { IConfigItem } from "@org-common/lib/configuration/configuration.interfaces";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { SearchService } from "@org-common/lib/search/search.service";
import { lastValueFrom } from "rxjs";
import { debounceTime, filter, switchMap, tap } from "rxjs/operators";
import { DeleteLabelDialogComponent, IConfirmDeleteLabelData } from "../delete-label-dialog/delete-label-dialog.component";
import { MergeLabelsDialogComponent } from "../merge-labels-dialog/merge-labels-dialog.component";

@Component({
    selector: "adapt-configure-labels",
    templateUrl: "./configure-labels.component.html",
    styleUrls: ["./configure-labels.component.scss"],
})
export class ConfigureLabelsComponent extends ConfigureFeatureBase {
    @Input() public configItem?: IConfigItem;
    public allLabels: Label[] = [];
    public selectedLabels: Label[] = [];

    public constructor(
        configurationService: ConfigurationService,
        private labellingService: LabellingService,
        private dialogService: AdaptCommonDialogService,
        private searchService: SearchService,
        private routeService: RouteService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super(configurationService);
        rxjsBreezeService.entityTypeChanged(Label).pipe(
            debounceTime(100),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.initialiseData());
    }

    @Autobind
    public async initialiseData() {
        this.allLabels = await lastValueFrom(this.labellingService.getAllLabels());
    }

    @Autobind
    public addNewLabel() {
        return this.labellingService.createLabel("").pipe(
            switchMap((label) => {
                const dialogData: IEditSimpleValueBreezeEntityDialogData = {
                    title: "Add New Label",
                    entities: [{
                        label: "Label Name",
                        entity: label,
                        fieldName: "name",
                        type: SimpleValueType.Text,
                        usePropertyValidator: true,
                        customValidators: [{
                            validator: ({ value }: IDxCustomRuleValidationCallbackEvent) => {
                                if (typeof value === "string") {
                                    return !this.allLabels.find((i) => i.name.trim().toLowerCase() === value.trim().toLowerCase());
                                }
                                return false;
                            },
                            message: "A label with that name already exists",
                        }],
                    }],
                    saveOnClose: true,
                };
                return this.dialogService.open(EditSimpleValueBreezeEntityDialogComponent, dialogData);
            }),
            tap(([label]) => this.allLabels.push(label as Label)),
            this.takeUntilDestroyed(),
        );
    }

    @Autobind
    public deleteLabel(label: Label) {
        const dialogData: IConfirmDeleteLabelData = {
            label,
        };
        return this.dialogService.open(
            DeleteLabelDialogComponent,
            dialogData,
        ).pipe(
            filter((accepted) => !!accepted?.result),
            switchMap(() => this.labellingService.remove(label)),
            switchMap(() => this.labellingService.save()),
            tap(() => ArrayUtilities.removeElementFromArray(label, this.allLabels)),
        );
    }

    @Autobind
    public mergeLabels() {
        // the button should be disabled if less than 2 labels are selected - so no need to check here
        return this.dialogService.open(MergeLabelsDialogComponent, this.selectedLabels).pipe(
            switchMap(() => this.labellingService.getAllLabels()),
            tap((labels) => {
                this.allLabels = labels;
                this.selectedLabels = [];
            }),
            this.takeUntilDestroyed(),
        );
    }

    @Autobind
    public renameLabel(label: Label) {
        const renameDialog: IEditSimpleValueBreezeEntityDialogData = {
            title: "Edit Label",
            entities: [{
                label: "Label Name",
                entity: label,
                fieldName: "name",
                type: SimpleValueType.Text,
                usePropertyValidator: true,
                customValidators: [{
                    validator: ({ value }: IDxCustomRuleValidationCallbackEvent) => {
                        if (typeof value === "string") {
                            return !this.allLabels.find((i) => i.name.trim().toLowerCase() === value.trim().toLowerCase() && i.labelId !== label.labelId);
                        }
                        return false;
                    },
                    message: "A label with that name already exists",
                }],
            }],
            saveOnClose: true,
        };
        return this.dialogService.open(EditSimpleValueBreezeEntityDialogComponent, renameDialog);
    }

    @Autobind
    public async searchLabel(label: Label) {
        this.searchService.getSearchUrl$({
            keyword: undefined,
            labelIds: new Set<number>([label.labelId]),
        }).pipe(
            switchMap((search) => this.routeService.navigateByUrl(search.url)),
            this.takeUntilDestroyed(),
        ).subscribe();
    }
}

import { BaseEntity } from "@common/ADAPT.Common.Model/base-entity";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { MeetingAgendaTemplate } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-template";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { EntityExtensions } from "../entity-extensions.decorator";
import { MeetingAgendaItemExtensions } from "./meeting-agenda-item-extensions";
import { MeetingAgendaItemSupplementaryData } from "./meeting-agenda-item-supplementary-data";

export enum MeetingAgendaItemStatus {
    NotStarted = "NotStarted",
    InProgress = "InProgress",
    Ended = "Ended",
}

// copied in meeting.cy.ts
export enum MeetingAgendaItemType {
    AgendaItem = "AgendaItem",
    PreWork = "PreWork",
}

export class MeetingAgendaItem extends BaseEntity<MeetingAgendaItem> {
    public meetingAgendaItemId!: number;
    public meetingId!: number;
    public meetingAgendaTemplateId!: number;
    public name!: string;
    public plannedDurationInMinutes?: number;
    public status!: MeetingAgendaItemStatus;
    public type!: MeetingAgendaItemType;
    public ordinal!: number;
    public articleSlug?: ImplementationKitArticle;
    public componentSelector?: string;

    public supplementaryData?: MeetingAgendaItemSupplementaryData;
    public meeting?: Meeting;
    public meetingAgendaTemplate?: MeetingAgendaTemplate;

    @EntityExtensions(MeetingAgendaItemExtensions)
    public extensions!: MeetingAgendaItemExtensions;
}

export const MeetingAgendaItemBreezeModel = new BreezeModelBuilder("MeetingAgendaItem", MeetingAgendaItem)
    .hasSource()
    .isOrganisationEntity()
    .persistChangedEntity()
    .build();

<adapt-confirmation-dialog (closed)="onDialogClosed($event)"
                           (initialised)="onInitialised($event)">
    <div *ngIf="hasImpact">
        The impact of removing
        <adapt-display-label [label]="this.label"
                             [adaptLinkDisabled]="true"></adapt-display-label>
        will result in:
        <ul class="mt-2">
            <li>
                <adapt-display-label [label]="this.label"
                                     [adaptLinkDisabled]="true"></adapt-display-label>
                will be removed from the following:
                <ul>
                    <li *ngIf="sourceItemCount > 0">{{sourceItemCount}} item{{sourceItemCount === 1 ? "" : "s"}}</li>
                    <li *ngIf="sourceKeyFunctionCount > 0">{{sourceKeyFunctionCount}} key
                        Function{{sourceKeyFunctionCount === 1 ? "" : "s"}}</li>
                    <li *ngIf="sourceTeamCount > 0">{{sourceTeamCount}} team{{sourceTeamCount === 1 ? "" : "s"}}</li>
                    <li *ngIf="sourceObjectiveCount > 0">{{sourceObjectiveCount}} objective{{sourceObjectiveCount === 1 ? "" : "s"}}
                    </li>
                    <li *ngIf="sourceSystemCount > 0">{{sourceSystemCount}} system{{sourceSystemCount === 1 ? "" : "s"}}</li>
                    <li *ngIf="sourceProcessStepCount > 0">{{sourceProcessStepCount}} process
                        step{{sourceProcessStepCount === 1 ? "" : "s"}}</li>
                    <li *ngIf="sourceRoleCount > 0">{{sourceRoleCount}} role{{sourceRoleCount === 1 ? "" : "s"}}</li>
                </ul>
            </li>
        </ul>

    </div>
</adapt-confirmation-dialog>

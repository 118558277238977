import { AdaptError } from "@common/lib/error-handler/adapt-error";

export class StorageLimitReachedError extends AdaptError {
    public constructor(key: string, source?: Error) {
        let msg = `Local storage limit has been reached on insertion of ${key}`;

        if (source) {
            msg += `: ${source.name} - ${source.message}`;
        }

        super(msg);
        this.name = "StorageLimitReachedError";
    }
}

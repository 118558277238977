<div *ngIf="!nested && allowEditing && showEditLayoutButton"
     adaptToolbarContent
     alignment="right"
     [ordinal]="-1">
    <button adaptButtonIcon="move"
            [adaptButtonToggle]="isEditing"
            (adaptButtonToggleChange)="toggleEditing($event).subscribe()"
            data-test="layout-edit-button">Edit layout
    </button>
</div>

<adapt-layout-manager-controls *ngIf="allowEditing && !nested"
                               [layoutManager]="this">
</adapt-layout-manager-controls>

<div [ngClass]="hostClasses"
     [class]="innerClass">
    <ng-content></ng-content>

    <adapt-layout-column *ngFor="let size of dynamicColumns"
                         [class]="size"></adapt-layout-column>
</div>

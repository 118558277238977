import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { AdaptClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { GoogleTagManagerService } from "angular-google-tag-manager";

@Component({
    selector: "adapt-marketing-tracker",
    templateUrl: "./marketing-tracker.component.html",
    styleUrl: "./marketing-tracker.component.scss",
})
export class MarketingTrackerComponent implements OnChanges, OnInit {
    @Input() public pageName = "/";

    public pixelImageUrl = "";

    public constructor(private gtmService: GoogleTagManagerService) { }

    public ngOnChanges() {
        const trackingUrl = encodeURIComponent(`${window.location.origin}${this.pageName}`);
        this.pixelImageUrl = `https://www.facebook.com/tr?id=${AdaptClientConfiguration.MetaPixelId}&ev=PageView&dl=${trackingUrl}&noscript=1`;
    }

    public ngOnInit() {
        if (AdaptClientConfiguration.GoogleTagManagerId && this.gtmService.googleTagManagerConfiguration.get()?.id) {
            this.gtmService.pushTag({
                event: "page_view",
                pageName: this.pageName,
            });
        }
    }

    public get shouldTrackPixel() {
        return !!AdaptClientConfiguration.MetaPixelId;
    }
}

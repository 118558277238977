import { PersonProfileItemType } from "./person-profile-item-type";

export class PersonProfileItemTypeLabel {
    public static readonly ByType: { [type in PersonProfileItemType]: string } = {
        [PersonProfileItemType.Text]: "Text",
        [PersonProfileItemType.Date]: "Date",
        [PersonProfileItemType.RichText]: "Rich Text",
        [PersonProfileItemType.Country]: "Country",
    };

    public static readonly All = Object.keys(PersonProfileItemTypeLabel.ByType)
        .map((t: PersonProfileItemType) => ({
            type: t,
            label: PersonProfileItemType[t],
        }));
}

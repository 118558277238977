<ng-container *adaptLoadingUntilFirstEmit="let team of team$">
    <adapt-toolbar-inactive [entity]="team"
                            type="team">
    </adapt-toolbar-inactive>

    <div adaptToolbarContent>
        <a [routerLink]="teamDashboardLink$ | async"
           adaptButton="dashboardLink"
           adaptTooltip="Go to team dashboard"></a>
    </div>

    <adapt-configuration-page-base [team]="team"
                                   [configGroups]="configGroups"
                                   configTitle="{{team?.name}} team configuration"
                                   (selectedItemChange)="selectedItem = $event">
        <adapt-configure-team-details-new *ngIf="selectedItem?.area === ConfigArea.TeamProfile"
                                          [team]="team"
                                          [configItem]="selectedItem">
        </adapt-configure-team-details-new>
        <adapt-configure-team-membership *ngIf="selectedItem?.area === ConfigArea.People"
                                         [team]="team"
                                         [configItem]="selectedItem">
        </adapt-configure-team-membership>
        <adapt-configure-external-dashboard *ngIf="selectedItem?.area === ConfigArea.ExternalLink"
                                            [team]="team"
                                            [configItem]="selectedItem">
        </adapt-configure-external-dashboard>
        <adapt-configure-objectives *ngIf="selectedItem?.area === ConfigArea.ObjectivesAndKeyResults"
                                    [team]="team"
                                    [configItem]="selectedItem">
        </adapt-configure-objectives>
        <adapt-configure-values-constitution *ngIf="selectedItem?.area === ConfigArea.ValuesConstitution"
                                             [team]="team"
                                             [configItem]="selectedItem">
        </adapt-configure-values-constitution>
        <adapt-configure-team-kanban *ngIf="selectedItem?.area === ConfigArea.Kanban"
                                     [team]="team"
                                     [configItem]="selectedItem">
        </adapt-configure-team-kanban>
        <adapt-configure-team-meetings *ngIf="selectedItem?.area === ConfigArea.Meetings"
                                       [team]="team"
                                       [configItem]="selectedItem"></adapt-configure-team-meetings>
        <adapt-configure-team-assessment *ngIf="selectedItem?.area === ConfigArea.TeamAssessment"
                                         [team]="team"
                                         [configItem]="selectedItem">
        </adapt-configure-team-assessment>
        <adapt-configure-team-access-permissions *ngIf="selectedItem?.area === ConfigArea.AccessPermissions"
                                                 [team]="team"
                                                 [configItem]="selectedItem">
        </adapt-configure-team-access-permissions>
    </adapt-configuration-page-base>
</ng-container>

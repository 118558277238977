import { Component, OnInit } from "@angular/core";
import { ZoneMetadata } from "@common/ADAPT.Common.Model/methodology/zone";
import { IAdaptLinkObject } from "@common/route/route.service";
import { SearchService } from "@org-common/lib/search/search.service";
import { SearchRowBaseComponent } from "@org-common/lib/search/search-row-base.component";
import { Observable } from "rxjs";
import { StrategyGoalsPageRoute } from "../../strategic-goals/strategic-goals-page/strategic-goals-page.component";
import { StrategicViewIcon } from "../../strategy/strategy-view-constants";
import { IStrategicGoalSearchResult } from "../search-results.interface";

@Component({
    selector: "adapt-search-row-strategic-goal",
    templateUrl: "./search-row-strategic-goal.component.html",
})
export class SearchRowStrategicGoalComponent extends SearchRowBaseComponent<IStrategicGoalSearchResult> implements OnInit {
    public readonly GoalIcon = StrategicViewIcon.GoalIcon;
    public readonly ZoneMetadata = ZoneMetadata;

    public href$?: Observable<IAdaptLinkObject>;

    public constructor(
        protected searchService: SearchService,
    ) {
        super(searchService);
    }

    public ngOnInit() {
        super.ngOnInit();

        this.href$ = StrategyGoalsPageRoute.getRouteObject();
        this.matches = this.excludeNameBreadcrumb(this.result.results);
    }
}

import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Zone } from "../methodology/zone";
import { KeyFunction } from "./key-function";
import { Role } from "./role";
import { SystemEntity } from "./system-entity";
import { Team } from "./team";

export class SystemLocation extends BaseEntity<SystemLocation> {
    public systemLocationId!: number;
    public ordinal!: number;
    public systemEntityId!: number;

    // only one of these will be defined
    public roleId?: number;
    public teamId?: number;
    public keyFunctionId?: number;
    public zone?: Zone;

    // navigation
    public system!: SystemEntity;
    public role?: Role;
    public team?: Team;
    public keyFunction?: KeyFunction;
}

export const SystemLocationBreezeModel = new BreezeModelBuilder("SystemLocation", SystemLocation)
    .hasSource()
    .withIdField()
    .alwaysFetchingNavigationProperty("system")
    .isOrganisationEntity()
    .orderBy("ordinal")
    .build();

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptImplementationKitModule } from "@common/implementation-kit/implementation-kit.module";
import { AdaptToolbarModule } from "@common/shell/toolbar/toolbar.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptProgressBarModule } from "@common/ux/progress-bar/progress-bar.module";
import { AdaptSeeMoreModule } from "@common/ux/see-more/see-more.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptDirectorySharedModule } from "@org-common/lib/directory-shared/directory-shared.module";
import { AdaptCommonMeetingsModule } from "@org-common/lib/meetings/common-meetings.module";
import { AdaptMeetingsSharedModule } from "@org-common/lib/meetings/meetings-shared.module";
import { AdaptSurveyModule } from "@org-common/lib/survey/survey.module";
import { AdaptWorkflowModule } from "@org-common/lib/workflow/workflow.module";
import { DxTextAreaModule, DxTextBoxModule, DxValidationGroupModule, DxValidatorModule } from "devextreme-angular";
import { AdaptAccountModule } from "../../account/account.module";
import { AdaptPersonModule } from "../../person/person.module";
import { AdaptAltoWorkflowsSharedModule } from "../shared/workflows-shared.module";
import { LaunchOrganisationDiagnosticSurveyComponent } from "./launch-organisation-diagnostic-survey/launch-organisation-diagnostic-survey.component";
import { OrganisationDiagnosticWorkshopAnalyseResultsComponent } from "./organisation-diagnostic-workshop-analyse-results/organisation-diagnostic-workshop-analyse-results.component";
import { PendingOrganisationDiagnosticSurveyComponent } from "./pending-organisation-diagnostic-survey/pending-organisation-diagnostic-survey.component";
import { ShowSurveyMeetingComponent } from "./show-survey-meeting/show-survey-meeting.component";
import { ShowSurveyProgressComponent } from "./show-survey-progress/show-survey-progress.component";

@NgModule({
    declarations: [
        PendingOrganisationDiagnosticSurveyComponent,
        ShowSurveyProgressComponent,
        OrganisationDiagnosticWorkshopAnalyseResultsComponent,
        ShowSurveyMeetingComponent,
        LaunchOrganisationDiagnosticSurveyComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        AdaptWorkflowModule,
        AdaptTooltipModule,
        DxValidationGroupModule,
        DxValidatorModule,
        DxTextBoxModule,
        DxTextAreaModule,
        AdaptAccountModule,
        AdaptPersonModule,
        AdaptLoadingSpinnerModule,
        AdaptProgressBarModule,
        AdaptDateModule,
        AdaptImplementationKitModule,
        AdaptToolbarModule,
        AdaptButtonModule,
        AdaptDirectorySharedModule,
        AdaptCommonMeetingsModule,
        AdaptMeetingsSharedModule,
        AdaptSurveyModule,
        AdaptSeeMoreModule,
        AdaptAltoWorkflowsSharedModule,
    ],
})
export class AdaptOrganisationDiagnosticModule {
    // prevent being optimised by tree-shaker
    public readonly CustomComponents = [
        LaunchOrganisationDiagnosticSurveyComponent,
        PendingOrganisationDiagnosticSurveyComponent,
        OrganisationDiagnosticWorkshopAnalyseResultsComponent,
        ShowSurveyProgressComponent,
        ShowSurveyMeetingComponent,
    ];
}

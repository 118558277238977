import { Logger } from "@common/lib/logger/logger";
import { ILogger } from "@common/lib/logger/logger.interface";
import Widget from "devextreme/ui/widget/ui.widget";
import { BaseChart } from "devextreme/viz/chart_components/base_chart";
import { ElementResizeHandler } from "./element-resize-handler";

interface IDxComponentInitialized<T> {
    component?: T;
}

/** Provides general DX logic which is reused across multiple types of DX Widgets */
export abstract class DxComponentWrapper<TComponent extends Widget<any> | BaseChart<any>> {
    protected readonly resizeHandler: ElementResizeHandler;
    protected readonly log: ILogger;

    private _component: TComponent;
    private hasBeenDestroyed = false;

    public constructor(
        componentId: string,
        $element: JQuery<any>,
        e: IDxComponentInitialized<TComponent>,
    ) {
        if (!e.component) {
            throw new Error("dx component or element was not defined in initialisation event");
        }

        this.log = Logger.getLogger(`${componentId}_DxWrapper`);

        this._component = e.component;
        this.resizeHandler = new ElementResizeHandler($element);
        this.resizeHandler.registerHandler(() => this.redraw());
        this.resizeHandler.startWatching();
    }

    public redraw() {
        this.runIfValid(() => this.performRedraw());
    }

    public destroy() {
        this.hasBeenDestroyed = true;
        this.resizeHandler.stopWatching();
    }

    public get isValid() {
        // Use private variable otherwise you'll get a stack overflow ;)
        return !this.hasBeenDestroyed && !(this._component as any)._disposed;
    }

    /**
     * Helper method used for setting the data source in the onInitiliazed DX event
     * Usually you will want to use setDataSource
     */
    public setDataSourceAfterTimeout(data: unknown) {
        setTimeout(() => this.setDataSource(data));
    }

    /** Sets the data source and forces a redraw of the component */
    public setDataSource(data: unknown) {
        if (!this.isValid) {
            return;
        }

        this.component.option("dataSource", data as any);

        // Redraw after timeout to handle initial data source draw not filling
        // the entire container.
        setTimeout(() => this.redraw());
    }

    public get component() {
        if (!this.isValid) {
            this.log.error("Component was accessed after destruction");
        }

        return this._component;
    }

    public runIfValid(callback: (component: TComponent) => void) {
        if (this.isValid) {
            callback(this.component);
        }
    }

    protected abstract performRedraw(): void;
}

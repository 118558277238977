/* eslint-disable max-classes-per-file */
import { Injectable } from "@angular/core";
import { IUserMenuItem } from "@common/shell/application-bar-user-item-content/user-menu-item";
import { IShellPopoverLink, IShellPopoverLinkItem } from "@common/shell/shell-popover-link-item/shell-popover-link-item";
import { SidebarTabIconComponent } from "@common/shell/sidebar-tab-icon/sidebar-tab-icon.component";
import { UserService } from "@common/user/user.service";
import { IComponentRender } from "@common/ux/render-component/component-render.interface";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { AuthorisationNotificationService } from "@org-common/lib/authorisation/authorisation-notification.service";
import { combineLatest, defer, Observable } from "rxjs";
import { map, startWith, switchMap } from "rxjs/operators";
import { myOrganisationsPageRoute } from "./my-organisations-page.route";

@Injectable()
export class MyOrganisationsUserMenuItem implements IUserMenuItem {
    public readonly text = "My organisations";
    public readonly icon = "fal fa-briefcase";
    public readonly testAttribute = "my-organisations";
    public readonly isInternal = true;
    public href$: Observable<string>;
    public isShown$: Observable<boolean>;

    public constructor(
        userService: UserService,
        authorisationService: AuthorisationService,
        authorisationNotificationService: AuthorisationNotificationService,
    ) {
        this.href$ = defer(() => myOrganisationsPageRoute.getRoute());
        this.isShown$ = currentPersonCanAccessMyOrganisations(userService, authorisationService, authorisationNotificationService).pipe(
            startWith(false),
        );
    }
}

@Injectable()
export class MyOrganisationsApplicationBarItem implements IShellPopoverLinkItem {
    public readonly link: IShellPopoverLink;
    public readonly linkContent: IComponentRender<SidebarTabIconComponent>;
    public readonly tooltip = "My organisations";
    public readonly ordinal = 5;
    public readonly isShown$: Observable<boolean>;
    public readonly isInternal = true;

    public constructor(
        userService: UserService,
        authorisationService: AuthorisationService,
        authorisationNotificationService: AuthorisationNotificationService,
        responsiveService: ResponsiveService,
    ) {
        this.link = {
            href$: myOrganisationsPageRoute.getRoute(),
            openInNewTab: false,
        };
        this.linkContent = {
            component: SidebarTabIconComponent,
            params: {
                iconClass: "fal fa-briefcase",
                iconText: "My Orgs",
            },
        };
        this.isShown$ = combineLatest([
            currentPersonCanAccessMyOrganisations(userService, authorisationService, authorisationNotificationService),
            responsiveService.currentBreakpoint$,
        ]).pipe(
            map(([hasAccess, breakpoint]) => hasAccess && breakpoint.isMobileSize),
        );
    }
}

function currentPersonCanAccessMyOrganisations(
    userService: UserService,
    authorisationService: AuthorisationService,
    authorisationNotificationService: AuthorisationNotificationService,
) {
    return combineLatest([
        userService.currentPerson$,
        // promiseToCheckIsStakeholderManager does not update when authorisation changes
        // this will force this to update
        authorisationNotificationService.authorisationChanged$.pipe(startWith(undefined)),
    ]).pipe(
        switchMap(async ([p]) => {
            if (!p) {
                return false;
            }

            const [isStakeholder, organisations] = await Promise.all([
                authorisationService.promiseToCheckIsStakeholderManager(),
                userService.getActiveOrganisationsForCurrentPerson(),
            ]);
            return isStakeholder || p.isCoach() || (organisations?.length ?? 0) > 1;
        }),
    );
}

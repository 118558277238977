<ng-container *ngIf="showQuestionResponseDistribution">
    <h3 class="mb-3">{{surveyQuestion}}</h3>
    <adapt-display-question-response-distribution [survey]="survey$ | async"
                                                  [maxResponseCount]="maxResponseCount"
                                                  (maxResponseCountChange)="maxResponseCountChange.emit($event)"
                                                  [surveyQuestions]="surveyQuestions"
                                                  [questionId]="questionId"></adapt-display-question-response-distribution>
</ng-container>
<ng-container *ngIf="!isInitialised; else showInitGauges">
    <!-- <div [ngClass]="presentResponseGroups.length === 1 ? 'gauge-placeholder-without-header' : 'gauge-placeholder-with-header'"></div> -->
    <div class="gauge-placeholder"></div>
</ng-container>
<ng-template #showInitGauges>
    <div class="d-flex flex-row justify-content-between">
        <ng-container *ngFor="let group of presentResponseGroups">
            <ng-container *ngIf="$any(groupStats)[group]; let stats">
                <div class="d-flex flex-column align-items-center realign-for-print"
                     [ngClass]="presentResponseGroups.length === 1 ? 'w-100' : 'w-50'">
                    <dx-linear-gauge *ngIf="stats"
                                     class="question-stats"
                                     [value]="stats!.average"
                                     [subvalues]="[stats!.min, stats!.max]"
                                     (onInitialized)="onInitialized($event)"
                                     (onDrawn)="isDrawn = true"
                                     (onDisposing)="onDisposing($event)">
                        <dxo-animation [enabled]="false"></dxo-animation>
                        <dxo-size *ngIf="!showQuestionResponseDistribution"
                                  [height]="60"></dxo-size>
                        <dxo-value-indicator *ngIf="getStatusColorFromPercentage"
                                             type="textCloud"
                                             [offset]="-5"
                                             [color]="getStatusColorFromPercentage!(stats!.average)">
                            <dxo-text [customizeText]="customiseScaleText">
                                <dxo-font [size]="12"></dxo-font>
                            </dxo-text>
                        </dxo-value-indicator>
                        <dxo-range-container>
                            <dxi-range *ngFor="let range of gaugeRanges"
                                       [color]="range.color"
                                       [startValue]="range.startValue"
                                       [endValue]="range.endValue"></dxi-range>
                        </dxo-range-container>
                        <dxo-scale [startValue]="0"
                                   [endValue]="100"
                                   [customTicks]="tickValues">
                            <dxo-label [indentFromTick]="5"
                                       [customizeText]="customiseScaleText">
                                <dxo-font [size]="10"></dxo-font>
                            </dxo-label>
                        </dxo-scale>
                        <dxo-title *ngIf="showQuestionResponseDistribution"
                                   text="Question average"
                                   verticalAlignment="bottom">
                            <dxo-font [size]="16"></dxo-font>
                        </dxo-title>
                        <dxo-tooltip [enabled]="true"
                                     [customizeTooltip]="customiseChartTooltip"></dxo-tooltip>
                    </dx-linear-gauge>
                    <span *ngIf="presentResponseGroups.length > 1 && !isXL"
                          class="response-group-header">{{$any(GroupLabel)[group]}}</span>
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-template>

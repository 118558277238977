import { NgModule } from "@angular/core";
import { AdaptTooltipModule } from "../tooltip.directive/tooltip.module";
import { DateFormatPipe } from "./date.pipe";
import { DateTimeFormatPipe } from "./date-time.pipe";
import { EntityTimestampComponent } from "./entity-timestamp/entity-timestamp.component";
import { MinutesToDurationPipe } from "./minutes-to-duration.pipe";
import { MomentPipe } from "./moment.pipe";
import { TimePipe } from "./time.pipe";
import { TimeAgoPipe } from "./time-ago.pipe";
import { TimeFromTimerPipe } from "./time-from-timer.pipe";

@NgModule({
    imports: [
        AdaptTooltipModule,
    ],
    exports: [
        DateFormatPipe,
        DateTimeFormatPipe,
        TimePipe,
        TimeAgoPipe,
        TimeFromTimerPipe,
        MomentPipe,
        EntityTimestampComponent,
        MinutesToDurationPipe,
    ],
    declarations: [
        DateFormatPipe,
        DateTimeFormatPipe,
        TimePipe,
        TimeAgoPipe,
        TimeFromTimerPipe,
        MomentPipe,
        EntityTimestampComponent,
        MinutesToDurationPipe,
    ],
})
export class AdaptDateModule {
}

<ng-container *adaptLoading="!isInitialised">
    <div class="card type-card">
        <div class="card-header">
            <i [ngClass]="inputTypeIconClass"
               class="me-1"></i>
            <h3>{{inputTypeTitle}}</h3>
            <button *adaptIfAuthorised="EditStrategicInputs"
                    adaptButton="addBorderless"
                    [attr.data-tour]="'input-' + inputType?.toLowerCase()"
                    class="mx-1"
                    adaptTooltip="Add {{inputType}}"
                    [adaptBlockingClick]="createInput"></button>
        </div>
        <div class="card-body type-body">
            <adapt-strategic-input *ngFor="let input of inputs"
                                   [input]="input"
                                   [isEditing]="true"></adapt-strategic-input>
            <div *ngIf="inputs.length < 1"
                 class="d-flex flex-column">
                <div class="alert alert-secondary mt-3">
                    There are currently no inputs of this type.
                    <ng-container *adaptIfAuthorised="EditStrategicInputs">
                        Please use the
                        <button adaptButton="addBorderless"
                                [adaptBlockingClick]="createInput"></button>
                        above to add a new input to this canvas.
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</ng-container>

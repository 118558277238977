import { Component, Injector, OnInit, ViewChild } from "@angular/core";
import { Params } from "@angular/router";
import { Survey, SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { Subject, takeUntil, timer } from "rxjs";
import { finalize, takeWhile } from "rxjs/operators";
import { AnalyseSurveyComponent, ICategorySummaryHeights, IQuestionMaxResponses } from "../analyse-survey/analyse-survey.component";
import { manageEmployeeEngagementsPageRoute } from "../employee-engagement/manage-employee-engagements-page/manage-employee-engagements-page.route";
import { manageOrganisationDiagnosticsPageRoute } from "../organisation-diagnostic/manage-organisation-diagnostics-page/manage-organisation-diagnostics-page.component";
import { manageRBAssessmentsPageRoute } from "../resilient-business-assessment/manage-rb-assessments-page/manage-rb-assessments-page.route";
import { SurveyService } from "../survey.service";
import { manageTeamAssessmentsPageRoute } from "../team-assessment/manage-team-assessments-page/manage-team-assessments-page.route";
import { analyseEngagementSurveyPageRoute, analyseOrganisationDiagnosticPageRoute, analyseResilientBusinessAssessmentSurveyPageRoute, analyseTeamAssessmentSurveyPageRoute } from "./analyse-survey-page.route";

const surveyManageRoutes: { [type in SurveyType]: IAdaptRoute<Params> } = {
    [SurveyType.EmployeeEngagement]: manageEmployeeEngagementsPageRoute,
    [SurveyType.ResilientBusinessAssessment]: manageRBAssessmentsPageRoute,
    [SurveyType.TeamAssessment]: manageTeamAssessmentsPageRoute,
    [SurveyType.OrganisationDiagnostic]: manageOrganisationDiagnosticsPageRoute,
};

const surveyParam = "surveyId";
const compareSurveyParam = "compareSurveyId";

@Component({
    selector: "adapt-analyse-survey-page",
    templateUrl: "./analyse-survey-page.component.html",
})
export class AnalyseSurveyPageComponent extends BaseRoutedComponent implements OnInit {
    @ViewChild("firstSurvey") private firstSurvey?: AnalyseSurveyComponent;
    @ViewChild("secondSurvey") private secondSurvey?: AnalyseSurveyComponent;

    public surveys: Survey[] = [];
    public selectedSurvey?: Survey;
    public selectedSurvey2?: Survey;
    public compareButtonClass = "";
    public compareMode = false;
    public isMobile = false; // compare panel on toolbar will be hidden if no longer showing side-by-side

    public maxResponses: IQuestionMaxResponses = {};
    public leftHeights: ICategorySummaryHeights = {};
    public rightHeights: ICategorySummaryHeights = {};

    private surveyService: SurveyService;
    public forceInitialise = false;
    public printLoading$ = new Subject<boolean>();

    public constructor(
        responsiveService: ResponsiveService,
        injector: Injector,
    ) {
        super(injector);
        this.surveyService = injector.get(SurveyService);

        responsiveService.currentBreakpoint$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((breakpoint) => {
            this.isMobile = breakpoint.isMobileSize;
            // disallow compare mode if don't have space to show side-by-side
            if (this.isMobile) {
                this.compareMode = false;
                this.compareButtonClass = "";
                this.resetMaxResponses();
            }
        });
    }
    public ngOnInit() {
        this.updateDataFromPageParams();
        this.navigationEnd.subscribe(() => this.updateDataFromPageParams());

        this.listenForBrowserPrint().subscribe(() => {
            this.initialiseElementsForPrint();
        });
    }

    private updateDataFromPageParams() {
        const controllerId = this.routeService.currentControllerId;
        let surveyType: SurveyType;
        if (controllerId === analyseTeamAssessmentSurveyPageRoute.id) {
            surveyType = SurveyType.TeamAssessment;
        } else if (controllerId === analyseEngagementSurveyPageRoute.id) {
            surveyType = SurveyType.EmployeeEngagement;
        } else if (controllerId === analyseResilientBusinessAssessmentSurveyPageRoute.id) {
            surveyType = SurveyType.ResilientBusinessAssessment;
        } else if (controllerId === analyseOrganisationDiagnosticPageRoute.id) {
            surveyType = SurveyType.OrganisationDiagnostic;
        } else {
            throw new AdaptError("Cannot determine survey type from route");
        }

        const teamId = this.getRouteParamInt("teamId");
        this.surveyService.getPreviousSurveys(surveyType, teamId ? { teamId } : undefined).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((surveys) => {
            if (!surveys || surveys.length === 0) {
                const manageRoute = surveyManageRoutes[surveyType];
                manageRoute.gotoRoute({ teamId }).subscribe();
            }

            this.surveys = surveys;

            const surveyIdValue = this.getSearchParameterValue(surveyParam);
            if (surveyIdValue) {
                this.selectedSurvey = this.getSurveyById(Number(surveyIdValue));
            }

            if (!this.selectedSurvey) {
                this.selectedSurvey = surveys[0];
            }

            const compareSurveyIdValue = this.getSearchParameterValue(compareSurveyParam);
            if (compareSurveyIdValue) {
                this.selectedSurvey2 = this.getSurveyById(Number(compareSurveyIdValue));
                this.compareClicked(this.selectedSurvey2 !== undefined);
            }

            // won't use selectedSurvey2 anymore when completed survey count < 2 - so don't have to init selectedSurvey2 = selectedSurvey
            this.onPrimarySurveyChanged(this.selectedSurvey!);

            this.notifyActivated();
        });
    }

    public compareClicked(compareMode: boolean) {
        this.compareMode = compareMode;
        this.compareButtonClass = this.compareMode ? "active" : "";
        this.onSecondarySurveyChanged(this.compareMode ? this.selectedSurvey2 : undefined);
    }

    @Autobind
    public exportSurvey() {
        return this.surveyService.surveyExcelExport(this.selectedSurvey!);
    }

    public resetMaxResponses() {
        this.maxResponses = {};
        Object.keys(this.maxResponses).map(Number).forEach((questionId) => this.maxResponses[questionId] = 0);
    }

    public onPrimarySurveyChanged(primarySurvey: Survey) {
        this.routeService.updateSearchParameterValue(surveyParam, primarySurvey?.surveyId);
        if (this.surveys.length > 1 && !this.selectedSurvey2) {
            this.selectedSurvey2 = this.surveys.find((survey) => survey !== primarySurvey);
        }

        this.resetMaxResponses();
    }

    public onSecondarySurveyChanged(secondarySurvey?: Survey) {
        this.routeService.updateSearchParameterValue(compareSurveyParam, secondarySurvey?.surveyId);
        this.resetMaxResponses();
    }

    private getSurveyById(surveyId: number) {
        return this.surveys.find((survey) => survey.surveyId === surveyId);
    }

    public initialiseElementsForPrint() {
        this.forceInitialise = true;
        this.printLoading$.next(true);
        timer(0, 500).pipe(
            takeWhile(() => {
                if (this.compareMode) {
                    return !this.firstSurvey?.isDrawn || !this.secondSurvey?.isDrawn;
                } else {
                    return !this.firstSurvey?.isDrawn;
                }

            }),
            takeUntil(timer(5000)),
            this.takeUntilDestroyed(),
            finalize(() => {
                if (this.firstSurvey?.isDrawn && (!this.secondSurvey || this.secondSurvey.isDrawn)) {
                    this.printLoading$.next(false);
                    setTimeout(() => window.print());
                }
            }),
        ).subscribe();
    }
}

import { Component, Inject, OnInit } from "@angular/core";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { ItemLink } from "@common/ADAPT.Common.Model/organisation/item-link";
import { ObjectiveItemLink } from "@common/ADAPT.Common.Model/organisation/objective-item-link";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseConfirmationDialogComponent } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/base-confirmation-dialog.component";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { ObjectivesService } from "@org-common/lib/objectives/objectives.service";
import { switchMap, tap } from "rxjs/operators";
import { KanbanService } from "../../kanban.service";


export interface IConfirmDeleteItemData extends IConfirmationDialogData {
    item: Item;
}

@Component({
    selector: "adapt-delete-item-dialog",
    templateUrl: "./delete-item-dialog.component.html",
    styleUrls: ["./delete-item-dialog.component.scss"],
})
export class DeleteItemDialogComponent extends BaseConfirmationDialogComponent<IConfirmDeleteItemData> implements OnInit {
    public readonly dialogName = "DeleteItemConfirmation";

    public item: Item;
    public loadingRelatedInfo = true;

    public itemLinks: ItemLink[] = [];
    public objectiveItemLinks: ObjectiveItemLink[] = [];

    constructor(
        @Inject(ADAPT_DIALOG_DATA) public dialogData: IConfirmDeleteItemData,
        private kanbanService: KanbanService,
        private objectivesService: ObjectivesService,
    ) {
        super(dialogData);

        this.item = dialogData.item;
        dialogData.title = "Delete action: " + this.item.code;
        dialogData.confirmButtonPreset = "deleteAndSave";
    }

    public async ngOnInit() {
        this.loadingRelatedInfo = true;

        if (this.item) {
            // prime the related item data
            this.kanbanService.primeRelatedItemData(this.item).pipe(
                switchMap(() => this.objectivesService.getObjectiveItemLinksForItem(this.item)),
                tap((objectiveItemLinks) => {
                    this.itemLinks = this.item.links.filter((link) => link.secondaryItem !== null && link.primaryItem !== null);
                    this.objectiveItemLinks = objectiveItemLinks;
                    this.loadingRelatedInfo = false;
                }),
                this.takeUntilDestroyed(),
            ).subscribe();
        }
    }
}

<adapt-dialog [maxWidth]="500">
    <h3 adapt-dialog-title>Confirm coach request</h3>

    <ng-container adapt-dialog-content>
        <adapt-coach-access-session-select #coachAccessPaymentComponent
                                           [readOnly]="true"
                                           [coachOption]="data.coachOption"
                                           [(coachRequest)]="data.coachRequest"
                                           [disabled]="submitting"
                                           (canRequestCoach)="canRequestCoach = $event"></adapt-coach-access-session-select>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   (cancelClick)="cancel()"
                                   saveButtonPreset="primary"
                                   [saveBlockingClick]="requestCoach"
                                   [saveIsDisabled]="!canRequestCoach"
                                   saveText="{{coachAccessPaymentComponent.totalCost !== 0 ? 'Pay & schedule' : 'Schedule'}} coach session"></adapt-standard-dialog-buttons>
</adapt-dialog>

<adapt-collapsible-search-row [result]="result"
                              [matches]="matches"
                              [href$]="href$"
                              [ngClass]="{'no-container': !showContainer}">
    <div class="d-flex flex-grow-1 align-items-center justify-content-between">
        <div class="d-flex flex-grow-0 me-2 align-items-center text-muted">
            <div class="d-flex align-items-center result-icon"
                 adaptTooltip="Page">
                <i class="fal fa-file-alt fa-inverse"></i>
            </div>
        </div>

        <div class="flex-grow-1 text-break d-flex align-items-center breadcrumbs">
            <ol class="breadcrumbs-directive">
                <li *ngIf="nodeExists(nodes.root)">
                    <ng-container *ngTemplateOutlet="nodeTemplate; context: {node: nodes.root}"></ng-container>
                </li>
                <li *ngIf="nodeExists(nodes.area)">
                    <ng-container *ngTemplateOutlet="nodeTemplate; context: {node: nodes.area}"></ng-container>
                </li>
                <li *ngFor="let node of nodes.branches">
                    <ng-container *ngTemplateOutlet="nodeTemplate; context: {node: node}"></ng-container>
                </li>
                <li *ngIf="nodeExists(nodes.leafParent)">
                    <ng-container *ngTemplateOutlet="nodeTemplate; context: {node: nodes.leafParent}"></ng-container>
                </li>
                <li>
                    <ng-container *ngTemplateOutlet="nodeTemplate; context: {node: nodes.leaf}"></ng-container>
                </li>
            </ol>
        </div>
    </div>
</adapt-collapsible-search-row>


<ng-template #nodeTemplate
             let-node="node">
    <span *ngIf="nodeTitle(node) as title"
          class="navigation-node-text">{{title}}</span>
</ng-template>

import { Injectable } from "@angular/core";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";

@Injectable({
    providedIn: "root",
})
export class WorkflowAuthService {
    public static readonly EditWorkflow = "editWorkflow";

    public static registerAccess(authorisationService: AuthorisationService) {
        authorisationService.registerAccessVerifier(
            WorkflowAuthService.EditWorkflow,
            {
                requirePermissions: [
                    FeaturePermissionName.PlatformWorkflowEdit,
                ],
            },
        );
    }
}

import { Component, ElementRef, EventEmitter, HostBinding, Input, Output } from "@angular/core";
import { IAdaptLinkObject, RouteService } from "@common/route/route.service";
import { BaseComponent } from "../base.component/base.component";

@Component({
    selector: "adapt-universal-dashboard-element",
    templateUrl: "./universal-dashboard-element.component.html",
    styleUrls: ["./universal-dashboard-element.component.scss"],
})
export class UniversalDashboardElementComponent extends BaseComponent {
    @Input() public name?: string;
    @Input() public info?: string;
    @Input() public linkText?: string;
    @Input() public contentCard = false;
    @Input() @HostBinding("class.nested") public nested = false;
    @Input() @HostBinding("class.background") public background = true;
    @Input() public hideBackground = false;
    @Input() public hideHeader = false;

    @Input() public iconClass?: string;
    @Input() public iconTooltip?: string;

    @Input() public isExpandable = true;
    @Input() public isCollapsible = false;
    @Input() public collapsed = false;
    @Output() public collapsedChange = new EventEmitter<boolean>();

    @HostBinding("class.card") public card = true;

    public linkObject?: IAdaptLinkObject;
    @Input() public set url(value: string | IAdaptLinkObject | undefined) {
        this.linkObject = this.routeService.parseRouterObject(value);
    }

    @Input() public disableClick = false;
    @Output() public headerClicked = new EventEmitter<any>();

    public constructor(
        private routeService: RouteService,
        protected elementRef: ElementRef,
    ) {
        super(elementRef);
    }

    public get hasClickSubscribers() {
        return !this.disableClick && this.headerClicked.observers.length > 0;
    }
}

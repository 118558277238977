<div class="progress"
     [ngStyle]="progressStyles">
    <div class="progress-content dark-background progress-bar"
         role="progressbar"
         [ngStyle]="progressBarStyles"
         [attr.aria-valuenow]="percentageProgress"
         [attr.aria-valuemin]="0"
         [attr.aria-valuemax]="totalProgress">
    </div>
    <div class="progress-content progress-text"
         [ngClass]="textAlignmentClasses"
         [ngStyle]="progressTextStyles">{{progressText}}</div>
    <div class="progress-content blending-layer"
         [adaptTooltip]="progressTooltip"
         [ngStyle]="overrideBarColorStyles"></div>
</div>

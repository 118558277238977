import { NgClass } from "@angular/common";
import { Directive, ElementRef, Input, OnChanges } from "@angular/core";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ReplaySubject } from "rxjs";
import { ILayoutBaseColumn, ILayoutBaseComponent, ILayoutUpdateListener } from "./layout.interface";
import { LayoutService } from "./layout.service";
import { LayoutManagerComponent } from "./layout-manager/layout-manager.component";

@Directive()
export abstract class LayoutBaseComponent<T extends ILayoutBaseColumn | ILayoutBaseComponent> extends BaseComponent implements OnChanges, ILayoutUpdateListener<T> {
    public isEditing = false;

    public customHostClasses: { [name: string]: boolean } = {};

    @Input() public set enabled(value: any) {
        if (this.layoutEntity) {
            LayoutService.setOptions(this.layoutEntity, { enabled: !!value } as Partial<T["options"]>);
            this.ngOnChanges();
        }
    }

    public updated$ = new ReplaySubject<void>(1);

    protected defaultSize?: string;

    private hostClasses: { [name: string]: boolean } = {};

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("class")
    public set sizeClasses(classes: string | undefined) {
        if (classes) {
            // only include sizing related classes
            // keep size undefined if no matching classes found
            classes = LayoutService.getElementSize(classes) || undefined;

            if (this.layoutEntity) {
                LayoutService.setSize(this.layoutEntity, classes);
            }
        }
        this.defaultSize = classes;
    }

    protected constructor(
        protected ngClass: NgClass,
        protected elementRef: ElementRef<HTMLElement>,
        protected layoutManager: LayoutManagerComponent,
    ) {
        super(elementRef);

        this.layoutManager.isEditing$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((isEditing) => {
            this.isEditing = isEditing;
            this.ngOnChanges();
        });
    }

    public abstract get layoutEntity(): T | undefined;

    public onUpdate(_data: T) {
        this.ngOnChanges();
    }

    public ngOnChanges() {
        this.updated$.next();

        // add classes to the host element
        this.hostClasses = {
            ...this.customHostClasses,
            "col-12": !this.layoutEntity?.size?.includes("col"),
            "editing": this.isEditing,
        };

        if (this.layoutEntity?.size) {
            this.layoutEntity.size.split(" ").forEach((cls) => {
                this.hostClasses[cls] = true;
            });
        }

        if (this.layoutEntity?.options?.enabled !== undefined && !this.layoutEntity?.options?.manuallyEnabled) {
            this.hostClasses["d-none"] = !this.layoutEntity.options.enabled;
        }

        // user hiding state supersedes any other visibility option (only when not editing)
        if (this.layoutEntity?.options?.userHidden === true) {
            if (!this.isEditing) {
                this.hostClasses["d-none"] = true;
            }
            this.hostClasses["user-hidden"] = true;
        }

        this.updateHostClasses();
    }

    private updateHostClasses() {
        this.ngClass.ngClass = this.hostClasses;
        this.ngClass.ngDoCheck();
    }
}

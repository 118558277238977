import { Component, ElementRef, Input, OnChanges } from "@angular/core";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { IGroupedData } from "@common/lib/utilities/grouped-data.interface";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ObjectivesService } from "../objectives.service";

@Component({
    selector: "adapt-select-supporting-objective",
    templateUrl: "./select-supporting-objective.component.html",
    styleUrl: "./select-supporting-objective.component.scss",
})
export class SelectSupportingObjectiveComponent extends BaseComponent implements OnChanges {
    @Input() public objective!: Objective;
    public groupedSupportiveObjectives: IGroupedData<number | undefined, Objective>[] = [];

    public constructor(
        private objectivesService: ObjectivesService,
        elementRef: ElementRef,
    ) {
        super(elementRef);
    }

    public ngOnChanges() {
        this.objectivesService.getUnclosedPotentialSupportiveObjectives(this.objective)
            .subscribe((objectives) => {
                if (this.objective.parentObjective && objectives.indexOf(this.objective.parentObjective) < 0) {
                    objectives.push(this.objective.parentObjective);
                }

                this.groupedSupportiveObjectives = ArrayUtilities.groupArrayBy(objectives.filter((obj) => obj !== this.objective), (o) => o.teamId);
            });
    }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptImplementationKitModule } from "@common/implementation-kit/implementation-kit.module";
import { AdaptContextSidebarModule } from "@common/shell/context-sidebar/context-sidebar.module";
import { AdaptToolbarModule } from "@common/shell/toolbar/toolbar.module";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptCollapsibleModule } from "@common/ux/collapsible/collapsible.module";
import { AdaptCollapsibleDashboardElementModule } from "@common/ux/collapsible-dashboard-element/collapsible-dashboard-element.module";
import { AdaptHtmlEditorModule } from "@common/ux/html-editor/html-editor.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptSeeMoreModule } from "@common/ux/see-more/see-more.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { DxTextBoxModule } from "devextreme-angular";
import { AdaptAuthorisationModule } from "../authorisation/authorisation.module";
import { AdaptCallToActionModule } from "../call-to-action/call-to-action.module";
import { AdaptStrategyModule } from "../strategy/strategy.module";
import { AdaptZoneMapModule } from "../zone-map/zone-map.module";
import { AttachBullseyeStatementsDialogComponent } from "./attach-bullseye-statements-dialog/attach-bullseye-statements-dialog.component";
import { BullseyePageComponent, BullseyePageRoute } from "./bullseye-page/bullseye-page.component";
import { BullseyeViewComponent } from "./bullseye-view/bullseye-view.component";
import { QuadrantViewComponent } from "./quadrant-view/quadrant-view.component";
import { ShowBullseyeDialogComponent } from "./show-bullseye-dialog/show-bullseye-dialog.component";
import { CustomBullseyeStatementStepComponent } from "./workflows/bullseye/custom-bullseye-statement-step/custom-bullseye-statement-step.component";
import { InputQuadrantStatementsComponent } from "./workflows/bullseye/input-quadrant-statements/input-quadrant-statements.component";
import { SetBullseyeStepComponent } from "./workflows/bullseye/set-bullseye-step/set-bullseye-step.component";
import { SettingUpBullseyeAgendaItemComponent } from "./workflows/bullseye/setting-up-bullseye-agenda-item/setting-up-bullseye-agenda-item.component";


@NgModule({
    declarations: [
        BullseyeViewComponent,
        QuadrantViewComponent,
        BullseyePageComponent,
        ShowBullseyeDialogComponent,
        AttachBullseyeStatementsDialogComponent,
        InputQuadrantStatementsComponent,
        SetBullseyeStepComponent,
        CustomBullseyeStatementStepComponent,
        SettingUpBullseyeAgendaItemComponent,
    ],
    exports: [
        BullseyeViewComponent,
    ],
    imports: [
        CommonModule,
        AdaptLoadingSpinnerModule,
        AdaptTooltipModule,
        AdaptToolbarModule,
        AdaptCallToActionModule,
        AdaptButtonModule,
        AdaptImplementationKitModule,
        AdaptCollapsibleDashboardElementModule,
        AdaptHtmlEditorModule,
        AdaptContextSidebarModule,
        AdaptCommonDialogModule,
        AdaptStrategyModule,
        AdaptZoneMapModule,
        AdaptAuthorisationModule,
        DxTextBoxModule,
        AdaptSeeMoreModule,
        AdaptCollapsibleModule,
    ],
})
export class AdaptBullseyeModule {
    public static readonly Routes = [
        ...BullseyePageRoute.routes,
    ];

    // prevent being optimised by tree-shaker
    public readonly workflowStepComponents = [
        InputQuadrantStatementsComponent,
        SetBullseyeStepComponent,
        CustomBullseyeStatementStepComponent,
        SettingUpBullseyeAgendaItemComponent,
    ];
}

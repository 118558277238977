import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { Stripe, StripeElements, StripePaymentElementChangeEvent } from "@stripe/stripe-js/dist/stripe-js";
import { delay, tap } from "rxjs";
import { ICardPartialDetails } from "../card-partial-details.interface";
import { ICardOrganisationIdentifier, PaymentProcessingService } from "../payment-processing.service";

export interface IUpdateCreditCardInput {
    cardDetails?: ICardPartialDetails;
    organisationIdentifier: ICardOrganisationIdentifier;
}

@Component({
    selector: "adapt-update-credit-card-details",
    templateUrl: "./update-credit-card-details.component.html",
    styleUrls: ["./update-credit-card-details.component.scss"],
})
export class UpdateCreditCardDetailsComponent extends BaseComponent implements OnInit {

    @Input() public updateCreditCardInput!: IUpdateCreditCardInput;
    @Output() public cardSet = new EventEmitter<any>();

    public cardDetails?: ICardPartialDetails;
    public organisationIdentifier!: ICardOrganisationIdentifier;

    public paymentElements?: StripeElements;
    public stripePaymentComplete: boolean = false;

    public constructor(
        private paymentProcessingService: PaymentProcessingService) {
        super();
    }

    public ngOnInit() {
        const self = this;

        this.cardDetails = this.updateCreditCardInput.cardDetails;
        this.organisationIdentifier = this.updateCreditCardInput.organisationIdentifier;

        if (!this.cardDetails) {
            this.cardDetails = {} as ICardPartialDetails;
        }

        self.paymentProcessingService.stripe$.pipe(
            // give angular time to build up the DOM prior to getting Stripe to create the card elements
            delay(0),
            tap(createStripeElements),
        ).subscribe();

        function createStripeElements(stripe: Stripe) {
            self.paymentElements = stripe.elements({
                mode: "setup",
                currency: "aud",
                payment_method_types: ["card"],
            });
            if (!self.paymentElements) {
                throw new Error("Can't create credit card elements (1).");
            }

            const paymentElement = self.paymentElements.create("payment");
            if (!paymentElement) {
                throw new Error("Can't create credit card elements (2).");
            }

            paymentElement.on("change", handleStripeElementChange);
            paymentElement.mount("#payment-element");

            function handleStripeElementChange(e: StripePaymentElementChangeEvent) {
                self.stripePaymentComplete = e.complete;
                self.cardSet.emit(self.stripePaymentComplete && self.cardDetails!.name);
            }
        }
    }

    public saveCreditCard() {
        return this.paymentProcessingService.setPaymentDetails(this.organisationIdentifier, this.paymentElements!, this.cardDetails!.name);
    }

    public handleCardNameChange(cardName: string) {
        this.cardDetails!.name = cardName;
        this.cardSet.emit(this.stripePaymentComplete && this.cardDetails!.name);
    }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { AdaptFeatureModule } from "@common/feature/feature.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { ContentWithSplashImageModule } from "@common/ux/content-with-splash-image/content-with-splash-image.module";
import { DxTextBoxModule, DxValidationGroupModule, DxValidatorModule } from "devextreme-angular";
import { ExternalLoginModule } from "../external-login/external-login.module";
import { ForgotPasswordPageComponent } from "./forgot-password-page/forgot-password-page.component";
import { forgotPasswordPageRoute } from "./forgot-password-page/forgot-password-page.route";
import { LoginPageComponent } from "./login-page/login-page.component";
import { loginPageRoute } from "./login-page/login-page.route";
import { ResetPasswordPageComponent } from "./reset-password-page/reset-password-page.component";
import { resetPasswordPageRoute } from "./reset-password-page/reset-password-page.route";
import { SetInitialPasswordPageComponent } from "./set-initial-password-page/set-initial-password-page.component";
import { setInitialPasswordPageRoute } from "./set-initial-password-page/set-initial-password-page.route";

@NgModule({
    declarations: [
        LoginPageComponent,
        ForgotPasswordPageComponent,
        ResetPasswordPageComponent,
        SetInitialPasswordPageComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        FormsModule,
        DxValidationGroupModule,
        DxValidatorModule,
        DxTextBoxModule,
        AdaptButtonModule,
        ContentWithSplashImageModule,
        AdaptFeatureModule,
        ExternalLoginModule,
    ],
})
export class IdentityUxModule {
    public static readonly Routes = [
        ...loginPageRoute.routes,
        ...forgotPasswordPageRoute.routes,
        ...resetPasswordPageRoute.routes,
        ...setInitialPasswordPageRoute.routes,
        ...ExternalLoginModule.Routes,
    ];
}

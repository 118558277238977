<!-- Ordinal of 100 to make sure we are always the furthest to the right -->
<button *ngIf="context === pageContext"
        adaptToolbarContent
        alignment="right"
        [ordinal]="2000"
        id="helpButton"
        adaptButton="help"
        [ngClass]="{active: contextSidebarVisible}"
        (click)="toggleHelp()"
        data-test="help-button"></button>

<div #titleRef>
    <ng-content select="[context-sidebar-title]"></ng-content>
</div>

<div #contentRef>
    <ng-content></ng-content>
</div>

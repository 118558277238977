<div class="d-flex flex-column align-items-center">
    <div class="pb-3">
        <adapt-strategic-guidance area="purpose"></adapt-strategic-guidance>
    </div>

    <ng-container *ngIf="purpose?.content">
        <div class="mb-4 bt-1">
            <div class="mt-2 p-3 purpose-statement">
                <label>Purpose statement</label>
                <div class="ms-2"
                     [froalaView]="purpose!.content"></div>
                <div class="mt-2"
                     *ngIf="purpose!.notes">
                    <label>Notes</label>
                    <div class="ms-2"
                         [froalaView]="purpose!.notes"></div>
                </div>
            </div>
        </div>
    </ng-container>

    <button *adaptIfAuthorised="CompassGuidingPhilosophyEdit"
            class="btn btn-primary px-4"
            [adaptBlockingClick]="recordPurposeStatement"
            role="button">{{!!purpose?.content ? "Update" : "Record"}} purpose statement
    </button>
</div>

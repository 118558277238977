import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { UserType } from "@common/ADAPT.Common.Model/embed/user-type";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleConnection, RoleConnectionBreezeModel } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { SelectAccessLevelComponent } from "@org-common/lib/user-management/select-access-level/select-access-level.component";
import { EntityState } from "breeze-client";
import { lastValueFrom } from "rxjs";

@Component({
    selector: "adapt-edit-person-access-dialog",
    templateUrl: "./edit-person-access-dialog.component.html",
})
export class EditPersonAccessDialogComponent extends BaseDialogWithDiscardConfirmationComponent<Connection, Person> implements OnInit {
    public readonly dialogName = "EditPersonAccessDialog";

    public get entitiesToConfirm() {
        if (!this.connection) {
            return [];
        }

        const connections = this.connection.roleConnections.filter((rc) => rc.entityAspect.entityState !== EntityState.Unchanged);
        return connections;
    }

    public title?: string;
    public person?: Person;
    public connection!: Connection;
    public roleConnections: RoleConnection[] = [];
    public newAccessLevel?: Role;

    public UserType = UserType;

    @ViewChild(SelectAccessLevelComponent) private selectAccessLevelComponent?: SelectAccessLevelComponent;

    constructor(
        @Inject(ADAPT_DIALOG_DATA) private dialogData: Connection,
        protected commonDialogService: AdaptCommonDialogService,
    ) {
        super();
    }

    public ngOnInit() {
        this.connection = this.dialogData;
        this.person = this.connection.person;
        this.title = `Edit access levels for ${this.person!.fullName}`;
        this.autoResolveData = this.person;

        this.setRoleConnections();
    }

    public permissionRemoved(roleConnection: RoleConnection) {
        roleConnection.endDate = new Date();

        this.setRoleConnections();
        this.selectAccessLevelComponent?.reload();
    }

    public async newAccessLevelSelected(role: Role) {
        if (!role) {
            return;
        }

        const existingRoleConnection = role.roleConnections.find((rc) => rc.connection === this.connection);
        if (existingRoleConnection) {
            // already have a connection to the role that we can use, just reset the endDate
            existingRoleConnection.endDate = undefined;
        } else {
            await lastValueFrom(this.commonDataService.create(RoleConnectionBreezeModel, {
                role,
                connection: this.connection,
                startDate: new Date(),
            }));
        }

        this.setRoleConnections();
        this.selectAccessLevelComponent?.reload();
    }

    @Autobind
    public roleFilter(role: Role) {
        return !this.roleConnections
            .map((rc) => rc.role)
            .includes(role);
    }

    private setRoleConnections() {
        this.roleConnections = this.connection.roleConnections
            .filter((rc) => rc.isActive() && rc.role && rc.role.extensions.isLeaderAccessRole());
    }
}

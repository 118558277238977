import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { ContactType, ContactTypeMetadata } from "../embed/contact-type";
import { Organisation } from "../organisation/organisation";
import { Person } from "./person";

export class PersonContact extends BaseEntity<PersonContact> {
    public personContactId!: number;
    public personId!: number;
    public organisationId?: number;
    public contactType!: ContactType;
    public value!: string;
    public description?: string;
    public isPreferred!: boolean;
    public isPrivate!: boolean;
    public organisation!: Organisation;
    public person!: Person;

    public get url() {
        const metaData = ContactTypeMetadata.ByType[this.contactType];
        return metaData.urlPrefix
            ? metaData.urlPrefix + this.value
            : undefined;
    }
}

export const PersonContactBreezeModel = new BreezeModelBuilder("PersonContact", PersonContact)
    .withIdField()
    .hasSource()
    .isOrganisationEntity()
    .build();

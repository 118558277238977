<adapt-feature-guidance>
    <p>External links allow you to embed links created in third party tools within {{ productName }}.
        Some commonly used dashboards are
        <a href="https://workspace.google.com/"
           rel="noreferrer noopener"
           target="_blank">Google G Suite</a>
        or
        <a href="https://cumul.io/"
           rel="noreferrer noopener"
           target="_blank">Cumul.io</a>.
    </p>
    <p *ngIf="!team">
        The links set up below will be visible to anyone with access to the organisation
        dashboard (typically all employees).
    </p>
</adapt-feature-guidance>

<adapt-feature-configuration [configItem]="configItem"
                             [team]="team">
    <ng-container *ngIf="featureIsEnabledAndSaved; else featureNotSaved">
        <dx-list [dataSource]="externalDashboards"
                 [focusStateEnabled]="false"
                 [activeStateEnabled]="false"
                 (onItemReordered)="onItemReordered($event)"
                 noDataText="No external links defined."
                 class="external-dashboards-list">
            <dxo-item-dragging [allowReordering]="true"></dxo-item-dragging>
            <div *dxTemplate="let dashboard of 'item'"
                 class="d-flex flex-row ps-3 align-items-center">
                <div>{{dashboard.name}}</div>
                <div class="ms-auto d-flex align-items-center gap-1">
                    <span *ngIf="dashboard.isPublicRead"
                          adaptTooltip="This dashboard is visible to anyone with access to public dashboards"
                          class="badge bg-warning m-1">
                        <i class="fal fa-fw fa-eye"></i>
                    </span>
                    <span *ngIf="!dashboard.isPublicRead"
                          adaptTooltip="This dashboard is private to the team"
                          class="badge bg-warning m-1">
                        <i class="fal fa-fw fa-lock"></i>
                    </span>
                    <button adaptButton="editBorderless"
                            adaptTooltip="Edit external link"
                            [adaptBlockingClick]="editExternalDashboard"
                            [adaptBlockingClickParam]="dashboard"></button>
                    <button class="m-0"
                            adaptButton="deleteBorderless"
                            adaptTooltip="Delete external link"
                            [adaptBlockingClick]="deleteExternalDashboard"
                            [adaptBlockingClickParam]="dashboard"
                            [unsubscribeOnDestroy]="false"></button>
                </div>
            </div>
        </dx-list>

        <button adaptButton="add"
                [adaptBlockingClick]="addExternalDashboard">external link
        </button>
    </ng-container>

    <ng-template #featureNotSaved>Please save your changes in order to configure external links.</ng-template>
</adapt-feature-configuration>

export enum Zone {
    OrganisationDesign = "OrganisationDesign",
    EconomicEngine = "EconomicEngine",
    HealthyCulture = "HealthyCulture",
    FinanceAndOwnership = "FinanceAndOwnership",
    ResearchAndDevelopment = "ResearchAndDevelopment",
}

export class ZoneMetadata {
    public static readonly Name: { [key in Zone]: string } = {
        [Zone.OrganisationDesign]: "Organisation design",
        [Zone.EconomicEngine]: "Economic engine",
        [Zone.HealthyCulture]: "Leadership & culture",
        [Zone.FinanceAndOwnership]: "Financial security",
        [Zone.ResearchAndDevelopment]: "Research & development",
    };

    public static readonly Ordinal: { [key in Zone]: number } = {
        [Zone.ResearchAndDevelopment]: 0,
        [Zone.HealthyCulture]: 1,
        [Zone.OrganisationDesign]: 2,
        [Zone.FinanceAndOwnership]: 3,
        [Zone.EconomicEngine]: 4,
    };

    public static readonly Icon: { [key in Zone]: string } = {
        [Zone.OrganisationDesign]: "fas fa-pen-ruler",
        [Zone.EconomicEngine]: "fas fa-train-subway",
        [Zone.ResearchAndDevelopment]: "fas fa-magnifying-glass-dollar",
        [Zone.HealthyCulture]: "fas fa-leaf",
        [Zone.FinanceAndOwnership]: "fas fa-dollar-sign",
    };

    public static readonly BackgroundStyleClass: { [key in Zone]: string } = {
        [Zone.OrganisationDesign]: "zone-organisation-design-dashboard-element",
        [Zone.EconomicEngine]: "zone-economic-engine-dashboard-element",
        [Zone.ResearchAndDevelopment]: "zone-research-development-dashboard-element",
        [Zone.HealthyCulture]: "zone-healthy-culture-dashboard-element",
        [Zone.FinanceAndOwnership]: "zone-finance-ownership-dashboard-element",
    };

    // These correspond with the colours in the scss
    public static readonly Colour: { [key in Zone]: string } = {
        [Zone.OrganisationDesign]: "var(--adapt-zone-organisation-design)",
        [Zone.EconomicEngine]: "var(--adapt-zone-economic-engine)",
        [Zone.ResearchAndDevelopment]: "var(--adapt-zone-research-development)",
        [Zone.HealthyCulture]: "var(--adapt-zone-healthy-culture)",
        [Zone.FinanceAndOwnership]: "var(--adapt-zone-finance-ownership)",
    };

    public static readonly VisibleZones = [Zone.EconomicEngine, Zone.HealthyCulture, Zone.FinanceAndOwnership, Zone.OrganisationDesign];

    public static readonly InOrder = [
        Zone.EconomicEngine,
        Zone.ResearchAndDevelopment,
        Zone.HealthyCulture,
        Zone.OrganisationDesign,
        Zone.FinanceAndOwnership,
    ];

    public static getZoneColour(zone: Zone, weight?: ZoneColorWeight) {
        if (weight) {
            return ZoneMetadata.Colour[zone].replace(")", "-" + weight + ")");
        }
        return ZoneMetadata.Colour[zone];
    }
}

export enum ZoneColorWeight {
    TWENTY_FIVE = "25",
    FIFTY = "50",
    ONE_HUNDRED = "100",
    TWO_HUNDRED = "200",
    THREE_HUNDRED = "300",
    FOUR_HUNDRED = "400",
    SEVEN_HUNDRED = "700",
    EIGHT_HUNDRED = "800",
    ONE_THOUSAND = "1000",
}

<adapt-dialog [maxWidth]="900"
              [disallowCancel]="!!dialogData.disallowCancel"
              [fitHeight]="true">
    <h3 adapt-dialog-title>Start {{ProductLabel}} subscription</h3>
    <ng-container adapt-dialog-content>
        <ng-container *ngIf="isIntroPage">
            <adapt-styled-implementation-kit [articleId]="StartSubscriptionArticle"
                                             contentStyle="embedded"></adapt-styled-implementation-kit>
        </ng-container>

        <ng-container *ngIf="!isIntroPage">
            <div *ngIf="promptingEula"
                 class="d-flex flex-column gap-3 h-100">
                <p class="m-0 border-bottom pb-3"><strong>
                        Please read the {{ProductLabel}} customer license agreement below,
                        tick the checkbox and accept on behalf of {{organisation?.name}}.</strong>
                </p>
                <adapt-eula (loadedChange)="eulaLoaded = $event"></adapt-eula>
                <dx-check-box class="d-block border-top pt-3"
                              text="I have read and agree to the terms of the customer license agreement"
                              [disabled]="!eulaLoaded"
                              [value]="eulaAccepted"
                              (valueChange)="eulaAccepted = $any($event)"></dx-check-box>
            </div>
            <ng-container *ngIf="!promptingEula">
                <ng-container *ngIf="account">
                    <div *ngIf="organisation"
                         class="d-flex flex-column">
                        <h4>Platform subscription</h4>
                        <p><small>See our&nbsp;<a href="https://theadaptway.com/pricing"
                                   target="_blank">pricing page</a>&nbsp;for details on features and plans. <b>Our risk-free 30-day refund
                                    policy applies</b>.</small></p>
                        <ng-container *ngIf="!account.extensions.isFree; else freeAccount">
                            <adapt-select-payment-frequency *ngIf="!account.pricingModel || account.pricingModel.annualDiscountPercentage; else monthlyOnly"
                                                            [account]="account"
                                                            (selectionChanged)="onSelect($event)"></adapt-select-payment-frequency>
                            <ng-template #monthlyOnly>
                                <adapt-display-pricing-breakdown class="mb-3"
                                                                 [pricingModel]="account.pricingModel"
                                                                 [organisation]="organisation"></adapt-display-pricing-breakdown>
                            </ng-template>
                        </ng-container>
                        <ng-template #freeAccount>
                            <div class="alert alert-secondary">
                                You will not be charged with your free account.
                            </div>
                        </ng-template>
                    </div>
                </ng-container>
                <ng-container *ngIf="!account.extensions.isFree">
                    <h4>Credit card details</h4>
                    <ng-container *adaptLoading="cardDetailsLoading">
                        <adapt-update-credit-card-details *ngIf="!cardDetails"
                                                          [updateCreditCardInput]="creditCardInput"
                                                          (cardSet)="cardSetCorrectly = $event"></adapt-update-credit-card-details>

                        <adapt-configure-credit-card *ngIf="cardDetails"
                                                     [(cardDetails)]="cardDetails"
                                                     [organisationId]="creditCardInput.organisationIdentifier.organisationId"
                                                     [eulaToken]="creditCardInput.organisationIdentifier.eulaToken"
                                                     [allowEditing]="true"
                                                     (cardIsSet)="cardSetCorrectly = $event"></adapt-configure-credit-card>
                    </ng-container>
                </ng-container>
                <div *ngIf="account.extensions.canResumeSubscriptionWithoutCharge(); else possiblyPaid"
                     class="alert alert-info mt-3 mb-0">
                    Your previous subscription will remain active until <strong>{{account.nextSubscriptionInvoiceDate |
                        adaptDate}}</strong>.
                    You will not be charged again until after this date.
                </div>
                <ng-template #possiblyPaid>
                    <ng-container *ngIf="account.extensions.accountPaidUntilDate as paidUntil">
                        <div *ngIf="paidUntil.getTime() > Now"
                             class="alert alert-info mt-3 mb-0">
                            Your subscription has already been paid for the period ending <strong>{{paidUntil |
                                adaptDate}}</strong>.
                            You will not be charged again until after this date.
                        </div>
                    </ng-container>
                </ng-template>
            </ng-container>
        </ng-container>
        <!-- track this when coming across from signup with 0-day trial -->
        <adapt-marketing-tracker *ngIf="dialogData.disallowCancel"
                                 pageName="/set-initial-subscription"></adapt-marketing-tracker>
    </ng-container>

    <div adapt-dialog-footer
         class="w-100 d-flex gap-1"
         *ngIf="isIntroPage; else acceptEulaAndSubscribe">
        <button adaptButton="workflowNext"
                class="ms-auto"
                (click)="isIntroPage = false"
                data-test="next-page-button">Next</button>
        <button *ngIf="!dialogData.disallowCancel"
                adaptButton="iconlessCancel"
                (click)="cancel()"></button>
    </div>
    <ng-template #acceptEulaAndSubscribe>
        <div adapt-dialog-footer
             class="w-100 d-flex">
            <button adaptButton="workflowPrevious"
                    (click)="isIntroPage = true">Back</button>
            <ng-container *ngIf="promptingEula; else promptSubscribe">
                <adapt-standard-dialog-buttons adapt-dialog-footer
                                               (cancelClick)="cancel()"
                                               saveButtonPreset="primary"
                                               [saveBlockingClick]="acceptEula"
                                               [saveIsDisabled]="!eulaAccepted"
                                               [hideCancelButton]="!!dialogData.disallowCancel"
                                               saveText="Accept"></adapt-standard-dialog-buttons>
            </ng-container>
            <ng-template #promptSubscribe>
                <adapt-standard-dialog-buttons adapt-dialog-footer
                                               (cancelClick)="cancel()"
                                               saveButtonPreset="primary"
                                               [saveBlockingClick]="saveAndClose"
                                               [saveIsDisabled]="(!cardSetCorrectly || !account.contactName || !account.contactEmail) && !account.extensions.isFree"
                                               [hideCancelButton]="!!dialogData.disallowCancel"
                                               [saveText]="account.extensions.isPendingCancellation ? 'Resume subscription' : 'Start subscription'"></adapt-standard-dialog-buttons>
            </ng-template>
        </div>
    </ng-template>
</adapt-dialog>

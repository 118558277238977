import { ISurveyCategory } from "../survey-category.interface";
import { TeamAssessmentCategoryGrade } from "./team-assessment-questions";

export class TeamAssessmentConstants {
    public static readonly GoodColor = "rgba(153, 180, 86, 1)";
    public static readonly GoodBackgroundColor = "#f2f5e9";
    public static readonly WarningColor = "rgba(239, 139, 44, 1)";
    public static readonly WarningBackgroundColor = "#fdf0e4";
    public static readonly BadColor = "rgba(192, 46, 29, 1)";
    public static readonly BadBackgroundColor = "#f7e4e2";

    public static readonly GoodPercentageThreshold = 80;
    public static readonly WarningPercentageThreshold = 50;

    public static readonly MaxScore = 4;

    public static readonly Categories: readonly ISurveyCategory[] = [{
        name: TeamAssessmentCategoryGrade.Good,
        color: TeamAssessmentConstants.GoodColor,
        percentageThreshold: TeamAssessmentConstants.GoodPercentageThreshold,
    }, {
        name: TeamAssessmentCategoryGrade.Warning,
        color: TeamAssessmentConstants.WarningColor,
        percentageThreshold: TeamAssessmentConstants.WarningPercentageThreshold,
    }, {
        name: TeamAssessmentCategoryGrade.Bad,
        color: TeamAssessmentConstants.BadColor,
        percentageThreshold: 0,
    }];
}

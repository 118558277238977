import { Injector } from "@angular/core";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { of } from "rxjs";
import { map } from "rxjs/operators";
import { BaseInitialisationService } from "./base-initialisation.service";

export abstract class BaseService extends BaseInitialisationService {
    public save = this.afterInitialisation(this.commonDataService.save);
    public saveEntities = this.afterInitialisation(this.commonDataService.saveEntities);
    public remove = this.afterInitialisation(this.commonDataService.remove);
    public rejectChanges = this.afterInitialisation(this.commonDataService.rejectChanges);
    public cancel = this.afterInitialisation(this.commonDataService.cancel);
    public create = this.afterInitialisation(this.commonDataService.create);
    public hasChanges = this.commonDataService.hasChanges;
    public getChanges = this.commonDataService.getUncommittedChanges;
    public changesAreValid = this.commonDataService.entitiesAreValid;

    public constructor(protected injector: Injector) {
        super();
    }

    protected get commonDataService() {
        return this.injector.get(CommonDataService);
    }

    protected saveChangedEntities<T extends IBreezeEntity>(entities: T[]) {
        const changedEntities = entities.filter((entity) => entity.entityAspect.entityState.isAddedModifiedOrDeleted());
        if (changedEntities.length > 0) {
            return this.saveEntities(changedEntities).pipe(
                map(() => entities),
            );
        } else {
            return of(entities);
        }
    }
}

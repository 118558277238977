import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { ItemMetadata } from "@common/ADAPT.Common.Model/organisation/item";
import { MeetingAgendaItem } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { MeetingItem } from "@common/ADAPT.Common.Model/organisation/meeting-item";
import { MeetingNote, MeetingNoteType, MeetingNoteTypeMetadata } from "@common/ADAPT.Common.Model/organisation/meeting-note";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { BehaviorSubject, forkJoin, Subscription } from "rxjs";
import { debounceTime, switchMap } from "rxjs/operators";
import { MeetingsService } from "../meetings.service";

// Do this in a separate component to be reused in the sidebar display if required
@Component({
    selector: "adapt-agenda-item-meta-data-count",
    templateUrl: "./agenda-item-meta-data-count.component.html",
    styleUrls: ["./agenda-item-meta-data-count.component.scss"],
})
export class AgendaItemMetaDataCountComponent extends BaseComponent implements OnChanges {
    public readonly MeetingNoteIconClass = MeetingNoteTypeMetadata.IconClass;
    public readonly MeetingNoteType = MeetingNoteType;
    public readonly MeetingItemIconClass = ItemMetadata.IconClass;

    @Output() public contentReady = new EventEmitter<void>();
    @Input() public showTotalBadgeOnly = false;

    // passing notes and items from parent container as they are already there since they are shown
    // in agenda-item details. No point issue another 2 queries for each agenda items.
    @Input() public meetingNotes: MeetingNote[] = [];
    @Input() public meetingItems: MeetingItem[] = [];

    // alternatively can pass in agenda item which will result in queries for notes and items
    @Input() public agendaItem?: MeetingAgendaItem;

    public decisionCount = 0;
    public minuteCount = 0;
    public itemCount = 0;
    public totalBadgeTooltip = "Total decisions, minutes and actions";

    private updateAgendaItem$ = new BehaviorSubject<void>(undefined);
    // The followings are only required if agendaItem is passed through input
    private itemSubscription?: Subscription;
    private meetingNoteUpdateSubscription?: Subscription;
    private meetingItemUpdateSubscription?: Subscription;

    constructor(
        private meetingsService: MeetingsService,
        private rxjsBreezeService: RxjsBreezeService,
    ) {
        super();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.meetingNotes && this.meetingNotes) {
            this.decisionCount = this.meetingNotes.filter((n) => n.type === MeetingNoteType.Decision).length;
            this.minuteCount = this.meetingNotes.filter((n) => n.type === MeetingNoteType.Minute).length;
            this.updateTotalBadgeTooltip();
        }

        if (changes.meetingItems && this.meetingItems) {
            this.itemCount = this.meetingItems.length;
            this.updateTotalBadgeTooltip();
        }

        if (changes.agendaItem && this.agendaItem) {
            if (this.itemSubscription) {
                this.itemSubscription.unsubscribe();
            }

            this.itemSubscription = this.updateAgendaItem$.pipe(
                debounceTime(10),
                switchMap(() => forkJoin([
                    this.meetingsService.getMeetingNotesForAgendaItem(this.agendaItem!),
                    this.meetingsService.getMeetingItemsForAgendaItem(this.agendaItem!),
                ])),
                this.takeUntilDestroyed(),
            ).subscribe(([meetingNotes, meetingItems]) => {
                this.decisionCount = meetingNotes.filter((n) => n.type === MeetingNoteType.Decision).length;
                this.minuteCount = meetingNotes.filter((n) => n.type === MeetingNoteType.Minute).length;
                this.itemCount = meetingItems.length;
                this.updateTotalBadgeTooltip();
            });

            // the followings are only needed if agendaItem is passed in through @Input. If passed in array, all will be captured by ngOnChanges
            if (!this.meetingNoteUpdateSubscription) {
                this.meetingNoteUpdateSubscription = this.rxjsBreezeService.entityTypeChanged(MeetingNote).pipe(
                    this.takeUntilDestroyed(),
                ).subscribe((meetingNote) => {
                    if (this.agendaItem && meetingNote.meetingAgendaItemId === this.agendaItem?.meetingAgendaItemId) {
                        this.updateAgendaItem$.next();
                    }
                });
            }

            if (!this.meetingItemUpdateSubscription) {
                this.meetingItemUpdateSubscription = this.rxjsBreezeService.entityTypeChanged(MeetingItem).pipe(
                    this.takeUntilDestroyed(),
                ).subscribe((meetingItem) => {
                    if (this.agendaItem && meetingItem.meetingAgendaItemId === this.agendaItem?.meetingAgendaItemId) {
                        this.updateAgendaItem$.next();
                    }
                });
            }
        }
    }

    private updateTotalBadgeTooltip() {
        // won't be shown if nothing
        this.totalBadgeTooltip = "";
        if (this.decisionCount > 0) {
            this.totalBadgeTooltip += `${this.decisionCount} decision${this.decisionCount > 1 ? "s" : ""}`;
        }

        if (this.minuteCount > 0) {
            if (this.totalBadgeTooltip) {
                this.totalBadgeTooltip += ", ";
            }
            this.totalBadgeTooltip += `${this.minuteCount} minute${this.minuteCount > 1 ? "s" : ""}`;
        }

        if (this.itemCount > 0) {
            if (this.totalBadgeTooltip) {
                this.totalBadgeTooltip += ", ";
            }
            this.totalBadgeTooltip += `${this.itemCount} work item${this.itemCount > 1 ? "s" : ""}`;
        }

        if (this.totalBadgeTooltip) {
            this.totalBadgeTooltip += " added to this agenda item.";
        }

        this.contentReady.next();
    }
}

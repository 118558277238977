import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from "@angular/core";
import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { ItemStatus, ItemStatusMetadata } from "@common/ADAPT.Common.Model/organisation/item-status";
import { Label } from "@common/ADAPT.Common.Model/organisation/label";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ShellStyleConstants } from "@common/shell/shell-style.constants";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { IDxSortableEvent } from "@common/ux/dx.types";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { ContentReadyEvent, HidingEvent } from "devextreme/ui/popover";
import dxSortable from "devextreme/ui/sortable";
import { DxScrollViewComponent } from "devextreme-angular";
import { of } from "rxjs";
import { KanbanService } from "../kanban.service";
import { KanbanGroup } from "../kanban-grouping.enum";

const ExcessiveItemCount = 10;

type ItemStatusObject<T> = { [key in ItemStatus]: T };
type ItemCollection = ItemStatusObject<Item[]>;

interface IGroupedItemCollection {
    key: Person | Label | Board | undefined;
    keyString: string;
    items: ItemCollection;
    itemCount: number;
    collapsed: boolean;
}

interface IStatusHeaderBadge {
    content: string;
    badgeClass: string;
}

interface IStatusHeaderTooltip {
    content: string;
    contentButton?: {
        buttonText: string;
        buttonClass: string;
        iconClass: string;
        onClick?: () => void;
    };
}

interface IStatusHeaderItem {
    status: ItemStatus;
    icon?: string;
    badge?: IStatusHeaderBadge;
    tooltip?: IStatusHeaderTooltip,
    tooltipVisible?: boolean;
}

@Component({
    selector: "adapt-board",
    templateUrl: "./board.component.html",
    styleUrls: ["./board.component.scss"],
})
export class BoardComponent extends BaseComponent implements OnChanges, AfterViewInit, OnDestroy {
    public readonly ItemStatuses = ItemStatusMetadata.ByStatus;
    public readonly Backlog = ItemStatus.Backlog;
    public readonly KanbanGroup = KanbanGroup;

    @Input() public showAssignees = true;
    @Input() public columnGroup?: KanbanGroup;

    // passed in from page based on query param to have item selected
    @Input() public selectedItem?: Item;
    @Output() public selectedItemChange = new EventEmitter<Item>();

    @Input() public inMultipleSelectionMode = false;
    @Input() public checkedItems: Item[] = [];
    @Output() public checkedItemsChange = new EventEmitter<Item[]>();

    @Input() public showStatuses = [ItemStatus.ToDo, ItemStatus.InProgress, ItemStatus.Done];
    @Input() public items: Item[] = [];

    @Output() public itemDialogOpened = new EventEmitter<Item>();
    @Output() public itemDialogClosed = new EventEmitter<Item>();

    @Output() public showBacklogClick = new EventEmitter<boolean>();

    // doing .bind(this) as we expect a function we can call for each item
    private readonly statusHeaderItemGenerators: (() => IStatusHeaderItem)[] = [
        // create an item count badge for every status
        ...ItemStatusMetadata.All.map(({ status }) => this.createStatusHeaderItemCountBadge.bind(this, status)),
    ];

    public headerItemsForStatus: ItemStatusObject<IStatusHeaderItem[]> = this.initialHeaderItems;
    public hasSelectionForStatus: ItemStatusObject<boolean> = this.initialAllSelection;
    public itemCountForStatus: ItemStatusObject<number> = this.initialItemCount;
    public itemCollectionGroups: IGroupedItemCollection[] = [];

    public loadingItems = true;
    public backlogSuggestionVisible = false;
    public popoverCancelHiding = false;
    public refreshRequired = false;
    public isMobileView = this.responsiveService.currentBreakpoint.isMobileSize;

    @ViewChild(DxScrollViewComponent) private scrollView!: DxScrollViewComponent;
    private mainView: HTMLElement | null = null; // type returned from HTMLElement.closest() - can't use ?: HTMLElement
    private draggingGroupKey?: string;

    public verticalLaneHeight?: string;

    public constructor(
        elementRef: ElementRef,
        private responsiveService: ResponsiveService,
        private kanbanService: KanbanService,
    ) {
        super(elementRef);
    }

    public ngOnChanges(changes: SimpleChanges) {
        let refreshViewStyle = false;
        if (changes.items || changes.columnGroup) {
            if (this.items) {
                this.processItemsChange();
                this.loadingItems = false;
                refreshViewStyle = true;
            } else {
                this.loadingItems = true;
            }
        }

        if (changes.inMultipleSelectionMode) {
            refreshViewStyle = true;
        }

        if (changes.showStatuses && this.items) {
            refreshViewStyle = true;

            // backlog display removed, reset selection status for backlog column
            if (changes.showStatuses.previousValue.includes(ItemStatus.Backlog)
                && !changes.showStatuses.currentValue.includes(ItemStatus.Backlog)) {
                this.hasSelectionForStatus.Backlog = false;
            }
        }

        if (changes.inMultipleSelectionMode && !changes.inMultipleSelectionMode.currentValue) {
            // clear out selection status
            this.hasSelectionForStatus = this.initialAllSelection;
        }

        if (refreshViewStyle) {
            // do within timeout so occurs once items are ready
            setTimeout(() => this.updateStyling());
            // without this an unneeded horizontal scroll shows up
            this.updateScrollView();
        }
    }

    public ngAfterViewInit() {
        this.mainView = (this.elementRef?.nativeElement as HTMLElement).closest<HTMLElement>(ShellStyleConstants.MainViewClassSelect);

        window.addEventListener("resize", this.resizeUpdateStyling);
    }

    public ngOnDestroy() {
        super.ngOnDestroy();

        window.removeEventListener("resize", this.resizeUpdateStyling);
    }

    public itemCollectionGroupTrackBy(_index: number, group: IGroupedItemCollection) {
        return group.keyString;
    }

    public itemTrackBy(_index: number, item: Item) {
        return item.itemId;
    }

    public statusTrackBy(_index: number, status: ItemStatus) {
        return status;
    }

    public asBoard(key: IGroupedItemCollection["key"]) {
        if (key instanceof Board) {
            return key;
        }

        return undefined;
    }

    public getVerticalLaneContentClass(status: ItemStatus, group: IGroupedItemCollection) {
        const contentClasses = [];
        if (this.draggingGroupKey === group.keyString) {
            contentClasses.push("on-drag");
        }

        if (status === ItemStatus.Backlog) {
            contentClasses.push("is-backlog");
        }

        if (!group.items[status].length) {
            contentClasses.push("no-item");
        } else if (this.hasExcessiveItemCount(status)) {
            contentClasses.push("too-many-items");
        }

        return contentClasses.join(" ");
    }

    public onDragStart(e: IDxSortableEvent<Item[]>, group: IGroupedItemCollection) {
        const item = e.fromData![e.fromIndex!];
        if (item.extensions.currentPersonCanEdit) { // this is set from kanban card
            this.draggingGroupKey = group.keyString;
        } else {
            e.cancel = true;
        }
    }

    public onDragEnd(e: IDxSortableEvent<Item[]>, group: IGroupedItemCollection) {
        this.draggingGroupKey = undefined;

        if (e.fromData !== e.toData) {
            // move across lane
            const changeItem = e.fromData![e.fromIndex!];
            e.fromData!.splice(e.fromIndex!, 1);
            e.toData!.splice(e.toIndex!, 0, changeItem);
            changeItem.rank = this.kanbanService.getProposedKanbanItemRank(e.toData!, e.toIndex!);

            // can only move items between lanes in the same group
            this.updateItemCollection(group);
        }
    }

    public onDragMoved(e: IDxSortableEvent<Item[]>) {
        if (e.toComponent instanceof dxSortable && this.refreshRequired) {
            this.refreshRequired = false;
            e.toComponent.update();
        }
    }

    public onOrderChanged(e: IDxSortableEvent<Item[]>) {
        const movedItem = e.fromData!.splice(e.fromIndex!, 1)[0];
        e.toData!.splice(e.toIndex!, 0, movedItem);
        movedItem.rank = this.kanbanService.getProposedKanbanItemRank(e.toData!, e.toIndex!);

        this.saveItems([movedItem]).subscribe();
    }

    public onItemSelected(item?: Item) {
        this.selectedItem = item;
        this.selectedItemChange.emit(item);
    }

    public hideBacklog() {
        this.showBacklogClick.emit(false);
        this.hasSelectionForStatus.Backlog = false;
    }

    public onPopoverContentReady(e: ContentReadyEvent, statusHeaderItem: IStatusHeaderItem) {
        // this is derived from:
        // https://www.devexpress.com/Support/Center/Question/Details/T546073/how-to-close-dx-popover-on-event-dxhoverend-angular
        //
        // Note that dxpointerdown will close the popover when the action link is clicked. The previous implementation of using actionPerformed
        // never worked (see production where you click on 'View Access' from person popover - the popover remains on top of the access
        // dialog). That's because person actions are registering promise function and waiting for promise resolution before actionPerformed
        // is being called. So provided the dialog is not closed, actionPerformed won't be toggled.
        // Not changing that behaviour as profile controller is relying on that to refresh the controller after changes from action.
        // Only need action started notification here - so might as well add the dxpointerdown to close popover instead.
        //
        // Also notice that personLink popover not showing for tablet and phone which was the behaviour before as the <a> will navigate
        // to the person dashboard immediate upon touch down.
        (e.component.content() as unknown as JQuery<HTMLElement>)
            .on("dxpointerleave", () => {
                statusHeaderItem.tooltipVisible = false;
                this.popoverCancelHiding = false;
            })
            .on("dxpointerdown", (event) => {
                // Don't process right click events on the popover (so the user can open in new tab/window for example)
                if (event.which !== 3) {
                    // use a longish timeout, as otherwise there is an intermittent error where the click doesn't register properly
                    // this event is registered so that events that the popover is hidden for events that just spawn dialog boxes (e.g. change password)
                    setTimeout(() => {
                        statusHeaderItem.tooltipVisible = false;
                        this.popoverCancelHiding = false;
                    }, 200);
                }
            })
            .on("dxpointerenter", () => this.popoverCancelHiding = true);
    }

    public onPopoverHiding(e: HidingEvent) {
        if (this.popoverCancelHiding) {
            e.cancel = true;
        }
    }

    public inMultipleSelectionChange(item: Item, selected: boolean) {
        if (selected) {
            ArrayUtilities.addElementIfNotAlreadyExists(this.checkedItems, item);
        } else {
            ArrayUtilities.removeElementFromArray(item, this.checkedItems);
        }

        // update select all state when individual item selection changes
        this.hasSelectionForStatus[item.status] = this.checkedItems.some((i) => i.status === item.status);
        this.emitCheckedItemsChange();
    }

    public toggleSelectAll(status: ItemStatus) {
        if (this.hasSelectionForStatus[status]) {
            this.checkedItems = this.checkedItems.filter((i) => i.status !== status);
        } else {
            const itemsForStatus = this.itemCollectionGroups.flatMap((g) => g.items[status]);
            ArrayUtilities.addElementIfNotAlreadyExists(this.checkedItems, ...itemsForStatus);
        }

        this.hasSelectionForStatus[status] = !this.hasSelectionForStatus[status];
        this.emitCheckedItemsChange();
    }

    public updateScrollView() {
        // this is required after sortable [data] is updated so that scrollbar won't show up unnecessarily
        setTimeout(() => this.scrollView.instance.update());
    }

    public collapseAllGroups() {
        this.itemCollectionGroups.forEach((g) => g.collapsed = true);
    }

    public expandAllGroups() {
        this.itemCollectionGroups.forEach((g) => g.collapsed = false);
    }

    private get initialAllSelection() {
        return this.generateItemStatusObject<boolean>(() => false);
    }

    private get initialItemCount() {
        return this.generateItemStatusObject<number>(() => 0);
    }

    private get initialHeaderItems() {
        return this.generateItemStatusObject<IStatusHeaderItem[]>(() => []);
    }

    /**
     * Generates a object mapping ItemStatus to the result of contentFn.
     *
     * contentFn is a function to avoid issues with filling the object with the same array.
     * (if you did `generateItemStatusObject([])`, each status would be sharing the same array)
     */
    private generateItemStatusObject<T>(contentFn: () => T): ItemStatusObject<T> {
        return {
            [ItemStatus.ToDo]: contentFn(),
            [ItemStatus.InProgress]: contentFn(),
            [ItemStatus.Done]: contentFn(),
            [ItemStatus.Backlog]: contentFn(),
            [ItemStatus.Closed]: contentFn(),
        };
    }

    private processItemsChange() {
        this.itemCollectionGroups = [];
        this.itemCountForStatus = this.initialItemCount;
        this.hasSelectionForStatus = this.initialAllSelection;
        this.headerItemsForStatus = this.initialHeaderItems;

        // remove items that are not present anymore from checked items
        this.checkedItems = this.checkedItems.filter((i) => this.items.includes(i));
        this.emitCheckedItemsChange();

        // use a temporary object so we can get the group by key quickly
        const itemGroupByKey: Record<string, IGroupedItemCollection> = {};

        for (const item of this.items) {
            // facilitates multiple groups per item (just for labels really)
            const itemGroups: Pick<IGroupedItemCollection, "key" | "keyString">[] = [];

            if (this.columnGroup === KanbanGroup.Assignee) {
                const key = item.assignee;
                itemGroups.push({
                    key,
                    keyString: key ? `person-${key.fullName}` : "undefined-person",
                });
            } else if (this.columnGroup === KanbanGroup.Label) {
                // add a default "undefined" label for if there are no labels
                const labels: (Label | undefined)[] = item.labelLocations.length > 0
                    ? item.labelLocations.map((ll) => ll.label)
                    : [undefined];
                for (const key of labels) {
                    itemGroups.push({
                        key,
                        keyString: key ? `label-${key.name}` : "undefined-label",
                    });
                }
            } else if (this.columnGroup === KanbanGroup.Board) {
                const key = item.board;
                itemGroups.push({
                    key,
                    keyString: key ? `board-${key.personId ?? "person-null"}-${key.team?.name ?? "team-null"}-${key.ordinal}-${key.name}` : "undefined-board",
                });
            } else {
                itemGroups.push({ key: undefined, keyString: "undefined" });
            }

            for (const { key, keyString } of itemGroups) {
                let group = itemGroupByKey[keyString];
                if (!group) {
                    group = {
                        key,
                        keyString,
                        items: this.generateItemStatusObject<Item[]>(() => []),
                        itemCount: 0,
                        collapsed: false,
                    };
                    itemGroupByKey[keyString] = group;
                }

                group.itemCount++;
                group.items[item.status].push(item);
            }

            this.itemCountForStatus[item.status]++;

            // once this has been set to true, it doesn't need to be checked again in this loop
            if (!this.hasSelectionForStatus[item.status]) {
                this.hasSelectionForStatus[item.status] = this.checkedItems.some((i) => i.status === item.status);
            }
        }

        // put the temporary group into the group collection
        this.itemCollectionGroups = Object.values(itemGroupByKey);

        for (const displayStatus of this.showStatuses) {
            // sort items within groups
            for (const group of this.itemCollectionGroups) {
                group.items[displayStatus].sort((a, b) => a.rank - b.rank);
            }

            // sort groups themselves
            this.itemCollectionGroups.sort((a, b) => a.keyString.localeCompare(b.keyString));
        }

        // generate status header items for each status
        for (const generateStatusHeaderItem of this.statusHeaderItemGenerators) {
            const statusHeaderItem = generateStatusHeaderItem();
            this.headerItemsForStatus[statusHeaderItem.status].push(statusHeaderItem);
        }
    }

    private updateItemCollection(group: IGroupedItemCollection) {
        const changedItems: Item[] = [];

        for (const status of this.showStatuses) {
            for (const item of group.items[status]) {
                if (item.status !== status) {
                    item.status = status;
                    changedItems.push(item);
                }
            }
        }

        this.saveItems(changedItems).subscribe(() => this.updateScrollView());
    }

    private saveItems(items: Item[]) {
        return items.length > 0
            ? this.kanbanService.saveEntities(items)
            : of(undefined);
    }

    @Autobind
    private resizeUpdateStyling() {
        this.updateStyling();

        // some resize types (like maximising window) may cause scrollbars to get stuck until next action
        this.updateScrollView();

        this.isMobileView = this.responsiveService.currentBreakpoint.isMobileSize;
    }

    private updateStyling() {
        if (this.items?.length) {
            // need more space at the top because of the selection toolbar
            const headerOffset = this.inMultipleSelectionMode ? 50 : 0;
            this.verticalLaneHeight = this.responsiveService.currentBreakpoint.isDesktopSize
                ? `${(this.mainView?.clientHeight ?? 10) - headerOffset}px` // 5px top and bottom margin + a small 10px gap
                : `calc(100vh - ${70 + (this.mainView?.offsetTop ?? 0) + headerOffset}px)`; // cannot use clientHeight for mobile
        } else {
            // no item, don't use full screen height
            // -> constant height for the header + padding
            // There will be text placeholder element
            this.verticalLaneHeight = "90px";
        }
    }

    private emitCheckedItemsChange() {
        // use Array.from to make a new array so that change detection will trigger when items added/removed
        // setTimeout to avoid ExpressionChangedAfterItHasBeenChecked
        setTimeout(() => this.checkedItemsChange.emit(Array.from(this.checkedItems)));
    }

    private createStatusHeaderItemCountBadge(status: ItemStatus) {
        const itemCount = this.itemCountForStatus[status];
        const suffix = itemCount !== 1 ? "s" : "";
        const prefix = itemCount !== 1 ? "are" : "is";

        const tooltip: IStatusHeaderTooltip = {
            content: `<p>There ${prefix} currently ${itemCount === 0 ? "no" : itemCount} action${suffix} in this column.</p>`,
        };

        const hasExcessiveItems = this.hasExcessiveItemCount(status);
        if (hasExcessiveItems) {
            if (status === ItemStatus.InProgress) {
                tooltip.content += `<p>You should consider limiting the number of actions you have in progress at one time, to ensure effective work.</p>`;
            } else if (status === ItemStatus.Done) {
                tooltip.content += `<p>You should consider archiving actions that are no longer relevant.</p>`;
            } else if (status === ItemStatus.ToDo) {
                tooltip.content += `<p>Please consider moving some actions into the backlog queue to be addressed later.</p>
                    <p>For more information on how a backlog can be used, please <a
                           href="https://intercom.help/adaptbydesign/en/articles/5459774-using-a-kanban-backlog"
                           target="_blank">read this article</a>.</p>`;

                // add show backlog button if backlog is not already visible
                if (!this.showStatuses.includes(ItemStatus.Backlog)) {
                    tooltip.contentButton = {
                        buttonText: "Show Backlog",
                        buttonClass: "btn btn-primary",
                        iconClass: "fal fa-fw item-status-badge-backlog text-white",
                        onClick: () => this.showBacklogClick.emit(true),
                    };
                }
            }
        }

        return {
            status,
            badge: {
                badgeClass: hasExcessiveItems
                    ? `text-bg-danger text-light`
                    : `text-bg-secondary`,
                content: itemCount.toString(),
            },
            tooltip,
        } as IStatusHeaderItem;
    }

    private hasExcessiveItemCount(status: ItemStatus) {
        return this.itemCountForStatus[status] >= ExcessiveItemCount && status !== ItemStatus.Backlog;
    }
}

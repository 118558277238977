import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AuthorisationService } from "../authorisation/authorisation.service";
import { CommonIntegratedArchitectureFrameworkAuthService } from "./common-integrated-architecture-framework-auth.service";
import { LinkRoleComponent } from "./link-role/link-role.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AdaptTooltipModule,
    ],
    exports: [
        LinkRoleComponent,
    ],
    declarations: [
        LinkRoleComponent,
    ],
})
export class AdaptArchitectureModule {
    public constructor(authorisationService: AuthorisationService) {
        CommonIntegratedArchitectureFrameworkAuthService.registerAccessVerifiers(authorisationService);
    }
}
/* eslint-disable max-classes-per-file */
import { BaseEntity } from "@common/ADAPT.Common.Model/base-entity";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Meeting } from "./meeting";
import { MeetingAgendaItem } from "./meeting-agenda-item";

export enum MeetingNoteType {
    Decision = "Decision",
    Minute = "Minute",
}

export class MeetingNoteTypeMetadata {
    public static readonly IconClass: Record<MeetingNoteType, string> = {
        [MeetingNoteType.Decision]: "fal fa-fw fa-gavel",
        [MeetingNoteType.Minute]: "fal fa-fw fa-file",
    };

    public static readonly ArticleId: Record<MeetingNoteType, ImplementationKitArticle> = {
        [MeetingNoteType.Decision]: ImplementationKitArticle.MeetingDecisionNote,
        [MeetingNoteType.Minute]: ImplementationKitArticle.MeetingMinuteNote,
    };
}

export class MeetingNote extends BaseEntity<MeetingNote> {
    public meetingNoteId!: number;
    public meetingId!: number;
    public meetingAgendaItemId!: number;
    public type!: MeetingNoteType;
    public content!: string;

    public meeting!: Meeting;
    public meetingAgendaItem!: MeetingAgendaItem;
}

export const MeetingNoteBreezeModel = new BreezeModelBuilder("MeetingNote", MeetingNote)
    .hasSource()
    .alwaysFetchingNavigationProperty("meeting")
    .isOrganisationEntity()
    .build();

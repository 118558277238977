import { InjectionToken } from "@angular/core";
import { DocumentDescriptor } from "../document-descriptor";

export const STORAGE_PROVIDERS = new InjectionToken<IStorageProvider[]>("IStorageProvider");

export enum StorageProviderGroup {
    CLOUD = "External",
    LOCAL = "My Computer",
}

export interface IStorageProvider {
    // this is used by dxSelectBox to disable selection
    disabled: boolean;

    // implement this if there is any initialisation required to the element when select-document.component is initialised
    setupComponent(params: ISetupComponentParameters): Promise<void>;

    // this will be called when the component is destroyed, use this to clean up the corresponding UX instances from init above
    cleanupComponent(componentInstanceId: number): void;

    // identification name of the provider
    getName(): string;

    // display name (shown in the selection dropdown list)
    // This is added besides getName() as we have been changing the display name in the dropdown box several times;
    // having this will not invalidate the previous link we have created (e.g. getName() is added to query param to identify storage type
    // and changing name will make the previously created storage type unknown)
    getDisplayName(): string;

    // grouping of the provider
    getGroupName(): StorageProviderGroup;

    // e.g. fal fa-dropbox
    getIconClass(): string;

    // text shown after the selection is made - before openChooser() promise is fulfilled
    getSelectionInProgressText(): string;

    // returns open dialog promise which gets resolved to a URL or rejected selection is cancelled
    openChooser(componentInstanceId: number, data?: DocumentDescriptor): Promise<DocumentDescriptor | null>;

    // Construct DocumentDescriptor object from url - only if the url is recognized by the provider.
    // Returning null if url unknown to the provider
    getDocument(url: string): DocumentDescriptor | null;

    // implement this if we are interested in organisation changed event, e.g. clearing oauth token when changing user
    onOrganisationChanged(organisationId: number | undefined): void;
}

export interface ISetupComponentParameters {
    componentInstanceId: number;
    isReadOnly: boolean;
    setEditorDomElement(element: HTMLElement): void;
    disableSelection(): void;
}

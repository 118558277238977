import { Injectable } from "@angular/core";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";

@Injectable({
    providedIn: "root",
})
export class DirectoryAuthService {
    public static readonly ManagePositionAndRoles = "managePositionAndRoles";
    public static readonly ManageRoles = "manageRoles";
    public static readonly ReadPublicProfiles = "readAllPublicProfileItems";
    public static readonly EditProfileForPerson = "editProfileForPerson";

    public static readonly EditAllPositions = "editAllPositions";
    public static readonly ReadMyProfile = "readMyProfile";
    public static readonly ReadAtLeastOneProfile = "readAtLeastOneProfile";
    public static readonly EditMyProfile = "editMyProfile";
    public static readonly EditMyPosition = "editMyPosition";
    public static readonly ReadProfileForPerson = "readProfileForPerson";
    public static readonly ReadAllProfileItems = "readAllProfileItems";
    public static readonly AllocateRoles = "allocateRoles";
    public static readonly EditAllPersonalProfiles = "editAllPersonalProfiles";

    public constructor(private authorisationService: AuthorisationService) {
    }

    public static registerAccess(authorisationService: AuthorisationService) {
        authorisationService.registerAccessVerifier(
            DirectoryAuthService.ManagePositionAndRoles,
            {
                invokeToGetEntityAccessVerifier: () => getVerifyAccessToEditPositionForPerson(),
            },
        );
        authorisationService.registerAccessVerifier(
            DirectoryAuthService.ManageRoles,
            {
                invokeToGetAccessVerifier: () => getVerifyAccessToEditRoles(),
            },
        );
        authorisationService.registerAccessVerifier(
            DirectoryAuthService.EditProfileForPerson,
            {
                invokeToGetEntityAccessVerifier: () => getVerifyAccessToEditProfileForPerson(),
            },
        );
        authorisationService.registerAccessVerifier(
            DirectoryAuthService.EditAllPositions,
            {
                requirePermissions: [
                    FeaturePermissionName.PeoplePositionEdit,
                    FeaturePermissionName.OrganisationAccessManagementConfigure,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            DirectoryAuthService.ReadMyProfile,
            {
                checkAuthServiceImplementation: (injector) => {
                    const authService = injector.get(DirectoryAuthService);
                    return authService.personCanReadTheirProfile;
                },
            },
        );
        authorisationService.registerAccessVerifier(
            DirectoryAuthService.ReadAtLeastOneProfile,
            {
                checkAuthServiceImplementation: (injector) => {
                    const authService = injector.get(DirectoryAuthService);
                    return authService.personCanReadAtLeastOneProfile;
                },
            },
        );
        authorisationService.registerAccessVerifier(
            DirectoryAuthService.EditMyProfile,
            {
                requirePermissions: [
                    FeaturePermissionName.PeopleProfilePersonalEdit,
                    FeaturePermissionName.PeopleProfileEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            DirectoryAuthService.EditMyPosition,
            {
                requirePermissions: [
                    FeaturePermissionName.PeoplePositionPersonalEdit,
                    FeaturePermissionName.PeoplePositionEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            DirectoryAuthService.ReadPublicProfiles,
            {
                requirePermissions: [
                    FeaturePermissionName.PeopleProfilePublicRead,
                    // The followings 2 permissions are Edit/View All Public and Private Profile data
                    // which should cover ReadPublicProfiles
                    FeaturePermissionName.PeopleProfileEdit,
                    FeaturePermissionName.PeopleProfileRead,
                ],
            },
        );

        authorisationService.registerAccessVerifier(
            DirectoryAuthService.ReadProfileForPerson,
            {
                invokeToGetEntityAccessVerifier: () => getVerifyAccessToReadPublicProfileForPerson(),
            },
        );
        authorisationService.registerAccessVerifier(
            DirectoryAuthService.ReadAllProfileItems,
            {
                requirePermissions: FeaturePermissionName.PeopleProfileRead,
            },
        );
        authorisationService.registerAccessVerifier(
            DirectoryAuthService.AllocateRoles,
            {
                requirePermissions: FeaturePermissionName.PeoplePositionEdit,
            },
        );
        authorisationService.registerAccessVerifier(
            DirectoryAuthService.EditAllPersonalProfiles,
            {
                requirePermissions: FeaturePermissionName.PeopleProfileEdit,
            },
        );

        function getVerifyAccessToReadPublicProfileForPerson() {
            return async (currentPerson: Person, entity: Person) => {
                if (entity.personId === currentPerson.personId) {
                    return authorisationService.promiseToVerifyAccess(DirectoryAuthService.ReadMyProfile);
                }

                return authorisationService.promiseToVerifyAccess(DirectoryAuthService.ReadPublicProfiles);
            };
        }

        function getVerifyAccessToEditProfileForPerson() {
            return async (currentPerson: Person, entity: Person) => {
                if (entity.personId === currentPerson.personId) {
                    return authorisationService.promiseToVerifyAccess(DirectoryAuthService.EditMyProfile);
                }

                return authorisationService.promiseToVerifyAccess(DirectoryAuthService.EditAllPersonalProfiles);
            };
        }

        function getVerifyAccessToEditPositionForPerson() {
            return async (currentPerson: Person, entity: any) => {
                if (entity.personId === currentPerson.personId) {
                    return authorisationService.promiseToVerifyAccess(DirectoryAuthService.EditMyPosition);
                }

                return authorisationService.promiseToVerifyAccess(DirectoryAuthService.EditAllPositions);
            };
        }

        function getVerifyAccessToEditRoles() {
            return async (_currentPerson: Person) => {
                return authorisationService.promiseToVerifyAccess(DirectoryAuthService.EditAllPositions);
            };
        }
    }

    public currentPersonCanReadPersonsProfile(profilePerson: Person) {
        if (!this.authorisationService.currentPerson) {
            return false;
        }

        return this.personCanReadProfileForPerson(this.authorisationService.currentPerson, profilePerson);
    }

    public personCanReadProfileForPerson(personToCheckPermission: Person, profilePerson: Person) {
        if (personToCheckPermission.personId === profilePerson.personId) {
            return this.personCanReadTheirProfile(personToCheckPermission);
        }

        return this.authorisationService.personHasAtLeastOnePermission(personToCheckPermission, [
            FeaturePermissionName.PeopleProfileRead,
            FeaturePermissionName.PeopleProfilePublicRead,
            FeaturePermissionName.PeopleProfileEdit,
        ]);
    }

    public currentPersonCanReadTheirProfile() {
        return !!this.authorisationService.currentPerson
            && this.personCanReadTheirProfile(this.authorisationService.currentPerson)
            && this.authorisationService.currentPerson.getActiveConnections().length > 0; // can't read your own profile if you have no connection to the org
    }

    @Autobind
    public personCanReadTheirProfile(person: Person) {
        return this.authorisationService.personHasAtLeastOnePermission(person, [
            FeaturePermissionName.PeopleProfilePersonalUseAndRead,
            FeaturePermissionName.PeopleProfilePersonalEdit,
            FeaturePermissionName.PeopleProfileRead,
            FeaturePermissionName.PeopleProfileEdit,
        ]);
    }

    public currentPersonCanReadAtLeastOneProfile() {
        if (!this.authorisationService.currentPerson) {
            return false;
        }

        return this.personCanReadAtLeastOneProfile(this.authorisationService.currentPerson);
    }

    @Autobind
    public personCanReadAtLeastOneProfile(person: Person) {
        return this.authorisationService.personHasAtLeastOnePermission(person, [
            FeaturePermissionName.PeopleProfilePersonalUseAndRead,
            FeaturePermissionName.PeopleProfilePersonalEdit,
            FeaturePermissionName.PeopleProfileRead,
            FeaturePermissionName.PeopleProfilePublicRead,
            FeaturePermissionName.PeopleProfileEdit,
        ]);
    }
}

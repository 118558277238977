<ng-container *ngIf="workflow && !errorMessage; else workflowErrorTemplate">
    <!-- header -->
    <div class="mx-2">
        <adapt-display-workflow-outcome-header [workflow]="workflow"
                                               [showStartButton]="true"
                                               [skipOutcomeStep]="true"
                                               [startWorkflow$]="startWorkflow$"
                                               (isCompleted)="onWorkflowCompletionStatus($event)"></adapt-display-workflow-outcome-header>
    </div>

    <!-- completed pathway -->
    <div *ngIf="workflow.wrapUpSlug && isCompleted"
         class="mx-3 mt-3">
        <adapt-workflow-journey-wrap-up-card [workflow]="workflow"></adapt-workflow-journey-wrap-up-card>
    </div>

    <!-- article -->
    <div *ngIf="workflow.articleSlug"
         class="outcomes-card px-3 py-3">
        <dx-scroll-view>
            <adapt-implementation-kit-content [articleId]="workflow.articleSlug">
            </adapt-implementation-kit-content>
        </dx-scroll-view>
    </div>
</ng-container>

<ng-template #workflowErrorTemplate>
    <div class="alert alert-danger"
         role="alert">
        {{errorMessage ?? "Unknown pathway"}}
    </div>
</ng-template>

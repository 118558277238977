import { CommonModule } from "@angular/common";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { NavigationUtilitiesService } from "@common/route/navigation-utilities.service";
import { AuthorisationService } from "./authorisation.service";
import { AdaptIfAuthorisedDirective } from "./if-authorised.directive";
import { initialiseNavigationAccessVerificationCallback } from "./navigation-access-verification-initialiser";

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        AdaptIfAuthorisedDirective,
    ],
    declarations: [
        AdaptIfAuthorisedDirective,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            multi: true,
            useFactory: initialiseNavigationAccessVerificationCallback,
            deps: [AuthorisationService, NavigationUtilitiesService],
        },
    ],
})
export class AdaptAuthorisationModule { }

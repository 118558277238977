import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { fromEvent } from "rxjs";
import { filter } from "rxjs/operators";
import { Logger } from "../logger/logger";
import { ILogger } from "../logger/logger.interface";
import { StorageLimitReachedError } from "./storage-limit-reached-error";
import { StorageNotAvailableError } from "./storage-not-available-error";

/**
 * Generic class for interacting with browser storage - either localStorage or sessionStorage
 */
export class AdaptStorage {
    private log: ILogger;

    private _isAvailable?: boolean;
    private capturedError?: Error;

    public constructor(
        private prefix: string,
        private _storage: Storage,
    ) {
        this.log = Logger.getLogger(prefix);
    }

    public dataModifiedInAnotherTab(key: string) {
        return fromEvent<StorageEvent>(window, "storage").pipe(
            filter((e) => e.storageArea === this._storage),
            filter((e) => e.key === this.wrapKey(key)),
        );
    }

    public containsKey(key: string): boolean {
        return !!this.get(key);
    }

    public get<T>(key: string): T | undefined {
        const rawData = this.storage.getItem(this.wrapKey(key));
        if (!rawData) {
            return undefined;
        }

        try {
            const parsedData = JSON.parse(rawData);
            return parsedData;
        } catch (e) {
            this.delete(key);
            return undefined;
        }
    }

    /** Attempts to set the given data to Storage. If there are any issues
     * then the errors are silently suppressed, so only use it for non-critical data.
     */
    public set(key: string, value: any) {
        try {
            this.setStrict(key, value);
        } catch (e) {
            if (!(e instanceof StorageLimitReachedError)) {
                throw e;
            }

            this.log.info("Storage limit has been reached - suppressing");
        }
    }

    /** Attempts to set the given data to Storage, throwing an exception if there are
     * any errors. Use this method for setting data critical to operation.
     */
    public setStrict(key: string, value: any) {
        const serialisedData = this.toJson(value);

        try {
            this.storage.setItem(this.wrapKey(key), serialisedData);
        } catch (e: any) {
            throw new StorageLimitReachedError(key, e);
        }
    }

    public delete(key: string) {
        this.storage.removeItem(this.wrapKey(key));
    }

    /** Clears all keys set by this service */
    public clearAdaptKeys() {
        this.clearAdaptKeysExcept([]);
    }

    /** Clears all keys set by this service, excluding the keys specified */
    public clearAdaptKeysExcept(keysToExclude: string[]) {
        const keysToClear = this.adaptKeys.filter((key) => keysToExclude.every((k) => key !== k));
        keysToClear.forEach((key) => this.delete(key));
    }

    /** Resets storage to a completely clean slate as if this is a new private browsing session */
    public clearAll() {
        this.storage.clear();
    }

    /**
     * Returns the keys which have been set in Storage by this service. Will not include keys set directly
     * in Storage, and any wrapping of the keys as performed by this class will be removed
     */
    public get adaptKeys() {
        return this.allKeys.filter((key) => key.startsWith(this.prefix))
            .map((key) => this.unwrapKey(key));
    }

    /** Returns all storage entries that has been set by this service */
    public get adaptEntries() {
        const entries: { [key: string]: any } = {};
        for (const key of this.adaptKeys) {
            entries[key] = this.get(key);
        }
        return entries;
    }

    /** Gets all storage entries not set by this service with their length in bytes */
    public get otherEntrySizes() {
        const entries: { [key: string]: number } = {};

        this.allKeys.filter((key) => !key.startsWith(this.prefix))
            .forEach((key) => {
                const data = this.storage.getItem(key)!;
                entries[key] = this.lengthOfStringInBytes(data);
            });

        return entries;
    }

    public get totalSize() {
        return this.allKeys.reduce((currentCount, nextKey) => {
            const data = this.storage.getItem(nextKey)!;
            return currentCount + this.lengthOfStringInBytes(data);
        }, 0);
    }

    public get isAvailable() {
        if (typeof this._isAvailable !== "boolean") {
            if (this._storage) {
                try {
                    const testKeyValue = "__" + Math.random();
                    this._storage.setItem(testKeyValue, testKeyValue);
                    this._storage.removeItem(testKeyValue);
                    this._isAvailable = true;
                } catch (e: any) {
                    this._isAvailable = false;
                    this.capturedError = e;
                }
            } else {
                this._isAvailable = false;
            }
        }

        return this._isAvailable;
    }

    private get allKeys() {
        const keys: string[] = [];
        for (let i = 0; i < this.storage.length; i++) {
            const key = this.storage.key(i);

            if (key) {
                keys.push(key);
            }
        }
        return keys;
    }

    private wrapKey(key: string) {
        return `${this.prefix}-${key}`;
    }

    private unwrapKey(wrappedKey: string) {
        // +1 to remove the dash inserted above
        return wrappedKey.substring(this.prefix.length + 1);
    }

    private toJson(value: any): string {
        try {
            return JSON.stringify(value);
        } catch (e: any) {
            throw AdaptError.fromError(e);
        }
    }

    private get storage() {
        if (!this.isAvailable) {
            throw new StorageNotAvailableError(this.capturedError);
        }

        // If not defined will be caught above
        return this._storage as Storage;
    }

    private lengthOfStringInBytes(stringToTest: string) {
        return new Blob([stringToTest]).size;
    }
}

import { Component, Input } from "@angular/core";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";

@Component({
    selector: "adapt-display-objective-progress",
    templateUrl: "./display-objective-progress.component.html",
    styleUrls: ["./display-objective-progress.component.scss"],
})
export class DisplayObjectiveProgressComponent {
    @Input() public scaleHeight = 1.5;
    @Input() public objective!: Objective;

    public get hasBeenMeasured() {
        return this.objective.keyResults.some((kr) => kr.values.length > 0);
    }

    public get currentProgress() {
        return this.objective.currentProgress;
    }
}

import { Component, ElementRef, Input, TemplateRef, ViewChild } from "@angular/core";
import { Zone } from "@common/ADAPT.Common.Model/methodology/zone";
import { Theme } from "@common/ADAPT.Common.Model/organisation/theme";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { Breakpoint } from "@common/ux/responsive/breakpoint";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { StrategyService } from "@org-common/lib/strategy/strategy.service";
import { StrategyAuthService } from "@org-common/lib/strategy/strategy-auth.service";
import { StrategicViewOption } from "@org-common/lib/strategy/strategy-view-constants";
import { BehaviorSubject, switchMap } from "rxjs";

export interface IStrategyBoardViewOption {
    option: StrategicViewOption;
    iconClass: string;
    text: string;
    tour?: string;
}

@Component({
    selector: "adapt-strategy-board",
    templateUrl: "./strategy-board.component.html",
})
export class StrategyBoardComponent extends BaseComponent {
    public readonly FullLayoutBreakpoint = Breakpoint.XXL;

    @Input() public isEditing = false;

    @Input() public showToolbarMenu = false;
    @Input() public views = [StrategicViewOption.SWTInputs, StrategicViewOption.CAInputs, StrategicViewOption.Bullseye];

    public hasEditAccess = false;
    public hasTheme = false;

    public viewToggleButtonsTemplate?: TemplateRef<HTMLElement> = undefined;
    private viewToggleButtonsTemplateUpdater = this.createThrottledUpdater<TemplateRef<HTMLElement>>((value) => this.viewToggleButtonsTemplate = value);

    @ViewChild("viewToggleButtons")
    public set viewToggleButtons(value: TemplateRef<HTMLElement>) {
        this.viewToggleButtonsTemplateUpdater.next(value);
    }

    private updater$ = new BehaviorSubject<void>(undefined);

    public constructor(
        elementRef: ElementRef,
        rxjsBreezeService: RxjsBreezeService,
        private authorisationService: AuthorisationService,
        private strategyService: StrategyService,
    ) {
        super(elementRef);

        this.updater$.pipe(
            switchMap(() => strategyService.getAllThemes()),
            this.takeUntilDestroyed(),
        ).subscribe((themes) => {
            this.hasTheme = themes.length > 0;
            this.isInitialised = true;
        });

        this.updater$.pipe(
            switchMap(() => this.authorisationService.promiseToGetHasAccess(StrategyAuthService.EditStrategyBoard)),
            this.takeUntilDestroyed(),
        ).subscribe((hasEditAccess) => {
            this.hasEditAccess = hasEditAccess;
        });

        rxjsBreezeService.entityTypeChanged(Theme).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.updater$.next());
    }

    @Autobind
    public addThemeInZone(zone: Zone) {
        return this.strategyService.editThemeAfterCreate(zone);
    }
}

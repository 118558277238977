import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { IMeetingLocation, Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { CalendarIntegrationProvider } from "@common/ADAPT.Common.Model/organisation/organisation-detail";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ScheduleInformation } from "@microsoft/microsoft-graph-types-beta";
import DataSource from "devextreme/data/data_source";
import { CustomItemCreatingEvent } from "devextreme/ui/select_box";
import { BehaviorSubject, lastValueFrom, switchMap } from "rxjs";
import { distinctUntilChanged, map, take } from "rxjs/operators";
import { MicrosoftCalendarService } from "../../calendar/microsoft-calendar.service";
import { OAuthService } from "../../oauth/oauth.service";

interface IMeetingLocationStatus extends IMeetingLocation {
    available?: boolean;
}

@Component({
    selector: "adapt-select-meeting-location",
    templateUrl: "./select-meeting-location.component.html",
})
export class SelectMeetingLocationComponent extends BaseComponent implements OnInit {
    @Input() public potentialLocations: IMeetingLocation[] = [];
    @Input() public disabled = false;
    @Input() public required = true;

    @Input() public location?: IMeetingLocation;
    @Output() public locationChange = new EventEmitter<IMeetingLocation | undefined>();

    public dataSource?: DataSource;
    public loading = true;

    private meetingRoomsFetched = false;
    private meetingRooms: IMeetingLocationStatus[] = [];
    private meetingRoomsSubject = new BehaviorSubject<IMeetingLocation[]>([]);

    public constructor(
        public oauthService: OAuthService,
        public microsoftCalendarService: MicrosoftCalendarService,
    ) {
        super();
    }

    public ngOnInit() {
        this.oauthService.isAuthedWithProvider$(CalendarIntegrationProvider.Microsoft).pipe(
            distinctUntilChanged(),
            switchMap((authenticated) => this.updateData(authenticated)),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    public getMeetingLocations(forceRemote = false) {
        if (this.meetingRoomsFetched && !forceRemote) {
            return this.meetingRoomsSubject.asObservable().pipe(take(1));
        }

        return this.oauthService.isAuthedWithProvider(CalendarIntegrationProvider.Microsoft).pipe(
            switchMap(async (authenticated) => {
                try {
                    if (authenticated) {
                        const rooms = await this.microsoftCalendarService.getMeetingRooms();
                        return rooms ?? [];
                    }
                    return [];
                } catch (e) {
                    return [];
                }
            }),
            map((rooms) => {
                const meetingRooms = (rooms ?? [])
                    .map((room) => ({
                        name: room.displayName,
                        emailAddress: room.emailAddress,
                        id: room.id,
                    } as IMeetingLocationStatus));

                const roomAddresses = meetingRooms.map((room) => room.emailAddress);
                const roomNames = meetingRooms.map((room) => room.name);
                const uniqueNewRooms = this.potentialLocations.filter((loc) => !roomAddresses.includes(loc.emailAddress) && !roomNames.includes(loc.name));

                this.meetingRooms = meetingRooms
                    .concat(uniqueNewRooms)
                    .sort(SortUtilities.getSortByFieldFunction<IMeetingLocation>("name"));
                this.meetingRoomsFetched = true;
                this.meetingRoomsSubject.next(this.meetingRooms);
                return this.meetingRooms;
            }),
        );
    }

    public isLocationAvailable(location: IMeetingLocation) {
        const room = this.meetingRooms.find((r) => r.emailAddress === location.emailAddress);
        return room?.available;
    }

    public setLocationAvailability(scheduleInformation: ScheduleInformation[], meeting: Meeting) {
        for (const room of this.meetingRooms) {
            const roomSchedule = scheduleInformation.find((schedule) => schedule.scheduleId === room.emailAddress);
            room.available = roomSchedule
                ? this.microsoftCalendarService.isLocationAvailable(roomSchedule, meeting)
                : undefined;
        }
    }

    public onCustomLocationCreating(event: CustomItemCreatingEvent) {
        if (!event.text) {
            event.customItem = null;
            return;
        }

        const newItem: IMeetingLocation = {
            name: event.text.trim(),
        };

        const itemInDataSource = this.meetingRooms.find((item) => item.name === newItem.name);
        if (itemInDataSource) {
            event.customItem = itemInDataSource;
        } else {
            this.meetingRooms.push(newItem);
            event.customItem = newItem;
            event.component.getDataSource().reload();
        }
    }

    private async updateData(forceRemote = false) {
        this.loading = true;
        this.meetingRooms = await lastValueFrom(this.getMeetingLocations(forceRemote));
        this.loading = false;

        this.restoreExistingLocation();
        this.dataSource = new DataSource(this.meetingRooms);
    }

    private restoreExistingLocation() {
        if (this.location) {
            if (this.location.emailAddress) {
                // microsoft location, select it by default
                const foundRoom = this.meetingRooms.find((room) => room.emailAddress === this.location?.emailAddress);
                if (foundRoom) {
                    this.location = foundRoom;
                }
            } else {
                const foundLocation = this.meetingRooms.find((room) => room.name === this.location?.name);
                if (foundLocation) {
                    this.location = foundLocation;
                } else {
                    // custom location, add to meetingRoom list
                    this.meetingRooms.push({ name: this.location.name });
                }
            }
        }
    }
}

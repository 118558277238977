<div class="edit-agenda-item d-flex flex-column">
    <div class="d-flex flex-row">
        <ng-container *ngIf="(readonly || isMeetingInProgress) && !hideProgressIcon">
            <i class="me-2 align-self-center fal fa-2x fa-check-circle"
               [ngClass]="progressColor"
               [adaptTooltip]="progressColor"></i>
        </ng-container>
        <h4 class="align-self-sm-center d-flex flex-grow-1 pe-3">
            <span class="d-flex align-items-center flex-grow-1">
                <ng-container *ngIf="canExpand; else simpleTitle">
                    <button (click)="toggleExpand()"
                            class="btn btn-blank d-flex w-100 agenda-item-expand align-items-center">
                        <h4 class="text-start">{{item.name}}</h4>

                        <adapt-agenda-item-meta-data-count *ngIf="!hideNotesAndItems"
                                                           class="flex-grow-1 text-start ms-1"
                                                           [showTotalBadgeOnly]="true"
                                                           [meetingNotes]="agendaNotes"
                                                           [meetingItems]="agendaActions">
                        </adapt-agenda-item-meta-data-count>
                    </button>
                </ng-container>
                <ng-template #simpleTitle>
                    {{item.name}}
                </ng-template>
            </span>
        </h4>
        <div class="d-flex align-self-sm-center ms-sm-auto">
            <div class="align-self-center agenda-item-time-container text-start">
                <ng-container *ngIf="canExpand; else showMinuteOnly">
                    <button (click)="toggleExpand()"
                            class="btn btn-blank d-flex w-100 agenda-item-expand align-items-center">
                        <div *ngIf="item.plannedDurationInMinutes"
                             class="agenda-item-time text-start">
                            <i class="fal fa-clock me-1"></i>
                            <span>{{item.plannedDurationInMinutes}} </span>
                            <span>mins</span>
                        </div>
                        <i
                           class="ms-auto align-self-center justify-content-end fal fa-fw {{expandDetails ? 'fa-chevron-up': 'fa-chevron-down'}}"></i>
                    </button>
                </ng-container>
                <ng-template #showMinuteOnly>
                    <div *ngIf="item.plannedDurationInMinutes"
                         class="agenda-item-time text-start">
                        <i class="fal fa-clock me-1"></i>
                        <span>{{item.plannedDurationInMinutes}} </span>
                        <span>mins</span>
                    </div>
                </ng-template>
            </div>
            <ng-container *ngIf="!readonly">
                <button adaptButton="editBorderless"
                        class="ms-3 align-self-center"
                        adaptTooltip="Edit Agenda Item"
                        [adaptBlockingClick]="editItemDetails"></button>
                <button adaptButton="deleteBorderless"
                        class="ms-1 align-self-center"
                        adaptTooltip="Delete Agenda Item"
                        [adaptBlockingClick]="deleteItem"
                        [unsubscribeOnDestroy]="false"></button>
            </ng-container>
        </div>
    </div>

    <div *ngIf="canExpand && expandDetails"
         class="pt-3">
        <div class="text-wrap"
             [froalaView]="item.supplementaryData?.itemDescription"></div>

        <div *ngIf="item.componentSelector"
             class="alert alert-secondary text-wrap my-3">
            <ng-container #container></ng-container>
        </div>

        <ng-container *ngIf="!hideNotesAndItems">
            <adapt-display-meeting-note *ngFor="let note of agendaNotes"
                                        [edit]="!readonly"
                                        [note]="note"></adapt-display-meeting-note>
            <adapt-display-meeting-action *ngFor="let action of agendaActions"
                                          [meetingItem]="action"></adapt-display-meeting-action>
        </ng-container>
    </div>
</div>

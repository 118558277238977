import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Survey } from "./survey";
import { SurveyResponseGroup } from "./survey-response";

export class SurveyResult extends BaseEntity<SurveyResult> {
    public surveyResultId!: number;
    public surveyId!: number;
    public responseGroup!: SurveyResponseGroup;
    public averagePercentageScore?: number;
    public percentageParticipation?: number;

    public survey!: Survey;
}

export const SurveyResultModel = new BreezeModelBuilder("SurveyResult", SurveyResult)
    .withIdField()
    .build();

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { countryList } from "./country-list";

@Component({
    selector: "adapt-select-country",
    templateUrl: "./select-country.component.html",
})
export class SelectCountryComponent {
    @Input() public country?: string;
    @Output() public countryChange = new EventEmitter<string>();
    @Input() public disabled?: boolean;

    public countryList = countryList;

    public itemChange(selection: string) {
        this.countryChange.emit(selection);
    }
}

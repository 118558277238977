declare const jQuery: JQueryStatic;

export class StringUtilities {
    // use virtual owner to prevent synchronous image load when html is wrapped into jQuery
    // https://stackoverflow.com/questions/15113910/jquery-parse-html-without-loading-images/
    private static virtualOwner = document.implementation.createHTMLDocument("virtual");

    /**
     * Convert html to plain text
     *
     * @param html - Html to convert
     * @return Plain text conversion
     */
    public static htmlToPlaintext(html: string): string {
        if (!html || !StringUtilities.isString(html)) {
            return "";
        }

        // Gracefully handle plain text
        const wrappedHtml = `<span>${html}</span>`
            .replace(/&nbsp;|&#160;/g, " "); // we have some strings with this, so replace it manually :(
        const htmlElement = jQuery(wrappedHtml, this.virtualOwner);
        for (const item of htmlElement.find("*")) {
            jQuery(item).prepend(" ").append(" ");
        }

        const elementText = htmlElement.text();

        return elementText
            .replace(/^\s+/, "")
            .replace(/\s+$/, "")
            .replace(/\s\s+/g, " ");
    }

    /**
     * Trim empty content from html
     *
     * @param html - Html to check
     * @return Trimmed text
     */
    public static trimHtml(html?: string) {
        if (!html) {
            return "";
        }

        let trimmedHtml = html.trim();

        // remove empty new lines from the beginning and end
        // (froala inserts a <br> tag inside the <p> from start / end)
        trimmedHtml = trimmedHtml.replace(/^(<p><br><\/p>)+/, "")
            .replace(/(<p><br><\/p>)+$/, "");

        // Remove empty p/div tags if they are the only content
        trimmedHtml = trimmedHtml.replace(/^(\s*<p>\s*<\/p>\s*)+$/i, "")
            .replace(/^(\s*<div>\s*<\/div>\s*)+$/i, "");

        // string is empty or just whitespace
        if (!/\S/.test(trimmedHtml)) {
            trimmedHtml = "";
        }

        return trimmedHtml;
    }

    /**
     * Strip spaces from a string
     *
     * @param val - String to be stripped of spaces
     * @return The stripped string
     */
    public static stripSpace(val?: string): string {
        if (!val) {
            return "";
        }

        return val.replace(/\s+/g, "");
    }

    /**
     * Converts a string to a number - NaN results can be replaced with an optional default value
     * @param val
     * @param defaultValue
     */
    public static stringToInt(val: string, defaultValue: number = 0): number {
        const result = parseInt(val, 10);

        if (isNaN(result)) {
            return defaultValue;
        }

        return result;
    }

    /** Returns true if the given string contains only special (non alphabetical) characters */
    public static isSpecial(str: string) {
        return str.toLocaleLowerCase() === str.toLocaleUpperCase();
    }

    public static equalsIgnoreCase(str1: string, str2: string) {
        return str1.toLocaleLowerCase() === str2.toLocaleLowerCase();
    }

    /**
     * Converts a positive integer to a string with the specified number of digits by padding with zeros.
     * @param {number} integer - The integer to be padded.
     * @param {number} digits - The desired number of digits.
     * @returns {string} The zero padded string.
     * @throws {Error} Only accepts positive integers
     */
    public static zeroPad(integer: number, digits: number) {
        if (!Number.isInteger(integer)) {
            throw new Error("zeroPad only accepts integers");
        }

        if (integer < 0) {
            throw new Error("zeroPad only accepts positive integers");
        }

        let output = integer.toString();

        while (output.length < digits) {
            output = "0" + output;
        }

        return output;
    }

    /**
     * Case insensitive search for a substring
     *
     * @param {string} text - String to search in
     * @param {string} searchText - Substring to search for
     * @return {bool} Boolean determination
     */
    public static textContains(text?: string, searchText?: string) {
        return text && searchText && text.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;
    }

    /**
     * Build a url with parameters.
     * The parameters can be simple strings, numbers or booleans
     * Additionally, arrays and objects are supported for the above types.
     *
     * @param {string} url - The base url
     * @param {object} params - The parameters to append to the url
     * @return {string} The completed url
     */
    public static buildUrl(url: string, params: { [key: string]: any }) {
        if (!params) {
            return url;
        }

        const parts: string[] = [];
        iterateObject(params, "");
        if (!parts.length) {
            return url;
        }

        const appendage = url.indexOf("?") === -1
            ? "?"
            : "&";
        return url + appendage + parts.join("&");

        function iterateObject(obj: { [key: string]: any }, keyPrefix: string) {
            for (const key in obj) {
                if (!obj.hasOwnProperty(key)) {
                    continue;
                }

                const value = obj[key];
                if (value === undefined || value === null) {
                    continue;
                }

                addPart(key, value, keyPrefix);
            }
        }

        function addPart(key: string, value: any, keyPrefix: string) {
            if (typeof value === "string" || typeof value === "number" || typeof value === "boolean") {
                parts.push(keyPrefix + encodeURIComponent(key) + "=" + encodeURIComponent(value));
            } else if (Array.isArray(value)) {
                value.forEach((arrayValue, index) => {
                    const arrayPrefix = keyPrefix + encodeURIComponent(key) + "[" + index + "]";
                    addPart("", arrayValue, arrayPrefix);
                });
            } else if (typeof value === "object") {
                iterateObject(value, keyPrefix + encodeURIComponent(key) + ".");
            } else {
                // unknown types end up here - we dont support bigints, symbols, functions for example
                throw new Error("Unsupported parameter value");
            }
        }
    }

    /**
     * Determines whether a value is a string
     *
     * @param {any} val- Reference to possible string
     * @returns {bool} Boolean determination
     */
    public static isString(val: any): val is string {
        return typeof val === "string";
    }

    /**
     * Truncates a string to the given target length, without breaking words
     *
     * @param str string to shorten
     * @param targetLength desired length of the string (this is a guideline not a hard limit)
     * @param separator what to break the string upon (e.g. spaces)
     */
    public static shorten(str: string, targetLength: number, separator = " ") {
        if (str.length <= targetLength) return str;
        let truncated = str.substr(0, str.lastIndexOf(separator, targetLength));
        if (truncated.length < str.length) {
            truncated += "…";
        }
        return truncated;
    }

    /**
     * Converts a string from camel/pascal case (e.g. "PascalCase") to sentence case (e.g. "Pascal Case")
     * - sentence case: besides first word, all others should be lowercase!
     *
     * Sourced from https://stackoverflow.com/a/39718708
     * @param str string to convert from camel/pascal case to sentence case
     */
    public static camelToSentenceCase(str: string) {
        return str.replace(/([A-Z])/g, (match) => ` ${match.toLowerCase()}`)
            .trim()
            .replace(/^./, (match) => match.toUpperCase())
            .trim();
    }

    public static upperCaseFirstCharacter(str: string) {
        if (str?.length) {
            return str.charAt(0).toUpperCase() + str.substring(1);
        }

        return str;
    }

    /**
     * Generate a snippet from the given text
     * @param text string to search for matching text within
     * @param keyword text to search for
     * @param words number of words to show each side of keyword in the snippet
     */
    public static generateSnippet(text: string, keyword: string, words = 5) {
        // strip html from text
        text = StringUtilities.htmlToPlaintext(text);

        const split = text.toLowerCase().split(keyword.toLowerCase());
        if (split.length <= 1) {
            // split of length one means the keyword did not match.
            // this can happen should the search term match, but is then stripped out by HtmlToPlainText.
            // in this case we'll just trim the string down from the beginning...
            return text.split(" ", words).join(" ") + " ...";
        }

        // take the first n words before the keyword
        const before = split[0].split(" ").reverse().slice(0, words + 1).reverse().join(" ");
        const beforeIdx = text.toLowerCase().indexOf(before);

        // take the first n words after the keyword
        const after = split[1].split(" ", words).join(" ");
        const afterIdx = text.toLowerCase().indexOf(after);

        // include keyword in the before string
        const snippet = text.substring(beforeIdx, beforeIdx + before.length + keyword.length) + text.substring(afterIdx, afterIdx + after.length);

        if (snippet === text) {
            return snippet;
        }

        // snippet starts at beginning of text
        if (beforeIdx === 0) {
            return `${snippet} ...`;
        }

        // snippet is at the end of the text
        if (afterIdx === 0 || afterIdx + after.length === text.length) {
            return `... ${snippet}`;
        }

        // snippet is surrounded by text
        return `... ${snippet} ...`;
    }

    /**
     * Converts the given string into a URL-friendly slug.
     * @param text text to slugify
     */
    public static slugify(text: string) {
        text = text.trim()
            .toLowerCase()
            .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
            .replace(/\s+/g, "-") // collapse whitespace and replace by -
            .replace(/-+/g, "-"); // collapse dashes;

        return text;
    }

    /**
     * Converts the given list into sentence form.
     * @example
     * toListSentence(["apple", "banana"])
     * // --> apple and banana
     * @example
     * toListSentence(["apple", "banana", "carrot"])
     * // --> apple, banana and carrot
     * @example
     * toListSentence(["apple", "banana", "carrot"], "or")
     * // --> apple, banana or carrot
     * @param arr list of strings to turn into a sentence
     * @param delimiter word to join items with (e.g. and, or)
     */
    public static toListSentence(arr: string[], delimiter = "and") {
        return arr.length < 3
            ? arr.join(` ${delimiter} `)
            : `${arr.slice(0, -1).join(", ")} ${delimiter} ${arr[arr.length - 1]}`;
    }

    public static isEmpty(text?: string) {
        if (!text) {
            return true;
        }

        return text.length === 0;
    }

    public static substringBetweenTags(content: string, startTag: string, endTag: string) {
        const startIndex = content.indexOf(startTag);
        const endIndex = content.indexOf(endTag);
        return (startIndex >= 0 && endIndex > startIndex)
            ? content.substring(startIndex + startTag.length, endIndex)
            : "";
    }
}

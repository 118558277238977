import later from "@breejs/later";
import { ActiveEntityUtilities } from "@common/lib/data/active-entity-utilities";
import moment from "moment";
import { EventSeries, EventSeriesType } from "./event-series";
import { EventSeriesDayOfWeekMetadata } from "./event-series-day-of-week";
import { EventSeriesWeekIndexMetadata } from "./event-series-week-index";
import { IMeetingCustomData, IMeetingLocation, Meeting } from "./meeting";

export class EventSeriesExtensions {
    public constructor(private eventSeries: EventSeries) {
    }

    public get isCompleted() {
        if (this.eventSeries.meetings.length > 0) {
            const allCompleted = this.eventSeries.meetings.every((m) => m.extensions.isEnded);

            if (!allCompleted) {
                // just check if the last meeting in the sequence is finished...
                const sortedMeetings = this.getSortedMeetings();
                const lastMeeting = sortedMeetings[sortedMeetings.length - 1];
                return lastMeeting.extensions.isEnded;
            }

            return allCompleted;
        }

        // no meetings found for the eventSeries, and it's a once-off event,
        // so the meeting was probably deleted. just mark it as complete at this point.
        if (this.eventSeries.eventSeriesType === EventSeriesType.Once) {
            return true;
        }

        // no meetings found (could have been deleted or something like that)
        // so just rely on if the series has ended
        return ActiveEntityUtilities.isHistoric(this.eventSeries);
    }

    public get hasRunAtLeastOneMeeting() {
        return this.eventSeries.lastSequenceId !== undefined && this.eventSeries.lastSequenceId !== null;
    }

    public getMeetingLocation() {
        if (this.eventSeries.location) {
            return {
                name: this.eventSeries.location,
                emailAddress: this.eventSeries.calendarIntegrationLocationId,
            } as IMeetingLocation;
        }

        return undefined;
    }

    public getNextMeeting(meeting: Meeting) {
        const sortedMeetings = this.getSortedMeetings();
        const meetingIndex = sortedMeetings.indexOf(meeting);
        return meetingIndex >= 0
            ? sortedMeetings[sortedMeetings.indexOf(meeting) + 1]
            : undefined;
    }

    public getLatestMeeting() {
        return this.getSortedMeetings().find((meeting) => {
            const customData = meeting.extensions.getCustomData<IMeetingCustomData>();
            return this.eventSeries.lastSequenceId === undefined || customData.seriesSequenceId === this.eventSeries.lastSequenceId;
        });
    }

    public getSortedMeetings() {
        return Array.from(this.eventSeries.meetings)
            .sort((a, b) => {
                // use the meeting datetime if no seriesSequenceId
                const customDataA = a.extensions.getCustomData<IMeetingCustomData>()?.seriesSequenceId ?? a.meetingDateTime.getTime();
                const customDataB = b.extensions.getCustomData<IMeetingCustomData>()?.seriesSequenceId ?? b.meetingDateTime.getTime();
                return customDataA! - customDataB!;
            });
    }

    public getNextTimes(dateFrom: Date, dateTo: Date) {
        const laterInstance = later.schedule(this.schedule);

        // maximum cadence length is 16 months, so generate that many events.
        const nextTimes = laterInstance.next(16, dateFrom, dateTo);

        // not an array if only one date
        const times = Array.isArray(nextTimes) ? nextTimes : [nextTimes];

        // no times found, return an empty array
        // instead of returning an empty array, later returns a 0 instead...
        if (times.length === 1 && Number(times[0]) === 0) {
            return [];
        }

        // dateTo doesn't seem to work as expected, so manually filter the times for all times less than dateTo.
        return times.filter((time) => time.getTime() <= dateTo.getTime());
    }

    private get schedule() {
        let schedule = later.parse.recur();

        if (this.eventSeries.eventSeriesType === EventSeriesType.Once) {
            schedule = schedule
                .on(this.eventSeries.startDate).fullDate();
        }

        if (this.eventSeries.eventSeriesType === EventSeriesType.RelativeYearly
            && this.eventSeries.dayOfWeek && this.eventSeries.weekIndex && this.eventSeries.month) {
            schedule = schedule
                .every(this.eventSeries.interval).year()
                .on(this.eventSeries.month).month()
                .on(EventSeriesWeekIndexMetadata.ByWeekIndex[this.eventSeries.weekIndex].value).dayOfWeekCount()
                .on(EventSeriesDayOfWeekMetadata.ByDayOfWeek[this.eventSeries.dayOfWeek].value).dayOfWeek();
        }

        if (this.eventSeries.eventSeriesType === EventSeriesType.RelativeMonthly
            && this.eventSeries.dayOfWeek && this.eventSeries.weekIndex && this.eventSeries.month) {
            schedule = schedule
                .on(EventSeriesWeekIndexMetadata.ByWeekIndex[this.eventSeries.weekIndex].value).dayOfWeekCount()
                .on(EventSeriesDayOfWeekMetadata.ByDayOfWeek[this.eventSeries.dayOfWeek].value).dayOfWeek();

            // above doesn't generate as we expect. manually take over
            // given interval=3, month=6, this results in [3, 6, 9, 12] (i.e. march, june, sept, dec)
            schedule.schedules[0].M = Array(12 / this.eventSeries.interval)
                .fill(0)
                .map((_, idx) => (((this.eventSeries.month! - 1) + (this.eventSeries.interval * idx)) % 12) + 1)
                .sort((a, b) => a - b);
        }

        const start = moment(this.eventSeries.startDate).utc();
        schedule = schedule
            .on(start.hour()).hour()
            .on(start.minute()).minute();

        return schedule;
    }
}

import { Component, ComponentRef, HostListener, Inject, Injector, ViewChild, ViewContainerRef } from "@angular/core";
import { Workflow, WorkflowDialogWidth, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { ButtonCallbackType, WorkflowStep, WorkflowStepGuidancePosition } from "@common/ADAPT.Common.Model/embed/workflow-step";
import { IWorkflowRating, PathwayRatingStatus } from "@common/ADAPT.Common.Model/organisation/workflow-rating";
import { WorkflowStatus, WorkflowStatusEnum } from "@common/ADAPT.Common.Model/organisation/workflow-status";
import { PersonFlag } from "@common/ADAPT.Common.Model/person/person-flag.enum";
import { ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CustomPersistableDialog, ICustomPersistableDialog } from "@common/lib/data/persistable-dialog.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { GuidedTour, GuidedTourService } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourRegistry } from "@common/lib/guided-tour/guided-tour-registrar";
import { Logger } from "@common/lib/logger/logger";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { ElementUtilities } from "@common/lib/utilities/element-utilities";
import { FunctionUtilities } from "@common/lib/utilities/function-utilities";
import { SortUtilities } from "@common/lib/utilities/sort-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { AdaptDialogComponent } from "@common/ux/adapt-common-dialog/adapt-dialog.component/adapt-dialog.component";
import { DialogResolveData } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { IFocusable } from "@common/ux/adapt-common-dialog/focusable";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import { IProgressStep } from "@common/ux/stepped-progress-bar/stepped-progress-bar.component";
import { PathwayReviewService } from "@common/workflow/components/pathway-review.service";
import { DxScrollViewComponent } from "devextreme-angular";
import { EMPTY, forkJoin, from, lastValueFrom, Observable, of, Subject, Subscription } from "rxjs";
import { catchError, debounceTime, filter, finalize, map, switchMap, takeUntil, tap } from "rxjs/operators";
import { PersonFlagService } from "../../person/person-flag.service";
import { DisplayWorkflowActivityBriefComponentSelector, IActivityBriefData } from "../display-workflow-activity-brief/display-workflow-activity-brief.component";
import { IWorkflowRunData } from "../workflow.interface";
import { WorkflowService } from "../workflow.service";
import { IWorkflowStepComponent, IWorkflowStepFooterTemplate, WorkflowStepComponentRegistry } from "../workflow-component-registry";
import { WorkflowConfirmDialogComponent } from "../workflow-confirm-dialog/workflow-confirm-dialog.component";
import { WrapUpText } from "../workflow-journey-page/workflow-journey-page.component";

export interface IWorkflowPersistenceData {
    id: string;
    data?: any;
}

const workflowRunDialogPersistenceConfig: ICustomPersistableDialog<IWorkflowRunData, IWorkflowPersistenceData> = {
    encode(runData, injector) {
        const persistenceId = runData.workflow.persistenceId;
        if (!persistenceId) {
            return undefined;
        }

        const workflowService = injector.get(WorkflowService);
        const persistentWorkflow = workflowService.getPersistentWorkflowById(persistenceId);
        if (!persistentWorkflow) {
            throw new Error(`Persistent workflow config not found for persistenceId "${persistenceId}"`);
        }

        return {
            id: persistentWorkflow.id,
            data: persistentWorkflow.encode(runData),
        } as IWorkflowPersistenceData;
    },
    decode(encodedData, injector) {
        if (!encodedData) {
            return undefined;
        }

        const workflowService = injector.get(WorkflowService);

        // get workflow and call its persistence decode function to get the runData
        const persistentWorkflow = workflowService.getPersistentWorkflowById(encodedData.id);
        if (!persistentWorkflow) {
            return undefined;
        }

        return persistentWorkflow.decode(encodedData.data);
    },
};

@Component({
    selector: "adapt-workflow-run-dialog",
    templateUrl: "./workflow-run-dialog.component.html",
    styleUrls: ["./workflow-run-dialog.component.scss"],
})
@CustomPersistableDialog("WorkflowRunDialog", workflowRunDialogPersistenceConfig)
export class WorkflowRunDialogComponent extends BaseDialogWithDiscardConfirmationComponent<IWorkflowRunData, undefined> {
    public readonly dialogName = "WorkflowRunDialog";
    public readonly dialogWidthMappings: { [key in WorkflowDialogWidth]: string } = {
        [WorkflowDialogWidth.FullWidth]: "90%",
        [WorkflowDialogWidth.ExtraLarge]: "1200px",
        [WorkflowDialogWidth.Large]: "900px",
        [WorkflowDialogWidth.SemiLarge]: "800px",
        [WorkflowDialogWidth.Medium]: "700px",
        [WorkflowDialogWidth.Small]: "500px",
    };
    public readonly WorkflowStepGuidancePosition = WorkflowStepGuidancePosition;

    @ViewChild("container", { read: ViewContainerRef }) public container!: ViewContainerRef;
    public currentWorkflowStep?: WorkflowStep;
    public progressSteps: IProgressStep<WorkflowStep>[] = [];
    public currentStepCompleted = false;
    public currentStepSkippable = true;
    public isBusy = false;
    public errorMessage?: string;
    public footerTemplates?: IWorkflowStepFooterTemplate[];

    public fullWidthImplementationKit = false;
    @ViewChild("implementationKitScrollView") private implementationKitScrollViewComponent?: DxScrollViewComponent;
    @ViewChild("stepComponentScrollView") private stepComponentScrollViewComponent?: DxScrollViewComponent;
    public fullWidthImplementationKitUpdater = this.createThrottledUpdater<boolean>((isFullWidth) => {
        this.fullWidthImplementationKit = isFullWidth;
    });

    @ViewChild(AdaptDialogComponent) private dialogComponent?: AdaptDialogComponent;

    public dialogWidth = this.dialogWidthMappings[WorkflowDialogWidth.Large];
    public isCompleted = false;
    public wrapUpTour?: GuidedTour;
    public wrapUpTourPersonFlag?: PersonFlag;
    public wrapUpSlug?: ImplementationKitArticle;
    public workflowRating: IWorkflowRating = {
        rating: 0,
        liked: "",
        improveOn: "",
        workflowId: this.data.workflow.workflowId,
        workflowName: this.data.workflow.name,
        workflowConnectionId: this.data.workflowConnection?.workflowConnectionId,
        status: PathwayRatingStatus.awaiting_review,
    };

    private readonly logger = Logger.getLogger("WorkflowRunDialogComponent");

    // holds any entity changed during the workflow steps emitted through workflowStepEntityChange subscription
    protected entitiesToConfirm: IBreezeEntity<any>[] = [];

    private stepUpdater = this.createThrottledUpdater((step?: WorkflowStep) => {
        const dialogWidthOverride = step && this.currentWorkflowStep?.isOutcomeStep
            ? WorkflowDialogWidth.FullWidth
            : undefined;
        this.setDialogWidth(step?.workflow ?? this.data.workflow, dialogWidthOverride);

        this.checkLoadComponentSelectorComponent();
        this.dialogComponent?.resetScroll();
    }, 100, true);
    private currentCustomComponent?: ComponentRef<IWorkflowStepComponent>;
    private stepCompletionSubscription?: Subscription;
    private stepSkippableSubscription?: Subscription;
    private stepEntityChangeSubscription?: Subscription;
    private goToStepSubscription?: Subscription;
    private updateDimensionsTriggerSubscription?: Subscription;
    private errorMessageSubscription?: Subscription;
    private finishCurrentSubscription?: Subscription;
    private footerTemplateSubscription?: Subscription;
    private postWorkflowSteps: { [stepOrdinal: number]: () => Observable<unknown> } = {};

    private lastCompletedStepIdx = -1;
    private workflowSteps: WorkflowStep[] = [];

    private fullscreen = false;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public data: IWorkflowRunData,
        private workflowService: WorkflowService,
        private workflowRatingService: PathwayReviewService,
        private dialogService: AdaptCommonDialogService,
        private guidedTourService: GuidedTourService,
        private personFlagService: PersonFlagService,
        private implementationKitService: ImplementationKitService,
        private responsiveService: ResponsiveService,
        private injector: Injector,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super(injector, DialogResolveData.NotRequired);

        this.initialise(data);

        if (this.data.workflowConnection) {
            // only persist dialog state if workflow is execute with a connection (with status tracking); otherwise, no restore!
            this.workflowService.updateWorkflowRunSearchParam(this.data.workflow.workflowId);
        }

        this.events.subscribe({
            complete: async () => {
                await this.workflowService.updateWorkflowStopSearchParam(this.data.workflow.workflowId);
            },
        });

        if (data.workflowConnection) {
            // only interested in status that are deleted or changed from another session for status in the current connection
            // - don't have to check for startedById or completedById against currentPersonId as same person may open multi sessions
            // - want to close if changed from another session.
            rxjsBreezeService.entityTypeChangedFromRemote(WorkflowStatus).pipe(
                filter((status) => status.workflowConnectionId === data.workflowConnection!.workflowConnectionId),
                debounceTime(500), // only emit once if there are multiple matches
                switchMap(() => this.dialogService.showMessageDialog(
                    "Pathway has been changed in another session",
                    `<p>The pathway has been modified in a different session. Any changes made to this pathway in the current session are
                        now outdated and cannot be saved.</p>
                     <p>You will need to discard the changes made to the pathway in this session, as it has been updated in another session
                        or by another user.</p>`,
                    "Accept & close pathway",
                )),
                switchMap(() => {
                    if (this.hasUnsavedEntity) {
                        // discard all
                        return this.commonDataService.rejectChanges(this.entitiesToConfirm);
                    }

                    return of(undefined);
                }),
                this.takeUntilDestroyed(),
            ).subscribe(() => this.cancel());
        }
    }

    private async initialise(data: IWorkflowRunData) {
        this.isBusy = false;
        this.data = data;
        const connectionWorkflow = this.workflowService.getWorkflow(data.workflowConnection)!;
        if (!data.workflow && data.workflowConnection) {
            if (connectionWorkflow.type === WorkflowType.Journey && connectionWorkflow.workflows?.length) {
                data.workflow = connectionWorkflow.workflows[0];
            } else {
                data.workflow = connectionWorkflow;
            }
        }

        if (data.workflow) {
            // set width ASAP so it doesn't flash
            this.setDialogWidth(data.workflow);

            data.workflow.runData = data.runData; // clear on start

            // enable any required features ASAP so any components will be ready
            if (data.workflow.featuresToEnable) {
                await lastValueFrom(this.workflowService.enableFeaturesForWorkflow(data.workflow));
            }

            if (data.workflow.steps) {
                this.workflowSteps = data.workflow.steps.slice();
                // make sure the workflow steps are sorted by ordinal,
                // as navigation properties are sorted by primary key by default.
                this.workflowSteps.sort(SortUtilities.getSortByFieldFunction<WorkflowStep>("ordinal"));
            } else {
                this.workflowSteps = [];
            }

            if (!data.workflow.isStateless && data.workflowConnection) {
                const preProc = FunctionUtilities.isFunction(data.workflow.preProcessSteps)
                    ? data.workflow.preProcessSteps(this.injector, data.workflowConnection, this.workflowSteps)
                    : of(undefined);
                preProc.pipe(
                    switchMap(() => this.workflowService.getOrSetCurrentStepForWorkflow(data.workflowConnection!, data.workflow)),
                    this.takeUntilDestroyed(),
                ).subscribe((step) => {
                    if (step) {
                        this.setCurrentWorkflowStep(step);

                        if (data.skipOutcomes && step.isOutcomeStep) {
                            this.skip();
                        }
                    }
                });
            } else if (this.workflowSteps.length) {
                this.setCurrentWorkflowStep(this.workflowSteps[0]);
            } else {
                throw new Error("Cannot execute a workflow without steps");
            }
        } else {
            throw new Error("Cannot execute workflow without a journey or workflow defined.");
        }
    }

    public get dialogTitle() {
        if (this.isCompleted && !!this.wrapUpSlug) {
            // showing wrap up slug
            return WrapUpText;
        }

        if (this.data.titleOverride) {
            return this.data.titleOverride;
        }

        // use the workflow name as the dialog title if using breadcrumbs
        // disabled, because the breadcrumbs arent that large, and the title is still useful!
        // if (this.data.workflow.showBreadcrumbs && !this.currentWorkflowStep?.isOutcomeStep) {
        //     return this.data.workflow.name;
        // }

        if (this.currentWorkflowStep?.deepDive?.title) {
            return this.currentWorkflowStep.deepDive.title;
        }

        return this.currentWorkflowStep?.name;
    }

    public get stepLocation() {
        if (this.workflowSteps.length === 1 || !this.data.workflow.showBreadcrumbs) {
            return "";
        }

        if (!this.currentWorkflowStep || this.currentWorkflowStep.ordinal === undefined) {
            return "";
        }

        return `Step ${this.currentWorkflowStep.ordinal + 1} of ${this.workflowSteps.length}`;
    }

    public get isFirstStep() {
        if (this.currentWorkflowStep) {
            return this.workflowSteps[0]?.workflowStepId === this.currentWorkflowStep.workflowStepId;
        } else {
            return false;
        }
    }

    public get isLastStep() {
        if (this.currentWorkflowStep) {
            return this.workflowSteps[this.workflowSteps.length - 1]?.workflowStepId === this.currentWorkflowStep.workflowStepId;
        } else {
            return false;
        }
    }

    public get nextWorkflowStep() {
        if (this.currentWorkflowStep && !this.isLastStep) {
            const currentIndex = this.data.workflow.extensions.getStepIndex(this.currentWorkflowStep, this.workflowSteps);
            return (currentIndex === undefined) ? undefined : this.workflowSteps[currentIndex + 1]; // !lastStep and currentIndex is defined -> steps will be defined
        }

        return undefined;
    }

    public get previousWorkflowStep() {
        if (this.currentWorkflowStep && !this.isFirstStep) {
            const currentIndex = this.data.workflow.extensions.getStepIndex(this.currentWorkflowStep, this.workflowSteps);
            return (currentIndex === undefined) ? undefined : this.workflowSteps[currentIndex - 1]; // !firstStep and index found
        }

        return undefined;
    }

    public get isMobile() {
        return this.responsiveService.currentBreakpoint.isMobileSize;
    }

    public get nextStepText() {
        const nextStep = this.nextWorkflowStep;
        const name = nextStep?.hideTitle ? undefined : nextStep?.name;
        return this.getNextStepText(name && !this.isMobile ? `Next - ${name}` : "Next");
    }

    public get closeText() {
        return this.getNextStepText(this.rootWorkflow?.wrapUpSlug ? "Wrap up" : "Close");
    }

    public get footerLeft() {
        return this.footerTemplates?.filter((template) => template.alignment === "left");
    }

    public get footerCenter() {
        return this.footerTemplates?.filter((template) => template.alignment === "center");
    }

    public get footerRight() {
        return this.footerTemplates?.filter((template) => template.alignment === "right");
    }

    private get rootWorkflow() {
        return this.workflowService.getWorkflow(this.data.workflowConnection);
    }

    private getNextStepText(fallback: string) {
        if (this.currentCustomComponent && FunctionUtilities.isFunction(this.currentCustomComponent.instance.workflowStepNextText)) {
            const text = this.currentCustomComponent.instance.workflowStepNextText();
            if (text) {
                return text;
            }
        }

        return this.currentWorkflowStep?.workflowStepNextText ?? fallback;
    }

    public handleButtonClick(callback?: ButtonCallbackType) {
        if (callback) {
            callback(this.injector, this).subscribe();
        }
    }

    public previous() {
        this.currentWorkflowStep = this.previousWorkflowStep;
        this.resetStepCompletion();
        this.stepUpdater.next(this.currentWorkflowStep);

        if (!this.data.workflow!.isStateless && this.data.workflowConnection) {
            const updatedEntities: WorkflowStatus[] = [];
            const updateIncomplete = this.workflowService.updateStatusForWorkflowStep(this.data.workflowConnection, this.nextWorkflowStep!, WorkflowStatusEnum.Incomplete);
            updatedEntities.push(...updateIncomplete);
            const updateCurrent = this.workflowService.updateStatusForWorkflowStep(this.data.workflowConnection, this.currentWorkflowStep!, WorkflowStatusEnum.Current);
            updatedEntities.push(...updateCurrent);
            this.workflowService.saveEntities(updatedEntities).subscribe();
        }
    }

    @Autobind
    public blockingPrevious() {
        this.isBusy = true;
        if (this.currentCustomComponent && FunctionUtilities.isFunction(this.currentCustomComponent.instance.workflowStepPrevious)) {
            this.errorMessage = undefined;
            const interruptShortcut = new Subject<void>();
            return from(this.currentCustomComponent.instance.workflowStepPrevious!(interruptShortcut)).pipe(
                tap(() => this.previous()),
                finalize(() => this.isBusy = false),
                this.takeUntilDestroyed(),
                takeUntil(interruptShortcut.asObservable()),
                catchError((error) => this.errorMessage = error.message ?? error),
            );
        } else {
            this.previous();
            this.isBusy = false;
            return Promise.resolve();
        }
    }

    @Autobind
    public blockingNext() {
        this.isBusy = true;
        if (this.currentCustomComponent && FunctionUtilities.isFunction(this.currentCustomComponent.instance.workflowStepNext)) {
            this.errorMessage = undefined;
            const interruptShortcut = new Subject<void>();
            return from(this.currentCustomComponent.instance.workflowStepNext!(interruptShortcut)).pipe(
                tap(() => this.next()),
                finalize(() => this.isBusy = false),
                this.takeUntilDestroyed(),
                takeUntil(interruptShortcut.asObservable()),
                catchError((error) => this.errorMessage = error.message ?? error),
            );
        } else {
            this.next();
            this.isBusy = false;
            return Promise.resolve();
        }
    }

    /**
     * @param skippedStatusEntities Status entities to be saved from the previous skip
     * @param skipPrevious boolean flag to indicate the previous step is skipped
     *      - need this instead of relying on skippedStatusEntities to determine if the previous step is skipped as
     *        there won't be any skip status entities if the workflow is stateless.
     */
    private next(skippedStatusEntities?: WorkflowStatus[], skipPrevious = false) {
        of(undefined).pipe(
            switchMap(() => {
                this.currentWorkflowStep = this.nextWorkflowStep;
                this.preloadNextStepGuidance();

                if (this.currentWorkflowStep?.skipIfPreviousSkipped && skipPrevious) {
                    if (skippedStatusEntities) {
                        // if skipped and is stateful will have this collection - otherwise, won't have to update current step to SKIPPED as it is stateless
                        // - the following call will set this step to skipped as well due to 'skipIfPreviousSkipped
                        skippedStatusEntities.push(...this.updateCurrentStepStatusToSkipped()!);
                    }

                    if (this.isLastStep) {
                        // not going to load the skip step - so need to flag complete that not to do workflowStepNext as this is skipped
                        this.currentWorkflowStep = this.previousWorkflowStep; // revert back or next step will be loaded in the template during the following save
                        return this.saveUpdateWorkflowStepStatus(skippedStatusEntities).pipe(
                            switchMap(() => this.complete(true)),
                        );
                    } else {
                        // can only skip 1 step, won't do cascade skip,
                        // - i.e. skip, next step will skip if previous skip, next step won't skip even with skipIfPreviousSkipped
                        //   as previous is not skipped (it was skipped if previous skip)
                        // - The above updateCurrentStepStatusToSkipped has already updated the step status to skipped - so just move onto next
                        this.currentWorkflowStep = this.nextWorkflowStep;
                        this.preloadNextStepGuidance();
                    }
                }

                this.resetStepCompletion();
                this.setDialogWidth(this.currentWorkflowStep?.workflow);
                this.stepUpdater.next(this.currentWorkflowStep);

                return this.saveUpdateWorkflowStepStatus(skippedStatusEntities, this.currentWorkflowStep);
            }),
            tap(() => {
                if (this.previousWorkflowStep?.closeAfterNext) {
                    setTimeout(() => this.cancel());
                }
            }),
            this.takeUntilDestroyed(),
        ).subscribe();
    }

    /**
     * This will ONLY be called from next(), i.e. current is already pointing to current step and there will be a previous step
     * (unless it comes from the outcome step which will be flagged from the wasWorkflowOutcomeStep param).
     * It is splitted into a separate function as it can be called under 2 conditions: 1) next 2) skip
     * If skipped, skipped status will be set and there will be status entities awaiting to be saved - previous step will not be touched
     * If next'ed, there won't be skippedStatusEntities and previous workflow step is guaranteed to be there (we just moved from there in 'next').
     *
     * Nothing will be done if the workflow is stateless.
     *
     * @param skippedStatusEntities Status entities that has been set to SKIPPED and needed to be saved (i.e. when skipping the previous step)
     * @param workflowStepToBeCurrent  WorkflowStep that will be set to CURRENT - can be undefined skipped to completion
     * @param wasWorkflowOutcomeStep Indicates the previous step was a outcome step which is not really a workflow step and won't be a status change
     * @returns Observable to be subscribed to
     */
    private saveUpdateWorkflowStepStatus(skippedStatusEntities?: WorkflowStatus[], workflowStepToBeCurrent?: WorkflowStep) {
        if (!this.data.workflow!.isStateless && this.data.workflowConnection) {
            const updatedEntities: WorkflowStatus[] = [];
            if (!skippedStatusEntities) { // previous step was not skipped
                const updateCompleted = this.workflowService.updateStatusForWorkflowStep(this.data.workflowConnection, this.previousWorkflowStep!, WorkflowStatusEnum.Completed);
                updatedEntities.push(...updateCompleted);
            } else {
                updatedEntities.push(...skippedStatusEntities);
            }

            if (workflowStepToBeCurrent) {
                const updateCurrent = this.workflowService.updateStatusForWorkflowStep(this.data.workflowConnection!, workflowStepToBeCurrent, WorkflowStatusEnum.Current);
                updatedEntities.push(...updateCurrent);
            }

            // need to find all excluded incomplete steps before this and set them to skipped (to not be identified as next step on resume)
            if (this.data.workflow.steps && workflowStepToBeCurrent) {
                const additionalSkippedSteps = this.incompleteExcludedSteps.filter((step) => step.ordinal < workflowStepToBeCurrent.ordinal);
                additionalSkippedSteps.forEach((skippedStep) => {
                    const additionalUpdatedEntities = this.workflowService.updateStatusForWorkflowStep(this.data.workflowConnection!, skippedStep, WorkflowStatusEnum.Skipped);
                    updatedEntities.push(...additionalUpdatedEntities);
                });
            }

            return this.workflowService.saveEntities(updatedEntities);
        } else {
            return of(undefined);
        }
    }

    public skip() {
        this.next(this.updateCurrentStepStatusToSkipped(), true);
    }

    private updateCurrentStepStatusToSkipped() {
        return !this.data.workflow!.isStateless && this.data.workflowConnection
            ? this.workflowService.updateStatusForWorkflowStep(this.data.workflowConnection, this.currentWorkflowStep!, WorkflowStatusEnum.Skipped)
            : undefined;
    }

    public showDismissDialog() {
        if (this.data.dismissDialog) {
            this.dialogService.open(WorkflowConfirmDialogComponent, this.data.dismissDialog).pipe(
                switchMap((dialogResult) => dialogResult!.result ? of(void 0) : EMPTY),
                tap(() => this.resolve(undefined)),
            ).subscribe();
        }
    }

    public cancel() {
        if (this.canSubmitRating()) {
            const dialogData: IConfirmationDialogData = {
                title: "Send Feedback",
                confirmButtonText: "Send Feedback",
                cancelButtonText: "Discard",
                message: "You are about to discard your feedback. If you choose to discard, your feedback will not be sent. <br/><br/> Are you sure you want to continue?",

            };
            this.commonDialogService.openConfirmationDialogWithBoolean(dialogData).subscribe((sendFeedback) => {
                if (sendFeedback) {
                    this.submitRating();
                }
                super.cancel();
            });
        } else {
            super.cancel();
        }
    }

    @Autobind
    public complete(skipToComplete = false, continueAfter = false) {
        this.isBusy = true;
        const interruptShortcut = new Subject<void>();
        let workflowStepNext$: Observable<unknown>;
        if (!skipToComplete && this.currentCustomComponent && FunctionUtilities.isFunction(this.currentCustomComponent.instance.workflowStepNext)) {
            workflowStepNext$ = from(this.currentCustomComponent.instance.workflowStepNext!(interruptShortcut));
        } else {
            workflowStepNext$ = of(undefined);
        }

        return workflowStepNext$.pipe(
            switchMap(() => {
                if (!this.data.workflow!.isStateless && this.data.workflowConnection && !skipToComplete) {
                    const updateCompleted = this.workflowService.updateStatusForWorkflowStep(this.data.workflowConnection, this.currentWorkflowStep!, WorkflowStatusEnum.Completed);
                    if (this.data.workflow.steps) {
                        // this is completing the workflow
                        // - so for steps after that which are excluded, they will need to be tagged as skipped or it will be resumed to next time
                        //   as the workflow is not really completed, having incomplete steps
                        // - tagging as skipped will complete the workflow
                        const skippedSteps = this.incompleteExcludedSteps.filter((step) => step.ordinal >= this.currentWorkflowStep!.ordinal);
                        skippedSteps.forEach((skippedStep) => {
                            const updatedEntities = this.workflowService.updateStatusForWorkflowStep(this.data.workflowConnection!, skippedStep, WorkflowStatusEnum.Skipped);
                            updateCompleted.push(...updatedEntities);
                        });
                    }
                    return this.workflowService.saveEntities(updateCompleted);
                } else {
                    return of(undefined);
                }
            }),
            switchMap(() => {
                const stepOrdinals = Object.keys(this.postWorkflowSteps)
                    .map((key) => Number(key))
                    .sort();
                if (stepOrdinals.length > 0) {
                    return forkJoin(stepOrdinals.map((ordinal) => this.postWorkflowSteps[ordinal]()));
                } else {
                    return of(undefined);
                }
            }),
            map(() => {
                if (this.data.workflow.parentWorkflow?.workflows) {
                    const currentWorkflowIndex = this.data.workflow.parentWorkflow.workflows.indexOf(this.data.workflow);
                    if (currentWorkflowIndex < (this.data.workflow.parentWorkflow.workflows.length - 1)) {
                        const nextWorkflow = this.data.workflow.parentWorkflow.workflows[currentWorkflowIndex + 1];
                        return nextWorkflow;
                    }
                    // workflow is part of a journey, check if the next workflow in the journey is incomplete -> start
                }

                return undefined;
            }),
            switchMap((nextWorkflow) => {
                if (nextWorkflow && this.data.workflowConnection) {
                    return this.workflowService.getStatusForWorkflow(this.data.workflowConnection, nextWorkflow).pipe(
                        map((statusEntity) => ({
                            workflow: nextWorkflow,
                            status: statusEntity?.status ?? WorkflowStatusEnum.Incomplete,
                        })),
                    );
                }
                return of({ workflow: nextWorkflow, status: WorkflowStatusEnum.Incomplete });
            }),
            switchMap((nextWorkflowStatus) => {
                const continueStatuses = [WorkflowStatusEnum.Incomplete, WorkflowStatusEnum.Current];
                if ((this.data.workflow.continueOnFinish || continueAfter) && nextWorkflowStatus.workflow && continueStatuses.includes(nextWorkflowStatus?.status)) {
                    // carry on with the next workflow
                    this.initialise({
                        workflowConnection: this.data.workflowConnection,
                        workflow: nextWorkflowStatus.workflow,
                    });
                    return EMPTY;
                }

                return of(undefined);
            }),
            switchMap(() => this.data.workflowConnection
                ? this.workflowService.getStatusForWorkflow(this.data.workflowConnection).pipe(
                    map((statusEntity) => statusEntity?.status ?? WorkflowStatusEnum.Incomplete),
                )
                : of(WorkflowStatusEnum.Incomplete)),
            switchMap((rootStatus) =>
                (rootStatus === WorkflowStatusEnum.Completed)
                    ? this.workflowService.finalizeWorkflow(this.data.workflowConnection).pipe(
                        map(() => rootStatus))
                    : of(rootStatus)),
            tap((rootStatus) => {
                this.isCompleted = true;
                if (rootStatus === WorkflowStatusEnum.Completed) {
                    const rootWorkflow = this.rootWorkflow;
                    if (rootWorkflow?.wrapUpSlug) {
                        this.wrapUpSlug = rootWorkflow.wrapUpSlug;
                        this.wrapUpTour = rootWorkflow.wrapUpGuidedTourIdentifier
                            ? GuidedTourRegistry.get(rootWorkflow.wrapUpGuidedTourIdentifier)
                            : undefined;
                        this.wrapUpTourPersonFlag = rootWorkflow?.wrapUpGuidedTourPersonFlag;
                        return;
                    }
                }

                this.closeDialog();
            }),
            takeUntil(interruptShortcut.asObservable()),
        );
    }

    public runWrapUpTour() {
        if (this.wrapUpTour) {
            this.closeDialog();
            // only run tour after the dialog is closed
            setTimeout(async () => {
                if (this.wrapUpTourPersonFlag) {
                    await this.personFlagService.setFlagAndRunTour(this.wrapUpTourPersonFlag, this.wrapUpTour);
                } else {
                    this.guidedTourService.run(this.wrapUpTour);
                }
            });
        }
    }

    public closeDialog() {
        this.submitRating();
        // only get rid of the dialog 1 digest cycle after everything completed
        setTimeout(() => this.resolve(undefined));
    }

    private get incompleteExcludedSteps() {
        return this.data.workflow.steps
            ? this.data.workflow.steps.filter((definedStep) =>
                !this.workflowSteps.find((activeStep) => activeStep.workflowStepId === definedStep.workflowStepId) &&
                this.workflowService.findStatusEntityForStep(definedStep, this.data.workflowConnection!.statuses)?.status === WorkflowStatusEnum.Incomplete)
            : [];
    }

    private checkLoadComponentSelectorComponent() {
        if (this.currentCustomComponent) {
            this.currentCustomComponent.destroy();
            this.currentCustomComponent = undefined;

            // cleanup previous subscription
            this.stepEntityChangeSubscription?.unsubscribe();
            this.stepEntityChangeSubscription = undefined;
            this.goToStepSubscription?.unsubscribe();
            this.goToStepSubscription = undefined;
            this.updateDimensionsTriggerSubscription?.unsubscribe();
            this.updateDimensionsTriggerSubscription = undefined;
            this.stepCompletionSubscription?.unsubscribe();
            this.stepCompletionSubscription = undefined;
            this.stepSkippableSubscription?.unsubscribe();
            this.stepSkippableSubscription = undefined;
            this.errorMessageSubscription?.unsubscribe();
            this.errorMessageSubscription = undefined;
            this.finishCurrentSubscription?.unsubscribe();
            this.finishCurrentSubscription = undefined;
            this.footerTemplateSubscription?.unsubscribe();
            this.footerTemplateSubscription = undefined;
            this.footerTemplates = undefined;
            this.errorMessage = undefined;
        }

        if (this.currentWorkflowStep?.componentSelector && this.container) {
            const type = WorkflowStepComponentRegistry.get(this.currentWorkflowStep.componentSelector);
            if (!type) {
                throw new Error(`component is not registered - ${this.currentWorkflowStep.componentSelector} - needs to use the WorkflowComponent class decorator`);
            }

            this.container.clear();
            this.currentCustomComponent = this.container.createComponent<IWorkflowStepComponent>(type);
            const instance = this.currentCustomComponent.instance;
            instance.workflowStep = this.currentWorkflowStep;
            instance.workflowConnection = this.data.workflowConnection;
            if (instance.workflowStepFinishCurrent) {
                this.finishCurrentSubscription = instance.workflowStepFinishCurrent.pipe(
                    switchMap((continueAfter = false) => {
                        continueAfter = typeof continueAfter === "boolean" ? continueAfter : false;
                        return this.isLastStep ? this.complete(false, continueAfter) : this.blockingNext();
                    }),
                    this.takeUntilDestroyed(),
                ).subscribe();
            }

            if (instance.workflowStepErrorMessage) {
                this.errorMessage = undefined;
                this.errorMessageSubscription = instance.workflowStepErrorMessage.pipe(
                    this.takeUntilDestroyed(),
                ).subscribe((msg) => this.errorMessage = msg);
            }

            if (instance.workflowStepFooterTemplates) {
                this.footerTemplateSubscription = instance.workflowStepFooterTemplates.pipe(
                    this.takeUntilDestroyed(),
                ).subscribe((templates) => this.footerTemplates = templates);
            }

            if (instance.workflowStepEntityChange) {
                this.stepEntityChangeSubscription = instance.workflowStepEntityChange.pipe(
                    this.takeUntilDestroyed(),
                ).subscribe((changedEntity) => ArrayUtilities.addElementIfNotAlreadyExists(this.entitiesToConfirm, changedEntity));
            }

            if (instance.workflowGoToStep) {
                this.goToStepSubscription = instance.workflowGoToStep.pipe(
                    this.takeUntilDestroyed(),
                ).subscribe((step) => {
                    if (step !== undefined && this.data?.workflow?.steps?.[step]) {
                        this.setCurrentWorkflowStep(this.workflowSteps[step]);
                    }
                });
            }

            if (instance.updateDimensionsTrigger) {
                this.updateDimensionsTriggerSubscription = instance.updateDimensionsTrigger.pipe(
                    debounceTime(200),
                    this.takeUntilDestroyed(),
                ).subscribe(() => this.updateDimensions());
            }

            if (FunctionUtilities.isFunction(instance.workflowStepOnInit)) {
                // this will be called if the step has the method implemented regardless of whether other steps have defined their data
                // - this is to allow step initialization after setting data
                instance.workflowStepOnInit!();
            }

            // record lastCompletedStepIdx so we can jump between completed steps
            if (instance.workflowStepCompleted) {
                this.currentStepCompleted = false;
                this.stepCompletionSubscription = instance.workflowStepCompleted.pipe(
                    this.takeUntilDestroyed(),
                ).subscribe((completed) => {
                    const currentOrdinal = this.currentWorkflowStep?.ordinal ?? 0;
                    this.lastCompletedStepIdx = completed
                        ? Math.max(this.lastCompletedStepIdx, currentOrdinal)
                        : currentOrdinal;
                    this.currentStepCompleted = completed;
                    this.progressSteps = this.getProgressSteps();
                });
            }

            // need a way to disable skip button too
            if (this.currentWorkflowStep.canSkip && instance.workflowStepSkipEnabled) {
                this.currentStepSkippable = false;
                this.stepSkippableSubscription = instance.workflowStepSkipEnabled.pipe(
                    this.takeUntilDestroyed(),
                    finalize(() => this.currentStepSkippable = true),
                ).subscribe((skippable) => this.currentStepSkippable = skippable);
            } else { // no observable defined in step - nothing stopping the step from being skipped
                this.currentStepSkippable = true;
            }

            if (instance.postWorkflow) {
                this.postWorkflowSteps[this.currentWorkflowStep.ordinal] = instance.postWorkflow.bind(instance);
            }

            if (this.isFocusable(instance)) {
                setTimeout(() => {
                    this.elementToFocus = instance.getElementToFocus();
                    // this will trigger the element focus
                    this.onShown();
                });
            }

            this.onFullscreenToggled(this.fullscreen); // update fullscreen status of the loaded component
            // if component load is slow, will need to update as it may be wrapped after loading.
            this.updateDimensions();
        }
    }

    private isFocusable(object: any): object is IFocusable {
        return "getElementToFocus" in object;
    }

    private setCurrentWorkflowStep(step?: WorkflowStep) {
        this.currentWorkflowStep = step;
        this.resetStepCompletion();
        this.stepUpdater.next(this.currentWorkflowStep);
        this.progressSteps = this.getProgressSteps();
        this.preloadNextStepGuidance();
    }

    private preloadNextStepGuidance() {
        const articleSlugs: ImplementationKitArticle[] = [];

        const nextStep = this.nextWorkflowStep;
        if (nextStep) {
            if (nextStep.articleSlug) {
                articleSlugs.push(nextStep.articleSlug);
            }

            if (nextStep.componentSelector === DisplayWorkflowActivityBriefComponentSelector) {
                const customData = nextStep.customData as IActivityBriefData;
                if (customData?.meetingDescriptionArticle) {
                    articleSlugs.push(customData.meetingDescriptionArticle);
                }
                if (customData?.meetingPreWorkArticle) {
                    articleSlugs.push(customData.meetingPreWorkArticle);
                }
            }
        } else if (this.isLastStep) {
            const rootWorkflow = this.rootWorkflow;
            if (rootWorkflow && rootWorkflow.wrapUpSlug) {
                articleSlugs.push(rootWorkflow.wrapUpSlug);
            }
        }

        if (articleSlugs.length > 0) {
            forkJoin(articleSlugs.map((slug) => this.implementationKitService.getArticle(slug))).subscribe();
        }
    }

    private resetStepCompletion() {
        if (!this.currentWorkflowStep?.deepDiveId) {
            // not deep dive -> step is already completed on entrance
            this.lastCompletedStepIdx = Math.max(this.lastCompletedStepIdx, this.currentWorkflowStep?.ordinal ?? 0);
            this.currentStepCompleted = true;
        } else {
            this.currentStepCompleted = false;
        }
    }

    private setDialogWidth(workflow?: Workflow, override?: WorkflowDialogWidth) {
        if (override) {
            this.dialogWidth = this.dialogWidthMappings[override];
            return;
        }

        if (workflow?.dialogWidth) {
            this.dialogWidth = this.dialogWidthMappings[workflow.dialogWidth];
        }
    }

    private getProgressSteps(): IProgressStep<WorkflowStep>[] {
        return this.workflowSteps
            .filter((step) => !step.isConfirmationStep)
            .map((step, stepIndex) => ({
                title: step.name,
                data: step,
                completed: stepIndex < this.lastCompletedStepIdx,
                onClick: (targetStep, idx) => {
                    // first step typically does not have allowBack enabled (of course...)
                    if ((this.currentWorkflowStep!.allowBack || this.currentWorkflowStep!.ordinal === 0)
                        && this.lastCompletedStepIdx >= idx) {
                        this.setCurrentWorkflowStep(targetStep.data);
                    }
                    return of(undefined);
                },
            }));
    }

    public submitRating() {
        if (this.canSubmitRating()) {
            if (this.data.workflowConnection) {
                this.workflowRating = {
                    ...this.workflowRating,
                    workflowConnectionId: this.data.workflowConnection.workflowConnectionId,
                    organisationId: this.data.workflowConnection.organisationId,
                    organisationName: this.data.workflowConnection.organisation.name,
                };
                this.workflowRatingService.submitRating(this.workflowRating).subscribe(() => {
                    this.logger.success("Thank you for your feedback");
                });
            } else {
                this.log.error("Failed to submit rating. workflowConnection is undefined", this.workflowRating);
            }
        }
    }

    public canSubmitRating() {
        return this.workflowRating.rating > 0 || this.workflowRating.improveOn || this.workflowRating.liked;
    }

    public onFullscreenToggled(fullscreen: boolean) {
        this.fullscreen = fullscreen;
        if (FunctionUtilities.isFunction(this.currentCustomComponent?.instance.onDialogFullscreenToggled)) {
            this.currentCustomComponent.instance.onDialogFullscreenToggled(fullscreen);
        }
    }

    @HostListener("window:resize")
    public updateDimensions() {
        const implementationKitElement = document.querySelector<HTMLElement>(".implementation-kit");
        // only do this if there are implementation kit
        if (implementationKitElement && implementationKitElement.parentElement) {
            this.fullWidthImplementationKitUpdater.next(!ElementUtilities.hasSiblingsOnTheSameRow(implementationKitElement));
        }

        // need these to make the 'always' shown scrollbar to show or go away (if the page is not high enough to trigger the scroll)
        setTimeout(() => {
            this.implementationKitScrollViewComponent?.instance.update();
            this.stepComponentScrollViewComponent?.instance.update();
        });
    }

    public clearRating() {
        this.workflowRating.rating = 0;
        this.workflowRating.improveOn = "";
        this.workflowRating.liked = "";
    }
}

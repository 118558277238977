import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from "@angular/core";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { emptyIfUndefinedOrNull } from "@common/lib/utilities/rxjs-utilities";
import { UserService } from "@common/user/user.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { take } from "rxjs/operators";

export interface INewComment {
    item: Item;
    person: Person;
}

@Component({
    selector: "adapt-add-comment-place-holder",
    templateUrl: "./add-comment-place-holder.component.html",
    styleUrls: ["./add-comment-place-holder.component.scss"],
})
export class AddCommentPlaceHolderComponent extends BaseComponent implements OnInit {
    @Output() public newCommentAdded = new EventEmitter<INewComment>();

    @Input() public item!: Item;

    public constructor(
        elementRef: ElementRef,
        public userService: UserService,
    ) {
        super(elementRef);
    }

    public ngOnInit() {
        if (!this.item) {
            throw new AdaptError("Expected item entity not passed into add-comment-place-holder");
        }
    }

    @HostListener("click", ["$event"])
    public onClick(e: InputEvent) {
        e.preventDefault();
        if (this.newCommentAdded.observers.length > 0) {
            this.userService.currentPerson$.pipe(
                emptyIfUndefinedOrNull(),
                take(1),
                this.takeUntilDestroyed(),
            ).subscribe((person) => this.newCommentAdded.emit({
                item: this.item,
                person,
            }));
        }
    }
}

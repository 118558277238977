import { Component, Input } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject } from "rxjs";
import { filter, switchMap, withLatestFrom } from "rxjs/operators";
import { CommonTeamsService } from "../../common-teams.service";
import { TeamDashboardSharedService } from "../team-dashboard-shared.service";

@Component({
    selector: "adapt-link-team",
    templateUrl: "./link-team-dashboard.component.html",
    styleUrls: ["link-team-dashboard.component.scss"],
})
export class LinkTeamDashboardComponent extends BaseComponent {
    @Input() public set teamId(value: number | undefined) {
        if (value) {
            this.teamId$.next(value);
        }
    }
    private teamId$ = new ReplaySubject<number>(1);
    @Input() public set team(value: Team | undefined) {
        if (value) {
            this.team$.next(value);
        }
    }
    public team$ = new ReplaySubject<Team>(1);

    @Input() public showIcon = false;
    @Input() public showIconBorder = false;
    @Input() public labelLinkDisabled = false;
    @Input() public showLabels = false;
    @Input() public set focusPersonId(value: number | undefined) {
        if (value) {
            this.focusPersonId$.next(value);
        }
    }
    public focusPersonId$ = new ReplaySubject<number>(1);

    @Input() public showChildren = false;
    @Input() public showAllChildren = false;
    @Input() public showChildCount?: boolean;
    @Input() public childrenExpanded = false;
    @Input() public filterTeams?: (teams: Team[]) => Team[] | Observable<Team[]>;

    public dashboardUrl$: Observable<string | undefined>;
    public showTeamLeaderIcon$: Observable<boolean>;
    public teamLeaderLabel$: Observable<string>;
    public childTeams$: Observable<Team[]>;

    private triggerChildrenUpdate$ = new BehaviorSubject<void>(undefined);

    public constructor(
        teamDashboardSharedService: TeamDashboardSharedService,
        commonTeamService: CommonTeamsService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        this.teamLeaderLabel$ = commonTeamService.getTeamLeaderLabel$();
        this.teamId$.pipe(
            switchMap((teamId) => commonTeamService.getTeamById(teamId)),
            this.takeUntilDestroyed(),
        ).subscribe((team) => {
            if (team) {
                this.team$.next(team);
            }
        });

        this.dashboardUrl$ = this.team$.pipe(
            switchMap((team) => teamDashboardSharedService.getTeamDashboardLink(team)),
        );

        this.showTeamLeaderIcon$ = combineLatest(
            this.team$, this.focusPersonId$,
            (team, focusPersonId) => team.teamLeaderPersonId === focusPersonId,
        );

        this.childTeams$ = this.triggerChildrenUpdate$.pipe(
            switchMap(() => this.team$),
            switchMap((team) => this.shouldShowChildCount
                ? commonTeamService.getActiveChildTeams(team)
                : of([])),
            switchMap((teams) => this.applyTeamFilter(teams)),
        );
        rxjsBreezeService.entityTypeChanged(Team).pipe(
            filter(() => this.shouldShowChildCount),
            withLatestFrom(this.team$),
            filter(([changedTeam, currentTeam]) => changedTeam.teamId === currentTeam.teamId || changedTeam.parentTeamId === currentTeam.teamId),
            this.takeUntilDestroyed(),
        ).subscribe(() => this.triggerChildrenUpdate$.next());
    }

    public get shouldShowChildCount() {
        return this.showChildCount || (this.showChildCount === undefined && this.showChildren);
    }

    private applyTeamFilter(teams: Team[]) {
        if (!teams) {
            return of([]);
        }

        if (!this.filterTeams) {
            return of(teams);
        }

        const filtered = this.filterTeams(teams);
        return Array.isArray(filtered)
            ? of(filtered)
            : filtered;
    }
}

import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationPageRouteBuilder } from "../../route/organisation-page-route-builder";
import { ExternalDashboardPageComponent } from "./external-dashboard-page.component";

export const organisationExternalDashboardPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-external-dashboard-page", ExternalDashboardPageComponent)
    .atOrganisationUrl("/external-link")
    .redirectToThisRouteFromOrganisationUrl("/externalDashboard")
    .verifyingFeatures(FeatureName.OrganisationExternalDashboard)
    .verifyingAccess("viewExternalDashboard")
    .withTitle("External Link")
    .reloadOnSearch(true)
    .build();

export const teamExternalDashboardPageRoute = new OrganisationPageRouteBuilder<{ teamId: number }>()
    .usingNgComponent("adapt-external-dashboard-page", ExternalDashboardPageComponent)
    .atOrganisationUrl("/team/:teamId/external-link")
    .redirectToThisRouteFromOrganisationUrl("/team/:teamId/externalDashboard")
    .redirectToThisRouteFromOrganisationUrl("/people/teams/:teamId/externalDashboard")
    .verifyingFeatures(FeatureName.OrganisationExternalDashboard)
    .withTitle("External Link")
    .reloadOnSearch(true)
    .build();

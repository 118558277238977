<adapt-dialog [maxWidth]="650">
    <h3 adapt-dialog-title>Reactivate {{person.fullName}}'s Account</h3>

    <form adapt-dialog-content
          role="form">

        <div role="alert"
             class="alert alert-info">
            Reactivate an account when a person who has previously left the organisation has now rejoined.
        </div>

        <h2>Set user type and access level for {{person.firstName}}</h2>
        <adapt-select-user-type [(userType)]="userType"
                                [connectionType]="connectionType"
                                (userTypeChange)="userTypeChange()"></adapt-select-user-type>

        <div *ngIf="userType !== UserType.None"
             class="mt-2">
            <label>Set Access Level</label>
            <adapt-select-access-level [userType]="userType"
                                       [(accessLevel)]="selectedAccessLevel"></adapt-select-access-level>
        </div>

        <dx-check-box class="mt-3"
                      [value]="allowLogin"
                      (valueChange)="allowLogin = $any($event)"
                      text="Allow {{person.firstName}} to log in to embedADAPT"></dx-check-box>
    </form>

    <div adapt-dialog-footer
         class="ms-auto">
        <button [disabled]="!selectedAccessLevel"
                [adaptBlockingClick]="reactivate"
                adaptButton="ok"></button>
        <button (click)="cancel()"
                adaptButton="cancel"></button>
    </div>
</adapt-dialog>

import { Injectable } from "@angular/core";
import { EventCadenceCycle, EventCadenceCycleBreezeModel } from "@common/ADAPT.Common.Model/organisation/event-cadence-cycle";
import { EventSeries, EventSeriesBreezeModel } from "@common/ADAPT.Common.Model/organisation/event-series";
import { EventSeriesDayOfWeek } from "@common/ADAPT.Common.Model/organisation/event-series-day-of-week";
import { EventSeriesWeekIndex } from "@common/ADAPT.Common.Model/organisation/event-series-week-index";
import { AllEventTypePresets, EventType, EventTypeBreezeModel, EventTypePreset } from "@common/ADAPT.Common.Model/organisation/event-type";
import { IMeetingCustomData, Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { DateUtilities } from "@common/lib/utilities/date-utilities";
import moment from "moment";
import { from, lastValueFrom, of, switchMap } from "rxjs";
import { map } from "rxjs/operators";
import { MeetingsService } from "../meetings/meetings.service";
import { OrganisationService } from "../organisation/organisation.service";
import { CommonTeamsService } from "../teams/common-teams.service";
import { EventSeriesDefaults } from "./event-series-defaults";
import { ISetCadenceRunData } from "./schedule.interface";
import { IScheduledPresetsMap, IScheduledRecurrence } from "./schedule-recurrence/schedule-recurrence.interface";

@Injectable({
    providedIn: "root",
})
export class ScheduleService {
    constructor(
        private commonDataService: CommonDataService,
        private organisationService: OrganisationService,
        private teamsService: CommonTeamsService,
        private meetingsService: MeetingsService,
    ) {}

    public getEventTypesForTeam(teamId: number) {
        const predicate = new MethodologyPredicate<EventType>("teamId", "==", teamId);
        return this.commonDataService.getWithOptions(EventTypeBreezeModel, this.getTeamEventTypesEncompassingKey(teamId), {
            predicate,
            navProperty: "meetingAgendaTemplate",
        });
    }

    public getEventTypeForTeam(code: string, teamId: number) {
        const predicate = new MethodologyPredicate<EventType>("teamId", "==", teamId)
            .and(new MethodologyPredicate<EventType>("code", "==", code));
        return this.commonDataService.getWithOptions(EventTypeBreezeModel, predicate.getKey(EventTypeBreezeModel.identifier), {
            predicate,
            encompassingKey: this.getTeamEventTypesEncompassingKey(teamId),
            navProperty: "meetingAgendaTemplate",
        }).pipe(
            map(ArrayUtilities.getSingleFromArray),
        );
    }

    public getCurrentEventSeriesForEventTypes(eventTypes: EventType[], includeEnded = false) {
        const latestEndDate = moment.utc().endOf("day").toDate();
        const predicate = new MethodologyPredicate<EventSeries>("eventTypeId", "in", eventTypes.map((e) => e.eventTypeId));
        if (!includeEnded) {
            predicate.and(new MethodologyPredicate<EventSeries>("endDate", ">", latestEndDate));
        }
        return this.commonDataService.getWithOptions(EventSeriesBreezeModel, predicate.getKey(EventSeriesBreezeModel.identifier), {
            predicate,
            orderBy: "startDate DESC",
            navProperty: "meetings",
        });
    }

    public getCurrentEventSeriesForEventType(eventType: EventType, includeEnded = false) {
        const latestEndDate = moment.utc().endOf("day").toDate();
        const predicate = new MethodologyPredicate<EventSeries>("eventTypeId", "==", eventType.eventTypeId);
        if (!includeEnded) {
            predicate.and(new MethodologyPredicate<EventSeries>("endDate", ">", latestEndDate));
        }
        return this.commonDataService.getWithOptions(EventSeriesBreezeModel, predicate.getKey(EventSeriesBreezeModel.identifier), {
            predicate,
            orderBy: "startDate DESC",
            top: 1,
            navProperty: "meetings",
        }).pipe(
            map(ArrayUtilities.getSingleFromArray<EventSeries>),
        );
    }

    public getLatestMeetingAndSeriesForEventTypePreset(preset: EventTypePreset, includeEnded = false) {
        return from(this.teamsService.promiseToGetLeadershipTeam()).pipe(
            switchMap((team) => this.getEventTypeForTeam(preset, team.teamId)),
            switchMap((eventType) => eventType
                ? this.getCurrentEventSeriesForEventType(eventType, includeEnded)
                : of(undefined)),
            map((eventSeries) => eventSeries
                ? ({ meeting: eventSeries.extensions.getLatestMeeting(), series: eventSeries })
                : undefined),
        );
    }

    public isLatestEventSeriesForPresetCompleted(preset: EventTypePreset, includeEnded = false) {
        return this.getLatestMeetingAndSeriesForEventTypePreset(preset, includeEnded).pipe(
            map((seriesWithMeeting) => seriesWithMeeting
                ? seriesWithMeeting.series.extensions.isCompleted || moment().isSameOrAfter(seriesWithMeeting.series.endDate)
                : false),
        );
    }

    public getOrCreateEventCadenceCycle(options?: Partial<EventCadenceCycle>) {
        return this.getEventCadenceCycle().pipe(
            switchMap((cadenceCycle) => cadenceCycle
                ? of(cadenceCycle)
                : this.createEventCadenceCycle(options)),
        );
    }

    public getEventCadenceCycle() {
        return this.commonDataService.getById(EventCadenceCycleBreezeModel, this.organisationService.getOrganisationId());
    }

    private createEventCadenceCycle(options?: Partial<EventCadenceCycle>) {
        return this.commonDataService.create(EventCadenceCycleBreezeModel, {
            organisationId: this.organisationService.getOrganisationId(),
            ...(options ?? {}),
        });
    }

    /**
     * Gets the current EventSeries for the given EventTypePreset within the given Team.
     * This is returned as a Map of EventTypePreset to EventSeries.
     * @param eventTypePresets presets to get EventSeries for
     * @param team team to fetch EventSeries within
     * @param includedEnded ended EventSeries will be included if true
     */
    public async getCurrentEventSeriesMapForPresetsWithinTeam(eventTypePresets: EventTypePreset[], team: Team, includedEnded = false) {
        const eventSeriesMap = new Map<EventTypePreset, EventSeries>();

        const teamEventTypes = await lastValueFrom(this.getEventTypesForTeam(team.teamId));

        const wantedEventTypes = teamEventTypes.filter((et) => eventTypePresets.includes(et.code as EventTypePreset));
        const eventSeries =  await lastValueFrom(this.getCurrentEventSeriesForEventTypes(wantedEventTypes, includedEnded));
        for (const series of eventSeries) {
            eventSeriesMap.set(series.eventType.code as EventTypePreset, series);
        }

        return eventSeriesMap;
    }

    public async getCadenceRunData(eventTypePresets: EventTypePreset[], team: Team, eventCadenceCycle: EventCadenceCycle, createRecurrences = true) {
        const runData = {
            eventTypePresets,
            scheduledPresets: new Map(),
            deletedEntities: [],
            eventCadenceCycle,
        } as ISetCadenceRunData;

        // can't do in Promise.all as its stateful and other event types need the startDate of the AS.
        for (const preset of eventTypePresets) {
            const recurrence = await this.getRecurrenceForEventTypePreset(preset, team, runData.scheduledPresets, createRecurrences);
            if (recurrence) {
                runData.scheduledPresets.set(recurrence.eventTypePreset!, recurrence);
            }
        }

        return runData;
    }

    public async getRecurrenceForEventTypePreset(eventTypePreset: EventTypePreset, team: Team, scheduledPresets: IScheduledPresetsMap, createRecurrence = true) {
        const eventType = await lastValueFrom(this.getEventTypeForTeam(eventTypePreset, team.teamId));
        if (!eventType) {
            throw new Error(`Event type ${eventTypePreset} not found for teamId=${team.teamId}`);
        }

        const cadenceCycle = await lastValueFrom(this.getEventCadenceCycle());
        if (!cadenceCycle) {
            throw new Error("EventCadenceCycle must exist to continue setting cadence");
        }

        // already have a configuration for this preset, use that
        const configuredSeries = scheduledPresets.get(eventTypePreset);
        if (configuredSeries) {
            return configuredSeries;
        }

        const defaultStartDate = moment(DateUtilities.getNextWorkingDay())
            .hour(9)
            .minute(0)
            .toDate();

        // always use the SFO startDate for calculating the cycle endDate
        // if this is the first preset calculation, we need to default to the same cadenceStartDate, as the scheduledPresets map won't have the AS preset yet.
        const cadenceStartDate = scheduledPresets.get(EventTypePreset.SetFirstObjectives)?.eventSeries.startDate ?? defaultStartDate;
        const endDate = cadenceCycle.extensions.getEndDate(defaultStartDate);

        // the date to start generating events from
        const startDate = EventSeriesDefaults[eventTypePreset]?.getStartDate?.(scheduledPresets, cadenceCycle) ?? cadenceStartDate;

        return lastValueFrom(this.getCurrentEventSeriesForEventType(eventType).pipe(
            switchMap((eventSeries) => {
                if (eventSeries) {
                    return of(this.getRecurrenceForEventSeries(eventTypePreset, eventSeries, startDate, endDate));
                }

                return createRecurrence
                    ? this.createRecurrenceForEventTypePreset(eventTypePreset, eventType, startDate, endDate)
                    : of(undefined);
            }),
        ));
    }

    private getRecurrenceForEventSeries(eventTypePreset: EventTypePreset, eventSeries: EventSeries, startDate?: Date, endDate?: Date) {
        const scheduleDefaults = EventSeriesDefaults[eventTypePreset];
        // we only want to update the startDate if the eventSeries has not been saved yet
        if (startDate && (eventSeries.entityAspect.entityState.isAdded() || eventSeries.entityAspect.entityState.isModified())) {
            eventSeries.startDate = startDate;
            eventSeries.endDate = endDate ?? eventSeries.endDate;
            eventSeries.month = startDate.getMonth() + 1; // month is indexed from 1
        }
        return {
            eventTypePreset,
            eventSeries,
            config: {
                ...scheduleDefaults,
                originalEndDate: eventSeries.endDate,
            },
        } as IScheduledRecurrence;
    }

    private createRecurrenceForEventTypePreset(eventTypePreset: EventTypePreset, eventType: EventType, startDate: Date, endDate: Date) {
        const scheduleDefaults = EventSeriesDefaults[eventTypePreset];

        return this.createEventSeries(eventType, {
            month: startDate.getMonth() + 1, // month is indexed from 1
            ...scheduleDefaults.eventSeriesDefaults,
            weekIndex: scheduleDefaults.eventSeriesDefaults.weekIndex ?? EventSeriesWeekIndex.First,
            dayOfWeek: scheduleDefaults.eventSeriesDefaults.dayOfWeek ?? EventSeriesDayOfWeek.Monday,
            startDate,
            endDate,
        }).pipe(
            map((eventSeries) => ({
                eventTypePreset,
                eventSeries,
                config: {
                    ...scheduleDefaults,
                    originalEndDate: endDate,
                },
            } as IScheduledRecurrence)),
        );
    }

    private createEventSeries(
        eventType: EventType,
        options?: Partial<EventSeries>,
    ) {
        return this.commonDataService.create(EventSeriesBreezeModel, {
            eventType,
            ...(options ?? {}),
        });
    }

    public async hasConfiguredCadence(team?: Team) {
        const cadenceCycle = await lastValueFrom(this.getEventCadenceCycle());
        if (!team || !cadenceCycle) {
            return false;
        }

        // we have a configured cadence if any of the events are not completed
        const currentEventSeries = await this.getCurrentEventSeriesMapForPresetsWithinTeam(AllEventTypePresets, team);
        return Array.from(currentEventSeries.values())
            .some((eventSeries) => !eventSeries.extensions.isCompleted);
    }

    public async promiseToClearCadence(eventTypePresets: EventTypePreset[]) {
        const team = await this.teamsService.promiseToGetLeadershipTeam();
        const cadenceCycle = await lastValueFrom(this.getEventCadenceCycle());
        if (!team || !cadenceCycle) {
            return [];
        }

        const modifiedEntities: IBreezeEntity[] = [];
        const currentEventSeries = await this.getCurrentEventSeriesMapForPresetsWithinTeam(eventTypePresets, team);
        for (const eventSeries of Array.from(currentEventSeries.values())) {
            // need to make a copy as we are deleting within a loop (original array will change)
            for (const meeting of Array.from(eventSeries.meetings)) {
                // only remove meetings which have not started
                if (meeting.extensions.isNotStarted) {
                    await lastValueFrom(this.commonDataService.remove(meeting));
                    modifiedEntities.push(meeting);
                }
            }

            // end the series
            eventSeries.endDate = new Date();
            modifiedEntities.push(eventSeries);
        }

        return modifiedEntities;
    }

    public async promiseToUpdateEventSeriesOnCycleChange(runData: ISetCadenceRunData, skipPreset?: EventTypePreset) {
        const presets = Array.from(runData.scheduledPresets.values())
            .filter(({ eventTypePreset }) => eventTypePreset !== skipPreset);

        const nextCycleStartDate = runData.eventCadenceCycle.extensions.nextCycleStartDate;

        const removePromises = presets.map(async (config) => {
            const deletedEntities: IBreezeEntity[] = [];

            // need to make a copy as we are deleting within a loop (original array will change)
            for (const meeting of Array.from(config.eventSeries.meetings)) {
                if (meeting.extensions.isNotStarted && moment(meeting.meetingDateTime).isAfter(nextCycleStartDate)) {
                    await lastValueFrom(this.commonDataService.remove(meeting));
                    deletedEntities.push(meeting);
                }
            }

            // store so we can access later if the eventSeries is deleted
            const eventType = config.eventSeries.eventType;
            if (config.eventSeries.meetings.length === 0) {
                // delete the eventSeries because there are no meetings left
                await lastValueFrom(this.commonDataService.remove(config.eventSeries));
                deletedEntities.push(config.eventSeries);
            } else {
                // update the eventSeries endDates to match with the cycle end date
                config.eventSeries.endDate = moment(nextCycleStartDate)
                    .add(-1, "day")
                    .toDate();
            }

            // update the AS event straight away to match the new cadence cycle
            if (config.eventTypePreset === EventTypePreset.AnnualStrategy) {
                // copy over the new calculated dates from a new AS config
                const newRunData = await this.getCadenceRunData([EventTypePreset.AnnualStrategy], eventType.team!, runData.eventCadenceCycle, false);
                const recurrence = newRunData.scheduledPresets.get(EventTypePreset.AnnualStrategy);
                if (recurrence) {
                    config.eventSeries.startDate = recurrence.eventSeries.startDate;
                    config.eventSeries.endDate = recurrence.eventSeries.endDate;
                    config.eventSeries.month = recurrence.eventSeries.month;
                }

                // this will cause the meeting for AS to be updated with the new config
                await this.promiseToCreateOrUpdateMeetingsForSchedule(config, []);
            }

            config.deletedEntities = (config.deletedEntities ?? []).concat(deletedEntities);
            return config;
        });
        const configs = await Promise.all(removePromises);

        // remove the deleted configs from the workflow runData, will be recreated when going next step
        configs.forEach((config) => {
            if (config.eventSeries.entityAspect.entityState.isDeleted()
                || config.eventSeries.entityAspect.entityState.isDetached()) {
                // need to store the deletedEntities in the runData else we'll lose references to them after deleting the preset
                runData.deletedEntities = runData.deletedEntities.concat(config.deletedEntities ?? []);
                runData.scheduledPresets.delete(config.eventTypePreset!);
            }
        });
    }

    public async promiseToCreateOrUpdateMeetingsForSchedule(recurrence: IScheduledRecurrence, potentialConflicts: Meeting[]) {
        const { eventSeries } = recurrence;

        // eventSeries didn't change, don't modify events
        if (eventSeries.entityAspect.entityState.isUnchanged()) {
            return eventSeries;
        }

        const nextTimes = eventSeries.extensions.getNextTimes(eventSeries.startDate, eventSeries.endDate)
            .filter((nextDate) => {
                const hasConflict = recurrence.config.conflictingEventTypeCheck
                    ? recurrence.config.conflictingEventTypeCheck(nextDate, potentialConflicts)
                    : potentialConflicts.some(({ meetingDateTime }) => moment(meetingDateTime).isSame(nextDate, "month"));
                return !hasConflict;
            });

        let allMeetingsBySequenceId = this.getEventSeriesMeetingsSequenceIdMap(eventSeries);

        // if any date in next times does not exist in the current meetings
        const datesChanged = allMeetingsBySequenceId.size !== nextTimes.length
            || nextTimes.some((date, idx) => {
                const meeting = allMeetingsBySequenceId.get(idx);
                return meeting?.meetingDateTime.getTime() !== date.getTime();
            });

        // dates haven't changed so no work to do here
        if (!datesChanged) {
            return eventSeries;
        }

        // clear any pending changes for the meetings
        await lastValueFrom(this.commonDataService.rejectChanges(eventSeries.meetings));

        if (eventSeries.entityAspect.entityState.isAdded()) {
            // and then create the meetings again
            for (const [sequenceId, date] of nextTimes.entries()) {
                await lastValueFrom(this.createMeeting(eventSeries, date, sequenceId));
            }
        } else if (eventSeries.entityAspect.entityState.isModified()) {
            const removedEntities: IBreezeEntity[] = [];

            allMeetingsBySequenceId = this.getEventSeriesMeetingsSequenceIdMap(eventSeries);
            const times = Array.from(nextTimes.entries());
            const lastSequenceId = eventSeries.lastSequenceId ?? -1;

            // Math.max so that we iterate for all expected meetings and all existing meetings
            // so we can delete any meetings that don't have scheduled times
            // and create any meetings that have scheduled times but don't exist
            for (let idx = 0; idx < Math.max(times.length, allMeetingsBySequenceId.size); idx++) {
                const meeting = allMeetingsBySequenceId.get(idx);
                const [timeSequenceId, nextTime] = times[idx] ?? [undefined, undefined];

                if (meeting) {
                    // only modify if the meeting is not started and it has future sequenceId
                    // or if there is no nextSequenceId, the meeting should be deleted
                    const isFutureSequenceId = timeSequenceId === undefined || timeSequenceId > lastSequenceId;
                    if (meeting.extensions.isNotStarted && isFutureSequenceId) {
                        if (nextTime !== undefined && timeSequenceId !== undefined) {
                            this.updateMeetingFromEventSeries(meeting, eventSeries, timeSequenceId, nextTime);
                        } else {
                            // no time for this sequenceId, delete the meeting
                            await lastValueFrom(this.commonDataService.remove(meeting));
                            removedEntities.push(meeting);
                        }
                    }
                } else if (nextTime !== undefined) {
                    // no meeting for this time, create it
                    await lastValueFrom(this.createMeeting(eventSeries, nextTime, timeSequenceId));
                }
            }

            // track deletedEntities in the recurrence so we can save them
            recurrence.deletedEntities = (recurrence.deletedEntities ?? []).concat(removedEntities);
        }

        return eventSeries;
    }

    private getEventSeriesMeetingsSequenceIdMap(eventSeries: EventSeries) {
        const meetingsBySequenceId = new Map<number, Meeting>();
        for (const meeting of eventSeries.extensions.getSortedMeetings()) {
            const customData = meeting.extensions.getCustomData<IMeetingCustomData>();
            if (customData.seriesSequenceId !== undefined) {
                meetingsBySequenceId.set(customData.seriesSequenceId, meeting);
            }
        }

        return meetingsBySequenceId;
    }

    private createMeeting(eventSeries: EventSeries, startDate: Date, sequenceId: number) {
        // TODO: handle no team id (not possible for now)
        return this.meetingsService.createMeeting(eventSeries.eventType.teamId!).pipe(
            map((meeting) => this.updateMeetingFromEventSeries(meeting, eventSeries, sequenceId, startDate)),
        );
    }

    private updateMeetingFromEventSeries(meeting: Meeting, eventSeries: EventSeries, sequenceId: number, startDate?: Date) {
        meeting.name = eventSeries.eventType.meetingAgendaTemplate?.name ?? eventSeries.eventType.name;
        meeting.eventSeries = eventSeries;
        meeting.eventSeriesId = eventSeries.eventSeriesId;
        meeting.location = eventSeries.location;

        const customData = meeting.extensions.getCustomData<IMeetingCustomData>();
        customData.seriesSequenceId = sequenceId;
        meeting.extensions.updateCustomData(customData);

        if (startDate) {
            meeting.meetingDateTime = startDate;
            meeting.endTime = moment(meeting.meetingDateTime)
                .add(eventSeries.eventType.durationInMinutes, "minutes")
                .toDate();
        }

        return meeting;
    }

    private getTeamEventTypesEncompassingKey(teamId: number) {
        return `eventTypesForTeamId=${teamId}`;
    }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { DxDataGridModule } from "devextreme-angular";
import { AdaptButtonModule } from "../ux/button/button.module";
import { RatingStarsModule } from "../ux/rating-stars/rating-stars.module";
import { AdaptTooltipModule } from "../ux/tooltip.directive/tooltip.module";
import { CoachSessionsGridComponent } from "./coach-sessions-grid/coach-sessions-grid.component";


@NgModule({
    imports: [
        CommonModule,

        AdaptButtonModule,
        AdaptTooltipModule,

        DxDataGridModule,
        RatingStarsModule,
    ],
    declarations: [
        CoachSessionsGridComponent,
    ],
    exports: [
        CoachSessionsGridComponent,
    ],
})
export class AdaptCoachAccessSharedModule {}

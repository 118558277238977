import { Component, OnInit } from "@angular/core";
import { InputTypeMetadata, InputTypeMetadataClass } from "@common/ADAPT.Common.Model/organisation/input-type-metadata";
import { IAdaptLinkObject } from "@common/route/route.service";
import { SearchService } from "@org-common/lib/search/search.service";
import { SearchRowBaseComponent } from "@org-common/lib/search/search-row-base.component";
import moment from "moment/moment";
import { Observable } from "rxjs";
import { InputsCanvasIdSearchParam, StrategicInputsPageRoute } from "../../strategic-inputs/strategic-inputs-page/strategic-inputs-page.component";
import { IStrengthWeaknessTrendSearchResult } from "../search-results.interface";

@Component({
    selector: "adapt-search-row-strength-weakness-trend",
    templateUrl: "./search-row-strength-weakness-trend.component.html",
})
export class SearchRowStrengthWeaknessTrendComponent extends SearchRowBaseComponent<IStrengthWeaknessTrendSearchResult> implements OnInit {
    public href$?: Observable<IAdaptLinkObject>;

    public inputTypeMetadata?: InputTypeMetadataClass;
    public canvasName?: string;

    public constructor(
        protected searchService: SearchService,
    ) {
        super(searchService);
    }

    public ngOnInit() {
        super.ngOnInit();

        this.href$ = StrategicInputsPageRoute.getRouteObject(undefined, {
            [InputsCanvasIdSearchParam]: this.result.canvasId,
        });

        this.inputTypeMetadata = InputTypeMetadata[this.result.inputType];
        this.canvasName = moment(this.result.canvasDate).format("DD/MM/YYYY HH:mm:ss");
        this.matches = this.excludeNameBreadcrumb(this.result.results);
    }
}

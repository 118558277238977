export class SetUtilities {
    /**
     * Determines if the given set is a superset of the given subset.
     * @param set set which is checked if superset of set
     * @param subset subset to check if within set
     */
    public static isSuperset(set: Set<any>, subset: Set<any>) {
        for (const elem of subset) {
            if (!set.has(elem)) {
                return false;
            }
        }
        return true;
    }
}
<div #meetingTabContent
     *ngIf="meeting$ | async as meeting"
     class="d-flex flex-column meeting-tab-content"
     data-tour="meeting-tab-content">
    <div>
        <button adaptButton="iconlessLink"
                [adaptBlockingClick]="gotoMeetingPage"
                [adaptBlockingClickParam]="meeting">
            <h2 class="d-inline">{{meeting!.name}}</h2>
        </button>
    </div>
    <div class="my-2 meeting-started-at">Started at: {{meeting!.extensions.startTime | adaptDateTime }}</div>
    <dx-scroll-view class="ps-0">
        <adapt-meeting-sidebar-agenda [meeting]="meeting"></adapt-meeting-sidebar-agenda>
        <div class="d-flex mt-2 align-items-center"
             *ngIf="meeting.team"> <!-- meeting can be detached and team becomes null, which will cause error in the auth check below -->
            <button *ngIf="canEditTeamMeeting"
                    adaptButton="editLink"
                    class="btn btn-blank edit-agenda-btn"
                    [adaptBlockingClick]="editMeetingAgenda"
                    [adaptBlockingClickParam]="meeting"
                    data-test="edit-agenda-button">Edit agenda</button>
            <a class="ms-auto team-work-btn"
               *adaptIfAuthorised="ViewTeamKanban;Entity:meeting.team"
               [routerLink]="teamWorkUrl$ | async"><i class="fal fa-columns"></i> Team actions</a>
        </div>
    </dx-scroll-view>
    <div class="mt-auto d-flex flex-row bottom-panel">
        <div class="align-self-center">
            <i class="fal fa-clock me-1"></i>
            <span>{{meeting.meetingDateTime | timeFromTimer$ | async}}</span>
        </div>
        <div class="live-meeting-control-buttons ms-auto">
            <button class="align-self-center btn btn-primary btn-move-meeting-to-page"
                    adaptTooltip="Move meeting to page"
                    adaptButtonIcon="fal fa-chevron-right"
                    [adaptBlockingClick]="moveToActiveMeetingPage"
                    [adaptBlockingClickParam]="meeting"
                    [unsubscribeOnDestroy]="false"
                    data-tour="move-meeting-button"
                    data-test="move-meeting-button"></button>
            <button *ngIf="canEditTeamMeeting"
                    class="align-self-center btn btn-primary btn-reschedule-meeting"
                    adaptTooltip="Reschedule meeting"
                    adaptButtonIcon="fal fa-sync-alt"
                    [adaptBlockingClick]="rescheduleMeeting"
                    [adaptBlockingClickParam]="meeting"
                    [unsubscribeOnDestroy]="false"
                    data-test="reschedule-meeting-button"></button>
            <button *ngIf="canEditTeamMeeting"
                    class="align-self-center btn btn-danger"
                    adaptButtonIcon="fal fa-stop-circle"
                    adaptTooltip="End meeting"
                    [adaptBlockingClick]="endMeeting"
                    [adaptBlockingClickParam]="meeting"
                    [unsubscribeOnDestroy]="false"
                    data-test="stop-meeting-button">End meeting</button>
        </div>
    </div>
</div>

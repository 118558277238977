<adapt-theme-test-container label="Fonts and Colors">
    <div>
        <h4>Zone Colours / Icons</h4>
        <div class="row">
            <div class="col-lg-6">
                <adapt-colour-swatch variable="zone-organisation-design"
                                     [variants]="[50, 100, 200]"
                                     [isPercentage]="false">
                    <h5><i class="fal fa-pen-ruler"></i> Organisation design</h5>
                    <ng-container swatch-inner-content>
                        <i class="fal fa-pen-ruler"></i>&nbsp;fa-pencil-ruler
                    </ng-container>
                </adapt-colour-swatch>
            </div>
            <div class="col-lg-6">
                <adapt-colour-swatch variable="zone-economic-engine"
                                     [variants]="[50, 100, 200]"
                                     [isPercentage]="false">
                    <h5><i class="fal fa-train-subway"></i> Economic engine</h5>
                    <ng-container swatch-inner-content>
                        <i class="fal fa-train-subway"></i>&nbsp;fa-subway
                    </ng-container>
                </adapt-colour-swatch>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <adapt-colour-swatch variable="zone-research-development"
                                     [variants]="[50, 100, 200]"
                                     [isPercentage]="false">
                    <h5><i class="fal fa-magnifying-glass-dollar"></i> Research &amp; development</h5>
                    <ng-container swatch-inner-content>
                        <i class="fal fa-magnifying-glass-dollar"></i>&nbsp;fa-search-dollar
                    </ng-container>
                </adapt-colour-swatch>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <adapt-colour-swatch variable="zone-healthy-culture"
                                     [variants]="[50, 100, 200]"
                                     [isPercentage]="false">
                    <h5><i class="fal fa-leaf"></i> Leadership &amp; culture</h5>
                    <ng-container swatch-inner-content>
                        <i class="fal fa-leaf"></i>&nbsp;fa-leaf
                    </ng-container>
                </adapt-colour-swatch>
            </div>
            <div class="col-lg-6">
                <adapt-colour-swatch variable="zone-finance-ownership"
                                     [variants]="[50, 100, 200]"
                                     [isPercentage]="false">
                    <h5><i class="fal fa-circle-dollar"></i> Finance &amp; ownership</h5>
                    <ng-container swatch-inner-content>
                        <i class="fal fa-circle-dollar"></i>&nbsp;fa-usd-circle
                    </ng-container>
                </adapt-colour-swatch>
            </div>
            <div class="col-lg-6">
                <adapt-colour-swatch variable="zone-global">
                    <h5>Global</h5>
                </adapt-colour-swatch>
            </div>
        </div>
    </div>

    <div>
        <h3>
            Colours
        </h3>
        <div>
            <h4>
                Health Status
            </h4>
            <div class="row">
                <adapt-colour-swatch variable="very-happy"
                                     class="col-lg-6">
                    <h5>Very happy</h5>
                </adapt-colour-swatch>

                <adapt-colour-swatch variable="happy"
                                     class="col-lg-6">
                    <h5>Happy</h5>
                </adapt-colour-swatch>
            </div>
            <div class="row">
                <adapt-colour-swatch variable="unhappy"
                                     class="col-lg-6">
                    <h5>Unhappy</h5>
                </adapt-colour-swatch>

                <adapt-colour-swatch variable="very-unhappy"
                                     class="col-lg-6">
                    <h5>Very unhappy</h5>
                </adapt-colour-swatch>
            </div>
        </div>
        <div>
            <h4>
                Network Health Status
            </h4>
            <div class="row">
                <adapt-colour-swatch variable="speed-catchup-green"
                                     class="col-lg-4">
                    <h5>Good Connection</h5>
                </adapt-colour-swatch>

                <adapt-colour-swatch variable="speed-catchup-orange"
                                     class="col-lg-4">
                    <h5>Minor Issues</h5>
                </adapt-colour-swatch>

                <adapt-colour-swatch variable="speed-catchup-red"
                                     class="col-lg-4">
                    <h5>Major Issues</h5>
                </adapt-colour-swatch>
            </div>
        </div>

        <div>
            <h4>
                Action status
            </h4>
            <div class="row">
                <adapt-colour-swatch variable="item-status-backlog"
                                     class="col-lg-4">
                    <h5>Action status 'Backlog'</h5>
                </adapt-colour-swatch>

                <adapt-colour-swatch variable="item-status-todo"
                                     class="col-lg-4">
                    <h5>Action status 'To do'</h5>
                </adapt-colour-swatch>

                <adapt-colour-swatch variable="item-status-inprogress"
                                     class="col-lg-4">
                    <h5>Action status 'In progress'</h5>
                </adapt-colour-swatch>
            </div>
            <div class="row">
                <adapt-colour-swatch variable="item-status-done"
                                     class="col-lg-4">
                    <h5>Action status 'Done'</h5>
                </adapt-colour-swatch>

                <adapt-colour-swatch variable="item-status-closed"
                                     class="col-lg-4">
                    <h5>Action status 'Closed'</h5>
                </adapt-colour-swatch>
            </div>
        </div>
        <div>
            <h4>Objective Status</h4>
            <div class="row">
                <adapt-colour-swatch variable="objective-status-on-track"
                                     class="col-lg-6">
                    <h5>Objective Status On Track</h5>
                </adapt-colour-swatch>

                <adapt-colour-swatch variable="objective-status-slow"
                                     class="col-lg-6">
                    <h5>Objective Status Slow</h5>
                </adapt-colour-swatch>

                <adapt-colour-swatch variable="objective-status-stuck"
                                     class="col-lg-6">
                    <h5>Objective Status Stuck</h5>
                </adapt-colour-swatch>

                <adapt-colour-swatch variable="objective-status-closed"
                                     class="col-lg-6">
                    <h5>Objective Status Closed</h5>
                </adapt-colour-swatch>
            </div>
        </div>
    </div>
    <div>
        <h4>Architecture</h4>
        <div class="row">
            <adapt-colour-swatch variable="step-type-process-step"
                                 [variants]="[40]"
                                 class="col-lg-4">
                <h5>Process Step</h5>
            </adapt-colour-swatch>

            <adapt-colour-swatch variable="step-type-role-task"
                                 [variants]="[40]"
                                 class="col-lg-4">
                <h5>Role Task</h5>
            </adapt-colour-swatch>


            <adapt-colour-swatch variable="step-type-process-map-link"
                                 [variants]="[40]"
                                 class="col-lg-4">
                <h5>Task Map Link</h5>
            </adapt-colour-swatch>
        </div>
    </div>
    <div>
        <h4>Systemisation</h4>
        <div class="row">
            <adapt-colour-swatch variable="system-color"
                                 [variants]="[40]"
                                 class="col-lg-4">
                <h5>Task Map Link</h5>
            </adapt-colour-swatch>
        </div>
    </div>
</adapt-theme-test-container>

import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Organisation } from "./organisation";

export class ProcessMap extends BaseEntity<ProcessMap> {
    public static readonly IconClass = "fal fa-fw fa-sitemap";

    public processMapId!: number;
    public organisationId!: number;
    public name!: string;

    public organisation!: Organisation | null;
}

export const ProcessMapBreezeModel = new BreezeModelBuilder("ProcessMap", ProcessMap)
    .hasSource()
    .isOrganisationEntity()
    .persistChangedEntity()
    .withIdField()
    .build();

import { NgClass } from "@angular/common";
import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, Self } from "@angular/core";
import { IAdaptMenuItem } from "@common/ux/menu/menu.component";
import isEqual from "lodash.isequal";
import { merge, timer } from "rxjs";
import { switchMap, take } from "rxjs/operators";
import { ColumnSize, ColumnSizePresets, ComponentSizePresets, ILayoutColumn, ILayoutComponent } from "../layout.interface";
import { LayoutService } from "../layout.service";
import { LayoutBaseComponent } from "../layout-base.component";
import { LayoutManagerComponent } from "../layout-manager/layout-manager.component";

const DefaultWidthPreset = "Default Width";

@Component({
    selector: "adapt-layout-component",
    templateUrl: "./layout-component.component.html",
    styleUrls: ["./layout-component.component.scss"],
    providers: [NgClass],
})
export class LayoutComponentComponent extends LayoutBaseComponent<ILayoutComponent> implements OnInit, OnChanges, OnDestroy {
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("id") public componentId!: string;

    public layoutComponent!: ILayoutComponent;
    public layoutColumn!: ILayoutColumn;
    public menuItems!: IAdaptMenuItem[];

    constructor(
        @Self() protected ngClass: NgClass,
        protected elementRef: ElementRef<HTMLElement>,
        protected layoutManager: LayoutManagerComponent,
    ) {
        super(ngClass, elementRef, layoutManager);

        merge(
            this.layoutManager.initialised,
            this.layoutManager.layoutUpdated$,
        ).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            const { component, column } = LayoutService.findComponentInLayout(this.layoutManager.layout.columns, this.componentId);
            if (component && column) {
                this.layoutComponent = component;
                this.layoutColumn = column;
            }

            this.ngOnChanges();
        });
    }

    public get layoutEntity() {
        return this.layoutComponent;
    }

    public get componentHidden() {
        return this.layoutEntity?.options?.userHidden ?? false;
    }

    public get isFirstComponentInColumn() {
        if (this.layoutEntity && this.layoutColumn) {
            const withoutHidden = this.layoutColumn.options.components.filter((cmp) => cmp.options?.enabled ?? true);
            return withoutHidden.indexOf(this.layoutEntity) === 0;
        }
        return false;
    }

    public get isLastComponentInColumn() {
        if (this.layoutEntity && this.layoutColumn) {
            const withoutHidden = this.layoutColumn.options.components.filter((cmp) => cmp.options?.enabled ?? true);
            return withoutHidden.indexOf(this.layoutEntity) === withoutHidden.length - 1;
        }
        return false;
    }

    public get inSmallColumn() {
        // couldn't find a matching column at this point (probably not initialised fully at this moment...)
        if (!this.layoutColumn) {
            return false;
        }

        const columnIdx = this.layoutManager.layout.columns.indexOf(this.layoutColumn);
        const colSizePreset = Object.keys(ColumnSizePresets).find((colPreset: ColumnSize) =>
            isEqual(this.layoutColumn.size, ColumnSizePresets[colPreset][columnIdx])) as ColumnSize | undefined;

        // couldn't find a matching preset
        if (!colSizePreset) {
            return false;
        }

        const preset = ColumnSizePresets[colSizePreset];

        // get the correct small column index for the given column size type
        const columnIdxMapping: Partial<Record<ColumnSize, number>> = { [ColumnSize.NarrowLeftHand]: 0, [ColumnSize.NarrowRightHand]: 1 };

        // if column is not one of the narrow presets we are not in a small column
        if (!Object.keys(columnIdxMapping).includes(colSizePreset)) {
            return false;
        }

        return preset[columnIdxMapping[colSizePreset]!] === this.layoutColumn.size;
    }

    public ngOnInit() {
        this.layoutComponent = this.layoutManager.getComponent(
            this.componentId,
            this,
            this.elementRef.nativeElement,
            this.defaultSize,
        );

        this.elementRef.nativeElement.id = this.layoutEntity.id;

        this.ngOnChanges();
    }

    public ngOnDestroy() {
        super.ngOnDestroy();

        if (this.layoutEntity) {
            this.layoutManager.removeComponent(this.layoutEntity);
        }
    }

    public ngOnChanges() {
        const presets: { [k: string]: string[] } = { ...ComponentSizePresets };

        // no matching size, add the stored default as a preset
        if (this.defaultSize && !Object.values(presets).some((sizes) => sizes.includes(this.defaultSize!))) {
            presets[DefaultWidthPreset] = [this.defaultSize];
        }

        const inSmallColumn = this.inSmallColumn;

        this.menuItems = [
            {
                text: "",
                icon: "fal fa-fw fa-columns",
                items: Object.entries(presets).map(([text, size], index) => ({
                    text,
                    icon: "",
                    onClick: () => this.updateSize(size[0]),
                    isChecked: size.includes(this.layoutEntity?.size ?? ""),
                    // disable if column too small for component size
                    disabled: index > 2 && inSmallColumn,
                })) as IAdaptMenuItem[],
            },
        ];

        super.ngOnChanges();
    }

    public moveComponent(change: number) {
        if (this.layoutEntity && this.layoutColumn) {
            const components = this.layoutColumn.options.components;
            let desiredIndex = components.indexOf(this.layoutEntity) + change;

            const rows = LayoutService.getColumnRows(components);
            const currentRow = rows.findIndex((row) => row.includes(this.layoutEntity));
            const desiredRow = currentRow + change;

            if (desiredRow < 0 || desiredRow >= rows.length) {
                // move within row since can't move out of the row
                while (components[desiredIndex].options?.enabled === false) {
                    desiredIndex += change;

                    if (desiredIndex < 0) {
                        desiredIndex = 0;
                        break;
                    } else if (desiredIndex > components.length - 1) {
                        desiredIndex = components.length - 1;
                        break;
                    }
                }
            } else {
                const isFullWidth = rows[currentRow].length === 1;
                const rowComponents = rows[desiredRow];

                let targetComponent = rowComponents[0];
                if (isFullWidth && rowComponents.length !== 1 && change > 0) {
                    // this component only fit in a single row, and the desired row has multiple components, so move it a full row...
                    // we only need this logic if moving downwards. inserts after the last component in the desired row
                    targetComponent = rowComponents[rowComponents.length - 1];
                }

                desiredIndex = components.findIndex((cmp) => cmp === targetComponent);
            }

            this.layoutManager.moveComponent(this.layoutEntity, this.layoutColumn, this.layoutColumn, desiredIndex);

            // move to component once the layout has updated
            this.layoutManager.layoutUpdated$.pipe(
                take(1),
                switchMap(() => timer(100)),
            ).subscribe(() => {
                this.elementRef.nativeElement.scrollIntoView({ behavior: "smooth" });
            });

            this.layoutManager.updateLayout();
        }
    }

    public toggleHideComponent() {
        LayoutService.setOptions(this.layoutEntity, { userHidden: !this.componentHidden || undefined });
        this.layoutManager.updateLayout();
    }

    private updateSize(size?: string) {
        LayoutService.setSize(this.layoutEntity, size);
        this.layoutManager.updateLayout();
    }
}

import { ComponentFactoryResolver, ComponentRef, Directive, EmbeddedViewRef, Input, OnChanges, Renderer2, TemplateRef, ViewContainerRef } from "@angular/core";
import { LoadingSpinnerComponent } from "./loading-spinner.component";

@Directive({
    selector: "[adaptLoading]",
})
export class LoadingDirective implements OnChanges {
    @Input() public adaptLoading: boolean = true;
    @Input("adaptLoadingDestroy") public destroy = true;

    private embeddedViewRef?: EmbeddedViewRef<unknown>;
    private spinner?: ComponentRef<LoadingSpinnerComponent>;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        private renderer: Renderer2,
    ) {
    }

    public ngOnChanges() {
        this.embeddedViewRef = this.embeddedViewRef || this.viewContainer.createEmbeddedView(this.templateRef);

        if (this.adaptLoading) {
            if (this.destroy) {
                // destroy view container
                this.viewContainer.clear();
            } else {
                // hide each child
                for (const node of this.embeddedViewRef.rootNodes) {
                    this.renderer.setStyle(node, "display", "none");
                }
            }

            // create spinner
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(LoadingSpinnerComponent);
            this.spinner = this.viewContainer.createComponent(componentFactory);
        } else {
            if (this.destroy) {
                // clear and recreate view
                this.viewContainer.clear();
                this.viewContainer.createEmbeddedView(this.templateRef);
            } else {
                // destroy spinner
                this.spinner?.destroy();

                // unhide each child
                for (const node of this.embeddedViewRef.rootNodes) {
                    this.renderer.removeStyle(node, "display");
                }
            }
        }
    }
}

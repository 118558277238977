import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";

export class SelectPersonUtilities {
    public static postProcessPeopleDataSource(data: any[]) {
        // add group data
        for (const row of data) {
            if (row.key === ConnectionType.Employee) {
                row.sortOrder = 1;
            } else if (row.key === ConnectionType.Stakeholder) {
                row.sortOrder = 2;
            } else if (row.key === ConnectionType.Coach) {
                row.sortOrder = 3;
            } else {
                row.sortOrder = 0;
                row.iconClass = "fal fa-users";
            }
        }

        return data.sort((group1, group2) => group1.sortOrder - group2.sortOrder);
    }

    public static getPersonGroup(person: Person, teamGroup?: Team, activeOnly = true) {
        const latestConnection = person.getLatestConnection();
        if (!latestConnection) {
            return "Others"; // will land here if you are a stakeholder without latestConnection
        }

        if (teamGroup) {
            const teamConnection = latestConnection.roleConnections
                .filter((rc) => !activeOnly || rc.isActive())
                .find((rc) => rc.teamId === teamGroup.teamId);
            if (teamConnection) {
                return teamGroup.name;
            }
        }

        return latestConnection.connectionType;
    }
}

import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { EntityExtensions } from "../entity-extensions.decorator";
import { Diagram } from "./diagram";
import { ProcessMap } from "./process-map";
import { SystemComponentExtensions } from "./system-component-extensions";
import { SystemComponentSupplementaryData } from "./system-component-supplementary-data";
import { SystemDocument } from "./system-document";
import { SystemEntity } from "./system-entity";

export enum DocumentSize {
    Small = "Small",
    Medium = "Medium",
    Large = "Large",
    ExtraLarge = "ExtraLarge",
}

export class SystemComponent extends BaseEntity<SystemComponent> {
    public systemComponentId!: number;
    public systemEntityId!: number;
    public name!: string;
    public systemProcessMapId?: number;
    public systemDocumentId?: number;
    public systemDiagramId?: number;
    public size!: DocumentSize;

    // navigation
    public system!: SystemEntity;
    public systemProcessMap?: ProcessMap;
    public systemDocument?: SystemDocument;
    public systemDiagram?: Diagram;
    public supplementaryData?: SystemComponentSupplementaryData;

    @EntityExtensions(SystemComponentExtensions)
    public extensions!: SystemComponentExtensions;
}

export const SystemComponentBreezeModel = new BreezeModelBuilder("SystemComponent", SystemComponent)
    .hasSource()
    .withIdField("systemComponentId")
    .withLabelField("name")
    // need these navProperties for entity sync auto-updater
    .alwaysFetchingNavigationProperty("systemDiagram")
    .alwaysFetchingNavigationProperty("systemDocument")
    .alwaysFetchingNavigationProperty("systemProcessMap")
    .isOrganisationEntity()
    .persistChangedEntity()
    .build();

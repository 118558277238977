import { Component, Injector, OnInit } from "@angular/core";
import { ReleaseNotifierService } from "@common/lib/release-notifier/release-notifier.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";

@Component({
    selector: "adapt-debug-page",
    templateUrl: "./debug-page.component.html",
    styleUrls: [],
})
export class DebugPageComponent extends BaseRoutedComponent implements OnInit {
    constructor(
        injector: Injector,
        public releaseNotifierService: ReleaseNotifierService,
    ) {
        super(injector);
    }

    public ngOnInit() {
        this.notifyActivated();
    }

    public loggingTest(): void {
        this.log.debug("Logging Test: A debug message");
        this.log.info("Logging Test: A info message");
        this.log.warn("Logging Test: A warning message");
        this.log.error("Logging Test: A error message");
        this.log.log("Logging Test: A log message");
        this.log.success("Logging Test: A success message");

        throw new Error("Logging Test: Throwing an exception");
    }
}

import { Component, Inject, Injector } from "@angular/core";
import { MeetingAgendaItem } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { MeetingAgendaTemplate } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-template";
import { PersistableDialog } from "@common/lib/data/persistable-dialog.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";

@Component({
    selector: "adapt-edit-agenda-template-dialog",
    templateUrl: "./edit-agenda-template-dialog.component.html",
})
@PersistableDialog("EditAgendaTemplateDialog")
export class EditAgendaTemplateDialogComponent extends BaseDialogWithDiscardConfirmationComponent<MeetingAgendaTemplate> {
    public readonly dialogName = "EditAgendaTemplateDialog";

    public agendaItems: MeetingAgendaItem[] = [];

    public constructor(
        injector: Injector,
        @Inject(ADAPT_DIALOG_DATA) public template: MeetingAgendaTemplate,
    ) {
        super(injector);
        this.autoResolveData = template;
    }

    public get isNew() {
        return this.template?.entityAspect.entityState.isAdded();
    }

    public get isSystem() {
        return !!this.template?.code;
    }

    public get hasAgendaItems() {
        // don't include removed agenda items in the total
        return this.agendaItems.filter((a) => !a.entityAspect.entityState.isDeleted() && !a.entityAspect.entityState.isDetached()).length > 0;
    }

    public get entitiesToConfirm() {
        return [
            ...this.agendaItems,
            ...this.agendaItems
                .filter((i) => !!i.supplementaryData)
                .map((i) => i.supplementaryData!),
            this.template,
        ];
    }
}

import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Organisation } from "./organisation";
import { RoleTypeCode } from "./role-type-code";

export class RoleType extends BaseEntity<RoleType> {
    public roleTypeId!: number;
    public organisationId!: number;
    public code!: RoleTypeCode;
    public defaultLabel!: string;
    public defaultLabelPlural!: string;

    public organisation!: Organisation;
}

export const RoleTypeBreezeModel = new BreezeModelBuilder("RoleType", RoleType)
    .hasSource()
    .isOrganisationEntity()
    .build();

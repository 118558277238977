import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { ISeed } from "../../shared/define-seed/seed.interface";

export enum PurposeSeed {
    StartBusiness = "StartBusiness",
    WhatsImportant = "WhatsImportant",
    Background = "Background",
    StrengthsSkills = "StrengthsSkills",
}

export const PurposeSeeds: ISeed<PurposeSeed>[] = [
    {
        key: PurposeSeed.StartBusiness,
        question: "Why did you start this business?",
        articleSlug: ImplementationKitArticle.DefineOwnersWhyStartBusiness,
        prefillArticleSlug: ImplementationKitArticle.DefineOwnersWhyStartBusinessPrefill,
    },
    {
        key: PurposeSeed.Background,
        question: "Your background",
        articleSlug: ImplementationKitArticle.DefineOwnersWhyBackground,
    },
    {
        key: PurposeSeed.WhatsImportant,
        question: "What's important to you?",
        articleSlug: ImplementationKitArticle.DefineOwnersWhyWhatsImportant,
    },
    {
        key: PurposeSeed.StrengthsSkills,
        question: "Your unique strengths and skills",
        articleSlug: ImplementationKitArticle.DefineOwnersWhyStrengthsSkills,
    },
];

import { Injectable, Injector } from "@angular/core";

export type IEditorViewCallback = (injector: Injector, editorElement: HTMLElement) => any;

@Injectable({
    providedIn: "root",
})
export class HtmlEditorService {
    private froalaActionCallbacks: VoidFunction[] = [];
    private froalaViewCallbacks: IEditorViewCallback[] = [];

    public constructor(private injector: Injector) {}

    /**
     * Register a custom action (toolbar button) that will be used when Froala is eventually initialised.
     * @param callback The callback function that will build the Froala action.
     */
    public registerEditorActionCallback(callback: VoidFunction) {
        this.froalaActionCallbacks.push(callback);
    }

    public registerEditorViewCallback(callback: IEditorViewCallback) {
        this.froalaViewCallbacks.push(callback);
    }

    /**
     * Called by the froala integration service once only to register any callbacks into Froala
     */
    public initialiseRegisteredActionCallbacks() {
        this.froalaActionCallbacks.forEach((service) => service());
    }

    public initialiseRegisteredViewCallbacks(editorElement: HTMLElement) {
        this.froalaViewCallbacks.forEach((callback) => callback(this.injector, editorElement));
    }
}

import { SurveyTypeLabel } from "@common/ADAPT.Common.Model/organisation/survey";
import { DefaultNextButtons, GuidedTour } from "@common/lib/guided-tour/guided-tour.service";
import { getHealthCheckPageTour } from "@org-common/lib/survey/health-check-tour";
import { CommonTourSteps } from "./common-tour-steps";

export const HealthCheckWrapUpTour: GuidedTour = {
    steps: [CommonTourSteps.openOrgMenuToShowSidebarNavNodeStep("The Health Check result", SurveyTypeLabel.OrganisationDiagnostic), {
        waitForAndAttachToElementSelector: "adapt-display-link-hierarchy adapt-display-navigation-node span",
        elementSelectorTextContent: SurveyTypeLabel.OrganisationDiagnostic, // looking for <adapt-display-navigation-node> with span content of this text
        // cannot use 'advanceOn' too as the selector is only accepting string selector - cannot pass in element like attachTo
        // and this is a dynamic node - cannot use [data-tour="identifier"] selector
        advanceOnAttachedElementEvent: "click",
        attachTo: {
            on: "bottom-end",
        },
        title: `Access Health Check`,
        text: `<p>The result of your health check survey is available here.</p>
        <p><b>Click on it now</b> to show the summary report.</p>`,
        modalOverlayOpeningPadding: 3,
    }, {
        title: "Survey summary page",
        text: "This is the health check survey summary page.",
        buttons: DefaultNextButtons,
    }, ...getHealthCheckPageTour(true).steps,
    ],
};



import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { EntityExtensions } from "../entity-extensions.decorator";
import { EventSeriesDayOfWeek } from "./event-series-day-of-week";
import { EventSeriesExtensions } from "./event-series-extensions";
import { EventSeriesWeekIndex } from "./event-series-week-index";
import { EventType } from "./event-type";
import { Meeting } from "./meeting";

export enum EventSeriesType {
    // Event repeats on the same day or days of the week, based on the number of weeks between each set of occurrences.
    Weekly = "Weekly",

    // Event repeats on the specified day or days of the week, in the same relative position in the month, based on the number of months between occurrences.
    RelativeMonthly = "RelativeMonthly",

    // Event repeats on the specified day or days of the week, in the same relative position in a specific month of the year, based on the number of years between occurrences.
    RelativeYearly = "RelativeYearly",

    // Event does not repeat.
    Once = "Once",
}

// remove breeze fields from settable fields
export type IEventSeriesFields = Omit<EventSeries, keyof BaseEntity<EventSeries> | "eventSeriesId" | "eventTypeId" | "eventType" | "extensions" | "meetings">;

export class EventSeries extends BaseEntity<EventSeries> {
    public eventSeriesId!: number;
    public eventTypeId!: number;

    public eventSeriesType!: EventSeriesType;

    // Day of the week on which the event occurs.
    // Required if EventSeriesType is Weekly, RelativeMonthly, or RelativeYearly.
    public dayOfWeek?: EventSeriesDayOfWeek;
    // Specifies on which instance of the allowed days specified in DaysOfWeek the event occurs, counted from the first instance in the month.
    // Default is First. Optional and used if EventSeriesType is RelativeMonthly or RelativeYearly.
    public weekIndex?: EventSeriesWeekIndex;
    // The number of units between occurrences, where units can be in days, weeks, months, or years, depending on the EventSeriesType. Required.
    public interval!: number;
    // The month in which the event occurs. This is a number from 1 to 12.
    public month?: number;

    public startDate!: Date;
    public endDate!: Date;
    public location?: string;
    public calendarIntegrationId?: string;
    public calendarIntegrationLocationId?: string;
    public lastSequenceId?: number;

    public eventType!: EventType;
    public meetings!: Meeting[];

    @EntityExtensions(EventSeriesExtensions)
    public extensions!: EventSeriesExtensions;
}

export const EventSeriesBreezeModel = new BreezeModelBuilder("EventSeries", EventSeries)
    .hasSource()
    .withIdField()
    .isOrganisationEntity()
    .withPluralName("Event Series")
    .build();

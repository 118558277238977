import { Component, Injector } from "@angular/core";
import { GuidingPhilosophy } from "@common/ADAPT.Common.Model/organisation/guiding-philosophy";
import { Purpose } from "@common/ADAPT.Common.Model/organisation/purpose";
import { ImplementationKitService } from "@common/implementation-kit/implementation-kit.service";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { CommonPurposeVisionService, SeedEntityConfigs } from "@org-common/lib/purpose-vision/common-purpose-vision.service";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";
import { BehaviorSubject, defer, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { ISeed, ISeedAnswers, ISeedStepData } from "./seed.interface";

@WorkflowStepComponent("adapt-define-seed")
@Component({
    selector: "adapt-define-seed",
    templateUrl: "./define-seed.component.html",
})
export class DefineSeedComponent<T extends string> extends WorkflowStepComponentAdapter {
    public isValid = new BehaviorSubject<boolean>(false);
    public workflowStepCompleted = this.isValid.asObservable();

    public selectedSeed?: ISeed<T>;
    public seedAnswers?: ISeedAnswers;

    private entityConfiguration?: SeedEntityConfigs;

    public constructor(
        injector: Injector,
        private commonDataService: CommonDataService,
        private commonPurposeVisionService: CommonPurposeVisionService,
        private implementationKitService: ImplementationKitService,
    ) {
        super();

        this.subscribeToEmitForEntityTypeChange(injector, GuidingPhilosophy);
        this.subscribeToEmitForEntityTypeChange(injector, Purpose);
    }

    public async workflowStepOnInit() {
        const stepData = this.workflowStep?.customData as ISeedStepData<T>;
        if (!stepData) {
            throw new Error("Seed data is not available");
        }

        this.seedAnswers = this.seedAnswers
            ?? Object.fromEntries(stepData.seeds.map((seed) => [seed.key, ""])) as ISeedAnswers;

        this.selectedSeed = stepData.seed;

        this.entityConfiguration = await this.commonPurposeVisionService.getSeedEntity(stepData.entity, true);
        if (this.entityConfiguration) {
            const value = this.entityConfiguration.entity![this.entityConfiguration.field];
            if (value) {
                this.seedAnswers = JSON.parse(value);
            }
        }

        // if no content, then prefill the answers if required
        if (!this.seedAnswers?.[this.selectedSeed.key] && this.selectedSeed.prefillArticleSlug) {
            this.implementationKitService.getArticle(this.selectedSeed.prefillArticleSlug)
                .subscribe((helpJuiceArticle) => this.onAnswerChange(this.selectedSeed!.key, helpJuiceArticle.answer, true));
        }

        this.validateEntity();
    }

    public workflowStepNext() {
        return defer(() => this.commonDataService.saveEntities(this.entityConfiguration!.entity)).pipe(
            tap(() => this.workflowStepErrorMessage.next(undefined)),
            catchError((err) => {
                const errorMessage = ErrorHandlingUtilities.getHttpResponseMessage(err);
                this.workflowStepErrorMessage.next(errorMessage);
                return throwError(() => new AdaptError(errorMessage));
            }),
        );
    }

    public async onAnswerChange(key: string, value: string, isPrefill = false) {
        if (this.entityConfiguration && this.seedAnswers) {
            this.seedAnswers[key] = value;

            this.entityConfiguration.entity![this.entityConfiguration.field] = Object.values(this.seedAnswers).some((answer) => !!answer)
                ? JSON.stringify(this.sanitizeSeedAnswer(this.seedAnswers, this.selectedSeed!.key, isPrefill))
                : undefined;
        }

        this.validateEntity();
    }

    public validateEntity(isEditorValid = true) {
        this.isValid.next((this.entityConfiguration?.entity?.entityAspect.validateEntity() && isEditorValid) ?? false);
    }

    // sanitize content to not see the highlight banner to edit the article if the content is straight from HJ
    // isPrefill is a flag that's only set if prefill is added in for the first time with empty content so that the edit banner is shown.
    // As soon as the content changed, the banner will no longer be shown (Steve OK with that)
    private sanitizeSeedAnswer(seedAnswers: ISeedAnswers, key: string, isPrefill = false) {
        const value = seedAnswers[key];
        if (value && !isPrefill) {
            seedAnswers[key] = this.implementationKitService.stripArticleEditLinkHeader(value);
        }

        return seedAnswers;
    }
}

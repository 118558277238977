import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { FeatureName } from "./feature-name.enum";

// keep this in sync with .\Projects\Server\ADAPT.Methodology.Model\Embed\Feature.cs
export enum FeatureModule {
    People = "People",
    CulturalNetwork = "CulturalNetwork",
    Stewardship = "Stewardship",
    Compass = "Compass",
    Architecture = "Architecture",
    Stakeholders = "Stakeholders",
    Platform = "Platform",
    Organisation = "Organisation",
}

export class Feature extends BaseEntity<Feature> {
    public featureId!: number;
    public name!: FeatureName;
    public featureModule!: FeatureModule;
}

export const FeatureBreezeModel = new BreezeModelBuilder("Feature", Feature)
    .hasSource()
    .build();

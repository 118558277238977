import { DefaultBackNextButtons, DefaultFinishButtons, DefaultProceedButtons, GuidedTour } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourUtils } from "@common/lib/guided-tour/guided-tour.utils";
import { CommonTourSteps } from "./common-tour-steps";

function createHasObjectiveFunction(exist: boolean, type: string) {
    return () => {
        const objectiveTitle = GuidedTourUtils.getFirstElementWithTextContent(`[data-tour="objective-type-${type}"] .objective-type`);
        return GuidedTourUtils.isElementVisible(objectiveTitle) === exist;
    };
}

export const HasNoAnnualObjectiveFunction = createHasObjectiveFunction(false, "AO");
export const HasAnnualObjectiveFunction = createHasObjectiveFunction(true, "AO");
export const HasNoQuarterlyObjectiveFunction = createHasObjectiveFunction(false, "QO");
export const HasQuarterlyObjectiveFunction = createHasObjectiveFunction(true, "QO");


export const ObjectiveFeatureTour: GuidedTour = {
    id: "ObjectiveFeatureTour",
    steps: [{
        title: "Introduction",
        text: "This tour will walk you through what you have just defined in your <i>Set First Objectives</i> meeting.",
        buttons: DefaultProceedButtons,
    }, CommonTourSteps.openOrgMenuToShowSidebarNavNodeStep("Your objectives", "Objectives"), {
        waitForAndAttachToElementSelector: "adapt-display-link-hierarchy adapt-display-navigation-node span",
        elementSelectorTextContent: "Objectives",
        advanceOnAttachedElementEvent: "click",
        waitForPageActivationAfterEvent: true,
        attachTo: {
            on: "bottom-end",
        },
        title: "Access objectives",
        text: `<p>Your organisation's objectives are available here.</p>
            <p><b>Click on it now</b> to show it.</p>`,
        modalOverlayOpeningPadding: 3,
    }, {
        title: "No annual objectives",
        text: `<p>There are no annual objectives which are supposed to be defined in the previous pathway.</p>
            <p>The guided tour cannot proceed unless there are annual objectives defined.</p>`,
        buttons: DefaultFinishButtons,
        showOn: HasNoAnnualObjectiveFunction,
    }, {
        title: "No quarterly objectives",
        text: `<p>There are no quarterly objectives which are supposed to be defined in the previous pathway.</p>
            <p>The guided tour cannot proceed unless there are quarterly objectives defined.</p>`,
        buttons: DefaultFinishButtons,
        showOn: HasNoQuarterlyObjectiveFunction,
    }, {
        title: "Annual objectives",
        text: `<p>Annual objectives are indicated by <b>AO</b> as shown here.</p>`,
        waitForAndAttachToElementSelector: `[data-tour="objective-type-AO"] .objective-type`,
        attachTo: {
            on: "right",
        },
        showOn: HasAnnualObjectiveFunction,
        buttons: DefaultBackNextButtons,
        modalOverlayOpeningPadding: 3,
    }, {
        title: "Quarterly objectives",
        text: `<p>Quarterly objectives are indicated by <b>QO</b> as shown here.</p>`,
        waitForAndAttachToElementSelector: `[data-tour="objective-type-QO"] .objective-type`,
        attachTo: {
            on: "right",
        },
        showOn: HasQuarterlyObjectiveFunction,
        buttons: DefaultBackNextButtons,
        modalOverlayOpeningPadding: 3,
    }, {
        title: "Focusing on objective type",
        text: `<p>You can set focus on any particular type of objectives through the filter option here</p>
            <p><b>Click on here now</b> to see the options.</p>`,
        waitForAndAttachToElementSelector: `[data-test="filter-button"]`,
        advanceOnAttachedElementEvent: "click",
        attachTo: {
            on: "left",
        },
        modalOverlayOpeningPadding: 3,
        showOn: HasQuarterlyObjectiveFunction,
    }, {
        title: "Focusing on quarterly objectives",
        text: `<p>Here is where you can select the particular type of objectives</p>
            <p><b>Click on here now</b> to try it out.</p>`,
        waitForAndAttachToElementSelector: `[data-test="filter-objective-type"]`,
        advanceOnAttachedElementEvent: "click",
        attachTo: {
            on: "top",
        },
        modalOverlayOpeningPadding: 3,
        showOn: HasQuarterlyObjectiveFunction,
    }, {
        title: "Select quarterly objectives",
        text: `<p><b>Click here</b> to select Quarterly objectives</p>`,
        waitForAndAttachToElementSelector: `.dx-list-item-content`,
        elementSelectorTextContent: "Quarterly objectives",
        advanceOnAttachedElementEvent: "click",
        attachTo: {
            on: "top",
        },
        modalOverlayOpeningPadding: 3,
        showOn: HasQuarterlyObjectiveFunction,
    }, {
        title: "Remove the focus filter",
        text: `<p>Notice that the quarterly objectives are now in focus, while the annual objectives are greyed out.</p>
            <p><b>Click here</b> to remove the focus filter</p>`,
        waitForAndAttachToElementSelector: `[data-test="filter-objective-type"] .dx-clear-button-area`,
        advanceOnAttachedElementEvent: "click",
        attachTo: {
            on: "top",
        },
        modalOverlayOpeningPadding: 3,
        showOn: HasQuarterlyObjectiveFunction,
    }, {
        title: "Edit an objective",
        text: `<p>This is the menu which will consist of actions that can be performed on an objective.</p>
            <p><b>Click on it now</b> to see what's available.</p>`,
        waitForAndAttachToElementSelector: `[data-test="objective-actions"]`,
        modalOverlayOpeningPadding: 3,
        attachTo: {
            on: "bottom",
        },
        advanceOnAttachedElementEvent: "click",
        showOn: HasQuarterlyObjectiveFunction,
    }, {
        title: "Return to meeting",
        text: `<p>After you have finished exploring the objectives page, you can click here to return to your meeting.</p>`,
        waitForAndAttachToElementSelector: `[id="sidebar-meeting"]`,
        canClickTarget: false,
        attachTo: {
            on: "right",
        },
        buttons: DefaultFinishButtons,
        showOn: () => {
            const objectivesNode = GuidedTourUtils.getFirstElementWithTextContent(`[id="sidebar-meeting"]`);
            return GuidedTourUtils.isElementVisible(objectivesNode);
        },
    }],
};

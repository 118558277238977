import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { AdaptCommonDialogModule } from "@common/ux/adapt-common-dialog/adapt-common-dialog.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptLoadingSpinnerModule } from "@common/ux/loading-spinner/loading-spinner.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { DxDataGridModule, DxTextBoxModule, DxValidationGroupModule, DxValidatorModule } from "devextreme-angular";
import { ConfigureCreditCardComponent } from "./configure-credit-card/configure-credit-card.component";
import { DisplayHistoricalInvoicesComponent } from "./display-historical-invoices/display-historical-invoices.component";
import { DisplayPricingBreakdownComponent } from "./display-pricing-breakdown/display-pricing-breakdown.component";
import { DisplayPricingModelComponent } from "./display-pricing-model/display-pricing-model.component";
import { UpdateCreditCardDetailsComponent } from "./update-credit-card-details/update-credit-card-details.component";
import { UpdateCreditCardDialogComponent } from "./update-credit-card-dialog/update-credit-card-dialog.component";

@NgModule({
    declarations: [
        ConfigureCreditCardComponent,
        DisplayPricingModelComponent,
        DisplayPricingBreakdownComponent,
        DisplayHistoricalInvoicesComponent,
        UpdateCreditCardDialogComponent,
        UpdateCreditCardDetailsComponent,
    ],
    exports: [
        ConfigureCreditCardComponent,
        DisplayPricingModelComponent,
        DisplayPricingBreakdownComponent,
        DisplayHistoricalInvoicesComponent,
        UpdateCreditCardDetailsComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        DxDataGridModule,
        DxTextBoxModule,
        DxValidatorModule,
        DxValidationGroupModule,
        AdaptLoadingSpinnerModule,
        AdaptButtonModule,
        AdaptCommonDialogModule,
        AdaptTooltipModule,
    ],
})
export class AdaptPaymentProcessingModule {
}

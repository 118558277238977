import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Route, Router } from "@angular/router";
import { IGuard } from "./guard.interface";
import { RouteService } from "./route.service";

@Injectable({
    providedIn: "root",
})
export class RedirectionGuard implements IGuard {
    public static readonly Id = "RedirectionGuard";

    constructor(
        private router: Router,
        private routeService: RouteService,
    ) { }

    public async canActivate(route: ActivatedRouteSnapshot) {
        if (route.data?.redirectTo) {
            const url = await this.routeService.getRouteUrl(route as Route, route.data!.redirectTo, route.params, route.queryParams);
            return this.router.parseUrl(url);
        }

        return true;
    }
}

import { Injector } from "@angular/core";
import { PersonFlag } from "@common/ADAPT.Common.Model/person/person-flag.enum";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Observable } from "rxjs";
import { EntityExtensions } from "../entity-extensions.decorator";
import { RoleInOrganisation } from "../organisation/connection";
import { WorkflowConnection } from "../organisation/workflow-connection";
import { FeatureName } from "./feature-name.enum";
import { WorkflowExtensions } from "./workflow-extensions";
import { WorkflowStep } from "./workflow-step";

export enum WorkflowDialogWidth {
    Small = "Small",
    Medium = "Medium",
    Large = "Large",
    SemiLarge = "SemiLarge",
    ExtraLarge = "ExtraLarge",
    FullWidth = "FullWidth",
}

export enum WorkflowType {
    Journey = "Journey",
    Workflow = "Workflow",
}

export enum OrganisationCategoryValue {
    Onboarding = "Onboarding",
    Foundation = "Foundation",
    BuildStrategy = "BuildStrategy",
    ImplementStrategy = "ImplementStrategy",
    CriticalNumbers = "CriticalNumbers",
    EffectiveLeadershipTeam = "EffectiveLeadershipTeam",
    DoTheRightWork = "DoTheRightWork",
    FocusAccountability = "FocusAccountability",
    Others = "Others",
}

export enum WorkflowScope {
    Personal = "Personal",
    Team = "Team",
}

// this is only used locally on the client side - no longer a breeze entity connected to server
export class Workflow {
    public workflowId!: string;
    public name!: string;
    public type!: WorkflowType;
    public parentWorkflowId?: number;
    public ordinal?: number;
    public isStateless?: boolean;  // todo: consolidate this with private - possible rename as well
    public iconStyle?: string;
    public dialogWidth?: WorkflowDialogWidth;
    public overview?: string;

    public time?: string;
    public people?: string;
    public mindset?: string;

    // i need some way to disallow a workflow from being run - not serialised to db!
    public disabled!: boolean;
    public private!: boolean;
    public scope = WorkflowScope.Team;
    public category?: OrganisationCategoryValue;

    public persistenceId?: string;

    // navigation
    public parentWorkflow?: Workflow;
    public workflows?: Workflow[];
    public steps?: WorkflowStep[];

    @EntityExtensions(WorkflowExtensions)
    public extensions!: WorkflowExtensions;

    public showBreadcrumbs = false;
    //public showBreadcrumbTitles = false;
    public continueOnFinish = true;
    public hideIfCompleted = false;
    public isDismissible = true;

    // will be used for the outcomes of the workflow/journey
    public articleSlug?: ImplementationKitArticle;
    // wrap up article slug
    public wrapUpSlug?: ImplementationKitArticle;
    public wrapUpGuidedTourIdentifier?: string;
    public wrapUpGuidedTourPersonFlag?: PersonFlag;

    // feature to unlock when starting workflow
    public featuresToEnable?: FeatureName[];
    public optionalPrerequisites?: string[];
    public compulsoryPrerequisites?: string[];
    public requiredRoles?: RoleInOrganisation[]; // if defined, person need to have one of the roles to continue or start

    // If this is defined, the workflow corresponding to this will be executed after the current one completes
    // (skip or really complete)
    // Can use WorkflowIdentifier type here as that's only defined in alto - will need to change if workflowId changes
    public nextWorkflowId?: string;

    public runData?: any;

    // something to perform before running/continuing the workflow
    // - will run in workflow-run-dialog.initialise
    // - so each time the workflow is executed (dialog is opened)
    // - typically used to determine if certain steps in the workflow are to be removed/added
    // - so need to pass in workflowSteps, which can be manipulated
    public preProcessSteps?: (injector: Injector, workflowConnection: WorkflowConnection, workflowSteps: WorkflowStep[]) => Observable<unknown>;

    // something to perform after workflow completed or discarded, e.g. if workflow contains sub workflows, this will be done after all sub workflows
    public finalizeWorkflow?: (injector: Injector, workflowConnection?: WorkflowConnection) => Observable<unknown>;
}

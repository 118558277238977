<adapt-feature-guidance [configItem]="configItem">
    <p>Labels are used to group or categorise various (functions, actions, systems, objectives, etc) in your organisation.</p>
</adapt-feature-guidance>

<adapt-feature-configuration [configItem]="configItem">
    <ng-container *ngIf="allLabels.length < 1; else customLabels">
        <div>
            There are no existing labels in this organisation.
        </div>
        <button class="mt-3"
                adaptButton="add"
                data-test="label-add"
                [adaptBlockingClick]="addNewLabel">New Label</button>
    </ng-container>
    <ng-template #customLabels>
        <label>Existing labels</label>
        <dx-list [dataSource]="allLabels"
                 [searchEnabled]="true"
                 searchExpr="name"
                 searchMode="contains"
                 selectionMode="all"
                 selectAllMode="allPages"
                 selectAllText="Select all"
                 [showSelectionControls]="true"
                 [(selectedItems)]="selectedLabels"
                 data-test="search-labels">
            <div *dxTemplate="let item of 'item'"
                 class="configure-label-item">
                <adapt-display-label [label]="item"
                                     [adaptLinkDisabled]="true"></adapt-display-label>
                <div class="label-options ms-2">
                    <button adaptButton="editLink"
                            [adaptBlockingClick]="renameLabel"
                            [adaptBlockingClickParam]="item"
                            [stopPropagation]="true"
                            adaptTooltip="Rename label"
                            data-test="label-rename"></button>

                    <button class="ms-2"
                            adaptButton="search"
                            (click)="searchLabel(item)"
                            adaptTooltip="Search for usages of this label"
                            data-test="label-search"></button>

                    <button class="btn btn-link ms-2"
                            adaptButtonIcon="fal fa-trash-alt"
                            [adaptBlockingClick]="deleteLabel"
                            [adaptBlockingClickParam]="item"
                            [stopPropagation]="true"
                            adaptTooltip="Delete label"
                            data-test="label-delete"></button>
                </div>
            </div>
        </dx-list>
        <div class="d-flex mt-3">
            <button adaptButton="mergePrimary"
                    *ngIf="allLabels.length > 1"
                    [disabled]="selectedLabels.length < 2"
                    [adaptBlockingClick]="mergeLabels"
                    data-test="label-merge"></button>
            <button adaptButton="add"
                    class="me-2"
                    [adaptBlockingClick]="addNewLabel"
                    data-test="label-add">New Label</button>
        </div>
    </ng-template>
</adapt-feature-configuration>

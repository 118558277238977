<div class="card p-3">
    <div class="card-header">
        <h1>Debug</h1>
    </div>

    <div class="card-body">
        <div>
            <button class="btn btn-primary"
                    (click)="loggingTest()">Logging Test</button>
        </div>

        <hr>

        <div *ngIf="releaseNotifierService.disable"
             class="mt-4">
            <div class="alert alert-info">
                <p>Enable the client release update notifier service in development environment.</p>
                <p>Development (including devcloud) are all sharing the same storage account - any developer pushing a different version
                    will
                    alert all others. This option is here for local test only.</p>
                <button class="btn btn-primary"
                        (click)="releaseNotifierService.disable = false">Enable client release update notifier</button>
            </div>
        </div>
    </div>
</div>

import { Injectable } from "@angular/core";
import { SurveyType } from "@common/ADAPT.Common.Model/organisation/survey";
import { EmployeeEngagementAuthService } from "./employee-engagement/employee-engagement-auth.service";
import { OrganisationDiagnosticAuthService } from "./organisation-diagnostic/organisation-diagnostic-auth.service";
import { ResilientBusinessAssessmentAuthService } from "./resilient-business-assessment/resilient-business-assessment-auth.service";
import { ISurveyAuthService } from "./survey-auth.service.interface";
import { TeamAssessmentAuthService } from "./team-assessment/team-assessment-auth.service";

@Injectable({
    providedIn: "root",
})
export class SurveyAuthService {
    private surveyTypeAuthService: { [type in SurveyType]: ISurveyAuthService };

    public constructor(
        employeeEngagementAuthService: EmployeeEngagementAuthService,
        teamAssessmentAuthService: TeamAssessmentAuthService,
        resilientBusinessAssessmentAuthService: ResilientBusinessAssessmentAuthService,
        orgDiagnosticAuthService: OrganisationDiagnosticAuthService,
    ) {
        // there will be a compile error here if we add a survey type without defining the corresponding auth service
        this.surveyTypeAuthService = {
            EmployeeEngagement: employeeEngagementAuthService,
            TeamAssessment: teamAssessmentAuthService,
            ResilientBusinessAssessment: resilientBusinessAssessmentAuthService,
            OrganisationDiagnostic: orgDiagnosticAuthService,
        };
    }

    public forSurveyType(surveyType: SurveyType) {
        return this.surveyTypeAuthService[surveyType];
    }
}

import { Inject, Injectable } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { TEAM_CONFIGURATION_PAGE } from "@common/page-route-providers";
import { NavigationNodeBuilder } from "@common/route/navigation-node-builder";
import { NavigationUtilitiesService } from "@common/route/navigation-utilities.service";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { AuthorisationNotificationService } from "@org-common/lib/authorisation/authorisation-notification.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { BaseOrgCommonNavigationHierarchyService } from "@org-common/lib/sidebar/base-org-common-navigation-hierarchy.service";
import { merge } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { AuthorisationService } from "../../authorisation/authorisation.service";
import { CommonTeamsService } from "../../teams/common-teams.service";
import { CommonTeamsAuthService } from "../../teams/common-teams-auth.service";
import { TeamPrivateIconComponent } from "../../teams/team-dashboard-shared/team-private-icon/team-private-icon.component";

@Injectable()
export class ConfigurationTeamHierarchyNavigationService extends BaseOrgCommonNavigationHierarchyService {
    public static readonly HierarchyId = "ADAPT.Configuration.Team.Navigation.Hierarchy";
    public static readonly IconClass = "fal fa-fw fa-users";

    public id = ConfigurationTeamHierarchyNavigationService.HierarchyId;

    public constructor(
        private navigationUtilitiesService: NavigationUtilitiesService,
        private teamsService: CommonTeamsService,
        private authorisationService: AuthorisationService,
        orgService: OrganisationService,
        authNotification: AuthorisationNotificationService,
        rxjsBreezeService: RxjsBreezeService,
        @Inject(TEAM_CONFIGURATION_PAGE) private teamConfigurationPageRoute: IAdaptRoute<{ teamId: number }>,
    ) {
        super(orgService, authNotification);

        this.rebuildHierarchy$ = merge(
            rxjsBreezeService.entityTypeChanged(Team), // still need this for team name change
        ).pipe(
            debounceTime(100), // only a single rebuild required for multiple boards loaded
        );
    }

    protected async buildHierarchy() {
        const allTeams = await this.teamsService.promiseToGetAllActiveTeams();

        // filter for teams the user has ConfigureTeam access for
        const teamsWithAccess = await ArrayUtilities.asyncFilter(allTeams, (team) => this.authorisationService.promiseToGetHasAccess(CommonTeamsAuthService.ConfigureTeam, team));

        // no need to show the team node if no teams
        if (teamsWithAccess.length === 0) {
            return new NavigationNodeBuilder().build();
        }

        const teamBuilder = this.navigationUtilitiesService.nodeBuilder()
            .setIconClass(ConfigurationTeamHierarchyNavigationService.IconClass)
            .setHideIconInBreadcrumb(true)
            .setTitle("Team Configuration");

        teamsWithAccess
            .map((team) => this.promiseToBuildTeamNavigationNode(team))
            .forEach((nodeBuildPromise) => teamBuilder.promiseToAddChild(nodeBuildPromise));

        return teamBuilder.promiseToBuild();
    }

    @Autobind
    private async promiseToBuildTeamNavigationNode(team: Team) {
        const teamParams = {
            teamId: team.teamId,
        };

        const builder = this.navigationUtilitiesService
            .nodeBuilderForControllerAndParams(this.teamConfigurationPageRoute.id, teamParams)
            .setTitle(team.name)
            .ifAuthorised(CommonTeamsAuthService.ConfigureTeam, team);

        if (team.isPrivate) {
            builder.setIconClass(TeamPrivateIconComponent.IconClass)
                .setIconPositionRight(true);
        }

        return builder.promiseToBuild();
    }
}

<adapt-styled-dashboard-element *adaptIfAlto="true"
                                [name]="name"
                                [info]="info"
                                [linkText]="linkText"
                                [contentCard]="contentCard"
                                [nested]="nested"
                                [background]="background"
                                [iconClass]="iconClass"
                                [iconTooltip]="iconTooltip"
                                [isExpandable]="isExpandable"
                                [isCollapsible]="isCollapsible"
                                [collapsed]="collapsed"
                                (collapsedChange)="collapsedChange.emit($event)"
                                [card]="card"
                                [linkObject]="linkObject"
                                [disableClick]="disableClick || !hasClickSubscribers"
                                (headerClicked)="headerClicked.emit($event)">
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
</adapt-styled-dashboard-element>
<adapt-dashboard-element *adaptIfAlto="false"
                         [name]="name"
                         [info]="info"
                         [nested]="nested"
                         [hideBackground]="hideBackground"
                         [hideHeader]="hideHeader"
                         [linkObject]="linkObject"
                         [disableClick]="disableClick || !hasClickSubscribers"
                         (headerClicked)="headerClicked.emit($event)">
    <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
</adapt-dashboard-element>

<!-- Use this or the content can only be projected into the last call <ng-content>, i.e. styled-dashboard-element always missing content -->
<ng-template #contentTemplate>
    <ng-content></ng-content>
</ng-template>

import { OrganisationCategoryValue, WorkflowDialogWidth, WorkflowType } from "@common/ADAPT.Common.Model/embed/workflow";
import { WorkflowStepGuidancePosition } from "@common/ADAPT.Common.Model/embed/workflow-step";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { buildLocalWorkflow } from "@org-common/lib/workflow/workflow.interface";
import { WorkflowIdentifier } from "@org-common/lib/workflow/workflow-identifier.enum";

export const inviteBusinessLeaderWorkflow = buildLocalWorkflow({
    name: "Invite leadership team",
    workflowId: WorkflowIdentifier.InviteBusinessLeaders,
    type: WorkflowType.Journey,
    iconStyle: "fal fa-people-group",
    overview: "Understand, at high level, who should be (or could be) on your leadership team, and invite them to the platform.",
    showBreadcrumbs: false,
    category: OrganisationCategoryValue.Onboarding,
    articleSlug: ImplementationKitArticle.InviteBusinessLeadersOverview,
    time: "10 minutes",
    people: "Owners or leaders",
    workflows: [{
        name: "Invite leadership team",
        workflowId: "ODInitiateInvite",
        type: WorkflowType.Workflow,
        dialogWidth: WorkflowDialogWidth.FullWidth,
        steps: [{
            name: "Invite your leadership team",
            ordinal: 0,
            allowBack: false,
            canSkip: false,
            articleSlug: ImplementationKitArticle.InviteBusinessLeaderAddLeaders,
            componentSelector: "adapt-invite-business-leaders-add-leadership-team",
            workflowStepNextText: "Send invites",
            articlePosition: WorkflowStepGuidancePosition.Left,
        }, {
            name: "Invitations complete",
            ordinal: 1,
            skipIfPreviousSkipped: true,
            allowBack: false,
            canSkip: false,
            articleSlug: ImplementationKitArticle.InviteBusinessLeaderEnd,
        }],
    }],
});

import { Component, Inject, Injector } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { ErrorHandlingUtilities } from "@common/lib/utilities/error-handling-utilities";
import { of } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { ImplementationKitArticle } from "../../implementation-kit/implementation-kit-article.enum";
import { ADAPT_DIALOG_DATA } from "../adapt-common-dialog/adapt-common-dialog.globals";
import { CommonDialogHelper } from "../adapt-common-dialog/adapt-common-dialog.helper";
import { BaseDialogWithDiscardConfirmationComponent } from "../adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { IDxCustomRuleValidationCallback } from "../dx.types";

// these map to the profile item text
export enum SimpleValueType {
    Text = "Text",
    Date = "Date",
    RichText = "RichText",
    Country = "Country",
    TextArea = "TextArea",
}

export interface IEditSimpleEntityCustomValidator {
    validator: IDxCustomRuleValidationCallback;
    message: string;
}

export interface IEditSimpleEntity {
    label?: string;
    entity: IBreezeEntity;
    fieldName: string;
    type: SimpleValueType;
    maxLength?: number;
    originalValue?: any;
    usePropertyValidator?: boolean;
    customValidators?: IEditSimpleEntityCustomValidator[];
    isEditorValid?: boolean;
}

export interface IEditSimpleValueBreezeEntityDialogData {
    title: string;
    tooltip?: string;
    dialogWidth?: number;
    articleId?: ImplementationKitArticle;
    entities: IEditSimpleEntity[];
    saveOnClose: boolean;
}

@Component({
    selector: "adapt-edit-simple-value-breeze-entity-dialog",
    templateUrl: "./edit-simple-value-breeze-entity-dialog.component.html",
    styleUrls: ["./edit-simple-value-breeze-entity-dialog.component.scss"],
})
export class EditSimpleValueBreezeEntityDialogComponent extends BaseDialogWithDiscardConfirmationComponent<IEditSimpleValueBreezeEntityDialogData, IBreezeEntity[]> {
    public readonly dialogName = "EditSimpleValueBreezeEntityDialog";

    public saveError?: string;
    public dialogWidth: number = 650;
    public isEditorValid = true;

    public constructor(
        injector: Injector,
        @Inject(ADAPT_DIALOG_DATA) public data: IEditSimpleValueBreezeEntityDialogData,
    ) {
        super(injector);

        if (!this.data.saveOnClose) {
            data.entities.forEach((editSimpleEntity) => editSimpleEntity.originalValue = editSimpleEntity.entity[editSimpleEntity.fieldName]);
        }

        data.entities.forEach((editSimpleEntity) => editSimpleEntity.isEditorValid = true);

        if (this.data.dialogWidth) {
            this.dialogWidth = this.data.dialogWidth;
        }
    }

    public get entitiesToConfirm() {
        return [...this.data.entities.map((entity) => entity.entity)];
    }

    public isRichTextEdit(entity: IEditSimpleEntity) {
        return entity.type === SimpleValueType.RichText;
    }

    public isTextEdit(entity: IEditSimpleEntity) {
        return entity.type === SimpleValueType.Text;
    }

    public isTextAreaEdit(entity: IEditSimpleEntity) {
        return entity.type === SimpleValueType.TextArea;
    }

    public isCountryEdit(entity: IEditSimpleEntity) {
        return entity.type === SimpleValueType.Country;
    }

    public isDateEdit(entity: IEditSimpleEntity) {
        return entity.type === SimpleValueType.Date;
    }

    public get hasRichText() {
        return this.data.entities.some((entity) => entity.type === SimpleValueType.RichText);
    }

    public get hasValidationErrors() {
        return this.data.entities.find((entity) => entity.entity.entityAspect.hasValidationErrors || !entity.isEditorValid) !== undefined
            || this.hasCustomValidationErrors;
    }

    public get hasCustomValidationErrors() {
        return this.data.entities.some((entity) =>
            entity.customValidators?.some((validator) =>
                !validator.validator({ value: entity.entity[entity.fieldName] })) ?? false);
    }

    @Autobind
    public saveAndClose() {
        const uniqueEntities = Array.from(new Set(this.entitiesToConfirm));

        if (!this.data.saveOnClose) {
            this.resolve(uniqueEntities);
            return of(uniqueEntities);
        } else {
            return this.commonDataService.saveEntities(uniqueEntities).pipe(
                tap(() => this.resolve(uniqueEntities)),
                catchError((e) => this.saveError = ErrorHandlingUtilities.getHttpResponseMessage(e)),
            );
        }
    }

    @Autobind
    public cancel() {
        if (!this.data.saveOnClose) {
            const hasChanges = this.data.entities.some((simpleEntity) => simpleEntity.entity[simpleEntity.fieldName] !== simpleEntity.originalValue);
            const hasOriginalValue = this.data.entities.some((simpleEntity) => !!simpleEntity.originalValue);

            return CommonDialogHelper.cancelDialogAndPreserveEntity(
                !hasChanges,
                hasOriginalValue,
                () => {
                    this.data.entities.forEach((editSimpleEntity) => {
                        editSimpleEntity.entity[editSimpleEntity.fieldName] = editSimpleEntity.originalValue;
                    });
                },
                this.data.entities[0].entity,
                () => super.close(),
            );
        }

        return super.cancel();
    }
}

import { DefaultBackFinishButtons, GuidedTour } from "@common/lib/guided-tour/guided-tour.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { CompetitorsAnalysisTour } from "@org-common/lib/strategic-inputs/competitor-analysis-tour";
import { CommonTourSteps } from "./common-tour-steps";
import { SWTTour } from "./swt-tour";

export const StrategicInputsWrapUpTour: GuidedTour = {
    steps: [CommonTourSteps.openOrgMenuToShowSidebarNavNodeStep("Your strategic inputs", "Strategic Inputs"), {
        waitForAndAttachToElementSelector: "adapt-display-link-hierarchy adapt-display-navigation-node span",
        elementSelectorTextContent: "Strengths, Weaknesses & Trends", // looking for <adapt-display-navigation-node> with span content of this text
        advanceOnAttachedElementEvent: "click",
        attachTo: {
            on: "bottom-end",
        },
        title: `Access strengths, weaknesses & trends`,
        text: `<p>Your organisation's strengths, weaknesses & trends are available here.</p>
                <p><b>Click on it now</b> to show it.</p>`,
        modalOverlayOpeningPadding: 3,
        waitForPageActivationAfterEvent: true,
    }, ...ArrayUtilities.copyArrayAndRemoveLastElement(SWTTour.steps), {
        waitForAndAttachToElementSelector: "adapt-display-link-hierarchy adapt-display-navigation-node span",
        elementSelectorTextContent: "Competitor Analysis", // looking for <adapt-display-navigation-node> with span content of this text
        advanceOnAttachedElementEvent: "click",
        attachTo: {
            on: "bottom-end",
        },
        title: `Access competitor analysis`,
        text: `<p>Your organisation's competitor analysis is available here.</p>
                <p><b>Click on it now</b> to show it.</p>`,
        modalOverlayOpeningPadding: 3,
        waitForPageActivationAfterEvent: true,
    }, ...ArrayUtilities.copyArrayAndRemoveLastElement(CompetitorsAnalysisTour.steps), {
        title: "End of strategic inputs tour",
        text: `<p>This concludes the tour on strategic inputs.</p>`,
        buttons: DefaultBackFinishButtons,
    }],
};

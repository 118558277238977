import { ClassProvider, InjectionToken, Type } from "@angular/core";
import { Observable } from "rxjs";
import { INavigationNode } from "./navigation-node.interface";

export const NAVIGATION_HIERARCHIES = new InjectionToken<INavigationHierarchy[]>("NAVIGATION_HIERARCHIES");

export function provideNavigationHierarchy(builderType: Type<INavigationHierarchy>): ClassProvider {
    return {
        provide: NAVIGATION_HIERARCHIES,
        useClass: builderType,
        multi: true,
    };
}

export interface INavigationHierarchy {
    id: string;
    hierarchyNode$: Observable<INavigationNode | undefined>;
}

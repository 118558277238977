import { Injectable, Injector } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AbstractGuard } from "@common/route/abstract.guard";
import { RouteService } from "@common/route/route.service";
import { GuardFailureType } from "@common/route/route-events.service";
import { ChangeManagerService } from "@common/ux/change-manager/change-manager.service";

@Injectable({
    providedIn: "root",
})
export class ChangeGuard extends AbstractGuard {
    public static readonly Id = "ChangeGuard";

    constructor(
        injector: Injector,
        private changeManager: ChangeManagerService,
        private routeService: RouteService,
    ) {
        super(injector);
    }

    public canActivateWithBypassEvent(route: ActivatedRouteSnapshot, state: RouterStateSnapshot, bypassEvent: boolean) {
        if (!this.routeService.currentNavigationUrl || !this.routeService.previousNavigationUrl) {
            // not moving from page to page -> no need to go through change manager test. canActivate
            return true;
        }

        // not staying -> page will move on -> canActivate
        const notStaying = this.changeManager.interceptRouteChange(
            this.routeService.currentNavigationUrl!,
            this.routeService.previousNavigationUrl!,
        );
        return this.wrapActivationResult(notStaying, GuardFailureType.ChangeGuardFailed, route, state, bypassEvent);
    }
}

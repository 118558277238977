<adapt-styled-dashboard-element *ngIf="outstandingWorkflowConnections.length > 0"
                            name="Active pathways"
                            [background]="false"
                            [nested]="true"
                            [url]="url$ | async"
                            [info]="activePathwaysTooltip"
                            [linkText]="recommendedWorkflows.length === 0 ? 'View all pathways' : undefined">
    <div class="workflows">
        <adapt-display-workflow-card *ngFor="let connection of outstandingWorkflowConnections"
                                     [connection]="connection"></adapt-display-workflow-card>
    </div>
</adapt-styled-dashboard-element>

<adapt-styled-dashboard-element *ngIf="recommendedWorkflows.length > 0"
                            name="Up next"
                            [background]="false"
                            [nested]="true"
                            [url]="url$ | async"
                            linkText="View all pathways">
    <div class="workflows">
        <adapt-display-workflow-card *ngFor="let workflow of recommendedWorkflows"
                                     [workflow]="workflow"></adapt-display-workflow-card>
    </div>
</adapt-styled-dashboard-element>

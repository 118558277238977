import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { DxAccordionModule, DxTreeViewModule } from "devextreme-angular";
import { AdaptTooltipModule } from "../ux/tooltip.directive/tooltip.module";
import { DisplayAccordionHierarchyComponent } from "./display-accordion-hierarchy.component/display-accordion-hierarchy.component";
import { DisplayLinkHierarchyComponent } from "./display-link-hierarchy.component/display-link-hierarchy.component";
import { DisplayNavigationNodeComponent } from "./display-navigation-node.component/display-navigation-node.component";

@NgModule({
    imports: [
        CommonModule,
        DxTreeViewModule,
        DxAccordionModule,
        AdaptTooltipModule,
        RouterModule,
    ],
    exports: [
        DisplayAccordionHierarchyComponent,
        DisplayNavigationNodeComponent,
        DisplayLinkHierarchyComponent,
    ],
    declarations: [
        DisplayAccordionHierarchyComponent,
        DisplayNavigationNodeComponent,
        DisplayLinkHierarchyComponent,
    ],
})
export class AdaptCommonRouteModule { }

import { Component, Inject } from "@angular/core";
import { Board } from "@common/ADAPT.Common.Model/organisation/board";
import { Item } from "@common/ADAPT.Common.Model/organisation/item";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogComponent } from "@common/ux/adapt-common-dialog/base-dialog.component/base-dialog.component";

@Component({
    selector: "adapt-move-items-dialog",
    templateUrl: "./move-items-dialog.component.html",
})
export class MoveItemsDialogComponent extends BaseDialogComponent<Item[], Board> {
    public readonly dialogName = "MoveItemsDialog";

    public firstBoard = this.items
        .map((i) => i.board)
        .filter((b) => !!b)[0];
    public board?: Board;
    public hasItemToMove = false;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public items: Item[],
    ) {
        super();
        this.onSelectedBoardChanged(this.firstBoard);
    }

    public onSelectedBoardChanged(board?: Board) {
        this.board = board;
        this.hasItemToMove = !!board && this.items.some((item) => item.boardId !== board.boardId);
    }
}

import { Component, ElementRef, Input, OnChanges } from "@angular/core";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ObjectiveStatusMetadata } from "@common/ADAPT.Common.Model/organisation/objective-status";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { DxGridWrapperHelper } from "@common/ux/base.component/dx-component-wrapper-builder";
import { DateFormats } from "@common/ux/date-formats";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { InitializedEvent } from "devextreme/ui/data_grid";
import { ObjectiveViewType } from "../objective-view-type.enum";
import { ObjectivesService } from "../objectives.service";


@Component({
    selector: "adapt-objectives-grid",
    templateUrl: "./objectives-grid.component.html",
    styleUrl: "./objectives-grid.component.scss",
})
export class ObjectivesGridComponent implements OnChanges {
    public readonly ObjectiveViewType = ObjectiveViewType;
    public readonly DateFormat = DateFormats.globalize.short;

    @Input() public objectives: Objective[] = [];
    @Input() public teamId?: number;
    @Input() public hasEditPermissions = false;

    public hasExternalObjectives = false;
    public dxGridWrapperHelper: DxGridWrapperHelper;

    // can't use objective or team id as the key through calculateGroupValue as that value will be used in export
    // - since moving away to exceljs for exporting, there is no way to customize the group value anymore.
    // If we want a decent name in the grouped row in the exported sheet, will have to use the display text as the key to look up.
    // This group value will be used for sorting too - won't use calculateSortValue (which is only used when ungrouped)
    private objectiveKeyMap: { [key: string]: Objective } = {};
    private teamKeyMap: { [key: string]: Team } = {};

    public constructor(
        elementRef: ElementRef,
        private objectivesService: ObjectivesService,
        private orgService: OrganisationService,
    ) {
        this.dxGridWrapperHelper = new DxGridWrapperHelper("adapt-objectives-grid", jQuery(elementRef.nativeElement));
    }

    public ngOnChanges() {
        this.objectives.sort(this.objectivesService.sortObjectives(this.teamId!));
        this.objectiveKeyMap = {};
        this.teamKeyMap = {};
        this.objectives.forEach((objective) => {
            this.objectiveKeyMap[this.getObjectiveDisplayText(objective)] = objective;
            if (objective.team) {
                this.teamKeyMap[this.getTeamDisplayText(objective)] = objective.team;
            }
        });
        this.hasExternalObjectives = this.objectives.some((o) => o.teamId != this.teamId);
    }

    public onGridInitialized(e: InitializedEvent) {
        this.dxGridWrapperHelper.saveGridState(`${this.orgService.getOrganisationId()}-${this.teamId}`);
        this.dxGridWrapperHelper.initialiseGrid(e);
        e.component!.option().onToolbarPreparing = this.dxGridWrapperHelper.addResetToolbarButton;
        this.dxGridWrapperHelper.callGrid((grid) => grid.setColumnsReady());
    }

    public getObjectiveByKey(key?: string) {
        return key ? this.objectiveKeyMap[key] : undefined;
    }

    public appendPercentage(obj: Objective) {
        return `${obj.currentProgress}%`;
    }

    public getObjectiveDisplayText(obj: Objective) {
        return obj ? `${obj.objectiveCode}: ${obj.title}` : "None";
    }

    @Autobind
    public getObjectiveParentDisplayText(obj: Objective) {
        return this.getObjectiveDisplayText(obj.parentObjective);
    }

    public getObjectiveStatusName(obj: Objective) {
        // Seen toaster without the ? below - metadata not initialized on reload? Not reproducible every time.
        return ObjectiveStatusMetadata.ByStatus[obj.status]?.name;
    }

    public getTeamDisplayText(obj: Objective) {
        return obj.team?.name ?? "Organisation";
    }

    public getTeamByKey(key?: string) {
        return key ? this.teamKeyMap[key] : undefined;
    }
}

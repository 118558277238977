import { Component, Input } from "@angular/core";
import { KeyResult } from "@common/ADAPT.Common.Model/organisation/key-result";
import { DateFormats } from "@common/ux/date-formats";
import moment from "moment";

@Component({
    selector: "adapt-display-key-result-progress",
    templateUrl: "./display-key-result-progress.component.html",
    styleUrls: ["./display-key-result-progress.component.scss"],
})
export class DisplayKeyResultProgressComponent {
    @Input() public keyResult!: KeyResult;

    public get progressChangeTooltip() {
        const currentValue = this.keyResult.currentKeyResultValue;
        if (currentValue) {
            return `Last updated by ${currentValue.person.fullName} on ${moment(currentValue.dateTime).format(DateFormats.moment.long)}`;
        }
    }

    public get progressText() {
        let withTargetSuffix = true;

        if (this.keyResult.targetValueSuffix === "%") {
            if (this.keyResult.targetValue === 100) {
                return this.keyResult.currentProgress + "%";
            } else {
                withTargetSuffix = false;
            }
        }

        const currentValue = this.keyResult.getFormattedValue(this.keyResult.currentValue, false);
        const targetValue = this.keyResult.getFormattedValue(this.keyResult.targetValue, withTargetSuffix);

        return `${currentValue} / ${targetValue} (${this.keyResult.currentProgress}%)`;
    }

    public get hasProgress() {
        return !!this.keyResult.currentKeyResultValue;
    }
}

<ng-container *ngIf="contentOverflows || popupVisible; else template">
    <dx-popup *ngIf="popupVisible"
              [wrapperAttr]="{class: 'see-more-popup'}"
              [hideOnOutsideClick]="true"
              [dragEnabled]="true"
              [showTitle]="true"
              [visible]="popupVisible"
              height="auto"
              maxHeight="80vh"
              [title]="popupTitle"
              [width]="desiredWidth"
              (onHidden)="popupVisible = false"
              shadingColor="rgba(0, 0, 0, 0.5)">
        <dx-scroll-view *dxTemplate="let data of 'content'"
                        width="100%"
                        height="100%"
                        showScrollbar="always">
            <div class="p-3">
                <ng-container [ngTemplateOutlet]="template"></ng-container>
            </div>
        </dx-scroll-view>
    </dx-popup>

    <div class="d-flex">
        <button class="btn btn-primary mx-auto"
                (click)="popupVisible = true">{{buttonText}}</button>
    </div>
</ng-container>

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { RoleInOrganisation } from "@common/ADAPT.Common.Model/organisation/connection";
import { SurveyQuestionResponse } from "@common/ADAPT.Common.Model/organisation/survey-question-response";
import { ServiceUri } from "@common/configuration/service-uri";

export interface ISignupSelfLedBindingModel {
    firstName: string;
    lastName: string;
    emailAddress: string;
    password: string;
    companyName: string;
    roleInOrganisation: RoleInOrganisation;
    referrer?: string;
    healthCheckQuestionResponses?: Partial<SurveyQuestionResponse>[];
}

export interface IConfirmEmailBindingModel {
    email?: string;
    token?: string;
    organisationId?: number;
}

export interface ISignupSelfLedResult {
    accessToken: string;
    organisationUrlIdentifier: string;
    surveyId?: number;
}

@Injectable({
    providedIn: "root",
})
export class AccountService {
    public constructor(
        private httpClient: HttpClient,
    ) { }

    public signupOrganisation(signupData: ISignupSelfLedBindingModel) {
        return this.httpClient.post<ISignupSelfLedResult>(`${ServiceUri.AccountServiceUri}/SignupSelfLedOrganisation`, signupData);
    }

    public confirmEmail(confirmEmailData: IConfirmEmailBindingModel) {
        return this.httpClient.post(`${ServiceUri.AccountServiceUri}/ConfirmEmail`, confirmEmailData);
    }
}

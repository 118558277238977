<h3 *ngIf="sortableGroupsByParent.length > 1"
    class="mx-3 mt-3">1. Team objectives supporting organisation objectives</h3>
<div *ngFor="let groupData of sortableGroupsByParent"
     class="sibling-group"
     [ngClass]="{'pt-3 mt-2': sortableGroupsByParent.length > 1}">
    <div class="mx-3"
         *ngIf="sortableGroupsByParent.length > 1">
        <div *ngIf="!!groupData.key"
             class="d-flex gap-2">
            <strong>Supporting organisation objective:</strong>
            <adapt-link-objective [objective]="groupData.key"
                                  [generateHref]="false"
                                  [showStatus]="false"></adapt-link-objective>
        </div>
        <ng-container *ngIf="!groupData.key">
            <h3 *ngIf="groupData.items[0].objective.teamId">2. Standalone team objectives</h3>
            <h3 *ngIf="!groupData.items[0].objective.teamId">
                2. Organisation Objectives
            </h3>
        </ng-container>
    </div>
    <dx-list [activeStateEnabled]="false"
             [hoverStateEnabled]="false"
             [focusStateEnabled]="false"
             [dataSource]="groupData.items"
             pageLoadMode="scrollBottom"
             (onItemReordered)="reorderObjectives($event, groupData.items)"
             [ngClass]="{'indented': indented}">
        <dxo-item-dragging [data]="groupData.items"
                           [allowReordering]="groupData.items.length > 1"></dxo-item-dragging>
        <div *dxTemplate="let objectiveGroup of 'item'">
            <adapt-link-objective [ngClass]="{'ms-2': !indented, 'ms-4': indented}"
                                  [objective]="objectiveGroup.objective"
                                  [generateHref]="false"
                                  [showStatus]="false"
                                  [showStatusIcon]="true"></adapt-link-objective>
            <ng-container *ngIf="sortableChildGroups[objectiveGroup.objective.objectiveId] as childGroups">
                <adapt-sortable-objective-groups *ngIf="childGroups.length > 1"
                                                 [objectiveGroups]="childGroups"
                                                 [teamId]="teamId"
                                                 [indented]="true"
                                                 (objectivesReordered)="objectivesReordered.emit($event)"></adapt-sortable-objective-groups>
            </ng-container>
        </div>
    </dx-list>
</div>

import { Component, Inject, Input } from "@angular/core";
import { PURPOSE_VISION_PAGE } from "@common/page-route-providers";
import { IAdaptRoute } from "@common/route/page-route-builder";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { CallToActionText } from "@org-common/lib/call-to-action/call-to-action-text";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CommonPurposeVisionService } from "../common-purpose-vision.service";

export type PurposeVisionPart = "purpose" | "vision" | "foundingStory" | "bullseye";

@Component({
    selector: "adapt-purpose-vision-part-dashboard-element",
    templateUrl: "./purpose-vision-part-dashboard-element.component.html",
})
export class PurposeVisionPartDashboardElementComponent extends BaseComponent {

    @Input() public set part(part: PurposeVisionPart) {
        this.part$.next(part);
    }

    public url$: Observable<string>;
    public title?: string;
    public linkText?: string;
    public content$?: Observable<string | undefined>;
    public part$ = new BehaviorSubject<PurposeVisionPart>("purpose");
    public blurb?: string;

    public constructor(
        purposeVisionService: CommonPurposeVisionService,
        @Inject(PURPOSE_VISION_PAGE) purposeVisionPageRoute: IAdaptRoute<{}>,
    ) {
        super();

        this.url$ = purposeVisionPageRoute.getRoute();

        this.part$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((part) => {
            switch (part) {
                case "purpose":
                    this.linkText = "Purpose";
                    this.title = this.isAlto ? "Purpose statement" : this.linkText;
                    this.content$ = purposeVisionService.getPurpose().pipe(
                        map((purpose) => purpose?.content),
                    );
                    this.blurb = CallToActionText.guidingPhilosophy.purpose;
                    break;

                case "vision":
                    this.linkText = "Vision";
                    this.title = this.isAlto ? "Vision statement" : this.linkText;
                    this.content$ = purposeVisionService.getVision().pipe(
                        map((gp) => gp?.vision),
                    );
                    this.blurb = CallToActionText.guidingPhilosophy.vision;
                    break;

                case "foundingStory":
                    this.title = "Founding story";
                    this.content$ = purposeVisionService.getVision().pipe(
                        map((gp) => gp?.context),
                    );
                    this.blurb = CallToActionText.guidingPhilosophy.foundingStory;
                    break;

                case "bullseye":
                    this.title = "Bullseye";
                    this.content$ = purposeVisionService.getVision().pipe(
                        map((gp) => gp?.bullseye),
                    );
                    this.blurb = CallToActionText.guidingPhilosophy.bullseye;
                    break;

                default:
                    throw new Error("unknown part");
            }
        });
    }
}

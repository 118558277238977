import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { BullseyeQuadrantStatement } from "./bullseye-quadrant-statement";
import { Organisation } from "./organisation";
import { Theme } from "./theme";

export class BullseyeStatementLocation extends BaseEntity<BullseyeStatementLocation> {
    public bullseyeStatementLocationId!: number;
    public bullseyeStatementId!: number;
    public themeId!: number;
    public ordinal!: number;
    public organisationId!: number;

    public organisation!: Organisation;
    public bullseyeStatement!: BullseyeQuadrantStatement;
    public theme!: Theme;
}

export const BullseyeStatementLocationBreezeModel = new BreezeModelBuilder("BullseyeStatementLocation", BullseyeStatementLocation)
    .hasSource()
    .isOrganisationEntity()
    .withIdField()
    .alwaysFetchingNavigationProperty("bullseyeStatement")
    .alwaysFetchingNavigationProperty("theme")
    .build();

import { Component, Input } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { ConfigureFeatureBase } from "../../base-components/configure-feature-base";
import { IConfigItem } from "../../configuration.interfaces";
import { ConfigurationService } from "../../configuration.service";

@Component({
    selector: "adapt-configure-team-meetings",
    templateUrl: "./configure-team-meetings.component.html",
})
export class ConfigureTeamMeetingsComponent extends ConfigureFeatureBase {
    @Input() public configItem?: IConfigItem;
    @Input() public team!: Team;

    public constructor(configurationService: ConfigurationService) {
        super(configurationService);
    }

    public async initialiseData() {
        return Promise.resolve();
    }
}

import { Injectable, Injector } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { FeaturePermission } from "@common/ADAPT.Common.Model/embed/feature-permission";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { FeatureStatus, FeatureStatusBreezeModel } from "@common/ADAPT.Common.Model/organisation/feature-status";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { RoleFeaturePermission } from "@common/ADAPT.Common.Model/organisation/role-feature-permission";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { IEntityWithOptionalTeam } from "@common/ADAPT.Common.Model/organisation/team-entity.interface";
import { FeatureService } from "@common/feature/feature.service";
import { IdentityService } from "@common/identity/identity.service";
import { UserService } from "@common/user/user.service";
import { lastValueFrom, Subject } from "rxjs";
import { AfterOrganisationInitialisation } from "../organisation/after-organisation-initialisation.decorator";
import { BaseOrganisationService } from "../organisation/base-organisation.service";
import { OrganisationService } from "../organisation/organisation.service";

@Injectable({
    providedIn: "root",
})
export class FeaturesService extends BaseOrganisationService {
    public static readonly NonConfigurableFeaturePermissions = [
        FeaturePermissionName.OrganisationTeamRead, // not configuration as to now allowing org leader to enable global access to private teams
    ];
    public isPlatformFeature = this.featureService.isPlatformFeature;

    private featureStatuses: FeatureStatus[] = [];
    private cachedFeaturePermissions: FeaturePermission[] = [];
    private currentOrganisation?: Organisation;

    private featureStatusUpdatedSubject = new Subject<void>();

    public constructor(
        injector: Injector,
        orgService: OrganisationService,
        private featureService: FeatureService,
        private identityService: IdentityService,
        private userService: UserService,
    ) {
        super(injector);

        orgService.organisationEntityUpdated$.subscribe((org) => this.promiseToInitialiseService(org!));
    }

    public get featureStatusUpdated$() {
        return this.featureStatusUpdatedSubject.asObservable();
    }

    public isRoleFeaturePermissionForActiveFeature(roleFeaturePermission: RoleFeaturePermission) {
        const featurePermission = roleFeaturePermission.featurePermission;
        return this.isFeatureActive(featurePermission.feature.name);
    }

    public async promiseToGetAllFeaturePermissions(): Promise<FeaturePermission[]> {
        await this.promiseToGetAndCacheFeatureStatuses();
        const featurePermissions = await lastValueFrom(this.featureService.getAllFeaturePermissions());

        // promiseToGetAllFeaturePermissions will be called by authorisation service from user service when org switch (including login)
        // - cache here should be sufficient
        this.cachedFeaturePermissions = featurePermissions.filter((featurePermission: FeaturePermission) => this.isFeatureActive(featurePermission.feature.name));
        return this.cachedFeaturePermissions;
    }

    public async promiseToSetFeatureStatus(name: FeatureName, team: Team | undefined, isActive: boolean) {
        let featureStatus = this.getActiveFeature(name, team);

        // state hasn't changed
        if ((isActive && featureStatus) || (!isActive && !featureStatus)) {
            return;
        }

        if (isActive) {
            if (team) {
                featureStatus = await lastValueFrom(this.featureService.addFeatureStatus(name, team));
            } else {
                featureStatus = await lastValueFrom(this.featureService.addFeatureStatus(name, this.currentOrganisation!));
            }
        } else {
            featureStatus = await lastValueFrom(this.featureService.removeFeatureStatus(featureStatus!));
        }

        // make sure the cache is updated so that we don't have duplicates if feature is toggled multiple times
        await this.promiseToGetAndCacheFeatureStatuses();
        this.featureStatusUpdatedSubject.next();

        return featureStatus;
    }

    public isFeatureActive(name: FeatureName, team?: IEntityWithOptionalTeam) {
        return this.getActiveFeature(name, team) !== undefined;
    }

    public checkIfFeatureActiveAndSaved(name: FeatureName, team?: IEntityWithOptionalTeam) {
        return this.getActiveFeature(name, team)?.entityAspect.entityState.isUnchanged() ?? false;
    }

    @AfterOrganisationInitialisation
    public async promiseToCheckIfFeatureActive(name: FeatureName, team?: IEntityWithOptionalTeam) {
        // potentially can request feature statuses for the wrong org if called during organisation change
        await this.promiseToGetAndCacheFeatureStatuses();
        return this.isFeatureActive(name, team);
    }

    public async promiseToVerifyRejectOrgFeatures(featureNamesToCheck: FeatureName[]) {
        if (!Array.isArray(featureNamesToCheck) || featureNamesToCheck.length < 1) {
            return true;
        }

        // update features as this can be called after switching organisation - accessing
        // from the cache frequently got out-dated data
        await this.promiseToGetAndCacheFeatureStatuses();

        const statuses = featureNamesToCheck.map((featureName) => this.isFeatureActive(featureName));
        if (statuses.some((s) => !s)) {
            return Promise.reject({
                verifyFeatures: featureNamesToCheck,
            });
        }

        return true;
    }

    public async promiseToGetAndCacheFeatureStatuses() {
        this.featureStatuses = await lastValueFrom(this.commonDataService.getAll(FeatureStatusBreezeModel));
        return this.featureStatuses;
    }

    private async promiseToInitialiseService(organisation: Organisation) {
        this.currentOrganisation = organisation;

        await this.identityService.promiseToDoIfLoggedIn(() => Promise.all([
            lastValueFrom(this.featureService.getAllFeatures()),
            this.promiseToGetAndCacheFeatureStatuses(),
        ]), () => Promise.resolve());

        this.userService.notifyUserChanged();
    }

    // TODO Write some tests for this
    private getActiveFeature(name: FeatureName, team?: IEntityWithOptionalTeam) {
        const relevantFeatureStatuses = this.featureStatuses
            // If this is checked immediately after a feature status is removed, then
            // the navigation property will be null as the entity has been detached.
            .filter((i) => i.feature)
            .filter((i) => i.feature.name === name);
        const organisationFeature = relevantFeatureStatuses.find((i) => !i.teamId);

        if (!organisationFeature) {
            return undefined;
        } else if (!team || !team.teamId) {
            return organisationFeature;
        } else {
            return relevantFeatureStatuses.find((i) => i.teamId === team.teamId);
        }
    }
}

import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { SurveyTypeLabel } from "@common/ADAPT.Common.Model/organisation/survey";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { ManageTeamAssessmentsPageComponent } from "./manage-team-assessments-page.component";

export const manageTeamAssessmentsPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-manage-team-assessments-page", ManageTeamAssessmentsPageComponent)
    .atOrganisationUrl("/team/:teamId/team-assessment")
    .redirectToThisRouteFromOrganisationUrl("/people/teams/:teamId/team-assessment")
    .verifyingFeatures(FeatureName.CulturalNetworkTeamAssessment)
    .withTitle(SurveyTypeLabel.TeamAssessment)
    .reloadOnSearch(false)
    .build();

export enum EventSeriesWeekIndex {
    First = "First",
    Second = "Second",
    Third = "Third",
    Fourth = "Fourth",
    Last = "Last",
}

export class EventSeriesWeekIndexMetadata {
    public static readonly First = new EventSeriesWeekIndexMetadata(EventSeriesWeekIndex.First, "1st", 1);
    public static readonly Second = new EventSeriesWeekIndexMetadata(EventSeriesWeekIndex.Second, "2nd", 2);
    public static readonly Third = new EventSeriesWeekIndexMetadata(EventSeriesWeekIndex.Third, "3rd", 3);
    public static readonly Fourth = new EventSeriesWeekIndexMetadata(EventSeriesWeekIndex.Fourth, "4th", 4);
    public static readonly Last = new EventSeriesWeekIndexMetadata(EventSeriesWeekIndex.Last, "Last", 0);

    public static readonly All = [
        EventSeriesWeekIndexMetadata.First,
        EventSeriesWeekIndexMetadata.Second,
        EventSeriesWeekIndexMetadata.Third,
        EventSeriesWeekIndexMetadata.Fourth,
        EventSeriesWeekIndexMetadata.Last,
    ];

    public static readonly ByWeekIndex: { [status in EventSeriesWeekIndex]: EventSeriesWeekIndexMetadata } = {
        [EventSeriesWeekIndex.First]: EventSeriesWeekIndexMetadata.First,
        [EventSeriesWeekIndex.Second]: EventSeriesWeekIndexMetadata.Second,
        [EventSeriesWeekIndex.Third]: EventSeriesWeekIndexMetadata.Third,
        [EventSeriesWeekIndex.Fourth]: EventSeriesWeekIndexMetadata.Fourth,
        [EventSeriesWeekIndex.Last]: EventSeriesWeekIndexMetadata.Last,
    };

    public static readonly ByIntValue: { [value: number]: EventSeriesWeekIndexMetadata } = {
        [EventSeriesWeekIndexMetadata.Last.value]: EventSeriesWeekIndexMetadata.Last,
        [EventSeriesWeekIndexMetadata.First.value]: EventSeriesWeekIndexMetadata.First,
        [EventSeriesWeekIndexMetadata.Second.value]: EventSeriesWeekIndexMetadata.Second,
        [EventSeriesWeekIndexMetadata.Third.value]: EventSeriesWeekIndexMetadata.Third,
        [EventSeriesWeekIndexMetadata.Fourth.value]: EventSeriesWeekIndexMetadata.Fourth,
    };

    private constructor(
        public readonly weekIndex: EventSeriesWeekIndex,
        public readonly name: string,
        public readonly value: number,
    ) { }
}
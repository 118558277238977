import { Component, Input } from "@angular/core";
import { GoalStatus, GoalStatusLabel } from "@common/ADAPT.Common.Model/organisation/goal";

@Component({
    selector: "adapt-goal-status",
    templateUrl: "./goal-status.component.html",
    styleUrls: ["./goal-status.component.scss"],
})
export class GoalStatusComponent {
    @Input() public status!: GoalStatus;
    @Input() public showCollapsible = false;
    @Input() public isCollapsed = true;

    public readonly StatusClass: { [status in GoalStatus]: string } = {
        [GoalStatus.OnTrack]: "on-track",
        [GoalStatus.Slow]: "slow",
        [GoalStatus.Stuck]: "stuck",
        [GoalStatus.Closed]: "closed",
    };

    public get statusText() {
        return this.status ? GoalStatusLabel[this.status] : "No status";
    }
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { AdaptDateModule } from "@common/ux/date/date.module";
import { AdaptMenuModule } from "@common/ux/menu/menu.module";
import { AdaptTooltipModule } from "@common/ux/tooltip.directive/tooltip.module";
import { AdaptTeamDashboardSharedModule } from "@org-common/lib/teams/team-dashboard-shared/team-dashboard-shared.module";
import { DxDropDownBoxModule, DxTreeViewModule } from "devextreme-angular";
import { AdaptDirectorySharedModule } from "../directory-shared/directory-shared.module";
import { AdaptLabellingModule } from "../labelling/labeling.module";
import { ItemActionMenuComponent } from "./items/item-action-menu/item-action-menu.component";
import { ItemLinkComponent } from "./items/item-link/item-link.component";
import { LinkTeamKanbanComponent } from "./link-team-kanban/link-team-kanban.component";
import { SelectBoardComponent } from "./select-board/select-board.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AdaptDateModule,
        AdaptTooltipModule,
        AdaptMenuModule,
        DxDropDownBoxModule,
        DxTreeViewModule,
        AdaptTeamDashboardSharedModule,
        AdaptDirectorySharedModule,
        AdaptLabellingModule,
    ],
    declarations: [
        LinkTeamKanbanComponent,
        ItemLinkComponent,
        SelectBoardComponent,
        ItemActionMenuComponent,
    ],
    exports: [
        LinkTeamKanbanComponent,
        ItemLinkComponent,
        SelectBoardComponent,
        ItemActionMenuComponent,
    ],
})
export class AdaptKanbanSharedModule { }

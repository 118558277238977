import { Injectable, Injector } from "@angular/core";
import { Layout, LayoutBreezeModel } from "@common/ADAPT.Common.Model/organisation/layout";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { BaseService } from "@common/service/base.service";
import { Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { ComponentSize, ComponentSizePresets, EntityWithLayout, ILayoutBase, ILayoutBaseColumn, ILayoutBaseComponent, ILayoutColumn, ILayoutColumnOptions, ILayoutComponent, ILayoutComponentOptions, TComponentFromLayout } from "./layout.interface";


@Injectable({
    providedIn: "root",
})
export class LayoutService extends BaseService {
    public constructor(
        injector: Injector,
    ) {
        super(injector);
    }

    /**
     * Clones a layout component into a serialisable form.
     * @param component component to clone
     * @param withEnabled whether to include component enabled status in cloned component
     */
    public static cloneComponent(component: ILayoutComponent, withEnabled = false): ILayoutBaseComponent {
        const clonedComponent: ILayoutBaseComponent = Object.assign({}, component);
        if (component.options) {
            const options = this.cloneOptions(component.options);
            Object.assign(clonedComponent, { options });
        }
        this.removeUnserialisableFields(clonedComponent);

        if (!withEnabled) {
            // only used for components hiding due to adaptLayoutComponentEnabled on contents
            if (clonedComponent.options?.enabled !== undefined) {
                delete clonedComponent.options.enabled;
            }
        }

        return clonedComponent;
    }

    /**
     * Clones a layout column into a serialisable form.
     * @param column column to clone
     * @param withComponentsEnabled whether to include component enabled status in cloned component
     */
    public static cloneColumn(column: ILayoutColumn, withComponentsEnabled = false): ILayoutBaseColumn {
        const clonedComponents = column.options.components.map((cmp) => this.cloneComponent(cmp, withComponentsEnabled));
        const clonedOptions = this.cloneOptions(column.options, { components: clonedComponents });

        const clonedColumn = Object.assign({}, column, { options: clonedOptions }) as ILayoutBaseColumn;
        this.removeUnserialisableFields(clonedColumn);

        return clonedColumn;
    }

    private static cloneOptions(options: ILayoutComponentOptions | ILayoutColumnOptions, extras?: Partial<ILayoutComponentOptions | ILayoutColumnOptions>) {
        const clonedOptions = Object.assign({}, options, extras ?? {});

        // remove all undefined option values when cloning (we don't want to serialise these)
        Object.keys(clonedOptions).forEach((key) => clonedOptions[key] === undefined && delete clonedOptions[key]);

        return clonedOptions;
    }

    /**
     * Gets the applicable size classes from the given class string.
     * @param classes string of css classes e.g. "d-flex col-12 col-md-6"
     */
    public static getElementSize(classes: string) {
        return classes.split(" ").filter((cls) => cls.includes("col") && cls !== "col-12").sort().join(" ");
    }

    /**
     * Finds the given component in the given layout.
     * Returns an object with the component and its containing column.
     * @param layout layout to search for the given component within
     * @param component component to find within the given layout
     * @private
     */
    public static findComponentInLayout<T extends ILayoutBaseColumn>(layout: T[], component: string) {
        return layout.reduce((prev, col) => {
            // already found the element, skip
            if (prev) return prev;

            const foundComponent = col.options.components.find((cmp) => cmp.id === component);
            if (foundComponent) {
                return { component: foundComponent as TComponentFromLayout<T>, column: col };
            }

            return undefined;
        }, undefined) ?? { component: undefined, column: undefined };
    }


    /**
     * Sets options for the given entity.
     * @param entity entity to set options for
     * @param options options to set
     */
    public static setOptions<T extends ILayoutBaseComponent | ILayoutBaseColumn>(entity: T, options?: Partial<T["options"]>) {
        // assign to current options, or create options if undefined
        entity.options = Object.assign(entity.options ?? {}, options as T["options"]);
    }

    /**
     * Sets size for the given entity.
     * @param entity entity to set size for
     * @param size size to set
     */
    public static setSize<T extends ILayoutBaseComponent | ILayoutBaseColumn>(entity: T, size?: T["size"]) {
        entity.size = size ? this.getElementSize(size) : size;
    }

    /**
     * Gets the visible rows given a set of components.
     * @param components components to get rows for
     */
    public static getColumnRows<TComponent extends ILayoutBaseComponent>(components: TComponent[]) {
        const sizeWeights: Record<ComponentSize, number> = {
            [ComponentSize.Full]: 1,
            [ComponentSize.ThreeQuarter]: 0.75,
            [ComponentSize.Half]: 0.5,
            [ComponentSize.Quarter]: 0.25,
        };

        const processed = new Set<TComponent>();

        return components.reduce((acc, cmp, idx) => {
            if (processed.has(cmp)) {
                return acc;
            }

            const currentRow: TComponent[] = [];

            let rowSum = 1;
            let rowIndex = 0;

            while (rowSum > 0 && (idx + rowIndex < components.length)) {
                const component = components[idx + rowIndex];
                rowSum -= getWeight(component);

                if (rowSum >= 0) {
                    processed.add(component);
                    currentRow.push(component);
                    rowIndex++;
                } else {
                    break;
                }
            }

            acc.push(currentRow);
            return acc;
        }, [] as TComponent[][]);

        function isSize(cmp: TComponent, size: ComponentSize) {
            return ComponentSizePresets[size].includes(cmp.size ?? "");
        }

        function getWeight(cmp: TComponent) {
            if (cmp.options?.enabled === false) {
                return 0;
            }

            for (const preset of Object.keys(ComponentSizePresets)) {
                if (isSize(cmp, preset as ComponentSize)) {
                    return sizeWeights[preset as ComponentSize];
                }
            }
            return 0;
        }
    }

    /**
     * Removes fields from an entity that we do not want to serialise.
     * @param entity entity to remove fields from
     */
    private static removeUnserialisableFields(entity: ILayoutBaseComponent | ILayoutBaseColumn) {
        if (!entity.size) {
            // don't serialize if undefined
            delete entity.size;
        }
        delete entity.ref;
        delete entity.element;
    }

    /**
     * Creates the base structure for a new component.
     */
    public createComponent<T extends ILayoutBaseComponent>(id: string, fields: Partial<T> = {}) {
        return {
            id,
            ...fields,
            options: {
                enabled: true,
            } as T["options"],
        } as T;
    }

    /**
     * Creates the base structure for a new column.
     */
    public createColumn<T extends ILayoutBaseColumn>(fields: Partial<T> = {}) {
        return {
            ...fields,
            options: {
                enabled: true,
                components: [],
            } as T["options"],
        } as T;
    }

    /**
     * Loads layout from an entity which has an associated layout.
     * @param entityWithLayout entity with associated layout
     */
    public loadLayout<T extends EntityWithLayout>(entityWithLayout: T) {
        const config = entityWithLayout.layout?.configuration;
        if (config) {
            return JSON.parse(config) as ILayoutBase;
        }
        return undefined;
    }

    /**
     * Saves layout to an entity which supports layout.
     *
     * This works for entities that have a "layout" navigation property.
     * If the layout entity exists, it will be updated, else it will be created.
     * @param entityWithLayout entity which supports layout
     * @param layout layout to save to entity
     */
    public saveLayout<T extends EntityWithLayout>(entityWithLayout: T, layout: ILayoutBase): Observable<(T | Layout)[]> {
        if (layout.columns.length > 0 && layout.columns.some((col) => Object.keys(col).includes("ref"))) {
            throw new AdaptError("Cannot save a non-serialisable layout");
        }

        const layoutDefaults = {
            configuration: JSON.stringify(layout),
            organisationId: entityWithLayout.organisationId,
        };

        if (entityWithLayout.layout) {
            entityWithLayout.layout.configuration = layoutDefaults.configuration!;
            return of([entityWithLayout.layout]);
        } else {
            const layoutProp = "layout";
            const layoutNavProp = entityWithLayout.entityType.getNavigationProperty(layoutProp);
            if (!layoutNavProp) {
                throw new AdaptError("Entity does not have layout navigation property");
            }

            return this.commonDataService.create(LayoutBreezeModel, layoutDefaults).pipe(
                tap((layoutEntity: Layout) => entityWithLayout.layout = layoutEntity),
                map((layoutEntity: Layout) => [layoutEntity, entityWithLayout]),
            );
        }
    }
}

import { EntityWithLayout } from "@common/ux/layout/layout.interface";
import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { EntityExtensions } from "../entity-extensions.decorator";
import { Person } from "../person/person";
import { IEntityWithLabelLocations } from "./entity-with-label-locations";
import { LabelLocation } from "./label-location";
import { Layout } from "./layout";
import { Organisation } from "./organisation";
import { SystemComponent } from "./system-component";
import { SystemEntityExtensions } from "./system-entity-extensions";
import { SystemKnowledgeExpert } from "./system-knowledge-expert";
import { SystemLocation } from "./system-location";

export class SystemEntity extends BaseEntity<SystemEntity> implements EntityWithLayout, IEntityWithLabelLocations {
    public static readonly IconClass = "fal fa-fw fa-solar-system";

    public systemEntityId!: number;
    public organisationId!: number;
    public name!: string;
    public ownerId?: number;

    // navigation
    public organisation!: Organisation;
    public systemComponents!: SystemComponent[];
    public owner?: Person;
    public systemKnowledgeExperts!: SystemKnowledgeExpert[];
    public systemLocations!: SystemLocation[];
    public layoutId!: number | null;
    public layout!: Layout | null;
    public labelLocations!: LabelLocation[];

    @EntityExtensions(SystemEntityExtensions)
    public extensions!: SystemEntityExtensions;
}

export const SystemEntityBreezeModel = new BreezeModelBuilder("SystemEntity", SystemEntity)
    .withSingularName("System")
    .withPluralName("Systems")
    .hasSource()
    .withIdField("systemEntityId")
    .withLabelField("name")
    .isOrganisationEntity()
    .build();

import { Injector } from "@angular/core";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { Observable } from "rxjs";
import { DeepDive } from "./deep-dive";
import { Workflow } from "./workflow";

export enum WorkflowStepGuidancePosition {
    Top,
    Left,
}

export type ButtonCallbackType = (injector: Injector, workflowRunDialog: BaseDialogWithDiscardConfirmationComponent<any>) => Observable<any>;
export interface IButtonDefinition {
    buttonText: string,
    icon?: string,
    onClick?: ButtonCallbackType;
}

// this is only used locally - no longer a breeze entity
export class WorkflowStep {
    public workflowStepId!: string;
    public workflowId!: number;
    public name!: string;
    public ordinal!: number;
    public allowBack!: boolean;
    public canSkip!: boolean;

    public simpleHtml?: string;
    public articleSlug?: ImplementationKitArticle;
    public componentSelector?: string;
    public componentMinWidth?: string;
    public deepDiveId?: number;

    // navigation
    public workflow!: Workflow;
    public deepDive?: DeepDive;

    public hideTitle = false;
    public overviewComponentSelector?: string;
    public workflowStepNextText?: string;
    public workflowStepNextIcon?: string;
    public isOutcomeStep = false;
    public isActivityStep = false;
    public isConfirmationStep = false;
    public confirmationStepConfig?: { title: string, message?: string };
    public customData?: any;
    public closeAfterNext?: boolean;
    public showCancelButton?: boolean;
    public cancelButtonText?: string;
    public backButtonText?: string;
    public skipIfPreviousSkipped = false;
    public articlePosition: WorkflowStepGuidancePosition = WorkflowStepGuidancePosition.Top;
    public titleRightButtons?: IButtonDefinition[];
}

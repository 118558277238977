import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Person } from "./person";
import { PersonProfileItem } from "./person-profile-item";

export class PersonProfileItemValue extends BaseEntity<PersonProfileItemValue> {
    public personProfileItemValueId!: number;
    public personId!: number;
    public personProfileItemId!: number;
    public text?: string;
    public date?: Date;
    public isPrivate!: boolean;

    public person!: Person;
    public profileItem!: PersonProfileItem;

    public get isEmpty() {
        if (!this.profileItem.isDate) {
            return !this.text || this.text === "";
        }

        return this.date === undefined;
    }
}

export const PersonProfileItemValueBreezeModel = new BreezeModelBuilder("PersonProfileItemValue", PersonProfileItemValue)
    .withIdField()
    .hasSource()
    .isOrganisationEntity()
    .build();

<div class="select-image-component"
     id="{{triggerIdentifier}}"
     [ngClass]="{'edit-inline': inline, 'pe-none': uploading}"> <!-- pe-none to stop further uploading while it is uploading -->

    <adapt-storage-image [ngClass]="{'image-selector': !disabled}"
                         [hidden]="imageDataUrl || !(imageIdentifier || this.defaultImageSrc)"
                         [imageIdentifier]="imageIdentifier"
                         [imageHeight]="imageHeight"
                         [imageWidth]="imageWidth"
                         [defaultImageSrc]="defaultImageSrc"
                         (load)="showRealImage()"
                         (error)="showRealImage()"></adapt-storage-image>

    <img *ngIf="imageDataUrl"
         [style]="imageStyle"
         [src]="imageDataUrl"
         alt="">

    <!-- This is never used since we always define defaultImageSrc for all usage of <adapt-select-image>
        but just in case, change role to button for the cursor to appear clickable -->
    <div *ngIf="!imageDataUrl && !imageIdentifier && !this.defaultImageSrc"
         [ngClass]="{'disabled': disabled}"
         role="button">
        <i class="fal fa-fw fa-camera"></i>
        UPLOAD
    </div>

    <dx-file-uploader [accept]="acceptedFilterTypes.toString()"
                      dialogTrigger="#{{triggerIdentifier}}"
                      [visible]="false"
                      [disabled]="disabled"
                      [uploadMethod]="uploadMethod"
                      [uploadUrl]="uploadUrl"
                      [uploadHeaders]="uploadHeaders"
                      (onInitialized)="fileUploader = $event.component"
                      (onUploadError)="onUploadError($event)"
                      (onValueChanged)="onValueChanged($event)"
                      (onUploadStarted)="onUploadStarted()"
                      (onProgress)="onProgress($event)"
                      (onUploaded)="onUploaded($event)">
    </dx-file-uploader>

    <div *ngIf="error"
         class="alert alert-danger">{{error}}</div>
</div>

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RerenderDirective } from "./rerender.directive";


@NgModule({
    declarations: [
        RerenderDirective,
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        RerenderDirective,
    ],
})
export class AdaptRerenderModule {
}

import dxDataGrid from "devextreme/ui/data_grid";
import { DxComponentWrapper } from "./dx-component-wrapper";

export class DxGridWrapper extends DxComponentWrapper<dxDataGrid> {
    protected performRedraw(): void {
        this.component.updateDimensions();
    }

    /** Reset the state of the grid to its initial state */
    public resetState() {
        /* Although this is not documented in https://js.devexpress.com/Documentation/ApiReference/UI_Widgets/dxDataGrid/Methods/#statestate
           However, it is mentioned in
            https://www.devexpress.com/Support/Center/Question/Details/T213405/dxdatagrid-provide-the-capability-to-clear-the-saved-state
           that setting state to null will reset state */
        this.component.state(null);
        this.component.refresh();
    }

    /** Flag that the columns of this grid have been set up and ready to be rendered. */
    public setColumnsReady() {
        // noop
    }

    /** Register a callback to be called when the state of the grid is reset */
    public onStateReset(_callback: () => void) { // eslint-disable-line no-unused-vars, @typescript-eslint/no-unused-vars
        // noop
    }
}

export enum TourIdentifier {
    // shell
    AltoShellTour = "AltoShellTour",

    // pathway wrap ups
    HealthCheckWrapUpTour = "HealthCheckWrapUpTour",
    OwnersWhyWrapUpTour = "OwnersWhyWrapUpTour",
    AlignOwnersVisionWrapUpTour = "AlignOwnersVisionWrapUpTour",
    VisionStatementWrapUpTour = "VisionStatementWrapUpTour",
    PurposeStatementWrapUpTour = "PurposeStatementWrapUpTour",
    SetValuesWrapUpTour = "SetValuesWrapUpTour",
    StrategicThemesWrapUpTour = "StrategicThemesWrapUpTour",
    BullseyeWrapUpTour = "BullseyeWrapUpTour",
    StrategicAnchorsWrapUpTour = "StrategicAnchorsWrapUpTour",
    StrategicGoalsWrapUpTour = "StrategicGoalsWrapUpTour",
    ObjectiveFeatureTour = "ObjectiveFeatureTour",

    // feature tours
    RecordObjectivesFeatureTour = "RecordObjectivesFeatureTour",
    CreateKeyResultsForObjectiveFeatureTour = "CreateKeyResultsForObjectiveFeatureTour",

    SWTFeatureTour = "SWTFeatureTour",
    CompetitorsAnalysisTour = "CompetitorsAnalysisTour",
    StrategicInputsWrapUpTour = "StrategicInputsWrapUpTour",
    AttachInputsFeatureTour = "AttachInputsFeatureTour",
    AttachBullseyeStatementsFeatureTour = "AttachBullseyeStatementsFeatureTour",
    RecordAnchorsFeatureTour = "RecordAnchorsFeatureTour",
}

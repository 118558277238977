import { Injectable } from "@angular/core";
import { IDynamicNodeBuilder } from "@common/route/dynamic-node-builder";
import { INavigationNode } from "@common/route/navigation-node.interface";
import { NavigationUtilitiesService } from "@common/route/navigation-utilities.service";
import { Observable, of } from "rxjs";
import { personalDashboardPageRoute } from "../person/personal-dashboard-page/personal-dashboard-page.route";
import { organisationConfigurationPageRoute } from "./organisation-configuration-page/organisation-configuration-page.component";

@Injectable()
export class ConfigurationDynamicNavigationService implements IDynamicNodeBuilder {
    public activeNodeShouldBeRebuilt$: Observable<unknown> = of(false);

    public constructor(
        private navUtilitiesFactory: NavigationUtilitiesService,
    ) { }

    public buildDynamicNodes(): INavigationNode[] {
        return [
            this.buildDynamicConfigurationNode(),
        ];
    }

    private buildDynamicConfigurationNode() {
        return this.navUtilitiesFactory.nodeBuilderForController(organisationConfigurationPageRoute.id)
            .setDynamicParentController(personalDashboardPageRoute.id)
            .build();
    }
}

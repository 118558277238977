import { Injectable } from "@angular/core";
import { CommonSidebarConstants } from "@common/shell/common-sidebar/common-sidebar.constants";
import { ISidebarTab, SidebarTabPosition } from "@common/shell/common-sidebar/sidebar-tab";
import { ShellUiService } from "@common/shell/shell-ui.service";
import { SidebarTabIconComponent } from "@common/shell/sidebar-tab-icon/sidebar-tab-icon.component";
import { IComponentRender } from "@common/ux/render-component/component-render.interface";
import { Observable, of, Subject } from "rxjs";
import { distinctUntilChanged, withLatestFrom } from "rxjs/operators";
import { SearchService } from "../search.service";
import { SearchTabContentComponent } from "./search-tab-content.component";

export const SearchTabId = "sidebar-search";
export const SearchTabIcon = "fal fa-fw fa-search";

@Injectable()
export class SearchTab implements ISidebarTab {
    public id = SearchTabId;
    public label = "Search";
    public ordinal = 4;
    public position = SidebarTabPosition.Bottom;
    public isLoading$: Observable<boolean>;
    public isDisplayed$: Observable<boolean>;
    public maxWidth = CommonSidebarConstants.SearchTabMaxWidth;

    private triggerFocus$ = new Subject<void>(); // init before being used by focusTab$
    public focusTab$ = this.triggerFocus$.asObservable();

    public icon: IComponentRender<SidebarTabIconComponent> = {
        component: SidebarTabIconComponent,
        params: {
            iconClass: SearchTabIcon,
            iconText: "Search",
        },
    };

    public content: IComponentRender<SearchTabContentComponent> = {
        component: SearchTabContentComponent,
    };

    public constructor(
        searchService: SearchService,
        shellUiService: ShellUiService,
    ) {
        this.isLoading$ = of(false);
        this.isDisplayed$ = of(true);

        shellUiService.sidebarTab$.pipe(
            distinctUntilChanged(),
            withLatestFrom(searchService.searchResults$),
        ).subscribe(([tab, results]) => {
            if (tab === this && results) {
                searchService.showResults();
            }
        });

        searchService.showSearchResults$.subscribe(() => {
            this.triggerFocus$.next();
        });
    }
}

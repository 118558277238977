import { AfterContentChecked, Directive, ElementRef, Input, OnChanges } from "@angular/core";

@Directive({
    selector: "[adaptLinkDisabled]",
})
export class LinkDisabledDirective implements OnChanges, AfterContentChecked {
    @Input("adaptLinkDisabled") public linkDisabled = false;

    private originalNode?: HTMLAnchorElement;

    constructor(
        private el: ElementRef<HTMLElement>,
    ) {
    }

    public ngOnChanges() {
        this.updateLink();
    }

    public ngAfterContentChecked() {
        this.updateLink();
    }

    private updateLink() {
        const anchorElement = this.el.nativeElement.querySelector("a");
        if (anchorElement) {
            if (!this.linkDisabled) {
                if (this.originalNode) {
                    anchorElement.replaceWith(this.originalNode);
                    anchorElement.dataset.linkDisabled = "false";
                }
            } else {
                if (anchorElement.href !== this.originalNode?.href || anchorElement.dataset.linkDisabled !== "true") {
                    this.originalNode = anchorElement;
                    anchorElement.dataset.linkDisabled = "true";

                    // clone node to remove event listeners
                    // we need to do this as routerLink still applies even when href is unset
                    const newNode = anchorElement.cloneNode(true) as HTMLAnchorElement;
                    newNode.removeAttribute("href");
                    anchorElement.replaceWith(newNode);
                }
            }
        }
    }
}

<adapt-dialog [maxWidth]="600"
              [expandable]="false"
              [showHelpButton]="false">
    <h3 adapt-dialog-title>Migrate values</h3>

    <div adapt-dialog-content>
        <ng-container *adaptLoading="loadingStatus">
            <p *ngIf="migrationData?.ok; else changesRequired">All your values are safe to migrate. You may proceed.</p>

            <ng-template #changesRequired>
                <div>
                    Some of your values have content we cannot automatically migrate.
                    You must remove the following from any value "Ask Yourself" questions:
                </div>
                <ul>
                    <li>Images</li>
                    <li>Tables</li>
                    <li>Lists (both ordered and unordered)</li>
                </ul>

                <ng-container *ngIf="teamsToUpdate.size > 0 || migrationData?.organisation">
                    <div>
                        The following values need to be updated.
                        Clicking the link will open the respective values page in a new tab.
                        If you do not have access to the values, please ask a team member to update them, or contact us for support.
                    </div>
                    <ul>
                        <li *ngIf="migrationData?.organisation">
                            <a [href]="organisationValuesRoute$ | async"
                               target="_blank"
                               rel="noopener">Organisation values</a>
                        </li>
                        <li *ngFor="let teamUrlKeyValue of teamsToUpdate | keyvalue">
                            <a [href]="teamUrlKeyValue.value"
                               target="_blank"
                               rel="noopener">
                                <adapt-team-private-icon [team]="teamUrlKeyValue.key"></adapt-team-private-icon>{{teamUrlKeyValue.key.name}}
                                team values
                            </a>
                        </li>
                    </ul>
                </ng-container>
            </ng-template>
        </ng-container>

        <button adaptButton="refreshLink"
                [disabled]="loadingStatus"
                [adaptBlockingClick]="migrateValues">Check values</button>

        <div *ngIf="migrationData?.ok; else notOk"
             class="mt-3">
            <dx-check-box [value]="checkboxConfirmation"
                          (valueChange)="checkboxConfirmation = $any($event)"
                          data-test="confirm-checkbox"
                          text="Confirm that you want to migrate to behaviour-based values. This cannot be reverted."></dx-check-box>
        </div>

        <ng-template #notOk>
            <p class="mt-3 mb-0">You must update the above values before proceeding.</p>
        </ng-template>
    </div>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveButtonPreset="mergePrimary"
                                   saveText="Migrate values"
                                   [saveIsDisabled]="!checkboxConfirmation || !migrationData?.ok"
                                   [saveBlockingClick]="performMigrateValues"
                                   (cancelClick)="cancel()"></adapt-standard-dialog-buttons>
</adapt-dialog>

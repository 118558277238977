import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { CommonPurposeVisionAuthService } from "@org-common/lib/purpose-vision/common-purpose-vision-auth.service";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { PurposeVisionPageComponent } from "./purpose-vision-page.component";

export const purposeVisionPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-purpose-vision-page", PurposeVisionPageComponent)
    .atOrganisationUrl("/purpose-and-vision")
    .verifyingFeatures(FeatureName.PurposeAndVision)
    .verifyingAccess(CommonPurposeVisionAuthService.ReadPurposeVision)
    .withTitle("Purpose & vision")
    .build();

import { Injectable, Injector } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Observable, of } from "rxjs";
import { BaseOrganisationService } from "../../organisation/base-organisation.service";

@Injectable({
    providedIn: "root",
})
export class TeamDashboardSharedService extends BaseOrganisationService {
    private teamDashboardLinkProvider?: (team: Team) => Observable<string>;

    public constructor(
        injector: Injector,
    ) {
        super(injector);
    }

    public registerTeamDashboardLinkProvider(provider: (team: Team) => Observable<string>) {
        this.teamDashboardLinkProvider = provider;
    }

    public getTeamDashboardLink(team: Team) {
        return this.teamDashboardLinkProvider ? this.teamDashboardLinkProvider(team) : of(undefined);
    }
}

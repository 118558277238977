<ng-container *adaptLoading="!isInitialised">
    <div>
        <adapt-render-zone-map [zoneContentTemplate]="zoneContentTemplate"
                               [fullLayoutBreakpoint]="FullLayoutBreakpoint"
                               [zoneMenuTemplate]="zoneMenuTemplate"></adapt-render-zone-map>
    </div>
</ng-container>

<ng-template #zoneContentTemplate
             let-zone>
    <adapt-strategy-zone [zone]="zone"
                         [isEditing]="isEditing && hasEditAccess"
                         [hasThemes]="hasTheme"
                         [views]="views"></adapt-strategy-zone>
</ng-template>

<ng-template #zoneMenuTemplate
             let-zone>
    <button class="btn btn-blank btn-inherit-colour py-1 px-2 me-1"
            [adaptBlockingClick]="addThemeInZone"
            [adaptBlockingClickParam]="zone"
            adaptTooltip="Add strategic theme"
            adaptButtonIcon="fas fa-plus"></button>
</ng-template>

<ng-template #noGoalsOrThemesTemplate>
    No items on the strategic working board yet - add a strategic theme using the button above
</ng-template>

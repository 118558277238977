import { APP_INITIALIZER, NgModule } from "@angular/core";
import { IdentityService } from "@common/identity/identity.service";
import { ISessionData } from "@common/identity/identity-storage.service";
import { SignalRService } from "./signalr.service";

@NgModule({
    providers: [
        {
            // initialise signalR on app initialise
            provide: APP_INITIALIZER,
            useFactory: (signalRService: SignalRService, identityService: IdentityService) => () => {
                SignalRService.registerAccessTokenFactory(() =>
                    identityService.promiseToGetAccessToken().then((token) => token ?? ""));
                
                signalRService.setup();

                // moved this to after setup as this will be immediate (from a behavior subject)
                // - otherwise connect attempt before setup
                identityService.identity$.subscribe((sessionData?: ISessionData) => {
                    if (sessionData) {
                        signalRService.promiseToConnect();
                    } else {
                        signalRService.disconnect();
                    }
                });

                // disconnect when the identity is lost in this tab
                identityService.identityChangedInAnotherTab$.subscribe(() => {
                    signalRService.disconnect();
                });
            },
            deps: [SignalRService, IdentityService],
            multi: true,
        },
    ],
})
export class AdaptSignalRModule {}

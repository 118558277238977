import { Component, Inject } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { DisableEntityPersistent } from "@common/lib/data/persistable-dialog.decorator";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { forkJoin, from, Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { ObjectiveViewType } from "../objective-view-type.enum";
import { ObjectivesService } from "../objectives.service";
import { ObjectivesRouteService } from "../objectives-route.service";

@Component({
    selector: "adapt-move-objective-dialog",
    templateUrl: "./move-objective-dialog.component.html",
    styleUrls: ["./move-objective-dialog.component.scss"],
})
@DisableEntityPersistent()
export class MoveObjectiveDialogComponent extends BaseDialogWithDiscardConfirmationComponent<Objective> {
    public readonly dialogName = "MoveObjectiveDialog";

    public originTeam?: Team;
    public makeOrganisationObjective?: boolean | null = false;

    public canMoveToOrg = false;
    public canMoveToTeam = false;
    public canMoveBetweenTeams = false;
    public canMove = false;

    constructor(
        private objectiveRouteService: ObjectivesRouteService,
        private featuresService: FeaturesService,
        @Inject(ADAPT_DIALOG_DATA) public objective: Objective,
        objectivesService: ObjectivesService,
    ) {
        super();

        this.originTeam = objective.team;
        this.autoResolveData = objective;

        // Prime objectives first as different filter condition may result in parent/child objectives not primed, causing incorrect move
        // need the type cast here as getObjectiveById type is different than getObjectivesWithParentId
        const primeObservables: [Observable<unknown>] = [objectivesService.getObjectivesWithParentId(objective.objectiveId)];
        if (objective.parentObjectiveId && !objective.parentObjective) {
            primeObservables.push(objectivesService.getObjectiveById(objective.parentObjectiveId));
        }
        forkJoin(primeObservables).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            const teamObjectiveWithOrgParent = !!this.originTeam && objective.parentObjective && !objective.parentObjective.teamId;
            const teamObjectiveWithoutChildren = !!this.originTeam && !objective.childObjectives?.length;
            this.canMoveBetweenTeams = !!this.originTeam && teamObjectiveWithoutChildren &&
                (!objective.parentObjectiveId || teamObjectiveWithOrgParent);
            this.canMoveToOrg = !!this.originTeam && !objective.parentObjectiveId;
            this.canMoveToTeam = !this.originTeam && !objective.childObjectives?.length;
            this.canMove = this.canMoveToOrg || this.canMoveToTeam || this.canMoveBetweenTeams;
            this.isInitialised = true;
        });
    }

    public get entitiesToConfirm() {
        return [this.objective];
    }

    public saveIsDisabled() {
        if (!this.canMove || !this.makeOrganisationObjective && this.objective.entityAspect.entityState.isUnchanged()) {
            return true;
        }

        return false;
    }

    public onTeamChange() {
        if (this.makeOrganisationObjective && this.objective.team) {
            this.makeOrganisationObjective = false;
        }
    }

    public onMakeOrgObjectiveChange() {
        if (this.objective.team && this.makeOrganisationObjective) {
            this.objective.team = undefined;
        } else {
            // this is for tick and untick
            this.objective.entityAspect.rejectChanges();
        }
    }

    public save() {
        from(super.saveAndClose()).pipe(
            switchMap(() => this.objectiveRouteService.gotoObjectivePageRoute(this.objective.teamId, ObjectiveViewType.ObjectiveTreeView)),
        ).subscribe();
    }

    @Autobind
    public hasObjectivesEnabled(team: Team) {
        return this.featuresService.isFeatureActive(FeatureName.StewardshipObjectives, team) && team !== this.originTeam;
    }
}

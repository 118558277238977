import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptShellModule } from "@common/shell/shell.module";
import { AdaptButtonModule } from "@common/ux/button/button.module";
import { AdaptMenuModule } from "@common/ux/menu/menu.module";
import { SortablejsModule } from "nxt-sortablejs";
import { AdaptTooltipModule } from "../tooltip.directive/tooltip.module";
import { LayoutColumnComponent } from "./layout-column/layout-column.component";
import { LayoutColumnEnabledDirective } from "./layout-column-enabled.directive";
import { LayoutComponentComponent } from "./layout-component/layout-component.component";
import { LayoutComponentEnabledDirective } from "./layout-component-enabled.directive";
import { LayoutManagerComponent } from "./layout-manager/layout-manager.component";
import { LayoutManagerControlsComponent } from "./layout-manager-controls/layout-manager-controls.component";


@NgModule({
    declarations: [
        LayoutManagerComponent,
        LayoutComponentComponent,
        LayoutColumnComponent,
        LayoutManagerControlsComponent,
        LayoutComponentEnabledDirective,
        LayoutColumnEnabledDirective,
    ],
    imports: [
        CommonModule,
        AdaptButtonModule,
        AdaptShellModule,
        AdaptMenuModule,
        SortablejsModule,
        AdaptTooltipModule,
    ],
    exports: [
        LayoutManagerComponent,
        LayoutComponentComponent,
        LayoutColumnComponent,
        LayoutManagerControlsComponent,
        LayoutComponentEnabledDirective,
        LayoutColumnEnabledDirective,
    ],
})
export class AdaptLayoutModule {
}

<adapt-dialog [maxWidth]="600"
              [showHelpButton]="false"
              [expandable]="false">
    <h3 adapt-dialog-title>Select assignee for actions</h3>

    <ng-container adapt-dialog-content>
        <div class="form-group">
            <label for="inputAssignee">Assignee</label>
            <adapt-select-person id="inputAssignee"
                                 [(person)]="selectedPerson"
                                 [filter]="hasAccessToView"
                                 data-test="item-assignee"></adapt-select-person>
        </div>

        <div class="alert alert-warning">
            <p>Any assignee will be unassigned for the selected actions if you do not select a person.</p>
            <p *ngIf="data.isPersonalBoardView">If you change the assignee for these actions to someone other than
                yourself, these actions will not be visible in your personal actions view anymore.</p>
        </div>
    </ng-container>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveButtonPreset="saveAndClose"
                                   (saveClick)="resolve(selectedPerson)"
                                   (cancelClick)="cancel()"></adapt-standard-dialog-buttons>
</adapt-dialog>

import { InjectionToken, Provider } from "@angular/core";
import { IAdaptRoute } from "@common/route/page-route-builder";

export const THEME_TEST_PAGES = new InjectionToken<IThemeTestPage[]>("THEME_TEST_PAGES");

export interface IThemeTestPage {
    route: IAdaptRoute<any>;
    title: string;
    sections?: {id: string, title: string}[];
}

export function provideThemeTestPage(route: IThemeTestPage): Provider {
    return {
        provide: THEME_TEST_PAGES,
        useValue: route,
        multi: true,
    };
}

<ng-container *ngIf="anchors.length > 0; else noAnchor">
    <dx-scroll-view [scrollByContent]="true"
                    direction="vertical"
                    class="scroll-view">
        <dx-sortable data-test="anchors-list"
                     [data]="anchors"
                     [ngClass]="{'dragging-anchor': isDraggingAnchor}"
                     itemOrientation="vertical"
                     handle="#anchorGripper"
                     [allowReordering]="true"
                     [dragDirection]="'vertical'"
                     dropFeedbackMode="push"
                     [allowDropInsideItem]="false"
                     (onDragStart)="onAnchorDragStart($event)"
                     (onReorder)="reorderAnchors($event)">
            <adapt-strategic-anchor *ngFor="let anchor of anchors"
                                    class="avoid-page-break-inside"
                                    [anchor]="anchor"
                                    [isEditing]="isEditing"
                                    [isCollapsible]="!isEditing"
                                    [collapsed]="collapsed">
            </adapt-strategic-anchor>
        </dx-sortable>
    </dx-scroll-view>
</ng-container>

<ng-template #noAnchor>
    No strategic anchors have been defined.
</ng-template>

import { Injectable } from "@angular/core";
import { forkJoin, from, last, ObservableInput, of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { IdentityStorageService } from "../../identity/identity-storage.service";
import { CommonDataService } from "../../lib/data/common-data.service";
import { ArrayUtilities } from "../../lib/utilities/array-utilities";
import { UrlUtilities } from "../../lib/utilities/url-utilities";
import { AdaptCommonDialogService } from "../../ux/adapt-common-dialog/adapt-common-dialog.service";
import { UnsavedChangesDialogComponent } from "./unsaved-changes-dialog/unsaved-changes-dialog.component";

export enum ChangeAction {
    SaveAndContinue = "SAVE_AND_CONTINUE",
    DiscardAndContinue = "DISCARD_AND_CONTINUE",
    Stay = "STAY",
    Continue = "CONTINUE",
}

interface ICallbackContainer {
    cleanup: (() => void)[];
    hasChanges: (() => boolean)[];
    customSave: (() => void)[];
    customDiscard: (() => void)[];
    customRestore: (() => void)[];
}

@Injectable({
    providedIn: "root",
})
export class ChangeManagerService {
    private callbacks: ICallbackContainer = {
        cleanup: [],
        hasChanges: [],
        customSave: [],
        customDiscard: [],
        customRestore: [],
    };

    private blockRoute = false;
    private unblockQueryParam = false;

    public registerHasChangesFunction = this.getRegisterCallbackFor("hasChanges");
    public registerCleanupFunction = this.getRegisterCallbackFor("cleanup");
    public registerCustomSaveFunction = this.getRegisterCallbackFor("customSave");
    public registerCustomDiscardFunction = this.getRegisterCallbackFor("customDiscard");
    public registerCustomRestoreFunction = this.getRegisterCallbackFor("customRestore");

    constructor(
        private identityStorageService: IdentityStorageService,
        private dialogService: AdaptCommonDialogService,
        private commonDataService: CommonDataService,
    ) { }

    public blockRouteOnUnsavedChanges() {
        this.blockRoute = true;
    }

    public unblockOnQueryParamChanges() {
        this.unblockQueryParam = true;
    }

    public hasUnsavedChanges() {
        return this.commonDataService.hasChanges() || this.callbacks.hasChanges.some((cb) => cb());
    }

    private getRegisterCallbackFor(name: keyof ICallbackContainer) {
        return (callback: any) => {
            const callbackGroup = this.callbacks[name];
            callbackGroup.push(callback);

            return () => {
                ArrayUtilities.removeElementFromArray(callback, callbackGroup);
            };
        };
    }

    public interceptRouteChange(destinationUrl: string, previousUrl: string) {
        if (!this.blockRoute
            || !this.identityStorageService.isLoggedIn
            || destinationUrl === previousUrl
        ) {
            return of(true);
        }

        const destinationUrlWithoutQuery = UrlUtilities.getUrlWithoutQueryString(destinationUrl);
        const previousUrlWithoutQuery = UrlUtilities.getUrlWithoutQueryString(previousUrl);

        if (this.unblockQueryParam && destinationUrlWithoutQuery === previousUrlWithoutQuery) {
            // not going to block query param changes
            return of(true);
        }

        // Heuristic, don't bother running any logic if there are no changes
        if (!this.hasUnsavedChanges()) {
            if (destinationUrlWithoutQuery !== previousUrlWithoutQuery) {
                this.cleanupRouteBlocker();
            }
            return of(true);
        }

        // returning false if staying or error happened -> which will result in NavigationCancel
        return this.checkForChangesAndPrompt().pipe(
            map((changeAction: ChangeAction) => changeAction !== ChangeAction.Stay),
            catchError(() => of(false)),
        );
    }

    public checkForChangesAndPrompt() {
        if (this.commonDataService.saveInProgress) {
            return this.notifySaveInProgress();
        }

        return this.runCleanupCallbacks().pipe(
            switchMap(() => {
                if (this.hasUnsavedChanges()) {
                    return this.promptForUnsavedChanges().pipe(
                        // "no elements in sequence" if user closes dialog using esc.
                        // this makes sure the default action is Stay in that case.
                        last(null, ChangeAction.Stay),
                        switchMap(this.handleDialog.bind(this)),
                    );
                }
                return of(ChangeAction.Continue);
            }),
            tap((changeAction) => {
                if (changeAction !== ChangeAction.Stay && changeAction !== ChangeAction.Continue) {
                    this.cleanupRouteBlocker();
                }
            }),
        );
    }

    public promptForUnsavedChanges() {
        const valid = this.commonDataService.entitiesAreValid();

        const dialog = {
            message: valid
                ? "You have unsaved changes, what would you like to do?"
                : "You have entered invalid data yet to be saved. What would you like to do?",
            entitiesValid: valid,
        };

        return this.dialogService.open(UnsavedChangesDialogComponent, dialog);
    }

    private handleDialog(changeAction: ChangeAction) {
        let promises: ObservableInput<any>[];

        switch (changeAction) {
            case ChangeAction.SaveAndContinue:
                promises = [...this.callbacks.customSave.map(async (cb) => cb()), this.commonDataService.save()];
                break;
            case ChangeAction.DiscardAndContinue:
                promises = [...this.callbacks.customDiscard.map(async (cb) => cb()), this.commonDataService.cancel()];
                break;
            case ChangeAction.Continue:
            case ChangeAction.Stay:
                // ensure all customRestore's resolve before completing the prompt dialog promise chain
                promises = this.callbacks.customRestore.map(async (cb) => cb());
                break;
            default:
                throw new Error("Unknown ChangeAction");
        }

        // forkJoin does not continue chain if no results/promises
        const func = promises.length > 0 ? forkJoin(promises) : of([]);
        return func.pipe(
            catchError((err) => {
                console.log("handleDialog catchError", func, err);
                return this.notifyFailure().pipe(
                    switchMap(() => Promise.reject(err)),
                );
            }),
            map(() => changeAction),
        );
    }

    private runCleanupCallbacks() {
        if (this.callbacks.cleanup.length === 0) {
            return of([]);
        }
        return forkJoin(this.callbacks.cleanup.map(async (cb) => cb()));
    }

    private notifySaveInProgress() {
        const title = "Save in Progress";
        const message = "We are currently saving your changes. You will automatically be redirected once the save is completed.";

        const saveInProgressDialogSubscription = this.dialogService.showMessageDialog(title, message).subscribe();

        return from(this.commonDataService.savePromise).pipe(
            tap(() => saveInProgressDialogSubscription.unsubscribe()),
            map(() => ChangeAction.Continue),
            catchError(() => {
                saveInProgressDialogSubscription.unsubscribe();
                return this.notifyFailure().pipe(map(() => ChangeAction.Stay));
            }),
        );
    }

    private notifyFailure() {
        // not using showErrorDialog as that emits EMPTY, we need to continue the chain here...
        return this.dialogService.showMessageDialog("Failed to save",
            "Failed to save your data. Please copy your changes externally "
            + "(e.g. to Word), reload the page and try again.", "Close");
    }

    private cleanupRouteBlocker() {
        this.blockRoute = false;
        this.unblockQueryParam = false;

        Object.keys(this.callbacks).forEach((name: keyof ICallbackContainer) => {
            this.callbacks[name] = [];
        });
    }
}

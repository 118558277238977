import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { UserType, UserTypeExtensions } from "@common/ADAPT.Common.Model/embed/user-type";
import { ConnectionType } from "@common/ADAPT.Common.Model/organisation/connection-type";

@Component({
    selector: "adapt-select-user-type",
    templateUrl: "./select-user-type.component.html",
})
export class SelectUserTypeComponent implements OnChanges {
    @Input() public userType!: UserType;
    @Output() public userTypeChange = new EventEmitter<UserType>();
    @Input() public connectionType!: ConnectionType;

    public userTypes: UserType[] = [];
    public UserTypeExtensions = UserTypeExtensions;

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.connectionType) {
            if (this.connectionType === ConnectionType.Employee) {
                this.userTypes = [UserType.Leader, UserType.Collaborator, UserType.Viewer];
            } else {
                this.userTypes = [UserType.Leader, UserType.Collaborator, UserType.Viewer, UserType.None];
            }
        }
    }
}

<adapt-dialog [maxWidth]="600"
              [expandable]="false"
              [showHelpButton]="false">
    <h3 adapt-dialog-title>{{dialogTitle}}</h3>

    <adapt-display-seeds adapt-dialog-content
                         [seedType]="seedEntity"></adapt-display-seeds>

    <adapt-standard-dialog-buttons adapt-dialog-footer
                                   saveButtonPreset="close"
                                   [hideCancelButton]="true"
                                   (saveClick)="cancel()"></adapt-standard-dialog-buttons>
</adapt-dialog>

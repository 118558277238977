import { Validator } from "breeze-client";
import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Zone } from "../methodology/zone";
import { IEntityWithLabelLocations } from "./entity-with-label-locations";
import { IKeyFunctionLocation, KeyFunctionLocation } from "./key-function-location";
import { KeyFunctionSupplementaryData } from "./key-function-supplementary-data";
import { LabelLocation } from "./label-location";
import { Organisation } from "./organisation";
import { Role } from "./role";
import { ValueStream } from "./value-stream";

export class KeyFunction extends BaseEntity<KeyFunction> implements IKeyFunctionLocation, IEntityWithLabelLocations {
    public static readonly IconClass = "fas fa-gear";

    public keyFunctionId!: number;
    public organisationId!: number;
    public leaderRoleId!: number;
    public name!: string;
    public zone!: Zone | null;
    public valueStreamId!: number | null;
    public ordinal!: number;

    public organisation!: Organisation | null;
    public leaderRole!: Role | null;
    public valueStream!: ValueStream | null;
    public supplementaryData!: KeyFunctionSupplementaryData | null;
    public labelLocations!: LabelLocation[];

    public isInLocation(location: IKeyFunctionLocation) {
        return KeyFunctionLocation.areEqual(this, location);
    }
}

export const validKeyFunctionLocationValidator = new Validator(
    "validKeyFunctionLocationValidator",
    (keyFunction: KeyFunction) => !!keyFunction.zone || !!keyFunction.valueStreamId,
    { messageTemplate: "Location is required" },
);

export const KeyFunctionBreezeModel = new BreezeModelBuilder("KeyFunction", KeyFunction)
    .hasSource()
    .isOrganisationEntity()
    .withIdField()
    .orderBy("ordinal")
    .withEntityValidator(validKeyFunctionLocationValidator)
    .build();

import { RawClientConfiguration } from "@common/configuration/adapt-client-configuration";
import { clientConfiguration as baseClientConfiguration } from "@common/configuration/environment.base";

export const clientConfiguration: Partial<RawClientConfiguration> = Object.assign({}, baseClientConfiguration, {
    AdaptProjectName: "Alto",
    GoogleClientId: "799098505879-rbfcffgn36d6u13s7o8bhk4v4q1o6pi7.apps.googleusercontent.com",
    CalendlyBaseUri: "https://calendly.com/adapt-hq-dev",
    GoogleTagManagerId: "GTM-KRVG7HS8",
    MetaPixelId: "899986201975347",
} as Partial<RawClientConfiguration>);

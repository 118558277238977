import { Component, Inject, ViewChild } from "@angular/core";
import { ContactType, ContactTypeMetadata } from "@common/ADAPT.Common.Model/embed/contact-type";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { PersonContact, PersonContactBreezeModel } from "@common/ADAPT.Common.Model/person/person-contact";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { DxValidationGroupComponent } from "devextreme-angular";
import { EMPTY, forkJoin, lastValueFrom, Observable, of, switchMap, throwError } from "rxjs";
import { catchError, filter, map, startWith, tap } from "rxjs/operators";

interface IContactGroup {
    contactType: ContactTypeMetadata;
    contactDetails: PersonContact[];
}

@Component({
    selector: "adapt-edit-person-contacts-dialog",
    templateUrl: "./edit-person-contacts-dialog.component.html",
})
export class EditPersonContactsDialogComponent extends BaseDialogWithDiscardConfirmationComponent<Person> {
    public readonly dialogName = "EditPersonContacts";
    public autoResolveData = this.person;

    @ViewChild(DxValidationGroupComponent) private validationGroup?: DxValidationGroupComponent;

    public saveDisabled = true;

    public ContactType = ContactType;

    public contactGroups$: Observable<IContactGroup[]>;

    private deletedEmailContacts: PersonContact[] = [];

    private deletedEntities: PersonContact[] = [];

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public person: Person,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        this.contactGroups$ = rxjsBreezeService.breezeEntityChanged$.pipe(
            map((value) => value.entity),
            filter(ObjectUtilities.createIsInstanceFilter(PersonContact)),
            startWith(undefined),
            map(() => this.buildContactGroups()),
        );

        rxjsBreezeService.breezeEntityChanged$.pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => this.validateEntities());
    }

    public contactGroupByType(_: number, contactGroup: IContactGroup) {
        return contactGroup.contactType;
    }

    public get entitiesToConfirm() {
        return [...this.person.personContacts, ...this.deletedEntities];
    }

    @Autobind
    public saveAndClose() {
        const observables = this.person.personContacts.map((personContact) => this.removeIfEmpty(personContact));
        const obs: Observable<any> = observables.length > 0 ? forkJoin(observables) : of(undefined);
        return obs.pipe(
            switchMap(() => this.hasDuplicateEmails()
                ? throwError(() => new Error("Duplicate email: cannot save multiple email entries of the same value"))
                : of(undefined)),
            switchMap(() => this.commonDataService.save()),
            tap(() => {
                this.deletedEmailContacts = [];
                this.resolve(this.person);
            }),
            catchError((e) => {
                this.handleError(e);
                return EMPTY;
            }),
        );
    }

    private buildContactGroups(): IContactGroup[] {
        const contactGroups = [];

        for (const contactType of ContactTypeMetadata.All) {
            contactGroups.push({
                contactType,
                contactDetails: this.person.personContacts.filter((personContact) => personContact.contactType === contactType.type),
            });
        }

        return contactGroups;
    }

    public validateEntities() {
        this.saveDisabled = !this.validationGroup || !this.validationGroup.instance.validate().isValid || this.entitiesAreUnmodifiedOrInvalid;
    }

    public promiseToAddContactDetail(contactTypeMetadata: ContactTypeMetadata) {
        return lastValueFrom(this.commonDataService.create(PersonContactBreezeModel, {
            personId: this.person.personId,
            contactType: contactTypeMetadata.type,
        }));
    }

    public isLoginEmail(personContact: PersonContact) {
        return personContact.contactType === ContactType.Email && personContact.isPreferred;
    }

    public async deleteContactDetail(contactType: ContactType, personContact: PersonContact) {
        await lastValueFrom(this.commonDataService.remove(personContact));

        this.deletedEntities.push(personContact);
        this.validateEntities();

        if (contactType === ContactType.Email) {
            this.deletedEmailContacts.push(personContact);
        }
    }

    private removeIfEmpty(personContact: PersonContact) {
        const trimmedValue = StringUtilities.trimHtml(personContact.value);

        if (trimmedValue) {
            return of(undefined);
        }

        return this.commonDataService.remove(personContact);
    }

    private hasDuplicateEmails() {
        return this.person.personContacts.some((personContact) => this.hasDuplicateEmail(personContact));
    }

    private hasDuplicateEmail(personContact: PersonContact) {
        if (personContact.contactType === ContactType.Email) {
            return this.person.personContacts.some((otherContact) => otherContact.contactType === ContactType.Email
                && otherContact !== personContact
                && otherContact.value === personContact.value,
            );
        }

        return false;
    }

    private handleError(error: Error) {
        let message = error.message;

        const preferredContact = this.person.personContacts.find((personContact) => personContact.isPreferred);

        if (preferredContact && message.includes("already in use")) {
            message = `Login email '${preferredContact.value}' is already in use.`;
        }

        this.setErrorMessage(message);
    }
}

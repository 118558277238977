import { exportDataGrid } from "devextreme/excel_exporter";
import dxDataGrid from "devextreme/ui/data_grid";
import saveAs from "file-saver";
import { Logger } from "../logger/logger";
import { StringUtilities } from "./string-utilities";

export class DxUtilities {
    protected static readonly log = Logger.getLogger("DxUtilities");
    public static async isCancelledFromPromise(promise: Promise<any>) {
        try {
            const value = await promise;
            // if the value of the promise is truthy, 'delete' was selected - therfore we do not want to cancel
            return !value;
        } catch (e) {
            // if the promise was rejected, 'cancel' was selected - therefore we do want to cancel
            return true;
        }
    }

    /**
     * @param {string} filename - The name of the file to be created, excluding extension
     * @param {dxDataGrid} component - The data grid to be saved as an Excel file
     */
    public static async exportGridToExcel(filename: string, component: dxDataGrid) {
        // import async since this package is huge, this will chunk it out and only load it when we need it
        const { Workbook } = (await import("exceljs")).default;

        if (StringUtilities.equalsIgnoreCase(filename, "DataGrid")) {
            this.log.error("File name not provided. Add fileName to dxo-export in given data grid");
        }

        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Sheet");

        await exportDataGrid({
            component,
            worksheet,
        });

        const buffer = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buffer], { type: "application/octet-stream" }), `${filename}.xlsx`);
    }
}

import { DefaultBackFinishButtons, DefaultBackNextButtons, DefaultBackTourButton, DefaultCancelProceedButtons, DefaultOKButtons, GuidedTour, GuidedTourStepOptions } from "@common/lib/guided-tour/guided-tour.service";
import { GuidedTourUtils } from "@common/lib/guided-tour/guided-tour.utils";
import { buttonPreset } from "@common/ux/button/button-preset";
import { ConfigurationTabIcon } from "@org-common/lib/configuration/configuration-tab-content/configuration-tab";
import { SearchTabIcon } from "@org-common/lib/search/search-tab-content/search-tab";
import { WorkflowIdentifier } from "@org-common/lib/workflow/workflow-identifier.enum";
import { OrganisationHierarchyNavigationService } from "../navigation/organisation-hierarchy-navigation.service";
import { PersonNavigationService } from "../navigation/person-navigation.service";
import { TeamHierarchyNavigationService } from "../navigation/team-hierarchy-navigation.service";

interface IDashboard {
    name: string;
    text: string;
    icon: string;
}

const dashboards: IDashboard[] = [
    { name: "Personal dashboard", text: "view your active pathways, meetings, actions and objectives.", icon: PersonNavigationService.IconClass },
    { name: "Team dashboard", text: "view the leadership team, upcoming meetings, actions and team guidance.", icon: TeamHierarchyNavigationService.IconClass },
    { name: "Organisation dashboard", text: "view the organisation's purpose, vision and values.", icon: OrganisationHierarchyNavigationService.IconClass },
    { name: "Strategy dashboard", text: "view your entire organisation's strategy on a single page.", icon: "fal fa-fw fa-bullseye-arrow" },
];

const dashboardText = dashboards.map((dashboard) => {
    return `<p><b><i class="${dashboard.icon}"></i> ${dashboard.name}</b>: ${dashboard.text}</p>`;
}).join("\n");

const actions: IDashboard[] = [
    { name: "Search", text: "search for anything inside the adapt HQ platform, such as people, teams, roles, work, objectives or support articles.", icon: SearchTabIcon },
    { name: "Configuration", text: "manage the platforms configuration, such as your subscription, billing, calendar integration and other features.", icon: ConfigurationTabIcon },
    { name: "Help", text: "view support articles, help & documentation for the adapt HQ platform.", icon: "fal fa-fw fa-circle-question" },
];

const actionsText = actions.map((action) => {
    return `<p><b><i class="${action.icon}"></i> ${action.name}</b>: ${action.text}</p>`;
}).join("\n");

const TakeTourAgainStep: GuidedTourStepOptions = {
    title: "Take the tour again",
    text: "You can take this tour again by clicking this button.",
    waitForAndAttachToElementSelector: `[data-tour="personal-dashboard-tour"]`,
    attachTo: {
        on: "bottom-start",
    },
    modalOverlayOpeningPadding: 3,
    buttons: [DefaultBackTourButton, DefaultOKButtons[0]],
};

export const PathwayStep: GuidedTourStepOptions = {
    id: "pathway-section",
    // this step will only be shown if there are pathways to complete
    title: "Your pathways",
    text: `<p>Pathways are units of work you can complete at your own pace. They can be for an individual, the owners or the wider leadership team.</p>
        <p>Each pathway has guidance about <b>why it is important</b> and <b>what the outcomes are</b> for you and your business.</p>
        <p>Here we will show you pathways we think you should work on next, as well as any pathways you are currently working on.</p>`,
    waitForAndAttachToElementSelector: "adapt-workflows-dashboard-element",
    attachTo: { on: "bottom" },
    showOn: () => {
        const pathways = GuidedTourUtils.getFirstElementWithTextContent(`adapt-workflows-dashboard-element`);
        return GuidedTourUtils.isElementVisible(pathways);
    },
    canClickTarget: false,
    buttons: DefaultOKButtons,
    width: "900px",
};

const inviteLeadershipTeamWorkflowSelector = `[data-workflow-id="${WorkflowIdentifier.InviteBusinessLeaders}"]`;
export const InviteLeadershipTeamStep: GuidedTourStepOptions = {
    title: "Invite your leadership team",
    text: `<p>We recommend completing this pathway next. Using this pathway, you can invite your leadership team to the platform.</p>
        <p>If you're not sure right now or you don't have any other leaders in your business, that’s ok,
           just skip this pathway and start doing the work - everything will become clear along the way</p>`,
    waitForAndAttachToElementSelector: inviteLeadershipTeamWorkflowSelector,
    attachTo: { on: "bottom-start" },
    showOn: () => GuidedTourUtils.isElementVisible(GuidedTourUtils.getFirstElementWithTextContent(inviteLeadershipTeamWorkflowSelector)),
    canClickTarget: false,
    buttons: DefaultOKButtons,
};

const understandJourneyWorkflowSelector = `[data-workflow-id="${WorkflowIdentifier.UnderstandJourney}"]`;
export const UnderstandJourneyStep: GuidedTourStepOptions = {
    title: "Understand your journey",
    text: `<p>We recommend completing this pathway next. Using this pathway, you can run a health check survey for your business.</p>
        <p>This survey allows you to determine the current state of your business.
           It will provide a baseline for where you are strong and where you can improve,
           as well as an understanding of the importance of doing the right work.</p>`,
    waitForAndAttachToElementSelector: understandJourneyWorkflowSelector,
    attachTo: { on: "bottom-start" },
    showOn: () => GuidedTourUtils.isElementVisible(GuidedTourUtils.getFirstElementWithTextContent(understandJourneyWorkflowSelector)),
    canClickTarget: false,
    buttons: DefaultOKButtons,
};

export const AltoShellTourButton: GuidedTour = {
    steps: [{
        ...TakeTourAgainStep,
        title: "Take the tour later.",
        text: "Take the tour anytime by clicking this button.",
        buttons: DefaultOKButtons,
    }],
};

export const AltoShellTour: GuidedTour = {
    steps: [{
        title: "Go to your personal dashboard",
        text: `<p>This menu allows you to navigate between pages on the platform.</p>
        <p><b>Click on here now</b> to open the menu.</p>`,
        attachTo: {
            element: `[id="sidebar-personal"]`,
            on: "right",
        },
        advanceOn: {
            selector: `[id="sidebar-personal"]`,
            event: "click",
        },
        showOn: () => {
            // this step will only be shown if personal-dashboard-tour is not there
            const personalDashboardTourNode = GuidedTourUtils.getFirstElementWithTextContent(
                `[data-tour="personal-dashboard-tour"]`);
            // need to look for the cta too for cases when the tour button is hidden due to cta not being dismissed.
            const personalDashboardTourCta = GuidedTourUtils.getFirstElementWithTextContent(
                `[data-tour="personal-dashboard-tour-cta"]`);
            return !GuidedTourUtils.isElementVisible(personalDashboardTourNode) && !GuidedTourUtils.isElementVisible(personalDashboardTourCta);
        },
    }, {
        title: "Your dashboards",
        text: `<p>Clicking on these will take you to the following dashboards:</p>
        ${dashboardText}`,
        waitForAndAttachToElementSelector: ".vertical-tabs-top",
        attachTo: {
            on: "right",
        },
        modalOverlayOpeningPadding: 3,
        canClickTarget: false,
        buttons: DefaultCancelProceedButtons,
    }, {
        title: "Platform actions",
        text: `<p>Clicking on these will let you do the following:</p>
        ${actionsText}`,
        waitForAndAttachToElementSelector: ".vertical-tabs-bottom",
        attachTo: {
            on: "right",
        },
        modalOverlayOpeningPadding: 3,
        canClickTarget: false,
        buttons: DefaultBackNextButtons,
    }, {
        title: "Menu",
        text: `<p>This is the main navigation for the platform. There will be links to all the important platform pages visible here.</p>
    <p>You can click here to collapse or expand the menu, and at any other time to show the navigation again.</p>`,
        waitForAndAttachToElementSelector: `[id="sidebar-menu"]`,
        attachTo: {
            on: "right",
        },
        modalOverlayOpeningPadding: 3,
        buttons: DefaultBackNextButtons,
    }, {
        title: "Navigation bar",
        text: `<p>This is the navigation bar. It shows the following:</p>
        <b>Organisation logo</b>:
            on the left is your organisation's logo. You can update this at any time through the organisation configuration.<br/><br/>
        <b>Navigation breadcrumbs</b>:
            next to your logo are the page breadcrumbs. You can click these at any time for easy navigation.<br/><br/>
        <b><i class="${buttonPreset.coachAccess.iconClass}"></i> Talk to a coach</b>:
            you can click this button to schedule some time with our coaches.
            If you are feeling stuck and would like some help from a real person, one of our coaches will be able to help.<br/><br/>
        <b>Your profile</b>:
            on the right is your profile. From there, you can edit your profile (such as your name & photo), or simply logout from the platform.`,
        waitForAndAttachToElementSelector: ".navbar",
        attachTo: {
            on: "bottom",
        },
        canClickTarget: false,
        buttons: DefaultBackNextButtons,
    }, {
        ...PathwayStep,
        buttons: DefaultBackNextButtons,
    }, {
        // this step will only be shown if intercom button is there
        title: "Chat with us",
        text: "You can chat with us if you have any issues or need help with the platform - this is available by clicking this icon.",
        waitForAndAttachToElementSelector: [".intercom-launcher-frame", ".intercom-launcher"],
        attachTo: {
            on: "top",
        },
        showOn: () => isIntercomVisible(),
        canClickTarget: false,
        buttons: DefaultBackNextButtons,
    }, {
        // this step will only be shown if intercom button is not there
        title: "Chat with us",
        text: "You can chat with us if you have any issues or need help with the platform - this is available by clicking the speech bubble in the bottom right corner.",
        showOn: () => !isIntercomVisible(),
        buttons: DefaultBackNextButtons,
    }, {
        ...TakeTourAgainStep,
        buttons: DefaultBackFinishButtons,
    }],
};

function isIntercomVisible() {
    const intercomFrame = GuidedTourUtils.getFirstElementWithTextContent(".intercom-launcher-frame");
    const intercomLauncher = GuidedTourUtils.getFirstElementWithTextContent(".intercom-launcher");
    return GuidedTourUtils.isElementVisible(intercomFrame) || GuidedTourUtils.isElementVisible(intercomLauncher);
}

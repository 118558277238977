import { Injectable } from "@angular/core";
import { Value } from "@common/ADAPT.Common.Model/organisation/value";
import { ValueQuestion } from "@common/ADAPT.Common.Model/organisation/value-question";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { map, switchMap } from "rxjs/operators";
import { OrganisationFeatureFlag } from "../features/organisation-feature-flag.enum";
import { OrganisationFeatureFlagService } from "../features/organisation-feature-flag.service";
import { WorkflowRunDialogComponent } from "../workflow/workflow-run-dialog/workflow-run-dialog.component";
import { createValueWorkflow, ICreateValueData } from "./create-value-workflow/create-value-workflow";
import { EditValueDialogComponent } from "./edit-value-dialog/edit-value-dialog.component";
import { EditValueQuestionDialogComponent } from "./edit-value-question-dialog/edit-value-question-dialog.component";
import { ValuesConstitutionService } from "./values-constitution.service";

@Injectable({
    providedIn: "root",
})
export class ValuesConstitutionUiService {
    public constructor(
        private dialogService: AdaptCommonDialogService,
        private vcService: ValuesConstitutionService,
        private flagService: OrganisationFeatureFlagService,
    ) {
    }

    public deleteValue(value: Value) {
        return this.yesNoConfirmation("Delete value", "Are you sure you wish to delete this value?").pipe(
            switchMap(() => this.vcService.deleteValue(value)),
        );
    }

    public editValue(value: Value) {
        return this.flagService.isFeatureFlagEnabled(OrganisationFeatureFlag.ValuesV2).pipe(
            switchMap((usingValuesV2) => usingValuesV2
                ? this.editValueWorkflow(value)
                : this.dialogService.open(EditValueDialogComponent, value)),
        );
    }

    public editValueWorkflow(value: Value) {
        return this.dialogService.open(WorkflowRunDialogComponent, {
            workflow: createValueWorkflow,
            runData: {
                valuesConstitution: value.valuesConstitution,
                value,
            } as ICreateValueData,
            titleOverride: value.entityAspect.entityState.isUnchangedOrModified()
                ? "Edit a value"
                : undefined,
        }).pipe(map(() => value));
    }

    public createAndEditValueQuestion(value: Value) {
        return this.vcService.createQuestionInValue(value).pipe(
            switchMap((vq) => this.editValueQuestion(vq)),
        );
    }

    public editValueQuestion(valueQuestion: ValueQuestion) {
        return this.dialogService.open(EditValueQuestionDialogComponent, valueQuestion);
    }

    public deleteValueQuestion(valueQuestion: ValueQuestion) {
        return this.yesNoConfirmation("Delete question", "Are you sure you wish to delete this question?").pipe(
            switchMap(() => this.vcService.deleteValueQuestion(valueQuestion)),
        );
    }

    private yesNoConfirmation(title: string, message: string) {
        return this.dialogService.openConfirmationDialog({
            title,
            message,
            confirmButtonText: "Yes",
            cancelButtonText: "No",
        });
    }
}

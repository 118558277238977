<ng-container *adaptLoading="!meeting">
    <div [ngSwitch]="meeting!.status"
         class="d-flex flex-column">
        <ng-container *ngSwitchCase="MeetingStatus.NotStarted">
            <div class="d-flex flex-column"
                 [innerHTML]="runWorkshopStepData?.notStartedContent"></div>
        </ng-container>
        <ng-container *ngSwitchCase="MeetingStatus.InProgress">
            <div class="d-flex flex-column"
                 [innerHTML]="runWorkshopStepData?.inProgressContent"></div>
        </ng-container>
        <ng-container *ngSwitchCase="MeetingStatus.Ended">
            <div class="d-flex flex-column"
                 [innerHTML]="runWorkshopStepData?.endedContent"></div>
        </ng-container>
        <adapt-styled-dashboard-element>
            <div class="d-flex justify-content-center">
                <adapt-meeting-dashboard-card [meeting]="meeting"></adapt-meeting-dashboard-card>
            </div>
        </adapt-styled-dashboard-element>
    </div>
</ng-container>

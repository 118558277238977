import { Component } from "@angular/core";
import { Observable } from "rxjs";
import { Person } from "../../ADAPT.Common.Model/person/person";
import { UserService } from "../../user/user.service";

@Component({
    selector: "adapt-application-bar-user-item",
    template: `
        <adapt-person-image [person]="loggedInUser$ | async"
                            data-test="personal-menu"
                            data-tour="personal-menu"></adapt-person-image>
    `,
})
export class ApplicationBarUserItemComponent {
    public loggedInUser$: Observable<Person | undefined>;
    public constructor(
        userService: UserService,
    ) {
        this.loggedInUser$ = userService.currentPerson$;
    }
}

import { FunctionUtilities } from "./function-utilities";

export class ElementUtilities {
    public static getSiblings(element: HTMLElement) {
        if (element.parentElement) {
            return Array.from(element.parentElement.children).filter((child) => child !== element);
        } else {
            return [];
        }
    }

    public static hasSiblingsOnTheSameRow(element: HTMLElement) {
        const boundingClientRect = element.getBoundingClientRect();
        const siblings = this.getSiblings(element);
        return siblings.some((sibling) => {
            const siblingClientRect = sibling.getBoundingClientRect();
            return this.isVisible(sibling) &&
                siblingClientRect.top >= boundingClientRect.top &&
                siblingClientRect.top < boundingClientRect.bottom;
        });
    }

    public static isVisible(element: Element) {
        const boundingClientRect = element.getBoundingClientRect();
        return boundingClientRect.width > 0 && boundingClientRect.height > 0;
    }

    public static isElementTopLeftMost(element: Element) {
        const boundingClientRect = element.getBoundingClientRect();
        const siblings = this.getSiblings(element as HTMLElement);
        const hasSiblingToTheLeftOrTop = siblings.some((sibling) => {
            const siblingClientRect = sibling.getBoundingClientRect();
            return this.isVisible(sibling) &&
                (siblingClientRect.top < boundingClientRect.top || siblingClientRect.left < boundingClientRect.left);
        });
        return !hasSiblingToTheLeftOrTop;
    }

    public static isElementInView(element: Element) {
        if (!this.isVisible(element)) {
            return false;
        }

        const boundingClientRect = element.getBoundingClientRect();
        const elementTop = boundingClientRect.top;
        const elementBottom = elementTop + boundingClientRect.bottom;
        return (elementTop >= 0 && elementTop <= window.innerHeight) || (elementBottom >= 0 && elementBottom <= window.innerHeight);
    }

    public static stringToElements(content: string) {
        return Array.from(new DOMParser().parseFromString(content, "text/html").body.children) as HTMLElement[];
    }

    public static removeAllChildrenWithSelector(element: HTMLElement, selector: string) {
        if (FunctionUtilities.isFunction(element.querySelectorAll)) {
            // sometimes text node get passed in as HTMLElement but they won't have the function
            const elements = element.querySelectorAll(selector);
            elements.forEach((removeElement) => element.removeChild(removeElement));
        }
        return element;
    }

    public static removeClassFromElement(element: HTMLElement, className: string) {
        if (element.classList) {
            if (element.classList.contains(className)) {
                element.classList.remove(className);
            }
        }
        return element;
    }

    public static isElementVerticallyWithinContainerBounds(container: DOMRect, element: DOMRect) {
        return element.bottom < container.bottom
            && container.top < element.top;
    }
}

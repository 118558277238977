<adapt-context-sidebar-implementation-kit [articleId]="ImplementationKitArticle.UsingTheAdaptTeamModel"
                                          context="page"></adapt-context-sidebar-implementation-kit>

<adapt-manage-surveys [surveyType]="surveyType"
                      [team]="team"
                      [callToActionTextTemplate]="callToActionText"
                      (initialise)="onInitialised()"></adapt-manage-surveys>

<ng-template #callToActionText>
    <p>
        Teams are the fundamental building blocks of a modern organisation. Finding a way
        of aligning everyone in a team to work effectively can be tough!
    </p>
    <p>
        Team Leaders need everyone to be accountable for their roles and relationships and
        deal with issues in an honest, authentic way.
    </p>
    <p>
        The adapt team assessment gives the team leader a way of measuring their team's
        effectiveness against each, highlighting where the focus on development needs to be.
    </p>

</ng-template>

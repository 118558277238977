import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { TeamActiveMeetingPageComponent } from "./team-active-meeting-page.component";

export const teamActiveMeetingPageRoute = new OrganisationPageRouteBuilder<{ teamId: number, meetingId: number }>()
    .usingNgComponent("adapt-team-active-meeting-page", TeamActiveMeetingPageComponent)
    .atOrganisationUrl("/team/:teamId/team-meetings/:meetingId")
    .verifyingFeatures(FeatureName.StewardshipWorkMeetings)
    .withTitle("Meeting")
    .reloadOnSearch(false)
    .build();

import { Workflow } from "@common/ADAPT.Common.Model/embed/workflow";
import { WorkflowIdentifier } from "@org-common/lib/workflow/workflow-identifier.enum";
import { alignTeamPurposeValuesWorkflow } from "./build-effective-team/align-team-purpose-values-workflow";
import { applyGoodStewardshipWorkflow } from "./build-effective-team/apply-good-stewardship/apply-good-stewardship-workflow";
import { assessEffectivenessWorkflow } from "./build-effective-team/assess-effectiveness-workflow";
import { buildTrustPsychologicalSafetyWorkflow } from "./build-effective-team/build-trust-psychological-safety-workflow";
import { meaningfulConversationsWorkflow } from "./build-effective-team/meaningful-conversations-workflow";
import { alignOwnersVisionJourneyWorkflow } from "./build-strategy-for-success/align-owners-vision/align-owners-vision-workflow";
import { BullseyeWorkflow } from "./build-strategy-for-success/bullseye/bullseye-workflow";
import { craftVisionWorkflow } from "./build-strategy-for-success/craft-vision/craft-vision-workflow";
import { defineOwnersWhyJourneyWorkflow } from "./build-strategy-for-success/define-owners-why/define-owners-why-workflow";
import { developStrategicAnchorsWorkflow } from "./build-strategy-for-success/develop-strategic-anchors-workflow";
import { gatherInputsWorkflow } from "./build-strategy-for-success/gather-inputs/gather-inputs-workflow";
import { purposeWorkflow } from "./build-strategy-for-success/purpose/purpose-workflow";
import { setStrategicGoalsWorkflow } from "./build-strategy-for-success/set-strategic-goals-workflow";
import { setValuesWorkflow } from "./build-strategy-for-success/set-values/set-values-workflow";
import { doTheRightWorkWorkflow } from "./do-the-right-work/do-the-right-work-workflow";
import { designFocusAccountabilityWorkflow } from "./focus-and-accountability/design-focus-accountability-workflow";
import { identifyStrategicThemesWorkflow } from "./identify-strategic-themes/identify-strategic-themes-workflow";
import { establishCadenceWorkflow, planYourStrategyCadenceWorkflow } from "./implement-your-strategy/establish-pulse/establish-cadence-workflows";
import { inviteBusinessLeaderWorkflow } from "./invite-business-leaders/invite-business-leader-workflow";
import { onboardingWorkflow } from "./onboarding/onboarding-workflow";
import { UnderstandJourneyWorkflow } from "./organisation-diagnostic/understand-journey-workflow";
import { knowYourCriticalNumbersWorkflow } from "./understand-your-critical-numbers/know-your-critical-numbers-workflow";

export const Workflows: Record<WorkflowIdentifier, Workflow> = {
    [WorkflowIdentifier.WelcomeOnboarding]: onboardingWorkflow,
    [WorkflowIdentifier.InviteBusinessLeaders]: inviteBusinessLeaderWorkflow,
    [WorkflowIdentifier.UnderstandJourney]: UnderstandJourneyWorkflow,
    [WorkflowIdentifier.PlanYourStrategyCadence]: planYourStrategyCadenceWorkflow,
    [WorkflowIdentifier.Bullseye]: BullseyeWorkflow,
    [WorkflowIdentifier.KnowYourCriticalNumbers]: knowYourCriticalNumbersWorkflow,
    [WorkflowIdentifier.EstablishBusinessCadence]: establishCadenceWorkflow,
    [WorkflowIdentifier.DefineOwnersWhy]: defineOwnersWhyJourneyWorkflow,
    [WorkflowIdentifier.AlignOwnersVision]: alignOwnersVisionJourneyWorkflow,
    [WorkflowIdentifier.CraftVision]: craftVisionWorkflow,
    [WorkflowIdentifier.Purpose]: purposeWorkflow,
    [WorkflowIdentifier.SetValues]: setValuesWorkflow,
    [WorkflowIdentifier.StrategicThemes]: identifyStrategicThemesWorkflow,
    [WorkflowIdentifier.StrategicGoals]: setStrategicGoalsWorkflow,
    [WorkflowIdentifier.StrategicAnchors]: developStrategicAnchorsWorkflow,
    [WorkflowIdentifier.GatherInputs]: gatherInputsWorkflow,
    [WorkflowIdentifier.DesignForFocusAndAccountability]: designFocusAccountabilityWorkflow,
    [WorkflowIdentifier.MeaningfulConversations]: meaningfulConversationsWorkflow,
    [WorkflowIdentifier.AlignTeamPurposeValues]: alignTeamPurposeValuesWorkflow,
    [WorkflowIdentifier.BuildTrustPsychologicalSafety]: buildTrustPsychologicalSafetyWorkflow,
    [WorkflowIdentifier.ApplyGoodStewardship]: applyGoodStewardshipWorkflow,
    [WorkflowIdentifier.AssessEffectiveness]: assessEffectivenessWorkflow,
    [WorkflowIdentifier.DoTheRightWork]: doTheRightWorkWorkflow,
};

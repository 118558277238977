// keep a separate enum list for identifier as workflow name is more likely to change
// - DO NOT change the value or the workflow connection and status entities will be broken
//
// *** THIS IS DUPLICATED IN WorkflowIdentifier.cs ***
// *** THIS IS DUPLICATED IN e2e/common/utility/pathway.ts ***
//
// todo: this is a string, not an enum!!!!

export enum WorkflowIdentifier {
    WelcomeOnboarding = "WelcomeOnboarding",
    UnderstandJourney = "UnderstandJourney",
    InviteBusinessLeaders = "InviteBusinessLeaders",

    /* ** build strategy ** */
    DefineOwnersWhy = "DefineOwnersWhy",
    Purpose = "Purpose",
    SetValues = "SetValues",
    AlignOwnersVision = "AlignOwnershipVision",
    CraftVision = "CraftVision",
    StrategicThemes = "StrategicThemes",
    GatherInputs = "GatherInputs",
    Bullseye = "Bullseye",
    StrategicAnchors = "StrategicAnchors",
    StrategicGoals = "StrategicGoals",

    /* ** implement strategy ** */
    PlanYourStrategyCadence = "PlanYourStrategyCadence",     // sub workflow
    EstablishBusinessCadence = "EstablishBusinessCadence",

    /* ** leadership team ** */
    DoTheRightWork = "DoTheRightWork",
    DesignForFocusAndAccountability = "DesignForFocusAndAccountability",
    MeaningfulConversations = "MeaningfulConversations",
    AlignTeamPurposeValues = "AlignTeamPurposeValues",
    BuildTrustPsychologicalSafety = "BuildTrustPsychologicalSafety",
    ApplyGoodStewardship = "ApplyGoodStewardship",
    AssessEffectiveness = "AssessEffectiveness",

    /* ** LATER!!!! ** */
    KnowYourCriticalNumbers = "KnowYourCriticalNumbers",
}

import { Component, Inject, Injector, OnDestroy } from "@angular/core";
import { KeyResult } from "@common/ADAPT.Common.Model/organisation/key-result";
import { KeyResultValue } from "@common/ADAPT.Common.Model/organisation/key-result-value";
import { ObjectiveComment } from "@common/ADAPT.Common.Model/organisation/objective-comment";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { EntityPersistentService } from "@common/lib/data/entity-persistent.service";
import { PersistableDialog } from "@common/lib/data/persistable-dialog.decorator";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { ADAPT_DIALOG_DATA } from "@common/ux/adapt-common-dialog/adapt-common-dialog.globals";
import { BaseDialogWithDiscardConfirmationComponent } from "@common/ux/adapt-common-dialog/base-dialog-with-discard-confirmation.component/base-dialog-with-discard-confirmation.component";
import { ChangeManagerService } from "@common/ux/change-manager/change-manager.service";
import { forkJoin, lastValueFrom, Observable, of, ReplaySubject, throwError } from "rxjs";
import { catchError, debounceTime, map, mergeMap, switchMap, tap } from "rxjs/operators";
import { ObjectivesService } from "../objectives.service";

export interface IUpdateKeyResultData {
    currentKeyResultValue?: KeyResultValue;
    keyResultValue: KeyResultValue;
    objectiveComment: ObjectiveComment;
}

@PersistableDialog("UpdateKeyResultDialogComponent")
@Component({
    selector: "adapt-update-key-result-dialog",
    templateUrl: "./update-key-result-dialog.component.html",
    styleUrls: ["./update-key-result-dialog.component.scss"],
})
export class UpdateKeyResultDialogComponent extends BaseDialogWithDiscardConfirmationComponent<IUpdateKeyResultData> implements OnDestroy {
    public readonly dialogName = "UpdateKeyResult";

    public keyResult: KeyResult;
    public keyResultValueUpdate$ = new ReplaySubject<number>(1);
    public sliderStep: number;
    public currentKeyResultValue?: KeyResultValue;
    public changeClass?: string;
    public changeIcon?: string;
    public difference$?: Observable<string>;
    public valueWidth: number;

    private cleanup: (() => void)[] = [];

    constructor(
        injector: Injector,
        protected commonDataService: CommonDataService,
        private objectivesService: ObjectivesService,
        @Inject(ADAPT_DIALOG_DATA) public data: IUpdateKeyResultData,
        private changeManager: ChangeManagerService,
        private entityPersistentService: EntityPersistentService,
    ) {
        super(injector);
        this.keyResult = data.keyResultValue.keyResult;
        this.currentKeyResultValue = data.currentKeyResultValue;
        this.sliderStep = calculateStepValue(this.keyResult.targetValue);

        // dxNumberBox looks too wide by default (with width: auto) - using max value here to calculate how wide it can be
        // - smallest is 1 - 9 will have 1 digit, 10 - 99 will have 2 digits, 100 - 999 will have 3, ...
        this.valueWidth = Math.floor(1 + Math.log10(this.keyResult.targetValue)) * 10 + 54; // 20 is the left-right padding and 34 is the button width

        this.keyResultValueUpdate$.pipe(
            debounceTime(100),
        ).subscribe((v) => {
            this.keyResultValue.value = v;
        });

        if (this.currentKeyResultValue) {
            this.difference$ = this.keyResultValueUpdate$.pipe(
                debounceTime(100),
                map((keyResultValue) => {
                    const valueDifference = keyResultValue - this.currentKeyResultValue!.value;
                    if (valueDifference > 0) {
                        this.changeClass = "positive-change";
                        this.changeIcon = "fal fa-arrow-up";
                    } else if (valueDifference < 0) {
                        this.changeClass = "negative-change";
                        this.changeIcon = "fal fa-arrow-down";
                    } else {
                        this.changeClass = "";
                        this.changeIcon = "";
                    }

                    return valueDifference === 0
                        ? "no change"
                        : this.keyResult.getFormattedValue(Math.abs(valueDifference));
                }));

            this.keyResultValueUpdate$.next(this.keyResultValue.value);
        }

        this.cleanup.push(this.changeManager.registerCleanupFunction(() => {
            const commentEntity = this.data.objectiveComment;
            if (commentEntity.entityAspect.entityState.isAdded() && !StringUtilities.trimHtml(commentEntity.comment)) {
                return lastValueFrom(this.commonDataService.remove(commentEntity));
            }
        }));
    }

    public get entitiesToConfirm() {
        return [this.keyResultValue, this.objectiveComment];
    }

    public get hasUnsavedEntity() {
        return [this.keyResultValue, this.objectiveComment].some((entity: IBreezeEntity) => this.entityPersistentService.isUnsavedEntity(entity));
    }

    public get keyResultValue() {
        return this.data.keyResultValue;
    }

    public get isNewValue() {
        return this.data.keyResultValue.entityAspect.entityState.isAdded();
    }

    public get isNewComment() {
        return this.data.objectiveComment.entityAspect.entityState.isAdded();
    }

    public get objectiveComment() {
        return this.data.objectiveComment;
    }

    public ngOnDestroy() {
        super.ngOnDestroy();
        this.keyResultValueUpdate$.complete();
        this.cleanup.forEach((cb) => cb());
    }

    public pushValueUpdate(value: number) {
        this.keyResultValueUpdate$.next(value);
    }

    @Autobind
    public tooltipChange(value: number) {
        return this.keyResult.getFormattedValue(value);
    }

    @Autobind
    public save() {
        const entitiesToSave: IBreezeEntity[] = [this.keyResultValue];
        const entitiesToReject: IBreezeEntity[] = [];
        const entitiesToDelete: IBreezeEntity[] = [];

        if (this.isNewValue) {
            this.keyResultValue.dateTime = new Date();
        }

        if (this.objectiveComment.comment) {
            this.objectiveComment.dateTime = this.keyResultValue.dateTime;
            entitiesToSave.push(this.objectiveComment);
        } else if (this.isNewComment) {
            entitiesToReject.push(this.objectiveComment);
        } else {
            entitiesToDelete.push(this.objectiveComment);
            entitiesToSave.push(this.objectiveComment);
        }

        return this.commonDataService.rejectChanges(entitiesToReject)
            .pipe(
                switchMap(() => entitiesToDelete.length
                    ? forkJoin(entitiesToDelete.map((entity) => this.commonDataService.remove(entity)))
                    : of([])),
                mergeMap(() => this.commonDataService.saveEntities(entitiesToSave)),
                tap(() => {
                    this.resolve(this.data);
                    this.objectivesService.emitKeyResultValueUpdate(this.keyResultValue);
                }),
                catchError((e) => {
                    this.setErrorMessage(e);
                    return throwError(() => e);
                }),
            );
    }
}

export function calculateStepValue(target: number) {
    const stepBase = target / 100;

    if (stepBase < 2) {
        return 1;
    } else if (stepBase < 5) {
        return 2;
    } else if (stepBase < 10) {
        return 5;
    }

    const stepPower = Math.floor(Math.log10(stepBase));
    const scaledStep = Math.pow(10, stepPower);
    return scaledStep;
}

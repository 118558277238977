import { Pipe, PipeTransform } from "@angular/core";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import moment from "moment";
import { Observable, timer } from "rxjs";
import { map } from "rxjs/operators";

@Pipe({
    name: "timeFromTimer$",
})
export class TimeFromTimerPipe implements PipeTransform {
    private timer$: Observable<number>;

    public constructor() {
        // only trigger this every 30 seconds as the output resolution is in minute
        // - Not doing this every minute for accuracy margin reason, e.g. may be up to a minute off
        // - 1st emit is 1msec -> get first output almost immediately
        this.timer$ = timer(1, 30000);
    }

    public transform(value: moment.MomentInput) {
        return this.timer$.pipe(
            map(() => {
                const now = moment();
                if (now.isSameOrAfter(value)) {
                    const duration = moment.duration(now.diff(value));
                    return `${StringUtilities.zeroPad((duration.days() * 24) + duration.hours(), 2)}:${StringUtilities.zeroPad(duration.minutes(), 2)}`;
                } else {
                    return "00:00";
                }
            }),
        );
    }
}

<div class="d-flex align-items-center gap-1">
    <adapt-key-result-list-item class="flex-grow-1"
                                [keyResult]="keyResult"
                                [isEditing]="isEditing"
                                (updated)="keyResultUpdated.emit($event)"></adapt-key-result-list-item>
    <button [(adaptCollapsibleButton)]="commentsCollapsed"></button>
</div>

<div class="mt-2 ms-2 pt-2"
     [ngClass]="{'me-2': isEditing, 'not-editing': !isEditing}"
     [collapse]="commentsCollapsed">
    <adapt-comment-chain [comments]="keyResultComments"
                         [updates]="keyResultUpdates"
                         (chainUpdateDeleted)="chainUpdateDeleted.emit($event)"
                         (chainUpdateEdited)="chainUpdateEdited.emit($event)"
                         noCommentsText="No activity yet."></adapt-comment-chain>
</div>

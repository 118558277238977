import { Injectable } from "@angular/core";
import { IMeetingCustomData, IMeetingLocation, Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { MeetingAgendaItemType } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { CalendarIntegrationProvider } from "@common/ADAPT.Common.Model/organisation/organisation-detail";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { UrlUtilities } from "@common/lib/utilities/url-utilities";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IConfirmationDialogData } from "@common/ux/adapt-common-dialog/confirmation-dialog.component/confirmation-dialog.component";
import { Event } from "@microsoft/microsoft-graph-types-beta";
import moment from "moment/moment";
import { lastValueFrom, of, switchMap, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { MeetingsService } from "../meetings/meetings.service";
import { MicrosoftAuthService, MicrosoftGraphClientError } from "../oauth/microsoft-oauth/microsoft-auth.service";
import { OAuthService } from "../oauth/oauth.service";
import { ICalendarProvider, IMeetingAttendee, IMeetingDifferences, IMeetingOrganiserInfo, IScheduleMeeting } from "./calendar.interface";
import { MicrosoftCalendarService } from "./microsoft-calendar.service";

export interface IProviderMeetingDetails {
    providerMeeting?: Event;
    isAuthenticated: boolean;
    provider?: ICalendarProvider;
}

@Injectable({
    providedIn: "root",
})
export class CalendarIntegrationUtilities {
    public constructor(
        private oauthService: OAuthService,
        private microsoftCalendarService: MicrosoftCalendarService,
        private microsoftAuthService: MicrosoftAuthService,
        private commonDialogService: AdaptCommonDialogService,
        private meetingsService: MeetingsService,
        private commonDataService: CommonDataService,
    ) {}

    public promptForConflicts() {
        return this.commonDialogService.openConfirmationDialogWithBoolean({
            title: "Meeting Has Scheduling Conflicts",
            message: "<p>This meeting has scheduling conflicts.</p><p>Do you still want to send an invite?</p>",
            confirmButtonText: "Yes, send invite anyway",
            cancelButtonText: "No, take me back",
            hideCancelButton: false,
        } as IConfirmationDialogData);
    }

    public getProviderMeetingId(meeting: Meeting, provider: CalendarIntegrationProvider) {
        const customData = meeting.extensions.getCustomData<IMeetingCustomData>();
        if (provider === CalendarIntegrationProvider.Microsoft && customData.microsoftUniqueId) {
            return customData.microsoftUniqueId;
        }

        return undefined;
    }

    public getProviderMeeting(meeting: Meeting) {
        // we only support provider meetings for microsoft at this point
        const customData = meeting.extensions.getCustomData<IMeetingCustomData>();
        if (customData.microsoftUniqueId) {
            return this.oauthService.isAuthedWithProvider(CalendarIntegrationProvider.Microsoft).pipe(
                switchMap(async (isAuthenticated) => {
                    // make sure we have a user at this point. there can be a race condition where the user is gone...
                    if (!isAuthenticated || !this.microsoftAuthService.user) {
                        return undefined;
                    }

                    const event = await this.microsoftCalendarService.getMeeting(customData.microsoftUniqueId!, meeting.meetingDateTime.getUTCFullYear());

                    // remove event association if event doesn't exist and the user is the recorded organiser
                    // we can't distinguish for an attendee if the event has been deleted or if the user has deleted the event from their own calendar
                    const { isOrganiser } = this.getMeetingOrganiser(meeting);
                    if (!event && isOrganiser) {
                        delete customData.microsoftUniqueId;
                        delete customData.microsoftUserId;
                        meeting.extensions.updateCustomData(customData);
                        await lastValueFrom(this.commonDataService.saveEntities([meeting]));
                    }

                    return event;
                }),
            );
        }

        return of(undefined);
    }

    public getMeetingOrganiser(meeting: Meeting, existingProviderMeeting?: Event) {
        const customData = meeting.extensions.getCustomData<IMeetingCustomData>();
        return {
            // the event response will let us know if we are the organiser, else check what we've recorded
            isOrganiser: existingProviderMeeting?.isOrganizer ?? customData.microsoftUserId === this.oauthService.user?.userId,
            name: existingProviderMeeting?.organizer?.emailAddress?.name ?? "the organiser",
        } as IMeetingOrganiserInfo;
    }

    public getMeetingEventDifferences(meeting: Meeting, event: Event) {
        const changes: IMeetingDifferences = {
            remoteShouldBeUpdated: false,
        };

        if (event.isOnlineMeeting) {
            changes.hasOnlineMeeting = true;
        }

        const customData = meeting.extensions.getCustomData<IMeetingCustomData>();
        const remoteModifiedTime = moment(event.lastModifiedDateTime);
        const lastSyncedTime = moment(customData.microsoftLastSynced ?? remoteModifiedTime);
        const syncDiff = lastSyncedTime.diff(meeting.lastUpdatedDateTime, "seconds");
        const remoteModifiedDiff = remoteModifiedTime.diff(lastSyncedTime, "seconds");
        if (syncDiff < -60 || remoteModifiedDiff < -60) {
            changes.remoteShouldBeUpdated = true;
        }

        if (meeting.name !== event.subject && event.subject) {
            changes.name = event.subject;
        }

        const eventStartTime = event.start ? moment.utc(event.start.dateTime) : undefined;
        const meetingStartTime = moment(meeting!.meetingDateTime).utc();
        if (eventStartTime && !meetingStartTime.isSame(eventStartTime)) {
            changes.meetingDateTime = eventStartTime.toDate();
        }

        const eventEndTime = event.end ? moment.utc(event.end.dateTime) : undefined;
        const meetingEndTime = moment(meeting!.endTime).utc();
        if (eventEndTime && !meetingEndTime.isSame(eventEndTime)) {
            changes.endTime = eventEndTime.toDate();
        }

        // can be "", undefined, null. use || to coalesce to nullish
        if ((meeting.location || undefined) !== (event.location?.displayName || undefined)) {
            changes.location = event.location?.displayName ?? undefined;
        }

        if ((customData.microsoftLocation || undefined) !== (event.location?.uniqueId || undefined)) {
            customData.microsoftLocation = event.location?.uniqueId ?? undefined;
            changes.customDataObject = customData;
        }

        return changes;
    }

    public createOrUpdateProviderMeeting(meeting: Meeting, meetingLocation?: IMeetingLocation, createOnlineMeeting = false) {
        return this.oauthService.authenticationStatusWithProvider.pipe(
            switchMap(async ([isAuthenticated, provider]) => {
                const details: IProviderMeetingDetails = { isAuthenticated, provider };

                if (isAuthenticated && provider?.id === CalendarIntegrationProvider.Microsoft) {
                    const uniqueId = this.getProviderMeetingId(meeting, provider.id);

                    // try get the existing event. if it exists we're updating, else creating.
                    const existingEvent = uniqueId !== undefined
                        ? await this.microsoftCalendarService.getMeeting(uniqueId, meeting.meetingDateTime.getUTCFullYear())
                        : undefined;

                    details.providerMeeting = existingEvent
                        ? await this.updateMicrosoftEvent(existingEvent, meeting, meetingLocation, createOnlineMeeting)
                        : await this.scheduleMicrosoftEvent(meeting, meetingLocation, createOnlineMeeting);

                    // wait a bit, then grab the provider meeting again as office may have made changes to the event
                    await new Promise((resolve) => setTimeout(resolve, 5_000));
                    if (details.providerMeeting?.id) {
                        details.providerMeeting = await this.microsoftCalendarService.getMeetingByEventId(details.providerMeeting.id);
                    }

                    // if you create a teams meeting without specifying the meeting location, outlook will set the location to "Microsoft Teams Meeting".
                    // this location also gets removed if you then remove the teams meeting.
                    // so just make sure our local location always matches the provider location
                    meeting.location = details.providerMeeting?.location?.displayName ?? undefined;

                    // issue a save for the customData (and the meeting location if it changed)
                    await lastValueFrom(this.commonDataService.saveEntities([meeting]));
                }

                if (isAuthenticated && provider?.id === CalendarIntegrationProvider.Local) {
                    await lastValueFrom(this.meetingsService.sendMeetingCalendarInvites(meeting));
                }

                return details;
            }),
            catchError((e) => {
                if (e instanceof MicrosoftGraphClientError) {
                    e = new Error("Failed to add the event to your Microsoft calendar. Please refresh the page and try again.");
                }

                return throwError(() => e);
            }),
        );
    }

    public async scheduleMicrosoftEvent(meeting: Meeting, meetingLocation?: IMeetingLocation, createOnlineMeeting = false) {
        try {
            const event = await this.getScheduleMeetingBody(meeting, meetingLocation, undefined, createOnlineMeeting);
            const microsoftMeeting = await this.microsoftCalendarService.scheduleMeeting(event);
            this.attachMicrosoftUniqueIdToMeeting(meeting, microsoftMeeting, meetingLocation);
            return microsoftMeeting;
        } catch (e) {
            throw new Error(`Failed to create Office calendar event: ${e}`);
        }
    }

    public async updateMicrosoftEvent(existingEvent: Event, meeting: Meeting, meetingLocation?: IMeetingLocation, createOnlineMeeting = false) {
        try {
            const event = await this.getScheduleMeetingBody(meeting, meetingLocation, existingEvent, createOnlineMeeting);
            const updatedMeeting = await this.microsoftCalendarService.updateMeeting(existingEvent.id!, event);
            this.attachMicrosoftUniqueIdToMeeting(meeting, updatedMeeting, meetingLocation);
            return updatedMeeting;
        } catch (e) {
            throw new Error(`Failed to update the Microsoft calendar event. Please retry after reloading the page.`);
        }
    }

    public attachMicrosoftUniqueIdToMeeting(meeting: Meeting, microsoftMeeting: Event, meetingLocation?: IMeetingLocation, imported?: boolean) {
        const customData = meeting.extensions.getCustomData<IMeetingCustomData>();
        customData.invitationsSent = true;
        customData.microsoftUserId = this.oauthService.user?.userId;
        customData.microsoftUniqueId = microsoftMeeting.iCalUId!;
        // don't override the microsoftLocation when importing (may have an existing location already)
        if (!customData.microsoftLocation || !imported) {
            customData.microsoftLocation = microsoftMeeting.location?.uniqueId ?? meetingLocation?.emailAddress ?? undefined;
        }
        if (imported != undefined) {
            customData.imported = imported;
        }
        customData.microsoftLastSynced = microsoftMeeting.lastModifiedDateTime ?? new Date().toISOString();
        meeting.extensions.updateCustomData(customData);
    }

    public detachMicrosoftUniqueIdFromMeeting(meeting: Meeting) {
        const customData = meeting.extensions.getCustomData<IMeetingCustomData>();
        delete customData.invitationsSent;
        delete customData.microsoftUserId;
        delete customData.microsoftUniqueId;
        delete customData.microsoftLocation;
        delete customData.microsoftLastSynced;
        delete customData.imported;
        meeting.extensions.updateCustomData(customData);
    }

    private async getScheduleMeetingBody(meeting: Meeting, meetingLocation?: IMeetingLocation, existingEvent?: Event, createOnlineMeeting = false) {
        const meetingBody: IScheduleMeeting = {
            name: meeting.name,
            startTime: meeting.meetingDateTime,
            endTime: meeting.endTime,
            location: meetingLocation,
            attendees: meeting.meetingAttendees.map((attendee) => ({
                type: "required",
                name: attendee.attendee.fullName,
                address: attendee.attendee.getLoginEmail()?.value,
            } as IMeetingAttendee)),
            createOnlineMeeting,
            body: await this.getMeetingBody(meeting, existingEvent, createOnlineMeeting),
        };

        return meetingBody;
    }

    private async getMeetingBody(meeting: Meeting, existingEvent?: Event, createOnlineMeeting = false) {
        // language=CSS
        const style = `
            h4, p {
                margin: 0 0 8px;
            }

            .description {
                background-color: #ffffff;
                border: 1px solid #e5e5e5;
                border-radius: 4px;
                margin: 10px 0;
                padding: 10px;
            }

            .prework {
                background-color: #fffbed;
                border: 1px solid #fae2b1;
                border-radius: 4px;
                margin: 10px 0;
                padding: 10px;
            }`;

        let meetingBodyContent = `<style><!-- ${style} --></style>`;
        if (meeting.meetingId > 0) {
            const relativeMeetingUrl = await lastValueFrom(this.meetingsService.getTeamMeetingsPage(meeting.teamId, meeting.meetingId));
            const meetingUrl = UrlUtilities.getAbsoluteUrl(relativeMeetingUrl);

            meetingBodyContent += `You have been invited to the
                <b><a href="${meetingUrl}">${meeting.name}</a></b> meeting for the ${meeting.team?.name} team.`;

            if (meeting.supplementaryData && meeting.supplementaryData.purpose) {
                const purpose = this.replaceRelativeLinksInHtml(meeting.supplementaryData.purpose);
                meetingBodyContent += `<div class="description"><h4>Meeting Description</h4> ${purpose}</div>`;
            }

            const preWork = meeting.meetingAgendaItems.find((ai) => ai.type === MeetingAgendaItemType.PreWork);
            if (preWork?.supplementaryData?.itemDescription) {
                const itemDescription = this.replaceRelativeLinksInHtml(preWork.supplementaryData.itemDescription);
                meetingBodyContent += `<br />This meeting has some required pre-work which should be completed prior to the meeting.<br />
                    <div class="prework">${itemDescription}</div>`;
            }
        }

        // we need to use the existing event body, as outlook adds teams info to the body.
        // if we remove the teams info, the online meeting disappears.
        // also need to make sure we don't override the event original body (for imported events)
        // const customData = meeting.extensions.getCustomData<IMeetingCustomData>();
        const existingEventBody = existingEvent?.body?.content;
        if (existingEventBody) {
            // the existing event body is a full html document, including <html>
            const parser = new DOMParser();
            const doc = parser.parseFromString(existingEventBody, "text/html");

            // however the event API won't set the body if we actually provide <html>.
            // this results in html/body being removed.
            const body = doc.body;

            // find the teams wrapper element that has the joinUrl link
            const teamsContent = existingEvent.onlineMeeting?.joinUrl
                ? Array.from(body.querySelectorAll(".me-email-text"))
                    .find((me) => me.querySelector(`a[href="${existingEvent.onlineMeeting?.joinUrl}"]`))
                : undefined;

            // need to remove the teams meeting while keeping the existing body
            if (!createOnlineMeeting && existingEvent.onlineMeeting?.joinUrl) {
                teamsContent?.remove();
            }

            // find our content element so we can update it
            let content = body.querySelector("#adapt-content");
            if (!content) {
                // our content element did not exist, so create it
                content = document.createElement("div");
                content.id = "adapt-content";

                // insert our content above the teams meeting details
                if (teamsContent) {
                    body.insertBefore(content, teamsContent);
                } else {
                    body.append(content);
                }
            }

            content.innerHTML = `<hr>${meetingBodyContent}`;
            return body.innerHTML;
        }

        // if createOnlineMeeting=false, we can remove the meeting by just blowing away the existing body.
        return `<div id="adapt-content">${meetingBodyContent}</div>`;
    }

    private replaceRelativeLinksInHtml(html: string) {
        try {
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, "text/html");

            const element = doc.body;

            // make all relative links absolute
            const anchors = element.querySelectorAll("a");
            for (const anchor of Array.from(anchors)) {
                if (anchor.href) {
                    anchor.href = UrlUtilities.getAbsoluteUrl(anchor.href);
                }
            }

            // replace iframes with link to the embed
            const iframes = element.querySelectorAll<HTMLIFrameElement>("iframe");
            for (const iframe of Array.from(iframes)) {
                if (!iframe.src) {
                    continue;
                }

                const anchor = document.createElement("a");
                anchor.href = iframe.src;
                anchor.textContent = "Click to view video";

                let parent = iframe.parentElement;
                while (parent != null) {
                    // direct parent div has style attribute, it's likely this is a video embed div from loom and similar.
                    // remove as it takes up a lot of space.
                    if (parent === iframe.parentElement && parent.tagName == "DIV" && parent.hasAttribute("style")) {
                        // don't break here so that we replace the figure/fr-video higher in the chain as well
                        parent.replaceWith(anchor);
                        // need set the parent to the anchor here now, as the parent will be gone
                        parent = anchor;
                    }

                    // explicitly replace figure.media with only the anchor (this is for helpjuice content)
                    // or the froala embed container
                    if ((parent.tagName === "FIGURE" && parent.classList.contains("media"))
                        || parent.classList.contains("fr-video")) {
                        break;
                    }

                    parent = parent.parentElement;
                }

                // otherwise just replace the iframe itself
                (parent ?? iframe).replaceWith(anchor);
            }

            return element.innerHTML;
        } catch (e) {
            // give up on parsing if any failure
            return html;
        }
    }
}

import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core";
import { buttonPreset, IButtonType } from "@common/ux/button/button-preset";
import { ADAPT_DIALOG_DATA } from "../adapt-common-dialog.globals";
import { BaseDialogComponent } from "../base-dialog.component/base-dialog.component";

export interface IConfirmationDialogData {
    title?: string;
    message?: string;
    headingIcon?: string;
    confirmButtonText?: string;
    confirmButtonPreset?: keyof typeof buttonPreset | IButtonType;
    cancelButtonText?: string;
    cancelButtonPreset?: keyof typeof buttonPreset | IButtonType;
    hideCancelButton?: boolean;
    checkboxMessage?: string;
    result?: boolean;
}

@Component({
    selector: "adapt-confirmation-dialog",
    templateUrl: "./confirmation-dialog.component.html",
})
export class ConfirmationDialogComponent extends BaseDialogComponent<IConfirmationDialogData> implements OnInit {
    public readonly dialogName = "ConfirmationDialog";

    @Output() public closed = new EventEmitter<boolean>();
    @Output() public initialised = new EventEmitter<ConfirmationDialogComponent>();

    public checkboxConfirmation = true;

    public constructor(
        @Inject(ADAPT_DIALOG_DATA) public dialogData: IConfirmationDialogData,
    ) {
        super();

        if (dialogData.checkboxMessage) {
            this.checkboxConfirmation = false;
        }
    }

    public ngOnInit() {
        this.initialised.emit(this);
    }

    public confirm() {
        this.dialogData.result = true;
        this.resolve(this.dialogData);
        this.closed.emit(true);
    }

    public cancel() {
        this.dialogData.result = false;
        this.resolve(this.dialogData);
        this.closed.emit(false);
    }
}

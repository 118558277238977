import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { StringUtilities } from "@common/lib/utilities/string-utilities";
import { UrlUtilities } from "@common/lib/utilities/url-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import dxSelectBox, { ContentReadyEvent, InitializedEvent } from "devextreme/ui/select_box";
import { DocumentDescriptor } from "../document-descriptor";
import { DocumentSelectorService } from "../document-selector.service";
import { CustomUrlProviderService } from "../storage-providers/custom-url-provider.service";
import { FileUploaderProviderService } from "../storage-providers/file-uploader-provider.service";
import { IStorageProvider } from "../storage-providers/storage-provider.interface";

@Component({
    selector: "adapt-select-document",
    templateUrl: "./select-document.component.html",
    styleUrls: ["./select-document.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class SelectDocumentComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    private static ComponentId = 0;

    @Input("selectedDocument") public _selectedDocument?: DocumentDescriptor;
    @Input("url") public _url?: string;
    @Input() public readOnly = false;

    @Output() public selectedDocumentChange = new EventEmitter<DocumentDescriptor>();
    @Output() public selectedDocumentError = new EventEmitter<string>();
    @Output() public urlChange = new EventEmitter<string>();

    public storageProviders: DataSource;
    public selectionDisabled: boolean = false;
    private selectionDisabledUpdater = this.createThrottledUpdater((disabled: boolean) => this.selectionDisabled = disabled, 0);

    public selectedStorageType?: IStorageProvider;
    private selectedStorageTypeUpdater = this.createThrottledUpdater((storageType: IStorageProvider | undefined) => this.selectedStorageType = storageType, 0);

    public errorMessage?: string | null;
    public isCookiesEnabled: boolean = true;
    public isInitialised = false;

    private selectBoxInstance?: dxSelectBox;
    private pendingProviderSelection?: IStorageProvider;
    private instanceIdentifier: number = 0;

    public get useEditableDisplay() {
        return !!(this.selectedDocument?.getUrl()) && !(this.isCustomUrlService(this.selectedStorageType));
    }

    public constructor(
        private documentSelectorService: DocumentSelectorService,
        elementRef: ElementRef,
        private renderer: Renderer2,
    ) {
        super(elementRef);
        // @ts-ignore group object form is missing from DataSourceOptions...
        this.storageProviders = new DataSource({
            store: new ArrayStore({
                data: this.documentSelectorService.storageProviders,
                key: "getName",
            }),
            filter: (provider: IStorageProvider) => !provider.disabled,
            group: { selector: "getGroupName", desc: true },
        });
    }

    public get selectedDocument() {
        return this._selectedDocument;
    }

    public set selectedDocument(value: DocumentDescriptor | undefined) {
        this._selectedDocument = value;
        this.selectedDocumentChange.emit(value);
    }

    public get url() {
        return this._url;
    }

    public set url(value: string | undefined) {
        this._url = value;
        this.urlChange.emit(value);

        // create a new document on url change if setting custom url
        if (this.isCustomUrlService(this.selectedStorageType)) {
            const service = this.getCustomUrlService();
            if (service && value) {
                try {
                    this.selectedDocument = service.getDocument(value)!;
                } catch (error: any) {
                    // Emit an error event when setting selectedDocument fails
                    this.selectedDocumentError.emit(error);
                }
            }
        }
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes._url && this.readOnly && this.isInitialised) {
            this._selectedDocument = new DocumentDescriptor("", this.url);
            this.updateStorageProviderSelection();
        }
    }

    public async ngOnInit() {
        SelectDocumentComponent.ComponentId++;
        this.instanceIdentifier = SelectDocumentComponent.ComponentId;

        if (!this.selectedDocument && this.url) {
            // url is set from @Input but there is no document initialised
            // - this is only used in GM where URL is displayed
            this.selectedDocument = new DocumentDescriptor("", this.url);
        }

        if (!StringUtilities.isString(this.url) || !this.url) {
            if (this.selectedDocument) {
                this.url = this.selectedDocument.getUrl();
            }
        }

        // none of the providers require setup before getDocument usage, so update the display ASAP
        this.updateStorageProviderSelection();

        const setupComponentPromises = this.documentSelectorService.storageProviders.map((storageProvider) => storageProvider.setupComponent({
            componentInstanceId: this.instanceIdentifier,
            isReadOnly: this.readOnly,
            disableSelection: () => this.selectionDisabledUpdater.next(true),
            setEditorDomElement: (element) => this.renderer.appendChild(this.elementRef!.nativeElement, element),
        }));
        await Promise.all(setupComponentPromises);

        this.isInitialised = true;

        // finally update again, disable should be set here now.
        this.updateStorageProviderSelection();
    }

    // cleanup after component destroyed
    public ngOnDestroy() {
        super.ngOnDestroy();
        for (const storageProvider of this.documentSelectorService.storageProviders) {
            storageProvider.cleanupComponent(this.instanceIdentifier);
        }
    }

    public ngAfterViewInit() {
        if (!this.selectedStorageType) {
            // no storage type set, assume custom URL
            this.selectedStorageTypeUpdater.next(this.getCustomUrlService());
        }
        if (this.isCustomUrlService(this.selectedStorageType)) {
            this.performStorageTypeSelection(this.selectedStorageType!);
        }
    }

    public getAbsoluteUrl(url: string) {
        return UrlUtilities.getAbsoluteUrl(url);
    }

    public editLink() {
        if (this.selectedStorageType) {
            this.performStorageTypeSelection(this.selectedStorageType);
        }
    }

    public resetLink() {
        this.selectedStorageTypeUpdater.next(this.getCustomUrlService());
        this.selectedDocument = undefined;
        this.url = undefined;
        this.pendingProviderSelection = this.selectedStorageType;
    }

    @Autobind
    public onSelectBoxInitialized(e: InitializedEvent) {
        this.selectBoxInstance = e.component!;

        if (this.pendingProviderSelection) {
            this.selectBoxInstance.option("value", this.pendingProviderSelection);
            this.pendingProviderSelection = undefined;
        }
    }

    @Autobind
    public onSelectBoxContentReady(e: ContentReadyEvent) {
        // CM-5081 DX 21.1 has screwed up types, this should return a jQuery object too
        const content = jQuery(e.component!.content());
        if (content) {
            content.addClass("document-selector-popup");
        }
    }

    @Autobind
    public onSelectionChanged(e: any) {
        if (!e.selectedItem) {
            this.resetLink();
        }
    }

    @Autobind
    public onStorageTypeSelected(e: any) {
        const selectedStorageType = e.itemData as IStorageProvider;

        this.performStorageTypeSelection(selectedStorageType);
    }

    @Autobind
    public storageDisplayExpr(storage: IStorageProvider) {
        return storage ? storage.getDisplayName() : null;
    }

    public isCustomUrlService(provider?: IStorageProvider) {
        return provider ? provider.getName() === CustomUrlProviderService.ServiceName : true;
    }

    public isFileUploaderService(provider?: IStorageProvider) {
        return provider ? provider.getName() === FileUploaderProviderService.ServiceName : true;
    }

    private getCustomUrlService() {
        return this.documentSelectorService.storageProviders.find(this.isCustomUrlService);
    }

    @Autobind
    private updateStorageProviderSelection() {
        if (StringUtilities.isString(this.url)) {
            let selectedDoc: DocumentDescriptor | undefined = this.selectedDocument;

            // going through the providers to see who can form DocumentDescriptor
            for (const provider of this.documentSelectorService.storageProviders) {

                try {
                    const doc = provider.getDocument(this.url);

                    if (doc) {
                        if (!selectedDoc || !doc.equals(selectedDoc)) {
                            // set the selection to the provider
                            selectedDoc = doc;
                        }

                        this.selectedStorageTypeUpdater.next(provider);

                        if (this.selectBoxInstance) {
                            this.selectBoxInstance.option("value", provider);
                        } else {
                            this.pendingProviderSelection = provider;
                        }

                        if (!this.isCustomUrlService(provider)) {
                            // if customUrl -> want to continue on with the search - customUrl is the fallback
                            break;
                        }
                    }
                } catch (error: any) {
                    // Emit an error event when setting selectedDocument fails
                    this.selectedDocumentError.emit(error);
                }
                

            }

            if (!this.selectedDocument || !selectedDoc || !selectedDoc.equals(this.selectedDocument)) {
                this.selectedDocument = selectedDoc;
            }

            // check if we need cookies for this provider
            this.checkCookiesEnabled();
        } else {
            this.isCookiesEnabled = true;
        }
    }

    private performStorageTypeSelection(selectedStorageType: IStorageProvider) {
        // changed storage type, wipe the document
        if (this.selectedStorageType !== selectedStorageType) {
            this.selectedDocument = undefined;
            this.url = undefined;
        }

        if (selectedStorageType) {
            this.selectedStorageTypeUpdater.next(selectedStorageType);
            // not disabling for fileuploader as we cannot detect cancellation from dxFileUploader
            // - the fileuploader service will disable the button when upload starts
            this.selectionDisabledUpdater.next(!this.isFileUploaderService(selectedStorageType));
            this.errorMessage = undefined;

            selectedStorageType.openChooser(this.instanceIdentifier, this.selectedDocument)
                .then((doc) => this.onDocumentSelected(doc, selectedStorageType))
                .catch((msg) => this.errorMessage = msg)
                .finally(() => this.selectionDisabledUpdater.next(false));
        }
    }

    private onDocumentSelected(selectedDoc: DocumentDescriptor | null, selectedStorageType: IStorageProvider) {
        if (selectedDoc != null) {
            this.selectedDocument = selectedDoc;
            this.selectedDocument.setIconClass(selectedStorageType.getIconClass());
            this.url = selectedDoc.getUrl();
            this.checkCookiesEnabled();
        } else {
            this.selectedDocument = undefined;
            this.url = undefined;
            this.isCookiesEnabled = true; // not showing error without selection
        }
    }

    private checkCookiesEnabled() {
        if (!this.selectedStorageType || !this.isFileUploaderService(this.selectedStorageType)) {
            // don't care about cookies enabled if not file uploader type or no selection type yet
            this.isCookiesEnabled = true;
        } else {
            this.isCookiesEnabled = this.documentSelectorService.isCookiesEnabled();
        }
    }
}

import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { ObjectivesAuthService } from "../objectives-auth.service";
import { EditObjectivePageComponent } from "./edit-objective-page.component";

interface ITeamEditObjectivePageRouteParams {
    teamId: number;
    objectiveId: number;
}

export const teamEditObjectivePageRoute = new OrganisationPageRouteBuilder<ITeamEditObjectivePageRouteParams>()
    .usingNgComponent("adapt-edit-objective-page", EditObjectivePageComponent)
    .atOrganisationUrl("/team/:teamId/objectives/:objectiveId")
    .redirectToThisRouteFromOrganisationUrl("/people/teams/:teamId/objectives/:objectiveId")
    .verifyingFeatures(FeatureName.StewardshipObjectives)
    .build();

interface IOrganisationEditObjectivePageRouteParams {
    objectiveId: number;
}

export const organisationEditObjectivePageRoute = new OrganisationPageRouteBuilder<IOrganisationEditObjectivePageRouteParams>()
    .usingNgComponent("adapt-edit-objective-page", EditObjectivePageComponent)
    .atOrganisationUrl("/leadership/objectives/:objectiveId")
    .verifyingFeatures(FeatureName.StewardshipObjectives)
    .verifyingAccess(ObjectivesAuthService.ReadPublicObjectives)
    .build();

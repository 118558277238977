import { Injector } from "@angular/core";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { ArgumentsType } from "@common/lib/arguments-type";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { BaseService } from "@common/service/base.service";
import { combineLatest, EMPTY, EmptyError, forkJoin, lastValueFrom, ObservableInput, of, ReplaySubject, Subject } from "rxjs";
import { catchError, filter, first, map, mergeMap, switchMap, take, takeUntil, tap } from "rxjs/operators";
import { OrganisationService } from "./organisation.service";

export abstract class BaseOrganisationService extends BaseService {
    private orgInitialisation$ = new ReplaySubject<void>(1);
    private isFirst = true;

    public constructor(protected injector: Injector) {
        super(injector);

        let lastOrganisation: Organisation;
        const interrupt$ = new Subject<void>();
        const organisationSetup$ = this.organisation$.pipe(
            // is a reply subject, on resubscribe, it will emit again
            // - not going to reinitialise previously performed sequence for the same org
            filter((org) => lastOrganisation !== org),
            tap((org) => {
                lastOrganisation = org;
                this.log.info(`Organisation service initialisation started for ${org?.name}`);
                this.isFirst = false;
            }),
            switchMap(() => {
                const orgInitialisations = this.organisationInitialisationActions();
                return orgInitialisations.length > 0
                    ? forkJoin(orgInitialisations)
                    : of({});
            }),
            tap(() => {
                this.orgInitialisation$.next();
                this.log.info(`Organisation service initialisation completed for ${lastOrganisation?.name}`);
            }),
            takeUntil(interrupt$),
        );

        // this is listening to organisation switch event (which will interrupt previously unfinished org service initialisation sequence)
        this.organisationSwitching$.subscribe(() => {
            if (!this.isFirst) {
                this.log.info(`Organisation service initialisation to ${lastOrganisation?.name} will be interrupted`);
                this.orgInitialisation$.complete();
                this.orgInitialisation$ = new ReplaySubject(1);
                interrupt$.next();
                // the interrupted subscription will be completed - so subscribe again
                setTimeout(() => organisationSetup$.subscribe());
            }
        });

        // Make sure the execution of organisationInitialisationActions occurs
        // *after* the constructor is finished running
        // As this will be the first call in the constructor of the implementing
        // class, any references to class properties will be undefined in
        // organisationInitialisationActions() if organisation$ has already emitted
        // a value and the data services have all been initialised.
        // If ngOnInit was called for services, this would be a better place for this.
        setTimeout(() => organisationSetup$.subscribe());
    }

    /**
     * These are used by the @AfterOrganisationInitialisation decorator. If you intend to use this in your organisation service,
     * consider using the @AfterOrganisationInitialisation decorator instead.
     */
    protected promiseAfterOrganisationInitialisation<T extends (...args: any[]) => any>(originalFn: T) {
        return async (...args: ArgumentsType<T>) => {
            // need take(1) here or you cannot get pass the following await - need the Observable to be completed!
            try {
                await this.promiseToWaitUntilOrganisationInitialised();
                return originalFn(...args) as ReturnType<T>;
            } catch (err) {
                // exit without a return if EmptyError is raised from orgInitialisation$
                if (err instanceof EmptyError) {
                    return undefined;
                }

                // pass through any other errors
                throw err;
            }
        };
    }

    /**
     * These are used by the @AfterOrganisationInitialisationAsync decorator. If you intend to use this in your organisation service,
     * consider using the @AfterOrganisationInitialisationAsync decorator instead.
     */
    protected wrapAfterOrganisationInitialisation<T extends (...args: any[]) => any>(getOriginalFn: () => T) {
        return (...args: ArgumentsType<T>) => {
            return this.waitUntilOrganisationInitialised().pipe(
                mergeMap(() => {
                    const originalFn = getOriginalFn();
                    return originalFn(...args) as ReturnType<T>;
                }),
                catchError((err) => {
                    // exit without a return if EmptyError is raised from orgInitialisation$
                    if (err instanceof EmptyError) {
                        return EMPTY;
                    }

                    // pass through any other errors
                    throw err;
                }),
            );
        };
    }

    /** A hot observable which will fire each time the organisation changes, waiting for initialisation
     * Do not use this to get the current organisation for use in a implementing method. Use
     * currentOrganisation$ instead.
     */
    protected get organisation$() {
        const organisationService = this.injector.get(OrganisationService);
        const organisation$ = organisationService.organisation$.pipe(
            filter(ObjectUtilities.createIsInstanceFilter(Organisation)),
        );

        return combineLatest([organisation$, this.initialisation$]).pipe(
            map((v) => v[0]),
        );
    }

    protected get organisationId() {
        const organisationService = this.injector.get(OrganisationService);
        return organisationService.getOrganisationId();
    }

    protected get organisationSwitching$() {
        const organisationService = this.injector.get(OrganisationService);
        return organisationService.organisationSwitching$;
    }

    /** Gets a cold observable which will return the current organisation and complete */
    protected get currentOrganisation$() {
        return this.organisation$.pipe(
            take(1),
        );
    }

    /** Initialisation actions to run once each time the organisation changes
     * and to delay the emission of organisation$ until they complete.
     * Beware! Functions used in here cannot depend on organisation$ or
     * promiseToWaitUntilOrganisationInitialised or a deadlock will occur.
     * Add code that was previously part of the promiseToInitialise() call.
     */
    protected organisationInitialisationActions(): ObservableInput<any>[] {
        return [];
    }

    /** Interop function to help with conversion to Angular services
     * Add this to functions that were previously @AfterInitialisation or
     * base.initialiseThenDo'd to achieve the same behaviour.
     */
    public promiseToWaitUntilOrganisationInitialised() {
        return lastValueFrom(this.waitUntilOrganisationInitialised());
    }

    protected waitUntilOrganisationInitialised() {
        return this.orgInitialisation$.pipe(
            first(),
            // there will be an EmptyError: no elements in sequence error if orgInitialisation$ is completed without an emit
        );
    }
}

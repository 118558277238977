import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Input as StrategicInput, InputType } from "@common/ADAPT.Common.Model/organisation/input";
import { CanvasInputTypes, InputsCanvas } from "@common/ADAPT.Common.Model/organisation/inputs-canvas";

@Component({
    selector: "adapt-select-input",
    templateUrl: "./select-input.component.html",
})
export class SelectInputComponent implements OnChanges {
    @Input() public canvas?: InputsCanvas;

    @Input() public input?: StrategicInput;
    @Output() public inputChange = new EventEmitter<StrategicInput>();

    @Input() public inputFilter?: (input: StrategicInput) => boolean;

    public dataSource?: { key: InputType | string, items: StrategicInput[] }[];
    public placeholderText = "Select an input...";

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.canvas && this.canvas) {
            this.updateDataSource();
        }
    }

    public updateDataSource() {
        this.dataSource = [];
        if (this.canvas?.inputs?.length) {
            const canvasInputTypes = CanvasInputTypes[this.canvas.type];
            for (const inputType of canvasInputTypes) {
                this.dataSource.push({
                    key: inputType,
                    items: this.canvas.inputs.filter((i) => i.type === inputType),
                });
            }

            if (this.inputFilter) {
                this.dataSource.forEach((i) => i.items = i.items.filter((input) => this.inputFilter!(input)));
            }

            // remove types without input
            this.dataSource = this.dataSource.filter((i) => i.items.length);
        } else {
            this.placeholderText = "There is no inputs for the selected canvas";
            this.dataSource.push({
                key: "No inputs for the selected canvas",
                items: [],
            });
        }

        if (this.input) {
            // previously selected input -> dx not emitting undefined/null if not dataSource not containing item
            const dataSourceHasInput = !!this.dataSource.find((group) => group.items.includes(this.input!));
            if (!dataSourceHasInput) {
                this.input = undefined;
                this.inputChange.emit(undefined);
            }
        }
    }

    public onInputChanged(input?: StrategicInput) {
        this.input = input;
        this.inputChange.emit(input);
    }
}

import FroalaEditor from "froala-editor";

export interface IVideoProvider {
    url: string;
    frame_html: string;

    test_regex: RegExp;
    url_regex: RegExp;
    url_text: string;
    html: string;
    provider: string;
}

export class UrlUtilities {
    /**
     * Simply return the part before the query string or the entire string
     * if there is no query parameter.
     * @param {string} url The url to trim
     * @returns {string} The string before ?
     */
    public static getUrlWithoutQueryString(url: string) {
        if (typeof url === "string") {
            return url.split("?")[0];
        } else {
            return "";
        }
    }

    /**
     * Set a query parameter on a URL. If the key already exists its value
     * will be replaced. If there are multiple values, then only the last
     * one will be replaced. Otherwise it will be added after the existing
     * query parameters.
     * @param url The url to modify and return
     * @param key The key of the query parameter to set
     * @param value The value of the query parameter to set
     */
    public static setQueryParam(url: string, key: string, value: string): string {
        const queryParamStartIndex = url.indexOf("?");
        value = encodeURIComponent(value);

        if (queryParamStartIndex < 0) {
            // no query param -> just add it
            return url + "?" + key + "=" + value;
        }

        let queryParams = url.substring(queryParamStartIndex + 1);

        if (queryParams.length < 1) { // no query param part but has ending ?
            return url + key + "=" + value;
        }

        const searchString = key + "=";
        let paramKeyStart = queryParams.indexOf(searchString);

        while (paramKeyStart > 0) {
            const previousCharacter = queryParams.charAt(paramKeyStart - 1);
            if (previousCharacter === "&" || previousCharacter === ",") {
                break;
            } else { // something in front of key, not what we are looking for -> search next
                paramKeyStart = queryParams.indexOf(searchString, paramKeyStart + key.length);
            }
        }

        if (paramKeyStart < 0) {
            // key not already exists -> append
            return url + "&" + key + "=" + value;
        }

        // find ending position - query params separator can be & or , according to the specs
        const commaIndex = queryParams.indexOf(",", paramKeyStart);
        const ampersandIndex = queryParams.indexOf("&", paramKeyStart);

        if (commaIndex < 0 && ampersandIndex < 0) { // last param
            queryParams = queryParams.substring(0, paramKeyStart) + key + "=" + value;
        } else {
            let endPosition: number;

            if (commaIndex > 0 && ampersandIndex > 0) {
                endPosition = commaIndex < ampersandIndex ? commaIndex : ampersandIndex;
            } else if (commaIndex > 0) {
                endPosition = commaIndex;
            } else {
                endPosition = ampersandIndex;
            }

            queryParams = queryParams.substring(0, paramKeyStart) + key + "=" + value + queryParams.substring(endPosition);
        }

        return url.substring(0, queryParamStartIndex + 1) + queryParams;
    }

    /**
     * Parses the given URL and returns the value of the specified query parameter,
     * returning undefined if not found.
     * @param url The URL to parse
     * @param key The key of the query parameter to search for
     */
    public static getQueryParamValue(url: string, key: string): string | undefined {
        const queryParamStartIndex = url.indexOf("?");

        if (queryParamStartIndex < 0) {
            return undefined;
        }

        const queryParams = url.substring(queryParamStartIndex + 1);
        const searchString = key + "=";
        let paramKeyStart = queryParams.indexOf(searchString);

        while (paramKeyStart > 0) {
            const previousCharacter = queryParams.charAt(paramKeyStart - 1);
            if (previousCharacter === "&" || previousCharacter === ",") {
                break;
            } else { // something in front of key, not what we are looking for -> search next
                paramKeyStart = queryParams.indexOf(searchString, paramKeyStart + key.length);
            }
        }

        if (paramKeyStart < 0) {
            return undefined;
        }

        // find ending position - query params separator can be & or , according to the specs
        const commaIndex = queryParams.indexOf(",", paramKeyStart);
        const ampersandIndex = queryParams.indexOf("&", paramKeyStart);

        if (commaIndex < 0 && ampersandIndex < 0) { // last param
            return queryParams.substring(paramKeyStart + searchString.length);
        }

        let endPosition: number;

        if (commaIndex > 0 && ampersandIndex > 0) {
            endPosition = commaIndex < ampersandIndex ? commaIndex : ampersandIndex;
        } else if (commaIndex > 0) {
            endPosition = commaIndex;
        } else {
            endPosition = ampersandIndex;
        }

        return queryParams.substring(paramKeyStart + searchString.length, endPosition);
    }

    public static getFileName(url: string): string {
        const queryParamStartIndex = url.indexOf("?");

        if (queryParamStartIndex > 0) {
            url = url.substring(0, queryParamStartIndex);
        }

        let lastSlashIndex = url.lastIndexOf("/");

        if (lastSlashIndex === url.length - 1) {
            lastSlashIndex = url.lastIndexOf("/", lastSlashIndex - 1);
            // remove the last /
            url = url.substring(0, url.length - 1);
        }

        return url.substring(lastSlashIndex + 1);
    }

    /**
     * Check if the given URL is an absolute URL.
     * Sourced from: https://stackoverflow.com/a/38979205
     * @param url The URL to check
     */
    public static isUrlAbsolute(url: string) {
        if (url.indexOf("//") === 0) { return true; } // URL is protocol-relative (= absolute)
        if (url.indexOf("://") === -1) { return false; } // URL has no protocol (= relative)
        if (url.indexOf("/") === -1) { return false; } // URL does not contain a single slash (= relative)
        if (url.indexOf(":") > url.indexOf("/")) { return false; } // The first colon comes after the first slash (= relative)
        if (url.indexOf("://")) { return true; } // Protocol is defined (= absolute)
        return false; // Anything else must be relative
    }

    /**
     * Transforms a URL to a relevant absolute URL.
     * @param url The URL to transform
     */
    public static getAbsoluteUrl(url: string) {
        if (!UrlUtilities.isUrlAbsolute(url)) {
            if (url.indexOf(":/") === 1 || url.indexOf(":\\") === 1) {
                // likely a windows file path, add file:/// prefix so it can be copy and pasted into explorer/run dialog
                return "file:///" + url;
            } else if (url.startsWith("/")) {
                return window.location.origin + url;
            }

            return "//" + url;
        }
        return url;
    }

    /**
     * Checks if the given URL is a valid HTTP/s URL. Sourced from: https://stackoverflow.com/a/43467144
     */
    public static isValidHttpUrl(url: string) {
        try {
            const parsedUrl = new URL(url);
            return parsedUrl.protocol === "http:" || parsedUrl.protocol === "https:";
        } catch (_) {
            return false;
        }
    }

    /**
     * Gets video provider details for the given URL.
     * @param url URL of video to get details for
     */
    public static getVideoEmbedDetails(url: string) {
        // this bit from froala video plugin
        if (!/^http/.test(url)) {
            url = "https://" + url;
        }

        let videoProvider: IVideoProvider | undefined;
        // froala will populate this list of provider when the video plugin is loaded
        for (const provider of (FroalaEditor.VIDEO_PROVIDERS as IVideoProvider[])) {
            if (provider.test_regex.test(url)) {
                const embedUrl = url.replace(provider.url_regex, provider.url_text);
                videoProvider = {
                    ...provider,
                    url: embedUrl,
                    frame_html: provider.html.replace(/\{url\}/, embedUrl),
                };
                break;
            }
        }

        return videoProvider;
    }
}

import { Component, ElementRef, Injector, OnInit, QueryList, ViewChildren } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { InputGroup, InputGroupType } from "@common/ADAPT.Common.Model/organisation/input-group";
import { CanvasType, InputsCanvas } from "@common/ADAPT.Common.Model/organisation/inputs-canvas";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { StrategyAuthService } from "@org-common/lib/strategy/strategy-auth.service";
import { first, forkJoin, of, switchMap } from "rxjs";
import { tap } from "rxjs/operators";
import { WorkflowRunDialogComponent } from "../../workflow/workflow-run-dialog/workflow-run-dialog.component";
import { AddCompetitorAnalysisWorkflow } from "../add-competitor-analysis-workflow/add-competitor-analysis-workflow";
import { CompetitorsAnalysisTour } from "../competitor-analysis-tour";
import { InputGroupComponent } from "../input-group/input-group.component";
import { StrategicInputsService } from "../strategic-inputs.service";

@Component({
    selector: "adapt-competitor-analysis-page",
    templateUrl: "./competitor-analysis-page.component.html",
})
export class CompetitorAnalysisPageComponent extends BaseRoutedComponent implements OnInit {
    public readonly EditStrategicInputs = StrategyAuthService.EditStrategicInputs;

    @ViewChildren(InputGroupComponent) public inputGroups = new QueryList<InputGroupComponent>();
    public canvas?: InputsCanvas;
    public competitors: InputGroup[] = [];

    public constructor(
        injector: Injector,
        elementRef: ElementRef,
        private inputsService: StrategicInputsService,
        rxjsBreezeService: RxjsBreezeService,
        private authService: AuthorisationService,
        private dialogService: AdaptCommonDialogService,
    ) {
        super(injector, elementRef);

        rxjsBreezeService.entityTypeChanged(InputGroup).pipe(
            this.takeUntilDestroyed(),
        ).subscribe(() => {
            if (this.canvas) {
                this.competitors = this.canvas.inputGroups.filter((i) => i.type === InputGroupType.Competitor);
            }
        });
    }

    public async ngOnInit() {
        forkJoin([
            this.inputsService.getCanvasesOfType(CanvasType.CompetitorAnalysis),
            this.authService.getHasAccess(StrategyAuthService.EditStrategicInputs).pipe(first()),
        ]).pipe(
            switchMap(([canvases, canEditStrategy]) => (canvases.length > 0 || !canEditStrategy)
                ? of(canvases[0])
                : this.inputsService.createCanvasOfType(CanvasType.CompetitorAnalysis).pipe(
                    switchMap((canvas) => this.inputsService.saveEntities(canvas)),
                )),
        ).subscribe((canvas: InputsCanvas | undefined) => {
            if (canvas) {
                this.competitors = canvas.inputGroups.filter((i) => i.type === InputGroupType.Competitor);
                this.canvas = canvas;
            }
            this.notifyActivated();
        });

        this.inputsService.triggerPulseStream.pipe(
            this.takeUntilDestroyed(),
        ).subscribe((inputGroupName) => {
            const selectedInputGroup = this.inputGroups.find((ig) => ig.inputGroup?.name === inputGroupName);
            if (selectedInputGroup) {
                selectedInputGroup.scrollIntoView();
                setTimeout(() => selectedInputGroup.pulseInputGroup(inputGroupName), 100);
            }
        });
    }

    @Autobind
    public addCompetitor() {
        return this.inputsService.createCompetitor(this.canvas!).pipe(
            switchMap((competitor) => this.dialogService.open(WorkflowRunDialogComponent, {
                workflow: AddCompetitorAnalysisWorkflow,
                runData: competitor,
            })),
            tap(() => {
                // only run the competitor analysis tour when the first one is added
                if (this.canvas!.inputGroups.filter((i) => i.type === InputGroupType.Competitor).length == 1) {
                    this.guidedTourService.run(CompetitorsAnalysisTour);
                }
            }),
            this.takeUntilDestroyed(),
        );
    }
}

export const CompetitorAnalysisPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-competitor-analysis-page", CompetitorAnalysisPageComponent)
    .atOrganisationUrl("/competitor-analysis")
    .withTitle("Competitor Analysis")
    .verifyingFeatures(FeatureName.StrategicInputs)
    .verifyingAccess(StrategyAuthService.ReadStrategicInputs)
    .reloadOnSearch(false)
    .build();

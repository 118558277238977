import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { IBreezeModel } from "@common/lib/data/breeze-model.interface";
import { IBreezeNamedParams, IBreezeQueryOptions } from "@common/lib/data/breeze-query-options.interface";
import { IMethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ObjectUtilities } from "@common/lib/utilities/object-utilities";
import { EntityManager, EntityQuery, QueryResult } from "breeze-client";
import { Logger } from "../logger/logger";
import { IBreezeExecuteQueryError } from "./errors/breeze-query-error";
import { QueryUtilities } from "./query-utilities";

type GetQueryFailedFn = (queryDescription: string) => (error: IBreezeExecuteQueryError) => Promise<never[]>;
type PromiseToInitialiseParametersFn = <T extends IBreezeEntity>(model: IBreezeModel<T>, dest: IBreezeNamedParams) => Promise<void>;

export interface IUseLocal { [requestKey: string]: boolean }

export class EntityCountHelper {
    private readonly log = Logger.getLogger(`ADAPT.Common.Data.EntityCount.Service`);

    private promiseCache: { [k: string]: Promise<number> } = {};
    private useLocal!: IUseLocal;
    private promiseToInitialiseParameters!: PromiseToInitialiseParametersFn;
    private queryFailed: GetQueryFailedFn;
    private entityManager!: EntityManager;
    private entityCountCache: { [k: string]: { [key: string]: number } } = {};

    public constructor(
        entityManager: EntityManager,
        useLocal: { [requestKey: string]: boolean },
        promiseToInitialiseParameters: PromiseToInitialiseParametersFn,
        queryFailed: GetQueryFailedFn,
    ) {
        this.promiseToInitialiseParameters = promiseToInitialiseParameters;
        this.queryFailed = queryFailed;
        this.setEntityManager(entityManager, useLocal);
    }

    public setEntityManager(entityManager: EntityManager, useLocal: IUseLocal) {
        this.entityManager = entityManager;
        this.useLocal = useLocal;
    }

    public clearCache() {
        this.entityCountCache = {};
        this.promiseCache = {};
    }

    public clearCountCacheForEntities(entities?: IBreezeEntity[]) {
        const changedEntities = Array.isArray(entities)
            ? entities
            : this.entityManager.getChanges();

        for (const v of changedEntities as IBreezeEntity[]) {
            this.clearEntityFromEntityCountCache(v);
        }
    }

    public clearEntityFromEntityCountCache(entity: IBreezeEntity) {
        if (entity.entityAspect.entityState.isDetached()) {
            return;
        }

        const source = entity.entityType.defaultResourceName;
        delete this.entityCountCache[source];
    }

    public saveEntityCountToCache(model: IBreezeModel, key: string, count: number) {
        let modelCounts = this.entityCountCache[model.source!];

        if (!modelCounts) {
            this.entityCountCache[model.source!] = {};
            modelCounts = this.entityCountCache[model.source!];
        }

        modelCounts[key] = count;
    }

    public getCountByPredicate<T extends IBreezeEntity<T> = any>(model: IBreezeModel, predicate: IMethodologyPredicate<T>) {
        return this.getCountByPredicateWithNamedParams(model, predicate);
    }

    public async getCountByPredicateWithNamedParams<T extends IBreezeEntity<T> = any>(model: IBreezeModel, predicate?: IMethodologyPredicate<T>, namedParams?: IBreezeNamedParams): Promise<number> {
        let result;
        let getOptions: IBreezeQueryOptions<T> | undefined;

        if (model.predicate) {
            if (predicate) {
                predicate.and(model.predicate); // join provided predicate and default predicate using AND
            } else {
                predicate = model.predicate; // use default predicate as no predicate provided
            }
        }

        const requestKey = this.getEntityCacheCountKey(model, predicate, namedParams);

        if (ObjectUtilities.isObject(namedParams)) {
            getOptions = Object.assign({}, model);
            getOptions.namedParams = namedParams;
        }

        // use promiseCache if an existing server request is pending
        if (this.promiseCache[requestKey] !== undefined) {
            const promise = await this.promiseCache[requestKey];
            this.log.debug("Retrieved Count" + model.identifier + " from cache", promise);
            return promise!;
        }

        // getAll sets useLocal with a key of the identifier for the model, if all have been retrieved then just count the local entities
        if (this.hasEntityCountInCache(model, requestKey)) {
            result = this.getEntityCountFromCache(model, requestKey);
        } else if (this.useLocal[requestKey] || this.useLocal[model.identifier]) {
            result = this.getLocalCount(model, predicate, requestKey, getOptions);
        } else {
            result = this.getServerCount(model, predicate, requestKey, getOptions);
        }

        return result;
    }

    public getEntityCacheCountKey<T extends IBreezeEntity<T> = any>(model: IBreezeModel, predicate: IMethodologyPredicate<T> | undefined, namedParams?: IBreezeNamedParams): string {
        let requestKey = model.identifier + "Count" + (predicate?.getKey() ?? "All");

        if (ObjectUtilities.isObject(namedParams)) {
            for (const [key, value] of Object.entries(namedParams!)) {
                requestKey += `,${key}=${value}`;
            }
        }

        return requestKey;
    }

    // Private stuffs below here

    private getLocalCount<T extends IBreezeEntity<T> = any>(model: IBreezeModel, predicate: IMethodologyPredicate<T> | undefined, requestKey: string, getOptions?: IBreezeQueryOptions<T>): Promise<number> {
        const breezePredicate = predicate?.createBreezePredicate();
        let entities = EntityQuery.from(model.source!)
            .where(breezePredicate)
            .using(this.entityManager)
            .executeLocally();

        entities = QueryUtilities.processQueryOptions(entities, getOptions);
        this.saveEntityCountToCache(model, requestKey, entities.length);

        this.log.debug(this.getModelAndRequestKeyLogPart(model, requestKey) + " from local data source",
            entities.length);

        return Promise.resolve(entities.length);
    }

    private getServerCount<T extends IBreezeEntity<T> = any>(model: IBreezeModel, predicate: IMethodologyPredicate<T> | undefined, requestKey: string, getOptions?: IBreezeQueryOptions<T>): Promise<number> {
        const breezePredicate = predicate?.createBreezePredicate();
        let params: IBreezeNamedParams = {};

        if (getOptions && getOptions.namedParams) {
            params = getOptions.namedParams;
        }

        this.promiseCache[requestKey] = this.promiseToInitialiseParameters(model, params)
            .then(() => EntityQuery.from(model.source!)
                .withParameters(params)
                .where(breezePredicate)
                .take(0)
                .inlineCount(true)
                .using(this.entityManager)
                .execute()
                .then((data) => this.querySucceeded(data, model, requestKey))
                .catch(this.queryFailed(model.singularName + " Count")) as Promise<number>)
            .finally(() => {
                delete this.promiseCache[requestKey];
            });

        return this.promiseCache[requestKey];
    }

    private querySucceeded(data: QueryResult, model: IBreezeModel, requestKey: string) {
        this.saveEntityCountToCache(model, requestKey, data.inlineCount!);

        this.log.debug(this.getModelAndRequestKeyLogPart(model, requestKey) + " from remote data source",
            data.inlineCount);

        return data.inlineCount;
    }

    private getModelAndRequestKeyLogPart(model: IBreezeModel, requestKey: string) {
        return "Retrieved [" + model.singularName + " Count][requestKey=" + requestKey + "]";
    }

    private hasEntityCountInCache(model: IBreezeModel, key: string): boolean {
        const modelCounts = this.entityCountCache[model.source!];

        return ObjectUtilities.isNotNullOrUndefined(modelCounts) && ObjectUtilities.isNotNullOrUndefined(modelCounts[key]);
    }

    private getEntityCountFromCache(model: IBreezeModel, key: string): number {
        return this.entityCountCache[model.source!][key];
    }
}

import { Component } from "@angular/core";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { WorkflowStepComponent } from "@org-common/lib/workflow/workflow-component-registry";
import { WorkflowStepComponentAdapter } from "@org-common/lib/workflow/workflow-step-component-adapter";

export interface IActivityBriefData {
    meetingDescriptionArticle?: ImplementationKitArticle,
    meetingPreWorkArticle?: ImplementationKitArticle,
}

export const DisplayWorkflowActivityBriefComponentSelector = "adapt-display-workflow-activity-brief";

@WorkflowStepComponent(DisplayWorkflowActivityBriefComponentSelector)
@Component({
    selector: "adapt-display-workflow-activity-brief",
    templateUrl: "./display-workflow-activity-brief.component.html",
    styleUrls: ["./display-workflow-activity-brief.component.scss"],
})
export class DisplayWorkflowActivityBriefComponent extends WorkflowStepComponentAdapter {
    public preWorkArticleId?: ImplementationKitArticle;
    public meetingDescriptionArticleId?: ImplementationKitArticle;
    public constructor() {
        super();
    }

    public async workflowStepOnInit() {
        this.preWorkArticleId = (this.workflowStep?.customData as IActivityBriefData).meetingPreWorkArticle;
        this.meetingDescriptionArticleId = (this.workflowStep?.customData as IActivityBriefData).meetingDescriptionArticle;
    }
}

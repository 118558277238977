import { Injectable, Injector } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { IEntityWithRequiredTeam } from "@common/ADAPT.Common.Model/organisation/team-entity.interface";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { BaseService } from "@common/service/base.service";
import { AuthorisationService } from "@org-common/lib/authorisation/authorisation.service";
import { forkJoin, from, lastValueFrom, Observable, of } from "rxjs";
import { combineAll, map, switchMap, take } from "rxjs/operators";
import { FeaturesService } from "../features/features.service";
import { CommonTeamsService } from "../teams/common-teams.service";

@Injectable({
    providedIn: "root",
})
export class ObjectivesAuthService extends BaseService {
    public static readonly ReadObjectives = "readObjectives";
    public static readonly EditObjectives = "editObjectives";
    public static readonly ReadPublicObjectives = "readPublicObjectives";
    public static readonly ReadOtherTeamObjectives = "readOtherTeamObjectives";
    public static readonly ReadObjectivesForTeam = "readObjectivesForTeam";
    public static readonly ReadAnyObjectives = "readAnyObjectives";

    public constructor(
        injector: Injector,
        private teamsService: CommonTeamsService,
        private authService: AuthorisationService,
        private featuresService: FeaturesService,
    ) {
        super(injector);
    }

    public static registerAccess(authorisationService: AuthorisationService) {
        authorisationService.registerAccessVerifier(
            ObjectivesAuthService.ReadOtherTeamObjectives,
            {
                requirePermissions: [
                    FeaturePermissionName.StewardshipObjectivesTeamRead,
                    FeaturePermissionName.StewardshipObjectivesRead,
                    FeaturePermissionName.StewardshipObjectivesEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            ObjectivesAuthService.ReadPublicObjectives,
            {
                requirePermissions: [
                    FeaturePermissionName.StewardshipObjectivesPublicRead,
                    FeaturePermissionName.StewardshipObjectivesRead,
                    FeaturePermissionName.StewardshipObjectivesEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            ObjectivesAuthService.ReadObjectives,
            {
                requirePermissions: [
                    FeaturePermissionName.StewardshipObjectivesRead,
                    FeaturePermissionName.StewardshipObjectivesEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            ObjectivesAuthService.ReadAnyObjectives,
            {
                requirePermissions: [
                    FeaturePermissionName.StewardshipObjectivesPublicRead,
                    FeaturePermissionName.StewardshipObjectivesTeamRead,
                    FeaturePermissionName.StewardshipObjectivesRead,
                    FeaturePermissionName.StewardshipObjectivesEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            ObjectivesAuthService.EditObjectives,
            {
                requirePermissions: [
                    FeaturePermissionName.StewardshipObjectivesEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            ObjectivesAuthService.ReadObjectivesForTeam,
            {
                invokeToGetEntityAccessVerifier: (injector) => getVerifyAccessToReadTeamObjectives(injector.get(ObjectivesAuthService)),
            },
        );

        function getVerifyAccessToReadTeamObjectives(objectivesAuthService: ObjectivesAuthService) {
            return async (_currentPerson: Person, team?: Team) =>
                await lastValueFrom(objectivesAuthService.hasReadAccessToObjective(team?.teamId))
                    ? Promise.resolve(true)
                    : Promise.reject();
        }
    }

    public get hasAnyReadAccessToObjective$() {
        return this.hasReadAccessToObjective().pipe(
            switchMap((hasOrgRead) => {
                if (hasOrgRead) {
                    return of(true);
                } else {
                    return this.initialisation$.pipe(
                        take(1),
                        switchMap(() => this.teamsService.promiseToGetActiveTeamsForCurrentPerson()),
                        switchMap((teams) => teams.length > 0
                            ? teams.map((team) => this.hasReadAccessToObjective(team.teamId))
                            : [of(false)]),
                        combineAll(),
                        map((hasTeamReads) => hasTeamReads.indexOf(true) >= 0),
                    );
                }
            }),
        );
    }

    // need function return type declaration as this is called from within the function
    public hasReadAccessToObjective(teamId?: number): Observable<boolean> {
        if (!teamId) {
            return from(this.authService.promiseToGetHasAccess(ObjectivesAuthService.ReadPublicObjectives));
        } else {
            return forkJoin([
                from(this.authService.promiseToGetHasAccess(ObjectivesAuthService.ReadObjectives, { teamId })),
                this.teamsService.getTeamById(teamId), // although this is not needed when hasAccess true, put this in forkJoin anyway as team is just fetched from cache
            ]).pipe(
                switchMap(([hasAccess, team]) => {
                    if (hasAccess) {
                        // ReadObjectives check already covered global and member read
                        return of(true);
                    }

                    // !team (team not found) should be from invalid teamId,
                    // in that case, access verification should fail! - don't fall to org objectives
                    if (!team || !team.allowObjectivesTeamRead || !this.featuresService.isFeatureActive(FeatureName.StewardshipObjectives, team)) {
                        // team not allow objectives team read or objectives feature is not enabled
                        return of(false);
                    }

                    // team allow objectives team read and feature is enabled
                    // -> check if current person can read other team objs
                    return this.authService.promiseToGetHasAccess(ObjectivesAuthService.ReadOtherTeamObjectives);
                }),
            );
        }
    }

    public hasWriteAccessToObjective(teamId?: number) {
        return from(this.authService.promiseToGetHasAccess(ObjectivesAuthService.EditObjectives, { teamId }));
    }

    public personCanEditObjectivesForTeam(person: Person, teamEntity: IEntityWithRequiredTeam) {
        return this.authService.personHasPermission(person, FeaturePermissionName.StewardshipObjectivesEdit, teamEntity);
    }
}

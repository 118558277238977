import { NgModule } from "@angular/core";
import { LoadingDirective } from "./loading.directive";
import { LoadingSpinnerComponent } from "./loading-spinner.component";
import { LoadingUntilFirstEmitDirective } from "./loading-until-first-emit.directive";

@NgModule({
    imports: [
    ],
    exports: [
        LoadingDirective,
        LoadingSpinnerComponent,
        LoadingUntilFirstEmitDirective,
    ],
    declarations: [
        LoadingDirective,
        LoadingSpinnerComponent,
        LoadingUntilFirstEmitDirective,
    ],
})
export class AdaptLoadingSpinnerModule {
}

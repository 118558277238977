import { Injectable } from "@angular/core";
import { CoachSession, CoachSessionBreezeModel } from "@common/ADAPT.Common.Model/organisation/coach-session";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";

@Injectable({
    providedIn: "root",
})
export class CoachAccessService {
    public constructor(
        private commonDataService: CommonDataService,
    ) { }

    public getCoachSessions(forceRemote = false) {
        return this.commonDataService.getAll(CoachSessionBreezeModel, forceRemote);
    }

    public getCoachSessionsForOrganisationId(organisationId: number, forceRemote = false) {
        const predicate = new MethodologyPredicate<CoachSession>("organisationId", "==", organisationId);
        return this.commonDataService.getByPredicate(CoachSessionBreezeModel, predicate, forceRemote);
    }
}

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { RouteEventsService } from "@common/route/route-events.service";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { IdentityInterceptorHelper } from "./identity-interceptor-helper";
import { IdentityStorageService } from "./identity-storage.service";

@Injectable()
export class IdentityInterceptor implements HttpInterceptor {
    private identityInterceptorHelper: IdentityInterceptorHelper;

    constructor(
        identityStorageService: IdentityStorageService,
        routeEventsService: RouteEventsService,
    ) {
        this.identityInterceptorHelper = new IdentityInterceptorHelper(identityStorageService, routeEventsService);
    }

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const accessToken = this.identityInterceptorHelper.getRequestAccessToken(request.url);
        if (accessToken) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        }

        return next.handle(request).pipe(
            catchError((response: HttpErrorResponse) => {
                this.identityInterceptorHelper.handleError(response.status, response.url, (token: string) => response.headers.has(token));
                return throwError(() => response);
            }),
        );
    }
}

<adapt-feature-guidance [configItem]="configItem">
    <p>Objectives allow your organisation to set goals with clear key results and time frames.
        Organisation objectives are generally shared with everyone in the organisation to enable transparency and accountability.</p>
    <p *ngIf="team">Edit permission is not configurable. Only leaders or collaborators in the team will be able to edit
        objectives &amp; key results for this team.</p>
</adapt-feature-guidance>

<adapt-feature-configuration [team]="team"
                             [configItem]="configItem">
    <fieldset *ngIf="team">
        <label>Who can view the objectives &amp; key results from this team?</label>
        <dx-radio-group [items]="TeamReadAccessOptions"
                        valueExpr="value"
                        displayExpr="text"
                        [(value)]="team.allowObjectivesTeamRead"
                        [disabled]="team.isPrivate"></dx-radio-group>
    </fieldset>
</adapt-feature-configuration>

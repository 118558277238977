import { Injectable } from "@angular/core";
import { IDynamicNodeBuilder } from "@common/route/dynamic-node-builder";
import { INavigationNode } from "@common/route/navigation-node.interface";
import { NavigationUtilitiesService } from "@common/route/navigation-utilities.service";
import { Observable, of } from "rxjs";
import { myProfilePageRoute } from "./profile-page/profile-page.component";

@Injectable({
    providedIn: "root",
})
export class PersonDynamicNavigationService implements IDynamicNodeBuilder {
    public activeNodeShouldBeRebuilt$: Observable<unknown> = of(false);

    public constructor(
        private navUtilsService: NavigationUtilitiesService,
    ) { }

    public buildDynamicNodes(): INavigationNode[] {
        return [
            this.buildDynamicMyProfileNode(),
        ];
    }

    private buildDynamicMyProfileNode() {
        return this.navUtilsService.nodeBuilderForController(myProfilePageRoute.id)
            .build();
    }
}

import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, QueryList, SimpleChanges, ViewChildren } from "@angular/core";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { MeetingAgendaItem, MeetingAgendaItemStatus } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ElementUtilities } from "@common/lib/utilities/element-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ButtonIconComponent } from "@common/ux/button/button-icon.component";
import { ResponsiveService } from "@common/ux/responsive/responsive.service";
import dxAccordion from "devextreme/ui/accordion";
import { merge, Subject } from "rxjs";
import { debounceTime, filter, switchMap, tap } from "rxjs/operators";
import { MeetingsService } from "../meetings.service";
import { MeetingsUiService } from "../meetings-ui.service";

@Component({
    selector: "adapt-meeting-sidebar-agenda",
    templateUrl: "./meeting-sidebar-agenda.component.html",
    styleUrls: ["./meeting-sidebar-agenda.component.scss"],
})
export class MeetingSidebarAgendaComponent extends BaseComponent implements OnChanges, AfterViewChecked, AfterViewInit {
    public readonly AgendaItemIdPrefix = "agenda_item_";

    @Input() public meeting!: Meeting;
    @Output() public endMeetingCalled = new EventEmitter<void>();

    public agendaItems: MeetingAgendaItem[] = [];
    public selectedItem?: MeetingAgendaItem;
    public initialised = false;

    public accordionInstance?: dxAccordion;
    public canParticipateInMeeting = false;

    private updateData$ = new Subject<void>();
    private hasUpdate = false;
    private updateAccordion$ = new Subject<void>();

    @ViewChildren("endMeetingButton") private endMeetingButtonElements?: QueryList<ButtonIconComponent>;

    constructor(
        private meetingsService: MeetingsService,
        public meetingsUiService: MeetingsUiService,
        public responsiveService: ResponsiveService,
        elementRef: ElementRef,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super(elementRef);
        this.updateData$.pipe(
            debounceTime(200), // prevent multiple updates caused by update from another session (typically multiple agenda items changed)
            tap(() => {
                this.initialised = false;
            }),
            filter(() => !!this.meeting),
            switchMap(() => this.meetingsService.getAgendaItemsForMeeting(this.meeting)),
            this.takeUntilDestroyed(),
        ).subscribe((agendaItems) => {
            // select the first in progress item
            for (const agendaItem of agendaItems) {
                if (agendaItem.extensions.isInProgress) {
                    // set the selected item only after DX has a chance to process the new agenda items
                    // (https://supportcenter.devexpress.com/ticket/details/t946156/accordion-cannot-read-property-resolved-of-undefined)
                    setTimeout(() => this.selectedItem = agendaItem, 50);
                    break;
                }
            }

            this.agendaItems = agendaItems;
            this.hasUpdate = true;
            this.initialised = true;
        });

        merge(
            rxjsBreezeService.entityTypeChanged(MeetingAgendaItem),
            rxjsBreezeService.entityTypeDetached(MeetingAgendaItem),
        ).pipe(
            this.takeUntilDestroyed(),
        ).subscribe((item) => {
            if (item.meetingId === this.meeting.meetingId) {
                this.updateData$.next();
            }
        });

        this.updateAccordion$.pipe(
            debounceTime(200), // only 1 refresh for multiple close emits
            this.takeUntilDestroyed(),
        ).subscribe(() => this.accordionInstance?.updateDimensions());
    }

    public ngAfterViewInit() {
        // scroll to the end meeting button when it appears
        this.endMeetingButtonElements?.changes.subscribe(() => this.endMeetingButtonElements?.first?.scrollIntoView());
    }

    public ngAfterViewChecked() {
        if (this.hasUpdate && this.elementRef!.nativeElement.offsetParent && this.accordionInstance) {
            this.hasUpdate = false;
            this.updateAccordion$.next();
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.meeting && this.meeting) {
            this.meetingsService.canEditMeetingItemsAsParticipant(this.meeting).pipe(
                this.takeUntilDestroyed(),
            ).subscribe((canParticipate) => {
                this.canParticipateInMeeting = canParticipate;
                // meeting update - get agenda items
                this.updateData$.next();
            });
        }
    }

    public getStatusButtonPresetType(agendaItem: MeetingAgendaItem) {
        if (agendaItem.extensions.isEnded) {
            return "checkedBorderless";
        } else {
            return "notCheckedBorderless";
        }
    }

    public toggleAgendaItem(agendaItem: MeetingAgendaItem, e: MouseEvent) {
        if (!agendaItem.extensions.isEnded) {
            agendaItem.extensions.endItem();
            let nextItem = this.agendaItems
                .find((i) => i.ordinal > agendaItem.ordinal && !i.extensions.isEnded);
            if (!nextItem) {
                nextItem = this.agendaItems.find((i) => !i.extensions.isEnded);
            }

            if (nextItem) {
                nextItem.extensions.startItem();
            }
        } else {
            agendaItem.extensions.startItem();
        }

        this.meetingsService.saveEntities(this.agendaItems).pipe(
            this.takeUntilDestroyed(),
        ).subscribe();

        e.preventDefault();
        e.stopPropagation();
    }

    public itemSelectionChanged(selectedItem: MeetingAgendaItem) {
        if (this.selectedItem !== selectedItem && this.initialised) {
            if (!selectedItem && this.selectedItem) {
                if (!this.selectedItem.extensions.isEnded) {
                    // all collapsed -> last selection should be reset if not ended
                    this.selectedItem.status = MeetingAgendaItemStatus.NotStarted;
                }
            }

            if (selectedItem && !selectedItem.extensions.isEnded) { // won't do anything if opening a selected item
                selectedItem.extensions.startItem();
            }

            this.meetingsService.saveEntities(this.agendaItems).pipe(
                this.takeUntilDestroyed(),
            ).subscribe(() => {
                if (selectedItem) { // selectedItem will be null if collapsed without expanding
                    setTimeout(() => {
                        const focusElement = this.elementRef?.nativeElement.querySelector(`#${this.AgendaItemIdPrefix}${selectedItem.meetingAgendaItemId}`) as HTMLElement;
                        if (focusElement) {
                            if (!ElementUtilities.isElementInView(focusElement)) { // only scroll if not already in the view
                                focusElement.scrollIntoView({ behavior: "smooth", block: "start" });
                            }
                        }
                    }, 500); // wait for animation to finish
                }
            });

            this.selectedItem = selectedItem;
        }
    }

    public getSelectedClass(item: MeetingAgendaItem) {
        const classes = [];
        if (item === this.selectedItem) {
            classes.push("agenda-item-selected");
        }
        if (item.extensions.isPreWork) {
            classes.push("pre-work");
        }
        return classes;
    }

    public onContentReady() {
        this.updateAccordion$.next();
    }
}

import { Component, ElementRef, Input, OnDestroy, OnInit } from "@angular/core";
import { ContactType, ContactTypeMetadata } from "@common/ADAPT.Common.Model/embed/contact-type";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { Connection } from "@common/ADAPT.Common.Model/organisation/connection";
import { ConnectionType, ConnectionTypeLabel } from "@common/ADAPT.Common.Model/organisation/connection-type";
import { CulturalLeadership } from "@common/ADAPT.Common.Model/organisation/cultural-leadership";
import { CulturalRelationship } from "@common/ADAPT.Common.Model/organisation/cultural-relationship";
import { Role } from "@common/ADAPT.Common.Model/organisation/role";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { PersonContact } from "@common/ADAPT.Common.Model/person/person-contact";
import { PersonProfileItem } from "@common/ADAPT.Common.Model/person/person-profile-item";
import { PersonProfileItemValue } from "@common/ADAPT.Common.Model/person/person-profile-item-value";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { ActiveEntityUtilities } from "@common/lib/data/active-entity-utilities";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { DxUtilities } from "@common/lib/utilities/dx-utilities";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { DxGridWrapperHelper } from "@common/ux/base.component/dx-component-wrapper-builder";
import { DateFormats } from "@common/ux/date-formats";
import { CulturalLeadershipFrameworkAuthService } from "@org-common/lib/cultural-leadership/cultural-leadership-framework-auth.service";
import { CulturalLeadershipQueryUtilities } from "@org-common/lib/cultural-leadership/cultural-leadership-query-utilities";
import { DirectoryAuthService } from "@org-common/lib/directory-shared/directory-auth.service";
import { DirectorySharedService } from "@org-common/lib/directory-shared/directory-shared.service";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { OrganisationService } from "@org-common/lib/organisation/organisation.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { UserManagementService } from "@org-common/lib/user-management/user-management.service";
import DevExpress from "devextreme";
import dxDataGrid, { ColumnGroupCellTemplateData, dxDataGridOptions, InitializedEvent } from "devextreme/ui/data_grid";
import moment from "moment";
import { Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";

interface IGridHeaderItem {
    text: string;
    value: any[];
}

interface IPeopleGridRow {
    person: Person;
    connectionType: ConnectionType;
    connectionTypeLabel: string;
    culturalLeaders: Person[];
    roleConnections: RoleConnection[];
    teams: Team[];
    contactDetails: { [type in ContactType]: PersonContact[] };
}

@Component({
    selector: "adapt-people-grid",
    templateUrl: "./people-grid.component.html",
})
export class PeopleGridComponent extends BaseComponent implements OnInit, OnDestroy {
    public static readonly GridName = "adaptPeopleGrid";

    @Input() public filterByConnectionTypes = [ConnectionType.Employee];

    private culturalLeadershipConfiguration?: CulturalLeadership;
    private profileItems: PersonProfileItem[] = [];
    private roleHeaderItems: IGridHeaderItem[] = [];
    private teamHeaderItems: IGridHeaderItem[] = [];
    private stakeholderFeatureEnabled = false;
    private showRoles = !this.isAlto;
    private showTeams = !this.isAlto;
    private showProfile = this.directoryAuthService.currentPersonCanReadAtLeastOneProfile();
    private profileItemValuesFetched = false;
    public isLoading = true;
    public gridColumns: DevExpress.ui.dxDataGridColumn[] = []; // CM-5081 DX 21.1 has screwed up types - really should be without the prefix
    public gridOptions!: dxDataGridOptions;
    private gridInstance!: dxDataGrid;
    public gridHelper: DxGridWrapperHelper;
    public gridData: IPeopleGridRow[] = [];
    private updateSubscriptions: Subscription[] = [];

    public constructor(
        elementRef: ElementRef<HTMLElement>,
        private commonDataService: CommonDataService,
        private directorySharedService: DirectorySharedService,
        private commonTeamsService: CommonTeamsService,
        private organisationService: OrganisationService,
        private featuresService: FeaturesService,
        private directoryAuthService: DirectoryAuthService,
        private culturalLeadershipFrameworkAuthService: CulturalLeadershipFrameworkAuthService,
        userManagementService: UserManagementService,
        rxjsBreezeService: RxjsBreezeService,
    ) {
        super();

        this.updateSubscriptions.push(userManagementService.peopleAdded$.subscribe(() => {
            // This will emit after adding person, which won't get the added entity back as that's going through
            // the non-EF AccountController.AddPeople
            // - need force remote here to get the new person
            this.refreshGrid(true);
        }));
        this.updateSubscriptions.push(rxjsBreezeService.entityTypeChanged(RoleConnection).pipe(
            // entityTypeChanged will emit 1 entity at a time - with RoleConnection,
            // there will be heaps of them on initialisation when entities are attached.
            // - only need 1 refresh required to update people data and role connections in grid data
            debounceTime(1000),
        ).subscribe(() => {
            // signalr sync already updated entities in entity manager, don't have to forceRemote
            this.refreshGrid();
        }));

        this.gridHelper = new DxGridWrapperHelper(PeopleGridComponent.GridName, jQuery(elementRef.nativeElement));
    }

    public ngOnDestroy() {
        this.updateSubscriptions.forEach((sub) => sub.unsubscribe());
    }

    public async ngOnInit() {
        const self = this;

        // Can't do this in constructor as stakeholderView relies on the @Input which won't be defined then
        this.gridHelper.saveGridState(String(this.stakeholderView) + String(this.organisationService.getOrganisationId()));

        await Promise.all([
            promiseToGetRoles(),
            promiseToGetTeams(),
            promiseToGetCulturalLeadershipConfig(),
            promiseToGetProfileCategoryItems(),
            promiseToVerifyFeatures(),
            this.featuresService.promiseToCheckIfFeatureActive(FeatureName.StakeholdersPeople)
                .then((isActive) => this.stakeholderFeatureEnabled = isActive),
        ]);

        await this.promiseToGetPeopleData();
        this.setupGridColumns();
        this.gridHelper.callGrid((grid) => grid.setColumnsReady());
        this.isLoading = false;

        function promiseToGetCulturalLeadershipConfig() {
            if (self.isAlto) {
                return Promise.resolve();
            }

            if (self.culturalLeadershipFrameworkAuthService.currentPersonHasPermissionToReadCohorts()) {
                return new CulturalLeadershipQueryUtilities(self.commonDataService).promiseToGetCulturalLeadershipConfiguration()
                    .then((config: CulturalLeadership) => self.culturalLeadershipConfiguration = config);
            }
        }

        async function promiseToVerifyFeatures() {
            if (self.isAlto) {
                return Promise.resolve();
            }

            self.showRoles = await self.directorySharedService.promiseToVerifyFeaturesToDisplayRole();
            self.showTeams = await self.directorySharedService.promiseToVerifyFeaturesToDisplayTeams();
        }

        function promiseToGetRoles() {
            if (self.isAlto) {
                return Promise.resolve();
            }

            return self.directorySharedService.promiseToGetActiveRolesByPredicate()
                .then(setData);

            function setData(roles: Role[]) {
                self.roleHeaderItems = roles.filter(DirectorySharedService.isNotTeamBasedRole)
                    .filter(DirectorySharedService.isNotAccessLevelRole)
                    .map(convertToHeaderItem);

                self.roleHeaderItems.unshift({
                    text: "(Blanks)",
                    value: [self.calculateRolesCellValue, "=", ""],
                });

                function convertToHeaderItem(role: Role) {
                    return {
                        text: role.label,
                        value: [self.calculateRolesCellValue, "contains", role.label],
                    } as IGridHeaderItem;
                }
            }
        }

        function promiseToGetTeams() {
            if (self.isAlto) {
                return Promise.resolve();
            }

            return self.commonTeamsService.promiseToGetAllTeams()
                .then(setData);

            function setData(teams: Team[]) {
                self.teamHeaderItems = teams.map(convertToHeaderItem);

                self.teamHeaderItems.unshift({
                    text: "(Blanks)",
                    value: [self.calculateTeamsCellValue, "=", ""],
                });

                function convertToHeaderItem(team: Team) {
                    return {
                        text: team.name,
                        value: [self.calculateTeamsCellValue, "contains", team.name],
                    } as IGridHeaderItem;
                }
            }
        }

        function promiseToGetProfileCategoryItems() {
            if (self.stakeholderView || !self.showProfile) {
                return Promise.resolve();
            }

            return self.directorySharedService.promiseToGetAccessiblePersonProfileItems()
                .then(setData);

            function setData(items: PersonProfileItem[]) {
                self.profileItems = items;
            }
        }
    }

    public get stakeholderView() {
        return this.filterByConnectionTypes.every((ct) => ct !== ConnectionType.Employee);
    }

    @Autobind
    public onGridInitialized(e: InitializedEvent) {
        this.gridHelper.initialiseGrid(e);
        this.gridInstance = e.component!;
    }

    @Autobind
    private setupGridColumns() {
        const self = this;

        setupNameColumns();

        if (this.showProfile) {
            ContactTypeMetadata.All.forEach(setupContactColumn);
            this.profileItems.forEach(setupProfileItemColumn);
        }

        setupConnectionAndPositionColumns();
        if (!this.isAlto) {
            setupCulturalLeaderColumn();
        }

        function setupNameColumns() {
            self.gridColumns.push({
                caption: "First name",
                dataField: "person.firstName",
                dataType: "string",
                visible: false,
            });

            self.gridColumns.push({
                caption: "Surname",
                dataField: "person.lastName",
                dataType: "string",
                visible: false,
            });

            self.gridColumns.push({
                caption: "Full name",
                dataField: "person.fullName",
                name: "People",
                dataType: "string",
                cellTemplate: "fullNameCellTemplate",
                sortOrder: "asc",
                visible: true,
            });
        }

        function setupContactColumn(contactTypeMetadata: ContactTypeMetadata) {
            self.gridColumns.push({
                caption: contactTypeMetadata.name,
                cellTemplate: "contactDetailCellTemplate",
                calculateCellValue,
                dataType: "string",
                dataField: "dummy_contact_" + contactTypeMetadata.name,
                allowSorting: true,
                allowFiltering: true,
                visible: contactTypeMetadata.type === ContactType.Email || contactTypeMetadata.type === ContactType.Phone,
            });

            function calculateCellValue(row: IPeopleGridRow) {
                return row.contactDetails[contactTypeMetadata.type]
                    .map((contact) => contact.value)
                    .join(", ");
            }
        }

        function setupConnectionAndPositionColumns() {
            if (self.filterByConnectionTypes.length > 1) {
                self.gridColumns.push({
                    dataField: "connectionTypeLabel",
                    dataType: "string",
                    groupIndex: 0,
                    groupCellTemplate: "connectionTypeTemplate",
                });
            }

            if (!self.stakeholderView && !self.isAlto) {
                self.gridColumns.push({
                    caption: "Position title",
                    calculateCellValue: calculateLatestPositionName,
                    dataType: "string",
                    dataField: "dummy_position",
                    allowSorting: true,
                    allowFiltering: true,
                });
            }

            self.gridColumns.push({
                caption: "Start date",
                calculateCellValue: calculateLatestConnectionStartDate,
                dataType: "date",
                dataField: "dummy_startdate",
                format: DateFormats.globalize.short,
                allowSorting: true,
                allowFiltering: true,
                visible: false,
            });

            if (self.showRoles) {
                self.gridColumns.push({
                    caption: "Roles",
                    cellTemplate: "roleCellTemplate",
                    calculateCellValue: self.calculateRolesCellValue,
                    dataType: "string",
                    dataField: "dummy_roles",
                    allowSorting: true,
                    allowFiltering: true,
                    headerFilter: {
                        dataSource: self.roleHeaderItems,
                    },
                });
            }

            if (self.showTeams) {
                self.gridColumns.push({
                    caption: "Teams",
                    cellTemplate: "teamCellTemplate",
                    calculateCellValue: self.calculateTeamsCellValue,
                    dataType: "string",
                    dataField: "dummy_teams",
                    allowSorting: true,
                    allowFiltering: true,
                    headerFilter: {
                        dataSource: self.teamHeaderItems,
                    },
                });
            }

            function calculateLatestPositionName(row: IPeopleGridRow): string | undefined {
                const person = row.person;
                if (!person) {
                    return undefined;
                }

                const connection = person.getLatestEmployeeConnection();
                if (!connection) {
                    return undefined;
                }

                const position = connection.getLatestPosition();
                if (!position) {
                    // if we get here, then there is probably a priming error or something like that
                    self.log.warn("possible position priming error in people-grid-component");
                    return undefined;
                }

                return position.name;
            }

            function calculateLatestConnectionStartDate(row: IPeopleGridRow) {
                return row.person?.getLatestConnection(row.connectionType)?.startDate;
            }
        }

        function setupCulturalLeaderColumn() {
            if (!self.stakeholderView && self.culturalLeadershipFrameworkAuthService.currentPersonHasPermissionToReadCohorts()) {
                self.gridColumns.push({
                    caption: self.culturalLeadershipConfiguration!.primaryName,
                    dataType: "string",
                    dataField: "dummy_culturalleaders",
                    cellTemplate: "culturalLeadersTemplate",
                    calculateCellValue: self.calculateCulturalLeadersCellValue,
                    allowSorting: true,
                    allowFiltering: true,
                    visible: false,
                });
            }
        }

        function setupProfileItemColumn(profileItem: PersonProfileItem) {
            const column: DevExpress.ui.dxDataGridColumn = { // CM-5081 DX 21.1 has screwed up types - really should be without the prefix
                caption: profileItem.label,
                calculateCellValue,
                dataType: profileItem.isDate
                    ? "date"
                    : "string",
                dataField: "dummy_profileitem_" + profileItem.label,
                encodeHtml: !profileItem.isRichText,
                allowHeaderFiltering: !profileItem.isRichText && !profileItem.isText,
                allowFiltering: true,
                cssClass: profileItem.isRichText
                    ? "user-rich-text"
                    : "",
                visible: false,
            };

            if (profileItem.isDate) {
                column.format = "monthAndDay";
                column.allowSorting = true;
                column.headerFilter = {
                    dataSource: buildMonthlyHeaderItems(),
                };
            }

            self.gridColumns.push(column);

            function calculateCellValue(row: IPeopleGridRow) {
                const person = row.person;
                if (!self.profileItemValuesFetched) {
                    const columnIndex = self.gridColumns.indexOf(column);
                    const isColumnVisible = self.gridInstance.columnOption(columnIndex, "visible");
                    if (isColumnVisible) {
                        self.profileItemValuesFetched = true;
                        self.directorySharedService.promiseToGetAllPersonProfileItemValues();
                    }

                    return undefined;
                }

                const value = ArrayUtilities.getSingleFromArray(person.personProfileItemValues.filter(isCurrentProfileItem));
                if (!value) {
                    return undefined;
                }

                return profileItem.isDate
                    ? value.date
                    : value.text;

                function isCurrentProfileItem(itemValue: PersonProfileItemValue) {
                    return itemValue.personProfileItemId === profileItem.personProfileItemId;
                }
            }

            function buildMonthlyHeaderItems() {
                const months: string[] = [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                ];

                return months.map(convertToHeaderItem);

                function convertToHeaderItem(month: string, index: number) {
                    return {
                        text: month,
                        value: [calculateCellMonth, "=", index],
                    };
                }

                function calculateCellMonth(row: IPeopleGridRow) {
                    const date = calculateCellValue(row);

                    if (!date) {
                        return undefined;
                    }

                    return moment(date).month();
                }
            }
        }
    }

    @Autobind
    public getNonTeamBasedRolesForPerson(person: Person) {
        return this.getRoleConnectionsForPerson(person)
            .filter(DirectorySharedService.isNotTeamBasedRoleConnection)
            .filter(DirectorySharedService.isNotAccessLevelRoleConnection)
            .sort((a, b) => a.role.label.localeCompare(b.role.label));
    }

    @Autobind
    public getRoleConnectionsForPerson(person: Person): RoleConnection[] {
        return this.directorySharedService.getRoleConnectionsForPerson(person, true);
    }

    @Autobind
    private async promiseToGetPeopleData(forceRemote = false) {
        // let these fetch and return in the background, I'm happy to let this data get fetched and processed as it arrives from the server

        // still leave these here just in case there is no initial get for these - signalr sync will only add modified entities and still missing prime
        this.directorySharedService.promiseToGetAllRoleConnections(forceRemote);
        this.directorySharedService.promiseToGetAllRoles(forceRemote);

        if (this.showProfile) {
            this.directorySharedService.promiseToGetAllPersonContacts(forceRemote)
                .then(() => {
                    self.gridData.forEach((r) => {
                        for (const contactType of ContactTypeMetadata.All) {
                            r.contactDetails[contactType.type] = r.person.getContactsOfType(contactType.type);
                        }
                    });
                });
        }

        const self = this;
        let people: Person[] = await this.directorySharedService.promiseToGetAllPeople(forceRemote);

        if (self.stakeholderFeatureEnabled) {
            people = people.filter((person: Person) => {
                return person.connections.some(isValidConnection);
            });
        }

        this.gridData = [];
        for (const connectionType of this.filterByConnectionTypes) {
            // if walter is a coach and also a stakeholder, he will appear twice under different connection type groupings
            const connectionTypeLabel = ConnectionTypeLabel.plural(connectionType);
            people
                .filter((p) => p.getLatestConnection(connectionType)?.isActive())
                .forEach((p) => this.gridData.push({
                    person: p,
                    connectionType,
                    connectionTypeLabel,
                    culturalLeaders: this.isAlto ? [] : this.getActiveCulturalLeadersForPerson(p),
                    roleConnections: this.isAlto ? [] : (this.getNonTeamBasedRolesForPerson(p)
                        .filter((roleConnection) => roleConnection.connection.connectionType === connectionType)),
                    teams: this.isAlto ? [] : this.getActiveTeamsForPerson(p, connectionType),
                    contactDetails: {
                        [ContactType.Address]: p.getContactsOfType(ContactType.Address),
                        [ContactType.Email]: p.getContactsOfType(ContactType.Email),
                        [ContactType.Messaging]: p.getContactsOfType(ContactType.Messaging),
                        [ContactType.Phone]: p.getContactsOfType(ContactType.Phone),
                    },
                }));
        }

        function isValidConnection(connection: Connection) {
            return self.stakeholderView
                ? !connection.isEmployeeConnection()
                : connection.isEmployeeConnection();
        }
    }

    @Autobind
    private calculateRolesCellValue(row: IPeopleGridRow) {
        return row.roleConnections.map(getRoleLabel)
            .join(",");

        function getRoleLabel(roleConnection: RoleConnection) {
            if (roleConnection && roleConnection.role) {
                return roleConnection.role.label;
            }

            return undefined;
        }
    }

    @Autobind
    private calculateTeamsCellValue(row: IPeopleGridRow) {
        return row.teams.map((t) => t.name)
            .join(",");
    }

    @Autobind
    private getActiveTeamsForPerson(person: Person, connectionType: ConnectionType) {
        const distinctTeams: Team[] = [];

        this.getRoleConnectionsForPerson(person)
            .filter((roleConnection) => roleConnection.connection.connectionType === connectionType &&
                DirectorySharedService.isActiveTeamRoleConnection(roleConnection) &&
                !!roleConnection.team)
            .map(getTeam)
            .sort((a, b) => a.name.localeCompare(b.name))
            .forEach(addToDistinctTeams);

        return distinctTeams;

        function addToDistinctTeams(team: Team) {
            if (team && team.isActive() && !distinctTeams.some(matchTeamId)) {
                distinctTeams.push(team);
            }

            function matchTeamId(checkTeam: Team) {
                return checkTeam.teamId === team.teamId;
            }
        }

        function getTeam(roleConnection: RoleConnection) {
            return roleConnection.team;
        }
    }

    @Autobind
    private calculateCulturalLeadersCellValue(row: IPeopleGridRow) {
        const person = row.person;
        return this.getActiveCulturalLeadersForPerson(person)
            .map((cl: Person) => cl.fullName)
            .join(",");
    }

    public getActiveCulturalLeadersForPerson(person: Person) {
        return person.culturalLeaderRelationships
            .filter(ActiveEntityUtilities.isActive)
            .map((i: CulturalRelationship) => i.culturalLeader);
    }

    @Autobind
    private refreshGrid(forceRemote = false) {
        if (this.gridInstance) {
            this.promiseToGetPeopleData(forceRemote);
        }
    }

    @Autobind
    public showColumnChooser() {
        if (this.gridInstance) {
            this.gridInstance.showColumnChooser();
        }
    }

    @Autobind
    public exportAllData() {
        if (this.gridInstance) {
            DxUtilities.exportGridToExcel("People", this.gridInstance);
        }
    }

    @Autobind
    public resetGridState() {
        this.gridHelper.callGrid((grid) => grid.resetState());
    }

    public getConnectionTypeSummaryCount(item: ColumnGroupCellTemplateData) {
        const summary = item.summaryItems.find((s: { name: string }) => s.name === "connectionTypeCountSummary");
        return summary ? summary.value : undefined;
    }
}

import { ClassProvider, InjectionToken, Type } from "@angular/core";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { Observable, of } from "rxjs";
import { ISearchProviderOptions, SearchType } from "./search.interface";

export const SEARCH_PROVIDERS = new InjectionToken("SEARCH_PROVIDERS");

export abstract class SearchProvider<T = any> extends BaseComponent {
    public abstract readonly Type: SearchType;

    public shouldSkip(options: ISearchProviderOptions): boolean {
        return !options;
    }

    public initialise(): Observable<any> {
        return of(true);
    }

    public abstract execute(options: ISearchProviderOptions): Observable<T[]>;

    protected hasKeyword(data: string | undefined, keyword: string) {
        return data?.toLowerCase().includes(keyword?.toLowerCase());
    }
}

export function provideSearchProvider(searchProvider: Type<SearchProvider>): ClassProvider {
    return {
        provide: SEARCH_PROVIDERS,
        useClass: searchProvider,
        multi: true,
    };
}

import { AdaptClientConfiguration, AdaptProject } from "@common/configuration/adapt-client-configuration";

export enum OrganisationFeatureFlag {
    ValuesV2 = "ValuesV2",
    HasSubscribed = "HasSubscribed",
}

// functions called for each flag to check if they should be default enabled
export const OrganisationFeatureFlagDefaults: { [k in OrganisationFeatureFlag]?: () => boolean } = {
    [OrganisationFeatureFlag.ValuesV2]: () => AdaptClientConfiguration.AdaptProjectName === AdaptProject.Alto,
};

import { Injectable } from "@angular/core";
import { FeaturePermissionName } from "@common/ADAPT.Common.Model/embed/feature-permission-name.enum";
import { AuthorisationService } from "../authorisation/authorisation.service";

@Injectable({
    providedIn: "root",
})
export class ValuesConstitutionAuthService {
    public static readonly ReadValuesConstitution = "readValuesConstitution";
    public static readonly EditValuesConstitution = "editValuesConstitution";

    public static registerAccess(authorisationService: AuthorisationService) {
        authorisationService.registerAccessVerifier(
            ValuesConstitutionAuthService.ReadValuesConstitution,
            {
                requirePermissions: [
                    FeaturePermissionName.CompassValuesConstitutionRead,
                    FeaturePermissionName.CompassValuesConstitutionEdit,
                ],
            },
        );
        authorisationService.registerAccessVerifier(
            ValuesConstitutionAuthService.EditValuesConstitution,
            {
                requirePermissions: [
                    FeaturePermissionName.CompassValuesConstitutionEdit,
                ],
            },
        );
    }
}

import { Injectable, Injector } from "@angular/core";
import { ContactTypeMetadata } from "@common/ADAPT.Common.Model/embed/contact-type";
import { RoleConnection } from "@common/ADAPT.Common.Model/organisation/role-connection";
import { RoleTypeBreezeModel } from "@common/ADAPT.Common.Model/organisation/role-type";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { Person, PersonBreezeModel } from "@common/ADAPT.Common.Model/person/person";
import { PersonDetail, PersonDetailBreezeModel } from "@common/ADAPT.Common.Model/person/person-detail";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { IntegratedArchitectureFrameworkQueryUtilities } from "@org-common/lib/architecture/integrated-architecture-framework-query-utilities";
import { AfterOrganisationInitialisationObservable } from "@org-common/lib/organisation/after-organisation-initialisation.decorator";
import { BaseOrganisationService } from "@org-common/lib/organisation/base-organisation.service";
import { from, Observable, of } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { OrganisationService } from "../organisation/organisation.service";
import { CommonTeamsService } from "../teams/common-teams.service";

@Injectable({
    providedIn: "root",
})
export class PersonService extends BaseOrganisationService {
    public constructor(
        injector: Injector,
        private commonTeamsService: CommonTeamsService,
        private organisationService: OrganisationService,
    ) {
        super(injector);
    }

    public getContactTypes() {
        return of(ContactTypeMetadata.All);
    }

    protected organisationInitialisationActions() {
        return [
            this.commonDataService.getAll(RoleTypeBreezeModel),
        ];
    }

    @AfterOrganisationInitialisationObservable
    public getActiveRoleConnections(personId: number): Observable<RoleConnection[]> {
        return new IntegratedArchitectureFrameworkQueryUtilities(this.commonDataService).getRoleConnectionsForPersonId(personId, true);
    }

    public getActiveTeams(personId: number): Observable<Team[]> {
        return from(this.commonTeamsService.promiseToGetActiveTeamsForPersonId(personId));
    }

    public getPerson(personId: number): Observable<Person | undefined> {
        return this.commonDataService.getById(PersonBreezeModel, personId);
    }

    public createPersonDetail(personId: number, name: string) {
        return this.commonDataService.create(PersonDetailBreezeModel, {
            name,
            personId,
            organisationId: this.organisationService.getOrganisationId(),
        } as Partial<PersonDetail>);
    }

    public getPersonDetail(personId: number, name: string) {
        const predicate = new MethodologyPredicate<PersonDetail>("personId", "==", personId)
            .and(new MethodologyPredicate<PersonDetail>("name", "==", name));
        return this.commonDataService.getWithOptions(PersonDetailBreezeModel, predicate.getKey(PersonDetailBreezeModel.identifier), {
            predicate,
            top: 1,
        }).pipe(
            map(ArrayUtilities.getSingleFromArray),
        );
    }

    public getOrCreatePersonDetail(personId: number, name: string) {
        return this.getPersonDetail(personId, name).pipe(
            switchMap((detail) => detail
                ? of(detail)
                : this.createPersonDetail(personId, name)),
        );
    }
}

<adapt-feature-guidance [configItem]="configItem">
    <p>
        Set the basic details for the team, as well as ending or deleting the team.
    </p>
</adapt-feature-guidance>

<adapt-feature-configuration [configItem]="configItem">
    <div class="row"
         *ngIf="team">
        <div class="col-md-5">
            <!-- name -->
            <div class="form-group">
                <label for="teamNameLabel">Team name</label>
                <dx-text-box id="teamNameLabel"
                             [adaptValidateEntity]="team"
                             adaptValidateEntityProperty="name"
                             [readOnly]="!isActive"
                             [(value)]="team.name"
                             data-test="team-name-input"></dx-text-box>
                <dx-check-box *adaptIfAlto="false"
                              class="mt-2"
                              [value]="team.isPrivate"
                              (valueChange)="onPrivateChanged($any($event))"
                              text="Private team"></dx-check-box>
            </div>

            <div class="form-group">
                <label>Labels</label>
                <adapt-select-label-for-related-entity [team]="team"
                                                       (entitiesChange)="changedLabelLocationEntities = $event">
                </adapt-select-label-for-related-entity>
            </div>

            <ng-container *adaptIfAlto="false">
                <!-- start-date -->
                <div class="form-group">
                    <label for="startDate">Start date</label>
                    <dx-date-box id="startDate"
                                 type="date"
                                 [value]="team.startDate"
                                 [max]="maxStartDate"
                                 [readOnly]="!isActive"
                                 (valueChange)="team.startDate = $any($event)">
                        <dx-validator>
                            <dxi-validation-rule type="required"
                                                 message="Start date is required"></dxi-validation-rule>
                        </dx-validator>
                    </dx-date-box>
                </div>

                <!-- end-date -->
                <div *ngIf="team.endDate"
                     class="form-group">
                    <label for="endDate">End date</label>
                    <div id="endDate">{{team.endDate | adaptDate}}</div>
                </div>

                <!-- buttons to end, delete or restore team -->
                <div [hidden]="!isActive || !originallyActive">
                    <div class="alert alert-secondary"
                         *ngIf="isLeaderTeam$ | async; else showEndTeamOption">
                        This team is currently set to be the leadership team for any resilient business
                        assessment surveys. You will need to update the resilient business assessment configuration in the organisation
                        configuration before ending or deleting this team.
                    </div>
                    <ng-template #showEndTeamOption>
                        <label class="pt-4">Deactivate team</label>
                        <div>
                            This preserves all data for this team in the organisation, but the team will not be accessible.
                            All boards will be archived.
                            <button class="d-block"
                                    adaptButtonIcon="fal fa-fw fa-stop-circle"
                                    (click)="deactivateTeam()">Deactivate team
                            </button>
                        </div>

                        <label class="pt-3">Delete team</label>
                        <div>
                            This is a destructive operation and all data associated with the team will be deleted.
                            <button class="d-block"
                                    adaptButton="delete"
                                    (click)="deleteTeam()">&nbsp;team
                            </button>
                        </div>
                    </ng-template>
                </div>
            </ng-container>
        </div>
    </div>
</adapt-feature-configuration>

import { Component } from "@angular/core";

@Component({
    selector: "adapt-two-column-page",
    template: `
        <adapt-two-column-layout [fullPage]="true">
            <ng-container left-column>
                <ng-content select="[left-column]"></ng-content>
            </ng-container>
            <ng-container right-column>
                <ng-content select="[right-column]"></ng-content>
            </ng-container>
        </adapt-two-column-layout>
    `,
})
export class TwoColumnPageComponent {}

import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Objective } from "@common/ADAPT.Common.Model/organisation/objective";
import { ObjectiveType } from "@common/ADAPT.Common.Model/organisation/objective-type";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { LabellingService } from "@org-common/lib/labelling/labelling.service";
import { ObjectivesService } from "@org-common/lib/objectives/objectives.service";
import { map, switchMap } from "rxjs";

@Component({
    selector: "adapt-org-objectives-dashboard-element",
    templateUrl: "./org-objectives-dashboard-element.component.html",
    styleUrls: ["./org-objectives-dashboard-element.component.scss"],
})
export class OrgObjectivesDashboardElementComponent extends BaseComponent implements OnInit {
    @Input() public name?: string;
    @Input() public objectiveType = ObjectiveType.Annual;

    @Output() public initialised = new EventEmitter<void>();

    public objectives: Objective[] = [];

    public constructor(
        elementRef: ElementRef,
        private objectivesService: ObjectivesService,
        private labellingService: LabellingService,
    ) {
        super(elementRef);
    }

    public ngOnInit() {
        this.objectivesService.getInProgressObjectives(undefined, false).pipe(
            map((objectives) => objectives.filter((objective) => objective.type === this.objectiveType)),
            switchMap((objectives) => this.labellingService.primeLabelLocationsForObjectives(objectives).pipe(
                map(() => objectives))),
            this.takeUntilDestroyed(),
        ).subscribe((objectives) => {
            this.objectives = objectives;
            this.isInitialised = true;
            this.initialised.next();
        });
    }
}

import { Component, Input, OnChanges, ViewChild } from "@angular/core";
import { PaymentMethod } from "@common/ADAPT.Common.Model/account/account";
import { Invoice, InvoiceStatus } from "@common/ADAPT.Common.Model/account/invoice";
import { Organisation } from "@common/ADAPT.Common.Model/organisation/organisation";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { DxUtilities } from "@common/lib/utilities/dx-utilities";
import { DateFormats } from "@common/ux/date-formats";
import { DxDataGridComponent } from "devextreme-angular";
import { InvoiceTypeMetadata } from "../../ADAPT.Common.Model/account/invoice-type";
import { PaymentProcessingService } from "../payment-processing.service";

@Component({
    selector: "adapt-display-historical-invoices",
    templateUrl: "./display-historical-invoices.component.html",
    styleUrls: ["./display-historical-invoices.component.scss"],
})
export class DisplayHistoricalInvoicesComponent implements OnChanges {
    @Input() public organisation!: Organisation;
    @Input() public displayAdvancedOptions!: boolean;
    @ViewChild(DxDataGridComponent) public gridInstance?: DxDataGridComponent;

    public invoices: Invoice[] = [];

    public dateFormat = DateFormats.globalize.short;
    public errorMessage?: string;

    constructor(
        private paymentProcessingService: PaymentProcessingService,
    ) {}

    public async ngOnChanges() {
        if (this.organisation) {
            this.invoices = await this.paymentProcessingService.getInvoices(this.organisation.organisationId);
        }
        this.invoices = (this.displayAdvancedOptions)
            ? this.invoices
            : this.invoices.filter((invoice) => invoice.paymentMethod === PaymentMethod.CreditCard && invoice.invoiceItems.length > 0);
    }

    @Autobind
    public onClickExport(invoice: Invoice) {
        const invoiceId = invoice.invoiceId;
        const issueDate = invoice.date;
        const formattedIssueDate = issueDate.toLocaleDateString();
        const filename = `${this.organisation.name}_Invoice_${formattedIssueDate}.pdf`;
        return this.paymentProcessingService.exportInvoicePdf(invoiceId, this.organisation.organisationId, filename);
    }

    public exportAllData() {
        DxUtilities.exportGridToExcel("invoices", this.gridInstance!.instance);
    }

    public showColumnChooser() {
        this.gridInstance!.instance.showColumnChooser();
    }

    public getTransactionId(invoice: Invoice) {
        // filter for successful payment (but only if the status of the invoice is Paid)
        const payment = invoice.accountPayments.find((ap) => invoice.status !== InvoiceStatus.Paid || ap.successful);
        return payment?.transactionId ?? "N/A";
    }

    public getPaymentMethod(pm: PaymentMethod) {
        return pm === PaymentMethod.CreditCard ? "Credit Card" : "Invoice";
    }

    public calculateTypeCellValue(invoice: Invoice) {
        return InvoiceTypeMetadata.ByType[invoice.type].name;
    }
}

import { Component, Injector, OnInit } from "@angular/core";
import { Goal } from "@common/ADAPT.Common.Model/organisation/goal";
import { ImplementationKitArticle } from "@common/implementation-kit/implementation-kit-article.enum";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { RxjsBreezeService } from "@common/lib/data/rxjs-breeze.service";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { debounceTime, merge } from "rxjs";
import { OrganisationPageRouteBuilder } from "../../route/organisation-page-route-builder";
import { StrategyAuthService } from "../../strategy/strategy-auth.service";
import { StrategicGoalsService } from "../strategic-goals.service";

@Component({
    selector: "adapt-strategic-goals-page",
    templateUrl: "./strategic-goals-page.component.html",
})
export class StrategicGoalsPageComponent extends BaseRoutedComponent implements OnInit {
    public readonly EditStrategy = StrategyAuthService.EditStrategicGoals;

    public hasGoals = false;
    public isReordering = false;
    public showClosed = false;
    public helpArticleId;

    public constructor(
        injector: Injector,
        rxjsBreezeService: RxjsBreezeService,
        private goalService: StrategicGoalsService,
    ) {
        super(injector);

        merge(
            rxjsBreezeService.entityTypeChanged(Goal),
        ).pipe(
            debounceTime(100), // just do 1 init even with multiple measurements and goal changes in a single save
            this.takeUntilDestroyed(),
        ).subscribe(() => this.ngOnInit());

        this.helpArticleId = this.isAlto
            ? ImplementationKitArticle.StrategicGoalsOverview
            : ImplementationKitArticle.StrategicGoalsOverviewEmbed;
    }

    public ngOnInit() {
        this.goalService.getAllGoals().pipe(
            this.takeUntilDestroyed(),
        ).subscribe((goals) => {
            this.hasGoals = goals.length > 0;
            this.notifyActivated();
        });
    }

    @Autobind
    public addGoal() {
        return this.goalService.editGoalAfterCreate();
    }

    public reorderGoals() {
        this.isReordering = !this.isReordering;
    }
}

export const StrategyGoalsPageRoute = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-strategic-goals-page", StrategicGoalsPageComponent)
    .atOrganisationUrl("/strategic-goals")
    .withTitle("Strategic Goals")
    .verifyingAccess(StrategyAuthService.ReadStrategicGoals)
    .reloadOnSearch(false)
    .build();

<div *adaptLoadingUntilFirstEmit="let account of account$">
    <div *ngIf="account!.extensions.isTrial; else normalSubscription"
         class="row gy-3">
        <div class="col-lg-6">
            <p>We'd love to have you on board! Click below to find out more and start your {{ProductLabel}} subscription!</p>

            <button adaptButton="default"
                    (click)="accountService.showSubscriptionDialog().subscribe()">Start subscription</button>
        </div>
        <div class="col-lg-6 d-flex flex-column">
            <h4>Customer license agreement</h4>
            <adapt-configure-eula [account]="account"></adapt-configure-eula>
        </div>
    </div>

    <ng-template #normalSubscription>
        <div class="row">
            <div class="col-md-4">
                <adapt-configure-payment-method></adapt-configure-payment-method>
            </div>
            <div class="col-md-4">
                <adapt-configure-billing-details></adapt-configure-billing-details>
            </div>
            <div class="col-md-4">
                <adapt-configure-subscription></adapt-configure-subscription>
            </div>
        </div>

        <adapt-display-historical-invoices *ngIf="account"
                                           [organisation]="account.organisation"
                                           [displayAdvancedOptions]="false"></adapt-display-historical-invoices>
    </ng-template>
</div>

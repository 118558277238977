import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AdaptTabStyledDashboardElementComponent } from "./tab-styled-dashboard-element.component";

@NgModule({
    declarations: [AdaptTabStyledDashboardElementComponent],
    imports: [
        CommonModule,
    ],
    exports: [
        AdaptTabStyledDashboardElementComponent,
    ],
})
export class AdaptTabStyledDashboardElementModule {}

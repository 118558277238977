<adapt-menu #menuComponent
            [items]="menuItems"
            data-test="objective-actions">
</adapt-menu>
<ng-template #itemTemplate
             let-item>
    <adapt-display-objective-status [objectiveStatus]="item.templateData"></adapt-display-objective-status>
</ng-template>
<ng-template #labelMenuTemplate>
    <adapt-recent-label-submenu-content [objective]="objective"
                                        (itemClicked)="menuComponent.refresh()"></adapt-recent-label-submenu-content>
</ng-template>

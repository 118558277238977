import { Directive, Injector } from "@angular/core";
import { AllEventTypePresets } from "@common/ADAPT.Common.Model/organisation/event-type";
import { MeetingAgendaItem } from "@common/ADAPT.Common.Model/organisation/meeting-agenda-item";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { IMeetingAgendaItemComponent } from "@org-common/lib/meetings/meeting-agenda-component-registry";
import { OAuthService } from "@org-common/lib/oauth/oauth.service";
import { IReviewRecurrencesMap } from "@org-common/lib/schedule/review-recurrences/review-recurrences.component";
import { ScheduleService } from "@org-common/lib/schedule/schedule.service";
import { ScheduleUiService } from "@org-common/lib/schedule/schedule-ui.service";
import { CommonTeamsService } from "@org-common/lib/teams/common-teams.service";
import { CommonTeamsAuthService } from "@org-common/lib/teams/common-teams-auth.service";

@Directive()
export class WorkshopSetCadenceBaseComponent implements IMeetingAgendaItemComponent {
    public readonly StewardshipWorkMeetingsEdit = CommonTeamsAuthService.EditTeamMeetings;

    public loading = true;
    public meetingAgendaItem?: MeetingAgendaItem;
    public recurrences?: IReviewRecurrencesMap;

    protected creatingNextCadence = false;

    protected eventTypePresets = AllEventTypePresets;

    protected commonDataService: CommonDataService;
    protected dialogService: AdaptCommonDialogService;
    protected scheduleService: ScheduleService;
    protected scheduleUiService: ScheduleUiService;
    protected teamsService: CommonTeamsService;
    protected oauthService: OAuthService;

    public constructor(
        injector: Injector,
    ) {
        this.commonDataService = injector.get(CommonDataService);
        this.dialogService = injector.get(AdaptCommonDialogService);
        this.scheduleService = injector.get(ScheduleService);
        this.scheduleUiService = injector.get(ScheduleUiService);
        this.teamsService = injector.get(CommonTeamsService);
        this.oauthService = injector.get(OAuthService);
    }

    public onDataChanged() {
        this.updateData();
    }

    public get hasExistingCadence() {
        return this.recurrences
            && this.recurrences.size > 0
            && !this.creatingNextCadence;
    }

    @Autobind
    public setCadence() {
        return this.scheduleUiService.updateCadence();
    }

    protected async updateData() {
        this.loading = true;

        const team = await this.teamsService.promiseToGetLeadershipTeam();
        if (!team) {
            throw new Error("Leadership team not found");
        }

        this.recurrences = await this.scheduleService.getCurrentEventSeriesMapForPresetsWithinTeam(this.eventTypePresets, team);

        this.loading = false;
    }
}

import { Inject, Injectable, InjectionToken, Injector, Optional, Provider } from "@angular/core";
import { Workflow } from "@common/ADAPT.Common.Model/embed/workflow";
import { EventCadenceCycle } from "@common/ADAPT.Common.Model/organisation/event-cadence-cycle";
import { EventSeriesType } from "@common/ADAPT.Common.Model/organisation/event-series";
import { EventType, EventTypePreset } from "@common/ADAPT.Common.Model/organisation/event-type";
import { Meeting } from "@common/ADAPT.Common.Model/organisation/meeting";
import { CalendarIntegrationProvider } from "@common/ADAPT.Common.Model/organisation/organisation-detail";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { BaseUiService } from "@common/ux/base-ui.service/base-ui.service";
import { IItem, ISection } from "@common/ux/time-scheduler/time-scheduler.interface";
import moment from "moment/moment";
import { combineLatest, EMPTY, switchMap, take } from "rxjs";
import { OAuthService } from "../oauth/oauth.service";
import { IWorkflowRunData } from "../workflow/workflow.interface";
import { WorkflowRunDialogComponent } from "../workflow/workflow-run-dialog/workflow-run-dialog.component";
import { CadenceClearDialogComponent } from "./cadence-clear-dialog/cadence-clear-dialog.component";
import { ISetCadenceRunData } from "./schedule.interface";
import { ScheduleService } from "./schedule.service";
import { IScheduledRecurrence } from "./schedule-recurrence/schedule-recurrence.interface";

// schedule-ui service is needed in org-common for team-meetings-with-cadence-page component.
// workflow resides in alto only so need to provide it
type GetCadenceWorkflowFunction = (includeCalendarIntegration: boolean, includeSetFirstObjectives: boolean) => Workflow;
export const GET_CADENCE_WORKFLOW_FUNCTION = new InjectionToken<GetCadenceWorkflowFunction>("GET_CADENCE_WORKFLOW_FUNCTION");

export function provideCadenceWorkflowFunction(getCadenceWorkflowFunction: GetCadenceWorkflowFunction): Provider {
    return {
        provide: GET_CADENCE_WORKFLOW_FUNCTION,
        useValue: getCadenceWorkflowFunction,
        multi: false,
    };
}

@Injectable({
    providedIn: "root",
})
export class ScheduleUiService extends BaseUiService {
    public constructor(
        injector: Injector,
        private oauthService: OAuthService,
        private scheduleService: ScheduleService,
        @Inject(GET_CADENCE_WORKFLOW_FUNCTION) @Optional() private buildEstablishCadenceWorkflowFn?: GetCadenceWorkflowFunction,
    ) {
        super(injector);
    }

    public static SchedulerItemFromMeeting(meeting: Meeting): IItem<Meeting> {
        const eventType = meeting.eventSeries?.eventType;
        const section = eventType
            ? ScheduleUiService.SchedulerSectionFromEventType(eventType)
            : ScheduleUiService.SchedulerSectionForAdhocMeeting();

        return {
            sectionId: section.id,
            name: moment(meeting.meetingDateTime).format("ddd"),
            start: moment(meeting.meetingDateTime),
            end: moment(meeting.endTime),
            styles: section.colour
                ? `background-color: ${section.colour}`
                : undefined,
            metadata: meeting,
            transparent: meeting.extensions.isEnded,
        };
    }

    public static SchedulerSectionFromEventType(eventType: EventType): ISection {
        return {
            name: eventType.name,
            code: eventType.code,
            id: eventType.eventTypeId,
            colour: eventType.colour,
            visible: true,
            tooltip: `Show/hide ${eventType.name} meetings`,
            toString: () => `section_${eventType.code}`,
        };
    }

    public static SchedulerSectionForAdhocMeeting(): ISection {
        return {
            name: "Adhoc meeting",
            code: "AM",
            id: -999,
            colour: "var(--adapt-new-day-yellow-500)",
            visible: true,
            tooltip: `Show/hide Ad-hoc meetings`,
            toString: () => "section_AM",
        };
    }

    public static RecurrenceConfigToString({ eventSeries, config }: IScheduledRecurrence, cadenceCycle?: EventCadenceCycle) {
        let output = "";

        if (eventSeries.eventSeriesType === EventSeriesType.Once) {
            // should only be one meeting
            const meeting = ArrayUtilities.getSingleFromArray(eventSeries.meetings);
            output += moment(meeting?.meetingDateTime).format("dddd, MMMM Do YYYY [at] h:mm a");
        } else {
            output = "every ";

            for (const field of config.fields) {
                const choices = field.getChoices?.(eventSeries, cadenceCycle) ?? [];
                const value = choices.find((c) => c.value === eventSeries[field.eventSeriesField]);
                if (value) {
                    if (field.prefix) {
                        output += field.prefix + " ";
                    }
                    output += value.text + " ";
                    if (field.suffix) {
                        output += field.suffix + " ";
                    }
                    output += " ";
                }
            }
        }

        if (eventSeries.location) {
            output += ` at ${eventSeries.location}`;
        }

        return output.trim();
    }

    public promptToClearCadence(eventTypes: EventTypePreset[]) {
        return this.dialogService.open(CadenceClearDialogComponent, eventTypes);
    }

    public updateCadence() {
        return combineLatest([
            this.oauthService.isAuthenticated$,
            this.oauthService.authProvider$,
            this.scheduleService.isLatestEventSeriesForPresetCompleted(EventTypePreset.SetFirstObjectives, true),
        ]).pipe(
            take(1),
            switchMap(([authed, provider, sfoCompleted]) => {
                // only show the Calendar Integration step if the user is not already authed with local
                const noIntegrationPromptForProviders = [CalendarIntegrationProvider.Local, CalendarIntegrationProvider.None];
                const isCalendarIntegrated = authed || (provider && noIntegrationPromptForProviders.includes(provider.id));

                const workflow = this.buildEstablishCadenceWorkflowFn?.(!isCalendarIntegrated, !sfoCompleted);
                if (!workflow) {
                    return EMPTY;
                }

                return this.dialogService.open(WorkflowRunDialogComponent, {
                    workflow,
                    // need to set it here again else it gets overridden in WorkflowRunDialogComponent::initialise
                    runData: workflow.runData,
                } as IWorkflowRunData<ISetCadenceRunData>);
            }),
        );
    }
}

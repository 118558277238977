import { ButtonType } from "devextreme/common";
import { BaseEntity } from "../base-entity";
import { BreezeModelBuilder } from "../breeze-model-builder";
import { Value } from "./value";

export const behaviourMetadata = {
    good: {
        isGood: true,
        type: "success" as ButtonType,
        buttonClass: "btn-outline-success",
        buttonSelectedClass: "btn-success",
        tooltip: "Behaviour that supports, demonstrates or represents this value",
        icon: "fas fa-fw fa-thumbs-up",
        selector: "behaviour-good",
    },
    bad: {
        isGood: false,
        type: "danger" as ButtonType,
        buttonClass: "btn-outline-danger",
        buttonSelectedClass: "btn-danger",
        tooltip: "Behaviour that does not support, demonstrate or represent this value",
        icon: "fas fa-fw fa-thumbs-down",
        selector: "behaviour-bad",
    },
};

export class ValueQuestion extends BaseEntity<ValueQuestion> {
    public valueQuestionId!: number;
    public valueId!: number;
    public question!: string;
    public ordinal!: number;
    public goodBehaviour?: boolean;

    public value!: Value;
}

export const ValueQuestionBreezeModel = new BreezeModelBuilder("ValueQuestion", ValueQuestion)
    .withSortField("ordinal")
    .hasSource()
    .isOrganisationEntity()
    .persistChangedEntity()
    .build();

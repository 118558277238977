import { Component, ElementRef, Input, OnInit, Renderer2 } from "@angular/core";
import { AdaptRenderer } from "../adapt-renderer.service";

@Component({
    selector: "adapt-loading-spinner",
    templateUrl: "./loading-spinner.component.html",
})
export class LoadingSpinnerComponent implements OnInit {
    @Input() public largeSpinner = false;

    private renderer: AdaptRenderer;

    public constructor(
        private elementRef: ElementRef<HTMLElement>,
        renderer: Renderer2,
    ) {
        this.renderer = new AdaptRenderer(renderer);
    }

    public ngOnInit() {
        const classesToAdd = ["loading-spinner"];
        if (!this.largeSpinner) {
            classesToAdd.push("loading-spinner-small");
        }
        this.renderer.addClasses(this.elementRef.nativeElement, classesToAdd);
    }
}

import { CulturalLeadershipBreezeModel } from "@common/ADAPT.Common.Model/organisation/cultural-leadership";
import { CulturalRelationship, CulturalRelationshipBreezeModel } from "@common/ADAPT.Common.Model/organisation/cultural-relationship";
import { BaseQueryUtilities } from "@common/lib/data/base-query-utilities";
import { BreezePredicateUtilities } from "@common/lib/data/breeze-predicate-utilities";
import { IBreezeQueryOptions } from "@common/lib/data/breeze-query-options.interface";
import { MethodologyPredicate } from "@common/lib/data/methodology-predicate";
import { ArrayUtilities } from "@common/lib/utilities/array-utilities";
import { lastValueFrom } from "rxjs";
import { CulturalLeadershipAccessLevels } from "./cultural-leadership-access-levels.enum";

export class CulturalLeadershipQueryUtilities extends BaseQueryUtilities {
    public promiseToGetAllCulturalRelationships() {
        return lastValueFrom(this.commonDataService.getAll(CulturalRelationshipBreezeModel));
    }

    public async promiseToGetCulturalLeadershipConfiguration() {
        const culturalLeadership = await lastValueFrom(this.commonDataService.getAll(CulturalLeadershipBreezeModel))
            .then(ArrayUtilities.getSingleFromArray);

        if (!culturalLeadership) {
            throw new Error("Misconfigured cultural leadership framework service");
        } else {
            return culturalLeadership;
        }
    }

    /**
     * Promise to get the cultural leader relationships for a person valid at a particular date
     * @param {int} personId : The identifier for the person in the cohort (not the leader)
     * @param {date} date : The date at which the relationship should be valid (optional, defaults to today)
     * @returns {Promise} A promise that resolves with the single relationship
     */
    public promiseToGetCulturalRelationshipsForPersonId(personId: number, date?: Date) {
        const predicate = new MethodologyPredicate<CulturalRelationship>("personId", "==", personId)
            .and(BreezePredicateUtilities.getIsActivePredicate(date));

        return lastValueFrom(this.commonDataService.getByPredicate(CulturalRelationshipBreezeModel, predicate));
    }

    public promiseToGetActiveCulturalLeadershipRelationships() {
        const predicate = BreezePredicateUtilities.getIsActivePredicate();

        return lastValueFrom(this.commonDataService.getByPredicate(CulturalRelationshipBreezeModel, predicate));
    }

    /**
     * Get the most recent cultural leader for a person or the one at a given date if supplied.
     * @param {integer} personId - The identifier of the person to get the cultural leader for.
     * @param {object} [date] - The date to search for this cultural leader at.
     * @returns {Promise} A promise that resolves with the cultural leader.
     */
    public promiseToGetCulturalLeadersForPersonId(personId: number, date?: Date) {
        return this.promiseToGetCulturalRelationshipsForPersonId(personId, date)
            .then(extractCulturalLeaders);

        function extractCulturalLeaders(relationships: CulturalRelationship[]) {
            return relationships.map(extractCulturalLeader);

            function extractCulturalLeader(relationship: CulturalRelationship) {
                return relationship.culturalLeader;
            }
        }
    }

    /**
     * Promise to get the cultural cohort relationships for a person valid at a particular date
     * @param {int} personId : The identifier for the cultural leader
     * @param {date} date : The date at which the relationship should be valid (optional, defaults to today)
     * @returns {Promise} A promise that resolves with the single relationship
     */
    public promiseToGetCulturalRelationshipsForCulturalLeaderId(personId: number, date?: Date) {
        const predicate = new MethodologyPredicate<CulturalRelationship>("culturalLeaderId", "==", personId)
            .and(BreezePredicateUtilities.getIsActivePredicate(date));

        return lastValueFrom(this.commonDataService.getByPredicate(CulturalRelationshipBreezeModel, predicate));
    }

    public setCulturalEntityOptions(options: IBreezeQueryOptions, accessLevel: CulturalLeadershipAccessLevels) {
        switch (accessLevel) {
            case CulturalLeadershipAccessLevels.CurrentPerson:
                if (!options.namedParams) {
                    options.namedParams = {};
                }

                options.namedParams.personalData = true;
                break;

            case CulturalLeadershipAccessLevels.QuantitativeAccess:
            case CulturalLeadershipAccessLevels.AnonymousAccess:
                options.isPartial = true;
                options.skipMerge = true;
                break;

            case CulturalLeadershipAccessLevels.FullAccess:
                // nothing to do here
                break;

            default:
                const msg = "Unknown Cultural access level";
                throw new Error(msg + accessLevel);
        }
    }
}

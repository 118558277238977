<div class="h-100"
     *adaptLoading="!inputGroup">
    <div class="ca-info">
        <h2 class="m-0">{{inputGroup?.name}}</h2>

        <adapt-see-more *ngIf="inputGroup?.description"
                        [maxHeight]="70">
            <div [froalaView]="inputGroup?.description"></div>
        </adapt-see-more>
    </div>


    <div class="d-flex flex-column flex-lg-row">
        <div class="flex-fill d-flex flex-column mt-4 mt-lg-0">
            <div *ngFor="let input of inputs; let first = first">
                <adapt-strategic-input [input]="input"
                                       [showIcon]="true"
                                       [ngClass]="{'mt-2': !first}"
                                       [isEditing]="hasEditAccess"
                                       [isCollapsible]="true"></adapt-strategic-input>
            </div>
            <button
                class="w-100 mt-2 p-2"
                adaptButton="addBorderless"
                (click)="createInput()">Add {{stepData?.inputType}}</button>
        </div>
    </div>
</div>

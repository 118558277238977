import { ServiceUri } from "@common/configuration/service-uri";
import { AdaptClientConfiguration, AdaptEnvironment } from "../../configuration/adapt-client-configuration";

// these should match the provider names in the dbo.AspNetUserLogins table
export enum ExternalLoginProvider {
    Microsoft = "Microsoft",
    Google = "Google",
    Adapt = "adapt",
}

export interface IExternalLoginProvider {
    provider: ExternalLoginProvider;
    providerDisplayName: string;
    iconPath: string;
    enabled: boolean;

    // this will typically be a getter as ServiceUri is not defined correctly until app is initialised
    signInUrl: string;
}

export const ExternalLoginProviders: Record<ExternalLoginProvider, IExternalLoginProvider> = {
    [ExternalLoginProvider.Microsoft]: {
        provider: ExternalLoginProvider.Microsoft,
        providerDisplayName: "Microsoft",
        iconPath: "/content/identity/external-login/assets/logo-ms.svg",
        enabled: true,
        get signInUrl() {
            return `${ServiceUri.AccountServiceUri}/ExternalLogin?provider=${ExternalLoginProvider.Microsoft}`;
        },
    },
    [ExternalLoginProvider.Google]: {
        provider: ExternalLoginProvider.Google,
        providerDisplayName: "Google",
        iconPath: "/content/identity/external-login/assets/logo-google.svg",
        enabled: true,
        get signInUrl() {
            return `${ServiceUri.AccountServiceUri}/ExternalLogin?provider=${ExternalLoginProvider.Google}`;
        },
    },
    [ExternalLoginProvider.Adapt]: {
        provider: ExternalLoginProvider.Adapt,
        providerDisplayName: "adapt",
        iconPath: "/favicon.png",
        get enabled() {
            return [AdaptEnvironment.Local, AdaptEnvironment.Devcloud].includes(AdaptClientConfiguration.AdaptEnvironment);
        },
        get signInUrl() {
            return `${ServiceUri.AccountServiceUri}/ExternalLogin?provider=${ExternalLoginProvider.Adapt}`;
        },
    },
};

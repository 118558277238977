import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { Person } from "@common/ADAPT.Common.Model/person/person";
import { Autobind } from "@common/lib/autobind.decorator/autobind.decorator";
import { IBreezeEntity } from "@common/lib/data/breeze-entity.interface";
import { CommonDataService } from "@common/lib/data/common-data.service";
import { UserService } from "@common/user/user.service";
import { AdaptCommonDialogService } from "@common/ux/adapt-common-dialog/adapt-common-dialog.service";
import { EMPTY, Observable } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";

export interface IChainComment<TEntity> {
    readonly person: Person;
    readonly dateTime: Date;
    comment: string;
    readonly entity: TEntity;
    readonly canUpdate: boolean;
    hasUpdate?: boolean;
}

@Component({
    selector: "adapt-chain-comment",
    templateUrl: "./chain-comment.component.html",
    styleUrls: ["./chain-comment.component.scss"],
})
export class ChainCommentComponent<TEntity extends IBreezeEntity<TEntity>> implements OnInit, OnDestroy {
    @Input() public chainItem!: IChainComment<TEntity>;
    @Input() public justAdded = false;
    @Input() public isEditing = false;
    @Output() public isEditingChange = new EventEmitter<boolean>();
    @Output() public saving = new EventEmitter<IChainComment<TEntity>>();
    @Output() public saved = new EventEmitter<IChainComment<TEntity>>();
    @Output() public saveFailed = new EventEmitter<{ e: any, item: IChainComment<TEntity> }>();
    @Output() public deleted = new EventEmitter<IChainComment<TEntity>>();

    public isCurrentPerson: Observable<boolean>;
    public commentIsInvalid = true;
    public entityChangeSubscriptionId?: number;

    public constructor(
        commonUserService: UserService,
        private dialogService: AdaptCommonDialogService,
        private commonDataService: CommonDataService,
    ) {
        this.isCurrentPerson = commonUserService.currentPerson$.pipe(
            map((p) => p === this.chainItem.person),
        );
    }

    public ngOnInit() {
        if (this.chainItem?.entity?.entityAspect?.entityState.isModified()) {
            // modified on init -> restored from previous unsaved session
            this.isEditing = true;
            this.commentIsInvalid = !this.chainItem.comment; // invalid only if previously changed to empty
        }

        this.isEditingChange.emit(this.isEditing);
    }

    public ngOnDestroy() {
        if (this.entityChangeSubscriptionId) {
            this.chainItem.entity.entityAspect.propertyChanged.unsubscribe(this.entityChangeSubscriptionId);
        }
    }

    public toggleEditing() {
        this.isEditing = !this.isEditing;
        this.isEditingChange.emit(this.isEditing);
    }

    @Autobind
    public save() {
        this.saving.emit(this.chainItem);
        return this.commonDataService.saveEntities(this.chainItem.entity).pipe(
            tap(() => {
                this.saved.emit(this.chainItem);
                this.isEditing = false;
            }),
            catchError((e) => {
                this.saveFailed.emit({ e, item: this.chainItem });
                return EMPTY;
            }),
        );
    }

    @Autobind
    public cancel() {
        return this.commonDataService.rejectChanges(this.chainItem.entity).pipe(
            tap(() => this.isEditing = false),
        );
    }

    @Autobind
    public delete() {
        return this.dialogService.openConfirmationDialog({
            title: "Delete Comment",
            message: "Are you sure you wish to delete this comment?",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
        }).pipe(
            // emit saving here to clear any previous submission errors (handled in edit-item-comments.component.ts:onSaving())
            tap(() => this.saving.emit(this.chainItem)),
            switchMap(() => this.commonDataService.remove(this.chainItem.entity)),
            switchMap(() => this.commonDataService.saveEntities(this.chainItem.entity)),
            tap(() => this.deleted.emit(this.chainItem)),
            catchError((e) => {
                this.saveFailed.emit({ e, item: this.chainItem });
                return this.commonDataService.rejectChanges(this.chainItem.entity);
            }),
        );
    }

    public validateComment(isValid: boolean) {
        this.commentIsInvalid = this.chainItem.entity.entityAspect.entityState.isUnchanged()
            || !isValid;
    }
}

import { Component, ElementRef, Injector, OnInit } from "@angular/core";
import { FeatureName } from "@common/ADAPT.Common.Model/embed/feature-name.enum";
import { Survey, SurveyType, SurveyTypeLabel } from "@common/ADAPT.Common.Model/organisation/survey";
import { PersonFlag } from "@common/ADAPT.Common.Model/person/person-flag.enum";
import { AdaptError } from "@common/lib/error-handler/adapt-error";
import { BaseRoutedComponent } from "@common/ux/base-routed.component";
import { OrganisationPageRouteBuilder } from "@org-common/lib/route/organisation-page-route-builder";
import { startWith, switchMap, tap } from "rxjs";
import { PersonFlagService } from "../../person/person-flag.service";
import { getHealthCheckPageTour } from "../health-check-tour";
import { OrganisationDiagnosticAuthService } from "../organisation-diagnostic/organisation-diagnostic-auth.service";
import { SurveyService } from "../survey.service";
import { ISurveyQuestions } from "../survey-questions.interface";
import { ISurveyTypeUtils } from "../survey-type-utils.interface";
import { SurveyUtils } from "../survey-utils";

const SelectedCategoryId = "selectedCategoryId";

@Component({
    selector: "adapt-survey-summary-page",
    templateUrl: "./survey-summary-page.component.html",
    styleUrls: ["./survey-summary-page.component.scss"],
})
export class SurveySummaryPageComponent extends BaseRoutedComponent implements OnInit {
    public survey?: Survey;
    public surveyQuestions?: ISurveyQuestions;
    public categoryIdSelected?: number;

    private surveyUtils?: ISurveyTypeUtils;

    public constructor(
        injector: Injector,
        elementRef: ElementRef,
        private surveyService: SurveyService,
        private personFlagService: PersonFlagService,
    ) {
        super(injector, elementRef);
    }

    public ngOnInit() {
        this.navigationEnd.pipe(
            startWith(undefined),
            tap(() => this.survey = undefined), // trigger the spinner while querying
            switchMap(() => {
                const surveyType = this.getSurveyTypeFromPage();
                this.surveyUtils = SurveyUtils.forSurveyType(surveyType);
                return this.surveyService.getMostRecentEndedSurveys(1, surveyType);
            }),
        ).subscribe(async (surveys) => {
            if (surveys.length > 0) {
                this.survey = surveys[0];
                this.surveyQuestions = this.surveyService.getQuestionsForSurvey(this.survey);
            }

            this.categoryIdSelected = this.getSearchParameterIntValue(SelectedCategoryId);
            this.isInitialised = true;

            if (!this.routeService.getSearchParameterValue(BaseRoutedComponent.PageActivationToursKey)) {
                await this.personFlagService.setFlagAndRunTour(PersonFlag.RanHealthCheckPageTour, getHealthCheckPageTour());
            }

            this.notifyActivated();
        });
    }

    public onCategoryIdChanged(categoryId?: number) {
        this.categoryIdSelected = categoryId;
        if (!categoryId) {
            this.deleteSearchParameter(SelectedCategoryId);
        } else {
            this.setSearchParameterValue(SelectedCategoryId, categoryId);
        }
    }

    public gotoHistory() {
        this.surveyUtils?.gotoManageSurveyPageRoute$().subscribe();
    }

    public gotoAnalyseSurveyPage() {
        this.surveyUtils?.gotoAnalyseSurveyPageRoute$(this.survey?.surveyId).subscribe();
    }

    public unselectCategory() {
        this.categoryIdSelected = undefined;
    }

    protected runTour() {
        this.guidedTourService.run(getHealthCheckPageTour());
    }

    private getSurveyTypeFromPage() {
        const controllerId = this.routeService.currentControllerId;
        if (controllerId === organisationDiagnosticSurveySummaryPage.id) {
            return SurveyType.OrganisationDiagnostic;
        }

        throw new AdaptError(`Page ${controllerId}: SurveyType is not implemented - add it in this function!`);
    }
}

export const organisationDiagnosticSurveySummaryPage = new OrganisationPageRouteBuilder()
    .usingNgComponent("adapt-survey-summary-page", SurveySummaryPageComponent)
    .atOrganisationUrl("/health-check/summary")
    .verifyingFeatures(FeatureName.OrganisationDiagnostic)
    .verifyingAccess(OrganisationDiagnosticAuthService.ReadOrganisationDiagnostic)
    .withTitle(SurveyTypeLabel.OrganisationDiagnostic)
    .reloadOnSearch(false)
    .build();

import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Team } from "@common/ADAPT.Common.Model/organisation/team";
import { BaseComponent } from "@common/ux/base.component/base.component";
import { ConfigurationService } from "@org-common/lib/configuration/configuration.service";
import { FeaturesService } from "@org-common/lib/features/features.service";
import { IConfigItem } from "../../configuration.interfaces";

@Component({
    selector: "adapt-feature-configuration",
    styleUrls: ["./feature-configuration.component.scss"],
    templateUrl: "./feature-configuration.component.html",
})
export class FeatureConfigurationComponent extends BaseComponent implements OnChanges {

    @Input() public configItem!: IConfigItem;
    @Input() public team?: Team;
    @Output() public featureChange = new EventEmitter<boolean>();

    public isFeatureEnabled: boolean = false;
    public enableFeatureText = "";

    constructor(
        protected featuresService: FeaturesService,
        protected configurationService: ConfigurationService,
    ) {
        super();

        this.configurationService.initialiseConfigPage$
            .pipe(this.takeUntilDestroyed())
            .subscribe(() => this.initialiseData());
    }

    public async ngOnChanges(changes: SimpleChanges) {
        if ((changes.team && changes.team.currentValue) || this.configItem) {
            if (this.configItem) {
                this.enableFeatureText = this.configItem.enableFeatureText ?? this.configItem.name;
            }

            await this.initialiseData();
            this.featureChange.emit(this.isFeatureEnabled);
        }
    }

    public async onFeatureStatusValueChanged(value: boolean) {
        if (this.isFeatureEnabled !== value) {
            this.isFeatureEnabled = value;
            await this.featuresService.promiseToSetFeatureStatus(this.configItem!.featureName!, this.team, this.isFeatureEnabled);
            this.featureChange.emit(value);
        }
    }

    public async initialiseData() {
        this.isFeatureEnabled = this.configItem!.featureName
            ? await this.featuresService.promiseToCheckIfFeatureActive(this.configItem!.featureName!, this.team)
            : true;
    }
}
